/**
 * user 전역 상태 리덕스 action 타입
 */
/** Login 시 userId 저장 */
export const SET_SOCIAL_USER_ID = 'SET_SOCIAL_USER_ID';
/** Login 시 login type 저장 */
export const SET_SOCIAL_LOGIN_TYPE = 'SET_SOCIAL_LOGIN_TYPE';
/** login 완료 시점 */
export const SET_SOCIAL_LOGIN_TIME = 'SET_SOCIAL_LOGIN_TIME';
/** login email */
export const SET_SOCIAL_USER_EMAIL = 'SET_SOCIAL_USER_EMAIL';
/** login name */
export const SET_SOCIAL_USER_NAME = 'SET_SOCIAL_USER_NAME';
/** confirm-code */
export const SET_SOCIAL_CONFIRM_CODE = 'SET_SOCIAL_CONFIRM_CODE';
/** all data clear */
export const CLEAR_SOCIAL_USER_STATE = 'CLEAR_SOCIAL_USER_STATE';
// user level
export const SET_USER_LEVEL = 'SET_USER_LEVEL';
