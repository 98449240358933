import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils';
import SessionStorage from 'utils/SessionStorage.utils';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

import { ReactComponent as ImgSvgIconPrinter } from 'assets/img/svg/icon_printer_02.svg';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

/** 아카데미 대시보드의 Students - Classes 페이지 : "Test List" 모달 컴포넌트 */
export default function TestListModal({ setShowModal, classSeq, className, setSelectedStudentList }) {
  const [testListState, setTestList] = useState([
    {
      studentList: [],
      testEndDate: '',
      testName: '',
      testStartDate: '',
      testStatus: '',
    },
  ]); // testStatus : 'P' (완료, Taken) | 'E' (진행 전, Scheduled) | 'I', 'B' (진행 중, In-progress)
  const [isLoading, setIsLoading] = useState(true);

  /** 해당 모달 "닫기" 핸들러 */
  const handleClose = () => {
    setShowModal(false);
    setSelectedStudentList((current) => {
      return {
        ...current,
        isView: false,
      };
    });
  };

  /** "버튼 핸들러 (토글 기능)" */
  const handleToggleButton = ({ event, studentList }) => {
    event.stopPropagation();
    /** 현재 클릭 된 요소의 위치 및 크기 등 정보 */
    const currentPosition = event.target.getBoundingClientRect();

    setSelectedStudentList((current) => {
      return {
        isView: !current.isView,
        studentList,
        position: {
          x: currentPosition.left + currentPosition.width,
          y: currentPosition.top,
        },
      };
    });
  };

  useEffect(() => {
    // 화면 열고 나서 바로 실행되는 함수
    const successHandler = (response) => {
      if (response.code === 200) {
        const testList = response.result.testList;

        /** 자바스크립트 문법으로 가공 */
        const newTestList = testList.map((item) => {
          return { ...item, studentList: JSON.parse(item.studentList) };
        });

        setTestList(newTestList);
        setIsLoading(false);
      }
    };
    request.get(`/api/v2/academy/class/${classSeq}/test-list`, null, successHandler).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
  }, [classSeq]);

  return (
    <SimpleModalBase onEscape={handleClose} p='0 1rem 1rem' w='60rem' gap='0'>
      <S.ModalTitleContainer className='modal_title_container'>
        <div className='left'>
          <S.ModalTitle>{className}</S.ModalTitle>
        </div>
        <button onClick={handleClose}>
          <i className='svg_icon btn_pop_close black'>&nbsp;</i>
        </button>
      </S.ModalTitleContainer>

      <S.TableScrollWrapper className='custom_scroll'>
        <S.Table>
          <thead>
            <S.HeaderTableRow>
              <th className='col_01'>No.</th>
              <th className='col_02'>Test Name</th>
              <th className='col_03'>Status</th>
              <th className='col_04'>Assigned Date</th>
              <th className='col_05'>Student List</th>
            </S.HeaderTableRow>
          </thead>

          <tbody>
            {!isLoading && Array.isArray(testListState) && testListState.length > 0 ? (
              testListState.map((item, index) => {
                /** API에서 받아 온 시험 상태 */
                const status = item.testStatus;

                /** 화면에 표시 할 시험 상태 */
                let displayStatus = '';
                /** 화면에 표시 할 Style 용 시험 상태 class name */
                let displayStatusClassName = '';

                switch (status) {
                  case 'P':
                    // 완료
                    displayStatus = 'Taken';
                    displayStatusClassName = 'p';
                    break;
                  case 'E':
                    // 진행 전
                    displayStatus = 'Scheduled';
                    displayStatusClassName = 'e';
                    break;
                  case 'I':
                    // 진행 중
                    displayStatus = 'In-progress';
                    displayStatusClassName = 'i';
                    break;
                  case 'B':
                    // 진행 중 (쉬는 시간)
                    displayStatus = 'In-progress';
                    displayStatusClassName = 'i';
                    break;
                  default:
                    alert('어떤 값인지 파악이 되지 않습니다.');
                }

                return (
                  <S.TableBodyRow key={`score_${index}`}>
                    <td>{testListState.length - index}</td>
                    <td>{item.testName}</td>
                    <td>
                      <div className={`status_box ${displayStatusClassName}`}>{displayStatus}</div>
                    </td>
                    <td>
                      {isoTimeToDayjs(item.testStartDate).format('YYYY / MM / DD')} ~ {isoTimeToDayjs(item.testEndDate).format('YYYY / MM / DD')}
                    </td>
                    <td>
                      <div className='student_list_area'>
                        <button type='button' onClick={(event) => handleToggleButton({ event, studentList: item.studentList })} className='view_button'>
                          View
                        </button>
                      </div>
                    </td>
                  </S.TableBodyRow>
                );
              })
            ) : (
              <tr>
                <td colSpan='5' style={{ padding: '3rem' }}>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </S.Table>
      </S.TableScrollWrapper>
    </SimpleModalBase>
  );
}

// --- --- ---

const S = {
  ModalTitleContainer: styled.div`
    .left {
      display: flex;
      align-items: center;
    }
    .btn_main_print {
      width: 2rem;
      height: 2rem;
      border: 1px solid #f1f1f5;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
      svg {
        width: 1.5rem;
        color: #505050;
      }
      &:hover {
        filter: brightness(95%);
        svg {
          color: #808080;
        }
      }
    }
  `,
}; // S-dot 문법용 객체

S.ModalTitle = styled.h2`
  color: #111111;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1.2rem 1rem;
`;

S.TableScrollWrapper = styled.div`
  max-height: 23.5rem;
  background-color: white;
  overflow: auto;
`;
S.Table = styled.table`
  width: 100%;
  text-align: center;
  th,
  td {
    padding: 0.75rem 1rem;
    &.col_01 {
      width: 1.5rem;
    }
    &.col_02 {
      width: auto;
    }
    &.col_03 {
      width: 8rem;
    }
    &.col_04 {
      width: 15.6rem;
    }
    &.col_05 {
      width: 8rem;
    }
  }
`;

S.HeaderTableRow = styled.tr`
  position: sticky;
  z-index: 2;
  margin-bottom: 1px;
  th {
    background-color: #000;
    color: #fff;
    height: 2.5rem;
    text-align: center;
    font-weight: 400;
    font-size: 0.875rem;
    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      overflow: hidden;
    }
    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      overflow: hidden;
    }
  }
`;
S.TableBodyRow = styled.tr`
  border-top: 1px solid #eff1f3;

  td {
    font-size: 0.875rem;
    height: 3rem;
    color: #4b5563;
    .status_box {
      font-weight: 400;
      font-size: 0.8125rem;
      background-color: #eff1f3;
      border-radius: 0.25rem;
      display: flex;
      padding: 0.125rem 0.25rem;
      justify-content: center;
      align-items: center;
      &.p {
        color: #ff5858;
      }
      &.e {
        color: #0068bd;
      }
      &.i {
        color: #0da674;
      }
    }

    .student_list_area {
      position: relative;
      .view_button {
        color: #7f8998;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 400;
        border-radius: 0.25rem;
        background-color: #f2f2f2;
        padding: 0.125rem 0.5rem;
      }
    }
  }
`;
