import styled from 'styled-components';
import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import ModalTitleBtn from 'components/_common/modals/_components/ModalTitleBtn';
import useUserLevels from 'hooks/useUserLevels';
import request from 'utils/Request.utils';
import { useNavigate } from 'react-router-dom';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';

import localStorage from 'utils/LocalStorage.utils';

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const DSAT_MODE = { SAT: 'SAT', AP: 'AP' };
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** 컴포넌트 JSDoc */
export default function SelectAPModal({ onClose = null }) {
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels } = useUserLevels(userInfo?.userLevel);
  const navigate = useNavigate();

  /** 모달 닫기 핸들러 - sat 클릭시에도 동작 */
  const handleClose = () => {
    localStorage.setItem('util', 'examMode', 'sat');
    onClose();
  };

  /** AP 클릭시 권한에 따른 경로 이동 */
  const handleClickAP = () => {
    localStorage.setItem('util', 'examMode', 'ap');

    const apB2bPath = `/ap/com/score/${obfuscateUrlParam('0')}?changeMode=${DSAT_MODE.AP}`;
    const apStudentPath = `/ap/student/dashboard?changeMode=${DSAT_MODE.AP}`;
    const apParentPath = `/ap/parent/dashboard?changeMode=${DSAT_MODE.AP}`;

    if (useLevels.isAcademy)
      navigate(apB2bPath); // 학원 관리자
    else if (useLevels.isStudent)
      navigate(apStudentPath); // 학생
    else if (useLevels.isTeacher)
      navigate(apB2bPath); // 선생님
    else if (useLevels.isTutor)
      navigate(apB2bPath); // 강사
    else navigate(apParentPath); // 학부모
  };

  return (
    <SimpleModalBase p='2rem'>
      <S.HeaderContainer>
        <div>
          <S.HeaderTitle>Select the test you want to take.</S.HeaderTitle>
          <S.HeaderDescription>Click on left for SAT, right for AP. You can switch anytime after your choice on the top-right menu.</S.HeaderDescription>
        </div>
        <div>
          <ModalTitleBtn onClick={handleClose} />
        </div>
      </S.HeaderContainer>

      <S.OptionsContainer>
        {/* SAT 카드 */}
        <OptionCard onClick={handleClose}>
          <S.GradientOverlaySAT aria-hidden='true' />
          <S.GradientOverlaySATHover aria-hidden='true' />

          <S.CardContent>
            <S.CardContentTop>
              <CardTitle>
                <span>DSAT</span>
              </CardTitle>
              <S.CardDescription>Click here to take Digital SAT test.</S.CardDescription>
            </S.CardContentTop>
          </S.CardContent>

          <S.ExamCard>
            <p>SAT</p>
          </S.ExamCard>
        </OptionCard>

        {/* AP 카드 */}
        <OptionCard onClick={handleClickAP}>
          <S.GradientOverlayAP aria-hidden='true' />
          <S.GradientOverlayAPHover aria-hidden='true' />

          <S.CardContent>
            <S.CardContentTop>
              <S.APCardTitle>
                <span>AP</span>
              </S.APCardTitle>
              <S.CardDescription>Click here to take Digital AP Test</S.CardDescription>

              <S.ExamCard>
                <p className='ap_card'>AP</p>
              </S.ExamCard>
            </S.CardContentTop>
          </S.CardContent>

          {/* <APImage src={freeTestMini1Img} alt="" /> */}
        </OptionCard>
      </S.OptionsContainer>
    </SimpleModalBase>
  );
}

// --- --- --- Styled Components --- --- --- //
const OptionCard = styled.button`
  width: 29rem;
  height: 20rem;
  border-radius: 1.25rem;
  border: 1px solid #eaeaea;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    box-shadow: 6px 8px 8px 0px rgba(0, 0, 0, 0.12);
  }
`;

const CardTitle = styled.p`
  font-size: 3rem;
  line-height: 3.625rem;
  white-space: nowrap;
  text-align: left;

  span {
    font-weight: bold;
  }
`;

const S = {
  HeaderContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  HeaderTitle: styled.h3`
    font-size: 1.875rem;
    line-height: 2rem;
    text-align: left;
  `,

  HeaderDescription: styled.p`
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #757575;
    text-align: left;
  `,

  OptionsContainer: styled.div`
    display: flex;
    gap: 20px;
  `,

  GradientOverlaySAT: styled.div`
    position: absolute;
    inset: 0;
    background: linear-gradient(113deg, #fff 27.98%, #e1e3fd 94.93%);
    transition: opacity 0.3s;

    ${OptionCard}:hover & {
      opacity: 0;
    }
  `,

  GradientOverlaySATHover: styled.div`
    position: absolute;
    inset: 0;
    background: linear-gradient(127deg, #fff 26.25%, #aeaaf5 95.43%);
    transition: opacity 0.3s;
    opacity: 0;

    ${OptionCard}:hover & {
      opacity: 1;
    }
  `,

  GradientOverlayAP: styled.div`
    position: absolute;
    inset: 0;
    background: linear-gradient(0deg, #fffaea 0%, #fff 89.29%);
    transition: opacity 0.3s;

    ${OptionCard}:hover & {
      opacity: 0;
    }
  `,

  GradientOverlayAPHover: styled.div`
    position: absolute;
    inset: 0;
    background: linear-gradient(0deg, #ffeeb9 0%, #fff 89.29%);
    transition: opacity 0.3s;
    opacity: 0;

    ${OptionCard}:hover & {
      opacity: 1;
    }
  `,

  CardContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding: 2rem;
    position: relative;
    z-index: 10;
    height: 100%;

    @media (min-width: 1024px) {
      padding: 32px 32px 40px;
    }
  `,

  CardContentTop: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,

  APCardTitle: styled(CardTitle)`
    span {
      font-family: 'Quicksand', sans-serif;
    }
  `,

  CardDescription: styled.p`
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #757575;
    text-align: left;
  `,

  ExamCard: styled.div`
    position: absolute;
    bottom: -3rem;
    right: -3rem;
    transform: rotate(-10deg);
    transition: all 0.3s;
    width: 22rem;
    height: 15rem;
    background-color: white;
    padding: 2rem;
    border-radius: 1.25rem;
    box-shadow: -3px -2px 24px 0px rgba(0, 0, 0, 0.06);

    > p {
      font-family: 'Roboto Slab', serif;
      font-size: 8rem;
      font-weight: 900;
      line-height: 0.9;
      word-break: keep-all;
      color: #029cdd;
    }
    .ap_card {
      padding-left: 3rem;
    }

    ${OptionCard}:hover & {
      transform: rotate(-5deg);
      right: -24px;
    }
  `,

  SATImage2: styled(Image)`
    position: absolute;
    bottom: -28px;
    right: 3.75rem;
    width: 307px;
    height: 200px;
    transition: all 0.3s;

    ${OptionCard}:hover & {
      transform: scale(1.02);
    }
  `,

  APImage: styled(Image)`
    position: absolute;
    transform: rotate(-4deg);
    transition: all 0.3s;
    bottom: -40px;
    right: -40px;
    width: 16.625rem;
    height: 14.1875rem;
  `,
};
