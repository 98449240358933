import React from 'react';
import styled from 'styled-components';

/** "아케데미 대시보드" 페이지 - "Test Status" 섹션 별 타이틀 문구 스타일 용 컴포넌트 */
export default function Title({ children, ...rest }) {
  return <S.Wrap {...rest}>{children}</S.Wrap>;
}

const S = {
  Wrap: styled.h4`
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;
  `,
};
