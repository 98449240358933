//#region    ////////// packages
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LabelText from './_components/styles/LabelText';
//#endregion ////////// packages

//#region    ////////// components

//#endregion ////////// components

/** AP / "Question Bank" 페이지의 첫 번째 화면 (과목 선택 단계) */
export default function CreateTestIntro({
  currentStep,
  setCurrentStep,
  subjectsData,
  currentSelectedSubject,
  setCurrentSelectedSubject,
  testNameInputValue,
  setTestNameInputValue,
  PrimaryButtonArea = ({ handleNext, handleCancel, cancelButtonText, className = '', ...rest }) => {},
}) {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const navigate = useNavigate();
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  /** "Next" 버튼 핸들러 */
  const handleNext = () => {
    if (currentSelectedSubject < 1) {
      alert('Please enter the Subject fields');
      return;
    }
    setCurrentStep((current) => (current += 1));
  };

  /** "Cancel" 버튼 핸들러 */
  const handleCancel = () => {
    if (window.confirm('Do you really want to leave?')) {
      navigate('/ap/question-bank');
    }
  };

  return (
    <>
      <S.CreateTestContainer>
        <S.CreateTestInputContainer>
          <div className='input_container'>
            <div className='input_label_wrapper'>
              <div className='required_mark'>*</div>
              <LabelText>Subject</LabelText>
            </div>

            {/* <SelectBox
              className='text_input_field'
              handleChange={(e) => {
                console.log('🚀 ~ QuestionBankRootPage ~ e:', e);
                // setSelectedSubject(e);
              }}
              placeholder='Select Subject'
              optionList={selectSubject} // individual 제외
              value={selectedSubject}
              fixedValue='Select Class'
            /> */}
            {/* <CustomSelect
              className='create-test-select'
              options={selectSubject}
              onSelect={(value) => {
                setSelectedSubject(value);
              }}
            /> */}
            <select id='subject-select' className='input-field' value={currentSelectedSubject} onChange={(e) => setCurrentSelectedSubject(e.target.value)}>
              <option value=''>Select a subject</option>
              {subjectsData.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>

          <div className='input_container'>
            <div className='input_label_wrapper'>
              <div className='required_mark'>*</div>
              <LabelText>Test Name</LabelText>
            </div>

            <input
              type='text'
              id='test_name'
              value={testNameInputValue}
              onChange={(e) => setTestNameInputValue(e.target.value)}
              className='text_input_field test_input'
              placeholder='Enter'
            />
          </div>
        </S.CreateTestInputContainer>
      </S.CreateTestContainer>
      <PrimaryButtonArea handleNext={handleNext} handleCancel={handleCancel} className={`${currentStep === 1 ? 'center' : ''}`} />
    </>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  CreateTestContainer: styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    width: 74.625rem;

    padding: 3rem 0;

    .input_container {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      gap: 1rem;

      .text_input_field {
        width: 380px;
      }
    }
  `,

  CreateTestInputContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: flex-end;
    width: 31.5625rem;
  `,
};
