import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgIconSelect } from 'assets/img/svg/icon_arrow_02.svg';
import SimpleLineChart from 'components/_common/chart/SimpleLineChart.js';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { STUDENT_PARAMS } from 'utils/constants';

/** 차트에서 한번에 보여줄 최대 길이 */
const TREND_LENGTH = 8;
/** 선택된 데이터로 부터의 간격 */
const INDEX_OFFSET = 4;

/** Average Weekly Scores */
export default function ScoreTrend({ currentClassTestData }) {
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** 현재 선택 된 시험 세트의 UTH Seq */
  const selectedUthSeq = getObfuscatedValue(STUDENT_PARAMS.UTH_SEQ.KEY);

  /** 차트에 보여줄 데이터의 시작 index */
  const [startIndex, setStartIndex] = useState(Math.max(0, currentClassTestData.length - TREND_LENGTH));

  // 이전/다음 버튼 활성화 상태 계산
  const { canGoPrevious, canGoNext } = useMemo(() => {
    return {
      canGoPrevious: startIndex > 0,
      canGoNext: startIndex + TREND_LENGTH < currentClassTestData.length,
    };
  }, [startIndex, currentClassTestData.length]);

  /** 이전 버튼 클릭 핸들러 */
  const handlePrevious = () => {
    if (canGoPrevious) {
      const newIndex = Math.max(0, startIndex - TREND_LENGTH);
      setStartIndex(newIndex);
    }
  };

  /** 다음 버튼 클릭 핸들러 */
  const handleNext = () => {
    if (canGoNext) {
      const newIndex = Math.min(currentClassTestData.length - TREND_LENGTH, startIndex + TREND_LENGTH);
      setStartIndex(newIndex);
    }
  };

  /** 차트에 전달할 데이터 객체 */
  const chartData = {
    seriesData: currentClassTestData.slice(startIndex, startIndex + TREND_LENGTH),
    startIndex: startIndex,
  };

  // 데이터가 변경될 때마다 startIndex를 업데이트
  useEffect(() => {
    if (currentClassTestData?.length) {
      // URL 쿼리 파라미터에 특정 시험 기록(uthSeq)이 지정된 경우
      if (selectedUthSeq) {
        // 선택된 시험 기록의 배열 내 위치를 찾음
        const targetIndex = currentClassTestData.findIndex((data) => data.uthSeq === Number(selectedUthSeq));

        // 선택된 시험 기록이 존재하고 && 선택된 위치 이후로 SELECTED_INDEX 만큼의 추가 데이터를 보여줄 수 있는 경우
        if (targetIndex >= 0 && currentClassTestData.length - (targetIndex + INDEX_OFFSET) >= 0) {
          // 전체 데이터 개수와 TREND_LENGTH 중 작은 값을 선택하여
          // 선택된 시험 기록 이후로 SELECTED_INDEX만큼 더 보이도록 시작 위치 조정
          setStartIndex(Math.min(currentClassTestData.length - TREND_LENGTH, currentClassTestData.length - (targetIndex + INDEX_OFFSET)));

          // 선택된 시험 기록이 없거나, 충분한 추가 데이터가 없는 경우 처음부터 보여줌
        } else {
          setStartIndex(0);
        }

        // 선택된 시험 기록이 없을 경우, 전체 데이터 중 최근 TREND_LENGTH개만큼만 보이도록 시작 위치 설정
      } else {
        setStartIndex(Math.max(0, currentClassTestData.length - TREND_LENGTH));
      }
    }
  }, [currentClassTestData, selectedUthSeq]);

  return (
    <S.Wrap className='weekly'>
      <S.SectionTitle>Score Trend</S.SectionTitle>
      <SimpleLineChart chartData={chartData} />
      <S.ChartBtnSection>
        <button className={`prev-btn ${!canGoPrevious ? 'disabled' : ''}`} onClick={handlePrevious} disabled={!canGoPrevious}>
          <ImgIconSelect />
        </button>
        <p>Test</p>
        <button className={`next-btn ${!canGoNext ? 'disabled' : ''}`} onClick={handleNext} disabled={!canGoNext}>
          <ImgIconSelect />
        </button>
      </S.ChartBtnSection>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    flex: 4.3;
  `,
  SectionTitle: styled.h4`
    color: #111111;
    font-weight: 600;
    font-size: 1.25rem;
  `,
  ChartBtnSection: styled.section`
    padding-top: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: 1rem;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .prev-btn {
      transform: rotate(90deg);
    }
    .next-btn {
      transform: rotate(270deg);
    }
  `,
};
