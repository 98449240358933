import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import request from 'utils/Request.utils';

import IconEmpty from 'assets/img/icon_empty.png';

import CustomAlert from 'components/_common/alerts/CustomAlert';
import ByClassListSection from './ByClassListSection';

import { useInfiniteScroll } from 'hooks/useInfiniteScroll';

/**
 * tests/scheduled 페이지의 "By Class" 텝에 해당하는 컨텐츠 영역 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/tests/scheduled
 */
export default function ByClass({ classEditClick, reloadData, classDeleteClick }) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const navigate = useNavigate();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /////////////////// React useRef 선언 영역 끝 ///////////////////////
  const [isLoading, setIsLoading] = useState(false);
  /** CustomAlert 코드 */
  const alertAttributeValue = {
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    returnValue: () => {},
  };
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  /** 최초 로드 여부 - 한번만 초기 데이터를 호출하기 위해 */
  const isFirstLoad = useRef(true);
  /** 현재 페이지 */
  const currentPageRef = useRef(1);
  /** 총 갯수 */
  const totalCountRef = useRef(0);
  /** 더 불러올수 있는 값이 있는지 */
  const [hasMore, setHasMore] = useState(true);
  const [scheduledClassList, setScheduledClassList] = useState([]);
  // scheduledClassList 타입 : [{
  //     classSeq: 0, // number    => 클래스 고유번호
  //     className: '', // string  => 클래스 이름
  //     customSetList: [
  //       {
  //         customSetSeq: 0, // number           => 커스텀 세트 고유번호
  //         testName: '', // string              => 테스트 이름
  //         customSetSeqStr: '', // string       => 커스텀 세트 고유번호 문자열
  //         testStartDate: '', // string         => 테스트 시작일
  //         testEndDate: '', // string           => 테스트 종료일
  //         isPrintAvailable: false, // boolean  => 인쇄 가능 여부 ( 미사용 )
  //         studentList: [
  //           {
  //             uthSeq: 0, // number                => 유저 고유번호
  //             userSeq: 0, // number               => 유저 고유번호
  //             upFileSeq: 0, // number             => 유저 프로필 이미지 파일 고유번호
  //             userName: '', // string             => 유저 이름
  //             testDate: '', // string             => 테스트 일자
  //             testStartDateKst: '', // string     => 한국 테스트 시작일 ( 미사용 )
  //             testTakenEndDateKst: null, // null  => 한국 테스트 완료일 ( 미사용 )
  //           },
  //         ],
  //       },
  //     ],
  //   }]

  const getScheduledClassList = (isReload = false) => {
    setIsLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        totalCountRef.current = response.result.totalCnt;
        const fetchedScheduledClassList = response.result.scheduleList;

        if (isReload) {
          currentPageRef.current = 2;
          setScheduledClassList(fetchedScheduledClassList);
          setHasMore(fetchedScheduledClassList.length < response.result.totalCnt);
        } else {
          // 현재 페이지 증가
          currentPageRef.current += 1;
          setScheduledClassList((prev) => [...prev, ...fetchedScheduledClassList]);
          // 더 불러올수 있는 값이 있는지 여부
          setHasMore(scheduledClassList.length + fetchedScheduledClassList.length < response.result.totalCnt);
        }
      }
    };

    request
      .get(`/api/v2/test/schedules/exams-by-class?currentPage=${isReload ? 1 : currentPageRef.current}`, null, successHandler)
      .catch((error) => console.error('com/tests/scheduled > ByClass > getScheduledClassList() - API 호출 실패', error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isFirstLoad.current) {
      getScheduledClassList();
      isFirstLoad.current = false;
    } else {
      getScheduledClassList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  /** 무한 스크롤 옵저버 설정 ( hook ) */
  const obsRef = useInfiniteScroll(getScheduledClassList, hasMore);

  return (
    <>
      <S.ByClassWrap>
        {scheduledClassList.length > 0 ? (
          <S.ClassListWrap>
            {scheduledClassList.map((item, index) => (
              <ByClassListSection
                testData={item}
                setAlertLayerPopup={setAlertLayerPopup}
                classEditClick={classEditClick}
                classDeleteClick={classDeleteClick}
                key={`class_${item.classSeq}_${index + 1}`}
              />
            ))}
          </S.ClassListWrap>
        ) : (
          // 데이터 없을 경우 노출
          <div className='com_nodata'>
            <p className='tit'>No test available.</p>
            <p className='txt'>Go to Set Test and set more test!</p>
            <div className='img'>
              <img src={IconEmpty} alt='' />
            </div>
            <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
              GO TO SET TEST
            </button>
          </div>
        )}
      </S.ByClassWrap>
      {!isLoading && <div ref={obsRef}></div>}
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={() => setAlertLayerPopup(() => ({ ...alertAttributeValue }))}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </>
  );
}

const S = {
  ByClassWrap: styled.div`
    padding: 1.25rem 1rem;
    background: #fff;
  `,
  ClassListWrap: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    background: white;
  `,
  ClassTestListSection: styled.div`
    flex: 1 1 400px;
    max-width: calc(50% - 0.5rem);
    min-width: 0;
    max-height: 39.75rem;
    padding: 1.25rem 1rem;
    border-radius: 0.625rem;
    background: #f8f8f9;

    .class_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;

      .class_header_title {
        line-height: 1.5;
        font-weight: 600;
        color: #0068bd;
      }

      .class_header_btn {
        font-size: 0.875rem;
        font-weight: 700;
        color: #4b5563;
        border: 1px solid #ebebeb;
        background: white;
        border-radius: 0.5rem;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .class_list_wrap {
      max-height: 34.625rem;
      overflow-x: hidden;
    }
  `,
};