import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import dayjsTZ from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils.js';
import { nvl } from 'utils/Common.utils.js';
import useOutsideClick from 'hooks/useOutsideClick.js';

//img
import IconEmpty from 'assets/img/icon_empty.png';
//components
import Paging from 'layout/Paging'; //페이징
import MiniAlert from 'components/_common/alerts/MiniAlert'; //한줄 알럿
import MainLink from 'components/_common/button/_MainLinks';
import SendMessage from './_components/modals/SendMessage';

/**
 * Test Status By Classes Page
 * @description
 * 🔍 검색 키워드 - #com/tests/status
 */
export default function TestStatusByClassesPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultClassSeq = location.state?.classSeq || 0;

  /////////////////// useState 선언 영역 시작 ////////////////////
  const [seeEveryTest, setSeeEveryTest] = useState(!!location.state?.seeEveryTest);
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const [sendMessage, setSendMessage] = useState(false);
  const [alert, alertSet] = useState(false);
  const [classInfo, setClassInfo] = useState({
    active: false,
    currentSeq: 0,
    currentName: '',
    list: [],
  });
  /** 시험 정보 */
  const [testInfo, setTestInfo] = useState({
    list: [],
    active: null, // 활성 화된 시험의 customSetSeq
    testName: '',
  });
  /** 학생 정보 */
  const [studentInfo, setStudentInfo] = useState({
    list: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });
  /** 체크 박스 */
  const [checkedList, setCheckedList] = useState([]);
  /////////////////// useState 선언 영역 끝 ////////////////////

  /////////////////// 기타 핸들러 선언 영역 시작 ////////////////////
  const PopSendMessage = () => {
    setSendMessage((prev) => !prev);
  };

  const getTestList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let testList = response.result.testList;
        testList = testList.filter((i) => nvl(i.testName) !== '');
        if (testList.length > 0) {
          if (seeEveryTest) {
            setTestInfo((prev) => {
              return { ...prev, list: testList, active: 0 };
            });
            setSeeEveryTest(false);
          } else {
            setTestInfo((prev) => {
              return {
                ...prev,
                list: testList,
                active: testList[0].customSetSeq,
                testName: testList[0].testName,
              };
            });
          }
        } else {
          setTestInfo((prev) => {
            return { ...prev, list: [], active: 0, testName: '' };
          });
          setStudentInfo((prev) => {
            return { ...prev, list: [], currentPage: 1 };
          });
        }
      }
    };

    request.get(`/api/dsat/schedule/ongoingness?ecSeq=${userInfo.ecSeq}&classSeq=${classInfo.currentSeq}`, null, successHandler);
  };

  const limitSelect = (e) => {
    let limit = e.target.value;

    setStudentInfo((prev) => {
      return { ...prev, numPerPage: limit };
    });
  };
  const nextPageMove = (value) => {
    setStudentInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };
  const getStudentList = (customSetSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let testList = response.result.testDetailList;
        let totalCnt = response.result.totalCnt;

        setStudentInfo((prev) => {
          return { ...prev, list: testList, totalCount: totalCnt };
        });
      }
    };

    request.get(
      `/api/dsat/schedule/ongoingness/detail?ecSeq=${userInfo.ecSeq}&classSeq=${classInfo.currentSeq}&customSetSeq=${customSetSeq}&currentPage=${studentInfo.currentPage}&limit=${studentInfo.numPerPage}`,
      null,
      successHandler
    );
  };

  const handleCheckboxChange = (userSeq, isChecked) => {
    if (isChecked) setCheckedList((prev) => [...prev, userSeq]);
    else setCheckedList((prev) => prev.filter((id) => id !== userSeq));
  };
  const handleAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) setCheckedList(studentInfo.list.map((data) => data.userSeq));
    else setCheckedList([]);
  };
  /////////////////// 기타 핸들러 선언 영역 끝 ////////////////////

  /////////////////// useEffect 선언 영역 시작 ////////////////////
  useEffect(() => {
    const getClassList = () => {
      const successHandler = (response) => {
        console.log(response);
        if (response.code === 200) {
          let classList = response.result.classList;
          if (classList && classList.length === 0) return;

          if (defaultClassSeq === 0)
            setClassInfo((prev) => {
              return {
                ...prev,
                list: classList,
                currentSeq: classList[0].classSeq,
                currentName: classList[0].className,
              };
            });
          else {
            let defaultClass = classList.filter((i) => i.classSeq === defaultClassSeq);
            setClassInfo((prev) => {
              return {
                ...prev,
                list: classList,
                currentSeq: defaultClass[0].classSeq,
                currentName: defaultClass[0].className,
              };
            });
          }
        }
      };

      request.get(`/api/dsat/classes?ecSeq=${userInfo.ecSeq}`, null, successHandler);
    };

    if (userInfo.ecSeq > 0) getClassList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (classInfo.currentSeq > 0) getTestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classInfo.currentSeq]);

  useEffect(() => {
    if (testInfo.list.length > 0) getStudentList(testInfo.active);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testInfo.active, classInfo.currentSeq, studentInfo.numPerPage, studentInfo.currentPage]);
  /////////////////// useEffect 선언 영역 끝 ////////////////////

  // hook
  const classListBox = useRef(null);
  useOutsideClick(classListBox, () =>
    setClassInfo((prev) => {
      return { ...prev, active: false };
    })
  );

  return (
    <>
      <MainLink />
      <S.Wrap className='com_center_wrap com_container p_teststatusbyclasses'>
        <div className='classes_select_sec'>
          <div
            className={`com_select_layer ${classInfo.active ? 'active' : ''}`}
            onClick={() =>
              setClassInfo((prev) => {
                return { ...prev, active: !classInfo.active };
              })
            }
            ref={classListBox}>
            {/*active 시 활성화*/}
            <input type='text' className='selected' placeholder={classInfo.currentName} />
            <div className='layer'>
              {classInfo.list &&
                classInfo.list?.map((item, index) => {
                  return (
                    <p
                      className='option'
                      key={`class_list_${index}`}
                      onClick={() =>
                        setClassInfo((prev) => {
                          return {
                            ...prev,
                            active: false,
                            currentName: item.className,
                            currentSeq: item.classSeq,
                          };
                        })
                      }>
                      {item.className}
                    </p>
                  );
                })}
            </div>
          </div>
        </div>
        <div className='box chart_sec'>
          <section className='tit_area'>
            <h2 className='tit'>Test List</h2>
            <article className='date'>Today {dayjsTZ().format('MM / DD / YYYY')}</article>
          </section>
          <section className='scroll'>
            {testInfo.list &&
              testInfo.list.map((item, index) => {
                return (
                  <article
                    className={`item ${item.customSetSeq === testInfo.active ? 'active' : ''}`}
                    key={`student_${index + 1}`}
                    onClick={() => {
                      setTestInfo((prev) => {
                        return {
                          ...prev,
                          active: item.customSetSeq, // customSetSeq의 해당하는 시험 리스트 가져옴
                          testName: item.testName,
                        };
                      });
                      setStudentInfo((prev) => {
                        return { ...prev, currentPage: 1 }; //
                      });
                    }}>
                    <div className='info'>
                      <p className='test_name'>{item.testName}</p>
                      {/* <p className="date">04 / 25 / 2024 ~ 04 / 25 / 2024</p> */}
                    </div>
                    {/* <div className="chart">
                  차트 영역
                </div> */}
                  </article>
                );
              })}
          </section>
        </div>
        <div className='box list_sec'>
          <div className='com_list'>
            <section className='top'>
              <article className='btn_area'>
                {/* <button className="com_btn line point m" onClick={() => setSendMessage(true)}><i className="svg_icon icon_message blue before">&nbsp;</i> Send Message</button> */}
              </article>
              <article className='list_tit'>{testInfo.active === 0 ? 'EVERY TEST' : testInfo.testName}</article>
              <article className='search_area '>
                <select className='select_list' onChange={limitSelect} value={studentInfo.numPerPage}>
                  <option value='10'>10 Line</option>
                  <option value='25'>25 Line</option>
                  <option value='50'>50 Line</option>
                </select>
              </article>
            </section>
            <table className='list'>
              <colgroup>
                <col width='7.8%' />
                <col />
                <col width='23%' />
                <col width='25%' />
                <col width='24%' />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <input
                      type='checkbox'
                      className='com_chk'
                      id='chkAll'
                      checked={nvl(studentInfo.list) !== '' && studentInfo.list.length > 0 && studentInfo.list.length === checkedList.length}
                      onChange={handleAllCheckboxChange}
                    />
                    <label htmlFor='chkAll'>&nbsp;</label>
                  </th>
                  <th>Name</th>
                  <th>Test Set</th>
                  <th>End Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {nvl(studentInfo.list) !== '' && studentInfo.list.length > 0 ? (
                  studentInfo.list.map((item, index) => (
                    <tr key={`student_list_${index + 1}`}>
                      <td>
                        <input
                          type='checkbox'
                          className='com_chk'
                          id={`chk${index + 1}`}
                          checked={checkedList.includes(item.uthSeq)}
                          onChange={(e) => handleCheckboxChange(item.uthSeq, e.target.checked)}
                        />
                        <label htmlFor={`chk${index + 1}`}></label>
                      </td>
                      <td className='tit_field alignL'>
                        <p className='ellipsis'>{item.userName}</p>
                      </td>
                      <td className='tit_field alignL'>{item.testName}</td>
                      <td>{dayjs(item.testEndDate).format('MM / DD / YYYY')}</td>
                      <td>
                        <p className={`state ${item.testStatus === 'P' ? 'completed' : item.testStatus === 'E' ? 'not' : 'ing'}`}>{item.testStatusName}</p>
                      </td>
                      {/* className state, not, ing, completed */}
                    </tr>
                  ))
                ) : (
                  // 데이터 없을 경우 노출
                  <tr>
                    <td colSpan='5'>
                      <div className='com_nodata'>
                        <p className='tit'>No test available.</p>
                        <p className='txt'>Go to Set Test and set more test!</p>
                        <div className='img'>
                          <img src={IconEmpty} alt='' />
                        </div>
                        <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
                          GO TO SET TEST
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className='com_btn_wrap bottom right'>
              <button
                className='com_btn s line'
                onClick={() => {
                  setTestInfo((prev) => {
                    return { ...prev, active: 0 }; // customSetSeq 0 이면 모든 시험 정보 가져옴
                  });
                  setStudentInfo((prev) => {
                    return { ...prev, currentPage: 1 }; // 페이지 초기화
                  });
                }}>
                See every test
              </button>
            </div>
            <Paging
              totalCount={studentInfo.totalCount}
              currentPage={studentInfo.currentPage}
              numPerPage={studentInfo.numPerPage}
              pagePerBlock={studentInfo.pagePerBlock}
              nextPageMove={nextPageMove}
            />
          </div>
        </div>
      </S.Wrap>
      <SendMessage sendMessage={sendMessage} setSendMessage={PopSendMessage} />
      <MiniAlert text='Status Changed' type='onlyText' active={alert} inactive={() => alertSet(false)} timeOut={2000} />
    </>
  );
}

const S = {
  Wrap: styled.div`
    &.com_container,
    .com_container {
      background: #f4f8ff;
      padding: 15px;
      border-radius: 10px;
    }
    .full {
      width: 100%;
    }
  `,
};
