//#region    ////////// packages
import { useState } from 'react';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import LabelFileUpload from 'components/user/sign-up/_components/pages/upload/LabelFileUpload';
import UploadedFileItem from 'components/user/sign-up/_components/pages/upload/UploadedFileItem';
import DeleteModal from 'components/user/sign-up/_components/pages/upload/DeleteModal';
import SimpleAlertModal from 'components/_common/modals/_SimpleAlertModal';
//#endregion ////////// components

//#region    ////////// utils & hooks
import { allowImages, allowPdfFiles } from 'utils/Common.utils';
//#endregion ////////// utils & hooks

/** 파일 업로드 컴포넌트 */
export default function FileUpload({ uploadFileList, setUploadFileList, postFileUpload, deleteFile }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** 현재 선택 된 파일 아이템  */
  const [currentFileItem, setCurrentFileItem] = useState(false);
  /** 삭제 확인 모달 노출 여부 상태 */
  const [isViewDeleteModal, setIsViewDeleteModal] = useState(false);
  /** 간단한 알림 모달 상태 */
  const [simpleAlert, setSimpleAlert] = useState({
    modalText: '',
    visible: false,
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const handleDragOver = (event) => event.preventDefault();
  const handleDrop = (event) => {
    event.preventDefault();

    const fileList = event.dataTransfer.files;
    getFileListInfo(fileList);
  };
  const handleFileInput = (e) => {
    const fileList = e.target.files;
    getFileListInfo(fileList);

    e.target.value = '';
  };
const getFileListInfo = async (fileList) => {
  let tempFileList = [...uploadFileList];
  const filesToUpload = [];

  // 유효한 파일만 필터링하고 기존에 업로드되지 않은 파일 확인
  for (const file of fileList) {
    const { name, size, type } = file;
    const mbSize = (size / (1024 * 1024)).toFixed(2) + 'mb';

    if (!allowImages(type) && !allowPdfFiles(type)) {
      setSimpleAlert((prev) => ({
        ...prev,
        visible: true,
        modalText: 'Please upload image file or pdf file.',
      }));

      return;
    }

    const fileExists = tempFileList.some((existingFile) => existingFile.fileName === name);

    // 이미 업로드 된 파일 중 동일한 이름을 가진 파일이 없을때만 동작
    if (!fileExists) {
      filesToUpload.push(file);
    }
  }

  // 업로드할 파일이 있을 경우에만 API 호출
  if (filesToUpload.length > 0) {
    try {
      // 여러 파일을 한 번에 API로 전송 && 업로드 결과를 배열로 받아오기
      const uploadResult = await postFileUpload(filesToUpload);

      // 파일별 정보 처리 및 상태 업데이트 (  )
      filesToUpload.forEach((file, index) => {
        const { name } = file;
        const upFileSeq = uploadResult[index]?.uploadFilesSeq[0];

        if (upFileSeq) {
          tempFileList.push({
            fileName: name,
            upFileSeq: upFileSeq,
          });
        }
      });

      setUploadFileList(tempFileList);
    } catch (error) {
      console.error('An error occurred while uploading the file:', error);
      setSimpleAlert((prev) => ({
        ...prev,
        visible: true,
        modalText: 'An error occurred while uploading the file.',
      }));
    }
  }
};
  /** 현재 선택 된 파일 삭제 버튼 핸들러 */
  const handleDeleteButton = (fileItem) => {
    setIsViewDeleteModal(true);
    setCurrentFileItem(fileItem);
  };
  /** 파일 삭제 확인 버튼 후 실제 로직 핸들러 */
  const handleDeleteFile = async (fileItem) => {
    await deleteFile(fileItem.upFileSeq);

    const deleteFileList = uploadFileList.filter((i) => i.upFileSeq !== fileItem.upFileSeq);
    setUploadFileList(deleteFileList);
    setIsViewDeleteModal(false);
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  const isUploaded = uploadFileList.length > 0;
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <>
      <S.FileUploadArea>
        <input type='file' className='upload_file' multiple id='fileInput' onChange={handleFileInput} accept='.pdf,application/pdf' />
        <>
          <LabelFileUpload htmlFor={'fileInput'} onDragOver={handleDragOver} onDrop={handleDrop} isUploaded={false} />
          <p className='guile-txt'>Please upload PDF files only</p>
        </>

        <div className='uploaded_files'>
          {isUploaded
            ? uploadFileList?.map((fileItem, index) => <UploadedFileItem key={`fileInfo_${index}`} fileItem={fileItem} handleDeleteFile={handleDeleteButton} />)
            : null}
        </div>
      </S.FileUploadArea>

      {/* 모달 */}
      {isViewDeleteModal && <DeleteModal setIsViewModal={setIsViewDeleteModal} callback={() => handleDeleteFile(currentFileItem)} />}
      <SimpleAlertModal alertInfo={simpleAlert} closeModal={() => setSimpleAlert((prev) => ({ ...prev, visible: false }))} />
    </>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  FileUploadArea: styled.div`
    font-family: 'Roboto', 'Noto Sans KR', sans-serif;
    .guile-txt {
      width: 100%;
      font-size: 0.875rem;
      font-weight: 500;
      color: #4b5563;
      padding: 0.75rem 0;
      text-align: center;
    }

    input[type='file'].upload_file {
      display: none;
    }
  `,
};
