import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import timezones from 'google-timezones-json';
import { getClientTimezone } from 'utils/functions/time/dayjs-config';
import jwt_decode from 'jwt-decode';
import request from 'utils/Request.utils';
import localStorage from 'utils/LocalStorage.utils';
import Cookies from 'js-cookie';

import Select from 'components/user/_components/common/forms/Select';

/** 프로필 - "Additional Settings" 텝에 해당 되는 화면 - 타임존 설정 Form 컴포넌트 */
export default function TimezoneForm({ setAlert, timezoneList }) {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  /** 현재 설정된 타임존 */
  const userTimeZone = userInfo?.timeZone ? userInfo?.timeZone : getClientTimezone();

  /** react-hook-form */
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      timezone: userTimeZone, // 기본 값
    },
  });

  const selectedTimezone = watch('timezone');

  useEffect(() => {
    if (selectedTimezone === userTimeZone || !selectedTimezone) return;

    /** 타임존 변경 api */
    const handleChangeTimezone = (selectedTimezone) => {
      let params = { timeZone: selectedTimezone };

      const successHandler = (response) => {
        if (response.code === 200) {
          console.log('🚀 타임존 설정! 🚀');
          refreshAccessToken();
        }
      };
      request.put('/api/academy/timezone', params, successHandler);
    };

    /** 토큰 갱신 api */
    const refreshAccessToken = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const { accessToken, refreshToken } = response.result;

          localStorage.setItem('userSession', 'accessToken', accessToken);
          localStorage.setItem('userSession', 'refreshToken', refreshToken);
          setUserInfo(jwt_decode(accessToken));

          setTimeZoneCookieHandler();
        }
      };

      const userSession = localStorage.getItemJsonParse('userSession');
      request.post('/api/member/token/refresh', { refreshToken: userSession?.refreshToken }, successHandler).catch((error) => {
        console.log('refresh token error! : ', error);
      });
    };

    /** 타임존 설정후 쿠키 세팅 및 2초 뒤에 새로고침 */
    const setTimeZoneCookieHandler = () => {
      const IS_SET_TIMEZONE = 'isSetTimezoneCookie';
      Cookies.set(IS_SET_TIMEZONE, 'true');

      setAlert({ active: true, message: 'Successfully edited' });

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    };

    handleChangeTimezone(selectedTimezone);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimezone]);

  const formatGmtOffset = (gmtOffset) => {
    const hours = Math.floor(gmtOffset / 60);
    const minutes = Math.abs(gmtOffset % 60);
    const sign = hours >= 0 ? '+' : '-';
    return `${sign}${String(Math.abs(hours)).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  return (
    <S.Wrap>
      <label htmlFor='timezone'>Timezone</label>
      <Select
        id='timezone'
        register={register('timezone', {
          required: true,
        })}>
        {timezoneList?.map((item) => (
          <option key={item.tzSeq} value={item.timezoneName}>
            (GMT{formatGmtOffset(item.gmtOffset)}) {item.timezoneName} - {item.abbreviation}
          </option>
        ))}
      </Select>
      <p className='guide'>Your timezone will be applied to students linked to you</p>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  label {
    display: none;
  }
  .guide {
    color: #7f8998;
    font-weight: 400;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
`;
