import styled from 'styled-components';
import { useSelector } from 'react-redux';

import CustomCheckbox from 'components/_common/CustomCheckbox';
import ApSettingSelectBox from './_components/ApSettingSelectBox';
import ApSettingStepper from './_components/ApSettingStepper';
import ApSettingInput from './_components/ApSettingInput';
import ApSettingFixedBox from './_components/ApSettingFixedBox';

import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/round_triangle.svg';

import { QUESTION_CONSTANT, ANSWER_CHOICE_CONSTANT, QUESTION_TYPE_CONSTANT } from 'components/ap/question-bank/test-editor/_constant';

/** AP Test Editor - 문제 데이터를 편집 하는 팝업 */
export default function QuestionSettingPopup({ isOpen, pos = 'left', questionInfo, handleUpdateQuestionInfo }) {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const apTestEditorReducer = useSelector((state) => state.apTestEditorReducer);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** question setting data 변경 핸들러 */
  const handleChangeQuestionSettingData = (key, value) => {
    handleUpdateQuestionInfo(key, value);
  };

  /** 현재 파트 데이터에서 문제 유형 조회 */
  const getQuestionType = () => {
    if (questionInfo.question_type === QUESTION_TYPE_CONSTANT.FRQ) {
      return QUESTION_TYPE_CONSTANT.FRQ_LABEL;
    } else return QUESTION_TYPE_CONSTANT.MCQ_LABEL;
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  /** 현재 문제 유형 */
  const questionType = getQuestionType();
  /** 문제 유형이 FRQ (주관식) 인지 확인 */
  const isFRQ = questionType === QUESTION_TYPE_CONSTANT.FRQ_LABEL;

  if (!isOpen) return null;
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <S.Container $pos={pos}>
      <div className='popup_header'>
        <h2>Question Settings</h2>
      </div>

      <S.ContentWrapper>
        {/* 🧰 커스텀 문제 번호 미사용 */}
        {/* <div className='control_row'>
          <span className='label'>Question Number</span>
          <ApSettingInput
            className='ap_input number_input'
            value={Number(questionInfo[QUESTION_CONSTANT.custom_question_order]) + 1}
            onChange={(e) => handleChangeQuestionSettingData(QUESTION_CONSTANT.custom_question_order, e.target.value)}
          />
        </div> */}

        {/* <div className='divider' /> */}

        {/* <div className='control_row'>
          <span className='label'>Question Context</span>

          <CustomCheckbox
            size='1.25rem'
            checkBoxId={`questionContext_checkbox`}
            isChecked={questionInfo[QUESTION_CONSTANT.question_context]}
            onChange={() => handleChangeQuestionSettingData(QUESTION_CONSTANT.question_context, !questionInfo[QUESTION_CONSTANT.question_context])}
          />
        </div> */}

        {/* <div className='divider' /> */}

        <div className='control_row'>
          <span className='label'>Question Type</span>
          <div className='select_control'>
            {/* <ApSettingSelectBox
              options={[
                { label: 'Multiple Choice', value: 'M' },
                { label: 'Free Response', value: 'E' },
              ]}
              onSelect={(value) => handleChangeQuestionSettingData(QUESTION_CONSTANT.question_type, value)}
              defaultValue={questionInfo[QUESTION_CONSTANT.question_type]}
            /> */}
            <ApSettingFixedBox value={questionType} />
          </div>
        </div>

        {/* 🧰 기획 간소화 및 select 선택 불가하게 변경 */}
        {/* {isFRQ ? (
          <>
            <div className='divider' />
            <div className='control_row'>
              <span className='label'>Type of Student input</span>
              <div className='select_control'>
                <ApSettingSelectBox options={[{ label: 'Text Input', value: 'Text Input' }]} onSelect={() => {}} defaultValue={'Text Input'} />
              </div>
            </div>
          </>
        ) : (
          <div className='control_row inner_row'>
            <span className='label'>Multiple choice type</span>
            <div className='select_control'>
              <ApSettingSelectBox
                options={[
                  { label: 'Single-select', value: ANSWER_CHOICE_CONSTANT.SINGLE },
                  { label: 'Multi-select', value: ANSWER_CHOICE_CONSTANT.MULTIPLE },
                ]}
                onSelect={(value) => handleChangeQuestionSettingData(QUESTION_CONSTANT.multiple_choice_type, value)}
                defaultValue={questionInfo.frontData?.[QUESTION_CONSTANT.multiple_choice_type] ?? ANSWER_CHOICE_CONSTANT.SINGLE}
              />
            </div>
          </div>
        )} */}

        {/* 🧰 기획 간소화 및 난이도 선택 불가하게 */}
        {/* <div className='divider' />

        <div className='control_row'>
          <span className='label'>Question Difficulty</span>

          <div className='select_control'>
            <ApSettingSelectBox
              options={[
                { label: 'None', value: null },
                { label: 'Easy', value: 'Easy' },
                { label: 'Medium', value: 'Medium' },
                { label: 'Hard', value: 'Hard' },
              ]}
              onSelect={(value) => handleChangeQuestionSettingData(QUESTION_CONSTANT.question_difficulty, value)}
              defaultValue={questionInfo[QUESTION_CONSTANT.question_difficulty]}
            />
          </div>
        </div> */}

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Possible Score</span>

          <ApSettingStepper
            number={questionInfo[QUESTION_CONSTANT.score]}
            onIncrease={() => {
              handleChangeQuestionSettingData(QUESTION_CONSTANT.score, questionInfo[QUESTION_CONSTANT.score] + 1);
            }}
            onDecrease={() => {
              // 점수 0 방지
              if (questionInfo[QUESTION_CONSTANT.score] > 1) {
                handleChangeQuestionSettingData(QUESTION_CONSTANT.score, questionInfo[QUESTION_CONSTANT.score] - 1);
              }
            }}
          />
        </div>

        {/* <div className='divider' />

        <div className='control_row'>
          <span className='label'>Question Code</span>

          <ApSettingInput
            className='ap_input code_input'
            value={questionInfo[QUESTION_CONSTANT.question_code]}
            onChange={(e) => handleChangeQuestionSettingData(QUESTION_CONSTANT.question_code, e.target.value)}
          />
        </div> */}
      </S.ContentWrapper>
      <ArrowIconSelect className='triangle_icon' />
    </S.Container>
  );
}

const getPopupPosition = (pos) => {
  switch (pos) {
    case 'left':
      return `
        right: 105%;
        top: 50%;
        transform: translateY(-50%);
        
        .triangle_icon {
          right: -3%;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
        }
      `;
    case 'right':
      return `
        left: 105%;
        top: 50%;
        transform: translateY(-50%);
        
        .triangle_icon {
          left: -3%;
          top: 50%;
          transform: translateY(-50%);
        }
      `;
    case 'top':
      return `
        bottom: 115%;
        left: 50%;
        transform: translateX(-50%);
        
        .triangle_icon {
          bottom: -4%;
          left: 50%;
          transform: translateX(-50%) rotate(270deg);
        }
      `;
    case 'bottom':
      return `
        top: 115%;
        left: 50%;
        transform: translateX(-50%);
        
        .triangle_icon {
          top: -4%;
          left: 50%;
          transform: translateX(-50%) rotate(90deg);
        }
      `;
    default:
      return `
        left: 116%;
        top: 50%;
        transform: translateY(-50%);
        
        .triangle_icon {
          left: -3%;
          transform: translateY(-50%);
        }
      `;
  }
};

const S = {
  Container: styled.div`
    position: absolute;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 24px;
    width: 430px;

    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.24));
    z-index: 99;
    ${({ $pos }) => getPopupPosition($pos)}

    .popup_header {
      width: 100%;
      h2 {
        color: #111111;
        font-family: 'Roboto-SemiBold', Helvetica;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin: 0;
      }
    }

    .triangle_icon {
      position: absolute;
      color: #ffffff;
    }
  `,

  ContentWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;

    .divider {
      height: 1px;
      background-color: #f1f1f5;
      width: 100%;
    }

    .control_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &.inner_row {
        background-color: #fafafa;
        padding: 0.625rem 0.75rem;
        border-radius: 0.25rem;
      }

      .label {
        color: #111111;
        font-family: 'Roboto-Regular', Helvetica;
        font-size: 14px;
        line-height: 20px;
      }

      .ap_input {
        width: 8.75rem;
        padding: 0 10px;

        &.number_input {
          max-height: 2.375rem;
        }
        &.code_input {
          max-height: 2.125rem;
        }
      }
    }

    .group_control {
      display: flex;
      align-items: center;
      gap: 8px;

      .separator {
        color: #111111;
        font-size: 12px;
        line-height: 18px;
      }
    }
  `,

  SectionDivider: styled.div`
    height: 2px;
    background-color: #505050;
    width: 100%;
  `,
};