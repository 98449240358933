import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addQuestion,
  updateApTestSeq,
  updateCurrentPartData,
  updateCurrentQuestionIndex,
  updateCurrentSectionData,
  updateQuestion,
  updateSectionMetadata,
} from 'reducers/apTestEditor/action';

import { EDITOR_CONSTANT, initAnswerChoiceInfo, initCurrentPart, initialQuestion, QUESTION_TYPE_CONSTANT } from './_constant';
import { transformChoicesToBackend, transformChoicesToFrontend } from './_utils';

import useMiniAlert from 'hooks/useMiniAlert';
import { useNavigate } from 'react-router-dom';
import store from 'store';
import request from 'utils/Request.utils';

export default function useTestEditor() {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** 전역 상태 참조 변수 - 직접 호출해서 구독하지 않고 특정 시점에 호출하기 위함 */
  const reduxStateRef = useRef({
    test_seq: { test_seq: '0' },
    currentQuestion_index: 0,
    questions: [],
    sectionMetadata: {},
    currentPartData: {},
    currentSectionData: {},
  });
  /** Redux 상태 업데이트 함수 - 직접 호출해서 구독하지 않고 특정 시점에 호출하기 위함 */
  const updateReduxState = useCallback(() => {
    const apTestEditorState = store.getState().apTestEditorReducer;
    reduxStateRef.current = {
      questions: apTestEditorState.questions,
      sectionMetadata: apTestEditorState.sectionMetadata,
      currentPartData: apTestEditorState.currentPartData,
      currentSectionData: apTestEditorState.currentSectionData,
      currentQuestion_index: apTestEditorState.currentQuestion_index,
      test_seq: apTestEditorState.test_seq,
    };
    return reduxStateRef.current;
  }, []);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [editorState, setEditorState] = useState({
    [EDITOR_CONSTANT.currentIndex]: 0, // 현재 문제 인덱스
    [EDITOR_CONSTANT.currentSection]: 0, // 현재 Section
    [EDITOR_CONSTANT.currentPart]: initCurrentPart, // 현재 Part 데이터
    [EDITOR_CONSTANT.currentQuestion]: initialQuestion, // 현재 문제
    [EDITOR_CONSTANT.answerChoiceInfo]: initAnswerChoiceInfo, // 답안 선택지 정보
    [EDITOR_CONSTANT.currentSourceOrder]: 0, // 현재 문제에서 선택된 소스
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** 새로운 테스트를 만들기 위한 메타데이터 호출 && 새로운 시험 문제 정보 삽입 */
  const getTestMetadata = async (test_set_seq) => {
    // /api/ap/question-bank/test/:test_set_seq GET
    setIsLoading(true);
    const successHandler = async (response) => {
      const fetchedSectionMetadata = response.result;
      const fetchedFirstPart = fetchedSectionMetadata.sections[0].parts[0];

      // 메타데이터 세팅 ( redux & state )
      dispatch(updateSectionMetadata(fetchedSectionMetadata)); // 메타데이터 redux에 저장
      editorStateUpdater(EDITOR_CONSTANT.currentSection, fetchedSectionMetadata.sections[0]); // 섹션 정보 세팅
      editorStateUpdater(EDITOR_CONSTANT.currentPart, fetchedFirstPart); // 파트 정보 세팅

      // 문제 정보 세팅 ( state )
      editorStateUpdater(EDITOR_CONSTANT.currentQuestion, {
        ...initialQuestion,
        section_name: fetchedSectionMetadata.sections[0].section_name,
        part_name: fetchedFirstPart.name,
        part_seq: fetchedFirstPart.part_seq,
        test_name: fetchedSectionMetadata.test_info.test_set_name,
        subject_name: fetchedSectionMetadata.test_info.subject_name,
        question_type: fetchedFirstPart.type_of_question,

        // 문제 보기 정보
        answer_options: {
          ...Array.from({ length: fetchedFirstPart.answer_choices }).reduce(
            (acc, _, index) => ({
              ...acc,
              [`option${index + 1}`]: '',
            }),
            {
              total: fetchedFirstPart.answer_choices,
              answerCount: 0,
            }
          ),
        },
      });

      // part 데이터에 따라 answerChoices 초기갯수 지정
      editorStateUpdater(EDITOR_CONSTANT.answerChoiceInfo, {
        answerChoices:
          fetchedFirstPart.type_of_question === QUESTION_TYPE_CONSTANT.E || fetchedFirstPart.type_of_question === QUESTION_TYPE_CONSTANT.H
            ? [{ choiceType: 'Text Input', content: '' }]
            : Array.from({ length: fetchedFirstPart.answer_choices }).map(() => ({
                choiceType: 'Text Input',
                content: '',
              })),
      });

      // 테스트 시퀀스 세팅
      dispatch(updateApTestSeq({ test_seq: test_set_seq }));
    };
    const errorHandler = async (error) => {
      console.error('getTestInfo errorHandler:', error);
      alert(error.message);
      location.href = '/ap/question-bank/create-test';
    };
    await request.get(`/api/ap/question-bank/test/detail/${test_set_seq}`, { isNew: true }, successHandler, errorHandler).finally(() => setIsLoading(false));
  };

  /** 수정중이던 테스트 데이터 불러오기 */
  const getEditingTestData = async (test_set_seq) => {
    // /api/ap/question-bank/test/:test_set_seq/question GET
    setIsLoading(true);
    const successHandler = async (response) => {
      const { sectionMetadata: fetchedSectionMetadata, questions: fetchedQuestions } = response.result;
      const uniqueQuestions = fetchedQuestions.reduce((acc, current) => {
        const key = `${current.part_seq}-${current.question_order}`;
        if (!acc[key]) {
          acc[key] = current;
        }
        return acc;
      }, {});
      const deduplicatedQuestions = Object.values(uniqueQuestions); // object화로 중복 제거 & 배열로 반환

      const firstQuestion = deduplicatedQuestions.find((q) => q.part_seq === fetchedSectionMetadata.sections[0].parts[0].part_seq && q.question_order === 0);

      /** 기존 메타데이터에서 section_name을 추가해서 반환 */
      const formattedSectionMetadata = {
        ...fetchedSectionMetadata,
        sections: fetchedSectionMetadata.sections.map((section) => ({
          ...section,
          section_name: section.name,
        })),
      };
      const firstPart = fetchedSectionMetadata.sections[0].parts[0];

      // api로 받은 문제 데이터 업데이트 ( 배열 전달로 전체 문제 업데이트 )
      dispatch({ type: 'UPDATE_QUESTION', payload: { questions: deduplicatedQuestions } });
      // 메타데이터 세팅 ( redux & state )
      dispatch(updateSectionMetadata(formattedSectionMetadata)); // 메타데이터 redux에 저장

      editorStateUpdater(EDITOR_CONSTANT.currentSection, formattedSectionMetadata.sections[0]); // 섹션 정보 세팅
      editorStateUpdater(EDITOR_CONSTANT.currentPart, firstPart); // 파트 정보 세팅

      // 문제 정보 세팅 ( state )
      editorStateUpdater(EDITOR_CONSTANT.currentQuestion, {
        ...initialQuestion,
        section_name: formattedSectionMetadata.sections[0].section_name,
        part_name: firstPart.name,
        part_seq: firstPart.part_seq,
        test_name: formattedSectionMetadata.test_info.test_set_name,
        subject_name: formattedSectionMetadata.test_info.subject_name,
        question_type: firstPart.type_of_question,
        question_content: firstQuestion.question_content,
        source_group: firstQuestion.source_group,
        score: firstQuestion.score,
        answer: firstQuestion.answer,
        custom_question_order: firstQuestion.custom_question_order,

        // 문제 보기 정보
        answer_options: firstQuestion.answer_options,
      });

      dispatch(updateApTestSeq({ test_seq: test_set_seq })); // 테스트 시퀀스 세팅

      // part 데이터에 따라 answerChoices 초기갯수 지정
      editorStateUpdater(EDITOR_CONSTANT.answerChoiceInfo, {
        answerChoices:
          firstPart.type_of_question === QUESTION_TYPE_CONSTANT.E || firstPart.type_of_question === QUESTION_TYPE_CONSTANT.H
            ? [{ choiceType: 'Text Input', content: firstQuestion.answer_options[`option1`] }]
            : Array.from({ length: firstQuestion.answer_options.total }).map((_, index) => ({
                choiceType: 'Text Input',
                content: firstQuestion.answer_options[`option${index + 1}`],
              })),
      });

      return new Promise((resolve) => {
        resolve();
      }).then(() => {
        updateReduxState();
      });
    };

    const errorHandler = (error) => {
      console.error('getApTestData errorHandler:', error);
      alert(error.message);
      location.href = '/ap/question-bank/create-test';
    };
    await request.get(`/api/ap/question-bank/test/${test_set_seq}/question`, { isNew: true }, successHandler, errorHandler).finally(() => setIsLoading(false));
  };

  /** 테스트 저장 ( status에 따라 단순 저장 or 작성 완료 ) */
  const postApTestData = async (status, questionsToSend) => {
    // /api/ap/question-bank/test/:test_set_seq/question POST
    setIsLoading(true);
    const successHandler = (response) => {
      if (status === 'Completed') {
        console.log('🚀 문제 게시 완료 🚀');
        navigate('/ap/question-bank');
        // window.close();
      }
      miniAlert.show('Saved');
    };
    const errorHandler = (error) => {
      console.error('postApTestData errorHandler:', error);
      alert(error.message);
      location.href = '/ap/question-bank/create-test';
    };
    const params = {
      isNew: true, // node api 호출 시 필요한 파라미터
      status: status,
      questions: questionsToSend,
    };

    await request
      .post(`/api/ap/question-bank/test/${reduxStateRef.current.test_seq.test_seq}/question`, params, successHandler, errorHandler)
      .finally(() => setIsLoading(false));
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  const miniAlert = useMiniAlert();

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 상태 setter ( 특정 key의 상태를 완전히 덮어씌움 ) */
  const editorStateSetter = (key, value) => {
    setEditorState((prev) => ({ ...prev, [key]: value }));
  };
  /** 상태 updater ( 특정 key의 내부 데이터를 업데이트 ) */
  const editorStateUpdater = (key, value) => {
    setEditorState((prev) => ({ ...prev, [key]: { ...prev[key], ...value } }));
  };
  /** 상태 mutator ( 기존 상태를 기반으로 값을 변경 ) */
  const editorStateMutator = (key, mutator) => {
    setEditorState((prev) => ({ ...prev, [key]: mutator(prev[key]) }));
  };

  // 파트 시퀀스와 일치하는 파트를 찾는 함수
  const findSectionAndPartByPartSeq = (seq) => {
    const targetSection = reduxStateRef.current.sectionMetadata.sections.find((section) => section.parts.some((part) => part.part_seq === seq));
    const targetPart = targetSection.parts.find((part) => part.part_seq === seq);

    return { targetSection, targetPart };
  };

  /** 현재 파트의 문제 목록 조회 */
  const getCurrentPartQuestion = () =>
    reduxStateRef.current.questions.filter((question) => question.part_seq === editorState[EDITOR_CONSTANT.currentPart].part_seq);

  /** 현재 파트의 answer_choices 갯수만큼 보기 생성 */
  const initAnswerChoicesToBackend = (answerChoicesLength) => {
    const options = Array.from({ length: answerChoicesLength }).reduce((acc, _, index) => ({ ...acc, [`option${index + 1}`]: '' }), {});

    return {
      ...options,
      total: answerChoicesLength,
      answerCount: 0,
    };
  };

  /** 섹션/파트별 문제 수 검증 및 이동 함수 */
  const validateQuestionsCount = (targetQuestionData) => {
    // 섹션과 파트 정보를 하나의 배열로 평탄화 & 각 섹션, 파트 객체로 반환
    const sectionPartPairs = reduxStateRef.current.sectionMetadata.sections.flatMap((section) => section.parts.map((part) => ({ section, part })));
    // 각 섹션, 파트별 문제 데이터 조회 & 추가
    const partsWithQuestions = sectionPartPairs.map(({ section, part }) => ({
      section,
      part,
      partQuestions: targetQuestionData.filter((q) => q.part_seq === part.part_seq),
    }));

    /** 문제 수가 부족한 첫 번째 파트 */
    const invalidPart = partsWithQuestions.find(({ part, partQuestions }) => partQuestions.length < part.number_of_questions);

    // 부족한 파트가 있으면 해당 위치로 이동
    if (invalidPart) {
      const { section, part, partQuestions } = invalidPart;
      const missingCount = part.number_of_questions - partQuestions.length;
      // 비어있는 문제 번호 찾아서 이동
      const missingQuestionOrder = findMissingQuestionOrder(partQuestions, part.number_of_questions);
      handleQuestionNavigation(missingQuestionOrder, part.part_seq);
      alert(
        `${invalidPart.section.section_name}${invalidPart.part.name !== '' ? ` - ${invalidPart.part.name}` : ''}: ${missingCount} question${missingCount > 1 ? 's' : ''} required`
      );

      return false;
    }

    return true;
  };
  /** 비어있는 문제 번호 찾기 */
  const findMissingQuestionOrder = (questions, requiredCount) => {
    const existingOrders = new Set(questions.map((question) => question.question_order));

    // 0부터 requiredCount까지 순회하면서 비어있는 첫 번째 번호 찾기
    const firstMissingOrder = Array.from({ length: requiredCount }).findIndex((_, index) => !existingOrders.has(index));

    // 비어있는 번호가 없으면 questions.length 반환
    return firstMissingOrder === -1 ? questions.length : firstMissingOrder;
  };
  /** 파트별 점수 총합 검증 */
  const validatePartScores = (targetQuestionData) => {
    // 섹션과 파트 정보를 하나의 배열로 평탄화 & 각 섹션, 파트 객체로 반환
    const sectionPartPairs = reduxStateRef.current.sectionMetadata.sections.flatMap((section) => section.parts.map((part) => ({ section, part })));
    // 각 섹션, 파트별 문제 데이터 조회 & 추가
    const partsWithQuestions = sectionPartPairs.map(({ section, part }) => ({
      section,
      part,
      partQuestionsTotalScore: targetQuestionData.filter((q) => q.part_seq === part.part_seq).reduce((sum, q) => sum + (Number(q.score) || 0), 0),
    }));

    // 각 파트의 총점이 total_raw_score와 일치하지 않는 파트 찾기
    const invalidPart = partsWithQuestions.find(({ part, partQuestionsTotalScore }) => partQuestionsTotalScore !== part.total_raw_score);

    if (invalidPart) {
      handleQuestionNavigation(0, invalidPart.part.part_seq);

      alert(
        `${invalidPart.section.section_name}${invalidPart.part.name !== '' ? ` - ${invalidPart.part.name}` : ''}: Score mismatch (Current: ${invalidPart.partQuestionsTotalScore}, Required: ${invalidPart.part.total_raw_score})`
      );

      return false;
    }

    return true;
  };
  /** 이전 소스 복사 */
  const copyPreviousSource = () => {
    const currentPartQuestions = getCurrentPartQuestion();
    const previousQuestion = currentPartQuestions.find((question) => question.question_order === editorState[EDITOR_CONSTANT.currentIndex] - 1);

    editorStateUpdater(EDITOR_CONSTANT.currentQuestion, { source_group: previousQuestion.source_group });
  };
  /** 정답이 없는 문제 찾아서 이동 */
  const validateCorrectAnswer = (targetQuestionData) => {
    const findNoCorrectAnswer = targetQuestionData.find((question) => question.answer.length === 0 && question.question_type === QUESTION_TYPE_CONSTANT.M);

    if (findNoCorrectAnswer) {
      const { targetSection, targetPart } = findSectionAndPartByPartSeq(findNoCorrectAnswer.part_seq);

      handleQuestionNavigation(findNoCorrectAnswer.question_order, findNoCorrectAnswer.part_seq);

      alert(
        `${targetSection.section_name}${targetPart.name !== '' ? ` - ${targetPart.name}` : ''}: Question ${findNoCorrectAnswer.question_order + 1} has no correct answer`
      );

      return false;
    }

    return true;
  };
  /** 지정된 index 번호의 문제 데이터 로드 및 상태 업데이트를 관리하는 함수 - 작성중이던 문제 저장 & 이동 */
  const handleQuestionNavigation = async (targetIndex, targetPartSeq = editorState[EDITOR_CONSTANT.currentPart].part_seq) => {
    if (isNavigating || isLoading) return;

    // 현재 활성화된 에디터의 composition 이벤트 강제 종료
    const activeElement = document.activeElement;
    if (activeElement) {
      activeElement.blur();
      // IME 입력 버퍼를 초기화하기 위한 지연
      return new Promise((resolve) => {
        setTimeout(async () => {
          await handleNavigationLogic(targetIndex, targetPartSeq);
          resolve();
        }, 0);
      });
    }

    return handleNavigationLogic(targetIndex, targetPartSeq);
  };

  const handleNavigationLogic = async (targetIndex, targetPartSeq) => {
    try {
      setIsNavigating(true);
      setIsLoading(true);

      // 현재 문제 데이터 전역 상태로 저장
      await handleSaveAllContent();

      // targetPartSeq에 해당하는 섹션, 파트 정보 조회
      const { targetSection, targetPart } = findSectionAndPartByPartSeq(targetPartSeq);

      dispatch(updateCurrentPartData(targetPart));
      dispatch(updateCurrentSectionData(targetSection));
      dispatch(updateCurrentQuestionIndex(targetIndex));
      editorStateSetter(EDITOR_CONSTANT.currentIndex, targetIndex); // 선택된 문제 인덱스
      editorStateSetter(EDITOR_CONSTANT.currentPart, targetPart); // 선택된 파트 데이터
      editorStateSetter(EDITOR_CONSTANT.currentSection, targetSection); // 선택된 섹션 데이터
      editorStateSetter(EDITOR_CONSTANT.currentSourceOrder, 0); // 현재 문제에서 선택된 소스 ( 기본값 0으로 초기화 )

      // 선택된 문제 데이터 조회
      const targetQuestion = reduxStateRef.current.questions.find((q) => q.question_order === targetIndex && q.part_seq === targetPartSeq);
      // 선택된 문제가 있는 경우 - 해당 문제 정보 세팅
      if (targetQuestion) {
        editorStateSetter(EDITOR_CONSTANT.currentQuestion, targetQuestion); // 선택된 문제 데이터
        editorStateSetter(EDITOR_CONSTANT.answerChoiceInfo, { answerChoices: transformChoicesToFrontend(targetQuestion.answer_options) }); // 객관식 문제 보기 데이터
      } else {
        // 선택된 문제가 없는 경우 - 새로운 문제 추가
        const newQuestion = {
          // source_group, question_content, score, answer 는 기본값으로 설정
          ...initialQuestion,
          part_seq: targetPart.part_seq, // number  => 파트 seq
          question_order: targetIndex, // number  => 실질 문제 순서
          custom_question_order: String(targetIndex + 1), // string  => 유저가 커스텀 한 문제 순서
          question_type: targetPart.type_of_question, //  (M: 객관식, E: 서술형, H: 하이브리드 서술형)
          section_name: targetSection.section_name, // string  => 섹션 이름
          part_name: targetPart.name, // string  => 파트 이름
          test_name: reduxStateRef.current.sectionMetadata.test_info.test_set_name, // string  => 시험 이름
          subject_name: reduxStateRef.current.sectionMetadata.test_info.subject_name, // string  => 과목 이름
          answer_options: { ...initAnswerChoicesToBackend(targetPart.answer_choices) },
        };
        editorStateSetter(EDITOR_CONSTANT.currentQuestion, newQuestion); // 선택된 문제 데이터
        editorStateSetter(EDITOR_CONSTANT.answerChoiceInfo, { answerChoices: transformChoicesToFrontend(newQuestion.answer_options) }); // 객관식 문제 보기 데이터
      }
    } catch (error) {
      console.error('Question navigation failed:', error);
    } finally {
      setIsNavigating(false);
      setIsLoading(false);
    }
  };

  /** 작성중인 Question Data 저장 */
  const handleSaveAllContent = async () => {
    return new Promise((resolve) => {
      try {
        /** 현재 문제 번호와 일치하는 문제 조회 */
        const existingQuestion = reduxStateRef.current.questions.find(
          (question) =>
            question.question_order === editorState[EDITOR_CONSTANT.currentIndex] && question.part_seq === editorState[EDITOR_CONSTANT.currentPart].part_seq
        );

        // 기존 데이터와 일치하는 문제가 있는 경우 - 기존 문제 업데이트
        if (existingQuestion) {
          dispatch(updateQuestion(existingQuestion.part_seq, existingQuestion.question_order, formatQuestionForSubmit()));
        } else {
          // 기존 데이터와 일치하는 문제가 없는 경우 - 새 문제 추가
          dispatch(addQuestion(formatQuestionForSubmit()));
        }

        requestAnimationFrame(() => {
          updateReduxState();
          resolve();
        });
      } catch (error) {
        console.error('❌ handleSaveAllContent 에러:', error);
        // 에러가 발생해도 Promise는 resolve 되어야 함
        requestAnimationFrame(() => resolve());
      }
    });
  };

  /** 백엔드에 보내기 위한 데이터 형식으로 변환된 Question Data - 기존 값을 참 */
  const formatQuestionForSubmit = () => {
    return {
      ...editorState[EDITOR_CONSTANT.currentQuestion],
      answer_options: {
        // 백엔드에서 사용하는 데이터 형식으로 변환
        ...transformChoicesToBackend(editorState[EDITOR_CONSTANT.answerChoiceInfo]),

        total:
          editorState[EDITOR_CONSTANT.currentPart].type_of_question === QUESTION_TYPE_CONSTANT.M
            ? editorState[EDITOR_CONSTANT.answerChoiceInfo].answerChoices.length
            : 1,
        answerCount: editorState[EDITOR_CONSTANT.currentQuestion].answer.length,
      },
    };
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  return {
    apReduxState: reduxStateRef.current,
    formatQuestionForSubmit: formatQuestionForSubmit,
    // ap테스트 api
    getTestMetadata,
    getEditingTestData,
    postApTestData,
    // State
    editorState,
    // State handler
    editorStateSetter,
    editorStateUpdater,
    editorStateMutator,
    // helper functions
    handleSaveAllContent,
    getCurrentPartQuestion,
    validateQuestionsCount,
    validatePartScores,
    copyPreviousSource,
    validateCorrectAnswer,
    handleQuestionNavigation,
    isLoading,
    isNavigating: isNavigating || isLoading,
  };
}
