import React from 'react';
import styled from 'styled-components';
import DropDownButtonWrap from './_components/DropDownButtonWrap';

import { ReactComponent as ImgSvgIconCheck } from 'assets/img/svg/icon/icon_check_square_01.svg';
import { ReactComponent as ImgSvgIconHelp } from 'assets/img/svg/icon/icon_help_circle_01.svg';
import { ReactComponent as ImgSvgIconLogout } from 'assets/img/svg/icon/icon_log_out_01.svg';

/** Grading Submission Header 컴포넌트 */
export default function GradingHeader() {
  return (
    <S.Header>
      <div className='center_wrap'>
        <h1>Grading</h1>
        <div className='center_area'>
          <div className='user_name'>Jeong Su Hyeon</div>
          <div className='sub_info_wrap'>
            <div>SUBJECT</div>
            <div>TEST NAMETEST NAME</div>
          </div>
        </div>
        <div className='button_wrap'>
          <DropDownButtonWrap title={'Test Settings'} dropdownChildren={<div>준비 중입니다.. (Phase 2)</div>} />
          <DropDownButtonWrap
            title={'More'}
            className='more_options'
            dropdownChildren={
              <ul>
                <li>
                  <button type='button' title='' onClick={() => {}} className=''>
                    <i className='icon_wrap'>
                      <ImgSvgIconHelp />
                    </i>
                    Help
                  </button>
                  <button type='button' title='' onClick={() => {}} className=''>
                    <i className='icon_wrap'>
                      <ImgSvgIconLogout />
                    </i>
                    Save and Exit
                  </button>
                  <button type='button' title='' onClick={() => {}} className=''>
                    <i className='icon_wrap'>
                      <ImgSvgIconCheck />
                    </i>
                    Complete
                  </button>
                </li>
              </ul>
            }
          />
        </div>
      </div>
      <hr />
    </S.Header>
  );
}

const S = {
  Header: styled.header`
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 10rem;
    background-color: #f4f8ff;
    hr {
      position: absolute;
      bottom: 0;
      border: none;
      background-image: linear-gradient(to right, black 80%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 80px 2px;
      background-repeat: repeat-x;
      height: 2px;
      width: 100%;
    }
    .center_wrap {
      padding: 3rem 4.5rem 2rem;
      display: flex;
      justify-content: space-between;
      h1 {
        color: #000;
        font-size: 1.375rem;
        font-weight: 600;
        line-height: 1.375rem;
      }
      .center_area {
        .user_name {
          color: #000;
          text-align: center;
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 2rem;
        }
        .sub_info_wrap {
          margin-top: 0.75rem;
          color: #000;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          display: flex;
          & > * {
            & + * {
              position: relative;
              margin-left: 1.5rem;
              &::before {
                content: '';
                display: inline-block;
                width: 0.125rem;
                height: 0.875rem;
                background-color: #eaeaea;
                margin-right: 1.5rem;
              }
            }
          }
        }
      }
      .button_wrap {
        display: flex;
        gap: 2.5rem;
        .more_options {
          .dropdown_area {
            left: auto;
            right: 0;
            button {
              color: #000;
              font-size: 0.875rem;
              font-weight: 500;
              width: 100%;
              min-width: 10.125rem;
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              gap: 0.5rem;
              padding: 0.375rem 0.625rem;
              border-radius: 0.3125rem;
              .icon_wrap {
                svg {
                  width: 1.5rem;
                }
              }
              &:hover {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%), #fff;
              }
            }
          }
        }
      }
    }
  `,
};
