import React from 'react';
import styled from 'styled-components';
import RawScoreArea from './_components/rawScoreArea/RawScoreArea';
import ScaledScoreArea from './_components/totalScaledScoreArea/ScaledScoreArea';

/** ap/com/score/grading/submission 페이지의 본문 영역 컴포넌트 */
export default function GradeSubmissionBody() {
  return (
    <S.Wrap>
      <div className='top_info_area'>
        <h2>Grade Submission</h2>
        <p>
          Enter Section 1 and Section 2 scores for your student. Section 1 has been automatically graded, but you need to enter the scaled score yourself.{' '}
          <br />
          When grading your student’s test, you must enter both raw score and scaled score in order to obtain a full score report.
        </p>
      </div>
      <div className='main_center_wrap'>
        <RawScoreArea />
        <ScaledScoreArea />
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.main`
    background-color: #ffffff;
    min-height: 100vh;
    margin: 10rem auto;

    .top_info_area {
      padding: 3.5rem 0 2rem;
      text-align: center;
      h2 {
        color: #111;
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 3rem;
      }
      p {
        color: #505050;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.75rem;
      }
    }

    .main_center_wrap {
      display: flex;
      gap: 1rem;
      justify-content: center;
    }
  `,
};
