import { Link } from 'react-router-dom';

/** 네비게이션 아이템 컴포넌트 */
export function NavItem({ path, icon: Icon, label, isActive, onClick }) {
  // 경로가 있으면 <Link />, 없으면 <button />
  const NavComponent = path ? Link : 'button';
  const props = path ? { to: path } : { onClick };

  return (
    <NavComponent className={`menu ${isActive ? 'active' : ''}`} {...props} title='Move to another Page'>
      {typeof Icon === 'object' ? <Icon /> : <img src={Icon} alt={label} className='icon' />}
      {label}
    </NavComponent>
  );
}
