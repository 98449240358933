import { useObfuscatedSearchParam, getValidParam } from 'hooks/useObfuscatedSearchParam';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { SCORE_PARAMS } from 'utils/constants';

import { obfuscateUrlParam } from 'utils/urlParamObfuscator';

/** Score Page 전체에서 유지되어야할 queryParameter들 */
const scorePageKeepParams = [SCORE_PARAMS.CUR_SCORE_STUDENT_SEQ, SCORE_PARAMS.CUR_SCORE_TEST_SEQ, SCORE_PARAMS.CUR_SCORE_CLASS_SEQ];

/** "/com/score/:category" 페이지 상단 메인 "Tab" 컴포넌트 */
export default function MainTab() {
  const location = useLocation();
  const { getObfuscatedValue, navigateWithParams } = useObfuscatedSearchParam();
  const queryCurScoreStudentSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_STUDENT_SEQ);
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);
  const queryCurScoreClassSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_CLASS_SEQ);
  const queryCurStudentSeq = getObfuscatedValue(SCORE_PARAMS.CUR_STU);

  /** mainTab에서 'Class' 탭의 Url을 생성하는 함수 */
  const getClassTabURL = () => {
    const pathToMove = `/com/score/class/${getValidParam(queryCurScoreClassSeq) ? obfuscateUrlParam(String(getValidParam(queryCurScoreClassSeq))) : obfuscateUrlParam('0')}`;
    return navigateWithParams(pathToMove, {
      keepParams: scorePageKeepParams,
      replace: true,
      isNavigate: false,
    });
  };
  /** mainTab에서 'Student' 탭의 Url을 생성하는 함수 */
  const getStudentTabURL = () => {
    const getTargetStudentSeq = () => {
      /** queryCurStudentSeq가 있는지 */
      const hasStudentSeq = getValidParam(queryCurStudentSeq);
      /** queryCurScoreStudentSeq가 있는지 */
      const hasScoreStudentSeq = getValidParam(queryCurScoreStudentSeq);

      // queryCurScoreStudentSeq가 있으면 우선 사용
      if (hasScoreStudentSeq) return obfuscateUrlParam(String(hasScoreStudentSeq));
      // scoreStudentSeq가 없고 studentSeq가 있으면 사용
      if (hasStudentSeq) return obfuscateUrlParam(String(hasStudentSeq));

      return obfuscateUrlParam('0'); // 둘 다 없을 경우 0 return
    };
    const pathToMove = `/com/score/student/${getTargetStudentSeq()}`;

    return navigateWithParams(pathToMove, {
      keepParams: scorePageKeepParams,
      newParams: {
        // queryCurScoreStudentSeq가 없을 경우, currentStudentSeq를 currentScoreStudentSeq로 설정
        ...(getValidParam(queryCurScoreStudentSeq) ? {} : queryCurStudentSeq ? { [SCORE_PARAMS.CUR_SCORE_STUDENT_SEQ]: queryCurStudentSeq } : {}),
      },
      replace: true,
      isNavigate: false,
    });
  };
  /** mainTab에서 'Lecture' 탭의 Url을 생성하는 함수 */
  const getLectureTabURL = () => {
    const pathToMove = `/com/score/lecture/${(queryCurScoreClassSeq ?? 0) > 0 ? obfuscateUrlParam(String(queryCurScoreClassSeq)) : obfuscateUrlParam('0')}`;
    return navigateWithParams(pathToMove, {
      keepParams: scorePageKeepParams,
      replace: true,
      isNavigate: false,
    });
  };

  /** Tab 데이터 */
  const tab = [
    { text: 'Class', path: getClassTabURL(), isDisable: false },
    { text: 'Student', path: getStudentTabURL(), isDisable: false },
    { text: 'Lecture', path: getLectureTabURL(), isDisable: false },
  ];

  const getCurrentCategory = () => {
    const path = location.pathname;
    if (path.startsWith('/com/score/class')) return '/com/score/class';
    if (path.startsWith('/com/score/student')) return '/com/score/student';
    if (path.startsWith('/com/score/lecture')) return '/com/score/lecture';
    return '';
  };

  const currentCategory = getCurrentCategory();

  return (
    <S.TabWrap>
      {tab?.map((item, idx) => {
        return (
          <Link
            to={!item.isDisable ? item.path : undefined}
            key={`${item.text}-${item.path}-${idx}`}
            className={`${item.path.startsWith(currentCategory) ? 'active' : ''} ${item.isDisable ? 'link-disable' : ''}`}>
            {item.text}
          </Link>
        );
      })}
    </S.TabWrap>
  );
}

const S = {
  TabWrap: styled.div`
    display: flex;
    flex: 0 0 14.625rem;
    background: #fff;
    border: 1.5px solid var(--point-color);
    border-radius: 0.5rem;
    height: 3.25rem;
    overflow: hidden;

    > :first-child {
      border-left: none;
      border-right: none;
    }
    > :last-child {
      border-left: none;
      border-right: none;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 0.875rem;
      color: #0068bd;
      border-left: 1.5px solid var(--point-color);
      border-right: 1.5px solid var(--point-color);
      position: relative; // disabled 일때 cursor: not-allowed 적용을 위해 설정
      &.active {
        background-color: #0068bd;
        color: #ffffff;
      }
      &.link-disable {
        pointer-events: none;
        background-color: #ffffff;
        filter: brightness(50%);
        cursor: not-allowed;
      }
    }
  `,
};
