import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import request from 'utils/Request.utils.js';
import { nvl } from 'utils/Common.utils.js';
import CustomAlert from 'components/_common/alerts/CustomAlert';
import SessionStorage from 'utils/SessionStorage.utils.js';
import dayjsTZ from 'utils/functions/time/dayjs-config';

//components
import Paging from 'layout/Paging'; //페이징
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod'; //팝업 달력
import MiniAlert from 'components/_common/alerts/MiniAlert'; //한줄 알럿
import TestList from 'components/com/tests/_components/_TestListLayer';
import MiniSvgIconBtn from 'components/_common/button/MiniSvgIconBtn';
import SMiniTextBtn from 'components/_common/button/SMiniTextBtn';
import ButtonBase from 'components/_common/button/_ButtonBase';
import SvgIconTextBtn from 'components/_common/button/SvgIconTextBtn';
import CustomSelect from 'components/_common/inputs/CustomSelect';
import PopBack from '../user/_PopBack.js';
import AddStudentAccount from 'components/_common/modals/AddStudentAccount';
import DeleteModal from 'components/_common/modals/DeleteModal';
import ScoreHistoryModal from './_components/modals/ScoreHistoryModal';
import ChangeClassModal from './_components/modals/ChangeClassModal';
// hook
import useOutsideClick from 'hooks/useOutsideClick.js';

//img
import IconOk from 'assets/img/icon_ok.svg';

import { USER_LEVELS } from 'utils/constants.js';
import DraggableProfilePopup from 'components/_common/modals/DraggableProfilePopup.jsx';
import MainLink from 'components/_common/button/_MainLinks.jsx';
import MultiClassList from 'components/com/tests/_components/_MultiClassListLayer.jsx';

/** 학생 메인 페이지 */
export default function StudentMainPage() {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const navigate = useNavigate();

  /** 오늘 날짜를 문자열로 return 하는 함수 */
  const getTodayDate = (value) => {
    const now = dayjsTZ();
    const formats = {
      time: 'YYYY. MM. DD. HH : mm',
      hyphen: 'YYYY-MM-DD',
      default: 'YYYY년 MM월 DD일',
    };
    const week = ['일', '월', '화', '수', '목', '금', '토'];

    const formatted = now.format(formats[value] || formats.default);
    return value === 'default' ? `${formatted} (${week[now.day()]})` : formatted;
  };

  const [studentInfo, setStudentInfo] = useState({
    studentList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });

  const [classList, setClassList] = useState();

  const [searchInfo, setSearchInfo] = useState({
    searchFieldActive: false,
    searchStartDate: '',
    searchEndDate: '',
    setSearchField: 'Name',
    searchKeyword: '',
    orderBy: 'MCH.reg_date DESC',
  });

  let alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };

  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  // 학생 history 모달 관련
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  // 학원의 학생 회원가입 모달 관련
  const [isBack, setBack] = useState(false);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);

  // 학생 상세 프로필 팝업
  const [showProfilePopupState, setShowProfilePopupState] = useState(false);
  const [ProfileDetail, setProfileDetail] = useState(null);
  const handleShowProfile = (e, item) => {
    e.stopPropagation();

    const studentInfo = item;
    let imageUrl = null;
    if (studentInfo.upFileSeq > 0) {
      imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}`;
    }
    studentInfo.profile = imageUrl;
    studentInfo.parentMobile = JSON.parse(studentInfo.familyInfo)?.[0].mobile;

    setProfileDetail(studentInfo);
    setShowProfilePopupState(true);
  };

  useEffect(() => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        returnValue: (value) => {
          if (nvl(value) === 'OK') {
            // console.log('요기에서 작업')
            // closeCustomAlert();
          }
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertLayerPopup?.id]);

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  const getStudentList = () => {
    let pms = {
      userSeq: userInfo?.userSeq,
      managerPosition: userInfo?.managerPosition,
      setSearchField: searchInfo.setSearchField === 'Name' ? 'user_name' : 'user_mobile',
      searchKeyword: searchInfo.searchKeyword,
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      limit: studentInfo.numPerPage,
      offset: (studentInfo.currentPage - 1) * studentInfo.numPerPage,
      orderBy: searchInfo.orderBy,
    };

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let studentList = response.result.studentInfo;
        let classList = response.result.classList;
        let totalCount = response.result.totalCount;

        setStudentInfo((prev) => {
          return { ...prev, studentList: studentList, totalCount: totalCount };
        });

        setClassList(
          classList != null &&
            classList?.map((item) => ({
              value: item.classSeq,
              name: item.className,
            }))
        );

        setSelectCheckBox((prev) => {
          return { ...prev, selectedKeys: [], seqStr: '' };
        });
      }
    };

    request.get(
      `/api/member/students?limit=${pms.limit}&offset=${pms.offset}&orderBy=${pms.orderBy}&searchField=${pms.setSearchField}&searchKeyword=${encodeURIComponent(pms.searchKeyword)}&startDate=${encodeURIComponent(pms.searchStartDate)}&endDate=${encodeURIComponent(pms.searchEndDate)}`,
      null,
      successHandler
    );
  };

  const studentListReload = () => {
    setStudentInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getStudentList();
  };

  const limitSelect = (value) => {
    let limit = value;

    setStudentInfo((prev) => {
      return { ...prev, numPerPage: value };
    });
  };

  const searchKeywordRef = useRef();

  const keywordSearchDo = (e) => {
    // console.log(nvl(searchKeywordRef.current.value));

    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: nvl(searchKeywordRef.current.value) };
    });
  };

  const [selectCheckBox, setSelectCheckBox] = useState({
    seqStr: '',
    selectedKeys: [],
  });

  const handleAllCheck = (checked) => {
    if (checked) {
      const idArray = [];
      let updatedSeqStr = '';

      for (let i = 0; i < studentInfo.studentList.length; i++) {
        idArray.push(studentInfo.studentList[i].userSeq);

        updatedSeqStr = idArray.join('|');
      }

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: idArray,
        seqStr: updatedSeqStr,
      });
    } else {
      setSelectCheckBox({ seqStr: '', selectedKeys: [] });
    }
  };

  const studentListCheck = (event, userSeq) => {
    const { selectedKeys, seqStr } = selectCheckBox;
    const { checked } = event.target;

    if (checked) {
      const updatedSelectedKeys = [...selectedKeys, userSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== userSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  const deleteStudentCompanyHub = () => {
    const { seqStr, selectedKeys } = selectCheckBox;

    if (selectedKeys.length > 0) {
      let pms = {
        seqStr: seqStr,
        ecSeq: studentInfo.studentList[0].ecSeq,
      };

      const successHandler = (response) => {
        if (response.code === 200) {
          deleteStudentAlertOpen();
          studentListReload();
        }
      };

      request.del(`/api/member/hub/${pms.seqStr}/${pms.ecSeq}`, null, successHandler);
    } else {
      setDeleteStudent(false);
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the student you want to delete.',
        };
      });
    }
  };

  useEffect(() => {
    getStudentList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo.currentPage]);

  useEffect(() => {
    studentListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo.numPerPage, searchInfo.searchKeyword, searchInfo.orderBy, searchInfo.searchStartDate, searchInfo.searchEndDate]);

  const nextPageMove = (value) => {
    setStudentInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const [deleteStudent, setDeleteStudent] = useState(false); //학생 삭제 팝업
  const [deleteStudentAlert, setDeleteStudentAlert] = useState(false); //학생 삭제 confirm 팝업
  const deleteStudentAlertOpen = () => {
    setDeleteStudent((prevState) => !prevState);
    setDeleteStudentAlert((prevState) => !prevState);
  };
  const [btnDownload, setBtnDownload] = useState(false); //다운로드 버튼
  const toggleBtnDownload = () => {
    setBtnDownload((prevState) => !prevState);
  };
  const [btnPrint, setBtnPrint] = useState(false); //프린트 버튼
  const toggleBtnPrint = () => {
    setBtnPrint((prevState) => !prevState);
  };

  const deletePopupOpen = () => {
    if (userInfo.userLevel === USER_LEVELS.TEACHER) return;
    if (selectCheckBox.selectedKeys.length > 0) {
      setDeleteStudent(true);
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the student you want to delete.',
        };
      });
    }
  };

  const excelDownloadAll = () => {
    toggleBtnDownload();
    let pms = {
      userSeq: userInfo?.userSeq,
      managerPosition: userInfo?.managerPosition,
      setSearchField: searchInfo.setSearchField === 'Name' ? 'user_name' : 'user_mobile',
      searchKeyword: searchInfo.searchKeyword,
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      orderBy: searchInfo.orderBy,
    };

    let filename = 'All Students ' + userInfo.companyName + '_' + getTodayDate('hyphen'); // 파일명

    request.downloadFile(
      `/api/member/student/excel?userSeq=${pms.userSeq}&orderBy=${pms.orderBy}&searchField=${pms.setSearchField}&searchKeyword=${encodeURIComponent(pms.searchKeyword)}&startDate=${pms.searchStartDate}&endDate=${pms.searchEndDate}`,
      filename
    );
  };

  const excelDownloadSelect = () => {
    if (selectCheckBox.selectedKeys.length > 0) {
      let filename = 'Selected Students ' + userInfo.companyName + '_' + getTodayDate('hyphen'); // 파일명

      request.downloadFile(`/api/member/student/excel?userSeq=${encodeURI(userInfo?.userSeq)}&seqStr=${encodeURI(selectCheckBox.seqStr)}`, filename);
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the student you want to download.',
        };
      });
    }
    toggleBtnDownload();
  };

  const printAll = () => {
    SessionStorage.clearItem('printCard');
    let pms = {
      userSeq: userInfo?.userSeq,
      managerPosition: userInfo?.managerPosition,
      setSearchField: searchInfo.setSearchField === 'Name' ? 'user_name' : 'user_mobile',
      searchKeyword: searchInfo.searchKeyword,
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      orderBy: searchInfo.orderBy,
    };

    const successHandler = (response) => {
      // console.log(response)

      if (response.code === 200) {
        let studentList = response.result.studentInfo;

        SessionStorage.setItem('printCard', 'studentList', studentList);
        window.open('/com/students/print', '_blank');

        toggleBtnPrint();
      }
    };

    request.get(
      `/api/member/students?orderBy=${pms.orderBy}&searchField=${pms.setSearchField}&searchKeyword=${encodeURIComponent(pms.searchKeyword)}&startDate=${encodeURIComponent(pms.searchStartDate)}&endDate=${encodeURIComponent(pms.searchEndDate)}`,
      null,
      successHandler
    );
  };

  const printSelect = () => {
    const { selectedKeys, seqStr } = selectCheckBox;
    if (selectedKeys.length > 0) {
      SessionStorage.clearItem('printCard');
      const selectStudentList = studentInfo.studentList.filter((i) => selectedKeys.includes(i.userSeq));
      SessionStorage.setItem('printCard', 'studentList', selectStudentList);
      window.open('/com/students/print', '_blank');
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the student you want to print.',
        };
      });
    }
    toggleBtnPrint();
  };

  const [alert, alertSet] = useState({
    visible: false,
    alertMessage: '',
  });

  //Class 선택 layer
  const [selectClass, setSelectClass] = useState(false);
  const [selectClassIdx, setSelectClassIdx] = useState(-1);
  const [selectMultiClass, setSelectMultiClass] = useState(false);
  const [selectMultiClassIdx, setSelectMultiClassIdx] = useState(-1);

  const searchFiledClick = (value) => {
    setSearchInfo((prev) => {
      return { ...prev, searchFieldActive: false, setSearchField: value };
    });
  };

  // 반 교체
  const classChange = (i) => {
    setSelectClass(!selectClass);
    setSelectClassIdx(i);

    setSelectMultiClassIdx(-1);
  };

  // 멀티 클래스 조회
  const multiClassChange = (i) => {
    setSelectMultiClass(!selectMultiClass);

    setSelectMultiClassIdx(i);
  };

  // grade toggle
  const [gradeOrder, setGradeOrder] = useState(0);
  const gradeOrderToggle = () => {
    if (searchInfo.orderBy === 'MCH.reg_date DESC') {
      setSearchInfo((prev) => {
        return { ...prev, orderBy: 'student_grade DESC, MCH.reg_date DESC' };
      });
      setGradeOrder(2);
    } else if (searchInfo.orderBy === 'student_grade DESC, MCH.reg_date DESC') {
      setSearchInfo((prev) => {
        return { ...prev, orderBy: 'student_grade ASC, MCH.reg_date DESC' };
      });
      setGradeOrder(1);
    } else {
      setSearchInfo((prev) => {
        return { ...prev, orderBy: 'MCH.reg_date DESC' };
      });
      setGradeOrder(0);
    }
  };
  // 외부 클릭시 닫히는 훅
  const downloadBox = useRef(null);
  useOutsideClick(downloadBox, () => setBtnDownload(false), btnDownload);
  const printBox = useRef(null);
  useOutsideClick(printBox, () => setBtnPrint(false), btnPrint);
  const searchBox = useRef(null);
  useOutsideClick(
    searchBox,
    () =>
      setSearchInfo((prev) => {
        return { ...prev, searchFieldActive: false };
      }),
    searchInfo.searchFieldActive
  );

  // 학생 스코어 히스토리
  const [userSeq, setUserSeq] = useState(0);
  const [userName, setUserName] = useState(null);

  const doubleCLickHandler = (userSeq, userName) => {
    setUserSeq(userSeq);
    setUserName(userName);

    setShowHistoryModal(true);
  };

  // tr 클릭 이벤트
  const trClickEvent = (userSeq) => {
    const { selectedKeys, seqStr } = selectCheckBox;

    if (!selectedKeys.includes(userSeq)) {
      const updatedSelectedKeys = [...selectedKeys, userSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== userSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  return (
    <>
      <div className='p_student_list'>
        <MainLink type='students' />
        <div className='com_center_wrap'>
          <div className='com_box'>
            {/* 📏 student main - Table 📏 */}
            <div className='com_list'>
              {/* 📏 student main - Table_Header 📏 */}
              <section className='top'>
                {/* 🧩 student main - Table_Header_버튼영역 🧩 */}
                <article className='btn_area'>
                  <SvgIconTextBtn
                    text='Add Student'
                    svgClassName='svg_icon icon_person_add blue'
                    bgColor='#DFF1FF'
                    color='#4692D1'
                    borderColor='none'
                    onClick={() => setShowAddStudentModal(true)}
                  />
                  {userInfo.userLevel !== USER_LEVELS.TEACHER && (
                    <MiniSvgIconBtn text='Delete' svgClassName='svg_icon icon_trash' onClick={deletePopupOpen} radius='medium' dataHover />
                  )}
                  <div className='com_btn_drop' ref={downloadBox}>
                    <MiniSvgIconBtn text='Download' svgClassName='svg_icon icon_download' onClick={toggleBtnDownload} radius='medium' dataHover />
                    <div className={`layer ${btnDownload ? 'active' : ''}`}>
                      <button className='btn' onClick={excelDownloadSelect}>
                        <i className='svg_icon icon_person_select'>&nbsp;</i>
                        Selected Students
                      </button>
                      <button className='btn' onClick={excelDownloadAll}>
                        <i className='svg_icon icon_person_all'>&nbsp;</i>All Students
                      </button>
                    </div>
                  </div>
                  <div className='com_btn_drop' ref={printBox}>
                    <MiniSvgIconBtn text='Print' svgClassName='svg_icon icon_print' onClick={toggleBtnPrint} radius='medium' dataHover />
                    <div className={`layer ${btnPrint ? 'active' : ''}`}>
                      <button className='btn' onClick={printSelect}>
                        <i className='svg_icon icon_person_select'>&nbsp;</i>
                        Selected Students
                      </button>
                      <button className='btn' onClick={printAll}>
                        <i className='svg_icon icon_person_all'>&nbsp;</i>All Students
                      </button>
                    </div>
                  </div>
                </article>

                {/* 🧩 student main - Table_Header_검색영역 🧩 */}
                <article className='search_area'>
                  <PopupCalendarPeriod setSearchInfo={setSearchInfo} />
                  <div
                    className={`com_select_layer ${searchInfo.searchFieldActive ? 'active' : ''}`}
                    onClick={() =>
                      setSearchInfo((prev) => {
                        return {
                          ...prev,
                          searchFieldActive: !searchInfo.searchFieldActive,
                        };
                      })
                    }
                    ref={searchBox}>
                    {/*클래스 active 포함 시 옵션 노출*/}
                    <input type='text' className='selected search_area_input' placeholder={searchInfo.setSearchField} />
                    <div className='layer'>
                      <p className='option' onClick={() => searchFiledClick('Name')}>
                        Name
                      </p>
                      <p className='option' onClick={() => searchFiledClick('Phone no')}>
                        Phone no
                      </p>
                    </div>
                  </div>
                  <div className='search'>
                    <input
                      type='text'
                      placeholder='Enter keywords'
                      className='input search_area_input'
                      ref={searchKeywordRef}
                      onKeyUp={(e) => (e.key === 'Enter' ? keywordSearchDo() : null)}
                    />
                    <button className='com_btn m blue btn_search' onClick={keywordSearchDo}>
                      <i className='svg_icon icon_search blue before'></i>
                      SEARCH
                    </button>
                  </div>
                  <CustomSelect
                    options={[
                      { label: '10 line', value: 10 },
                      { label: '25 line', value: 25 },
                      { label: '50 line', value: 50 },
                      { label: '100 line', value: 100 },
                    ]}
                    onSelect={limitSelect}
                  />
                </article>
              </section>

              {/* 📏 student main - Table_MainContent 📏 */}
              <table className='list'>
                <colgroup>
                  <col width='40' />
                  <col width='50' />
                  <col width='176' />
                  <col width='74' />
                  <col width='63' />
                  <col width='197' />
                  <col width='104' />
                  <col width='304' />
                  <col width='80' />
                  <col width='80' />
                </colgroup>
                {/* 🧩 student main - Table_MainContent_테이블헤더 🧩 */}
                <thead>
                  <tr>
                    <th>
                      <input
                        type='checkbox'
                        className='com_chk'
                        id='chk01'
                        onChange={(e) => handleAllCheck(e.target.checked)}
                        checked={
                          nvl(studentInfo?.studentList) !== '' &&
                          selectCheckBox.selectedKeys.length === studentInfo.studentList.length &&
                          studentInfo.totalCount > 0
                            ? true
                            : false
                        }
                      />
                      <label htmlFor='chk01'>&nbsp;</label>
                    </th>
                    <th>No.</th>
                    <th>Name</th>
                    <th>Profile</th>
                    <th>
                      <button
                        style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                        className={`btn_sort ${gradeOrder === 0 ? 'default' : gradeOrder === 1 ? 'active' : ''}`}
                        onClick={() => gradeOrderToggle()}>
                        Grade
                      </button>
                      {/*클릭 시 toggleClass active, 원위치 addClass default*/}
                    </th>
                    <th>School Name</th>
                    <th>Location</th>
                    <th>Class</th>
                    <th>Test Scheduled</th>
                    <th>Test Taken</th>
                  </tr>
                </thead>
                {/* 🧩 student main - Table_MainContent_테이블메인 🧩 */}
                <tbody>
                  {studentInfo.studentList == null || studentInfo.studentList?.length < 1 ? (
                    <tr>
                      <td colSpan='11'>You do not have any students… yet!</td>
                    </tr>
                  ) : (
                    studentInfo.studentList.map((item, index) => {
                      return (
                        <tr
                          key={`tr_${index}_${item.userSeq}`}
                          onClick={() => trClickEvent(item.userSeq)}
                          onDoubleClick={() => doubleCLickHandler(item.userSeq, item.userName)}>
                          <td>
                            <input
                              type='checkbox'
                              className='com_chk'
                              id={`chk_${index}`}
                              onChange={(event) => {
                                studentListCheck(event, item.userSeq);
                              }}
                              checked={selectCheckBox.selectedKeys.includes(item.userSeq)}
                            />
                            <label htmlFor={`chk_${index}`} onClick={(e) => e.stopPropagation()}>
                              &nbsp;
                            </label>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>{studentInfo.totalCount - studentInfo.numPerPage * (studentInfo.currentPage - 1) - index}</td>
                          <td style={{ ...textLeftStyle }}>
                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '4px' }}>
                              {item.readFlag === 'N' && <SMiniTextBtn text={'A'} />}
                              <p className='name'>{item.userName}</p>
                            </div>
                          </td>
                          <td>
                            <ButtonBase text='View' type='tableBtn' onClick={(e) => handleShowProfile(e, item)} />
                          </td>
                          <td>{item.studentGrade}</td>
                          <td style={textLeftStyle}>{item.studentSchool}</td>
                          <td style={textLeftStyle}>{item.schoolLocationName}</td>
                          <td style={{ ...textLeftStyle }} className='class'>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={(e) => e.stopPropagation()}>
                              <span
                                style={{ padding: '0 2px' }}
                                onClick={() => {
                                  JSON.parse(item.classList)?.length > 1 ? multiClassChange(index) : null;
                                }}>
                                {item.className || 'Individual'}
                                <MultiClassList
                                  item={item.classList}
                                  selectMultiClass={selectMultiClass}
                                  setSelectMultiClass={setSelectMultiClass}
                                  index={index}
                                  selectMultiClassIdx={selectMultiClassIdx}
                                />
                              </span>
                              <MiniSvgIconBtn
                                text='Change class'
                                type='tableBtn'
                                svgClassName='svg_icon icon_change_arrow gray'
                                onClick={() => classChange(index)}
                                dataHover
                              />
                              {/* Change class 모달 */}
                              {index === selectClassIdx && (
                                <ChangeClassModal
                                  studentInfo={{ userSeq: item.userSeq, userName: item.userName }}
                                  studentClassList={item.classList}
                                  companyClassList={classList}
                                  selectClass={selectClass}
                                  setSelectClass={setSelectClass}
                                  alertSet={alertSet}
                                  getStudentList={getStudentList}
                                />
                              )}
                            </div>
                          </td>
                          <td>
                            <TestList item={item.testHistoryInfo} status={'E'} page={'students'} />
                          </td>
                          <td>
                            <TestList item={item.testHistoryPastInfo} status={'P'} page={'students'} />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>

            {/* 📏 student main - Pagination 📏 */}
            <Paging
              totalCount={studentInfo.totalCount}
              currentPage={studentInfo.currentPage}
              numPerPage={studentInfo.numPerPage}
              pagePerBlock={studentInfo.pagePerBlock}
              nextPageMove={nextPageMove}
            />
            {alertLayerPopup.visible && (
              <CustomAlert
                onClose={closeCustomAlert}
                alertType={alertLayerPopup.alertType}
                alertMessage={alertLayerPopup.alertMessage}
                returnValue={alertLayerPopup.returnValue}
              />
            )}
          </div>
        </div>
      </div>

      {/* 학생 삭제 팝업 */}
      {deleteStudent && (
        <DeleteModal
          hideModal={() => setDeleteStudent(false)}
          callback={deleteStudentCompanyHub}
          text={'Once you delete a student from the list, you cannot add the \n student back until the student links back to you.'}
        />
      )}
      {/* 학생 삭제 완료 팝업 */}
      <div className={`com_popup type_alert ${deleteStudentAlert ? 'active' : ''}`}>
        <div className='pop_container'>
          <div className='pop_body ok_contents'>
            <p className='alert_title'>Successfully deleted</p>
            <div className='icon'>
              <img src={IconOk} alt='icon' />
            </div>
          </div>
          <div className='pop_footer com_btn_wrap'>
            <button className='com_btn point xl full' onClick={() => setDeleteStudentAlert(false)}>
              Confirm
            </button>
          </div>
        </div>
      </div>

      {/* 학생 상세 프로필 팝업 */}
      {showProfilePopupState && <DraggableProfilePopup setProfileState={setShowProfilePopupState} studentInfo={ProfileDetail} />}

      {/* 🪟 student main - 학생 회원가입 모달 🪟 */}
      {showAddStudentModal && <AddStudentAccount setBack={setBack} setShowAddStudentModal={setShowAddStudentModal} callback={studentListReload} />}
      {isBack && <PopBack closePopBack={() => setBack(false)} confirmCallback={() => setShowAddStudentModal(false)} />}

      {/* 🪟 student main - 학생 스코어 히스토리 모달 🪟 */}
      {showHistoryModal && <ScoreHistoryModal setShowModal={setShowHistoryModal} userSeq={userSeq} userName={userName} />}

      {/* 학생 삭제 확인 Toast Alert */}
      {alert.visible && (
        <MiniAlert text={alert.alertMessage} active={alert.visible} inactive={() => alertSet({ visible: false, alertMessage: '' })} timeOut={2000} />
      )}
    </>
  );
}

const textLeftStyle = {
  textAlign: 'left',
};
