//#region    ////////// packages
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
//#endregion ////////// packages

//#region    ////////// components
import MiniReview from '../../test-editor/_components/MiniReview';
import UI_ExamFooter from 'components/_common/exam/UI_ExamFooter';
//#endregion ////////// components

//#region    ////////// utils & hooks
import useOutsideClick from 'hooks/useOutsideClick';
//#endregion ////////// utils & hooks

//#region    ////////// constants
import { EDITOR_CONSTANT } from '../../test-editor/_constant';
//#endregion ////////// constants

//#region    ////////// assets
import { ReactComponent as ImageSvgIconArrowBlack02 } from 'assets/img/svg/icon_arrow_02.svg';
//#endregion ////////// assets

export default function ApExamFooter({ editorState, handleSaveAllContent, editorStateMutator, editorStateSetter }) {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const apTestEditorReducer = useSelector((state) => state.apTestEditorReducer);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** "Mini Review" 영역 노출 여부 */
  const [isShowMiniReview, setIsShowMiniReview] = useState(false);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const miniReviewRef = useRef(null); // MiniReview 외부 클릭용 Ref
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  /** 현재 위치하고 있는 문제 인덱스 */
  const currentQuestionIndex = editorState[EDITOR_CONSTANT.currentIndex];
  //#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** "Mini Review" 여닫기 버튼 핸들러 */
  const handleShowMiniReview = () => {
    setIsShowMiniReview((current) => !current);
  };

  /** "이전" 버튼 핸들러 */
  const backHandler = () => {
    if (currentQuestionIndex > 0) {
      handleSaveAllContent();
      editorStateMutator(EDITOR_CONSTANT.currentIndex, (prev) => prev - 1);
    }
  };
  /** "디음" 버튼 핸들러 */
  const nextHandler = () => {
    if (currentQuestionIndex <= editorState[EDITOR_CONSTANT.currentPart].number_of_questions) {
      handleSaveAllContent();
      editorStateMutator(EDITOR_CONSTANT.currentIndex, (prev) => prev + 1);
    }
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 --- START ////////////////////
  useOutsideClick(miniReviewRef, () => setIsShowMiniReview(false), isShowMiniReview); // MiniReview - 외부 클릭시 닫기
  //#endregion //////////////////  END  --- 커스텀 훅 ---  END  ////////////////////

  const centerContent = (
    <div ref={miniReviewRef}>
      <button type='button' title='Open/Close' onClick={handleShowMiniReview} className='question_nav_btn'>
        <p>
          Question <span>{currentQuestionIndex + 1}</span> of <span>{editorState[EDITOR_CONSTANT.currentPart]?.number_of_questions}</span>
        </p>
        <span className='question_nav_btn_arrow'>
          <ImageSvgIconArrowBlack02 />
        </span>
      </button>
      {isShowMiniReview && (
        <MiniReview
          editorStateSetter={editorStateSetter}
          editorState={editorState}
          setIsShowMiniReview={setIsShowMiniReview}
          handleSaveAllContent={handleSaveAllContent}
        />
      )}
    </div>
  );

  const rightContent = (
    <>
      <button disabled={currentQuestionIndex <= 0} onClick={backHandler}>
        Back
      </button>
      <button disabled={currentQuestionIndex >= editorState[EDITOR_CONSTANT.currentPart].number_of_questions - 1} onClick={nextHandler}>
        Next
      </button>
    </>
  );

  return <UI_ExamFooter leftContent={null} centerContent={centerContent} rightContent={rightContent} />;
}
