import React from 'react';
import styled from 'styled-components';

import Box from '../../common/styles/Box';
import SectionTitle from '../../common/styles/SectionTitle';

/** ap/com/score 페이지의 "AP Score" 섹션 컴포넌트 */
export default function ApScoreSection({ score = 0, className = '', ...rest }) {
  return (
    <S.Wrap className={` ${className}`} {...rest}>
      <Box className='main_box'>
        <SectionTitle>AP Score</SectionTitle>
        <div className='contents_area'>
          <div className='score_wrap'>
            <span>{score}</span>1~5
          </div>
        </div>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 11.25rem;
    .main_box {
      height: 100%;
      padding: 1.25rem 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .score_wrap {
      color: #999;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.75rem;
      span {
        color: #2a60ff;
        font-size: 3.5rem;
        font-weight: 500;
        line-height: 100%;
        padding-right: 0.5rem;
      }
    }
  `,
};
