import React, { useEffect, useState } from 'react';
import { getRBCEventColor, nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import { USER_LEVELS } from 'utils/constants';

//components
import CalendarTable from 'components/_common/calendar/Calendar';
import ModalHaveToLinkWithAcademyOrTutor from 'components/_common/modals/HaveToLinkWithAcademyOrTutor';
import MainLink from 'components/_common/button/_MainLinks';

import dayjsTZ, { getDateOnlyDayjsTZ } from 'utils/functions/time/dayjs-config';

/**
 * 달력 페이지 컴포넌트
 * @description
 * 🔍 검색 키워드 - #캘린더 #com/tests/calendar
 */
export default function TestCalendarPage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 //////////
  const rbcEventColors = getRBCEventColor();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 //////////
  /////////////////// React useState 선언 영역 시작 //////////////////////
  const userInfo = request.tokenDecoder();
  const [events, setEvents] = useState([
    // {
    //   uthSeq: 0
    //   classSeq: 0,
    //   title: '',
    //   start: dayjsTZ().toDate(),
    //   end: dayjsTZ().toDate(),
    //   classColor: '',
    //   allDay: true,
    // },
  ]);
  const [classInfo, setClassInfo] = useState([{ classSeq: 0, classTitle: '-', classColor: '-' }]);
  const [currentDate, setCurrentDate] = useState(dayjsTZ().toDate());
  const [isModalHaveToLinkWithAcademyOrTutor, setIsModalHaveToLinkWithAcademyOrTutor] = useState(false);
  /////////////////// React useState 선언 영역 끝 //////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  // 부모 계정의 경우 자녀의 ecSeq,userSeq를 가져오는 함수
  const childEcSeq = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let childInfo = response.result.childInfo;

        if (childInfo) {
          const childEcSeq = childInfo.ecSeq;
          const childUserSeq = childInfo.familySeq;

          getCalendarData(childEcSeq, childUserSeq);
          getClassColor(childEcSeq, childUserSeq);
        }
      }
    };

    request.get(`/api/member/child?userSeq=${userInfo.userSeq}`, null, successHandler);
  };

  const getCalendarData = (ecSeq, userSeq = userInfo.userSeq) => {
    if (ecSeq === 0) return;

    const successHandler = (response) => {
      if (response.code === 200) {
        const scheduleInfo = response.result.scheduleInfo;

        setEvents(
          scheduleInfo.map((item) => {
            return {
              uthSeq: item.uthSeq,
              classSeq: item.classSeq,
              title: item.testName,
              start: item.testStartDate,
              end: item.testEndDate,
              // start: item.testStartDate.split('T')[0],
              // end: item.testEndDate.split('T')[0],
              classColor: item.classColor,
              allDay: true,
            };
          })
        );
      }
    };

    const searchStartDate = encodeURIComponent(getDateOnlyDayjsTZ(currentDate).subtract(1, 'month').date('1').format());
    const searchEndDate = encodeURIComponent(getDateOnlyDayjsTZ(currentDate).add(2, 'month').date('1').format());

    // 학원관리자, 선생님, 튜터
    if (userInfo.userLevel === USER_LEVELS.ACADEMY || userInfo.userLevel === USER_LEVELS.TUTOR || userInfo.userLevel === USER_LEVELS.TEACHER) {
      const apiUrl = `/api/dsat/calendar/schedules?ecSeq=${ecSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`;
      request.get(apiUrl, null, successHandler);
      // 학부모, 학생
    } else {
      const apiUrl = `/api/dsat/calendar/schedules?ecSeq=${ecSeq}&userSeq=${userSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`;
      request.get(apiUrl, null, successHandler);
    }
  };

  const getClassColor = (ecSeq, userSeq = userInfo.userSeq) => {
    if (ecSeq === 0) return;

    const successHandler = (response) => {
      if (response.code === 200) {
        const classInfo = response.result.classInfo;
        setClassInfo(
          classInfo.map((data) => {
            return {
              classSeq: data.classSeq,
              classTitle: data.className,
              classColor: data.classColor === null ? '' : data.classColor,
            };
          })
        );
      }
    };
    const searchStartDate = encodeURIComponent(getDateOnlyDayjsTZ(currentDate).subtract(1, 'month').date('1').format());
    const searchEndDate = encodeURIComponent(getDateOnlyDayjsTZ(currentDate).add(2, 'month').date('1').format());

    // 학원관리자, 선생님, 튜터
    if (userInfo.userLevel === USER_LEVELS.ACADEMY || userInfo.userLevel === USER_LEVELS.TUTOR || userInfo.userLevel === USER_LEVELS.TEACHER) {
      const apiUrl = `/api/dsat/calendar/classes?ecSeq=${ecSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`;
      request.get(apiUrl, null, successHandler);

      // 학부모, 학생
    } else {
      const apiUrl = `/api/dsat/calendar/classes?ecSeq=${ecSeq}&userSeq=${userSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`;
      request.get(apiUrl, null, successHandler);
    }
  };

  const submitRandomClassColor = (classSeq, color) => {
    let params = { classColor: color };
    const successHandler = (response) => {
      //
    };
    request.put(`/api/dsat/calendar/classes/${classSeq}`, params, successHandler);
  };

  /** 학생 - 학원 연결 팝업 닫기 기능 */
  const handleModalHaveToLinkWithAcademyOrTutorClose = () => {
    setIsModalHaveToLinkWithAcademyOrTutor(false);
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ///////////////////////
  useEffect(() => {
    if (userInfo.userLevel === USER_LEVELS.PARENT) {
      childEcSeq();
    } else {
      getCalendarData(userInfo.ecSeq);
      getClassColor(userInfo.ecSeq);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  useEffect(() => {
    const colorMapping = {};
    classInfo.forEach((info) => {
      if (info.classColor === '') {
        info.classColor = rbcEventColors[Math.floor(Math.random() * rbcEventColors.length)].name;
        submitRandomClassColor(info.classSeq, info.classColor);
      }
      colorMapping[info.classSeq] = info.classColor;
    });

    const updatedEvents = events.map((event) => {
      const classColor = colorMapping[event.classSeq];
      return classColor ? { ...event, classColor } : event;
    });

    setEvents(updatedEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classInfo]);

  useEffect(() => {
    if (userInfo.ecSeq > 0) {
      console.log('학원 연결 됨');
      setIsModalHaveToLinkWithAcademyOrTutor(false);
    } else {
      console.log('학원 연결 안 됨');
      // 연결 된 학원 목록이 0개 이하인 경우, 모달 노출
      setIsModalHaveToLinkWithAcademyOrTutor(true);
    }
  }, [userInfo]);

  /////////////////// React useEffect 영역 끝 ///////////////////////

  return (
    <>
      <div className='p_calendar'>
        {userInfo.userLevel === USER_LEVELS.STUDENT || userInfo.userLevel === USER_LEVELS.PARENT ? <section className='com_main_link'></section> : <MainLink />}
        <div className='com_center_wrap'>
          <CalendarTable
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            events={events}
            setEvents={setEvents}
            classInfo={classInfo}
            setClassInfo={setClassInfo}
          />
        </div>
      </div>
      {/* Modals area Start */}
      {isModalHaveToLinkWithAcademyOrTutor && <ModalHaveToLinkWithAcademyOrTutor close={handleModalHaveToLinkWithAcademyOrTutorClose} />}
      {/* Modals area End */}
    </>
  );
}
