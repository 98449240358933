import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import MainBox from 'components/user/_components/layouts/MainBox';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';

import { ReactComponent as ImgSvgIconMailing } from 'assets/img/svg/icon/icon-mail-illust-01.svg';
import { clearReduxStateAcademyAdmin } from 'reducers/auth/academyadmin.action';
import { clearReduxPrivateTutorState } from 'reducers/auth/privatetutor.action';
import { clearReduxStateSocialUser } from 'reducers/auth/action';
/** 회원 가입 - "Successfully Submitted" 페이지 */
export default function SignUpSuccessfullySubmittedPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const userSocial = useSelector((state) => state.userReducer.social);
  // const userLevel = userSocial.userLevel;
  // console.log('🚀 ~ SignUpSuccessfullySubmittedPage ~ userSocial:', userSocial);
  const stateCustomer = useSelector((state) => state.Customer);

  let userLevel = stateCustomer.userLevel;
  if (!userLevel) {
    // console.log('코드가 없음');
    const userSocial = useSelector((state) => state.userReducer.social);
    // console.log('🚀 ~ SignUpSuccessfullySubmittedPage ~ userSocial:', userSocial);
    userLevel = userSocial.userLevel;
  }

  /** 버튼 클릭 핸들러 */
  const handleClick = () => {
    navigate('/');
  };

  useEffect(() => {
    // academy reducer 초기화
    // dispatch(clearReduxStateSocialUser());
    dispatch(clearReduxStateAcademyAdmin());
    dispatch(clearReduxPrivateTutorState()); // tutor 는 가입당시 비승인 상태이기때문에 초기화
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrap>
      <MainBox>
        <ImgSvgIconMailing className='main-icon' />
        <h4>Successfully submitted!</h4>
        <ul>
          <li>
            <p className='li-dot'>
              <div className='dot'>&nbsp;</div>
            </p>
            <p>
              In 1~2 business days, you will receive your {`${userLevel === '001001002000000' ? 'Tutor Code' : 'Academy Code'}`} to the email address you have
              submitted once you are verified.
            </p>
          </li>
          <li>
            <p className='li-dot'>
              <div className='dot'>&nbsp;</div>
            </p>
            <p>
              You can login with the {`${userLevel === '001001002000000' ? 'Tutor Code' : 'Academy Code'}`} link when you receive it. Make sure to remember your{' '}
              {`${userLevel === '001001002000000' ? 'Tutor Code' : 'Academy Code'}`} as your students will use your{' '}
              {`${userLevel === '001001002000000' ? 'Tutor Code' : 'Academy Code'}`} to link with you.
            </p>
          </li>
        </ul>
        <PrimaryButtonArea className='primary-btn-area'>
          <PrimaryButton type='button' title='페이지 이동' onClick={handleClick}>
            Return Home
          </PrimaryButton>
        </PrimaryButtonArea>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .main-icon {
    width: 5.125rem;
  }
  h4 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #111111;
    margin: 2rem 0 1.5rem;
  }
  li {
    color: #7f8998;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
    line-height: 1.25rem;
    list-style: none;
    display: flex;
    & + li {
      margin-top: 1rem;
    }
    .dot {
      color: rgb(127, 137, 152);
      background-color: rgb(127, 137, 152);
      margin: unset;
      margin-right: 8px;
      margin-top: 9px;
      width: 3px;
      height: 3px;
    }
  }

  .primary-btn-area {
    justify-content: center;
    button {
      width: 16.375rem;
    }
  }

  @media screen and (max-width: 640px) {
    .primary-btn-area {
      button {
        width: 100%;
      }
    }
  }
`;
