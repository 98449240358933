import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';
import { getValidParam, useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';

import request from 'utils/Request.utils.js';
import LoadingBar from 'utils/LoadingBar.js';

import { SCORE_PARAMS } from 'utils/constants';

//components
import ScoresChartSection from '../_components/sections/ScoresChartSection';
import StudentListSection from '../_components/sections/studentListSection/StudentListSection';
import AdvancedReport from '../_components/sections/advancedReportSection/AdvancedReport';
import MainTopArea from '../_components/layouts/main-top-area/MainTopArea';
import SelectClass from '../_components/layouts/main-top-area/SelectClass';
import SelectDetailSection from '../_components/layouts/main-top-area/SelectDetailSection';
import MainTab from '../_components/layouts/main-top-area/MainTab';
import OuterBox from '../_components/common/OuterBox';
import AverageWeeklyScores from '../_components/sections/averageWeeklyScores/AverageWeeklyScores';
import BtoBMainTabFrameHOC from 'layout/commonFrame/BtoBMainTabFrameHOC';

/** com/score/lecture 페이지 */
export default function ScoreLecturePage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  /** 현재 classSeq - Route Parameter */
  const { classSeq: encClassSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue, setObfuscatedValue, deleteObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [isLoadTestData, setIsLoadTestData] = useState(false);
  /** 중복호출 방지용 Ref값 초기화 여부 */
  const [isInitialized, setIsInitialized] = useState(false);
  /** 현재 class의 Test 정보 */
  const [currentClassTestData, setCurrentClassTestData] = useState([]);
  /** 현재 class && Test를 본 학생 데이터 - 초기화 객체 */
  const initialStudentList = [
    {
      userSeq: 0, // Number                     => 학생 고유번호
      uthSeq: 0, // Number                      => 유저 시험 기록 시퀀스(난독화X)
      userName: '', // String                   => 학생 이름
      testStatus: '', // "P" | "I" | "B" | "E"  => 학생의 시험 상태 ( 시험완료("P") | 시험중("I") | 쉬는시간("B") | 시험전("E") )
      mathScore: 0, // Number                   => 수학 점수
      rwScore: 0, // Number                     => 영어 점수
      upFileSeq: 0, // Number                   => 학생 프로필 사진 파일 시퀀스
    },
  ];
  /** 현재 class && Test를 본 학생 데이터 */
  const [studentList, setStudentList] = useState(initialStudentList);

  const [loading, setLoading] = useState(false);

  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  //////////////////// START --- API 중복 호출 방지용 Ref --- START ////////////////////
  const prevClassScoreTrendRef = useRef(classSeq); // score/class/trend API 중복 호출 방지용 Ref
  const prevStudentListRef = useRef({ classSeq, queryCurScoreTestSeq }); // score/class/student-list API 중복 호출 방지용 Ref
  ////////////////////  END  --- API 중복 호출 방지용 Ref ---  END  ////////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  // 현재 class가 바뀔때 마다 데이터 호출
  useEffect(() => {
    // classSeq가 유효하지 않다면 함수 종료
    if (!getValidParam(classSeq)) return;

    let isCurrentRequest = true; // 컴포넌트 언마운트시 API 호출 방지용 변수

    /** 해당하는 class의 score trend를 불러오는 함수 */
    const getScoreTrendDate = () => {
      // 중복 방지 Ref가 초기화가 되지 않았거나, API 파라미터가 유효하지 않을 경우 early return
      if (!(isInitialized && getValidParam(classSeq))) return;
      // 이전 호출한 API의 classSeq와 현재 classSeq가 같다면 함수 종료 ( 중복 호출 방지 )
      if (prevClassScoreTrendRef.current === classSeq) return;
      prevClassScoreTrendRef.current = classSeq; // classSeq 값 업데이트

      setIsLoadTestData(true);

      const successHandler = (response) => {
        if (!isCurrentRequest) return;

        if (response.code === 200) {
          const scoreTrendData = response.result.scoreTrend;

          if (scoreTrendData.length !== 0) {
            // 데이터 State 저장 및 마지막 8개의 데이터를 보여주기 위한 startIndex 설정
            setCurrentClassTestData(scoreTrendData);
            // queryCurScoreTestSeq데이터가 없을때만 URLSearchParams으로 SCORE_PARAMS.CUR_SCORE_TEST_SEQ 세팅
            if (!getValidParam(queryCurScoreTestSeq)) {
              setObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ, scoreTrendData[0]?.customSetSeq);
            }
          } else {
            // TestData가 없을 경우 - CurrentClassTestData를 빈 배열로 초기화 및 URLSearchParams으로 SCORE_PARAMS.CUR_SCORE_TEST_SEQ 제거
            setCurrentClassTestData([]);
            deleteObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);
          }
        }
      };

      request
        .get(`/api/v2/score/class/trend?classSeq=${classSeq}`, null, successHandler)
        .catch((error) => console.error('', error).finally(() => setIsLoadTestData(false)));
    };

    getScoreTrendDate();

    // 언마운트시 진행중인 API 호출을 취소
    return () => (isCurrentRequest = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classSeq, isInitialized]);

  useEffect(() => {
    // queryCurScoreTestSeq혹은 classSeq가 유효하지 않을 경우 early return
    if (!getValidParam(queryCurScoreTestSeq) || !getValidParam(classSeq)) {
      setStudentList(initialStudentList);
      return;
    }

    let isCurrentRequest = true; // 컴포넌트 언마운트시 API 호출 방지용 변수

    /** 해당하는 class && Test의 학생 목록 불러오기 */
    const getStudentListData = () => {
      // 중복 방지 Ref가 초기화가 되지 않았거나, API 파라미터가 유효하지 않을 경우 early return
      if (!(isInitialized && getValidParam(classSeq) && getValidParam(queryCurScoreTestSeq))) return;
      // 이전 호출한 API의 파라미터와 현재 파라미터가 같다면 함수 종료 ( 중복 호출 방지 )
      if (prevStudentListRef.current?.classSeq === classSeq && prevStudentListRef.current?.queryCurScoreTestSeq === queryCurScoreTestSeq) return;
      prevStudentListRef.current = { classSeq, queryCurScoreTestSeq }; // 현재 파라미터 값 업데이트

      const successHandler = (response) => {
        if (!isCurrentRequest) return;

        if (response.code === 200) {
          const fetchedStudentList = response.result.studentList;
          if (fetchedStudentList.length > 0) {
            setStudentList(fetchedStudentList);
            setObfuscatedValue(SCORE_PARAMS.CUR_STU, fetchedStudentList[0].userSeq);
          } else {
            setStudentList(initialStudentList);
            deleteObfuscatedValue(SCORE_PARAMS.CUR_STU);
          }
        }
      };

      request
        .get(`/api/v2/score/class/student-list?classSeq=${classSeq}&customSetSeq=${queryCurScoreTestSeq}`, null, successHandler)
        .catch((error) => console.error('', error))
        .finally(() => {
          if (isCurrentRequest) console.log('데이터 호출 완료');
        });
    };

    getStudentListData();

    // 언마운트시 진행중인 API 호출을 취소
    return () => (isCurrentRequest = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCurScoreTestSeq, classSeq, isInitialized]);

  // 컴포넌트 마운트시 Ref값 초기화 ( 새로고침시 이전 Ref 값으로 중복호출방지가 실행되는 것을 막음 )
  useEffect(() => {
    prevClassScoreTrendRef.current = null;
    prevStudentListRef.current = null;
    setIsInitialized(true);
  }, []);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  // 현재 선택된 TestSeq 보기 ( 디버깅용 console )
  // console.log(`\n┏━━━ 💡 💡 CurTest 💡 💡 ━━━\n`, getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ), `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);
  // console.log(`\n┏━━━ 💡 💡 classSeq 💡 💡 ━━━\n`, classSeq, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);

  const classAverageScore = {
    rwScore: studentList.reduce((acc, cur) => acc + cur.rwScore, 0) / studentList.length,
    mathScore: studentList.reduce((acc, cur) => acc + cur.mathScore, 0) / studentList.length,
  };

  return (
    <>
      <BtoBMainTabFrameHOC>
        <S.Warp className='p_score_main'>
          <OuterBox className='main_wrap'>
            <MainTopArea>
              <SelectClass mode='lecture' />
              <SelectDetailSection variant='class' dataList={currentClassTestData} />
              <MainTab />
            </MainTopArea>

            <div className='flex_row'>
              {/*Scores*/}
              <ScoresChartSection classAverageScore={classAverageScore} />

              {/*Student List*/}
              <StudentListSection studentList={studentList} mode={'lecture'} />

              <AverageWeeklyScores currentClassTestData={currentClassTestData} chartSize={{ width: 460, height: 300 }} />
            </div>
            {/*ADVANCED REPORT*/}

            <AdvancedReport mode={'lecture'} />
          </OuterBox>
        </S.Warp>
      </BtoBMainTabFrameHOC>

      {loading && <LoadingBar />}
    </>
  );
}

const S = {
  Warp: styled.div`
    .main_wrap {
      width: 1194px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
    .flex_row {
      display: flex;
      gap: 0.75rem;
      height: 27.1875rem;
    }
  `,
};
