import {
  SET_PRIVATE_TUTOR_ID,
  SET_PRIVATE_TUTOR_LOGIN_TYPE,
  SET_PRIVATE_TUTOR_USER_EMAIL,
  SET_PRIVATE_TUTOR_USER_MOBILE,
  SET_PRIVATE_TUTOR_USER_NAME,
  SET_PRIVATE_TUTOR_AGREE_PRIVACY_POLICY,
  SET_PRIVATE_TUTOR_AGREE_TERMS_SERVICE,
  SET_PRIVATE_TUTOR_AGREE_NEWS_LETTER,
  SET_PRIVATE_TUTOR_TUTORING_NAME,
  CLEAR_PRIVATE_TUTOR_STATE,
} from './privatetutor.actionTypes';

/** 리듀서 전역 상태 액션 : PRIVATE_TUTOR 아이디 저장 */
export const updateReduxPrivateTutorId = (userId) => ({
  type: SET_PRIVATE_TUTOR_ID,
  payload: userId,
});
/** 리듀서 전역 상태 액션 : PRIVATE_TUTOR 로그인 타입 저장 */
export const updateReduxPrivateTutorLoginType = (loginType) => ({
  type: SET_PRIVATE_TUTOR_LOGIN_TYPE,
  payload: loginType,
});
/** 리듀서 전역 상태 액션 : PRIVATE_TUTOR 이메일 저장 */
export const updateReduxPrivateTutorUserEmail = (userEmail) => ({
  type: SET_PRIVATE_TUTOR_USER_EMAIL,
  payload: userEmail,
});
/** 리듀서 전역 상태 액션 : PRIVATE_TUTOR 전화번호 저장 */
export const updateReduxPrivateTutorUserMobile = (userMobile) => ({
  type: SET_PRIVATE_TUTOR_USER_MOBILE,
  payload: userMobile,
});
/** 리듀서 전역 상태 액션 : PRIVATE_TUTOR 이름 저장 */
export const updateReduxPrivateTutorUserName = (userName) => ({
  type: SET_PRIVATE_TUTOR_USER_NAME,
  payload: userName,
});
/** 리듀서 전역 상태 액션 : PRIVATE_TUTOR 개인정보처리방침 동의 저장 */
export const updateReduxPrivateTutorAgreePrivacyPolicy = (agree) => ({
  type: SET_PRIVATE_TUTOR_AGREE_PRIVACY_POLICY,
  payload: agree,
});
/** 리듀서 전역 상태 액션 : PRIVATE_TUTOR 서비스이용약관 동의 저장 */
export const updateReduxPrivateTutorAgreeTermsService = (agree) => ({
  type: SET_PRIVATE_TUTOR_AGREE_TERMS_SERVICE,
  payload: agree,
});
/** 리듀서 전역 상태 액션 : PRIVATE_TUTOR 뉴스레터 동의 저장 */
export const updateReduxPrivateTutorAgreeNewsLetter = (agree) => ({
  type: SET_PRIVATE_TUTOR_AGREE_NEWS_LETTER,
  payload: agree,
});
/** 리듀서 전역 상태 액션 : PRIVATE_TUTOR TUTURING 이름 저장 */
export const updateReduxPrivateTutorTutoringName = (tutoringName) => ({
  type: SET_PRIVATE_TUTOR_TUTORING_NAME,
  payload: tutoringName,
});
/** 리듀서 전역 상태 액션 : PRIVATE_TUTOR 리듀서 전체 초기화 */
export const clearReduxPrivateTutorState = () => ({
  type: CLEAR_PRIVATE_TUTOR_STATE,
});
