import React from 'react';
import { Link } from 'react-router-dom';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import styled from 'styled-components';

import { nvl } from 'utils/Common.utils';

import usePressESC from 'hooks/usePressESC';
import useScrollLock from 'hooks/useScrollLock';

import { ReactComponent as ImageSVGIconStar } from 'assets/img/svg/icon-star.svg';
import { ReactComponent as ImgSvgX } from 'assets/img/svg/x-close.svg';
import Tooltip01 from 'components/_common/tooltips/Tooltip01';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS } from 'utils/constants';

/** Test list popup */
export default function PopTestList({ setShowModal, data }) {
  /** Query Parameters hook */
  const { setObfuscatedValue } = useObfuscatedSearchParam();

  const handleTrClick = (e, testSeq) => {
    e.preventDefault();
    setObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ, testSeq);
    setShowModal(false);
  };

  usePressESC(() => setShowModal(false));
  useScrollLock();

  return (
    <S.Wrap className='com_popup type_dim pop_test_list active'>
      <div className='pop_container lt'>
        <article className='com_sec_tit'>
          <h2 className='tit'>{data[0]?.className ? `Test List - ${data[0]?.className}` : 'No Test'}</h2>
          <button type='button' title='닫기' onClick={() => setShowModal(false)} className='button_close'>
            <ImgSvgX />
          </button>
        </article>
        <section className='pop_body'>
          <article className='com_list_box'>
            <div className='scroll'>
              <table className='list'>
                <colgroup>
                  <col width='7%' />
                  <col width='16.5%' />
                  <col width='20%' />
                  <col />
                  <col width='10%' />
                  <col width='10%' />
                  <col width='9%' />
                </colgroup>
                <thead>
                  <tr className='sticky top'>
                    <th className='alignC'>No.</th>
                    <th className='alignC'>Test set </th>
                    <th className='alignC'>Class</th>
                    <th className='alignC'>Most recent date taken</th>
                    <th className='alignC'>RW</th>
                    <th className='alignC'>Math</th>
                    <th className='alignC'>View</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, idx) => {
                    return (
                      <tr key={idx} onClick={(e) => handleTrClick(e, item.customSetSeq)}>
                        <td className='test_name'>{data.length - idx}</td>
                        <td className='test_name'>{item.testName}</td>
                        <td className='alignC'>
                          {item.className ? (
                            item.className
                          ) : (
                            <Tooltip01 text='This is a test you have set for yourself.'>
                              <ImageSVGIconStar />
                            </Tooltip01>
                          )}
                        </td>
                        <td className='alignC'>{isoTimeToDayjs(item.testTakenEndDate).format('MMM D, YYYY')}</td>
                        <td className='alignC'>{item.rwScore === 0 ? '-' : item.rwScore}</td>
                        <td className='alignC'>{item.mathScore === 0 ? '-' : item.mathScore}</td>
                        <td className='alignC' onClick={(e) => e.stopPropagation()}>
                          <Link
                            target='_blank'
                            to={`/test/test/view?viewTest=${nvl(item.uthSeqStr) === '' ? encodeURIComponent(item.customSetSeqStr) : encodeURIComponent(item.uthSeqStr)}`}
                            className='svg_icon icon_view black'>
                            &nbsp;
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </article>
        </section>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .com_sec_tit {
      border-bottom: none;
      padding-bottom: 0;
    }
    .button_close {
      color: #111111;
      svg {
        width: 1.5rem;
      }
    }
    .com_list_box {
      .list {
        tr {
          border-bottom: 1px solid #f4f4fc;
          th {
            padding: 0.875rem 0.75rem;
            background-color: #f8f8f9;
          }
          &:hover {
            td {
              background-color: #f5f8ff;
            }
          }
        }
      }
    }
  `,
};
