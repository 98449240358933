import React from 'react';
import styled from 'styled-components';
import GradationLine from 'components/user/_components/common/styles/GradationLine';

/** "회원 가입" 프로세스의 헤더 영역 */
export default function Header({ title }) {
  return (
    <S.Header>
      <p>Become a GATE + </p>
      <h3>{title}</h3>
      <GradationLine />
    </S.Header>
  );
}

const S = {};

S.Header = styled.header`
  margin-bottom: 1.5rem;
  p {
    font-size: 1rem;
    font-weight: 500;
    color: #7f8998;
    text-transform: uppercase;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--point-color);
    text-transform: uppercase;
    margin: 0.375rem 0 1.25rem;
  }
`;
