import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconCheck } from 'assets/img/svg/icon_radio_chk.svg';

/** "View GATEPLUS Average" 체크박스 스타일 용 컴포넌트 */
export default function CheckViewAboutAverageOfGateplus({ checkState, handleChange, className = '', ...rest }) {
  return (
    <S.Wrap className={`${checkState ? 'active' : ''} ${className}`}>
      <input type='checkbox' id='checkboxViewGateplusAverage' checked={checkState} onChange={handleChange} />
      <label htmlFor='checkboxViewGateplusAverage'>
        View GATEPLUS Average{' '}
        <div className='checkbox_icon'>
          <ImgSvgIconCheck />
        </div>
      </label>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    input {
      width: 0;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
    label {
      cursor: pointer;
      font-weight: 400;
      font-size: 0.875rem;
      color: #111111;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
    .checkbox_icon {
      background-color: #ffffff;
      border: 1px solid #d7dadf;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.25rem;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 75%;
      }
    }

    &.active {
      .checkbox_icon {
        background-color: #0068bd;
        border-color: #0068bd;
        color: #ffffff;
      }
    }
  `,
};
