import React, { useState, useEffect, useRef } from 'react';

import dayjsTZ, { dateStringToDayjs } from 'utils/functions/time/dayjs-config';
import { useReactToPrint } from 'react-to-print';

//img
import imgLogo from 'assets/img/logo.png';

import request from 'utils/Request.utils.js';
import SessionStorage from 'utils/SessionStorage.utils';
import { calculatePercentage, nvl, nvlNumber } from 'utils/Common.utils.js';
import useUserLevels from 'hooks/useUserLevels';

import ChartWithScoreContainer from './_components/ChartWithScoreContainer';
import BasicColumnChart from 'components/_common/chart/BasicColumnChart';

import GateplusLog from 'assets/img/logo.png';

// 🔍 검색 키워드 - #Report #Card #PDF
/** 특정 시험에 대한 정보를 보여주는 페이지 - pdf 인쇄 기능 있음 (Student Dashboard, Score 페이지 등에서 공통으로 사용)  */
export default function ReportCardPage() {
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 시작 ////////////
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 끝 /////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const todayDate = dayjsTZ().format('YYYYMMDD');

  const reportCard = SessionStorage.getItemJsonParse('reportCard');
  const uthSeq = reportCard?.uthSeq || 0;
  const userSeq = reportCard?.userSeq || 0;
  const classSeq = reportCard?.classSeq || 0;

  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** Report Card data */
  const [testResult, setTestResult] = useState({
    userName: '',
    studentGrade: '',
    questionList: [],
    questionAccuracyList: [],
    trhDate: '',

    academyUpFileSeq: 0,
    className: '',
    classSeq: 0,
    customSetSeq: 0,
    difficulty: '',
    ecSeq: 0,
    subject: '',
    testName: '',
    userSeq: 0,
  });

  const [barChartData, setBarChartData] = useState([
    {
      data: [0, 0, 0, 0],
      gatePlusData: [0, 0, 0, 0],
    },
  ]);
  const [barChartData2, setBarChartData2] = useState([
    {
      data: [0, 0, 0, 0],
      gatePlusData: [0, 0, 0, 0],
    },
  ]);
  const [timePerQuestionData, setTimePerQuestionData] = useState({
    series: [
      {
        name: 'You',
        data: [],
        color: '#0068BD',
      },
      {
        name: 'GATE⁺',
        data: [],
        color: '#E3E7ED',
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const xAxisValue = w.globals.labels[dataPointIndex];
          return `<div className="custom-tooltip">Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        fontFamily: 'Roboto, Arial, sans-serif',
        markers: {
          width: 25,
          height: 8,
          radius: 8,
        },
        itemMargin: {
          horizontal: 15,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.7,
          },
        },
      },
    },
  });
  const [filterValue, setFilterValue] = useState({
    uthSeq: 0,
    userSeq: 0,
    customSetSeq: 0,
    classSeq: 0,
    moduleNum: '',
    tmSubject: '',
    tmDifficulty: '',
    color: '#008CFF',
    subjectOption: false,
    moduleNumOption: false,
    className: 'custom-tooltip',
  });
  const [sortedQuestionList, setSortedQuestionList] = useState([]);
  const [perSubject, setPerSubject] = useState(false);
  const [perModuleNum, setPerModuleNum] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const componentRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const cas = Math.floor(barChartData[0].data[0] / 14);
  const iai = Math.floor(barChartData[0].data[1] / 14);
  const sec = Math.floor(barChartData[0].data[2] / 14);
  const eoi = Math.floor(barChartData[0].data[3] / 14);

  const alg = Math.floor(barChartData2[0].data[0] / 14);
  const am = Math.floor(barChartData2[0].data[1] / 14);
  const psada = Math.floor(barChartData2[0].data[2] / 14);
  const gat = Math.floor(barChartData2[0].data[3] / 14);

  /** 시험 점수 정오답 데이터 얻어오기 API 요청 */
  const getTestPointOX = ({ uthSeq, useLevels }) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const { testInfo, eDiff, mDiff, mathPoint, mathRank, rwPoint, rwRank } = response.result;
        setTestResult((prev) => {
          return {
            ...prev,
            ...testInfo,
            mathDiff: mDiff,
            engDiff: eDiff,
            mathCurrentScore: mathPoint,
            mathCurrentRank: 100 - mathRank,
            rwCurrentScore: rwPoint,
            rwCurrentRank: 100 - rwRank,
          };
        });
        setFilterValue((prev) => {
          return { ...prev, uthSeq: uthSeq, color: '#008CFF' };
        });
        if (useLevels.isAcademy) {
          // 아카데미 회원 일 경우
          getCategoriesRateInAcademyDashboardScore(uthSeq);
        }
        if (useLevels.isStudent) {
          // 학생 회원 일 경우
          getCategoriesRateInStudentDashboard(uthSeq);
        }
      }
    };

    request.get(`/api/dsat/history/test/score?uthSeq=${uthSeq}`, null, successHandler);
  };
  /** 학생 대시보드에서 접근 했을 시, 카테고리 별 점수 통계 얻어오기 API 요청 */
  const getCategoriesRateInStudentDashboard = (uthSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const personal = response.result.studentTestTypeCorrectRate;
        const overall = response.result.gateplusTestTypeCorrectRate;

        if (personal != null && personal?.length > 0) {
          // 학생 개인 통계
          /** 학생 영어 통계 */
          const personalReadingAndWriting = personal.filter((item) => item.subject === 'E')[0];
          /** 학생 수학 통계 */
          const personalMath = personal.filter((item) => item.subject === 'M')[0];
          /** 전체 평균 영어 통계 */
          const overallReadingAndWriting = overall.filter((item) => item.subject === 'E')[0];
          /** 전체 평균 수학 통계 */
          const overallMath = overall.filter((item) => item.subject === 'M')[0];

          const CAS = personalReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'CAS');
          const IAI = personalReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'IAI');
          const SEC = personalReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'SEC');
          const EOI = personalReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'EOI');

          const ALG = personalMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'Alg');
          const AM = personalMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'AM');
          const PSADA = personalMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'PSADA');
          const GAT = personalMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'GAT');

          const CAS_ = overallReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'CAS');
          const IAI_ = overallReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'IAI');
          const SEC_ = overallReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'SEC');
          const EOI_ = overallReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'EOI');

          const ALG_ = overallMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'Alg');
          const AM_ = overallMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'AM');
          const PSADA_ = overallMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'PSADA');
          const GAT_ = overallMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'GAT');

          setBarChartData((prev) => [
            {
              ...prev,
              data: [nvlNumber(CAS[0]?.correctRate), nvlNumber(IAI[0]?.correctRate), nvlNumber(SEC[0]?.correctRate), nvlNumber(EOI[0]?.correctRate)],
              gatePlusData: [
                nvlNumber(CAS_[0]?.correctRate),
                nvlNumber(IAI_[0]?.correctRate),
                nvlNumber(SEC_[0]?.correctRate),
                nvlNumber(EOI_[0]?.correctRate),
              ],
            },
          ]);
          setBarChartData2((prev) => [
            {
              ...prev,
              data: [nvlNumber(ALG[0]?.correctRate), nvlNumber(AM[0]?.correctRate), nvlNumber(PSADA[0]?.correctRate), nvlNumber(GAT[0]?.correctRate)],
              gatePlusData: [
                nvlNumber(ALG_[0]?.correctRate),
                nvlNumber(AM_[0]?.correctRate),
                nvlNumber(PSADA_[0]?.correctRate),
                nvlNumber(GAT_[0]?.correctRate),
              ],
            },
          ]);
        }

        getModuleAnswer(uthSeq);
      }
    };

    request.get(`/api/v2/student/dashboard/test/score-by-category?uthSeq=${uthSeq}`, null, successHandler);
  };
  /** 아카데미 대시보드 - Score에서 접근 했을 시, 카테고리 별 점수 통계 얻어오기 API 요청 */
  const getCategoriesRateInAcademyDashboardScore = (uthSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const personal = response.result.studentTestTypeCorrectRate;
        const overall = response.result.gateplusTestTypeCorrectRate;

        if (personal != null && personal?.length > 0) {
          // 학생 개인 통계
          /** 학생 영어 통계 */
          const personalReadingAndWriting = personal.filter((item) => item.subject === 'E')[0];
          /** 학생 수학 통계 */
          const personalMath = personal.filter((item) => item.subject === 'M')[0];
          /** 전체 평균 영어 통계 */
          const overallReadingAndWriting = overall.filter((item) => item.subject === 'E')[0];
          /** 전체 평균 수학 통계 */
          const overallMath = overall.filter((item) => item.subject === 'M')[0];

          const CAS = personalReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'CAS');
          const IAI = personalReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'IAI');
          const SEC = personalReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'SEC');
          const EOI = personalReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'EOI');

          const ALG = personalMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'Alg');
          const AM = personalMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'AM');
          const PSADA = personalMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'PSADA');
          const GAT = personalMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'GAT');

          const CAS_ = overallReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'CAS');
          const IAI_ = overallReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'IAI');
          const SEC_ = overallReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'SEC');
          const EOI_ = overallReadingAndWriting.categoriesRate?.filter((item) => item.dispContentDomainName === 'EOI');

          const ALG_ = overallMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'Alg');
          const AM_ = overallMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'AM');
          const PSADA_ = overallMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'PSADA');
          const GAT_ = overallMath.categoriesRate?.filter((item) => item.dispContentDomainName === 'GAT');

          setBarChartData((prev) => [
            {
              ...prev,
              data: [nvlNumber(CAS[0]?.correctRate), nvlNumber(IAI[0]?.correctRate), nvlNumber(SEC[0]?.correctRate), nvlNumber(EOI[0]?.correctRate)],
              gatePlusData: [
                nvlNumber(CAS_[0]?.correctRate),
                nvlNumber(IAI_[0]?.correctRate),
                nvlNumber(SEC_[0]?.correctRate),
                nvlNumber(EOI_[0]?.correctRate),
              ],
            },
          ]);
          setBarChartData2((prev) => [
            {
              ...prev,
              data: [nvlNumber(ALG[0]?.correctRate), nvlNumber(AM[0]?.correctRate), nvlNumber(PSADA[0]?.correctRate), nvlNumber(GAT[0]?.correctRate)],
              gatePlusData: [
                nvlNumber(ALG_[0]?.correctRate),
                nvlNumber(AM_[0]?.correctRate),
                nvlNumber(PSADA_[0]?.correctRate),
                nvlNumber(GAT_[0]?.correctRate),
              ],
            },
          ]);
        }

        getModuleAnswer(uthSeq);
      }
    };

    request.get(`/api/v2/score/student/category-rate?uthSeq=${uthSeq}`, null, successHandler);
  };

  const getModuleAnswer = (uthSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let questionList = response.result.questionList;

        setTestResult((prev) => {
          return {
            ...prev,
            questionList: questionList,
            trhDate: questionList[questionList.length - 1]?.trhRegDate,
            userName: questionList[0]?.userName,
            studentGrade: questionList[0]?.studentGrade,
          };
        });
        accuracyByQuestion(uthSeq);
      }
    };

    request.get(`/api/exam/moduleAnswer?uthSeq=${uthSeq}`, null, successHandler);
  };

  const accuracyByQuestion = (uthSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let questionAccuracyList = response.result.questionAccuracyList;

        setTestResult((prev) => {
          return { ...prev, questionAccuracyList: questionAccuracyList };
        });
      }
    };

    request.get(`/api/dsat/accuracy/question?uthSeq=${uthSeq}`, null, successHandler);
  };

  const timePerQuestion = (uthSeq) => {
    let pms = {
      userSeq: userSeq,
      moduleNum: filterValue.moduleNum,
      subject: filterValue.tmSubject,
      difficulty: nvl(filterValue.moduleNum === '1' ? '' : filterValue.tmSubject !== 'E' ? testResult.mathDiff : testResult.engDiff),
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let gatePlusDurationList = response.result.personalDurationListAll;
        let personalDurationList = response.result.personalDurationList;

        const youUseSeconds = personalDurationList?.map((item) => item.useSeconds);
        const gateUseSeconds = gatePlusDurationList?.map((item) => item.secAvg);

        if (youUseSeconds && youUseSeconds != null) {
          setTimePerQuestionData((prevData) => ({
            ...prevData,
            series: [
              { name: 'You', data: youUseSeconds, color: filterValue.color },
              { name: 'GATE⁺', data: gateUseSeconds, color: '#E3E7ED' },
            ],
            options: {
              ...prevData.options,
              xaxis: {
                categories: youUseSeconds?.map((_, index) => index + 1),
              },
              tooltip: {
                custom: function ({ seriesIndex, dataPointIndex, w }) {
                  const xAxisValue = w.globals.labels[dataPointIndex];
                  const seriesName = w.config.series[seriesIndex]['name'].toUpperCase();

                  if (seriesName === 'YOU' && seriesIndex === 0) {
                    return `<div class=${filterValue.className}>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
                  } else if (seriesName === 'GATE⁺' && seriesIndex === 1) {
                    return `<div class='custom-tooltip-tests'>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
                  }
                },
              },
            },
          }));
        }
      }
    };

    request.get(
      `/api/dsat/duration/question?uthSeq=${uthSeq}&userSeq=${pms.userSeq}&moduleNum=${pms.moduleNum}&subject=${pms.subject}&difficulty=${pms.difficulty}`,
      null,
      successHandler
    );
  };

  const perSubActive = () => {
    setPerSubject((prev) => !prev);
    setPerModuleNum(false);
  };

  const perNumActive = () => {
    setPerSubject(false);
    setPerModuleNum((prev) => !prev);
  };

  const selectedTimeSubject = () => {
    return filterValue.tmSubject === 'E' ? 'RW' : filterValue.tmSubject === 'M' ? 'MATH' : '-';
  };

  const selectedTimeModuleNum = () => {
    return filterValue.moduleNum === '1' ? 'Module 1' : filterValue.moduleNum === '2' ? 'Module 2' : '-';
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${testResult.testName}성적표_${todayDate}_${testResult?.userName}`,
  });

  const renderProfileImage = () => {
    let profileImageURL = '';
    let academyProfileImageURL = '';

    profileImageURL = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${userInfo?.userSeq}?${Date.now()}`;
    academyProfileImageURL = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/academyProfile/${testResult?.ecSeq}?${Date.now()}`;

    if (classSeq === 0) {
      // 사용자 프로필 이미지 렌더링
      return (
        <img
          className='profile-img-background'
          src={profileImageURL}
          alt='프로필이미지'
          onError={(e) => (e.target.src = GateplusLog)}
          style={{ objectFit: 'scale-down' }}
        />
      );
    } else {
      // 학원 프로필 이미지 렌더링
      if (testResult.academyUpFileSeq) {
        return <img className='profile-img-background' src={academyProfileImageURL} alt='academy-profile' />;
      } else {
        return <span className='name'>{testResult?.companyName[0]}</span>;
      }
    }
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 ////////////////
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    if (!useLevelsIsLoading && uthSeq) {
      // 사용자 유형 얻어오기 로딩이 완료 되면 실행
      getTestPointOX({ uthSeq, useLevels });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLevelsIsLoading, useLevels]);

  useEffect(() => {
    if (testResult.questionList != null && testResult.questionList.length > 0) {
      setSortedQuestionList(testResult.questionList.sort((a, b) => a.questionOrder - b.questionOrder));
    }

    if (testResult.subject === 'M') setFilterValue((prev) => ({ ...prev, tmSubject: 'M', moduleNum: '1' }));
    else {
      setFilterValue((prev) => ({
        ...prev,
        tmSubject: 'E',
        moduleNum: '1',
        color: '#008CFF',
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testResult]);

  useEffect(() => {
    if (filterValue.uthSeq > 0) timePerQuestion(filterValue.uthSeq);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue.tmSubject, filterValue.uthSeq, filterValue.moduleNum]);

  useEffect(() => {
    document.body.classList.add('p_report_cart'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('p_report_cart'); //다른 페이지에선 removeClass
    };
  }, []);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <div className='pop_report com_center_wrap'>
      <div className='pop_report_print' ref={(el) => (componentRef.current = el)}>
        {/*상단 로고*/}
        <h1 className='top'>Report Card</h1>
        <div className='box'>
          {/*시험응시자 정보*/}
          <section className='student_sec'>
            <h2 className='tit'>Your Scores</h2>
            <ul className='info_list'>
              <li className='tit_field'>NAME</li>
              <li className='info_field'>{testResult?.userName}</li>
              <li className='tit_field'>Grade</li>
              <li className='info_field'>{testResult?.studentGrade}</li>
              <li className='tit_field'>Tested on</li>
              <li className='info_field'>{dateStringToDayjs(testResult.trhDate, 'YYYY-MM-DD HH:mm:ss').format('MMM D, YYYY')}</li>
              <li className='tit_field'>Test Set Name</li>
              <li className='info_field'>{testResult.testName}</li>
            </ul>
            <div className='com_profile_img T big-profile-img logo'>{(testResult.ecSeq > 0 || testResult.userSeq > 0) && renderProfileImage()}</div>
          </section>
          {/*점수*/}
          <section className='score_sec'>
            <article className='container left'>
              <h3 className='tit'>SAT Scores</h3>
              <div className='info'>
                <h4 className='tit_field'>Section Scores</h4>
                <p>* Percentiles represent the percent of SAT test takers in Gate+ who scored equal to or lower than you.</p>
                <ul className='info_area'>
                  <li className='field'>
                    <p className='subject'>Reading and Writing</p>
                    <ChartWithScoreContainer
                      testResultCurrentScore={testResult.rwCurrentScore}
                      testResultCurrentRank={testResult.rwCurrentRank}
                      ScoreRange={['200', '800']}
                      CustomLabel={`${Math.min(testResult.rwCurrentRank ?? 0, 99)}%`}
                    />
                  </li>
                  <li className='field'>
                    <p className='subject'>Math</p>
                    <ChartWithScoreContainer
                      testResultCurrentScore={testResult.mathCurrentScore}
                      testResultCurrentRank={testResult.mathCurrentRank}
                      ScoreRange={['200', '800']}
                      CustomLabel={`${Math.min(testResult.mathCurrentRank ?? 0, 99)}%`}
                    />
                  </li>
                </ul>
              </div>
            </article>
            <article className='container right'>
              <div className='total'>
                <h3 className='tit'>Total Score</h3>
                <div className='info'>
                  <ul className='info_area'>
                    <li className='field'>
                      <ChartWithScoreContainer
                        testResultCurrentScore={nvlNumber(testResult.rwCurrentScore) + nvlNumber(testResult.mathCurrentScore)}
                        testResultCurrentRank={
                          testResult?.subject === 'F'
                            ? (testResult.mathCurrentRank + testResult.rwCurrentRank) / 2
                            : nvlNumber(testResult.rwCurrentRank) + nvlNumber(testResult.mathCurrentRank)
                        }
                        ScoreRange={[testResult?.subject === 'F' ? '400' : '200', testResult?.subject === 'F' ? '1600' : '800']}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              {/*안내글*/}
              <div className='notice_box'>
                <h3 className='tit_field'>Knowledge and Skills</h3>
                <p className='txt'>View your performance across the 8 content domains measured on the SAT.</p>
              </div>
              {/*skills*/}
              <div className='skill_sec'>
                <section className='inner'>
                  <h4 className='subject'>Reading and Writing</h4>
                  <article className='item'>
                    <h5 className='skill'>Craft and Structure</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < cas ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade1'>{barChartData[0].data[0]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Information and Ideas</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < iai ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade1'>{barChartData[0].data[1]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Standard English Conventions</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < sec ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade3'>{barChartData[0].data[2]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Expression of Ideas</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < eoi ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade4'>{barChartData[0].data[3]}%</p>
                  </article>
                </section>
                <section className='inner'>
                  <h4 className='subject'>Math</h4>
                  <article className='item'>
                    <h5 className='skill'>Algebra</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < alg ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade5'>{barChartData2[0].data[0]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Advanced Math</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < am ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade6'>{barChartData2[0].data[1]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Problem-Solving and Data Analysis</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < psada ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade7'>{barChartData2[0].data[2]}%</p>
                  </article>
                  <article className='item'>
                    <h5 className='skill'>Geometry and Trigonometry</h5>
                    <div className='graph'>
                      {Array.from({ length: 7 }, (_, index) => (
                        <i className={`bar ${index < gat ? 'active' : ''}`} key={index}>
                          &nbsp;
                        </i>
                      ))}
                    </div>
                    <p className='per grade4'>{barChartData2[0].data[3]}%</p>
                  </article>
                </section>
              </div>
            </article>
          </section>
        </div>
        {/*안내글*/}
        <section className='notice_box'>
          <h3 className='tit_field'>Your Answers</h3>
          <p className='txt'>Check which question you have gotten correct or wrong and view the correct answer choice. </p>
        </section>
        {/*정답여부*/}
        <section className='answer_sec'>
          {testResult.subject !== 'M' && (
            <>
              <h4 className='subject'>Reading and Writing</h4>
              <table className='table'>
                <thead>
                  <tr>
                    <th className='first'>Module1</th>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <th key={index}>{item.questionOrder}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Answer</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <td key={index}>{item.correctAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Your Choice</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <td key={index}>{item.tryAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Result</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'E')
                      .map((item, index) => {
                        /** 정오답 여부 */
                        const isCorrect = item.correctAnswerCnt;

                        return <td key={index}>{isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}</td>;
                      })}
                  </tr>
                  <tr>
                    <th className='first type2'>Module 2</th>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <th key={index}>{item.questionOrder}</th>
                      ))}
                  </tr>
                  <tr>
                    <td>Answer</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <td key={index}>{item.correctAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Your Choice</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'E')
                      .map((item, index) => (
                        <td key={index}>{item.tryAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Result</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'E')
                      .map((item, index) => {
                        /** 정오답 여부 */
                        const isCorrect = item.correctAnswerCnt;

                        return <td key={index}>{isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}</td>;
                      })}
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {testResult.subject !== 'E' && (
            <>
              <h4 className='subject'>Math</h4>
              <table className='table'>
                <thead>
                  <tr>
                    <th className='first'>Module1</th>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <th key={index}>{item.questionOrder}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Answer</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <td key={index}>{item.correctAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Your Choice</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <td key={index}>{item.tryAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Result</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'M')
                      .map((item, index) => {
                        /** 정오답 여부 */
                        const isCorrect = item.correctAnswerCnt;

                        return <td key={index}>{isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}</td>;
                      })}
                  </tr>
                  <tr>
                    <th className='first type2'>Module 2</th>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '1' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <th key={index}>{item.questionOrder}</th>
                      ))}
                  </tr>
                  <tr>
                    <td>Answer</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <td key={index}>{item.correctAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Your Choice</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'M')
                      .map((item, index) => (
                        <td key={index}>{item.tryAnswer}</td>
                      ))}
                  </tr>
                  <tr>
                    <td>Result</td>
                    {sortedQuestionList
                      .filter((item) => item.moduleNum === '2' && item.tmSubject === 'M')
                      .map((item, index) => {
                        /** 정오답 여부 */
                        const isCorrect = item.correctAnswerCnt;

                        return <td key={index}>{isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}</td>;
                      })}
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </section>
        <section className='copy'>
          <p className='txt'>Copyright © 2023 Gate+. All rights reserved.</p>
          <img src={imgLogo} alt='logo' className='logo' />
        </section>
      </div>
      {/*차트*/}
      <section className='chart_sec'>
        <article className='tit_box'>
          <h3 className='tit'>Time per Question</h3>
          <div className={`com_select_layer ${perSubject ? 'active' : ''}`} onClick={perSubActive}>
            {/*클래스 active 포함 시 옵션 노출*/}
            {/*클래스 active 포함 시 옵션 노출*/}
            <section className='selected__wrap'>
              <input type='text' className='selected' placeholder={selectedTimeSubject()} size='5' />
            </section>
            <section className='layer'>
              {testResult.subject === 'F' && (
                <>
                  <p
                    className='option'
                    onClick={() =>
                      setFilterValue((prev) => {
                        return {
                          ...prev,
                          tmSubject: 'E',
                          moduleNum: '1',
                          color: '#008CFF',
                          className: 'custom-tooltip',
                        };
                      })
                    }>
                    RW
                  </p>
                  <p
                    className='option'
                    onClick={() =>
                      setFilterValue((prev) => {
                        return {
                          ...prev,
                          tmSubject: 'M',
                          moduleNum: '1',
                          color: '#1FDF9F',
                          className: 'custom-tooltip-gate',
                        };
                      })
                    }>
                    MATH
                  </p>
                </>
              )}
              {testResult.subject === 'E' && <p className='option'>RW</p>}
              {testResult.subject === 'M' && <p className='option'>MATH</p>}
            </section>
          </div>
          <div className={`com_select_layer type_tit ${perModuleNum ? 'active' : ''}`} onClick={perNumActive}>
            {/*클래스 active 포함 시 옵션 노출*/}
            <section className='selected__wrap'>
              {/* <p className="tit_field">Module</p> */}
              <input type='text' className='selected' placeholder={selectedTimeModuleNum()} size='8' />
            </section>
            <section className='layer'>
              <p
                className='option'
                onClick={() =>
                  setFilterValue((prev) => {
                    return { ...prev, moduleNum: '1' };
                  })
                }>
                Module 1
              </p>
              <p
                className='option'
                onClick={() =>
                  setFilterValue((prev) => {
                    return { ...prev, moduleNum: '2' };
                  })
                }>
                Module 2
              </p>
            </section>
          </div>
        </article>
        <article>
          <BasicColumnChart timePerQuestionData={timePerQuestionData} />
        </article>
      </section>
      {/*하단버튼*/}
      <section className='com_btn_wrap bottom'>
        <button className='com_btn l point' onClick={handlePrint}>
          Print
        </button>
      </section>
    </div>
  );
}
