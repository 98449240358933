import { useRef } from 'react';
import { checkKoreanAlphabet, nvl } from 'utils/Common.utils';
import localStorage from 'utils/LocalStorage.utils';

/** 문제 선택 (미니 창) 컴포넌트 */
export default function MiniReviewPage({ stateExamInfo, setStateExamInfo, questionInfo = [], closePopup }) {
  const miniReviewRef = useRef();

  // 특정 문제로 이동하는 핸들러
  const gotoChoiceQuestion = (num) => {
    setStateExamInfo((prev) => ({ ...prev, currentNum: num, isReviewPopupOpen: false }));
    localStorage.setItem(`apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`, 'currentNum', num);
  };

  const gotoBigReview = () => {
    setStateExamInfo((prev) => ({ ...prev, isReviewPopupOpen: true }));
    closePopup();
  };

  const testInfo = localStorage.getItemJsonParse(`apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`);

  return (
    <article ref={miniReviewRef} className='question_layer layer'>
      <button className='btn_layer_close' onClick={closePopup}>
        <i className='svg_icon btn_pop_close black'>&nbsp;</i>
      </button>
      <div className='tit_field'>
        {testInfo.sectionName} {testInfo.partName} <br /> Question
      </div>
      <button className='btn_close'>
        <i className='svg_icon icon_delete'>&nbsp;</i>
      </button>
      <div className='info_field'>
        <div className='item'>
          <i className='svg_icon icon_current'>&nbsp;</i>Current
        </div>
        <div className='item'>
          <i className='svg_icon icon_unanswered'>&nbsp;</i>Unanswered
        </div>
        <div className='item'>
          <i className='svg_icon icon_bookmark'>&nbsp;</i>For Review
        </div>
      </div>
      <div className='question_field'>
        {/* 문제 목록 렌더링 */}
        {Array.from({ length: stateExamInfo.totalQuestion }, (_, index) => {
          const questionOrder = index + 1;
          const question = questionInfo?.find((q) => q.questionOrder === questionOrder) || {};
          const customQuestionOrder = questionInfo[index]?.customQuestionOrder;

          return (
            <button
              key={`mini_${questionOrder}`}
              className={`item
                ${customQuestionOrder.length === 1 ? 'single_char' : ''}
                ${stateExamInfo.currentNum === questionOrder ? 'current' : ''}
                ${questionInfo[index]?.selectAnswer.length > 0 || nvl(questionInfo[index]?.answerContent?.replace(/<[^>]*>/g, '').trim()) != '' ? 'answered' : ''}
                ${questionInfo[index]?.isBookmarked ? 'review' : ''}
              `}
              onClick={() => gotoChoiceQuestion(questionOrder)}>
              {customQuestionOrder}
            </button>
          );
        })}
      </div>
      <div className='btn_field'>
        <button className='btn' onClick={gotoBigReview}>
          Go to Review Page
        </button>
      </div>
    </article>
  );
}
