/** ap/dashboard 페이지에서 사용하는 파라미터 쿼리 관리 상수 */
export const AP_PARAMS = {
  isAcademy: {
    KEY: 'isAcademy',
    value: {
      TRUE: 'true',
      FALSE: 'false',
    },
  },
  UTH_SEQ: {
    // 현재 선택 된 시험 세트의 UTH Seq
    KEY: 'uthSeq',
  },
  SECTION_SEQ: {
    // 현재 선택 된 시험의 section Seq
    KEY: 'sectionSeq',
  },
  SECTION_NUM: {
    // 현재 선택 된 시험의 section 번호
    KEY: 'sectionNumber',
  },
  PART_SEQ: {
    // 현재 선택 된 시험의 part Seq
    KEY: 'partSeq',
  },
  PART_NUM: {
    // 현재 선택 된 시험의 part 번호
    KEY: 'partNumber',
  },
  QUESTION_ORDER: {
    // 현재 선택 된 시험의 question Order
    KEY: 'questionOrder',
  },
};
