import 'rangy/lib/rangy-classapplier';

/** 문제 영역 컴포넌트 */
const QuestionDirection = ({ sourceDirections }) => {
  const passageAreaStyle = {
    WebkitUserSelect: 'text',
    MozUserSelect: 'text',
    MsUserSelect: 'text',
    userSelect: 'text',
  };

  return (
    <>
      <div style={passageAreaStyle} className='question' dangerouslySetInnerHTML={{ __html: sourceDirections }} />
      {/* <Tooltip ref={tooltipRef} /> */}
    </>
  );
};

export default QuestionDirection;
