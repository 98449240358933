//#region    ////////// components
import EquationsPanel from './_components/EquationsPanel';
import EquationsPanelContentContainer from './_components/EquationsPanelContentContainer';
import EquationsList from './_components/EquationsList';
//#endregion ////////// components

//#region    ////////// assets
import descriptiveStatistics1 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/descriptive_statistics_1.png';
import probabilitiesDistributions1 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/probabilities_distributions_1.png';
import probabilitiesDistributions2 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/probabilities_distributions_2.png';
import samplingDistributionsInferentialStatistics1 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/sampling_distributions_inferential_statistics_1.png';
import samplingDistributionsInferentialStatistics2 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/sampling_distributions_inferential_statistics_2.png';
import samplingDistributionsInferentialStatistics3 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/sampling_distributions_inferential_statistics_3.png';
import samplingDistributionsInferentialStatistics4 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/sampling_distributions_inferential_statistics_4.png';
import samplingDistributionsInferentialStatistics5 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/sampling_distributions_inferential_statistics_5.png';
import samplingDistributionsInferentialStatistics6 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/sampling_distributions_inferential_statistics_6.png';
//#endregion ////////// assets

/** Statistics 레퍼런스 시트 수식 컴포넌트 */
export default function StatisticsEquations() {
  return (
    <EquationsList>
      <EquationsPanel
        panelHeaderText='Descriptive Statistics'
        PanelContent={<EquationsPanelContentContainer contents={[descriptiveStatistics1]} containerHeight='24rem' />}
      />
      <EquationsPanel
        panelHeaderText='Probabilities and Distributions'
        PanelContent={<EquationsPanelContentContainer contents={[probabilitiesDistributions1, probabilitiesDistributions2]} containerHeight='40rem' />}
      />
      <EquationsPanel
        panelHeaderText='Sampling Distributions and Inferential Statistics'
        PanelContent={
          <EquationsPanelContentContainer
            contents={[
              samplingDistributionsInferentialStatistics1,
              samplingDistributionsInferentialStatistics2,
              samplingDistributionsInferentialStatistics3,
              samplingDistributionsInferentialStatistics4,
              samplingDistributionsInferentialStatistics5,
              samplingDistributionsInferentialStatistics6,
            ]}
            containerHeight='90rem'
          />
        }
      />
    </EquationsList>
  );
}
