import { useEffect, useState } from 'react';
import styled from 'styled-components';
import SectionScaledScoreArea from './_components/SectionArea';
import TotalScaledScoreArea from './_components/TotalSectionArea';

/** ap/com/score/grading/submission 페이지의 "전체 등급 점수 영역" 컴포넌트 */
export default function ScaledScoreArea({ sectionInfo }) {
  const [totalScore, setTotalScore] = useState({ correctRawScore: 0, totalRawScore: 0, totalWeightedScore: 0 });

  useEffect(() => {
    if (sectionInfo && sectionInfo.length > 0) {
      const totalCorrect = sectionInfo.reduce((acc, curr) => acc + (curr.correctRawScore || 0), 0);
      const totalRaw = sectionInfo.reduce((acc, curr) => acc + (curr.totalRawScore || 0), 0);

      // 모든 section의 partList에 대해, 가중 점수를 계산 후 합산
      const totalWeighted = sectionInfo.reduce((accSection, section) => {
        if (!section.partList) return accSection; // partList가 없으면 패스

        // 섹션 내 모든 파트에 대해 가중 점수를 구해서 합산
        const partWeightedSum = section.partList.reduce((accPart, part) => {
          // rawScore나 totalScore가 없거나 totalScore가 0이면 계산 불가하므로 0 처리
          if (part.rawScore == null || !part.totalScore) {
            return accPart;
          }
          // 가중치 = weightOfPart * (rawScore / totalScore)
          const weighted = part.weightOfPart * (part.rawScore / part.totalScore);
          return accPart + weighted;
        }, 0);

        return accSection + partWeightedSum;
      }, 0);

      setTotalScore({ correctRawScore: totalCorrect, totalRawScore: totalRaw, totalWeightedScore: totalWeighted.toFixed(2) });
    }
  }, [sectionInfo]);

  return sectionInfo && sectionInfo[0].sectionSeq === 0 ? (
    'sectionInfo no data'
  ) : (
    <S.Wrap>
      {sectionInfo && sectionInfo.map((item, index) => <SectionScaledScoreArea key={index} sectionInfo={item} />)}
      <TotalScaledScoreArea totalScore={totalScore} sectionInfo={sectionInfo} />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    width: 22.375rem;
    height: fit-content;
    border-radius: 0.625rem;
    border: 1px solid #c5ccd2;
    background: #f8f8f9;
    overflow: hidden;
    .section_scaled_score_area {
      & + .section_scaled_score_area {
        .inner_wrap {
          border-top: 1px solid #c5ccd2;
        }
      }
    }
  `,
};
