import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';

import request from 'utils/Request.utils';

//components
import CommonLoadingAnimation from 'components/_common/CommonLoadingAnimation';
import MiniCalendarSection from 'components/academy/dashboard/_components/MiniCalendarSection';
import ApScoreSection from 'components/ap/_components/sections/apScoreSection/ApScoreSection';
import IndividualQuestionResultsSection from 'components/ap/_components/sections/individualQuestionResultsSection/IndividualQuestionResultsSection';
import RowScoreSection from 'components/ap/_components/sections/rowScoreSection/RowScoreSection';
import { AP_PARAMS } from 'components/ap/_utils/constants/queryParamsString';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import Box from './_components/common/Box';
import OuterBox from './_components/common/OuterBox';
import TimeLineSection from './_components/layouts/sections/setTimeLine/TimeLineSection';
import TestList from './_components/layouts/sections/testList/TestList';
import UpcomingTestSection from './_components/layouts/sections/upcomingTest/UpcomingTestSection';
import VisualArea from './_components/layouts/visualArea/VisualArea';
import VisualTextArea from './_components/layouts/visualArea/VisualTextArea';

/** 대시보드 페이지 (Student, Parent 라우트에서 공유하는 페이지) */
export default function ApDashboardPage() {
  const { setBulkObfuscatedValues, getObfuscatedValue } = useObfuscatedSearchParam();

  /** 현재 선택 된 UTH 시퀀스 */
  const queryCurUthSeq = getObfuscatedValue(AP_PARAMS.UTH_SEQ.KEY);

  /** 현재 학생이 본 시험 목록 상태 */
  const [testList, setTestList] = useState([]);
  /** 현재 선택 된 시험에 해당하는 점수 데이터 상태 */
  const [currentTestRowScoreList, setCurrentTestRowScoreList] = useState([]);
  /** 현재 선택 된 시험에 해당하는 Section / Part 이름과 시퀀스 목록 상태 */
  const [currentTestSectionAndPartList, setCurrentTestSectionAndPartList] = useState([]);
  /** 현재 선택 된 시험의 Section / Part 에 해당하는 시험 결과 데이터 목록 상태 */
  const [currentIndividualQuestionResults, setCurrentIndividualQuestionResults] = useState([]);
  /** 로딩 상태 */
  const [isLoading, setIsLoading] = useState(false);
  /** 시험 리뷰 가능 기간 */
  const [reviewPeriodDate, setReviewPeriodDate] = useState(null);

  /** 학생이 본 시험 목록 불러오기 */
  const getTestList = () => {
    setIsLoading(true);
    const successHandler = (response) => {
      setTestList(response.result.tests);
    };

    request
      .apGet('/api/v1/student/dashboard/test-list', null, successHandler)
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  /** "Individual Question Results" 불러오기 API 요청 */
  const getIndividualQuestionResults = ({ queryCurUthSeq, queryCurPartSeq }) => {
    console.log('getIndividualQuestionResults');
    if (
      !queryCurUthSeq ||
      queryCurUthSeq === 'null' ||
      queryCurUthSeq === 'undefined' ||
      !queryCurPartSeq ||
      queryCurPartSeq === 'null' ||
      queryCurPartSeq === 'undefined'
    )
      return;

    setIsLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        const reportSectionData = response.result.reportSectionData;
        setCurrentIndividualQuestionResults(reportSectionData);
      }
    };

    const apiUrl = `/api/v1/student/dashboard/part/${queryCurPartSeq}/test/${queryCurUthSeq}`;

    request
      .apGet(apiUrl, null, successHandler)
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.body.classList.add('main_page'); //현재페이지에서만 addClass

    getTestList();

    return () => {
      document.body.classList.remove('main_page'); //다른 페이지에선 removeClass
    };
  }, []);

  useEffect(() => {
    /** 선택 된 시험에 해당하는 점수 데이터 가져오기 */
    const getTestScore = ({ queryCurUthSeq }) => {
      setIsLoading(true);
      const successHandler = (response) => {
        if (response.code === 200) {
          const scores = response.result.score;
          setCurrentTestRowScoreList(scores);
        }
      };
      request
        .apGet(`/api/v1/student/dashboard/score/${queryCurUthSeq}`, null, successHandler)
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
        });
    };

    /** 선택 된 시험에 해당 존재하는 Section / Part 이름과 시퀀스 목록 가져오기 */
    const getTestParts = ({ queryCurUthSeq }) => {
      setIsLoading(true);
      const successHandler = (response) => {
        if (response.code === 200) {
          const data = response.result.sectionData;
          const reviewPeriod = response.result?.reviewPeriod;

          setReviewPeriodDate(reviewPeriod);
          setCurrentTestSectionAndPartList(data);
          setBulkObfuscatedValues({
            paramValues: {
              [AP_PARAMS.UTH_SEQ.KEY]: queryCurUthSeq,
              [AP_PARAMS.SECTION_SEQ.KEY]: data[0].sectionSeq,
              [AP_PARAMS.PART_SEQ.KEY]: data[0].partData[0].partSeq,
            },
          });
        }
      };
      request
        .apGet(`/api/v1/student/dashboard/part/${queryCurUthSeq}`, null, successHandler)
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (queryCurUthSeq && queryCurUthSeq !== 'null' && queryCurUthSeq !== 'undefined') {
      getTestScore({ queryCurUthSeq });
      getTestParts({ queryCurUthSeq });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCurUthSeq]);

  return (
    <>
      <S.Wrap className='p_student_dashboard'>
        <VisualArea />
        <div className='com_center_wrap'>
          <VisualTextArea />
          <div className='dashboard_wrap'>
            <OuterBox className='com_container left'>
              {/*CALENDAR*/}
              <Box className='full'>
                <MiniCalendarSection />
              </Box>
            </OuterBox>
            <OuterBox className='com_container right'>
              <Box className='full upcoming_test_sec'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_time black'>&nbsp;</i>
                    Upcoming Test
                  </h2>
                </article>
                <UpcomingTestSection />
              </Box>
              <Box className='full'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_time black'>&nbsp;</i>
                    AP Timeline
                  </h2>
                </article>
                <TimeLineSection />
              </Box>
            </OuterBox>
            <OuterBox className='full'>
              <Box className='full'>
                <TestList testList={testList} handleClickTestCallback={getIndividualQuestionResults} />
              </Box>
            </OuterBox>
            {currentTestRowScoreList && currentTestRowScoreList.length > 0 ? (
              <OuterBox className='full flex_row'>
                <RowScoreSection scoreListData={currentTestRowScoreList} />
                <ApScoreSection score={currentTestRowScoreList[0].scaledScore} />
              </OuterBox>
            ) : (
              ''
            )}
            {currentTestSectionAndPartList && currentTestSectionAndPartList.length > 0 && (
              <OuterBox className='full'>
                <IndividualQuestionResultsSection
                  sectionAndPartList={currentTestSectionAndPartList}
                  currentIndividualQuestionResults={currentIndividualQuestionResults}
                  selectCallback={getIndividualQuestionResults}
                  reviewPeriodDate={reviewPeriodDate}
                />
              </OuterBox>
            )}
          </div>
        </div>

        {isLoading && <CommonLoadingAnimation size='10rem' className='' />}
      </S.Wrap>
    </>
  );
}

const S = {
  Wrap: styled.div`
    .full {
      width: 100%;
    }
    .flex_row {
      display: flex;
      align-items: stretch;
      gap: 0.75rem;
    }
    .custom_flex {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
    .scores_chart_section_box {
      width: calc(100% - 31.25rem);
    }
    .average_weekly_scores_box {
      width: 31.25rem;
    }
  `,
};
