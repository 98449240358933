import { useState } from 'react';

/**
 * 기본적인 숫자 입력 컴포넌트
 * @param {function} setValue - 입력 값 설정 함수
 * @param {number} defaultValue - input에 보일 기본 값
 * @param {number} minNumber - 최소 값
 * @param {number} maxNumber - 최대 값
 * @param {boolean} allowDecimal - 소수점 허용 여부 및 소수점 자릿수 설정

 * @param {string} className - 컴포넌트 클래스 이름
 * @param {object} inputProps - 기본 HTML input 속성
 */
export default function CommonNumberInput({
  handleCallback,
  defaultValue = 1,
  minNumber = 1,
  maxNumber = 100,
  allowDecimal = 0, // 소수점 허용 여부 및 소수점 자릿수 설정
  className = '',
  ...inputProps // 기본 HTML input 속성
}) {
  /** input 태그 값 상태 관리 */
  const [inputValue, setInputValue] = useState(String(defaultValue));

  const handleUpdateValue = (newValue) => {
    setInputValue(newValue);
    handleCallback(newValue);
  };

  /** input 태그 onchange 핸들러 */
  const handleOnChangeInput = (event) => {
    const newValue = event.target.value;

    // 소수점 허용 여부에 따라 다른 정규식 패턴 사용
    const pattern = allowDecimal ? new RegExp(`^-?\\d*(\\.\\d{0,${allowDecimal}})?$`) : /^-?\d*$/;
    if (newValue !== '' && !pattern.test(newValue)) return;

    handleUpdateValue(newValue);
  };

  /** input 태그 blur 핸들러 */
  const handleOnBlur = () => {
    const numValue = Number(inputValue);

    if (numValue > maxNumber) handleUpdateValue(String(maxNumber));
    else if (numValue < minNumber) handleUpdateValue(String(minNumber));
    else if (inputValue === '') handleUpdateValue(String(defaultValue));
    else {
      // 소수점 허용 여부에 따라 처리
      const finalValue = allowDecimal ? String(numValue) : String(Math.round(numValue));
      handleUpdateValue(finalValue);
    }
  };

  return (
    <input
      {...inputProps} // 기본 HTML input 속성
      className={className}
      type='number'
      value={inputValue}
      onChange={handleOnChangeInput}
      onBlur={handleOnBlur}
      min={minNumber}
      max={maxNumber}
      step={allowDecimal ? 'any' : '1'} // 소수점 허용 여부에 따라 step 설정
    />
  );
}
