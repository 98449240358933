import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjsTZ from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils';
import LocalStorage from 'utils/LocalStorage.utils';
import { GLOBAL_EN_TEST_TIME, GLOBAL_MA_TEST_TIME } from 'components/exam/dsat/_utils/constants/examTimeSetting';
import { TOTAL_QUESTION_NUMBER_EN, TOTAL_QUESTION_NUMBER_MATH } from 'components/exam/dsat/_utils/constants/totalQuestionNumberSetting';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deleteLocalStorageTime } from 'components/exam/dsat/_utils/functions/timerLocalStorageFunctions';

/** 시험 : 튜토리얼 페이지의 Footer 영역 */
export default function ExamTutorialFooter({ loading, setLoading, setExamMain, setSubjectRef, examMain }) {
  /////////////////// 기타 React Hook 및 외부 패키지 선언 영역 시작 //////////////
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const userInfo = request.tokenDecoder();
  /////////////////// 기타 React Hook 및 외부 패키지 선언 영역 끝 //////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 시험 일정 수정 API 요청 */
  const testReady = () => {
    let params = {
      testStatus: 'I',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        setExamMain((prev) => {
          return { ...prev, progressDisplay: false };
        });
        if (setSubjectRef.current !== 'M')
          setExamMain((prev) => {
            return { ...prev, examEnStateBool: true };
          });
        else if (setSubjectRef.current === 'M')
          setExamMain((prev) => {
            return { ...prev, examMaStateBool: true };
          });

        LocalStorage.setItem('sendTime', 'time', dayjsTZ());

        window.opener.parentLoadData(); // modified by psk 20240117 - 함수 호출로 바꿈
        setLoading(false);
      }
    };

    request.put(`/api/exam/userTestHistory/${stateExamInfo.uthSeq}`, params, successHandler).catch((error) => {
      console.error(error);
      setLoading(false);
      alert('시험 - 튜토리얼 - 시험 일정 수정 API 응답 실패');
    });
  };

  /** 시험 초기값 저장 API 요청 */
  const testInit = () => {
    let paramsList = [];
    let params = {};
    /** 시험 모듈 당 문제 갯수 (기본 영어) */
    let count = TOTAL_QUESTION_NUMBER_EN;

    // 수학 과목 일 경우
    if (setSubjectRef.current === 'M') count = TOTAL_QUESTION_NUMBER_MATH;

    setExamMain((prev) => {
      return { ...prev, ExamInitBool: false };
    });

    for (let i = 0; i < count; i++) {
      params = {
        uthSeq: stateExamInfo.uthSeq,
        questionSeq: stateExamInfo.questionList[i]?.questionSeq,
        testModuleSeq: stateExamInfo.questionList[i]?.testModuleSeq,
        remainingTime: GLOBAL_EN_TEST_TIME,
        regUserSeq: userInfo?.userSeq,
      };

      const meta = {
        question_subject: stateExamInfo.questionList[i]?.questionSubject,
        question_format: stateExamInfo.questionList[i]?.questionFormat,
        question_difficulty: stateExamInfo.questionList[i]?.questionDifficulty,
      };

      params.metaData = JSON.stringify(meta);

      paramsList.push(params);
    }

    const successHandler = (response) => {
      if (response.code === 200) {
        deleteLocalStorageTime();

        setExamMain((prev) => {
          return { ...prev, ExamInitBool: true };
        });

        if (setSubjectRef.current !== 'M') {
          let currentExamInfo = stateExamInfo.currentTest;
          currentExamInfo = { ...currentExamInfo, em1: 'I' };

          dispatch({
            type: 'setExamInfo',
            payload: { fieldName: 'currentTest', data: { ...currentExamInfo } },
          });
        } else if (setSubjectRef.current === 'M') {
          let currentExamInfo = stateExamInfo.currentTest;
          currentExamInfo = { ...currentExamInfo, mm1: 'I' };

          dispatch({
            type: 'setExamInfo',
            payload: { fieldName: 'currentTest', data: { ...currentExamInfo } },
          });
        }
        dispatch({
          type: 'setExamInfo',
          payload: { fieldName: 'currentNum', data: 1 },
        });

        testReady();
      }
    };

    request.post('/api/exam/resultHistoryList', paramsList, successHandler).catch((error) => {
      console.error(error);
      alert('시험 - 튜토리얼 - 시험 초기값 저장 API 응답 실패');
    });
  };

  /** 다음 버튼 동작 함수 */
  const handleButtonNext = () => {
    setLoading(true);
    setExamMain((prev) => {
      return { ...prev, progressDisplay: true, examEnStateBool: false, examMaStateBool: false };
    });

    if (examMain.filterCompletionBool) refreshTokenAndNavigate();
  };

  /** 토큰 새로고침 후 페이지 이동 */
  const refreshTokenAndNavigate = () => {
    const userToken = LocalStorage.getItemJsonParse('userSession');

    const successHandler = (response) => {
      // console.log('🚀 ~ successHandler ~ response:', response);
      if (response.code === 200) {
        const { accessToken, refreshToken } = response.result;

        // 토큰 저장
        LocalStorage.setItem('userSession', 'accessToken', accessToken);
        LocalStorage.setItem('userSession', 'refreshToken', refreshToken);

        // API 호출 성공 시 시험 초기화 함수 실행
        testInit();
      }
    };

    request.post('/api/member/token/refresh', { refreshToken: userToken?.refreshToken }, successHandler).catch((error) => {
      console.log('refresh token error! : ', error);
      alert('토큰 갱신 실패');
    });
  };

  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    if (examMain.examEnStateBool) {
      LocalStorage.setItem('sendTime', 'time', dayjsTZ());
      navigate('/exam/dsat/rw', {
        state: { currentNum: stateExamInfo.currentNum },
        replace: true,
      });
    } else if (examMain.examMaStateBool) {
      LocalStorage.setItem('sendTime', 'time', dayjsTZ());
      navigate('/exam/dsat/math', {
        state: { currentNum: stateExamInfo.currentNum },
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examMain.examEnStateBool, examMain.examMaStateBool]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <section className='pop_footer'>
      <article className='btn_area'>
        {/* <button className="btn" onClick={() => navigate(-1)}>Back</button> */}
        <S.Button className='btn' onClick={() => (loading ? null : handleButtonNext())} disabled={loading}>
          Next
        </S.Button>
      </article>
    </section>
  );
}

const S = {};

S.Button = styled.button`
  &:disabled {
    background-color: #999999;
  }
`;
