import CorrectRateDifficultyGraphBox from './graphComponent/CorrectRateDifficultyGraphBox';
import TimeSpentGraphBox from './graphComponent/TimeSpentGraphBox';

/**  */
export default function ExamStatisticsGraphSection({ checkViewAboutAverageOfGateplus, childSeqState }) {
  return (
    <div className='average_time_container'>
      <TimeSpentGraphBox checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus} childSeqState={childSeqState} />
      <CorrectRateDifficultyGraphBox checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus} childSeqState={childSeqState} />
    </div>
  );
}
