import { useState } from 'react';
import TestList from 'components/com/tests/_components/_TestListLayer';

/** classes 테이블 row */
export default function ClassesInfoRow({
  index,
  classId,
  classTitle,
  startDate,
  endDate,
  studentCount,
  testScheduled,
  testTaken,
  checked,
  onCheckboxChange,
  handleShowEdit,
}) {
  const [scheduledIsActive, setScheduledIsActive] = useState(false);
  const [takenIsActive, setTakenIsActive] = useState(false);

  const handleCheckboxChange = (event) => {
    onCheckboxChange(classId, event.target.checked);
  };

  const checkboxId = `chk02-${classId}`;
  return (
    <tr onDoubleClick={() => handleShowEdit(classId)}>
      <td>
        <input type='checkbox' className='com_chk' id={checkboxId} checked={checked} onChange={handleCheckboxChange} />
        <label htmlFor={checkboxId}>&nbsp;</label>
      </td>
      <td>{index}</td>
      <td className='alignL'>
        <p className='name'>{classTitle}</p>
      </td>
      <td>{startDate}</td>
      <td>{endDate}</td>
      <td>{studentCount}</td>

      <td>
        <TestList item={JSON.stringify(testScheduled)} status='E' />
      </td>
      <td>
        <TestList item={JSON.stringify(testTaken)} status='P' />
      </td>
    </tr>
  );
}
