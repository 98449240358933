import React from 'react';
import styled from 'styled-components';
import TimezoneForm from './_components/TimezoneForm';

/**
 * 프로필 - "Additional Settings" 텝에 해당 되는 화면 컴포넌트
 */
export default function AdditionalSettings({ setAlert, timezoneList }) {
  return (
    <>
      <h2 className='tit'>Additional Settings</h2>
      <p className='sub_tit'>Personalize your settings on our platform</p>
      <S.Wrap>
        <table>
          <tbody>
            <tr>
              <th>Timezone</th>
              <td>
                <TimezoneForm setAlert={setAlert} timezoneList={timezoneList} />
              </td>
            </tr>
          </tbody>
        </table>
      </S.Wrap>
    </>
  );
}

const S = {};

S.Wrap = styled.div`
  table {
    th,
    td {
      border-top: 1px solid #d2dbe2;
      padding: 2rem 1.25rem;
    }
    th {
      text-align: left;
      width: 30%;
    }
  }
`;
