import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';

import CollapsibleList from 'components/com/tests/_components/_common/_CollapsibleList/_CollapsibleList';
import CollapsibleContentItemContainer from 'components/com/tests/_components/_common/_CollapsibleList/CollapsibleContentItemContainer';

/** ByClass에서 클래스 별 테스트 리스트 컴포넌트 - Class 단위 */
export default function ByClassListSection({ testData, setSimpleAlert }) {
  const [isShowPdfPopup, setIsShowPdfPopup] = useState(false);
  const [selectedCustomSetSeqs, setSelectedCustomSetSeqs] = useState([]);
  const selectedTestInfoRef = useRef({
    pdfData: '',
    editData: {
      testName: '',
      testStartDate: '',
      testEndDate: '',
      studentList: [],
    },
  });
  const [selectedClassInfo, setSelectedClassInfo] = useState({
    0: {
      headerInfo: {
        classSeq: 0,
        className: '',
        customSetSeq: 0,
        testName: '',
        testStartDate: '',
        testEndDate: '',
        customSetSeqStr: '',
      },
      contentInfo: [
        {
          userName: '',
          userSeq: 0,
          upFileSeq: 0,
          testDate: '',
          uthSeq: 0,
        },
      ],
    },
  });

  const getFormattedDate = (date) => isoTimeToDayjsTZ(date).format('YYYY.MM.DD');

  // 고유 키 생성 함수
  const generateUniqueKey = (item) => {
    return `${item.customSetSeq}_${item.testStartDate}-${item.testEndDate}`; // 시간 정보를 포함한 고유 키 생성
  };

  useEffect(() => {
    setSelectedClassInfo({});
  }, [testData]);

  return (
    <S.ClassTestListSection>
      <CollapsibleList
        className='class_list_wrap custom_scroll'
        collapsibleListId={testData.classSeq} // input, label의 고유 id 생성을 위한 고유 값
        items={testData.testSetList}
        itemId='testSetSeq'
        expandedContentInfo={{
          contentKey: 'studentList',
          itemId: 'uthSeq',
        }}
        selectedDataInfo={selectedClassInfo}
        setSelectedDataInfo={setSelectedClassInfo}
        getUniqueKey={generateUniqueKey} // 고유 키 생성 함수 전달
        renderHeader={(itemInfo) => (
          <div className='title_wrapper'>
            <p className='title'>{itemInfo.testName}</p>
            <p className='sub_title'>{`${getFormattedDate(itemInfo.testStartDate)} - ${getFormattedDate(itemInfo.testEndDate)}`}</p>
          </div>
        )}
        renderContent={(studentInfo) => {
          return (
            <CollapsibleContentItemContainer
              userInfo={{
                userName: studentInfo.userName,
                userSeq: studentInfo.userSeq,
                upFileSeq: studentInfo.upFileSeq,
              }}
              title={studentInfo.userName}
              subTitle={isoTimeToDayjsTZ(studentInfo.testDate).format('YYYY.MM.DD hh:mm A')}
            />
          );
        }}
      />
    </S.ClassTestListSection>
  );
}

const S = {
  ClassTestListSection: styled.div`
    flex: 1 1 400px;
    max-width: calc(50% - 0.5rem);
    min-width: 0;
    max-height: 39.75rem;
    padding: 1.25rem 1rem;
    border-radius: 0.625rem;
    background: #f8f8f9;

    .class_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;

      .class_header_title {
        line-height: 1.5;
        font-weight: 600;
        color: #0068bd;
      }

      .class_header_btn_wrap {
        display: flex;
        gap: 0.5rem;
      }
    }

    .class_list_wrap {
      max-height: 34.625rem;
      overflow-x: hidden;
    }
  `,
};
