import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import Gnb from '../layout/Gnb';
import Lnb from '../layout/Lnb';
import Footer from '../layout/Footer';

import request from '../utils/Request.utils';

import Login from 'components/user/sign-in/Page'; //로그인

import { USER_LEVELS } from '../utils/constants';

import ReportCardPage from 'components/report/Page';
import styled from 'styled-components';

import ApScorePage from 'components/ap/com/score/Page';

import ApComponentsPage from 'components/ap/com/ap-components/Page';
import ApTestSchedulePage from 'components/ap/com/tests/scheduled/Page';
import ApComScoreGradingPage from 'components/ap/com/score/grading/Page';
import ApComScoreGradingSubmissionPage from 'components/ap/com/score/grading/submission/Page';

import ApTestsSetSelectTypePage from 'components/ap/com/tests/set/selection/type/Page';
import ApTestsSetSelectClassPage from 'components/ap/com/tests/set/selection/class/Page';
import ApTestsSetSelectStudentPage from 'components/ap/com/tests/set/selection/student/Page';
import ApTestsSetSelectTestMorePage from 'components/ap/com/tests/set/selection/tests/Page';

const apComponentsPathData = {
  path: '/ap-components', // ap/com/ap-components
  element: <ApComponentsPage />,
  routeInfo: { path: '/ap-components', element: <ApComponentsPage /> },
};

/** AP : "com" 으로 시작하는 경로 라우트 설정 컴포넌트 */
export default function ApCommonRoute() {
  let { pathname } = window.location;
  let authBool = false;
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const navigate = useNavigate();

  if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname);

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  useEffect(() => {
    if (userInfo && !(userInfo.userLevel === USER_LEVELS.STUDENT || userInfo.userLevel === USER_LEVELS.PARENT) && pathname.indexOf('/tests/set') > -1) {
      const successHandler = (response) => {
        if (response.code === 200) {
          const { isFreezing } = response.result;

          if (isFreezing) {
            navigate('/academy/dashboard');
            return;
          }
        }
      };

      request.get('/api/academy/gatekey/freezing', null, successHandler).catch((error) => {
        console.log('key freezing 에러 발생! : ', error);
      });
    }
  }, [pathname, userInfo, navigate]);

  const AuthorizationRoutes = () => {
    if (authBool) {
      if (userInfo && userInfo.userLevel === USER_LEVELS.PARENT && !pathname.includes('calendar')) {
        window.location.href = '/student/dashboard';
        return;
      }

      const authRoutes = [
        apComponentsPathData.routeInfo, // 🧰 ap에서 사용하는 components가 모여있는 페이지

        { path: '/score', element: <ApScorePage /> },
        { path: '/score/grading', element: <ApComScoreGradingPage /> },
        { path: '/score/grading/submission', element: <ApComScoreGradingSubmissionPage /> },

        { path: '/tests/scheduled', element: <ApTestSchedulePage /> },

        { path: '/tests/set/selection/type', element: <ApTestsSetSelectTypePage /> },
        { path: '/tests/set/selection/class', element: <ApTestsSetSelectClassPage /> },
        { path: '/tests/set/selection/student', element: <ApTestsSetSelectStudentPage /> },
        { path: '/tests/set/selection/tests', element: <ApTestsSetSelectTestMorePage /> },

        { path: '/reports', element: <ReportCardPage /> },

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/signin', element: <Login /> },
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };

  const PathCalendar = pathname.startsWith('/test/calendar');
  const studentsPathPrint = pathname.startsWith('/com/students/print');
  const classesPathPrint = pathname.startsWith('/com/classes/print');
  const PathReportCard = pathname.toLowerCase().startsWith('/com/reports');
  const mobileIntro = pathname.toLowerCase().startsWith('/com/intro');

  const pathApComponents = pathname.toLowerCase().startsWith('/ap/com/ap-components'); // 🧰 ap에서 사용하는 components가 모여있는 페이지
  const pathApScoreGradingSubmission = pathname.toLowerCase().startsWith('/ap/com/score/grading/submission');

  const renderLnb = () => {
    if (pathApComponents || pathApScoreGradingSubmission) return null; // 🧰 ap에서 사용하는 components가 모여있는 페이지

    if (PathCalendar || PathReportCard || studentsPathPrint || classesPathPrint || mobileIntro) return null;
    return <Lnb />;
  };

  const renderGnb = () => {
    if (pathApComponents || pathApScoreGradingSubmission) return null; // 🧰 ap에서 사용하는 components가 모여있는 페이지

    if (PathReportCard || mobileIntro) return null;
    return <Gnb />;
  };

  const renderFooter = () => {
    if (pathApComponents || pathApScoreGradingSubmission) return null; // 🧰 ap에서 사용하는 components가 모여있는 페이지

    if (PathReportCard || mobileIntro) return null;
    return <Footer />;
  };

  return (
    <>
      {renderGnb()}
      {renderLnb()}
      <S.Main>
        <Routes>{AuthorizationRoutes()}</Routes>
      </S.Main>
      {renderFooter()}
    </>
  );
}

const S = {
  Main: styled.main`
    min-height: 80vh;
  `,
};
