const setItem = (category, fieldName, data) => {
  let localStorageItem = window.localStorage.getItem(category);
  let localStorageItemJson = JSON.parse(localStorageItem ? localStorageItem : '{}');

  localStorageItemJson[fieldName] = data;

  window.localStorage.setItem(category, JSON.stringify(localStorageItemJson));
};

const getItem = (category) => {
  return window.localStorage.getItem(category);
};

const getItemJsonParse = (category) => {
  return JSON.parse(getItem(category));
};

const clearItem = (category) => {
  window.localStorage.setItem(category, '{}');
};

const clearAll = () => {
  window.localStorage.clear();
};

const removeItem = (category) => {
  window.localStorage.removeItem(category);
};

const updateItemFieldAtIndex = (category, fieldName, index, newData) => {
  const localStorageItemJson = getItemJsonParse(category) || {};

  if (Array.isArray(localStorageItemJson[fieldName])) {
    localStorageItemJson[fieldName][index] = {
      ...localStorageItemJson[fieldName][index],
      ...newData,
    };
  } else {
    console.error(`${fieldName} is not an array or doesn't exist in localStorage.`);
    return;
  }

  setItem(category, fieldName, localStorageItemJson[fieldName]);
};

const localStorage = {
  setItem,
  getItem,
  getItemJsonParse,
  clearItem,
  clearAll,
  removeItem,
  updateItemFieldAtIndex,
};

export default localStorage;
