import { useState } from 'react';

//components
import MiniAlert from 'components/_common/alerts/MiniAlert'; //텍스트 알럿
import MainLink from 'components/_common/button/_MainLinks';
import SimpleAlertModal from 'components/_common/modals/_SimpleAlertModal';
import styled from 'styled-components';
import ContentTopTabs from './_components/ContentTopTabs';
import DeleteStudentAlert from './_components/DeleteStudentAlert';
import EditTestScheduleModal from './_components/EditTestScheduleModal'; //test 수정 모달
import ByClass from './_components/tapContents/ByClass';
import ByTest from './_components/tapContents/ByTest';

/** ap/com/tests/scheduled 경로의 Page */
export default function ApTestsTakenPage() {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [reloadData, setReloadData] = useState(false);
  /** tab */
  const [tabState, setTabState] = useState(0);
  /** mini alert */
  const [miniAlert, setMiniAlert] = useState({
    active: false,
    text: '',
    type: '',
    timeOut: 0,
  });

  const [editTest, setEditTest] = useState({
    active: false,
    editData: {
      testName: '',
      className: '',
      testStartDate: '',
      testEndDate: '',
      studentList: [],
    },
    type: '',
  });
  /** 삭제 모달 */
  const [deleteTestModal, setDeleteTestModal] = useState({
    active: false,
    deleteData: '',
  });
  const [isShowDeleteAlert, setIsShowDeleteAlert] = useState(false);
  const [simpleAlert, setSimpleAlert] = useState({
    modalText: 'SOME_MODAL_TEXT',
    visible: false,
  });

  /** 인피니티 스크롤 */
  const [isLoading, setIsLoading] = useState(true);

  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** class 수정 클릭 핸들러 */
  const classEditClick = (item) => {
    setEditTest((prev) => {
      return { ...prev, active: true, editData: item, type: 'class' };
    });
  };
  const classDeleteClick = (item) => {
    setDeleteTestModal((prev) => {
      return { ...prev, active: true, deleteData: item };
    });
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  const testScheduleTabs = [
    { text: 'By Class', value: 0 },
    { text: 'By Test', value: 1 },
  ];
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <>
      <S.Wrap className='com_schedule'>
        <MainLink type='test' />
        <div className='com_center_wrap'>
          <ContentTopTabs tabData={testScheduleTabs} tabState={tabState} setTabState={setTabState} />
          <>
            {tabState === 0 ? (
              <ByClass reloadData={reloadData} setSimpleAlert={setSimpleAlert} />
            ) : tabState === 1 ? (
              <ByTest reloadData={reloadData} setSimpleAlert={setSimpleAlert} />
            ) : null}
          </>
        </div>
      </S.Wrap>
      {miniAlert.active && (
        <MiniAlert
          text={miniAlert.text}
          type={miniAlert.type}
          active={miniAlert.active}
          inactive={() =>
            setMiniAlert((prev) => {
              return { ...prev, active: false };
            })
          }
          timeOut={miniAlert.timeOut}
        />
      )}

      {isShowDeleteAlert && <DeleteStudentAlert setDeleteStudentAlert={setIsShowDeleteAlert} />}
      {editTest.active && (
        <EditTestScheduleModal
          editTest={editTest}
          setEditTest={setEditTest}
          setSimpleAlert={setSimpleAlert}
          setIsLoading={setIsLoading}
          setMiniAlert={setMiniAlert}
          //
          setReloadData={setReloadData}
        />
      )}

      <SimpleAlertModal alertInfo={simpleAlert} closeModal={() => setSimpleAlert((prev) => ({ ...prev, visible: false }))} />
    </>
  );
}

const S = {
  Wrap: styled.div`
    .com_container {
      background: #f4f8ff;
      padding: 15px;
      border-radius: 10px;
    }
    .full {
      width: 100%;
    }
  `,
};
