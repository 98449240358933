//#region    ////////// packages
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentQuestionIndex, updateCurrentPartData, updateCurrentSectionData } from 'reducers/apTestEditor/action';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import TestPreviewHeader from './_components/TestPreviewHeader';
import PreviewFooter from '../_components/layout/PreviewFooter';
import ExamAnswerList from './_components/ExamAnswerList';
import QuestionTopArea from 'components/ap/com/ap-components/_components/QuestionTopArea';
import SourcesForReview from 'components/ap/com/ap-components/_components/SourcesForReview';
import CommonLoadingAnimation from 'components/_common/CommonLoadingAnimation';
import QuillEditorContainer from 'components/ap/_components/common/QuillEditorContainer';
//#region    ///// UI components
import UI_ExamContainer from 'components/ap/question-bank/test-editor/_components/ui/UI_ExamContainer';
import UI_EditorContainer from 'components/ap/question-bank/test-editor/_components/ui/UI_EditorContainer';
//#endregion ///// UI components
import FileUpload from 'components/ap/_components/examLayout/examBody/FileUpload';
//#endregion ////////// components

//#region    ////////// utils & hooks
import useTestEditor from 'components/ap/question-bank/test-editor/useTestEditor';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
//#endregion ////////// utils & hooks

//#region    ////////// constants
import { QUESTION_TYPE_CONSTANT } from 'components/ap/question-bank/test-editor/_constant';
//#endregion ////////// constants

/** Preview 페이지 */
export default function TestPreviewPage() {
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryTestSeq = getObfuscatedValue('test_seq');
  const queryTestStatus = getObfuscatedValue('status');
  const { getEditingTestData, isLoading } = useTestEditor();

  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const dispatch = useDispatch();
  const apTestEditorReducer = useSelector((state) => state.apTestEditorReducer);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [undoAnswer, setUndoAnswer] = useState([]);
  /** 현재 Source 번호 */
  const [currentSourceIndex, setCurrentSourceIndex] = useState(0);
  const [uploadFileList, setUploadFileList] = useState([]);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  const currentPartQuestions = apTestEditorReducer?.questions.filter((question) => question.part_seq === apTestEditorReducer.currentPartData.part_seq);
  const currentQuestion = currentPartQuestions.find((question) => question.question_order === apTestEditorReducer.currentQuestion_index);
  //#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const handleSourceNavigation = (item) => {
    if (item.order !== undefined || item.order !== null) {
      setCurrentSourceIndex(Number(item.order));
    } else {
      console.error(`item 매개변수를 확인해 주세요.`);
    }
  };

  const handleNavigation = (targetIndex, targetPartSeq = apTestEditorReducer.currentPartData.part_seq) => {
    const targetSection = apTestEditorReducer.sectionMetadata.sections.find((section) => section.parts.some((part) => part.part_seq === targetPartSeq));
    const targetPart = targetSection.parts.find((part) => part.part_seq === targetPartSeq);

    dispatch(updateCurrentSectionData(targetSection));
    dispatch(updateCurrentPartData(targetPart));
    dispatch(updateCurrentQuestionIndex(targetIndex));
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  /** 서술형 문제 여부 */
  const isEssayQuestion = currentQuestion?.question_type === QUESTION_TYPE_CONSTANT.E || currentQuestion?.question_type === QUESTION_TYPE_CONSTANT.H;
  /** 헤더에서 보이는 정보 */
  const headerInfo = {
    sectionName: apTestEditorReducer.currentSectionData?.section_name,
    partName: apTestEditorReducer.currentPartData?.name,
  };
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  // 게시 완료된 시험의 경우 즉시 preview로 이동해서 데이터가 없음
  useEffect(() => {
    if (queryTestStatus === 'publish') getEditingTestData(queryTestSeq);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.QuestionBankTestEditorPageWrapper className='mathjax-ignore'>
      <TestPreviewHeader headerInfo={headerInfo} />
      <S.ExamContainerWrapper>
        {(currentQuestion?.source_group?.source_directions ||
          (currentQuestion?.source_group?.sources.length > 0 && currentQuestion?.source_group?.sources[0]?.content !== '')) && (
          <>
            <UI_ExamContainer className='left'>
              <UI_EditorContainer>
                <div className='passage'>
                  {currentQuestion?.source_group?.source_directions && (
                    <QuillEditorContainer className='text' content={currentQuestion.source_group.source_directions} draggable />
                  )}
                  {currentQuestion?.source_group?.sources.length > 0 && (
                    <>
                      {currentQuestion?.source_group?.sources.length > 1 && (
                        <S.SourcesForReviewContainer>
                          <S.SectionTitle>Sources for Review :</S.SectionTitle>
                          <SourcesForReview
                            sourceData={currentQuestion?.source_group?.sources}
                            currentOrder={currentSourceIndex}
                            handleClickReviewItem={handleSourceNavigation}
                          />
                        </S.SourcesForReviewContainer>
                      )}
                      {currentQuestion?.source_group?.sources.map((item, index) => {
                        return (
                          item.order === currentSourceIndex && (
                            <Fragment key={`key_${index}`}>
                              <S.SectionTitle>Document {currentSourceIndex + 1}</S.SectionTitle>
                              <QuillEditorContainer className='text' content={item.content} draggable />
                            </Fragment>
                          )
                        );
                      })}
                    </>
                  )}
                </div>
              </UI_EditorContainer>
            </UI_ExamContainer>

            <S.VerticalDivider />
          </>
        )}

        <UI_ExamContainer className='right'>
          {currentQuestion?.question_type === QUESTION_TYPE_CONSTANT.E && (
            <UI_EditorContainer>
              <S.QuestionItem className='passage'>
                <QuestionTopArea order={currentQuestion.custom_question_order} />

                <QuillEditorContainer className='text' content={currentQuestion?.question_content} draggable />

                <S.SectionTitle>Your answer:</S.SectionTitle>

                <S.EssayQuestionContainer className='custom_scroll' />
              </S.QuestionItem>
            </UI_EditorContainer>
          )}

          {currentQuestion?.question_type === QUESTION_TYPE_CONSTANT.H && (
            <UI_EditorContainer>
              <S.QuestionItem className='passage'>
                <QuestionTopArea order={currentQuestion.custom_question_order} />

                <QuillEditorContainer className='text' content={currentQuestion?.question_content} draggable />

                <S.SectionTitle>Your answer:</S.SectionTitle>

                <S.HybridQuestionDescription>
                  <p className='description'>
                    Instructions: Use a scanner app like CamScanner, Adobe Scan, or Microsoft Lens to capture your document and submit it as a PDF. If you have
                    multiple pages, make sure to merge them into a single file before submitting.
                  </p>
                  <p className='additional-description'>
                    Warning: In the actual hybrid exam, you must submit a physical paper, not a digital capture. Please note that mobile phones are not allowed.
                  </p>
                </S.HybridQuestionDescription>

                {/* <S.EssayQuestionContainer className='custom_scroll' /> */}
                <FileUpload uploadFileList={[]} setUploadFileList={() => {}} />
              </S.QuestionItem>
            </UI_EditorContainer>
          )}

          {currentQuestion?.question_type === QUESTION_TYPE_CONSTANT.M && (
            <UI_EditorContainer>
              <S.QuestionItem className='passage'>
                <QuestionTopArea order={currentQuestion.custom_question_order} />

                <QuillEditorContainer className='text' content={currentQuestion?.question_content} draggable />

                <ExamAnswerList
                  questionOptions={Object.entries(currentQuestion?.answer_options || {})
                    .filter(([key]) => key.startsWith('option'))
                    .sort(([keyA], [keyB]) => {
                      const numA = parseInt(keyA.replace('option', ''));
                      const numB = parseInt(keyB.replace('option', ''));
                      return numA - numB;
                    })
                    .map(([_, value], index) => ({
                      id: index + 1,
                      choiceType: 'Text Input',
                      content: value,
                    }))}
                  selectedAnswer={selectedAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  undoAnswer={undoAnswer}
                  setUndoAnswer={setUndoAnswer}
                />
              </S.QuestionItem>
            </UI_EditorContainer>
          )}
        </UI_ExamContainer>
      </S.ExamContainerWrapper>
      <PreviewFooter handleNavigation={handleNavigation} />
      {queryTestStatus === 'publish' && isLoading && <CommonLoadingAnimation size='15rem' text='Loading test data...' />}
    </S.QuestionBankTestEditorPageWrapper>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  QuestionBankTestEditorPageWrapper: styled.div`
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    // Quill 스타일 오버라이딩
    .ql-snow {
      border: none;
      .ql-editor {
        padding: 0;
      }
    }
  `,
  ExamContainerWrapper: styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 0;
    & > * {
      padding-top: 4.435rem;
    }
  `,

  QuestionItem: styled.div`
    .text {
      padding: 1.5rem 0 1rem 0;

      .MJXc-display {
        margin: 0 !important;
      }
    }
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif', serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0.5rem;
  `,

  HybridQuestionDescription: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-family: 'Noto Serif', serif;
    color: #ff5858;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;

    .description {
      font-weight: 400;
    }
    .additional-description {
      font-weight: 700;
    }
  `,

  EssayQuestionContainer: styled.div`
    padding: 12px 8px;
    background-color: #f1f1f1;
    height: 23.25rem;

    p {
      color: #000000;
      font-family: 'Noto Sans';
      letter-spacing: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
      max-width: 100%;
    }
  `,

  /** 수직 구분선 */
  VerticalDivider: styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 1px;
    background-color: black;
  `,

  SourcesForReviewContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
};
