import { ANSWER_CHOICE_CONSTANT } from './_constant';

export const getPartData = (sectionMetadata) => {
  // sections 배열이 없을때 빈 배열 반환
  if (!sectionMetadata?.sections) return [];

  // 파트 데이터 반환 ( 배열 Depth가 있기 때문에 평탄화 추가 )
  return sectionMetadata.sections.flatMap((section) => {
    return section.parts.map((part) => ({
      label: `${section.section_name} - ${part.name} (Seq ${part.part_seq})`,
      value: {
        sectionSeq: section.section_seq,
        partSeq: part.part_seq,
      },
    }));
  });
};

/** 프론트에서 쓰이는 데이터 형식을 백엔드로 보내주기 위한 변환 코드 */
export const transformChoicesToBackend = (answerChoiceInfo) => {
  return answerChoiceInfo.answerChoices.reduce(
    (acc, item, index) => ({
      ...acc,
      [`option${index + 1}`]: item.content,
    }),
    {
      total: answerChoiceInfo.answerChoices.length,
      answerCount: answerChoiceInfo.answerChoices.length,
    }
  );
};

/** 백엔드에서 쓰이는 데이터 형식을 프론트엔드로 보내주기 위한 변환 코드 */
export const transformChoicesToFrontend = (answerChoiceInfo) => {
  return Array.from({ length: answerChoiceInfo.total }, (_, index) => ({
    choiceType: 'Text Input',
    content: answerChoiceInfo[`option${index + 1}`],
  }));
};

// /** 객관식 문제 유형 변환 */
// export const getMultipleChoiceQuestionType = (questionType) => {
//   if (questionType === 'M') return ANSWER_CHOICE_CONSTANT.MULTIPLE;
//   if (questionType === 'S') return ANSWER_CHOICE_CONSTANT.SINGLE;

//   // 서술형일때("E") 이지만 - 에러 상황이지만 단일 문제로 예외 처리
//   return ANSWER_CHOICE_CONSTANT.SINGLE;
// };
