import styled from 'styled-components';

import QuestionItem from './_components/QuestionItem';

/** AP - Report Card - Section2 - 하이브리드 시험 */
export default function HybridSection({ part }) {
  return (
    <S.Section2>
      <S.QuestionList>
        {part.questions.map((question) => {
          return <QuestionItem key={`question-${question.questionNum}`} question={question} isLastQuestion={part.questions.length} />;
        })}
      </S.QuestionList>
    </S.Section2>
  );
}

// --- --- ---

const S = {
  Section2: styled.div`
    .section_title {
      margin-bottom: 1.875rem;
      font-size: 1.5rem;
      font-weight: 700;
    }
  `,

  QuestionList: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  `,
};
