import React from 'react';
import styled from 'styled-components';
import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import noticeIcon from 'assets/gate_member/image/notice_icon.svg';

/** 관리자 첫 로그인을 학원 코드 없이 했을때, 발생하는 모달 */
export default function EmailLoginModal({ setShowEmailLoginModal }) {
  return (
    <SimpleModalBase>
      <h2>Your account has not been approved yet.</h2>
      <div className='modal_img_wrapper'>
        <img src={noticeIcon} />
      </div>
      <div className='modal_text_container'>
        <p>Please wait until we review your credentials and approve your account.</p>
        <p>Once you receive an email, you will be able to sign in to your account.</p>
        <S.NotiTextContainer>
          <p className='noti_text'>If you have not received an email yet</p>
          <div className='noti_detail'>
            <p>1&#41; Please check your spam mail folder.</p>
            <p>2&#41; If you still did not receive it, we are still reviewing your files. Please wait patiently until we finish reviewing.</p>
          </div>
        </S.NotiTextContainer>
      </div>
      <div className='modal_btn_container'>
        <button className='btn_fill' onClick={() => setShowEmailLoginModal(false)}>
          Back
        </button>
      </div>
    </SimpleModalBase>
  );
}

const S = {};

S.NotiTextContainer = styled.div`
  margin-top: 0.625rem !important;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;

  .noti_text {
    color: #0068bd;
    background-color: #edf7ff;
    border-radius: 1.25rem;
    padding: 0.25rem 0;
  }
  .noti_detail {
    margin-top: 0.625rem;
    text-align: left;
    color: #a5aab1;
    padding: 0 0.5rem;
  }
`;
