import styled from 'styled-components';

import { ReactComponent as ImgSvgIconPlus } from 'assets/img/svg/icon_plus.svg';
import { ReactComponent as ImgSvgTrash } from 'assets/img/svg/icon_trash_01.svg';
/** AP "Question Bank" main - Table_Header_버튼영역 */
export default function TopBtnArea({ handleDeleteButton }) {
  /** "Create Test" 버튼 핸들러 */
  const handleCreateTestButton = () => {
    window.open(`/ap/question-bank/create-test`, '_blank');
  };

  return (
    <>
      <S.Wrap>
        <button type='button' title='Move to another Page' className='btn_create_test' onClick={handleCreateTestButton}>
          <ImgSvgIconPlus />
          Create Test
        </button>
        <button
          type='button'
          title='Move to another Page'
          className='btn_create_test'
          onClick={() => {
            console.log('Clicked');
            handleDeleteButton();
          }}>
          <ImgSvgTrash />
        </button>
      </S.Wrap>
    </>
  );
}

const S = {
  Wrap: styled.article`
    margin-bottom: 1rem;
    display: flex;
    gap: 0.5rem;
    button {
      transition: 0.2s;
      &:hover {
        filter: brightness(95%);
      }
    }
    .btn_create_test {
      background-color: #f4f8ff;
      color: #0068bd;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.5rem;
      padding: 0.5rem 0.625rem;
      font-weight: 400;
      font-size: 0.875rem;
      svg {
        width: 1.125rem;
      }
    }
  `,
};
