import {
  SET_SOCIAL_USER_ID,
  SET_SOCIAL_LOGIN_TYPE,
  SET_SOCIAL_LOGIN_TIME,
  SET_SOCIAL_USER_EMAIL,
  CLEAR_SOCIAL_USER_STATE,
  SET_SOCIAL_USER_NAME,
  SET_SOCIAL_CONFIRM_CODE,
  SET_USER_LEVEL,
} from './actionTypes';

// default state
const initialState = {
  social: {
    userId: '',
    loginType: '',
    userLevel: '',
    userEmail: '',
    userName: '',
    loginTime: 0,
    confirmCode: '',
  },
};

// 전역 상태 Reducer
const userSocialReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SOCIAL_USER_ID:
      return {
        ...state,
        social: {
          ...state.social,
          userId: action.payload,
        },
      };
    case SET_SOCIAL_LOGIN_TYPE:
      return {
        ...state,
        social: {
          ...state.social,
          loginType: action.payload,
        },
      };
    case SET_SOCIAL_LOGIN_TIME:
      return {
        ...state,
        social: {
          ...state.social,
          loginTime: action.payload,
        },
      };
    case SET_SOCIAL_USER_EMAIL:
      return {
        ...state,
        social: {
          ...state.social,
          userEmail: action.payload,
        },
      };
    case SET_SOCIAL_USER_NAME:
      return {
        ...state,
        social: {
          ...state.social,
          userName: action.payload,
        },
      };
    case SET_SOCIAL_CONFIRM_CODE:
      return {
        ...state,
        social: {
          ...state.social,
          confirmCode: action.payload,
        },
      };
    case SET_USER_LEVEL:
      return {
        ...state,
        social: {
          ...state.social,
          userLevel: action.payload,
        },
      };
    case CLEAR_SOCIAL_USER_STATE:
      return initialState;
    default:
      return state;
  }
};

export default userSocialReducer;
