import { useEffect, useState } from 'react';
import request from 'utils/Request.utils.js';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

import IconDel from 'assets/img/icon_del.svg';
import ProfilePopup from 'components/_common/modals/PopupProfile';

const TeacherListInformation = () => {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const [deleteTeacher, setDeleteTeacher] = useState(false);
  const [deleteTeacherSeq, setDeleteTeacherSeq] = useState(0);
  const [teacherList, setTeacherList] = useState([]);

  const [profileState, setProfileState] = useState(0);
  const [teacherProfile, setTeacherProfile] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getTeacherList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeacherList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        setTeacherList(response.result.teacherList);
        setIsLoading(false);
      }
    };

    request.get(`/api/member/profile/academy/${userInfo.ecSeq}/teachers`, null, successHandler);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h2 className='tit'>teacher list</h2>
      <p className='sub_tit'>View all the teachers associated with your Academy</p>
      {teacherList.length === 0 ? (
        <div className='no_academy'>
          <i className='svg_icon icon_no_link gray'>&nbsp;</i>
          No linked teachers.
          <br />
          Teachers need to link with your institution using your Academy Code in their profile settings.
        </div>
      ) : (
        teacherList.map((item) => {
          return (
            <TeacherList
              key={item.userName + item.userSeq}
              teacherProfile={teacherProfile}
              setTeacherProfile={setTeacherProfile}
              teacherData={item}
              setDeleteTeacher={setDeleteTeacher}
              setDeleteTeacherSeq={setDeleteTeacherSeq}
              setProfileState={setProfileState}
            />
          );
        })
      )}

      {profileState === 1 ? (
        <div className='com_popup active pop_profile teacher'>
          <div className='pop_container'>
            <ProfilePopup type='teacher' setProfileState={setProfileState} studentInfo={teacherProfile} />
          </div>
        </div>
      ) : null}
      {deleteTeacher && <DeleteTeacherModal setDeleteTeacher={setDeleteTeacher} deleteTeacherSeq={deleteTeacherSeq} getTeacherList={getTeacherList} />}
    </>
  );
};
export default TeacherListInformation;

// TeacherListInformation에서 사용되는 components
const TeacherList = (props) => {
  const { teacherData, setDeleteTeacher, setProfileState, setDeleteTeacherSeq, setTeacherProfile } = props;

  const handleShowProfile = (userSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const teacherProfileData = response.result.memberList[0];

        setTeacherProfile({
          userName: teacherProfileData.userName || '',
          userEmail: teacherProfileData.userEmail || '',
          userMobile: teacherProfileData.userMobile || '',
          regDate: teacherProfileData.regDate.split(' ')[0] || '',
          profile:
            teacherProfileData.upFileSeq > 0
              ? `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${teacherProfileData.userSeq}?${Date.now()}`
              : '',
        });

        setProfileState(1);
      }
    };

    request.get(`/api/member/list?userSeq=${userSeq}`, null, successHandler);
  };

  const deletePophandler = (mcSeq) => {
    setDeleteTeacherSeq(mcSeq);
    setDeleteTeacher(true);
  };

  return (
    <article className='teacher_list'>
      <div className='com_profile_img T m'>
        {/* <img src={ImgProfile} alt="프로필이미지"/> */}
        {teacherData.upFileSeq > 0 ? (
          <img
            className='profile-img-background'
            src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${teacherData.userSeq}?${Date.now()}`}
            alt='프로필이미지'
          />
        ) : (
          <span className='name'>{teacherData.userName[0] || 'NONE'}</span>
        )}
      </div>
      <div className='info'>
        <p className='name'>{teacherData.userName}</p>
        <p className='date'>Linked date {isoTimeToDayjs(teacherData.regDate).format('MM / DD / YYYY') || 'NONE'}</p>
      </div>
      <div className='btn_field'>
        <button className='com_btn xs line btn_view' onClick={() => handleShowProfile(teacherData.userSeq)}>
          View Profile
        </button>
        <div className='btn_more'>
          <i className='svg_icon icon_more gray'>&nbsp;</i>
          <div className='layer'>
            <button className='com_btn' onClick={() => deletePophandler(teacherData.mcSeq)}>
              <i className='svg_icon icon_trashcan2 gray'>&nbsp;</i>Remove
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

const DeleteTeacherModal = ({ setDeleteTeacher, deleteTeacherSeq, getTeacherList }) => {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const handleDeleteTeacher = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        getTeacherList();
        setDeleteTeacher(false);
      }
    };
    request.del(`/api/member/profile/academy/${userInfo.ecSeq}/teachers/${deleteTeacherSeq}?userSeq=${userInfo.userSeq}`, null, successHandler);
  };

  return (
    <div className='com_popup type_alert active'>
      <div className='pop_container'>
        <div className='pop_body ok_contents'>
          <p className='alert_title'>
            Are you sure you want to
            <br />
            remove this teacher?
          </p>
          <div className='icon'>
            <img src={IconDel} alt='icon' />
          </div>
          <div className='alert_text'>
            You cannot add the teacher back <br />
            until the teacher links back to your academy
          </div>
        </div>
        <div className='pop_footer com_btn_wrap many'>
          <button className='com_btn point line xl' onClick={() => setDeleteTeacher(false)}>
            Back
          </button>
          <button className='com_btn point xl' onClick={handleDeleteTeacher}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
