/**
 * 신규가입 인경우 학원 confirm code 전역 상태 리덕스 action 타입
 */
/** 사용자 uid */
export const SET_JOIN_COMPANY_UID = 'SET_JOIN_COMPANY_UID';
/** 로그인 타입 */
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';
/** 학원코드 */
export const SET_JOIN_COMPANY_CONFRIM_CODE = 'SET_JOIN_COMPANY_CONFRIM_CODE';
/** 타임존 no */
export const SET_JOIN_COMPANY_TIMEZONE_NO = 'SET_JOIN_COMPANY_TIMEZONE_NO';
/** all data clear */
export const CLEAR_JOIN_COMPANY_STATE = 'CLEAR_JOIN_COMPANY_STATE';
/** user level */
export const SET_JOIN_COMPANY_USER_LEVEL = 'SET_JOIN_COMPANY_USER_LEVEL';
