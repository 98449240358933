import {
  SET_JOIN_COMPANY_UID,
  SET_LOGIN_TYPE,
  SET_JOIN_COMPANY_CONFRIM_CODE,
  SET_JOIN_COMPANY_TIMEZONE_NO,
  CLEAR_JOIN_COMPANY_STATE,
  SET_JOIN_COMPANY_USER_LEVEL,
} from './joincompany.actionTypes';

/** 리듀서 전역 상태 액션 : uid 저장 */
export const updateReduxJoinCompanyUid = (uid) => ({
  type: SET_JOIN_COMPANY_UID,
  payload: uid,
});
/** 리듀서 전역 상태 액션 : user level 저장 */
export const updateReduxJoinCompanyUserLevel = (userLevel) => ({
  type: SET_JOIN_COMPANY_USER_LEVEL,
  payload: userLevel,
});
/** 리듀서 전역 상태 액션 : login type 저장 */
export const updateReduxJoinCompanyLoginType = (loginType) => ({
  type: SET_LOGIN_TYPE,
  payload: loginType,
});
/** 리듀서 전역 상태 액션 : 학원 코드 저장 */
export const updateReduxJoinCompanyConfirmCode = (confirmCode) => ({
  type: SET_JOIN_COMPANY_CONFRIM_CODE,
  payload: confirmCode,
});
/** 리듀서 전역 상태 액션 : 타임존 저장 */
export const updateReduxJoinCompanyTimezoneNo = (timezoneNo) => ({
  type: SET_JOIN_COMPANY_TIMEZONE_NO,
  payload: timezoneNo,
});

/** 학원 컨펌코드 리듀서 전체 초기화 */
export const clearReduxStateJoinCompany = () => {
  return {
    type: CLEAR_JOIN_COMPANY_STATE,
  };
};
