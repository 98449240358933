import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { clearApTestEditor } from 'reducers/apTestEditor/action';
import request from 'utils/Request.utils';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';

import { ReactComponent as ImgSvgIconX } from 'assets/img/svg/x-close.svg';
import CommonNumberInput from 'components/_common/inputs/CommonNumberInput';
import InnerBox from './_components/styles/InnerBox';
import InputStyleWrap02 from './_components/styles/InputStyleWrap02';
import InputWrapForInnerBox from './_components/styles/InputWrapForInnerBox';
import LabelText from './_components/styles/LabelText';
import LabelText02 from './_components/styles/LabelText02';
import LabelTextForInnerBox from './_components/styles/LabelTextForInnerBox';
import TitleForSection from './_components/styles/TitleForSection';

/** "Ap" - "Question Bank" - "Create Test" 페이지의 - "Advanced setting" 영역 컴포넌트 */
export default function AdvancedSetting({
  currentStep,
  setCurrentStep,
  subjectsData,
  setSubjectsData,
  currentSelectedSubject,
  testNameInputValue,
  setTestNameInputValue,
  PrimaryButtonArea = ({ handleNext, handleCancel, cancelButtonText, className = '', ...rest }) => {},
}) {
  if (!subjectsData || subjectsData.length <= 0) {
    console.error('전체 과목 데이터를 확인해 주세요');
    return;
  }
  if (!currentSelectedSubject) {
    console.error('선택 된 과목을 전달 받지 않았습니다.');
    return;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** 현재 선택 된 과목 전체 데이터 */
  const [currentSelectedSubjectData, setCurrentSelectedSubjectData] = useState();

  /** 현재 선택 된 과목 전체 데이터 입력 상태 관리 */
  const [inputValues, setInputValues] = useState([
    {
      section_seq: 0,
      template_seq: 0,
      section_name: '',
      break_time: 0,
      order_no: 0,
      ap_template_parts: [
        {
          part_seq: 0,
          order_no: 0,
          part_name: '',
          type_of_question: '',
          description: '',
          answer_choices: 0,
          total_raw_score: 0,
          number_of_questions: 0,
          part_time: 0,
          break_time: 0,
          calculator: true,
          reference_sheet: true,
          directions_sheet: true,
          directions: null,
          score_per_question: 0,
          source_grouped_questions: 0,
          weight_of_part_score: 0,
        },
      ],
    },
  ]);

  /** 특정 파트의 데이터 변경 */
  const updatePart = ({ sectionSeq, partSeq = null, key = '', value = '' }) => {
    setInputValues((prevSections) =>
      prevSections.map((section) => {
        if (partSeq) {
          return section.section_seq === sectionSeq
            ? {
                ...section,
                ap_template_parts: section.ap_template_parts.map((part) => (part.part_seq === partSeq ? { ...part, [key]: Number(value) } : part)),
              }
            : section;
        } else {
          return section.section_seq === sectionSeq
            ? {
                ...section,
                [key]: Number(value),
              }
            : section;
        }
      })
    );
  };

  /** "Cancel" 버튼 핸들러 */
  const handleCancel = () => {
    if (window.confirm('Do you really want to leave?')) {
      setCurrentStep((current) => (current -= 1));
    }
  };

  /** "Next" 버튼 핸들러 */
  const handleNext = () => {
    if (!testNameInputValue) {
      alert('Please enter the Test Name field');
      return;
    }

    /** 유효성 검사 */
    const isValid = inputValues
      .map((sectionItem, sectionIndex) => {
        if (sectionItem.break_time === '') {
          alert('Please enter the Break time (min) field');
          return false;
        }
        const isValidOfParts = sectionItem.ap_template_parts
          .map((partItem, partIndex) => {
            if (partItem.number_of_questions === '') {
              alert('Please enter the Questions field');
              return false;
            } else if (partItem.part_time === '') {
              alert('Please enter the Exam Duration (min) field');
              return false;
            } else if (partItem.total_raw_score === '') {
              alert('Please enter the Raw Score field');
              return false;
            } else if (partItem.weight_of_part_score === '') {
              alert('Please enter the Weighted Score (%) field');
              return false;
            } else if (partItem.break_time === '') {
              alert('Please enter the Break time (min) field');
              return false;
            } else {
              return true;
            }
          })
          .every(Boolean);

        if (!isValidOfParts) {
          return false;
        } else {
          return true;
        }
      })
      .every(Boolean);

    if (!isValid) {
      return;
    }

    /** 전체 가중치 점수의 합산 (100%) 이어야 한다.  */
    let totalWeightScore = 0;

    const newData = inputValues.map((sectionItem) => {
      const newApTemplateParts = sectionItem.ap_template_parts.map((partItem) => {
        totalWeightScore += partItem.weight_of_part_score;
        return {
          ...partItem,
          break_time: Number(partItem.break_time) * 60,
          part_time: Number(partItem.part_time) * 60,
        };
      });

      return {
        ...sectionItem,
        break_time: Number(sectionItem.break_time) * 60,
        ap_template_parts: newApTemplateParts,
      };
    });

    if (Math.round(Number(totalWeightScore)) !== 100) {
      alert('The sum of the Weighted Scores must be 100%.');
      return;
    }

    const template = {
      ...currentSelectedSubjectData,
      template: {
        ...currentSelectedSubjectData.template,
        ap_template_sections: newData,
      },
    };

    const params = {
      test_name: String(testNameInputValue).trim(),
      subject_seq: currentSelectedSubjectData.template.subject_seq,
      datas: template,
      isNew: true,
    };

    const successHandler = (response) => {
      const { test_set_seq } = response.result;

      dispatch(clearApTestEditor()); // 로컬 스토리지 테스트 데이터 초기화
      navigate(`/ap/question-bank/test-editor?test_seq=${obfuscateUrlParam(test_set_seq)}&status=${obfuscateUrlParam('new')}`); // 새로운 테스트 라는것을 알리는 인자 추가
    };
    const errorHandler = (error) => {
      console.error(error);
    };
    request.post(request.apiUris.ap.questionBank.createTest, params, successHandler, errorHandler);
  };

  /** 섹션 삭제 핸들러 */
  const handleDeleteSection = (sectionSeq) => {
    setInputValues((prevSections) => {
      return prevSections.filter((section) => {
        return section.section_seq !== sectionSeq;
      });
    });
  };

  /** 파트 삭제 핸들러 */
  /** 파트 삭제 핸들러 */
  const handleDeletePart = (partSeq) => {
    setInputValues((prevSections) => {
      const updatedSections = prevSections
        .map((section) => {
          // 해당 partSeq에 해당하는 파트를 완전히 삭제
          const updatedParts = section.ap_template_parts.filter((part) => part.part_seq !== partSeq);

          // 남은 파트가 없으면 섹션 전체 삭제
          if (updatedParts.length === 0) {
            return null;
          }

          // 남은 파트가 정확히 하나라면 그 파트의 part_name을 ''으로 수정
          if (updatedParts.length === 1) {
            updatedParts[0] = { ...updatedParts[0], part_name: '' };
          }

          return {
            ...section,
            ap_template_parts: updatedParts,
          };
        })
        .filter((section) => section !== null); // 삭제된 섹션 제거

      return updatedSections;
    });
  };

  useEffect(() => {
    if (currentSelectedSubjectData && currentSelectedSubjectData?.template.ap_template_sections) {
      const newData = currentSelectedSubjectData.template.ap_template_sections.map((sectionItem) => {
        const newApTemplateParts = sectionItem.ap_template_parts.map((partItem) => {
          return {
            ...partItem,
            break_time: Number(partItem.break_time) / 60,
            part_time: Number(partItem.part_time) / 60,
          };
        });

        return {
          ...sectionItem,
          break_time: Number(sectionItem.break_time) / 60,
          ap_template_parts: newApTemplateParts,
        };
      });

      setInputValues(newData);
    }
  }, [currentSelectedSubjectData, currentSelectedSubjectData?.template.ap_template_sections]);

  useEffect(() => {
    const currentSelectedSubjectData = subjectsData.find((item) => {
      return Number(item.value) === Number(currentSelectedSubject);
    });

    setCurrentSelectedSubjectData(currentSelectedSubjectData);
  }, [subjectsData, currentSelectedSubject]);

  return (
    currentSelectedSubjectData && (
      <>
        <S.Wrap>
          <div className='input_container'>
            <div className='input_label_wrapper'>
              <div className='required_mark'>*</div>
              <LabelText>Subject</LabelText>
            </div>

            <input type='text' id='subject-select' value={currentSelectedSubjectData?.label} readOnly className='text_input_field test_input' />
          </div>

          <div className='input_container'>
            <div className='input_label_wrapper'>
              <div className='required_mark'>*</div>
              <LabelText>Test Name</LabelText>
            </div>

            <input
              type='text'
              id='test_name'
              value={testNameInputValue}
              onChange={(e) => setTestNameInputValue(e.target.value)}
              className='text_input_field test_input'
              placeholder='Enter'
            />
          </div>
          <div className='input_label_wrapper'>
            <LabelText className={``}>Advanced setting</LabelText>
          </div>
          {inputValues.map((sectionItem, sectionIndex) => {
            const isMultiPart = sectionItem.ap_template_parts.length > 1;

            return (
              sectionItem.section_seq && (
                <React.Fragment key={`section_key_${sectionIndex}`}>
                  <InnerBox>
                    <div className='section_header'>
                      <TitleForSection>{sectionItem.section_name}</TitleForSection>
                      <button
                        style={{
                          width: '1.25rem',
                          height: '1.25rem',
                          borderRadius: '50%',
                          backgroundColor: '#ECECEC',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: 'none',
                        }}
                        onClick={() => handleDeleteSection(sectionItem.section_seq)}>
                        <ImgSvgIconX
                          style={{
                            width: '0.75rem',
                            height: '0.75rem',
                            color: '#A6B3BE',
                          }}
                        />
                      </button>
                    </div>

                    <div className='inner_box_contents_wrap'>
                      {isMultiPart && (
                        <div className='part_info'>
                          <div>Part</div>
                          <div style={{ display: 'flex', gap: '2rem', marginRight: '0.5rem' }}>
                            {sectionItem.ap_template_parts.map((partItem, partIndex) => (
                              <div className='part_header' key={`part_${partItem.part_seq}`}>
                                {/* part_name 에서 Part 빼고 보여주기 */}
                                <h5 className='part_title'>{partItem.part_name.replace('Part', '')}</h5>
                                <button
                                  style={{
                                    width: '1.25rem',
                                    height: '1.25rem',
                                    borderRadius: '50%',
                                    backgroundColor: '#ECECEC',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'none',
                                  }}
                                  onClick={() => handleDeletePart(partItem.part_seq)}>
                                  <ImgSvgIconX
                                    style={{
                                      width: '0.75rem',
                                      height: '0.75rem',
                                      color: '#A6B3BE',
                                    }}
                                  />
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      <InputWrapForInnerBox>
                        <LabelTextForInnerBox>Question</LabelTextForInnerBox>
                        <div className='input_wrap'>
                          {sectionItem.ap_template_parts.map((partItem, partIndex) => {
                            const isMultipleQuestion = partItem.type_of_question === 'M';
                            return (
                              <div className='first_row' key={`name_key_${partIndex}`}>
                                <InputStyleWrap02>
                                  <CommonNumberInput
                                    defaultValue={partItem.number_of_questions}
                                    handleCallback={(newValue) => {
                                      updatePart({
                                        sectionSeq: sectionItem.section_seq,
                                        partSeq: partItem.part_seq,
                                        key: 'number_of_questions',
                                        value: newValue,
                                      });

                                      if (isMultipleQuestion) {
                                        updatePart({
                                          sectionSeq: sectionItem.section_seq,
                                          partSeq: partItem.part_seq,
                                          key: 'total_raw_score',
                                          value: newValue,
                                        });
                                      }
                                    }}
                                    maxNumber={999}
                                    minNumber={1}
                                    placeholder='questions'
                                  />
                                </InputStyleWrap02>
                              </div>
                            );
                          })}
                        </div>
                      </InputWrapForInnerBox>
                      <InputWrapForInnerBox>
                        <LabelTextForInnerBox>Exam Duration (min)</LabelTextForInnerBox>
                        <div className='input_wrap'>
                          {sectionItem.ap_template_parts.map((partItem, partIndex) => {
                            return (
                              <InputStyleWrap02 key={`wrap_key_${partIndex}`}>
                                <CommonNumberInput
                                  defaultValue={partItem.part_time}
                                  handleCallback={(newValue) => {
                                    updatePart({
                                      sectionSeq: sectionItem.section_seq,
                                      partSeq: partItem.part_seq,
                                      key: 'part_time',
                                      value: newValue,
                                    });
                                  }}
                                  maxNumber={999}
                                  minNumber={1}
                                  placeholder='Score'
                                />
                              </InputStyleWrap02>
                            );
                          })}
                        </div>
                      </InputWrapForInnerBox>
                      <InputWrapForInnerBox>
                        <LabelTextForInnerBox>Raw Score</LabelTextForInnerBox>
                        <div className='input_wrap'>
                          {sectionItem.ap_template_parts.map((partItem, partIndex) => {
                            /** 현재 시험이 객관식 시험인지 여부 */
                            const isMultipleQuestion = partItem.type_of_question === 'M';
                            return isMultipleQuestion ? (
                              <InputStyleWrap02 key={`wrap2_key_${partIndex}`}>
                                <input type='number' value={partItem.total_raw_score} readOnly />
                              </InputStyleWrap02>
                            ) : (
                              <InputStyleWrap02 key={`wrap3_key_${partIndex}`}>
                                <CommonNumberInput
                                  defaultValue={partItem.total_raw_score}
                                  handleCallback={(newValue) => {
                                    updatePart({
                                      sectionSeq: sectionItem.section_seq,
                                      partSeq: partItem.part_seq,
                                      key: 'total_raw_score',
                                      value: newValue,
                                    });
                                  }}
                                  maxNumber={999}
                                  minNumber={1}
                                  placeholder='Score'
                                />
                              </InputStyleWrap02>
                            );
                          })}
                        </div>
                      </InputWrapForInnerBox>
                      <InputWrapForInnerBox>
                        <LabelTextForInnerBox>Weighted Score (%)</LabelTextForInnerBox>
                        <div className='input_wrap'>
                          {sectionItem.ap_template_parts.map((partItem, partIndex) => {
                            return (
                              <InputStyleWrap02 key={`Weighted_key_${partIndex}`}>
                                <CommonNumberInput
                                  defaultValue={partItem.weight_of_part_score}
                                  handleCallback={(newValue) => {
                                    updatePart({
                                      sectionSeq: sectionItem.section_seq,
                                      partSeq: partItem.part_seq,
                                      key: 'weight_of_part_score',
                                      value: newValue,
                                    });
                                  }}
                                  maxNumber={999}
                                  minNumber={1}
                                  placeholder='Score'
                                  allowDecimal={2}
                                />
                              </InputStyleWrap02>
                            );
                          })}
                        </div>
                      </InputWrapForInnerBox>
                      {isMultiPart && (
                        <InputWrapForInnerBox>
                          <LabelTextForInnerBox key={`break_time_${sectionIndex}`}>Break time (min)</LabelTextForInnerBox>
                          <div className='input_wrap'>
                            {sectionItem.ap_template_parts.map((partItem, partIndex, partArray) => {
                              // 파트 배열 중 마지막 요소가 아닐 경우에만 브레이크 타임 입력 요소 렌더링
                              return partIndex < partArray.length - 1 ? (
                                <InputStyleWrap02 key={`break_time_key_${partIndex}`}>
                                  <CommonNumberInput
                                    defaultValue={partItem.break_time}
                                    handleCallback={(newValue) => {
                                      updatePart({
                                        sectionSeq: sectionItem.section_seq,
                                        partSeq: partItem.part_seq,
                                        key: 'break_time',
                                        value: newValue,
                                      });
                                    }}
                                    maxNumber={999}
                                    minNumber={1}
                                    placeholder='Score'
                                  />
                                </InputStyleWrap02>
                              ) : (
                                <div className='empty' key={`empty_${partIndex}`} />
                              );
                            })}
                          </div>
                        </InputWrapForInnerBox>
                      )}
                    </div>
                  </InnerBox>
                  {inputValues[sectionIndex + 1] ? (
                    // Sections 배열 중 다음 요소가 존재하면, 현재 Section에 쉬는 있는 것으로 판단 (그냥 break_time 값 여부로 판단해버리면, input 필드가 비었을 경우에도 조건부 렌더링이 안된다.)
                    <InputWrapForInnerBox className='breaktime_input_wrap'>
                      <LabelText02>Section Break Time (min)</LabelText02>
                      <InputStyleWrap02>
                        <CommonNumberInput
                          defaultValue={sectionItem.break_time}
                          handleCallback={(newValue) => {
                            updatePart({
                              sectionSeq: sectionItem.section_seq,
                              key: 'break_time',
                              value: newValue,
                            });
                          }}
                          maxNumber={999}
                          minNumber={1}
                          placeholder='Score'
                        />
                      </InputStyleWrap02>
                    </InputWrapForInnerBox>
                  ) : (
                    ''
                  )}
                </React.Fragment>
              )
            );
          })}
        </S.Wrap>
        <PrimaryButtonArea handleNext={handleNext} handleCancel={handleCancel} cancelButtonText={'Back'} className={`${currentStep === 1 ? 'center' : ''}`} />
      </>
    )
  );
}

const S = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    width: 28.75rem;
    padding: 2rem 0 3rem;

    .section_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;
    }

    .part_info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        font-family: Roboto;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    .part_header {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      align-items: center;

      h5 {
        font-family: Roboto;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    .input_container {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.5rem;
      width: 100%;
      input {
        width: 100%;
        &:read-only {
          background-color: #f4f4f4;
          border: 1px solid #d2dbe2;
          color: #a6b3be;
        }
      }
    }

    .breaktime_input_wrap {
      width: 100%;
      justify-content: center;
      gap: 0.75rem;
    }
  `,
};
