import React, { useState } from 'react';
import styled from 'styled-components';

import LabelText from '../common/styles/LabelText';

import { ReactComponent as ImgSvgArrow } from 'assets/img/svg/icon_arrow_02.svg';
import Input from '../common/styles/Input';

/** "Ap" - "Question Bank" - "Create Test" 페이지의 - "Advanced setting" 영역 컴포넌트 */
export default function AdvancedSetting() {
  /** Advanced setting" 영역이 펼쳐져 있는지 여부 */
  const [isAdvancedSettingOpen, setIsAdvancedSettingOpen] = useState(false);

  /** "Advanced setting" 버튼 클릭 핸들러 */
  const handleToggleAdvancedSettingAreaView = () => {
    setIsAdvancedSettingOpen((current) => !current);
  };

  return (
    <S.Wrap>
      <button type='button' title='open/close' onClick={handleToggleAdvancedSettingAreaView}>
        <LabelText className={`toggle_btn ${isAdvancedSettingOpen ? 'on' : ''}`}>
          Advanced setting <ImgSvgArrow />
        </LabelText>
      </button>
      {isAdvancedSettingOpen && (
        <div className='advanced_setting_detail_area'>
          <form>
            <ul className='list_depth_01'>
              <li>
                <dl>
                  <dt>
                    <LabelText>Number of sections</LabelText>
                  </dt>
                  <dd>섹션 수 선택</dd>
                </dl>
                <ul>
                  <li>
                    <dl>
                      <dt>
                        <LabelText>number of parts in section 1</LabelText>
                      </dt>
                      <dd>파트 수 선택</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>
                        <LabelText>number of parts in section 2</LabelText>
                      </dt>
                      <dd>파트 수 선택</dd>
                    </dl>
                  </li>
                </ul>
              </li>
              <li>
                <dl>
                  <dt>
                    <LabelText>Break time between sections</LabelText>
                  </dt>
                  <dd>시간 선택</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>
                    <LabelText>automatic Grading table*</LabelText>
                  </dt>
                  <dd>
                    <table className='automatic_grading_table'>
                      <caption>automatic Grading table</caption>
                      <thead>
                        <tr>
                          <th>Raw score</th>
                          <th>Scaled score</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className='range_wrap'>
                              <Input type='number'></Input> ~<Input type='number'></Input>
                            </div>
                          </td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>
                            <div className='range_wrap'>
                              <Input type='number'></Input> ~<Input type='number'></Input>
                            </div>
                          </td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>
                            <div className='range_wrap'>
                              <Input type='number'></Input> ~<Input type='number'></Input>
                            </div>
                          </td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>
                            <div className='range_wrap'>
                              <Input type='number'></Input> ~<Input type='number'></Input>
                            </div>
                          </td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>
                            <div className='range_wrap'>
                              <Input type='number'></Input> ~<Input type='number'></Input>
                            </div>
                          </td>
                          <td>1</td>
                        </tr>
                      </tbody>
                    </table>
                  </dd>
                </dl>
              </li>
            </ul>
          </form>
        </div>
      )}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    .toggle_btn {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      svg {
        width: 1rem;
        transition: 0.2s;
      }

      &.on {
        svg {
          transform: rotateZ(180deg);
        }
      }
    }

    .advanced_setting_detail_area {
      padding: 1rem;
      dl {
        display: flex;
        dt {
          width: 14rem;
          flex-shrink: 0;
        }
      }

      ul.list_depth_01 {
        ul {
          margin-top: 1rem;
          margin-left: 1rem;
        }
        li + li {
          margin-top: 1rem;
        }
      }
    }

    .automatic_grading_table {
      th,
      td {
        border: 1px solid #d2dbe2;
        height: 3rem;
        padding: 0 1rem;
      }
      th {
        background-color: #d2dbe2;
      }

      input {
        width: 4rem;
      }

      .range_wrap {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
  `,
};
