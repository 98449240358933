//#region    ////////// packages
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import CustomSelect from 'components/_common/inputs/CustomSelect';
import useUserLevels from 'hooks/useUserLevels';
import Layout from '../../../_components/_common/Layout';
import MainTitle from '../../_components/title/MainTitle';
import MainTitleArea from '../../_components/title/MainTitleArea';
import SubTitle from '../../_components/title/SubTitle';
import ClickableTableRow from 'components/_common/table/TableRow';
//#endregion ////////// components

//#region    ////////// utils
import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';
//#endregion ////////// utils

/** AP Set Tests : 학원 선택 페이지 */
export default function ApTestsSetSelectClassPage() {
  //#region    ////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  //#endregion //////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [classesInfo, setClassesInfo] = useState({
    classesList: [],
    currentPage: 1,
    numPerPage: 10, // line 수
    pagePerBlock: 10,
    selectedClassSeq: nvl(stateSetTest.selectedClass.classSeq) !== '' ? stateSetTest.selectedClass.classSeq : 0,
    selectedClassName: nvl(stateSetTest.selectedClass.className) !== '' ? stateSetTest.selectedClass.className : '',
  });

  const [searchInfo, setSearchInfo] = useState({
    searchStartDate: '',
    searchEndDate: '',
    searchField: '',
    searchKeyword: '',
  });

  const [teacherList, setTeacherList] = useState([]);

  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const searchKeywordRef = useRef();
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** 최초 선생 목록 API 호출 */
  const getTeachersList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const teachersList = response.result.teacherList;

        setTeacherList(teachersList);
      }
    };

    request.apGet(`/api/v1/academy/teacher`, null, successHandler);
  };

  /** 최초 Class 목록 얻어오기 API 호출 */
  const getClassesList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const classesList = response.result.classList;

        setClassesInfo((prev) => {
          return { ...prev, classesList };
        });
      }
    };

    request.apGet(`/api/v1/academy/class`, null, successHandler);
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const onChangeLineHandler = (value) => {
    setClassesInfo((prev) => ({ ...prev, numPerPage: value }));
  };

  const nextPageMove = (value) => {
    setClassesInfo((prev) => ({ ...prev, currentPage: value }));
  };

  /** 테이블 열 선택 체크박스 핸들러 */
  const onCheckHandler = ({ event, item }) => {
    let parentNode = event.target.parentElement.parentElement; // tr

    parentNodeHandler(parentNode);

    setClassesInfo((prev) => {
      return {
        ...prev,
        selectedClassSeq: item.classSeq,
        selectedClassName: item.className,
      };
    });
  };

  /** 테이블 열 선택 체크박스 조작 함수 */
  const parentNodeHandler = (parentNode) => {
    for (let i = 0; i < parentNode.parentElement.children.length; i++) {
      parentNode?.parentElement?.children[i]?.classList.remove('active');

      if (parentNode?.parentElement?.children[i]?.children[0]?.children[0]) {
        parentNode.parentElement.children[i].children[0].children[0].checked = false;
      }
    }

    parentNode?.classList.add('active');

    if (parentNode?.children[0]?.children[0]) parentNode.children[0].children[0].checked = true;

    if (parentNode?.classList.contains('active')) {
      parentNode.parentElement.children[0].checked = true;
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearchInfo((prev) => ({ ...prev, searchKeyword: e.target.value }));
    }
  };

  const searchClassList = (e) => {
    setSearchInfo((prev) => ({ ...prev, searchKeyword: searchKeywordRef.current.value }));
  };

  // 선생 선택시 해당 선생의 클래스 목록을 얻어오는 API 호출s
  const selectTeacher = (value) => {
    // teacherSeq를 세션 스토리지에 저장
    sessionStorage.setItem('setTest-teacherSeq', value);

    const successHandler = (response) => {
      if (response.code === 200) {
        const classesList = response.result.classList;

        setClassesInfo((prev) => ({ ...prev, classesList }));
      }
    };

    request.apGet(`/api/v1/academy/class?teacherSeq=${value}`, null, successHandler);
  };

  /** ClassList Row 선택 핸들러 */
  const handleClassSelect = (selectedClass) => {
    if (selectedClass.classSeq === classesInfo.selectedClassSeq) {
      setClassesInfo((prev) => ({
        ...prev,
        selectedClassSeq: 0,
        selectedClassName: '',
      }));
    } else {
      setClassesInfo((prev) => ({
        ...prev,
        selectedClassSeq: selectedClass.classSeq,
        selectedClassName: selectedClass.className,
      }));
    }
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    // 권한 확인
    if (useLevels.isAcademy) getTeachersList();
    if (useLevels.isTeacher || useLevels.isTutor) getClassesList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLevelsIsLoading]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <Layout classSeq={classesInfo.selectedClassSeq} selectedClassName={classesInfo.selectedClassName}>
      <MainTitleArea>
        <MainTitle>Class List</MainTitle> <SubTitle>Select a teacher you want to view the class list of.</SubTitle>
      </MainTitleArea>
      <S.Wrap className='com_list'>
        <section className='top'>
          <CustomSelect
            options={[
              { label: 'select teacher', value: null },
              ...(teacherList.length > 0
                ? teacherList.map((item) => {
                    return { label: item.teacherName, value: item.teacherSeq };
                  })
                : []),
            ]}
            onSelect={selectTeacher}
            className={'select_teacher'}
          />

          {/* <article className='search_area'>
            <div className='search'>
              <input ref={searchKeywordRef} type='text' placeholder='Enter keywords' className='input search_area_input' onKeyUp={handleOnKeyPress} />
              <button className='com_btn m blue btn_search' onClick={searchClassList}>
                <i className='svg_icon icon_search blue before'></i>
                SEARCH
              </button>
            </div>
            <CustomSelect
              options={[
                { label: '10 line', value: 10 },
                { label: '25 line', value: 25 },
                { label: '50 line', value: 50 },
                { label: '100 line', value: 100 },
              ]}
              onSelect={onChangeLineHandler}
            />
          </article> */}
        </section>
        <table className='list'>
          <colgroup>
            <col width='56px' />
            <col width='53px' />
            <col width='128px' />
            <col />
            <col width='120' />
            <col width='120' />
            <col width='100' />
          </colgroup>
          <thead>
            <tr>
              {/* <th><input type="checkbox"  className="com_chk" id="chkAll" /><label htmlFor="chkAll">&nbsp;</label></th> */}
              <th> </th>
              <th>No.</th>
              <th>Subject</th>
              <th>Classes Name</th>
              <th>Start Date </th>
              <th>End Date </th>
              <th>No. of Students</th>
            </tr>
          </thead>
          <tbody>
            {!classesInfo.classesList?.length ? (
              <tr>
                <td colSpan='7'>No results. Please make sure you have searched correct keyword.</td>
              </tr>
            ) : (
              classesInfo.classesList.map((item, index) => (
                <ClickableTableRow
                  item={item}
                  index={classesInfo.classesList.length - index}
                  isSelected={Number(item.classSeq) === Number(classesInfo.selectedClassSeq)}
                  onSelect={handleClassSelect}>
                  <td>{index}</td>
                  <td className='tit_field'>
                    <span className='ellipsis'>{item.subjectName}</span>
                  </td>
                  <td className='tit_field alignL'>
                    <span className='ellipsis'>{item.className}</span>
                  </td>
                  <td>{dayjs(item.classStartDate).format('MM / DD / YYYY')}</td>
                  <td>{dayjs(item.classEndDate).format('MM / DD / YYYY')}</td>
                  <td>{item.studentCount}</td>
                </ClickableTableRow>
              ))
            )}
          </tbody>
        </table>
        {/* <Paging
          totalCount={classesInfo.totalCount}
          currentPage={classesInfo.currentPage}
          numPerPage={classesInfo.numPerPage}
          pagePerBlock={classesInfo.pagePerBlock}
          nextPageMove={nextPageMove}
        /> */}
      </S.Wrap>
    </Layout>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  Wrap: styled.section`
    margin-top: 0.75rem;
    .top {
      .select_teacher {
        width: 15.125rem;
      }
    }
  `,
};
