import {
  SET_ACADEMY_TEACHER_ID,
  SET_ACADEMY_TEACHER_LOGIN_TYPE,
  SET_ACADEMY_TEACHER_USER_EMAIL,
  SET_ACADEMY_TEACHER_USER_MOBILE,
  SET_ACADEMY_TEACHER_USER_NAME,
  SET_ACADEMY_TEACHER_AGREE_PRIVACY_POLICY,
  SET_ACADEMY_TEACHER_AGREE_TERMS_SERVICE,
  SET_ACADEMY_TEACHER_AGREE_NEWS_LETTER,
  SET_ACADEMY_TEACHER_ACADEMY_CODE,
  CLEAR_ACADEMY_TEACHER_STATE,
} from './academyteacher.actionTypes';
import { USER_LEVELS } from 'utils/constants';

// default state
const initialState = {
  userId: '',
  loginType: '',
  userEmail: '',
  userLevel: USER_LEVELS.TEACHER, // 고정
  userMobile: '',
  userName: '',
  academyCode: '',
  agree_privacyPolicy: false,
  agree_termsService: false,
  agree_newsLetter: false,
};

// 전역 상태 Reducer
const academyTeacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACADEMY_TEACHER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case SET_ACADEMY_TEACHER_LOGIN_TYPE:
      return {
        ...state,
        loginType: action.payload,
      };
    case SET_ACADEMY_TEACHER_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload,
      };
    case SET_ACADEMY_TEACHER_USER_MOBILE:
      return {
        ...state,
        userMobile: action.payload,
      };
    case SET_ACADEMY_TEACHER_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };
    case SET_ACADEMY_TEACHER_AGREE_PRIVACY_POLICY:
      return {
        ...state,
        agree_privacyPolicy: action.payload,
      };
    case SET_ACADEMY_TEACHER_AGREE_TERMS_SERVICE:
      return {
        ...state,
        agree_termsService: action.payload,
      };
    case SET_ACADEMY_TEACHER_AGREE_NEWS_LETTER:
      return {
        ...state,
        agree_newsLetter: action.payload,
      };
    case SET_ACADEMY_TEACHER_ACADEMY_CODE:
      return {
        ...state,
        academyCode: action.payload,
      };
    case CLEAR_ACADEMY_TEACHER_STATE:
      return initialState;
    default:
      return state;
  }
};

export default academyTeacherReducer;
