import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

////////// components
import MiniAlert from 'components/_common/alerts/MiniAlert'; //텍스트 알럿
import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import CustomAlert from 'components/_common/alerts/CustomAlert';

////////// hook

////////// Image

import MainLink from 'components/_common/button/_MainLinks';

///
import ContentTopTabs from './_components/ContentTopTabs';
import ByTest from './_components/tapContents/ByTest';
import ByClass from './_components/tapContents/ByClass';
import ProfilePopup from 'components/_common/modals/PopupProfile';

export default function TestsTakenPage() {
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 /////////////////
  /** 커스텀 알럿 */
  let alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };

  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 /////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const navigate = useNavigate();
  const userInfo = request.tokenDecoder();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [scheduleInfo, setScheduleInfo] = useState({
    uthList: [],
    studentInfoList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });

  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  const [tabState, setTabState] = useState(0);

  const [miniAlert, setMiniAlert] = useState({
    active: false,
    text: '',
    type: '',
    timeOut: 0,
  });

  /** 프로필 팝업 */
  const [profileState, setProfileState] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        returnValue: (value) => {
          if (nvl(value) === 'OK') {
            console.log('요기에서 작업');

            // closeCustomAlert();
          }
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertLayerPopup?.id]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  const testTakenTabs = [
    { text: 'By Class', value: 0 },
    { text: 'By Test', value: 1 },
  ];

  return (
    <>
      <S.Wrap className='com_schedule'>
        <MainLink type='test' />
        <div className='com_center_wrap'>
          <ContentTopTabs tabData={testTakenTabs} tabState={tabState} setTabState={setTabState} />
          {tabState === 0 ? (
            <ByClass />
          ) : tabState === 1 ? (
            <ByTest tabState={tabState} scheduleInfo={scheduleInfo} setScheduleInfo={setScheduleInfo} setAlertLayerPopup={setAlertLayerPopup} />
          ) : (
            <></>
          )}
        </div>
      </S.Wrap>
      {miniAlert.active && (
        <MiniAlert
          text={miniAlert.text}
          type={miniAlert.type}
          active={miniAlert.active}
          inactive={() =>
            setMiniAlert((prev) => {
              return { ...prev, active: false };
            })
          }
          timeOut={miniAlert.timeOut}
        />
      )}
      {/*//To Do : 테스트 셋팅 완료 후 Test schedule 페이지로 이동 시 뜨도록*/}
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}

      {/* 학생 상세 프로필 팝업 */}
      {profileState && <ProfilePopup type='student' setProfileState={setProfileState} studentInfo={scheduleInfo.studentInfoList} />}
    </>
  );
}

const S = {
  Wrap: styled.div`
    .com_container {
      background: #f4f8ff;
      padding: 15px;
      border-radius: 10px;
    }
    .full {
      width: 100%;
    }
  `,
};
