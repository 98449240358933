import React, { useState } from 'react';
import styled from 'styled-components';

/** ap/com/score/grading/submission 페이지의 "전체 등급 점수 영역"의 "Section" 컴포넌트 */
export default function SectionScaledScoreArea() {
  const [inputValue, setInputValue] = useState('');

  /** input 태그 onchange 핸들러 */
  const handleOnChangeInput = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <S.Wrap className={`section_scaled_score_area`}>
      <div className='inner_wrap'>
        <h3 className='title'>Section Name</h3>
        <dl>
          <dt>Raw Score</dt>
          <dd>
            <span className='value'>111</span> / 999
          </dd>
        </dl>
        <dl>
          <dt>Scaled Score</dt>
          <dd>
            <input type='text' placeholder='Score' value={inputValue} onChange={handleOnChangeInput} /> / 5
          </dd>
        </dl>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background: #ffffff;
    padding: 0 1.25rem;
    .inner_wrap {
      padding: 1.25rem 0;
      & > .title {
        color: #111;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
    dl {
      display: flex;
      justify-content: space-between;
      & + dl {
        margin-top: 0.65rem;
      }
      dt {
        color: #111;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
      dd {
        color: #111;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        align-items: center;
        gap: 0.55rem;
        .value {
          font-size: 1rem;
          font-weight: 500;
        }
        input {
          width: 4rem;
          height: 2.25rem;
          border-radius: 0.25rem;
          border: 1px solid #d7dadf;
          background: #fff;
          padding: 0.5rem 0.625rem;
        }
      }
    }
  `,
};
