import styled from 'styled-components';
import { ReactComponent as ImgSvgIconPlus } from 'assets/img/svg/icon_plus.svg';
import { ReactComponent as ImgSvgIconMinus } from 'assets/img/svg/icon_minus.svg';

/** AP 시험 세팅에 사용되는 스탭퍼 */
export default function ApSettingStepper({ number, onDecrease, onIncrease }) {
  const handleDecrease = () => {
    onDecrease();
  };

  const handleIncrease = () => {
    onIncrease();
  };

  return (
    <S.NumberControl>
      <button className='decrease' onClick={handleDecrease}>
        <ImgSvgIconMinus className='operator' />
      </button>
      <span className='value'>{number}</span>
      <button className='increase' onClick={handleIncrease}>
        <ImgSvgIconPlus className='operator' />
      </button>
    </S.NumberControl>
  );
}

const S = {
  NumberControl: styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #d7dadf;
    border-radius: 4px;

    button {
      width: 34px;
      height: 34px;
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .operator {
        width: 1.25rem;
        height: 1.25rem;
        color: #767676;
      }

      &.decrease {
        border-right: 1px solid #d7dadf;
      }
      &.increase {
        border-left: 1px solid #d7dadf;
      }
    }

    .value {
      padding: 1px 10px;
      color: #111111;
      font-family: 'Roboto-Regular', Helvetica;
      font-size: 14px;
      line-height: 20px;
    }
  `,
};
