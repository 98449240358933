import React from 'react';
import styled from 'styled-components';
import ImgSvgLogoGateplus from 'assets/img/logo_gateplus_row_02.png';

/** PDF 프린트의 매 페이지마다 반복 될 Footer */
export default function PrintFooter() {
  return (
    <S.Footer>
      <div className='warning_area'>
        <strong>WARNING</strong>: All questions are protected as GATEPLUS’s intellectual property. <br />
        Each PDF generated includes a unique identifier and institution or instructor code enabling the tracking of unauthorized reproduction or distribution.{' '}
        <br />
        Unauthorized copy, use or distribution may result in criminal prosecution for intellectual property infringement.
      </div>
      <div className='logo_area'>
        <p>www.gateplus.org</p>
      </div>
    </S.Footer>
  );
}

const S = {
  Footer: styled.footer`
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 1rem 1.5rem 1.5rem;
    background-color: rgba(255, 255, 255, 0.5);

    .warning_area {
      text-align: left;
      color: #767676;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.125rem;
    }

    .logo_area {
      font-weight: 400;
      font-size: 0.875rem;
      color: #767676;
      text-align: center;
      line-height: 0;
      background: url(${ImgSvgLogoGateplus}) no-repeat top center;
      background-size: 8.25rem;
      padding: 2.75rem 1rem 0;
    }

    @media print {
      display: flex;
    }
  `,
};
