import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// import storage from "redux-persist/lib/storage/session"; // session storage
import storage from 'redux-persist/lib/storage'; // local storage

import Customer from './Customer';
import MenuManager from './MenuManager';
import stateSetTests from './setTests/reducer';
import stateGateKeys from './gateKeys/reducer';
import stateExamInfo from './exam/examInfo/reducer';
import stateExamComponents from './exam/examComponents/reducer';
import userReducer from './auth/reducer';
import academyAdminReducer from './auth/academyadmin.reducer';
import studentReducer from './auth/student.reducer';
import privateTutorReducer from './auth/privatetutor.reducer';
import joinCompanyReducer from './auth/joincompany.reducer';
import academyTeacherReducer from './auth/academyteacher.reducer';
import apTestEditorReducer from './apTestEditor/reducer';
import miniAlertReducer from './miniAlert/miniAlertSlice';

const persistConfig = {
  key: 'dsat_root',
  storage,
  // whitelist: ['Customer'], // storage에 저장
  blacklist: ['miniAlert'], // 로컬 스토리지에 저장하지 않을 리듀서 목록
};

export const rootReducer = combineReducers({
  Customer,
  MenuManager,
  stateExamInfo,
  stateExamComponents,
  stateSetTests,
  stateGateKeys,
  userReducer,
  academyAdminReducer,
  studentReducer,
  privateTutorReducer,
  joinCompanyReducer,
  academyTeacherReducer,
  apTestEditorReducer,
  miniAlert: miniAlertReducer,
});

export default persistReducer(persistConfig, rootReducer);
