import ReactApexChart from 'react-apexcharts';
import styled from 'styled-components';
import adjustColorBrightness from 'utils/adjustColorBrightness';

const defaultChartStyle = {
  countAnimation: false,
  fillOptions: {
    fillColor: '#0068BD',
    brightness: 20,
  },
  chartHollowSize: '40%',
};

// chartHollowSize에 따른 size 조정 함수
const adjustSizeBasedOnHollowSize = (baseSize, hollowSize) => {
  const baseHollowSize = 40; // 기본값
  const sizeAdjustmentFactor = 0.69; // 1% 변화당 0.67% 조정

  const hollowSizeNumber = parseFloat(hollowSize);
  const hollowSizeDifference = baseHollowSize - hollowSizeNumber;

  const adjustmentPercentage = 1 + (hollowSizeDifference * sizeAdjustmentFactor) / 100;

  const baseSizeNumber = parseFloat(baseSize);
  const adjustedSize = baseSizeNumber * adjustmentPercentage;

  return `${adjustedSize.toFixed(3)}rem`;
};

export default function ScoreCircleChart({ rank, size = '4.25rem', chartStyle = {}, CustomLabel = null }) {
  const chartConfig = {
    ...defaultChartStyle,
    ...chartStyle,
    fillOptions: {
      ...defaultChartStyle.fillOptions,
      ...chartStyle.fillOptions,
    },
    size: size,
    adjustedSize: '',
  };

  // chartConfig 내에서 size 조정
  chartConfig.adjustedSize = adjustSizeBasedOnHollowSize(size, chartConfig.chartHollowSize);

  const options = {
    series: [`${rank}`],
    chart: {
      height: '100%',
      width: '100%',
      type: 'radialBar',
      sparkline: { enabled: true }, // 차트 외 모든 요소 숨김, 작은 차트에 최적화
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: chartConfig.chartHollowSize, // 내부 원의 크기를 조절합니다. 값이 클수록 그래프 얇아짐
        },
        dataLabels: {
          // 차트 안에 표시되는 라벨 스타일 속성
          name: {
            show: !CustomLabel, // 라벨 표시 여부 ( customLabel이 있을 경우 false )
            offsetY: 4, // 라벨 위에서부터 거리
            fontSize: '10px',
            color: '#111',
            fontWeight: 'bold',
          },
          value: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      style: {
        fontSize: '5px',
      },
    },

    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          {
            offset: 0, //그라데이션의 위치 0~100
            color: chartConfig.fillOptions.fillColor, // 시작 색상
            opacity: 0.7, //그라데이션의 투명도 0~1
          },
          {
            offset: 100,
            color: adjustColorBrightness(chartConfig.fillOptions.fillColor, chartConfig.fillOptions.brightness), // 끝 색상
            opacity: 1,
          },
        ],
      },
    },
    labels: [`${rank}${rank !== '-' ? '%' : ''}`],
  };

  return (
    <S.Wrap id='chart' $size={size}>
      <S.ChartWrapper $size={chartConfig.adjustedSize}>
        {CustomLabel && <S.CustomLabel>{CustomLabel}</S.CustomLabel>}

        <ReactApexChart options={options} series={options.series} type='radialBar' width='100%' height='100%' />
      </S.ChartWrapper>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: ${(props) => props.$size};
    height: ${(props) => props.$size};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
  ChartWrapper: styled.div`
    width: ${(props) => props.$size};
    height: ${(props) => props.$size};
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1.5);
    position: relative;
  `,
  CustomLabel: styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    transform: scale(0.6667);
    justify-content: center;
  `,
};
