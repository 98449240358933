import React from 'react';
import styled from 'styled-components';

/** "Ap" - "Question Bank" - "Create Test" 페이지의 "Advanced Setting" 에서 사용 할 "Label" 요소 스타일 용 컴포넌트 Type 1 */
export default function LabelText({ children, ...rest }) {
  return <S.Wrap {...rest}>{children}</S.Wrap>;
}

const S = {
  Wrap: styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #7f8998;
  `,
};
