import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconEmail } from 'assets/img/svg/icon_email.svg';
import { ReactComponent as ImgSvgIconSchool } from 'assets/img/svg/icon_school_building.svg';

import Box from '../../common/Box';
import DropdownButton from './_components/DropdownButton';

import ProfileImage from 'components/_common/ProfileImage';

/** 아카데미 회원 - 대시보드 - Score - Student 페이지의 프로필 영역 컴포넌트 */
export default function Profile({ studentInfo }) {
  const defaultInfo = {
    userSeq: 0,
    upFileSeq: 0,
    userName: 'PLEASE SELECT STUDENT',
    userEmail: '-',
    studentSchool: '-',
    classList: [
      {
        classSeq: 0,
        className: '-',
      },
    ],
  };

  // studentInfo가 유효하지않으면 defaultInfo 사용
  const info = studentInfo ?? defaultInfo;

  return (
    <S.Wrap>
      <Box className='main_box'>
        <div className='top_area'>
          <ProfileImage
            size='6.875rem'
            userInfo={{
              userName: info.userName,
              userSeq: info.userSeq,
              upFileSeq: info.upFileSeq,
            }}
          />
          <div className='user_name'>{info.userName}</div>
        </div>
        <div className='class_area'>
          <h5>Class</h5>
          <DropdownButton classList={info.classList} />
        </div>
        <table>
          <tbody>
            <tr className='email_area'>
              <th>
                <ImgSvgIconEmail />
                <span>Email</span>
              </th>
              <td>{info.userEmail}</td>
            </tr>
            <tr className='school_area'>
              <th>
                <ImgSvgIconSchool />
                <span>School</span>
              </th>
              <td>{info.studentSchool}</td>
            </tr>
          </tbody>
        </table>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .main_box {
      width: 17.188rem;
      padding: 2rem 1.5rem;
      box-sizing: border-box;
      height: 26.875rem;
    }
    .top_area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .profile_img {
      width: 6.875rem;
      height: 6.875rem;
      border-radius: 100%;
      background: linear-gradient(0deg, #c8cdff 0%, #008cff 100%);
    }
    .user_name {
      font-weight: 600;
      font-size: 0.875rem;
      color: #111111;
      padding: 0.5rem 0 1rem;
      border-bottom: 1px solid #f1f1f5;
      width: 100%;
      text-align: center;
    }
    .class_area {
      padding-top: 1rem;
      h5 {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 0.813rem;
        color: #7f8998;
        padding-bottom: 0.25rem;
      }
    }
    table {
      margin-top: 1.5rem;
      width: 100%;

      tr {
        &.email_area,
        &.school_area {
          display: flex;
          justify-content: space-between;
          padding: 0.625rem 0;

          th,
          td {
            font-weight: 400;
            font-size: 0.813rem;
          }
          th {
            color: #7f8998;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            width: fit-content;
          }
          td {
            text-align: right;
            color: #4b5563;
          }
          svg {
            width: 1.25rem;
            color: #0068bd;
          }
        }
      }
    }
  `,
};
