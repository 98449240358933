import React from 'react';
import styled from 'styled-components';

/** Form 영역의 에러 메세지 스타일 용 컴포넌트 */
export default function ErrorText({ children, errer_type = 'error' }) {
  if (errer_type === 'error') {
    return <S.Wrap>{children}</S.Wrap>;
  }
  if (errer_type === 'check-error') {
    return <S_2.Wrap>{children}</S_2.Wrap>;
  }
}

const S = {};

S.Wrap = styled.div`
  color: var(--sub-red);
  text-align: left;
  font-weight: 400;
  font-size: 0.813rem;
  margin-top: 0.5rem;
`;
const S_2 = {};
S_2.Wrap = styled.div`
  color: var(--sub-red);
  text-align: left;
  font-weight: 400;
  font-size: 0.813rem;
  margin-top: 0;
`;
