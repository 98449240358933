import React, { useEffect, useState } from 'react';
import request from 'utils/Request.utils.js';
import { nvl } from 'utils/Common.utils.js';

import dayjsTZ, { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

//components
import Paging from 'layout/Paging'; //페이징
import MainLink from 'components/_common/button/_MainLinks.jsx';
import ClassesMainTable from './_components/table/classesMainTableArea/ClassesMainTable.jsx';
import TopBtnArea from './_components/table/classesMainTableArea/TopBtnArea.jsx';
import CustomAlert from 'components/_common/alerts/CustomAlert.jsx';
import SearchArea from './_components/table/classesMainTableArea/SearchArea.jsx';
import styled from 'styled-components';

// import PdfDownloadButton from '../_common/PdfDownloadButton.jsx'

/**
 * classes 페이지
 * @description
 * 🔍 검색 키워드 - #classes #page #com/students/classes
 */
export default function ClassesMainPage() {
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 시작 ////////////
  /** CustomAlert 코드 */
  let alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 끝 /////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [userInfo, _] = useState(request.tokenDecoder());
  const [lineLength, setLineLength] = useState(10);
  const [openClassManage, setOpenClassManage] = useState(false);
  /** Edit 버튼 코드 */
  const [editClassInfo, setEditClassInfo] = useState(null);
  const [searchInfo, setSearchInfo] = useState({
    isDateRangeSelected: false,
    searchStartDate: dayjsTZ().toDate(),
    searchEndDate: dayjsTZ().toDate(),
  });
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchData, setSearchData] = useState([]);
  /** check 버튼 코드 */
  const [checkedClassIds, setCheckedClassIds] = useState([]);
  const [sortStartDate, setSortStartDate] = useState(0);
  const [sortEndDate, setSortEndDate] = useState(0);
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  const [pagingInfo, setPagingInfo] = useState({
    totalCount: 10,
    currentPage: 1,
    numPerPage: lineLength,
    pagePerBlock: 10,
  });
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const handleShowEdit = (classId = null) => {
    if (classId === null) {
      if (checkedClassIds.length > 1 || checkedClassIds.length === 0) {
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'You can only edit one class at a time',
          };
        });
        return;
      }
    }

    classId = classId || checkedClassIds[0];

    let selectedEditData = searchData.find((data) => data.classId === classId);
    selectedEditData = {
      ...selectedEditData,
      startDate: selectedEditData.startDate,
      endDate: selectedEditData.endDate,
    };
    setEditClassInfo(selectedEditData);
    setOpenClassManage(true);
  };

  const searchClasses = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const { totalCount } = response.result;
        setPagingInfo((prev) => {
          return { ...prev, totalCount, pagePerBlock: 10 };
        });

        const searchData = response.result.classesList.map((data) => {
          return {
            classId: data.classSeq,
            classTitle: data.className,
            startDate: isoTimeToDayjs(data.classStartDate),
            endDate: isoTimeToDayjs(data.classEndDate),
            studentList: data.studentList ? JSON.parse(data.studentList) : [],
            testScheduled: data.testHistoryScheduled ? JSON.parse(data.testHistoryScheduled) : [],
            testTaken: data.testHistoryTaken ? JSON.parse(data.testHistoryTaken) : [],
          };
        });
        setSearchData(searchData);
      }
    };

    let orderBy = 'reg_date DESC';

    if (sortStartDate === 1) orderBy = 'class_start_date DESC, reg_date DESC';
    if (sortStartDate === 2) orderBy = 'class_start_date ASC, reg_date DESC';
    if (sortEndDate === 1) orderBy = 'class_end_date DESC, reg_date DESC';
    if (sortEndDate === 2) orderBy = 'class_end_date ASC, reg_date DESC';

    const searchStartDate = encodeURIComponent(dayjsTZ(searchInfo.searchStartDate).startOf('day').format());
    const searchEndDate = encodeURIComponent(dayjsTZ(searchInfo.searchEndDate).endOf('day').format());

    // 전체검색, 날짜검색, 날짜검색&키워드검색
    // 검색 날짜를 선택 안하고, searchKeyword가 빈문자열(검색어 지정 안함)이면 - 전체 날짜, 모든 키워드 검색
    if (!searchInfo.isDateRangeSelected && searchKeyword === '') {
      request.get(
        `/api/academy/classes?ecSeq=${userInfo.ecSeq}&orderBy=${orderBy}&limit=${lineLength}&offset=${(pagingInfo.currentPage - 1) * lineLength}`,
        null,
        successHandler
      );
      // 검색 날짜가 지정되고, searchKeyword가 빈문자열(검색어 지정 안함)이면 - 지정 날짜, 모든 키워드 검색
    } else if (searchInfo.isDateRangeSelected && searchKeyword === '') {
      request.get(
        `/api/academy/classes?ecSeq=${userInfo.ecSeq}&orderBy=${orderBy}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}&limit=${lineLength}&offset=${(pagingInfo.currentPage - 1) * lineLength}`,
        null,
        successHandler
      );
      // 검색 날짜를 선택 안하고, searchKeyword에 검색어가 지정되면 - 전체 날짜, 지정 키워드 검색
    } else if (!searchInfo.isDateRangeSelected && searchKeyword !== '') {
      request.get(
        `/api/academy/classes?ecSeq=${userInfo.ecSeq}&orderBy=${orderBy}&searchKeyword=${encodeURIComponent(searchKeyword)}&limit=${lineLength}&offset=${(pagingInfo.currentPage - 1) * lineLength}`,
        null,
        successHandler
      );
      // 검색 날짜가 지정되고, searchKeyword에 검색어가 지정되면 - 지정 날짜, 지정 키워드 검색
    } else {
      request.get(
        `/api/academy/classes?ecSeq=${userInfo.ecSeq}&orderBy=${orderBy}&searchKeyword=${encodeURIComponent(searchKeyword)}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}&limit=${lineLength}&offset=${(pagingInfo.currentPage - 1) * lineLength}`,
        null,
        successHandler
      );
    }
  };

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  const nextPageMove = (value) => {
    setPagingInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 ////////////////
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        returnValue: (value) => {
          if (nvl(value) === 'OK') {
            // closeCustomAlert()
          }
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertLayerPopup?.id]);

  useEffect(() => {
    if (userInfo.ecSeq > 0) searchClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingInfo.currentPage, pagingInfo.numPerPage, searchInfo, sortStartDate, sortEndDate]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <S.Wrap className='p_classes'>
        <MainLink type='students' />
        <div className='com_center_wrap'>
          <div className='com_box'>
            <div className='com_list'>
              <section className='top'>
                <TopBtnArea
                  setEditClassInfo={setEditClassInfo}
                  setOpenClassManage={setOpenClassManage}
                  checkedClassIds={checkedClassIds}
                  setAlertLayerPopup={setAlertLayerPopup}
                  searchInfo={searchInfo}
                  searchKeyword={searchKeyword}
                  searchData={searchData}
                  searchClasses={searchClasses}
                  handleShowEdit={handleShowEdit}
                  openClassManage={openClassManage}
                  editClassInfo={editClassInfo}
                />
                <SearchArea
                  setSearchInfo={setSearchInfo}
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  searchClasses={searchClasses}
                  setLineLength={setLineLength}
                  setPagingInfo={setPagingInfo}
                />
              </section>
              <ClassesMainTable
                setCheckedClassIds={setCheckedClassIds}
                searchData={searchData}
                setSortStartDate={setSortStartDate}
                sortStartDate={sortStartDate}
                setSortEndDate={setSortEndDate}
                sortEndDate={sortEndDate}
                checkedClassIds={checkedClassIds}
                lineLength={lineLength}
                pagingInfo={pagingInfo}
                handleShowEdit={handleShowEdit}
              />
            </div>
            <Paging
              totalCount={pagingInfo.totalCount}
              currentPage={pagingInfo.currentPage}
              numPerPage={pagingInfo.numPerPage}
              pagePerBlock={pagingInfo.pagePerBlock}
              nextPageMove={nextPageMove}
            />
          </div>
        </div>
      </S.Wrap>

      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </>
  );
}

const S = {
  Wrap: styled.div`
    position: relative;
  `,
};
