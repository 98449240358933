import ImgAssistiveTechnology from 'assets/img/exam/assistive_technology.svg';
import ImgFileCheck from 'assets/img/exam/file_check_02.svg';
import ImgIconClock from 'assets/img/exam/icon_clock.svg';
import ImgLockUnlocked from 'assets/img/exam/lock_unlocked_01.svg';

/** 초 시간을 분 단위로 반환 */
const getTimeText = (time) => {
  const dividedTime = time / 60;
  const hours = Math.floor(dividedTime / 60);
  const minutes = Math.floor(dividedTime % 60);

  const hourText = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
  const minuteText = minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';

  if (hours > 0 && minutes > 0) return `${hourText} ${minuteText}`;
  return hourText || minuteText || '0 minutes';
};
/** 파트 배열을 받아 전체 시간을 시:분 형식으로 반환 */
const getTotalTimeText = (partInfo) => {
  const totalTime = partInfo.reduce((acc, cur) => acc + cur.partExamTime, 0);
  return getTimeText(totalTime);
};
/** 파트 배열을 받아 일치하는 파트의 시간을 시:분 형식으로 반환 */
const getPartTimeText = (partInfo, partNo) => getTimeText(partInfo.find((part) => part.partNo === partNo).partExamTime);

/** 테스트 정보를 받아 타이밍 텍스트를 반환 */
const getTimingText = (testInfo) => {
  if (!testInfo) return ''; // 테스트 정보가 없는 경우 실행 안함

  const { subjectName, partInfo } = testInfo;
  const totalTime = partInfo.reduce((acc, cur) => acc + cur.partExamTime, 0);
  const totalTimeText = getTimeText(totalTime);

  // 2개의 파트로 나누어진 경우
  if (partInfo.length === 2) {
    return `The ${subjectName} exam has a total duration of ${totalTimeText}. The exam is divided into two sections.

    Section 1 lasts for a total of ${getTimeText(partInfo[0].partExamTime)} and Section 2 lasts for a total of ${getTimeText(partInfo[1].partExamTime)}.

    Once you begin the exam, you must complete each section within the given time limits. If you exit the test, your work will be graded based on what you have completed up to that point, and you will not be able to return to the test.`;
  }
  // 3개의 파트로 나누어진 경우
  else if (partInfo.length === 3) {
    const section1Part = partInfo.filter((part) => part.sectionNo === 1);
    const section2Part = partInfo.filter((part) => part.sectionNo === 2);

    const section1TimeText =
      section1Part.length === 1
        ? `Section 1 lasts for a total of ${getTimeText(section1Part[0].partExamTime)}.`
        : `Section 1 lasts for a total of ${getTotalTimeText(section1Part)}, with Part A lasting ${getPartTimeText(section1Part, 1)} and Part B lasting ${getPartTimeText(section1Part, 2)}.`;

    const section2TimeText =
      section2Part.length === 1
        ? `Section 2 lasts for a total of ${getTimeText(section2Part[0].partExamTime)}.`
        : `Section 2 lasts for a total of ${getTotalTimeText(section2Part)}, with Part A lasting ${getPartTimeText(section2Part, 1)} and Part B lasting ${getPartTimeText(section2Part, 2)}.`;

    return `The ${subjectName} exam has a total duration of ${totalTimeText}. The exam is divided into two sections.
    
    ${section1TimeText}
    ${section2TimeText}
    
    Once you begin the exam, you must complete each section within the given time limits. If you exit the test, your work will be graded based on what you have completed up to that point, and you will not be able to return to the test.`;
  }
  // 4개의 파트로 나누어진 경우
  else if (partInfo.length === 4) {
    const section1Part = partInfo.filter((part) => part.sectionNo === 1);
    const section2Part = partInfo.filter((part) => part.sectionNo === 2);

    return `The ${subjectName} exam has a total duration of ${totalTimeText}. The exam is divided into two sections.
    
    Section 1 lasts for a total of ${getTotalTimeText(section1Part)}, with Part A lasting ${getPartTimeText(section1Part, 1)} and Part B lasting ${getPartTimeText(section1Part, 2)}.
    Section 2 lasts for a total of ${getTotalTimeText(section2Part)}, with Part A lasting ${getPartTimeText(section2Part, 1)} and Part B lasting ${getPartTimeText(section2Part, 2)}.

    Once you begin the exam, you must complete each section within the given time limits. If you exit the test, your work will be graded based on what you have completed up to that point, and you will not be able to return to the test.`;
  }
  // 그 외 (예외처리)
  else {
    return '';
  }
};

/** 시험 : 튜토리얼 페이지의 Body 영역 */
export default function ExamTutorialBody({ testInfo }) {
  return (
    <section className='pop_body'>
      <div className='tit'>Practice Test</div>
      <div className='box' style={{ width: '440px' }}>
        <ul>
          <li>
            <img src={ImgIconClock} alt='Timing' style={{ width: '30px' }} />
            <span className='fontWeight'>Timing</span>
          </li>
          <li className='txt' style={{ whiteSpace: 'pre-line' }}>
            {getTimingText(testInfo)}
          </li>
          <li>
            <img src={ImgFileCheck} alt='Score' style={{ width: '30px' }} />
            <span className='fontWeight'>Scores</span>
          </li>
          <li className='txt'>
            When you finish the test, go to <b>Your Most Recent Test</b> section of your dashboard to see your scores. If you want to see past test scores,
            click on Test List and choose the test you want to see.
          </li>
          <li>
            <img src={ImgAssistiveTechnology} alt='Assistive Technology' style={{ width: '30px' }} />
            <span className='fontWeight'>Assistive Technology</span>
          </li>
          <li className='txt'>Currently, we do not support this feature. Please stay tuned for updates!</li>
          <li>
            <img src={ImgLockUnlocked} alt='No Device Lock' style={{ width: '30px' }} />
            <span className='fontWeight'>No Device Lock</span>
          </li>
          <li className='txt'>
            We don’t lock down our tests. If you accidentally close the testing window, your work will automatically be saved and you will be able to return to
            the exam but your test timer will continue ticking. On the actual test, you will not be able to access other programs or apps.
          </li>
        </ul>
      </div>
    </section>
  );
}
