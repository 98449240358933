import React, { useState } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import StackedColumnsChart from 'components/_common/chart/StackedColumnsChart';
import SimpleDonutChart from 'components/_common/chart/SimpleDonutChart';
import LineAreaChart from 'components/_common/chart/LineAreaChart';
import BarChart from 'components/_common/chart/BarChart';
import LineChart from 'components/_common/chart/LineChart';
import GroupedBarChart from 'components/_common/chart/GroupedBarChart';
import SplineChart from 'components/_common/chart/SplineChart';

dayjs.extend(isSameOrBefore);

/** 차트 개발 담당자 전용. 전체 차트 보여주는 페이지 */
const AllChartViewPage = () => {
  const [colChartData, setColChartData] = useState({
    series: [
      {
        name: 'Currently tasking',
        data: [3, 8, 6, 4, 5, 3],
      },
      {
        name: 'Class Status',
        data: [13, 23, 18, 15, 16, 14],
      },
      {
        name: 'Not taking test',
        data: [4, 15, 12, 10, 13, 11],
      },
    ],
    xaxis: {
      type: 'category',
      categories: ['A', 'B', 'C', 'D', 'E', 'F'],
      title: {
        text: 'Class',
      },
    },
    yaxis: {
      title: {
        text: '인원', // y축 레이블
        rotate: 0, // 레이블을 가로로 표시
        offsetX: -5, // 오른쪽으로 이동
        offsetY: -115, // 아래로 이동
      },
    },
    colors: ['#000000', '#0068BD', '#949494'],
  });

  const [donutChartData, setDonutChartData] = useState({
    data: [40, 30, 100],
    legend: {
      show: false,
    },
  });

  const getRandomAccumulatedNumbers = (arrLen) => {
    const minInterval = 5;
    const maxInterval = 20;
    const count = arrLen;

    let accumulatedValue = 0;

    return Array.from({ length: count }, () => {
      const randomInterval = Math.floor(Math.random() * (maxInterval - minInterval + 1)) + minInterval;
      accumulatedValue += randomInterval;
      return accumulatedValue;
    });
  };

  const getWeekDateArr = () => {
    const now = dayjs().tz();
    const prevOneWeek = now.subtract(6, 'day');

    const dateArray = [];
    for (let currentDate = prevOneWeek; currentDate <= now; currentDate = currentDate.add(1, 'day')) {
      dateArray.push(currentDate.format('MM/DD'));
    }

    return dateArray;
  };

  const getRandomNumbersArr = (arrLen) => {
    const min = 200;
    const max = 800;

    return Array.from({ length: arrLen }, () => Math.floor(Math.random() * (max - min + 1)) + min);
  };

  const [dateArray, setDateArray] = useState(getWeekDateArr());

  const randomLabels1 = getRandomAccumulatedNumbers(dateArray?.length);
  const randomLabels2 = getRandomAccumulatedNumbers(dateArray?.length);

  const randomLabels3 = getRandomNumbersArr(dateArray?.length);
  const randomLabels4 = getRandomNumbersArr(dateArray?.length);

  const [lineAreaChartData, setLineAreaChartData] = useState([
    {
      name: 'CUMULATIVE TESTS',
      type: 'area',
      data: randomLabels1,
    },
    {
      name: 'CUMULATIVE STUDENTS',
      type: 'line',
      data: randomLabels2,
    },
  ]);

  const getDateArray = (type) => {
    const now = dayjs.tz();

    const dateArray = [];

    switch (type) {
      case 'week':
        console.log('week ');
        const prevOneWeek = now.subtract(6, 'day');
        for (let currentDate = prevOneWeek; currentDate.isSameOrBefore(now, 'day'); currentDate = currentDate.add(1, 'day')) {
          dateArray.push(currentDate.format('MM/DD'));
        }

        setDateArray(dateArray);
        break;

      case 'month':
        console.log('month ');
        dateArray.push(now.format('MM/DD'));
        for (let i = 0; i < 7; i++) {
          const currentDate = now.subtract(1, 'month');
          dateArray.push(currentDate.format('MM/DD'));
        }

        setDateArray(dateArray.reverse());
        break;

      case '3month':
        dateArray.push(dayjs(now).format('YYYY-MM-DD'));
        for (let i = 1; i <= 5; i++) {
          const previousDate = dayjs(now).subtract(i * 15, 'day');
          dateArray.push(previousDate.format('YYYY-MM-DD'));
        }

        setDateArray(dateArray.reverse());
        break;

      case 'year':
        dateArray.push('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
        setDateArray(dateArray);
        break;

      default:
        break;
    }

    return dateArray;
  };

  const [barChartData, setBarChartData] = useState([
    {
      data: [400, 430, 448, 470],
      xaxisCategories: ['C&S', 'I&I', 'SEC', 'EOI'],
      horizontal: true,
      borderRadius: 8,
      columnWidth: '10%',
      colors: ['#FFA88C', '#8FFF8C', '#8CC1FF', '#A38CFF'],
    },
  ]);

  const [barChartData1, setBarChartData1] = useState([
    {
      data: [510, 420],
      xaxisCategories: ['Reading & Writing', 'Math'],
      horizontal: true,
      borderRadius: 14,
      columnWidth: '10%',
      colors: ['#008CFF', '#47F5C0'],
    },
  ]);

  const getRandomNumbers = () => {
    const min = 50;
    const max = 500;
    const step = 50;
    const count = 27;

    return Array.from({ length: count }, () => Math.floor((Math.random() * (max - min + 1)) / step) * step + min);
  };

  const randomLabels = getRandomNumbers();

  const [timePerQuestionData, setTimePerQuestionData] = useState({
    series: [
      {
        name: 'Class/Student',
        data: randomLabels,
      },
      {
        name: 'GATE',
        data: randomLabels,
      },
    ],
    xaxis: {
      type: 'category',
      categories: Array.from({ length: 27 }, (_, i) => i + 1), // 1부터 27까지의 레이블 생성
      title: {
        text: 'Q. No.',
        offsetX: -630,
        offsetY: -30,
      },
    },
    yaxis: {
      title: {
        text: 'Sec', // y축 레이블
        rotate: 0, // 레이블을 가로로 표시
        offsetX: -5, // 오른쪽으로 이동
        offsetY: -115, // 아래로 이동
      },
    },
    colors: ['#0068BD', '#949494'],
  });

  const [groupedBarChart, setGroupedBarChart] = useState({
    series: [
      {
        name: 'Class/Student',
        data: [800, 1000],
      },
      {
        name: 'GATE+',
        data: [500, 700],
      },
    ],
  });

  const [classBarChartData, setClassBarChartData] = useState([
    {
      data: [1600, 1800, 1100, 800, 1600],
      xaxisCategories: ['A', 'B', 'C', 'D', 'E'],
      horizontal: false,
      borderRadius: 0,
      columnWidth: '50%',
      colors: ['#0068BD'],
    },
  ]);

  const [buttons, setButtons] = useState([
    { text: 'Week', value: 0, active: true },
    { text: 'Month', value: 1, active: false },
    { text: '3 Month', value: 2, active: false },
    { text: 'Year', value: 3, active: false },
  ]);

  const [trendsButtons, setTrendsButtons] = useState([
    { text: 'Day', value: 0, active: true },
    { text: 'Week', value: 1, active: false },
    { text: 'Month', value: 2, active: false },
  ]);

  return (
    <>
      <div className='p_main'>
        <section className='welcome_sec'>
          <p className='txt'>Graph</p>
        </section>
        <section className='dashboard_sec com_center_wrap'>
          <div className='com_container right'>
            <article className='box'>
              <StackedColumnsChart colChartData={colChartData} />
              <div className='com_btn_wrap'>
                <button className='com_btn m point full'>
                  Add more classes
                  <i className='svg_icon after icon_add white'>&nbsp;</i>
                </button>
              </div>
            </article>

            <article className='box'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  TEST STATUS BY TOTAL
                </h2>
              </div>
              <SimpleDonutChart donutChartData={donutChartData} />
            </article>

            <article className='box full'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  TOTAL STUDENTS AND TESTS STAT
                </h2>
              </div>
              <LineAreaChart
                buttons={buttons}
                setButtons={setButtons}
                lineAreaChartData={lineAreaChartData}
                dateArray={dateArray}
                getDateArray={getDateArray}
                randomLabels1={randomLabels1}
                randomLabels2={randomLabels2}
              />
            </article>

            <article className='box full'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  Correct answer rate by Categories
                </h2>
              </div>
              <BarChart barChartData={barChartData} />
            </article>

            <article className='box full'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  Average Weekly Scores
                </h2>
              </div>
              <LineChart />
            </article>

            <article className='box full'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  Time per Question
                </h2>
              </div>
              <StackedColumnsChart colChartData={timePerQuestionData} />
            </article>

            <article className='box'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  Class/Student vs. GATE+
                </h2>
              </div>
              <GroupedBarChart groupedBarChart={groupedBarChart} />
            </article>

            <article className='box'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  Class/Student vs. Class
                </h2>
              </div>
              <BarChart barChartData={classBarChartData} />
            </article>

            <article className='box'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  section score
                </h2>
              </div>
              <BarChart barChartData={barChartData1} />
            </article>

            <article className='box full'>
              <div className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_graph black'>&nbsp;</i>
                  Score Trends
                </h2>
              </div>
              <SplineChart
                buttons={trendsButtons}
                setButtons={setTrendsButtons}
                dateArray={dateArray}
                getDateArray={getDateArray}
                randomLabels1={randomLabels3}
                randomLabels2={randomLabels4}
              />
            </article>
          </div>
        </section>
      </div>
    </>
  );
};

export default AllChartViewPage;
