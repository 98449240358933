import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';

import { nvlNumber } from 'utils/Common.utils';

import StackedColumnsChart from 'components/_common/chart/StackedColumnsChart';
import Title from './_components/Title';

/** "아케데미 대시보드" 페이지 - "Test Status By Classes" 컴포넌트 */
export default function TestStatusByClasses({
  classColChartData,
  colChartData,
  currentClassSeq,
  currentClassName,
  firstClassSeq,
  setCurrentClassSeq,
  ...rest
}) {
  const navigate = useNavigate();

  //////////////////// 기타 핸들러 선언 영역 시작 ///////////////////
  const currentlyTotalByClass = () => {
    return classColChartData?.series[1].data.reduce((acc, value) => acc + value, 0);
  };
  const currentlyTotal = () => {
    return colChartData?.series[1].data.reduce((acc, value) => acc + value, 0);
  };
  const completedTotalByClass = () => {
    return classColChartData?.series[2].data.reduce((acc, value) => acc + value, 0);
  };
  const completedTotal = () => {
    return colChartData?.series[2].data.reduce((acc, value) => acc + value, 0);
  };
  const notTakingTotal = () => {
    return colChartData?.series[0].data.reduce((acc, value) => acc + value, 0);
  };
  const notTakingTotalByClass = () => {
    return classColChartData?.series[0].data.reduce((acc, value) => acc + value, 0);
  };

  //////////////////// 기타 핸들러 선언 영역 끝 ///////////////////

  return (
    <S.Wrap {...rest}>
      <Title>By Classes</Title>
      {/* 그래프 영역 */}
      {notTakingTotal() + currentlyTotal() + completedTotal() !== 0 && (
        <StackedColumnsChart colChartData={colChartData} currentClassSeq={currentClassSeq} setCurrentClassSeq={setCurrentClassSeq} />
      )}
      <div className='com_classes_chart'>
        <h5 className='class_name'>{currentClassSeq > 0 ? currentClassName : 'Total'}</h5>
        <Link
          to='/com/tests/status'
          state={{
            classSeq: nvlNumber(currentClassSeq) === 0 ? firstClassSeq : nvlNumber(currentClassSeq),
          }}
          className='com_chart_name'>
          <div className='item'>
            <span className='color gray'>&nbsp;</span>
            <p className='name'>Completed tests</p>
            {currentClassSeq > 0 ? (
              <CountUp start='0' end={completedTotalByClass()} duration='3' className='num' />
            ) : (
              <CountUp start='0' end={completedTotal()} duration='3' className='num' />
            )}
          </div>
          <div className='item'>
            <span className='color blue'>&nbsp;</span>
            <p className='name'>Currently taking</p>
            {currentClassSeq > 0 ? (
              <CountUp start='0' end={currentlyTotalByClass()} duration='3' className='num' />
            ) : (
              <CountUp start='0' end={currentlyTotal()} duration='3' className='num' />
            )}
          </div>
          <div className='item'>
            <span className='color black'>&nbsp;</span>
            <p className='name'>Not taking the test</p>
            {currentClassSeq > 0 ? (
              <CountUp start='0' end={notTakingTotalByClass()} duration='3' className='num' />
            ) : (
              <CountUp start='0' end={notTakingTotal()} duration='3' className='num' />
            )}
          </div>
        </Link>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .com_btn_wrap {
      margin-top: 20px !important;
    }
    .com_classes_chart {
      background-color: #f8f8fa;
      padding: 0.75rem;
      border-radius: 0.5rem;
      margin-bottom: 0;
    }
    .com_chart_name {
      cursor: pointer;
      padding-inline: 0;
      .item {
        height: 29px;
        .name {
          font-weight: 400;
          font-size: 0.875rem;
        }
        .num {
          font-size: 1rem;
        }
      }
      &:hover {
        .num {
          font-size: 24px;
          transition: all 0.2s;
        }
      }
    }
    .class_name {
      color: #111111;
      font-size: 0.875rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 0.5rem;
    }
  `,
};
