import SelectSubjectBox from './_components/questionsStatisticsHeader/SelectSubjectBox';
import SelectModuleBox from './_components/questionsStatisticsHeader/SelectModuleBox';

const SUBJECT = { RW: 'E', MATH: 'M' };

/** Correct answer (rate) by Questions 부분의 header */
export default function QuestionsStatisticsHeader({ activeSatBox, currentTestModuleList, selectedModuleInfo, setSelectedModuleInfo, handleSelectSubject }) {
  /** 모듈들중 현재 선택된 과목과 일치하는 모듈 */
  const selectedSubjectModule = currentTestModuleList.filter((moduleInfo) => moduleInfo.tmSubject === SUBJECT[activeSatBox]);

  return (
    <div className='top_area'>
      <p className='tit_field'>Correct answer (rate) by Questions</p>
      <SelectSubjectBox activeSatBox={activeSatBox} selectedModuleInfo={selectedModuleInfo} handleSelectSubject={handleSelectSubject} />
      <SelectModuleBox selectedSubjectModule={selectedSubjectModule} selectedModuleInfo={selectedModuleInfo} setSelectedModuleInfo={setSelectedModuleInfo} />
    </div>
  );
}
