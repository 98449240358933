import React, { useEffect, useRef, useState } from 'react';
import GroupedBarChart from 'components/_common/chart/GroupedBarChart';
import SectionTitle from '../../../common/SectionTitle';
import styled from 'styled-components';
import Box from '../../../common/Box';
import request from 'utils/Request.utils';
import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useObfuscatedSearchParam, getValidParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS } from 'utils/constants';

const gateButtons = ['Class', 'Student'];

/** "/com/score/:category" 페이지의 "Class | Student Vs GATE+" 영역 컴포넌트 */
export default function ClassVsGatePlus({ selectedStudentSeq, setSelectedStudentSeq }) {
  const userInfo = request.tokenDecoder();
  /** 현재 classSeq - Route Parameter */
  const { classSeq: encClassSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);
  const queryCurStudentSeq = getObfuscatedValue(SCORE_PARAMS.CUR_STU);
  /** 중복호출 방지용 Ref값 초기화 여부 */
  const [isInitialized, setIsInitialized] = useState(false);

  const [selectedTarget, setSelectedTarget] = useState('Class'); // 'Class' | 'Student' => 선택된 대상 ( Class | Student )

  const prevAverageVsGateplusRef = useRef({}); // average/vs/gateplus API 중복 호출 방지용 Ref - userInfo.ecSeq, queryCurScoreTestSeq, classSeq, userSeq

  /** VsGate⁺ 차트에서 Class 선택시 데이터 */
  const [classVsGateChartData, setClassVsGateChartData] = useState({
    series: [
      {
        name: 'Class', // 'Class'          => 차트에 그려질 이름
        data: [0, 0], // [number, number]  => 선택된 시험에 대한 class의 [수학, 영어] 점수
      },
      {
        name: 'GATE+', // 'GATE+'          => 차트에 그려질 이름
        data: [0, 0], // [number, number]  => 선택된 시험에 대한 Gate⁺의 [수학, 영어] 점수
      },
    ],
    colors: ['#0068BD', '#E3E7ED'], // ['#0068BD', '#E3E7ED']  => 차트에 그려질 색
  });
  /** VsGate⁺ 차트에서 Student 선택시 데이터 */
  const [studentVsGateChartData, setStudentVsGateChartData] = useState({
    series: [
      {
        name: 'Student', // 'Student'      => 차트에 그려질 이름
        data: [0, 0], // [number, number]  => 선택된 시험에 대한 student의 [수학, 영어] 점수
      },
      {
        name: 'GATE+', // 'GATE+'          => 차트에 그려질 이름
        data: [0, 0], // [number, number]  => 선택된 시험에 대한 Gate⁺의 [수학, 영어] 점수
      },
    ],
    colors: ['#0068BD', '#E3E7ED'], // ['#0068BD', '#E3E7ED']  => 차트에 그려질 색
  });

  //////////////////// START --- useEffect_선언_영역 --- START ////////////////////
  // 컴포넌트 마운트시 Ref값 초기화 ( 새로고침시 이전 Ref 값으로 중복호출방지가 실행되는 것을 막음 )
  useEffect(() => {
    prevAverageVsGateplusRef.current = {};
    setIsInitialized(true);
  }, []);

  // API 인자가 유효할 경우 해당 Class에서 본 시험정보 리스트 불러오기
  useEffect(() => {
    if (!(isInitialized && getValidParam(classSeq) && getValidParam(queryCurScoreTestSeq))) return;
    /**
     * vsGate⁺ 영역의 차트 데이터를 가져오는 API 요청 - target에 따라 호출되는 데이터가 다름
     * @param {String} target - 'class' | 'student'
     * @param {Number} userSeq - 학생의 userSeq ( 0 전달시 classVsGate, userSeq 전달시 studentVsGate 데이터 호출 )
     */
    const getVsGatePlusChartData = (target, userSeq = 0) => {
      // 중복 방지 Ref가 초기화가 되지 않았거나, API 파라미터가 유효하지 않을 경우 early return
      if (!(isInitialized && userInfo?.ecSeq && getValidParam(queryCurScoreTestSeq) && getValidParam(classSeq))) return;
      // 이전 호출한 API의 파라미터와 현재 파라미터가 같다면 함수 종료 ( 중복 호출 방지 )
      if (
        prevAverageVsGateplusRef.current.ecSeq === userInfo?.ecSeq &&
        prevAverageVsGateplusRef.current.queryCurScoreTestSeq === queryCurScoreTestSeq &&
        prevAverageVsGateplusRef.current.classSeq === classSeq &&
        prevAverageVsGateplusRef.current.userSeq === userSeq
      )
        return;
      prevAverageVsGateplusRef.current = { ecSeq: userInfo.ecSeq, queryCurScoreTestSeq, classSeq, userSeq }; // 현재 파라미터 값 업데이트

      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedRwPoint = response.result.rwPoint || 0;
          const fetchedRwAvg = response.result.rwAvg || 0;
          const fetchedMathPoint = response.result.mathPoint || 0;
          const fetchedMathAvg = response.result.mathAvg || 0;

          // target에 따라 다른 데이터 호출 class: classData, user: studentData
          if (target === 'Student') {
            setStudentVsGateChartData((prev) => {
              return {
                ...prev,
                series: [
                  { name: 'Student', data: [fetchedRwPoint, fetchedMathPoint] },
                  { name: 'GATE+', data: [fetchedRwAvg, fetchedMathAvg] },
                ],
              };
            });
          }
          if (target === 'Class') {
            setClassVsGateChartData((prev) => {
              return {
                ...prev,
                series: [
                  { name: 'Class', data: [fetchedRwPoint, fetchedMathPoint] },
                  { name: 'GATE+', data: [fetchedRwAvg, fetchedMathAvg] },
                ],
              };
            });
          }
        }
      };

      request
        .get(
          `/api/dsat/average/vs/gateplus?ecSeq=${userInfo.ecSeq}&customSetSeq=${queryCurScoreTestSeq}&classSeq=${classSeq}&userSeq=${userSeq}`,
          null,
          successHandler
        )
        .catch((error) => console.error('', error));
    };

    // target에 따라 다른 데이터 호출 Class: classData, Student: studentData
    getVsGatePlusChartData(selectedTarget, selectedTarget === 'Student' ? selectedStudentSeq : 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, classSeq, queryCurScoreTestSeq, selectedTarget, selectedStudentSeq]);
  ////////////////////  END  --- useEffect_선언_영역 ---  END  ////////////////////

  useEffect(() => {
    if (selectedStudentSeq === 0) {
      setSelectedTarget('Class');
    } else {
      setSelectedTarget('Student');
    }
  }, [selectedStudentSeq]);

  return (
    <S.Wrap className='element'>
      <Box className='main_box'>
        <SectionTitle>
          {selectedTarget} vs GATE
          <sup>+</sup>
        </SectionTitle>
        <div className='com_tab dark'>
          {gateButtons?.map((item, index) => (
            <button
              key={`button_${index}`}
              className={`menu ${selectedTarget === item ? 'active' : ''}`}
              onClick={() => {
                if (item === 'Student') {
                  setSelectedStudentSeq(getValidParam(queryCurStudentSeq));
                } else {
                  setSelectedStudentSeq(0);
                }

                setSelectedTarget(item);
              }}>
              {item}
            </button>
          ))}
        </div>
        {selectedTarget === 'Class' ? <GroupedBarChart groupedBarChart={classVsGateChartData} /> : <GroupedBarChart groupedBarChart={studentVsGateChartData} />}
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    width: calc(25% - 15px);
    .main_box {
      padding: 1.25rem 1.5rem;
      height: 100%;
    }
  `,
};
