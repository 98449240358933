import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import dayjsTZ from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils.js';
import { nvl } from 'utils/Common.utils.js';
import useOutsideClick from 'hooks/useOutsideClick.js';

//img
import IconEmpty from 'assets/img/icon_empty.png';
import { ReactComponent as ImgSvgIconArrow } from 'assets/img/svg/icon_arrow_02.svg';
//components
import Paging from 'layout/Paging'; //페이징
import MiniAlert from 'components/_common/alerts/MiniAlert'; //한줄 알럿
import MainLink from 'components/_common/button/_MainLinks';
import Box from 'components/academy/dashboard/_components/common/Box';
import SortToggleButton from './_components/common/buttons/SortToggleButton';
import SendMessage from './_components/common/modals/SendMessage';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

/** "Test Status" 페이지에서 사용 할 파라미터 및 상태 문자열  */
const TESTS_STATUS_PARAMS = {
  CURRENT_ORDER_DIRECTION: {
    KEY: 'orderDirection', // 정렬 상태
    value: {
      ASC: 'asc', // 오름차순
      DESC: 'desc', // 내림차순
      NULL: null, // 선택하지 않음
    },
  },
  CURRENT_ORDER_BY: {
    KEY: 'orderBy', // "현재 정렬 할 기준이 되는 항목"
    value: {
      USER: 'user', // 학생 이름
      TEST: 'test', // 시험 이름
      DATE: 'date', // 시험 종료 날짜
      STATUS: 'status', // 시험 진행 상태 Not taking the test -> Currently taking -> Completed Tests
    },
  },
};

/**
 * Test Status By Classes Page
 * @description
 * 🔍 검색 키워드 - #com/tests/status
 */
export default function TestStatusByClassesPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultClassSeq = location.state?.classSeq || 0;

  const { getObfuscatedValue, setBulkObfuscatedValues } = useObfuscatedSearchParam();

  /** "현재 정렬 할 기준이 되는 항목" 정렬 상태 파라미터 */
  const queryCurOrderDirection = getObfuscatedValue(TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY);
  /** "현재 정렬 상태" 파라미터 */
  const queryCurOrderBy = getObfuscatedValue(TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY);

  /////////////////// useState 선언 영역 시작 ////////////////////
  const [seeEveryTest, setSeeEveryTest] = useState(!!location.state?.seeEveryTest);
  const [userInfo, _] = useState(request.tokenDecoder());
  const [sendMessage, setSendMessage] = useState(false);
  const [alert, alertSet] = useState(false);
  const [classInfo, setClassInfo] = useState({
    active: false,
    currentSeq: 0,
    currentName: '',
    list: [],
  });
  /** 시험 정보 */
  const [testInfo, setTestInfo] = useState({
    list: [],
    active: null, // 활성 화된 시험의 customSetSeq
    testName: '',
  });
  /** 학생 정보 */
  const [studentInfo, setStudentInfo] = useState({
    list: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });
  /////////////////// useState 선언 영역 끝 ////////////////////

  /////////////////// 기타 핸들러 선언 영역 시작 ////////////////////
  const PopSendMessage = () => {
    setSendMessage((prev) => !prev);
  };
  /** 한 번에 볼 테이블 목록 갯수 선택 핸들러 */
  const limitSelect = (e) => {
    let limit = e.target.value;

    setStudentInfo((prev) => {
      return { ...prev, numPerPage: limit };
    });
  };
  /** "다음" 페이지 핸들러 */
  const nextPageMove = (value) => {
    setStudentInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  /** 시험 세트 별 진행 상태 목록 얻어오기 API 요청 */
  const getStatusByTestList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let testList = response.result.testList;
        testList = testList.filter((i) => nvl(i.testName) !== '');
        if (testList.length > 0) {
          if (seeEveryTest) {
            setTestInfo((prev) => {
              return { ...prev, list: testList, active: 0 };
            });
            setSeeEveryTest(false);
          } else {
            setTestInfo((prev) => {
              return {
                ...prev,
                list: testList,
                active: testList[0].customSetSeq,
                testName: testList[0].testName,
              };
            });
          }
        } else {
          setTestInfo((prev) => {
            return { ...prev, list: [], active: 0, testName: '' };
          });
          setStudentInfo((prev) => {
            return { ...prev, list: [], currentPage: 1 };
          });
        }
      }
    };

    request.get(`/api/dsat/schedule/ongoingness?ecSeq=${userInfo.ecSeq}&classSeq=${classInfo.currentSeq}`, null, successHandler);
  };

  /** 학생 목록 (우측 테이블 용 데이터) 얻어오기 API 요청 */
  const getStudentList = ({ customSetSeq, orderBy, orderDirection }) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const testList = response.result.testDetailList;
        const totalCnt = response.result.totalCnt;

        setStudentInfo((prev) => {
          return { ...prev, list: testList, totalCount: totalCnt };
        });
      }
    };

    request.get(
      `/api/dsat/schedule/ongoingness/detail?ecSeq=${userInfo.ecSeq}&classSeq=${classInfo.currentSeq}&customSetSeq=${customSetSeq}&currentPage=${studentInfo.currentPage}&limit=${studentInfo.numPerPage}${orderBy ? `&orderBy=${orderBy}` : ''}${orderDirection ? `&orderDirection=${orderDirection}` : ''}`,
      null,
      successHandler
    );
  };

  /** "Name" 열 정열 버튼 핸들러 */
  const handleSortUserName = (current) => {
    if (current === 1) {
      // 현재 오름차순 일 때, 내림차순으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.USER,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.DESC,
      });
    } else if (current === 2) {
      // 현재 내림차순 일 때, 기본 값(선택 안함)으로 변경 (쿼리 파라미터 삭제)
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.USER,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.NULL,
      });
    } else {
      // 현재 기본 값(선택 안함) 일 때, 오름차순 으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.USER,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.ASC,
      });
    }
  };
  /** "Test Name" 열 정열 버튼 핸들러 */
  const handleSortTestName = (current) => {
    if (current === 1) {
      // 현재 오름차순 일 때, 내림차순으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.TEST,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.DESC,
      });
    } else if (current === 2) {
      // 현재 내림차순 일 때, 기본 값(선택 안함)으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.TEST,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.NULL,
      });
    } else {
      // 현재 기본 값(선택 안함) 일 때, 오름차순 으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.TEST,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.ASC,
      });
    }
  };
  /** "End Date" 열 정열 버튼 핸들러 */
  const handleSortEndDate = (current) => {
    if (current === 1) {
      // 현재 오름차순 일 때, 내림차순으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.DATE,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.DESC,
      });
    } else if (current === 2) {
      // 현재 내림차순 일 때, 기본 값(선택 안함)으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.DATE,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.NULL,
      });
    } else {
      // 현재 기본 값(선택 안함) 일 때, 오름차순 으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.DATE,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.ASC,
      });
    }
  };
  /** "Status" 열 필터 버튼 핸들러 */
  const handleFilterStatus = (current) => {
    if (current === 1) {
      // 현재 오름차순 일 때, 내림차순으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.STATUS,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.DESC,
      });
    } else if (current === 2) {
      // 현재 내림차순 일 때, 기본 값(선택 안함)으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.STATUS,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.NULL,
      });
    } else {
      // 현재 기본 값(선택 안함) 일 때, 오름차순 으로 변경
      setBulkObfuscatedValues({
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.STATUS,
        [TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.KEY]: TESTS_STATUS_PARAMS.CURRENT_ORDER_DIRECTION.value.ASC,
      });
    }
  };

  /////////////////// 기타 핸들러 선언 영역 끝 ////////////////////

  /////////////////// useEffect 선언 영역 시작 ////////////////////
  useEffect(() => {
    /** 셀렉트 버튼에서 사용 할 Class 목록 얻어오기 API 요청 */
    const getClassList = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          let classList = response.result.classList;
          if (classList && classList.length === 0) return;

          if (defaultClassSeq === 0)
            setClassInfo((prev) => {
              return {
                ...prev,
                list: classList,
                currentSeq: classList[0].classSeq,
                currentName: classList[0].className,
              };
            });
          else {
            let defaultClass = classList.filter((i) => i.classSeq === defaultClassSeq);
            setClassInfo((prev) => {
              return {
                ...prev,
                list: classList,
                currentSeq: defaultClass[0].classSeq,
                currentName: defaultClass[0].className,
              };
            });
          }
        }
      };

      request.get(`/api/dsat/classes?ecSeq=${userInfo.ecSeq}`, null, successHandler);
    };

    if (userInfo.ecSeq > 0) getClassList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (classInfo.currentSeq > 0) getStatusByTestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classInfo.currentSeq]);

  useEffect(() => {
    if (testInfo.list.length > 0) getStudentList({ customSetSeq: testInfo.active, orderBy: queryCurOrderBy, orderDirection: queryCurOrderDirection });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testInfo.active, classInfo.currentSeq, studentInfo.numPerPage, studentInfo.currentPage, queryCurOrderBy, queryCurOrderDirection]);
  /////////////////// useEffect 선언 영역 끝 ////////////////////

  // hook
  const classListBox = useRef(null);
  useOutsideClick(classListBox, () =>
    setClassInfo((prev) => {
      return { ...prev, active: false };
    })
  );

  return (
    <>
      <MainLink />
      <S.Wrap className='com_center_wrap com_container'>
        <Box className='main_box'>
          <div className='chart_sec'>
            <div
              className={`com_select_layer ${classInfo.active ? 'active' : ''}`}
              onClick={() =>
                setClassInfo((prev) => {
                  return { ...prev, active: !classInfo.active };
                })
              }
              ref={classListBox}>
              <div className='cover_wrap'>
                <div className='title'>Class</div>
                {/*active 시 활성화*/}
                <input type='text' className='selected' placeholder={classInfo.currentName} />
                <i className='select_button'>
                  <ImgSvgIconArrow />
                </i>
              </div>
              <div className='layer'>
                {classInfo.list &&
                  classInfo.list?.map((item, index) => {
                    return (
                      <p
                        className='option'
                        key={`class_list_${index}`}
                        onClick={() =>
                          setClassInfo((prev) => {
                            return {
                              ...prev,
                              active: false,
                              currentName: item.className,
                              currentSeq: item.classSeq,
                            };
                          })
                        }>
                        {item.className}
                      </p>
                    );
                  })}
              </div>
            </div>
            <section className='tit_area'>
              <article className='date'>Today {dayjsTZ().format('MM / DD / YYYY')}</article>
              <button
                className='com_btn'
                onClick={() => {
                  setTestInfo((prev) => {
                    return { ...prev, active: 0 }; // customSetSeq 0 이면 모든 시험 정보 가져옴
                  });
                  setStudentInfo((prev) => {
                    return { ...prev, currentPage: 1 }; // 페이지 초기화
                  });
                }}>
                See every test
              </button>
            </section>
            <section className='status_by_test_list scroll'>
              {testInfo.list &&
                testInfo.list.map((item, index) => {
                  /** 화면에 필요한 데이터 (숫자 타입 값 & 너비에 사용 할 퍼센티지) 얻는 함수 */
                  const getStatusData = ({ notTakingCountString, takingCountString, completedCountString }) => {
                    /** 전체 상태 갯수 */
                    const totalCount = [notTakingCountString, takingCountString, completedCountString].reduce((accumulator, currentValue) => {
                      return Number(accumulator) + Number(currentValue);
                    }, 0);
                    const getNewData = ({ countString }) => {
                      const count = Number(countString);
                      const percentage = (count / totalCount) * 100;
                      return {
                        count,
                        percentage,
                      };
                    };
                    /** "Not taking" 상태 갯수 및 너비 값 */
                    const notTakingCountData = getNewData({ countString: notTakingCountString });
                    /** "Taking" 상태 갯수 및 너비 값 */
                    const takingCountData = getNewData({ countString: takingCountString });
                    /** "Complete" 상태 갯수 및 너비 값 */
                    const completedCountData = getNewData({ countString: completedCountString });

                    return {
                      totalCount,
                      notTakingCountData,
                      takingCountData,
                      completedCountData,
                    };
                  };

                  /** 화면에서 사용하기 편리하게 "Status" 데이터 가공 (숫자 타입 값 & 너비에 사용 할 퍼센티지) */
                  const { totalCount, notTakingCountData, takingCountData, completedCountData } = getStatusData({
                    notTakingCountString: item.notTakingCount,
                    takingCountString: item.takingCount,
                    completedCountString: item.completedCount,
                  });

                  return (
                    <article
                      className={`item ${item.customSetSeq === testInfo.active ? 'active' : ''}`}
                      key={`student_${index + 1}`}
                      onClick={() => {
                        setTestInfo((prev) => {
                          return {
                            ...prev,
                            active: item.customSetSeq, // customSetSeq의 해당하는 시험 리스트 가져옴
                            testName: item.testName,
                          };
                        });
                        setStudentInfo((prev) => {
                          return { ...prev, currentPage: 1 }; //
                        });
                      }}>
                      <div className='info'>
                        <p className='test_name'>{item.testName}</p>
                        <p className='date'>
                          {dayjsTZ(item.testStartDate).format('MM / DD / YYYY')} ~ {dayjsTZ(item.testEndDate).format('MM / DD / YYYY')}
                        </p>
                      </div>
                      <div className='chart_area'>
                        <div className='range'>
                          <div className='min'>0</div>
                          <div className='max'>{totalCount}</div>
                        </div>
                        <div className='chart'>
                          <S.ChartBar className='not_taking' customWidth={`${notTakingCountData.percentage}%`} />
                          <S.ChartBar className='taking' customWidth={`${takingCountData.percentage}%`} />
                          <S.ChartBar className='complete' customWidth={`${completedCountData.percentage}%`} />
                        </div>
                      </div>
                      <div className='legend_area'>
                        <div className='not_taking'>
                          <div className='label'>Not taking</div>
                          <div className='legend'>{notTakingCountData.count}</div>
                        </div>
                        <div className='taking'>
                          <div className='label'>Taking</div>
                          <div className='legend'>{takingCountData.count}</div>
                        </div>
                        <div className='complete'>
                          <div className='label'>Complete</div>
                          <div className='legend'>{completedCountData.count}</div>
                        </div>
                      </div>
                    </article>
                  );
                })}
            </section>
          </div>
          <div className='list_sec'>
            <div className='com_list'>
              <section className='top'>
                <article className='btn_area'>
                  {/* <button className="com_btn line point m" onClick={() => setSendMessage(true)}><i className="svg_icon icon_message blue before">&nbsp;</i> Send Message</button> */}
                </article>
                <article className='list_tit'>{testInfo.active === 0 ? 'EVERY TEST' : testInfo.testName}</article>
                <article className='search_area '>
                  <select className='select_list' onChange={limitSelect} value={studentInfo.numPerPage}>
                    <option value='10'>10 Line</option>
                    <option value='25'>25 Line</option>
                    <option value='50'>50 Line</option>
                  </select>
                </article>
              </section>
              <table className='list'>
                <thead>
                  <tr>
                    <th>
                      <SortToggleButton
                        title='Sort list'
                        className='sort_button'
                        handlerCallback={handleSortUserName}
                        isReest={queryCurOrderBy !== TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.USER}>
                        Name
                      </SortToggleButton>
                    </th>
                    <th>
                      <SortToggleButton
                        title='Sort list'
                        className='sort_button'
                        handlerCallback={handleSortTestName}
                        isReest={queryCurOrderBy !== TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.TEST}>
                        Test Name
                      </SortToggleButton>
                    </th>
                    <th>
                      <SortToggleButton
                        title='Sort list'
                        className='sort_button'
                        handlerCallback={handleSortEndDate}
                        isReest={queryCurOrderBy !== TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.DATE}>
                        End Date
                      </SortToggleButton>
                    </th>
                    <th>
                      <SortToggleButton
                        title='Sort list'
                        className='sort_button'
                        handlerCallback={handleFilterStatus}
                        isReest={queryCurOrderBy !== TESTS_STATUS_PARAMS.CURRENT_ORDER_BY.value.STATUS}>
                        Status
                      </SortToggleButton>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {nvl(studentInfo.list) !== '' && studentInfo.list.length > 0 ? (
                    studentInfo.list.map((item, index) => (
                      <tr key={`student_list_${index + 1}`}>
                        <td className='tit_field alignL'>
                          <p className='ellipsis'>{item.userName}</p>
                        </td>
                        <td className='tit_field alignL'>{item.testName}</td>
                        <td>{dayjs(item.testEndDate).format('MM / DD / YYYY')}</td>
                        <td>
                          <p className={`state ${item.testStatus === 'P' ? 'completed' : item.testStatus === 'E' ? 'not' : 'ing'}`}>{item.testStatusName}</p>
                        </td>
                        {/* className state, not, ing, completed */}
                      </tr>
                    ))
                  ) : (
                    // 데이터 없을 경우 노출
                    <tr>
                      <td colSpan='4'>
                        <div className='com_nodata'>
                          <p className='tit'>No test available.</p>
                          <p className='txt'>Go to Set Test and set more test!</p>
                          <div className='img'>
                            <img src={IconEmpty} alt='' />
                          </div>
                          <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
                            GO TO SET TEST
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Paging
                totalCount={studentInfo.totalCount}
                currentPage={studentInfo.currentPage}
                numPerPage={studentInfo.numPerPage}
                pagePerBlock={studentInfo.pagePerBlock}
                nextPageMove={nextPageMove}
              />
            </div>
          </div>
        </Box>
      </S.Wrap>
      <SendMessage sendMessage={sendMessage} setSendMessage={PopSendMessage} />
      <MiniAlert text='Status Changed' type='onlyText' active={alert} inactive={() => alertSet(false)} timeOut={2000} />
    </>
  );
}

const S = {
  Wrap: styled.div`
    &.com_container,
    .com_container {
      background: #f4f8ff;
      padding: 1rem;
      border-radius: 0.625rem;
    }
    .main_box {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 1rem;
    }

    .com_select_layer {
      width: 100%;
      cursor: pointer;
      &:after {
        display: none;
      }
      .cover_wrap {
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid #d2dbe2;
        border-radius: 0.5rem;
        height: 2.5rem;
        padding: 0.65rem 1rem;
        overflow: hidden;
        .title {
          font-weight: 400;
          font-size: 0.875rem;
          color: #111111;
          border-right: 1px solid #d2dbe2;
          padding-right: 0.75rem;
          margin-right: 0.75rem;
        }
        .select_button {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
          transform: rotateY(0);
          width: 2.125rem;
          height: 100%;
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          background: none;
          border-left: 1px solid #d2dbe2;
          cursor: pointer;
          svg {
            color: #7f8998;
            width: 1.125rem;
            transition: 0.2s;
          }
        }
        .selected {
          border: none;
          background: #fff;
          color: #111111;
          font-size: 22px;
          font-weight: 500;
          padding: 0;
        }
      }

      &.active {
        .cover_wrap {
          .select_button {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .chart_sec {
      width: 394px;
      padding: 0;
      align-self: stretch;
      .tit_area {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .date {
          color: #111111;
          font-size: 0.8125rem;
          font-weight: 400;
        }
        .com_btn {
          border-radius: 0.375rem;
          border: 1px solid #ebebeb;
          background: #fff;
          display: flex;
          padding: 0.375rem 0.625rem;
          justify-content: center;
          align-items: center;
          color: #4b5563;
          font-size: 0.875rem;
          font-weight: 400;
          &:hover {
          }
        }
      }
      .status_by_test_list {
        max-height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 0.35rem;
        padding: 0.35rem 0;
        .item {
          border-radius: 0.5rem;
          border: 1px solid #eaeaea;
          padding: 0.75rem;
          background: #fff;
          cursor: pointer;
          &:hover {
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06);
          }
          &.active {
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06);
            border: 1px solid #505050;
          }
          .info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            .test_name {
              color: #111111;
              font-weight: 400;
              font-size: 1rem;
            }
            .date {
              color: #767676;
              font-weight: 400;
              font-size: 0.8125rem;
            }
          }
          .chart_area {
            .range {
              display: flex;
              justify-content: space-between;
              color: #999999;
              font-weight: 400;
              font-size: 0.8125rem;
            }
            .chart {
              display: flex;
              justify-content: space-between;
              gap: 0.25rem;
              margin: 0.25rem 0;
              & > * {
                height: 0.375rem;
                border-radius: 0.25rem;
              }
            }
          }
          .legend_area {
            display: flex;
            justify-content: space-between;
            & > * {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 0.25rem;
            }
            .label {
              color: #505050;
              font-weight: 400;
              font-size: 0.8125rem;
            }
            .legend {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 0.35rem;
              font-weight: 500;
              font-size: 1rem;
              &::before {
                content: '';
                display: inline-block;
                width: 0.625rem;
                height: 0.625rem;
                border-radius: 100%;
              }
            }
          }
          .chart_area .chart .not_taking,
          .legend_area .not_taking .legend::before {
            background-color: #1d1d1d;
          }
          .chart_area .chart .taking,
          .legend_area .taking .legend::before {
            background-color: #025aa1;
          }
          .chart_area .chart .complete,
          .legend_area .complete .legend::before {
            background-color: #dbe0e7;
          }
        }
      }
    }
    .list_sec {
      width: calc(100% - 394px - 15px);
      align-self: stretch;
      .com_list {
        .tit_field {
          font-weight: 400;
        }
        .sort_button {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          svg {
            color: #ffffff;
            width: 1.125rem;
          }
        }
      }
    }
  `,

  ChartBar: styled.div`
    width: ${(props) => props.customWidth || 0};
  `,
};
