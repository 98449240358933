import React from 'react';
import styled from 'styled-components';

/** Set Tests 페이지들에서 사용 할 보조 타이틀 문구 스타일 용 컴포넌트 */
export default function SubTitle({ children }) {
  return <S.Title>{children}</S.Title>;
}

const S = {
  Title: styled.p`
    font-weight: 400;
    font-size: 0.875rem;
    color: #999999;
  `,
};
