import styled from 'styled-components';
import ProfileImage from 'components/_common/ProfileImage';

function CollapsibleContentItemContainer({ userInfo, title, subTitle }) {
  return (
    <S.ContentItemContainer>
      <div className='expanded_title_wrapper'>
        <ProfileImage
          userInfo={{
            userName: userInfo.userName,
            userSeq: userInfo.userSeq,
            upFileSeq: userInfo.upFileSeq,
          }}
          size='1.5rem'
        />
        <p>{title}</p>
      </div>
      <p className='expanded_sub_title'>{subTitle}</p>
    </S.ContentItemContainer>
  );
}

export default CollapsibleContentItemContainer;

const S = {
  ContentItemContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    border-radius: 0.5rem;
    .expanded_title_wrapper {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }
    .expanded_sub_title {
      color: #6c7685;
      font-size: 0.875rem;
      padding-right: 0.75rem;
    }

    &.no_select_able {
      padding-left: 0.75rem;
    }
  `,
};
