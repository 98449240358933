import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import FileUpload from 'components/ap/_components/examLayout/examBody/FileUpload';
import request from 'utils/Request.utils';
import CommonLoadingAnimation from 'components/_common/CommonLoadingAnimation';

/** AP 시험 */
export default function ExamUpload({ currentQuestionInfo }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [uploadFileList, setUploadFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  /** 파일 업로드 API - 유저가 파일 업로드 시 호출 */
  const postFileUpload = async (files) => {
    setIsLoading(true);

    // 파일 배열이 아닌 경우 배열로 변환
    const fileArray = Array.isArray(files) ? files : [files];

    return Promise.all(
      fileArray.map((file) => {
        return new Promise((resolve, reject) => {
          // 파일 크기 체크
          const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB
          if (file.size > MAX_FILE_SIZE) {
            window.alert(`The file '${file.name}' exceeds 20MB.`);
            reject(new Error('file size exceeded'));
            return;
          }

          const params = {
            uploadFiles: file,
            etcSeq: currentQuestionInfo.studentTestResultSeq,
          };

          const successHandler = (response) => {
            if (response.code === 200) {
              resolve(response.result); // Promise의 결과물을 배열로 반환
            } else {
              reject(new Error('Upload failed'));
            }
          };

          request.postMultipart(`/api/v2/ap/upload/exam/files/answer_pdf`, params, successHandler);
        });
      })
    ).finally(() => {
      setIsLoading(false);
    });
  };

  /** 파일 삭제 API - 유저가 파일 삭제 시 호출 */
  const deleteFile = (fileSeq) => {
    setIsLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) return;
    };

    request.del(`/api/v2/ap/delete/exam/files/${fileSeq}`, null, successHandler).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setUploadFileList([]); // 문제 변경 시 파일 목록 초기화
    /** 하이브리드 문제 파일 조회 - 파일 조회 및 파일 목록 업데이트 */
    const getHybridQuestionFile = () => {
      setIsLoading(true);
      const successHandler = (response) => {
        if (response.code === 200) {
          const uploadFiles = response.result.uploadFiles;
          setUploadFileList(uploadFiles);
        }
      };

      request.get(`/api/v2/ap/get/exam/files/answer_pdf/${currentQuestionInfo.studentTestResultSeq}`, null, successHandler).finally(() => {
        setIsLoading(false);
      });
    };

    getHybridQuestionFile();
  }, [currentQuestionInfo]);

  return (
    <S.Wrap className='pop_body'>
      <div className='custom_scroll center_wrap'>
        <div className='title_area'>
          <h2 className='main'>Time’s over</h2>
          <div className='sub'>
            Upload your FRQ PDFs now if you haven’t. <br />
            If you've already submitted the PDFs, Click "Complete" to finish the exam.
          </div>
        </div>
        <div className='guide_area'>
          <h3 className='title'>Your answer: </h3>
          <div className='guide'>
            <p>
              Instructions: Use a scanner app like CamScanner, Adobe Scan, or Microsoft Lens to capture your document and submit it as a PDF. If you have
              multiple pages, make sure to merge them into a single file before submitting.
            </p>
            <p>
              <strong>
                Warning: In the actual hybrid exam, you must submit a physical paper, not a digital capture. Please note that mobile phones are not allowed.
              </strong>
            </p>
          </div>
        </div>
        <div className='upload_area'>
          <FileUpload uploadFileList={uploadFileList} setUploadFileList={setUploadFileList} postFileUpload={postFileUpload} deleteFile={deleteFile} />
        </div>
      </div>

      {isLoading && <CommonLoadingAnimation size='15rem' className='loading_container' />}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .center_wrap {
      width: 40.5rem;
      margin: 0 auto;
    }
    .title_area {
      padding: 3.125rem 0 1.125rem;
      font-family: Roboto;
      text-align: center;
      .main {
        color: #111;
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 3rem;
      }
      .sub {
        color: #505050;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.75rem;
      }
    }
    .guide_area {
      font-family: 'Noto Serif';
      .title {
        color: #111;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.75rem;
        margin-top: 0.5rem;
      }
      p + p {
        margin-top: 1rem;
      }
      .guide {
        color: #ff5858;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
    }

    .upload_area {
      margin-top: 1.25rem;
    }
  `,
};
