import React from 'react';
import styled from 'styled-components';

import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

/** Set Test - Select Test 페이지의 "텝 버튼" 데이터 */
export const TAB_DATA = {
  ALL: {
    viewText: 'All Tests',
    value: 'all',
  },
  FAVORITES: {
    viewText: 'Favorites',
    value: 'favorites',
  },
};

/** Set Test - Select Test 페이지의 "텝 버튼" 영역 컴포넌트 */
export default function TabButtons() {
  const { getObfuscatedValue, setBulkObfuscatedValues } = useObfuscatedSearchParam();

  /** 현재 선택 된 Tab */
  const selectedTab = getObfuscatedValue('tab');

  const handler = (value) => {
    setBulkObfuscatedValues({ paramValues: { tab: value, search: '' }, replace: true });
  };

  return (
    <S.Tabs>
      {Object.keys(TAB_DATA).map((item, index) => {
        return (
          <button
            key={`tab_${index}`}
            type='button'
            onClick={() => {
              handler(TAB_DATA[item].value);
            }}
            className={`${TAB_DATA[item].value === selectedTab ? 'active' : ''}`}>
            {TAB_DATA[item].viewText}
          </button>
        );
      })}
    </S.Tabs>
  );
}

const S = {
  Tabs: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 2rem;
    padding: 0 0.65rem;
    margin-bottom: -2px;
    button {
      font-weight: 400;
      font-size: 1rem;
      color: #767676;
      padding-bottom: 0.65rem;
      border-bottom: 3px solid rgba(0, 104, 189, 0);
      &.active {
        font-weight: 600;
        color: #0068bd;
        border-color: rgba(0, 104, 189, 1);
      }
    }
  `,
};
