import React from 'react';
import styled from 'styled-components';

import dayjsTZ from 'utils/functions/time/dayjs-config';

import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod';
import CustomSelect from 'components/_common/inputs/CustomSelect';

/** 아카데미 대시보드 - Students - Classes 페이지의 대표 Table 상단 "검색" 영역 컴포넌트 */
export default function SearchArea({ setSearchInfo, searchKeyword, setSearchKeyword, searchClasses, setLineLength, setPagingInfo }) {
  const limitSelect = (value) => {
    setLineLength(value);
    setPagingInfo((prev) => {
      return { ...prev, numPerPage: value };
    });
  };

  return (
    <S.Wrap className='search_area'>
      <PopupCalendarPeriod tit={dayjsTZ().format('MM / DD / YYYY')} setSearchInfo={setSearchInfo} />
      <div className='search'>
        <input
          type='text'
          placeholder='Enter class name'
          className='input search_area_input'
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && searchClasses()}
        />
        <button className='com_btn m blue btn_search' onClick={searchClasses}>
          <i className='svg_icon icon_search blue before'>&nbsp;</i>
          SEARCH
        </button>
      </div>
      <CustomSelect
        options={[
          { label: '10 line', value: 10 },
          { label: '25 line', value: 25 },
          { label: '50 line', value: 50 },
          { label: '100 line', value: 100 },
        ]}
        onSelect={limitSelect}
      />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.article`
    .search_area_input {
      width: 6.25rem;
    }
  `,
};
