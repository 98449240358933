import React from 'react';
import styled from 'styled-components';

/** 그라데이션 라인 스타일 용 컴포넌트 */
export default function GradationLine() {
  return <S.Line />;
}

const S = {};

S.Line = styled.i`
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 20%, rgba(209, 209, 209, 1) 50%, rgba(255, 255, 255, 1) 80%);
`;
