import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconCongratulation } from 'assets/img/congratulation_illust.svg';

import MainBox from 'components/user/_components/layouts/MainBox';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import { clearReduxStateJoinCompany } from 'reducers/auth/joincompany.action';
import LocalStorage from 'utils/LocalStorage.utils';
import { USER_LEVELS } from 'utils/constants';

/** 회원 가입 - "완료" 페이지 */
export default function SignUpCompletePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userSocial = useSelector((state) => state.userReducer.social);
  console.log('🚀 ~ SignUpCompletePage ~ userSocial:', userSocial);
  const userLevel = userSocial.userLevel;
  const [myPageState, setMyPageState] = useState(true);

  /** "Testgate Dashboard" 버튼 클릭 핸들러 */
  const handleGoToDashboard = () => {
    // navigate('/');
    location.href = '/';
  };
  /** "My Page" 버튼 클릭 핸들러 */
  const handleGoToMyPage = () => {
    // window.open(`${process.env.REACT_APP_MAINGATE_ROOT_URL}/sign/signin/?lastRoute=/en-US/mypage&accessToken=${userSession.accessToken}&refreshToken=${userSession.refreshToken}`);
  };

  useEffect(() => {
    dispatch({ type: 'clearCustomer' });
    dispatch({ type: 'clearMenuInfo' }); // added by psk 20240313
    if (userLevel === USER_LEVELS.PARENT) {
      setMyPageState(false);
    }
    // joinCompanyReducer 초기화
    dispatch(clearReduxStateJoinCompany());
    document.body.classList.add('gate_member');
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <S.Wrap>
      <MainBox className='main-box'>
        <ImgSvgIconCongratulation />
        <h4>Thank you.</h4>
        <div className='txt'>
          <p>Your account has been successfully created.</p>
          <p>Please go to Dashboard if you want to start your tests right away!</p>
        </div>
        <PrimaryButtonArea className='signup-complete-btn-area'>
          <PrimaryButton type='button' title='페이지 이동' onClick={handleGoToDashboard}>
            Testgate Dashboard
          </PrimaryButton>
          {/* myPageState가 true인 경우 버튼 노출  */}
          {/* {myPageState && (
            <PrimaryButton type='button' title='새 텝에서 열기' onClick={handleGoToMyPage}>
              My Page
            </PrimaryButton>
          )} */}
        </PrimaryButtonArea>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .main-box {
    width: 674px;
    max-width: 674px;
  }
  h4 {
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: #111111;
  }
  .txt {
    margin-top: 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: #7f8998;
    line-height: 1.2rem;
    p {
      & + P {
        margin-top: 1rem;
      }
    }
  }
  .signup-complete-btn-area {
    justify-content: center;
    button {
      margin: 0 auto;
      max-width: 289px;
    }
  }
`;
