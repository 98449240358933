import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import ImgSvgIconAcademy from 'assets/img/svg/icon/user-type/icon-user-type-01-academy-01.svg';
import ImgSvgIconAcademyHover from 'assets/img/svg/icon/user-type/icon-user-type-01-academy-01-gradient.svg';
import ImgSvgIconTeacher from 'assets/img/svg/icon/user-type/icon-user-type-01-teacher-01.svg';
import ImgSvgIconTeacherHover from 'assets/img/svg/icon/user-type/icon-user-type-01-teacher-01-gradient.svg';
import ImgSvgIconTutor from 'assets/img/svg/icon/user-type/icon-user-type-01-tutor-01.svg';
import ImgSvgIconTutorHover from 'assets/img/svg/icon/user-type/icon-user-type-01-tutor-01-gradient.svg';
import ImgSvgIconStudent from 'assets/img/svg/icon/user-type/icon-user-type-01-student-01.svg';
import ImgSvgIconStudentHover from 'assets/img/svg/icon/user-type/icon-user-type-01-student-01-gradient.svg';
import ImgSvgIconParent from 'assets/img/svg/icon/user-type/icon-user-type-01-parent-01.svg';
import ImgSvgIconParentHover from 'assets/img/svg/icon/user-type/icon-user-type-01-parent-01-gradient.svg';
import { updateReduxSocialUserLevel } from 'reducers/auth/action';

/** "사용자 유형" 결정하기 버튼 */
export default function UserTypeButton({ itemId, link, titleText, subText, userLevel = '' }) {
  console.log('🚀 ~ UserTypeButton ~ link:', link);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // dispatch(updateReduxSocialUserLevel(userLevel));
  const hendleClick = (e) => {
    e.preventDefault();
    // console.log('🚀 ~ hendleClick ~ userLevel:', userLevel);
    dispatch(updateReduxSocialUserLevel(userLevel));
    navigate(link);
  };
  return (
    <S.Wrap>
      {/* <Link to={link} onClick={} title=''> */}
      <Link onClick={hendleClick} title=''>
        <div className='text-wrap'>
          <h3 className='tit'>{titleText}</h3>
          <p className='txt'>{subText}</p>
        </div>
        <div className={`img ${itemId}`} />
      </Link>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  & > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1.5rem;
    box-sizing: border-box;
    padding: 1.25rem;
    border: 1px solid #f1f1f5;
    border-radius: 0.625rem;
    margin-bottom: 1rem;
    transition: 0.3s;
    width: 100%;
    .tit {
      font-size: 1rem;
      font-weight: 500;
      color: #000000;
      margin-bottom: 0.25rem;
    }
    .txt {
      line-height: 1rem;
      font-size: 0.813rem;
      font-weight: 400;
      color: #767676;
    }

    .last {
      margin-bottom: 0;
    }
    .text-wrap {
      flex-grow: 1;
      text-align: left;
    }
    .img {
      flex-shrink: 0;
      width: 3.125rem;
      height: 3.125rem;
      transition: 0.3s;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      &.academyAdmin {
        background-image: url(${ImgSvgIconAcademy});
      }
      &.academyTeacher {
        background-image: url(${ImgSvgIconTeacher});
      }
      &.tutor {
        background-image: url(${ImgSvgIconTutor});
      }
      &.student {
        background-image: url(${ImgSvgIconStudent});
      }
      &.parent {
        background-image: url(${ImgSvgIconParent});
      }
    }

    &:hover,
    &:focus {
      transform: scale(1.01);
      border: 1px solid #4758ff;
      border-radius: 0.75rem;
      background-image: #fbfcff;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
      .img {
        &.academyAdmin {
          background-image: url(${ImgSvgIconAcademyHover});
        }
        &.academyTeacher {
          background-image: url(${ImgSvgIconTeacherHover});
        }
        &.tutor {
          background-image: url(${ImgSvgIconTutorHover});
        }
        &.student {
          background-image: url(${ImgSvgIconStudentHover});
        }
        &.parent {
          background-image: url(${ImgSvgIconParentHover});
        }
      }
      .tit {
        color: var(--point-color);
        font-size: 1rem;
      }
      .txt {
        color: var(--point-color);
      }
    }
  }

  @media screen and (max-width: 640px) {
    & > a {
      flex-direction: column;
      .text-wrap {
        order: 2;
        text-align-last: center;
      }
      .img {
        order: 1;
        width: 2rem;
        height: 2rem;
      }
    }
  }
`;
