import React, { useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Logo from 'components/user/_components/layouts/Logo';
import MainBox from 'components/user/_components/layouts/MainBox';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import Input from 'components/user/_components/common/forms/Input';
import Label from 'components/user/_components/common/forms/Label';
import Header from 'components/user/sign-up/_components/layouts/Header';
import ErrorText from 'components/user/_components/styles/ErrorText';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import SubButton from 'components/user/_components/common/buttons/SubButton';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import { useSelector, useDispatch } from 'react-redux';
import request from 'utils/Request.utils';
import LocalStorage from 'utils/LocalStorage.utils';

/** 회원가입 - 소셜 - 아카데미 Teacher - 코드 입력 페이지 */
export default function SignUpAcademyTeacherAcademyPage() {
  const navigate = useNavigate();
  const academyTeacher = useSelector((state) => state.academyTeacherReducer);
  const userId = academyTeacher.userId;
  const submitParams = {
    userId: academyTeacher.userId,
    loginType: academyTeacher.loginType,
    userEmail: academyTeacher.userEmail,
    userLevel: academyTeacher.userLevel,
    userMobile: academyTeacher.userMobile,
    userName: academyTeacher.userName,
    agree_privacyPolicy: academyTeacher.agree_privacyPolicy,
    agree_termsService: academyTeacher.agree_termsService,
    agree_newsLetter: academyTeacher.agree_newsLetter,
    academyCode: '',
    isNew: true,
  };
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {},
  });

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    console.log('🚀 ~ onValid ~ data:', data);
    // navigate('/signup/complete');
    confirmAcademyCode(data.academyCode);
  };
  const submitAcademyTeacherSignup = (code) => {
    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);
      // navigate('/signup/complete');
      if (response.code === 200) {
        // 토근을 로컬에 저장
        LocalStorage.setItem('userSession', 'accessToken', response.result.token.accessToken);
        LocalStorage.setItem('userSession', 'refreshToken', response.result.token.refreshToken);
        // 저장후 1초뒤 이동
        setTimeout(() => {
          navigate('/signup/complete');
        }, 1000);
      } else {
        // 관리자에게 문의 안내
        alert('Please contact the administrator.');
      }
    };
    submitParams.academyCode = code;
    request.post(request.apiUris.auth.signupAcademyTeacher, submitParams, successHandler);
  };

  const confirmAcademyCode = (code) => {
    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);
      if (response.result.count > 0) {
        submitAcademyTeacherSignup(code);
      } else {
        setError('academyCode', { type: 'validate', message: 'Code is invalid' });
      }
    };
    request.get(request.apiUris.auth.academyUniqueCode, { isNew: true, uniqueCode: code }, successHandler);
  };

  /** 뒤로가기 버튼 핸들러 */
  const handleClickBackButton = () => {
    navigate('/signup/social/academy-teacher/information-review');
  };

  useEffect(() => {
    if (!userId) {
      navigate('/404');
    }
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrap>
      <MainBox className='main-box'>
        <Logo />
        <Header title={'Academy Teacher'} />
        <form onSubmit={handleSubmit(onValid)}>
          <p className='guide'>Enter the Academy Code</p>
          <FormItemWrap>
            <Label className={'red-dot'} htmlFor='academyCode'>
              ACADEMY CODE
            </Label>
            <Input
              id='academyCode'
              register={register('academyCode', {
                required: 'Please enter Academy Code',
              })}
              type='text'
              autoFocus
            />
            {errors.academyCode && <ErrorText>{errors.academyCode.message}</ErrorText>}
          </FormItemWrap>
          <PrimaryButtonArea>
            <SubButton type='button' onClick={handleClickBackButton} title='이전 페이지'>
              Back
            </SubButton>
            <PrimaryButton type='submit' title='제출'>
              Submit
            </PrimaryButton>
          </PrimaryButtonArea>
        </form>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  .guide {
    color: #7f8998;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
  }
  .agree-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    margin-top: 2.5rem;
  }
  .red-dot {
    padding-left: 0.65rem;
    ::before {
      content: '*';
      color: var(--sub-red);
      margin-top: 2px;
      margin-left: -10px;
      position: absolute;
    }
  }
`;
