import styled from 'styled-components';
import { ReactComponent as IconCheck } from 'assets/img/svg/icon_check_01.svg';

/** 커스텀 체크박스 컴포넌트 - DSAT 전반에서 사용되는 체크박스 컴포넌트화 */
export default function CustomCheckbox({ checkBoxId, isChecked, onChange, size = '1.5rem', className = '' }) {
  const handleChangeCheckbox = (e) => {
    // onChange(e.target.checked);
    onChange();
  };

  return (
    <S.CheckboxWrapper onClick={(e) => e.stopPropagation()} $size={size} className={className}>
      <input type='checkbox' id={checkBoxId} checked={isChecked} onChange={handleChangeCheckbox} />
      <label htmlFor={checkBoxId}>
        <IconCheck className='check_icon' />
      </label>
    </S.CheckboxWrapper>
  );
}

const S = {
  CheckboxWrapper: styled.div`
    display: flex;
    width: ${({ $size }) => $size};
    height: ${({ $size }) => $size};
    align-items: center;
    justify-content: center;
    position: relative;

    input[type='checkbox'] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 100%;
      border: 1px solid #d7dadf;
      border-radius: 25%;
      background-color: #ffffff;

      .check_icon {
        width: 100%;
        height: 100%;
        padding: 1px;
        color: white;
        display: none;
      }

      &:checked {
        background-color: #0068bd;
      }
    }

    // 체크 박스 체크 시
    input[type='checkbox']:checked + label {
      background-color: #0068bd;
      border-color: #0068bd;

      .check_icon {
        display: block;
      }
    }
  `,
};
