import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'utils/functions/time/dayjs-config';

import request from 'utils/Request.utils';
import { nvl, removeTags, stringUnEscape } from 'utils/Common.utils';
import Box from 'components/dashboard/_components/common/Box';

export default function BoardPreView({ type, user }) {
  const navigate = useNavigate();

  const moreBtn = () => {
    if (type === 'gate') navigate('/gateplus/news/list');
    else navigate('/dsat/info/list');
  };

  const [boardList, setBoardList] = useState([]);

  const getBoardList = () => {
    let boardType = type === 'gate' ? 'notice' : 'info';
    let preBoardList = [];

    const successHandler = (response) => {
      // console.log(response)
      if (response.code === 200) {
        preBoardList = type === 'gate' ? response.result.newsList : response.result.dsatList;

        setBoardList(preBoardList);
      }
    };

    request.get(`/api/board/${boardType}/pre/list`, null, successHandler);
  };

  useEffect(() => {
    getBoardList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sideListClick = (boardSeq, notiType, url) => {
    // const cleanUrl = url.replace(/<\/?p>/g, '');

    const cleanUrl = stringUnEscape(removeTags(url));

    // console.log("cleanUrl : ", cleanUrl)

    if (type === 'gate') {
      if (notiType === 'Y') window.open(cleanUrl, '_blank');
      else navigate(`/gateplus/news/view?post-num=${boardSeq}`);
    } else {
      if (notiType === 'Y') window.open(cleanUrl, '_blank');
      else navigate(`/dsat/info/view?post-num=${boardSeq}`);
    }
  };

  return (
    <Box className={`box notice ${user !== 'student' && 'full'}`}>
      <div className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_list black'>&nbsp;</i>
          {type === 'gate' ? (
            <>
              GATE <sup>+</sup>&nbsp;News
            </>
          ) : (
            <>SAT Info</>
          )}
        </h2>
        <button className='btn_more' onClick={moreBtn}>
          More
        </button>
      </div>
      <ul className='list'>
        {nvl(boardList) !== '' &&
          boardList.map((item, index) => (
            <li className='item' key={`${type}_${index + 1}`} onClick={() => sideListClick(item.boardSeq, item.notiType, item.contents)}>
              <button className='tit'>{item.title}</button>
              <span className='date'>{dayjs(item.regDate).format('MM. DD. YYYY')}</span>
            </li>
          ))}
      </ul>
    </Box>
  );
}
