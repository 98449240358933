import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { set, useForm } from 'react-hook-form';
import styled from 'styled-components';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import SubButton from 'components/user/_components/common/buttons/SubButton';
import SkipButton from 'components/user/_components/common/buttons/SkipButton';
import MainBox from 'components/user/_components/layouts/MainBox';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import Label from 'components/user/_components/common/forms/Label';
import Input from 'components/user/_components/common/forms/Input';
import ErrorText from 'components/user/_components/styles/ErrorText';
import Select from 'components/user/_components/common/forms/Select';
import Header from 'components/user/sign-up/_components/layouts/Header';
import Logo from 'components/user/_components/layouts/Logo';
import SelectBox from 'components/_common/inputs/SelectBox';
import SchoolLocationSelectBox from 'components/_common/inputs/SchoolLocationSelectBox';
import { useSelector, useDispatch } from 'react-redux';
import request from 'utils/Request.utils';
import LocalStorage from 'utils/LocalStorage.utils';
import { clearReduxStateSocialUser } from 'reducers/auth/action';
import { clearReduxStateAcademyAdmin } from 'reducers/auth/academyadmin.action';
import { nvl } from 'utils/Common.utils';
import {
  clearReduxStateStudent,
  updateReduxStudentSchoolGrade,
  updateReduxStudentSchoolLocation,
  updateReduxStudentSchoolName,
  updateReduxStudentSchoolType,
} from 'reducers/auth/student.action';
import LoadingBar from 'utils/LoadingBar';
/** 회원가입 - 소셜 - 학생 - 학생 정보 입력 페이지 */
export default function SignUpSocialStudentInformationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const student = useSelector((state) => state.studentReducer);
  const stateCustomer = useSelector((state) => state.Customer);
  // redux에서 저장된 데이터 가져오기
  // const storedGrade = useSelector((state) => state.studentReducer.schoolGrade);
  // const storedSchoolLocation = useSelector((state) => state.studentReducer.schoolLocation);
  // const storedSchoolName = useSelector((state) => state.studentReducer.schoolName);
  // const storedSchoolType = useSelector((state) => state.studentReducer.schoolType);
  const [loadingBarActive, setLoadingBarActive] = useState(false);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {},
  });
  // const schoolName = watch('schoolName', storedSchoolName);
  // const grade = watch('grade', storedGrade);

  /** Grade 목록 설정 */
  const gradeList = Array.from({ length: 12 }, (_, index) => ({
    value: String(index + 1),
    name: String(index + 1),
  }));
  const [schoolTypeList, setSchoolTypeList] = useState(); // 학교 종류 목록 상태
  const [locationList, setLocationList] = useState([]); // 학교 위치 목록 상태
  const [schoolType, setSchoolType] = useState(''); // 학교 종류 상태
  const [schoolLocation, setSchoolLocation] = useState(''); // 학교 위치 상태

  /** 학교 분류 목록, 학교 위치 목록 API 얻어오기 */
  const getSchoolTypeList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let commonCodeList = response.result.commonCodeList;
        let locationList = response.result.locationList;

        setSchoolTypeList(
          commonCodeList &&
            commonCodeList
              .filter((i) => i.parentCode === '012000000000000')
              .map((item) => ({
                value: item.commonCode,
                name: item.codeName,
              }))
        );

        setLocationList(locationList);
      }
    };
    request.get(`/api/common/common-code?multiCode=${encodeURI('012000000000000')}&schoolLocationYn=Y`, null, successHandler);
    // request.get(request.apiUris.common.getSchoolLocation, { isNew: true }, successHandler);
  };
  const skipProcess = () => {
    setLoadingBarActive(true);
    updateReduxStudentSchoolGrade('');
    updateReduxStudentSchoolLocation('');
    updateReduxStudentSchoolName('');
    updateReduxStudentSchoolType('');
    const params = {
      userId: student.userId,
      loginType: student.loginType,
      userEmail: student.userEmail,
      userLevel: student.userLevel,
      userMobile: student.userMobile,
      userName: student.userName,
      schoolName: '',
      schoolType: '',
      grade: '',
      schoolLocation: '',
      agree_privacyPolicy: student.agree_privacyPolicy,
      agree_termsService: student.agree_termsService,
      agree_newsLetter: student.agree_newsLetter,
      user_passwd: stateCustomer.userPasswd,
      isNew: true,
    };
    console.log(params);
    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);
      if (response.code === 200) {
        // localStorage.setItem('userId', response.result.userId);
        LocalStorage.setItem('etcSession', 'saveId', nvl(params.userEmail));
        LocalStorage.clearItem('userSession');
        LocalStorage.clearItem('sendParams');
        LocalStorage.clearItem('util');
        LocalStorage.setItem('userSession', 'accessToken', response.result.token.accessToken);
        LocalStorage.setItem('userSession', 'refreshToken', response.result.token.refreshToken);
        // auth 관련 redux 초기화
        dispatch(clearReduxStateSocialUser());
        dispatch(clearReduxStateAcademyAdmin());
        dispatch(clearReduxStateStudent());
        setTimeout(() => {
          setLoadingBarActive(false);
          navigate('/signup/complete');
        }, 500);
      }
    };
    request.post(request.apiUris.auth.signupStudent, params, successHandler);
  };
  /** Form 제출 핸들러 */
  const onValid = (data) => {
    console.log('🚀 ~ onValid ~ data:', data);
    setLoadingBarActive(true);
    // dispatch(updateReduxSocialUserEmail(data.userEmail));
    updateReduxStudentSchoolGrade(data.grade);
    updateReduxStudentSchoolLocation(schoolLocation);
    updateReduxStudentSchoolName(data.schoolName);
    updateReduxStudentSchoolType(schoolType);
    const params = {
      userId: student.userId,
      loginType: student.loginType,
      userEmail: student.userEmail,
      userLevel: student.userLevel,
      userMobile: student.userMobile,
      userName: student.userName,
      schoolName: data.schoolName,
      schoolType: schoolType,
      grade: data.grade,
      schoolLocation: schoolLocation,
      agree_privacyPolicy: student.agree_privacyPolicy,
      agree_termsService: student.agree_termsService,
      agree_newsLetter: student.agree_newsLetter,
      user_passwd: stateCustomer.userPasswd,
      isNew: true,
    };
    console.log('🚀 ~ onValid ~ params:', params);
    // 서버와 통싱하여 데이터 저장

    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);
      if (response.code === 200) {
        // localStorage.setItem('userId', response.result.userId);
        LocalStorage.setItem('etcSession', 'saveId', nvl(data.userEmail));
        LocalStorage.clearItem('userSession');
        LocalStorage.clearItem('sendParams');
        LocalStorage.clearItem('util');
        LocalStorage.setItem('userSession', 'accessToken', response.result.token.accessToken);
        LocalStorage.setItem('userSession', 'refreshToken', response.result.token.refreshToken);
        // auth 관련 redux 초기화
        dispatch(clearReduxStateSocialUser());
        dispatch(clearReduxStateAcademyAdmin());
        dispatch(clearReduxStateStudent());
        setTimeout(() => {
          setLoadingBarActive(false);
          navigate('/signup/complete');
        }, 500);
      }
    };
    request.post(request.apiUris.auth.signupStudent, params, successHandler);
  };

  /** 뒤로가기 버튼 핸들러 */
  const handleClickBackButton = () => {
    navigate('/signup/social/student/information-review');
  };

  /** 셀렉트 박스(SchoolType) 선택 동작 핸들러 */
  const handleSelectChangeSchoolType = (name = '', item) => {
    setSchoolType(item.value);
    setValue(name, item.name);
  };
  /** 셀렉트 박스(Grade) 선택 동작 핸들러 */
  const handleSelectChangeGrade = (name = '', item) => {
    setValue(name, item.name);
  };
  /** 셀렉트 박스(SchoolLocation) 선택 동작 핸들러 */
  const handleSelectChangeSchoolLocation = (name = '', item) => {
    setSchoolLocation(item.commonCode);
    setValue(name, item.schoolLocation);
  };

  useEffect(() => {
    getSchoolTypeList();
    // student redux 에 userId 가 없으면 페이지 404
    if (!student.userId) {
      navigate('/404');
    }
    // setSchoolType(storedSchoolType || '');
    // setSchoolLocation(storedSchoolLocation || '');
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <S.Wrap>
        <MainBox>
          <Logo />
          <Header title={'Student'} />
          <form onSubmit={handleSubmit(onValid)}>
            <p className='guide'>Tells us about your school</p>
            <FormItemWrap>
              <Label htmlFor='schoolName'>School Name</Label>
              <Input id='schoolName' register={register('schoolName')} type='text' autoFocus />
              {/* {errors.schoolName && <ErrorText>{errors.schoolName.message}</ErrorText>} */}
            </FormItemWrap>

            <div className='row-input-wrap'>
              <FormItemWrap>
                <Label htmlFor='schoolType'>School Type</Label>
                <SelectBox
                  register={register('schoolType')}
                  handleChange={handleSelectChangeSchoolType}
                  optionList={schoolTypeList}
                  autoComplete='off'
                  id={'schoolType'}
                />
                {/* <Select id='schoolType' register={register('schoolType', {})}>
                <option>1</option>
                <option>2</option>
              </Select> */}
                {errors.schoolType && <ErrorText>{errors.schoolType.message}</ErrorText>}
              </FormItemWrap>
              <FormItemWrap>
                <Label htmlFor='userGrade'>Grade</Label>
                {/* <Select id='userGrade' autoComplete='off' register={register('userGrade', {})}></Select> */}
                <SelectBox register={register('grade')} handleChange={handleSelectChangeGrade} optionList={gradeList} autoComplete='off' name={'grade'} />
                {errors.userGrade && <ErrorText>{errors.userGrade.message}</ErrorText>}
              </FormItemWrap>
            </div>

            <FormItemWrap>
              <Label htmlFor='schoolLocation'>School Location</Label>
              <SchoolLocationSelectBox
                register={register('schoolLocation')}
                handleChange={handleSelectChangeSchoolLocation}
                placeholder='Enter school location'
                optionList={locationList}
                name={'schoolLocation'}
              />
              {/* <Select id='schoolLocation' register={register('schoolLocation', {})}>
              <option>1</option>
              <option>2</option>
            </Select> */}
              {errors.schoolLocation && <ErrorText>{errors.schoolLocation.message}</ErrorText>}
            </FormItemWrap>

            <PrimaryButtonArea>
              <SubButton type='button' onClick={handleClickBackButton} title='이전 페이지'>
                Back
              </SubButton>
              <PrimaryButton type='submit' title='다음 페이지'>
                Next
              </PrimaryButton>
            </PrimaryButtonArea>
            <div className='skipBtnArea'>
              <SkipButton type='button' onClick={skipProcess} title='Skip'>
                Skip
              </SkipButton>
            </div>
          </form>
        </MainBox>
      </S.Wrap>
      {loadingBarActive && <LoadingBar type={'spin'} color={'#000000'} />}
    </>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .guide {
    color: #7f8998;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
  }
  .row-input-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    & > *:nth-child(1) {
      flex: 1;
    }
    & > *:nth-child(2) {
      flex: 0 0 25%;
    }
  }
  .skipBtnArea {
    > button {
      width: 100px;
      border-radius: 0.375rem;
      font-size: 1rem;
      padding: 1.25rem;
      border: none;
      color: #7f8998;
    }
  }
`;
