import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import IconKebabMenu from 'components/_common/button/IconKebabMenu';
import useOutsideClick from 'hooks/useOutsideClick';

/** AP/Grading/Submission 페이지에서 드롭 다운 기능 및 스타일 용 컴포넌트 */
export default function DropDownButtonWrap({ title, dropdownChildren, ...rest }) {
  /** 드롭다운 영역 노출 여부 */
  const [isView, setIsView] = useState(false);

  /** 드롭다운 외부 클릭 이벤트 관리 용 ref */
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setIsView(false), isView);

  /** 드롭다운 버튼 핸들러 */
  const handleClick = () => {
    setIsView((current) => !current);
  };

  return (
    <S.Wrap ref={dropdownRef} {...rest}>
      <button type='button' title='Open/Close' onClick={handleClick} className='cover_button'>
        <IconKebabMenu />
        {title}
      </button>
      {isView && <div className='dropdown_area'>{dropdownChildren}</div>}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    position: relative;
    .cover_button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      color: #111111;
      font-size: 1.125rem;
      font-weight: 500;
    }
    .dropdown_area {
      position: absolute;
      top: 4rem;
      left: 0;
      z-index: 10;
      padding: 0.625rem;
      border-radius: 0.625rem;
      background: #ffffff;
      box-shadow: 0px 4px 15.3px -2px rgba(0, 0, 0, 0.25);
      width: fit-content;
    }
  `,
};
