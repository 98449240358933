import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QuestionArea from './QuestionArea';
import ReviewPopup from './ReviewPopup';
import EnglishQuestion from './EnglishQuestion';

import { MathJax } from 'better-react-mathjax';

/** 영어 시험 화면 본문 영역 컴포넌트 */
function ExamBody({
  setIsDragged,
  setAnnotateName,
  setAnnotateMemo,
  annotationId,
  setAnnotationId,
  setIsClickAnnotate,
  tryAnswerRef,
  setIsLoading,
  isLoading,
}) {
  const dispatch = useDispatch();
  /** 초기 마운트를 알리는 state ( useEffect로 첫 마운트때 변경 ) */
  const [isInitialRender, setIsInitialRender] = useState(true);

  const isRenderMathJaxRef = useRef(false);
  const timerRef = useRef(null);

  const [questionArea, setQuestionArea] = useState(null);

  /** 시험 정보 전역 상태 */
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);

  /** 문제 넓이 조절 */
  const [wrap, setWrap] = useState({
    leftArrow: false,
    rightArrow: false,
  });

  /** 문제 넓이 조절 버튼 핸들러 */
  const wrapButtonHandler = useCallback((arrow) => {
    setWrap((prev) => {
      if (arrow === 'left') {
        if (prev.leftArrow) return { ...prev, leftArrow: false };
        if (!prev.leftArrow && prev.rightArrow) return { ...prev, rightArrow: false };
        return { ...prev, leftArrow: true };
      } else {
        if (prev.rightArrow) return { ...prev, rightArrow: false };
        if (!prev.rightArrow && prev.leftArrow) return { ...prev, leftArrow: false };
        return { ...prev, rightArrow: true };
      }
    });
  }, []);

  // 초기 시험창 진입시 MathJax 렌더링용 useEffect - 최초 진입시 MathJax 랜더링이 발생하지 않아 추가함
  useEffect(() => {
    // 즉시 State 업데이트시 종종 씹히는 문제 있어 setTimeout 추가
    const initRender = setTimeout(() => {
      if (isInitialRender) setIsInitialRender(false);
    }, 300);

    return () => clearTimeout(initRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** MathJax 타입캐스팅 결과에 따라 타이머 갱신 + 상태 변경하는 함수 */
  const updateMathJaxStatus = useCallback((value) => {
    isRenderMathJaxRef.current = value;

    const setMathJaxTimer = (currentValue) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        if (currentValue) {
          // console.log('🚀 MathJax 타입캐스팅 성공 🚀');
        } else {
          console.log('🚫 MathJax 타입캐스팅 실패 🚫');
          dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: true } });
        }
      }, 1500);
    };

    setMathJaxTimer(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 문제 이동시 MathJax 관련 상태 초기화
  useEffect(() => {
    if (!stateExamComponents.reviewDisplay) {
      updateMathJaxStatus(false);
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: false } });
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamInfo.currentNum, stateExamComponents.reviewDisplay]);

  // 타이머와 관계없는 속성들이 바뀔때만 렌더링
  useEffect(() => {
    setQuestionArea(
      <MathJax
        style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}
        className={`pop_body ${wrap.leftArrow !== wrap.rightArrow ? 'change_wrap_width' : ''}`}
        hideUntilTypeset='first'
        onTypeset={() => updateMathJaxStatus(true)}>
        <EnglishQuestion
          wrap={wrap}
          wrapButtonHandler={wrapButtonHandler}
          setIsDragged={setIsDragged}
          setAnnotateName={setAnnotateName}
          setAnnotateMemo={setAnnotateMemo}
          annotationId={annotationId}
          setAnnotationId={setAnnotationId}
          setIsClickAnnotate={setIsClickAnnotate}
        />
        <QuestionArea wrap={wrap} wrapButtonHandler={wrapButtonHandler} tryAnswerRef={tryAnswerRef} setIsLoading={setIsLoading} isLoading={isLoading} />
      </MathJax>
    );
  }, [
    isInitialRender,
    updateMathJaxStatus,
    wrap,
    wrapButtonHandler,
    setIsDragged,
    setAnnotateName,
    setAnnotateMemo,
    annotationId,
    setAnnotationId,
    setIsClickAnnotate,
    tryAnswerRef,
    setIsLoading,
    isLoading,
  ]);

  return !stateExamComponents.reviewDisplay ? (
    questionArea
  ) : (
    <section className='pop_body'>
      <ReviewPopup />
    </section>
  );
}

export default ExamBody;
