import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

//component
import ReviewNotes from 'components/student/_components/modals/ReviewNotes';

//img
import ImgLnbDashboard from '../assets/img/logo_white.png';
import ImgLnbCalendar from '../assets/img/lnb_calendar.png';
import ImgLnbTest from '../assets/img/lnb_test.png';
import ImgLnbMessage from '../assets/img/lnb_message.png';
// import ImgLnbClasses from 'assets/img/lnb_classes.png';
import { ReactComponent as ImgLnbClasses } from 'assets/img/svg/icon_classes_01.svg';
import { ReactComponent as ImgLnbStudents } from 'assets/img/svg/icon_users_01.svg';
import ImgLnbHome from '../assets/img/lnb_home.png';
import ImgLnbNote from '../assets/img/lnb_note.png';
import ImgLnbSchedule from '../assets/img/lnb_schedule.png';
import ImgLnbTestTaken from '../assets/img/lnb_test_taken.png';

// hook
import useUserLevels from '../hooks/useUserLevels.js';

import request from '../utils/Request.utils';
import FreezingKeyModal from 'components/_common/modals/FreezingKeyModal';
import LoadingBar from 'utils/LoadingBar';
import styled from 'styled-components';

export default function Lnb() {
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  const isB2c = useLevels.isStudent; // || useLevels.isParent;

  let { pathname } = useLocation();
  pathname = pathname.toLowerCase();

  //console.log("학생 페이지에서", pathname, pathname.indexOf("/test/set"))

  const [ReviewNotesActive, setReviewNotesActive] = useState(false); //review note popup

  const isStudentOrParent = useLevels.isStudent || useLevels.isParent;

  // set test 페이지로 이동
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /** 닫기 버튼 */
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const goToSetTest = () => {
    let url = isB2c ? '/com/tests/set/selection/config' : '/com/tests/set/selection/type';

    if (!isB2c) {
      setIsLoading(true);
      const successHandler = (response) => {
        // console.log('🚀 ~ successHandler ~ response:', response);
        if (response.code === 200) {
          const { isFreezing } = response.result;

          setIsLoading(false);

          if (!isFreezing) navigate(url);
          else setShowPopup(true);
        }
      };

      request.get('/api/academy/gatekey/freezing', null, successHandler).catch((error) => {
        console.log('key freezing 에러 발생! : ', error);
      });
    } else navigate(url);
  };
  // set test 페이지로 이동 끝

  return (
    <>
      <S.Wrap className='com_lnb'>
        <Link
          to={`${useLevels.isStudent ? '/student/dashboard' : useLevels.isParent ? '/parent/dashboard' : '/academy/dashboard'}`}
          className='menu'
          title='페이지 이동'>
          {/*link to="" 페이지로 이동시 addClass active*/}
          <img src={ImgLnbHome} alt='Home' className='icon' />
          Home
        </Link>

        {useLevels.isParent || (
          <button className={`menu ${pathname.includes('tests/set') ? 'active' : ''}`} onClick={goToSetTest}>
            <img src={ImgLnbTest} alt='Test' className='icon' />
            Set Tests
          </button>
        )}

        {isStudentOrParent && (
          <button type='button' className={`menu ${ReviewNotesActive ? 'active' : ''} `} onClick={() => setReviewNotesActive(true)} title='페이지 이동'>
            {/*Student Dashboard*/}
            <img src={ImgLnbNote} alt='Review Notes' className='icon' />
            Review Notes
          </button>
        )}
        {
          <Link to='/com/tests/calendar' className={`menu ${pathname === '/com/tests/calendar' && !ReviewNotesActive ? 'active' : ''} `} title='페이지 이동'>
            {/*Students/Classes/Tests/Test Scheduled/Test Taken/Calendar/Score/Set Tests/Student Dashboard */}
            <img src={ImgLnbCalendar} alt='Calendar' className='icon' />
            Calendar
          </Link>
        }
        <hr className='divider' />
        {pathname.indexOf('/student') >= 0 && !isStudentOrParent && (
          <>
            <Link to='/com/students' className={`menu ${pathname === '/com/students' ? 'active' : ''} `} title='페이지 이동'>
              {/*Students, Classes */}
              <ImgLnbStudents />
              Students
            </Link>
            <Link to='/com/students/classes' className={`menu ${pathname === '/com/students/classes' ? 'active' : ''} `} title='페이지 이동'>
              {/*Students, Classes */}
              <ImgLnbClasses />
              Classes
            </Link>
          </>
        )}
        {pathname.indexOf('/tests/set') < 0 && pathname.indexOf('/tests') >= 0 && !isStudentOrParent && (
          <Link to='/com/tests/scheduled' className={`menu ${pathname === '/com/tests/scheduled' ? 'active' : ''} `} title='페이지 이동'>
            {/*Tests, Test Scheduled, Test Taken, Calendar*/}
            <img src={ImgLnbSchedule} alt='Schedule' className='icon' />
            Test Scheduled
          </Link>
        )}
        {pathname.indexOf('/tests/set') < 0 && pathname.indexOf('/tests') >= 0 && !isStudentOrParent && (
          <Link to='/com/tests/taken' className={`menu ${pathname === '/com/tests/taken' ? 'active' : ''} `} title='페이지 이동'>
            {/*Tests, Test Scheduled, Test Taken, Calendar*/}
            <img src={ImgLnbTestTaken} alt='Test Taken' className='icon' />
            Test Taken
          </Link>
        )}
        <Link to={`${useLevels.isStudent ? '/student/dashboard' : '/academy/dashboard'}`} className='logo' title='페이지 이동'>
          <img src={ImgLnbDashboard} alt='main' className='icon' />
        </Link>
      </S.Wrap>
      {ReviewNotesActive && <ReviewNotes setReviewNotesActive={setReviewNotesActive} />}
      {showPopup && <FreezingKeyModal hideModal={() => setShowPopup(false)} />}
      {isLoading && <LoadingBar type={'spin'} color={'#000000'} />}
    </>
  );
}

const S = {
  Wrap: styled.nav`
    background: var(--point-color);
    height: 100vh;
    position: fixed;
    z-index: 6;
    left: 0;
    top: 0;
    width: 3.75rem;
    text-align: center;
    padding-block: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .logo {
      margin-top: auto;
      img {
        width: 35px;
      }
    }
    .menu {
      display: block;
      color: #fff;
      font-size: 11px;
      min-height: 61px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0.5;
      svg,
      .icon {
        width: 1.75rem;
        margin-bottom: 6px;
      }
      &.message {
        margin-top: auto;
      }
      &:hover {
        opacity: 1;
      }
      &.active {
        opacity: 1;
        box-shadow: 0px 3px 5px 0px #0000001a;
        border-left: 3px solid #fff;
        width: calc(100% + 3px);
        margin-right: -3px;
        background-color: #107fd9;
        padding-block: 10px;
      }
    }
    .divider {
      border: 0;
      margin-inline: 16.25px;
      opacity: 0.3;
      height: 1px;
      width: calc(100% - 16.25px * 2);
      background-color: #fff;
    }
  `,
};
