import React from 'react';
import styled from 'styled-components';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

import { ReactComponent as ImgSvgIconX } from 'assets/img/svg/x-close.svg';

/** 허락을 구하는 모달 컴포넌트 */
export default function ConfirmModal({ handleCancel, handleConfirm, children }) {
  return (
    <SimpleModalBase onEscape noPad>
      <S.Wrap>
        <div className='modal_title_container'>
          <button type='button' onClick={handleCancel} title='Close' className='close_button'>
            <ImgSvgIconX />
          </button>
        </div>
        <div className='modal_text_container'>
          <p>{children}</p>
        </div>
        <div className='modal_btn_container'>
          <button type='button' onClick={handleCancel}>
            Cancel
          </button>
          <button type='button' onClick={handleConfirm} className='btn_fill'>
            Confirm
          </button>
        </div>
      </S.Wrap>
    </SimpleModalBase>
  );
}

const S = {
  Wrap: styled.div`
    padding: 1.25rem;
    .modal_title_container {
      justify-content: flex-end;
      .close_button {
        svg {
          color: #111111;
          width: 1.25rem;
        }
      }
    }

    .modal_text_container {
      padding: 0.75rem 0 2rem;
      font-size: 1.25rem;
      font-weight: 600;
    }

    .modal_btn_container {
      button {
        height: 2.75rem;
        padding: 1.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        flex: 1 0 0;
      }
    }
  `,
};
