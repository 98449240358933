import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

// hook
import useUserLevels from 'hooks/useUserLevels';
import useResponsiveFontSize from 'hooks/useResponsiveFontSize';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

// Images
import ImgVisual01 from 'assets/img/main_bg.png';

import MainLink from 'components/_common/button/_MainLinks';
import styled from 'styled-components';

/** 아카데미 대시보드 상단 비주얼 영역 컴포넌트 */
export default function MainVisualArea() {
  /** useResponsiveFontSize 사용 코드(학원 이름 크기 조절) */
  const textRef = useRef(null);

  const fontSize = useResponsiveFontSize(textRef, 48);

  const userInfo = request.tokenDecoder();

  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);

  const stateMenuManager = useSelector((state) => state.MenuManager);

  const isAcademyMember = userInfo.ecSeq > 0;

  const displayName = isAcademyMember
    ? nvl(stateMenuManager?.academyName) !== ''
      ? stateMenuManager?.academyName
      : userInfo?.companyName
    : nvl(stateMenuManager?.userName) !== ''
      ? stateMenuManager?.userName
      : userInfo?.userName;

  const displayImg = isAcademyMember
    ? userInfo.academyUpFileSeq > 0 || nvl(stateMenuManager?.academyProfileImage) !== ''
      ? `${process.env.REACT_APP_API_URL}/api/common/profile/upload/academyProfile/${userInfo.ecSeq}?${Date.now()}`
      : null
    : userInfo.upFileSeq > 0
      ? `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${userInfo.userSeq}?${Date.now()}`
      : null;

  return (
    <S.Wrap>
      <section className='com_visual_area'>
        <div className='com_center_wrap'>
          <div className='right'>
            <img src={ImgVisual01} alt='illustration' />
          </div>
        </div>
      </section>
      <div className='com_center_wrap'>
        <section className='com_visual_text'>
          <div className='tit'>
            <div className='com_profile_img T big-profile-img'>
              {useLevels.isAcademy || useLevels.isTutor || useLevels.isTeacher ? (
                userInfo.academyUpFileSeq > 0 || nvl(stateMenuManager?.academyProfileImage) !== '' ? (
                  <img className='profile-img-background' src={displayImg} alt='프로필이미지' />
                ) : (
                  <span className='name'>{displayName[0]}</span>
                )
              ) : userInfo.upFileSeq > 0 ? (
                <img className='profile-img-background' src={displayImg} alt='프로필이미지' />
              ) : (
                <span className='name'>{displayName[0]}</span>
              )}
            </div>
            Welcome,
            <span className='dashboard-text-section company-name-section'>
              <b
                className='point name'
                ref={textRef}
                style={{
                  fontSize: `${fontSize}px`,
                  visibility: fontSize ? 'visible' : 'hidden',
                }}>
                &nbsp;{displayName}!
              </b>
            </span>
          </div>
        </section>
        <MainLink />
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    overflow: hidden;
  `,
};
