import { useState } from 'react';
import Calendar from 'react-calendar';
import dayjsTZ from 'utils/functions/time/dayjs-config';

/**
 * SetTest/Review 페이지의 TestListItem 컴포넌트에 있는 달력 모달 컴포넌트
 * @description
 * 🔍 검색 키워드 - #Calendar #com/tests/set/review
 */
const PopupCalendarPeriod = ({ tit, setUpdateStartDate, setUpdateCart }) => {
  const [viewType, setViewType] = useState(false);
  const [date, setDate] = useState();
  const changeDate = (e) => {
    setUpdateCart(true);
    const DateFormat = dayjsTZ(e).format('MM.DD.YYYY') + '~' + dayjsTZ(e).add(6, 'day').format('MM.DD.YYYY');
    setUpdateStartDate(dayjsTZ(e).format('YYYY-MM-DD'));
    setDate(DateFormat);
    setViewType(false);
  };
  const handleCalendar = (e) => {
    e.stopPropagation();
    setViewType((prev) => !prev);
  };
  return (
    <div className='com_calendar_wrap'>
      <input
        type='text'
        className='input datepicker'
        onClick={handleCalendar}
        readOnly
        placeholder={tit}
        // value={date}
      />
      {viewType && (
        <div className='PopupCalendar'>
          <div className='PopupCalendar_header'>
            <button type='button' className='svg_icon btn_pop_close black' onClick={handleCalendar}>
              &nbsp;
            </button>
          </div>
          <Calendar
            className='calendar'
            onChange={changeDate}
            // 타임존에 맞게 날짜 표시 변경
            formatDay={(locale, date) => dayjsTZ(date).format('DD')}
            // 타임존에 맞게 요일 표시 변경
            formatShortWeekday={(locale, date) => dayjsTZ(date).format('ddd')}
            locale='en-EN'
          />
        </div>
      )}
    </div>
  );
};

export default PopupCalendarPeriod;
