import { ReactComponent as ImageSvgLogoGoogle } from 'assets/img/svg/logo/logo-symbol-google-01.svg';
import styled from 'styled-components';
export default function UserDetails(props) {
  return (
    <S.Wrap>
      <ul className='input_area'>
        {props.renderingData.map((item, index) => {
          return (
            <li className='item' key={item.value + index}>
              <div className='tit_field'>{item.name}</div>
              <div className={`${item.loginType === 'GOOGLE' ? 'google-div' : 'input_field'}`}>
                {item.loginType === 'GOOGLE' && (
                  <S.GoogleLogo>
                    <ImageSvgLogoGoogle />{' '}
                  </S.GoogleLogo>
                )}
                <input type='text' defaultValue={item.value} readOnly />
              </div>
            </li>
          );
        })}
      </ul>
    </S.Wrap>
  );
}
const S = {
  Wrap: styled.div`
    .input_field {
      border: 1px solid #d2dbe2;
      border-radius: 0.25rem;
    }
    .google-div {
      border: 1px solid #d2dbe2;
      border-radius: 0.25rem;
      display: flex;
      padding: 0 1.25rem;
      width: calc(100% - 193px);
    }
  `,

  GoogleLogo: styled.div`
    display: flex;
    width: 1.2rem;
  `,
};