//#region    ////////// components
import EquationsPanel from './_components/EquationsPanel';
import EquationsPanelContentContainer from './_components/EquationsPanelContentContainer';
import EquationsList from './_components/EquationsList';
//#endregion ////////// components

//#region    ////////// assets
import assignmentDisplayInput1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_principles/assignment_display_input_1.png';
import arithmeticOperations1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_principles/arithmetic_operations_1.png';
import relationalBooleanOperators1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_principles/relational_boolean_operators_1.png';
import selection1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_principles/selection_1.png';
import iteration1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_principles/iteration_1.png';
import listOperations1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_principles/list_operations_1.png';
import proceduresProcedureCalls1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_principles/procedures_procedure_calls_1.png';
import robot1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_principles/robot_1.png';
//#endregion ////////// assets

/** computer science principles 레퍼런스 시트 수식 컴포넌트 */
export default function ComputerSciencePrinciplesEquations() {
  return (
    <EquationsList>
      <EquationsPanel
        panelHeaderText='Assignment, Display, and Input'
        PanelContent={<EquationsPanelContentContainer contents={[assignmentDisplayInput1]} containerHeight='39rem' />}
      />
      <EquationsPanel
        panelHeaderText='Arithmetic Operations and Numeric Procedures'
        PanelContent={<EquationsPanelContentContainer contents={[arithmeticOperations1]} containerHeight='42rem' />}
      />
      <EquationsPanel
        panelHeaderText='Relational and Boolean Operators'
        PanelContent={<EquationsPanelContentContainer contents={[relationalBooleanOperators1]} containerHeight='54rem' />}
      />
      <EquationsPanel panelHeaderText='Selection' PanelContent={<EquationsPanelContentContainer contents={[selection1]} containerHeight='61rem' />} />
      <EquationsPanel panelHeaderText='Iteration' PanelContent={<EquationsPanelContentContainer contents={[iteration1]} containerHeight='48rem' />} />
      <EquationsPanel
        panelHeaderText='List Operations'
        PanelContent={<EquationsPanelContentContainer contents={[listOperations1]} containerHeight='140rem' />}
      />
      <EquationsPanel
        panelHeaderText='Procedures and Procedure Calls'
        PanelContent={<EquationsPanelContentContainer contents={[proceduresProcedureCalls1]} containerHeight='67rem' />}
      />
      <EquationsPanel panelHeaderText='Robot' PanelContent={<EquationsPanelContentContainer contents={[robot1]} containerHeight='48rem' />} />
    </EquationsList>
  );
}
