import React from 'react';
//img
import IconOk from 'assets/img/icon_ok.svg';

/** 학생 삭제 완료 팝업 */
export default function PopSuccessfullyDeleted({ deleteClassAlert, setDeleteClassAlert }) {
  return (
    <div className={`com_popup type_alert ${deleteClassAlert ? 'active' : ''}`}>
      <div className='pop_container'>
        <div className='pop_body ok_contents'>
          <div
            className='icon'
            style={{
              marginTop: '0',
            }}>
            <img
              src={IconOk}
              style={{
                height: '4.44rem',
                marginBottom: '2rem',
              }}
              alt='icon'
            />
          </div>
          <p className='alert_title'>Successfully deleted</p>
        </div>
        <div className='pop_footer com_btn_wrap'>
          <button className='com_btn point xl full' onClick={() => setDeleteClassAlert(false)}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
