import React from 'react';
import { nvl } from 'utils/Common.utils';
import SectionTitle from '../../../common/SectionTitle';
import styled from 'styled-components';
import Box from '../../../common/Box';
import ProfileImage from 'components/_common/ProfileImage';

/** "/com/score" 페이지의 "ScoreStats" 영역 (tap 에 따라서 나타나는 영역)의 "Percentile within Gate+ and Grade Change from Past tests" 영역 컴포넌트 */
export default function PercentileWithinGatePlusAndGradeChangeFromPastTests({
  studentPercentileGradeAverage,
  currentSubject,
  selectedSubject,
  setSelectedSubject,
  selectedStudentSeq,
  setSelectedStudentSeq,
}) {
  const ChangeSubject = (e) => {
    // setSubjectTable(prev => !prev);
    if (currentSubject !== 'F') return;
    const eventSubject = e.target.checked ? 'M' : 'E';
    setSelectedSubject(eventSubject);
  };

  const isChecked = selectedSubject === 'M' ? true : false;

  return (
    <S.Wrap className='element'>
      <Box className='main_box'>
        <div className='tit_area'>
          <SectionTitle className='main_tit'>Gate+ Percentile and Grade Change from Past Tests</SectionTitle>
          <div className='com_subject_switch__wrap'>
            <input
              type='checkbox'
              id='subjectSwitch'
              className={`com_subject_switch ${currentSubject !== 'F' ? 'disabled' : ''}`}
              onChange={ChangeSubject}
              checked={isChecked}
              title='Change Subject'
            />
          </div>
        </div>
        <div className={`com_list_box ${isChecked ? 'math' : 'rw'}`}>
          <div className='scroll'>
            <table className='list'>
              <thead className='sticky top'>
                <tr>
                  <th></th>
                  <th>Student Name</th>
                  <th className='alignC'>Percentile</th>
                  <th className='alignC'>3 tests</th>
                  <th className='alignC'>5 tests</th>
                  <th className='alignC'>10 tests</th>
                </tr>
              </thead>
              <tbody>
                {studentPercentileGradeAverage &&
                  studentPercentileGradeAverage?.map((item, idx) => {
                    return (
                      <tr
                        key={`percentile_${idx}`}
                        onClick={() => {
                          setSelectedStudentSeq(item.userSeq);
                        }}
                        className={selectedStudentSeq === item.userSeq ? 'active' : ''}>
                        <td>
                          <ProfileImage
                            size='1.5rem'
                            userInfo={{
                              userName: item.userName,
                              userSeq: item.userSeq,
                              upFileSeq: item.upFileSeq,
                            }}
                          />
                        </td>
                        <td>
                          <span className='name'>{item.userName}</span>
                        </td>
                        <td className='alignC data_field'>{item.all}</td>
                        <td className={`alignC data_change ${item.tests3 >= 0 ? 'plus' : 'minus'}`}>{nvl(item.tests3) !== '' ? item.tests3 : '-'}</td>
                        <td className={`alignC data_change ${item.tests5 >= 0 ? 'plus' : 'minus'}`}>{nvl(item.tests5) !== '' ? item.tests5 : '-'}</td>
                        <td className={`alignC data_change ${item.tests10 >= 0 ? 'plus' : 'minus'}`}>{nvl(item.tests10) !== '' ? item.tests10 : '-'}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    width: calc(50% - 15px);
    .main_box {
      padding: 1.25rem 1.5rem;
      height: 100%;
    }

    .tit_area {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.75rem;
      .main_tit {
        font-size: 1.125rem;
      }
    }

    .com_subject_switch__wrap {
      position: relative;
    }
    .com_subject_switch {
      width: 69px;
      height: 28px;
      display: inline-block;
      -webkit-appearance: none;
      background-color: var(--rw);
      border-radius: 50px;
      position: absolute;
      right: 0;
      top: 0%;
      transition: all 0.3s ease;
      border: none;
      cursor: pointer;
      &:after {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        transition: all 0.3s ease;
      }
      &:before {
        content: 'RW';
        position: absolute;
        right: 15px;
        color: var(--rw-text);
        top: 50%;
        transform: translateY(-50%);
        font-weight: 500;
        font-size: 12px;
      }
      &:checked[type='checkbox'] {
        background-color: var(--math);
        transition: all 0.3s ease;
      }
      &:checked[type='checkbox']:before {
        content: 'MATH';
        color: var(--math-text);
        left: 6px;
      }
      &:checked[type='checkbox']:after {
        right: 4px;
        left: auto;
        transition: all 0.3s ease;
      }
      &.disabled {
        cursor: not-allowed;
      }
    }
  `,
};
