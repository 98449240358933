import { useObfuscatedSearchParam, getValidParam } from 'hooks/useObfuscatedSearchParam';
import { useParams } from 'react-router-dom';
import { SCORE_PARAMS } from 'utils/constants';
import SessionStorage from 'utils/SessionStorage.utils.js';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';

/** QuestionsStatisticsSection에서 mode가 student 일때 노출되는 Table */
export default function StudentQuestionsStatsTable({ selectedModuleChoiceRate, setForceFetch }) {
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** 전체 score에서 추적중인 Class 시퀀스 */
  const queryCurScoreClassSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_CLASS_SEQ);

  const getClassForContentDomain = (contentDomain) => {
    if (contentDomain === '008001000000000') return 'state01 pointer';
    else if (contentDomain === '008002000000000') return 'state02 pointer';
    else if (contentDomain === '008003000000000') return 'state03 pointer';
    else if (contentDomain === '008004000000000') return 'state04 pointer';
    else if (contentDomain === '009001000000000') return 'state01 pointer';
    else if (contentDomain === '009002000000000') return 'state02 pointer';
    else if (contentDomain === '009003000000000') return 'state03 pointer';
    else if (contentDomain === '009004000000000') return 'state04 pointer';

    return '';
  };

  const questionCheck = (e, item) => {
    SessionStorage.clearItem('review');
    SessionStorage.setItem('review', 'classSeq', getValidParam(queryCurScoreClassSeq));

    window.open(`/student/review?viewQuestion=${encodeURIComponent(item.uthSeqStr + 'GATEPLUS' + item.questionSeqStr)}`, '_blank');
  };

  // 리뷰 팝업창에서 북마크 클릭시 호출
  window.getParentQuestion = () => {
    setForceFetch((prev) => !prev);
  };

  return (
    <table className='table'>
      <thead>
        <tr>
          <th className='first'>Question No.</th>
          {selectedModuleChoiceRate.map((item, index) => (
            <th key={`${item.questionOrder}-${index}`} className={getClassForContentDomain(item.contentDomain)} onClick={(e) => questionCheck(e, item)}>
              {item.questionOrder}
              {item.bookmarkYn === 'Y' && <i className='svg_icon icon_bookmark_on'>&nbsp;</i>}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* 241129 Trello 카드 "335. 관리자쪽 Score 추가 요청사항" 참고 */}
        {/* <tr>
          <td>Difficulty</td>
          {selectedModuleChoiceRate.map((item, index) => {
            return <td key={`${item.difficulty}-${index}`}>{item.difficulty}</td>;
          })}
        </tr> */}
        <tr>
          <td className='first'>Class Average</td>
          {selectedModuleChoiceRate.map((item, index) => (
            <td key={`${item.correctAnswerRate}-${index}`} className={item.correctAnswerRate <= 50 ? 'rate01' : item.correctAnswerRate <= 75 ? 'rate02' : ''}>
              {item.correctAnswerRate}%
            </td>
          ))}
        </tr>
        <tr>
          <td>Result</td>
          {selectedModuleChoiceRate.map((item, index) => (
            <td key={`${item.isCorrect}-${index}`}>
              {item.isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}
