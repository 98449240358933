import styled from 'styled-components';
import { ReactComponent as ImgSvgX } from 'assets/img/svg/x-close.svg';

/** 각종 모달 상단에 위치하는 버튼 컴포넌트 */
export default function ModalTitleBtn({ onClick = () => console.log('모달 버튼 클릭됨') }) {
  return (
    <S.ModalTitleBtn onClick={onClick}>
      <ImgSvgX />
    </S.ModalTitleBtn>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  ModalTitleBtn: styled.button`
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    border: 1px solid transparent; // 호버 안했을떄
    svg {
      width: 1.25rem;
      height: 1.25rem;
      color: #616161;
    }
    &:hover {
      svg {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        color: black;
      }
      border-color: #0068bd;
    }
  `,
};
