import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import AnswerChoiceItem from './AnswerChoiceItem';

import { EDITOR_CONSTANT } from '../_constant';

/** 답안 선택지 (Answer Choice) 리스트 컴포넌트 */
export default function AnswerChoiceList({ editorState, editorStateUpdater, isNavigating }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [answerChoiceInfo, setAnswerChoiceInfo] = useState(editorState[EDITOR_CONSTANT.answerChoiceInfo]);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 업데이트된 보기 데이터 저장 */
  const updateAnswerChoiceInfo = (updatedInfo) => {
    if (JSON.stringify(answerChoiceInfo) !== JSON.stringify(updatedInfo)) {
      setAnswerChoiceInfo(updatedInfo);
      editorStateUpdater(EDITOR_CONSTANT.answerChoiceInfo, updatedInfo);
    }
  };

  /** answerChoice(보기 데이터) 변경 핸들러 */
  const handleAnswerChoiceContentChange = (targetIndex, value) => {
    const updatedChoices = answerChoiceInfo.answerChoices.map((choice, idx) => (idx === targetIndex ? { ...choice, content: value } : choice));

    // 변경된 보기 데이터 && 기존 보기 데이터를 합쳐 업데이트
    updateAnswerChoiceInfo({ ...answerChoiceInfo, answerChoices: updatedChoices });
  };

  /** answerChoiceInfo 변경 핸들러 */
  const handleAnswerChoiceInfoChange = (info) => {
    // 변경된 보기 데이터 && 기존 보기 데이터를 합쳐 업데이트
    updateAnswerChoiceInfo({ ...answerChoiceInfo, ...info });
  };

  /** 보기 삭제 핸들러 */
  const handleDeleteAnswerChoice = (targetIndex) => {
    const updatedChoices = answerChoiceInfo.answerChoices.filter((_, idx) => idx !== targetIndex);

    updateAnswerChoiceInfo({ ...answerChoiceInfo, answerChoices: updatedChoices });
    editorStateUpdater(EDITOR_CONSTANT.currentQuestion, { ...editorState[EDITOR_CONSTANT.currentQuestion], answer: [] });
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  // 상위에서 state 변경시 컴포넌트의 state 도 변경
  useEffect(() => {
    if (isNavigating) return;
    // 현재 상태와 새로운 상태를 비교
    const newAnswerChoiceInfo = editorState[EDITOR_CONSTANT.answerChoiceInfo];

    if (JSON.stringify(answerChoiceInfo) !== JSON.stringify(newAnswerChoiceInfo)) setAnswerChoiceInfo(newAnswerChoiceInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState[EDITOR_CONSTANT.answerChoiceInfo], isNavigating]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <S.AnswerChoiceContainer>
      <S.SectionTitle>Answer Choice</S.SectionTitle>

      <S.AnswerChoiceList>
        {answerChoiceInfo.answerChoices.map((item, index) => (
          <AnswerChoiceItem
            key={index}
            answerChoice={item}
            answerChoiceInfo={answerChoiceInfo}
            handleAnswerChoiceContentChange={handleAnswerChoiceContentChange}
            handleAnswerChoiceInfoChange={handleAnswerChoiceInfoChange}
            handleDeleteAnswerChoice={handleDeleteAnswerChoice}
            targetIndex={index}
            editorState={editorState}
            editorStateUpdater={editorStateUpdater}
          />
        ))}
      </S.AnswerChoiceList>
    </S.AnswerChoiceContainer>
  );
}

// --- --- --- Styled components --- --- ---
const S = {
  AnswerChoiceContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  `,
  AnswerChoiceList: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
};