import { useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import RouteChangeTracker from './RouteChangeTracker';

import ChannelTalkProvider from 'components/_common/providers/ChannelTalkProvider';

// import './App.css';
import './styles/scss/pages/gate_member/gate_member.scss';
import './styles/scss/styles.scss';

import PrivacypolicyPage from 'components/doc/Privacypolicy';
import TermsofservicePage from 'components/doc/Termsofservice';
import ErrorPage from 'components/errors/Page';
import TutorRoute from 'routes/TutorRoute';
import AcademyRoute from './routes/AcademyRoute';
import CommonRoute from './routes/CommonRoute';
import DSATInfoRoute from './routes/DSATInfoRoute';
import ExamRoute from './routes/ExamRoute';
import MainRoute from './routes/MainRoute';
import ParentRoute from './routes/ParentRoute';
import SigninRoute from './routes/SigninRoute';
import SignupRoute from './routes/SignupRoute';
import StudentRoute from './routes/StudentRoute';
import TestgateNewsRoute from './routes/TestgateNewsRoute';
import TestRoute from './routes/TestRoute';

import ExternalSignin from 'components/user/external-signin/Page';
import ExternalSignout from 'components/user/external-signout/Page';
import ApCommonRoute from 'routes/ApCommonRoute';
import ApQuestionBankRoute from 'routes/ApQuestionBankRoute';
import ApStudentRoute from 'routes/ApStudentRoute';
import TubletRoute from 'routes/TubletRoute';
import ApTestRoute from './routes/ApTestRoute';

import { GlobalMiniAlert } from 'components/_common/alerts/GlobalMiniAlert';

// const ExamRoute = lazy(() => import('./routes/ExamRoute'))

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      document.title = 'Dev: DSAT';
    } else if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
      document.title = 'G+ SAT Staging';
    } else {
      document.title = 'G+ SAT';
    }
  }, []);

  useEffect(() => {
    const favicon = document.getElementById('favicon');

    /** 서버 환경에 따른 파비콘 이미지 경로 */
    const faviconPath = () => {
      if (!process.env.REACT_APP_ENVIRONMENT) {
        console.error('env 파일을 확인해 주세요');
        return null;
      }
      return `/favicon-${process.env.REACT_APP_ENVIRONMENT}.ico`;
    };

    if (favicon && faviconPath) {
      favicon.href = `${faviconPath()}`;
    }
  }, []);

  return (
    <Router>
      <RouteChangeTracker />
      <ChannelTalkProvider />
      <GlobalMiniAlert />
      <Routes>
        {/* <Route path="/" element={ <Navigate to='/main' /> } /> */}
        <Route path='/' element={<Navigate to='/signin' />} />,
        <Route path='/signup/*' element={<SignupRoute />} />
        <Route path='/signin/*' element={<SigninRoute />} />
        <Route path='/com/*' element={<CommonRoute />} />
        <Route path='/main/*' element={<MainRoute />} />
        <Route path='/academy/*' element={<AcademyRoute />} />
        <Route path='/parent/*' element={<ParentRoute />} />
        <Route path='/student/*' element={<StudentRoute />} /> {/* 학생용 */}
        <Route path='/tutor/*' element={<TutorRoute />} />
        <Route path='/test/*' element={<TestRoute />} /> {/* 시험관리 */}
        <Route path='/exam/*' element={<ExamRoute />} /> {/* 시험 모듈 */}
        <Route path='/dsat/info/*' element={<DSATInfoRoute />} /> {/* DSATInfo */}
        <Route path='/gateplus/*' element={<TestgateNewsRoute />} /> {/* testgateNews */}
        <Route path='*' element={<Navigate to='/signin' />} />
        <Route path='/external-signin' element={<ExternalSignin />} />
        <Route path='/external-signout' element={<ExternalSignout />} />
        <Route path='/404' element={<ErrorPage />} /> {/* 현재는 시험을 URL로 접근하려 했을때만 노출 보이게 설정 */}
        <Route path='/privacypolicy' element={<PrivacypolicyPage />} />
        <Route path='/termsofservice' element={<TermsofservicePage />} />
        {/* ap route */}
        <Route path='/ap/com/*' element={<ApCommonRoute />} />
        <Route path='/ap/academy/*' element={<AcademyRoute />} />
        <Route path='/ap/parent/*' element={<ParentRoute />} />
        <Route path='/ap/student/*' element={<ApStudentRoute />} /> {/* 학생용 */}
        <Route path='/ap/tutor/*' element={<TutorRoute />} />
        <Route path='/ap/exam/*' element={<ApTestRoute />} /> {/* 시험 모듈 */}
        <Route path='/ap/dsat/info/*' element={<DSATInfoRoute />} /> {/* DSATInfo */}
        <Route path='/ap/gateplus/*' element={<TestgateNewsRoute />} /> {/* testgateNews */}
        <Route path='/ap/question-bank/*' element={<ApQuestionBankRoute />} /> {/* Ap Question Bank */}
        {/* tubulet */}
        <Route path='/tublet/*' element={<TubletRoute />} />
      </Routes>
    </Router>
  );
}

export default App;
