import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconSearch } from 'assets/img/svg/icon_search.svg';
import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import useScrollLock from 'hooks/useScrollLock';
import usePressESC from 'hooks/usePressESC';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS } from 'utils/constants';
import ClassListDropboxItem from './_components/ClassListDropboxItem';

/** 학생 목록 선택 모달 */
export default function StudentListModal({ setShowModal, data, modalStateHandler }) {
  usePressESC(() => setShowModal(false));
  useScrollLock();

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  /** Query Parameters hook */
  const { navigateWithParams } = useObfuscatedSearchParam();
  const [searchField, setSearchField] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  const handleSearch = (e) => {
    e.preventDefault();
    modalStateHandler({
      searchField,
      searchKeyword,
    });
  };

  const handleSearchTypeChange = (e) => {
    setSearchField(e.target.value);
  };

  const handleSearchKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  /** 학생 정보 클릭시 해당 학생seq로 주소 이동 */
  const handleSelectStudent = (studentSeq) => {
    setShowModal(false);
    // 명시적으로 student 선택시 기존 CSSS 변경 및 기존 currentScore 파라미터 유지
    navigateWithParams(`/com/score/student/${obfuscateUrlParam(studentSeq)}`, {
      keepParams: [SCORE_PARAMS.CUR_SCORE_TEST_SEQ, SCORE_PARAMS.CUR_SCORE_CLASS_SEQ],
      newParams: { [SCORE_PARAMS.CUR_SCORE_STUDENT_SEQ]: studentSeq },
      replace: true,
    });
  };

  return (
    <SimpleModalBase onEscape={() => setShowModal(false)} w='45rem' h='29.25rem' p='1.25rem' gap='.75rem'>
      <S.Wrap>
        <div className='modal_title_container'>
          <S.ModalTitle>Student List</S.ModalTitle>
          <button onClick={() => setShowModal(false)}>
            <i className='svg_icon btn_pop_close black'>&nbsp;</i>
          </button>
        </div>

        <S.Content>
          <form onSubmit={handleSearch} className='search_area'>
            <select value={searchField} onChange={handleSearchTypeChange}>
              <option value=''>All</option>
              <option value='user_name'>Name</option>
              <option value='class_name'>Class</option>
              <option value='user_email'>Email</option>
            </select>
            <div className='search_bar'>
              <input type='text' placeholder='Enter Keywords' value={searchKeyword} onChange={handleSearchKeywordChange} />
              <button type='submit' title='Search'>
                <ImgSvgIconSearch />
              </button>
            </div>
          </form>
          <S.TableContainer className='scroll'>
            <table className='list'>
              <thead>
                <tr>
                  <th className='col_01'>Name</th>
                  <th className='col_02'>Class</th>
                  <th className='col_03'>Email</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          handleSelectStudent(item.userSeq);
                        }}>
                        <td>{item.userName}</td>

                        {item.classList.length > 1 ? (
                          <ClassListDropboxItem classList={item.classList} />
                        ) : (
                          <td>
                            <span className='class_name'>{item.classList[0]?.className ?? 'Individual'}</span>
                          </td>
                        )}

                        <td>{item.userEmail}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </S.TableContainer>
        </S.Content>
      </S.Wrap>
    </SimpleModalBase>
  );
}

const S = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    .modal_title_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;
    }
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;

    .search_area {
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;
      padding-bottom: 0.5rem;
      select {
        width: 5.75rem;
        border: 1px solid #f1f1f5;
        padding: 0.625rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 500;
        height: 1.25rem;
        box-sizing: content-box;
        border-radius: 0.5rem;
      }
      .search_bar {
        width: 14.125rem;
        border: 1px solid #f1f1f5;
        padding: 0 0.75rem;
        display: flex;
        border-radius: 0.5rem;
        input {
          border: none;
          padding: 0.625rem 0;
          height: 1.25rem;
          box-sizing: content-box;
          font-size: 0.875rem;
          font-weight: 400;
          flex: 1;
        }
        button {
          svg {
            width: 1.125rem;
            color: #505050;
          }
        }
      }
    }
  `,

  TableContainer: styled.div`
    flex: 1;
    overflow-y: auto;
    min-height: 0;

    table {
      border: 1px solid #f4f4fc;
      border-radius: 0.625rem;
      width: 100%;
      border-collapse: separate; // table header에 sticky 적용시 border 투명화 현상 방지
      border-spacing: 0; // table header에 sticky 적용시 border 투명화 현상 방지
      tr {
        &:hover {
          td {
            background-color: #f5f8ff;
          }
        }
      }
      th,
      td {
        &.col_01 {
          width: 9.75rem;
        }
        &.col_02 {
          width: auto;
        }
        &.col_03 {
          width: 15.875rem;
        }
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        height: 1.25rem;
        text-align: left;
      }
      th {
        background-color: #f8f8f9;
        color: #111111;
        padding: 0.625rem 0.75rem;
        position: sticky;
        top: 0;
        z-index: 1;
      }

      td {
        padding: 0.875rem 0.75rem;
        cursor: pointer;

        & + td {
          border-top: 1px solid #f4f4fc;
        }
        .dropdown_item {
          display: flex;
          justify-content: space-between;
          position: relative;
          svg {
            width: 1rem;
          }
          .scroll {
            position: absolute;
            right: 0;
            top: 2rem;
          }
          .class_name {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 0.875rem;
            svg {
              width: 1.25rem;
              transform: rotate(0);
              transition: 0.2s;
              &.active {
                transform: rotate(180deg);
              }
            }
          }
          ul {
            width: 100%;
            max-height: 8.75rem;
            position: absolute;
            top: 2.75rem;
            left: 0;
            padding: 0.625rem 0.75rem;
            border: 1px solid #f1f1f5;
            border-radius: 0.5rem;
            background-color: #ffffff;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
            display: none;
            z-index: 1;
            &::-webkit-scrollbar {
              width: 2px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #2f3542;
            }
            &.active {
              display: block;
            }
            li {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0.625rem;
              font-weight: 400;
              font-size: 0.875rem;
              color: #4b5563;
              & + li {
                border-top: 1px solid #f1f1f5;
              }
            }
          }
        }
      }
    }
  `,

  ModalTitle: styled.h2`
    font-size: 1.25rem;
  `,
};
