import React from 'react';
import styled from 'styled-components';

/** tests/scheduled 페이지의 상단 메인 Tab 컴포넌트 */
export default function ContentTopTabs({ tabData, tabState, setTabState }) {
  return (
    <S.TabWrap>
      {tabData.map((item, idx) => {
        return (
          <S.TabMenu key={idx} className={`${item.value === tabState ? 'active' : ''}`} onClick={() => setTabState(item.value)}>
            {item.text}
          </S.TabMenu>
        );
      })}
    </S.TabWrap>
  );
}

const S = {
  TabWrap: styled.div`
    display: flex;
    gap: 0.125rem;
  `,

  TabMenu: styled.button`
    height: 3.375rem;
    background: #f1f1f5;
    color: #999999;
    flex: 1;
    border-radius: 0.625rem 0.625rem 0 0;
    border: 1px solid #f1f1f5;
    font-size: 1.25rem;
    font-weight: 600;

    &.active {
      background: #fff;
      border-bottom: 1px solid #f1f1f5;
      color: #0068bd;
    }
  `,
};
