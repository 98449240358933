import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as ImageSvgLogoGoogle } from 'assets/img/svg/logo/logo-symbol-google-01.svg';
import { ReactComponent as ImageSvgLogoFacebook } from 'assets/img/svg/logo/logo-symbol-facebook-01.svg';
import { ReactComponent as ImageSvgLogoApple } from 'assets/img/svg/logo/logo-symbol-apple-01.svg';
import { ReactComponent as ImageSvgIconMail } from 'assets/img/svg/icon-mail.svg';

import Header from '../_components/layouts/_Header';
import MainBox from '../_components/layouts/MainBox';

/** 회원 가입 수단 선택 */
export default function SignUpMethodTypePage() {
  const navigate = useNavigate();

  /** 소셜 로그인 버튼 핸들러 : 구글 */
  const handleSnsLoginGoogle = () => {
    console.log('구글 로그인');
    // Google's OAuth 2.0 endpoint for requesting an access token
    var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {
      client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid email profile',
      include_granted_scopes: 'true',
      state: 'pass-through value',
    };

    // Add form parameters as hidden input values.
    for (var p in params) {
      var input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  };
  /** 소셜 로그인 버튼 핸들러 : 페이스북 */
  const handleSnsLoginFacebook = () => {
    console.log('페이스북 로그인');
  };
  /** 소셜 로그인 버튼 핸들러 : 애플 */
  const handleSnsLoginApple = () => {
    console.log('애플 로그인');
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <S.SignInForm>
      <MainBox>
        <Header>
          <p className='txt'>The door to educational aspiration</p>
        </Header>

        <section className='button-area'>
          <h1 className='title'>Sign Up</h1>
          <button title='페이지 이동' onClick={handleSnsLoginGoogle}>
            <ImageSvgLogoGoogle />
            <div className='txt-wrap'>Continue with Google</div>
          </button>
          {/* <button title='페이지 이동' onClick={handleSnsLoginFacebook}>
            <ImageSvgLogoFacebook />
            <div className='txt-wrap'>Continue with Facebook</div>
          </button> */}
          {/* <button title='페이지 이동' onClick={handleSnsLoginApple}>
            <ImageSvgLogoApple />
            <div className='txt-wrap'>Continue with Apple</div>
          </button> */}
          <Link to={'email/user/type'}>
            <ImageSvgIconMail style={{ color: '#000000' }} />
            <div className='txt-wrap'>Sign up with Email</div>
          </Link>
        </section>
      </MainBox>
    </S.SignInForm>
  );
}

const S = {};

S.SignInForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .title {
    font-weight: 700;
    text-align: left;
  }
  .button-area {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    button,
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      border: 1px solid #ececec;
      width: 100%;
      height: 4rem;
      border-radius: 0.25rem;
      box-sizing: border-box;
      padding: 0.5rem;
      font-weight: 500;
      font-size: 1rem;
      color: #505050;
      transition: 0.5s;
      svg {
        border-radius: 100%;
        width: 1.75rem;
        height: 1.75rem;
      }
      .txt-wrap {
        text-align: center;
      }
      &:hover {
        background-color: #e7ebff;
        border-color: #4758ff;
        color: var(--point-color);
        box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
        transform: scale(1.01);
      }
    }
  }

  @media screen and (max-width: 640px) {
    .title {
      text-align: center;
    }
    .button-area {
      button,
      a {
        font-size: 0.875rem;
      }
    }
  }
`;
