import React from 'react';
import styled from 'styled-components';
import SectionArea from './_components/SectionArea';
import PartArea from './_components/PartArea';

/** ap/com/score/grading/submission 페이지의 "Raw 점수 영역" 컴포넌트 */
export default function RawScoreArea() {
  return (
    <S.Wrap>
      <SectionArea
        dropdownChildren={
          <>
            <PartArea />
            <PartArea />
          </>
        }
      />
      <SectionArea />
      <SectionArea isGatePlus={false} />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
};
