import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { calculatePercentage } from 'utils/Common.utils';
import Box from '../../common/Box';

import ChartContainer from './_components/ChartContainer';
import request from 'utils/Request.utils';
import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS } from 'utils/constants';

/**
 * OverallAverageScore에서 정오답 정보를 보여주는 컴포넌트
 * @description
 * 🔍 검색 키워드 - #Calendar #com/score/student/:studentSeq
 */
export default function OverallAverageScore() {
  /** 현재 studentSeq - Route Parameter */
  const { studentSeq: encStudentSeq } = useParams();
  const studentSeq = deobfuscateUrlParam(encStudentSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurUthSeq = getObfuscatedValue(SCORE_PARAMS.CUR_UTH);
  const queryCurTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_TEST);

  /** scorePercentile의 초기화 데이터 */
  const scorePercentileInitData = {
    userSeq: 0, // number         => 유저 시퀀스
    mathScore: 0, // number       => 수학 점수
    rwScore: 0, // number         => 영어 점수
    mathPercentile: 0, // number  => 수학 백분위
    rwPercentile: 0, // number    => 영어 백분위
  };
  /** 시험 선택시 - 선택된 시험에 대한 해당 유저의 평균, 미 선택시 - 다른 유저들의 평균 */
  const [scorePercentile, setScorePercentile] = useState(scorePercentileInitData);

  /** scoreCorrectRate의 초기화 데이터 */
  const scoreCorrectRateInitData = [
    {
      subject: '', // "E" | "M"  => 시험 과목 ( 영어("E") | 수학("M") )
      correctRate: 0, // number  => 정답률
      omittedRate: 0, // number  => 안푼 문제 비율
      wrongRate: 0, // number    => 틀린 문제 비율
    },
  ];
  /** 시험 선택시 - 선택된 시험에 대한 해당 유저의 평균, 미 선택시 - 다른 유저들의 평균 */
  const [scoreCorrectRate, setScoreCorrectRate] = useState(scoreCorrectRateInitData);

  // 선택된 정답률 인덱스
  const [RWSelectedRateIndex, setRWSelectedRateIndex] = useState(0);
  const [mathSelectedRateIndex, setMathSelectedRateIndex] = useState(0);

  useEffect(() => {
    /** 선택된 학생의 TestList를 불러오는 함수 */
    const getTestScoreAverage = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentTestScorePercentile = response.result.studentTestScorePercentile[0];
          const fetchedStudentTestCorrectRate = response.result.studentTestCorrectRate;

          // 유효하다면 데이터 갱신 - 데이터가 없다면 (빈배열) 이라면 initData로 변경
          if (fetchedStudentTestScorePercentile) setScorePercentile(fetchedStudentTestScorePercentile);
          else setScorePercentile(scorePercentileInitData);

          // 유효하다면 데이터 갱신 - 데이터가 없다면 (빈배열) 이라면 initData로 변경
          if (fetchedStudentTestCorrectRate) setScoreCorrectRate(fetchedStudentTestCorrectRate);
          else setScoreCorrectRate(scoreCorrectRateInitData);
        }
      };

      // 선택된 시험이 있는지에 따라 다른 API 호출 ( 개별 시험 점수 / 전체 시험 점수 )
      const apiURL = isSelectedTest
        ? `/api/v2/score/student/average-score?userSeq=${studentSeq}&uthSeq=${queryCurUthSeq}`
        : `/api/v2/score/student/average-score?userSeq=${studentSeq}`;

      request.get(apiURL, null, successHandler).catch((error) => console.error('', error).finally(console.log('데이터 호출 완료')));
    };

    // 학생의 정보가 nullish하지 않고 0보다 클때만 호출
    if (Number(studentSeq ?? 0) > 0) getTestScoreAverage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentSeq, queryCurUthSeq]);

  /** scoreRate가 없을때 에러가 나오지 않게 해주는 초기화 객체 */
  const initScoreCorrectRate = { correctRate: 0, omittedRate: 0, subject: '', wrongRate: 0 };
  // 영어, 수학 정답률
  const RWCorrectRate = scoreCorrectRate.find((item) => item.subject === 'E') ?? initScoreCorrectRate;
  const mathCorrectRate = scoreCorrectRate.find((item) => item.subject === 'M') ?? initScoreCorrectRate;

  // 선택된 시험이 있는지 확인
  const isSelectedTest = queryCurTestSeq !== null;

  return (
    <S.Wrap>
      <Box className='main_box'>
        <h2>{isSelectedTest ? 'Score' : 'Overall Average Score'}</h2>
        <S.ContentContainer>
          <ChartContainer
            subject='RW'
            fillColor='#008CFF'
            chartData={{
              // 단일 값 차트 ( radialBar 차트 )
              chart01: {
                score: Math.round(scorePercentile.rwScore),
                totalScore: 800,
                label: `Percentile ${Math.min(scorePercentile.rwPercentile, 99)}%`,
              },
              // 다중 값 차트 ( donut 차트 )
              chart02: {
                data: [
                  { value: RWCorrectRate.correctRate, color: '#008cff', label: 'correct' },
                  { value: RWCorrectRate.wrongRate, color: '#929292', label: 'incorrect' },
                  { value: RWCorrectRate.omittedRate, color: '#fafaf8', label: 'omit' },
                ],
                label: ['correct', 'incorrect', 'omit'][RWSelectedRateIndex],
                handleClickData: (index) => setRWSelectedRateIndex(index),
              },
            }}
            stats={[
              { iconClass: 'rw', label: 'Total Correct', value: `${RWCorrectRate.correctRate}%` },
              { iconClass: 'incorrect', label: 'Total Incorrect', value: `${RWCorrectRate.wrongRate}%` },
              { iconClass: '', label: 'Total Omitted', value: `${RWCorrectRate.omittedRate}%` },
            ]}
          />
          <hr />
          <ChartContainer
            subject='MA'
            fillColor='#1fdf9f'
            chartData={{
              // 단일 값 차트 ( radialBar 차트 )
              chart01: {
                score: scorePercentile.mathScore,
                totalScore: 800,
                label: `Percentile ${Math.min(scorePercentile.mathPercentile, 99)}%`,
              },
              // 다중 값 차트 ( donut 차트 )
              chart02: {
                data: [
                  { value: mathCorrectRate.correctRate, color: '#1fdf9f', label: 'correct' },
                  { value: mathCorrectRate.wrongRate, color: '#929292', label: 'incorrect' },
                  { value: mathCorrectRate.omittedRate, color: '#fafaf8', label: 'omit' },
                ],
                label: ['correct', 'incorrect', 'omit'][mathSelectedRateIndex],
                handleClickData: (index) => setMathSelectedRateIndex(index),
              },
            }}
            stats={[
              { iconClass: 'math', label: 'Total Correct', value: `${mathCorrectRate.correctRate}%` },
              { iconClass: 'incorrect', label: 'Total Incorrect', value: `${mathCorrectRate.wrongRate}%` },
              { iconClass: '', label: 'Total Omitted', value: `${mathCorrectRate.omittedRate}%` },
            ]}
          />
        </S.ContentContainer>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    flex: 5.7;
    .main_box {
      height: 100%;
      padding: 1.25rem 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      > h2 {
        font-weight: 600;
        font-size: 1.25rem;
      }

      .answer_data_container {
        display: flex;
        gap: 1.5rem;
      }
    }
  `,
  ContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    hr {
      background-color: #f1f1f5;
      height: 1px;
      border: 0;
    }
  `,
};
