import React, { useEffect } from 'react';
import styled from 'styled-components';

/** 토스트 메시지 컴포넌트 */
export default function ToastMessage({ isShowSuccessSubmit = false, setShowThisComponent, children }) {
  useEffect(() => {
    setTimeout(() => {
      // 일정 시간만 노출 후 해당 컴포넌트 렌더링 하지 않기
      setShowThisComponent(false);
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isShowSuccessSubmit ? (
    <S.Wrap>
      <div className='message_box'>{children}</div>
    </S.Wrap>
  ) : null;
}

const S = {
  Wrap: styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .message_box {
      color: #fff;
      text-align: center;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 0.5rem;
      background: #000;
      padding: 0.25rem 0.75rem;
    }
  `,
};
