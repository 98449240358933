import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import ReactQuillEditor from 'components/_common/test-editor/quilljs/ReactQuillEditor';
import SimpleEditorBtn from 'components/ap/com/ap-components/_components/SimpleEditorBtn';
import AnswerChoiceSettingPopup from 'components/ap/com/ap-components/_components/SourceSettingPopup/AnswerChoiceSettingPopup';
import CustomCheckbox from 'components/_common/CustomCheckbox';

import useOutsideClick from 'hooks/useOutsideClick';
import { EDITOR_CONSTANT } from '../_constant';

//////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const ANSWER_DATA = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
};
////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/**
 * answer choice 를 편집할때 쓰는 컴포넌트
 * @param {Object} answerChoiceInfo = {
 *  id: 1, // number                     => 답안 선택 아이디
 *  choiceType: 'Text Input', // string  => 답안 선택 유형
 *  content: '' // string                => 답안 내용
 * }
 */
export default function AnswerChoiceItem({
  answerChoice,
  answerChoiceInfo,
  handleAnswerChoiceContentChange,
  handleAnswerChoiceInfoChange,
  handleDeleteAnswerChoice,

  targetIndex,
  editorState,
  editorStateUpdater,
}) {
  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [editorContentState, setEditorContentState] = useState(answerChoice.content);
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  const popupWrap = useRef(null);
  useOutsideClick(popupWrap, () => setIsShowPopup(false));
  ////////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const handleEditorClick = () => setIsShowPopup(true);

  /** 문제 객관식 보기 입력창 (ReactQuillEditor) 핸들러 */
  const handleReactQuillEditor = (event) => {
    setEditorContentState(event);
  };

  /** 정답 데이터 업데이트 핸들러 */
  const handleUpdateCorrectAnswer = (targetIndex, value) => {
    const newAnswer = [...editorState[EDITOR_CONSTANT.currentQuestion].answer.filter((item) => item !== null)];

    // value가 true 일 경우 정답 추가, false 일 경우 정답 제거
    if (value) newAnswer.push(`option${targetIndex + 1}`);
    else newAnswer.splice(newAnswer.indexOf(`option${targetIndex + 1}`), 1);

    editorStateUpdater(EDITOR_CONSTANT.currentQuestion, { ...editorState[EDITOR_CONSTANT.currentQuestion], answer: newAnswer });
  };

  /** 정답 체크 여부 확인 */
  const getIsCorrectAnswer = (targetIndex) => {
    return editorState[EDITOR_CONSTANT.currentQuestion].answer.includes(`option${targetIndex + 1}`);
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  // 상위에서 state 변경시 해당 컴포넌트의 state 도 변경
  useEffect(() => {
    setEditorContentState(answerChoice.content);
  }, [answerChoice.content]);

  useEffect(() => {
    handleAnswerChoiceContentChange(targetIndex, editorContentState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContentState]);

  return (
    <S.AnswerChoiceItem>
      <div className='answer_title_container'>
        <p className='answer_title'>{ANSWER_DATA[targetIndex + 1]}</p>

        <div className='correct_answer_checkbox_container'>
          <CustomCheckbox
            size='1.5rem'
            checkBoxId={`answerChoice_checkbox_${targetIndex}`}
            isChecked={getIsCorrectAnswer(targetIndex)}
            onChange={(e) => handleUpdateCorrectAnswer(targetIndex, e.target.checked)}
          />
          <p>Correct Answer Check</p>
        </div>
      </div>
      <div className='answer_choice_editor_container'>
        <div className='editor_wrapper' onClick={handleEditorClick} ref={popupWrap}>
          <ReactQuillEditor className='quill_editor' editorContentState={editorContentState} handleChange={handleReactQuillEditor} />
          <AnswerChoiceSettingPopup
            isOpen={isShowPopup}
            pos='left'
            answerChoiceInfo={answerChoiceInfo}
            handleAnswerChoiceInfoChange={handleAnswerChoiceInfoChange}
          />
        </div>
        <div className='btn_container'>
          <SimpleEditorBtn className='normal' onClick={() => handleDeleteAnswerChoice(targetIndex)}>
            Delete
          </SimpleEditorBtn>
        </div>
      </div>
    </S.AnswerChoiceItem>
  );
}

// --- --- --- Styled components --- --- ---
const S = {
  AnswerChoiceItem: styled.li`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;

    .answer_title_container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.75rem;
      .answer_title {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
        border-radius: 6.25rem;
        border: 1px solid black;
        width: 1.75rem;
        height: 1.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .correct_answer_checkbox_container {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        p {
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }

    .answer_choice_editor_container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .editor_wrapper {
        position: relative;
      }

      // quill editor 커스텀 스타일 적용
      .quill_editor {
        position: relative;
        z-index: 100;

        .ql-editor {
          min-height: 7.25rem;
          max-height: 15rem;
        }
      }

      .btn_container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        gap: 0.75rem;
      }
    }
  `,
};
