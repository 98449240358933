import React from 'react';
import styled from 'styled-components';
import GradingHeader from './_components/layouts/header/GradingHeader';
import GradingFooter from './_components/layouts/GradingFooter';
import GradeSubmissionBody from './_components/layouts/gradeSubmissionBody/GradeSubmissionBody';

/** ap/com/score/grading/submission 페이지 */
export default function ApComScoreGradingSubmissionPage() {
  return (
    <S.Wrap>
      <GradingHeader />
      <GradeSubmissionBody />
      <GradingFooter />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background-color: #ffffff;
    min-height: 100vh;
  `,
};
