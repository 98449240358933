import { useState } from 'react';
import styled from 'styled-components';

import ExpendedItemList from './_components/ExpendedItemList';
import CustomCheckbox from 'components/_common/CustomCheckbox';
import ApSettingSelectBox from './_components/ApSettingSelectBox';
import ApSettingStepper from './_components/ApSettingStepper';

import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/round_triangle.svg';

export default function SourceSettingPopup({ isOpen }) {
  // source 데이터
  const [data, setData] = useState([
    {
      id: 1,
      title: 'Source 1',
      content: [
        {
          id: 1,
          title: 'Footnote',
          isActive: false,
        },
      ],
    },
    {
      id: 2,
      title: 'Source 2',
      content: [
        {
          id: 1,
          title: 'FOOT',
          isActive: false,
        },
        {
          id: 2,
          title: 'FOOT-2',
          isActive: false,
        },
        {
          id: 3,
          title: 'FOOT-3',
          isActive: false,
        },
      ],
    },
  ]);

  // source setting data
  const [sourceSettingData, setSourceSettingData] = useState({
    noOfSources: 6,
    sourceGroup: [12, 13],
    sourceNumbering: 'Number',
    sourceDirections: false,
  });
  /** source setting data 변경 핸들러 */
  const handleChangeSourceSettingData = (key, value) => {
    setSourceSettingData((prev) => {
      const newData = { ...prev };
      newData[key] = value;
      return newData;
    });
  };

  if (!isOpen) return null;

  return (
    <S.Container>
      <div className='popup_header'>
        <h2>Source Settings</h2>
      </div>

      <S.ContentWrapper>
        <div className='control_row'>
          <span className='label'>No. Of Sources</span>
          <ApSettingStepper
            number={sourceSettingData.noOfSources}
            onIncrease={() => {
              handleChangeSourceSettingData('noOfSources', sourceSettingData.noOfSources + 1);
            }}
            onDecrease={() => {
              handleChangeSourceSettingData('noOfSources', sourceSettingData.noOfSources - 1);
            }}
          />
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Source group</span>
          <div className='group_control'>
            <ApSettingStepper
              number={sourceSettingData.sourceGroup[0]}
              onIncrease={() => {
                handleChangeSourceSettingData('sourceGroup', [sourceSettingData.sourceGroup[0] + 1, sourceSettingData.sourceGroup[1]]);
              }}
              onDecrease={() => {
                handleChangeSourceSettingData('sourceGroup', [sourceSettingData.sourceGroup[0] - 1, sourceSettingData.sourceGroup[1]]);
              }}
            />
            <span className='separator'>~</span>
            <ApSettingStepper
              number={sourceSettingData.sourceGroup[1]}
              onIncrease={() => {
                handleChangeSourceSettingData('sourceGroup', [sourceSettingData.sourceGroup[0], sourceSettingData.sourceGroup[1] + 1]);
              }}
              onDecrease={() => {
                handleChangeSourceSettingData('sourceGroup', [sourceSettingData.sourceGroup[0], sourceSettingData.sourceGroup[1] - 1]);
              }}
            />
          </div>
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Source Numbering</span>
          <div className='select_control'>
            <ApSettingSelectBox
              options={[
                { label: 'Number', value: 'Number' },
                { label: 'Alphabet', value: 'Alphabet' },
                { label: 'Roman numberal', value: 'Roman numberal' },
              ]}
              onSelect={(value) => handleChangeSourceSettingData('sourceNumbering', value)}
            />
          </div>
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Source Directions</span>
          <CustomCheckbox
            size='1.25rem'
            checkBoxId={`sourceDirections_checkbox`}
            isChecked={sourceSettingData.sourceDirections}
            onChange={() => handleChangeSourceSettingData('sourceDirections', !sourceSettingData.sourceDirections)}
          />
        </div>

        <S.SectionDivider />

        <ul>
          {data.map((source, headerIndex) => (
            <ExpendedItemList key={source.id} data={source} headerIndex={headerIndex} setData={setData} />
          ))}
        </ul>
      </S.ContentWrapper>
      <ArrowIconSelect className='triangle_icon' />
    </S.Container>
  );
}

const S = {
  Container: styled.div`
    position: absolute;
    left: 116%;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 24px;
    width: 430px;

    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.24));

    .popup_header {
      width: 100%;
      h2 {
        color: #111111;
        font-family: 'Roboto-SemiBold', Helvetica;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin: 0;
      }
    }

    .triangle_icon {
      position: absolute;
      left: -3%;
      top: 50%;
      transform: translateY(-50%);
      color: #ffffff;
    }
  `,

  ContentWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;

    .divider {
      height: 1px;
      background-color: #f1f1f5;
      width: 100%;
    }

    .control_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .label {
        color: #111111;
        font-family: 'Roboto-Regular', Helvetica;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .group_control {
      display: flex;
      align-items: center;
      gap: 8px;

      .separator {
        color: #111111;
        font-size: 12px;
        line-height: 18px;
      }
    }
  `,

  SectionDivider: styled.div`
    height: 2px;
    background-color: #505050;
    width: 100%;
  `,
};
