import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { nvl } from 'utils/Common.utils';

import ChangeClassModal from '../../modals/ChangeClassModal';
import SMiniTextBtn from 'components/_common/button/SMiniTextBtn';
import ScoreHistoryModal from '../../modals/ScoreHistoryModal';
import MiniAlert from 'components/_common/alerts/MiniAlert';

import { ReactComponent as ImgSvgIconFileSearch } from 'assets/img/svg/icon_file_search_02.svg';
import { ReactComponent as ImgSvgIconFileTime } from 'assets/img/svg/icon_file_time_02.svg';
import { ReactComponent as ImgSvgIconSwitchHorizontal } from 'assets/img/svg/icon_switch_horizontal_02.svg';
import { ReactComponent as ImgSvgIconSelect } from 'assets/img/svg/select.svg';
import StudentListModal from '../../modals/StudentListModal';
import ClassListButton from './_components/buttons/ClassListButton';
import ProfileModal from '../../modals/profileModal/ProfileModal';

const textLeftStyle = {
  textAlign: 'left',
};

/** student main - Table_MainContent */
export default function StudentsMainTable({ studentInfo, setSelectCheckBox, selectCheckBox, searchInfo, setSearchInfo, classList, getStudentList }) {
  /** Class 선택 layer */
  const [selectClass, setSelectClass] = useState(false);
  const [selectClassIdx, setSelectClassIdx] = useState(-1);
  /** "시험 목록" 모달 노출 여부 */
  const [isShowTestListModal, setIsShowTestListModal] = useState(false);
  /** 학생 history 모달 노출 여부 */
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  /** 학생 스코어 히스토리 */
  const [userSeq, setUserSeq] = useState(0);
  const [userName, setUserName] = useState(null);
  /** 학생 상세 프로필 팝업 */
  const [showProfilePopupState, setShowProfilePopupState] = useState(false);
  const [ProfileDetail, setProfileDetail] = useState(null);
  const [alert, alertSet] = useState({
    visible: false,
    alertMessage: '',
  });

  const handleAllCheck = (checked) => {
    if (checked) {
      const idArray = [];
      let updatedSeqStr = '';

      for (let i = 0; i < studentInfo.studentList.length; i++) {
        idArray.push(studentInfo.studentList[i].userSeq);

        updatedSeqStr = idArray.join('|');
      }

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: idArray,
        seqStr: updatedSeqStr,
      });
    } else {
      setSelectCheckBox({ seqStr: '', selectedKeys: [] });
    }
  };

  const studentListCheck = (event, userSeq) => {
    const { selectedKeys, seqStr } = selectCheckBox;
    const { checked } = event.target;

    if (checked) {
      const updatedSelectedKeys = [...selectedKeys, userSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== userSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  const gradeOrderToggle = () => {
    if (searchInfo.orderBy === 'desc') {
      setSearchInfo((prev) => {
        return { ...prev, orderBy: 'asc' };
      });
    } else if (searchInfo.orderBy === 'asc') {
      setSearchInfo((prev) => {
        return { ...prev, orderBy: 'desc' };
      });
    } else {
      setSearchInfo((prev) => {
        return { ...prev, orderBy: 'desc' };
      });
    }
  };

  /** tr (표의 행) 클릭 이벤트 */
  const trClickEvent = (userSeq) => {
    const { selectedKeys, seqStr } = selectCheckBox;

    if (!selectedKeys.includes(userSeq)) {
      const updatedSelectedKeys = [...selectedKeys, userSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== userSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  /** "View Profile" 버튼 핸들러 */
  const handleShowProfile = ({ event, item }) => {
    event.stopPropagation();
    let studentInfo = item;
    let imageUrl = null;
    if (studentInfo.profile.upFileSeq > 0) {
      imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}`;
    }
    studentInfo.profile.imageUrl = imageUrl;
    // studentInfo.parentMobile = JSON.parse(studentInfo.familyInfo)?.[0].mobile;
    setProfileDetail(studentInfo);
    setShowProfilePopupState(true);
  };

  /** 반 교체 */
  const classChange = ({ event, index }) => {
    event.stopPropagation();
    setSelectClass(!selectClass);
    setSelectClassIdx(index);
  };

  /** "Test List" 버튼 핸들러 */
  const handleTestListButton = ({ event, userSeq, userName }) => {
    event.stopPropagation();
    setUserSeq(userSeq);
    setUserName(userName);
    setIsShowTestListModal(true);
  };

  /** Score History 버튼 클릭 핸들러 */
  const handleClickScoreHistory = ({ event, userSeq, userName }) => {
    event.stopPropagation();
    setUserSeq(userSeq);
    setUserName(userName);

    setShowHistoryModal(true);
  };

  return (
    <>
      <S.Table>
        {/* 🧩 student main - Table_MainContent_테이블헤더 🧩 */}
        <thead className='list'>
          <tr>
            <th className='col_01'>
              <input
                type='checkbox'
                className='com_chk'
                id='chk01'
                onChange={(e) => handleAllCheck(e.target.checked)}
                checked={
                  nvl(studentInfo?.studentList) !== '' && selectCheckBox.selectedKeys.length === studentInfo.studentList.length && studentInfo.totalCount > 0
                    ? true
                    : false
                }
              />
              <label htmlFor='chk01'>&nbsp;</label>
            </th>
            <th className='col_02'>No.</th>
            <th className='col_03'>Name</th>
            <th className='col_04'>Profile</th>
            <th className='col_05'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${searchInfo.orderBy === 'desc' ? '' : 'on'}`}
                onClick={() => gradeOrderToggle()}>
                Grade <ImgSvgIconSelect />
              </button>
              {/*클릭 시 toggleClass active, 원위치 addClass default*/}
            </th>
            <th className='col_06'>School Name</th>
            <th className='col_07'>Class</th>
            <th className='col_08'>Test List</th>
            <th className='col_09'>Score History</th>
          </tr>
        </thead>
        {/* 🧩 student main - Table_MainContent_테이블메인 🧩 */}
        <tbody>
          {studentInfo.studentList == null || studentInfo.studentList?.length < 1 ? (
            <tr>
              <td colSpan='9'>You do not have any students… yet!</td>
            </tr>
          ) : (
            studentInfo.studentList.map((item, index) => {
              return (
                <tr key={`tr_${index}_${item.userSeq}`} onClick={() => trClickEvent(item.userSeq)}>
                  <td>
                    <input
                      type='checkbox'
                      className='com_chk'
                      id={`chk_${index}`}
                      onChange={(event) => {
                        studentListCheck(event, item.userSeq);
                      }}
                      checked={selectCheckBox.selectedKeys.includes(item.userSeq)}
                    />
                    <label htmlFor={`chk_${index}`} onClick={(event) => event.stopPropagation()}>
                      &nbsp;
                    </label>
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>{studentInfo.totalCount - studentInfo.numPerPage * (studentInfo.currentPage - 1) - index}</td>
                  <td style={{ ...textLeftStyle }}>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '4px' }}>
                      {item.readFlag === 'N' && <SMiniTextBtn text={'A'} />}
                      <p className='name'>{item.userName}</p>
                    </div>
                  </td>
                  <td>
                    <button type='button' title='Open a Modal' onClick={(event) => handleShowProfile({ event, item })} className='btn_view'>
                      View
                    </button>
                  </td>
                  <td>{item.studentGrade}</td>
                  <td style={textLeftStyle}>{item.studentSchool}</td>
                  <td style={{ ...textLeftStyle }} className='class'>
                    <div className='table_data_inner_box'>
                      <div>
                        {item.classList.className || 'Individual'}
                        {item.profile.classLists.length > 1 && <ClassListButton classLists={item.profile.classLists} />}
                      </div>
                      <button type='button' title='Change class' onClick={(event) => classChange({ event, index })} className='btn_change_class'>
                        <ImgSvgIconSwitchHorizontal />
                      </button>
                      {/* Change class 모달 */}
                      {index === selectClassIdx && (
                        <ChangeClassModal
                          studentInfo={{ userSeq: item.userSeq, userName: item.userName }}
                          studentClassList={item.classList}
                          companyClassList={classList}
                          selectClass={selectClass}
                          setSelectClass={setSelectClass}
                          alertSet={alertSet}
                          getStudentList={getStudentList}
                        />
                      )}
                    </div>
                  </td>
                  <td className='table_data_btn_area'>
                    <button
                      type='button'
                      title='Open a Modal Test List'
                      onClick={(event) => {
                        handleTestListButton({ event, userSeq: item.userSeq, userName: item.userName });
                      }}>
                      <ImgSvgIconFileSearch />
                    </button>
                  </td>
                  <td className='table_data_btn_area'>
                    <button
                      type='button'
                      title='Open a Modal Score History'
                      onClick={(event) => {
                        handleClickScoreHistory({ event, userSeq: item.userSeq, userName: item.userName });
                      }}>
                      <ImgSvgIconFileTime />
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </S.Table>

      {isShowTestListModal && <StudentListModal setShowModal={setIsShowTestListModal} userSeq={userSeq} userName={userName} />}

      {/* 🪟 student main - 학생 스코어 히스토리 모달 🪟 */}
      {showHistoryModal && <ScoreHistoryModal setShowModal={setShowHistoryModal} userSeq={userSeq} userName={userName} />}

      {/* 학생 상세 프로필 팝업 */}
      {showProfilePopupState && <ProfileModal setProfileState={setShowProfilePopupState} studentInfo={ProfileDetail} />}

      {/* 학생 삭제 확인 Toast Alert */}
      {alert.visible && (
        <MiniAlert text={alert.alertMessage} active={alert.visible} inactive={() => alertSet({ visible: false, alertMessage: '' })} timeOut={2000} />
      )}
    </>
  );
}

const S = {
  Table: styled.table`
    tr {
      th,
      td {
        vertical-align: middle;
        padding: 0 0.65rem;
        &.col_01 {
          width: 2.5rem;
        }
        &.col_02 {
          width: 2.25rem;
        }
        &.col_03 {
          width: 10%;
        }
        &.col_04 {
          width: 4rem;
        }
        &.col_05 {
          width: 5rem;
        }
        &.col_06 {
          width: 20%;
        }
        &.col_07 {
          width: auto;
        }
        &.col_08 {
          width: 5rem;
        }
        &.col_09 {
          width: 7rem;
        }
      }
      th {
        background-color: #000;
        color: #fff;
        height: 2.5rem;
        text-align: center;
        font-weight: 700;
        font-size: 0.8125rem;
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          overflow: hidden;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          overflow: hidden;
        }
        &.col_08,
        &.col_09 {
          border-left: 1px solid #ffffff;
        }

        .btn_sort {
          color: #ffffff;
          font-weight: 700;
          font-size: 0.8125rem;
          svg {
            width: 0.75rem;
            transition: 0.2s;
            transform-origin: center;
          }
          &.on {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
      td {
        height: 3rem;
        text-align: center;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        border-bottom: 1px solid #ebebeb;

        &.col_03,
        &.col_06,
        &.col_07 {
          text-align: left;
        }
        .table_data_inner_box {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
        }

        & > button,
        .table_data_inner_box > button {
          width: 1.5rem;
          height: 1.5rem;
          transition: 0.2s;
          &:hover {
            filter: brightness(95%);
          }

          &.btn_view {
            width: auto;
            height: auto;
            background-color: #f2f2f2;
            color: #7f8998;
            font-weight: 400;
            font-size: 0.875rem;
            border-radius: 0.25rem;
            padding: 0.125rem 0.5rem;
          }

          &.btn_change_class {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #f2f2f2;
            border-radius: 0.25rem;
            svg {
              width: 1rem;
              color: #a6b3be;
            }
          }
        }

        &.table_data_btn_area {
          button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #e8f0ff;
            border-radius: 0.25rem;
            svg {
              width: 1.125rem;
              color: #0068bd;
            }
          }
        }
      }
      background-color: #fff;
      &:nth-child(even) {
        background-color: #fafafa;
      }
      &.active {
        background-color: #edf7ff;
      }
      &:has(.com_chk):hover {
        background-color: #f2f2f2;
        //color:#4B5563 ;
      }
    }
  `,
};
