import React from 'react';
import styled from 'styled-components';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

/** 아카데미 대시보드 - Students - Main Table - "class 목록" 모달 */
export default function ClassListModal({ classLists, setShowModal }) {
  return (
    <SimpleModalBase onEscape={() => setShowModal(false)} p='1rem' w='15rem' gap='0'>
      <S.Wrap>
        <div className='modal_title_container'>
          <h2>Class</h2>
          <button onClick={() => setShowModal(false)}>
            <i className='svg_icon btn_pop_close black'>&nbsp;</i>
          </button>
        </div>
        <div className='contents_area scroll'>
          {classLists && classLists.length > 0 ? (
            <ul>
              {classLists.map((item, index) => {
                return <li key={`item_${index}`}>{item.className}</li>;
              })}
            </ul>
          ) : (
            <>no data</>
          )}
        </div>
      </S.Wrap>
    </SimpleModalBase>
  );
}

const S = {
  Wrap: styled.div`
    .modal_title_container {
      padding-bottom: 1rem;
    }
    .contents_area {
      background-color: #fafafa;
      overflow-y: scroll;
      border-radius: 0.5rem;
      max-height: 8rem;
      li {
        padding: 1rem 0.5rem;
      }
    }
  `,
};
