import { useRef, useState } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';

const MODULE_TYPES = { MODULE1: '1', MODULE2: '2' };
const DIFFICULTY = { EASY: 'E', HARD: 'H' };

/** Header에서 모듈 선택 부분 layer + input */
export default function SelectModuleBox({ selectedSubjectModule, selectedModuleInfo, setSelectedModuleInfo }) {
  const [isShowSelectModuleLayer, setIsShowSelectModuleLayer] = useState(false);
  const moduleSelectBox = useRef(null);

  const selectedModuleNum = () => {
    if (!selectedModuleInfo?.moduleNum) return '-';

    if (selectedModuleInfo.moduleNum === MODULE_TYPES.MODULE1) return 'Module 1';
    if (selectedModuleInfo.moduleNum === MODULE_TYPES.MODULE2) {
      if (selectedModuleInfo.tmDifficulty === DIFFICULTY.EASY) return 'Module 2 Easy';
      if (selectedModuleInfo.tmDifficulty === DIFFICULTY.HARD) return 'Module 2 Hard';
      return 'Module 2';
    }
    return '-';
  };

  // Module 1 옵션 렌더링
  const renderModule1Option = (moduleInfo) => {
    /** 해당 모듈을 봤는지(시험을 봤는지) */
    const isTested = moduleInfo.isTested;
    const handleItemClick = (event) => {
      if (!isTested) {
        event.stopPropagation();
        return;
      }
      setSelectedModuleInfo(moduleInfo);
    };
    return (
      <p key={`module-1-${moduleInfo.testModuleSeq}`} className={`option ${isTested ? '' : 'disabled'}`} onClick={handleItemClick}>
        Module 1
      </p>
    );
  };

  // Module 2 옵션 렌더링 (Easy/Hard)
  const renderModule2Option = (moduleInfo, difficulty) => {
    const difficultyText = difficulty === DIFFICULTY.EASY ? 'Easy' : 'Hard';
    /** 해당 모듈을 봤는지(시험을 봤는지) */
    const isTested = moduleInfo.isTested;
    const handleItemClick = (event) => {
      if (!isTested) {
        event.stopPropagation();
        return;
      }
      setSelectedModuleInfo(moduleInfo);
    };

    return (
      <p key={`module-2-${difficulty}-${moduleInfo.testModuleSeq}`} className={`option ${isTested ? '' : 'disabled'}`} onClick={handleItemClick}>
        Module 2 {difficultyText}
      </p>
    );
  };

  // 모듈 옵션 목록 생성
  const getModuleOptions = () => {
    const sortedModules = [...selectedSubjectModule].sort((a, b) => {
      // 모듈 번호로 1차 정렬
      if (a.moduleNum !== b.moduleNum) {
        return parseInt(a.moduleNum) - parseInt(b.moduleNum);
      }
      // 난이도로 2차 정렬 (Easy가 먼저)
      return a.tmDifficulty === DIFFICULTY.EASY ? -1 : 1;
    });

    // 모듈 옵션 렌더링 ( null 제거 )
    return sortedModules
      .map((moduleInfo) => {
        if (moduleInfo.moduleNum === MODULE_TYPES.MODULE1) {
          return renderModule1Option(moduleInfo);
        }

        if (moduleInfo.moduleNum === MODULE_TYPES.MODULE2) {
          if (moduleInfo.tmDifficulty === DIFFICULTY.EASY || !moduleInfo.tmDifficulty) {
            return renderModule2Option(moduleInfo, DIFFICULTY.EASY);
          }
          if (moduleInfo.tmDifficulty === DIFFICULTY.HARD || !moduleInfo.tmDifficulty) {
            return renderModule2Option(moduleInfo, DIFFICULTY.HARD);
          }
        }

        return null;
      })
      .filter(Boolean); // null 제거
  };

  // --- --- ---

  useOutsideClick(moduleSelectBox, () => setIsShowSelectModuleLayer(false));

  return (
    <div
      className={`com_select_layer type_tit ${isShowSelectModuleLayer ? 'active' : ''}`}
      onClick={() => setIsShowSelectModuleLayer((prev) => !prev)}
      ref={moduleSelectBox}>
      <section className='selected__wrap'>
        <input type='text' className='selected' placeholder={selectedModuleNum()} size='15' readOnly />
      </section>
      <section className='layer'>{getModuleOptions()}</section>
    </div>
  );
}
