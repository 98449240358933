import { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import ReactQuill, { Quill } from 'react-quill-new';
import styled from 'styled-components';

import request from 'utils/Request.utils';

import 'highlight.js/styles/github.css';

import 'katex/dist/katex.min.css';
import 'mathquill/build/mathquill.css'; // MathQuill 스타일
import 'react-quill-new/dist/quill.snow.css'; // Quill 에디터 스타일

import { ReactComponent as ImgSvgIconDivideParagraph } from 'assets/img/svg/icon/icon_divide_paragraph.svg';

import { ImageDrop } from 'quill-image-drop-module';

/** 기본 글꼴 크기 설정 */
const DEFAULT_SIZE = '14px';
/** 기본 글꼴 설정 */
const DEFAULT_FONT = 'noto-serif';

// helper: data URL -> File 변환 함수
function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mimeMatch = arr[0].match(/:(.*?);/);
  if (!mimeMatch) return null;
  const mime = mimeMatch[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// --- CustomImageDrop 모듈 ---
// 드래그 앤 드롭 시 파일 또는 data URL 이미지가 있을 경우 customImageDropHandler를 호출
class CustomImageDrop extends ImageDrop {
  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();

    let file = null;

    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      file = e.dataTransfer.files[0];
    }

    if (file) {
      this.quill.options.modules.imageDrop.customImageDropHandler(file);
      return;
    }
  }
}

// 모듈 등록
Quill.register('modules/imageDrop', CustomImageDrop);

const userInfo = request.tokenDecoder();

// 1. Quill의 Size 스타일을 커스텀으로 등록
const Size = Quill.import('attributors/style/size');
Size.whitelist = ['14px', '10px', '12px', '16px', '18px', '24px', '32px', '48px']; // 원하는 크기 설정
Quill.register(Size, true);

// 1. Quill의 Font 스타일을 가져와서 "Courier New" 추가
const Font = Quill.import('formats/font');
Font.whitelist = ['noto-serif', 'sans-serif', 'monospace', 'courier-prime'];
Quill.register(Font, true);

// Quill 클립보드 매처 설정
const Delta = Quill.import('delta');

/** 각 인스턴스별로 공통으로 사용할 수 있는 설정 객체 */
const TOOLBAR_OPTIONS = {
  container: [
    [{ font: Font.whitelist }], // 폰트 설정
    [{ size: Size.whitelist }], // 폰트 크기 옵션 추가
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ script: 'super' }, { script: 'sub' }],
    ['code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    ['formula'],
    // ['clean'],
    // ['table'],
    ['image'],
    // ['image', 'link'],
    ['multiColumn'], // 👈 커스텀 버튼 추가
  ],
};

/** "Quill" 을 사용하여 구현한 텍스트 에디터 컴포넌트 */
export default function ReactQuillEditor({ editorContentState, handleChange, className, onBlur = null }) {
  const quillRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  /** "Quill" 텍스트 에디터 포커스 핸들러 */
  const handleFocus = (event) => {
    setIsFocus(true);
  };

  /** "Quill" 텍스트 에디터 블러 (포커스 해제) 핸들러 */
  const handleBlur = () => {
    setIsFocus(false);
    if (onBlur) {
      onBlur();
    }
  };

  // 커스텀 버튼 동작
  const handleMultiColumn = () => {
    const editor = quillRef.current?.getEditor().root; // Quill의 에디터 요소 가져오기
    if (editor) {
      let currentColumns = parseInt(getComputedStyle(editor).columnCount) || 1;
      let newColumns = currentColumns === 1 ? 2 : 1; // 1단 ↔ 2단 토글
      editor.style.columnCount = newColumns;
    }
  };

  // 파일 업로드 로직을 처리하는 함수
  const processFile = (file) => {
    if (!file) return;
    const formData = new FormData();
    formData.append('uploadFiles', file);

    const successHandler = (response) => {
      const imageUrl = response.result.uploadImagesUrl;
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true) || { index: quill.getLength() };

      // 에디터 내 data URL 이미지 제거 (있다면)
      const editorElem = quill.root;
      const dataUrlImg = editorElem.querySelector('img[src^="data:image"]');
      if (dataUrlImg) dataUrlImg.remove();

      quill.insertEmbed(range.index, 'image', imageUrl);
      quill.setSelection(range.index + 1);
    };

    request.postMultipart(`/api/common/upload/images/teacher/${userInfo.userSeq}`, formData, successHandler, (error) =>
      console.error(`파일 업로드 실패 : ${error}`)
    );
  };

  // 파일 인풋 onChange 핸들러
  const handleFileChange = (event) => {
    processFile(event.target.files?.[0]);
    event.target.value = '';
  };

  // modules 설정 (드롭 시 processFile 호출)
  const modules = useMemo(
    () => ({
      toolbar: {
        container: TOOLBAR_OPTIONS.container,
        handlers: {
          image: () => fileInputRef.current?.click(),
          multiColumn: handleMultiColumn, // 👈 핸들러 연결
        },
      },
      keyboard: {
        bindings: {
          formula: {
            key: 'F',
            shortKey: true,
            handler: () => {
              quillRef.current?.getEditor().format('formula', true);
            },
          },
          tab: {
            key: 9, // 탭 키 코드
            handler: function (range) {
              // 들여쓰기 스타일 적용
              this.quill.format('indent', '+1');
              return false; // 기본 동작 중단
            },
          },
          'indent tab': {
            key: 9,
            shiftKey: false,
            handler: function (range) {
              this.quill.format('indent', '+1');
              return false;
            },
          },
          'outdent tab': {
            key: 9,
            shiftKey: true,
            handler: function (range) {
              this.quill.format('indent', '-1');
              return false;
            },
          },
          superscript: {
            key: 'S'.charCodeAt(0),
            shiftKey: true,
            shortKey: true, // ctrl+shift+s (Mac: command+shift+s)
            handler: function (range, context) {
              this.quill.format('script', 'super');
            },
          },
          subscript: {
            key: 'B'.charCodeAt(0),
            shiftKey: true,
            shortKey: true, // ctrl+= (Mac: command+=)
            handler: function (range, context) {
              this.quill.format('script', 'sub');
            },
          },
        },
      },
      imageDrop: {
        customImageDropHandler: (file) => {
          processFile(file);
        },
      },
      // 콘텐츠를 에디터에 주입할때 동작 ( Quill 자체 파싱 비활성화 && 손상없이 주입 + 커스텀 폰트 적용 )
      clipboard: {
        matchVisual: false, // Quill 에디터 차체 변환 기능 비활성화
        matchers: [
          [
            'span', // 적용할 요소
            (node, delta) => {
              /** 커스텀 폰트 className 추출 */
              const fontClass = Array.from(node.classList).find((cls) => cls.startsWith('ql-font-'));
              if (fontClass) {
                /** className에서 폰트 문자열 추출 */
                const fontValue = fontClass.replace('ql-font-', '');
                // 추출 폰트가 폰트 목록에 포함되어 있는지 확인 - Delta 형식으로 변환
                if (Font.whitelist.includes(fontValue)) {
                  return new Delta([{ insert: node.textContent, attributes: { font: fontValue } }]);
                }
              }
              return delta; // 폰트 문자열이 없으면 기본 delta 반환
            },
          ],
        ],
      },
    }),
    []
  );

  // 지원하는 포맷 설정
  const formats = [
    'font',
    'size',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'indent',
    'align',
    'formula', // 수식 포맷 추가
    // 'table',
    'image',
    // 'link',
    'code-block',
    'script',
  ];
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const toolbars = document.querySelectorAll('.ql-toolbar'); // 👈 모든 툴바 가져오기
      toolbars.forEach((toolbar) => {
        /** 다단 버튼 */
        const multiColumnBtn = toolbar.querySelector('.ql-multiColumn');
        /** 수식 입력 버튼 */
        const formulaBtn = toolbar.querySelector('.ql-formula');
        if (multiColumnBtn && !multiColumnBtn.dataset.processed) {
          const root = ReactDOM.createRoot(multiColumnBtn);
          // 중복 적용 방지
          root.render(<ImgSvgIconDivideParagraph className='svg_icon_divide_paragraph' />);
          multiColumnBtn.setAttribute('title', 'Divide Paragraph');
          multiColumnBtn.dataset.processed = 'true'; // 이미 적용된 버튼 표시
        }
        if (formulaBtn && !formulaBtn.dataset.processed) {
          formulaBtn.setAttribute('title', 'Ctrl + m');
          formulaBtn.setAttribute('accesskey', 'm');
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // DOM이 준비된 후 에디터 렌더링
    // setIsEditorReady(true);
    setInterval(() => {
      setIsEditorReady(true);
    }, 500);

    return () => {
      observer.disconnect(); // 컴포넌트 언마운트 시 감지 중지}
    };
  }, []);

  useEffect(() => {
    if (isFocus) {
      const handleKeyDown = (event) => {
        if (!quillRef.current) return;
        const quill = quillRef.current.getEditor();

        if (event.key === 'Escape') {
          const selection = quill.getSelection();
          if (selection) {
            if (selection.length > 0) {
              // 선택된 영역이 있다면 해당 영역 전체 포맷 제거
              quill.removeFormat(selection.index, selection.length);
            } else {
              // 선택된 영역이 없으면 현재 커서 위치의 포맷만 제거
              const formats = quill.getFormat();
              Object.keys(formats).forEach((format) => {
                quill.format(format, false);
              });
            }
          }
        }

        if (event.ctrlKey || event.metaKey) {
          // ⌘ (Mac) 또는 Ctrl (Windows) 감지
          switch (event.key.toLowerCase()) {
            case 'f': // Ctrl + F
              event.preventDefault();
              const formulaButton = document.querySelector('.ql-formula');
              if (formulaButton) {
                formulaButton.click(); // 강제 클릭 실행
              }

              break;
            // case 'b': // Ctrl + B (Bold)
            //   event.preventDefault();
            //   quill.format('bold', true);
            //   break;
            // case 'i': // Ctrl + I (Italic)
            //   event.preventDefault();
            //   quill.format('italic', true);
            //   break;
            // case 'u': // Ctrl + U (Underline)
            //   event.preventDefault();
            //   quill.format('underline', true);
            //   break;
            // case 's': // Ctrl + S (Strike-through)
            //   event.preventDefault();
            //   quill.format('strike', true);
            //   break;
            // case 'd': // Ctrl + D (MultiColumn)
            //   event.preventDefault();
            //   handleMultiColumn();
            //   break;
            default:
              break;
          }
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isFocus]);

  // 맞춤법 검사 비활성화
  useEffect(() => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      editor.root.setAttribute('spellcheck', 'false');
      editor.format('size', DEFAULT_SIZE);
      editor.format('font', DEFAULT_FONT);
    }
  }, [isEditorReady]);

  // 복사 붙여 넣기 이벤트 핸들러
  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    if (!quill) return;
    const editorElem = quill.root;

    const handlePaste = (e) => {
      console.log('handlePaste 호출:', e);
      const clipboardData = e.clipboardData || window.clipboardData;
      if (clipboardData && clipboardData.items) {
        for (let i = 0; i < clipboardData.items.length; i++) {
          const item = clipboardData.items[i];
          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            if (file) {
              processFile(file);
              e.preventDefault();
            }
            break;
          }
        }
      }
    };

    editorElem.addEventListener('paste', handlePaste);
    return () => {
      editorElem.removeEventListener('paste', handlePaste);
    };
  }, [isEditorReady]);

  if (!isEditorReady) {
    return <></>;
  }
  return (
    <S.Wrap className={className}>
      {/* 파일 업로드용 input 요소 */}
      <input aria-hidden='true' type='file' ref={fileInputRef} style={{ display: 'none' }} accept='image/*' onChange={handleFileChange} />
      <ReactQuill
        ref={quillRef}
        theme='snow'
        modules={modules}
        formats={formats}
        value={editorContentState}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    .ql-container.ql-snow .ql-editor {
      font-size: 14px;
      font-family: 'Noto Serif';
    }

    .ql-font-noto-serif {
      font-family: 'Noto Serif' !important;
    }
    .ql-font-sans-serif {
      font-family: sans-serif !important;
    }
    .ql-font-monospace {
      font-family: monospace !important;
    }
    .ql-font-courier-prime,
    .ql-code-block-container {
      font-family: 'Courier Prime', monospace !important;
    }

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border-top: 1px solid #d9d9d9;
    }

    .ql-container {
      padding: 1rem;
    }

    .ql-multiColumn {
      display: inline-flex;
    }

    .svg_icon_divide_paragraph {
      width: 1.5rem;
      color: #111111;
      &:hover {
        color: #06c;
      }
    }

    .ql-active {
      .svg_icon_divide_paragraph {
        color: #06c;
      }
    }

    // 수식 입력 툴팁 위치 조정
    .ql-tooltip[data-mode='formula'] {
      transform: translateX(5rem);
    }

    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
      content: '10px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
      content: '12px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
      content: '14px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
      content: '16px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
      content: '18px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
      content: '24px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
      content: '32px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48px']::before {
      content: '48px';
    }

    /* 선택된 폰트 크기 표시 */
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before {
      content: '10px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before {
      content: '12px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before {
      content: '14px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before {
      content: '16px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before {
      content: '18px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before {
      content: '24px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before {
      content: '32px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='48px']::before {
      content: '48px';
    }

    // 폰트 선택기 아이템 표시
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='noto-serif']::before {
      content: 'Noto Serif';
      font-family: 'Noto Serif';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='sans-serif']::before {
      content: 'Sans Serif';
      font-family: sans-serif;
    }
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
      content: 'Monospace';
      font-family: monospace;
    }
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='courier-prime']::before {
      content: 'Courier Prime';
      font-family: 'Courier Prime';
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80px;
    }

    // 선택된 폰트 레이블 표시
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='noto-serif']::before {
      content: 'Noto Serif';
      font-family: 'Noto Serif';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='sans-serif']::before {
      content: 'Sans Serif';
      font-family: sans-serif;
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before {
      content: 'Monospace';
      font-family: monospace;
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='courier-prime']::before {
      content: 'Courier Prime';
      font-family: 'Courier Prime';
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80px;
    }
  `,
};
