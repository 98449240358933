import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils';

import MainLink from 'components/_common/button/_MainLinks';
import TopBtnArea from './_components/TopBtnArea';
import ApQuestionBankMainTable from './_components/QuestionBankMainTable';

/** AP / "Question Bank" 목록 페이지(루트 페이지) */
export default function ApQuestionBankRootPage() {
  //////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  ////////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  ////////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** API 에서 받아온 AP 시험 목록 */
  const [apTestListData, setApTestListData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  ////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  ////////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //////////////////// START --- API 호출 --- START ////////////////////
  /** "AP Test" 목록 얻어오기 API 요청 */
  const getApTestList = () => {
    const params = {
      isNew: true, // 새로운 API 서버인 node 서버로 요청을 보낼 것인가?
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        const apTestList = response.result;
        setApTestListData(apTestList);
      }
    };

    request.get(`/api/ap/question-bank/test`, params, successHandler).catch((error) => {
      console.error(error);
    });
  };
  /** checked 된 ap test delete */
  const deleteApTest = (apTests) => {
    const params = {
      isNew: true, // 새로운 API 서버인 node 서버로 요청을 보낼 것인가?
      apTests: apTests.join(','),
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        const apTestList = response.result;
        setApTestListData(apTestList);
      }
    };

    request.post(request.apiUris.ap.questionBank.deleteTest, params, successHandler).catch((error) => {
      console.error(error);
    });
  };
  ////////////////////  END  --- API 호출 ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const handleDeleteButton = () => {
    if (confirm('Are you sure you want to delete the selected test?')) {
      console.log('selectedData:', selectedData);
      deleteApTest(selectedData);
    }
    // alert('hello');
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    getApTestList();
  }, []);
  useEffect(() => {
    console.log('selectedData:', selectedData);
  }, [selectedData]);
  ////////////////////  END  --- React useEffect ---  END  ////////////////////

  //////////////////// START --- 랜더링 보조 --- START ////////////////////
  ////////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <S.Wrap>
      <div>
        <MainLink />
        <div className='com_center_wrap'>
          <div className='com_box'>
            <div className='com_list'>
              <section className='top_area'>
                <TopBtnArea handleDeleteButton={handleDeleteButton} />
              </section>

              {/* 📏 student main - Table_MainContent 📏 */}
              <ApQuestionBankMainTable listData={apTestListData} selectedData={selectedData} setSelectedData={setSelectedData} />
            </div>
          </div>
        </div>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.main``,
};
