import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ImgSvgIconArrow } from 'assets/img/svg/icon_arrow_02.svg';

export default function DropdownButton({ classList }) {
  /** 드롭다운 영역 노출 여부 */
  const [isShow, setIsShow] = useState(false);

  return (
    <S.Wrap>
      {classList.length > 1 ? (
        <>
          <button className='class_name' type='button' title='목록 펼치기' onClick={() => setIsShow((prev) => !prev)}>
            {classList[0].className} <ImgSvgIconArrow className={`${isShow ? 'on' : ''}`} />
          </button>
          <ul className={`${isShow ? 'on' : ''} scroll`}>
            {classList.map((item, index) => (
              <li key={`classListData-${item.className}-${index}`}>{item.className}</li>
            ))}
          </ul>
        </>
      ) : (
        <p className='class_name'>Individual</p>
      )}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    position: relative;
    .class_name {
      font-weight: 400;
      font-size: 0.875rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.625rem 0;
      width: 100%;
      svg {
        width: 1.25rem;
        transform: rotate(0);
        transition: 0.2s;
        &.on {
          transform: rotate(180deg);
        }
      }
    }
    ul {
      width: 100%;
      max-height: 8.75rem;
      position: absolute;
      top: 2.75rem;
      left: 0;
      padding: 0.625rem 0.75rem;
      border: 1px solid #f1f1f5;
      border-radius: 0.5rem;
      background-color: #ffffff;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
      display: none;
      z-index: 1;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #2f3542;
      }
      &.on {
        display: block;
      }
      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0.625rem;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        & + li {
          border-top: 1px solid #f1f1f5;
        }
      }
    }
  `,
};
