import React from 'react';
import styled from 'styled-components';

import { clearApTestEditor } from 'reducers/apTestEditor/action';

import { useDispatch } from 'react-redux';

import { ReactComponent as ImgSvgIconSelect } from 'assets/img/svg/select.svg';
import { ReactComponent as ImgSvgIconCheck } from 'assets/img/svg/icon_check_02.svg';

import dayjsTZ from 'utils/functions/time/dayjs-config';

/** AP "Question Bank" main - Table_MainContent */
export default function ApQuestionBankMainTable({ listData = [], selectedData, setSelectedData }) {
  const dispatch = useDispatch();

  const handleAllCheck = (checked) => {};

  const studentListCheck = (event, userSeq) => {
    event.stopPropagation(); // 전파 방지

    if (event.target.checked) {
      // 체크된 경우
      setSelectedData((prev) => [...prev, userSeq]);
    } else {
      // 체크 해제된 경우
      setSelectedData((prev) => prev.filter((key) => key !== userSeq));
    }
    console.log('Selected Keys:', selectedData);
  };
  const handleNameClick = (test) => {
    // release된 테스트는 수정 불가
    if (test.release_status === 'Completed') return;

    const selectedTestSetSeq = test.test_set_seq;
    // console.log('🚀 ~ handleNameClick ~ idx:', idx);
    // alert('handleNameClick' + idx);
    dispatch(clearApTestEditor()); // 로컬 스토리지 테스트 데이터 초기화
    window.open(`/ap/question-bank/test-editor?test_seq=${selectedTestSetSeq}`, '_blank');
  };
  const gradeOrderToggle = () => {};

  return (
    <>
      <S.Table>
        {/* 🧩 student main - Table_MainContent_테이블헤더 🧩 */}
        <thead className='list'>
          <tr>
            <th className='col_01'>
              <input
                type='checkbox'
                className='com_chk'
                id='chk01'
                onChange={(e) => handleAllCheck(e.target.checked)}
                checked={
                  false
                  // nvl(studentInfo?.studentList) !== '' && selectCheckBox.selectedKeys.length === studentInfo.studentList.length && studentInfo.totalCount > 0
                  //   ? true
                  //   : false
                }
              />
              <label htmlFor='chk01'>&nbsp;</label>
            </th>
            <th className='col_02'>No.</th>
            <th className='col_03'>
              <button style={{ padding: '0 2px', whiteSpace: 'nowrap' }} className={`btn_sort `} onClick={() => gradeOrderToggle()}>
                Release Status <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_04'>Subject</th>
            <th className='col_05'>Test</th>
            <th className='col_06'>
              <button style={{ padding: '0 2px', whiteSpace: 'nowrap' }} className={`btn_sort `} onClick={() => gradeOrderToggle()}>
                Created On <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_07'>
              <button style={{ padding: '0 2px', whiteSpace: 'nowrap' }} className={`btn_sort`} onClick={() => gradeOrderToggle()}>
                Last edited <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_08'>Created By</th>
          </tr>
        </thead>
        {/* 🧩 student main - Table_MainContent_테이블메인 🧩 */}
        <tbody>
          {listData === undefined || listData === null || listData?.length < 1 ? (
            <tr>
              <td colSpan='8'>You do not have any test… yet!</td>
            </tr>
          ) : (
            listData.map((item, index) => {
              return (
                <tr
                  style={{ cursor: item.release_status === 'Completed' ? 'default' : 'pointer' }}
                  key={`tr_${index}_${item.test_set_seq}`}
                  onDoubleClick={() => handleNameClick(item)}>
                  <td>
                    <input
                      type='checkbox'
                      className='com_chk'
                      id={`chk_${index}`}
                      onChange={(event) => {
                        studentListCheck(event, item.test_set_seq);
                      }}
                      // checked={
                      //   false
                      //   // selectCheckBox.selectedKeys.includes(item.userSeq)
                      // }
                    />
                    <label htmlFor={`chk_${index}`} onClick={(event) => event.stopPropagation()}>
                      &nbsp;
                    </label>
                  </td>
                  <td>{listData.length - index}</td>
                  <td>
                    {item.release_status === 'Completed' ? (
                      <i className='icon_check_circle'>
                        <ImgSvgIconCheck />
                      </i>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>{item.subject}</td>
                  <td className='alien_left'>{item.test_set_name}</td>
                  <td>{dayjsTZ(item.created).format('MM / DD / YYYY')}</td>
                  <td>{item.las_edited ? dayjsTZ(item.las_edited).format('MM / DD / YYYY') : '-'}</td>
                  <td className='alien_left'>{item.created_by}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </S.Table>
    </>
  );
}

const S = {
  Table: styled.table`
    tr {
      background-color: #fff;
      &:nth-child(even) {
        background-color: #fafafa;
      }
      &.active {
        background-color: #edf7ff;
      }
      &:has(.com_chk):hover {
        background-color: #f2f2f2;
        //color:#4B5563 ;
      }

      th,
      td {
        vertical-align: middle;
        padding: 0 0.65rem;
        &.col_01 {
          width: 2.5rem;
        }
        &.col_02 {
          width: 2.25rem;
        }
        &.col_03 {
          width: 9rem;
        }
        &.col_04 {
          width: 11rem;
        }
        &.col_05 {
          width: auto;
        }
        &.col_06 {
          width: 8rem;
        }
        &.col_07 {
          width: 8rem;
        }
        &.col_08 {
          width: 11rem;
        }
      }
      th {
        background-color: #000;
        color: #fff;
        height: 2.5rem;
        text-align: center;
        font-weight: 700;
        font-size: 0.8125rem;
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          overflow: hidden;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          overflow: hidden;
        }

        .btn_sort {
          color: #ffffff;
          font-weight: 700;
          font-size: 0.8125rem;
          svg {
            width: 0.75rem;
            transition: 0.2s;
            transform-origin: center;
          }
          &.on {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
      td {
        height: 3rem;
        text-align: center;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        border-bottom: 1px solid #ebebeb;

        &.alien_left {
          text-align: left;
        }

        .icon_check_circle {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 1rem;
          height: 1rem;
          border-radius: 100%;
          background-color: #0da674;
          svg {
            color: #ffffff;
            width: 1rem;
          }
        }
      }
    }
  `,
};
