import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import UI_MiniReview from './ui/UI_MiniReview';

import ApSettingSelectBox from 'components/ap/com/ap-components/_components/SourceSettingPopup/_components/ApSettingSelectBox';
import styled from 'styled-components';

/** Preview 페이지 전용 미니 리뷰 컴포넌트 */
export default function PreviewMiniReview({ setIsShowMiniReview, handleNavigation }) {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const { sectionMetadata, questions, currentQuestion_index, currentPartData, currentSectionData } = useSelector((state) => state.apTestEditorReducer);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  const currentPartQuestions = questions.filter((question) => question.part_seq === currentPartData.part_seq);
  //#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 문제 이동 핸들러 - 선택한 문제로 이동 */
  const gotoQuestion = (index) => {
    handleNavigation(index);
    setIsShowMiniReview(false);
  };

  /** 파트 선택 핸들러 - 선택한 파트의 첫 번째 문제로 이동 */
  const handleSelectPart = (targetPartSeq) => {
    handleNavigation(0, targetPartSeq);
  };

  /** 섹션 선택 핸들러 - 선택한 섹션의 첫 번째 문제로 이동 */
  const handleSelectSection = (targetSectionSeq) => {
    const targetSection = sectionMetadata.sections.find((section) => section.section_seq === targetSectionSeq);
    const targetPart = targetSection.parts[0];

    handleNavigation(0, targetPart.part_seq);
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  /** 섹션 옵션 ( 해당 섹션에 question이 존재하는 경우만 표시 ) */
  const sectionOptions = sectionMetadata.sections
    .filter((section) => questions.some((question) => question.section_name === section.section_name))
    .map((item) => ({ label: item.section_name, value: item.section_seq }));

  /** 파트 옵션 ( 해당 파트에 question이 존재하는 경우만 표시 ) */
  const partOptions = currentSectionData.parts
    .filter((part) => questions.some((question) => question.part_seq === part.part_seq))
    .map((item) => ({ label: item.name, value: item.part_seq }));

  const selectArea = (
    <S.SelectArea>
      <ApSettingSelectBox
        options={sectionOptions}
        onSelect={(value) => handleSelectSection(value)}
        defaultValue={currentSectionData.section_seq}
        className='review_select_box'
      />
      {partOptions.length > 1 && (
        <ApSettingSelectBox
          options={partOptions}
          onSelect={(value) => handleSelectPart(value)}
          defaultValue={currentPartData.part_seq}
          className='review_select_box'
        />
      )}
    </S.SelectArea>
  );
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  const questionButtons = currentPartQuestions
    .sort((a, b) => a.question_order - b.question_order)
    .map((question) => (
      <Fragment key={`mini_${question.question_order}`}>
        <button
          className={`item ${currentQuestion_index === question.question_order ? 'answered' : ''} ${question.custom_question_order.length === 1 ? 'single_char' : ''}`}
          onClick={() => gotoQuestion(question.question_order)}>
          {currentQuestion_index === question.question_order && <i className='svg_icon icon_current current'>&nbsp;</i>}
          {question.custom_question_order}
        </button>
      </Fragment>
    ));

  return <UI_MiniReview onClose={() => setIsShowMiniReview(false)} selectArea={selectArea} questionButtons={questionButtons} />;
}

const S = {
  SelectArea: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .review_select_box {
      max-width: none;
      width: 100%;
      font-weight: 600;
      * div {
        font-size: 1.125rem;
      }
    }
  `,
};
