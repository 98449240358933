//#region    ////////// packages
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// utils & hooks
import useMiniAlert from 'hooks/useMiniAlert';
import useOutsideClick from 'hooks/useOutsideClick';
import useUserLevels from 'hooks/useUserLevels';
import request from 'utils/Request.utils';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';
//#endregion ////////// utils & hooks

//#region    ////////// assets
import { ReactComponent as ImgSvgTextAP } from 'assets/img/svg/test_AP.svg';
import { ReactComponent as ImgSvgTextSAT } from 'assets/img/svg/test_SAT.svg';
import { AP_ACADEMY_LIST } from 'utils/constants';
//#endregion ////////// assets

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const TYPE_CHANGE_MODE = { SAT: 'SAT', AP: 'AP' };
/** 해당 컴포넌트의 버튼 */
const BUTTONS_DATA_ARRAY = {
  SAT: {
    displayTitle: TYPE_CHANGE_MODE.SAT,
    svgIconComponent: <ImgSvgTextSAT />,
  },
  AP: {
    displayTitle: TYPE_CHANGE_MODE.AP,
    svgIconComponent: <ImgSvgTextAP />,
  },
};
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/**
 * 프로젝트의 메인 헤더 영역 메뉴 중 "AP" 페이지에 접근 할 수 있는 셀렉트 버튼 컴포넌트
 */
export default function MainApSelectButton() {
  //#region    ////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  //#endregion //////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const navigate = useNavigate();
  const { pathname } = useLocation();
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** 드롭다운 영역 노출 여부 */
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const dropdownRef = useRef(null);
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 링크 이동 핸들러 */
  const handleLink = ({ type }) => {
    // console.log('🚀 ~ handleLink ~ type:', type);
    // // 이미 해당 페이지에 있을 경우, 이동하지 않음
    // console.log('🚀 ~ handleLink ~ BUTTONS_DATA_ARRAY.SAT.displayTitle:', BUTTONS_DATA_ARRAY.SAT.displayTitle);
    // if (type === BUTTONS_DATA_ARRAY.AP.displayTitle && pathname.includes('/ap/com/score')) return;
    // if (type === BUTTONS_DATA_ARRAY.SAT.displayTitle && useLevels.isAcademy && pathname.includes('/dashboard')) return;

    const apB2bPath = `/ap/com/score/${obfuscateUrlParam('0')}?changeMode=${type}`;
    const apStudentPath = `/ap/student/dashboard?changeMode=${type}`;
    const apParentPath = `/ap/parent/dashboard?changeMode=${type}`;

    const satB2bPath = `/academy/dashboard?changeMode=${TYPE_CHANGE_MODE.SAT}`;
    const satStudentPath = `/student/dashboard?changeMode=${TYPE_CHANGE_MODE.SAT}`;
    const satParentPath = `/parent/dashboard?changeMode=${TYPE_CHANGE_MODE.SAT}`;

    if (type === BUTTONS_DATA_ARRAY.AP.displayTitle) {
      if (useLevels.isAcademy)
        navigate(apB2bPath); // 학원 관리자
      else if (useLevels.isStudent)
        navigate(apStudentPath); // 학생
      else if (useLevels.isTeacher)
        navigate(apB2bPath); // 선생님
      else if (useLevels.isTutor)
        navigate(apB2bPath); // 강사
      else navigate(apParentPath); // 학부모

      // SAT로 이동 시,
    } else {
      if (useLevels.isAcademy)
        navigate(satB2bPath); // 학원 관리자
      else if (useLevels.isStudent)
        navigate(satStudentPath); // 학생
      else if (useLevels.isTeacher)
        navigate(satB2bPath); // 선생님
      else if (useLevels.isTutor)
        navigate(satB2bPath); // 강사
      else navigate(satParentPath); // 학부모
    }
  };

  /** 드롭다운 여/닫기 핸들러 */
  const handleToggleDropdown = () => setIsOpenDropdown((current) => !current);
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    miniAlert.showWithCondition('You are now in SAT Dashboard.', { queryParam: { key: 'changeMode', value: TYPE_CHANGE_MODE.SAT } });
    miniAlert.showWithCondition('You are now in AP Dashboard.', { queryParam: { key: 'changeMode', value: TYPE_CHANGE_MODE.AP } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 --- START ////////////////////
  /** 미니 알림 훅 */
  const miniAlert = useMiniAlert();
  // 드롭다운 영역 외부를 클릭 했을 경우, 드롭다운 영역 닫기
  useOutsideClick(dropdownRef, () => setIsOpenDropdown(false), isOpenDropdown);
  //#endregion //////////////////  END  --- 커스텀 훅 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  /** AP에 접근가능한 학원인지 */
  const isApAcademy = AP_ACADEMY_LIST.map((item) => Number(item)).includes(userInfo?.ecSeq);
  if (!isApAcademy) return null;
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    !useLevelsIsLoading && (
      <S.Wrap>
        <button type='button' title='Drop down' onClick={handleToggleDropdown} className='button_ap sat'>
          <div className='icon_wrap'>{pathname.includes('/ap/') ? BUTTONS_DATA_ARRAY.AP.svgIconComponent : BUTTONS_DATA_ARRAY.SAT.svgIconComponent}</div>
          <span>{pathname.includes('/ap/') ? BUTTONS_DATA_ARRAY.AP.displayTitle : BUTTONS_DATA_ARRAY.SAT.displayTitle}</span>
        </button>
        {isOpenDropdown && (
          <div className='dropdown_wrap' ref={dropdownRef}>
            <ul>
              {Object.values(BUTTONS_DATA_ARRAY).map((item, index) => {
                return (
                  <li key={`key_${index}`}>
                    <button type='button' title='Move to another Page' onClick={() => handleLink({ type: item.displayTitle })}>
                      {item.displayTitle}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </S.Wrap>
    )
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  Wrap: styled.div`
    position: relative;
    width: 4.5rem;
    display: flex;
    justify-content: center;
    .button_ap {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      .icon_wrap {
        border-radius: 100%;
        width: 1.875rem;
        height: 1.875rem;
        background-color: #ffffff;
        color: #111111;
        font-family: 'Roboto Serif';
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          height: 0.625rem;
        }
      }
      span {
        font-weight: 400;
        font-size: 0.625rem;
      }
    }

    .dropdown_wrap {
      position: absolute;
      top: 4rem;
      left: 0;
      border-radius: 0.25rem;
      overflow: hidden;
      ul {
        li {
          & + li {
            border-top: 1px solid #d2dbe2;
          }
        }
      }
      button {
        width: 10.5rem;
        height: 2rem;
        padding: 0 1rem;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        background-color: #ffffff;
        &:hover {
          filter: brightness(0.98);
        }
      }
    }
  `,
};
