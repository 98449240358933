import { useRef, useState } from 'react';
import styled from 'styled-components';

import { AP_PARAMS } from 'components/ap/_utils/constants/queryParamsString';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import useOutsideClick from 'hooks/useOutsideClick';

import { ReactComponent as ImgSvgIconArrow } from 'assets/img/svg/icon/icon_arrow_01.svg';

import UI_ExamFooter from 'components/_common/exam/UI_ExamFooter';
import MiniReview from './_components/MiniReview';

/** AP Review Footer 컴포넌트 */
export default function ReviewFooter({ isLoading = false, questionData = [] }) {
  const { getObfuscatedValue, navigateWithParams } = useObfuscatedSearchParam();

  /** 현재 선택 된 문제의 순서 */
  const queryCurQuestionOrder = JSON.parse(getObfuscatedValue(AP_PARAMS.QUESTION_ORDER.KEY));

  /** "Mini Review" 영역 노출 여부 */
  const [isOpenedMiniReview, setIsOpenedMiniReview] = useState(false);

  const miniReviewToggleRef = useRef(null);
  useOutsideClick(miniReviewToggleRef, () => setIsOpenedMiniReview((prev) => !prev), isOpenedMiniReview);

  /** 문제 번호 이동 핸들러 */
  const navigationQuestion = ({ queryCurQuestionOrder = 0, destinationOrder = 0 }) => {
    if (
      queryCurQuestionOrder === null ||
      queryCurQuestionOrder === undefined ||
      queryCurQuestionOrder === '' ||
      destinationOrder === null ||
      destinationOrder === undefined
    )
      return;

    navigateWithParams(`/ap/com/score/review`, {
      keepParams: [[AP_PARAMS.UTH_SEQ.KEY], [AP_PARAMS.SECTION_SEQ.KEY], [AP_PARAMS.PART_SEQ.KEY]],
      newParams: { [AP_PARAMS.QUESTION_ORDER.KEY]: destinationOrder },
    });
  };

  /** "Next" / "Back" 버튼 핸들러 */
  const handleNavigation = ({ queryCurQuestionOrder = 0 }) => {
    if (queryCurQuestionOrder === null || queryCurQuestionOrder === undefined || queryCurQuestionOrder === '') return;

    /** Next 버튼 핸들러 */
    const next = () => {
      navigationQuestion({ queryCurQuestionOrder, destinationOrder: queryCurQuestionOrder + 1 });
    };
    /** Back 버튼 핸들러 */
    const back = () => {
      navigationQuestion({ queryCurQuestionOrder, destinationOrder: queryCurQuestionOrder - 1 });
    };

    return {
      next,
      back,
    };
  };

  const centerContent = isLoading ? (
    'Loading...'
  ) : (
    <S.ButtonMiniReviewWrap ref={miniReviewToggleRef}>
      <button type='button' title='Open/Close Mini Review' className='btn_mini_review' onClick={() => setIsOpenedMiniReview((prev) => !prev)}>
        Question {queryCurQuestionOrder + 1} of {questionData.length} <ImgSvgIconArrow />
      </button>
      {isOpenedMiniReview && (
        <MiniReview
          className='modal_mini_review'
          questionDataArray={questionData}
          currentNum={queryCurQuestionOrder}
          handleMoveCallBack={navigationQuestion}
          closePopup={() => setIsOpenedMiniReview(false)}
        />
      )}
    </S.ButtonMiniReviewWrap>
  );

  const rightSection = queryCurQuestionOrder !== null && queryCurQuestionOrder !== undefined && questionData.length > 0 && (
    <S.RightArea>
      <button
        type='button'
        title=''
        disabled={Number(queryCurQuestionOrder) <= 0}
        onClick={() => {
          handleNavigation({ queryCurQuestionOrder }).back();
        }}>
        Back
      </button>
      <button
        type='button'
        title=''
        disabled={Number(queryCurQuestionOrder) >= questionData.length - 1}
        onClick={() => {
          handleNavigation({ queryCurQuestionOrder }).next();
        }}>
        Next
      </button>
    </S.RightArea>
  );

  return <UI_ExamFooter centerContent={centerContent} rightContent={rightSection} />;
}

const S = {
  ButtonMiniReviewWrap: styled.div`
    position: relative;
    .btn_mini_review {
      display: flex;
      padding: 0.5625rem 1rem;
      align-items: center;
      gap: 0.25rem;
      border-radius: 0.375rem;
      background: #000;

      color: #fff;
      font-family: Pretendard;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.25rem;
      svg {
        width: 1.25rem;
        transform: rotateZ(270deg);
      }
    }

    .modal_mini_review {
      position: absolute;
      bottom: 4.5rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }
  `,

  RightArea: styled.div`
    display: flex;
    gap: 1.25rem;
    button {
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.25rem;

      display: flex;
      height: 2.375rem;
      padding: 0.875rem 1.5rem;
      align-items: center;
      gap: 0.25rem;
      border-radius: 1.875rem;
      background: #004a9e;

      &:disabled {
        background: #eaeaea;
        color: #767676;
      }
    }
  `,
};
