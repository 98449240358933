import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showMiniAlert } from 'reducers/miniAlert/miniAlertSlice';

/**
 * GlobalMiniAlert을 사용하기 위한 훅
 * @example
 * // 선언
 * const miniAlert = useMiniAlert();
 *
 * // 단순 메시지 표시
 * miniAlert.show('MESSAGE');
 * // 조건부 메시지 표시
 * miniAlert.showWithCondition('MESSAGE', { duration: 1500, queryParam: { key: 'status', value: 'success' } });
 */
export default function useMiniAlert() {
  const dispatch = useDispatch();

  const showWithCondition = (message, { duration = 1500, queryParam } = {}) => {
    // 쿼리 파라미터 체크 ex) { key: 'status', value: 'success' } => status=success 일때 실행
    if (queryParam) {
      const urlParams = new URLSearchParams(window.location.search); // 쿼리 파라미터 추출
      const paramValue = urlParams.get(queryParam.key); // 쿼리 파라미터 값 추출
      if (paramValue !== queryParam.value) return; // 쿼리 파라미터 value가 일치하지 않으면 실행하지 않음
    }

    dispatch(showMiniAlert({ message, duration }));
  };

  return {
    show: useCallback((message, duration) => dispatch(showMiniAlert({ message, duration })), [dispatch]),
    showWithCondition,
  };
}
