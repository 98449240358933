import useOutsideClick from 'hooks/useOutsideClick';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

/** 달력 세팅 모달 에서 사용 하는 커스텀 "셀렉트 박스" 컴포넌트 */
export default function CustomSelectBox({ optionsArray, defaultLabel, handleOptionClick }) {
  const [isActive, setIsActive] = useState(false);

  const dropdownRef = useRef(null);

  // 영역 외부 클릭 시, 창 닫기
  useOutsideClick(dropdownRef, () => setIsActive(false), isActive);

  return (
    <S.Wrap ref={dropdownRef} className={`com_select_layer type_updown ${isActive ? 'active' : ''}`} onClick={() => setIsActive((prev) => !prev)}>
      <section className='selected_wrap'>
        <input value={defaultLabel} readOnly />
      </section>
      <section className='layer scroll'>
        {optionsArray.map(({ label, value }, index) => (
          <p key={index} className='option' onClick={() => handleOptionClick(label, value)}>
            {label}
          </p>
        ))}
      </section>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    .selected_wrap {
      input {
        box-sizing: content-box;
        border-radius: 0.5rem;
        background: #fff;
        outline: none;
        padding: 0.5rem 0.75rem;
        padding-right: 1.375rem;
        border: 1px solid #000;
        width: 8.2rem;
        height: 2.75rem;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: nowrap;
        caret-color: transparent;
        cursor: default;
        border-color: #d2dbe2;
      }
    }
  `,
};
