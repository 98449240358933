import React, { useState } from 'react';
import styled from 'styled-components';

import PopTestList from './modals/PopTestList';
import StudentListModal from './modals/StudentListModal';
import { useParams } from 'react-router-dom';
import { SCORE_PARAMS } from 'utils/constants';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';

// 상수 정의
const VARIANTS = {
  CLASS: 'class',
  STUDENT: 'student',
};

// variant별 설정 객체
const VARIANT_CONFIG = {
  [VARIANTS.CLASS]: {
    buttonText: 'TEST LIST', // 버튼 텍스트
    modalComponent: PopTestList, // 모달 컴포넌트
    shouldShowTitle: true, // 타이틀 노출 여부
    getTitle: (curDataName) => (curDataName ? curDataName : 'No available test'), // 타이틀 텍스트를 가져오는 함수( variant별 사전 정의 필요 )
    buttonWidth: '8rem', // 버튼 너비
    targetLabel: 'testName', // 타이틀 텍스트를 가져오는 데이터의 키
  },
  [VARIANTS.STUDENT]: {
    buttonText: 'STUDENT LIST',
    modalComponent: StudentListModal,
    shouldShowTitle: true,
    getTitle: (curDataName) => (curDataName ? curDataName : 'Select Student'),
    buttonWidth: '10rem',
    targetLabel: 'userName',
  },
};

/** "/com/score" 페이지 상단 메인 "Test 선택" 영역 컴포넌트 */
export default function SelectDetailSection({ variant, dataList, isModalActive = null, modalStateHandler = null }) {
  /** 현재 studentSeq - Route Parameter */
  const { studentSeq: encStudentSeq } = useParams();
  const studentSeq = deobfuscateUrlParam(encStudentSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [activeModal, setActiveModal] = useState(isModalActive);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  const config = VARIANT_CONFIG[variant];

  /** 현재 선택된 데이터명을 가져오는 함수 (시험, 학생) */
  const curDataName = () => {
    // variant이 student 일때 ( 현재 선택된 student의 Seq로 데이터를 가져옴 )
    if (variant === VARIANTS.STUDENT) {
      if (studentSeq) {
        return dataList?.find((item) => item.userSeq === Number(studentSeq))?.[config?.targetLabel];
      }
      return null;
    }

    // variant이 class 일때 ( 현재 선택된 test의 Seq로 데이터를 가져옴 )
    if (variant === VARIANTS.CLASS) {
      if (queryCurScoreTestSeq) {
        return dataList?.find((item) => item.customSetSeq === Number(queryCurScoreTestSeq))?.[config?.targetLabel];
      }
      return dataList[0]?.[config?.targetLabel] ?? null;
    }

    console.error('Invalid variant');
    return 'Invalid variant';
  };

  //////////////////// START --- variant 별 조건부 랜더링 로직 --- START ////////////////////
  const renderTitle = () => {
    if (!config.shouldShowTitle) return <p className='tit' />;
    return <p className='tit'>{config.getTitle(curDataName())}</p>;
  };

  const renderModal = () => {
    if (!activeModal) return null;

    const modalProps = {
      setShowModal: setActiveModal,
      data: dataList,
      ...(modalStateHandler && { modalStateHandler }),
    };
    const ModalComponent = config.modalComponent;
    return <ModalComponent {...modalProps} />;
  };
  ////////////////////  END  --- variant 별 조건부 랜더링 로직 ---  END  ////////////////////

  return (
    <>
      <S.Wrap>
        {renderTitle()}
        <S.ListButton variant={variant} disabled={!curDataName} onClick={() => setActiveModal(true)}>
          {config.buttonText}
          <i className='svg_icon after icon_list_white'>&nbsp;</i>
        </S.ListButton>
      </S.Wrap>
      {renderModal()}
    </>
  );
}

const S = {
  Wrap: styled.div`
    flex: auto;
    height: 3.25rem;
    display: flex;
    gap: 0.75rem;

    .tit {
      border: 2px solid #111111;
      border-radius: 0.5rem;
      background-color: #ffffff;
      font-weight: 600;
      font-size: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  `,

  ListButton: styled.button`
    font-weight: 600;
    font-size: 0.875rem;
    color: #ffffff;
    background-color: #111111;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex: 0 0 ${({ variant }) => VARIANT_CONFIG[variant]?.buttonWidth || '8rem'}; // variant 값에 따라 width 변경 (없을떄 8rem)
    transition: all 0.4s ease;
    filter: brightness(100%);

    &:disabled {
      background-color: gray;
      filter: brightness(50%);
    }
  `,
};
