import { useState, useCallback } from 'react';
import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';

/** 텍스트 주석 기능을 위한 커스텀 훅 */
export function useAnnotation({ onAnnotationClick, onAnnotationHover, onAnnotationLeave, containerRef, tooltipRef }) {
  // 주석 관련 상태들
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [isShowAnnotationInput, setIsShowAnnotationInput] = useState(false);
  const [annotationMemo, setAnnotationMemo] = useState('');
  const [isAnnotation, setIsAnnotation] = useState(false);

  // rangy 초기화
  const initializeRangy = () => {
    rangy.init();
  };

  /** 주석 객체 생성 */
  const createAnnotation = (selection) => {
    const uniqueId = Math.floor(Math.random() * 9000) + 1000;
    const text = selection.toString();

    return {
      id: uniqueId,
      text: text.length > 30 ? `${text.slice(0, 10)}...${text.slice(-10)}` : text,
      memo: '',
    };
  };

  // 선택된 텍스트에 하이라이트 적용 (memo 파라미터 추가)
  const applyAnnotation = (annotation, memo = '') => {
    const classApplier = rangy.createClassApplier('rangy-highlight', {
      elementTagName: 'span',
      elementProperties: {
        className: 'rangy-highlight-selected',
      },
      elementAttributes: {
        'data-annotation-id': annotation.id,
        'data-text': annotation.text,
        'data-memo': memo,
      },
      onElementCreate: (element) => {
        // 이벤트 리스너 등록
        element.addEventListener('mouseover', handleMouseOver);
        element.addEventListener('mouseout', handleMouseOut);
        element.addEventListener('click', handleClick);
      },
    });

    classApplier.applyToSelection();
    rangy.getSelection().removeAllRanges();

    return annotation;
  };

  // 주석 처리를 하는 함수
  const handleAnnotateSelection = useCallback(() => {
    const selection = window.getSelection();
    if (selection && !selection.isCollapsed && containerRef.current.contains(selection.anchorNode)) {
      const newAnnotation = createAnnotation(selection);
      const applied = applyAnnotation(newAnnotation);

      if (applied) {
        setSelectedAnnotation(newAnnotation);
        setAnnotationMemo('');
        setIsShowAnnotationInput(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  const removeAnnotation = (annotationId) => {
    const elements = containerRef.current.querySelectorAll(`span[data-annotation-id="${annotationId}"]`);

    elements.forEach((el) => {
      const parent = el.parentNode;
      const textNode = document.createTextNode(el.textContent);
      parent.replaceChild(textNode, el);
    });
  };

  // 마우스 오버 이벤트 핸들러
  const handleMouseOver = (event) => {
    const annotationId = event.target.getAttribute('data-annotation-id');
    const memoTitle = event.target.getAttribute('data-text');
    const memo = event.target.getAttribute('data-memo');

    /** 같은 id를 가진 모든 하이라이트 요소들 선택 */
    const selectedAnnotations = containerRef.current.querySelectorAll(`span[data-annotation-id="${annotationId}"]`);

    const firstElement = selectedAnnotations[0];

    // 하이라이트 스타일 적용
    selectedAnnotations.forEach((el) => el.classList.add('rangy-highlight-hover'));

    // 툴팁 위치 계산 및 표시
    if (tooltipRef.current && memo) {
      const position = calculateTooltipPosition(firstElement);
      showTooltip(memo, position);
    }

    // 외부 이벤트 핸들러 호출
    if (onAnnotationHover) onAnnotationHover({ id: annotationId, text: memoTitle, memo });
  };

  // 마우스 아웃 이벤트 핸들러
  const handleMouseOut = (event) => {
    const annotationId = event.target.getAttribute('data-annotation-id');
    const memoTitle = event.target.getAttribute('data-text');

    /** 같은 id를 가진 모든 하이라이트 요소들 선택 */
    const selectedAnnotations = containerRef.current.querySelectorAll(`span[data-annotation-id="${annotationId}"]`);

    selectedAnnotations.forEach((el) => el.classList.remove('rangy-highlight-hover'));

    // 툴팁 숨기기
    if (tooltipRef.current) hideTooltip();

    // 외부 이벤트 핸들러 호출
    if (onAnnotationLeave) onAnnotationLeave({ id: annotationId, text: memoTitle });
  };

  // 클릭 이벤트 핸들러
  const handleClick = (event) => {
    const annotationId = event.target.getAttribute('data-annotation-id');
    const text = event.target.getAttribute('data-text');
    const memo = event.target.getAttribute('data-memo');

    /** 같은 id를 가진 모든 하이라이트 요소들 선택 */
    const selectedAnnotations = containerRef.current.querySelectorAll(`span[data-annotation-id="${annotationId}"]`);

    // 기존 선택 해제 및 새로운 선택 적용
    containerRef.current.querySelectorAll('.rangy-highlight').forEach((el) => {
      el.classList.remove('rangy-highlight-selected');
    });

    selectedAnnotations.forEach((el) => {
      el.classList.add('rangy-highlight-selected');
    });

    // 상태 업데이트
    const annotation = { id: annotationId, text, memo };
    setSelectedAnnotation(annotation);
    setAnnotationMemo(memo || '');
    setIsShowAnnotationInput(true);

    // 외부 이벤트 핸들러 호출
    if (onAnnotationClick) onAnnotationClick(annotation);
  };

  // 툴팁 위치 계산
  const calculateTooltipPosition = (element) => {
    const rect = element.getBoundingClientRect();

    return {
      x: rect.left + rect.width / 2,
      y: rect.top,
    };
  };

  // 툴팁 표시
  const showTooltip = (text, position) => {
    const tooltip = tooltipRef.current;
    if (!tooltip) return;

    tooltip.innerText = text;
    tooltip.style.left = `${position.x}px`;
    tooltip.style.bottom = `calc(100vh - ${position.y}px)`;
    tooltip.style.display = 'inline-block';
    tooltip.style.transform = 'translate(-50%, -10px)';
    tooltip.style.visibility = 'visible';
  };

  // 툴팁 숨기기
  const hideTooltip = () => {
    if (!tooltipRef.current) return;

    tooltipRef.current.style.display = 'none';
    tooltipRef.current.style.visibility = 'hidden';
  };

  // 주석 업데이트 (메모 변경 등)
  const updateAnnotation = (annotationId, newMemo) => {
    /** 같은 id를 가진 모든 하이라이트 요소들 선택 */
    const elements = containerRef.current.querySelectorAll(`span[data-annotation-id="${annotationId}"]`);

    elements.forEach((el) => el.setAttribute('data-memo', newMemo));
  };

  // 주석 저장 처리
  const handleSaveAnnotation = (handleEditContent, contentId) => {
    if (selectedAnnotation) {
      updateAnnotation(selectedAnnotation.id, annotationMemo);
      toggleClassName(selectedAnnotation.id, 'rangy-highlight-selected');

      // ql-editor 내부 콘텐츠만 추출
      const editorContent = containerRef.current.querySelector('.ql-editor');
      if (editorContent && handleEditContent) {
        handleEditContent(editorContent.innerHTML, contentId);
      } else if (handleEditContent) {
        // 만약 ql-editor가 없다면 전체 내용 사용
        handleEditContent(containerRef.current.innerHTML, contentId);
      }

      // 소스 내용이 변경된 후 이벤트 다시 연결
      setTimeout(() => {
        reattachAnnotationEvents();
      }, 50);

      clearAnnotation();
    }
  };

  // 주석 삭제 - handler와 contentId를 파라미터로 받음
  const handleDeleteAnnotation = (handleEditContent, contentId) => {
    if (selectedAnnotation) {
      removeAnnotation(selectedAnnotation.id);

      // ql-editor 내부 콘텐츠만 추출
      const editorContent = containerRef.current.querySelector('.ql-editor');
      if (editorContent && handleEditContent) {
        handleEditContent(editorContent.innerHTML, contentId);
      } else if (handleEditContent) {
        // 만약 ql-editor가 없다면 전체 내용 사용
        handleEditContent(containerRef.current.innerHTML, contentId);
      }
    }
    clearAnnotation();
  };

  // 주석 취소 처리
  const handleCancelAnnotation = () => {
    if (selectedAnnotation) {
      // 선택된 주석의 하이라이트 클래스 제거
      toggleClassName(selectedAnnotation.id, 'rangy-highlight-selected');

      // 새로 생성된 주석이고 메모가 없는 경우 삭제
      if (!selectedAnnotation.memo) {
        removeAnnotation(selectedAnnotation.id);
      }
    }

    clearAnnotation();
  };

  const clearAnnotation = () => {
    setIsAnnotation(false);
    setIsShowAnnotationInput(false);
    setSelectedAnnotation(null);
    setAnnotationMemo('');
  };

  // HTML 콘텐츠에서 주석 정보 추출
  const getAnnotationsFromHTML = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const annotations = [];
    const elements = tempDiv.querySelectorAll('.rangy-highlight');

    elements.forEach((el) => {
      const id = el.getAttribute('data-annotation-id');
      const text = el.getAttribute('data-text');
      const memo = el.getAttribute('data-memo');

      // 중복 제거를 위한 확인
      if (!annotations.some((a) => a.id === id)) {
        annotations.push({ id, text, memo });
      }
    });

    return annotations;
  };

  /** 주석 이벤트 리스너 재연결 함수 */
  const reattachAnnotationEvents = () => {
    if (!containerRef || !containerRef.current) return;

    // 컨테이너 내의 모든 rangy-highlight 요소 찾기
    const highlightedElements = containerRef.current.querySelectorAll('.rangy-highlight');

    // 각 요소에 이벤트 리스너 다시 연결
    highlightedElements.forEach((element) => {
      // 기존 이벤트 리스너 제거 (중복 방지)
      element.removeEventListener('mouseover', handleMouseOver);
      element.removeEventListener('mouseout', handleMouseOut);
      element.removeEventListener('click', handleClick);

      // 새 이벤트 리스너 추가
      element.addEventListener('mouseover', handleMouseOver);
      element.addEventListener('mouseout', handleMouseOut);
      element.addEventListener('click', handleClick);
    });
  };

  const toggleClassName = (annotationId, className) => {
    const elements = containerRef.current.querySelectorAll(`span[data-annotation-id="${annotationId}"]`);

    elements.forEach((el) => el.classList.toggle(className));
  };

  return {
    initializeRangy,
    createAnnotation,
    applyAnnotation,
    removeAnnotation,
    updateAnnotation,
    getAnnotationsFromHTML,
    showTooltip,
    hideTooltip,
    toggleClassName,
    reattachAnnotationEvents,
    handleAnnotateSelection,
    handleSaveAnnotation,
    handleDeleteAnnotation,
    handleCancelAnnotation,
    clearAnnotation,
    selectedAnnotation,
    isShowAnnotationInput,
    annotationMemo,
    setAnnotationMemo,
    isAnnotation,
    setIsAnnotation,
  };
}
