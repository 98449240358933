import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import GradingHeader from '../_components/layouts/header/GradingHeader';
import GradingFooter from '../_components/layouts/GradingFooter';
import ApComScoreGradingSubmissionQuestionView from './_components/questionView';

/** ap/com/score/grading/submission/question-view 페이지 */
export default function ApComScoreGradingSubmissionQuestionViewPage() {
  const questionViewRef = useRef();

  const handleBeforeClose = async () => {
    if (questionViewRef.current?.saveCurrentQuestion) {
      await questionViewRef.current.saveCurrentQuestion();
    }
  };

  useEffect(() => {
    //body에 클래스 추가
    document.body.classList.add('drag_on'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('drag_on'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrap>
      <GradingHeader />
      <ApComScoreGradingSubmissionQuestionView ref={questionViewRef} />
      <GradingFooter onBeforeClose={handleBeforeClose} />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
};
