import { useState } from 'react';
import styled from 'styled-components';

import iconHighlight from 'assets/img/exam/icon_highlight.png';
import iconUnderline from 'assets/img/exam/icon_underline.png';

/** 하단 annotation 영역 컴포넌트 */
export default function AnnotationBottom({
  handleDeleteAnnotation,
  handleCancelAnnotation,
  handleSaveAnnotation,
  annotateInfo,
  annotateMemo,
  setAnnotateMemo,
  isNewAnnotation = true,
}) {
  const [annotateDelete, setAnnotateDelete] = useState(false);

  const handleMemoChange = (event) => {
    setAnnotateMemo(event.target.value);
  };

  const toggleDeleteMode = () => {
    setAnnotateDelete((prev) => !prev);
  };

  return (
    <S.AnnotateSection>
      <article className='top'>
        <div className='txt'>
          <b>New Annotation:</b> "{annotateInfo?.text}"
        </div>
        <button className='btn_layer_close' onClick={handleCancelAnnotation}>
          CLOSE<i className='svg_icon btn_pop_close white'></i>
        </button>
      </article>
      {
        <>
          {!annotateDelete ? (
            <article className='contents'>
              <ul className='info'>
                <li className='item'>
                  Highlight Color: <i className='svg_icon icon_highlight'>&nbsp;</i>
                </li>
                <li className='item'>
                  Underline style: <i className='svg_icon icon_underline'>&nbsp;</i>
                </li>
              </ul>
              <textarea className='textarea' value={annotateMemo} onChange={handleMemoChange} placeholder='Enter a memo.' autoFocus></textarea>
              <button className='btn' onClick={handleSaveAnnotation}>
                Save
              </button>
              {isNewAnnotation ? (
                <button className='btn cancel' onClick={handleCancelAnnotation}>
                  Cancel
                </button>
              ) : (
                <button className='btn del' onClick={toggleDeleteMode}>
                  <i className='svg_icon icon_trashcan before'>&nbsp;</i>
                  Delete
                </button>
              )}
            </article>
          ) : (
            <article className='contents notice'>
              <div className='tit'>Are You Sure You Want To Delete This Annotation?</div>
              <div className='txt'>Once you delete this annotation, any notes you've made will no longer be available.</div>
              <div className='com_btn_wrap bottom'>
                <button className='btn keep' onClick={handleCancelAnnotation}>
                  Keep Annotation
                </button>
                <button className='btn yellow' onClick={handleDeleteAnnotation}>
                  Delete Annotation
                </button>
              </div>
            </article>
          )}
        </>
      }
    </S.AnnotateSection>
  );
}

// 스타일드 컴포넌트
const S = {
  AnnotateSection: styled.section`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 1000;

    .top {
      background-color: #000;
      color: #fff;
      padding: 10px 38px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .txt {
        font-size: 18px;
      }
      .btn_layer_close {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
      }
    }
    .contents {
      padding: 20px 38px;
      background: #f5f5f5;
      .info {
        display: flex;
        align-items: center;
        font-weight: bold;
        margin-bottom: 10px;
        .item {
          margin-right: 40px;
        }
      }
      .textarea {
        height: 150px;
        border: 1px solid #000;
        border-radius: 0;
        margin-bottom: 10px;
      }
      .btn {
        height: 38px;
        padding-inline: 20px;
        background-color: #0047de;
        color: #fff;
        &:hover {
          outline: 1px solid #000;
        }
        &.gray {
          background: #bbb;
        }
        &.yellow {
          border: 1px solid #000;
          color: #000;
          background-color: #f8dc4a;
        }
        &.keep {
          background: none;
          color: #0047de;
          margin-right: 50px;
          &:hover {
            outline: none;
            text-decoration: underline;
            text-underline-offset: 1px;
          }
        }
        &.cancel {
          background: none;
          color: #000;
          margin-left: 30px !important;
          &:hover {
            outline: none;
            text-decoration: underline;
            text-underline-offset: 1px;
          }
        }
        &.del {
          background: none;
          color: #c43145;
          margin-left: 30px !important;
          .svg_icon {
            margin-right: 8px;
          }
          &:hover {
            outline: none;
            text-decoration: underline;
            text-underline-offset: 1px;
          }
        }
        & + .btn {
          margin-left: 10px;
        }
      }
      &.notice {
        text-align: center;
        padding-block: 40px;
        .tit {
          font-size: 30px;
          margin-bottom: 20px;
        }
        .txt {
          font-size: 18px;
          margin-bottom: 20px;
        }
      }
    }
    .icon_highlight {
      background-image: url(${iconHighlight});
      width: 22px;
      height: 22px;
    }
    .icon_underline {
      background-image: url(${iconUnderline});
      width: 22px;
      height: 21px;
    }
  `,
};
