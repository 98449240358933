import React, { useEffect } from 'react';
import styled from 'styled-components';

import { STUDENT_PARAMS } from 'utils/constants';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

import BasicColumnChart from 'components/_common/chart/BasicColumnChart';

/** 학생 대시보드 페이지의 "Advanced Report" Section 의 "Correct answer rate by Questions" 영역 컴포넌트 */
export default function CorrectAnswerRateByQuestions({
  perSubject,
  setPerSubject,
  setPerModuleNum,
  subjectBox,
  filterValue,
  testResult,
  perModuleNum,
  moduleBox,
  setFilterValue,
  filteredList,
  timePerQuestionData,
  selectedTest,
}) {
  const { getObfuscatedValue, setBulkObfuscatedValues } = useObfuscatedSearchParam();
  /** 현재 선택 된 시험의 과목 문자열 */
  const selectedSubject = getObfuscatedValue(STUDENT_PARAMS.SUBJECT.KEY);
  /** 현재 선택 된 시험의 모듈 번호 문자열 */
  const selectedModuleNum = getObfuscatedValue(STUDENT_PARAMS.MODULE_NUM.KEY);

  const getClassForContentDomain = (contentDomain) => {
    if (contentDomain === '008001000000000') return 'state01 pointer';
    else if (contentDomain === '008002000000000') return 'state02 pointer';
    else if (contentDomain === '008003000000000') return 'state03 pointer';
    else if (contentDomain === '008004000000000') return 'state04 pointer';
    else if (contentDomain === '009001000000000') return 'state01 pointer';
    else if (contentDomain === '009002000000000') return 'state02 pointer';
    else if (contentDomain === '009003000000000') return 'state03 pointer';
    else if (contentDomain === '009004000000000') return 'state04 pointer';

    return '';
  };

  const perSubActive = () => {
    setPerSubject((prev) => !prev);
  };

  const perNumActive = () => {
    setPerModuleNum((prev) => !prev);
  };

  const questionCheck = (e, item) => {
    window.open(`/student/review?viewQuestion=${encodeURIComponent(item.uthSeqStr + 'GATEPLUS' + item.questionSeqStr)}`, '_blank');
  };

  /** 화면에 표시 될 현재 선택 된 과목 표시 */
  const displaySelectedTimeSubject = (selectedSubject) => {
    return selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.MATH ? 'Math' : 'RW';
  };

  /** 화면에 표시 될 현재 선택 된 모듈 번호 표시 */
  const displaySelectedTimeModuleNum = (selectedModuleNum) => {
    /** 화면에 표시 될 모듈 번호 */
    let displayModuleNum;
    if (selectedModuleNum === STUDENT_PARAMS.MODULE_NUM.VALUE.MODULE_1) displayModuleNum = 'Module 1';
    else if (selectedModuleNum === STUDENT_PARAMS.MODULE_NUM.VALUE.MODULE_2 && filterValue.tmDifficulty === 'E') displayModuleNum = 'Module 2 Easy';
    else if (selectedModuleNum === STUDENT_PARAMS.MODULE_NUM.VALUE.MODULE_2 && filterValue.tmDifficulty === 'H') displayModuleNum = 'Module 2 Hard';
    else if (selectedModuleNum === STUDENT_PARAMS.MODULE_NUM.VALUE.MODULE_2) displayModuleNum = 'Module 2';
    else displayModuleNum = '-';

    return displayModuleNum;
  };

  /** "Correct answer (rete) by Question" 차트의 "과목" 선택 셀렉트 박스 핸들러 */
  const handleSelectSubject = (subject) => {
    if (testResult.subject === 'F') {
      setBulkObfuscatedValues({ paramValues: { [STUDENT_PARAMS.SUBJECT.KEY]: subject } });
    }
  };
  /** "Correct answer (rete) by Question" 차트의 "모듈 번호" 선택 셀렉트 박스 핸들러 */
  const handleSelectModuleNum = (moduleNum) => {
    setBulkObfuscatedValues({ paramValues: { [STUDENT_PARAMS.MODULE_NUM.KEY]: moduleNum } });
    setFilterValue((prev) => {
      return { ...prev, moduleNum };
    });
  };

  return (
    <S.Wrap className='box_graph active correct_answer_sec'>
      <div className='top_area'>
        <p className='tit_field'>Correct answer (rate) by Questions</p>
        <div className={`com_select_layer ${perSubject ? 'active' : ''}`} onClick={perSubActive} ref={subjectBox}>
          {/*클래스 active 포함 시 옵션 노출*/}
          <section className='selected__wrap'>
            <input type='text' className='selected' placeholder={displaySelectedTimeSubject(selectedSubject)} size='5' />
          </section>
          <section className='layer'>
            {selectedTest?.rwScore !== null && (
              <p className='option' onClick={() => handleSelectSubject(STUDENT_PARAMS.SUBJECT.VALUE.RW)}>
                RW
              </p>
            )}
            {selectedTest?.mathScore !== null && (
              <p className='option' onClick={() => handleSelectSubject(STUDENT_PARAMS.SUBJECT.VALUE.MATH)}>
                MATH
              </p>
            )}
          </section>
        </div>
        <div className={`com_select_layer type_tit ${perModuleNum ? 'active' : ''}`} onClick={perNumActive} ref={moduleBox}>
          {/*클래스 active 포함 시 옵션 노출*/}
          <section className='selected__wrap'>
            {/* <p className="tit_field">Module</p> */}
            <input type='text' className='selected' placeholder={displaySelectedTimeModuleNum(selectedModuleNum)} size='10' />
          </section>
          <section className='layer'>
            <p className='option' onClick={() => handleSelectModuleNum(STUDENT_PARAMS.MODULE_NUM.VALUE.MODULE_1)}>
              Module 1
            </p>
            <p className='option' onClick={() => handleSelectModuleNum(STUDENT_PARAMS.MODULE_NUM.VALUE.MODULE_2)}>
              Module 2
            </p>
          </section>
        </div>
      </div>
      <div className='content_area'>
        <table className='table'>
          <thead>
            <tr>
              <th className='first'>Question No.</th>
              {filteredList.map((item, index) => {
                return (
                  <th key={index} className={getClassForContentDomain(item.contentDomain)} onClick={(e) => questionCheck(e, item)}>
                    {item.questionOrder}
                    {item.bookmarkYn === 'Y' && <i className='svg_icon icon_bookmark_on'>&nbsp;</i>}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {/* 241129 Trello 카드 "336. 학생대시보드 Score 추가 요청사항" 참고 */}
            {/* <tr>
              <td>Difficulty</td>
              {testResult.questionAccuracyList.map((item, index) => {
                return <td key={`${item.questionSeqStr}${index * Number(item.questionSeq)}`}>{item.difficulty}</td>;
              })}
            </tr> */}
            <tr>
              <td className='first'>GATEPLUS Average</td>
              {testResult.questionAccuracyList.map((item, index) => {
                /** 정답률 수치에 따른 Style 설정 용 className */
                const className = item.correctAnswerRate <= 50 ? 'rate01' : item.per <= 75 ? 'rate02' : '';

                return (
                  <td key={`${item.questionSeqStr}${index * Number(item.questionSeq)}`} className={className}>
                    {item.correctAnswerRate}%
                  </td>
                );
              })}
            </tr>
            <tr>
              <td>Result</td>
              {filteredList.map((item, index) => (
                <td key={index}>{item.isCorrect ? <i className='svg_icon icon_right'>&nbsp;</i> : <i className='svg_icon icon_wrong'>&nbsp;</i>}</td>
              ))}
            </tr>
          </tbody>
        </table>
        {/*Time per Question*/}
        <div className='box_graph active time_per_question'>
          <div className='top_area'>
            {' '}
            <span className='tit_field'>Time per Question</span>{' '}
          </div>
          <div className='chart_area'>
            {/* <StackedColumnsChart timePerQuestionData={timePerQuestionData}/> */}
            <BasicColumnChart timePerQuestionData={timePerQuestionData} />
          </div>
        </div>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.article`
    input.selected::placeholder {
      text-transform: uppercase;
    }
  `,
};
