import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import request from 'utils/Request.utils';
import SubjectList from './_components/_SubjectList';
import TeacherList from './_components/_TeacherList';

////////// components

/** http://localhost:3000/ap/academy/teacher-import  */
export default function TeacherImport() {
  const navigate = useNavigate();
  // 과목 관련 state
  const [apSubject, setApSubject] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(0);

  // teacher 관련 state
  const [teacherList, setTeacherList] = useState([]);
  const [checkedTeacher, setCheckedTeacher] = useState([]);

  // 과목 리스트 가져오기
  const getSelectSubject = () => {
    // api 호출
    const successHandler = (response) => {
      const ap_subject = response.result.map((item) => {
        return { subjectSeq: item.subject_seq, subjectName: item.subject_name };
      });
      setApSubject(ap_subject);
    };
    const errorHandler = (error) => {};
    const params = { isNew: true };
    request.get(request.apiUris.ap.subject.list, params, successHandler, errorHandler);
  };

  // teacher list 가져오기
  const getTeachersList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const teachersList = response.result.teacherList;

        setTeacherList(teachersList);
      }
    };

    request.apGet('/api/v1/academy/teacher/subject', null, successHandler);
  };

  useEffect(() => {
    getSelectSubject();
    getTeachersList();
  }, []);

  return (
    <>
      <div className='com_center_wrap' style={{ paddingTop: '7rem' }}>
        {/* <SetTestsStep /> */}
        <div className='com_box'>
          <S.TopArea>
            <h2>Teacher Subject Labelling</h2>
            <p>Once subjects are assigned, students’ information will be viewed by teachers assigned with the student’s respective subject.</p>
          </S.TopArea>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SubjectList apSubject={apSubject} setSelectedSubject={setSelectedSubject} />

            <div className='com_list custom_scroll' style={{ width: '77.38%', maxHeight: '54rem' }}>
              <section className='top_area'></section>
              <TeacherList
                teacherList={teacherList}
                setTeacherList={setTeacherList}
                apSubject={apSubject}
                selectedSubject={selectedSubject}
                checkedTeacher={checkedTeacher}
                setCheckedTeacher={setCheckedTeacher}
              />
            </div>
          </div>
          <article className='com_btn_wrap bottom' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button
              className='com_btn point l'
              onClick={() => {
                navigate('/ap/com/students/classes');
              }}>
              SAVE
            </button>
            {/* <button
              className='com_btn point l'
              onClick={() => {
                navigate('/academy/teacher-student');
              }}>
              NEXT
            </button> */}
          </article>
        </div>
      </div>
    </>
  );
}

const S = {
  TopArea: styled.div`
    margin: 1.5rem 1.25rem;
    padding: 1.5px;

    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.75rem; /* 140% */
    }
    p {
      color: #999;

      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
    }
  `,
};
