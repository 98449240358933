import { useState } from 'react';
import styled from 'styled-components';

import RateGraphItem from './RateGraphItem';

/** score에서 차트를 보여주는 Box */
export default function AnswerRateGraphBox({
  boxTitle,
  handleSelectSubject,
  isActive = true,
  chartInfo,
  isShowAverage = false,
  checkViewAboutAverageOfGateplus,
  disabled = false,
}) {
  /** 차트 평균 데이터 - 평균 노출 여부 Props에 따라 결정 */
  const barChartDataAvg = isShowAverage && chartInfo.chartDate.reduce((acc, cur) => acc + cur.data, 0) / chartInfo.chartDate.length;

  const handleClickBox = () => {
    if (disabled) return;
    handleSelectSubject(chartInfo.label);
  };

  return (
    <S.BoxContainer className={`${isActive ? 'box_active' : 'box_disable'} ${disabled ? 'disable_user_event' : ''}`} onClick={handleClickBox}>
      <S.ContainerHeader>
        <p className='container_title'>{boxTitle}</p>
        <div className='rate_info'>
          {isShowAverage ? (
            <>
              <p className='name'>{chartInfo.label}</p>
              <p className='divider'>|</p>
              <p className='data'>{`${barChartDataAvg.toFixed(2)}${chartInfo.unit}`}</p>
            </>
          ) : (
            <p>{chartInfo.label}</p>
          )}
        </div>
      </S.ContainerHeader>

      <S.BoxContent className='com_answer_grah'>
        {chartInfo.chartDate.map((item, index) => {
          return (
            <RateGraphItem
              key={index}
              graphData={{
                ...item,
                max: chartInfo.max ?? 100,
                unit: chartInfo.unit,
              }}
              index={index + 1}
              checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
            />
          );
        })}
      </S.BoxContent>
    </S.BoxContainer>
  );
}

const S = {
  BoxContainer: styled.div`
    width: 100%;
    transition: all 0.3s ease;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;

    &.box_disable {
      background: #fafafa;
      &:hover {
        transform: scale(1.01);
        transition: all 0.3s ease;
        cursor: pointer;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);
        opacity: 0.7;
      }
      .container_title,
      .rate_info,
      .tit_field,
      .grah_field {
        color: #505050;
        font-weight: 400;
        .graph_wrap {
          .num {
            font-weight: 400;
          }
        }
      }
    }
    &.box_active {
      background: #f4f8ff;
    }
    &.disable_user_event {
      cursor: not-allowed !important;
      /* filter: brightness(80%); */
      &:hover {
        transform: none;
        opacity: 1;
        box-shadow: none;
      }
    }
  `,

  ContainerHeader: styled.div`
    .box_active & {
      background-color: #d9e7ff;
      color: #0068bd;
    }
    background-color: #f2f2f2;
    color: black;

    font-size: 0.875rem;
    font-weight: 700;
    border-bottom: 1px solid #fff;
    height: 3.125rem;
    gap: 0.625rem;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .rate_info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      background-color: white;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      /* height: 1.25rem; */
      height: 1.75rem;
      padding: 0 0.625rem;

      color: black;

      .divider {
        color: #f1f1f5;
      }

      p {
        line-height: 1;
      }
    }
  `,

  BoxContent: styled.div`
    display: flex;
    gap: 1rem;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: 1.25rem 1.5rem;
  `,
};
