import React, { useState, useEffect } from 'react';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

//components
import MiniAlert from 'components/_common/alerts/MiniAlert'; //텍스트 알럿
import CustomAlert from 'components/_common/alerts/CustomAlert';
import EditTestScheduleModal from './_components/EditTestScheduleModal'; //test 수정 모달
import DeleteModal from 'components/_common/modals/DeleteModal'; //test 삭제 모달
import ContentTopTabs from './_components/ContentTopTabs';
import ByTest from './_components/tapContents/ByTest';
import ByClass from './_components/tapContents/ByClass';
import MainLink from 'components/_common/button/_MainLinks';
import styled from 'styled-components';
import DeleteStudentAlert from './_components/DeleteStudentAlert';

/** com/tests/scheduled 경로의 Page */
export default function TestSchedulePage() {
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 시작 /////////////
  /** 커스텀 알럿 */
  let alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 끝 /////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [reloadData, setReloadData] = useState(false);
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  /** tab */
  const [tabState, setTabState] = useState(0);
  /** mini alert */
  const [miniAlert, setMiniAlert] = useState({
    active: false,
    text: '',
    type: '',
    timeOut: 0,
  });

  const [editTest, setEditTest] = useState({
    active: false,
    editData: {
      testName: '',
      className: '',
      testStartDate: '',
      testEndDate: '',
      studentList: [],
    },
    type: '',
  });
  /** 삭제 모달 */
  const [deleteTestModal, setDeleteTestModal] = useState({
    active: false,
    editData: {
      testName: '',
      className: '',
      testStartDate: '',
      testEndDate: '',
      studentList: [],
    },
    type: '',
  });
  const [isShowDeleteAlert, setIsShowDeleteAlert] = useState(false);
  /** 인피니티 스크롤 */
  const [isLoading, setIsLoading] = useState(true);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  /** class 수정 클릭 핸들러 */
  const classEditClick = (item) => {
    setEditTest((prev) => {
      return { ...prev, active: true, editData: item, type: 'class' };
    });
  };
  const classDeleteClick = (item) => {
    setDeleteTestModal((prev) => {
      return { ...prev, active: true, deleteData: item };
    });
  };

  /** 시험 일정 삭제 API 호출 */
  const delUserTestSchedule = () => {
    const successHandler = (response) => {
      console.log(`\n┏━━━ 💡 💡 response 💡 💡 ━━━\n`, response, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);
      if (response.code === 200) {
        setIsShowDeleteAlert(true);
        setDeleteTestModal((prev) => ({ ...prev, active: false }));
        setReloadData((prev) => !prev);
      }
    };

    const seqStr = deleteTestModal.deleteData.join('|');
    request
      .del(`/api/test/schedules?seqStr=${encodeURI(seqStr)}`, null, successHandler)
      .catch((error) => console.error('tests/scheduled 페이지의 "시험 일정 삭제" API 응답 에러', error));
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        returnValue: (value) => {
          if (nvl(value) === 'OK') {
            console.log('요기에서 작업');

            // closeCustomAlert();
          }
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertLayerPopup?.id]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  const testScheduleTabs = [
    { text: 'By Class', value: 0 },
    { text: 'By Test', value: 1 },
  ];

  return (
    <>
      <S.Wrap className='com_schedule'>
        <MainLink type='test' />
        <div className='com_center_wrap'>
          <ContentTopTabs tabData={testScheduleTabs} tabState={tabState} setTabState={setTabState} />
          <>
            {tabState === 0 ? (
              <ByClass classEditClick={classEditClick} reloadData={reloadData} classDeleteClick={classDeleteClick} />
            ) : tabState === 1 ? (
              <ByTest setEditTest={setEditTest} classEditClick={classEditClick} classDeleteClick={classDeleteClick} reloadData={reloadData} />
            ) : null}
          </>
        </div>
      </S.Wrap>
      {miniAlert.active && (
        <MiniAlert
          text={miniAlert.text}
          type={miniAlert.type}
          active={miniAlert.active}
          inactive={() =>
            setMiniAlert((prev) => {
              return { ...prev, active: false };
            })
          }
          timeOut={miniAlert.timeOut}
        />
      )}
      {deleteTestModal.active && (
        <DeleteModal
          setIsViewModal={setDeleteTestModal}
          text={'You are removing scheduled test.\nYour Gate Key will be refunded.'}
          hideModal={() => setDeleteTestModal((prev) => ({ ...prev, active: false }))}
          callback={delUserTestSchedule}
        />
      )}
      {isShowDeleteAlert && <DeleteStudentAlert setDeleteStudentAlert={setIsShowDeleteAlert} />}
      {editTest.active && (
        <EditTestScheduleModal
          editTest={editTest}
          setEditTest={setEditTest}
          setAlertLayerPopup={setAlertLayerPopup}
          setIsLoading={setIsLoading}
          setMiniAlert={setMiniAlert}
          //
          setReloadData={setReloadData}
        />
      )}
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </>
  );
}

const S = {
  Wrap: styled.div`
    .com_container {
      background: #f4f8ff;
      padding: 15px;
      border-radius: 10px;
    }
    .full {
      width: 100%;
    }
  `,
};
