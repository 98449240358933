import React from 'react';
import CountUp from 'react-countup';
import styled from 'styled-components';

import SectionTitle from '../common/SectionTitle';
import Box from '../common/Box';
import ScoreCircleChart from 'components/_common/chart/ScoreCircleChart';

import { calculatePercentage } from 'utils/Common.utils';

/** "/com/score" 페이지의 "Scores" 차트 영역 컴포넌트 */
export default function ScoresChartSection({ classAverageScore }) {
  return (
    <S.Wrap>
      <Box className='main_box score'>
        <SectionTitle style={{ paddingBottom: '2rem' }}>Score</SectionTitle>
        <S.ChartContainer>
          <ScoreCircleChart
            rank={calculatePercentage(classAverageScore.rwScore, 800)}
            size='9.125rem'
            chartStyle={{
              countAnimation: true,
              fillOptions: {
                fillColor: '#008CFF',
              },
              chartHollowSize: '50%',
            }}
            CustomLabel={
              <S.ChartLabel>
                <CountUp start={0} end={classAverageScore.rwScore === 0 ? '-' : classAverageScore.rwScore} duration={3} className='label-value' />
                <p className='label-name'>RW</p>
              </S.ChartLabel>
            }
          />
          <hr className='div_chart' />
          <ScoreCircleChart
            rank={calculatePercentage(classAverageScore.mathScore, 800)}
            size='9.125rem'
            chartStyle={{
              countAnimation: true,
              fillOptions: {
                fillColor: '#1FDF9F',
              },
              chartHollowSize: '50%',
            }}
            CustomLabel={
              <S.ChartLabel>
                <CountUp start={0} end={classAverageScore.mathScore === 0 ? '-' : classAverageScore.mathScore} duration={3} className='label-value' />
                <p className='label-name'>Math</p>
              </S.ChartLabel>
            }
          />
        </S.ChartContainer>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    flex: 2;
    .main_box {
      padding: 1.25rem 1.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .div_chart {
      width: 100%;
      border: none;
      border-top: 1px solid #e5e5e5;
    }
  `,
  ChartContainer: styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  `,
  ChartLabel: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .label-value {
      font-size: 2rem;
      font-weight: 700;
      line-height: 118%;
    }
    .label-name {
      font-size: 0.8125rem;
      color: #4b5563;
    }
  `,
};
