import React, { useState, useEffect } from 'react';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import { getRBCEventColor } from 'utils/Common.utils';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import dayjsTZ from 'utils/functions/time/dayjs-config';

// 타임존 사용을 위한 기본 플러그인
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

// components
import Toolbar from './CalendarToolbar';
import DateHeader from './CalendarDateHeader';
import DateCellWrapper from './CalendarDateCellWrapper';

//css
import 'react-big-calendar/lib/css/react-big-calendar.css';

dayjs.locale('en');
const localizer = dayjsLocalizer(dayjs);
// 타임존 설정시 타임존의 offset이 클라이언트를 넘어가는 경우 이를 보정하기 위한 설정
localizer.segmentOffset = 0;

const normalizeEvents = (events) => {
  return events.map((event) => {
    // 단순 문자열 (YYYY-MM-DD)을 dayjs 객체로 변환
    const startDate = dayjsTZ(event.start).startOf('day');
    const endDate = dayjsTZ(event.end).endOf('day');

    return {
      ...event,
      start: startDate.toDate(),
      end: endDate.toDate(),
      // 스타일링을 위한 메타데이터 추가
      isMultiDay: !startDate.isSame(endDate, 'day'),
      totalDays: endDate.diff(startDate, 'day') + 1,
    };
  });
};

export default function CalendarTable({ currentDate, setCurrentDate, events, setEvents, classInfo, setClassInfo }) {
  const [selectedEventTitle, setSelectedEventTitle] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const today = dayjsTZ();
  const location = useLocation();

  const eventsForThisDay = (slotInfo) => {
    setSelectedEventTitle(false);
    setSelectedEvent(null);
  };

  const selectDate = (event) => {
    setSelectedEventTitle(event.title);
    setSelectedEvent(event);
  };

  // 정규화된 이벤트 생성
  const formattedEvents = normalizeEvents(events);

  return (
    <div className='CalendarTable'>
      <Calendar
        defaultDate={location.state ? dayjsTZ(location.state.selectedDate).toDate() : currentDate}
        localizer={localizer}
        events={formattedEvents}
        startAccessor='start'
        endAccessor='end'
        defaultView='month'
        style={{ minHeight: 974 }}
        components={{
          toolbar: (props) => <Toolbar {...props} classInfo={classInfo} setClassInfo={setClassInfo} events={events} setEvents={setEvents} />,
          month: {
            dateHeader: DateHeader,
          },
          dateCellWrapper: DateCellWrapper,
        }}
        onSelectSlot={eventsForThisDay}
        eventPropGetter={(event) => {
          const barColorInfo = dayjsTZ(event.end).isBefore(today)
            ? {
                name: 'gray',
                backgroundColor: '#CFCFCF',
                color: '#606060',
                borderColor: '#BEBEBE',
              }
            : getRBCEventColor().find((item) => item.name === event.classColor);

          return {
            style: {
              ...barColorInfo,
              marginBottom: '4px',
              borderWidth: '2px',
              borderStyle: 'solid',
              outline: event === selectedEvent ? '2px solid rgb(13, 101, 255, 0.7)' : 'none',
            },
          };
        }}
        onSelectEvent={selectDate}
        onDragStart={() => {
          return;
        }}
        selectable
        popup={true}
        onNavigate={(date, _) => {
          setCurrentDate(dayjsTZ(date).toDate());
        }}
        messages={{ showMore: (count) => `${count} More...` }}
      />
    </div>
  );
}
