import React from 'react';
import styled from 'styled-components';

/** 주요 버튼 스타일 용 컴포넌트 */
export default function PrimaryButton({ children, ...rest }) {
  return <S.Button {...rest}>{children}</S.Button>;
}

const S = {};

S.Button = styled.button`
  background-color: var(--point-color);
  color: #ffffff;
  &:focus {
    outline: 1px solid var(--point-color);
  }
  &:disabled {
    background-color: #cccccc;
    cursor: default;
  }
`;
