import React from 'react';
import styled from 'styled-components';

const Tooltip = React.forwardRef((props, ref) => {
  return <S.wrap ref={ref}></S.wrap>;
});

export default Tooltip;

const S = {};

S.wrap = styled.div`
  position: fixed;
  left: -1000px;
  bottom: -1000px;
  background-color: white;
  color: black;
  padding: 8px;
  border: 2px solid black;
  border-radius: 5px;
  z-index: 100;
`;
