import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSectionMetadata } from 'reducers/apTestEditor/action';

import { transformChoicesToFrontend } from './_utils';
import { EDITOR_CONSTANT, initialQuestion, initAnswerChoiceInfo, ANSWER_CHOICE_CONSTANT, initCurrentPart, QUESTION_TYPE_CONSTANT } from './_constant';

import request from 'utils/Request.utils';

export default function useTestEditor() {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const dispatch = useDispatch();
  const apTestEditorReducer = useSelector((state) => state.apTestEditorReducer);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [editorState, setEditorState] = useState({
    [EDITOR_CONSTANT.currentIndex]: 0, // 현재 문제 인덱스
    [EDITOR_CONSTANT.currentSection]: 0, // 현재 Section
    [EDITOR_CONSTANT.currentPart]: initCurrentPart, // 현재 Part 데이터
    [EDITOR_CONSTANT.currentQuestion]: initialQuestion, // 현재 문제
    [EDITOR_CONSTANT.answerChoiceInfo]: initAnswerChoiceInfo, // 답안 선택지 정보
    [EDITOR_CONSTANT.sourceContent]: [''], // 소스 내용
    [EDITOR_CONSTANT.currentSourceOrder]: 0, // 현재 선택된 소스
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** 새로운 테스트를 만들기 위한 메타데이터 호출 && 새로운 시험 문제 정보 삽입 */
  const getTestMetadata = async (test_set_seq) => {
    // /api/ap/question-bank/test/:test_set_seq GET
    const successHandler = async (response) => {
      const fetchedSectionMetadata = response.result;

      // 메타데이터 세팅 ( redux & state )
      dispatch(updateSectionMetadata(fetchedSectionMetadata)); // 메타데이터 redux에 저장
      editorStateUpdater(EDITOR_CONSTANT.currentSection, fetchedSectionMetadata.sections[0]); // 섹션 정보 세팅
      editorStateUpdater(EDITOR_CONSTANT.currentPart, fetchedSectionMetadata.sections[0].parts[0]); // 파트 정보 세팅

      // 문제 정보 세팅 ( state )
      editorStateUpdater(EDITOR_CONSTANT.currentQuestion, {
        section_name: fetchedSectionMetadata.sections[0].section_name,
        part_name: fetchedSectionMetadata.sections[0].parts[0].name,
        test_name: fetchedSectionMetadata.test_info.test_set_name,
        subject_name: fetchedSectionMetadata.test_info.subject_name,
      });

      // part 데이터에 따라 answerChoices 초기갯수 지정
      editorStateUpdater(EDITOR_CONSTANT.answerChoiceInfo, {
        ...initAnswerChoiceInfo,
        answerChoices:
          fetchedSectionMetadata.sections[0].parts[0].type_of_question === QUESTION_TYPE_CONSTANT.FRQ
            ? [{ choiceType: 'Text Input', content: '' }]
            : Array.from({ length: fetchedSectionMetadata.sections[0].parts[0].answer_choices }).map((_, index) => ({
                choiceType: 'Text Input',
                content: '',
              })),
      });
    };
    const errorHandler = async (error) => {
      console.error('getTestInfo errorHandler:', error);
      alert(error.message);
      location.href = '/ap/question-bank/create-test';
    };
    await request.get(`/api/ap/question-bank/test/detail/${test_set_seq}`, { isNew: true }, successHandler, errorHandler);
  };

  /** 수정중이던 테스트 데이터 불러오기 */
  const getEditingTestData = async (test_set_seq) => {
    // /api/ap/question-bank/test/:test_set_seq/question GET
    const successHandler = (response) => {
      const { sectionMetadata: fetchedSectionMetadata, questions: fetchedQuestions } = response.result;

      /** 기존 메타데이터에서 section_name을 추가해서 반환 */
      const formattedSectionMetadata = {
        ...fetchedSectionMetadata,
        sections: fetchedSectionMetadata.sections.map((section) => ({
          ...section,
          section_name: section.name,
        })),
      };

      // 메타데이터 세팅 ( redux & state )
      dispatch(updateSectionMetadata(formattedSectionMetadata)); // 메타데이터 redux에 저장
      editorStateUpdater(EDITOR_CONSTANT.currentSection, formattedSectionMetadata.sections[0]); // 섹션 정보 세팅
      editorStateUpdater(EDITOR_CONSTANT.currentPart, formattedSectionMetadata.sections[0].parts[0]); // 파트 정보 세팅

      // 문제 데이터 세팅 ( redux & state ) 전체 questions 배열을 한번에 설정
      dispatch({ type: 'UPDATE_QUESTION', payload: { questions: fetchedQuestions } });
      editorStateUpdater(EDITOR_CONSTANT.currentQuestion, {
        ...fetchedQuestions[0],
        section_name: formattedSectionMetadata.sections[0].section_name,
        part_name: formattedSectionMetadata.sections[0].parts[0].name,
        test_name: formattedSectionMetadata.test_info.test_set_name,
        subject_name: formattedSectionMetadata.test_info.subject_name,
      });
    };
    const errorHandler = (error) => {
      console.error('getApTestData errorHandler:', error);
      alert(error.message);
      location.href = '/ap/question-bank/create-test';
    };
    await request.get(`/api/ap/question-bank/test/${test_set_seq}/question`, { isNew: true }, successHandler, errorHandler);
  };

  /** 테스트 저장 ( status에 따라 단순 저장 or 작성 완료 ) */
  const postApTestData = async (status, questionsToSend) => {
    // /api/ap/question-bank/test/:test_set_seq/question POST
    const successHandler = (response) => {
      if (status === 'Completed') {
        console.log('🚀 문제 게시 완료 🚀');
        location.href = '/ap/question-bank';
      }
    };
    const errorHandler = (error) => {
      console.error('postApTestData errorHandler:', error);
      alert(error.message);
      location.href = '/ap/question-bank/create-test';
    };
    const params = {
      isNew: true, // node api 호출 시 필요한 파라미터
      status: status,
      questions: questionsToSend,
    };

    await request.post(`/api/ap/question-bank/test/${apTestEditorReducer.test_seq.test_seq}/question`, params, successHandler, errorHandler);
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 상태 setter ( 특정 key의 상태를 완전히 덮어씌움 ) */
  const editorStateSetter = (key, value) => {
    setEditorState((prev) => ({ ...prev, [key]: value }));
  };
  /** 상태 updater ( 특정 key의 내부 데이터를 업데이트 ) */
  const editorStateUpdater = (key, value) => {
    setEditorState((prev) => ({ ...prev, [key]: { ...prev[key], ...value } }));
  };
  /** 상태 mutator ( 기존 상태를 기반으로 값을 변경 ) */
  const editorStateMutator = (key, mutator) => {
    setEditorState((prev) => ({ ...prev, [key]: mutator(prev[key]) }));
  };

  /** 현재 파트의 문제 목록 조회 */
  const getCurrentPartQuestion = () =>
    apTestEditorReducer.questions.filter((question) => question.part_seq === editorState[EDITOR_CONSTANT.currentPart].part_seq);

  /** 현재 파트의 answer_choices 갯수만큼 보기 생성 */
  const initAnswerChoicesToBackend = () => {
    const answerChoices = editorState[EDITOR_CONSTANT.currentPart].answer_choices;

    const options = Array.from({ length: answerChoices }).reduce((acc, _, index) => ({ ...acc, [`option${index + 1}`]: '' }), {});

    return {
      ...options,
      total: answerChoices,
      answerCount: 0,
    };
  };

  /** 새로운 문제 추가 */
  const addNewQuestion = () => {
    // 문제 정보 초기값으로 초기화 ( 단, 기존 파트 데이터는 유지 )
    editorStateSetter(EDITOR_CONSTANT.currentQuestion, {
      ...initialQuestion,
      part_seq: editorState[EDITOR_CONSTANT.currentPart].part_seq, // 기존 파트 번호 유지
      part_name: editorState[EDITOR_CONSTANT.currentPart].name, // 기존 파트 명 유지
      section_name: editorState[EDITOR_CONSTANT.currentSection].section_name, // 기존 섹션 명 유지
      test_name: editorState[EDITOR_CONSTANT.currentQuestion].test_name, // 기존 테스트 명 유지
      subject_name: editorState[EDITOR_CONSTANT.currentQuestion].subject_name, // 기존 과목 명 유지
      question_order: editorState[EDITOR_CONSTANT.currentIndex], // 문제 번호 +1
      // custom_question_order: editorState[EDITOR_CONSTANT.currentIndex].toString(), // 문제 번호 +1
      custom_question_order: null,
      question_type: editorState[EDITOR_CONSTANT.currentPart].type_of_question,

      // 현재 파트의 answer_choices 갯수만큼 보기 생성
      answer_options: {
        ...initAnswerChoicesToBackend(),
        total:
          editorState[EDITOR_CONSTANT.currentPart].type_of_question === QUESTION_TYPE_CONSTANT.FRQ
            ? 1
            : editorState[EDITOR_CONSTANT.currentPart].answer_choices,
        answerCount: 0,
      },
    });

    // 주관식은 하나의 정답만을 제공
    if (editorState[EDITOR_CONSTANT.currentPart].type_of_question === QUESTION_TYPE_CONSTANT.FRQ) {
      editorStateSetter(EDITOR_CONSTANT.answerChoiceInfo, initAnswerChoiceInfo);

      // 객관식은 파트 데이터에 따라 answer_choices 갯수만큼 보기 생성
    } else if (editorState[EDITOR_CONSTANT.currentPart].type_of_question === QUESTION_TYPE_CONSTANT.MCQ) {
      // 현재 파트의 answer_choices 갯수만큼 보기 생성
      editorStateSetter(EDITOR_CONSTANT.answerChoiceInfo, {
        ...initAnswerChoiceInfo,
        answerChoices: Array.from({ length: editorState[EDITOR_CONSTANT.currentPart].answer_choices }).map(() => ({
          choiceType: 'Text Input',
          content: '',
        })),
      });
    }

    editorStateSetter(EDITOR_CONSTANT.sourceContent, ['']); // 소스 내용 초기화
    editorStateSetter(EDITOR_CONSTANT.currentSourceOrder, 0); // 현재 선택된 소스
  };

  /** 파트와 문제 번호에 따른 문제 데이터 로드 */
  const loadQuestionData = (partSeq, questionOrder) => {
    /** 문제의 part_seq가 일치하면서 && 동일한 번호의 문제가 있는지 */
    const hasQuestion = apTestEditorReducer.questions.find((question) => question.question_order === questionOrder && question.part_seq === partSeq);

    // 해당하는 문제가 있는 경우
    if (hasQuestion) {
      editorStateSetter(EDITOR_CONSTANT.currentQuestion, hasQuestion); // 문제 정보 세팅

      // 문제 보기 정보 세팅
      editorStateSetter(EDITOR_CONSTANT.answerChoiceInfo, {
        // 🧰 기획 간소화 및 모드 변경 불가하게 설정
        // currentMode: ANSWER_CHOICE_CONSTANT.SINGLE,
        // [ANSWER_CHOICE_CONSTANT.SINGLE]: {
        //   multipleChoiceType: 'Single-select',
        //   correctAnswer: [null],
        // },
        // [ANSWER_CHOICE_CONSTANT.MULTIPLE]: {
        //   multipleChoiceType: 'Multi-select',
        //   noOfCorrectAnswer: 1,
        //   correctAnswer: [null],
        // },
        answerChoices: transformChoicesToFrontend(hasQuestion.answer_options),
      });
    } else {
      addNewQuestion();
    }
  };
  /** 섹션/파트별 문제 수 검증 및 이동 함수 */
  const validateQuestionsCount = (targetQuestionData) => {
    // 섹션과 파트 정보를 하나의 배열로 평탄화 & 각 섹션, 파트 객체로 반환
    const sectionPartPairs = apTestEditorReducer.sectionMetadata.sections.flatMap((section) => section.parts.map((part) => ({ section, part })));
    // 각 섹션, 파트별 문제 데이터 조회 & 추가
    const partsWithQuestions = sectionPartPairs.map(({ section, part }) => ({
      section,
      part,
      partQuestions: targetQuestionData.filter((q) => q.part_seq === part.part_seq),
    }));

    /** 문제 수가 부족한 첫 번째 파트 */
    const invalidPart = partsWithQuestions.find(({ part, partQuestions }) => partQuestions.length < part.number_of_questions);

    // 부족한 파트가 있으면 해당 위치로 이동
    if (invalidPart) {
      const { section, part, partQuestions } = invalidPart;
      const missingCount = part.number_of_questions - partQuestions.length;

      // 에디터 상태 업데이트
      editorStateUpdater(EDITOR_CONSTANT.currentSection, section);
      editorStateUpdater(EDITOR_CONSTANT.currentPart, part);

      // 비어있는 문제 번호 찾아서 이동
      const missingQuestionOrder = findMissingQuestionOrder(partQuestions, part.number_of_questions);
      editorStateSetter(EDITOR_CONSTANT.currentIndex, missingQuestionOrder);

      console.log(`${section.section_name} - ${part.name} ${missingCount}개 부족`);

      return false;
    }

    return true;
  };
  /** 비어있는 문제 번호 찾기 */
  const findMissingQuestionOrder = (questions, requiredCount) => {
    const existingOrders = new Set(questions.map((question) => question.question_order));

    // 0부터 requiredCount까지 순회하면서 비어있는 첫 번째 번호 찾기
    const firstMissingOrder = Array.from({ length: requiredCount }).findIndex((_, index) => !existingOrders.has(index));

    // 비어있는 번호가 없으면 questions.length 반환
    return firstMissingOrder === -1 ? questions.length : firstMissingOrder;
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  return {
    // ap테스트 api
    getTestMetadata,
    getEditingTestData,
    postApTestData,
    // State
    editorState,
    // State handler
    editorStateSetter,
    editorStateUpdater,
    editorStateMutator,
    // helper functions
    getCurrentPartQuestion,
    loadQuestionData,
    validateQuestionsCount,
  };
}
