import React from 'react';
import styled from 'styled-components';
import TotalScaledScoreArea from './_components/TotalSectionArea';
import SectionScaledScoreArea from './_components/SectionArea';

/** ap/com/score/grading/submission 페이지의 "전체 등급 점수 영역" 컴포넌트 */
export default function ScaledScoreArea() {
  return (
    <S.Wrap>
      <SectionScaledScoreArea />
      <SectionScaledScoreArea />
      <TotalScaledScoreArea />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    width: 22.375rem;
    height: fit-content;
    border-radius: 0.625rem;
    border: 1px solid #c5ccd2;
    background: #f8f8f9;
    overflow: hidden;
    .section_scaled_score_area {
      & + .section_scaled_score_area {
        .inner_wrap {
          border-top: 1px solid #c5ccd2;
        }
      }
    }
  `,
};
