import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

import 'styles/css/exam.css';
import PassageAreaJYT from './PassageAreaJYT';

const ExamEnPsk = ({ setExamMain, examComponents, setExamComponents, currentQuestion, setCurrentQuestion }) => {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const [isDragged, setIsDragged] = useState(false);
  const [annotateName, setAnnotateName] = useState('');
  const annotateInputRef = useRef('');
  const [annotateMemo, setAnnotateMemo] = useState('');
  const [annotationId, setAnnotationId] = useState('');
  const [isClickAnnotate, setIsClickAnnotate] = useState(false);

  const [isUndoActive, setUndoActive] = useState(false);
  const [bookmarkYn, setBookmarkYn] = useState(false);
  const [helpItem, setHelpItem] = useState({
    item1: false,
    item2: false,
    item3: false,
    item4: false,
    item5: false,
    item6: false,
    item7: false,
    item8: false,
    item9: false,
    item10: false,
    item11: false,
    item12: false,
    item13: false,
  });

  const examDivRef = useRef();

  const bookmarkChangeHandler = (e) => {
    //console.log("target 확인 : ", e.target, e.target.value);

    setBookmarkYn(!bookmarkYn);
  };

  const radioAnswerChangeHandler = (e, no) => {
    //console.log("target 확인 : ", e.target, e.target.value);
  };

  const handleUndoButtonClick = () => {
    setUndoActive(!isUndoActive);
  };

  useEffect(() => {
    document.body.classList.add('scroll_lock');

    return () => {
      document.body.classList.remove('scroll_lock');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ------------------------------------------

  const [wrap, setWrap] = useState({
    leftArrow: false,
    rightArrow: false,
  });

  const moreRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (moreRef.current && !moreRef.current.contains(event.target) && examDivRef.current && !examDivRef.current.contains(event.target)) {
        setExamComponents((prev) => ({ ...prev, moreDisplay: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setExamComponents]);

  const exitExam = () => {
    // navigate('/main')
  };

  const wrapButtonHandler = (arrow) => {
    if (arrow === 'left') {
      if (wrap.leftArrow)
        setWrap((prev) => {
          return { ...prev, leftArrow: false };
        });
      else {
        if (!wrap.leftArrow && wrap.rightArrow)
          setWrap((prev) => {
            return { ...prev, rightArrow: false };
          });
        else
          setWrap((prev) => {
            return { ...prev, leftArrow: !wrap.leftArrow };
          });
      }
    } else {
      if (wrap.rightArrow)
        setWrap((prev) => {
          return { ...prev, rightArrow: false };
        });
      else {
        if (!wrap.rightArrow && wrap.leftArrow)
          setWrap((prev) => {
            return { ...prev, leftArrow: false };
          });
        else
          setWrap((prev) => {
            return { ...prev, rightArrow: !wrap.rightArrow };
          });
      }
    }
  };

  const helpTitleClick = (index) => {
    const item = `item${index}`;
    setHelpItem((prev) => {
      return { ...prev, [item]: !helpItem[item] };
    });
  };

  const helpExpandAll = () => {
    setExamComponents((prev) => {
      return { ...prev, helpItemDisplay: true };
    });
  };

  const helpCollapseAll = () => {
    setExamComponents((prev) => {
      return { ...prev, helpItemDisplay: false };
    });
    setHelpItem((prev) => Array.from({ length: Object.keys(prev).length }, () => false));
  };

  const annotaionClickHandler = (e) => {
    if (!isDragged) {
      setExamComponents((prev) => {
        return { ...prev, annotationEmptyDisplay: true };
      });
      return;
    }
    setExamComponents((prev) => {
      return { ...prev, annotationEmptyDisplay: false };
    });
    setExamComponents((prev) => {
      return { ...prev, annotationDisplay: true };
    });

    // 블럭 지정된 게 없을 때
    // annotationEmptyDisplay true
  };

  return (
    <>
      <div className={`com_popup pop_exam active ${examComponents.directionDisplay ? 'direction_on' : ''} `}>
        {/*addClass 'active' 시 노출*/} {/*addClass 'direction_on' 시 Directions 레이어 노출*/} {/*계산기 노출 시 addClass 'calculator_on'*/}
        <div className='pop_container'>
          <section ref={examDivRef} className='pop_header'>
            <div className='tit_area'>
              <div className='tit'>미리보기</div>
              <button
                className='btn'
                onClick={() =>
                  setExamComponents((prev) => {
                    return {
                      ...prev,
                      directionDisplay: !examComponents.directionDisplay,
                    };
                  })
                }>
                Directions<i className='svg_icon icon_arrow black'>&nbsp;</i>
              </button>
              {examComponents.directionDisplay && (
                <DirectionsPopup setExamComponents={setExamComponents} subject={currentQuestion.questionSubject} examDivRef={examDivRef} />
              )}
            </div>
            <div className={`time_area ${examComponents.timeAreaDisplay ? '' : 'hide'}`}>
              {/*btn_time 클릭 시 addClass 'hide'*/}
              <div className='time'>00 : 00</div>
              <button
                className='btn_time'
                onClick={() =>
                  setExamComponents((prev) => {
                    return {
                      ...prev,
                      timeAreaDisplay: !examComponents.timeAreaDisplay,
                    };
                  })
                }>
                Hide
              </button>
            </div>
            <div className='btn_area'>
              <button className={`btn ${examComponents.annotationDisplay ? 'active gray' : ''}`} onClick={(e) => annotaionClickHandler(e)}>
                <i className='svg_icon icon_annotate'>&nbsp;</i>
                Annotate
                {examComponents.annotationEmptyDisplay && (
                  <div className='tip_layer'>
                    <div className='tit'>MAKE A SELECTION FIRST</div>
                    Select some text, then press annotate.
                  </div>
                )}
              </button>
              <button
                className={`btn ${examComponents.moreDisplay ? 'active' : ''}`}
                onClick={() => {
                  setExamComponents((prev) => {
                    return {
                      ...prev,
                      moreDisplay: !examComponents.moreDisplay,
                    };
                  });
                }}>
                {/*클릭 시 addClass 'active'*/}
                <i className='svg_icon icon_more'>&nbsp;</i>
                More
              </button>
              {examComponents.moreDisplay && (
                <div className='more_layer' ref={moreRef}>
                  <button
                    className='item'
                    onClick={() =>
                      setExamComponents((prev) => {
                        return { ...prev, helpDisplay: true };
                      })
                    }>
                    <i className='svg_icon icon_help'>&nbsp;</i>Help
                  </button>
                  <button
                    className='item'
                    onClick={() =>
                      setExamComponents((prev) => {
                        return { ...prev, exitExamDisplay: true };
                      })
                    }>
                    <i className='svg_icon icon_exit'>&nbsp;</i>Exit the Exam
                  </button>
                </div>
              )}
            </div>
          </section>
          <section className={`pop_body ${wrap.leftArrow !== wrap.rightArrow ? 'change_wrap_width' : ''}`}>
            {' '}
            {/*btn_wrap 클릭 시 addClass " change_wrap_width"*/}
            <EnglishQuestion
              examComponents={examComponents}
              setExamComponents={setExamComponents}
              currentQuestion={currentQuestion}
              wrap={wrap}
              setWrap={setWrap}
              wrapButtonHandler={wrapButtonHandler}
              setIsDragged={setIsDragged}
              setAnnotateName={setAnnotateName}
              annotateName={annotateName}
              setAnnotateMemo={setAnnotateMemo}
              annotateMemo={annotateMemo}
              annotationId={annotationId}
              setAnnotationId={setAnnotationId}
              annotateInputRef={annotateInputRef}
              setIsClickAnnotate={setIsClickAnnotate}
            />
            <article className={`wrap ${isUndoActive ? 'undo_active' : ''} ${wrap.rightArrow ? 'big' : ''}`}>
              {/*btn_undo 클릭 시 addClass 'undo_active'*/}
              <button className='btn_wrap svg_icon icon_wrap right' onClick={() => wrapButtonHandler('right')}>
                &nbsp;
              </button>
              <div className='scroll_wrap'>
                <div className='inner'>
                  <div className='top_area'>
                    <div className='num'>0</div>
                    <div className='bookmark'>
                      <input
                        type='checkbox'
                        id='bookmark1'
                        className='btn_bookmark'
                        value={bookmarkYn}
                        onChange={(e) => {
                          bookmarkChangeHandler(e);
                        }}
                        checked={currentQuestion?.bookMark === 'Y'}
                      />
                      <label htmlFor='bookmark1'>Mark for Review</label>
                    </div>
                    {/*주관식에서 비노출*/}
                    {currentQuestion?.questionFormat !== 'S' && (
                      <button className={`btn_undo ${isUndoActive ? 'undo_active' : ''}`} onClick={handleUndoButtonClick}>
                        <i className='svg_icon icon_undo'>&nbsp;</i>
                        <div className='tip_layer'>Cross out answer choices you think are wrong.</div>
                      </button>
                    )}
                  </div>
                  <div className='text'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentQuestion.question.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
                      }}
                    />
                  </div>
                  <div id='answerArea' className='answer_area'>
                    <div className={nvl(currentQuestion?.underLine1) !== 'Y' ? 'item' : 'item undo_selected'}>
                      <input
                        type='radio'
                        name='answer1'
                        id='answer1_1'
                        className='radio_answer'
                        value='A'
                        onChange={(e) => radioAnswerChangeHandler(e, 1)}
                        checked={currentQuestion?.tryAnswer === 'A'}
                      />
                      <label className='answer' htmlFor='answer1_1'>
                        <span className='num'>A</span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentQuestion?.multipleChoiceItems1,
                          }}
                        />
                      </label>
                    </div>

                    <div className={nvl(currentQuestion?.underLine2) !== 'Y' ? 'item' : 'item undo_selected'}>
                      <input
                        type='radio'
                        name='answer1'
                        id='answer1_2'
                        className='radio_answer'
                        value='B'
                        onChange={(e) => radioAnswerChangeHandler(e, 2)}
                        checked={currentQuestion?.tryAnswer === 'B'}
                      />
                      <label className='answer' htmlFor='answer1_2'>
                        <span className='num'>B</span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentQuestion?.multipleChoiceItems2,
                          }}
                        />
                      </label>
                    </div>

                    <div className={nvl(currentQuestion?.underLine3) !== 'Y' ? 'item' : 'item undo_selected'}>
                      <input
                        type='radio'
                        name='answer1'
                        id='answer1_3'
                        className='radio_answer'
                        value='C'
                        onChange={(e) => radioAnswerChangeHandler(e, 3)}
                        checked={currentQuestion?.tryAnswer === 'C'}
                      />
                      <label className='answer' htmlFor='answer1_3'>
                        <span className='num'>C</span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentQuestion?.multipleChoiceItems3,
                          }}
                        />
                      </label>
                    </div>

                    <div className={nvl(currentQuestion?.underLine4) !== 'Y' ? 'item' : 'item undo_selected'}>
                      <input
                        type='radio'
                        name='answer1'
                        id='answer1_4'
                        className='radio_answer'
                        value='D'
                        onChange={(e) => radioAnswerChangeHandler(e, 4)}
                        checked={currentQuestion?.tryAnswer === 'D'}
                      />
                      <label className='answer' htmlFor='answer1_4'>
                        <span className='num'>D</span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentQuestion?.multipleChoiceItems4,
                          }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>

          <section className='pop_footer'>
            <div className='name'>미리보기</div>
          </section>
          {(isClickAnnotate || (examComponents.annotationDisplay && isDragged)) && (
            <AnnotationBottom
              examComponents={examComponents}
              setExamComponents={setExamComponents}
              annotationDisplay={examComponents.annotationDisplay}
              setIsClickAnnotate={setIsClickAnnotate}
              annotateName={annotateName}
              annotateInputRef={annotateInputRef}
              annotateMemo={annotateMemo}
              setAnnotateMemo={setAnnotateMemo}
              setIsDragged={setIsDragged}
              annotationId={annotationId}
              setAnnotateName={setAnnotateName}
              setAnnotationId={setAnnotationId}
            />
          )}
        </div>
      </div>
      {/*help 팝업*/}
      {examComponents.helpDisplay && (
        <div className={`com_popup type_dim pop_exam_help ${examComponents.helpDisplay ? 'active' : ''}`}>
          {/*addClass 'active' 시 노출*/}
          <div className='pop_container xl'>
            <div className='pop_header'>
              Help
              <button
                className='svg_icon btn_pop_close'
                onClick={() =>
                  setExamComponents((prev) => {
                    return { ...prev, helpDisplay: false };
                  })
                }>
                &nbsp;
              </button>
              <div className='btn_area'>
                <button className='btn' onClick={helpExpandAll}>
                  Expand All
                </button>
                <button className='btn' onClick={helpCollapseAll}>
                  Collapse All
                </button>
              </div>
            </div>
            <div className='pop_body'>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item1 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(1)}>
                  Zoom In/Out
                </div>
                <div className='info_field'>
                  Use keyboard shortcuts to zoom in or out on laptops and pinch to zoom on tablets. <br />
                  Windows and Chromebooks: <b>Control + Plus (+)</b> to zoom in and <b>Control + Minus (-)</b> to zoom out. <br />
                  Mac: <b>Command + Plus (+)</b> to zoom in and <b>Command + Minus (-)</b> to zoom out.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item2 ? 'active' : ''}`}>
                {/*클릭 시 addClass 'active' info_field 노출*/}
                <div className='tit_field' onClick={() => helpTitleClick(2)}>
                  In-App Calculator
                </div>
                <div className='info_field'>
                  <i className='svg_icon icon_calculator'>&nbsp;</i>
                  <b>Calculator:</b>On math questions, you will have access to graphing calculator built into the webpage. You can also use your own approved
                  caclulator.{' '}
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item3 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(3)}>
                  Testing Timers
                </div>
                <div className='info_field'>
                  During testing, a timer will let you know much time remains for each module or part of the test. <br />
                  <br />
                  At the end of section 1, you'll have a short break. During the break, we'll show you a timer counting down to the beginning of section 2.{' '}
                  <br />
                  <br />
                  When time runs out on section 2, the test will end, and your answers will be automatically submitted.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item4 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(4)}>
                  Annotate Tool
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item5 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(5)}>
                  Option-Eliminator
                </div>
                <div className='info_field'>
                  <i className='svg_icon icon_undo'>&nbsp;</i>
                  <b>Option Eliminator:</b>
                  On any multiple-choice question, you can cross out answer options you think are wrong. Above the answer, click the <b>ABC</b> button, then
                  you'll see a button next to each answer option, allowing you to cross it out. You can always undo this action.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item6 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(6)}>
                  Mark for Review
                </div>
                <div className='info_field'>
                  <i className='svg_icon icon_bookmark_off'>&nbsp;</i>
                  <b>Mark for Review:</b>Flag any questions you want to come back later.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item7 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(7)}>
                  Question Menu
                </div>
                <div className='info_field'>
                  At the bottom of each testing screen, you'll find a menu that allows you to navigate to any question in the section. You'll see which
                  questions you've answered, which you haven't, and any that you've marked for review.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item8 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(8)}>
                  No penalty for Guessing
                </div>
                <div className='info_field'>Points are never deducted for wrong answer. So, it's always better to guess than to leave an answer blank.</div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item9 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(9)}>
                  Reloading the Webpage
                </div>
                <div className='info_field'>
                  If your webpage crashes during your exam, just reload the webpage to get back to the question you were on. The testing timer will not be
                  paused, however. Same applies if you accidentally close the webpage; just relaunch the webpage. In case you exit the exam through the{' '}
                  <b>Exit</b> button, you will be graded on what you have solved until that point and will not be able to return to the exam.
                </div>
              </div>
              <div className={`item ${examComponents.helpItemDisplay || helpItem.item10 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(10)}>
                  Submitting Your Answers
                </div>
                <div className='info_field'>
                  During testing, the app will save all your work. When the exam is over, your answers will be submitted automatically. If you're still working
                  when time expires, your responses will be automatically saved and submitted. If your device is offline or your submission fails for any
                  reason, you'll have time to reconnect and submit your work.
                </div>
              </div>

              <div className={`item ${examComponents.helpItemDisplay || helpItem.item13 ? 'active' : ''}`}>
                <div className='tit_field' onClick={() => helpTitleClick(13)}>
                  Screen Magnification
                </div>
                <div className='info_field'>
                  Screen magnification technology enlarges the content displayed on the computer screen in order to assist students with visual impairments.
                  Although the exam app supports some non-embedded screen magnifier tools from third parties, all users can zoom using their device's native
                  controls (Control +/- on a PC; Command +/- on a Mac; pinch and zoom on an iPad).
                </div>
              </div>
            </div>
            <div className='pop_footer'>
              <button
                className='com_btn m yellow oval'
                onClick={() =>
                  setExamComponents((prev) => {
                    return { ...prev, helpDisplay: false };
                  })
                }>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/*exit 팝업*/}
      {examComponents.exitExamDisplay && (
        <div className='com_popup type_alert active'>
          {/*addClass 'active' 시 노출*/}
          <div className='pop_container'>
            <button className='svg_icon btn_pop_close'>&nbsp;</button>
            <div className='pop_body'>
              <div className='alert_title'>Are You Sure You Want to Exit the Preview?</div>
              <div className='alert_text'>
                Test Previews are not timed or scored, and your progress isn't saved. If you exit and come back, you'll start over.
              </div>
            </div>
            <div className='pop_footer com_btn_wrap'>
              <button className='com_btn m gray oval'>Continue Test Preview</button>
              <button className='com_btn m yellow oval'>Close</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// Directions
const DirectionsPopup = ({ setExamComponents, subject, examDivRef }) => {
  const directionRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (directionRef.current && !directionRef.current.contains(event.target) && examDivRef.current && !examDivRef.current.contains(event.target)) {
        setExamComponents((prev) => ({ ...prev, directionDisplay: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setExamComponents, examDivRef]);

  return (
    <article ref={directionRef} className='direction_layer layer'>
      <div className='scroll'>
        The questions in this section address a number of inportant reading and writing skills. Each question includes one or more passages, which may include a
        table or grahp. Read each passage and question carefully, and then choose the best answer to the question based on the passage(s).
        <br />
        All questions in this wection are multiple-choice with four answer choices. Each question has a single best answer.
      </div>
      <div className='btn_field'>
        <button
          className='btn close'
          onClick={() =>
            setExamComponents((prev) => {
              return { ...prev, directionDisplay: false };
            })
          }>
          Close
        </button>
      </div>
    </article>
  );
};

const EnglishQuestion = ({
  setAnnotateMemo,
  setIsClickAnnotate,
  annotationId,
  setAnnotationId,
  examComponents,
  setExamComponents,
  currentQuestion,
  wrap,
  setWrap,
  wrapButtonHandler,
  highlighted,
  setIsDragged,
  setAnnotateName,
  annotateName,
  annotateMemo,
}) => {
  const [passage, setPassage] = useState(
    currentQuestion.passage.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`)
  );

  const restrictedWords = ['emphasized', 'importance of safeguarding'];

  const onChangeHandler = (event) => {
    console.log('onChangeHandler', event);
  };

  const onKeyDownHandler = (event) => {
    console.log('onKeyDownHandler', event);
  };

  const handleMouseUp = () => {
    const selection = window.getSelection();

    const range = selection.getRangeAt(0);

    let startIndex = range.startOffset;
    let endIndex = range.endOffset;

    // if(startIndex > endIndex) {
    //   startIndex = range.endOffset;
    //   endIndex = range.startOffset;
    // }

    // let userSelection = selection.toString();

    // const beforeSelection = passage.substring(0, startIndex);
    // const afterSelection = passage.substring(endIndex, passage.length);

    // console.log("이거 이거", beforeSelection, afterSelection, userSelection)

    // setPassage(`${beforeSelection}<span class='annotate up active'>${userSelection.toString()}</span>${afterSelection}`)

    // currentStringHighlight()
  };

  // ---------------------------------------------------

  //   const currentStringHighlight = () => {
  //       // get user's selection
  //       const selection = window.getSelection();
  //       // get the start and end index of selection
  //       // remember user can select backwards
  //       let startIndex = selection.anchorOffset;
  //       let endIndex = selection.focusOffset;

  //       if(startIndex > endIndex) {
  //         startIndex = selection.focusOffset;
  //         endIndex = selection.anchorOffset;
  //       }
  //       // now get the text before and after the selection
  //       const beforeSelection = passage.substring(0, startIndex);
  //       const afterSelection = passage.substring(endIndex, passage.length);

  //       // and set the html as you see fit
  //       setPassage(`${beforeSelection}<span className='annotate up active'>${selection.toString()}</span>${afterSelection}`)
  // };

  return (
    <article className={`wrap ${wrap.leftArrow ? 'big' : ''}`}>
      {' '}
      {/*icon_wrap 클릭 시 addClass "big"*/}
      <button className='btn_wrap svg_icon icon_wrap left' onClick={() => wrapButtonHandler('left')}>
        &nbsp;
      </button>
      <div className='scroll_wrap'>
        <div className='inner'>
          <PassageAreaJYT
            examComponents={examComponents}
            setExamComponents={setExamComponents}
            passage={passage}
            highlighted={restrictedWords}
            setIsDragged={setIsDragged}
            annotateName={annotateName}
            setAnnotateName={setAnnotateName}
            annotateMemo={annotateMemo}
            setAnnotateMemo={setAnnotateMemo}
            annotationId={annotationId}
            setAnnotationId={setAnnotationId}
            setIsClickAnnotate={setIsClickAnnotate}
          />
        </div>
      </div>
    </article>
  );
};

const AnnotationBottom = ({
  setAnnotationId,
  setAnnotateName,
  annotationId,
  setIsDragged,
  setIsClickAnnotate,
  annotateMemo,
  setAnnotateMemo,
  annotateInputRef,
  setExamComponents,
  annotationDisplay,
  annotateName,
}) => {
  const [annontateDelete, setAnnontateDelete] = useState(false);

  return (
    <>
      <section className='annotate_sec'>
        <article className='top'>
          <div className='txt'>
            <b>New Annotation:</b> "{annotateName}"
          </div>
          <button
            className='btn_layer_close'
            onClick={() => {
              const selectedAnnotations = document.querySelectorAll(`span[data-annotation-id="${annotationId}"][data-text="${annotateName}"]`);

              selectedAnnotations.forEach((el) => {
                if (annotateMemo.length === 0) {
                  const parent = el.parentNode;
                  const textNode = document.createTextNode(el.textContent);
                  parent.replaceChild(textNode, el);
                } else {
                  el.classList.remove('rangy-highlight-selected');
                }
              });

              setExamComponents((prev) => {
                return { ...prev, annotationDisplay: false };
              });
              setIsDragged(false);
              setIsClickAnnotate(false);
              setAnnotateMemo('');
              setAnnotationId('');
              setAnnotateName('');
            }}>
            CLOSE<i className='svg_icon btn_pop_close white'></i>
          </button>
        </article>
        {
          <>
            {!annontateDelete ? (
              <article className='contents'>
                <ul className='info'>
                  <li className='item'>
                    Highlight Color: <i className='svg_icon icon_highlight'>&nbsp;</i>
                  </li>
                  <li className='item'>
                    Underline style: <i className='svg_icon icon_underline'>&nbsp;</i>
                  </li>
                </ul>
                <textarea className='textarea' ref={annotateInputRef} defaultValue={annotateMemo}></textarea>
                <button
                  className='btn'
                  onClick={() => {
                    setExamComponents((prev) => {
                      return { ...prev, annotationDisplay: false };
                    });

                    const highlightedElements = document.querySelectorAll('.rangy-highlight-selected');
                    highlightedElements.forEach((el) => {
                      el.setAttribute('data-memo', annotateInputRef.current.value);
                      el.classList.remove('rangy-highlight-selected');
                    });
                  }}>
                  Save
                </button>
                {annotateMemo === '' ? (
                  <button
                    className='btn cancel'
                    onClick={() => {
                      const highlightedElements = document.querySelectorAll('.rangy-highlight-selected');
                      highlightedElements.forEach((el) => {
                        const parent = el.parentNode;
                        const textNode = document.createTextNode(el.textContent);
                        parent.replaceChild(textNode, el);
                      });
                      setExamComponents((prev) => {
                        return { ...prev, annotationDisplay: false };
                      });
                      setIsDragged(false);
                      setIsClickAnnotate(false);
                    }}>
                    Cancel
                  </button>
                ) : (
                  <button
                    className='btn del'
                    onClick={() => {
                      setAnnontateDelete(!annontateDelete);
                    }}>
                    <i className='svg_icon icon_trashcan before'>&nbsp;</i>
                    Delete
                  </button>
                )}
                {/*1130 수정,상세화면에서만 노출*/}
              </article>
            ) : (
              <article className='contents notice'>
                <div className='tit'>Are You Sure You Want To Delete This Annotation?</div>
                <div className='txt'>Once you delete this annotation, any notes you've made will no longer be abilable.</div>
                <div className='com_btn_wrap bottom'>
                  <button
                    className='btn keep'
                    onClick={() => {
                      const highlightedElements = document.querySelectorAll('.rangy-highlight-selected');
                      highlightedElements.forEach((el) => {
                        el.classList.remove('rangy-highlight-selected');
                      });
                      setExamComponents((prev) => {
                        return { ...prev, annotationDisplay: false };
                      });
                      setIsDragged(false);
                      setIsClickAnnotate(false);
                    }}>
                    Keep Annotation
                  </button>
                  <button
                    className='btn yellow'
                    onClick={() => {
                      const highlightedElements = document.querySelectorAll('.rangy-highlight-selected');
                      highlightedElements.forEach((el) => {
                        const parent = el.parentNode;
                        const textNode = document.createTextNode(el.textContent);
                        parent.replaceChild(textNode, el);
                      });
                      setExamComponents((prev) => {
                        return { ...prev, annotationDisplay: false };
                      });
                      setIsDragged(false);
                      setIsClickAnnotate(false);
                      setAnnotateMemo('');
                      setAnnotationId('');
                      setAnnotateName('');
                    }}>
                    Delete Annotation
                  </button>
                </div>
              </article>
            )}
          </>
        }
      </section>
    </>
  );
};

export default ExamEnPsk;
