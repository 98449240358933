import useOutsideClick from 'hooks/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import LoadingBar from 'utils/LoadingBar';

import Timer from './Timer';
import DirectionsPopupBtn from './_components/directionsPopup/DirectionsPopupBtn';

import localStorage from 'utils/LocalStorage.utils';
import CalculatorPopup from './_components/calculatorPopup/CalculatorPopup';
import ApReferenceSheetPopup from './_components/referenceSheet/ApReferenceSheetPopup';
import LineReaderPopup from './_components/lineReaderPopup/LineReaderPopup';

import { ReactComponent as ImgSvgIconLineReader } from 'assets/img/svg/exam-icon/icon_line_reader.svg';

import { SUBJECT_SEQUENCE } from './_components/referenceSheet/_constant';

/** ap시험 화면 Header 영역 컴포넌트 */
export default function ExamHeader({
  stateExamInfo,
  setStateExamInfo,
  isDirectionsPopup,
  setIsDirectionsPopup,
  setIsExitModal,
  setIsHelpModal,
  isAnnotation,
  isDragged,
  handleToggleAnnotation,
}) {
  const [isTimerHide, setIsTimerHide] = useState(false);

  const [isMore, setIsMore] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isShowRefSheet, setIsShowRefSheet] = useState(false);
  const [isShowCalculator, setIsShowCalculator] = useState(false);
  const [isShowLineReader, setIsShowLineReader] = useState(false);

  const moreRef = useRef(null);
  useOutsideClick(moreRef, () => setIsMore(false), isMore);

  // 현재 part 정보
  const [partInfo, setPartInfo] = useState({
    sectionSeq: 0,
    sectionNo: 0,
    partSeq: 0,
    partNo: 0,
    partCount: 0,
    breakTime: 0,
    partBreakTime: 0,
    partDirections: null,
    partCalculate: true,
    referenceSheet: true,
  });

  const [testInfo, setTestInfo] = useState({
    testSetSeq: 0, // number    => 시험 세트 seq
    testSetName: '', // string  => 시험 세트 이름
    subjectSeq: '0', // string  => 과목 seq
    subjectName: '', // string  => 과목 이름
    sectionCount: 0, // number  => 섹션 갯수
  });

  useEffect(() => {
    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;

    const examInfo = localStorage.getItemJsonParse(`apTest_${uthSeq}`);
    const testInfo = examInfo.testInfo;
    const partSeq = examInfo.partSeq;

    const currentIndex = examInfo.testInfo.partInfo.findIndex((item) => item.partSeq === examInfo.partSeq);

    const currentPartInfo = examInfo.testInfo.partInfo[currentIndex];
    setPartInfo(currentPartInfo);

    const { partInfo, ...testInfoWithoutPartInfo } = examInfo.testInfo;
    setTestInfo(testInfoWithoutPartInfo); // 파트정보를 제외한 시험 정보 업데이트

    setIsDirectionsPopup(currentPartInfo.partDirections !== null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const annotationEmptyTooltipRef = useRef(null);

  useOutsideClick(annotationEmptyTooltipRef, handleCloseAnnotationEmptyTooltip);

  // 하이라이트 버튼 클릭 핸들러
  const onAnnotateButtonClick = () => {
    // 하이라이트 모드가 아니고 드래그된 텍스트가 없으면 팁 레이어 표시
    if (!isAnnotation && !isDragged) {
      setStateExamInfo((prev) => ({
        ...prev,
        annotationEmptyDisplay: true,
      }));
    } else {
      // 그렇지 않으면 하이라이트 토글
      handleToggleAnnotation();
    }
  };

  function handleCloseAnnotationEmptyTooltip() {
    if (stateExamInfo.annotationEmptyDisplay) {
      setStateExamInfo((prev) => ({
        ...prev,
        annotationEmptyDisplay: false,
      }));
    }
  }

  return (
    <>
      <section className='pop_header'>
        <div className='tit_area'>
          <div className='tit'>
            {stateExamInfo.sectionName} {stateExamInfo.partName}
          </div>

          {partInfo.partDirections !== null && (
            <DirectionsPopupBtn
              isShowDirectionsPopup={isDirectionsPopup}
              setIsShowDirectionsPopup={setIsDirectionsPopup}
              directionContent={partInfo.partDirections}
            />
          )}
        </div>

        <div className={`time_area ${isTimerHide ? 'hide' : ''}`}>
          <div className={`time ${stateExamInfo.isUnderFiveMinutes ? 'end' : ''}`}>
            <Timer variant='exam' setStateExamInfo={setStateExamInfo} />
          </div>
          <button className='btn_time' onClick={() => setIsTimerHide((prev) => !prev)}>
            Hide
          </button>
        </div>

        <div className='btn_area' ref={moreRef}>
          <button className={`btn ${isAnnotation && isDragged ? 'active' : ''}`} onClick={onAnnotateButtonClick}>
            <i className='svg_icon icon_annotate'>&nbsp;</i>
            Annotate
            {stateExamInfo.annotationEmptyDisplay && (
              <div className='tip_layer' ref={annotationEmptyTooltipRef}>
                <div className='tit'>MAKE A SELECTION FIRST</div>
                Select some text, then press annotate.
              </div>
            )}
          </button>
          {partInfo.partCalculate && (
            <button className={`btn ${isShowCalculator ? 'active' : ''}`} onClick={() => setIsShowCalculator((prev) => !prev)}>
              <i className='svg_icon icon_calculator'>&nbsp;</i>
              Calculator
            </button>
          )}
          {partInfo.referenceSheet && (
            <button className={`btn ${isShowRefSheet ? 'active' : ''}`} onClick={() => setIsShowRefSheet((prev) => !prev)}>
              <i className='svg_icon icon_reference'>&nbsp;</i>
              Reference
            </button>
          )}
          <button
            className={`btn`}
            onClick={() => {
              setIsMore((prev) => !prev);
            }}>
            <i className='svg_icon icon_more'>&nbsp;</i>
            More
          </button>
          {isMore && (
            <div className='more_layer'>
              <button
                className='item'
                onClick={() => {
                  setIsMore(false);
                  setIsHelpModal(true);
                }}>
                <i className='svg_icon icon_help'>&nbsp;</i>Help
              </button>
              <button
                className='item'
                onClick={() => {
                  setIsMore(false);
                  setIsShowLineReader((prev) => !prev);
                }}>
                <i className='svg_icon'>
                  <ImgSvgIconLineReader />
                </i>
                Line Reader
              </button>
              <button
                className='item'
                onClick={() => {
                  setIsMore(false);
                  setIsExitModal(true);
                }}>
                <i className='svg_icon icon_exit'>&nbsp;</i>Exit the Exam
              </button>
            </div>
          )}
        </div>
      </section>
      {isLoading && <LoadingBar />}

      <ApReferenceSheetPopup isShowRefSheet={isShowRefSheet} setIsShowRefSheet={setIsShowRefSheet} subjectSeq={testInfo.subjectSeq} />
      <CalculatorPopup isShowCalculator={isShowCalculator} setIsShowCalculator={setIsShowCalculator} />
      <LineReaderPopup isShowLineReader={isShowLineReader} setIsShowLineReader={setIsShowLineReader} />
    </>
  );
}
