import React from 'react';
import styled from 'styled-components';

/** "Ap" - "Question Bank" - "Create Test" 페이지의 "Advanced Setting" 에서 사용 할 "Label" 요소 스타일 용 컴포넌트 Type 2 */
export default function LabelText02({ children, ...rest }) {
  return <S.Wrap {...rest}>{children}</S.Wrap>;
}

const S = {
  Wrap: styled.div`
    color: #000;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  `,
};
