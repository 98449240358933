import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

////////// Components
import InsufficientKeyModal from 'components/com/tests/set/review/_components/InsufficientKeyModal';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import useUserLevels from 'hooks/useUserLevels';
import Layout from '../../../_components/_common/Layout';
import MainTitle from '../../_components/title/MainTitle';
import MainTitleArea from '../../_components/title/MainTitleArea';
import SubTitle from '../../_components/title/SubTitle';
import SearchArea from './_components/SearchArea';
import TabButtons, { TAB_DATA } from './_components/TabButtons';
import TestButton from './_components/testButton/TestButton';

/** AP Set Tests : "시험 모듈 선택" 페이지 */
export default function ApTestsSetSelectTestMorePage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const [userInfo, _] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);

  /** "Set Tests 전역 상태" */
  const stateSetTests = useSelector((state) => state.stateSetTests.setTests);
  /** 사용자의 Gate Key 전역 상태 */
  const stateGateKeys = useSelector((state) => state.stateGateKeys);

  const { getObfuscatedValue, setBulkObfuscatedValues } = useObfuscatedSearchParam();
  /** 현재 선택 된 Tab */
  const selectedTab = getObfuscatedValue('tab');
  /** 현재 입력 된 검색어 */
  const selectedSearchKeyword = getObfuscatedValue('search');
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** 게이트 키 부족 시 노출되는 모달 */
  const [showInsufficientKeyModal, setShowInsufficientKeyModal] = useState(false);
  /** 검색어 입력창 문자열 실시간 갱신(& 비교) 용 상태 */
  const [searchInputValue, setSearchInputValue] = useState(selectedSearchKeyword ? selectedSearchKeyword : '');
  const [isB2c, setIsB2c] = useState(null);
  /** API에서 받아온 전체 시험 세트 목록 */
  const [customSetInfo, setCustomSetInfo] = useState({
    list: [],
    // {
    //   testSetSeq: number; 시험 시퀀스
    //   participantUsers: { 시험 참여 중인 사용자
    //    studentName: null
    //    testStatus: "E" | "P" | "I" | "B" // 학생의 시험 진행 상태 ( E - Scheduled, P - Taken, I - In-progress, B - Break Time )
    //    userSeq: null
    //   }[];
    //   participantCount: number; 시험 참여 중인 사용자 수
    //   isLiked: boolean; 현재 시험 좋아요 상태
    //   testSetName: string; 시험 세트 이름
    //   subjectSeq: number; 과목 시퀀스
    //   subjectName: string; 과목 이름
    // }[]
  });

  /** 이 페이지에서 사용자가 실시간으로 선택하는 시험 세트 상태 */
  const [selectTest, setSelectTest] = useState([]);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 시험 목록 얻어오기 API 요청 함수 */
  const getCustomSet = ({ isAcademy }) => {
    const { selectedStudents } = stateSetTests;

    /** API에 전달 할 학생 시퀀스들을 쉽표로 연결한 문자열 */
    const studentsSeqList = selectedStudents.map((item) => item.studentSeq).join(',');

    const successHandler = (response) => {
      if (response.code === 200) {
        const testSetList = response.result.testSetList;

        setCustomSetInfo((prev) => {
          return {
            ...prev,
            list: testSetList,
          };
        });
      }
    };

    // 선생이면 teacherSeq 파라미터를 보내지 않음
    // 어드민이면 teacherSeq 파라미터를 보냄
    // 세선스토리지에서 setTest-teacherSeq를 가져옴
    const teacherSeq = sessionStorage.getItem('setTest-teacherSeq');

    request
      .apGet(`/api/v1/academy/exam/test-set?studentSeqList=${studentsSeqList}${isAcademy ? `&teacherSeq=${teacherSeq}` : ''}`, null, successHandler)
      .catch((error) => console.error(error));
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    if (!useLevelsIsLoading) {
      getCustomSet({ isAcademy: useLevels.isAcademy });
    }
    setBulkObfuscatedValues({ paramValues: { tab: TAB_DATA.ALL.value, search: '' }, replace: true }); // 최초 텝 상태 설정

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLevelsIsLoading]);

  useEffect(() => {
    // 사용자가 시험 세트 선택하는 동작
    const filterTest = [];

    customSetInfo.list.forEach((item) => {
      if (selectTest.includes(item.testSetSeq)) {
        filterTest.push(item);
      }
    });
  }, [customSetInfo, selectTest]);

  useEffect(() => {
    if (!useLevelsIsLoading) {
      // 사용자 유형 분석 로딩이 끝난 후 상태 값 갱신
      setIsB2c(useLevels.isStudent);
    }
  }, [useLevelsIsLoading, useLevels]);

  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <Layout selectTest={selectTest} setShowInsufficientKeyModal={setShowInsufficientKeyModal}>
      <S.Wrap>
        <MainTitleArea>
          <MainTitle>Available Tests</MainTitle>
          <SubTitle>Select one or more tests</SubTitle>
        </MainTitleArea>
        <div className='main_top_area'>
          <TabButtons />
          <SearchArea searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue} customSetInfo={customSetInfo} />
        </div>
        <div className='btn_test__wrap'>
          {isB2c !== null &&
            nvl(customSetInfo.list) !== '' &&
            customSetInfo.list.map((item, index) => {
              /** 텝 조건 */
              const conditionForTab = selectedTab === TAB_DATA.FAVORITES.value ? item.isLiked : true;
              /** 검색어 조건 */
              const conditionForSearchKeyword =
                searchInputValue === null || searchInputValue === '' ? true : item.testSetName.toLowerCase().includes(searchInputValue.toLowerCase());

              return (
                conditionForTab &&
                conditionForSearchKeyword && (
                  <TestButton
                    key={`set_${index}`}
                    isB2c={isB2c}
                    itemData={item}
                    setCustomSetInfo={setCustomSetInfo}
                    selectTest={selectTest}
                    setSelectTest={setSelectTest}
                  />
                )
              );
            })}
        </div>
      </S.Wrap>
      {showInsufficientKeyModal && (
        <InsufficientKeyModal
          requiredKey={Math.abs(Number(stateGateKeys.remainingKey) - Number(stateSetTests.totalGateKey))}
          setShowModal={setShowInsufficientKeyModal}
        />
      )}
    </Layout>
  );
}

const S = {
  Wrap: styled.div`
    .main_top_area {
      border-bottom: 1px solid #eaeaea;
      margin: 1.5rem 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .btn_test__wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;
    }
  `,
};
