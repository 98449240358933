import { useEffect, useState } from 'react';
import styled from 'styled-components';

/**
 * 확장/축소가 가능한 패널 컴포넌트
 * @param {Object} props
 * @param {React.ReactNode} props.children - 패널 내부에 표시될 컨텐츠
 * @param {React.ReactNode} props.panelHeader - 패널의 헤더
 * @param {React.ComponentType} props.ArrowIcon - 확장/축소 아이콘 컴포넌트
 * @param {boolean} props.defaultExpanded - 초기 확장 상태 (기본값: false)
 * @param {string} props.className - 최상위 div에 전달될 클래스 이름
 */
export default function UI_ExpandablePanel({ children, panelHeader = <p>Panel Header</p>, ArrowIcon = null, defaultExpanded = false, className = '' }) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const DefaultArrowIcon = () => <i className='svg_icon icon_select gray'>&nbsp;</i>;

  const IconComponent = ArrowIcon || DefaultArrowIcon;

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <div className={className}>
      <S.PanelHeader onClick={() => setIsExpanded((prev) => !prev)} className='panel_header'>
        {panelHeader}
        <S.Arrow $isExpanded={isExpanded}>
          <IconComponent isExpanded={isExpanded} />
        </S.Arrow>
      </S.PanelHeader>
      <S.PanelContent $isExpanded={isExpanded} className='panel_content'>
        {children}
      </S.PanelContent>
    </div>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  PanelHeader: styled.div`
    cursor: pointer;

    &:hover {
      filter: brightness(95%);
    }
  `,

  Arrow: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    transform: ${(props) => (props.$isExpanded ? 'rotate(0deg)' : 'rotate(180deg)')};
  `,

  PanelContent: styled.div`
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.$isExpanded ? 1 : 0)};
    max-height: ${(props) => (props.$isExpanded ? '3000px' : '0')};
    overflow: hidden;
    visibility: ${(props) => (props.$isExpanded ? 'visible' : 'hidden')};
  `,
};
