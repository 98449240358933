//#region    ////////// packages
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import ReactQuillEditor from 'components/_common/test-editor/quilljs/ReactQuillEditor';
import QuillEditorContainer from 'components/ap/_components/common/QuillEditorContainer';
import AnnotationBottom from './AnnotationBottom';
import HybridQuestionArea from './HybridQuestionArea';
import Tooltip from './Tooltip';
//#endregion ////////// components

//#region    ////////// utils & hooks
import { useAnnotation } from 'hooks/useAnnotation';
import localStorage from 'utils/LocalStorage.utils';
//#endregion ////////// utils & hooks

//#region    ////////// constants
import { QUESTION_TYPE_CONSTANT } from 'components/ap/question-bank/test-editor/_constant';
//#endregion ////////// constants

import { ReactComponent as ImgSvgIconCheck } from 'assets/img/svg/icon_radio_chk.svg';

/** ap 시험 문제 풀이 영역 컴포넌트 */
export default function QuestionArea({
  stateExamInfo,
  currentQuestionInfo,
  setQuestionInfo,
  setLoadingInfo,
  // 하이라이트 관련 props
  isAnnotation: parentIsAnnotation,
  setIsAnnotation: setParentIsAnnotation,
  isDragged,
  contentRef,
}) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [answerOptions, setAnswerOptions] = useState({});
  const [isUndoActive, setIsUndoActive] = useState(false);
  const tooltipRef = useRef(null);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  // useAnnotation 훅 사용 - 모든 주석 로직 캡슐화
  const {
    initializeRangy,
    handleAnnotateSelection,
    reattachAnnotationEvents,
    handleSaveAnnotation,
    handleDeleteAnnotation,
    handleCancelAnnotation,
    selectedAnnotation,
    isShowAnnotationInput,
    annotationMemo,
    setAnnotationMemo,
    isAnnotation,
    setIsAnnotation,
  } = useAnnotation({
    containerRef: contentRef,
    tooltipRef,
  });

  // 컴포넌트 마운트 시 rangy 초기화
  useEffect(() => {
    initializeRangy();
  }, [initializeRangy]);

  // 부모 컴포넌트의 isAnnotation 상태와 동기화
  useEffect(() => {
    setIsAnnotation(parentIsAnnotation);
  }, [parentIsAnnotation, setIsAnnotation]);

  // isAnnotation이 변경되었을 때 실행
  useEffect(() => {
    if (isAnnotation && isDragged && contentRef.current && contentRef.current.contains(window.getSelection().anchorNode)) {
      handleAnnotateSelection();
    }
  }, [isAnnotation, isDragged, handleAnnotateSelection, contentRef]);

  // 현재 문제가 변경될 때 이벤트 다시 연결
  useEffect(() => {
    const timer = setTimeout(() => {
      reattachAnnotationEvents();
    }, 100);
    return () => clearTimeout(timer);
  }, [currentQuestionInfo, reattachAnnotationEvents]);

  // 현재 훅의 상태가 변경되면 부모 컴포넌트에게 알림
  useEffect(() => {
    setParentIsAnnotation(isAnnotation);
  }, [isAnnotation, setParentIsAnnotation]);

  // 주석 저장 및 삭제 핸들러
  const editQuestionContent = (content, _) => {
    setQuestionInfo((prev) =>
      prev.map((question) => (question.questionOrder === currentQuestionInfo.questionOrder ? { ...question, questionContent: content } : question))
    );
  };

  // 각 동작에 editQuestionContent 전달
  const onSaveAnnotation = () => handleSaveAnnotation(editQuestionContent, null);
  const onDeleteAnnotation = () => handleDeleteAnnotation(editQuestionContent, null);

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 서술형 답안 변경 핸들러 (ReactQuillEditor로 입력받은 값을 저장) */
  const handleEssayChange = (value) => {
    const currentTestKey = `apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`;
    // 로컬 스토리지 업데이트
    localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { answerContent: value });

    // 상태 업데이트
    setQuestionInfo((prev) => {
      const updated = [...prev];
      updated[stateExamInfo.currentNum - 1] = {
        ...updated[stateExamInfo.currentNum - 1],
        answerContent: value,
      };
      return updated;
    });
  };
  /** 객관식 선택 취소(취소선) 함수 */
  const dangerouslyToggle = (e, num) => {
    const strikethrough = `strikethrough${num}`;
    const currentTestKey = `apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`;
    const isStrikethrough = currentQuestionInfo && currentQuestionInfo[strikethrough] === true;
    const updatedValue = !isStrikethrough;

    // 현재 선택된 답안 (key값으로 저장된 상태)
    const currentAnswers = localStorage.getItemJsonParse(currentTestKey)?.questionList[stateExamInfo.currentNum - 1]?.selectAnswer || [];
    let updatedAnswers = [...currentAnswers];

    // 취소선 적용 시 해당 답안이 선택되어 있다면 제거
    const answerValue = e.target.value;
    if (updatedValue && updatedAnswers.includes(answerValue)) {
      updatedAnswers = updatedAnswers.filter((answer) => answer !== answerValue);
    }

    // 로컬 스토리지 업데이트 (취소선 상태와 선택 답안 둘 다 반영)
    localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, {
      [strikethrough]: updatedValue,
      selectAnswer: updatedAnswers,
    });

    // 상태 업데이트
    setQuestionInfo((prev) => {
      const updatedQuestionInfo = [...prev];
      updatedQuestionInfo[stateExamInfo.currentNum - 1] = {
        ...updatedQuestionInfo[stateExamInfo.currentNum - 1],
        [strikethrough]: updatedValue,
        selectAnswer: updatedAnswers,
      };
      return updatedQuestionInfo;
    });
  };
  /** 북마크 변경 핸들러 */
  const bookmarkChangeHandler = (e) => {
    const isBookmarked = e.target.checked;
    const currentTestKey = `apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`;

    localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { isBookmarked });

    setQuestionInfo((prev) => {
      const updatedQuestionInfo = [...prev];
      updatedQuestionInfo[stateExamInfo.currentNum - 1] = {
        ...updatedQuestionInfo[stateExamInfo.currentNum - 1],
        isBookmarked,
      };
      return updatedQuestionInfo;
    });
  };
  /** 객관식 선택 함수 (answerCount에 따라 단일/다중 선택 제어) */
  const answerCheckHandler = (e, num) => {
    const { value: selectedKey, checked } = e.target; // 수정: 선택한 값이 key값이 되도록 (option1, option2...)
    const currentTestKey = `apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`;
    const currentAnswers = localStorage.getItemJsonParse(currentTestKey)?.questionList[stateExamInfo.currentNum - 1]?.selectAnswer || [];
    const { answerCount = 1 } = answerOptions;

    let updatedAnswers;
    if (answerCount > 1) {
      // 복수 선택 가능
      updatedAnswers = checked ? [...currentAnswers, selectedKey] : currentAnswers.filter((answer) => answer !== selectedKey);
    } else {
      // 단일 선택만 가능
      updatedAnswers = checked ? [selectedKey] : [];
    }

    // 로컬 스토리지 업데이트
    localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { selectAnswer: updatedAnswers });

    // 상태 업데이트
    setQuestionInfo((prev) => {
      const updatedQuestionInfo = [...prev];
      let targetQuestion = { ...updatedQuestionInfo[stateExamInfo.currentNum - 1], selectAnswer: updatedAnswers };

      // 답안을 선택할 때 취소선이 설정되어 있으면 해제
      if (checked) {
        const strikethroughKey = `strikethrough${num}`;
        if (targetQuestion[strikethroughKey] === true) {
          // 취소선 해제
          targetQuestion[strikethroughKey] = false;

          // 로컬 스토리지 업데이트
          localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { [strikethroughKey]: false });
        }

        // 선택한 답안의 취소선만 해제
        if (checked) {
          const strikethroughKey = `strikethrough${num}`;
          if (targetQuestion[strikethroughKey] === true) {
            // 취소선 해제
            targetQuestion[strikethroughKey] = false;

            // 로컬 스토리지 업데이트
            localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { [strikethroughKey]: false });
          }
        }
      }

      updatedQuestionInfo[stateExamInfo.currentNum - 1] = targetQuestion;
      return updatedQuestionInfo;
    });
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    if (!currentQuestionInfo?.answerOptions) return;
    setAnswerOptions(JSON.parse(currentQuestionInfo.answerOptions));
  }, [currentQuestionInfo]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <article className={`wrap ${isUndoActive ? 'undo_active' : ''}`}>
      <div className='scroll_wrap'>
        <div className='inner'>
          <div className='top_area'>
            <div className='num'>{currentQuestionInfo.customQuestionOrder}</div>
            <div className='bookmark'>
              <input
                type='checkbox'
                id='bookmark1'
                className='btn_bookmark'
                onChange={bookmarkChangeHandler}
                checked={currentQuestionInfo?.isBookmarked === true}
              />
              <label htmlFor='bookmark1'>Mark for Review</label>
            </div>
            <button className={`btn_undo ${isUndoActive ? 'undo_active' : ''}`} onClick={() => setIsUndoActive((prev) => !prev)}>
              <i className='svg_icon icon_undo'>&nbsp;</i>
              <div className='tip_layer'>Cross out answer choices you think are wrong.</div>
            </button>
          </div>
          <QuillEditorContainer className='text' ref={contentRef} content={currentQuestionInfo?.questionContent} draggable />
          <S.AnswerArea id='answerArea' className='answer_area'>
            {/* 객관식 */}
            {currentQuestionInfo?.questionType === QUESTION_TYPE_CONSTANT.M && (
              <>
                {Array.from({ length: answerOptions?.total || 0 }, (_, index) => {
                  const num = index + 1;
                  const answerKey = `option${num}`; // key값
                  const answerLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

                  // key값을 이용하여 선택 여부 판단
                  const isSelected = Array.isArray(currentQuestionInfo?.selectAnswer) ? currentQuestionInfo.selectAnswer.includes(answerKey) : false;
                  const strikethrough = `strikethrough${num}`;
                  const isStrikethrough = strikethrough in currentQuestionInfo ? currentQuestionInfo[strikethrough] : false;

                  return (
                    <div key={num} className={`item ${isStrikethrough ? ' undo_selected' : ''} ${answerOptions.answerCount > 1 ? 'multiple_answer' : ''}`}>
                      <input
                        type='checkbox'
                        name='answer1'
                        id={`answer1_${num}`}
                        className='radio_answer'
                        value={answerKey} // key값으로 저장
                        checked={isSelected}
                        onChange={(e) => answerCheckHandler(e, num)}
                      />
                      <label className='answer' htmlFor={`answer1_${num}`}>
                        {answerOptions.answerCount > 1 ? (
                          <i className='check_icon'>
                            <ImgSvgIconCheck />
                          </i>
                        ) : (
                          <span className='num'>{answerLabels[index]}</span>
                        )}
                        <QuillEditorContainer content={answerOptions[answerKey]} />
                      </label>
                      <button className='num' onClick={(e) => dangerouslyToggle(e, num)} value={answerKey}>
                        {answerLabels[index]}
                      </button>
                    </div>
                  );
                })}
              </>
            )}

            {/* 서술형 */}
            {currentQuestionInfo?.questionType === QUESTION_TYPE_CONSTANT.E && (
              <div className='editor_area'>
                <ReactQuillEditor className='quill_editor' editorContentState={currentQuestionInfo?.answerContent} handleChange={handleEssayChange} />
              </div>
            )}

            {/* 하이브리드 서술형 - 파일 업로드 */}
            {currentQuestionInfo?.questionType === QUESTION_TYPE_CONSTANT.H && (
              <HybridQuestionArea
                currentQuestionInfo={currentQuestionInfo}
                setLoadingInfo={setLoadingInfo}
                stateExamInfo={stateExamInfo}
                setQuestionInfo={setQuestionInfo}
              />
            )}
          </S.AnswerArea>
          <Tooltip ref={tooltipRef} />
          {isShowAnnotationInput && (
            <AnnotationBottom
              handleDeleteAnnotation={onDeleteAnnotation}
              handleSaveAnnotation={onSaveAnnotation}
              handleCancelAnnotation={handleCancelAnnotation}
              annotateInfo={selectedAnnotation}
              annotateMemo={annotationMemo}
              setAnnotateMemo={setAnnotationMemo}
              isNewAnnotation={selectedAnnotation?.memo === ''}
            />
          )}
        </div>
      </div>
    </article>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  QuestionContent: styled(QuillEditorContainer)`
    p {
      user-select: text;
    }
  `,
  AnswerArea: styled.div`
    .item {
      &.multiple_answer {
        input.radio_answer {
          & + .answer {
            .check_icon {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              padding: 0.25rem;
              border-radius: 0.125rem;
              width: 1.5rem;
              height: 1.5rem;
              border: 1px solid #999999;
              background-color: #ffffff;
              color: #ffffff;
            }
          }

          &:checked {
            & + .answer {
              .check_icon {
                border-color: #384cc0;
                background-color: #384cc0;
              }
            }
          }
        }
      }
    }
  `,
};
