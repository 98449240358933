import QuillEditorContainer from 'components/ap/_components/common/QuillEditorContainer';
import FileItemDownload from 'components/ap/_components/examLayout/examBody/FileItemDownload';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils';

/** AP - Report Card - Section2 - 하이브리드 시험의 각 문제 요소 컴포넌트 */
export default function QuestionItem({ question, isLastQuestion }) {
  /** frq 학생 첨삭 파일 목록 */
  const [uploadFileList, setUploadFileList] = useState([]);

  /** frq 선생 첨삭 파일 목록 */
  const [commentFileList, setCommentFileList] = useState([]);

  /** 하이브리드 문제 파일 조회 - 파일 조회 및 파일 목록 업데이트 */
  const getHybridQuestionFile = ({ etcSeq }) => {
    if (!etcSeq) return;
    const successHandler = (response) => {
      if (response.code === 200) {
        setUploadFileList(response.result.uploadFiles);
      }
    };

    request.get(`/api/v2/ap/get/exam/files/${'answer_pdf'}/${etcSeq}`, null, successHandler).finally(() => {});
  };

  /** 하이브리드 선생님 코멘트 */
  const getHybridQuestionCommentFile = ({ etcSeq }) => {
    if (!etcSeq) return;
    const successHandler = (response) => {
      if (response.code === 200) {
        setCommentFileList(response.result.uploadFiles);
      }
    };

    request.get(`/api/v2/ap/get/exam/files/${'comment_pdf'}/${etcSeq}`, null, successHandler).finally(() => {});
  };

  /** 파일 다운로드 API */
  const downloadHybridQuestionFile = ({ fileCate, upFileSeq, fileName }) => {
    request.downloadFile(`/api/v2/ap/question/${fileCate}/pdf/${upFileSeq}`, fileName).finally(() => {});
  };

  /** 현재 선택 된 파일 다운로드 버튼 핸들러 */
  const handleDownload = async ({ upFileSeq, fileName }) => {
    downloadHybridQuestionFile({ fileCate: 'answer_pdf', upFileSeq, fileName });
  };

  useEffect(() => {
    if (question && question.studentResultSeq && isLastQuestion === question.questionNum + 1) {
      getHybridQuestionFile({ etcSeq: question.studentResultSeq });
      getHybridQuestionCommentFile({ etcSeq: question.studentResultSeq });
    }
  }, [question, isLastQuestion]);

  return (
    <S.QuestionContainer>
      <QuillEditorContainer content={question.customQuestionOrder + '. ' + question.question} />

      <div className='flex_wrap'>
        {isLastQuestion === question.questionNum + 1 && (
          <>
            <div className='answer_container'>
              <div className='title'>Download My Files</div>
              <div className='uploaded_files'>
                {uploadFileList && uploadFileList.length > 0
                  ? uploadFileList?.map((fileItem, index) => (
                      <FileItemDownload
                        key={`fileInfo_${index}`}
                        fileItem={fileItem}
                        handleDownload={() => {
                          handleDownload({ fileName: fileItem.fileName, upFileSeq: fileItem.upFileSeq });
                        }}
                      />
                    ))
                  : null}
              </div>
            </div>
            <div className='teachers_comment_area'>
              <div className='title'>Teacher’s comment :</div>
              <S.CommentWrapper className='custom_scroll'>
                {question.answerComment ? <QuillEditorContainer content={question.answerComment} draggable /> : <p className='placeholder'>No comment.</p>}
              </S.CommentWrapper>
              <div className='answer_container'>
                <div className='uploaded_files' style={{ marginTop: '30px' }}>
                  <div className='title'>Download Teacher's Files</div>
                  {commentFileList && commentFileList.length > 0
                    ? commentFileList?.map((fileItem, index) => (
                        <FileItemDownload
                          key={`fileInfo_${index}`}
                          fileItem={fileItem}
                          handleDownload={() => {
                            handleDownload({ fileName: fileItem.fileName, upFileSeq: fileItem.upFileSeq });
                          }}
                        />
                      ))
                    : null}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <S.TotalScore>
        score:
        <span className='user_score_text'>{question.rawScore}</span>/ {question.maxScore}
      </S.TotalScore>
    </S.QuestionContainer>
  );
}

const S = {
  QuestionContainer: styled.div`
    .flex_wrap {
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: space-between;
      gap: 0.3125rem;
      & > *:nth-child(1) {
        width: 100%;
      }
      & > *:nth-child(2) {
        flex-shrink: 0;
        width: 100%;
      }
    }
    .title {
      font-size: 14px;
      font-weight: 500;
    }

    .question-text {
      font-feature-settings:
        'liga' off,
        'clig' off;
      font-family: 'Noto Serif';
    }

    .answer_container {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .teachers_comment_area {
    }
  `,

  AnswerWrapper: styled.div`
    background-color: #f1f1f1;
    height: 286px;
    padding: 12px;
    width: 100%;
  `,
  CommentWrapper: styled.div`
    background-color: #ffffff;
    border: 1px solid #999;
    height: 286px;
    padding: 12px;
    width: 100%;
    .placeholder {
      color: #999999;
    }
  `,

  TotalScore: styled.p`
    text-align: right;
    font-size: 20px;
    font-weight: 700;
    .user_score_text {
      font-size: 32px;
    }
  `,
};
