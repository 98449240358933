import React, { useState } from 'react';
import styled from 'styled-components';

import { nvl } from 'utils/Common.utils';

import SMiniTextBtn from 'components/_common/button/SMiniTextBtn';
import MiniAlert from 'components/_common/alerts/MiniAlert';

import { ReactComponent as ImgSvgIconSelect } from 'assets/img/svg/select.svg';

const textLeftStyle = {
  textAlign: 'left',
};

/** AP Grading main Table */
export default function GradingMainTable({ studentInfo }) {
  /** grade toggle */
  const [gradeOrder, setGradeOrder] = useState(0);
  const [alert, alertSet] = useState({
    visible: false,
    alertMessage: '',
  });

  /** 학년 정렬 핸들러 (실제 데이터가 오면 각 행에 맞는 데이터를 쿼리 파라미터 방식으로 구현하기) */
  const gradeOrderToggle = () => {};

  /** 테이블 tr (표의 행) 클릭 핸들러 */
  const handleClickTableRow = () => {
    window.open(
      window.location.origin + '/ap/com/score/grading/submission',
      'dsat',
      `top=0, left=0, width=${window.screen.width}, height=${window.screen.height}, fullscreen=yes`
    );
  };

  return (
    <>
      <S.Table>
        {/* 🧩 student main - Table_MainContent_테이블헤더 🧩 */}
        <thead className='list'>
          <tr>
            <th className='col_01'>No.</th>
            <th className='col_02'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${gradeOrder === 'desc' ? '' : 'on'}`}
                onClick={() => gradeOrderToggle()}>
                Status <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_03'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${gradeOrder === 'desc' ? '' : 'on'}`}
                onClick={() => gradeOrderToggle()}>
                Subject <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_04'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${gradeOrder === 'desc' ? '' : 'on'}`}
                onClick={() => gradeOrderToggle()}>
                Test <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_05'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${gradeOrder === 'desc' ? '' : 'on'}`}
                onClick={() => gradeOrderToggle()}>
                Teacher <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_06'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${gradeOrder === 'desc' ? '' : 'on'}`}
                onClick={() => gradeOrderToggle()}>
                Student <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_07'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${gradeOrder === 'desc' ? '' : 'on'}`}
                onClick={() => gradeOrderToggle()}>
                Score <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_08'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${gradeOrder === 'desc' ? '' : 'on'}`}
                onClick={() => gradeOrderToggle()}>
                Test Date <ImgSvgIconSelect />
              </button>
            </th>
          </tr>
        </thead>
        {/* 🧩 student main - Table_MainContent_테이블메인 🧩 */}
        <tbody>
          {studentInfo.studentList == null || studentInfo.studentList?.length < 1 ? (
            <tr>
              <td colSpan='8'>You do not have any students… yet!</td>
            </tr>
          ) : (
            studentInfo.studentList.map((item, index) => {
              return (
                <tr key={`tr_${index}_${item.userSeq}`} onClick={() => handleClickTableRow(item.userSeq)} title='Open in a New Window'>
                  <td style={{ whiteSpace: 'nowrap' }}>{studentInfo.totalCount - studentInfo.numPerPage * (studentInfo.currentPage - 1) - index}</td>
                  <td style={{ ...textLeftStyle }}>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '4px' }}>
                      {item.readFlag === 'N' && <SMiniTextBtn text={'A'} />}
                      <p className='name'>{item.userName}</p>
                    </div>
                  </td>
                  <td>-</td>
                  <td>{item.studentGrade}</td>
                  <td style={textLeftStyle}>{item.studentSchool}</td>
                  <td style={{ ...textLeftStyle }} className='class'>
                    -
                  </td>
                  <td className='table_data_btn_area'>-</td>
                  <td className='table_data_btn_area'>-</td>
                </tr>
              );
            })
          )}
        </tbody>
      </S.Table>

      {/* 학생 삭제 확인 Toast Alert */}
      {alert.visible && (
        <MiniAlert text={alert.alertMessage} active={alert.visible} inactive={() => alertSet({ visible: false, alertMessage: '' })} timeOut={2000} />
      )}
    </>
  );
}

const S = {
  Table: styled.table`
    tbody {
      tr {
        cursor: pointer;
      }
    }
    tr {
      th,
      td {
        vertical-align: middle;
        padding: 0 0.65rem;
        &.col_01 {
          width: 2.5rem;
        }
        &.col_02 {
          width: 6.8rem;
        }
        &.col_03 {
          width: 11.5rem;
        }
        &.col_04 {
          width: auto;
        }
        &.col_05 {
          width: 11.5rem;
        }
        &.col_06 {
          width: 11.5rem;
        }
        &.col_07 {
          width: 5.5rem;
        }
        &.col_08 {
          width: 7rem;
        }
      }
      th {
        background-color: #000;
        color: #fff;
        height: 2.5rem;
        text-align: center;
        font-weight: 500;
        font-size: 0.875rem;
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          overflow: hidden;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          overflow: hidden;
        }

        .btn_sort {
          color: #ffffff;
          font-weight: 500;
          font-size: 0.875rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          svg {
            width: 0.75rem;
            transition: 0.2s;
            transform-origin: center;
          }
          &.on {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
      td {
        height: 3rem;
        text-align: center;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        border-bottom: 1px solid #ebebeb;

        &.col_03,
        &.col_06,
        &.col_07 {
          text-align: left;
        }
        .table_data_inner_box {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
        }

        & > button,
        .table_data_inner_box > button {
          width: 1.5rem;
          height: 1.5rem;
          transition: 0.2s;
          &:hover {
            filter: brightness(95%);
          }

          &.btn_view {
            width: auto;
            height: auto;
            background-color: #f2f2f2;
            color: #7f8998;
            font-weight: 400;
            font-size: 0.875rem;
            border-radius: 0.25rem;
            padding: 0.125rem 0.5rem;
          }

          &.btn_change_class {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #f2f2f2;
            border-radius: 0.25rem;
            svg {
              width: 1rem;
              color: #a6b3be;
            }
          }
        }

        &.table_data_btn_area {
          button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #e8f0ff;
            border-radius: 0.25rem;
            svg {
              width: 1.125rem;
              color: #0068bd;
            }
          }
        }
      }
      background-color: #fff;
      &:nth-child(even) {
        background-color: #fafafa;
      }
      &.active {
        background-color: #edf7ff;
      }
      &:has(.com_chk):hover {
        background-color: #f2f2f2;
        //color:#4B5563 ;
      }
    }
  `,
};
