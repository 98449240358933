// Packages
import ScoreCircleChart from 'components/_common/chart/ScoreCircleChart';
import styled from 'styled-components';
// Functions
import { nvl, nvlNumber } from 'utils/Common.utils.js';
// Components

function ChartWithScoreContainer({ testResultCurrentScore, testResultCurrentRank, ScoreRange, CustomLabel }) {
  return (
    <S.ChartWithScoreContainer>
      <S.ScoreWrapper>
        {testResultCurrentScore && <p className='my-score'>{nvl(testResultCurrentScore) === '' ? '-' : testResultCurrentScore}</p>}
        <div className='divide-wrapper'>
          <hr className='divide-line' />
        </div>
        <p className='score'>
          <span>
            {`${ScoreRange[0]}-`}
            <br />
            {`${ScoreRange[1]}`}
          </span>
        </p>
      </S.ScoreWrapper>
      {testResultCurrentScore && testResultCurrentRank && (
        <ScoreCircleChart rank={nvl(testResultCurrentScore) === '' ? '-' : testResultCurrentRank} CustomLabel={CustomLabel} />
      )}
    </S.ChartWithScoreContainer>
  );
}

export default ChartWithScoreContainer;

// --- --- ---

const S = {};

S.ChartWithScoreContainer = styled.div`
  display: flex;
  gap: 1.5rem;
`;

S.ScoreWrapper = styled.div`
  position: relative;
  width: 6rem;
  height: 4.25rem;
  .divide-wrapper {
    left: 1.5rem;
    bottom: 0.5rem;
    position: absolute;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .divide-line {
      width: 2px;
      height: calc(100% * 1.414); // 대각선 계산
      border-color: #000;
      transform: rotate(45deg);
    }
  }

  .my-score {
    text-align: right;
    position: absolute;
    top: 0.5rem;
    right: 2.8rem;
    line-height: 1;
    font-size: 1.5rem;
    font-weight: 700;
    background: linear-gradient(90deg, #4758ff 5.05%, #008cff 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  .score {
    position: absolute;
    font-size: 0.875rem;
    right: 0;
    bottom: 0;
  }
`;
