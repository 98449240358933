import styled from 'styled-components';

import QuillEditorContainer from 'components/ap/_components/common/QuillEditorContainer';

/** Report Card - Section2 */
export default function FreeResponseSection({ part }) {
  return (
    <S.Section2>
      <S.QuestionList>
        {part.questions.map((question) => {
          return (
            <S.QuestionContainer key={`question-${question.questionNum}`}>
              <QuillEditorContainer content={question.customQuestionOrder + '. ' + question.question} draggable />

              <div className='flex-wrap'>
                <div className='answer_container'>
                  <div className='title'>Your Answers :</div>

                  <S.AnswerWrapper className='custom_scroll'>
                    <QuillEditorContainer content={question.tryAnswer} draggable />
                  </S.AnswerWrapper>
                </div>
                <div className='teachers_comment_area'>
                  <div className='title'>Teacher’s comment :</div>
                  <S.CommentWrapper className='custom_scroll'>
                    {question.answerComment ? <QuillEditorContainer content={question.answerComment} draggable /> : <p className='placeholder'>No comment.</p>}
                  </S.CommentWrapper>
                </div>
              </div>
              <S.TotalScore>
                score:
                <span className='user_score_text'>{question.rawScore}</span>/ {question.maxScore}
              </S.TotalScore>
            </S.QuestionContainer>
          );
        })}
      </S.QuestionList>
    </S.Section2>
  );
}

// --- --- ---

const S = {
  Section2: styled.div`
    .section_title {
      margin-bottom: 1.875rem;
      font-size: 1.5rem;
      font-weight: 700;
    }
  `,

  QuestionList: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  `,

  QuestionContainer: styled.div`
    .flex-wrap {
      display: flex;
      align-items: end;
      justify-content: space-between;
      gap: 0.3125rem;
      & > *:nth-child(1) {
        width: 100%;
      }
      & > *:nth-child(2) {
        flex-shrink: 0;
        width: 30%;
      }
    }
    .title {
      font-size: 14px;
      font-weight: 500;
    }

    .question-text {
      font-feature-settings:
        'liga' off,
        'clig' off;
      font-family: 'Noto Serif';
    }

    .answer_container {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .teachers_comment_area {
    }
  `,

  AnswerWrapper: styled.div`
    background-color: #f1f1f1;
    height: 286px;
    padding: 12px;
    width: 100%;
  `,
  CommentWrapper: styled.div`
    background-color: #ffffff;
    border: 1px solid #999;
    height: 286px;
    padding: 12px;
    width: 100%;
    .placeholder {
      color: #999999;
    }
  `,

  TotalScore: styled.p`
    text-align: right;
    font-size: 20px;
    font-weight: 700;
    .user_score_text {
      font-size: 32px;
    }
  `,
};
