import React from 'react';
import { Routes, Route } from 'react-router-dom';

//components
import SignUpMethodTypePage from 'components/user/sign-up/Page';

import SignUpEmailUserTypePage from 'components/user/sign-up/email/user-type/Page';
import SignUpSocialUserTypePage from 'components/user/sign-up/social/user-type/Page';

import SignUpSuccessfullySubmittedPage from 'components/user/sign-up/successfully-submitted/Page';
import SignUpCompletePage from 'components/user/sign-up/complete/Page';

import SignUpAcademyAdminAcademyInformationPage from 'components/user/sign-up/social/academy-admin/academy-information/Page';
import SignUpAcademyAdminInformationReviewPage from 'components/user/sign-up/social/academy-admin/information-review/Page';
import SignUpSocialAcademyAdminUploadPage from 'components/user/sign-up/social/academy-admin/upload/Page';
import SignUpAcademyTeacherInformationReviewPage from 'components/user/sign-up/social/academy-teacher/information-review/Page';
import SignUpAcademyTeacherAcademyPage from 'components/user/sign-up/social/academy-teacher/academy-code/Page';
import SignUpTutorInformationReviewPage from 'components/user/sign-up/social/tutor/information-review/Page';
import SignUpTutorInformationPage from 'components/user/sign-up/social/tutor/tutor-information/Page';
import SignUpSocialTutorUploadPage from 'components/user/sign-up/social/tutor/upload/Page';
import SignUpParentInformationReviewPage from 'components/user/sign-up/social/parent/information-review/Page';
import SignUpStudentInformationReviewPage from 'components/user/sign-up/social/student/information-review/Page';
import SignUpSocialStudentInformationPage from 'components/user/sign-up/social/student/student-information/Page';

import SignUpConfirmCodePage from 'components/user/sign-up/email/confirm-code/Page';
import SignUpEmailAcademyAdminInfoPage from 'components/user/sign-up/email/academy-admin/info-form/Page';
import SignUpEmailAcademyAdminUploadPage from 'components/user/sign-up/email/academy-admin/upload/Page';
import SignUpEmailAcademyAdminFirstTimeSignInPage from 'components/user/sign-up/email/academy-admin/first-time-sign-in/Page';
import SignUpEmailAcademyTeacherInfoPage from 'components/user/sign-up/email/academy-teacher/info-form/Page';
import SignUpEmailAcademyTeacherAcademyCodePage from 'components/user/sign-up/email/academy-teacher/academy-code/Page';
import SignUpEmailTutorInfoPage from 'components/user/sign-up/email/tutor/info-form/Page';
import SignUpEmailTutorUploadPage from 'components/user/sign-up/email/tutor/upload/Page';
import SignUpEmailTutorFirstTimeSignInPage from 'components/user/sign-up/email/tutor/first-time-sign-in/Page';
import SignUpEmailStudentInfoPage from 'components/user/sign-up/email/student/info-form/Page';
import SignUpEmailStudentAdditionalInfoPage from 'components/user/sign-up/email/student/student-information/Page';
import SignUpEmailParentInfoPage from 'components/user/sign-up/email/parent/info-form/Page';
import SignUpSocialAcademyAdminFirstTimeSignInPage from 'components/user/sign-up/social/academy-admin/first-time-sign-in/Page';
import SignUpEmailAcademyAdminTimezonePage from 'components/user/sign-up/email/academy-admin/timezone/Page';
import SignUpEmailTutorTimezonePage from 'components/user/sign-up/email/tutor/timezone/Page';
import SignUpSocialTutorTimezonePage from 'components/user/sign-up/social/tutor/timezone/Page';
import SignUpSocialAcademyAdminTimezonePage from 'components/user/sign-up/social/academy-admin/timezone/Page';

/** 회원 가입 라우트 (로그인을 하지 않아도 접근 가능) */
const MainRoute = () => {
  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    const unAuthRoutes = [
      { path: '/', element: <SignUpMethodTypePage /> },
      // 이하 이메일 회원 가입
      { path: '/email/user/type', element: <SignUpEmailUserTypePage /> },

      { path: '/email/academy-admin', element: <SignUpEmailAcademyAdminInfoPage /> },
      { path: '/email/academy-admin/confirm', element: <SignUpConfirmCodePage /> },
      { path: '/email/academy-admin/upload', element: <SignUpEmailAcademyAdminUploadPage /> },
      { path: '/email/academy-admin/first-time-signin', element: <SignUpEmailAcademyAdminFirstTimeSignInPage /> },
      { path: '/email/academy-admin/timezone', element: <SignUpEmailAcademyAdminTimezonePage /> },

      { path: '/email/academy-teacher', element: <SignUpEmailAcademyTeacherInfoPage /> },
      { path: '/email/academy-teacher/confirm', element: <SignUpConfirmCodePage /> },
      { path: '/email/academy-teacher/academy-code', element: <SignUpEmailAcademyTeacherAcademyCodePage /> },

      { path: '/email/tutor', element: <SignUpEmailTutorInfoPage /> },
      { path: '/email/tutor/confirm', element: <SignUpConfirmCodePage /> },
      { path: '/email/tutor/upload', element: <SignUpEmailTutorUploadPage /> },
      { path: '/email/tutor/first-time-signin', element: <SignUpEmailTutorFirstTimeSignInPage /> },
      { path: '/email/tutor/timezone', element: <SignUpEmailTutorTimezonePage /> },

      { path: '/email/student', element: <SignUpEmailStudentInfoPage /> },
      { path: '/email/student/confirm', element: <SignUpConfirmCodePage /> },
      { path: '/email/student/student-information ', element: <SignUpEmailStudentAdditionalInfoPage /> },

      { path: '/email/parent', element: <SignUpEmailParentInfoPage /> },
      { path: '/email/parent/confirm', element: <SignUpConfirmCodePage /> },

      // 이하 소셜 회원 가입
      { path: '/social/user-type', element: <SignUpSocialUserTypePage /> },

      { path: '/social/academy-admin/information-review', element: <SignUpAcademyAdminInformationReviewPage /> },
      { path: '/social/academy-admin/academy-information', element: <SignUpAcademyAdminAcademyInformationPage /> },
      { path: '/social/academy-admin/upload', element: <SignUpSocialAcademyAdminUploadPage /> },
      { path: '/social/academy-admin/first-time-signin', element: <SignUpSocialAcademyAdminFirstTimeSignInPage /> },
      { path: '/social/academy-admin/timezone', element: <SignUpSocialAcademyAdminTimezonePage /> },

      { path: '/social/academy-teacher/information-review', element: <SignUpAcademyTeacherInformationReviewPage /> },
      { path: '/social/academy-teacher/academy-code', element: <SignUpAcademyTeacherAcademyPage /> },

      { path: '/social/tutor/information-review', element: <SignUpTutorInformationReviewPage /> },
      { path: '/social/tutor/tutor-information', element: <SignUpTutorInformationPage /> },
      { path: '/social/tutor/upload', element: <SignUpSocialTutorUploadPage /> },
      { path: '/social/tutor/first-time-signin', element: <SignUpSocialAcademyAdminFirstTimeSignInPage /> },
      { path: '/social/tutor/timezone', element: <SignUpSocialAcademyAdminTimezonePage /> },

      { path: '/social/student/information-review', element: <SignUpStudentInformationReviewPage /> },
      { path: '/social/student/student-information', element: <SignUpSocialStudentInformationPage /> },

      { path: '/social/parent/information-review', element: <SignUpParentInformationReviewPage /> },

      ////// 공통 라우트 페이지
      { path: '/successfully-submitted', element: <SignUpSuccessfullySubmittedPage /> },
      { path: '/complete', element: <SignUpCompletePage /> },
    ];

    return returnRoutes(unAuthRoutes);
  };

  return (
    <>
      <Routes>{AuthorizationRoutes()}</Routes>
      <p className='copyright'>Copyright © 2023 Gate⁺ All rights reserved.</p>
    </>
  );
};

export default MainRoute;
