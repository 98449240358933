import { useState } from 'react';
import styled from 'styled-components';

import ApSettingSelectBox from './_components/ApSettingSelectBox';
import ApSettingStepper from './_components/ApSettingStepper';
import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/round_triangle.svg';

// 상수 그룹화
const SETTINGS = {
  MULTIPLE_CHOICE: [
    { label: 'Single-select', value: 'Single-select' },
    { label: 'Multi-select', value: 'Multi-select' },
  ],
  CORRECT_ANSWERS: [
    { label: 'Select', value: null },
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
  ],
  DEFAULT_CHOICE: [{ label: 'Text Input', value: 'Text Input' }],
};

/** 각 State의 초기 상태 */
const INITIAL_STATE = {
  single: {
    multipleChoiceType: 'Single-select',
    answerChoices: ['Text Input'],
    correctAnswer: null,
  },
  multiple: {
    multipleChoiceType: 'Multi-select',
    answerChoices: ['Text Input'],
    noOfCorrectAnswer: 1,
    correctAnswer: [null],
  },
};

/** 답안 선택 설정을 위한 팝업 컴포넌트 */
export default function AnswerChoiceSettingPopup({ isOpen }) {
  const [isSingleSelect, setIsSingleSelect] = useState(true);
  const [singleAnswerChoiceSettingData, setSingleAnswerChoiceSettingData] = useState(INITIAL_STATE.single);
  const [multipleAnswerChoiceSettingData, setMultipleAnswerChoiceSettingData] = useState(INITIAL_STATE.multiple);

  /** 현재 선택된 모드의 데이터를 반환 */
  const getCurrentModeData = () => (isSingleSelect ? singleAnswerChoiceSettingData : multipleAnswerChoiceSettingData);

  /** 답안 선택 데이터 업데이트 */
  const updateAnswerChoice = (index, value) => {
    const updateFunction = isSingleSelect ? setSingleAnswerChoiceSettingData : setMultipleAnswerChoiceSettingData;
    updateFunction((prev) => ({
      ...prev,
      answerChoices: prev.answerChoices.map((choice, i) => (i === index ? value : choice)),
    }));
  };

  /** 정답 데이터 업데이트 */
  const updateCorrectAnswer = (index, value) => {
    if (isSingleSelect) {
      setSingleAnswerChoiceSettingData((prev) => ({ ...prev, correctAnswer: value }));
    } else {
      setMultipleAnswerChoiceSettingData((prev) => ({
        ...prev,
        correctAnswer: prev.correctAnswer.map((choice, i) => (i === index ? value : choice)),
      }));
    }
  };

  /** 증가/감소를 받아 답안 선택지 개수를 조절하는 함수 */
  const handleChoicesCount = (isIncrease) => {
    const updateFunction = isSingleSelect ? setSingleAnswerChoiceSettingData : setMultipleAnswerChoiceSettingData;
    updateFunction((prev) => ({
      ...prev,
      answerChoices: isIncrease ? [...prev.answerChoices, 'Text Input'] : prev.answerChoices.slice(0, -1),
    }));
  };

  /** 증가/감소를 받아 정답 개수를 조절하는 함수 (다중 선택 모드 전용) */
  const handleCorrectAnswerCount = (isIncrease) => {
    setMultipleAnswerChoiceSettingData((prev) => ({
      ...prev,
      correctAnswer: isIncrease ? [...prev.correctAnswer, null] : prev.correctAnswer.slice(0, -1),
    }));
  };

  if (!isOpen) return null;

  return (
    <S.Container>
      <div className='popup_header'>
        <h2>Answer Choice Settings</h2>
      </div>

      <S.ContentWrapper>
        <div className='control_row'>
          <span className='label'>Multiple choice type</span>
          <ApSettingSelectBox options={SETTINGS.MULTIPLE_CHOICE} onSelect={(value) => setIsSingleSelect(value === 'Single-select')} />
        </div>

        <div className='divider' />

        <div>
          <div className='control_row has_inner_row'>
            <span className='label'>No. of answer choices</span>
            <ApSettingStepper
              number={getCurrentModeData().answerChoices.length}
              onIncrease={() => handleChoicesCount(true)}
              onDecrease={() => handleChoicesCount(false)}
            />
          </div>

          <div className='inner_row'>
            {getCurrentModeData().answerChoices.map((_, index) => (
              <div className='control_row' key={index}>
                <span className='label'>Choice {index + 1}</span>
                <ApSettingSelectBox options={SETTINGS.DEFAULT_CHOICE} onSelect={(value) => updateAnswerChoice(index, value)} />
              </div>
            ))}
          </div>
        </div>

        <div className='divider' />

        {isSingleSelect ? (
          <div className='control_row'>
            <span className='label'>Correct answer</span>
            <ApSettingSelectBox className='correct_answer_select' options={SETTINGS.CORRECT_ANSWERS} onSelect={(value) => updateCorrectAnswer(0, value)} />
          </div>
        ) : (
          <div>
            <div className='control_row has_inner_row'>
              <span className='label'>No. of Correct Answer</span>
              <ApSettingStepper
                number={multipleAnswerChoiceSettingData.correctAnswer.length}
                onIncrease={() => handleCorrectAnswerCount(true)}
                onDecrease={() => handleCorrectAnswerCount(false)}
              />
            </div>

            <div className='inner_row'>
              {multipleAnswerChoiceSettingData.correctAnswer.map((_, index) => (
                <div className='control_row' key={index}>
                  <span className='label'>Correct Answer {index + 1}</span>
                  <ApSettingSelectBox
                    className='correct_answer_select'
                    options={SETTINGS.CORRECT_ANSWERS}
                    onSelect={(value) => updateCorrectAnswer(index, value)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </S.ContentWrapper>
      <ArrowIconSelect className='triangle_icon' />
    </S.Container>
  );
}

const S = {
  Container: styled.div`
    position: absolute;
    left: 116%;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 24px;
    width: 430px;

    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.24));

    .popup_header {
      width: 100%;
      h2 {
        color: #111111;
        font-family: 'Roboto-SemiBold', Helvetica;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin: 0;
      }
    }

    .triangle_icon {
      position: absolute;
      left: -3%;
      top: 50%;
      transform: translateY(-50%);
      color: #ffffff;
    }
  `,
  ContentWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;

    .divider {
      height: 1px;
      background-color: #f1f1f5;
      width: 100%;
    }

    .control_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .label {
        color: #111111;
        font-family: 'Roboto-Regular', Helvetica;
        font-size: 14px;
        line-height: 20px;
      }

      &.has_inner_row {
        margin-bottom: 0.5rem;
      }

      .correct_answer_select {
        width: 6rem;
      }
    }

    .inner_row {
      background-color: #fafafa;
      padding: 0.625rem 0.75rem;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .group_control {
      display: flex;
      align-items: center;
      gap: 8px;

      .separator {
        color: #111111;
        font-size: 12px;
        line-height: 18px;
      }
    }
  `,
};


