import { doc, onSnapshot } from 'firebase/firestore';
import jwt_decode from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { nvl, nvlNumber } from 'utils/Common.utils';
import LoadingBar from 'utils/LoadingBar';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
import SessionStorage from 'utils/SessionStorage.utils';
import clearAppData from 'utils/clearAppData.js';
import { fetchGetRemainingKeyInBToB, fetchGetRemainingKeyInBToC } from 'utils/functions/fetch/getRemainingKey';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';

// hook
import useOnlineStatus from 'hooks/useOnlineStatus.js';
import useUserLevels from 'hooks/useUserLevels.js';

import { updateGateKeyAmount } from 'reducers/gateKeys/action';

// components
import CustomAlert from 'components/_common/alerts/CustomAlert';
import ImageModal from 'components/_common/modals/ImageModal';
import Notification from 'layout/Notification';
import DebugTimezone from './_gnb-component/DebugTimezone';
import GateKeyBtn from './_gnb-component/gateKeyPopup/GateKeyBtn';
import UserProfileBtn from './_gnb-component/userProfile/UserProfileBtn';
// import EmergencyWarningModal from 'components/_common/modals/EmergencyWarningModal'; // 사용안하는 긴급공지 가림 - 24.7.22
import FreezingKeyModal from 'components/_common/modals/FreezingKeyModal';
import db from '../firebase.js';

//img
import ImgGnbPush from 'assets/img/gnb_push.png';
import ImgGnbTest from 'assets/img/gnb_test.png';
import ImgIconDashboard from 'assets/img/logo.png';
import noInternetIcon from 'assets/img/svg/no_internet_icon.svg';

import MainApSelectButton from './_gnb-component/buttons/ap/mainApSelectButton/MainApSelectButton';

import ProfileImage from 'components/_common/ProfileImage';

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const alertAttributeValue = {
  visible: false,
  alertMessage: '',
  alertType: 'alert',
  returnValue: () => {},
  id: '',
};
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** 메인 Header 컴포넌트 */
export default function Header(props) {
  //#region    ////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  let { pathname } = window.location;
  const windowManager = SessionStorage.getItemJsonParse('windowManager');
  const userSession = localStorage.getItemJsonParse('userSession');
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  const isB2c = useLevels.isStudent; // || useLevels.isParent;
  //#endregion //////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** alertLayerPopup 코드 */
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  /** 스크롤 시 addClass */
  const [scroll, setScroll] = useState(false);
  /** Notifications 레이어 노출 */
  const [notification, setNotification] = useState(false);
  const [unseenNotificationsCnt, setUnseenNotificationsCnt] = useState(0);
  /** 유저의 남은 Key */
  const [remainingKey, setRemainingKey] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  // set test 페이지로 이동
  const [showPopup, setShowPopup] = useState(false);

  const [firebaseInfo, setFirebaseInfo] = useState({
    ec_seq: 0,
    user_level: '',
    isGet: false,
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const intervalRef = useRef(null);
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** 읽지 않은 알람 수 확인 */
  const getUnseenNotifications = () => {
    // console.log("호출 확인")
    const successHandler = (response) => {
      if (response.code === 200) {
        let notiList = response.result.notificationList;
        let gloNoti = response.result.globalNotificationList;

        let notiCnt = notiList.filter((i) => nvl(i.notiStatus) === '').length + gloNoti.filter((i) => nvl(i.notiStatus) === '').length;

        setUnseenNotificationsCnt(notiCnt);
      }
    };

    request.get(`/api/etc/notifications?userSeq=${userInfo?.userSeq}&userLevel=${userInfo?.userLevel}`, null, successHandler).catch((error) => {
      console.log('GNB Notification 에러 발생! : ', error);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    });
  };

  /** 유저가 가진 key를 불러오는 함수 */
  const getRemainingKey = (isB2c) => {
    /** API 요청 성공 시 CallBack 함수 */
    const successHandler = (response) => {
      if (response.code === 200) {
        const remainingKey = isB2c ? response.result.b2cGateKeyAmount : response.result.gateKeyAmount;

        setRemainingKey(remainingKey === 0 ? 0 : remainingKey);
        dispatch(updateGateKeyAmount(remainingKey));
      }
    };
    /** API 요청 실패 시 CallBack 함수 */
    const errorHandler = (error) => {
      console.log('GNB KeyData 에러 발생! : ', error);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };

    isB2c ? fetchGetRemainingKeyInBToC(successHandler, errorHandler) : fetchGetRemainingKeyInBToB(successHandler, errorHandler);
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      if (alertLayerPopup.activateMode === 'testLogout') {
        clearAppData(dispatch, userInfo?.userId);

        window.location.href = '/';
      }
    }
  };

  const logout = (offline = false) => {
    if (offline) {
      clearAppData(dispatch, userInfo?.userId);

      window.location.href = '/signin';
    } else {
      if (windowManager?.popupOn === true)
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            alertType: 'confirm',
            activateMode: 'testLogout',
            visible: true,
            alertMessage: 'Test is in progress. Are you sure you want to log out? Your test window will also close.',
          };
        });
      else {
        clearAppData(dispatch, userInfo?.userId);

        window.location.href = '/signin';
      }
    }
  };

  /** 메인 로고 클릭 핸들러 */
  const logoClick = () => {
    // 주소에 /ap/ 가 있으면 ap로 이동
    if (!pathname.includes('/ap/')) {
      if (useLevels.isAcademy)
        navigate('/academy/dashboard'); // 학원 관리자
      else if (useLevels.isStudent)
        navigate('/student/dashboard'); // 학색
      else if (useLevels.isTeacher)
        navigate('/academy/dashboard'); // 선생님
      else if (useLevels.isTutor)
        navigate('/academy/dashboard'); // 강사
      else if (useLevels.isTubletAdmin)
        navigate('/tublet/dashboard'); // 튜블렛 관리자
      else if (useLevels.isTubletTutor)
        navigate('/tublet/tutor/dashboard'); // 튜블렛 튜터
      else navigate('/parent/dashboard'); // 학부모
    } else {
      // 이미 score 페이지로 이동 중이라면 다시 이동하지 않음
      if (pathname.includes('/com/score') && !pathname.includes('/com/score/grading')) return;

      if (useLevels.isAcademy)
        // navigate('/ap/academy/dashboard'); // 학원 관리자
        navigate(`/ap/com/score/${obfuscateUrlParam('0')}`); // 학원 관리자
      else if (useLevels.isStudent)
        navigate('/ap/student/dashboard'); // 학생
      else if (useLevels.isTeacher)
        // navigate('/ap/academy/dashboard'); // 선생님
        navigate(`/ap/com/score/${obfuscateUrlParam('0')}`); // 선생님
      else if (useLevels.isTutor)
        // navigate('/ap/academy/dashboard'); // 강사
        navigate(`/ap/com/score/${obfuscateUrlParam('0')}`); // 강사
      else if (useLevels.isTubletAdmin)
        navigate('/tublet/dashboard'); // 튜블렛 관리자
      else navigate('/ap/parent/dashboard'); // 학부모
    }
  };

  /** 닫기 버튼 */
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const notificationPop = (e) => {
    e.stopPropagation();

    setNotification(!notification);
  };
  /** set Test 페이지로 이동 && 환불중인지 확인하는 API 호출 (현재 환불 진행중인 상태라면 set Test 진입 불가) */
  const goToSetTest = () => {
    // url /ap/ 로 시작하면 ap로 이동
    let url;
    if (pathname.includes('/ap/')) {
      url = isB2c ? '/ap/com/tests/set/selection/config' : '/ap/com/tests/set/selection/class';
    } else {
      url = isB2c ? '/com/tests/set/selection/config' : '/com/tests/set/selection/type';
    }
    if (!isB2c) {
      setIsLoading(true);

      const successHandler = (response) => {
        // console.log('🚀 ~ successHandler ~ response:', response);
        if (response.code === 200) {
          const { isFreezing } = response.result;

          setIsLoading(false);

          if (!isFreezing) navigate(url);
          else setShowPopup(true);
        }
      };

      request.get('/api/academy/gatekey/freezing', null, successHandler).catch((error) => {
        console.log('key freezing 에러 발생! : ', error);
      });
    } else navigate(url);
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });

    if (nvlNumber(userInfo?.userSeq) > 0) {
      getUnseenNotifications();

      intervalRef.current = setInterval(() => {
        getUnseenNotifications();
        getRemainingKey();
      }, 10000);
    }

    return () => {
      clearInterval(intervalRef.current);

      intervalRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!useLevelsIsLoading) {
      getRemainingKey(isB2c);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLevelsIsLoading, useLevels]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!userInfo?.userSeq) return;

      const userDocRef = doc(db, 'users', String(userInfo.userSeq));
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const { ec_seq, user_level } = data;
          setFirebaseInfo({ ec_seq, user_level, isGet: true });
        } else {
          console.log('No such document!');
        }
      });

      return () => {
        unsubscribe();
      };
    };

    fetchUsers();
  }, [userInfo?.userSeq]);

  useEffect(() => {
    // console.log('🚀 ~ useEffect ~ firebaseInfo.ec_seq, userInfo.ecSeq:', firebaseInfo.ec_seq, userInfo.ecSeq, firebaseInfo.isGet);

    if (firebaseInfo.isGet && firebaseInfo.ec_seq !== userInfo.ecSeq) {
      const successHandler = (response) => {
        // console.log('🚀 ~ successHandler ~ response:', response);
        if (response.code === 200) {
          const { accessToken, refreshToken } = response.result;

          localStorage.setItem('userSession', 'accessToken', accessToken);
          localStorage.setItem('userSession', 'refreshToken', refreshToken);
          setUserInfo(jwt_decode(accessToken));
        }
      };

      request.post('/api/member/token/refresh', { refreshToken: userSession?.refreshToken }, successHandler).catch((error) => {
        console.log('refresh token error! : ', error);
      });
    }
  }, [firebaseInfo, userInfo, userSession]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  /** 우측 상단의 페이지 메인 타이틀 문구 얻기 */
  function renderMainHeaderTitle() {
    let text;

    if (pathname.includes('/dashboard')) {
      text = 'SAT';
      if (pathname.includes('/ap')) {
        text = 'AP';
      }
    } else if (pathname.includes('/com/students/students')) text = 'STUDENTS';
    else if (pathname.includes('/com/tests/taken')) text = 'TEST TAKEN';
    else if (pathname.includes('/com/tests/scheduled')) text = 'TEST SCHEDULED';
    else if (pathname.includes('/tests/set/')) text = 'SET TEST';
    else if (pathname.includes('/com/tests/calendar')) text = 'CALENDAR';
    else if (pathname.includes('/com/students/classes')) text = 'CLASSES';
    else if (pathname.includes('/score/grading')) text = 'GRADING';
    else if (pathname.includes('/com/score')) text = 'SCORE';
    else if (pathname.includes('/gateplus/news')) text = 'GATE⁺ NEWS';
    else if (pathname.includes('/dsat')) text = 'SAT INFO';
    else if (pathname.includes('/com/tests/status')) text = 'TEST STATUS';
    else if (pathname.includes('/profile')) text = 'PROFILE';
    else if (pathname.includes('/question-bank')) text = 'QUESTION BANK';
    else text = 'GATE PLUS';

    return text;
  }

  const offlineModalInfo = {
    modalImage: noInternetIcon,
    alertTitle: 'Unstable network connection',
    alertText:
      'Your network connection is low or unstable.\nYou will now move to sign in page.\nYou can continue using our service when your network becomes stable.',
    buttonTextAndOnClick: [{ text: 'OK', onClick: () => logout(true) }],
  };

  const displayProfileName = userInfo?.companyName;

  const profileInfo = {
    academyName: displayProfileName,
    ecSeq: userInfo?.ecSeq,
    upFileSeq: userInfo?.academyUpFileSeq,
  };

  /** 오프라인시 유저 로그아웃 */
  const isOnline = useOnlineStatus();
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <S.Header className={scroll ? 'header bg' : 'header '}>
      {/* <ModalChuseok /> */}
      {/* <ImportantAnnouncementModal /> */}
      {/* 사용안하는 긴급공지 가림 - 24.7.22 */}
      {/* <EmergencyWarningModal /> */}
      <div className='com_center_wrap'>
        <div className='pg_tit__wrap'>
          {props.children}
          <button className='icon_area' onClick={logoClick}>
            <img src={ImgIconDashboard} alt='아이콘' className='icon' />
          </button>
          <h1 className='pg_tit'>{renderMainHeaderTitle()}</h1>

          {/* 개발중 타인존 확인을 위한 디버깅용 요소 */}
          <DebugTimezone />
        </div>
        <nav className='nav'>
          <MainApSelectButton />
          {
            // parent 일때 랜더링X
            !useLevels.isParent && !useLevels.isTubletTutor && userInfo?.ecSeq !== 0 && (
              <button className='menu' onClick={logoClick}>
                <ProfileImage className='gnb_profile_img' userInfo={profileInfo} isB2B={true} size='1.875rem' isAcademyImg />
                <p className='tit'>{displayProfileName}</p>
              </button>
            )
          }
          {(pathname.includes('/ap/')
            ? !useLevels.isParent && !useLevels.isTubletTutor && !useLevels.isStudent
            : !useLevels.isParent && !useLevels.isTubletTutor) && (
            <button className='menu' onClick={goToSetTest}>
              {/*Academy Admin, Tutor*/}
              <img src={ImgGnbTest} alt='Set Tests' className='icon' />
              <p className='tit'>Set Tests</p>
            </button>
          )}
          {/* GATE KEY 코드 */}
          {useLevels.isParent || useLevels.isTubletTutor || <GateKeyBtn remainingKey={remainingKey} isB2c={isB2c} />}
          <button className='menu' onClick={(e) => notificationPop(e)}>
            {/*Academy Admin, Student, Parent Dashboard, Tutor*/}
            {unseenNotificationsCnt > 0 && <span className='num'>{unseenNotificationsCnt}</span>}
            <img src={ImgGnbPush} alt='아이콘' className='icon' />
            <p className='tit'>Notifications</p>
          </button>
          {notification > 0 && <Notification notification={notification} close={() => setNotification(false)} />}
          {/* 메세지 기능 잠정 보류 */}
          {/* <div className='message_container'>
            <button className='menu' onClick={() => setShowMessagePopup(true)}>
              <img src={ImgGnbMessage} alt='아이콘' className='icon' />
              <p className='tit'>Message</p>
            </button>
            {showMessagePopup && <MessageContainer />}
          </div> */}
          {/* <button
            className='menu'
            onClick={() =>
              setAlertLayerPopup((prev) => {
                return {
                  ...prev,
                  visible: true,
                  alertMessage: 'Please stay tuned for future updates… it’s coming soon!',
                };
              })
            }>
            
            <img src={ImgGnbMessage} alt='아이콘' className='icon' />
            <p className='tit'>Message</p>
          </button> */}
          <UserProfileBtn setAlertLayerPopup={setAlertLayerPopup} />
        </nav>
      </div>

      {/* --- --- --- alert & modal 랜더링 부분 --- --- --- */}
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
      {!isOnline && (
        <ImageModal
          modalImage={offlineModalInfo.modalImage}
          buttonTextAndOnClick={offlineModalInfo.buttonTextAndOnClick}
          alertTitle={offlineModalInfo.alertTitle}
          alertText={offlineModalInfo.alertText}
        />
      )}
      {showPopup && <FreezingKeyModal hideModal={handleClosePopup} />}
      {isLoading && <LoadingBar type={'spin'} color={'#000000'} />}
    </S.Header>
  );
}

const S = {
  Header: styled.header`
    .nav {
    }

    .gnb_profile_img {
      margin-bottom: 0.4375rem;
    }
  `,
};
