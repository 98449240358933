import React, { useState, useRef, useEffect } from 'react';
import request from 'utils/Request.utils.js';
import { nvl, nvlNumber } from 'utils/Common.utils.js';
import { USER_LEVELS } from 'utils/constants.js';

const memberType = {
  [USER_LEVELS.STUDENT]: 'Student',
  [USER_LEVELS.TEACHER]: 'Academy Teacher', // 선생님
  [USER_LEVELS.TUTOR]: 'Private Tutor', // 강사
  [USER_LEVELS.ACADEMY]: 'Academy', // 학원 관리자
  [USER_LEVELS.PARENT]: 'Parent', // 학부모
};
const API_URL = process.env.REACT_APP_API_URL;

function OtherUserId({ accountInfo, size }) {
  const userType = memberType[accountInfo.userLevel] || 'Unknown';
  const isAcademy = !accountInfo.userName; // 아카데미 프로필인지 확인 (존재 여부로 학원과 사용자 구분)

  const profileType = isAcademy ? 'academyProfile' : 'userProfile';
  const upFileSeq = isAcademy ? accountInfo.academyUpFileSeq : accountInfo.upFileSeq;
  const profileImgSeq = isAcademy ? accountInfo.ecSeq : accountInfo.userSeq;
  const profileImage = nvlNumber(upFileSeq) > 0 ? `${API_URL}/api/common/profile/upload/${profileType}/${profileImgSeq}?${Date.now()}` : null;

  const displayName = isAcademy ? accountInfo.companyName : accountInfo.userName;

  return (
    <div className={`id ${size}`}>
      <ViewProfileImageSection profileImage={profileImage} altText={displayName} userType={userType} accountInfo={accountInfo} />
      <div className='info'>
        <p className='type'>{userType}</p>
        <p className='name'>{displayName}</p>
      </div>
    </div>
  );
}

// --- --- --- ---

function ViewProfileImageSection({ profileImage, altText, userType, accountInfo }) {
  const initial = altText?.[0]?.toUpperCase() || '';
  const addClassT = [USER_LEVELS.TEACHER, USER_LEVELS.TUTOR, USER_LEVELS.ACADEMY].includes(accountInfo.userLevel) ? 'T' : '';

  return (
    <div className={`com_profile_img m ${addClassT}`}>
      {profileImage ? <img className='profile-img-background' src={profileImage} alt='profile' /> : <span className='name'>{initial}</span>}
    </div>
  );
}

export default OtherUserId;
