import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconArrow } from 'assets/img/svg/icon_arrow_02.svg';

/** 정렬, 드롭다운 등에 사용 할 수 있는 3단계 (단계 선택 가능, 누를 때마다 순차적으로 변경) 토글 버튼 스타일 용 컴포넌트 */
export default function SortToggleButton({ children, className, handlerCallback, isReest, ...rest }) {
  /** 선택 할 수 있는 옵션 수 */
  const maxStep = 3;
  /** 버튼 상태 */
  const [current, setCurrent] = useState(0);

  /** 버튼 클릭 핸들러 함수 (토글 상태만을 담당) */
  const handler = () => {
    setCurrent((current) => {
      if (current + 1 >= maxStep) {
        return 0;
      } else {
        return current + 1;
      }
    });
    // 외부에서 props로 전달 받은 콜백 함수 (실제 기능을 담당)
    handlerCallback(current);
  };

  useEffect(() => {
    if (isReest) {
      // "isReest" props 가 넘어 올 경우, 해당 버튼은 기본 값으로 초기화
      setCurrent(0);
    }
  }, [isReest]);

  return (
    <S.Button type='button' onClick={handler} className={`${className} ${current === 1 ? 'up' : current === 2 ? 'down' : ''}`} {...rest}>
      {children}
      {current === 1 || current === 2 ? <ImgSvgIconArrow /> : <i className='icon_minus'>-</i>}
    </S.Button>
  );
}

const S = {
  Button: styled.button`
    .icon_minus {
      font-size: 1.2rem;
      font-weight: 500;
      margin-left: 0.25rem;
    }
    svg {
      transition: 0.2s;
    }
    &.up {
      svg {
        transform: rotate(-180deg);
      }
    }
    &.down {
      svg {
        transform: rotate(0);
      }
    }
  `,
};
