import React from 'react';
import styled from 'styled-components';
import Tooltip01 from 'components/_common/tooltips/Tooltip01';
import { ReactComponent as ImageSVGIconStar } from 'assets/img/svg/icon-star.svg';

// test list layer
const TestList = ({ item, status, page = 'none' }) => {
  let testList = !!item ? JSON.parse(item) : [];

  if (page === 'students') {
    testList = testList.filter((item) => {
      return item.ec_seq !== 0;
    });
  }

  return (
    <div style={{ width: '1.5rem', height: '1.5rem', borderRadius: '0.25rem', backgroundColor: '#E8F0FF' }} className='com_btn_report has_layer'>
      <i style={{ width: '1rem', height: '1rem' }} className='svg_icon icon_score_report s blue'>
        &nbsp;
      </i>
      <S.TransparentGap />
      <div className='com_list_layer'>
        <p className='tit_area'>Test List</p>
        <div className='list_area scroll'>
          {testList.length !== 0 ? (
            testList.map((item, idx) => {
              return (
                <div className='item' key={`test_${idx}`}>
                  <i className='svg_icon icon_report'>&nbsp;</i>
                  {item.test_name}
                  {item.ec_seq === 0 && (
                    <Tooltip01 text='This is a test you have set for yourself.'>
                      <ImageSVGIconStar />
                    </Tooltip01>
                  )}
                </div>
              );
            })
          ) : (
            <p className='option'>{status === 'E' ? 'No scheduled test yet' : 'No available test'}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestList;

const S = {};

S.TransparentGap = styled.div`
  position: absolute;
  top: 100%;
  left: -10%;
  right: -10%;
  height: 10px;
  z-index: 0;

  display: none;
`;
