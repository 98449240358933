import React from 'react';

//img
import IconOk from 'assets/img/icon_ok.svg';

/** tests/scheduled 페이지의 "학생 삭제 완료" 팝업 컴포넌트 */
function DeleteStudentAlert({
  setDeleteStudentAlert,
  callbackHandle = () => {
    console.log('콜백 핸들러를 전달해 주세요');
  },
}) {
  /** "Confirm" 버튼 핸들러 */
  const confirmHandler = () => {
    callbackHandle();
    setDeleteStudentAlert(false);
  };

  return (
    <div className={'com_popup type_alert active'}>
      <div className='pop_container'>
        <div className='pop_body ok_contents'>
          <div
            className='icon'
            style={{
              marginTop: '0',
            }}>
            <img
              src={IconOk}
              style={{
                height: '4.44rem',
                marginBottom: '2rem',
              }}
              alt='icon'
            />
          </div>
          <p className='alert_title'>Successfully deleted</p>
        </div>
        <div className='pop_footer com_btn_wrap'>
          <button className='com_btn point xl full' onClick={confirmHandler}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteStudentAlert;
