import styled from 'styled-components';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
//component
import MiniAlert from 'components/_common/alerts/MiniAlert'; //한줄 알럿
//img
import ImgNoData from 'assets/img/no_push.svg';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { nvl } from 'utils/Common.utils';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

const NOTI_PURPOSE = {
  Pending: 'Pending',
  Completed: 'Completed',
  Request: 'Request',
};

const Notification = ({ notification, close }) => {
  const userInfo = request.tokenDecoder();
  const [isLoading, setIsLoading] = useState(false);

  //tab
  const [tabState, setTabState] = useState(0);
  const tab = [
    { text: 'ALL', value: 0 },
    { text: 'Enrolled', value: 1, color: 'enrolled' },
    { text: 'Score', value: 2, color: 'score' },
    { text: 'GATEPLUS', value: 3, color: 'news' },
  ];

  const renderTab = useCallback(() => {
    return tab.map((v, idx) => {
      return (
        <button key={idx} className={`tab ${(tabState === v.value && 'active') || ''} ${v.color}`} onClick={() => setTabState(v.value)}>
          {v.text}
        </button>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  //더보기 버튼 클릭 시
  const [more, setMore] = useState(false);
  const ClickMore = () => {
    setMore((prevState) => !prevState);
  };

  const modalRef = useRef(null);

  // 컴포넌트 밖 클릭시
  useEffect(() => {
    const handleClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        close();
      }
    };
    getNoti();

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRef]);
  const [alert, alertSet] = useState(false); //한줄 알럿

  // 알림 리스트
  const [notiList, setNotiList] = useState([]);
  const [notiSeqList, setNotiSeqList] = useState('');
  const [globalNotiSeqList, setGlobalNotiSeqList] = useState('');

  const getNoti = () => {
    const successHandler = (response) => {
      console.log(response);

      if (response.code === 200) {
        const notiList = response.result.notificationList;
        const globalNoti = response.result.globalNotificationList;

        const combinedNoti = notiList.concat(globalNoti);
        let sortNoti = combinedNoti.sort((a, b) => {
          return isoTimeToDayjs(b.regDate).diff(isoTimeToDayjs(a.regDate));
        });

        if (sortNoti.length > 50) sortNoti = sortNoti.slice(0, 50);

        const dateList = sortNoti.map((i) => isoTimeToDayjs(i.regDate).format('LL'));
        const uniqueData = [...new Set(dateList)];

        const courseList = [];

        for (let i = 0; i < uniqueData.length; i++) {
          const regDate = uniqueData[i];

          const filterList = sortNoti.filter((i) => isoTimeToDayjs(i.regDate).format('LL') === regDate);
          courseList.push(filterList);
        }

        setNotiList(courseList);

        // 전체 알림 읽기 스트링 만들기
        const notiSeqList = notiList.filter((i) => nvl(i.notiStatus) === '' && i.purpose !== 'Request').map((i) => i.notiSeq);
        const notiSeqStr = notiSeqList.join('|');

        const globalNotiSeqList = globalNoti.filter((i) => nvl(i.notiStatus) === '').map((i) => i.globalNotiSeq);
        const globalNotiSeqStr = globalNotiSeqList.join('|');

        setNotiSeqList(notiSeqStr);
        setGlobalNotiSeqList(globalNotiSeqStr);
        setIsLoading(false);
      }
    };

    request
      .get(`/api/etc/notifications?userSeq=${userInfo?.userSeq}&userLevel=${userInfo?.userLevel}`, null, successHandler)
      .catch((error) => console.error(error));
  };

  // 읽음 처리
  const readNoti = (seqStr, globalSeq) => {
    setIsLoading(true);
    let pms = {
      userSeq: userInfo.userSeq,
      notiStatus: 'R',
      seqStr: nvl(seqStr) === '' ? 0 : seqStr,
      globalSeqStr: nvl(globalSeq) === '' ? 0 : globalSeq,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        getNoti();
      }
    };

    request.post('/api/etc/notifications/history', pms, successHandler).catch((error) => console.error(error));
  };

  // 전체 읽음
  const handleReadAll = () => {
    setMore(false);
    if (nvl(notiSeqList) !== '' || nvl(globalNotiSeqList !== '')) readNoti(notiSeqList, globalNotiSeqList);
  };

  // 연결 수락, 거절
  const notiConfirm = (e, notiSeq, senderSeq, purpose, userLevel, status) => {
    if (isLoading) return;
    e.stopPropagation();
    setIsLoading(true);

    let pms = {
      notiSeq: notiSeq,
      userSeq: userInfo.userSeq,
      ecSeq: userInfo.ecSeq,
      notiStatus: status,
      senderSeq: senderSeq,
      purpose: purpose,
      userLevel: userLevel,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        requestApprovalStatus(senderSeq, status);
      }
    };

    request.post('/api/etc/notifications/enrolled', pms, successHandler).catch((error) => console.error(error));
  };

  const requestApprovalStatus = (senderSeq, notiStatus) => {
    let pms = {
      senderSeq: userInfo.userSeq,
      regUserSeq: userInfo.userSeq,
      userLevel: userInfo.userLevel,

      userSeq: senderSeq,
      ecSeq: userInfo.ecSeq,
      notiStatus: notiStatus,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        getNoti();
      }
    };

    request.post('/api/etc/notifications/link/result', pms, successHandler).catch((error) => console.error(error));
  };

  return (
    <>
      <div className={`notification ${notification ? 'active' : ''}`} ref={modalRef}>
        <div className='tit__wrap'>
          <h2 className='tit'>Notification</h2>
          <button className='btn_more' onClick={ClickMore}>
            <i className='svg_icon icon_more'>&nbsp;</i>
          </button>
          <div className={`more_layer ${more ? 'active' : ''}`}>
            <button className='btn' onClick={handleReadAll}>
              Mark All As Read
            </button>
          </div>
        </div>
        <div className='tab_wrap'>{renderTab()}</div>
        {tabState === 0 ? (
          <>
            {notiList && notiList.length > 0 ? (
              <NotiContent notiList={notiList} category={'all'} readNoti={readNoti} notiConfirm={notiConfirm} />
            ) : (
              <EmptyNoti />
            )}
          </>
        ) : tabState === 1 ? (
          <>
            {notiList && notiList.flatMap((item) => item.filter((i) => i.category === 'enrolled')).length > 0 ? (
              <NotiContent notiList={notiList} category={'enrolled'} readNoti={readNoti} notiConfirm={notiConfirm} />
            ) : (
              <EmptyNoti />
            )}
          </>
        ) : tabState === 2 ? (
          <>
            {notiList && notiList.flatMap((item) => item.filter((i) => i.category === 'score')).length > 0 ? (
              <NotiContent notiList={notiList} category={'score'} readNoti={readNoti} />
            ) : (
              <EmptyNoti />
            )}
          </>
        ) : tabState === 3 ? (
          <>
            {notiList && notiList.flatMap((item) => item.filter((i) => i.category === 'news')).length > 0 ? (
              <NotiContent notiList={notiList} category={'news'} readNoti={readNoti} />
            ) : (
              <EmptyNoti />
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      {/*Accepted 배너형 알럿*/}
      <MiniAlert text='You have been successfully linked with [NAME].' active={alert} inactive={() => alertSet(false)} timeOut={2000} />
      {/*Deny 배너형 알럿*/}
      <MiniAlert text='You have denied link request from [NAME].' active={alert} inactive={() => alertSet(false)} timeOut={2000} />
    </>
  );
};

export default Notification;

function NotiContent({ notiList, category, readNoti, notiConfirm }) {
  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a key={index} href={part} target='_blank' rel='noopener noreferrer' style={{ color: 'purple' }}>
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    <div className='tab_contents scroll'>
      {nvl(notiList) !== '' &&
        notiList.map((item, index) => (
          <Fragment key={`noti_list_${index}`}>
            {item.filter((i) => category === 'all' || i.category === category).length > 0 && (
              <div className='date'>{isoTimeToDayjs(item[0].regDate).format('LL')}</div>
            )}
            {item
              .filter((i) => category === 'all' || i.category === category)
              .map((innerItem, innerIndex) => (
                <Fragment key={`inner_list_${innerIndex}`}>
                  <div
                    className={`item ${nvl(innerItem.notiStatus) !== '' ? 'off' : ''}`}
                    onClick={() =>
                      nvl(innerItem.notiStatus) === '' && innerItem.purpose !== NOTI_PURPOSE.Request
                        ? readNoti(innerItem.notiSeq, innerItem.globalNotiSeq)
                        : null
                    }>
                    <S.NotiTitleArea>
                      <div className='noti_title_area'>
                        <S.Dot className={innerItem.category} />
                        <p className='noti_title'>{innerItem.tit}</p>
                      </div>

                      {(innerItem.purpose === NOTI_PURPOSE.Pending || innerItem.purpose === NOTI_PURPOSE.Completed) && (
                        <p className={`purpose ${innerItem.purpose}`}>
                          {innerItem.purpose} {/* Pending, Completed */}
                        </p>
                      )}
                    </S.NotiTitleArea>

                    <div className='txt'>
                      {urlify(innerItem.content)}
                      {nvl(innerItem.notiStatus) === '' && innerItem.purpose === NOTI_PURPOSE.Request && (
                        <div className='com_btn_wrap right'>
                          <button
                            className='com_btn s line point'
                            onClick={(e) => notiConfirm(e, innerItem.notiSeq, innerItem.senderSeq, innerItem.purpose, innerItem.userLevel, 'D')}>
                            Deny
                          </button>
                          <button
                            className='com_btn s point'
                            onClick={(e) => notiConfirm(e, innerItem.notiSeq, innerItem.senderSeq, innerItem.purpose, innerItem.userLevel, 'A')}>
                            Accept
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </Fragment>
              ))}
          </Fragment>
        ))}
    </div>
  );
}

function EmptyNoti() {
  return (
    <div className='tab_contents scroll'>
      <div className='no_data'>
        <img src={ImgNoData} alt='icon' />
        Be prepared... soon your notifications will be flooded!
      </div>
    </div>
  );
}

const S = {
  NotiTitleArea: styled.div`
    display: flex;
    flex-direction: column;
    width: 5.44rem;

    .item.off & {
      color: #a5aab1;
    }

    .noti_title_area {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      .noti_title {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    .purpose {
      padding-left: 0.94rem;
      font-size: 0.8125rem;
      &.Pending {
        color: #f24b4b;
      }
      &.Completed {
        color: #00a65d;
      }
    }
  `,
  Dot: styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: transparent;

    .item.off & {
      background-color: #a5aab1;
    }

    &.enrolled {
      background-color: #0068bd;
    }
    &.score {
      background-color: #fad92a;
    }
    &.news {
      background-color: #00a65d;
    }
  `,
};
