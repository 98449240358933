import styled from 'styled-components';

export default function SubjectList({ apSubject, setSelectedSubject }) {
  return (
    <S.LeftArea>
      <div className='tit'>Subject</div>
      <div className='list custom_scroll'>
        {apSubject &&
          apSubject.map((item, index) => (
            <div className='input_field' key={index}>
              <input
                type='radio'
                name='radio111'
                className='com_chk radio'
                id={`subject_${item.subjectSeq}`}
                value={item.subjectSeq}
                onChange={(e) => setSelectedSubject(e.target.value)}
              />
              <label htmlFor={`subject_${item.subjectSeq}`} style={{ whiteSpace: 'normal' }}>
                {item.subjectName}
              </label>
            </div>
          ))}
      </div>
    </S.LeftArea>
  );
}

const S = {
  LeftArea: styled.div`
    max-width: 21.62%;

    .tit {
      padding: 1rem;
      color: #111;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
    }

    .list {
      max-height: 50rem;
      overflow-x: hidden;
      width: 100%;
    }

    .input_field {
      padding: 10px;
      gap: 30px;
      border: 1px solid #f1f1f5;
    }
  `,
};
