import { useEffect, useState } from 'react';
import { nvl } from 'utils/Common.utils.js';
import request from 'utils/Request.utils.js';

import dayjsTZ, { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

//components
import CustomAlert from 'components/_common/alerts/CustomAlert.jsx';
import MainLink from 'components/_common/button/_MainLinks.jsx';
import Paging from 'layout/Paging'; //페이징
import styled from 'styled-components';
import ClassesMainTable from './_components/table/classesMainTableArea/ClassesMainTable.jsx';
import TopBtnArea from './_components/table/classesMainTableArea/TopBtnArea.jsx';

// import PdfDownloadButton from '../_common/PdfDownloadButton.jsx'

/**
 * classes 페이지
 * @description
 * 🔍 검색 키워드 - #classes #page #com/students/classes
 */
export default function ClassesMainPage() {
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 시작 ////////////
  /** CustomAlert 코드 */
  let alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  /////////////////// 컴포넌트 내 전역 상수 및 변수 선언 영역 끝 /////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [userInfo, _] = useState(request.tokenDecoder());
  const [lineLength, setLineLength] = useState(10);
  /** Class 수정 모달 노출 여부 */
  const [openClassManage, setOpenClassManage] = useState(false);
  /** Edit 버튼 코드 */
  const [editClassData, setEditClassData] = useState(null);
  const [searchInfo, setSearchInfo] = useState({
    isDateRangeSelected: false,
    searchStartDate: dayjsTZ().toDate(),
    searchEndDate: dayjsTZ().toDate(),
  });
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchData, setSearchData] = useState([]);
  /** check 버튼 코드 */
  const [checkedClassIds, setCheckedClassIds] = useState([]);
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  const [pagingInfo, setPagingInfo] = useState({
    totalCount: 10,
    currentPage: 1,
    numPerPage: lineLength,
    pagePerBlock: 10,
  });
  /** API 로딩 상태 */
  const [isLoading, setIsLoading] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  //////////////////// START --- API 호출 --- START ////////////////////
  /** Class 목록 불러오기 API 요청 */
  const searchClasses = () => {
    setIsLoading(true);

    const successHandler = (response) => {
      if (response.code === 200) {
        const { totalCount, classList } = response.result;

        setPagingInfo((prev) => {
          return { ...prev, totalCount, pagePerBlock: 10 };
        });

        const searchData = classList.map((data) => {
          return {
            classId: data.classSeq,
            classTitle: data.className,
            companyName: data.companyName,
            subjectName: data.subjectName,
            startDate: isoTimeToDayjs(data.classStartDate),
            endDate: isoTimeToDayjs(data.classEndDate),
            studentCount: data.studentCount,
          };
        });
        setSearchData(searchData);
      }
    };

    let orderBy = 'reg_date DESC';

    const searchStartDate = encodeURIComponent(dayjsTZ(searchInfo.searchStartDate).startOf('day').format());
    const searchEndDate = encodeURIComponent(dayjsTZ(searchInfo.searchEndDate).endOf('day').format());

    // 전체검색, 날짜검색, 날짜검색&키워드검색
    // 검색 날짜를 선택 안하고, searchKeyword가 빈문자열(검색어 지정 안함)이면 - 전체 날짜, 모든 키워드 검색
    if (!searchInfo.isDateRangeSelected && searchKeyword === '') {
      request.apGet(`/api/v1/academy/student/class/list`, null, successHandler).finally(() => {
        setIsLoading(false);
      });
    }
  };
  ////////////////////  END  --- API 호출 ---  END  ////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  /** Class 수정 화면 보기 핸들러 */
  const handleShowEdit = (classId = null) => {
    if (classId === null) {
      // 매개변수 "classId" 를 전달 받지 않았을 경우 => "Edit" 버튼으로 접근 했을 경우
      if (checkedClassIds.length !== 1) {
        // 선택 된 Class 가 없거나, 2개 이상 일 경우 -> 경고
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'You can only edit one class at a time',
          };
        });
        return;
      } else {
        // 선택 된 Class 가 1개인 경우 -> 정상
        classId = checkedClassIds[0];
      }
    }
    // 나머지 = 매개변수 "classId" 를 전달 받는 경우 => 목록을 직접 더블 클릭해서 접근 했을 경우

    /** 편집 할 Class Data */
    let selectedEditData = searchData.find((item) => {
      return item.classId === classId;
    });
    selectedEditData = {
      ...selectedEditData,
      startDate: selectedEditData.startDate,
      endDate: selectedEditData.endDate,
    };
    setEditClassData(selectedEditData);
    setOpenClassManage(true);
  };

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  const nextPageMove = (value) => {
    setPagingInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 ////////////////
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        returnValue: (value) => {
          if (nvl(value) === 'OK') {
            // closeCustomAlert()
          }
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertLayerPopup?.id]);

  useEffect(() => {
    if (userInfo.ecSeq > 0) searchClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingInfo.currentPage, pagingInfo.numPerPage, searchInfo]);

  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <S.Wrap className='p_classes'>
        <MainLink type='students' />
        <div className='com_center_wrap'>
          <div className='com_box'>
            <div className='com_list'>
              <section className='top'>
                <TopBtnArea
                  setEditClassData={setEditClassData}
                  setOpenClassManage={setOpenClassManage}
                  checkedClassIds={checkedClassIds}
                  setAlertLayerPopup={setAlertLayerPopup}
                  searchInfo={searchInfo}
                  searchKeyword={searchKeyword}
                  searchData={searchData}
                  searchClasses={searchClasses}
                  handleShowEdit={handleShowEdit}
                  openClassManage={openClassManage}
                  editClassData={editClassData}
                />
                {/* <SearchArea
                  setSearchInfo={setSearchInfo}
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  searchClasses={searchClasses}
                  setLineLength={setLineLength}
                  setPagingInfo={setPagingInfo}
                /> */}
              </section>
              <ClassesMainTable
                setCheckedClassIds={setCheckedClassIds}
                searchData={searchData}
                checkedClassIds={checkedClassIds}
                lineLength={lineLength}
                handleShowEdit={handleShowEdit}
                pagingInfo={pagingInfo}
                isLoading={isLoading}
              />
            </div>
            <Paging
              totalCount={pagingInfo.totalCount}
              currentPage={pagingInfo.currentPage}
              numPerPage={pagingInfo.numPerPage}
              pagePerBlock={pagingInfo.pagePerBlock}
              nextPageMove={nextPageMove}
            />
          </div>
        </div>
      </S.Wrap>

      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </>
  );
}

const S = {
  Wrap: styled.div`
    position: relative;
  `,
};
