import React from 'react';
import styled from 'styled-components';
import { ReactComponent as IconDel } from 'assets/img/x-circle_icon.svg';

/** 로그인 & 회원가입 용 인풋 박스 스타일 컴포넌트 */
export default function SignInputBase({ inputReset, children }) {
  return (
    <S.Wrap>
      {children}
      <button onMouseDown={inputReset} title='입력 된 내용 삭제' className='btn_del'>
        <IconDel />
      </button>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid var(--input-stroke);
  background-color: #fff;
  border-radius: 0.25rem;
  transition: 0.3s;
  font-size: 0.875rem;
  height: 3.375rem;
  label {
    display: none;
  }
  input {
    max-height: 2.625rem;
    border: none;
    &::placeholder {
      font-size: 0.875rem;
      color: #a6b3be;
    }
  }
  .icon {
    color: #a6b3be;
    width: 1.375rem;
  }
  .btn_del {
    flex: 1 0 1.375rem;
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: 0.2s;
    transform: scale(0);
    svg {
      width: 100%;
      height: 100%;
    }
    &:focus {
      outline: 1px solid var(--point-color);
      transform: scale(1);
    }
  }
  &:has(input:focus) {
    border-color: var(--point-color);
    .icon {
      color: #333333;
    }
    .btn_del {
      transform: scale(1);
      /* position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%); */
    }
  }
  &:focus-within {
    border-color: var(--point-color);
  }
  &:has(input:read-only) {
    background-color: #f4f4f4;
    border-color: #d2dbe2;
    .sign_up_input {
      color: #a6b3be;
      &::placeholder {
        color: #a6b3be;
      }
    }
  }
`;
