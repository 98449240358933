import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import TestEditComBtn from 'components/ap/com/ap-components/_components/TestEditComBtn';
import SourcesForReview from 'components/ap/com/ap-components/_components/SourcesForReview';

import QuestionEditor from './_components/QuestionEditor';
import AnswerChoiceList from './_components/AnswerChoiceList';
import SourceContainer from './_components/SourceContainer';

import TestEditorHeader from './_components/TestEditorHeader';
import ApExamFooter from '../_components/layout/ApExamFooter';
import { useDispatch, useSelector } from 'react-redux';

import { transformChoicesToBackend, transformChoicesToFrontend } from './_utils';

import {
  addQuestion,
  updateQuestion,
  updateApTestSeq,
  updateCurrentQuestionIndex,
  updateCurrentPartData,
  updateCurrentSectionData,
} from 'reducers/apTestEditor/action';

import useTestEditor from './useTestEditor';

import { EDITOR_CONSTANT, QUESTION_TYPE_CONSTANT } from './_constant';

//#region    ////////////////// START --- UI 컴포넌트 --- START ////////////////////
import UI_ExamContainer from './_components/ui/UI_ExamContainer';
import UI_EditorContainer from './_components/ui/UI_EditorContainer';
import SourceDirectionArea from './_components/SourceDirectionArea';
import MiniAlert from 'components/_common/alerts/MiniAlert';
//#endregion //////////////////  END  --- UI 컴포넌트 ---  END  ////////////////////

/** QuestionBank에서 Test를 Edit 하는 페이지 */
export default function QuestionBankTestEditorPage() {
  const {
    getTestMetadata,
    getEditingTestData,
    postApTestData,
    // State
    editorState,
    // State handler
    editorStateSetter,
    editorStateUpdater,
    editorStateMutator,
    // helper functions
    getCurrentPartQuestion,
    loadQuestionData,
    validateQuestionsCount,
  } = useTestEditor();

  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const dispatch = useDispatch();
  const apTestEditorReducer = useSelector((state) => state.apTestEditorReducer);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  /** Dispatch 용 최초 문제 데이터 */
  const questionData = useMemo(
    () => ({
      ...editorState[EDITOR_CONSTANT.currentQuestion],
      part_seq: editorState[EDITOR_CONSTANT.currentPart].part_seq,
      source_order: [0],
      // source_content: editorState[EDITOR_CONSTANT.sourceContent],
      source_content: [''], // 🧰 데이터들중 source_content가 잘못 들어간 케이스가 종종 있어 명시적 초기화

      answer_options: {
        // 백엔드에서 사용하는 데이터 형식으로 변환
        ...transformChoicesToBackend(editorState[EDITOR_CONSTANT.answerChoiceInfo]),

        total:
          editorState[EDITOR_CONSTANT.currentPart].type_of_question === QUESTION_TYPE_CONSTANT.MCQ
            ? editorState[EDITOR_CONSTANT.answerChoiceInfo].answerChoices.length
            : 1,
        answerCount: editorState[EDITOR_CONSTANT.currentQuestion].answer.length,
      },
      answer: editorState[EDITOR_CONSTANT.currentQuestion].answer,
      question_order: editorState[EDITOR_CONSTANT.currentIndex],
      // custom_question_order: editorState[EDITOR_CONSTANT.currentQuestion].custom_question_order.toString(),
      custom_question_order: null, // 기획 변경으로 보류
      question_type: editorState[EDITOR_CONSTANT.currentPart].type_of_question,
      question_content: editorState[EDITOR_CONSTANT.currentQuestion].question_content,
      score: editorState[EDITOR_CONSTANT.currentQuestion].score,
      question_code: editorState[EDITOR_CONSTANT.currentQuestion].question_code,
      question_context: editorState[EDITOR_CONSTANT.currentQuestion].question_context,
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorState]
  );
  //#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //#endregion //////////////////  START  --- useState 선언 ---  START  ////////////////////
  /** 미니 알림 창(토스트 메세지) 노출용 상태 */
  const [alert, alertSet] = useState({
    isView: false,
    text: `.`,
  });
  //#endregion //////////////////  END  --- useState 선언 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** "현재 Source" 변경 */
  const moveCurrentSource = ({ order = 0 }) => {
    editorStateSetter(EDITOR_CONSTANT.currentSourceOrder, order);
  };

  /** 테스트 State 저장 */
  const handleSaveAllContent = () => {
    // 소스 내용 저장
    editorStateUpdater(EDITOR_CONSTANT.currentQuestion, { source_content: editorState[EDITOR_CONSTANT.sourceContent] });

    const currentPartSeqQuestions = getCurrentPartQuestion();

    // 현재 파트와 현재 문제 번호가 일치하면 해당 데이터 업데이트
    if (currentPartSeqQuestions && currentPartSeqQuestions.find((question) => question.question_order === editorState[EDITOR_CONSTANT.currentIndex])) {
      dispatch(updateQuestion(editorState[EDITOR_CONSTANT.currentPart].part_seq, editorState[EDITOR_CONSTANT.currentIndex], questionData));
      // 현재 정보와 일치하는 데이터 없을경우 새로운 데이터 추가
    } else {
      dispatch(addQuestion(questionData));
    }
  };
  /** 테스트 서버 저장 */
  const handlePostApTestData = async () => {
    const currentPartSeqQuestions = getCurrentPartQuestion();

    /** 업데이트 or 새로운 데이터가 추가된 데이터 배열 */
    let questionsToSend;
    if (currentPartSeqQuestions && currentPartSeqQuestions.find((question) => question.question_order === editorState[EDITOR_CONSTANT.currentIndex])) {
      questionsToSend = apTestEditorReducer.questions.map((question, index) => (index === editorState[EDITOR_CONSTANT.currentIndex] ? questionData : question));
    } else {
      questionsToSend = [...apTestEditorReducer.questions, questionData];
    }

    // 수정된 데이터로 API 호출
    await postApTestData('Editing', questionsToSend).then(() => {
      alertSet((current) => {
        return { ...current, isView: true, text: 'Saved' };
      });
    });
    handleSaveAllContent(); // 전역 상태 업데이트
  };
  /** 테스트 게시 */
  const handlePublish = async () => {
    const currentPartQuestions = getCurrentPartQuestion();

    /** 업데이트 or 새로운 데이터가 추가된 데이터 배열 */
    let questionsToSend;
    if (currentPartQuestions.find((question) => question.question_order === editorState[EDITOR_CONSTANT.currentIndex])) {
      questionsToSend = apTestEditorReducer.questions.map((question) => {
        if (question.question_order === editorState[EDITOR_CONSTANT.currentIndex] && question.part_seq === editorState[EDITOR_CONSTANT.currentPart].part_seq) {
          return questionData;
        } else {
          return question;
        }
      });
    } else {
      questionsToSend = [...apTestEditorReducer.questions, questionData];
    }
    // 문제 수 검증
    const isValidPublish = validateQuestionsCount(questionsToSend);

    if (isValidPublish) {
      if (confirm('Some questions have not been set. If you have any unfinished questions, please go back and complete your test.')) {
        await postApTestData('Completed', questionsToSend);
      }
    } else {
      handleSaveAllContent();
    }
  };

  /** "Add Source" 버튼 핸들러 */
  const handleAddSource = () => {
    /** 새로 추가 할 Source */
    const newSource = {
      id: 0,
      order: editorState.currentQuestion.source_group.sources.length,
      content: '',
    };

    editorStateMutator(EDITOR_CONSTANT.currentQuestion, (prev) => {
      return {
        ...prev,
        source_group: {
          ...prev.source_group,
          sources: prev.source_group.sources.concat(newSource),
        },
      };
    });

    moveCurrentSource({ order: editorState.currentQuestion.source_group.sources.length });
  };

  /** "Source 삭제" 버튼 핸들러 */
  const handleDeleteSource = () => {
    const newData = editorState[EDITOR_CONSTANT.currentQuestion].source_group.sources.filter((item) => {
      return Number(item.order) !== Number(editorState[EDITOR_CONSTANT.currentSourceOrder]);
    });

    moveCurrentSource({ order: newData.length - 1 });

    editorStateMutator(EDITOR_CONSTANT.currentQuestion, (prev) => {
      return {
        ...prev,
        source_group: {
          sources: newData,
        },
      };
    });
  };

  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    const loadInitialData = () => {
      // 이미 리덕스에 데이터가 있는 경우 editorState 업데이트
      if (apTestEditorReducer.questions.length > 0 && apTestEditorReducer.sectionMetadata) {
        const currentSection = apTestEditorReducer.currentSectionData;
        const currentPart = apTestEditorReducer.currentPartData;
        const firstQuestion = apTestEditorReducer.questions.find((question) => question.question_order === 0);

        // editorState 초기화
        editorStateSetter(EDITOR_CONSTANT.currentIndex, firstQuestion.question_order);
        editorStateSetter(EDITOR_CONSTANT.currentSection, currentSection);
        editorStateSetter(EDITOR_CONSTANT.currentPart, currentPart);
        editorStateSetter(EDITOR_CONSTANT.currentQuestion, firstQuestion);
        editorStateSetter(EDITOR_CONSTANT.answerChoiceInfo, { answerChoices: transformChoicesToFrontend(firstQuestion.answer_options) });
      } else {
        // query string에서 test_seq를 받아와서 해당 데이터를 불러온다.
        const urlParams = new URLSearchParams(window.location.search);
        const test_seq = urlParams.get('test_seq');
        const isNew = urlParams.get('isNew');

        // 유효하지 않은 test_seq시 테스트 생성으로 방출
        if (!test_seq) {
          alert('test_seq is not exist');
          window.location.href = '/ap/question-bank/create-test';
          return;
        }

        dispatch(updateApTestSeq({ test_seq })); // 초기값 설정
        // create-test 페이지에서 생성된 테스트일 경우
        if (isNew) {
          getTestMetadata(test_seq); // 테스트 메타데이터 불러오기 & 프론트에서 파트데이터 설정
          // 기존 테스트 편집 일때
        } else {
          getEditingTestData(test_seq); // 수정중이던 테스트 데이터 불러오기
        }
      }
    };

    loadInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 파트 또는 문제 번호가 변경될 때마다 데이터 로드
  useEffect(() => {
    loadQuestionData(editorState[EDITOR_CONSTANT.currentPart].part_seq, editorState[EDITOR_CONSTANT.currentIndex]);

    dispatch(updateCurrentQuestionIndex(editorState[EDITOR_CONSTANT.currentIndex]));
    dispatch(updateCurrentPartData(editorState[EDITOR_CONSTANT.currentPart]));
    dispatch(updateCurrentSectionData(editorState[EDITOR_CONSTANT.currentSection]));

    moveCurrentSource({ order: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState[EDITOR_CONSTANT.currentPart].part_seq, editorState[EDITOR_CONSTANT.currentIndex]]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  // console.log(`\n┏━━━ 💡 💡 apTestEditorReducer 💡 💡 ━━━\n`, apTestEditorReducer, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);

  return (
    <S.QuestionBankTestEditorPageWrapper>
      <TestEditorHeader
        editorState={editorState}
        currentQuestion={questionData}
        handlePostApTestData={handlePostApTestData}
        handlePublish={handlePublish}
        handleSaveAllContent={handleSaveAllContent}
      />

      <S.TestEditorPageWrap className='custom_scroll'>
        <UI_ExamContainer className='left'>
          <UI_EditorContainer>
            <SourceDirectionArea editorState={editorState} editorStateMutator={editorStateMutator} />

            {editorState.currentQuestion.source_group.sources.length > 0 && (
              <>
                {editorState.currentQuestion.source_group.sources.length > 1 && (
                  <S.SourcesForReviewContainer>
                    <S.SectionTitle>Sources for Review : </S.SectionTitle>
                    <SourcesForReview
                      sourceData={editorState.currentQuestion.source_group.sources}
                      currentOrder={editorState.currentSourceOrder}
                      handleClickReviewItem={(item) => {
                        moveCurrentSource({ order: item.order });
                      }}
                    />
                  </S.SourcesForReviewContainer>
                )}

                <SourceContainer
                  sourceOrder={editorState[EDITOR_CONSTANT.currentSourceOrder]}
                  editorState={editorState}
                  editorStateMutator={editorStateMutator}
                  handleDelete={handleDeleteSource}
                />
              </>
            )}

            <div className='source_btn_container'>
              <TestEditComBtn title='Source' onClick={handleAddSource} variant='blue' />
              {/* <TestEditComBtn title='Source Footnote' onClick={() => console.log('콜백 주세영')} /> */}
            </div>
          </UI_EditorContainer>
        </UI_ExamContainer>

        <S.VerticalDivider />

        <UI_ExamContainer className='right'>
          <UI_EditorContainer>
            {/* <TestEditComBtn title='Question' onClick={() => console.log('콜백 주세영')} /> */}

            <QuestionEditor currentQuestion={editorState[EDITOR_CONSTANT.currentQuestion]} editorStateUpdater={editorStateUpdater} />

            {editorState[EDITOR_CONSTANT.currentPart].type_of_question === QUESTION_TYPE_CONSTANT.MCQ && (
              <>
                <S.HorizontalDivider />

                <AnswerChoiceList editorState={editorState} editorStateUpdater={editorStateUpdater} />
              </>
            )}
          </UI_EditorContainer>
        </UI_ExamContainer>
      </S.TestEditorPageWrap>
      <ApExamFooter
        editorState={editorState}
        handleSaveAllContent={handleSaveAllContent}
        editorStateMutator={editorStateMutator}
        editorStateSetter={editorStateSetter}
      />

      {alert.isView && (
        <MiniAlert
          text={alert.text}
          type=''
          active={alert.isView}
          inactive={() =>
            alertSet((current) => {
              return { ...current, isView: false };
            })
          }
          timeOut={1500}
        />
      )}
    </S.QuestionBankTestEditorPageWrapper>
  );
}

// --- --- --- Styled components --- --- ---
const S = {
  QuestionBankTestEditorPageWrapper: styled.div`
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,

  TestEditorPageWrap: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    & > * {
      padding-top: 4.435rem;
    }
  `,

  VerticalDivider: styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 1px;
    background-color: black;
  `,
  HorizontalDivider: styled.div`
    height: 2px;
    width: 100%;
    background-color: #767676;
  `,

  SourcesForReviewContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,

  AnswerChoiceContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  `,
  AnswerChoiceList: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
};
