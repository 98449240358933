import { useState } from 'react';
import CustomCheckbox from 'components/_common/CustomCheckbox';
import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/icon_arrow_02.svg';
import styled from 'styled-components';

/** source 데이터를 표시하는 접히는 컴포넌트 */
export default function ExpendedItemList({ data, setData, headerIndex }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <S.SourceItem>
      {headerIndex > 0 && <div className='divider' />}
      <div className='source_item'>
        <div className='source_header'>
          <h3>{data.title}</h3>
          <button className={`toggle ${isExpanded ? 'expanded' : ''}`} onClick={() => setIsExpanded(!isExpanded)}>
            <ArrowIconSelect />
          </button>
        </div>
        <div className={`source_content_wrap ${isExpanded ? 'expanded' : ''}`}>
          {data.content.map((sourceContent, contentIndex) => {
            return (
              <div className='source_content' key={contentIndex}>
                <span className='label'>{sourceContent.title}</span>
                <CustomCheckbox
                  className='source_checkbox'
                  size='1.25rem'
                  checkBoxId={`${sourceContent.title}_${headerIndex}_${contentIndex}`}
                  isChecked={sourceContent.isActive}
                  onChange={() => {
                    setData((prev) => {
                      const newData = [...prev];
                      newData[headerIndex].content[contentIndex].isActive = !newData[headerIndex].content[contentIndex].isActive;
                      return newData;
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </S.SourceItem>
  );
}

const S = {
  SourceItem: styled.li`
    .source_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.75rem 0;

      h3 {
        color: #111111;
        font-family: 'Roboto-Medium', Helvetica;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
      }

      .toggle {
        width: 24px;
        height: 24px;
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;
        transition: transform 0.3s ease;

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    .source_content_wrap {
      display: none;
      &.expanded {
        display: block;
      }

      .source_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 34px;
        margin-bottom: 0.75rem;

        .source_checkbox {
          margin-right: 0.1875rem;
        }
      }
    }
  `,
};
