import React from 'react';

/**
 * Classes에서 class 정보 편집 모달의 우측에 있는 Class Details 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/students/classes
 */
export default function ClassDetails({ classInfo, handleShowProfile, checkedStudents, onCheckboxChange }) {
  return (
    <>
      {/*4.Class Details*/}
      <article className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon bullet_4 black'>&nbsp;</i>
          Class Details
        </h2>
      </article>
      <article className='class_info'>
        <div className='item'>
          <div className='tit'>Class</div>
          <div className='info'>
            <p className='name'>{classInfo?.className.value ? classInfo.className.value : '-'}</p>
          </div>
        </div>
        <div className='item'>
          <div className='tit'>Date</div>
          <div className='info'>
            <input type='text' value={classInfo.date.value.start?.format('MM / DD / YYYY') ?? '-'} className='input non-interactive-input' readOnly /> ~{' '}
            <input type='text' value={classInfo.date.value.end?.format('MM / DD / YYYY') ?? '-'} className='input non-interactive-input' readOnly />
          </div>
        </div>
      </article>
      <article className='com_list_box'>
        <div className='scroll'>
          <table className='list'>
            <colgroup>
              <col width='8%' />
              <col />
              <col width='6.25rem' />
            </colgroup>
            <thead>
              <tr className='sticky top'>
                <th colSpan={3} className='table_tit black'>
                  <div className='wrap'>
                    Selected Student List
                    <p className='num'>
                      <i className='svg_icon icon_person white'>&nbsp;</i>
                      {checkedStudents.length}
                    </p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {classInfo &&
                classInfo.studentList.value.map((studentInfo, idx) => {
                  return (
                    <tr key={idx}>
                      <td className='alignC' style={{ paddingLeft: '.625rem' }}>
                        <input
                          type='checkbox'
                          id={`chk12-${idx}`}
                          className='com_chk'
                          checked={checkedStudents.includes(studentInfo)}
                          onChange={(e) => onCheckboxChange(studentInfo, !checkedStudents.includes(studentInfo))}
                        />
                        <label htmlFor={`chk12-${idx}`}></label>
                      </td>
                      <td className='alignL'>
                        <p className='ellipsis'>{studentInfo.userName}</p>
                      </td>
                      <td>
                        <button className='com_btn s line gray' onClick={() => handleShowProfile(studentInfo.userSeq)}>
                          View
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </article>
    </>
  );
}
