import React, { useState } from 'react';
import styled from 'styled-components';

import MainLink from 'components/_common/button/_MainLinks';
import Tab from './_components/Tab';
import ReactQuillEditor from 'components/_common/test-editor/quilljs/ReactQuillEditor';

/** AP / "Question Bank" 페이지 */
export default function QuestionBankRootPage() {
  /** tab */
  const [tabState, setTabState] = useState(0);

  /** 텍스트 에디터에 입력하는 상태 */
  const [testValue, setTextValue] = useState('');

  return (
    <S.Wrap>
      <MainLink />
      <div className='center_wrap'>
        <Tab tabState={tabState} setTabState={setTabState} />
        <div className='container'>
          <ReactQuillEditor editorContentState={testValue} setEditorContentState={setTextValue} />
          <div>
            <h2>리액트 상태 값으로 관리 되고 있는 텍스트 에디터 값 : </h2>
            <p>{testValue}</p>
          </div>
        </div>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .center_wrap {
      width: 90rem;
      margin: auto;
    }
  `,
};
