import { useLocation } from 'react-router-dom';
import { ARRAY_CURRENT_STEP_PRIMARY_BUTTON_DATA } from '../_utils/constants/stepOfSetTests';

/** "Set Tests" 의 단계 중 현재 페이지의 순서 (현재 라우트를 기준으로 배열 상수에서 index를 가져옴) */
function getCurrentIndex() {
  const location = useLocation();
  const currentIndex = ARRAY_CURRENT_STEP_PRIMARY_BUTTON_DATA.findIndex((item) => `${item.currentRouter}` === location.pathname);

  return currentIndex;
}

export default getCurrentIndex;
