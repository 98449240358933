import { clearReduxStateSetTests } from 'reducers/setTests/action.js';
import LocalStorage from './LocalStorage.utils.js';
import SessionStorage from './SessionStorage.utils.js';
import request from './Request.utils.js';

export default function clearAppData(dispatch, userId) {
  if (userId) request.post('/api/member/logout', { userId: userId }, () => console.log('logout'));

  SessionStorage.clearItem('windowManager');

  LocalStorage.clearItem('managerSession');
  LocalStorage.clearItem('userSession');
  LocalStorage.clearItem('sendParams');
  LocalStorage.clearItem('util');

  dispatch({ type: 'clearCustomer' });
  dispatch({ type: 'clearExamInfo' });
  dispatch({ type: 'clearExamComponents' });
  dispatch(clearReduxStateSetTests());
  dispatch({ type: 'clearMenuInfo' });
}
