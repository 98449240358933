import React from 'react';
import styled from 'styled-components';

/** '/com/score'페이지의 영역 별 타이틀 문구 스타일 용 컴포넌트 */
export default function SectionTitle({ children, ...rest }) {
  return <S.Heading {...rest}>{children}</S.Heading>;
}

const S = {
  Heading: styled.h4`
    color: #111111;
    font-weight: 600;
    font-size: 1.25rem;
  `,
};
