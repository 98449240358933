import React, { useRef, useState } from 'react';
import dayjsTZ, { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils.js';
import { nvl, nvlNumber } from 'utils/Common.utils.js';
// hook
import useScrollLock from 'hooks/useScrollLock';
//img
import IconDel from 'assets/img/icon_del.svg';
import ProfilePopup from 'components/_common/modals/PopupProfile';
//component
import EditModalCalendar from './EditModalCalendar';

/**
 * 세팅 된 테스트의 일정을 수정하는 모달 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/tests/scheduled
 */
export default function EditTestScheduleModal({
  editTest,
  activeTest,
  setEditTest,
  setAlertLayerPopup,
  setIsLoading,
  getSchedules,
  getTestClassMember,
  getAllTestClassMember,
  setMiniAlert,
}) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** 프로필 영역 */
  const [ProfileState, setProfileState] = useState(false);
  /** 선택된 달력 날짜 */
  const [selectedDateState, setSelectedDateState] = useState([
    isoTimeToDayjsTZ(editTest.item.length > 0 ? editTest.item[0].testStartDate : editTest.item.testStartDate),
    isoTimeToDayjsTZ(editTest.item.length > 0 ? editTest.item[0].testEndDate : editTest.item.testEndDate),
  ]);
  /** time select box */
  const [timeSelect, setTimeSelect] = useState(false);
  const [testStartTime, setTestStartTime] = useState(isoTimeToDayjsTZ(editTest.item.length > 0 ? editTest.item[0].testStartDate : editTest.item.testStartDate));
  /** 프로필 */
  const [profileInfo, setProfileInfo] = useState([]);
  /** edit check */
  const [deSelectCheckBox, setDeSelectCheckBox] = useState({
    seqStr: '',
    deSelectedKeys: [],
  });
  /** save change */
  const [editSeqStr, setEditSeqStr] = useState('');
  const [saveChanges, setSaveChanges] = useState(false);
  /** search student */
  const [searchStudentList, setSearchStudentList] = useState(editTest.item);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const searchStudentNameRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const TimeActive = () => {
    setTimeSelect((prevState) => !prevState);
  };

  const handleTimeSelect = (e, time) => {
    e.stopPropagation();

    setTimeSelect(false);
    setTestStartTime(time);
  };

  const generateTimeOptions = () => {
    const timeOptions = [];
    const startTime = dayjsTZ().startOf('day');
    for (let i = 0; i < 24; i++) {
      const time = startTime.add(i, 'hours');
      timeOptions.push(
        <p key={time.format('HH:mm')} className='option' onClick={(e) => handleTimeSelect(e, time)}>
          {time.format('A hh : mm')}
        </p>
      );

      const halfHour = time.add(30, 'minutes');
      timeOptions.push(
        <p key={halfHour.format('HH:mm')} className='option' onClick={(e) => handleTimeSelect(e, halfHour)}>
          {halfHour.format('A hh : mm')}
        </p>
      );
    }
    return timeOptions;
  };

  /** 프로필 불러오기 API 요청 */
  const getProfileView = (e, userSeq) => {
    e.stopPropagation();

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let studentInfo = response.result.studentInfo;

        let imageUrl = null;

        if (studentInfo.upFileSeq > 0) {
          imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}?${Date.now()}`;
        }

        studentInfo.profile = imageUrl;

        setProfileInfo(studentInfo);
        setProfileState(true);
      }
    };

    request.get(`/api/test/profiles?userSeq=${userSeq}&ecSeq=${userInfo.ecSeq}`, null, successHandler);
  };

  const deleteUth = (e, uthSeq) => {
    const { seqStr, deSelectedKeys } = deSelectCheckBox;

    if (e.target.checked) {
      const updatedSelectedKeys = deSelectedKeys.filter((key) => key !== uthSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setDeSelectCheckBox({
        ...deSelectCheckBox,
        deSelectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = [...deSelectedKeys, uthSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setDeSelectCheckBox({
        ...deSelectCheckBox,
        deSelectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  /** 저장 버튼 핸들러 */
  const saveBtn = () => {
    const { seqStr, deSelectedKeys } = deSelectCheckBox;

    let editUth;
    let editStr;

    if (editTest.item.length > 0) {
      editUth = editTest.item.map((i) => i.uthSeq);

      editUth = editUth.filter((uthSeq) => !deSelectedKeys.includes(uthSeq));
      editStr = editUth.join('|');
    } else {
      editUth = editTest.item.uthSeq;
      editStr = editUth;
    }

    setEditSeqStr(editStr);

    if (nvl(selectedDateState[0]) !== '' && nvl(selectedDateState[1]) !== '') {
      if (deSelectCheckBox.deSelectedKeys > 0) {
        setSaveChanges(true);
      } else if (nvl(editStr) !== '') {
        putSchedule(editStr);
      }
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please select a date.',
        };
      });
    }
  };

  const delUserTestSchedule = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        if (nvl(editSeqStr) === '') {
          setEditTest((prev) => {
            return { ...prev, active: false };
          });
          setSaveChanges(false);
          getSchedules();
        } else {
          putSchedule(editSeqStr);
        }
        getTestClassMember();
        getAllTestClassMember();
      }
    };

    request.del(`/api/test/schedules?seqStr=${encodeURI(deSelectCheckBox.seqStr)}`, null, successHandler);
  };

  const putSchedule = (editSeqStr) => {
    let params = {
      seqStr: editSeqStr,
      testStartDate: selectedDateState[0].hour(testStartTime.hour()).minute(testStartTime.minute()).format(),
      testEndDate: selectedDateState[1].endOf('day').format(),
    };

    const successHandler = (response) => {
      // console.log(response);
      if (response.code === 200) {
        setEditTest((prev) => {
          return { ...prev, active: false };
        });
        setMiniAlert((prev) => {
          return {
            ...prev,
            active: true,
            text: 'Edit Successful!',
            timeOut: 1500,
          };
        });
        setIsLoading(true);
        getSchedules();
        getTestClassMember();
        getAllTestClassMember();
      }
    };
    request.put('/api/test/schedules', params, successHandler);
  };

  const searchStudentNameBtn = (e) => {
    if (nvl(searchStudentNameRef.current.value) !== '') {
      let filterList = searchStudentList.filter((name) => name.userName.includes(nvl(searchStudentNameRef.current.value)));
      setSearchStudentList(filterList);
    } else {
      setSearchStudentList(editTest.item);
    }
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 ////////////////
  useScrollLock();
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  return (
    <>
      {/* <div className='com_popup pop_edit_test pop_manage type_dim active' onClick={() => setEditTest((prev) => {return { ...prev, active: false }})}>
        <div className='pop_container xl' onClick={e => e.stopPropagation()}> */}
      <div className='com_popup pop_edit_test pop_manage type_dim active'>
        <div className='pop_container xl'>
          <section className='pop_body'>
            <button
              className='btn_pop_close svg_icon black'
              onClick={() =>
                setEditTest((prev) => {
                  return { ...prev, active: false };
                })
              }></button>
            <div className='left'>
              {/*1.Change Date*/}
              <article className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon bullet_1 black'>&nbsp;</i>
                  Change Date
                </h2>
              </article>
              <EditModalCalendar setAlertLayerPopup={setAlertLayerPopup} selectedDateState={selectedDateState} setSelectedDateState={setSelectedDateState} />
            </div>

            {!ProfileState ? (
              //Test Details
              <div className='right test_details'>
                {/*2.Test Details*/}
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon bullet_2 black'>&nbsp;</i>
                    Test Details
                  </h2>
                </article>
                <article className='class_info'>
                  <div className='item col2'>
                    <div className='tit'>Test</div>
                    <div className='info'>
                      {editTest.item.length > 0 ? (editTest.item[0].testName ? editTest.item[0].testName : activeTest.testName) : activeTest.testName}
                    </div>
                  </div>
                  <div className='item col2'>
                    <div className='tit'>Class</div>
                    <div className='info'>{editTest.item.length > 0 ? editTest.item[0].className : editTest.item.className}</div>
                  </div>
                  <div className='item'>
                    <div className='tit'>Date</div>
                    <div className='info'>
                      <input
                        type='text'
                        value={selectedDateState[0] ? selectedDateState[0].format('MM / DD / YYYY') : 'select a date'}
                        className='input'
                        readOnly
                      />
                      -
                      <input
                        type='text'
                        value={selectedDateState[1] ? selectedDateState[1].format('MM / DD / YYYY') : 'select a date'}
                        className='input'
                        readOnly
                      />
                    </div>
                    <div className='info'>
                      <div className={`com_select_layer type_updown ${timeSelect ? 'active' : ''}`} onClick={() => TimeActive()}>
                        <section className='selected_wrap'>
                          <input className='selected' placeholder={testStartTime.format('A hh : mm')} />
                        </section>
                        <section className='layer scroll'>{generateTimeOptions()}</section>
                      </div>
                    </div>
                  </div>
                </article>

                {/*개발 To do : 개별 student 수정 시 search_sec 비노출*/}
                {editTest.type === 'class' && (
                  <article className='search_sec'>
                    <div className='search'>
                      <input type='text' className='input' placeholder='Search students' ref={searchStudentNameRef} />
                      <button className='com_btn black m' onClick={(e) => searchStudentNameBtn(e)}>
                        SEARCH
                      </button>
                    </div>
                  </article>
                )}

                <article className='com_list'>
                  <div className='scroll'>
                    <table className='list'>
                      <colgroup>
                        <col width='40' />
                        <col />
                        <col width='100' />
                      </colgroup>
                      <thead>
                        <tr className='sticky top'>
                          <th colSpan={3} className='table_tit black'>
                            {editTest.type === 'class' ? `Student List : ${editTest.item.length}` : 'Student List'}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchStudentList.length > 0 &&
                          searchStudentList.map((i, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <input
                                    type='checkbox'
                                    id={`chk${idx}`}
                                    className='com_chk'
                                    checked={!deSelectCheckBox.deSelectedKeys.includes(i.uthSeq)}
                                    onChange={(e) => deleteUth(e, i.uthSeq)}
                                  />
                                  <label htmlFor={`chk${idx}`}></label>
                                </td>
                                <td className='alignL'>
                                  <p className='ellipsis'>{i.userName}</p>
                                </td>
                                <td className='alignC'>
                                  <button className='com_btn s line gray' onClick={(e) => getProfileView(e, i.userSeq)}>
                                    View
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        {nvlNumber(editTest.item.length) === 0 && (
                          <tr>
                            <td>
                              <input
                                type='checkbox'
                                id='checkOne'
                                className='com_chk'
                                checked={!deSelectCheckBox.deSelectedKeys.includes(editTest.item.uthSeq)}
                                onChange={(e) => deleteUth(e, editTest.item.uthSeq)}
                              />
                              <label htmlFor='checkOne'></label>
                            </td>
                            <td className='alignL'>
                              <p className='ellipsis'>{editTest.item.userName}</p>
                            </td>
                            <td className='alignC'>
                              <button className='com_btn s line gray' onClick={(e) => getProfileView(e, editTest.item.userSeq)}>
                                View
                              </button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </article>
                <article className='com_btn_wrap bottom'>
                  <button className='com_btn l point full' onClick={() => saveBtn()}>
                    Save Changes
                  </button>
                </article>
              </div>
            ) : (
              ProfileState && (
                //프로필
                <ProfilePopup type='student' setProfileState={setProfileState} studentInfo={profileInfo} />
              )
            )}
            {/* Teacher 삭제 팝업 */}
          </section>
        </div>
      </div>
      {/*변경내용 저장 시 팝업*/}
      {/*전달 받은 문구 및 아이콘이 틀린 듯함 → 디자인팀에 요청 후 대기 중*/}
      <div className={`com_popup type_alert ${saveChanges ? 'active' : ''}`}>
        <div className='pop_container'>
          <div className='pop_body ok_contents'>
            <p className='alert_title'>
              Are you sure you want to remove
              <br /> selected student(s)?
            </p>
            <div className='icon'>
              <img src={IconDel} alt='icon' />
            </div>
            <div className='alert_text'>
              You are removing student(s) from this test. <br /> Your Gate Cash will be refunded.
            </div>
          </div>
          <div className='pop_footer com_btn_wrap many'>
            <button className='com_btn point line xl' onClick={() => setSaveChanges(false)}>
              Back
            </button>
            <button className='com_btn point xl' onClick={() => delUserTestSchedule()}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
