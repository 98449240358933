import React from 'react';
import styled from 'styled-components';

/** "/com/score" 페이지 상단 메인 "텝" 컴포넌트 */
export default function MainTab({ tabState, setTabState, scorePageInfo, handleSelectStudent }) {
  /** 텝 데이터 */
  const tab = [
    { text: 'Class', value: 0 },
    { text: 'Student', value: 1 },
    { text: 'Lecture', value: 2 },
  ];

  /** 텝 버튼 클릭 핸들러 함수 */
  const tabBtn = (tab) => {
    if (tab === 1) {
      if (scorePageInfo.memberList && scorePageInfo.memberList.length > 0) {
        handleSelectStudent(
          scorePageInfo.memberList[0].uthSeq,
          scorePageInfo.memberList[0].userSeq,
          scorePageInfo.memberList[0].rwPoint,
          scorePageInfo.memberList[0].mathPoint
        );
      }
    }
    setTabState(tab);
  };

  return (
    <S.TabWrap>
      {tab?.map((v, idx) => {
        return (
          <button title='데이터 재로딩' key={idx} className={`menu ${tabState === v.value ? 'active' : ''}`} onClick={() => tabBtn(v.value)}>
            {v.text}
          </button>
        );
      })}
    </S.TabWrap>
  );
}

const S = {};

S.TabWrap = styled.div`
  flex: 0 0 14.625rem;
  border: 1px solid var(--point-color);
  border-radius: 0.5rem;
  background: #fff;
  overflow: hidden;
  display: flex;
  button {
    flex: 1;
    font-weight: 400;
    font-size: 0.875rem;
    padding: 1rem 0.75rem;
    color: #0068bd;
    &.active {
      background-color: #0068bd;
      color: #ffffff;
    }
  }
  /* &:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  } */
`;
