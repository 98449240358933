import { useEffect, useState } from 'react';
import styled from 'styled-components';

import ApSettingStepper from './_components/ApSettingStepper';
import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/round_triangle.svg';

import { ANSWER_CHOICE_CONSTANT, initAnswerChoice, MULTIPLE_CHOICE_ANSWER_DATA } from 'components/ap/question-bank/test-editor/_constant';

//////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
// 🧰 기획 간소화 및 모드 변경 불가하게 설정
// 상수 그룹화
// const SETTINGS = {
//   MULTIPLE_CHOICE: [
//     { label: 'Single-select', value: ANSWER_CHOICE_CONSTANT.SINGLE },
//     { label: 'Multi-select', value: ANSWER_CHOICE_CONSTANT.MULTIPLE },
//   ],
//   CORRECT_ANSWERS: [
//     { label: 'Select', value: null },
//     { label: 'A', value: 'option1' },
//     { label: 'B', value: 'option2' },
//     { label: 'C', value: 'option3' },
//     { label: 'D', value: 'option4' },
//     { label: 'E', value: 'option5' },
//   ],
//   DEFAULT_CHOICE: [{ label: 'Text Input', value: 'Text Input' }],
// };
////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** 답안 선택 설정을 위한 팝업 컴포넌트 */
export default function AnswerChoiceSettingPopup({ isOpen, pos = 'left', answerChoiceInfo, handleAnswerChoiceInfoChange, wrapperRef, scrollRef }) {
  // 팝업 위치
  const [position, setPosition] = useState({ top: 0, left: 0 });

  // 팝업 위치 업데이트 및 이벤트 리스너 설정
  useEffect(() => {
    if (!isOpen || !wrapperRef?.current) return;

    /** 팝업 위치 업데이트 */
    const updatePosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        setPosition({
          top: rect.top + rect.height / 2,
          left: rect.left + (pos === 'left' ? -450 : rect.width),
        });
      }
    };

    updatePosition();

    // 스크롤 컨테이너 찾기 (.custom_scroll 클래스를 가진 가장 가까운 부모)
    const scrollContainer = wrapperRef.current?.closest('.custom_scroll');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', updatePosition);
    }

    // 윈도우 리사이즈 이벤트 리스너
    window.addEventListener('resize', updatePosition);

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', updatePosition);
      }
      window.removeEventListener('resize', updatePosition);
    };
  }, [isOpen, pos, wrapperRef]);

  //////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  // 🧰 기획 간소화 및 모드 변경 불가하게 설정
  /** 현재 선택된 모드가 single 인지 */
  // const isSingleSelect = answerChoiceInfo.currentMode === 'single';
  ////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  // 🧰 기획 간소화 및 모드 변경 불가하게 설정
  /** 모드 업데이트 - 단일정답/다중정답 */
  // const handleUpdateMode = (mode) => handleAnswerChoiceInfoChange({ currentMode: mode });
  /** 답안 선택지 개수 조절 */
  const handleNumOfAnswerChoices = (isIncrease) => {
    let newAnswerChoices = [];

    if (isIncrease) {
      // MULTIPLE_CHOICE_ANSWER_DATA의 최대 갯수만큼만 증가
      if (answerChoiceInfo.answerChoices.length >= Object.keys(MULTIPLE_CHOICE_ANSWER_DATA).length) return answerChoiceInfo;

      newAnswerChoices = [...answerChoiceInfo.answerChoices, initAnswerChoice];
    } else {
      newAnswerChoices = answerChoiceInfo.answerChoices.slice(0, -1);
    }

    // 변경된 답안 갯수 && 정답 데이터 초기화
    handleAnswerChoiceInfoChange({ answerChoices: newAnswerChoices });
  };
  // 🧰 기획 간소화 및 모드 변경 불가하게 설정
  // /** answerChoice의 입력 타입 데이터 업데이트 - 현재는 Text Input 만 지원 */
  // const updateAnswerChoiceType = (index, value) => {
  //   handleAnswerChoiceInfoChange({
  //     answerChoices: answerChoiceInfo.answerChoices.map((choice, i) => (i === index ? { ...choice, choiceType: value } : choice)),
  //   });
  // };
  // /** 정답 데이터 업데이트 */
  // const updateCorrectAnswer = (index, value) => {
  //   if (isSingleSelect) {
  //     handleAnswerChoiceInfoChange({ single: { ...answerChoiceInfo.single, correctAnswer: [value] } });
  //   } else {
  //     const newCorrectAnswers = [...answerChoiceInfo.multiple.correctAnswer];
  //     newCorrectAnswers[index] = value;
  //     handleAnswerChoiceInfoChange({ multiple: { ...answerChoiceInfo.multiple, correctAnswer: newCorrectAnswers } });
  //   }
  // };
  // 🧰 기획 간소화 및 모드 변경 불가하게 설정
  // /** 증가/감소를 받아 정답 개수를 조절하는 함수 (다중 선택 모드 전용) */
  // const handleCorrectAnswerCount = (isIncrease) => {
  //   const newCorrectAnswers = [...answerChoiceInfo.multiple.correctAnswer];
  //   if (isIncrease) {
  //     newCorrectAnswers.push(null);
  //   } else {
  //     newCorrectAnswers.pop();
  //   }

  //   handleAnswerChoiceInfoChange({ multiple: { ...answerChoiceInfo.multiple, correctAnswer: newCorrectAnswers } });
  // };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- 랜더링 보조 --- START ////////////////////
  // 팝업 위치가 0 일 경우 or isOpen 이 false 일 경우 랜더링 하지 않음
  if (!isOpen || position.top === 0 || position.left === 0) return null;
  ////////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <S.Container style={{ top: position.top, left: position.left }} $pos={pos}>
      <div className='popup_header'>
        <h2>Answer Choice Settings</h2>
      </div>

      <S.ContentWrapper>
        {/* 🧰 기획 간소화 및 모드 변경 불가하게 설정 */}
        {/* <div className='control_row'>
          <span className='label'>Multiple choice type</span>
          <ApSettingSelectBox options={SETTINGS.MULTIPLE_CHOICE} onSelect={(value) => handleUpdateMode(value)} />
        </div>

        <div className='divider' /> */}

        <div>
          <div className='control_row has_inner_row'>
            <span className='label'>No. of Answer Choices</span>
            <ApSettingStepper
              number={answerChoiceInfo.answerChoices.length}
              onIncrease={() => handleNumOfAnswerChoices(true)}
              onDecrease={() => handleNumOfAnswerChoices(false)}
            />
          </div>

          <div className='inner_row'>
            {answerChoiceInfo.answerChoices.map((_, index) => (
              <div className='control_row' key={index}>
                <span className='label'>Choice {index + 1}</span>
                {/* 🧰 기획 간소화 및 입력 타입 변경 불가하게 설정 */}
                {/* <ApSettingSelectBox
                  options={SETTINGS.DEFAULT_CHOICE}
                  onSelect={(value) => updateAnswerChoiceType(index + 1, value)}
                  defaultValue={answerChoiceInfo.answerChoices[index].choiceType}
                /> */}
              </div>
            ))}
          </div>
        </div>

        <div className='divider' />

        {/* 🧰 기획 간소화 및 모드 변경 불가하게 설정 */}
        {/* {isSingleSelect ? (
          <div className='control_row'>
            <span className='label'>Correct answer</span>
            <ApSettingSelectBox
              className='correct_answer_select'
              options={SETTINGS.CORRECT_ANSWERS.slice(0, answerChoiceInfo.answerChoices.length + 1)}
              onSelect={(value) => updateCorrectAnswer(0, value)}
              defaultValue={answerChoiceInfo.single.correctAnswer[0]}
            />
          </div>
        ) : (
          <div>
            <div className='control_row has_inner_row'>
              <span className='label'>No. of Correct Answer</span>
              <ApSettingStepper
                number={answerChoiceInfo.multiple.correctAnswer.length}
                onIncrease={() => handleCorrectAnswerCount(true)}
                onDecrease={() => handleCorrectAnswerCount(false)}
              />
            </div>

            <div className='inner_row'>
              {answerChoiceInfo.multiple.correctAnswer.map((_, index) => (
                <div className='control_row' key={index}>
                  <span className='label'>Correct Answer {index + 1}</span>
                  <ApSettingSelectBox
                    className='correct_answer_select'
                    options={SETTINGS.CORRECT_ANSWERS}
                    onSelect={(value) => updateCorrectAnswer(index, value)}
                    defaultValue={answerChoiceInfo.multiple.correctAnswer[index]}
                  />
                </div>
              ))}
            </div>
          </div>
        )} */}
      </S.ContentWrapper>
      <ArrowIconSelect className='triangle_icon' />
    </S.Container>
  );
}

const getPopupPosition = (pos) => {
  const positions = {
    left: `
      transform: translateY(-50%);
      .triangle_icon {
        right: -3%;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
      }
    `,
    right: `
      transform: translateY(-50%);
      .triangle_icon {
        left: -3%;
        top: 50%;
        transform: translateY(-50%);
      }
    `,
    top: `
      transform: translate(-50%, -100%);
      .triangle_icon {
        bottom: -4%;
        left: 50%;
        transform: translateX(-50%) rotate(270deg);
      }
    `,
    bottom: `
      transform: translate(-50%, 0);
      .triangle_icon {
        top: -4%;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
      }
    `,
  };

  return positions[pos] || positions.left;
};

const S = {
  Container: styled.div`
    position: fixed;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 24px;
    width: 430px;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.24));
    z-index: 99;
    ${({ $pos }) => getPopupPosition($pos)}

    .popup_header {
      width: 100%;
      h2 {
        color: #111111;
        font-family: 'Roboto-SemiBold', Helvetica;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin: 0;
      }
    }

    .triangle_icon {
      position: absolute;
      color: #ffffff;
    }
  `,
  ContentWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;

    .divider {
      height: 1px;
      background-color: #f1f1f5;
      width: 100%;
    }

    .control_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 2.125rem;

      .label {
        color: #111111;
        font-family: 'Roboto-Regular', Helvetica;
        font-size: 14px;
        line-height: 20px;
      }

      &.has_inner_row {
        margin-bottom: 0.5rem;
      }

      .correct_answer_select {
        width: 6rem;
      }
    }

    .inner_row {
      background-color: #fafafa;
      padding: 0.625rem 0.75rem;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .group_control {
      display: flex;
      align-items: center;
      gap: 8px;

      .separator {
        color: #111111;
        font-size: 12px;
        line-height: 18px;
      }
    }
  `,
};
