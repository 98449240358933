//#region    ////////// components
import EquationsPanel from './_components/EquationsPanel';
import EquationsPanelContentContainer from './_components/EquationsPanelContentContainer';
import EquationsList from './_components/EquationsList';
import PrefixesEquationPanel from './_components/equationsContent/PrefixesEquationPanel';
//#endregion ////////// components

//#region    ////////// assets
import kinematicsForcesEnergyMomentum_1 from 'assets/img/ap_exam/reference_sheet/equations/physics_algebra_based/kinematics_forces_energy_momentum_1.png';
import kinematicsForcesEnergyMomentum_2 from 'assets/img/ap_exam/reference_sheet/equations/physics_algebra_based/kinematics_forces_energy_momentum_2.png';
import rotationsSimpleHarmonicMotionFluids_1 from 'assets/img/ap_exam/reference_sheet/equations/physics_algebra_based/rotations_simple_harmonic_motion_fluids_1.png';
import rotationsSimpleHarmonicMotionFluids_2 from 'assets/img/ap_exam/reference_sheet/equations/physics_algebra_based/rotations_simple_harmonic_motion_fluids_2.png';
import constantsConversionFactors_1 from 'assets/img/ap_exam/reference_sheet/equations/physics_algebra_based/constants_conversion_factors_1.png';
import unitSymbols_1 from 'assets/img/ap_exam/reference_sheet/equations/physics_algebra_based/unit_symbols_1.png';
import geometryTrigonometry_1 from 'assets/img/ap_exam/reference_sheet/equations/physics_algebra_based/geometry_trigonometry_1.png';
import geometryTrigonometry_2 from 'assets/img/ap_exam/reference_sheet/equations/physics_algebra_based/geometry_trigonometry_2.png';
import trigonometricFunctionsCommonAngles_1 from 'assets/img/ap_exam/reference_sheet/equations/physics_algebra_based/trigonometric_functions_common_angles_1.png';
import examConventions_1 from 'assets/img/ap_exam/reference_sheet/equations/physics_algebra_based/examConventions_1.png';
//#endregion ////////// assets

/** Physics 1: Algebra-Based 레퍼런스 시트 수식 컴포넌트 */
export default function PhysicsAlgebraBasedEquations() {
  return (
    <EquationsList>
      <EquationsPanel
        panelHeaderText='Kinematics, Forces, Energy, Momentum'
        PanelContent={
          <EquationsPanelContentContainer contents={[kinematicsForcesEnergyMomentum_1, kinematicsForcesEnergyMomentum_2]} containerHeight='115rem' />
        }
      />
      <EquationsPanel
        panelHeaderText='Rotation, Simple Harmonic Motion, and Fluids'
        PanelContent={
          <EquationsPanelContentContainer contents={[rotationsSimpleHarmonicMotionFluids_1, rotationsSimpleHarmonicMotionFluids_2]} containerHeight='143rem' />
        }
      />
      <EquationsPanel
        panelHeaderText='Constants and Conversion Factors'
        PanelContent={<EquationsPanelContentContainer contents={[constantsConversionFactors_1]} containerHeight='24rem' />}
      />
      <EquationsPanel panelHeaderText='Unit Symbols' PanelContent={<EquationsPanelContentContainer contents={[unitSymbols_1]} containerHeight='10rem' />} />
      <EquationsPanel
        panelHeaderText='Geometry and Trigonometry'
        PanelContent={<EquationsPanelContentContainer contents={[geometryTrigonometry_1, geometryTrigonometry_2]} containerHeight='91rem' />}
      />
      <EquationsPanel
        panelHeaderText='Values of Trigonometric Functions for Common Angles'
        PanelContent={<EquationsPanelContentContainer contents={[trigonometricFunctionsCommonAngles_1]} containerHeight='33rem' />}
      />
      <PrefixesEquationPanel />
      <EquationsPanel
        panelHeaderText='Exam Conventions'
        PanelContent={<EquationsPanelContentContainer contents={[examConventions_1]} containerHeight='11rem' />}
      />
    </EquationsList>
  );
}
