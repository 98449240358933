import styled from 'styled-components';
import lineBg from 'assets/img/exam/line_bg.png';

/**
 * 시험에서 사용될 footer UI 컴포넌트
 * 좌측 모듈, 중앙 모듈, 우측 모듈을 받을수 있게 구성 ( 재사용성 높게 )
 */
export default function UI_ExamFooter({ leftContent, centerContent, rightContent }) {
  return (
    <S.Container>
      <S.UserInfoArea>{leftContent}</S.UserInfoArea>

      <S.TestInfoArea>{centerContent}</S.TestInfoArea>

      <S.ButtonArea>{rightContent}</S.ButtonArea>
    </S.Container>
  );
}

const S = {
  Container: styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 4.375rem;
    background: #f4f8ff;
    position: relative;
    z-index: 100;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${lineBg});
      width: 100%;
      height: 0.1875rem;
      background-repeat: repeat-x;
    }
  `,

  UserInfoArea: styled.div`
    padding-left: 4.375rem;
    width: 30%;

    .user_name {
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.375rem;
    }
  `,

  TestInfoArea: styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
    justify-content: center;
    position: relative;
    .question_nav_btn {
      font-size: 0.875rem;
      font-weight: 600;
      padding: 0.875rem 1.5rem;
      border-radius: 0.625rem;
      background-color: black;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;
      .question_nav_btn_arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.75rem;
        height: 0.75rem;
        transform: rotate(180deg);
      }
    }
  `,

  ButtonArea: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 0.625rem;
    padding-right: 4.375rem;
    width: 30%;

    button {
      background-color: #004a9e;
      height: 2.375rem;
      padding: 0.875rem 1.5rem;
      display: flex;
      align-items: center;
      border-radius: 1.875rem;
      color: white;
      font-size: 0.875rem;
      font-weight: 600;

      &:disabled {
        background-color: #ccc;
        color: #666;
      }
    }
  `,
};
