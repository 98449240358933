import React from 'react';

import { ReactComponent as IconPassword } from 'assets/gate_member/image/lock_icon.svg';
import SignInputBase from 'components/user/_components/common/forms/inputs/_SignInputBase';

/** 로그인 - "비밀번호" 입력 창 컴포넌트 */
export default function InputPassword({ register, setValue }) {
  return (
    <SignInputBase
      inputReset={() => {
        setValue('userPassword', '');
      }}>
      <IconPassword className='icon' />
      <label htmlFor='userPassword'>Password</label>
      <input type='password' id='userPassword' {...register} placeholder='Enter your password' />
    </SignInputBase>
  );
}
