//#region    ////////// packages
import { useState } from 'react';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// utils & hooks
import { allowImages, allowPdfFiles } from 'utils/Common.utils';
import request from 'utils/Request.utils';
//#endregion ////////// utils & hooks

//#region    ////////// components
import LabelFileUpload from 'components/user/sign-up/_components/pages/upload/LabelFileUpload';
import SimpleAlertModal from 'components/_common/modals/_SimpleAlertModal';
import FileItemDownload from './FileItemDownload';
//#endregion ////////// components

/** 파일 조회 및 다운로드 컴포넌트 */
export default function FileViewAndDownload({ uploadFileList, setUploadFileList, postFileUpload, deleteFile }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** 현재 선택 된 파일 아이템  */
  const [currentFileItem, setCurrentFileItem] = useState(false);
  /** 간단한 알림 모달 상태 */
  const [simpleAlert, setSimpleAlert] = useState({
    modalText: '',
    visible: false,
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const handleDragOver = (event) => event.preventDefault();
  const handleDrop = (event) => {
    event.preventDefault();

    const fileList = event.dataTransfer.files;
    getFileListInfo(fileList);
  };
  const handleFileInput = (e) => {
    const fileList = e.target.files;
    getFileListInfo(fileList);

    e.target.value = '';
  };
  const getFileListInfo = async (fileList) => {
    let tempFileList = [...uploadFileList];

    for (const file of fileList) {
      const { name, size, type } = file;
      const mbSize = (size / (1024 * 1024)).toFixed(2) + 'mb';

      if (!allowImages(type) && !allowPdfFiles(type)) {
        setSimpleAlert((prev) => ({
          ...prev,
          visible: true,
          modalText: 'Please upload image file or pdf file.',
        }));

        return;
      }

      const fileExists = tempFileList.some((existingFile) => existingFile.fileName === name);

      // 이미 업로드 된 파일 중 동일한 이름을 가진 파일이 없을때만 동작
      if (!fileExists) {
        const uploadResult = await postFileUpload(file);

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          // const readerResult = reader.result; // 미사용

          tempFileList.push({
            fileName: name,
            upFileSeq: uploadResult.uploadFilesSeq[0],
            // fileSize: mbSize, // 미사용
            // mimeType: type, // 미사용
            // fileTextData: readerResult, // 미사용
            // fileData: file, // 미사용
          });

          setUploadFileList(tempFileList);
        };
      }
    }
  };

  /** 파일 다운로드 API */
  const downloadHybridQuestionFile = ({ fileCate, upFileSeq, fileName }) => {
    request.downloadFile(`/api/v2/ap/question/${fileCate}/pdf/${upFileSeq}`, fileName).finally(() => {});
  };

  /** 현재 선택 된 파일 다운로드 버튼 핸들러 */
  const handleDownload = async ({ upFileSeq, fileName }) => {
    downloadHybridQuestionFile({ fileCate: 'answer_pdf', upFileSeq, fileName });
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  const isUploaded = uploadFileList.length > 0;
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <>
      <S.FileUploadArea>
        <div className='uploaded_files'>
          {isUploaded
            ? uploadFileList?.map((fileItem, index) => (
                <FileItemDownload
                  key={`fileInfo_${index}`}
                  fileItem={fileItem}
                  handleDownload={() => {
                    handleDownload({ fileName: fileItem.fileName, upFileSeq: fileItem.upFileSeq });
                  }}
                />
              ))
            : null}
        </div>
      </S.FileUploadArea>

      <SimpleAlertModal alertInfo={simpleAlert} closeModal={() => setSimpleAlert((prev) => ({ ...prev, visible: false }))} />
    </>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  FileUploadArea: styled.div`
    font-family: 'Roboto', 'Noto Sans KR', sans-serif;
    .guile-txt {
      width: 100%;
      font-size: 0.875rem;
      font-weight: 500;
      color: #4b5563;
      padding: 0.75rem 0;
      text-align: center;
    }

    input[type='file'].upload_file {
      display: none;
    }
  `,
};
