import React, { useEffect, useState } from 'react';
import request from 'utils/Request.utils';
import LocalStorage from 'utils/LocalStorage.utils';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateReduxSocialLoginTime,
  updateReduxSocialUserId,
  updateReduxSocialUserEmail,
  updateReduxSocialLoginType,
  clearReduxStateSocialUser,
  updateReduxSocialUserName,
} from 'reducers/auth/action';
import { updateReduxJoinCompanyLoginType, updateReduxJoinCompanyUid, updateReduxJoinCompanyUserLevel } from 'reducers/auth/joincompany.action';
import { SOCIAL_LOGIN_TYPE } from 'utils/constants';
import CustomAlert from 'components/_common/alerts/CustomAlert';
import LoadingBar from 'utils/LoadingBar';
import jwt_decode from 'jwt-decode';
import { nvl } from 'utils/Common.utils';
import { USER_LEVELS } from 'utils/constants';
import EmailLoginModal from './_components/modals/EmailLoginModal';
// response code 중에서 redux에저장할 상태값
const updateReduxState = [200, 403, 406, 407];
/** 소셜 로그인 : 구글 (리다이렉트 페이지) */
export default function SocialLoginGoogle() {
  console.log('리다이렉트 페이지 : 구글');
  const navigate = useNavigate();
  const userSocial = useSelector((state) => state.userReducer.social);
  console.log('🚀 ~ SocialLoginGoogle ~ userSocial:', userSocial);
  const dispatch = useDispatch();

  // 페이지 진입시 join company 정보는 초기화

  const [loadingBarActive, setLoadingBarActive] = useState(false);
  const [showEmailLoginModal, setShowEmailLoginModal] = useState(false);
  // 406 에러 발생시 이메일 로그인 모달 callback 함수
  const callbackHandler = (response) => {
    setShowEmailLoginModal(response);
    // 1추후에 로그인 페이지로 이동
    setLoadingBarActive(true);
    setTimeout(() => {
      console.log(userSocial);
      navigate('/signin');
    }, 1000);
  };
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
  });
  /** 커스텀 알럿 창 "닫기" 버튼 핸들러 */
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...prev, alertType: 'alert', visible: false, alertMessage: '' };
    });
  };
  /** 커스텀 알럿 창 "확인" 버튼 핸들러 */
  const returnAlertValue = (value) => {
    if (value === 'OK') {
      navigate('/signin');
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      //   // 서버로 code 전송
      setLoadingBarActive(true); // 로딩바 보이기
      const successHandler = (response) => {
        console.log('🚀 ~ successHandler ~ response:', response);
        // updateReduxState 에 포함된 code 일 경우 redux에 저장
        if (updateReduxState.includes(response.code)) {
          dispatch(updateReduxSocialUserId(response.result.id));
          // 백엔드에서 login type을 받아서 저장 해야함
          dispatch(updateReduxSocialLoginType(SOCIAL_LOGIN_TYPE.GOOGLE));
          // unix time
          dispatch(updateReduxSocialLoginTime(Math.floor(Date.now())));
          dispatch(updateReduxSocialUserEmail(response.result.email));
          dispatch(updateReduxSocialUserName(response.result.name));
        } else {
          // 아닌경우 초기화 하기
          dispatch(clearReduxStateSocialUser());
        }
        if (response.code === 200) {
          console.log(response.result);
          LocalStorage.clearItem('userSession');
          LocalStorage.clearItem('sendParams');
          LocalStorage.clearItem('util');
          LocalStorage.setItem('userSession', 'accessToken', response.result.accessToken);
          LocalStorage.setItem('userSession', 'refreshToken', response.result.refreshToken);
          // 로그인 성공 후 메인
          if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.STUDENT) window.location.href = '/student/dashboard';
          else if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.PARENT) window.location.href = '/parent/dashboard';
          else window.location.href = '/academy/dashboard';
          setLoadingBarActive(false); // 로딩바 없애기
          return;
        }
        if (response.code === 403) {
          // 학원이 대리 가입 시켜 준 학생이 첫 로그인 시도 했을 때 약관 동의 화면 띄우기
          setLoadingBarActive(false); // 로딩바 없애기
          // 약관 동의 페이지로 이동
          navigate('/signin/agree');
          return;
        }
        if (response.code === 406) {
          // 관리자 첫 로그인을 학원 코드 없이 했을때
          setShowEmailLoginModal(true);
          setLoadingBarActive(false); // 로딩바 없애기
          // navigate 이동은 callbackHandler 에서 처리
          return;
        }
        if (response.code === 407) {
          // 회원 가입이 필요한 소셜 로그인 경우 socilaLoginType, id을 redux에 저장하고 회원가입 페이지로 이동
          navigate('/signup/social/user-type');
          return;
        }
        if (response.code === 409) {
          // 신규 회원인경우 code 입력 단계로 이동
          dispatch(updateReduxJoinCompanyLoginType(SOCIAL_LOGIN_TYPE.GOOGLE));
          dispatch(updateReduxJoinCompanyUid(response.result.userSeq));
          dispatch(updateReduxJoinCompanyUserLevel(response.result.userLevel));
          console.log('신규 회원인경우 code 입력 단계로 이동');
          console.log(response);
          setLoadingBarActive(false); // 로딩바 없애기
          navigate('/signup/social/tutor/first-time-signin');
          return;
        }
        setLoadingBarActive(false); // 로딩바 없애기
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertType: 'okOnly',
            alertMessage: response.message,
          };
        });
      };
      const errorHandler = (result) => {
        const message = result.response?.data?.message[0];
        setLoadingBarActive(false); // 로딩바 없애기
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            alertType: 'okOnly',
            visible: true,
            alertMessage: message,
          };
        });
      };
      const isNew = true;
      const params = {
        token: code,
        isNew: isNew,
        confirmCode: userSocial.confirmCode,
        redirectUrl: process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI, // 서버 측에서 google에 요청을 보낼 때 일치 하는지 확인 용
      };
      request.post(request.apiUris.auth.signinGoogle, params, successHandler, errorHandler);
    } else {
      // code 가 없는 경우
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertType: 'okOnly',
          alertMessage: '로그인에 실패했습니다.',
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <h1>소셜 로그인 : 구글</h1> */}
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
      {loadingBarActive && <LoadingBar type={'spin'} color={'#000000'} />}
      {showEmailLoginModal && <EmailLoginModal setShowEmailLoginModal={callbackHandler} />}
    </>
  );
}
