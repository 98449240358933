import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import ReactQuillEditor from 'components/_common/test-editor/quilljs/ReactQuillEditor';
import QuestionTopArea from 'components/ap/com/ap-components/_components/QuestionTopArea';

import QuestionSettingPopup from 'components/ap/com/ap-components/_components/SourceSettingPopup/QuestionSettingPopup';

import useOutsideClick from 'hooks/useOutsideClick';

import { EDITOR_CONSTANT } from '../_constant';
import debounce from 'lodash/debounce';

/** 문제 작성 영역 */
export default function QuestionEditor({ currentQuestion, editorStateUpdater }) {
  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [editorContentState, setEditorContentState] = useState(currentQuestion.question_content);
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  const popupWrap = useRef(null);
  // 디바운스 함수를 useRef로 관리하여 리렌더링 시 초기화 방지
  const debouncedUpdateRef = useRef(
    debounce(
      (content) => {
        editorStateUpdater(EDITOR_CONSTANT.currentQuestion, { question_content: content });
      },
      100,
      {
        leading: false,
        trailing: true,
      }
    )
  ).current;
  useOutsideClick(popupWrap, () => setIsShowPopup(false));
  ////////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const handleClickEditor = (event) => {
    event.stopPropagation();
    setIsShowPopup(true);
  };

  const handleUpdateQuestionInfo = (key, value) => {
    editorStateUpdater(EDITOR_CONSTANT.currentQuestion, { [key]: value });
  };

  /** 문제 입력창 (ReactQuillEditor) 핸들러 */
  const handleReactQuillEditor = (event) => {
    setEditorContentState(event);
    debouncedUpdateRef(event);
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    if (editorContentState !== currentQuestion.question_content) {
      setEditorContentState(currentQuestion.question_content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion.question_content]);

  // 컴포넌트 언마운트 시 디바운스 타이머 정리
  useEffect(() => {
    return () => {
      debouncedUpdateRef.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  ////////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <S.QuestionContainer className={currentQuestion.question_type === 'E' ? 'FRQ' : ''}>
      <QuestionTopArea order={currentQuestion.question_order + 1} />
      {/* <div className='question_title_container'>
        <S.SectionTitle>Question</S.SectionTitle>
        <input
          type='text'
          value={Number(currentQuestion.custom_question_order ?? 0) + 1}
          onChange={(e) => editorStateUpdater(EDITOR_CONSTANT.currentQuestion, { custom_question_order: e.target.value })}
        />
      </div> */}

      <div className='question_editor_container'>
        <div className='editor_wrapper' onClick={handleClickEditor} ref={popupWrap}>
          <ReactQuillEditor className='quill_editor' editorContentState={editorContentState} handleChange={handleReactQuillEditor} />
          <QuestionSettingPopup isOpen={isShowPopup} pos='left' questionInfo={currentQuestion} handleUpdateQuestionInfo={handleUpdateQuestionInfo} />
        </div>
        {/* <div className='btn_container'>
          <SimpleEditorBtn className='filled' onClick={handleSaveContent}>
            Save
          </SimpleEditorBtn>
        </div> */}
      </div>
    </S.QuestionContainer>
  );
}

// --- --- --- Styled components --- --- ---
const S = {
  QuestionContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;

    .question_title_container {
      display: flex;
      gap: 0.625rem;
      align-items: center;

      input {
        border-radius: 4px;
        border: 1px solid #d7dadf;
        padding: 0.4375rem 0.75rem;
        height: 2.125rem;
        width: 6.375rem;
        font-size: 14px;
        color: black;
      }
    }

    .question_editor_container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .editor_wrapper {
        position: relative;
      }

      .btn_container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }

    // quill editor 커스텀 스타일 적용
    .quill_editor {
      position: relative;
      z-index: 100;

      .ql-editor {
        min-height: 10.125rem;
      }
    }

    &.FRQ {
      .quill_editor {
        position: relative;
        z-index: 100;

        .ql-editor {
          min-height: 20rem;
        }
      }
    }
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
};
