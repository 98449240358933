import useOutsideClick from 'hooks/useOutsideClick';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgArrow } from 'assets/img/svg/icon/icon_arrow_01.svg';

/** 달력 세팅 모달 에서 사용 하는 커스텀 "셀렉트 박스" 컴포넌트 */
export default function CustomSelectBox({ optionsArray, defaultLabel, handleOptionClick }) {
  const [isActive, setIsActive] = useState(false);

  const dropdownRef = useRef(null);

  // 영역 외부 클릭 시, 창 닫기
  useOutsideClick(dropdownRef, () => setIsActive(false), isActive);

  return (
    <S.Wrap ref={dropdownRef} className={`com_select_layer type_updown ${isActive ? 'active' : ''}`} onClick={() => setIsActive((prev) => !prev)}>
      <section className='selected_wrap'>
        <input value={defaultLabel} readOnly />
        <ImgSvgArrow />
      </section>
      <section className='layer custom_scroll'>
        {optionsArray.map(({ label, value }, index) => (
          <p key={index} className='option' onClick={() => handleOptionClick(label, value)}>
            {label}
          </p>
        ))}
      </section>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    &.type_updown::before {
      background-image: none;
    }
    &.type_updown::after {
      background-image: none;
    }
    .selected_wrap {
      position: relative;
      input {
        box-sizing: border-box;
        border-radius: 0.5rem;
        background: #fff;
        outline: none;
        padding: 0.5rem 0.75rem;
        padding-right: 1.375rem;
        border: 1px solid #000;
        width: 100%;
        height: 2.5rem;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: nowrap;
        caret-color: transparent;
        cursor: pointer;
        border-color: #d2dbe2;
      }
      svg {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        width: 1.125rem;
        color: #7f8998;
        transform: translateY(-50%) rotateZ(270deg);
        cursor: pointer;
        transition: 0.2s;
      }
    }

    &.active {
      .selected_wrap {
        svg {
          transform: translateY(-50%) rotateZ(90deg);
        }
      }
    }
  `,
};
