import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import useOutsideClick from 'hooks/useOutsideClick';

function CustomSelect({ options, onSelect, ...rest }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const selectedNode = dropdownRef.current.querySelector('[data-selected="true"]');
      if (selectedNode) {
        selectedNode.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [isOpen, selectedOption]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelect(option.value);
    setIsOpen(false);
  };

  useOutsideClick(dropdownRef, () => setIsOpen(false), isOpen);

  return (
    <SelectWrapper ref={dropdownRef} {...rest}>
      <SelectTrigger onClick={toggleDropdown}>
        <SelectValueSection>{selectedOption.label}</SelectValueSection>
        <SelectArrowSection>
          <Arrow />
        </SelectArrowSection>
      </SelectTrigger>
      {isOpen && (
        <Options>
          {options.map(
            (option, index) =>
              option.value !== null && (
                <Option key={index} data-selected={option === selectedOption} onClick={() => handleOptionClick(option)}>
                  {option.label}
                </Option>
              )
          )}
        </Options>
      )}
    </SelectWrapper>
  );
}

export default CustomSelect;

// --- --- --- Styled Components --- --- --- //
const SelectWrapper = styled.div`
  position: relative;
  user-select: none;
  width: 7rem;
`;

const SelectTrigger = styled.div`
  height: 2.5rem;
  border: 1px solid #d2dbe2;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;
const SelectValueSection = styled.div`
  padding: 0.6rem 0;
  padding-left: 1rem;
  color: #7f8998;
  font-weight: 400;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SelectArrowSection = styled.div`
  position: relative;
  border-left: 1px solid #d2dbe2;
  height: 100%;
  width: 2rem;
`;

const Options = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  z-index: 1000;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
`;

const Option = styled.li`
  padding: 0.6rem 1rem;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;
const Arrow = styled.span`
  --line-length: 5px;

  position: absolute;
  right: 50%;
  top: 45%;
  content: '';
  width: var(--line-length);
  height: var(--line-length);
  border-top: 1px solid #000;
  border-right: 1px solid #000;

  transform: rotate(135deg) translate(-50%, -50%); // 45deg: >, 225deg: <, 135deg: ∨, 315deg: ∧
  /* transform: rotate(135deg) translate(calc(-50% - var(--line-length) / 2), calc(-50% - var(--line-length) / 4)); // 45deg: >, 225deg: <, 135deg: ∨, 315deg: ∧ */
`;
