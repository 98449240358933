import React, { useState } from 'react';
import styled from 'styled-components';

import { allowImages, allowPdfFiles } from 'utils/Common.utils';

import { ReactComponent as ImgSvgIconCheck } from 'assets/gate_member/image/check_circle.svg';

import LabelFileUpload from './LabelFileUpload';
import UploadedFileItem from './UploadedFileItem';
import DeleteModal from './DeleteModal';

/** 파일 업로드 영역 컴포넌트 */
export default function Upload({ type, uploadFileList, setUploadFileList, setAlertLayerPopup, userPermission }) {
  const [fileCount, setFileCount] = useState(0);

  /** 삭제 확인 모달 노출 여부 상태 */
  const [isViewDeleteModal, setIsViewDeleteModal] = useState(false);

  const [isActive, setActive] = useState(false);

  /** 현재 선택 된 파일 아이템  */
  const [currentFileItem, setCurrentFileItem] = useState(false);

  const handleDragStart = () => setActive(true);
  const handleDragEnd = () => setActive(false);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    // const currentFile = event.dataTransfer.files[0];
    const fileList = event.dataTransfer.files;

    // for(const file of fileList) {
    //   getFileInfo(file)
    // }

    getFileListInfo(fileList);

    setActive(false);
  };

  const handleFileInput = (e) => {
    const fileList = e.target.files;

    getFileListInfo(fileList);

    e.target.value = '';
  };

  const getFileListInfo = (fileList) => {
    let tempFileList = [...uploadFileList];

    for (const file of fileList) {
      const { name, size, type } = file;
      const mbSize = (size / (1024 * 1024)).toFixed(2) + 'mb';

      if (!allowImages(type) && !allowPdfFiles(type)) {
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'Please upload image file or pdf file.',
          };
        });

        return;
      }

      // ----------------------------------------------
      const fileExists = tempFileList.some((existingFile) => existingFile.fileName === name);

      if (!fileExists) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          const readerResult = reader.result;

          tempFileList.push({
            fileName: name,
            fileSize: mbSize,
            mimeType: type,
            fileTextData: readerResult,
            fileData: file,
          });

          setUploadFileList(tempFileList);
          setFileCount(tempFileList.length);
        };
      }
    }
  };

  /** 현재 선택 된 파일 삭제 버튼 핸들러 */
  const handleDeleteButton = (fileItem) => {
    setIsViewDeleteModal(true);
    setCurrentFileItem(fileItem);
  };
  /** 파일 삭제 확인 버튼 후 실제 로직 핸들러 */
  const handleDeleteFile = (fileItem) => {
    let tempFileList = uploadFileList;

    let deleteFileList = tempFileList.filter((i) => i.fileName !== fileItem.fileName);
    setUploadFileList(deleteFileList);
    setIsViewDeleteModal(false);
  };

  return (
    <>
      <S.Wrap>
        <ul className='guide-general'>
          <li>
            <p className='li-dot'>
              <div className='dot'>&nbsp;</div>
            </p>
            <p>We strive to foster a diverse and productive community of educators.</p>
          </li>
          <li>
            <p className='li-dot'>
              <div className='dot'>&nbsp;</div>
            </p>
            {userPermission === 'tutor' ? (
              <p>To become a GATE+ Tutor, you have to give us proof of education in order to verify your qualification.</p>
            ) : (
              <p>To become a GATE+ Academy Administrator, you have to give us business credentials in order to verify your qualification.</p>
            )}
          </li>
        </ul>
        <div className='guide-required-data-by-user-type'>
          <p className='tit'>Please upload a copy or a photo of your :</p>
          {userPermission !== 'tutor' ? (
            <div className='item'>
              <ImgSvgIconCheck />
              Business Registration
            </div>
          ) : (
            <div className='item'>
              <ImgSvgIconCheck />
              Diploma or Student ID Card.
            </div>
          )}
        </div>

        <S.FileUploadArea className={`${uploadFileList.length > 0 ? 'file_uploaded' : 'fileupload_area'}`}>
          {/* file_uploaded   fileupload_area */}
          <div className='uploaded_files'>
            {uploadFileList != null && uploadFileList.length > 0
              ? uploadFileList?.map((fileItem, index) => (
                  <UploadedFileItem key={`fileInfo_${index}`} fileItem={fileItem} handleDeleteFile={handleDeleteButton} />
                ))
              : null}
          </div>
          <input type='file' className='upload_file' multiple id='fileInput' onChange={handleFileInput} />
          {uploadFileList == null || uploadFileList.length < 1 ? (
            <>
              <LabelFileUpload
                htmlFor={'fileInput'}
                onDragEnter={handleDragStart}
                onDragLeave={handleDragEnd}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                isUploaded={false}
              />
              <p className='guile-txt'>Image and/or PDF copy would suffice</p>
            </>
          ) : (
            <LabelFileUpload
              htmlFor={'fileInput'}
              onDragEnter={handleDragStart}
              onDragLeave={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              isUploaded={true}
            />
          )}
        </S.FileUploadArea>
      </S.Wrap>
      {/*첨부파일 삭제 클릭 시 팝업*/}
      {isViewDeleteModal && <DeleteModal setIsViewModal={setIsViewDeleteModal} callback={() => handleDeleteFile(currentFileItem)} />}
    </>
  );
}

const S = {};

S.Wrap = styled.div`
  .guide-general {
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #7f8998;
    li {
      display: flex;
      & + li {
        margin-top: 0.75rem;
      }
      .dot {
        color: rgb(127, 137, 152);
        background-color: rgb(127, 137, 152);
        margin: unset;
        margin-right: 8px;
        margin-top: 9px;
        width: 3px;
        height: 3px;
      }
    }
  }
  .guide-required-data-by-user-type {
    padding: 1.875rem 0;
    .tit {
      text-align: left;
      font-size: 0.875rem;
      font-weight: 400;
      color: #a5aab1;
    }

    .item {
      margin-top: 0.5rem;
      border-radius: 0.25rem;
      background-color: var(--Table-set-Table_fill_blue);
      color: var(--point-color);
      font-size: 0.875rem;
      font-weight: 400;
      padding: 1rem 0.625rem;
      width: 100%;
      line-height: 1rem;
      display: flex;
      align-items: center;
      svg {
        content: '';
        width: 1.125rem;
        min-width: 1.125rem;
        height: 1.125rem;
        border-radius: 100%;
        margin-right: 0.625rem;
      }
    }
  }

  input[type='file'].upload_file {
    display: none;
  }
`;

S.FileUploadArea = styled.div`
  .guile-txt {
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    padding: 0.75rem 0;
  }
`;
