import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import LoadingBar from 'utils/LoadingBar';

//component
import CustomAlert from 'components/_common/alerts/CustomAlert';
import MainBox from 'components/user/_components/layouts/MainBox';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import Header from 'components/user/sign-up/_components/layouts/Header';
import Logo from 'components/user/_components/layouts/Logo';
import Upload from 'components/user/sign-up/_components/pages/upload/_Upload';

/** 회원가입 - 소셜 회원가입 - Tutor - File Upload 페이지 */
export default function SignUpSocialTutorUploadPage() {
  const navigate = useNavigate();
  // const stateCustomer = useSelector((state) => state.customerReducer);
  const privateTutorReducer = useSelector((state) => state.privateTutorReducer);
  const userSocial = useSelector((state) => state.userReducer.social);
  console.log('🚀 ~ SignUpSocialTutorUploadPage ~ userSocial:', userSocial);
  const userSocialEmail = userSocial.userEmail;

  const [uploadFileList, setUploadFileList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    // userSocialEmail 값이 없으면 에러처리 404
    if (!userSocialEmail) {
      navigate('/404');
    }
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 알랏
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
    value: 0,
  });

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        alertType: 'alert',
        visible: false,
        alertMessage: '',
        activateMode: '',
        value: 0,
      };
    });
  };

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
    }
  };

  const signup = () => {
    console.log(`privateTutorReducer`, privateTutorReducer);
    // setLoading(true);

    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);

      if (response.code === 200) {
        let userSeq = response.result.userSeq;
        uploadActivate(userSeq);
      } else {
        setLoading(false);
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: response.message,
          };
        });
      }
    };
    const payload = {
      ...privateTutorReducer,
      isNew: true,
    };
    console.log(`payload`, payload);
    request.post(request.apiUris.auth.signupTutor, payload, successHandler);
  };

  const uploadActivate = (userSeq) => {
    // console.log("전송", uploadFileList)

    const formData = new FormData();

    Object.values(uploadFileList).forEach((file) => formData.append('uploadFiles', file.fileData));

    // for(const fileInfo of uploadFileList) {
    //   console.log(fileInfo.fileTextData, fileInfo.fileData)
    // }

    const successHandler = (response) => {
      // console.log(response)
      setLoading(false);
      navigate('/signup/successfully-submitted');
    };

    request.postMultipart(`/api/common/upload/files/academy/${userSeq}`, formData, successHandler);
  };

  const submitBtn = () => {
    if (uploadFileList.length > 0) {
      signup();
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please upload the file.',
        };
      });
    }
  };

  return (
    <>
      <S.Wrap>
        <MainBox>
          <Logo />
          <Header title={'Tutor'} />
          <Upload
            type={'tutor'}
            uploadFileList={uploadFileList}
            setUploadFileList={setUploadFileList}
            setAlertLayerPopup={setAlertLayerPopup}
            userPermission={'tutor'}
          />
          <PrimaryButtonArea>
            <PrimaryButton type='button' onClick={submitBtn} title='제출'>
              Submit
            </PrimaryButton>
          </PrimaryButtonArea>
        </MainBox>
      </S.Wrap>
      {isLoading && <LoadingBar />}
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
    </>
  );
}

const S = {};

S.Wrap = styled.div``;
