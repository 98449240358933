import React from 'react';
import styled from 'styled-components';

import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css'; // Quill 에디터 스타일
import 'mathquill/build/mathquill.css'; // MathQuill 스타일
import 'katex/dist/katex.min.css';

/** "Quill" 을 사용하여 구현 한 텍스트 에디터 컴포넌트 */
export default function ReactQuillEditor({ editorContentState, setEditorContentState }) {
  // Quill 모듈 설정
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // 제목 스타일
      ['bold', 'italic', 'underline', 'strike'], // 텍스트 스타일
      [{ list: 'ordered' }, { list: 'bullet' }], // 목록
      [{ indent: '-1' }, { indent: '+1' }], // 들여쓰기
      [{ align: [] }], // 정렬
      ['formula'], // 수식 입력 버튼 추가
      ['clean'], // 서식 제거
      ['table'],
    ],
  };

  // 지원하는 포맷 설정
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'indent',
    'align',
    'formula', // 수식 포맷 추가
    'table',
  ];

  return (
    <S.Wrap>
      <ReactQuill theme='snow' modules={modules} formats={formats} value={editorContentState} onChange={setEditorContentState} />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background-color: #ffffff;
  `,
};
