import BasicColumnChart from 'components/_common/chart/BasicColumnChart';

const SUBJECT = { RW: 'E', MATH: 'M' };

/** score에 timePerQuestion에 있는 그래프 영역 -  */
export default function TimePerQuestionChartSection({ activeSatBox, timePerQuestionData }) {
  const targetChartData = timePerQuestionData.targetTimePerQuestion.map((item) => Math.max(item.timeSpent ?? 0, 0));
  const gateChartData = timePerQuestionData.gateTimePerQuestion.map((item) => Math.max(item.timeSpent ?? 0, 0));

  const timePerQuestionChartDate = {
    series: [
      {
        name: 'You',
        data: targetChartData,
        color: SUBJECT[activeSatBox] === 'E' ? '#0068BD' : '#1fdf9f',
      },
      {
        name: 'GATE⁺',
        data: gateChartData,
        color: '#E3E7ED',
      },
    ],

    options: {
      xaxis: {
        categories: timePerQuestionData.targetTimePerQuestion.map((_, index) => index + 1),
      },
      chart: {
        zoom: {
          enabled: false,
        },
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const xAxisValue = w.globals.labels[dataPointIndex];
          return `<div className="custom-tooltip">Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        fontFamily: 'Roboto, Arial, sans-serif',
        markers: {
          width: 25,
          height: 8,
          radius: 8,
        },
        itemMargin: {
          horizontal: 15,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.7,
          },
        },
      },
    },
  };

  return <BasicColumnChart timePerQuestionData={timePerQuestionChartDate} />;
}
