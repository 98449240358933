import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import dayjsTZ from 'utils/functions/time/dayjs-config';
import styled from 'styled-components';
import request from 'utils/Request.utils';

import SimpleModalBase from './_SimpleModalBase';
import { ReactComponent as ImageIconAnnotationAlert01 } from 'assets/img/svg/icon-annotation-alert-01.svg';
import { ReactComponent as Logo } from 'assets/img/svg/logo_gateplus_row_01.svg';

import useUserLevels from 'hooks/useUserLevels';

// 🔍 검색 키워드 - #모달 #배포시간 #정기배포
/** 정기 배포 시간 안내 모달 컴포넌트 */
export default function ImportantAnnouncementModal() {
  /** 쿠키를 위한 아이디 문자열 (모달 별로 구분) */
  const cookiesId = 'popupCloseDate';

  const [showPopup, setShowPopup] = useState(false);

  // 🧰 공지 모달 내용 일시적 변경 ( 241210 )
  const userInfo = request.tokenDecoder();
  const { isB2B } = useUserLevels(userInfo?.userLevel);

  useEffect(() => {
    const today = dayjsTZ().toISOString().split('T')[0];
    const popupCloseDate = Cookies.get(cookiesId);
    if (popupCloseDate !== today) {
      setShowPopup(true);
    }
  }, []);

  /** 닫기 버튼 */
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  // 🧰 공지 모달 내용 일시적 변경 ( 241210 )
  const handleClickDetails = () => {
    window.open('https://gateplus.notion.site/Release-Notes-aa14a88d843a4e80af5dbc2f53cb622f', '_blank');
  };

  /** 오늘 그만 보기 버튼 */
  const handleStopToday = () => {
    const today = dayjsTZ().toISOString().split('T')[0];

    Cookies.set(cookiesId, today, { expires: 1 });
    setShowPopup(false);
  };

  if (!showPopup) return null;

  return (
    <SimpleModalBase noPad={true}>
      <S.Wrap>
        <div className='modal_title_container'>
          <Logo className='logo' />
          <button onClick={handleClosePopup}>
            <i className='svg_icon btn_pop_close black'>&nbsp;</i>
          </button>
        </div>
        <div className='contents-area'>
          <ImageIconAnnotationAlert01 />

          {/* 🧰 공지 모달 내용 일시적 변경 ( 241210 ) */}
          <h2>GATE+ Platform Update Notice</h2>

          {isB2B ? (
            <S.UpdateNotice>
              <h3>1. Score Class Tab</h3>
              <div className='update_notice_content'>
                <p className='update_notice_title'>Score Trend</p>
                <p className='update_notice_description'>The chart now shows changes in average scores for each test instead of weekly averages.</p>
              </div>
              <div className='update_notice_content'>
                <p className='update_notice_title'>Show Selection Rate</p>
                <p className='update_notice_description'>Check how often each answer choice was selected by students.</p>
              </div>
              <h3>2. Score Student Tab</h3>
              <div className='update_notice_content'>
                <p className='update_notice_title'>Now provides all test scores and individual results at a glance.</p>
              </div>
            </S.UpdateNotice>
          ) : (
            <S.UpdateNotice>
              <h3>1. Your Overall Average</h3>
              <div className='update_notice_content'>
                <p className='update_notice_description'>Now displays the average and statistics for all your tests instead of just a single test.</p>
              </div>
              <h3>2. Score Trend</h3>
              <div className='update_notice_content'>
                <p className='update_notice_description'>Updated to show scores by tests, making it easier to track performance changes.</p>
              </div>
              <h3>3. Test PDF Discontinued</h3>
              <div className='update_notice_content'>
                <p className='update_notice_description'>
                  To protect our intellectual property, the View PDF feature is disabled. Click each question number in the Correct Answer Rate list to view the
                  question on the platform.
                </p>
              </div>
            </S.UpdateNotice>
          )}
        </div>

        <div className='button-area'>
          <button onClick={handleStopToday}>Enough for today</button>
          <button onClick={handleClickDetails} className='black'>
            View Details
          </button>
        </div>
      </S.Wrap>
    </SimpleModalBase>
  );
}

const S = {};

// 🧰 공지 모달 내용 일시적 변경 ( 241210 )
S.UpdateNotice = styled.div`
  width: 100%;
  text-align: left;
  font-size: 0.875rem;
  h3 {
    font-weight: 500;
  }

  .update_notice_content {
    line-height: 23px;
    padding-left: 1.25rem;
    .update_notice_title {
      position: relative;

      ::before {
        content: '';
        position: absolute;
        width: 3px;
        height: 3px;
        background-color: #000000;
        border-radius: 50%;

        left: -13px;
        top: 10px;
      }
    }
  }
`;

S.Wrap = styled.div`
  width: 340px;
  padding: 1.875rem;
  box-sizing: content-box;
  .modal_title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eaeaea;
    .logo {
      width: 100px;
      color: #000000;
    }
  }
  .contents-area {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.875rem;
    padding: 1.25rem 0;
    h2 {
      font-size: 1rem;
      font-weight: bold;
    }
    p {
      font-size: 0.875rem;
    }
    .time {
      font-size: 1rem;
      font-weight: bold;
    }
  }
  .button-area {
    display: flex;
    gap: 0.5rem;
    button {
      width: 100%;
      border-radius: 0.5rem;
      padding: 0.625rem 0;
      border: 1px solid #000000;
      font-size: 0.875rem;
      &.black {
        background-color: #000000;
        color: #ffffff;
      }
    }
  }
`;

// 🧰 공지 모달 내용 일시적 변경 ( 241210 )

// <S.Wrap>
//   <div className='modal_title_container'>
//     <Logo className='logo' />
//     <button onClick={handleClosePopup}>
//       <i className='svg_icon btn_pop_close black'>&nbsp;</i>
//     </button>
//   </div>
//   <div className='contents-area'>
//     <ImageIconAnnotationAlert01 />
//     <h2>IMPORTANT ANNOUNCEMENT</h2>
//     <p>
//       For your seamless experience, we are tirelessly updating our platform everyday. During our updates, you will not be able to use our platform at all,
//       which includes everything from taking your test to checking your scores. Please avoid using our platform the following times:
//     </p>
//     <div className='time'>
//       Everyday, 07:00 AM ~ 07:10 AM KST <br /> Everyday, 23:00 PM ~ 23:10 PM KST
//     </div>
//     <p>Thank you for your interest and patience!</p>
//   </div>
//   <div className='button-area'>
//     <button onClick={handleStopToday}>Enough for today</button>
//     <button onClick={handleClosePopup} className='black'>
//       Close
//     </button>
//   </div>
// </S.Wrap>
