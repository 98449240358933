import { useEffect, useRef } from 'react';

/**
 * 무한 스크롤 기능을 제공하는 커스텀 훅
 *
 * @param {Function} onIntersect - 타겟 요소가 뷰포트와 교차할 때 실행될 콜백 함수
 * @param {boolean} hasMore - 추가로 불러올 데이터가 있는지 여부
 * @param {Object} options - IntersectionObserver 옵션
 * @param {number} options.threshold - 타겟 요소가 뷰포트와 교차하는 비율 (0.0 - 1.0, 기본값: 0.3)
 *
 * @returns {React.RefObject} obsRef - 관찰할 타겟 요소에 연결할 ref 객체
 *
 * @example
 * function MyComponent() {
 *   // 처음에는 true로 설정해야함
 *   const [hasMore, setHasMore] = useState(true);
 *
 *   const getData = () => { ... };
 *
 *   const obsRef = useInfiniteScroll(getData, hasMore, { threshold: 0.5 });
 *
 *   return (
 *     <div ref={obsRef} />
 *   );
 * }
 */
export function useInfiniteScroll(onIntersect, hasMore, options = { threshold: 0.3 }) {
  const obsRef = useRef(null);
  const optionsRef = useRef(options); // useEffect lint 경고 방지용 Ref

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore) onIntersect();
      },
      { ...optionsRef.current }
    );

    const currentObsRef = obsRef.current;
    if (currentObsRef) {
      observer.observe(currentObsRef);
    }

    return () => observer.disconnect();
  }, [hasMore, onIntersect]);

  return obsRef;
}
