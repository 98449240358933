import React, { useState } from 'react';
import styled from 'styled-components';

import StudentListModal from './modals/StudentListModal';
import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';

// variant별 설정 객체
const VARIANT_CONFIG = {
  buttonText: 'STUDENT LIST', // 외부에서 보일 버튼 텍스트
  modalComponent: StudentListModal, // 모달 컴포넌트
  shouldShowTitle: true, // 타이틀 보일지 여부
  getTitle: (curDataName) => (curDataName ? curDataName : 'Select Student'), // 타이틀 텍스트
  buttonWidth: '10rem', // 버튼 너비
  targetLabel: 'userName', // 데이터 명칭
};

/** "ap/com/score" 페이지 상단 메인 "Test 선택" 영역 컴포넌트 */
export default function SelectDetailSection({ variant, dataList, isModalActive = null }) {
  /** 현재 studentSeq - Route Parameter */
  const { studentSeq: encStudentSeq } = useParams();
  const studentSeq = deobfuscateUrlParam(encStudentSeq);

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [activeModal, setActiveModal] = useState(isModalActive);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /** 현재 선택된 데이터명을 가져오는 함수 (시험, 학생) */
  const curDataName = () => {
    // variant이 student 일때 ( 현재 선택된 student의 Seq로 데이터를 가져옴 )
    if (studentSeq) {
      return dataList?.find((item) => item.userSeq === Number(studentSeq))?.[VARIANT_CONFIG?.targetLabel];
    }
    return null;
  };

  //////////////////// START --- variant 별 조건부 랜더링 로직 --- START ////////////////////
  const renderTitle = () => {
    if (!VARIANT_CONFIG.shouldShowTitle) return <p className='tit' />;
    return <p className='tit'>{VARIANT_CONFIG.getTitle(curDataName())}</p>;
  };

  const renderModal = () => {
    if (!activeModal) return null;

    const modalProps = {
      setShowModal: setActiveModal,
      data: dataList,
    };
    const ModalComponent = VARIANT_CONFIG.modalComponent;
    return <ModalComponent {...modalProps} />;
  };
  ////////////////////  END  --- variant 별 조건부 랜더링 로직 ---  END  ////////////////////

  return (
    <>
      <S.Wrap>
        {renderTitle()}
        <S.ListButton variant={variant} disabled={!curDataName} onClick={() => setActiveModal(true)}>
          {VARIANT_CONFIG.buttonText}
          <i className='svg_icon after icon_list_white'>&nbsp;</i>
        </S.ListButton>
      </S.Wrap>
      {renderModal()}
    </>
  );
}

const S = {
  Wrap: styled.div`
    flex: auto;
    height: 3.25rem;
    display: flex;
    gap: 0.75rem;

    .tit {
      border: 2px solid #111111;
      border-radius: 0.5rem;
      background-color: #ffffff;
      font-weight: 600;
      font-size: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  `,

  ListButton: styled.button`
    font-weight: 600;
    font-size: 0.875rem;
    color: #ffffff;
    background-color: #111111;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex: 0 0 ${({ variant }) => VARIANT_CONFIG?.buttonWidth || '8rem'}; // variant 값에 따라 width 변경 (없을떄 8rem)
    transition: all 0.4s ease;
    filter: brightness(100%);

    &:disabled {
      background-color: gray;
      filter: brightness(50%);
    }
  `,
};
