import { Fragment } from 'react';
import styled from 'styled-components';
import PartArea from './_components/PartArea';
import SectionArea from './_components/SectionArea';
import FreeResponseQuestionArea from './_components/freeResponseQuestion/FreeResponseQuestionArea';
import MultipleChoiceQuestionArea from './_components/multipleChoiceQuestion/MultipleChoiceQuestionArea';

/** ap/com/score/grading/submission 페이지의 "Raw 점수 영역" 컴포넌트 */
export default function RawScoreArea({ sectionInfo }) {
  return (
    <S.Wrap>
      {sectionInfo &&
        sectionInfo.map((item, index) => (
          <Fragment key={index}>
            <SectionArea
              sectionInfo={item}
              dropdownChildren={
                item.partList &&
                item.partList.map((partItem, partIndex) => {
                  return item.partList.length > 1 ? (
                    <PartArea
                      key={`part_${partIndex}`}
                      partInfo={partItem}
                      dropdownChildren={
                        partItem.typeOfQuestion === 'M' ? (
                          <MultipleChoiceQuestionArea key={partIndex} partSeq={partItem.partSeq} questionsData={partItem.questionList} />
                        ) : partItem.typeOfQuestion === 'E' ? (
                          <FreeResponseQuestionArea key={partIndex} partSeq={partItem.partSeq} questionsData={partItem.questionList} />
                        ) : null
                      }
                    />
                  ) : partItem.typeOfQuestion === 'M' ? (
                    <MultipleChoiceQuestionArea key={`single_mcq_${partIndex}`} partSeq={partItem.partSeq} questionsData={partItem.questionList} />
                  ) : partItem.typeOfQuestion === 'E' ? (
                    <FreeResponseQuestionArea key={`single_frq_${partIndex}`} partSeq={partItem.partSeq} questionsData={partItem.questionList} />
                  ) : null;
                })
              }
            />
          </Fragment>
        ))}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
};
