import styled from 'styled-components';

/** 개별 주기율표 원소 컴포넌트 */
export default function UI_PeriodicElement({ element }) {
  return (
    <S.PeriodicElement>
      <span className='atomic_number'>{element.number}</span>
      <span className='symbol'>{element.symbol}</span>
      {element.mass !== false && <span className='mass'>{element.mass}</span>}
    </S.PeriodicElement>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  PeriodicElement: styled.div`
    box-shadow: 0 0 0 1px black;
    border: none;
    padding-top: 0.75rem;
    padding-bottom: 0.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    cursor: pointer;
    background-color: white;

    &:hover {
      transform: scale(2);
      z-index: 1;
    }

    span {
      text-align: center;
      line-height: 1;
    }

    .atomic_number {
      font-size: 0.875rem;
      line-height: 0.75;
    }

    .symbol {
      font-size: 1.75rem;
      font-weight: bold;
      line-height: 1.25;
    }

    .mass {
      font-size: 0.875rem;
      min-height: 1rem;
    }
  `,
};
