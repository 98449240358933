import { useEffect, useState } from 'react';
import AnswerRateGraphBox from '../graphComponent/AnswerRateGraphBox';
import request from 'utils/Request.utils.js';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { STUDENT_PARAMS } from 'utils/constants';
import useUserLevels from 'hooks/useUserLevels';

// 차트 설정 데이터: 각 난이도별 라벨과 그래프 색상 정의
const DIFFICULTY_CHART_CONFIG = {
  [STUDENT_PARAMS.SUBJECT.VALUE.RW]: {
    E: { label: 'Easy', graphColor: '#79C3FF' },
    M: { label: 'Medium', graphColor: '#008CFF' },
    H: { label: 'Hard', graphColor: '#0068BD' },
  },
  [STUDENT_PARAMS.SUBJECT.VALUE.MATH]: {
    E: { label: 'Easy', graphColor: '#1FDF9F' },
    M: { label: 'Medium', graphColor: '#14BD85' },
    H: { label: 'Hard', graphColor: '#178A64' },
  },
};

export default function CorrectRateDifficultyGraphBox({ checkViewAboutAverageOfGateplus, childSeqState }) {
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** 현재 선택 된 시험 세트의 UTH Seq */
  const selectedUthSeq = getObfuscatedValue(STUDENT_PARAMS.UTH_SEQ.KEY);
  /** 현재 선택 된 시험의 과목 문자열 */
  const selectedSubject = getObfuscatedValue(STUDENT_PARAMS.SUBJECT.KEY);

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo.userLevel);

  // 랜더링할 난이도별 정답률 데이터 ( class or student )
  const [correctRate, setCorrectRate] = useState({
    targetCorrectRateOfDifficulty: [
      {
        difficulty: '', // "E" | "M" | "H"  => 문제 난이도 ( Easy("E") | Medium("M") | Hard("H") )
        rateOfCorrect: 0, // number         => 정답률
        subject: '', // "E" | "M"           => 문제 과목 ( 영어("E") | 수학("M") )
      },
    ],
    gateCorrectRateOfDifficulty: [
      {
        difficulty: '', // "E" | "M" | "H"  => 문제 난이도 ( Easy("E") | Medium("M") | Hard("H") )
        rateOfCorrect: 0, // number         => 정답률
        subject: '', // "E" | "M"           => 문제 과목 ( 영어("E") | 수학("M") )
      },
    ],
  });

  useEffect(() => {
    /** mode가 student 일때 난이도별 정답률 정보를 가져오는 API 요청 함수 */
    const getStudentCorrectRateDifficulty = ({ selectedSubject, selectedUthSeq, childSeq = null }) => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentCorrectRateOfDifficulty = response.result.studentCorrectRateOfDifficulty;
          const fetchedGateCorrectRateOfDifficulty = response.result.gateCorrectRateOfDifficulty;
          setCorrectRate({
            targetCorrectRateOfDifficulty: fetchedStudentCorrectRateOfDifficulty,
            gateCorrectRateOfDifficulty: fetchedGateCorrectRateOfDifficulty,
          });
        }
      };

      const targetSubject = selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.RW ? 'E' : 'M';

      // Uth가 존재하는 경우 Uth를 포함하여 API 호출
      const apiURL = `/api/v2/student/dashboard/correct-rate/${targetSubject}${selectedUthSeq ? `?uthSeq=${selectedUthSeq}${childSeq ? `${selectedUthSeq ? '&' : '?'}childStudentSeq=${childSeq}` : ''}` : ''}`;

      request.get(apiURL, null, successHandler).catch((error) => console.error('API 호출 중 오류 발생:', error));
    };

    if (!useLevelsIsLoading) {
      if (useLevels.isParent) {
        // 학부모 계정 일 경우
        if (!childSeqState.isLoading && childSeqState.value) {
          // 자식 시퀸스 로딩이 끝났을 경우에만 실행
          getStudentCorrectRateDifficulty({ selectedSubject, selectedUthSeq, childSeq: childSeqState.value });
        }
      } else {
        // 학부모 계정이 아닐 경우
        getStudentCorrectRateDifficulty({ selectedSubject, selectedUthSeq });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject, selectedUthSeq, childSeqState, useLevels, useLevelsIsLoading]);

  // 차트 데이터 가공 - 랜더링 할 time spent 데이터 배열
  const processChartData = () => {
    // 응답 데이터가 없는 경우 빈 배열 반환
    if (!correctRate.targetCorrectRateOfDifficulty || !correctRate.gateCorrectRateOfDifficulty) {
      return [];
    }

    // 난이도별로 데이터 매핑
    return Object.keys(DIFFICULTY_CHART_CONFIG[selectedSubject ? selectedSubject : STUDENT_PARAMS.SUBJECT.VALUE.RW]).map((difficulty) => {
      // 각 난이도별 클래스 정답률 찾기
      const classData = correctRate.targetCorrectRateOfDifficulty.find((item) => item.difficulty === difficulty);

      // 각 난이도별 GATE+ 정답률 찾기
      const gateData = correctRate.gateCorrectRateOfDifficulty.find((item) => item.difficulty === difficulty);

      const classDifficulty = classData?.difficulty;

      return {
        label: DIFFICULTY_CHART_CONFIG[selectedSubject ? selectedSubject : STUDENT_PARAMS.SUBJECT.VALUE.RW][difficulty].label,
        graphColor: DIFFICULTY_CHART_CONFIG[selectedSubject ? selectedSubject : STUDENT_PARAMS.SUBJECT.VALUE.RW][difficulty].graphColor,
        data: classData?.rateOfCorrect ?? 0,
        gatePlusData: gateData?.rateOfCorrect ?? 0,
        toolTipStr: classDifficulty === 'E' ? 'Easy' : classDifficulty === 'M' ? 'Medium' : classDifficulty === 'H' ? 'Hard' : '',
      };
    });
  };

  return (
    <AnswerRateGraphBox
      boxTitle='Correct rate by Difficulty'
      setActiveSatBox={() => {}}
      isActive
      chartInfo={{
        label: selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.MATH ? 'Math' : 'RW',
        unit: '%',
        chartDate: processChartData(),
      }}
      checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
    />
  );
}
