import styled from 'styled-components';

/** 시험 생성 페이지에서 좌우 영역을 나누는 ui 컴포넌트 */
export default function UI_ExamContainer({ className = '', children, ...rest }) {
  return (
    <S.ExamContainer className={`custom_scroll ${className}`} {...rest}>
      {children}
    </S.ExamContainer>
  );
}

// --- --- --- Styled Components --- --- ---
const S = {
  ExamContainer: styled.section`
    display: flex;
    flex: 1;
    padding: 0.75rem 0;
    max-width: 50%;
    min-height: 0;
    justify-content: center;

    &.left {
      align-items: flex-end;
    }
    &.right {
      align-items: flex-start;
    }

    .full_width {
      width: 100%;
    }
  `,
};
