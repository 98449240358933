import SimpleAlertModal from 'components/_common/modals/_SimpleAlertModal';
import useOutsideClick from 'hooks/useOutsideClick';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
import MiniReviewPage from './MiniReviewPage';

/** AP 시험 화면 Footer 영역 컴포넌트 */
export default function ExamFooter({ stateExamInfo, setStateExamInfo, questionInfo }) {
  //#region    ////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  const userInfo = request.tokenDecoder();
  //#endregion //////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const navigate = useNavigate();
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [isOpened, setIsOpened] = useState(false);
  const [simpleAlert, setSimpleAlert] = useState({
    modalText: 'SOME_MODAL_TEXT',
    visible: false,
    callback: () => console.log('모달_클릭'),
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  const miniReviewToggleRef = useRef(null);
  useOutsideClick(miniReviewToggleRef, () => setIsOpened((prev) => !prev), isOpened);
  //#endregion //////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 시험 - 이전 버튼 클릭 이벤트 핸들러 */
  const backButtonClick = () => {
    // 첫 번째 문제일 경우 - 이전 버튼 클릭 무시
    if (stateExamInfo.currentNum === 1) return;

    // 리뷰 팝업이 열려있을 경우 - 리뷰 팝업 닫기
    if (stateExamInfo.isReviewPopupOpen) {
      setStateExamInfo((prev) => ({ ...prev, isReviewPopupOpen: false }));
      return;
    }

    setStateExamInfo((prev) => ({ ...prev, currentNum: prev.currentNum - 1 }));
    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
    localStorage.setItem(`apTest_${uthSeq}`, 'currentNum', stateExamInfo.currentNum - 1);
  };
  /** 시험 - 다음 버튼 클릭 이벤트 핸들러 */
  const nextButtonClick = () => {
    // 리뷰 팝업이 열려있지 않고 마지막 문제일 경우 - 리뷰 팝업 오픈
    if (!stateExamInfo.isReviewPopupOpen && stateExamInfo.currentNum === stateExamInfo.totalQuestion) {
      setStateExamInfo((prev) => ({ ...prev, isReviewPopupOpen: true }));
      return;

      // 리뷰 팝업이 열려있을 경우 ( 세션의 마지막 ) - 이동을 묻는 모달 출력, Confirm 클릭시 상황에 맞는 동작 실행
    } else if (stateExamInfo.isReviewPopupOpen) {
      setSimpleAlert({
        modalText: 'Are you sure you want to move on to next Section?\nMake sure you have answered all the questions.',
        isConfirm: true,
        visible: true,
        callback: () => {
          setSimpleAlert((prev) => ({ ...prev, visible: false }));
          const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;

          const examInfo = localStorage.getItemJsonParse(`apTest_${uthSeq}`);
          const testInfo = examInfo.testInfo;
          const partSeq = examInfo.partSeq;

          const currentIndex = examInfo.testInfo.partInfo.findIndex((item) => item.partSeq === examInfo.partSeq);
          const lastPartIndex = examInfo.testInfo.partInfo.length - 1;
          const nextPartIndex = currentIndex + 1;

          const currentPartInfo = examInfo.testInfo.partInfo[currentIndex];
          const nextPartInfo = examInfo.testInfo.partInfo[nextPartIndex];

          // 다음 순서가 휴식시간일 경우
          if (!(lastPartIndex === currentIndex) && currentPartInfo.sectionNo < nextPartInfo.sectionNo) {
            localStorage.setItem(`apTest_${uthSeq}`, 'startTime', Date.now());
            navigate('/ap/exam/break', { replace: true });
            return;
          }

          // 마지막 세션일 경우
          if (lastPartIndex === currentIndex) {
            // 마지막 세션일 경우
            navigate('/ap/exam/finish', { replace: true, state: { giveUp: false } });
            return;
          }

          // 다음 세션으로 이동
          setStateExamInfo((prev) => ({ ...prev, showModuleOver: true }));
        },
      });

      // 리뷰 팝업이 열려있지 않고 마지막 문제가 아닐 경우 - 다음 문제로 이동
    } else {
      setStateExamInfo((prev) => ({ ...prev, currentNum: prev.currentNum + 1, isReviewPopupOpen: false }));
      const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
      localStorage.setItem(`apTest_${uthSeq}`, 'currentNum', stateExamInfo.currentNum + 1);
    }
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  // 문제당 머문 시간 체크
  useEffect(() => {
    const uthSeq = localStorage.getItemJsonParse('currentTest')?.uthSeq;

    const currentTestKey = `apTest_${uthSeq}`;
    const savedData = localStorage.getItemJsonParse(currentTestKey);

    const questionIndex = stateExamInfo.currentNum - 1;
    const questionList = savedData.questionList;

    let solvingTime = questionList[questionIndex]?.solvingTime || 0;
    // 타이머 시작
    const intervalId = setInterval(() => {
      solvingTime += 1;
    }, 1000);

    // 5초마다 로컬스토리지 업데이트
    const saveIntervalId = setInterval(() => {
      localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', questionIndex, { solvingTime });
    }, 5000);

    return () => {
      clearInterval(intervalId);
      clearInterval(saveIntervalId);

      // 마지막 저장
      localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', questionIndex, { solvingTime });
    };
  }, [stateExamInfo.currentNum]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <>
      <section className='pop_footer'>
        <div className='name'>{userInfo?.userName}</div>
        <div ref={miniReviewToggleRef}>
          <button className='btn_question' onClick={() => setIsOpened((prev) => !prev)}>
            Question {stateExamInfo.currentNum} of {stateExamInfo.totalQuestion}
            <i className='svg_icon icon_arrow white'>&nbsp;</i>
          </button>
          {isOpened && (
            <MiniReviewPage
              stateExamInfo={stateExamInfo}
              setStateExamInfo={setStateExamInfo}
              questionInfo={questionInfo}
              closePopup={() => setIsOpened(false)}
            />
          )}
        </div>
        <article className='btn_area'>
          <button className='btn' onClick={backButtonClick}>
            Back
          </button>
          <button className='btn' onClick={nextButtonClick}>
            Next
          </button>
        </article>
      </section>

      <SimpleAlertModal alertInfo={simpleAlert} closeModal={() => setSimpleAlert((prev) => ({ ...prev, visible: false }))} />
    </>
  );
}
