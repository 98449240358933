import React from 'react';
import styled from 'styled-components';
import { ReactComponent as IconCheck } from 'assets/img/svg/icon_check_01.svg';

/** 로그인 & 회원가입 용 체크박스 스타일 컴포넌트 */
export default function SignCheckBoxBase({ checkBoxId, register, children, ...rest }) {
  return (
    <S.Wrap>
      <input type='checkbox' id={checkBoxId} {...register} {...rest} />
      <label htmlFor={checkBoxId}>
        <div className='check-box'>
          <IconCheck className='check_icon' />
        </div>
        <span className='remember'>{children}</span>
      </label>
    </S.Wrap>
  );
}

const S = {};
S.Wrap = styled.div`
  input[type='checkbox'] {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    width: 0;
    height: 0;
    opacity: 0;
    & + label {
      color: #505050;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      display: flex;
      gap: 0.5rem;
      align-items: flex-start;
      text-align: left;
      cursor: pointer;
      margin-top: 4px;
      .check-box {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 1.2rem;
        height: 1.2rem;
        flex-shrink: 0;
        border-radius: 25%;
        background-color: #ffffff;
        border: 1px solid #d7dadf;
        .check_icon {
          width: 1rem;
          height: 1rem;
          visibility: hidden;
          color: #ffffff;
        }
      }
    }
    &:checked + label {
      .check-box {
        border: none;
        background-color: #0068bd;
        .check_icon {
          visibility: visible;
          width: 0.9rem;
          height: 1.3rem;
        }
      }
    }
    &:focus + label {
      .check-box {
        outline: 1px solid var(--point-color);
      }
    }
  }
`;
