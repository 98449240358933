import styled from 'styled-components';

import usePressESC from 'hooks/usePressESC';

import { ReactComponent as ImgSvgIconX } from 'assets/img/svg/x-close.svg';

/** AP - Review 페이지의 문제 선택 (미니 창) 컴포넌트 */
export default function MiniReview({
  className = '',
  questionDataArray = [],
  currentNum = 0,
  handleMoveCallBack = ({ queryCurQuestionOrder = 0, destinationOrder = 0 }) => {},
  closePopup,
}) {
  usePressESC(() => closePopup());

  /** 각 문제 번호 클릭 핸들러 */
  const handleClickQuestion = (item) => {
    handleMoveCallBack({ queryCurQuestionOrder: currentNum, destinationOrder: item.questionNum });
  };

  return (
    <S.Wrap className={` ${className}`}>
      <div className='top_area'>
        <button type='button' title='Close this modal' className='btn_close' onClick={closePopup}>
          <ImgSvgIconX />
        </button>
      </div>
      <div className='question_field'>
        {/* 문제 목록 렌더링 */}
        {questionDataArray &&
          questionDataArray.map((item, index) => {
            const questionOrder = item.questionNum + 1;

            return (
              <button
                key={`mini_${questionOrder}`}
                type='button'
                title='Move to this Question'
                disabled={item.questionNum === currentNum}
                className={`item
                  ${item.isCorrect ? 'correct' : ''}
              `}
                onClick={() => handleClickQuestion(item)}>
                {questionOrder}
              </button>
            );
          })}
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    min-width: 19.5rem;
    padding: 1.25rem 2rem 2rem 2rem;
    border-radius: 0.625rem;
    background: #fff;
    filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.24));

    &:after {
      content: '';
      position: absolute;
      bottom: -1.75rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 1.75rem 0.9375rem;
      border-top-color: #fff;
      border-bottom: 0;
    }

    .top_area {
      position: relative;
      width: 100%;
      .btn_close {
        position: absolute;
        right: 0;

        svg {
          width: 1.25rem;
          color: #111111;
        }
      }
    }

    .question_field {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem;
      .item {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.25rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;

        border: 1px dashed #111;
        background: #c03838;

        color: #fff;
        text-align: center;
        font-family: Roboto;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem;

        &.correct {
          background: #384cc0;
        }
        &:disabled {
          background: #ffffff;
          color: #111;
        }
      }
    }
  `,
};
