/** "useTestEditor" Hook에서 사용하는 상태 접근 용 상수 값 */
export const EDITOR_CONSTANT = {
  currentIndex: 'currentIndex',

  // State
  currentSection: 'currentSection',
  currentPart: 'currentPart',
  currentQuestion: 'currentQuestion',
  answerChoiceInfo: 'answerChoiceInfo',
  sourceContent: 'sourceContent',
  currentSourceOrder: 'currentSourceOrder',
};

/** 객관식 답변 타입 : 정답 갯수 단수/복수 */
export const ANSWER_CHOICE_CONSTANT = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
};

export const QUESTION_TYPE_CONSTANT = {
  MCQ: 'M',
  FRQ: 'E',
  FRQ_LABEL: 'Free Response',
  MCQ_LABEL: 'Multiple Choice',
};

/** 문제 데이터(API 요청 값)의 키 값 접근 용 상수 */
export const QUESTION_CONSTANT = {
  question_order: 'question_order',
  custom_question_order: 'custom_question_order',
  question_context: 'question_context',
  question_type: 'question_type',
  multiple_choice_type: 'multiple_choice_type',
  question_difficulty: 'question_difficulty',
  score: 'score',
  question_code: 'question_code',
};

/** 문제 데이터(API 요청 값) 초기 값 */
export const initialQuestion = {
  part_seq: 0, // number  => 파트 seq
  // Source
  source_group: {
    source_directions: '', // 미사용
    sources: [
      // {
      //   id: 0, // 각 소스의 고유 값
      //   order: 0, // 각 소스의 순서
      //   content: '', // 각 소스 내용
      // },
    ],
  },
  // Question
  question_order: 0, // number  => 실질 문제 순서
  // custom_question_order: '', // string  => 유저가 커스텀 한 문제 순서
  custom_question_order: null,
  question_type: 'M', //  (M: 객관식, E: 서술형)
  question_difficulty: null, // "None" | "Easy" | "Medium" | "Hard" => 문제 난이도
  question_content: '', // string  => 문제 내용
  score: 1, // number  => 문제 점수 ( 초기값 1 )
  question_code: '', // string  => 문제 코드
  question_context: false, // 미사용
  // 문제 보기 정보
  answer_options: {
    option1: '',
    // option2: '',
    // ...

    total: 1, // 보기 전체 갯수
    answerCount: 0, // 정답 갯수
  },
  answer: [], // "option1", "option2", ...  => 정답 key

  // section_name: '',
  // part_name: '',
  // test_name: '',
  // subject_name: '',
};

export const initAnswerChoice = {
  choiceType: 'Text Input', // "Text Input" => 보기 입력 유형
  content: '', // string => 보기 내용
};

/** "Answer Choice Settings" 모달에서 사용 할 옵션 값 상수 */
export const initAnswerChoiceInfo = {
  // 🧰 기획 간소화 및 모드 변경 불가하게 설정
  // currentMode: 'single', // "single" | "multiple" => 현재 선택된 모드
  // single: {
  //   multipleChoiceType: 'Single-select', // "Single-select"  => 단일 선택 문제 유형
  //   correctAnswer: [null], // number[] => 정답 번호
  // },
  // multiple: {
  //   multipleChoiceType: 'Multi-select', // "Multi-select" => 다중 선택 문제 유형
  //   noOfCorrectAnswer: 1, // number => 정답 갯수
  //   correctAnswer: [null], // number[] => 정답 번호
  // },
  answerChoices: [initAnswerChoice],
};

export const initCurrentPart = {
  part_seq: 0, // number => 파트 seq
  order_no: 0, // number => 파트 순서
  name: '', // string => 파트 이름
  section_time: 0, // number => 섹션 시간
  type_of_question: 'M', //  (M: 객관식, E: 서술형)
  number_of_questions: 0, // number => 문제 갯수

  calculator: false, //
  reference_sheet: false, //
  directions_sheet: false, //
  source_grouped_questions: 0, //
  answer_choices: 0, //
  score_per_question: 0, //
  weight_of_part_score: 0, //
  total_raw_score: 0, //
};
