import React from 'react';
import styled from 'styled-components';

/** "회원 가입"페이지의 input 요소 컴포넌트 */
export default function Input({ register, isError, ...rest }, ref) {
  return <S.Input className={isError ? 'error' : ''} {...register} {...rest} />;
}

const S = {};

S.Input = styled.input`
  border: 1px solid #d2dbe2;
  padding: 0.75rem 1rem;
  height: auto;
  width: 100%;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  &:read-only {
    border-color: #d2dbe2;
    background: #f4f4f4;
    color: #a6b3be;
  }
  &[type='text'],
  &[type='email'],
  &[type='number'],
  &[type='tel'],
  &[type='password'] {
    &.error {
      border-color: #f24b4b;
    }
    &:focus.error {
      border-color: #f24b4b;
    }
  }
`;
