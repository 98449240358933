import TimeSpentGraphBox from '../graphComponent/TimeSpentGraphBox';
import CorrectRateDifficultyGraphBox from '../graphComponent/CorrectRateDifficultyGraphBox';

/**  */
export default function ExamStatisticsGraphSection({ mode, selectedSubject, checkViewAboutAverageOfGateplus }) {
  return (
    <div className='average_time_container'>
      <TimeSpentGraphBox mode={mode} selectedSubject={selectedSubject} checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus} />
      <CorrectRateDifficultyGraphBox mode={mode} selectedSubject={selectedSubject} checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus} />
    </div>
  );
}
