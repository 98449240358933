import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

import TestStatusByClasses from './TestStatusByClasses';
import TestStatusByTotal from './TestStatusByTotal';
import NoData from './_components/NoData';

/** "아케데미 대시보드" 페이지 - "Test Status" 컴포넌트 ("By ~ 로 시작하는 하위 컴포넌트를 갖는 컴포넌트") */
export default function TestStatus() {
  const userInfo = request.tokenDecoder();

  //////////////////// useState 선언 영역 시작 ///////////////////
  const [currentClassSeq, setCurrentClassSeq] = useState(0);
  const [firstClassSeq, setFirstClassSeq] = useState(0);
  const [donutChartData, setDonutChartData] = useState({
    data: [0, 0, 0],
    legend: {
      show: false,
    },
    width: 370,
  });
  const [currentClassName, setCurrentClassName] = useState('');
  const [colChartData, setColChartData] = useState({
    series: [
      {
        name: 'Not taking the test',
        data: [],
      },
      {
        name: 'Currently taking',
        data: [],
      },
      {
        name: 'Completed tests',
        data: [],
      },
    ],
    xaxis: {
      type: 'category',
      categories: [],
      title: {
        //text: 'Class',
        rotate: 0, // 레이블을 가로로 표시
        offsetX: 80, // 오른쪽으로 이동
        offsetY: -10, // 아래로 이동
      },
    },
    yaxis: {
      title: {
        //text: 'People', // y축 레이블
        rotate: 0, // 레이블을 가로로 표시
        offsetX: 5, // 오른쪽으로 이동
        offsetY: -150, // 아래로 이동
        style: {
          fontSize: '12px', // 글자 크기
          color: '#000000', // 글자색
        },
      },
      forceNiceScale: true, // 소수점 없이 정수 간격으로 표시
    },
    colors: ['#000000', '#0068BD', '#E3E7ED'],
    enabled: false,
    // labels: false
    labels: [77, 88, 99],
  });

  // added by psk 20240131 - 반 선택했을 때 처리
  const [classColChartData, setClassColChartData] = useState({
    series: [
      {
        name: 'Not taking the test',
        data: [],
      },
      {
        name: 'Currently taking',
        data: [],
      },
      {
        name: 'Completed tests',
        data: [],
      },
    ],
  });
  //////////////////// useState 선언 영역 끝 ///////////////////

  //////////////////// 기타 핸들러 선언 영역 시작 ///////////////////
  const getTestStatusCnt = (classSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let testStatusCnt = response.result.testStatusCnt;
        let classTestStatus = response.result.classTestStatusCnt;

        if (nvl(testStatusCnt) !== '' && testStatusCnt.length > 0) {
          setDonutChartData((prev) => {
            return {
              ...prev,
              data: [testStatusCnt[0].testStatusI, testStatusCnt[0].testStatusE, testStatusCnt[0].testStatusP],
            };
          });
        }

        let classTestStatusI = [];
        let classTestStatusE = [];
        let classTestStatusP = [];
        let className = [];
        let classSeq = [];

        if (classTestStatus && classTestStatus.length > 0) {
          setFirstClassSeq(classTestStatus[0].classSeq);

          for (let i = 0; i < classTestStatus.length; i++) {
            classTestStatusI.push(classTestStatus[i].testStatusI);
            classTestStatusE.push(classTestStatus[i].testStatusE);
            classTestStatusP.push(classTestStatus[i].testStatusP);
            className.push(classTestStatus[i].className);
            classSeq.push(classTestStatus[i].classSeq);
          }
        }

        const newSeries = [
          {
            name: 'Not taking the test',
            data: classTestStatusE,
            seq: classSeq,
          },
          {
            name: 'Currently taking',
            data: classTestStatusI,
            seq: classSeq,
          },
          {
            name: 'Completed tests',
            data: classTestStatusP,
            seq: classSeq,
          },
        ];

        setColChartData((prev) => ({
          ...prev,
          series: newSeries,
          xaxis: {
            ...prev.xaxis,
            categories: className,
          },
        }));
      }
    };

    request.get(`/api/dsat/test/status?ecSeq=${userInfo?.ecSeq}&classSeq=${classSeq}`, null, successHandler);
  };

  const getTestStatusCntByClass = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let testStatusCnt = response.result.testStatusCnt;
        let classTestStatus = response.result.classTestStatusCnt;

        let classTestStatusI = [];
        let classTestStatusE = [];
        let classTestStatusP = [];
        let className = [];

        setCurrentClassName(classTestStatus[0].className);

        for (let i = 0; i < classTestStatus.length; i++) {
          classTestStatusI.push(classTestStatus[i].testStatusI);
          classTestStatusE.push(classTestStatus[i].testStatusE);
          classTestStatusP.push(classTestStatus[i].testStatusP);
          className.push(classTestStatus[i].className);
        }

        const newSeries = [
          {
            name: 'Not taking the test',
            data: classTestStatusE,
          },
          {
            name: 'Currently taking',
            data: classTestStatusI,
          },
          {
            name: 'Completed tests',
            data: classTestStatusP,
          },
        ];

        setClassColChartData((prev) => ({
          ...prev,
          series: newSeries,
          xaxis: {
            ...prev.xaxis,
            categories: className,
          },
        }));
      }
    };

    request.get(`/api/dsat/test/status?ecSeq=${userInfo?.ecSeq}&classSeq=${currentClassSeq}`, null, successHandler);
  };
  //////////////////// 기타 핸들러 선언 영역 끝 ///////////////////

  //////////////////// useEffect 선언 영역 시작 ///////////////////
  useEffect(() => {
    if (currentClassSeq > 0) getTestStatusCntByClass();
    else getTestStatusCnt(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClassSeq]);
  //////////////////// useEffect 선언 영역 끝 ///////////////////
  return (
    <S.Wrap>
      <div className='top_area'>
        <h3 className='main_title'>Test Status</h3>
        {donutChartData.data[0] + donutChartData.data[1] + donutChartData.data[2] !== 0 && (
          <Link className='button' to='/com/tests/status'>
            Go to Test Status
          </Link>
        )}
      </div>
      {donutChartData.data[0] + donutChartData.data[1] + donutChartData.data[2] === 0 && <NoData className='no_data' />}
      <div className={`contents_area ${donutChartData.data[0] + donutChartData.data[1] + donutChartData.data[2] === 0 && 'no_data'}`}>
        <TestStatusByClasses
          classColChartData={classColChartData}
          colChartData={colChartData}
          currentClassSeq={currentClassSeq}
          currentClassName={currentClassName}
          firstClassSeq={firstClassSeq}
          setCurrentClassSeq={setCurrentClassSeq}
        />
        <TestStatusByTotal currentClassSeq={currentClassSeq} firstClassSeq={firstClassSeq} donutChartData={donutChartData} />
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    background-color: #ffffff;
    padding: 1.5rem 1.25rem;
    .top_area {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
      .main_title {
        font-size: 1.25rem;
        font-weight: 600;
        text-transform: uppercase;
      }
      .button {
        color: #0068bd;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: -0.28px;
        border: 1px solid #0068bd;
        border-radius: 0.375rem;
        padding: 0 0.625rem;
        height: 1.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .contents_area {
      display: flex;
      flex: auto;
      & > * {
        width: calc(50% - 10px);
        flex: auto;
        padding: 0;
        background: #fff;
        box-shadow: none;
        &:nth-child(odd) {
          padding-right: 1.75rem;
        }
        &:nth-child(even) {
          padding-left: 1.75rem;
          border-left: 1px solid #eaeaea;
        }
      }

      &.no_data {
        & > * {
          &:nth-child(even) {
            border-left: none;
          }
        }
      }
    }
  `,
};
