import { useState, useEffect } from 'react';
import styled from 'styled-components';
import request from 'utils/Request.utils';
import AnswerRateGraphBox from './graphComponent/AnswerRateGraphBox';
import { STUDENT_PARAMS } from 'utils/constants';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import useUserLevels from 'hooks/useUserLevels';

// 차트 설정 데이터: 각 타입별 라벨과 그래프 색상 정의
const CHART_CONFIG = {
  // RW
  E: {
    label: 'RW',
    unit: '%',
    chartOptions: [
      { label: 'CAS', graphColor: '#ffa88c' },
      { label: 'IAI', graphColor: '#8fff8c' },
      { label: 'SEC', graphColor: '#8cc1ff' },
      { label: 'EOI', graphColor: '#a38cff' },
    ],
  },
  // MATH
  M: {
    label: 'MATH',
    unit: '%',
    chartOptions: [
      { label: 'Alg', graphColor: '#ffa88c' },
      { label: 'AM', graphColor: '#8fff8c' },
      { label: 'PSADA', graphColor: '#8cc1ff' },
      { label: 'GAT', graphColor: '#a38cff' },
    ],
  },
};

/** advanced report에서 과목별 정답률을 보여주는 영역 */
export default function AnswerRateGraphsSection({ childSeqState, checkViewAboutAverageOfGateplus, testList, selectedTest }) {
  const { getObfuscatedValue, setBulkObfuscatedValues } = useObfuscatedSearchParam();
  /** 현재 선택 된 시험 세트의 UTH Seq */
  const selectedUthSeq = getObfuscatedValue(STUDENT_PARAMS.UTH_SEQ.KEY);
  /** 현재 선택 된 시험의 과목 문자열 */
  const selectedSubject = getObfuscatedValue(STUDENT_PARAMS.SUBJECT.KEY);

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo.userLevel);

  /** 과목 활성화 여부(영어) */
  const [isActiveRW, setIsActiveRW] = useState(selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.RW);
  /** 과목 활성화 여부(수학) */
  const [isActiveMa, setIsActiveMa] = useState(selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.MATH);

  const [RWChartData, setRWChartData] = useState({
    label: 'RW',
    value: STUDENT_PARAMS.SUBJECT.VALUE.RW,
    unit: '',
    chartDate: [
      {
        data: 0,
        gatePlusData: 0,
        graphColor: '',
        label: '',
      },
    ],
  });
  const [mathChartData, setMathChartData] = useState({
    label: 'MATH',
    value: STUDENT_PARAMS.SUBJECT.VALUE.MATH,
    unit: '',
    chartDate: [
      {
        data: 0,
        gatePlusData: 0,
        graphColor: '',
        label: '',
      },
    ],
  });

  useEffect(() => {
    /** mode가 student일때 카테고리별 정답률을 조회하고 차트 데이터를 설정하는 함수 */
    const getCategoriesRate = (selectedUthSeq, childSeq = null) => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const processChartData = (response) => {
            // 기본 데이터 유효성 검사
            const fetchedStudentTestTypeCorrectRate = response.result.studentTestTypeCorrectRate;
            const fetchedGateplusTestTypeCorrectRate = response.result.gateplusTestTypeCorrectRate;

            if (!(fetchedStudentTestTypeCorrectRate && fetchedGateplusTestTypeCorrectRate)) return;

            // RW와 MATH 데이터 생성 및 반환
            return {
              rwChartData: generateChartData('E', {
                gateplusCorrectRate: fetchedGateplusTestTypeCorrectRate,
                targetCorrectRate: fetchedStudentTestTypeCorrectRate,
              }),
              mathChartData: generateChartData('M', {
                gateplusCorrectRate: fetchedGateplusTestTypeCorrectRate,
                targetCorrectRate: fetchedStudentTestTypeCorrectRate,
              }),
            };
          };
          // RW(Reading and Writing)와 MATH 차트 데이터 설정
          const chartData = processChartData(response);
          if (chartData) {
            setRWChartData(chartData.rwChartData);
            setMathChartData(chartData.mathChartData);
          }
        }
      };

      request
        .get(
          `/api/v2/student/dashboard/test/score-by-category${selectedUthSeq ? `?uthSeq=${selectedUthSeq}` : ''}${childSeq ? `${selectedUthSeq ? '&' : '?'}childStudentSeq=${childSeq}` : ''}`,
          null,
          successHandler
        )
        .catch((error) => console.error('API 호출 중 오류 발생:', error));
    };
    /** RW와 MATH 차트 데이터를 한번에 처리하는 함수 */
    const generateChartData = (type, fetchedData) => {
      // 선택된 타입별 차트 테이터 설정
      const config = CHART_CONFIG[type];
      const fetchedTargetCategoryRate = fetchedData.targetCorrectRate.find((item) => item.subject === type);
      const fetchedTargetGateplusCorrectRate = fetchedData.gateplusCorrectRate.find((item) => item.subject === type);

      // 각 타입(CAS, IAI 등)별 데이터 처리
      const chartData = config.chartOptions.map((chartOption) => {
        // classAnswer와 overall 데이터에서 해당하는 타입의 데이터 찾기
        const targetCategory = fetchedTargetCategoryRate?.categoriesRate.find((item) => item.dispContentDomainName === chartOption.label);
        const categoryRate = fetchedTargetCategoryRate?.categoriesRate.find((item) => item.dispContentDomainName === chartOption.label)?.correctRate ?? 0;
        const categoryToolTip = targetCategory?.contentDomainName ?? '';

        const gateplusCorrectRate =
          fetchedTargetGateplusCorrectRate?.categoriesRate.find((item) => item.dispContentDomainName === chartOption.label)?.correctRate ?? 0;

        // 차트 아이템에 데이터 결합
        return {
          ...chartOption,
          data: categoryRate,
          gatePlusData: gateplusCorrectRate,
          toolTipStr: categoryToolTip,
        };
      });

      return {
        label: config.label,
        value: config.label === 'MATH' ? STUDENT_PARAMS.SUBJECT.VALUE.MATH : STUDENT_PARAMS.SUBJECT.VALUE.RW,
        unit: config.unit,
        max: config.max,
        chartDate: chartData,
      };
    };

    if (!useLevelsIsLoading) {
      if (useLevels.isParent) {
        // 학부모 계정 일 경우
        if (!childSeqState.isLoading && childSeqState.value) {
          // 자식 시퀸스 로딩이 끝났을 경우에만 실행
          getCategoriesRate(selectedUthSeq, childSeqState.value);
        }
      } else {
        // 학부모 계정이 아닐 경우
        getCategoriesRate(selectedUthSeq);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUthSeq, childSeqState, useLevels, useLevelsIsLoading]);

  useEffect(() => {
    // 과목 변경 시, 실행
    if (testList && testList.length > 0) {
      // 시험 목록 존재 시, 실행

      if (selectedSubject) {
        // 과목이 선택 되었을 경우
        setIsActiveRW(selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.RW);
        setIsActiveMa(selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.MATH);
      } else {
        // 과목이 선택 되지 않았을 경우

        /** 시험 목록 중 영어 시험이 존재 하는지 여부 */
        const isRW = testList.findIndex((item) => {
          return !!item.rwDiff;
        });
        if (isRW === -1) {
          // 영어 시험 존재하지 않는 경우
          setIsActiveRW(false);
          setIsActiveMa(true);
          setBulkObfuscatedValues({ paramValues: { [STUDENT_PARAMS.SUBJECT.KEY]: STUDENT_PARAMS.SUBJECT.VALUE.MATH } });
        } else {
          // 영어 시험 존재하는 경우
          setIsActiveRW(true);
          setIsActiveMa(false);
          setBulkObfuscatedValues({ paramValues: { [STUDENT_PARAMS.SUBJECT.KEY]: STUDENT_PARAMS.SUBJECT.VALUE.RW } });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject, testList]);

  return (
    <S.AnswerRateSection>
      <AnswerRateGraphBox
        boxTitle='Correct answer rate by Categories'
        isActive={isActiveRW}
        chartInfo={RWChartData}
        isShowAverage
        checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
        testList={testList}
        selectedTest={selectedTest}
      />
      <AnswerRateGraphBox
        boxTitle='Correct answer rate by Categories'
        isActive={isActiveMa}
        chartInfo={mathChartData}
        isShowAverage
        checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
        testList={testList}
        selectedTest={selectedTest}
      />
    </S.AnswerRateSection>
  );
}

const S = {
  AnswerRateSection: styled.section`
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
  `,
};
