import { useEffect, useState } from 'react';
import AnswerRateGraphBox from '../graphComponent/AnswerRateGraphBox';
import { getValidParam, useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { SCORE_PARAMS } from 'utils/constants';
import request from 'utils/Request.utils.js';
import { GLOBAL_EN_TEST_TIME, GLOBAL_MA_TEST_TIME } from 'components/exam/dsat/_utils/constants/examTimeSetting';

/** Time spent를 보여주는 그래프 영역 */
export default function TimeSpentGraphBox({ mode, selectedSubject, checkViewAboutAverageOfGateplus }) {
  /** 현재 classSeq, studentSeq - Route Parameter */
  const { classSeq: encClassSeq, studentSeq: encStudentSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  const studentSeq = deobfuscateUrlParam(encStudentSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurUthSeq = getObfuscatedValue(SCORE_PARAMS.CUR_UTH);
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);

  /** Time spent 초기화 객체 */
  const initialTimeSpent = {
    subject: '', // "E" | "M"    => 문제 과목 ( 영어(E) | 수학(M) )
    modules: [
      {
        moduleNum: 1, // 1 | 2   => 모듈 번호
        timeSpent: 0, // number  => 유저가 모듈을 푼 시간 (초)
        gateTime: 0, // number   => GATE⁺ 전체 유저가 모듈을 푼 시간 (초)
      },
    ],
  };
  /** time spent 그래프에서 보여줄 State */
  const [timeSpent, setTimeSpent] = useState({ initialTimeSpent });

  useEffect(() => {
    /** mode가 class or lecture 일때 timeSpent(시험 소요시간) 정보를 가져오는 함수 */
    const getClassTimeSpentData = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          setTimeSpent(response.result.timeSpent);
        }
      };

      const targetSubject = selectedSubject === 'RW' ? 'E' : 'M';

      request
        .get(`/api/v2/score/class/time-spent/${targetSubject}?classSeq=${classSeq}&customSetSeq=${queryCurScoreTestSeq}`, null, successHandler)
        .catch((error) => console.error('API 호출 중 오류 발생:', error));
    };
    /** mode가 student 일때 timeSpent(시험 소요시간) 정보를 가져오는 함수 */
    const getStudentTimeSpentData = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          setTimeSpent(response.result.timeSpent);
        }
      };

      const targetSubject = selectedSubject === 'RW' ? 'E' : 'M';

      const apiURL = getValidParam(queryCurUthSeq)
        ? `/api/v2/score/student/time-spent/${targetSubject}?userSeq=${studentSeq}&uthSeq=${queryCurUthSeq}`
        : `/api/v2/score/student/time-spent/${targetSubject}?userSeq=${studentSeq}`;

      request.get(apiURL, null, successHandler).catch((error) => console.error('API 호출 중 오류 발생:', error));
    };

    const isValidTestSeq = queryCurScoreTestSeq !== null && queryCurScoreTestSeq > 0;
    const isValidStudentSeq = studentSeq !== null && studentSeq > 0;

    if (mode === 'class' || mode === 'lecture') {
      if (isValidTestSeq) getClassTimeSpentData();
    }
    if (mode === 'student') {
      if (isValidStudentSeq) getStudentTimeSpentData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject, studentSeq, classSeq, queryCurUthSeq, queryCurScoreTestSeq]);

  /** 선택된 과목에 따라 그래프의 최대값이 설정 */
  const maxGraphData = selectedSubject === 'RW' ? GLOBAL_EN_TEST_TIME / 60 : GLOBAL_MA_TEST_TIME / 60;

  /** 랜더링 할 time spent 데이터 배열 */
  const timeSpentChartData = (timeSpent.modules ?? initialTimeSpent.modules).map((module) => {
    return {
      data: Math.min((module.timeSpent < 0 ? 0 : module.timeSpent / 60).toFixed(2), maxGraphData), // 시간 데이터 음수: 0으로, max 값보다 클때: max 값으로 설정
      gatePlusData: Math.min((module.gateTime < 0 ? 0 : module.gateTime / 60).toFixed(2), maxGraphData), // 시간 데이터 음수: 0으로, max 값보다 클때: max 값으로 설정
      graphColor: selectedSubject === 'RW' ? '#008CFF' : '#1FDF9F',
      label: `Module ${module.moduleNum}`,
      toolTipStr: `Module ${module.moduleNum}`,
    };
  });

  return (
    <AnswerRateGraphBox
      boxTitle='Time Spent'
      setActiveSatBox={() => {}}
      isActive
      chartInfo={{
        label: selectedSubject,
        unit: 'min',
        max: maxGraphData,
        chartDate: timeSpentChartData,
      }}
      checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
    />
  );
}
