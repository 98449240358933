import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconUpload } from 'assets/gate_member/image/svg/icon/icon-upload-01.svg';

/** 파일 업로드 드래그 앤 드롭 버튼 영역 styled-component */
export default function LabelFileUpload({ htmlFor, handleDragStart, handleDragEnd, handleDragOver, handleDrop, isUploaded, ...rest }) {
  return (
    <S.LabelFileUpload
      htmlFor={htmlFor}
      onDragEnter={handleDragStart}
      onDragLeave={handleDragEnd}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={isUploaded ? 'isUploaded' : ''}
      {...rest}>
      <ImgSvgIconUpload />
      {isUploaded ? (
        // 파일 업로드 된 상태
        <p className='guide-text'>
          Drag and drop file(s) here to upload or <span>choose file</span>
        </p>
      ) : (
        // 파일 업로드 안 된 상태 (기본)
        <>
          <p className='guide-text'>Drag and drop your file(s) here</p>
          <div className='or-lien'>
            <span>OR</span>
          </div>
          <div className='button-box'>Choose File</div>
        </>
      )}
    </S.LabelFileUpload>
  );
}

const S = {};

S.LabelFileUpload = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px dashed #acacac;
  outline: none;
  width: 100%;
  height: 180px;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  svg {
    width: 2rem;
    color: #a6b3be;
  }
  .guide-text {
    font-size: 12px;
    font-weight: 400;
    color: #505050;
  }
  .or-lien {
    position: relative;
    background-color: #ffffff;
    height: 1.25rem;
    width: 9.25rem;
    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
      display: inline-block;
      padding: 0 0.313rem;
      background-color: #ffffff;
      font-size: 0.625rem;
      font-weight: 500;
      color: #7f8998;
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #d2dbe2;
      position: absolute;
      left: 0;
      top: 50%;
      z-index: 0;
    }
  }

  .button-box {
    background-color: var(--point-color);
    color: #ffffff;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    width: 7.625rem;
    height: 2.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.isUploaded {
    flex-direction: row;
    height: auto;
    padding: 1.125rem 1rem;
    svg {
      width: 1.25rem;
      color: var(--point-color);
    }
    .guide-text {
      color: var(--point-color);
      span {
        text-decoration: underline;
      }
    }
  }

  &:hover {
    background-color: #efefef;
    .or-lien {
      background-color: #efefef;
      span {
        background-color: #efefef;
      }
    }
  }
`;
