import styled from 'styled-components';

function TeamofservicePage() {
  return (
    <S.ErrorPageContainer>
      <S.ErrorPageContent>
        <h1>Terms of service</h1>

        <div className='error-content'>
          <p>“Please visit the following link: https://gateplus.notion.site/Terms-of-Service-5a16766471974bb0b51f63a9f983dc13”</p>
          <a href='https://gateplus.notion.site/Terms-of-Service-5a16766471974bb0b51f63a9f983dc13'>go to page</a>
        </div>
      </S.ErrorPageContent>
      <S.WaveSection>
        <div className='error-circle' />
        <svg className='waves' xmlns='http://www.w3.org/2000/svg' viewBox='0 24 150 28' preserveAspectRatio='none' shape-rendering='auto'>
          <defs>
            <path id='gentle-wave' d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z' />
            <linearGradient id='1st-gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
              <stop offset='0%' stop-color='rgb(0, 140, 255)' stop-opacity='0.5' />
              <stop offset='100%' stop-color='rgb(119, 194, 255)' stop-opacity='0' />
            </linearGradient>
          </defs>
          <g className='parallax'>
            <use href='#gentle-wave' x='48' y='0' fill='url(#1st-gradient)' opacity='0.3' />
            <use href='#gentle-wave' x='48' y='1' fill='url(#1st-gradient)' opacity='0.3' />
            <use href='#gentle-wave' x='48' y='2' fill='url(#1st-gradient)' opacity='0.3' />
            <use href='#gentle-wave' x='48' y='3' fill='url(#1st-gradient)' opacity='0.3' />
          </g>
        </svg>
      </S.WaveSection>
    </S.ErrorPageContainer>
  );
}

export default TeamofservicePage;

// --- --- --- styled-component --- --- ---

const S = {}; // S-dot 문법

S.ErrorPageContainer = styled.div`
  background: linear-gradient(180deg, #eef3f8 0%, #fff 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

S.ErrorPageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  color: #adbfd2;
  h1 {
    font-size: 6.25rem;
    font-weight: 700;
  }
  .error-content {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    color: red;
  }
`;

S.WaveSection = styled.section`
  .error-circle {
    margin: 0 auto;
    margin-bottom: 5.5rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: linear-gradient(180deg, rgba(0, 140, 255, 0.5) 0%, rgba(119, 194, 255, 0) 100%);
  }
  width: 100%;
  .waves {
    position: relative;
    width: 100%;
    margin-bottom: -7px; /*Fix for safari gap*/
    height: 18rem;
    .parallax > use {
      animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
    }
    .parallax > use:nth-child(1) {
      animation-delay: -2s;
      animation-duration: 7s;
    }
    .parallax > use:nth-child(2) {
      animation-delay: -3s;
      animation-duration: 10s;
    }
    .parallax > use:nth-child(3) {
      animation-delay: -4s;
      animation-duration: 13s;
    }
    .parallax > use:nth-child(4) {
      animation-delay: -5s;
      animation-duration: 20s;
    }
    @keyframes move-forever {
      0% {
        transform: translate3d(-90px, 0, 0);
      }
      100% {
        transform: translate3d(85px, 0, 0);
      }
    }
  }
`;
