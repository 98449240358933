import IntButtonBase from 'components/_common/button/_IntButtonBase';
import styled from 'styled-components';

/** Test에서 쓰이는 버튼
 * @param {React.ReactNode} children - 버튼 내부 컨텐츠
 * @param {string} hoverText - 툴팁 텍스트
 * @param {function} onClick - 클릭 핸들러
 *
 * @description 기본적으로 쓰이는 스타일 적용 및 IntButtonBase로 호버 효과 추가
 */
function _commonTestBtn({ children, hoverText = '', onClick, iconBtn = false, className }) {
  return (
    <S.CommonTestBtn iconBtn={iconBtn} className={className}>
      <IntButtonBase dataHover text={hoverText} onClick={onClick}>
        {children}
      </IntButtonBase>
    </S.CommonTestBtn>
  );
}

export default _commonTestBtn;

const S = {
  CommonTestBtn: styled.div`
    button {
      height: 2rem;
      font-size: 0.875rem;
      font-weight: 700;
      color: #4b5563;
      border: 1px solid #ebebeb;
      background: white;
      border-radius: 0.5rem;
      padding: ${({ iconBtn }) => (iconBtn ? '0.25rem' : '0.5rem')};
      display: flex;
      align-items: center;
      justify-content: center;

      .svg_icon {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  `,
};
