import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconSearch } from 'assets/img/svg/icon_search.svg';
import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import usePressESC from 'hooks/usePressESC';
import useScrollLock from 'hooks/useScrollLock';
import { useNavigate } from 'react-router-dom';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';
import ClassListDropboxItem from './_components/ClassListDropboxItem';

/** 검색 필드 상수 */
const SEARCH_VALUE = {
  userName: 'userName',
  subjectName: 'subjectName',
  className: 'className',
  userEmail: 'userEmail',
};
/** 검색 필드 옵션 */
const SEARCH_FIELD_OPTIONS = [
  { label: 'Name', value: SEARCH_VALUE.userName },
  { label: 'Subject', value: SEARCH_VALUE.subjectName },
  { label: 'Class', value: SEARCH_VALUE.className },
  { label: 'Email', value: SEARCH_VALUE.userEmail },
];

/** 학생 목록 선택 모달 */
export default function StudentListModal({ setShowModal, data }) {
  const [searchData, setSearchData] = useState(data);
  usePressESC(() => setShowModal(false));
  useScrollLock();
  const navigate = useNavigate();

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  /** Query Parameters hook */
  const [searchField, setSearchField] = useState(SEARCH_VALUE.userName);
  const [searchKeyword, setSearchKeyword] = useState('');
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /** 검색 버튼 클릭시 작동하는 함수 */
  const handleClickSearch = (e) => {
    e.preventDefault();
    handleSearchData();
  };

  /** 검색 데이터 필터링 함수 */
  const handleSearchData = () => {
    const filteredData = data.filter((item) => {
      const keyword = searchKeyword.toLowerCase();
      if (searchField === '') return true;
      if (searchField === SEARCH_VALUE.userName) return item.userName.toLowerCase().includes(keyword);
      if (searchField === SEARCH_VALUE.subjectName) return item.subjectList.some((subject) => subject.subjectName.toLowerCase().includes(keyword));
      if (searchField === SEARCH_VALUE.className) return item.classList.some((classItem) => classItem.className.toLowerCase().includes(keyword));
      if (searchField === SEARCH_VALUE.userEmail) return item.userEmail.toLowerCase().includes(keyword);
    });

    setSearchData(filteredData);
  };

  /** 검색 필드 변경시 작동하는 함수 */
  const handleSearchTypeChange = (e) => setSearchField(e.target.value);
  /** 검색 키워드 변경시 작동하는 함수 */
  const handleSearchKeywordChange = (e) => setSearchKeyword(e.target.value);

  /** 학생 정보 클릭시 해당 학생의 seq를 route 파라미터 설정 */
  const handleSelectStudent = (studentSeq) => {
    setShowModal(false);
    navigate(`/ap/com/score/${obfuscateUrlParam(studentSeq)}`, { replace: true });
  };

  return (
    <SimpleModalBase onEscape={() => setShowModal(false)} w='45rem' h='29.25rem' p='1.25rem' gap='.75rem'>
      <S.Wrap>
        <div className='modal_title_container'>
          <S.ModalTitle className='modal_title_area'>Student List</S.ModalTitle>
          <button className='modal_title_btn' onClick={() => setShowModal(false)}>
            <i className='svg_icon btn_pop_close black'>&nbsp;</i>
          </button>
        </div>

        <S.Content>
          <form onSubmit={handleSearchData} className='search_area'>
            <select value={searchField} onChange={handleSearchTypeChange}>
              {SEARCH_FIELD_OPTIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className='search_bar'>
              <input type='text' placeholder='Enter Keywords' value={searchKeyword} onChange={handleSearchKeywordChange} />
              <button type='submit' title='Search' onClick={handleClickSearch}>
                <ImgSvgIconSearch />
              </button>
            </div>
          </form>
          <S.TableContainer className='custom_scroll'>
            <table className='list'>
              <thead>
                <tr>
                  <th className='col_01'>Name</th>
                  <th className='col_02'>Subject</th>
                  <th className='col_02'>Class</th>
                  <th className='col_03'>Email</th>
                </tr>
              </thead>
              <tbody>
                {searchData &&
                  searchData.length > 0 &&
                  searchData.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          handleSelectStudent(item.userSeq);
                        }}>
                        <td>{item.userName}</td>

                        {item?.subjectList && item.subjectList.length > 1 ? (
                          <ClassListDropboxItem dataList={item.subjectList.map((subject) => ({ label: subject.subjectName, seq: subject.subjectSeq }))} />
                        ) : (
                          <td>
                            <span className='class_name'>{item.subjectList[0]?.subjectName ?? ''}</span>
                          </td>
                        )}

                        {item?.classList && item.classList.length > 1 ? (
                          <ClassListDropboxItem dataList={item.classList.map((classItem) => ({ label: classItem.className, seq: classItem.classSeq }))} />
                        ) : (
                          <td>
                            <span className='class_name'>{item.classList[0]?.className ?? 'Individual'}</span>
                          </td>
                        )}

                        <td>{item.userEmail}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </S.TableContainer>
        </S.Content>
      </S.Wrap>
    </SimpleModalBase>
  );
}

const S = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    .modal_title_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;
    }
  `,

  Content: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;

    .search_area {
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;
      padding-bottom: 0.5rem;
      select {
        width: 5.75rem;
        border: 1px solid #f1f1f5;
        padding: 0.625rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 500;
        height: 1.25rem;
        box-sizing: content-box;
        border-radius: 0.5rem;
      }
      .search_bar {
        width: 14.125rem;
        border: 1px solid #f1f1f5;
        padding: 0 0.75rem;
        display: flex;
        border-radius: 0.5rem;
        input {
          border: none;
          padding: 0.625rem 0;
          height: 1.25rem;
          box-sizing: content-box;
          font-size: 0.875rem;
          font-weight: 400;
          flex: 1;
        }
        button {
          svg {
            width: 1.125rem;
            color: #505050;
          }
        }
      }
    }
  `,

  TableContainer: styled.div`
    flex: 1;
    overflow-y: auto;
    min-height: 0;

    table {
      border: 1px solid #f4f4fc;
      border-radius: 0.625rem;
      width: 100%;
      border-collapse: separate; // table header에 sticky 적용시 border 투명화 현상 방지
      border-spacing: 0; // table header에 sticky 적용시 border 투명화 현상 방지
      tr {
        &:hover {
          td {
            background-color: #f5f8ff;
          }
        }
      }
      th,
      td {
        &.col_01 {
          width: 9.75rem;
        }
        &.col_02 {
          width: auto;
        }
        &.col_03 {
          width: 15.875rem;
        }
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        height: 1.25rem;
        text-align: left;
      }
      th {
        background-color: #f8f8f9;
        color: #111111;
        padding: 0.625rem 0.75rem;
        position: sticky;
        top: 0;
        z-index: 1;
      }

      td {
        padding: 0.875rem 0.75rem;
        cursor: pointer;
        &:has(.dropdown_item) {
          padding: 0;
        }

        & + td {
          border-top: 1px solid #f4f4fc;
        }
        .dropdown_item {
          display: flex;
          justify-content: space-between;
          position: relative;
          border-radius: 0.25rem;

          > button {
            margin: 0.375rem 0.25rem;
            padding: 0.5rem 0.5rem;
            border-radius: 0.25rem;
            &:hover {
              background-color: rgb(220, 225, 235);
            }
          }
          svg {
            width: 1rem;
          }
          .class_name {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 0.875rem;

            p {
              width: 100%;
              text-align: left;
            }
            svg {
              width: 0.875rem;
              transform: rotate(0);
              transition: 0.2s;
              &.active {
                transform: rotate(180deg);
              }
            }
          }
          ul {
            pointer-events: none;
            cursor: default;
            max-height: 8.75rem;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            padding: 0.625rem 0.75rem;
            border: 1px solid #f1f1f5;
            border-radius: 0.5rem;
            background-color: #ffffff;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
            display: none;
            z-index: 1;
            &::-webkit-scrollbar {
              width: 2px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #2f3542;
            }
            &.active {
              display: block;
            }
            li {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0.625rem;
              font-weight: 400;
              font-size: 0.875rem;
              color: #4b5563;
              & + li {
                border-top: 1px solid #f1f1f5;
              }
            }
          }
        }
      }
    }
  `,

  ModalTitle: styled.h2`
    font-size: 1.25rem;
  `,
};
