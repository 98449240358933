import { useEffect, useState } from 'react';

import request from 'utils/Request.utils.js';

import AnswerRateGraphBox from '../graphComponent/AnswerRateGraphBox';
import { GLOBAL_EN_TEST_TIME, GLOBAL_MA_TEST_TIME } from 'components/exam/dsat/_utils/constants/examTimeSetting';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { STUDENT_PARAMS } from 'utils/constants';
import useUserLevels from 'hooks/useUserLevels';

/** Time spent 초기화 객체 */
const initialTimeSpent = {
  subject: '', // "E" | "M"    => 문제 과목 ( 영어(E) | 수학(M) )
  modules: [
    {
      moduleNum: 1, // 1 | 2   => 모듈 번호
      timeSpent: 0, // number  => 유저가 모듈을 푼 시간 (초)
      gateTime: 0, // number   => GATE⁺ 전체 유저가 모듈을 푼 시간 (초)
    },
  ],
};

/** Time spent를 보여주는 그래프 영역 */
export default function TimeSpentGraphBox({ checkViewAboutAverageOfGateplus, childSeqState }) {
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** 현재 선택 된 시험 세트의 UTH Seq */
  const selectedUthSeq = getObfuscatedValue(STUDENT_PARAMS.UTH_SEQ.KEY);
  /** 현재 선택 된 시험의 과목 문자열 */
  const selectedSubject = getObfuscatedValue(STUDENT_PARAMS.SUBJECT.KEY);

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo.userLevel);

  /** time spent 그래프에서 보여줄 State */
  const [timeSpent, setTimeSpent] = useState({ initialTimeSpent });

  useEffect(() => {
    /** "Time Spent" API 요청 함수 */
    const getTimeSpentData = ({ selectedSubject, selectedUthSeq, childSeq = null }) => {
      const successHandler = (response) => {
        if (response.code === 200) {
          setTimeSpent(response.result.timeSpent);
        }
      };

      const targetSubject = selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.RW ? 'E' : 'M';

      request
        .get(
          `/api/v2/student/dashboard/time-spent/${targetSubject}${selectedUthSeq ? `?uthSeq=${selectedUthSeq}` : ''}${childSeq ? `${selectedUthSeq ? '&' : '?'}childStudentSeq=${childSeq}` : ''}`,
          null,
          successHandler
        )
        .catch((error) => console.error('API 호출 중 오류 발생:', error));
    };

    if (!useLevelsIsLoading) {
      if (useLevels.isParent) {
        // 학부모 계정 일 경우
        if (!childSeqState.isLoading && childSeqState.value) {
          // 자식 시퀸스 로딩이 끝났을 경우에만 실행
          getTimeSpentData({ selectedSubject, selectedUthSeq, childSeq: childSeqState.value });
        }
      } else {
        // 학부모 계정이 아닐 경우
        getTimeSpentData({ selectedSubject, selectedUthSeq });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject, selectedUthSeq, useLevels, useLevelsIsLoading]);

  /** 선택된 과목에 따라 그래프의 최대값이 설정 */
  const maxGraphData = selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.RW ? GLOBAL_EN_TEST_TIME / 60 : GLOBAL_MA_TEST_TIME / 60;

  /** 랜더링 할 time spent 데이터 배열 */
  const timeSpentChartData = (timeSpent.modules ?? initialTimeSpent.modules).map((module) => {
    return {
      data: Math.min((module.timeSpent < 0 ? 0 : module.timeSpent / 60).toFixed(2), maxGraphData), // 시간 데이터 음수: 0으로, max 값보다 클때: max 값으로 설정
      gatePlusData: Math.min((module.gateTime < 0 ? 0 : module.gateTime / 60).toFixed(2), maxGraphData), // 시간 데이터 음수: 0으로, max 값보다 클때: max 값으로 설정
      graphColor: selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.MATH ? '#1FDF9F' : '#008CFF',
      label: `Module ${module.moduleNum}`,
      toolTipStr: `Module ${module.moduleNum}`,
    };
  });

  return (
    <AnswerRateGraphBox
      boxTitle='Time Spent'
      setActiveSatBox={() => {}}
      isActive
      chartInfo={{
        label: selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.MATH ? 'Math' : 'RW',
        unit: 'min',
        max: maxGraphData,
        chartDate: timeSpentChartData,
      }}
      checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
    />
  );
}
