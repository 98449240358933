/**
 * 주어진 HEX 색상의 밝기를 조정합니다.
 *
 * @param {string} hexColor - 조정할 색상의 HEX 코드 (예: '#0068BD').
 * @param {number} brightnessAdjustment - 밝기 조정값. 양수는 밝게, 음수는 어둡게 만듭니다.
 *                                        값의 범위는 -100에서 100 사이입니다.
 * @returns {string} 밝기가 조정된 새로운 HEX 색상 코드.
 *
 * @example
 * // 색상을 20% 밝게 만들기
 * const lighterColor = adjustColorBrightness('#0068BD', 20);
 *
 * @example
 * // 색상을 10% 어둡게 만들기
 * const darkerColor = adjustColorBrightness('#0068BD', -10);
 */
export default function adjustColorBrightness(hexColor, brightnessAdjustment = 20) {
  // HEX to RGB
  const hexToRgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  };

  // RGB to HSL
  const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return [h * 360, s * 100, l * 100];
  };

  // HSL to RGB
  const hslToRgb = (h, s, l) => {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  // RGB to HEX
  const rgbToHex = (r, g, b) => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  };

  // 메인 로직
  const [r, g, b] = hexToRgb(hexColor);
  let [h, s, l] = rgbToHsl(r, g, b);

  // 밝기 조정 (입력된 값만큼)
  l = Math.max(0, Math.min(100, l + brightnessAdjustment));

  const [newR, newG, newB] = hslToRgb(h, s, l);
  return rgbToHex(newR, newG, newB);
}
