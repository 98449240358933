import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

/** 아카데미 대시보드의 Students 페이지 : "Test List" 모달 컴포넌트 */
export default function TestListModal({ setShowModal, userSeq, userName }) {
  const [testListState, setTestList] = useState([{ className: '', testEndDate: '', testName: '', testStartDate: '', testStatus: '', testTakenEndDate: '' }]); // testStatus : 'P' (완료, Taken) | 'E' (진행 전, Scheduled) | 'I', 'B' (진행 중, In-progress)
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let testList = [
      {
        className: '',
        testEndDate: '',
        testName: '',
        testStartDate: '',
        testStatus: '',
        testTakenEndDate: '',
      },
    ];

    /** 정렬 기준 정의 */
    const statusPriority = {
      E: 1,
      I: 2,
      B: 2,
      P: 3,
    };

    /** 날짜만 추출하는 헬퍼 함수 */
    const getDateOnly = (dateString) => {
      if (!dateString) {
        return;
      }
      return dateString.split('T')[0]; // 'YYYY-MM-DD' 부분만 추출
    };

    /** 숫자를 추출하는 헬퍼 함수 */
    const extractNumber = (testName) => {
      const match = testName.match(/(?:RW|MA)(\d+)/);
      return match ? parseInt(match[1], 10) : -1;
    };

    // 화면 열고 나서 바로 실행되는 함수
    const successHandler = (response) => {
      if (response.code === 200) {
        testList = response.result.testList;

        getApTestList();
      }
    };
    request
      .get(`/api/v2/academy/student/${userSeq}/test-list`, null, successHandler)
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});

    // ap 시험 정보 조회
    const getApTestList = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const apTestList = response.result.testList;
          testList = testList.concat(apTestList);

          /** 정렬 실행 */
          const sortedData = testList.sort((a, b) => {
            // 1. testStatus 우선순위 비교
            if (statusPriority[a.testStatus] !== statusPriority[b.testStatus]) {
              return statusPriority[a.testStatus] - statusPriority[b.testStatus];
            }

            // 2. testTakenEndDate 비교 (날짜 최신순)
            const dateA = new Date(getDateOnly(a.testTakenEndDate));
            const dateB = new Date(getDateOnly(b.testTakenEndDate));
            if (dateA.getTime() !== dateB.getTime()) {
              return dateB - dateA;
            }
            // 3. testName 기준 정렬
            const aLevelTest = a.testName.includes('Level Test');
            const bLevelTest = b.testName.includes('Level Test');
            if (aLevelTest && !bLevelTest) return -1;
            if (!aLevelTest && bLevelTest) return 1;

            const aNumber = extractNumber(a.testName);
            const bNumber = extractNumber(b.testName);
            return bNumber - aNumber; // 숫자가 클수록 상단
          });

          setTestList(sortedData);
          setIsLoading(false);
        }
      };
      request.apGet(`/api/v1/academy/student/test/list?studentSeq=${userSeq}`, null, successHandler);
    };
  }, [userSeq]);

  // ap 학원인경우 true
  const [userInfo, _] = useState(request.tokenDecoder());
  const apAcademySeq = Number(process.env.REACT_APP_AP_ACADEMY); // ap가 보이는 학원 seq
  const isApAcademy = userInfo?.ecSeq === apAcademySeq;

  return (
    <SimpleModalBase onEscape={() => setShowModal(false)} p='0 1.25rem 1.25rem' w='60rem' gap='0'>
      <S.ModalTitleContainer className='modal_title_container'>
        <div className='modal_title_area left'>
          <S.ModalTitle>{userName}</S.ModalTitle>
        </div>
        <button className='modal_title_btn' onClick={() => setShowModal(false)}>
          <i className='svg_icon btn_pop_close black'>&nbsp;</i>
        </button>
      </S.ModalTitleContainer>

      <S.TableScrollWrapper className='custom_scroll'>
        <S.Table>
          <thead>
            <S.HeaderTableRow>
              <th className='col_01'>No.</th>
              {isApAcademy && <th className='col_04'>TEST FORMAT</th>}
              <th className='col_02'>Class</th>
              <th className='col_03'>Test Name</th>
              <th className='col_04'>Status</th>
              <th className='col_05'>Assigned Date</th>
              <th className='col_06'>Date Taken</th>
            </S.HeaderTableRow>
          </thead>

          <tbody>
            {!isLoading && Array.isArray(testListState) && testListState.length > 0 ? (
              testListState.map((item, index) => {
                /** API에서 받아 온 시험 상태 */
                const status = item.testStatus;

                if (status === '') {
                  return;
                }

                /** 화면에 표시 할 시험 상태 */
                let displayStatus = '';
                /** 화면에 표시 할 Style 용 시험 상태 class name */
                let displayStatusClassName = '';

                switch (status) {
                  case 'P':
                    // 완료
                    displayStatus = 'Taken';
                    displayStatusClassName = 'p';
                    break;
                  case 'E':
                    // 진행 전
                    displayStatus = 'Scheduled';
                    displayStatusClassName = 'e';
                    break;
                  case 'I':
                    // 진행 중
                    displayStatus = 'In-progress';
                    displayStatusClassName = 'i';
                    break;
                  case 'B':
                    // 진행 중 (쉬는 시간)
                    displayStatus = 'In-progress';
                    displayStatusClassName = 'i';
                    break;
                  default:
                    alert('어떤 값인지 파악이 되지 않습니다.');
                }

                return (
                  <S.TableBodyRow key={`score_${index}`}>
                    <td>{testListState.length - index}</td>
                    {isApAcademy && <td>{item.apSubject ? item.apSubject : 'SAT'}</td>}
                    <td>{item.className}</td>
                    <td>{item.testName}</td>
                    <td>
                      <div className={`status_box ${displayStatusClassName}`}>{displayStatus}</div>
                    </td>
                    <td>
                      {isoTimeToDayjs(item.testStartDate).format('YYYY / MM / DD')} ~ {isoTimeToDayjs(item.testEndDate).format('YYYY / MM / DD')}
                    </td>
                    <td>{status === 'P' ? isoTimeToDayjs(item.testTakenEndDate).format('YYYY / MM / DD') : '-'}</td>
                  </S.TableBodyRow>
                );
              })
            ) : (
              <tr>
                <td colSpan='6' style={{ padding: '3rem' }}>
                  No data
                </td>
              </tr>
            )}
          </tbody>
        </S.Table>
      </S.TableScrollWrapper>
    </SimpleModalBase>
  );
}

// --- --- ---

const S = {
  ModalTitleContainer: styled.div`
    .left {
      display: flex;
      align-items: center;
    }
    .btn_main_print {
      width: 2rem;
      height: 2rem;
      border: 1px solid #f1f1f5;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
      svg {
        width: 1.5rem;
        color: #505050;
      }
      &:hover {
        filter: brightness(95%);
        svg {
          color: #808080;
        }
      }
    }
  `,
}; // S-dot 문법용 객체

S.ModalTitle = styled.h2`
  color: #111111;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1.2rem 1rem;
`;

S.TableScrollWrapper = styled.div`
  max-height: 23.5rem;
  background-color: white;
  overflow: auto;
`;
S.Table = styled.table`
  width: 100%;
  text-align: center;
  th,
  td {
    padding: 0.75rem 1rem;
    &.col_01 {
      width: 3rem;
    }
    &.col_02 {
      width: 8rem;
    }
    &.col_03 {
      width: auto;
    }
    &.col_04 {
      width: 8rem;
    }
    &.col_05 {
      width: 15rem;
    }
    &.col_06 {
      width: 8rem;
    }
  }
`;

S.HeaderTableRow = styled.tr`
  position: sticky;
  z-index: 2;
  margin-bottom: 1px;
  th {
    position: sticky;
    top: 0;
    background-color: #000;
    color: #fff;
    height: 2.5rem;
    text-align: center;
    font-weight: 400;
    font-size: 0.875rem;
    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      overflow: hidden;
    }
    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      overflow: hidden;
    }
  }
`;
S.TableBodyRow = styled.tr`
  border-top: 1px solid #eff1f3;

  td {
    font-size: 0.875rem;
    height: 3rem;
    color: #4b5563;
    .status_box {
      width: fit-content;
      font-weight: 400;
      font-size: 0.8125rem;
      background-color: #eff1f3;
      border-radius: 0.25rem;
      display: inline-flex;
      padding: 0.125rem 0.25rem;
      justify-content: center;
      align-items: center;
      &.p {
        color: #ff5858;
      }
      &.e {
        color: #0068bd;
      }
      &.i {
        color: #0da674;
      }
    }
  }
`;
