import {
  SET_ACADEMY_TEACHER_ID,
  SET_ACADEMY_TEACHER_LOGIN_TYPE,
  SET_ACADEMY_TEACHER_USER_EMAIL,
  SET_ACADEMY_TEACHER_USER_MOBILE,
  SET_ACADEMY_TEACHER_USER_NAME,
  SET_ACADEMY_TEACHER_AGREE_PRIVACY_POLICY,
  SET_ACADEMY_TEACHER_AGREE_TERMS_SERVICE,
  SET_ACADEMY_TEACHER_AGREE_NEWS_LETTER,
  SET_ACADEMY_TEACHER_ACADEMY_CODE,
  CLEAR_ACADEMY_TEACHER_STATE,
} from './academyteacher.actionTypes';

/** 리듀서 전역 상태 액션 : 선생님 아이디 저장 */
export const updateReduxAcademyTeacherId = (userId) => ({
  type: SET_ACADEMY_TEACHER_ID,
  payload: userId,
});

/** 리듀서 전역 상태 액션 : 선생님 로그인 타입 저장 */
export const updateReduxAcademyTeacherLoginType = (loginType) => ({
  type: SET_ACADEMY_TEACHER_LOGIN_TYPE,
  payload: loginType,
});

/** 선생님 이메일 저장 */
export const updateReduxAcademyTeacherUserEmail = (userEmail) => ({
  type: SET_ACADEMY_TEACHER_USER_EMAIL,
  payload: userEmail,
});

/** 선생님 전화번호 저장 */
export const updateReduxAcademyTeacherUserMobile = (userMobile) => ({
  type: SET_ACADEMY_TEACHER_USER_MOBILE,
  payload: userMobile,
});

/** 선생님 이름 저장 */
export const updateReduxAcademyTeacherUserName = (userName) => ({
  type: SET_ACADEMY_TEACHER_USER_NAME,
  payload: userName,
});

/** 선생님 개인정보처리방침 동의 저장 */
export const updateReduxAcademyTeacherAgreePrivacyPolicy = (agree) => ({
  type: SET_ACADEMY_TEACHER_AGREE_PRIVACY_POLICY,
  payload: agree,
});

/** 선생님 서비스이용약관 동의 저장 */
export const updateReduxAcademyTeacherAgreeTermsService = (agree) => ({
  type: SET_ACADEMY_TEACHER_AGREE_TERMS_SERVICE,
  payload: agree,
});

/** 선생님 뉴스레터 동의 저장 */
export const updateReduxAcademyTeacherAgreeNewsLetter = (agree) => ({
  type: SET_ACADEMY_TEACHER_AGREE_NEWS_LETTER,
  payload: agree,
});
/** 선생님 학원코드 저장 */
export const updateReduxAcademyTeacherAcademyCode = (academyCode) => ({
  type: SET_ACADEMY_TEACHER_ACADEMY_CODE,
  payload: academyCode,
});

/** 선생님 리듀서 전체 초기화 */
export const clearReduxStateAcademyTeacher = () => {
  return {
    type: CLEAR_ACADEMY_TEACHER_STATE,
  };
};
