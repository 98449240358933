import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import request from 'utils/Request.utils';
///////// components
//order details
import dayjs from 'dayjs';
import dayjsTZ, { getDateOnlyDayjsTZ } from 'utils/functions/time/dayjs-config';
import Layout from '../../../_components/_common/Layout';
import SetTestCalendar from './_components/_SetTestCalender';

/**
 * AP Set Tests : "날짜 선택(달력)" 페이지
 * @description
 * 🔍 검색 키워드 - #setTest #com/tests/set/selection/date
 */
export default function ApTestsSetSelectDatePage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////
  const [timeLayer, setTimeLayer] = useState();

  const [testOrderInfo, setTestOrderInfo] = useState(
    stateSetTest.selectedModules.map((item) => {
      return {
        testSetName: item.testSetName,
        testSetSeq: item.testSetSeq,
        testTimeInfo: {
          start: {
            date: '',
            time: '',
          },
          end: {
            date: '',
            time: '',
          },
        },
        reviewPeriod: 0,
      };
    })
  );

  /** 날짜 선택 단계에서 현재 선택 한 시험 시퀀스(고유 값) 상태(오른 쪽 패널에서 보여 줌) */
  const [selectedTestSeqInDate, setSelectedTestSeqInDate] = useState(
    stateSetTest.selectedModules && stateSetTest.selectedModules.length > 0 ? stateSetTest.selectedModules[0].testSetSeq : null
  );

  const [selectedDate, setSelectedDate] = useState(dayjsTZ().format('YYYY-MM-DD'));
  /** 선택 된 시험 시간 */
  const [selectedTime, setSelectedTime] = useState('00:00');
  /** 선택 된 시험 기간  */
  const [selectedPeriod, setSelectedPeriod] = useState(7);
  /** 선택 된 시험 문제 볼 수 있는 기간 */
  const [selectedReviewPeriod, setSelectedReviewPeriod] = useState(60);

  const [events, setEvents] = useState([
    // {
    //   testSetSeq: 0,
    //   title: '',
    //   start: null,
    //   end: null,
    //   allDay: true,
    // },
  ]);
  const [currentDate, setCurrentDate] = useState(dayjsTZ().toDate());

  const [customSetInfo, setCustomSetInfo] = useState([{ customSetSeq: 0, classTitle: '', classColor: 'random' }]);
  /////////////////// React useState 선언 영역 끝 ///////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 날짜 & 시간 설정 제출 핸들러 */
  const handleTestConfirm = (type) => {
    if (selectedTestSeqInDate === null) return;
    const selectTime = type === 'skip' ? '00:00' : selectedTime;

    /** 현재 선택 된 시험 필터링 */
    const filteredChangeTestInfo = testOrderInfo.find((item) => selectedTestSeqInDate === item.testSetSeq);

    const updatedTestOrderInfo = testOrderInfo.map((item) => {
      if (filteredChangeTestInfo === item) {
        return {
          ...item,
          startDate: dayjs(`${selectedDate} ${selectTime}`).format('MM / DD / YYYY hh : mm A'),
          testTimeInfo: {
            start: {
              date: dayjs(`${selectedDate}`).format('MM / DD / YYYY'),
              time: dayjs(`${selectedDate} ${selectTime}`).format('hh:mm A'),
              apiFormattedDate: dayjs(`${selectedDate} ${selectTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
            },
            end: {
              date: dayjs(selectedDate).add(selectedPeriod, 'day').format('MM / DD / YYYY'),
              time: dayjs(`${selectedDate} ${selectTime}`).format('hh:mm A'),
              apiFormattedDate: dayjs(`${selectedDate} ${selectTime}`).add(selectedPeriod, 'day').format('YYYY-MM-DDTHH:mm:ssZ'),
            },
          },
          reviewPeriod: selectedReviewPeriod,
        };
      }
      return item;
    });
    console.log('🚀 ~ updatedTestOrderInfo ~ updatedTestOrderInfo:', updatedTestOrderInfo);

    setTestOrderInfo(updatedTestOrderInfo);

    setEvents((prev) => {
      const updatedData = {
        testSetSeq: filteredChangeTestInfo.testSetSeq,
        title: filteredChangeTestInfo.testName,
        start: dayjsTZ(selectedDate).startOf('day').toDate(),
        end: dayjsTZ(selectedDate).endOf('day').toDate(),
        allDay: true,
      };

      const filteredEvents = prev.filter((event) => updatedData.title !== event.title);

      return [...filteredEvents, updatedData];
    });

    setSelectedTestSeqInDate((prev) => {
      if (updatedTestOrderInfo.filter((item) => !item.startDate).length === 0) return null;
      return updatedTestOrderInfo.filter((item) => !item.startDate)[0].testSetSeq;
    });

    setTimeLayer(false);
    setSelectedDate(null);
  };

  const getCalendarData = ({ currentDate }) => {
    if (!currentDate) return;

    const successHandler = (response) => {
      if (response.code === 200) {
        const scheduleInfo = response.result?.monthlyTestList;
        // scheduleInfo가 없을때 return으로 에러 발생 예외 처리 ( set Test 가 이루어지는 공간이므로 큰 문제는 없음 )
        if (!scheduleInfo) return;

        /** API 응답 데이터를 리액트 상태 값으로 맴핑 */
        const filteredEvents = scheduleInfo
          .filter((item) => {
            const endMoment = dayjs(item.testEndDate.split(' ')[0]);
            return endMoment.isSameOrAfter(dayjsTZ(), 'day');
          })
          .map((item) => {
            return {
              testSetSeq: item.testSetSeq,
              title: item.testName,
              start: dayjs(item.testStartDate.split(' ')[0]).toDate(),
              end: dayjs(item.testEndDate.split(' ')[0]).toDate(),
              allDay: true,
            };
          });

        setEvents(filteredEvents);
      }
    };

    /** API 요청에 필요한 "월" */
    const month = getDateOnlyDayjsTZ(currentDate).month() + 1;
    /** API 요청에 필요한 "년" */
    const year = getDateOnlyDayjsTZ(currentDate).year();

    /** API 요청 할 URL */
    const apiUrl = `/api/v1/academy/exam/monthly?month=${month}&year=${year}`;
    request.apGet(apiUrl, null, successHandler);
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    getCalendarData({ currentDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <Layout testOrderInfo={testOrderInfo} selectedTestSeqInDate={selectedTestSeqInDate} setSelectedTestSeqInDate={setSelectedTestSeqInDate}>
      <SetTestCalendar
        setCurrentDate={setCurrentDate}
        events={events}
        setEvents={setEvents}
        customSetInfo={customSetInfo}
        setCustomSetInfo={setCustomSetInfo} // setTest에서 미사용
        setSelectedTime={setSelectedTime}
        setSelectedPeriod={setSelectedPeriod}
        setSelectedReviewPeriod={setSelectedReviewPeriod}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleTestConfirm={handleTestConfirm}
        setTimeLayer={setTimeLayer}
        timeLayer={timeLayer}
        selectedTestSeqInDate={selectedTestSeqInDate}
      />
    </Layout>
  );
}
