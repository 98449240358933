import { Navigate, Route, Routes } from 'react-router-dom';

import request from 'utils/Request.utils';

//components
import Footer from 'layout/Footer'; //Footer
import Gnb from 'layout/Gnb';
import Lnb from 'layout/Lnb'; //Lnb
import QuestionBankRootPage from 'components/ap/question-bank/Page';

/** AP - "Question Bank" 라우트 */
const ApQuestionBankRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    if (authBool) {
      // 로그인 한 사람만 사용
      const authRoutes = [
        // { path: "/", element: <Students /> },
        { path: '/', element: <QuestionBankRootPage /> },
        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unauthRoutes = [
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unauthRoutes);
    }
  };

  const PathStudents = pathname.toLowerCase().startsWith('/ap/student/dashboard');
  const PathReview = pathname.toLowerCase().startsWith('/ap/student/review');
  const PathReportCard = pathname.toLowerCase().startsWith('/ap/student/report');
  const PathStudentsList = pathname.toLowerCase().startsWith('/ap/student');

  const renderGnb = () => {
    if (PathReportCard || PathReview) return null;
    return <Gnb />;
  };

  const renderLnb = () => {
    //경로에 따른 lnb 노출 설정
    if (PathReportCard || PathReview) return null;
    return <Lnb />;
  };

  const renderFooter = () => {
    //경로에 따른 Footer 노출 설정
    if (PathReview || PathReportCard) return null;
    return <Footer />;
  };

  return (
    <>
      {renderLnb()}
      {renderGnb()}
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      {renderFooter()}
    </>
  );
};

export default ApQuestionBankRoute;
