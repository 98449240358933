import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import clearAppData from 'utils/clearAppData.js';
import { nvl, nvlNumber } from 'utils/Common.utils';
import SessionStorage from 'utils/SessionStorage.utils';
import request from 'utils/Request.utils';

import useUserLevels from 'hooks/useUserLevels.js';
import useOutsideClick from 'hooks/useOutsideClick';

import UserProfilePopup from './UserProfilePopup';

/** 사용자 프로필 버튼 컴포넌트 - 사용자 정보를 표시하고 프로필 관련 작업을 수행 */
function UserProfileBtn({ setAlertLayerPopup }) {
  // 사용자 정보와 레이어 표시 여부 상태
  const [userInfo, _] = useState(request.tokenDecoder());
  // 상태 관리
  const [userLayer, setUserLayer] = useState(false);
  // 레이어 표시를 위한 Ref
  const userMenuRef = useRef(null);
  // 패키지 Hook 관리
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // 세션 스토리지
  const windowManager = SessionStorage.getItemJsonParse('windowManager');
  // 선택자 및 사용자 정의 훅
  const stateMenuManager = useSelector((state) => state.MenuManager);
  const { permissions: useLevels } = useUserLevels(userInfo?.userLevel);

  // 기타 랜더링 변수
  const isEducationStaff = useLevels.isTeacher || useLevels.isTutor || useLevels.isAcademy;
  const displayUserName = stateMenuManager?.userName || userInfo?.userName || 'UNKNOWN';

  /** 로그아웃 처리 함수 */
  const handleLogout = () => {
    // 테스트 진행 중일 경우 확인 팝업 표시 ( 아니면 로그아웃 )
    if (windowManager?.popupOn) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          alertType: 'confirm',
          activateMode: 'testLogout',
          visible: true,
          alertMessage: 'Test is in progress. Are you sure you want to log out? Your test window will also close.',
        };
      });
    } else {
      clearAppData(dispatch, userInfo?.userId);
      window.location.href = '/signin';
    }
  };

  /** 사용자 레벨에 따른 프로필 설정 페이지로 이동 */
  const handleProfileSetting = () => {
    const routes = {
      isAcademy: '/academy/profile',
      isStudent: '/student/profile',
      isTeacher: '/tutor/profile',
      isTutor: '/tutor/profile',
      isParent: '/parent/profile',
    };
    const route = Object.keys(routes).find((key) => useLevels[key]);
    if (route) navigate(routes[route]);
    setUserLayer(false);
  };

  /** 사용자 프로필 이미지 렌더링 */
  const renderProfileImage = () => {
    if (nvlNumber(userInfo?.upFileSeq) > 0 || nvl(stateMenuManager?.profileImage) !== '') {
      return nvl(stateMenuManager?.profileImage) !== '' ? (
        <img className='profile-img-background' src={stateMenuManager?.profileImage} alt='프로필이미지' />
      ) : (
        <img
          className='profile-img-background'
          src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${userInfo.userSeq}?${Date.now()}`}
          alt='프로필이미지'
        />
      );
    }
    return <span className='name'>{displayUserName[0]}</span>;
  };

  // 외부 클릭시 팝업 닫기
  useOutsideClick(userMenuRef, () => setUserLayer(false), userLayer);

  return (
    <div className='user_wrap' ref={userMenuRef}>
      {/* 사용자 프로필 버튼 */}
      <button className='menu' onClick={() => setUserLayer((prev) => !prev)}>
        <div className={`com_profile_img ${isEducationStaff ? 'T' : ''}`}>{renderProfileImage()}</div>
        <p className='tit'>{displayUserName}</p>
      </button>

      {/* 사용자 옵션 레이어 */}
      {userLayer && <UserProfilePopup handleLogout={handleLogout} setUserLayer={setUserLayer} handleProfileSetting={handleProfileSetting} />}
    </div>
  );
}

export default UserProfileBtn;
