import { useEffect, useState } from 'react';
import AnswerRateGraphBox from '../graphComponent/AnswerRateGraphBox';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { SCORE_PARAMS } from 'utils/constants';
import request from 'utils/Request.utils.js';

// 차트 설정 데이터: 각 난이도별 라벨과 그래프 색상 정의
const DIFFICULTY_CHART_CONFIG = {
  RW: {
    E: { label: 'Easy', graphColor: '#79C3FF' },
    M: { label: 'Medium', graphColor: '#008CFF' },
    H: { label: 'Hard', graphColor: '#0068BD' },
  },
  MATH: {
    E: { label: 'Easy', graphColor: '#1FDF9F' },
    M: { label: 'Medium', graphColor: '#14BD85' },
    H: { label: 'Hard', graphColor: '#178A64' },
  },
};

export default function CorrectRateDifficultyGraphBox({ mode, selectedSubject, checkViewAboutAverageOfGateplus }) {
  /** 현재 classSeq, studentSeq - Route Parameter */
  const { classSeq: encClassSeq, studentSeq: encStudentSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  const studentSeq = deobfuscateUrlParam(encStudentSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurUthSeq = getObfuscatedValue(SCORE_PARAMS.CUR_UTH);
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);

  // 랜더링할 난이도별 정답률 데이터 ( class or student )
  const [correctRate, setCorrectRate] = useState({
    targetCorrectRateOfDifficulty: [
      {
        difficulty: '', // "E" | "M" | "H"  => 문제 난이도 ( Easy("E") | Medium("M") | Hard("H") )
        rateOfCorrect: 0, // number         => 정답률
        subject: '', // "E" | "M"           => 문제 과목 ( 영어("E") | 수학("M") )
      },
    ],
    gateCorrectRateOfDifficulty: [
      {
        difficulty: '', // "E" | "M" | "H"  => 문제 난이도 ( Easy("E") | Medium("M") | Hard("H") )
        rateOfCorrect: 0, // number         => 정답률
        subject: '', // "E" | "M"           => 문제 과목 ( 영어("E") | 수학("M") )
      },
    ],
  });

  useEffect(() => {
    /** mode가 class 일때 난이도별 정답률 정보를 가져오는 함수 */
    const getClassCorrectRateDifficulty = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedClassCorrectRateOfDifficulty = response.result.classCorrectRateOfDifficulty;
          const fetchedGateCorrectRateOfDifficulty = response.result.gateCorrectRateOfDifficulty;
          setCorrectRate({
            targetCorrectRateOfDifficulty: fetchedClassCorrectRateOfDifficulty,
            gateCorrectRateOfDifficulty: fetchedGateCorrectRateOfDifficulty,
          });
        }
      };

      const targetSubject = selectedSubject === 'RW' ? 'E' : 'M';

      request
        .get(`/api/v2/score/class/correct-rate/${targetSubject}?classSeq=${classSeq}&customSetSeq=${queryCurScoreTestSeq}`, null, successHandler)
        .catch((error) => console.error('API 호출 중 오류 발생:', error));
    };
    /** mode가 student 일때 난이도별 정답률 정보를 가져오는 함수 */
    const getStudentCorrectRateDifficulty = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentCorrectRateOfDifficulty = response.result.studentCorrectRateOfDifficulty;
          const fetchedGateCorrectRateOfDifficulty = response.result.gateCorrectRateOfDifficulty;
          setCorrectRate({
            targetCorrectRateOfDifficulty: fetchedStudentCorrectRateOfDifficulty,
            gateCorrectRateOfDifficulty: fetchedGateCorrectRateOfDifficulty,
          });
        }
      };

      const targetSubject = selectedSubject === 'RW' ? 'E' : 'M';

      // Uth가 존재하는 경우 Uth를 포함하여 API 호출
      const apiURL = queryCurUthSeq
        ? `/api/v2/score/student/correct-rate/${targetSubject}?userSeq=${studentSeq}&uthSeq=${queryCurUthSeq}`
        : `/api/v2/score/student/correct-rate/${targetSubject}?userSeq=${studentSeq}`;

      request.get(apiURL, null, successHandler).catch((error) => console.error('API 호출 중 오류 발생:', error));
    };

    if (mode === 'class' || mode === 'lecture') {
      if (queryCurScoreTestSeq !== null && queryCurScoreTestSeq > 0) getClassCorrectRateDifficulty();
    }
    if (mode === 'student') {
      if (studentSeq !== null && studentSeq > 0) getStudentCorrectRateDifficulty();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject, studentSeq, queryCurScoreTestSeq, queryCurUthSeq]);

  // 차트 데이터 가공 - 랜더링 할 time spent 데이터 배열
  const processChartData = () => {
    // 응답 데이터가 없는 경우 빈 배열 반환
    if (!correctRate.targetCorrectRateOfDifficulty || !correctRate.gateCorrectRateOfDifficulty) {
      return [];
    }

    // 난이도별로 데이터 매핑
    return Object.keys(DIFFICULTY_CHART_CONFIG[selectedSubject]).map((difficulty) => {
      // 각 난이도별 클래스 정답률 찾기
      const classData = correctRate.targetCorrectRateOfDifficulty.find((item) => item.difficulty === difficulty);

      // 각 난이도별 GATE+ 정답률 찾기
      const gateData = correctRate.gateCorrectRateOfDifficulty.find((item) => item.difficulty === difficulty);

      const classDifficulty = classData?.difficulty;

      return {
        label: DIFFICULTY_CHART_CONFIG[selectedSubject][difficulty].label,
        graphColor: DIFFICULTY_CHART_CONFIG[selectedSubject][difficulty].graphColor,
        data: classData?.rateOfCorrect ?? 0,
        gatePlusData: gateData?.rateOfCorrect ?? 0,
        toolTipStr: classDifficulty === 'E' ? 'Easy' : classDifficulty === 'M' ? 'Medium' : classDifficulty === 'H' ? 'Hard' : '',
      };
    });
  };

  return (
    <AnswerRateGraphBox
      boxTitle='Correct rate by Difficulty'
      setActiveSatBox={() => {}}
      isActive
      chartInfo={{
        label: selectedSubject,
        unit: '%',
        chartDate: processChartData(),
      }}
      checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
    />
  );
}
