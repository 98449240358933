import MainLink from 'components/_common/button/_MainLinks';
import styled from 'styled-components';

/** B2B 회원용 페이지에 있는 Student, Test, Score 탭 */
export default function BtoBMainTabFrameHOC({ children, ...rest }) {
  return (
    <S.Wrap {...rest}>
      <MainLink />
      {children}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div``,
};
