import styled from 'styled-components';
import { ReactComponent as ImgSvgIconPlus } from 'assets/img/svg/icon_plus.svg';

export default function TestEditComBtn({ title = '타이틀 주세영', onClick = () => console.log('콜백 주세영'), variant = '' }) {
  return (
    <S.Container onClick={onClick} className={variant}>
      <div className='content_wrapper'>
        <div className='icon_box'>
          <ImgSvgIconPlus className='plus_icon' />
        </div>

        <p className='props_title_wrapper'>
          <span>{variant === 'blue' ? 'Add ' : 'Add a '}</span>

          <span className='props_title'>{title}</span>
        </p>
      </div>
    </S.Container>
  );
}

// --- --- --- Styled Components --- --- ---
const S = {
  Container: styled.button`
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 0.625rem;
    display: flex;
    padding: 0.9375rem 0.7813rem;

    border: 2px solid #e5e5e5;
    border-radius: 0.5rem;
    display: flex;
    padding: 0.75rem 0px;

    width: 100%;
    max-width: 46.0625rem;
    height: 3rem;

    .content_wrapper {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      overflow: hidden; // 텍스트가 일정이상 길어지지 못하게 초과분은 숨김

      .props_title_wrapper {
        color: #505050;
        font-size: 1rem;
        .props_title {
          font-weight: 600;
        }
      }
    }

    .icon_box {
      border-radius: 50%;
      min-height: 1.5rem;
      min-width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #f1f1f5;
      color: #505050;
    }

    &.blue {
      background-color: #f4f8ff;
      border: none;
      color: #0068bd;

      .icon_box {
        background-color: #0068bd;
        color: white;
        border: none;
      }
      .props_title_wrapper {
        color: #0068bd;
      }
    }
  `,
};
