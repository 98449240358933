import { useEffect, useState, useRef, useCallback } from 'react';
import 'rangy/lib/rangy-classapplier';
import { useAnnotation } from 'hooks/useAnnotation';
import Tooltip from './Tooltip';
import AnnotationBottom from './AnnotationBottom';
import styled from 'styled-components';
import QuillEditorContainer from 'components/ap/_components/common/QuillEditorContainer';

/** 문제 영역 컴포넌트 */
export default function QuestionDirection({
  sourceDirections,
  isAnnotation: parentIsAnnotation,
  setIsAnnotation: setParentIsAnnotation,
  isDragged,
  contentRef, // 외부에서 ref 주입받음
  editDirections,
}) {
  const tooltipRef = useRef(null);

  // useAnnotation 훅 사용
  const {
    initializeRangy,
    handleAnnotateSelection,
    handleSaveAnnotation, // 주석 저장
    handleDeleteAnnotation, // 주석 삭제
    handleCancelAnnotation,
    reattachAnnotationEvents,
    // 주석 관련 상태 (State)
    selectedAnnotation, // 선택된 주석
    isShowAnnotationInput, // 주석 입력 표시 여부
    annotationMemo, // 주석 메모
    setAnnotationMemo, // 주석 메모 Setter
    isAnnotation, // 주석 활성화 여부
    setIsAnnotation, // 주석 활성화 여부 Setter
  } = useAnnotation({
    containerRef: contentRef,
    tooltipRef,
  });

  // 컴포넌트 마운트 시 rangy 초기화
  useEffect(() => {
    initializeRangy();
  }, [initializeRangy]);

  // 부모 컴포넌트의 isAnnotation 상태와 동기화
  useEffect(() => {
    setIsAnnotation(parentIsAnnotation);
  }, [parentIsAnnotation, setIsAnnotation]);

  // isAnnotation이 변경되었을 때 실행
  useEffect(() => {
    if (isAnnotation && isDragged && contentRef.current.contains(window.getSelection().anchorNode)) {
      handleAnnotateSelection();
    }
  }, [isAnnotation, isDragged, handleAnnotateSelection, contentRef]);

  // sourceDirections가 변경될 때 이벤트 다시 연결
  useEffect(() => {
    const timer = setTimeout(() => {
      reattachAnnotationEvents();
    }, 100);
    return () => clearTimeout(timer);
  }, [sourceDirections, reattachAnnotationEvents]);

  // 현재 훅의 상태가 변경되면 부모 컴포넌트에게 알림
  useEffect(() => {
    setParentIsAnnotation(isAnnotation);
  }, [isAnnotation, setParentIsAnnotation]);

  // 각 동작에 editDirections와 sourceDirections 전달
  const onSaveAnnotation = () => handleSaveAnnotation(editDirections, sourceDirections.directionId);
  const onDeleteAnnotation = () => handleDeleteAnnotation(editDirections, sourceDirections.directionId);

  return (
    <S.Container>
      <QuillEditorContainer ref={contentRef} className='ap_left_area' content={sourceDirections?.directionContent} draggable />

      <Tooltip ref={tooltipRef} />
      {isShowAnnotationInput && (
        <AnnotationBottom
          handleDeleteAnnotation={onDeleteAnnotation}
          handleSaveAnnotation={onSaveAnnotation}
          handleCancelAnnotation={handleCancelAnnotation}
          annotateInfo={selectedAnnotation}
          annotateMemo={annotationMemo}
          setAnnotateMemo={setAnnotationMemo}
          isNewAnnotation={selectedAnnotation?.memo === ''}
        />
      )}
    </S.Container>
  );
}

const S = {
  Container: styled.div`
    .rangy-highlight {
      background-color: #fae89d;
      border-bottom: 1px dashed black;
    }
    .rangy-highlight-hover {
      background-color: #fad021;
    }
    .rangy-highlight-selected {
      background-color: #fad021;
    }
  `,
};
