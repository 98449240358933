import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';

import RawScoreArea from './_components/rawScoreArea/RawScoreArea';
import ScaledScoreArea from './_components/totalScaledScoreArea/ScaledScoreArea';

import ApExamBodyBase from 'components/ap/_components/examLayout/examBody/ApExamBodyBase';

/** ap/com/score/grading/submission 페이지의 본문 영역 컴포넌트 */
export default function GradeSubmissionBody() {
  /** 현재 uthSeq - Route Parameter */
  const { uthSeq: encUthSeq } = useParams();
  const uthSeq = deobfuscateUrlParam(encUthSeq);

  /** 목업 데이터 : 섹션 데이터 */
  const [sectionInfo, setSectionInfo] = useState([
    {
      sectionSeq: 0,
      sectionName: '',
      sectionNum: 0,
      totalRawScore: 0,
      partList: [
        {
          rawScore: 0,
          totalScore: 0,
          questionCount: 0,
          typeOfQuestion: 'M', // M,E
          partNum: 1,
          partName: '',
          questionList: [
            {
              questionDetailSeq: 0,
              questionNum: 0,
              isCorrect: true,
              isAnswered: '',
            },
          ],
        },
      ],
    },
  ]);

  useEffect(() => {
    if (uthSeq) {
      const successHandler = (response) => {
        if (response.code === 200) {
          setSectionInfo(response.result.sectionInfo);
        }
      };

      request.apGet(`/api/v1/academy/score/grading/section-info?uthSeq=${uthSeq}`, null, successHandler);
    }
  }, [uthSeq]);

  const examSection = (
    <S.Wrap>
      <div className='top_info_area'>
        <h2>Grade Submission</h2>
        <p>
          Enter Section 1 and Section 2 scores for your student. Section 1 has been automatically graded, but you need to enter the scaled score yourself.{' '}
          <br />
          When grading your student’s test, you must enter both raw score and scaled score in order to obtain a full score report.
        </p>
      </div>
      <div className='main_center_wrap'>
        <RawScoreArea sectionInfo={sectionInfo} />
        <ScaledScoreArea sectionInfo={sectionInfo} />
      </div>
    </S.Wrap>
  );

  return <ApExamBodyBase examSection={examSection} />;
}

const S = {
  Wrap: styled.main`
    background-color: #ffffff;
    margin: 0 auto;
    padding: 10rem 0 12rem;

    .top_info_area {
      padding: 3.5rem 0 2rem;
      text-align: center;
      h2 {
        color: #111;
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 3rem;
      }
      p {
        color: #505050;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.75rem;
      }
    }

    .main_center_wrap {
      display: flex;
      gap: 1rem;
      justify-content: center;
    }
  `,
};
