import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

////////// Components
import useUserLevels from 'hooks/useUserLevels';
import TabButtons, { TAB_DATA } from './_components/TabButtons';
import TestButton from './_components/testButton/TestButton';
import MainTitleArea from '../../_components/title/MainTitleArea';
import MainTitle from '../../_components/title/MainTitle';
import SubTitle from '../../_components/title/SubTitle';
import SearchArea from './_components/SearchArea';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import Layout from '../../../_components/_common/Layout';

/** AP Set Tests : "시험 모듈 선택" 페이지 */
export default function ApTestsSetSelectTestMorePage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const [userInfo, _] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);

  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);

  const { getObfuscatedValue, setBulkObfuscatedValues } = useObfuscatedSearchParam();
  /** 현재 선택 된 Tab */
  const selectedTab = getObfuscatedValue('tab');
  /** 현재 입력 된 검색어 */
  const selectedSearchKeyword = getObfuscatedValue('search');
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** 검색어 입력창 문자열 실시간 갱신(& 비교) 용 상태 */
  const [searchInputValue, setSearchInputValue] = useState(selectedSearchKeyword ? selectedSearchKeyword : '');
  const [isB2c, setIsB2c] = useState(null);
  /** API에서 받아온 전체 시험 세트 목록 */
  const [customSetInfo, setCustomSetInfo] = useState({
    list: [],
    // {
    //   customSetSeq: number;
    //   englishAvg: number;
    //   englishCnt: number;
    //   englishStudentList: [];
    //   isAcademyExclusive: number;
    //   isLike: boolean;
    //   mathAvg: number;
    //   mathCnt: number;
    //   mathStudentList: [];
    //   testName: string;
    // }[]
  });

  const [selectModules, setSelectModules] = useState(nvl(stateSetTest.selectedModules) === '' ? [] : stateSetTest.selectedModules.map((i) => i.customSetSeq));

  /** 이 페이지에서 사용자가 실시간으로 선택하는 시험 세트 상태 */
  const [selectTestSet, setSelectTestSet] = useState([]);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const getCustomSet = () => {
    const {
      selectedConfig: { testConfiguration, testType, testRwDiff, testMathDiff },
      selectedStudents,
    } = stateSetTest;

    const subjectsToFetch = testConfiguration === 'RW' ? 'E' : testConfiguration;

    let difficultyToFetch = '';
    const difficultyMapping = {
      EE: 'E', // testRwDiff, testMathDiff 둘 다 'E'
      HH: 'H', // testRwDiff, testMathDiff 둘 다 'H'
      EH: 'Y', // testRwDiff == 'E', testMathDiff == 'H'
      HE: 'D', // testRwDiff == 'H', testMathDiff == 'E'
    };

    if (testType) difficultyToFetch = 'F';
    else if (testConfiguration === 'RW') difficultyToFetch = testRwDiff;
    else if (testConfiguration === 'M') difficultyToFetch = testMathDiff;
    else difficultyToFetch = difficultyMapping[testRwDiff + testMathDiff] || '난이도선택안됨!';

    let pms = {
      subject: subjectsToFetch,
      difficulty: difficultyToFetch,
      seqStr: selectedStudents.map((item) => item.userSeq).join('|'),
      isExclusive: 'Y', // 학원 전용 세트 보여주도록 함 일단은 Y
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        const customSetList = response.result.customSetList;

        setCustomSetInfo((prev) => {
          return {
            ...prev,
            list: customSetList,
          };
        });
      }
    };

    request.get(
      `/api/dsat/customset/list?subject=${pms.subject}&difficulty=${pms.difficulty}&seqStr=${encodeURIComponent(pms.seqStr)}&isExclusive=${pms.isExclusive}`,
      null,
      successHandler
    );
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    getCustomSet();
    setBulkObfuscatedValues({ paramValues: { tab: TAB_DATA.ALL.value, search: '' }, replace: true }); // 최초 텝 상태 설정

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 사용자가 시험 세트 선택하는 동작
    const filterTest = [];

    customSetInfo.list.forEach((item) => {
      if (selectModules.includes(item.customSetSeq)) {
        filterTest.push(item);
      }
    });
    setSelectTestSet(filterTest);
  }, [selectModules, customSetInfo.list]);

  useEffect(() => {
    if (!useLevelsIsLoading) {
      // 사용자 유형 분석 로딩이 끝난 후 상태 값 갱신
      setIsB2c(useLevels.isStudent);
    }
  }, [useLevelsIsLoading, useLevels]);

  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <Layout selectModules={selectModules} selectTestSet={selectTestSet}>
      <S.Wrap>
        <MainTitleArea>
          <MainTitle>Available Tests</MainTitle>
          <SubTitle>Select one or more tests</SubTitle>
        </MainTitleArea>
        <div className='main_top_area'>
          <TabButtons />
          <SearchArea searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue} customSetInfo={customSetInfo} />
        </div>
        <div className='btn_test__wrap'>
          {isB2c !== null &&
            nvl(customSetInfo.list) !== '' &&
            customSetInfo.list.map((item, index) => {
              /** 텝 조건 */
              const conditionForTab = selectedTab === TAB_DATA.FAVORITES.value ? item.isLike : true;
              /** 검색어 조건 */
              const conditionForSearchKeyword =
                searchInputValue === null || searchInputValue === '' ? true : item.testName.toLowerCase().includes(searchInputValue.toLowerCase());

              return (
                conditionForTab &&
                conditionForSearchKeyword && (
                  <TestButton
                    key={`set_${index}`}
                    isB2c={isB2c}
                    tit={item.testName}
                    seq={item.customSetSeq}
                    rw={`Average RW score : ${item.englishAvg}/800`}
                    math={`Average Math score : ${item.mathAvg}/800`}
                    rwTakenNum={item.englishCnt}
                    maTakenNum={item.mathCnt}
                    rwTakenUser={item.englishStudentList}
                    maTakenUser={item.mathStudentList}
                    isLike={item.isLike}
                    viewAverageSubject={stateSetTest.selectedConfig.testConfiguration}
                    selectModules={selectModules}
                    setSelectModules={setSelectModules}
                    setCustomSetInfo={setCustomSetInfo}
                  />
                )
              );
            })}
        </div>
      </S.Wrap>
    </Layout>
  );
}

const S = {
  Wrap: styled.div`
    .main_top_area {
      border-bottom: 1px solid #eaeaea;
      margin: 1.5rem 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .btn_test__wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;
    }
  `,
};
