import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import TestPreviewHeader from './_components/TestPreviewHeader';
import PreviewFooter from '../_components/layout/PreviewFooter';

import ExamAnswerList from './_components/ExamAnswerList';

import QuestionTopArea from 'components/ap/com/ap-components/_components/QuestionTopArea';

import SourcesForReview from 'components/ap/com/ap-components/_components/SourcesForReview';

// const channel = new BroadcastChannel('editor-channel');

/** Preview 페이지 */
export default function TestPreviewPage() {
  const apTestEditorReducer = useSelector((state) => state.apTestEditorReducer);

  const currentPartQuestions = apTestEditorReducer?.questions.filter((question) => question.part_seq === apTestEditorReducer.currentPartData.part_seq);

  const currentQuestion = currentPartQuestions[Math.min(apTestEditorReducer.currentQuestion_index, currentPartQuestions.length - 1)];

  const [selectedAnswer, setSelectedAnswer] = useState(0);
  const [undoAnswer, setUndoAnswer] = useState([]);
  /** 현재 Source 번호 */
  const [currentSourceIndex, setCurrentSourceIndex] = useState(0);

  const handleSourceNavigation = (item) => {
    if (item.order !== undefined || item.order !== null) {
      setCurrentSourceIndex(Number(item.order));
    } else {
      console.error(`item 매개변수를 확인해 주세요.`);
    }
  };

  // useEffect(() => {
  //   // 컴포넌트가 마운트되면 준비 완료 신호 전송
  //   channel.postMessage('PREVIEW_WINDOW_READY');

  //   return () => channel.close();
  // }, []);

  const isEssayQuestion = currentQuestion?.question_type === 'FRQ';

  const headerInfo = {
    sectionName: apTestEditorReducer.currentSectionData.section_name,
    partName: apTestEditorReducer.currentPartData.name,
  };

  return (
    <S.QuestionBankTestEditorPageWrapper>
      <TestPreviewHeader headerInfo={headerInfo} />
      <S.ExamContainerWrapper className='custom_scroll'>
        {(currentQuestion?.source_group?.source_directions ||
          (currentQuestion?.source_group?.sources.length > 0 && currentQuestion?.source_group?.sources[0]?.content !== '')) && (
          <>
            <S.ExamContainer className='left'>
              <S.ExamContainerInner>
                <div className='passage'>
                  {currentQuestion?.source_group?.source_directions && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: currentQuestion.source_group.source_directions.replace(
                          '/common/commonImageView.do',
                          `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                        ),
                      }}
                    />
                  )}
                  {currentQuestion?.source_group?.sources.length > 0 && (
                    <>
                      {currentQuestion?.source_group?.sources.length > 1 && (
                        <S.SourcesForReviewContainer>
                          <S.SectionTitle>Sources for Review :</S.SectionTitle>
                          <SourcesForReview
                            sourceData={currentQuestion?.source_group?.sources}
                            currentOrder={currentSourceIndex}
                            handleClickReviewItem={handleSourceNavigation}
                          />
                        </S.SourcesForReviewContainer>
                      )}
                      {currentQuestion?.source_group?.sources.map((item, index) => {
                        return (
                          item.order === currentSourceIndex && (
                            <React.Fragment key={`key_${index}`}>
                              <S.SectionTitle>Sources {currentSourceIndex + 1}</S.SectionTitle>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.content.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
                                }}
                              />
                            </React.Fragment>
                          )
                        );
                      })}
                    </>
                  )}
                </div>
              </S.ExamContainerInner>
            </S.ExamContainer>

            <S.VerticalDivider />
          </>
        )}

        <S.ExamContainer className='right'>
          {isEssayQuestion ? (
            <S.ExamContainerInner>
              <S.QuestionItem className='passage'>
                <QuestionTopArea />

                <div className='text'>
                  <p>객관식 문제 질문</p>
                </div>

                <S.SectionTitle>Your answer:</S.SectionTitle>

                <S.EssayQuestionContainer className='custom_scroll'>
                  <p>
                    I have written this text as a sample. I love Gateplus. But come on. Let's do better by singing in a <br />
                    melody, oo-hhh dsdddd hahahahahahahahahahahahaha. <br />
                    <br />
                    [Indent]
                    <br />
                    <br />
                    dddddddd
                    <br />
                    zz
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    zzzzzzzzzzzzzzz I have written this text as a sample. I love Gateplus. But come on. Let's do better by singing in a <br />
                    melody, oo-hhh dsdddd hahahahahahahahahahahahaha. <br />
                    <br />
                    [Indent]
                    <br />
                    <br />
                    dddddddd
                    <br />
                    zz
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    zzzzzzzzzzzzzzz I have written this text as a sample. I love Gateplus. But come on. Let's do better by singing in a <br />
                    melody, oo-hhh dsdddd hahahahahahahahahahahahaha. <br />
                    <br />
                    [Indent]
                    <br />
                    <br />
                    dddddddd
                    <br />
                    zz
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    zzzzzzzzzzzzzzz I have written this text as a sample. I love Gateplus. But come on. Let's do better by singing in a <br />
                    melody, oo-hhh dsdddd hahahahahahahahahahahahaha. <br />
                    <br />
                    [Indent]
                    <br />
                    <br />
                    dddddddd
                    <br />
                    zz
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    zzzzzzzzzzzzzzz
                  </p>
                </S.EssayQuestionContainer>
              </S.QuestionItem>
            </S.ExamContainerInner>
          ) : (
            <S.ExamContainerInner>
              <S.QuestionItem className='passage'>
                <QuestionTopArea order={apTestEditorReducer.currentQuestion_index + 1} />

                <div className='text'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentQuestion?.question_content?.replace(
                        '/common/commonImageView.do',
                        `${process.env.REACT_APP_API_URL}/api/common/commonImageView`
                      ),
                    }}
                  />
                </div>

                <ExamAnswerList
                  questionOptions={Object.entries(currentQuestion?.answer_options || {})
                    .filter(([key]) => key.startsWith('option'))
                    .sort(([keyA], [keyB]) => {
                      const numA = parseInt(keyA.replace('option', ''));
                      const numB = parseInt(keyB.replace('option', ''));
                      return numA - numB;
                    })
                    .map(([_, value], index) => ({
                      id: index + 1,
                      choiceType: 'Text Input',
                      content: value,
                    }))}
                  selectedAnswer={selectedAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  undoAnswer={undoAnswer}
                  setUndoAnswer={setUndoAnswer}
                />
              </S.QuestionItem>
            </S.ExamContainerInner>
          )}
        </S.ExamContainer>
      </S.ExamContainerWrapper>
      <PreviewFooter />
    </S.QuestionBankTestEditorPageWrapper>
  );
}

const S = {
  QuestionBankTestEditorPageWrapper: styled.div`
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
  ExamContainerWrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:has(.left) {
      & > .right {
        align-items: flex-start;
      }
    }
  `,

  SingleExamContainer: styled.div`
    padding-top: 4.375rem;
    width: 70%;
    margin: 0 auto;

    .text {
      padding: 15px 0;

      .MJXc-display {
        margin: 0 !important;
      }
    }
  `,

  ExamContainer: styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0.75rem 0;
    min-height: 100%;
    height: fit-content;
    max-width: 50%;

    &.left {
      align-items: flex-end;
    }
    &.right {
      align-items: center;
    }
  `,
  ExamContainerInner: styled.div`
    padding: 0 4.375rem;
    max-width: 61.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    // 문제 지문
    .passage {
      font-feature-settings:
        'liga' off,
        'clig' off;
      font-family: 'Noto Serif';
    }
  `,

  QuestionItem: styled.div`
    .text {
      padding: 15px 0;

      .MJXc-display {
        margin: 0 !important;
      }
    }
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,

  EssayQuestionContainer: styled.div`
    padding: 12px 8px;
    background-color: #f1f1f1;
    height: 23.25rem;

    p {
      color: #000000;
      font-family: 'Noto Sans';
      letter-spacing: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
      max-width: 100%;
    }
  `,

  /** 수직 구분선 */
  VerticalDivider: styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 1px;
    background-color: black;
  `,
  /** 수평 구분선 */
  HorizontalDivider: styled.div`
    height: 2px;
    width: 100%;
    background-color: #767676;
  `,

  SourcesForReviewContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
};
