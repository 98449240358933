import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import Logo from 'components/user/_components/layouts/Logo';
import MainBox from 'components/user/_components/layouts/MainBox';
import ErrorText from 'components/user/_components/styles/ErrorText';
import Input from 'components/user/_components/common/forms/Input';
import Label from 'components/user/_components/common/forms/Label';
import Header from 'components/user/sign-up/_components/layouts/Header';

import ImgIconKey from 'assets/gate_member/image/key_icon.svg';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import { USER_LEVELS } from 'utils/constants';
import request from 'utils/Request.utils';
import LocalStorage from 'utils/LocalStorage.utils';

/** "회원가입 - 일반 이메일 가입 - 아카데미 Teacher - 아카데미 코드 입력" 페이지 */
export default function SignUpEmailAcademyTeacherAcademyCodePage() {
  const navigate = useNavigate();
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {},
  });
  const stateCustomer = useSelector((state) => state.Customer);
  console.log('🚀 ~ SignUpEmailAcademyTeacherAcademyCodePage ~ stateCustomer:', stateCustomer);
  const submitParams = {
    userId: stateCustomer.userEmail,
    loginType: 'email',
    userEmail: stateCustomer.userEmail,
    userLevel: stateCustomer.userLevel,
    userMobile: stateCustomer.userMobile,
    userName: stateCustomer.userName,
    agree_privacyPolicy: true,
    agree_termsService: stateCustomer.marketingYn === 'Y' ? true : false,
    agree_newsLetter: stateCustomer.mailYn === 'Y' ? true : false,
    academyCode: '',
    isNew: true,
  };

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    confirmAcademyCode(data.academyCode);
  };
  const submitAcademyTeacherSignup = (code) => {
    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);
      // navigate('/signup/complete');
      if (response.code === 200) {
        // 토근을 로컬에 저장
        LocalStorage.setItem('userSession', 'accessToken', response.result.token.accessToken);
        LocalStorage.setItem('userSession', 'refreshToken', response.result.token.refreshToken);
        // 저장후 1초뒤 이동
        setTimeout(() => {
          navigate('/signup/complete');
        }, 1000);
      } else {
        // 관리자에게 문의 안내
        alert('Please contact the administrator.');
      }
    };
    submitParams.academyCode = code;
    request.post(request.apiUris.auth.signupAcademyTeacher, submitParams, successHandler);
  };

  const confirmAcademyCode = (code) => {
    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);
      if (response.result.count > 0) {
        submitAcademyTeacherSignup(code);
      } else {
        setError('academyCode', { type: 'validate', message: 'Code is invalid' });
      }
    };
    request.get(request.apiUris.auth.academyUniqueCode, { isNew: true, uniqueCode: code }, successHandler);
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <S.Wrap>
      <MainBox>
        <Logo />
        <Header title={'Academy Teacher'} />
        <p className='guide-text'>Already a part of an Academy?</p>
        <form onSubmit={handleSubmit(onValid)}>
          <FormItemWrap>
            <Label className='red-dot' htmlFor='academyCode'>
              Academy Code
            </Label>
            <Input
              id='academyCode'
              register={register('academyCode', {
                required: 'Please enter Academy Code',
              })}
              type='text'
              autoFocus
            />
            {errors.academyCode && <ErrorText>{errors.academyCode.message}</ErrorText>}
          </FormItemWrap>
          <PrimaryButtonArea>
            <PrimaryButton type='submit' title='다음 페이지'>
              Next
            </PrimaryButton>
          </PrimaryButtonArea>
        </form>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .guide-text {
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #7f8998;
  }
  input {
    margin-bottom: 0.5rem;
    background-image: url(${ImgIconKey});
    background-repeat: no-repeat;
    background-position: 1rem center;
    padding-left: 3rem;
  }
  .red-dot {
    padding-left: 0.65rem;
    ::before {
      content: '*';
      color: var(--sub-red);
      margin-top: 2px;
      margin-left: -10px;
      position: absolute;
    }
  }
`;
