import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

import useUserLevels from 'hooks/useUserLevels';

////////// contents
import DeleteModal from 'components/_common/modals/DeleteModal';
import InsufficientKeyModal from './_components/InsufficientKeyModal';
import TestListItem from './_components/TestListItem';
import StudentListItem from './_components/StudentListItem';
import Layout from '../../_components/_common/Layout';
import { updateReduxStateSelectedModules, updateReduxStateSelectedUserList } from 'reducers/setTests/action';
import DraggableProfilePopup from 'components/_common/modals/DraggableProfilePopup';

/** Set Tests : "리뷰" 페이지 */
export default function TestsSetReviewPage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  const isB2c = useLevels.isStudent;
  const dispatch = useDispatch();
  /** "Set Tests 전역 상태" */
  const stateSetTests = useSelector((state) => state.stateSetTests.setTests);
  /** 사용자의 Gate Key 전역 상태 */
  const stateGateKeys = useSelector((state) => state.stateGateKeys);

  /** 삭제 할 대상의 종류 별 이름 및 상태 수정 디스패치 */
  const deleteTargetType = {
    testSet: {
      typeName: 'testSet',
      setStateFunction: (seq) => {
        const newModulesState = stateSetTests.selectedModules.filter((item) => item.customSetSeq !== seq);
        dispatch(updateReduxStateSelectedModules(newModulesState));
      },
    },
    student: {
      typeName: 'student',
      setStateFunction: (seq) => {
        const newStudentsState = stateSetTests.selectedStudents.filter((item) => item.userSeq !== seq);
        dispatch(updateReduxStateSelectedUserList(newStudentsState));
      },
    },
  };

  /** Review 단계에서 존재해야 하는 선택 항목이 비어있는가? (시험 셋트 목록 or 학생 목록) */
  const isEmpty = isB2c
    ? stateSetTests.selectedModules.length <= 0
    : (stateSetTests.selectedModules.length <= 0) | (stateSetTests.selectedStudents.length <= 0);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////
  /** Selection 삭제 팝업 */
  const [deleteSelection, setDeleteSelection] = useState(false);
  const [showInsufficientKeyModal, setShowInsufficientKeyModal] = useState(false);
  /** 현재 삭제 요청 한 대상의 종류 (testSet | student) */
  const [currentDeleteTargetType, setCurrentDeleteTargetType] = useState({ type: '', seq: '' });

  const [profileState, setProfileState] = useState(false);
  const [studentInfo, setStudentInfo] = useState({
    userName: '',
    profile: null,
    className: '',
    schoolType: '',
    regDate: '',
    studentGrade: 0,
    schoolLocation: '',
    userEmail: '',
    userMobile: '',
    userParentMobile: '',
  });
  /////////////////// React useState 선언 영역 끝 ///////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 삭제 요청 핸들러 */
  const handleDeleteClick = ({ type, seq }) => {
    setCurrentDeleteTargetType({ type, seq });
    setDeleteSelection((prev) => !prev);
  };

  /** 실제 삭제 동작 핸들러 */
  const finalDelete = (currentDeleteTargetType) => {
    deleteTargetType[currentDeleteTargetType.type].setStateFunction(currentDeleteTargetType.seq);
    setDeleteSelection((prev) => !prev);
  };

  /** 프로필 상세 보기 API 요청 함수 */
  const handleShowProfile = (userSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const studentInfo = response.result?.studentInfo;

        let imageUrl = null;
        if (studentInfo.upFileSeq > 0) {
          imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}`;
        }
        studentInfo.profile = imageUrl;

        setStudentInfo(studentInfo);
      }
    };

    request.get(`/api/test/profiles?ecSeq=${userInfo.ecSeq}&userSeq=${userSeq}`, null, successHandler).catch((error) => {
      console.error(error);
    });

    setProfileState(true);
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <Layout selectTestSet={stateSetTests.selectedModules} setShowInsufficientKeyModal={setShowInsufficientKeyModal}>
      <S.CartWrap className='p_cart'>
        <div className='cart'>
          <section className='com_container'>
            <section className='box'>
              <article className='com_sec_tit'>
                <h2 className='tit'>
                  <i className='svg_icon icon_ol black'></i>SELECTION DETAILS
                </h2>
                {/* <div className='cash'>
                    Cost <span className='point'>{orderInfo.list[orderInfo.detailIndex].totalPoint}</span> Gate Key
                  </div> */}
              </article>
              {isEmpty ? (
                <div className='empty_area'>
                  <p>You have no selection. Click on Set Test to set more tests!</p>
                </div>
              ) : (
                <>
                  <h3 className='sub_tit'>Test List</h3>
                  <div className='test_list'>
                    {Object.values(stateSetTests.selectedModules).map((item, index) => {
                      if (useLevelsIsLoading) {
                        return;
                      }
                      if (isB2c) {
                        return (
                          <Fragment key={`test_${index}`}>
                            <TestListItem
                              customSetSeq={item.customSetSeq}
                              setDeleteSelection={() => handleDeleteClick({ type: deleteTargetType.testSet.typeName, seq: item.customSetSeq })}
                              testName={item.testName}
                              isB2c={isB2c}
                            />
                          </Fragment>
                        );
                      } else {
                        return (
                          <Fragment key={`test_${index}`}>
                            <TestListItem
                              customSetSeq={item.customSetSeq}
                              setDeleteSelection={() => handleDeleteClick({ type: deleteTargetType.testSet.typeName, seq: item.customSetSeq })}
                              testName={item.testName}
                              time={item.testTimeInfo.start.time}
                              startDate={dayjs(item.testTimeInfo.start.date).format('MM-DD-YYYY')}
                              endDate={dayjs(item.testTimeInfo.end.date).format('MM-DD-YYYY')}
                            />
                          </Fragment>
                        );
                      }
                    })}
                  </div>
                  {!isB2c && (
                    <>
                      <div className='sub_tit__wrap'>
                        <h3 className='sub_tit'>Class or Students List</h3>
                        <div className='member'>
                          {stateSetTests.selectedStudents.length} students
                          <div className='member_num'>
                            <i className='svg_icon icon_person gray'>&nbsp;</i>
                            {stateSetTests.selectedStudents.length}
                          </div>
                        </div>
                      </div>
                      <div className='list_tit'>
                        {stateSetTests.selectedStudents && stateSetTests.selectedStudents.length > 0 && nvl(stateSetTests.selectedClass.classSeq) !== '' ? (
                          <>
                            <b>Class </b>
                            {nvl(stateSetTests.selectedClass.className)}
                          </>
                        ) : (
                          <>
                            <b>Student</b>
                          </>
                        )}
                        <div className='member_num'>
                          <i className='svg_icon icon_person gray'>&nbsp;</i>
                          {stateSetTests.selectedStudents.length}
                        </div>
                      </div>
                      <div className='student_list'>
                        {stateSetTests.selectedStudents.map((item, index) => {
                          return (
                            <StudentListItem
                              key={`user_${index}`}
                              setDeleteSelection={() => handleDeleteClick({ type: deleteTargetType.student.typeName, seq: item.userSeq })}
                              item={item}
                              handleShowProfile={handleShowProfile}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </>
              )}
            </section>
          </section>
        </div>
      </S.CartWrap>
      {/*아이템 삭세 시 팝업*/}
      {deleteSelection && (
        <DeleteModal
          hideModal={() => setDeleteSelection(false)}
          callback={() => finalDelete(currentDeleteTargetType)}
          title={'Are you sure you want to remove \n the selected item?'}
          text={''}
        />
      )}
      {profileState && <DraggableProfilePopup setProfileState={setProfileState} studentInfo={studentInfo} />}
      {showInsufficientKeyModal && (
        <InsufficientKeyModal
          requiredKey={Math.abs(Number(stateGateKeys.remainingKey) - Number(stateSetTests.totalGateKey))}
          setShowModal={setShowInsufficientKeyModal}
        />
      )}
    </Layout>
  );
}

const S = {};

/** Set tests:Review 페이지 (기존 "장바구니") 의 Styled-components */
S.CartWrap = styled.div`
  .com_center_wrap {
    border: 0.625rem;
    padding: 1rem;
    background: #fff;
    border-radius: 0.625rem;
  }

  .empty_area {
    padding: 6rem 2rem;
    text-align: center;
    color: var(--SubText-color);
  }

  .cart {
    display: flex;
    align-items: stretch;
    gap: 2rem;
    .com_container {
      display: flex;
      width: calc(100% - 316px);
      gap: 1rem;
      align-items: stretch;
      .box {
        display: block;
        width: 100%;
        .com_sec_tit {
          border-bottom-color: #000;
          border-bottom-width: 2px;
          .cash {
            font-size: 1rem;
            font-weight: 700;
            .point {
              color: var(--point-color);
            }
          }
        }
        .sub_tit {
          padding: 0 1rem 1rem 1rem;
          color: var(--SubText-color);
          font-weight: 700;
          &__wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 1rem;
            .member {
              display: flex;
              align-items: center;
              font-size: 0.75rem;
              font-weight: 700;
              gap: 0.625rem;
            }
          }
        }
        .test_list {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid #7f7f7f;
          padding-bottom: 1rem;
          margin-bottom: 2.813rem;
          gap: 2rem;
        }
        .member_num {
          color: var(--SubText-color);
          font-size: 0.625rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          .svg_icon {
            background-size: 0.875rem auto;
            margin-inline: 0.625rem 0.375rem;
          }
        }
        .list_tit {
          padding-inline: 1rem;
          padding-bottom: 1rem;
          border-bottom: 2px solid #000;
          display: flex;
          align-items: center;
          font-weight: 500;
          b {
            margin-right: 1rem;
          }
        }
        .student_list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          border-bottom: 1px solid #000;

          & + .list_tit {
            margin-top: 2rem;
          }
        }
      }
    }
  }
`;
