import React, { useState } from 'react';
import styled from 'styled-components';
import CompleteModal from '../../../../../common/modals/CompleteModal';

/** ap/com/score/grading/submission 페이지의 "전체 등급 점수 영역"의 "Total" 컴포넌트 */
export default function TotalScaledScoreArea() {
  /** input 태그 값 상태 관리 */
  const [inputValue, setInputValue] = useState('');
  /** "Complete" 모달 노출 여부 */
  const [isViewCompleteModal, setIsViewCompleteModal] = useState(false);

  /** input 태그 onchange 핸들러 */
  const handleOnChangeInput = (e) => {
    setInputValue(e.target.value);
  };

  /** "Complete" 버튼 핸들러 */
  const handleComplete = () => {
    setIsViewCompleteModal(true);
  };

  return (
    <S.Wrap>
      <h3 className='title'>Total</h3>
      <dl>
        <dt>Raw Score</dt>
        <dd>
          <span className='value'>111</span> / 999
        </dd>
      </dl>
      <dl>
        <dt>Scaled Score</dt>
        <dd>
          <input type='text' placeholder='Score' value={inputValue} onChange={handleOnChangeInput} /> / 5
        </dd>
      </dl>
      <button type='button' title='' onClick={handleComplete} className='primary_button'>
        Complete
      </button>

      {isViewCompleteModal && <CompleteModal setShowModal={setIsViewCompleteModal} />}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    border-top: 1px solid #c5ccd2;
    background: #f8f8f9;
    padding: 1.25rem;
    & > .title {
      color: #384cc0;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }
    dl {
      display: flex;
      justify-content: space-between;
      & + dl {
        margin-top: 0.65rem;
      }
      dt {
        color: #111;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
      dd {
        color: #111;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        align-items: center;
        gap: 0.55rem;
        .value {
          font-size: 1rem;
          font-weight: 500;
        }
        input {
          width: 4rem;
          height: 2.25rem;
          border-radius: 0.25rem;
          border: 1px solid #d7dadf;
          background: #fff;
          padding: 0.5rem 0.625rem;
        }
      }
    }
    .primary_button {
      margin-top: 1.25rem;
      width: 100%;
      border-radius: 0.375rem;
      background: #004a9e;
      padding: 1rem 1.25rem;
      color: #fff;
      font-size: 1.125rem;
      font-weight: 500;
      &:disabled {
        border: 1px solid #ebebeb;
        background: #fff;
        color: #999999;
      }
    }
  `,
};
