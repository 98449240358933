import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { nvlNumber } from 'utils/Common.utils';
import SectionTitle from '../../../common/SectionTitle';
import Box from '../../../common/Box';

import request from 'utils/Request.utils';
import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { getValidParam } from 'hooks/useObfuscatedSearchParam';

/** "/com/score" 페이지의 "ScoreStats" 영역 (tap 에 따라서 나타나는 영역) - "Deviations from Average*" 영역 컴포넌트 */
export default function DeviationsFromAverage() {
  const userInfo = request.tokenDecoder();
  /** 현재 classSeq - Route Parameter */
  const { classSeq: encClassSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  /** 중복호출 방지용 Ref값 초기화 여부 */
  const [isInitialized, setIsInitialized] = useState(false);

  const prevAverageTireRef = useRef({}); // average/tier API 중복 호출 방지용 Ref - userInfo.ecSeq, classSeq

  const [belowClassAverage, setBelowClassAverage] = useState({
    rwAvg: 0,
    mathAvg: 0,
    rwHigh: [],
    mathHigh: [],
    rwLow: [],
    mathLow: [],
  });

  /** 등급 별 평균 점수 API 요청 */
  const getAverageTier = () => {
    // 중복 방지 Ref가 초기화가 되지 않았거나, API 파라미터가 유효하지 않을 경우 early return
    if (!(isInitialized && userInfo?.ecSeq && getValidParam(classSeq))) return;

    const successHandler = (response) => {
      // 이전 호출한 API의 파라미터와 현재 파라미터가 같다면 함수 종료 ( 중복 호출 방지 )
      if (prevAverageTireRef.current.ecSeq === userInfo?.ecSeq && prevAverageTireRef.current.classSeq === classSeq) return;
      prevAverageTireRef.current = { ecSeq: userInfo?.ecSeq, classSeq }; // 현재 파라미터 값 업데이트

      if (response.code === 200) {
        let aboveBelowClassAverage = response.result.aboveBelowClassAverage;

        let classMathAvg = aboveBelowClassAverage?.filter((i) => i.type === 'AVG' && i.subject === 'Math');
        let classRWAvg = aboveBelowClassAverage?.filter((i) => i.type === 'AVG' && i.subject === 'English');

        let classMathHigh = aboveBelowClassAverage?.filter((i) => i.type === 'HIGH' && i.subject === 'Math');
        let classRWHigh = aboveBelowClassAverage?.filter((i) => i.type === 'HIGH' && i.subject === 'English');

        let classMathLow = aboveBelowClassAverage?.filter((i) => i.type === 'LOW' && i.subject === 'Math');
        let classRWLow = aboveBelowClassAverage?.filter((i) => i.type === 'LOW' && i.subject === 'English');

        setBelowClassAverage({
          rwAvg: classRWAvg[0]?.avg,
          mathAvg: classMathAvg[0]?.avg,
          rwHigh: classRWHigh,
          mathHigh: classMathHigh,
          rwLow: classRWLow,
          mathLow: classMathLow,
        });
      }
    };

    request.get(`/api/dsat/average/tier?ecSeq=${userInfo?.ecSeq}&classSeq=${classSeq}`, null, successHandler).catch((error) => console.error('', error));
  };

  useEffect(() => {
    if (!(isInitialized && getValidParam(classSeq))) return;
    getAverageTier();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, classSeq]);
  // 컴포넌트 마운트시 Ref값 초기화 ( 새로고침시 이전 Ref 값으로 중복호출방지가 실행되는 것을 막음 )
  useEffect(() => {
    prevAverageTireRef.current = {};
    setIsInitialized(true);
  }, []);

  return (
    <S.Wrap>
      <Box className='main_box'>
        <div className='title_area'>
          <SectionTitle>Deviations from Average</SectionTitle>
          <span className='sub'>Consider above/below average students for class transfer</span>
        </div>
        <div className='cont_area'>
          <div className='wrap'>
            <p className='tit_box'>
              Past 5 RW tests Cumulative Average
              <span className='num'>{nvlNumber(belowClassAverage?.rwAvg)} / 800</span>
            </p>
            <div className='com_list_box'>
              <div className='scroll'>
                <table className='list'>
                  <colgroup>
                    <col width='20%' />
                    <col width='50%' />
                    <col />
                  </colgroup>
                  <thead>
                    <tr className='sticky top'>
                      <th colSpan={3} className='table_tit gray'>
                        Higher than average
                        <i className='svg_icon icon_graph_line black'>&nbsp;</i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {belowClassAverage.rwHigh.map((i, idx) => {
                      return (
                        <tr key={`rw_${idx}`}>
                          <td>
                            <div className='com_profile_img'>
                              <span className='name'>{i.userName?.substring(0, 1)}</span>
                              {/*<img src={ImgProfile} alt="프로필이미지"/>*/}
                            </div>
                          </td>
                          <td>
                            <span className='name'>{i.userName}</span>
                          </td>
                          <td className='alignC data_change plus'>+ {i.avg - belowClassAverage?.rwAvg}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='com_list_box'>
              <div className='scroll'>
                <table className='list'>
                  <colgroup>
                    <col width='20%' />
                    <col width='50%' />
                    <col />
                  </colgroup>
                  <thead>
                    <tr className='sticky top'>
                      <th colSpan={3} className='table_tit gray'>
                        Lower than average
                        <i className='svg_icon icon_graph_line black'>&nbsp;</i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {belowClassAverage.rwLow.map((i, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <div className='com_profile_img'>
                              <span className='name'>{i.userName?.substring(0, 1)}</span>
                              {/*<img src={ImgProfile} alt="프로필이미지"/>*/}
                            </div>
                          </td>
                          <td>
                            <span className='name'>{i.userName}</span>
                          </td>
                          <td className='alignC data_change minus'>- {belowClassAverage.rwAvg - i.avg}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='wrap'>
            <p className='tit_box green'>
              Past 5 Math tests Cumulative Average
              <span className='num'>{nvlNumber(belowClassAverage?.mathAvg)} / 800</span>
            </p>
            <div className='com_list_box'>
              <div className='scroll'>
                <table className='list'>
                  <colgroup>
                    <col width='20%' />
                    <col width='50%' />
                    <col />
                  </colgroup>
                  <thead>
                    <tr className='sticky top'>
                      <th colSpan={3} className='table_tit gray'>
                        Higher than average
                        <i className='svg_icon icon_graph_line black'>&nbsp;</i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {belowClassAverage.mathHigh.map((i, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <div className='com_profile_img'>
                              <span className='name'>{i.userName?.substring(0, 1)}</span>
                              {/*<img src={ImgProfile} alt="프로필이미지"/>*/}
                            </div>
                          </td>
                          <td>
                            <span className='name'>{i.userName}</span>
                          </td>
                          <td className='alignC data_change plus'>+ {i.avg - belowClassAverage?.mathAvg}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='com_list_box'>
              <div className='scroll'>
                <table className='list'>
                  <colgroup>
                    <col width='20%' />
                    <col width='50%' />
                    <col />
                  </colgroup>
                  <thead>
                    <tr className='sticky top'>
                      <th colSpan={3} className='table_tit gray'>
                        Lower than average
                        <i className='svg_icon icon_graph_line black'>&nbsp;</i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {belowClassAverage.mathLow.map((i, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <div className='com_profile_img'>
                              <span className='name'>{i.userName?.substring(0, 1)}</span>
                              {/*<img src={ImgProfile} alt="프로필이미지"/>*/}
                            </div>
                          </td>
                          <td>
                            <span className='name'>{i.userName}</span>
                          </td>
                          <td className='alignC data_change minus'>- {belowClassAverage.mathAvg - i.avg}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    //Deviations from Average
    /* .com_sec_tit {
      width: 100%;
      @include flex(between);
      .com_select_layer {
        width: 245px;
        height: 28px;
        .selected {
          height: 26px !important;
          font-size: 11px !important;
          font-weight: 500;
          &__wrap {
            border-width: 1px;
            .tit_field {
              font-size: 12px !important;
            }
          }
        }
        &:after {
          right: 0;
          //top:0;
          background-color: #000;
          @include addImg('icon_select_white.png', 32px, 28px, 10px);
          @include svg-icon-color('icon_select', 'fff');
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      .tip_txt {
        font-size: 12px;
        color: #8490a1;
      }
      .tit {
        margin-right: 15px;
      }
    } */
    .main_box {
      padding: 1.25rem 1.5rem;
      height: 100%;
    }
    .title_area {
      padding-bottom: 1rem;
      .sub {
        color: #8490a1;
        font-weight: 400;
        font-size: 0.813rem;
      }
    }
    .cont_area {
      display: flex;
      gap: 1rem;
    }
    .wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 15px;
      width: calc(50% - 7.5px);
      .tit_box {
        width: 100%;
        border-radius: 4px;
        height: 50px;
        font-size: 14px;
        font-weight: 700;
        background: #008cff;
        color: #fff;
        &.green {
          background: #1fdf9f;
          color: #023524;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-left: 15px;
        }
      }
      .com_list_box {
        width: calc(50% - 7.5px);
        .scroll {
          height: 375px;
        }
      }
    }
  `,
};
