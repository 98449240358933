import React from 'react';
import styled from 'styled-components';

//img
import ImgLogo from 'assets/img/gateplus_logo_shadow.png';

/** 로그인/회원가입 패이지의 header (큰 로고 이미지) */
export default function Header(props) {
  const logoBtn = () => {
    const currentURL = window.location.href;
    if (currentURL.includes('signin')) {
      window.location.href = !currentURL.includes('/find') ? 'https://www.gateplus.org' : '/signin';
    } else window.location.href = '/signin';
  };
  return (
    <S.Header>
      <button onClick={logoBtn} className='logo_link'>
        <img src={ImgLogo} alt='Logo' className='logo' />
      </button>
      {props.children}
    </S.Header>
  );
}

const S = {};

S.Header = styled.header`
  margin-bottom: 2.5rem;
  h1 {
    color: var(--point-color);
    font-weight: 700;
    margin-bottom: 0.625rem;
  }
  .logo {
    margin-bottom: 1rem;
    width: 5rem;
  }
  .sub_title {
    margin-block: 0 0.313rem;
    color: var(--SubText-color);
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-weight: 500;
  }
  .txt {
    color: #7f8998;
    font-size: 0.875rem;
  }

  @media screen and (max-width: 1024px) {
  }
`;
