import styled from 'styled-components';
import UI_ExpandablePanel from 'components/_common/UI_ExpandablePanel';
import { ReactComponent as SVG_Plus } from 'assets/img/svg/icon_plus.svg';
import { ReactComponent as SVG_Minus } from 'assets/img/svg/icon_minus.svg';
import { MathJax } from 'better-react-mathjax';

export default function EquationsPanel({ renderLatex = false, isExpanded, panelHeaderText, PanelContent }) {
  const PanelHeader = () => <div className='panel_header'>{renderLatex ? <MathJax>{panelHeaderText}</MathJax> : <p>{panelHeaderText}</p>}</div>;

  const ToggleIcon = ({ isExpanded }) => (
    <S.ToggleIcon $isExpanded={isExpanded}>
      <div className='icon_wrapper'>
        <SVG_Plus className='icon plus' />
        <SVG_Minus className='icon minus' />
      </div>
    </S.ToggleIcon>
  );

  return (
    <S.EquationsPanel panelHeader={<PanelHeader />} ArrowIcon={ToggleIcon} defaultExpanded={isExpanded} className='equations_panel'>
      {PanelContent}
    </S.EquationsPanel>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  EquationsPanel: styled(UI_ExpandablePanel)`
    background-color: white;
    .panel_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 1rem;
      font-size: 1.25rem;
    }
  `,

  ToggleIcon: styled.div`
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .icon_wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
    }
    .icon {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0.3s ease;
    }
    .plus {
      opacity: ${(props) => (props.$isExpanded ? 0 : 1)};
    }
    .minus {
      opacity: ${(props) => (props.$isExpanded ? 1 : 0)};
    }
  `,
};
