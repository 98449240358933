import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'styles/css/exam.css';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';

/** InProgress (ap 진행 중인 시험 재진입 시, 안내 페이지) 페이지 */
const InProgress = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    // local storage에 저장된 데이터를 가져와서 다시 시험을 시작할 수 있도록 함
    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;

    const currentKey = `apTest_${uthSeq}`;
    const currentSession = localStorage.getItemJsonParse(currentKey).sessionOrder;

    // 현재 정보가 있으면 가지고 있는 정보로 시험을 시작
    if (currentSession) {
      const questionList = localStorage.getItemJsonParse(currentKey).questionList;
      const totalTime = localStorage.getItemJsonParse(currentKey).totalTime;

      // 진행된 시간 변수 선언
      let elapsedTime = 0;

      for (let i = 0; i < questionList.length; i++) {
        elapsedTime += questionList[i].solvingTime;
      }
      // 전체 시간에서 진행된 시간을 빼서 남은 시간을 계산
      localStorage.setItem(currentKey, 'totalTime', totalTime - elapsedTime);
      localStorage.setItem(currentKey, 'startTime', Date.now());

      navigate('/ap/exam/test', { replace: true });
    } else {
      getProgressSession(uthSeq);
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProgressSession = (uthSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const sessionOrder = response.result.getSessionOrder;

        getQuestion(uthSeq, sessionOrder);
      }
    };

    request.apGet(`/api/v1/student/test/last-session?uthSeq=${uthSeq}`, null, successHandler);
  };

  const getQuestion = (uthSeq, sessionOrder) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        console.log(response.result.getTestSessionQuestionList);
        const { testSessionName, questionList, totalQuestionCount, totalTime } = response.result.getTestSessionQuestionList;
        const currentKey = `apTest_${uthSeq}`;

        localStorage.setItem(currentKey, 'currentNum', 1);
        localStorage.setItem(currentKey, 'sessionName', testSessionName);
        localStorage.setItem(currentKey, 'questionList', questionList);
        localStorage.setItem(currentKey, 'sessionOrder', sessionOrder);
        localStorage.setItem(currentKey, 'totalQuestionCount', totalQuestionCount);

        let elapsedTime = 0;

        for (let i = 0; i < questionList.length; i++) {
          elapsedTime += questionList[i].solvingTime;
        }
        const remainTime = totalTime - elapsedTime;
        // 전체 시간에서 진행된 시간을 빼서 남은 시간을 계산
        localStorage.setItem(currentKey, 'totalTime', remainTime);
        // 시험 시작 시간 저장
        localStorage.setItem(currentKey, 'startTime', Date.now());

        navigate('/ap/exam/test', { replace: true });
      }
    };

    request.apGet(`/api/v1/student/test?uthSeq=${uthSeq}&sessionOrder=${sessionOrder}`, null, successHandler);
  };

  return (
    <div className='com_popup type_normal active pop_progress'>
      <div className='pop_container'>
        <section className='pop_body'>
          <div className='dsat-intro-3_main-flex-container'>
            <h1>We’re Preparing Your Practice Test</h1>
            <div>
              <div className='dsat-intro-3_loading-img-container'>
                <div className='3_hourglass'></div>
                <div id='cloud1' className='dsat-intro-3_cloud'></div>
                <div id='cloud2' className='dsat-intro-3_cloud'></div>
              </div>
              <p>
                This may take up to a minute.
                <br />
                Please don’t refresh this page or quit the app.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default InProgress;
