//#region    ////////// packages
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import Box from './_components/common/styles/Box';
import AdvancedSetting from './_components/sections/advancedSetting/AdvancedSetting';
import CreateTestIntro from './_components/sections/createTestIntro/CreateTestIntro';
//#endregion ////////// components

//#region    ////////// utils & hooks
import request from 'utils/Request.utils';
//#endregion ////////// utils & hooks

/** AP / "Question Bank" 페이지 */
export default function ApQuestionBankCreateQuestionPage() {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** 현재 라우트 페이지 내에서 화면 간 이동 용 상태 */
  const [currentStep, setCurrentStep] = useState(0);
  /** API에서 받아 온 선택 가능한 과목 목록 */
  const [subjectsData, setSubjectsData] = useState([{ value: '0', label: '선택해주세요' }]);
  /** 현재 Select Box에서 선택 된 과목 상태 */
  const [currentSelectedSubject, setCurrentSelectedSubject] = useState('');
  /** 비동기 작업 용 로딩 상태 */
  const [isLoading, setIsLoading] = useState(false);
  /** "Test Name" Input 값 관리 용 상태 */
  const [testNameInputValue, setTestNameInputValue] = useState('');
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** 선택 가능한 과목 얻어오기 API 요청 */
  const getSelectSubject = () => {
    setIsLoading(true);
    const successHandler = (response) => {
      const clear_data = response.result.map((item) => {
        return { value: item.subject_seq, label: item.subject_name };
      });
      getTemplate(clear_data);
    };
    const errorHandler = (error) => {
      console.error(error);
    };
    const params = { isNew: true };
    request.get(request.apiUris.ap.subject.teacher, params, successHandler, errorHandler);
  };

  /** 선택 가능한 각 과목에 해당하는 시험 초기 정보 얻어오기 API 요청 */
  const getTemplate = (parent_data) => {
    const successHandler = (response) => {
      const result = parent_data.map((item) => {
        const template = response.result.find((template) => template.key === item.value);
        return {
          ...item,
          template: template,
        };
      });
      setSubjectsData(result);
    };
    const errorHandler = (error) => {
      console.error(error);
    };
    const params = { isNew: true };
    request.get(request.apiUris.ap.questionBank.template, params, successHandler, errorHandler).finally(() => {
      setIsLoading(false);
    });
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    getSelectSubject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  /** "타이틀" 영역 공통 컴포넌트 */
  const TitleArea = ({ className = '', ...rest }) => {
    return (
      <div className={`create_test_header ${className}`} {...rest}>
        <h3 className='title'>Create Test</h3>
        <p className='title_description'>Select AP subject first, then continue with the detailed setup.</p>
      </div>
    );
  };

  /** "주요 버튼" 영역 컴포넌트 */
  const PrimaryButtonArea = ({ handleNext, handleCancel, nextButtonText = 'Next', cancelButtonText = 'Cancel', className = '', ...rest }) => {
    return (
      <div className={`primary_btn_area ${className}`} {...rest}>
        <button onClick={handleCancel} className='bottom_btn cancel_button'>
          {cancelButtonText}
        </button>
        <Link onClick={handleNext} className='bottom_btn next_button'>
          {nextButtonText}
        </Link>
      </div>
    );
  };

  return (
    <S.Wrap>
      <Box>
        <TitleArea />
        {isLoading ? (
          'Loading...'
        ) : (
          <>
            {currentStep === 0 && (
              <CreateTestIntro
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                subjectsData={subjectsData}
                currentSelectedSubject={currentSelectedSubject}
                setCurrentSelectedSubject={setCurrentSelectedSubject}
                testNameInputValue={testNameInputValue}
                setTestNameInputValue={setTestNameInputValue}
                PrimaryButtonArea={PrimaryButtonArea}
              />
            )}
            {currentStep === 1 && (
              <AdvancedSetting
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                currentSelectedSubject={currentSelectedSubject}
                testNameInputValue={testNameInputValue}
                setTestNameInputValue={setTestNameInputValue}
                subjectsData={subjectsData}
                setSubjectsData={setSubjectsData}
                PrimaryButtonArea={PrimaryButtonArea}
              />
            )}
          </>
        )}
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12.875rem;

    .create_test_header {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;
      }
      .title_description {
        color: #757575;

        font-size: 14px;
        font-weight: 400;

        line-height: 20px;
      }
    }

    .input_label_wrapper {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      padding-left: 0.75rem;
      position: relative;

      .required_mark {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
        color: #ff5858;
      }
      .input_label_text {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: #7f8998;
      }
    }
    .input_container {
      .text_input_field {
        /* align-items: flex-start;
        display: flex;
        height: 44px;
        border: 1px solid #d2dbe2;
        border-radius: 4px; */

        &.test_input {
          border: 1px solid #d2dbe2;
        }
      }

      select {
        width: 23.75rem;
        height: 2.75rem;
        padding: 0.75rem 1rem;
        border: 1px solid #d2dbe2;
      }
    }

    .primary_btn_area {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      gap: 0.75rem;

      &.center {
        justify-content: center;
      }

      .bottom_btn {
        padding: 0.625rem 1.25rem;
        border-radius: 0.375rem;
        width: 8.625rem;
        line-height: 24px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;

        &.cancel_button {
          background-color: white;
          color: #0068bd;
          border: 1px solid #0068bd;
        }
        &.next_button {
          background-color: #0068bd;
          color: white;
        }
      }
    }
  `,
};
