import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import QuestionTopArea from 'components/ap/com/ap-components/_components/QuestionTopArea';
import request from 'utils/Request.utils';
import { deobfuscateUrlParam, obfuscateUrlParam } from 'utils/urlParamObfuscator';
import ExamAnswerList from './ExamAnswerList';

import { ReactComponent as IconArrow } from 'assets/img/svg/icon/icon_arrow_01.svg';

import ApExamBodyBase from 'components/ap/_components/examLayout/examBody/ApExamBodyBase';

/** ap/com/score/grading/submission/question-view 페이지 본문 영역 컴포넌트 */
export default function ApComScoreGradingSubmissionQuestionView() {
  //////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  ////////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const navigate = useNavigate();
  ////////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** API에서 받아온 파트별 전체 문제 목록 */
  const [questionListData, setQuestionListData] = useState(null);
  /** API에서 받아온 파트별 전체 문제 목록 중 현재 선택 된 번호에 해당하는 문제 */
  const [currentQuestion, setCurrentQuestion] = useState(null);
  /** 현재 객관식 문제의 보기 목록 */
  const [answerOptionsArray, setAnswerOptionsArray] = useState([]);
  /** 현재 객관식 문제에서 정답 목록(number) */
  const [correctAnswersArray, setCorrectAnswersArray] = useState([]);
  /** 현재 객관식 문제에서 사용자가 선택한 답변 목록(number) */
  const [selectAnswersArray, setSelectAnswersArray] = useState([]);
  /** 사용자가 입력하는 점수 (주관식) */
  const [scoreInput, setScoreInput] = useState('');
  /** 초기 마운트를 알리는 state ( useEffect로 첫 마운트때 변경 ) */
  const [isInitialRender, setIsInitialRender] = useState(true);
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  ////////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  const { uthSeq: encUthSeq, partSeq: encPartSeq, questionNumber: encQuestionNumber } = useParams();
  /** 현재 uthSeq - Route Parameter */
  const uthSeq = deobfuscateUrlParam(encUthSeq);
  /** 현재 partSeq - Route Parameter */
  const partSeq = deobfuscateUrlParam(encPartSeq);
  /** 현재 문제 번호 (0 부터 시작) - Route Parameter */
  const questionNumber = deobfuscateUrlParam(encQuestionNumber);
  ////////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //////////////////// START --- API 호출 --- START ////////////////////
  /** 채점 할 파트의 문제 목록 불러오기 API 요청 */
  const getQuestions = ({ uthSeq, partSeq }) => {
    if (uthSeq && partSeq) {
      const successHandler = (response) => {
        if (response.code === 200) {
          const questionList = response.result.questionList;
          setQuestionListData(questionList);
        }
      };

      request.apGet(`/api/v1/academy/score/record/${uthSeq}/questions/${partSeq}`, null, successHandler);
    } else {
      console.error('uthSeq 또는 partSeq 값을 확인해 주세요.');
    }
  };

  /** 개별 문제 점수 제출 API 요청 */
  const submitIndividualQuestionScores = ({ studentResultSeq, receivedPoint, max, callBack }) => {
    if (studentResultSeq && receivedPoint && max) {
      if (Number(receivedPoint) > max) {
        console.log('최대 값을 넘을 수 없습니다.');
        return;
      }
      if (Number(receivedPoint) < 0) {
        console.log('0 이하 일 수 없습니다.');
        return;
      }

      const params = {
        receivedPoint,
      };

      const successHandler = (response) => {
        if (response.code === 200) {
          console.log('개별 문제 점수 제출 성공! receivedPoint : ', receivedPoint);
          if (callBack) {
            callBack();
          }
          getQuestions({ uthSeq, partSeq });
        }
      };

      request.apPut(`/api/v1/academy/score/question/grading/${studentResultSeq}`, params, successHandler);
    } else {
      console.error('studentResultSeq 또는 receivedPoint 값 또는 max값을 확인해 주세요.');
    }
  };
  ////////////////////  END  --- API 호출 ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** "이전" 문제 버튼 핸들러 */
  const handlePrevQuestion = () => {
    const min = 0;
    const prevValue = Number(questionNumber) - 1;

    /** 이전 문제로 라우트 파라미터 수정 */
    const movePrev = () => {
      navigate(
        `/ap/com/score/grading/submission/${obfuscateUrlParam(String(uthSeq))}/question-view/${obfuscateUrlParam(String(partSeq))}/${obfuscateUrlParam(String(prevValue))}`
      );
    };

    if (prevValue >= min) {
      // 이전 문제 한계
      if (currentQuestion.questionType === 'E') {
        // 주관식
        movePrev();
        // submitIndividualQuestionScores({ studentResultSeq: currentQuestion.studentTestResultSeq, receivedPoint: scoreInput, callBack: movePrev });
      } else {
        // 객관식
        movePrev();
      }
    }
  };
  /** "다음" 문제 버튼 핸들러 */
  const handleNextQuestion = () => {
    const max = questionListData.totalQuestionCount;
    const nextValue = Number(questionNumber) + 1;

    /** 다음 문제로 라우트 파라미터 수정 */
    const moveNext = () => {
      navigate(
        `/ap/com/score/grading/submission/${obfuscateUrlParam(String(uthSeq))}/question-view/${obfuscateUrlParam(String(partSeq))}/${obfuscateUrlParam(String(nextValue))}`
      );
    };

    if (nextValue < max) {
      // 다음 문제 한계
      // 이전 문제 한계
      if (currentQuestion.questionType === 'E') {
        // 주관식
        moveNext();
        // submitIndividualQuestionScores({ studentResultSeq: currentQuestion.studentTestResultSeq, receivedPoint: scoreInput, callBack: moveNext });
      } else {
        // 객관식
        moveNext();
      }
    }
  };

  /** 개별 점수 입력 핸들러 */
  const handleScoreInputChange = ({ event, max }) => {
    if (Number(event.target.value) === isNaN) {
      console.error('숫자 값만 입력 할 수 있습니다.');
    }

    if (Number(event.target.value) <= max) {
      setScoreInput(event.target.value);
    } else {
      console.error('최대 값을 넘을 수 없습니다.');
    }
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    getQuestions({ uthSeq, partSeq });
  }, [uthSeq, partSeq]);

  useEffect(() => {
    if (questionListData && questionNumber) {
      // 받아 온 문제 목록 중, 현재 선택 된 번호의 문제 설정하기
      setCurrentQuestion(questionListData.questionList[questionNumber]);
    }
  }, [questionListData, questionNumber]);

  useEffect(() => {
    if (currentQuestion && currentQuestion.questionType === 'M') {
      // 현재 선택 된 객관식 문제의 객관식 보기 추출하기
      /** 객관식 JSON 형식 변환 */
      const answerOptions = currentQuestion.answerOptions ? JSON.parse(currentQuestion.answerOptions) : null;

      /** 객체 형태에서 객관식 답변만 추출하여, 배열로 변환 */
      const getOptionsArray = ({ answerOptionsData }) => {
        /** "option"으로 시작하는 키를 배열로 추출 */
        let optionArray = [];

        for (let key in answerOptionsData) {
          if (key.startsWith('option')) {
            optionArray.push(answerOptionsData[key]);
          }
        }
        return optionArray;
      };

      setAnswerOptionsArray(getOptionsArray({ answerOptionsData: answerOptions }));

      /** 사용자가 선택한 답변 번호 목록 얻기 (문자열 배열에서 번호만 추출하여 숫자 배열로 변환) */
      const getCorrectAnswersArray = ({ correctAnswerStringArray }) => {
        /** 인수로 전달 받은 데이터의 타입에 따라서 객체로 변환 할 데이터 */
        let sourceObject = correctAnswerStringArray;

        if (typeof sourceObject !== 'object' && typeof sourceObject === 'string') {
          // 인수로 전달 받은 데이터가 객체가 아니라면, JSON이라고 판단
          sourceObject = JSON.parse(correctAnswerStringArray);
        }
        const correctAnswerNumberArray = sourceObject.map((item) => Number(item.replace('option', '')));
        /** Test Data : 틀린 답 */
        // const correctAnswerNumberArray = [3];

        return correctAnswerNumberArray;
      };

      setCorrectAnswersArray(getCorrectAnswersArray({ correctAnswerStringArray: currentQuestion.answer }));

      /** 사용자가 선택한 답변 번호 목록 얻기 (문자열 배열에서 번호만 추출하여 숫자 배열로 변환) */
      const getSelectAnswersArray = ({ selectAnswerStringArray }) => {
        const selectAnswerNumberArray = selectAnswerStringArray.map((item) => Number(item.replace('option', '')));

        return selectAnswerNumberArray;
      };

      setSelectAnswersArray(getSelectAnswersArray({ selectAnswerStringArray: currentQuestion.selectAnswer }));
    } else if (currentQuestion && currentQuestion.questionType === 'E') {
      // 주관식 문제의 경우, 입력된 점수를 설정
      setScoreInput(String(currentQuestion?.receivedPoint) || '');
    }

    console.log(`🚨 currentQuestion.sourceDirections :`, currentQuestion?.sourceDirections, `🚨`);
  }, [currentQuestion]);
  // 초기 마운트시 MathJax가 정상적으로 랜더링 될수 있게 돕는 useEffect
  useEffect(() => {
    // 즉시 State 업데이트시 종종 씹히는 문제 있어 setTimeout 추가
    const initRender = setTimeout(() => {
      if (isInitialRender) setIsInitialRender(false);
    }, 300);

    return () => clearTimeout(initRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  ////////////////////  END  --- React useEffect ---  END  ////////////////////

  /** 안전 JSON 파싱 함수 - 실패시 [] 반환 */
  const safeJsonParse = (jsonString) => {
    if (jsonString === '' || jsonString === null) return [];

    try {
      return jsonString ? JSON.parse(jsonString) : [];
    } catch (e) {
      return [];
    }
  };

  // currentQuestion이 존재할 때만 파싱 시도
  const parsedSourceContent = safeJsonParse(currentQuestion?.sourceContent);

  return currentQuestion ? (
    <ApExamBodyBase
      examSection={[
        <S.ExamContainerInner>
          {currentQuestion.sourceDirections && <div className='passage' dangerouslySetInnerHTML={{ __html: currentQuestion.sourceDirections }} />}

          {currentQuestion.sourceContent &&
            parsedSourceContent.map((item, index) => <div className='passage' dangerouslySetInnerHTML={{ __html: item.sourceContent }} key={`key_${index}`} />)}
        </S.ExamContainerInner>,
        currentQuestion.questionType === 'E' ? (
          // 주관식, 서술형 문제
          <S.ExamContainerInner>
            <S.QuestionItem className='passage'>
              <QuestionTopArea order={currentQuestion.questionOrder + 1} />

              <div className='text' dangerouslySetInnerHTML={{ __html: currentQuestion.questionContent }}></div>

              <S.SectionTitle>Your Answer : </S.SectionTitle>

              <S.EssayQuestionContainer
                className='ql-container ql-snow' // Quill 스타일 적용을 위한 className
                dangerouslySetInnerHTML={{
                  __html: `<div class="ql-editor">${currentQuestion.descriptiveAnswer}</div>`,
                }}
              />
            </S.QuestionItem>
            <div className={`raw_score_area input_mode ${scoreInput.length > 0 ? 'entered' : ''}`}>
              <dl>
                <dt>Raw Score</dt>
                <dd>
                  <input
                    type='number'
                    placeholder='Score'
                    value={scoreInput}
                    onChange={(event) => handleScoreInputChange({ event, max: currentQuestion.score })}
                    onBlur={() => {
                      submitIndividualQuestionScores({
                        studentResultSeq: currentQuestion.studentTestResultSeq,
                        receivedPoint: scoreInput,
                        max: currentQuestion.score,
                      });
                    }}
                    max={currentQuestion.score}
                  />{' '}
                  / {currentQuestion.score}
                </dd>
              </dl>
            </div>
          </S.ExamContainerInner>
        ) : (
          // 객관식 문제
          <S.ExamContainerInner>
            <S.QuestionItem className='passage'>
              <QuestionTopArea order={currentQuestion.questionOrder + 1} />

              <div className='text' dangerouslySetInnerHTML={{ __html: currentQuestion.questionContent }}></div>

              <ExamAnswerList questionTextList={answerOptionsArray} correctAnswerArray={correctAnswersArray} selectAnswerArray={selectAnswersArray} />

              {currentQuestion.selectAnswer.length <= 0 && <div className='guide'>you have omitted this question</div>}
            </S.QuestionItem>
            <div className='raw_score_area'>
              <dl>
                <dt>Raw Score</dt>
                <dd>
                  <b>{currentQuestion.receivedPoint}</b> / {currentQuestion.score}
                </dd>
              </dl>
            </div>
          </S.ExamContainerInner>
        ),
      ]}>
      {questionListData && (
        <S.ButtonWrap>
          <button type='button' title='Prev Question' onClick={handlePrevQuestion} disabled={Number(questionNumber) <= 0} className='button_prev_next prev'>
            <IconArrow />
          </button>
          <button
            type='button'
            title='Next Question'
            onClick={handleNextQuestion}
            disabled={Number(questionNumber) >= questionListData.totalQuestionCount - 1}
            className='button_prev_next next'>
            <IconArrow />
          </button>
        </S.ButtonWrap>
      )}
    </ApExamBodyBase>
  ) : (
    'Loading...'
  );
}

const S = {
  ButtonWrap: styled.div`
    .button_prev_next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      width: 3.75rem;
      height: 3.75rem;
      border: 1px solid #e5e5e5;
      transition: 0.2s;
      &:hover {
        box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.16);
      }
      svg {
        width: 3.75rem;
        color: #505050;
      }
      &:disabled {
        &:hover {
          box-shadow: none;
        }
        svg {
          color: #c5ccd2;
        }
      }
      &.prev {
        left: 2.5rem;
        svg {
          transform: rotateZ(0);
        }
      }
      &.next {
        right: 2.5rem;
        svg {
          transform: rotateZ(180deg);
        }
      }
    }
  `,

  ExamContainer: styled.section`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 0;
    min-height: 100%;
    height: fit-content;
    max-width: 50%;

    &.center {
      max-width: 100%;
      align-items: center;
    }
  `,
  ExamContainerInner: styled.div`
    padding: 2rem 4.375rem;
    max-width: 61.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    // 문제 지문
    .passage {
      font-feature-settings:
        'liga' off,
        'clig' off;
      font-family: 'Noto Serif';
    }

    .raw_score_area {
      display: flex;
      justify-content: right;
      dl {
        height: 3.5rem;
        width: fit-content;
        padding: 0.625rem 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2.5rem;
        border-radius: 0.625rem;
        border: 1px solid #c5ccd2;
        background: #f8f8f9;
        dt {
          color: #111;
          text-align: center;
          font-family: Roboto;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.75rem;
        }
        dd {
          color: #505050;
          text-align: center;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
          b {
            color: #111;
            text-align: center;
            font-family: Roboto;
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 1.75rem;
          }
          input {
            width: 4rem;
            height: 2.25rem;
            padding: 0.5rem 0.625rem;
            border-radius: 0.25rem;
            border: 1px solid #d7dadf;
            background: #fff;
            color: #111111;
            font-family: Roboto;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
            margin-right: 0.5rem;
            &:focus {
              border-color: #0068bd;
            }
          }
        }
      }

      &.input_mode {
        dl {
          border-color: #ececec;
          background: #ffffff;
          dt {
            color: #384cc0;
          }
          dd {
          }
        }
        &.entered {
          &:not(:has(input:focus)) {
            dl {
              border-color: #111111;
            }
          }
        }
      }
    }
  `,

  QuestionItem: styled.div`
    .text {
      padding: 15px 0;

      .MJXc-display {
        margin: 0 !important;
      }
    }

    .guide {
      color: #f24b4b;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.625rem; /* 162.5% */
      text-transform: uppercase;
      margin-top: 2rem;
    }
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,

  EssayQuestionContainer: styled.div`
    padding: 12px 8px;
    background-color: #f1f1f1;
    min-height: 23.25rem;

    p {
      align-self: stretch;
      color: #000000;
      font-family: 'Noto Sans';
      letter-spacing: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
      max-width: 100%;
    }

    .ql-editor {
      padding: 0;
    }
  `,
};
