import React from 'react';
import styled from 'styled-components';

/** 대시보드 페이지를 이루는 각 박스 (외곽) 공통 스타일용 컴포넌트 */
export default function OuterBox({ children, ...rest }) {
  return <S.Wrap {...rest}>{children}</S.Wrap>;
}

const S = {
  Wrap: styled.div`
    background-color: #f4f8ff;
    border-radius: 0.625rem;
    padding: 1.25rem;
  `,
};
