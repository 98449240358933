import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './store';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { MathJaxContext } from 'better-react-mathjax';

// 채널톡
import * as ChannelService from '@channel.io/channel-web-sdk-loader';
// 구글 애널리스틱
import ReactGA from 'react-ga4';
import { GlobalStyles } from 'styles/styledComponents/globalStyles';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));

const isDevEnvironmental = process.env.REACT_APP_ENVIRONMENT === 'localhost' || process.env.REACT_APP_ENVIRONMENT === 'development';

// 개발&로컬에서 console 사용
// if (!isDevEnvironmental) {
//   ReactGA.initialize(TRACKING_ID);
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

// const mathJaxOptions = {
//   jax: ["input/TeX", "output/HTML-CSS"],
//   // CommonHTML: {
//   //   scale: 100,
//   //   minScaleAdjust: 70, // 주변 텍스트와 일치시키기 위해 MathJax에서 사용하는 스케일링에 대한 최소 스케일(백분율)을 제공
//   //   mtextFontInherit: false
//   // },
//   // "HTML-CSS": {
//   //   availableFonts: ['MJXMATHREGULAR', 'MJXTEX'],
//   //   preferredFont: "MJXTEX",
//   //   webFont: null,
//   //   EqnChunk: 30,
//   //   EqnChunkFactor: 1.0,
//   //   EqnChunkDelay: 10,
//   // },
//   styles: {
//     // '.mjx-math *': {
//     //   "font-family": 'MJXMATHREGULAR, MJXTEX',
//     // },

//     '.MJXc-display': {
//       display: "inline-block !important",
//       "font-family": 'MJXMATHREGULAR, MJXTEX !important',
//     },

//     // '.MJXc-TeX-math-I': {
//     //   'font-family': 'MJXMATHREGULAR, MJXTEX',
//     // },
//     // '.MJXc-TeX-main-R': {
//     //   // 'font-family': 'MJXc-TeX-main-R, MJXc-TeX-main-Rw',
//     //   'font-family': 'MJXMATHREGULAR, MJXTEX',
//     // }

//   },
// }

const mathJaxOptions = {
  jax: ['input/TeX', 'output/CommonHTML'],
  // extensions: ['tex2jax.js', 'Safe.js'],
  styles: {
    '.mjx-chtml': { padding: '0.1em 0.1em' }, //new names of the selectors, working!
    '.MJXc-display, .mjx-chtml': {
      color: 'black',
      display: 'inline-block !important',
    },
  },
  displayAlign: 'center',
  tex2jax: {
    // inlineMath: [
    //   ['$', '$'],
    //   ['\\(', '\\)']
    // ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]'],
    ],
    processEscapes: false,
  },
  CommonHTML: {
    scale: 110,
    linebreaks: { automatic: false },
    mtextFontInherit: false,
  },
  showProcessingMessages: false,
  menuSettings: { zoom: 'None', mpContext: true, mpMouse: true },
  messageStyle: 'none',
};

ChannelService.loadScript();

root.render(
  // 지속적인 에러 발생으로 인한 주석 처리 ( 개발 서버에서 의도치 않은 에러가 발생함 )
  // <React.StrictMode>

  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <MathJaxContext config={mathJaxOptions} version={2} onStartup={(mathJax) => (mathJax.Hub.processSectionDelay = 0)}>
        <GlobalStyles />
        <App />
      </MathJaxContext>
    </PersistGate>
  </Provider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
