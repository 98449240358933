import ReactApexChart from 'react-apexcharts';
import styled from 'styled-components';
import adjustColorBrightness from 'utils/adjustColorBrightness';

const defaultChartStyle = {
  countAnimation: false,
  chartHollowSize: '77%',
};

const adjustSizeBasedOnHollowSize = (baseSize, hollowSize) => {
  const baseHollowSize = 40;
  const sizeAdjustmentFactor = 0.69;
  const hollowSizeNumber = parseFloat(hollowSize);
  const hollowSizeDifference = baseHollowSize - hollowSizeNumber;
  const adjustmentPercentage = 1 + (hollowSizeDifference * sizeAdjustmentFactor) / 100;
  const baseSizeNumber = parseFloat(baseSize);
  const adjustedSize = baseSizeNumber * adjustmentPercentage;
  return `${adjustedSize.toFixed(3)}rem`;
};

export default function DonutPieChart({ data = [], size = '4.25rem', chartStyle = {}, handleClickData = null, CustomLabel = null }) {
  const chartConfig = {
    ...defaultChartStyle,
    ...chartStyle,
    size: size,
    adjustedSize: '',
  };

  chartConfig.adjustedSize = adjustSizeBasedOnHollowSize(size, chartConfig.chartHollowSize);

  const options = {
    series: data.map((item) => item.value),
    chart: {
      height: '100%',
      width: '100%',
      type: 'donut',
      sparkline: { enabled: true },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          // handleClickData가 있을 경우에만 실행
          // config.dataPointIndex - 클릭된 섹션의 인덱스
          handleClickData && handleClickData(config.dataPointIndex);
        },
      },
    },

    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light', // 'light' 또는 'dark' - 그라데이션의 기본 톤
        type: 'horizontal', // 'horizontal', 'vertical', 'diagonal1', 'diagonal2' - 그라데이션 방향
        shadeIntensity: 1, // 0부터 1까지 - shade의 강도
        opacityFrom: 1, // 0부터 1까지 - 시작 지점의 투명도
        opacityTo: 0.9, // 0부터 1까지 - 끝 지점의 투명도
        stops: [0, 100], // 그라데이션의 시작과 끝 위치 (퍼센트)
      },
    },
    colors: data.map((item) => item.color),
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: chartConfig.chartHollowSize,
          labels: {
            show: false,
          },
        },
      },
    },
    stroke: {
      show: false, // 테두리
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false, // 툴팁
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        // filter: {
        //   type: 'none', // 호버 효과
        // },
      },
      active: {
        filter: {
          type: 'none', // 클릭 효과
        },
      },
    },
  };

  return (
    <S.Wrap id='chart' $size={size}>
      <S.ChartWrapper $size={chartConfig.adjustedSize}>
        {CustomLabel && <S.CustomLabel>{CustomLabel}</S.CustomLabel>}
        <ReactApexChart options={options} series={options.series} type='donut' width='100%' height='100%' />
      </S.ChartWrapper>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: ${(props) => props.$size};
    height: ${(props) => props.$size};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
  ChartWrapper: styled.div`
    width: ${(props) => props.$size};
    height: ${(props) => props.$size};
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1.5);
    position: relative;
  `,
  CustomLabel: styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    transform: scale(0.6667);
    justify-content: center;
  `,
};
