import styled from 'styled-components';
import dayjsTZ from 'utils/functions/time/dayjs-config';

const timeLineDateText = {
  1: {
    1: '8AM: Biology, Latin',
    2: '12PM: European History, Microeconomics',
  },
  2: {
    1: '8AM: Chemistry, Human Geography',
    2: '12PM: United States Government and Politics',
  },
  3: {
    1: '8AM: English Literature and Composition',
    2: '12PM: Comparative Government and Politics, Computer Science A',
  },
  4: {
    1: '8AM: African American Studies, Statistics',
    2: '12PM: Japanese Language and Culture, World History: Modern',
  },
  5: {
    1: '8AM: Italian Language and Culture, United States History',
    2: '12PM: Chinese Language and Culture, Macroeconomics',
  },
  6: {
    1: '8AM: Calculus AB, Calculus BC',
    2: '12PM: Music Theory, Seminar',
  },
  7: {
    1: '8AM: French Language and Culture, Precalculus',
    2: '12PM: Environmental Science, Physics 2: Algebra-based',
  },
  8: {
    1: '8AM: English Language and Composition, German Language and Culture',
    2: '12PM: Physics C: Mechanics',
  },
  9: {
    1: '8AM: Art History, Spanish Language and Culture',
    2: '12PM: Computer Science Principles, Physics C: Electricity and Magnetism',
  },
  10: {
    1: '8AM: Physics 1: Algebra-based, Spanish Literature and Culture',
    2: '12PM: Psychology',
  },
};

const timeLineDate = [
  { date: '2025-05-05', textIndex: 1 },
  { date: '2025-05-06', textIndex: 2 },
  { date: '2025-05-07', textIndex: 3 },
  { date: '2025-05-08', textIndex: 4 },
  { date: '2025-05-09', textIndex: 5 },
  { date: '2025-05-12', textIndex: 6 },
  { date: '2025-05-13', textIndex: 7 },
  { date: '2025-05-14', textIndex: 8 },
  { date: '2025-05-15', textIndex: 9 },
  { date: '2025-05-16', textIndex: 10 },
];

/** 학생 대시보드 페이지의 "SAT Timeline" Section 컴포넌트 */
export default function TimeLineSection() {
  return (
    <S.TimeLine className='time_line custom_scroll'>
      {timeLineDate.map((date, index) => (
        <div className='col_wrap' key={index}>
          <TimeLineItem date={date.date} txt01={timeLineDateText[date.textIndex][1]} txt02={timeLineDateText[date.textIndex][2]} />
        </div>
      ))}
    </S.TimeLine>
  );
}

function TimeLineItem({ date, txt01, txt02 }) {
  const formattedDate = dayjsTZ(date);

  return (
    <div className='item'>
      <div className='date'>
        <b className='point'>{formattedDate.format('MMM')}</b>.<b className='point'>{formattedDate.format('D')}</b>.{formattedDate.format('YYYY')}
      </div>
      <div className='col_wrap'>
        <div className='txt'>{txt01}</div>
        <div className='txt'>{txt02}</div>
      </div>
    </div>
  );
}

const S = {
  TimeLine: styled.div`
    display: flex;
    align-items: flex-start !important;
    .col_wrap {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  `,
};
