//#region    ////////// packages
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import ApExamHeaderBase from 'components/ap/_components/examLayout/examHeader/ApExamHeaderBase';
import DirectionsPopupBtn from 'components/ap/_components/examLayout/examHeader/_components/directionsPopup/DirectionsPopupBtn';
//#endregion ////////// components

//#region    ////////// constants
import { EDITOR_CONSTANT } from '../_constant';
//#endregion ////////// constants

//#region    ////////// assets
import { ReactComponent as ImageSvgIconHelp } from 'assets/img/svg/icon/icon_help_circle_01.svg';
import { useNavigate } from 'react-router-dom';
import request from 'utils/Request.utils';
//#endregion ////////// assets

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const AUTO_SAVE_INTERVAL = 300000; // 5분
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** ap/question-bank/test-editor 에서 사용될 header */
export default function TestEditorHeader({ editorState, handlePostApTestData, handlePublish, handleSaveAllContent }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const navigate = useNavigate();
  const [isShowDirectionsPopup, setIsShowDirectionsPopup] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const saveCallback = useRef(); // 자동 저장 취소
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 테스트 미리보기 핸들러 */
  const handleClickPreview = () => {
    handleSaveAllContent();
    window.open('/ap/question-bank/test-preview', '_blank');
  };
  /** 테스트 저장 - 자동 저장을 위해 Ref에 저장 */
  const handleSave = useCallback(async () => {
    if (isSaving) return;
    setIsSaving(true);
    try {
      await handlePostApTestData();
    } finally {
      setIsSaving(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);
  /** 테스트 배포 핸들러 */
  const handlePublishClick = async () => {
    if (isPublishing) return;
    setIsPublishing(true);
    try {
      await handlePublish();
    } finally {
      setIsPublishing(false);
    }
  };
  /** 테스트 종료 핸들러 - 저장 후 테스트 목록으로 이동 */
  const handleExitClick = async () => {
    if (window.confirm('Your test has not been saved. Are you sure you want to leave?')) {
      // await handleSave();

      navigate('/ap/question-bank');
    }
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  const userInfo = request.tokenDecoder();
  // handleSave 함수가 랜더링 영향을 일으키지 않으면서 최신 함수를 참조하기 위해 useRef 사용
  useEffect(() => {
    saveCallback.current = handleSave;
  }, [handleSave]);
  // 저장된 최신 함수를 1분마다 실행
  useEffect(() => {
    if (userInfo && ![10, 1129].includes(userInfo.userSeq)) {
      const interval = setInterval(() => saveCallback.current(), AUTO_SAVE_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [isSaving, userInfo]); // 유저가 직접 저장한 경우 1분 자동 저장 갱신
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  const leftSection = (
    <S.LeftArea>
      <p className='module_title'>
        {`${editorState[EDITOR_CONSTANT.currentSection].section_name}${editorState[EDITOR_CONSTANT.currentPart].name !== '' ? `: ${editorState[EDITOR_CONSTANT.currentPart].name}` : ''}`}
      </p>
      <DirectionsPopupBtn isShowDirectionsPopup={isShowDirectionsPopup} setIsShowDirectionsPopup={setIsShowDirectionsPopup} />
    </S.LeftArea>
  );
  const centerSection = (
    <S.CenterArea>
      <p className='test_name'>{editorState[EDITOR_CONSTANT.currentQuestion].test_name}</p>
      <p className='subject_name'>{editorState[EDITOR_CONSTANT.currentQuestion].subject_name}</p>
    </S.CenterArea>
  );
  const rightSection = (
    <S.RightArea>
      <button type='button' title='' className='button btn_sub' onClick={handleClickPreview}>
        Preview
      </button>
      <button type='button' title='' className='button btn_sub' onClick={handleSave} disabled={isSaving}>
        {isSaving ? 'Saving...' : 'Save'}
      </button>
      <button type='button' title='' className='button btn_sub' onClick={handleExitClick}>
        Exit
      </button>
      <button type='button' title='' className='button btn_primary' onClick={handlePublishClick} disabled={isPublishing}>
        {isPublishing ? 'Publishing...' : 'Publish'}
      </button>
      <button className='help_button'>
        <ImageSvgIconHelp />
      </button>
    </S.RightArea>
  );
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return <ApExamHeaderBase leftSection={leftSection} centerSection={centerSection} rightSection={rightSection} />;
}

// --- --- --- Styled Components --- --- --- //
const S = {
  LeftArea: styled.div`
    padding-left: 4.375rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

    .module_title {
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.375rem;
    }
  `,

  CenterArea: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    text-align: center;
    .test_name {
      font-size: 1.375rem;
      font-weight: 600;
    }
    .subject_name {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.75rem;
    }
  `,

  RightArea: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1.25rem;
    padding-right: 3.875rem;

    .button {
      font-size: 1.125rem;
      font-weight: 500;
      padding: 0.5rem 1.25rem;
      border-radius: 6.25rem;
      cursor: pointer;
      line-height: 1.75rem;
      word-break: keep-all;
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        filter: grayscale(100%);
      }
    }

    .btn_primary {
      background-color: #ffde88;
    }
    .btn_sub {
      border: 1px solid #767676;
    }

    .help_button {
      color: #111111;
      cursor: pointer;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  `,
};
