import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import request from 'utils/Request.utils';

import * as ChannelService from '@channel.io/channel-web-sdk-loader';

const CHANNEL_TALK_PLUGIN_KEY = '416ec5df-15df-4ba6-abc6-f32c1e4fdbd0';

/** 채널톡 비활성화 경로 */
const NOT_USE_CHANNEL_TALK_PATH = [
  '/exam', // 시험 페이지
  '/ap/question-bank/test-editor', // ap - test editor ( 시험 생성 )
  '/ap/com/score/grading/submission', // ap - grading ( 시험 채점 페이지 )
];

const ChannelTalkProvider = () => {
  const userInfo = request.tokenDecoder();
  const location = useLocation();

  useEffect(() => {
    const isShutdownChannelTalk = NOT_USE_CHANNEL_TALK_PATH.some((path) => location.pathname.includes(path));
    try {
      if (isShutdownChannelTalk) {
        ChannelService.shutdown();
      } else {
        ChannelService.boot({
          pluginKey: CHANNEL_TALK_PLUGIN_KEY,
          memberId: userInfo.userEmail,
          profile: {
            name: userInfo.userName,
            email: userInfo.userEmail,
          },
          language: 'en',
        });
      }
    } catch (error) {
      console.log('미로그인 유저');
    }
  }, [location, userInfo]);

  // 단순 채널톡 플러그인 로드 프로바이더 ( 랜더링 데이터 없음 )
  return null;
};

export default ChannelTalkProvider;
