import { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import request from 'utils/Request.utils';

import ExitModal from '../common/modals/ExitModal';
import { deobfuscateUrlParam, obfuscateUrlParam } from 'utils/urlParamObfuscator';

import UI_ExamFooter from 'components/_common/exam/UI_ExamFooter';

/** Grading Submission Footer 컴포넌트 */
export default function GradingFooter() {
  const userInfo = request.tokenDecoder();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  /** 현재 uthSeq - Route Parameter */
  const { uthSeq: encUthSeq } = useParams();
  const uthSeq = deobfuscateUrlParam(encUthSeq);

  /** "Exit" 모달 노출 여부 */
  const [isViewExitModal, setIsViewExitModal] = useState(false);

  /** "Exit" 버튼 핸들러 */
  const handleExitButton = () => {
    setIsViewExitModal(true);
  };
  /** "Close" 버튼 핸들러 */
  const handleCloseButton = ({ uthSeq }) => {
    if (!uthSeq) {
      console.error('라우트 파라미터에 uthSeq 을 찾을 수 없습니다.');
      return;
    }
    const destinationUrl = `/ap/com/score/grading/submission/${obfuscateUrlParam(String(uthSeq))}`;

    navigate(destinationUrl);
  };

  const leftSection = <S.LeftArea className='user_name'>{userInfo.userName}</S.LeftArea>;
  const rightSection = (
    <S.RightArea>
      {pathname.includes('question-view') ? (
        <button type='button' title='' onClick={() => handleCloseButton({ uthSeq })}>
          Close
        </button>
      ) : (
        <button type='button' title='' onClick={handleExitButton}>
          Exit
        </button>
      )}
    </S.RightArea>
  );

  return (
    <>
      <UI_ExamFooter leftContent={leftSection} rightContent={rightSection} />
      {isViewExitModal && <ExitModal setShowModal={setIsViewExitModal} />}
    </>
  );
}

const S = {
  LeftArea: styled.div`
    padding-left: 4.5rem;
    &.user_name {
      color: #000;
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.375rem;
    }
  `,

  RightArea: styled.div`
    padding-right: 4.5rem;
    button {
      color: #fff;
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.625rem;
      border-radius: 1.875rem;
      background-color: #004a9e;
      padding: 0.875rem 1.5rem;
    }
  `,
};
