import {
  SET_JOIN_COMPANY_UID,
  SET_LOGIN_TYPE,
  SET_JOIN_COMPANY_CONFRIM_CODE,
  SET_JOIN_COMPANY_TIMEZONE_NO,
  CLEAR_JOIN_COMPANY_STATE,
  SET_JOIN_COMPANY_USER_LEVEL,
} from './joincompany.actionTypes';

// default state
const initialState = {
  userUid: '',
  loginType: '',
  confirmCode: '',
  timezoneNo: 0,
  userLevel: '',
};
const joinCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_JOIN_COMPANY_UID:
      return {
        ...state,
        userUid: action.payload,
      };
    case SET_LOGIN_TYPE:
      return {
        ...state,
        loginType: action.payload,
      };
    case SET_JOIN_COMPANY_CONFRIM_CODE:
      return {
        ...state,
        confirmCode: action.payload,
      };
    case SET_JOIN_COMPANY_TIMEZONE_NO:
      return {
        ...state,
        timezoneNo: action.payload,
      };
    case SET_JOIN_COMPANY_USER_LEVEL:
      return {
        ...state,
        userLevel: action.payload,
      };
    case CLEAR_JOIN_COMPANY_STATE:
      return initialState;
    default:
      return state;
  }
};
export default joinCompanyReducer;
