import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { nvl } from 'utils/Common.utils';

import MiniAlert from 'components/_common/alerts/MiniAlert';
import SMiniTextBtn from 'components/_common/button/SMiniTextBtn';
import ChangeClassModal from '../../modals/ChangeClassModal';
import ScoreHistoryModal from '../../modals/ScoreHistoryModal';

import { ReactComponent as ImgSvgIconFileSearch } from 'assets/img/svg/icon_file_search_02.svg';
import { ReactComponent as ImgSvgIconFileTime } from 'assets/img/svg/icon_file_time_02.svg';
import { ReactComponent as ImgSvgIconSwitchHorizontal } from 'assets/img/svg/icon_switch_horizontal_02.svg';
import { ReactComponent as ImgSvgIconSelect } from 'assets/img/svg/select.svg';
import ProfileModal from 'components/_common/modals/profileModal/ProfileModal';
import { STUDENTS_LIST_ORDER_FILTER_PARAMS } from 'components/student/constants/params';
import { USER_LEVELS } from 'utils/constants';
import request from 'utils/Request.utils';
import TestListModal from '../../modals/TestListModal';
import ClassListButton from './_components/buttons/ClassListButton';
import SubjectListButton from './_components/buttons/SubjectListButton';

const textLeftStyle = {
  textAlign: 'left',
};

/** student main - Table_MainContent */
export default function StudentsMainTable({ studentInfo, setSelectCheckBox, selectCheckBox, searchInfo, setSearchInfo, classList, getStudentList, isLoading }) {
  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** Class 선택 layer */
  const [userInfo, _] = useState(request.tokenDecoder());
  const [selectClass, setSelectClass] = useState(false);
  const [selectClassIdx, setSelectClassIdx] = useState(-1);
  /** "시험 목록" 모달 노출 여부 */
  const [isShowTestListModal, setIsShowTestListModal] = useState(false);
  /** 학생 history 모달 노출 여부 */
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  /** 학생 스코어 히스토리 */
  const [userSeq, setUserSeq] = useState(0);
  const [userName, setUserName] = useState(null);
  /** 학생 상세 프로필 모달 State */
  const [profileModalInfo, setProfileModalInfo] = useState({
    visible: false, // boolean           => 프로필 모달 노출 여부
    userSeq: 0, // number              => 학생 시퀀스
  });

  const [alert, alertSet] = useState({
    visible: false,
    alertMessage: '',
  });

  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  const { getObfuscatedValue, setObfuscatedValue, deleteObfuscatedValue } = useObfuscatedSearchParam();
  /** 현재 파라미터 객체 */
  const currentParams = {
    gradeOrder: getObfuscatedValue(STUDENTS_LIST_ORDER_FILTER_PARAMS.grade.KEY),
  };
  ////////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 쿼리 파라미터를 사용하여 3단계 (오름차순, 내림차순, 정렬 없음) 정렬 기능 함수 */
  const orderUtilWithQueryParam3step = ({ currentParam, key }) => {
    if (currentParam === 'asc') {
      setObfuscatedValue({ paramKey: key, value: 'desc' });
    } else if (currentParam === 'desc') {
      deleteObfuscatedValue({ paramKey: key });
    } else {
      setObfuscatedValue({ paramKey: key, value: 'asc' });
    }
  };

  /** "학년" 별 정렬 핸들러 */
  const handleToggleGradeOrder = () => {
    orderUtilWithQueryParam3step({ currentParam: currentParams.gradeOrder, key: STUDENTS_LIST_ORDER_FILTER_PARAMS.grade.KEY });
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      const idArray = [];
      let updatedSeqStr = '';

      for (let i = 0; i < studentInfo.studentList.length; i++) {
        idArray.push(studentInfo.studentList[i].userSeq);

        updatedSeqStr = idArray.join('|');
      }

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: idArray,
        seqStr: updatedSeqStr,
      });
    } else {
      setSelectCheckBox({ seqStr: '', selectedKeys: [] });
    }
  };

  const studentListCheck = (event, userSeq) => {
    const { selectedKeys, seqStr } = selectCheckBox;
    const { checked } = event.target;

    if (checked) {
      const updatedSelectedKeys = [...selectedKeys, userSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== userSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  /** tr (표의 행) 클릭 이벤트 */
  const trClickEvent = (userSeq) => {
    const { selectedKeys, seqStr } = selectCheckBox;

    if (!selectedKeys.includes(userSeq)) {
      const updatedSelectedKeys = [...selectedKeys, userSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== userSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  /** "View Profile" 버튼 핸들러 */
  const handleShowProfile = ({ event, item }) => {
    event.stopPropagation();
    const studentInfo = item;

    setProfileModalInfo({
      visible: true,
      userSeq: studentInfo.userSeq,
    });
  };

  /** 반 교체 */
  const classChange = ({ event, index }) => {
    event.stopPropagation();
    setSelectClass(!selectClass);
    setSelectClassIdx(index);
  };

  /** "Test List" 버튼 핸들러 */
  const handleTestListButton = ({ event, userSeq, userName }) => {
    event.stopPropagation();
    setUserSeq(userSeq);
    setUserName(userName);
    setIsShowTestListModal(true);
  };

  /** Score History 버튼 클릭 핸들러 */
  const handleClickScoreHistory = ({ event, userSeq, userName }) => {
    event.stopPropagation();
    setUserSeq(userSeq);
    setUserName(userName);

    setShowHistoryModal(true);
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    // "학년" 별 정렬 실제 구현 로직
    if (currentParams.gradeOrder === STUDENTS_LIST_ORDER_FILTER_PARAMS.grade.value.DESCENDING) {
      setSearchInfo((prev) => {
        return { ...prev, orderBy: 'student_grade DESC, MCH.reg_date DESC' };
      });
    } else if (currentParams.gradeOrder === STUDENTS_LIST_ORDER_FILTER_PARAMS.grade.value.ASCENDING) {
      setSearchInfo((prev) => {
        return { ...prev, orderBy: 'student_grade ASC, MCH.reg_date DESC' };
      });
    } else {
      setSearchInfo((prev) => {
        return { ...prev, orderBy: 'MCH.reg_date DESC' };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams.gradeOrder]);
  ////////////////////  END  --- React useEffect ---  END  ////////////////////

  // 주소에 /ap/ 가 붙어있는지 확인
  const isAp = window.location.pathname.includes('/ap/');
  // userLevel이 teacher인지 확인
  const isTeacher = userInfo.userLevel === USER_LEVELS.TEACHER;

  // 테이블 헤더 개수를 동적으로 계산
  const columnCount = (() => {
    let count = 8;

    if (isAp) count += 1; // 시험 형식 열 추가

    return count;
  })();

  return (
    <>
      <S.Table>
        {/* 🧩 student main - Table_MainContent_테이블헤더 🧩 */}
        <thead className='list'>
          <tr>
            <th className='col_01'>
              <input
                type='checkbox'
                className='com_chk'
                id='chk01'
                onChange={(e) => handleAllCheck(e.target.checked)}
                checked={
                  nvl(studentInfo?.studentList) !== '' && selectCheckBox.selectedKeys.length === studentInfo.studentList.length && studentInfo.totalCount > 0
                    ? true
                    : false
                }
              />
              <label htmlFor='chk01'>&nbsp;</label>
            </th>
            <th className='col_02'>No.</th>
            {isAp && <th className='test_format'>Test Format</th>}
            <th className='col_03'>Name</th>
            <th className='col_04'>Profile</th>
            <th className='col_05'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${currentParams.gradeOrder === STUDENTS_LIST_ORDER_FILTER_PARAMS.grade.value.ASCENDING ? 'up' : currentParams.gradeOrder === STUDENTS_LIST_ORDER_FILTER_PARAMS.grade.value.DESCENDING ? 'down' : ''}`}
                onClick={() => handleToggleGradeOrder()}>
                Grade <ImgSvgIconSelect />
              </button>
              {/*클릭 시 toggleClass active, 원위치 addClass default*/}
            </th>
            <th className='col_06'>School Name</th>
            <th className='col_07'>Class</th>
            <th className='col_08'>Test List</th>
            <th className='col_09'>Score History</th>
          </tr>
        </thead>
        {/* 🧩 student main - Table_MainContent_테이블메인 🧩 */}
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={columnCount}>Loading...</td>
            </tr>
          ) : studentInfo.studentList == null || studentInfo.studentList.length < 1 ? (
            <tr>
              <td colSpan={columnCount}>You do not have any students… yet!</td>
            </tr>
          ) : (
            studentInfo.studentList.map((item, index) => {
              return (
                <tr key={`tr_${index}_${item.userSeq}`} onClick={() => trClickEvent(item.userSeq)}>
                  <td>
                    <input
                      type='checkbox'
                      className='com_chk'
                      id={`chk_${index}`}
                      onChange={(event) => {
                        studentListCheck(event, item.userSeq);
                      }}
                      checked={selectCheckBox.selectedKeys.includes(item.userSeq)}
                    />
                    <label htmlFor={`chk_${index}`} onClick={(event) => event.stopPropagation()}>
                      &nbsp;
                    </label>
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>{studentInfo.totalCount - studentInfo.numPerPage * (studentInfo.currentPage - 1) - index}</td>
                  {isAp && (
                    <td>
                      {item.classList != null ? (
                        <div className='table_data_inner_box'>
                          <div>
                            {item.classList[0].subjectName} {item.classList.length > 1 && <SubjectListButton classList={item.classList} />}
                          </div>
                        </div>
                      ) : (
                        '-'
                      )}
                    </td>
                  )}
                  <td style={{ ...textLeftStyle }}>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '4px' }}>
                      {item.readFlag === 'N' && <SMiniTextBtn text={'A'} />}
                      <p className='name'>{item.userName}</p>
                    </div>
                  </td>
                  <td>
                    <button type='button' title='Open a Modal' onClick={(event) => handleShowProfile({ event, item })} className='btn_view'>
                      View
                    </button>
                  </td>
                  <td>{item.studentGrade}</td>
                  <td style={textLeftStyle}>{item.studentSchool}</td>
                  <td style={{ ...textLeftStyle }} className='class'>
                    <div className='table_data_inner_box'>
                      <div>
                        {item.classList?.[0]?.className || 'Individual'}
                        {item.classList?.length > 1 && <ClassListButton classLists={item.classList} />}
                      </div>
                      <button type='button' title='Change class' onClick={(event) => classChange({ event, index })} className='btn_change_class'>
                        <ImgSvgIconSwitchHorizontal />
                      </button>
                      {/* Change class 모달 */}
                      {index === selectClassIdx && (
                        <ChangeClassModal
                          studentInfo={{ userSeq: item.userSeq, userName: item.userName }}
                          studentClassList={item.classList}
                          companyClassList={classList}
                          selectClass={selectClass}
                          setSelectClass={setSelectClass}
                          alertSet={alertSet}
                          getStudentList={getStudentList}
                        />
                      )}
                    </div>
                  </td>
                  <td className='table_data_btn_area'>
                    <button
                      type='button'
                      title='Open a Modal Test List'
                      onClick={(event) => {
                        handleTestListButton({ event, userSeq: item.userSeq, userName: item.userName });
                      }}>
                      <ImgSvgIconFileSearch />
                    </button>
                  </td>
                  <td className='table_data_btn_area'>
                    <button
                      type='button'
                      title='Open a Modal Score History'
                      onClick={(event) => {
                        handleClickScoreHistory({ event, userSeq: item.userSeq, userName: item.userName });
                      }}>
                      <ImgSvgIconFileTime />
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </S.Table>

      {isShowTestListModal && <TestListModal setShowModal={setIsShowTestListModal} userSeq={userSeq} userName={userName} />}

      {/* 🪟 student main - 학생 스코어 히스토리 모달 🪟 */}
      {showHistoryModal && <ScoreHistoryModal setShowModal={setShowHistoryModal} userSeq={userSeq} userName={userName} />}

      {/* 학생 상세 프로필 모달 */}
      <ProfileModal profileModalInfo={profileModalInfo} closeProfileModal={() => setProfileModalInfo((prev) => ({ ...prev, visible: false }))} />

      {/* 학생 삭제 확인 Toast Alert */}
      {alert.visible && (
        <MiniAlert text={alert.alertMessage} active={alert.visible} inactive={() => alertSet({ visible: false, alertMessage: '' })} timeOut={2000} />
      )}
    </>
  );
}

const S = {
  Table: styled.table`
    tr {
      th,
      td {
        vertical-align: middle;
        padding: 0 0.625rem;
        &.col_01 {
          width: 2.5rem;
        }
        &.col_02 {
          width: 2.25rem;
        }
        &.col_03 {
          width: 10rem;
        }
        &.col_04 {
          width: 4.2rem;
        }
        &.col_05 {
          width: 5rem;
        }
        &.col_06 {
          width: 8rem;
        }
        &.col_07 {
          width: 15rem;
        }
        &.col_08 {
          width: 5rem;
        }
        &.col_09 {
          width: 7rem;
        }
        &.test_format {
          width: 12rem;
        }
      }
      th {
        background-color: #000;
        color: #fff;
        height: 2.5rem;
        text-align: center;
        font-weight: 700;
        font-size: 0.8125rem;
        word-break: keep-all;
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          overflow: hidden;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          overflow: hidden;
        }

        .btn_sort {
          color: #ffffff;
          font-weight: 700;
          font-size: 0.8125rem;
          &::after {
            content: '-';
          }
          svg {
            width: 0.75rem;
            transition: 0.2s;
            transform-origin: center;
            display: none;
          }
          &.up {
            &::after {
              display: none;
            }
            svg {
              display: inline-block;
            }
          }
          &.down {
            &::after {
              display: none;
            }
            svg {
              display: inline-block;
              transform: rotate(180deg);
            }
          }
        }
      }
      td {
        height: 3rem;
        text-align: center;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        border-bottom: 1px solid #ebebeb;

        &.col_03,
        &.col_06,
        &.col_07 {
          text-align: left;
        }
        .table_data_inner_box {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
        }

        & > button,
        .table_data_inner_box > button {
          width: 1.5rem;
          height: 1.5rem;
          transition: 0.2s;
          &:hover {
            filter: brightness(95%);
          }

          &.btn_view {
            width: auto;
            height: auto;
            background-color: #f2f2f2;
            color: #7f8998;
            font-weight: 400;
            font-size: 0.875rem;
            border-radius: 0.25rem;
            padding: 0.125rem 0.5rem;
          }

          &.btn_change_class {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #f2f2f2;
            border-radius: 0.25rem;
            svg {
              width: 1rem;
              color: #a6b3be;
            }
          }
        }

        &.table_data_btn_area {
          button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #e8f0ff;
            border-radius: 0.25rem;
            svg {
              width: 1.125rem;
              color: #0068bd;
            }
          }
        }
      }
      background-color: #fff;
      &:nth-child(even) {
        background-color: #fafafa;
      }
      &.active {
        background-color: #edf7ff;
      }
      &:has(.com_chk):hover {
        background-color: #f2f2f2;
        //color:#4B5563 ;
      }
    }
  `,
};
