import React from 'react';
import IconDel from 'assets/img/icon_del.svg';

/** 삭제 확인 모달 */
export default function DeleteModal({ setIsViewModal, callback }) {
  return (
    <div className='popup type_alert active'>
      {' '}
      {/*활성화 시 addClass active*/}
      <div className='pop_container'>
        <div className='pop_body ok_contents'>
          <p className='alert_title'>
            Are you sure you want to
            <br />
            remove the file?
          </p>
          <div className='icon'>
            <img src={IconDel} alt='icon' />
          </div>
        </div>
        <div className='pop_footer many'>
          <button className='btn btn_stroke btn_middle' onClick={() => setIsViewModal(false)}>
            Cancel
          </button>
          <button className='btn btn_fill btn_middle' onClick={callback}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
