import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { deobfuscateUrlParam, obfuscateUrlParam } from 'utils/urlParamObfuscator';

import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/icon_arrow_02.svg';

/** ap/com/score/grading/submission 페이지의 "Hybrid Free Response Question" 의 각 문제 아이템 컴포넌트
* props :
number : 문제 번호
isEnter : 문제를 풀었는가?
score : 점수
*/
export default function QuestionItem({ item, partSeq, ...rest }) {
  const navigate = useNavigate();

  /** 현재 uthSeq - Route Parameter */
  const { uthSeq: encUthSeq } = useParams();
  const uthSeq = deobfuscateUrlParam(encUthSeq);

  /** 각 개별 문제 클릭 핸들러 */
  const handleClick = ({ e, item, uthSeq, partSeq }) => {
    if (uthSeq && partSeq && item.questionNum !== undefined && item.questionNum !== null) {
      navigate(
        `/ap/com/score/grading/submission/${obfuscateUrlParam(String(uthSeq))}/question-view/${obfuscateUrlParam(String(partSeq))}/${obfuscateUrlParam(String(item.questionNum))}`
      );
    } else {
      console.error('uthSeq 또는 partSeq 또는 questionNum 를 확인해 주세요.');
    }
  };

  return (
    <S.Wrap {...rest}>
      <button type='button' title='Move to another Page' onClick={(event) => handleClick({ event, item, uthSeq, partSeq })}>
        <div
          className={`number_wrap ${item.receivedPoint !== null && item.receivedPoint !== undefined ? '' : 'not_taken'} ${item.customQuestionOrder.length === 1 ? 'single_char' : ''}`}>
          {item.customQuestionOrder}
        </div>
        <div className='right_wrap'>
          <div className='score_wrap'>
            <dl>
              <dt>Raw Score</dt>
              <dl>
                <strong>{item.receivedPoint === undefined || item.receivedPoint === null || item.receivedPoint === '' ? '-' : item.receivedPoint}</strong> /{' '}
                {item.score ? item.score : '-'}
              </dl>
            </dl>
            {/* 20241220 기획은 해당 화면에서 점수 수정을 요구하나, 이는 사용자 관점에서 의미 없는 UI로 보인다. 일단, Phase 1 에서는 주석 처리 */}
            {/* <label htmlFor={`question${item.number}`}>Raw Score</label>
        <input id={`question${item.number}`} type='text' value={item.score} onChange={(event) => {}} placeholder='Score' /> / 21 */}
          </div>
          <i className='arrow_icon_wrap'>
            <ArrowIconSelect />
          </i>
        </div>
      </button>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    button {
      width: 100%;
      height: 3.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .number_wrap {
      border: 1px dashed #111;
      width: 2.75rem;
      height: 2.75rem;
      padding: 0.5625rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #384cc0;
      color: #ffffff;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: 600;
      word-break: keep-all;
      &.not_taken {
        background-color: #ffffff;
        color: #384cc0;
      }
      &.single_char {
        font-size: 1.5rem;
      }
    }

    .score_wrap {
      color: #505050;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      dl {
        display: flex;
        align-items: center;
        justify-content: right;
        flex-wrap: nowrap;
        gap: 0.5rem;
        dt {
          color: #111;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
          margin-right: 1rem;
        }
        dd {
          width: 4.25rem;
          padding: 0.5rem 0.625rem;
          border-radius: 0.25rem;
          border: 1px solid #d7dadf;
          background: #fff;
          color: #999;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
        }
      }
    }

    .right_wrap {
      display: flex;
      align-items: center;
      gap: 2.5rem;
    }
    .arrow_icon_wrap {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 1rem;
        color: #999999;
        transition: 0.2s;
        transform: rotateZ(270deg);
      }
    }
  `,
};
