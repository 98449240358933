import React from 'react';
import styled from 'styled-components';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

/** ap/com/score/review 페이지에서 유저가 “Exit” 버튼을 누르거나, 브라우저 탭을 닫을려고 할때 나오는 모달 */
export default function ExitModal({ setShowModal }) {
  /** "Cancel" 버튼 핸들러 */
  const handleCancel = () => {
    setShowModal(false);
  };
  /** "Exit" 버튼 핸들러 */
  const handleExit = () => {
    window.close();
  };

  return (
    <S.Wrap>
      <SimpleModalBase onEscape={() => setShowModal(false)} w='38.125rem' p='1.5rem 1.25rem'>
        <div className='modal_title_container'>
          <button className='modal_title_btn' onClick={() => setShowModal(false)}>
            <i className='svg_icon btn_pop_close black'>&nbsp;</i>
          </button>
        </div>

        <div className='modal_text_container'>
          <p>really?</p>
        </div>
        <div className='modal_btn_container'>
          <button type='button' title='' onClick={handleCancel}>
            Cancel
          </button>
          <button type='button' title='' onClick={handleExit} className='btn_fill'>
            Exit
          </button>
        </div>
      </SimpleModalBase>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .modal_text_container {
      & > p {
        text-align: left;
      }
    }
    .modal_btn_container {
      justify-content: flex-end;
      button {
        width: 8.625rem;
        height: 3rem;
        padding: 0;
      }
    }
  `,
};
