import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
import MiniReviewPage from './MiniReviewPage';

/** 영어 시험 화면 Footer 영역 컴포넌트 */
export default function ExamFooter({ stateExamInfo, setStateExamInfo, questionInfo }) {
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  const miniReviewToggleRef = useRef();
  const [isOpened, setIsOpened] = useState(false);

  const backButtonClick = () => {
    if (stateExamInfo.currentNum === 1) return;
    setStateExamInfo((prev) => ({ ...prev, currentNum: prev.currentNum - 1, isReviewPopupOpen: false }));
    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
    localStorage.setItem(`apTest_${uthSeq}`, 'currentNum', stateExamInfo.currentNum - 1);
  };

  const nextButtonClick = () => {
    // 리뷰 팝업이 열려있지 않고 마지막 문제일 경우
    if (!stateExamInfo.isReviewPopupOpen && stateExamInfo.currentNum === stateExamInfo.totalQuestion) {
      setStateExamInfo((prev) => ({ ...prev, isReviewPopupOpen: true }));
      return;
    }
    // 리뷰 팝업이 열려있을 경우
    if (stateExamInfo.isReviewPopupOpen) {
      const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;

      const testInfo = localStorage.getItemJsonParse(`apTest_${uthSeq}`).testInfo;
      const currentSession = localStorage.getItemJsonParse(`apTest_${uthSeq}`).sessionOrder;

      const isBreakTime = testInfo.breakTimeSession === currentSession;

      if (isBreakTime) {
        // 휴식시간일 경우
        localStorage.setItem(`apTest_${uthSeq}`, 'startTime', Date.now());
        navigate('/ap/exam/break', { replace: true });
        return;
      }

      // 마지막 세션일 경우
      if (testInfo.totalSession === currentSession) {
        // 마지막 세션일 경우
        navigate('/ap/exam/finish', {
          replace: true,
        });
        return;
      }

      // 다음 세션으로 이동
      if (confirm('다음 세션으로 이동')) setStateExamInfo((prev) => ({ ...prev, showModuleOver: true }));
      return;
    }

    // 다음 문제로 이동
    setStateExamInfo((prev) => ({ ...prev, currentNum: prev.currentNum + 1, isReviewPopupOpen: false }));
    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
    localStorage.setItem(`apTest_${uthSeq}`, 'currentNum', stateExamInfo.currentNum + 1);
  };

  // 문제당 머문 시간 체크
  useEffect(() => {
    const uthSeq = localStorage.getItemJsonParse('currentTest')?.uthSeq;

    const currentTestKey = `apTest_${uthSeq}`;
    const savedData = localStorage.getItemJsonParse(currentTestKey);

    const questionIndex = stateExamInfo.currentNum - 1;
    const questionList = savedData.questionList;

    let solvingTime = questionList[questionIndex]?.solvingTime || 0;
    // 타이머 시작
    const intervalId = setInterval(() => {
      solvingTime += 1;
    }, 1000);

    // 5초마다 로컬스토리지 업데이트
    const saveIntervalId = setInterval(() => {
      localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', questionIndex, { solvingTime });
    }, 5000);

    return () => {
      clearInterval(intervalId);
      clearInterval(saveIntervalId);

      // 마지막 저장
      localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', questionIndex, { solvingTime });
    };
  }, [stateExamInfo.currentNum]);

  return (
    <section className='pop_footer'>
      <div className='name'>{userInfo?.userName}</div>
      {
        <button className='btn_question' ref={miniReviewToggleRef} onClick={() => setIsOpened((prev) => !prev)}>
          Question {stateExamInfo.currentNum} of {stateExamInfo.totalQuestion}
          <i className='svg_icon icon_arrow white'>&nbsp;</i>
        </button>
      }
      <article className='btn_area'>
        <button className='btn' onClick={backButtonClick}>
          Back
        </button>
        <button className='btn' onClick={nextButtonClick}>
          Next
        </button>
      </article>
      {isOpened && (
        <MiniReviewPage stateExamInfo={stateExamInfo} setStateExamInfo={setStateExamInfo} questionInfo={questionInfo} closePopup={() => setIsOpened(false)} />
      )}
    </section>
  );
}
