import React, { useState, useEffect, useRef } from 'react';

const timeModalBackgroundStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function CalendarSetTimeModal({ close, confirm, btnType, setTimeLayer, setSelectedTime }) {
  const layerRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (layerRef.current && !layerRef.current.contains(event.target)) {
        setTimeLayer(false);
        setSelectedTime('00:00');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setTimeLayer, setSelectedTime]);

  const [timeSelect, setTime] = useState(false);
  const [positionStyle, setPositionStyle] = useState({});
  const [selectTime, setSelectTime] = useState({ label: '12:00 AM', value: '00:00' });

  const handleSetTime = (label, value) => {
    setSelectTime({ label, value });
    setSelectedTime(value);
  };

  const timeOptions = Array.from({ length: 48 }, (_, index) => {
    const hour = Math.floor(index / 2);
    const minute = index % 2 === 0 ? '00' : '30';
    const ampm = hour < 12 || hour === 24 ? 'AM' : 'PM';
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    const label = `${hour12 < 10 ? '0' + hour12 : hour12}:${minute} ${ampm}`;
    const value = `${hour < 10 ? '0' + hour : hour}:${minute}`;
    return { label, value };
  });

  return (
    <div style={timeModalBackgroundStyle}>
      <div className='com_time_layer active' onClick={(e) => e.stopPropagation()} style={positionStyle} ref={layerRef}>
        <div className='layer_tit'>Start Time</div>
        <div className={`com_select_layer type_updown ${timeSelect ? 'active' : ''}`} onClick={() => setTime((prev) => !prev)}>
          <section className='selected_wrap'>
            <input className='selected' value={selectTime.label} readOnly />
          </section>
          <section className='layer scroll'>
            {timeOptions.map(({ label, value }, index) => (
              <p key={index} className='option' onClick={() => handleSetTime(label, value)}>
                {label}
              </p>
            ))}
          </section>
        </div>

        <div className='com_btn_wrap bottom'>
          {btnType === 'skip' && (
            <button className='com_btn line point m full' onClick={close}>
              Skip
            </button>
          )}
          <button className='com_btn point m full' onClick={confirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default CalendarSetTimeModal;
