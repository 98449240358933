import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { nvl, validateEmail } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import LoadingBar from 'utils/LoadingBar';
import { USER_LEVELS } from 'utils/constants';

//component
import PopBack from 'components/_common/popups/_PopBack';
import MainBox from 'components/user/_components/layouts/MainBox';
import Logo from 'components/user/_components/layouts/Logo';
import Header from 'components/user/sign-up/_components/layouts/Header';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import Label from 'components/user/_components/common/forms/Label';
import Input from 'components/user/_components/common/forms/Input';
import SignCheckBoxBase from 'components/user/_components/common/forms/inputs/_SignCheckBoxBase';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import SubButton from 'components/user/_components/common/buttons/SubButton';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import ErrorText from 'components/user/_components/styles/ErrorText';

/** 회원 가입 - 이메일 회원 가입 - 아카데미 관리자 - 정보 입력 페이지 */
export default function SignUpEmailAcademyAdminInfoPage() {
  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //현재페이지에서만 removeClass
    };
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  /** "뒤로 가기" 버튼이 눌렸는가 여부 */
  const [isBack, setBack] = useState(false);

  /** Form 유효성 검사 상태 (중복 검사 및 매치 여부 등) */
  const [validityForm, setValidityForm] = useState({
    isDuplicateEmail: false, // 이메일 중복 여부
    isMobileDuplicate: false, // 전화 번호 중복 여부
    isPasswordLengthEight: false, // 비밀 번호 8자 이상 인지 여부
    isPasswordSpecial: false, // 비밀 번호 유효성 형식에 맞는지 여부
    isPasswordMismatch: false, // 입력 한 비밀 번호 확인과 동일 한지 여부
  });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    setFocus,
  } = useForm({
    defaultValues: {
      userEmail: '',
      userPassword: '',
      confirmPassword: '',
      userName: '',
      mobilePhone: '',
      mobilePhone02: '',
      mobilePhone03: '',
      companyName: '',
      officePhone: '',
      officePhone02: '',
      officePhone03: '',
      agree01: false,
      agree02: false,
      agree03: false,
    },
    mode: 'onChange',
  });

  /** 비밀 번호 유효성 검사 */
  const passwordCheck = (data) => {
    if (nvl(data.userPassword).length >= 8) {
      setValidityForm((prev) => {
        return { ...prev, isPasswordLengthEight: true };
      });
    } else {
      setValidityForm((prev) => {
        return { ...prev, isPasswordLengthEight: false };
      });
    }
    const patternSpecial = /[\W_]/;
    const patternNum = /[0-9]/;
    if (patternSpecial.test(nvl(data.userPassword)) && patternNum.test(nvl(data.userPassword))) {
      setValidityForm((prev) => {
        return { ...prev, isPasswordSpecial: true };
      });
    } else {
      setValidityForm((prev) => {
        return { ...prev, isPasswordSpecial: false };
      });
    }
    if (nvl(data.userPassword) === nvl(data.confirmPassword) && nvl(data.confirmPassword).length !== 0) {
      setValidityForm((prev) => {
        return { ...prev, isPasswordMismatch: true };
      });
    } else {
      setValidityForm((prev) => {
        return { ...prev, isPasswordMismatch: false };
      });
    }
  };

  /** 전화번호 유효성 검사 */
  const phoneNumberCheck = (event, maxLength) => {
    const inputValue = event.target.value;

    event.target.value = inputValue.replace(/[^0-9]/g, '');

    if (inputValue.length > maxLength) {
      event.target.value = inputValue.slice(0, maxLength);
    }
  };

  /** 메일 보내기 API 요청 */
  const sendMail = (data) => {
    let params = {
      userEmail: data.userEmail,
      userName: data.userName,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        navigate('/signup/email/academy-admin/confirm');
        setLoading(false);
      }
    };

    request.post('/api/common/email', params, successHandler);
  };

  /** 이메일 중복 체크 API 요청 */
  const checkEmailDuplicate = (data) => {
    setLoading(true);
    let params = {
      userEmail: data.userEmail,
      mobile: data.mobilePhone + data.mobilePhone02 + data.mobilePhone03,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let emailDuplicate = response.result.idDuplicate;
        let mobileDuplicate = response.result.mobileDuplicate;

        if (emailDuplicate || mobileDuplicate) {
          setValidityForm((prev) => {
            return {
              ...prev,
              isDuplicateEmail: emailDuplicate,
              isMobileDuplicate: mobileDuplicate,
            };
          });
          if (emailDuplicate) setFocus('userEmail');
          else if (mobileDuplicate) setFocus('mobilePhone');
          setLoading(false);
        } else {
          sendMail(data);
        }
      }
    };

    request.get(`/api/common/email/duplicate?userEmail=${params.userEmail}&userMobile=${params.mobile}`, null, successHandler);
  };

  /** Form 제출 핸들러 (react-hook-form) */
  const onValid = (data) => {
    let allPassed = true;

    if (nvl(data.officePhone) === '' && nvl(data.officePhone02) === '' && nvl(data.officePhone03) === '') {
      allPassed = false;
      setFocus('officePhone');
    }

    if (nvl(data.companyName) === '') {
      allPassed = false;
      setFocus('companyName');
    }

    if (nvl(data.mobilePhone) === '' && nvl(data.mobilePhone02) === '' && nvl(data.mobilePhone03) === '') {
      allPassed = false;
      setFocus('mobilePhone');
    }

    if (nvl(data.userName) === '') {
      allPassed = false;
      setFocus('userName');
    }

    if (!validateEmail(nvl(data.userEmail))) {
      allPassed = false;
      setFocus('userEmail');
    }

    passwordCheck(data);

    if (!data.userPassword || !data.confirmPassword) {
      allPassed = false;
    }

    if (!data.agree01) allPassed = false;

    if (allPassed) {
      dispatch({
        type: 'setCustomer',
        payload: { fieldName: 'userName', data: data.userName },
      });
      dispatch({
        type: 'setCustomer',
        payload: { fieldName: 'userEmail', data: data.userEmail },
      });
      dispatch({
        type: 'setCustomer',
        payload: { fieldName: 'userPasswd', data: data.userPassword },
      });
      dispatch({
        type: 'setCustomer',
        payload: {
          fieldName: 'userMobile',
          data: data.mobilePhone + '-' + data.mobilePhone02 + '-' + data.mobilePhone03,
        },
      });
      dispatch({
        type: 'setCustomer',
        payload: {
          fieldName: 'companyName',
          data: data.companyName,
        },
      });
      dispatch({
        type: 'setCustomer',
        payload: {
          fieldName: 'companyNum',
          data: data.officePhone + '-' + data.officePhone02 + '-' + data.officePhone03,
        },
      });
      dispatch({
        type: 'setCustomer',
        payload: {
          fieldName: 'mailYn',
          data: data.agree02 ? 'Y' : 'N',
        },
      });
      dispatch({
        type: 'setCustomer',
        payload: {
          fieldName: 'marketingYn',
          data: data.agree03 ? 'Y' : 'N',
        },
      });

      dispatch({
        type: 'setCustomer',
        payload: { fieldName: 'userLevel', data: USER_LEVELS.ACADEMY },
      });

      checkEmailDuplicate(data);
    }
  };

  return (
    <>
      <S.Wrap>
        <MainBox>
          <Logo />
          <Header title={'Academy Admin'} />
          <form onSubmit={handleSubmit(onValid)} className='form'>
            <FormItemWrap>
              <Label className='red-dot' htmlFor='userEmail'>
                Email
              </Label>
              <Input
                id='userEmail'
                type='email'
                register={register('userEmail', {
                  required: 'Please type in your email address.',
                  pattern: {
                    value: /^[^@]+@[^@]+$/,
                    message: 'Email format is not acceptable. Please check again.',
                  },
                })}
                autoFocus
                isError={errors.userEmail || validityForm.isDuplicateEmail}
              />
            </FormItemWrap>
            {errors.userEmail && <ErrorText>{errors.userEmail.message}</ErrorText>}
            {validityForm.isDuplicateEmail && <ErrorText>The email is already in use. Please use another email.</ErrorText>}
            <FormItemWrap>
              <Label className='red-dot' htmlFor='userPassword'>
                Password
              </Label>
              <Input
                id='userPassword'
                type='password'
                register={register('userPassword', {
                  required: '',
                  onChange: (e) => {
                    passwordCheck(watch());
                  },
                })}
                isError={errors.userPassword || !validityForm.isPasswordLengthEight || !validityForm.isPasswordSpecial}
              />
              {errors.userPassword && <ErrorText>{errors.userPassword.message}</ErrorText>}
            </FormItemWrap>
            <FormItemWrap>
              <Label className='red-dot' htmlFor='confirmPassword'>
                Conform Password
              </Label>
              <Input
                id='confirmPassword'
                type='password'
                register={register('confirmPassword', { required: '', onChange: (e) => passwordCheck(watch()) })}
                isError={errors.confirmPassword || !validityForm.isPasswordLengthEight || !validityForm.isPasswordSpecial || !validityForm.isPasswordMismatch}
              />
              {errors.confirmPassword && <ErrorText>{errors.confirmPassword.message}</ErrorText>}
            </FormItemWrap>
            <div className='com_password_message'>
              <p className={`check ${validityForm.isPasswordLengthEight ? 'valid' : ''}`} id='lengthMessage'>
                Must be over 8 characters
              </p>
              <p className={`check ${validityForm.isPasswordSpecial ? 'valid' : ''}`} id='characterMessage'>
                Must have a number and a special character (!, @ , ?)
              </p>
              <p className={`check ${validityForm.isPasswordMismatch ? 'valid' : ''}`} id='matchMessage'>
                Passwords must match
              </p>
            </div>
            <FormItemWrap>
              <Label className='red-dot' htmlFor='userName'>
                Name
              </Label>
              <Input
                id='userName'
                type='text'
                register={register('userName', { required: 'Please type in your full name' })}
                className={errors.userName ? 'error' : ''}
              />
              {errors.userName && <ErrorText>{errors.userName.message}</ErrorText>}
            </FormItemWrap>
            <FormItemWrap>
              <Label className='red-dot' htmlFor='mobilePhone'>
                Mobile Phone No.
              </Label>
              <div className='phone-input-wrap'>
                <Input
                  id='mobilePhone'
                  type='tel'
                  register={register('mobilePhone', {
                    required: 'Please type in a phone number.',
                    maxLength: 3,
                    onChange: (event) => phoneNumberCheck(event, 3),
                  })}
                  maxLength={3}
                  isError={errors.mobilePhone || validityForm.isMobileDuplicate}
                />
                -
                <Input
                  type='tel'
                  register={register('mobilePhone02', {
                    required: 'Please type in a phone number.',
                    maxLength: 4,
                    onChange: (event) => phoneNumberCheck(event, 4),
                  })}
                  maxLength={4}
                  isError={errors.mobilePhone02 || validityForm.isMobileDuplicate}
                />
                -
                <Input
                  type='tel'
                  register={register('mobilePhone03', {
                    required: 'Please type in a phone number.',
                    maxLength: 4,
                    onChange: (event) => phoneNumberCheck(event, 4),
                  })}
                  maxLength={4}
                  isError={errors.mobilePhone03 || validityForm.isMobileDuplicate}
                />
              </div>
              {errors.mobilePhone && <ErrorText>{errors.mobilePhone.message}</ErrorText>}
              {validityForm.isMobileDuplicate && <ErrorText>The phone number is already in use. Please use another phone number.</ErrorText>}
            </FormItemWrap>
            <p className='tip'>Your mobile number will be used to identify you</p>
            <FormItemWrap>
              <Label className='red-dot' htmlFor='companyName'>
                Company Name
              </Label>
              <Input
                id='companyName'
                type='text'
                register={register('companyName', { required: 'Please type in your official company name', maxLength: 20 })}
                maxLength={20}
                isError={errors.companyName}
              />
              {errors.companyName && <ErrorText>{errors.companyName.message}</ErrorText>}
            </FormItemWrap>
            <FormItemWrap>
              <Label className='red-dot' htmlFor='officePhone'>
                Office Phone No.
              </Label>
              <div className='phone-input-wrap'>
                <Input
                  id='officePhone'
                  type='tel'
                  register={register('officePhone', {
                    required: `Please type in your company's office phone number. If you don't have one, type in your cell phone number.`,
                    maxLength: 5,
                    onChange: (event) => phoneNumberCheck(event, 5),
                  })}
                  maxLength={5}
                  isError={errors.officePhone}
                />
                -
                <Input
                  type='tel'
                  register={register('officePhone02', {
                    required: `Please type in your company's office phone number. If you don't have one, type in your cell phone number.`,
                    maxLength: 5,
                    onChange: (event) => phoneNumberCheck(event, 5),
                  })}
                  maxLength={5}
                  isError={errors.officePhone}
                />
                -
                <Input
                  type='tel'
                  register={register('officePhone03', {
                    required: `Please type in your company's office phone number. If you don't have one, type in your cell phone number.`,
                    maxLength: 5,
                    onChange: (event) => phoneNumberCheck(event, 5),
                  })}
                  maxLength={5}
                  isError={errors.officePhone}
                />
              </div>
              {errors.officePhone && <ErrorText>{errors.officePhone.message}</ErrorText>}
            </FormItemWrap>
            <div className='agree-wrap'>
              <SignCheckBoxBase checkBoxId={'agree01'} register={register('agree01', { required: 'You must agree with our terms to use our service!' })}>
                I have read and agree to Gate⁺
                <a
                  className='btn_txt'
                  href='https://gateplus.notion.site/Privacy-policy-460855e375544490ba01ca7d24404dd3'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='새 탭으로 열기'>
                  Privacy Policy
                </a>{' '}
                and
                <br />
                <a
                  className='btn_txt'
                  href='https://gateplus.notion.site/Terms-of-Service-5a16766471974bb0b51f63a9f983dc13'
                  target='_blank'
                  rel='noopener noreferrer'
                  title='새 탭으로 열기'>
                  Terms of Service
                </a>
                (Mandatory)
              </SignCheckBoxBase>
              {errors.agree01 && <ErrorText>{errors.agree01.message}</ErrorText>}

              <SignCheckBoxBase checkBoxId={'agree02'} register={register('agree02')}>
                I agree to receive
                <a
                  className='btn_txt'
                  href='https://gateplus.notion.site/Marketing-Consent-f0623a824e9a4c7a8de7b4c4639f4369'
                  target='_blank'
                  rel='noopener noreferrer'>
                  marketing communications
                </a>
                from Gate⁺ (optional)
              </SignCheckBoxBase>
              <SignCheckBoxBase checkBoxId={'agree03'} register={register('agree03')}>
                I want to receive
                <a
                  className='btn_txt'
                  href='https://gateplus.notion.site/Marketing-Consent-f0623a824e9a4c7a8de7b4c4639f4369'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Gate⁺ Newsletter
                </a>
                (Optional)
              </SignCheckBoxBase>
            </div>
            <PrimaryButtonArea>
              <SubButton type='button' onClick={() => setBack(true)} title='이전 페이지'>
                Back
              </SubButton>
              <PrimaryButton type='submit' title='다음 페이지'>
                Next
              </PrimaryButton>
            </PrimaryButtonArea>
          </form>
        </MainBox>
      </S.Wrap>
      {/*뒤로가기 클릭 시 팝업*/}
      {isBack && <PopBack closePopBack={() => setBack(false)} />}
      {isLoading && <LoadingBar />}
    </>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .guide {
    color: #7f8998;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
  }
  .com_password_message {
    color: #7f8998;
    font-size: 0.813rem;
    font-weight: 400;
    text-align: left;
  }
  .phone-input-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    input {
      width: 30%;
      flex: 1;
    }
  }
  .agree-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding-left: 2.5px;
    margin-top: 2.5rem;
    .checkbox-error-txt {
      color: #000 !important;
    }
    .btn_txt {
      text-decoration: none;
      line-height: 16px;
      height: 20px;
      margin: 0 3px;
    }
    .line-height-18 {
      line-height: 18px;
    }
  }
  .red-dot {
    padding-left: 0.65rem;
    ::before {
      content: '*';
      color: var(--sub-red);
      margin-top: 2px;
      margin-left: -10px;
      position: absolute;
    }
  }

  .tip {
    color: #7f8998;
    text-align: left;
    font-weight: 400;
    font-size: 0.813rem;
    margin-top: 0.5rem;
  }
`;
