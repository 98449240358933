import React, { useState } from 'react';
import styled from 'styled-components';

import { current } from '@reduxjs/toolkit';
import PopTestList from '../../modals/_PopTestList';
import StudentListModal from '../../modals/StudentListModal';

/** "/com/score" 페이지 상단 메인 "Test 선택" 영역 컴포넌트 */
export default function SelectButton({ tabState, scorePageInfo, classAvgPoint }) {
  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [testListModalActive, setTestListModalActive] = useState(false);
  const [studentListModalActive, setStudentListModalActive] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /** "Test List 버튼 클릭 핸들러 (팝업 노출)" */
  const handleSelectTestList = () => {
    setTestListModalActive((current) => !current);
  };

  /** "Student List" 버튼 클릭 핸들러 (팝업 노출) */
  const handleSelectStudent = () => {
    setStudentListModalActive((current) => !current);
  };

  return (
    <>
      <S.Wrap>
        {tabState === 1 ? (
          <p className='tit'></p>
        ) : scorePageInfo?.takenTest == null || scorePageInfo.takenTest?.length < 1 ? (
          <p className='tit'>No available test</p>
        ) : (
          <p className='tit'>
            {scorePageInfo.testName}{' '}
            {/*, {moment(scorePageInfo?.startDate).format("MMM. DD, YYYY")} ~ {moment(scorePageInfo?.endDate).format("MMM. DD, YYYY")} */}
          </p>
        )}
        <button title='Open a Modal' onClick={tabState === 1 ? handleSelectStudent : handleSelectTestList}>
          {tabState === 1 ? 'Student List' : 'Test List'}
          <i className='svg_icon after icon_list_white'>&nbsp;</i>
        </button>
      </S.Wrap>
      {testListModalActive && (
        <PopTestList
          close={() => setTestListModalActive(false)}
          className={scorePageInfo.className}
          testTakenList={scorePageInfo?.takenTest}
          clickEvent={classAvgPoint}
        />
      )}
      {studentListModalActive && <StudentListModal setShowModal={setStudentListModalActive} />}
    </>
  );
}

const S = {};

S.Wrap = styled.div`
  flex: auto;
  display: flex;
  gap: 0.75rem;
  .tit {
    border: 2px solid #111111;
    border-radius: 0.5rem;
    background-color: #ffffff;
    font-weight: 600;
    font-size: 1.125rem;
    text-align: center;
    padding: 0.75rem 1rem;
    flex: auto;
  }
  button {
    font-weight: 600;
    font-size: 0.875rem;
    color: #ffffff;
    background-color: #111111;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-transform: uppercase;
  }
`;
