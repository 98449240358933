import {
  SET_STUDENT_ID,
  SET_STUDENT_LOGIN_TYPE,
  SET_STUDENT_USER_EMAIL,
  SET_STUDENT_USER_MOBILE,
  SET_STUDENT_AGREE_PRIVACY_POLICY,
  SET_STUDENT_AGREE_TERMS_SERVICE,
  SET_STUDENT_AGREE_NEWS_LETTER,
  SET_STUDENT_USER_NAME,
  CLEAR_STUDENT_STATE,
  SET_STUDENT_SCHOOL_GRADE,
  SET_STUDENT_SCHOOL_LOCATION,
  SET_STUDENT_SCHOOL_NAME,
  SET_STUDENT_SCHOOL_TYPE,
} from './student.actionTypes';

/** 리듀서 전역 상태 액션 : 학생 아이디 저장 */
export const updateReduxStudentId = (userId) => ({
  type: SET_STUDENT_ID,
  payload: userId,
});

/** 리듀서 전역 상태 액션 : 학생 로그인 타입 저장 */
export const updateReduxStudentLoginType = (loginType) => ({
  type: SET_STUDENT_LOGIN_TYPE,
  payload: loginType,
});

/** 학생 이메일 저장 */
export const updateReduxStudentUserEmail = (userEmail) => ({
  type: SET_STUDENT_USER_EMAIL,
  payload: userEmail,
});

/** 학생 전화번호 저장 */
export const updateReduxStudentUserMobile = (userMobile) => ({
  type: SET_STUDENT_USER_MOBILE,
  payload: userMobile,
});

/** 학생 이름 저장 */
export const updateReduxStudentUserName = (userName) => ({
  type: SET_STUDENT_USER_NAME,
  payload: userName,
});

/** 학생 개인정보처리방침 동의 저장 */
export const updateReduxStudentAgreePrivacyPolicy = (agree) => ({
  type: SET_STUDENT_AGREE_PRIVACY_POLICY,
  payload: agree,
});

/** 학생 서비스이용약관 동의 저장 */
export const updateReduxStudentAgreeTermsService = (agree) => ({
  type: SET_STUDENT_AGREE_TERMS_SERVICE,
  payload: agree,
});

/** 학생 뉴스레터 동의 저장 */
export const updateReduxStudentAgreeNewsLetter = (agree) => ({
  type: SET_STUDENT_AGREE_NEWS_LETTER,
  payload: agree,
});

/** 학생 학년 저장 */
export const updateReduxStudentSchoolGrade = (grade) => ({
  type: SET_STUDENT_SCHOOL_GRADE,
  payload: grade,
});

/** 학생 학교 위치 저장 */
export const updateReduxStudentSchoolLocation = (location) => ({
  type: SET_STUDENT_SCHOOL_LOCATION,
  payload: location,
});

/** 학생 학교 이름 저장 */
export const updateReduxStudentSchoolName = (name) => ({
  type: SET_STUDENT_SCHOOL_NAME,
  payload: name,
});

/** 학생 학교 유형 저장 */
export const updateReduxStudentSchoolType = (type) => ({
  type: SET_STUDENT_SCHOOL_TYPE,
  payload: type,
});

/** 학생 리듀서 전체 초기화 */
export const clearReduxStateStudent = () => {
  return {
    type: CLEAR_STUDENT_STATE,
  };
};
