import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { AP_PARAMS } from 'components/ap/_utils/constants/queryParamsString';

import ReviewFooter from './_components/layouts/footer/ReviewFooter';
import ReviewHeader from './_components/layouts/header/ReviewHeader';
import ApReviewQuestionView from './_components/questionView';
import CommonLoadingAnimation from 'components/_common/CommonLoadingAnimation';

/** ap/review 페이지 (학생, 아카데미 공통) 컴포넌트 */
export default function ApReviewPage() {
  const { getObfuscatedValue } = useObfuscatedSearchParam();

  /** 현재 선택 된 UTH 시퀀스 */
  const queryCurUthSeq = JSON.parse(getObfuscatedValue(AP_PARAMS.UTH_SEQ.KEY));
  /** 현재 선택 된 Section 시퀀스 */
  const queryCurSectionSeq = JSON.parse(getObfuscatedValue(AP_PARAMS.SECTION_SEQ.KEY));
  /** 현재 선택 된 Part 시퀀스 */
  const queryCurPartSeq = JSON.parse(getObfuscatedValue(AP_PARAMS.PART_SEQ.KEY));

  /** 로딩 상태 */
  const [isLoading, setIsLoading] = useState(false);
  /** "Mini Review" 영역 데이터 (UTH Seq 로 얻어옴) */
  const [miniReviewData, setMiniReviewData] = useState([]);
  /** 현재 선택 된 "Section" 이름 (UTH Seq, section Seq 로 얻어옴) */
  const [sectionName, setSectionName] = useState('');
  /** 현재 선택 된 "Part" 이름 (UTH Seq, section Seq, part Seq 로 얻어옴) */
  const [partName, setPartName] = useState('');
  /** 현재 선택 된 "Part"에 해당하는 문제 데이터 (UTH Seq, section Seq, part Seq 로 얻어옴) */
  const [questionData, setQuestionData] = useState([]);

  const questionViewRef = useRef();

  useEffect(() => {
    if (queryCurUthSeq) {
      /** 리뷰 창 밑에 맞았는지 나오는 화면에 필요한 데이터 얻어오기 API 요청 */
      const getMiniReviewData = ({ uthSeq }) => {
        if (!uthSeq || uthSeq === 'null' || uthSeq === 'undefined') return;
        setIsLoading(true);
        const successHandler = (response) => {
          if (response.code === 200) {
            const miniReviewDataArray = response.result.miniReview;
            setMiniReviewData(miniReviewDataArray);
          }
        };

        request
          .apGet(`/api/v1/academy/score/mini-review/${uthSeq}`, null, successHandler)
          .catch((error) => console.error(error))
          .finally(() => {
            setIsLoading(false);
          });
      };

      // 쿼리 파라미터로 전달 받은 UTH 시퀀스가 바뀔 때 (=페이지가 열릴 때) 딱 한 번 실행
      getMiniReviewData({ uthSeq: queryCurUthSeq });
    }
  }, [queryCurUthSeq]);

  useEffect(() => {
    if (queryCurSectionSeq && queryCurPartSeq && miniReviewData.length > 0) {
      miniReviewData.map((sectionItem, sectionIndex) => {
        if (Number(sectionItem.sectionSeq) === Number(queryCurSectionSeq)) {
          /** 현재 선택 된 Section 이름. 전달 받는 값이 없다면, 직접 조합하여 만든다 */
          const sectionName = sectionItem.sectionName
            ? sectionItem.sectionName
            : `section ${sectionItem.sectionNum ? sectionItem.sectionNum : sectionIndex + 1}`;

          setSectionName(sectionName);
          sectionItem.partData.find((partItem, partIndex) => {
            if (Number(partItem.partSeq) === Number(queryCurPartSeq)) {
              /** 현재 선택 된 Part 이름. 전달 받는 값이 없다면, 직접 조합하여 만든다 */
              const partName = partItem.partName ? partItem.partName : `part ${partItem.partNum ? partItem.partNum : partIndex + 1}`;

              setPartName(partName);
              setQuestionData(partItem.questionData);
            }
          });
        }
      });
    }
  }, [miniReviewData, queryCurSectionSeq, queryCurPartSeq]);

  return (
    <S.Wrap>
      <ReviewHeader isLoadingProps={isLoading} sectionName={sectionName} partName={partName} />
      <ApReviewQuestionView ref={questionViewRef} />
      <ReviewFooter isLoading={isLoading} questionData={questionData} />

      {isLoading && <CommonLoadingAnimation size='10rem' className='' />}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    background-color: #ffffff;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
};
