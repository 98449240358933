import React from 'react';
import styled from 'styled-components';

/** "회원 가입"페이지의 label 요소 컴포넌트 */
export default function Label({ children, ...rest }) {
  return <S.Label {...rest}>{children}</S.Label>;
}

const S = {};

S.Label = styled.label`
  display: block;
  text-transform: uppercase;
  text-align: left;
  padding: 1.25rem 0 0.5rem;
  color: #999999;
  font-size: 0.875rem;
`;
