import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

//component
import Header from 'components/user/_components/layouts/_Header';
import MainBox from 'components/user/_components/layouts/MainBox';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import Label from 'components/user/_components/common/forms/Label';
import Input from 'components/user/_components/common/forms/Input';
import ErrorText from 'components/user/_components/styles/ErrorText';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';

/** "이메일 찾기" 페이지 */
export default function FindEmailPage() {
  const navigate = useNavigate();

  /** 아이디 조회 결과, 못 찾았는지 여부 */
  const [isNotFound, setIsNotFound] = useState(false);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    setFocus,
  } = useForm({
    defaultValues: {
      userName: '',
      mobileNumber: '',
    },
    mode: 'onChange',
  });

  /** ID 찾기 API 요청 */
  const findId = (data) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let findEmail = response.result.userId;

        if (nvl(findEmail) === '') {
          // 못 찾았을 경우
          setIsNotFound(true);
        } else {
          // 입력 된 정보에 해당하는 아이디를 찾은 경우
          setIsNotFound(false);
          navigate('complete', { state: findEmail });
        }
      }
    };

    request.get(`/api/common/email/id?userName=${data.userName}&userMobile=${data.mobileNumber}`, null, successHandler);
  };

  /** Form 제출 핸들러 (react-hook-form 용) */
  const onValid = (data) => {
    findId(data);
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <S.Wrap>
      <MainBox>
        <Header>
          <p className='txt'>The door to educational aspiration</p>
        </Header>
        <section className='forgot-email-top-text-area'>
          <h4 className='tit'>Forgot your email?</h4>
          <p className='txt'>
            Type in your name and mobile number
            <br />
            to find your email address.
          </p>
        </section>
        <form onSubmit={handleSubmit(onValid)}>
          <FormItemWrap>
            <Label className='red-dot' htmlFor='userName'>
              Name
            </Label>
            <Input
              id='userName'
              type='text'
              register={register('userName', {
                required: 'Please type in your name.',
                onChange: (e) => {
                  clearErrors('userName');
                  setIsNotFound(false);
                },
              })}
              autoFocus
              isError={errors.userName || isNotFound}
            />
            {errors.userName && <ErrorText>{errors.userName.message}</ErrorText>}
            {isNotFound && <ErrorText>Your name was not found. Please try again</ErrorText>}
          </FormItemWrap>
          <FormItemWrap>
            <Label className='red-dot' htmlFor='mobileNumber'>
              Mobile Number
            </Label>
            <Input
              id='mobileNumber'
              type='tel'
              register={register('mobileNumber', {
                required: 'Please type in your mobile number.',
                onChange: (e) => {
                  clearErrors('mobileNumber');
                  setIsNotFound(false);
                },
              })}
              isError={errors.mobileNumber || isNotFound}
            />
            {errors.mobileNumber && <ErrorText>{errors.mobileNumber.message}</ErrorText>}
            {isNotFound && <ErrorText>Your mobile number was not found. Please try again</ErrorText>}
          </FormItemWrap>
          <PrimaryButtonArea>
            <PrimaryButton type='submit' title='제출'>
              Submit
            </PrimaryButton>
          </PrimaryButtonArea>
        </form>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .forgot-email-top-text-area {
    text-align: left;
    color: #7f8998;
    padding-bottom: 1.5rem;
    .tit {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .txt {
      margin-top: 0.5rem;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
  .red-dot {
    padding-left: 0.65rem;
    ::before {
      content: '*';
      color: var(--sub-red);
      margin-top: 2px;
      margin-left: -10px;
      position: absolute;
    }
  }
`;
