import useUserLevels from 'hooks/useUserLevels';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import request from 'utils/Request.utils';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';

/** 각 메인별 이동 버튼 */
export default function MainLink() {
  const { pathname } = window.location;
  const location = useLocation();
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);

  /** 현재 경로에 따라 활성화 시킬 Tab에 className을 부여하는 함수 */
  const isSelected = (type) => (location.pathname.includes(type) ? 'active' : '');

  const isAp = location.pathname.includes('/ap/');

  const [apUrl, setApUrl] = useState('');

  useEffect(() => {
    setApUrl(isAp ? '/ap' : '');
  }, [isAp]);

  return (
    <S.Wrap className='com_main_link'>
      <Link to={isAp ? '/ap/com/students' : apUrl + '/com/students'} className={`link students ${isSelected('students')} `}>
        <i className='icon'>&nbsp;</i>
        <p className='tit'>STUDENTS</p>
        <p className='txt'>Manage student settings</p>
      </Link>
      {useLevels.isTubletAdmin && (
        <Link to={apUrl + '/tublet/tutor'} className={`link tutors ${isSelected('tutor')}`}>
          <i className='icon'>&nbsp;</i>
          <p className='tit'>TUTORS</p>
          <p className='txt'>Manage tutor settings</p>
        </Link>
      )}
      <Link to={isAp ? '/ap/com/tests/scheduled' : apUrl + '/com/tests/scheduled'} className={`link test ${isSelected('tests')}`}>
        <i className='icon'>&nbsp;</i>
        <p className='tit'>TEST</p>
        <p className='txt'>Manage test settings</p>
      </Link>
      {/* 클릭시 기본 초기값으로 호출 */}
      <Link
        to={isAp ? `/ap/com/score/${obfuscateUrlParam('0')}` : `/com/score/class/${obfuscateUrlParam('0')}`}
        className={`link score ${isSelected('score')}`}>
        <i className='icon'>&nbsp;</i>
        <p className='tit'>SCORE</p>
        <p className='txt'>See test reports</p>
      </Link>
      {pathname.includes('/ap/') && (
        <Link to={isAp ? '/ap/question-bank' : ``} className={`link question_bank ${isSelected('question-bank')}`}>
          <i className='icon'>&nbsp;</i>
          <p className='tit'>Question Bank</p>
          <p className='txt'>Create your test</p>
        </Link>
      )}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    &.com_main_link {
      width: 1194px;
      margin: auto;
      padding-bottom: 3.125rem;
      justify-content: center;
      & > * {
        flex: 0 1 auto;
      }
    }
  `,
};
