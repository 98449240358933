import { useState } from 'react';
import EditModalCalendar from 'components/com/tests/scheduled/_components/EditModalCalendar';

/** 팝업 달력 컴포넌트 */
export default function PopupCalendarPeriod({ setSearchInfo, tit, size }) {
  /** 달력 팝업 View - State */
  const [isShowCalendar, setIsShowCalendar] = useState(false);

  const [selectedDateState, setSelectedDateState] = useState([]);

  const handleSelectDate = (dateArr) => {
    setSelectedDateState(dateArr);
    if (dateArr[1] !== null) {
      updateDates(dateArr);
    }
  };

  /** 선택된 날짜 ([시작일, 종료일]) 범위 업데이트 및 검색 정보 설정 */
  const updateDates = ([start, end]) => {
    // 검색 정보 설정
    setSearchInfo((prev) => {
      return {
        ...prev,
        isDateRangeSelected: true,
        searchStartDate: start.startOf('day').format(),
        searchEndDate: end.endOf('day').format(),
      };
    });

    setIsShowCalendar(false);
  };

  return (
    <div className='com_calendar_wrap'>
      <input
        type='text'
        className='input datepicker search_area_input'
        onClick={() => setIsShowCalendar(true)}
        readOnly
        placeholder={tit}
        value={
          selectedDateState.length === 2 && selectedDateState[1] !== null
            ? `${selectedDateState[0].format('MM.DD.YYYY')} ~ ${selectedDateState[1].format('MM.DD.YYYY')}`
            : ''
        }
        size={size}
      />
      {isShowCalendar && (
        <div className='PopupCalendar'>
          <div className='PopupCalendar_header'>
            <button type='button' className='svg_icon btn_pop_close black' onClick={() => setIsShowCalendar(false)}>
              &nbsp;
            </button>
          </div>

          <EditModalCalendar selectedDateState={selectedDateState} setSelectedDateState={handleSelectDate} />
        </div>
      )}
    </div>
  );
}
