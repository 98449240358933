import { useState, useRef } from 'react';
import styled from 'styled-components';

import useOutsideClick from 'hooks/useOutsideClick';
import QuestionSettingPopup from './SourceSettingPopup/QuestionSettingPopup';

/** QuestionSetting Popup Section 컴포넌트 (팝업 열기 버튼 클릭 시 팝업 열기) */
export default function QuestionSettingPopupSection() {
  // Question Setting 팝업 State
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleClickPopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const popupWrap = useRef(null);
  useOutsideClick(popupWrap, () => setIsPopupOpen(false));

  return (
    <S.PopupSectionContainer ref={popupWrap}>
      <S.QuestionSettingPopupBtn onClick={handleClickPopup}>팝업 열기</S.QuestionSettingPopupBtn>
      <QuestionSettingPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
    </S.PopupSectionContainer>
  );
}

const S = {
  PopupSectionContainer: styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
  `,

  QuestionSettingPopupBtn: styled.button`
    padding: 10px 20px;
    border-radius: 10px;
    border: 4px solid #d7dadf;
  `,
};
