import React, { useEffect } from 'react';
import request from 'utils/Request.utils';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SignUpFirstTimeSignIn from 'components/user/sign-up/_components/pages/SignUpFirstTimeSignIn';
import { updateReduxJoinCompanyConfirmCode } from 'reducers/auth/joincompany.action';

/** "회원가입 - 소셜 이메일 가입 - 아카데미 관리자 - 첫 번째 로그인" 페이지 */
export default function SignUpSocialAcademyAdminFirstTimeSignInPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const joincompany = useSelector((state) => state.joinCompanyReducer);
  const userLevel = joincompany.userLevel;
  console.log('🚀 ~ SignUpSocialAcademyAdminFirstTimeSignInPage ~ joincompany:', joincompany);
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {},
  });
  // const headerTitle = userLevel === '001001002000000' ? 'EDUCATOR' : 'EDUCATOR';
  const headerTitle = 'EDUCATOR';

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    console.log('🚀 ~ onValid ~ data:', data);
    confirm_academycode(data.confireCode);
  };
  const confirm_academycode = (code) => {
    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);
      if (response.result.count > 0) {
        dispatch(updateReduxJoinCompanyConfirmCode(code));
        navigate('/signup/social/tutor/timezone');
      } else {
        setError('confireCode', { type: 'validate', message: 'Code is invalid' });
      }
    };
    request.get(request.apiUris.auth.academyUniqueCode, { isNew: true, uniqueCode: code }, successHandler);
  };

  const duplicatedMobile = (mobile) => {};

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <SignUpFirstTimeSignIn headerTitle={headerTitle} handleSubmit={handleSubmit} onValid={onValid} register={register} errors={errors}>
      To complete your sign in as an Educator, you must enter your Academy Code or Tutor Code. Please type in the code from your email.
    </SignUpFirstTimeSignIn>
  );
}
