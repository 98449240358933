import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS } from 'utils/constants';
import request from 'utils/Request.utils.js';
import { deobfuscateUrlParam, obfuscateUrlParam } from 'utils/urlParamObfuscator';

//components
//chart
import CommonLoadingAnimation from 'components/_common/CommonLoadingAnimation';
import ApScoreSection from 'components/ap/_components/sections/apScoreSection/ApScoreSection';
import IndividualQuestionResultsSection from 'components/ap/_components/sections/individualQuestionResultsSection/IndividualQuestionResultsSection';
import RowScoreSection from 'components/ap/_components/sections/rowScoreSection/RowScoreSection';
import { AP_PARAMS } from 'components/ap/_utils/constants/queryParamsString';
import BtoBMainTabFrameHOC from 'layout/commonFrame/BtoBMainTabFrameHOC';
import OuterBox from './_components/common/OuterBox';
import SelectDetailSection from './_components/layouts/main-top-area/SelectDetailSection';
import MainTopArea from './_components/layouts/old-main-top-area/MainTopArea';
import Profile from './_components/sections/profile/Profile';
import TestList from './_components/sections/testList/TestList';

/**
 * ap/com/score/ 페이지
 */
export default function ApScorePage() {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const navigate = useNavigate();
  /** 현재 studentSeq - Route Parameter */
  const { studentSeq: encStudentSeq } = useParams();
  const studentSeq = deobfuscateUrlParam(encStudentSeq);

  const { getObfuscatedValue, setBulkObfuscatedValues } = useObfuscatedSearchParam();

  /** 현재 선택 된 UTH 시퀀스 */
  const queryCurUthSeq = getObfuscatedValue(SCORE_PARAMS.CUR_UTH);
  /** 현재 선택 된 Section 시퀀스 */
  const queryCurSectionSeq = getObfuscatedValue(AP_PARAMS.SECTION_SEQ.KEY);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  // 학생 정보 List
  const [apStudentList, setApStudentList] = useState([
    {
      userSeq: 0, // number         => 학생 seq
      userName: '', // string       => 학생 이름
      userEmail: '', // string      => 학생 이메일
      studentSchool: '', // string  => 학생 학교
      // 학생이 속한 반 리스트
      classList: [
        {
          classSeq: 0, // number    => 학생 반 seq
          className: '', // string  => 학생 반 이름
        },
      ],
      // 학생이 치른 과목 리스트
      subjectList: [
        {
          subjectSeq: 0, // number    => 학생 과목 seq
          subjectName: '', // string  => 학생 과목 이름
        },
      ],
    },
  ]);
  // 선택된 단일 학생의 데이터
  const [selectedStudent, setSelectedStudent] = useState({
    userSeq: 0, // number         => 학생 seq
    userName: '', // string       => 학생 이름
    userEmail: '', // string      => 학생 이메일
    studentSchool: '', // string  => 학생 학교
    // 학생이 속한 반 리스트
    classList: [
      {
        classSeq: 0, // number    => 학생 반 seq
        className: '', // string  => 학생 반 이름
      },
    ],
    // 학생이 치른 과목 리스트
    subjectList: [
      {
        subjectSeq: 0, // number    => 학생 과목 seq
        subjectName: '', // string  => 학생 과목 이름
      },
    ],
  });
  // 선택된 단일 학생이 본 시험 리스트
  const [selectedStudentTestList, setSelectedStudentTestList] = useState([
    {
      testSetSeq: 0, // number         => 시험 세트 seq
      uthSeq: 0, // number             => 해당 시험 기록 seq
      classSeq: 0, // number           => 시험을 치른 반 seq
      className: '', // string         => 시험을 치른 반 이름
      testSetName: '', // string       => 시험 세트 이름
      subjectName: '', // string       => 시험 세트 과목 이름
      score: 0, // number              => 시험 세트 점수
      testTakenEndDate: '', // string  => 시험 세트 치른 날짜
      isCompleted: false, // boolean   => 시험 세트 완료 여부
    },
  ]);

  /** 현재 선택 된 시험에 해당하는 점수 데이터 상태 */
  const [currentTestRowScoreList, setCurrentTestRowScoreList] = useState([]);
  /** 현재 선택 된 시험에 해당하는 Section / Part 이름과 시퀀스 목록 상태 */
  const [currentTestSectionAndPartList, setCurrentTestSectionAndPartList] = useState([]);
  /** 현재 선택 된 시험의 Section / Part 에 해당하는 시험 결과 데이터 목록 상태 */
  const [currentIndividualQuestionResults, setCurrentIndividualQuestionResults] = useState([]);
  /** 로딩 상태 */
  const [isLoading, setIsLoading] = useState(false);
  /** 시험 리뷰 가능 기간 */
  const [reviewPeriodDate, setReviewPeriodDate] = useState(null);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** 선택된 학생이 본 시험 리스트 받아오는 API - 인자를 못받아오는 경우 현재 선택된 학생의 시퀀스를 사용 */
  const getStudentTestList = (selectedStudentSeq) => {
    setIsLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        const fetchedTestList = response.result.testList;
        setSelectedStudentTestList(fetchedTestList);

        // 학생이 본 시험이 없을때 - Section / Part 리스트 초기화
        if (fetchedTestList.filter((item) => item.testStatus === 'P').length === 0) {
          setCurrentTestRowScoreList([]);
          setCurrentTestSectionAndPartList([]);
        }
      }
    };

    request
      .apGet(`/api/v1/academy/score?studentSeq=${selectedStudentSeq}`, null, successHandler)
      .catch((error) => console.error('ap/com/score/:studentSeq - "/api/v1/academy/score?studentSeq={}" API 응답 에러', error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  /** "Individual Question Results" 불러오기 API 요청 */
  const getIndividualQuestionResults = ({ queryCurUthSeq, queryCurPartSeq }) => {
    if (
      !queryCurUthSeq ||
      queryCurUthSeq === 'null' ||
      queryCurUthSeq === 'undefined' ||
      !queryCurPartSeq ||
      queryCurPartSeq === 'null' ||
      queryCurPartSeq === 'undefined'
    )
      return;

    setIsLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        const reportSectionData = response.result.reportSectionData;
        setCurrentIndividualQuestionResults(reportSectionData);
      }
    };

    const apiUrl = `/api/v1/academy/score/part/${queryCurPartSeq}/test/${queryCurUthSeq}`;

    request
      .apGet(apiUrl, null, successHandler)
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  // 초기 진입시 학생 데이터 세팅
  useEffect(() => {
    /** AP 학생 List 받아오는 API */
    const getApStudentList = () => {
      setIsLoading(true);
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentList = response.result.studentList;

          setApStudentList(fetchedStudentList);

          // 세팅된 학생이 없을때 - 첫번째 학생을 선택하고 해당 학생의 시퀀스를 route 파라미터 설정
          if (studentSeq === '0' || selectedStudent.userSeq === 0) {
            setSelectedStudent(fetchedStudentList[0]);
            navigate(`/ap/com/score/${obfuscateUrlParam(fetchedStudentList[0]?.userSeq ?? 0)}`, { replace: true }); // 유효한 학생 시퀀스가 없을 경우 0을 사용
          }
        }
      };

      request
        .apGet(`/api/v1/academy/score/student-list`, null, successHandler)
        .catch((error) => console.error('ap/com/score/:studentSeq - "/api/v1/academy/score/student-list" API 응답 에러', error))
        .finally(() => {
          setIsLoading(false);
        });
    };

    getApStudentList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (studentSeq !== '0' || studentSeq > 0) {
      getStudentTestList(studentSeq);

      setSelectedStudent(apStudentList.find((student) => student.userSeq === Number(studentSeq)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentSeq]);

  useEffect(() => {
    /** 선택 된 시험에 해당하는 점수 데이터 가져오기 */
    const getTestScore = ({ queryCurUthSeq }) => {
      if (!queryCurUthSeq || queryCurUthSeq === 'null' || queryCurUthSeq === 'undefined') {
        return;
      }
      setIsLoading(true);
      const successHandler = (response) => {
        if (response.code === 200) {
          const scores = response.result.score;

          setCurrentTestRowScoreList(scores);
        }
      };
      request
        .apGet(`/api/v1/academy/score/${queryCurUthSeq}`, null, successHandler)
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
        });
    };

    /** 선택 된 시험에 해당 존재하는 Section / Part 이름과 시퀀스 목록 가져오기 */
    const getTestParts = ({ queryCurUthSeq }) => {
      if (!queryCurUthSeq || queryCurUthSeq === 'null' || queryCurUthSeq === 'undefined') return;
      setIsLoading(true);
      const successHandler = (response) => {
        if (response.code === 200) {
          const data = response.result.sectionData;
          const reviewPeriod = response.result?.reviewPeriod;

          setReviewPeriodDate(reviewPeriod);

          setCurrentTestSectionAndPartList(data);
          setBulkObfuscatedValues({
            paramValues: {
              [SCORE_PARAMS.CUR_UTH]: queryCurUthSeq,
              [AP_PARAMS.SECTION_SEQ.KEY]: data[0].sectionSeq,
              [AP_PARAMS.PART_SEQ.KEY]: data[0].partData[0].partSeq,
            },
          });
        }
      };

      request
        .apGet(`/api/v1/academy/score/part/${queryCurUthSeq}`, null, successHandler)
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (queryCurUthSeq && queryCurUthSeq !== '0') {
      getTestScore({ queryCurUthSeq });
      getTestParts({ queryCurUthSeq });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCurUthSeq]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <BtoBMainTabFrameHOC>
      <S.Warp className='p_score_main'>
        <OuterBox className='main_wrap'>
          <MainTopArea>
            <SelectDetailSection variant='student' dataList={apStudentList} isModalActive={false} />
          </MainTopArea>
          <div className='flex_row'>
            <Profile studentInfo={selectedStudent} />
            <TestList selectedStudentTestList={selectedStudentTestList} handleClickTestCallback={getIndividualQuestionResults} />
          </div>
          {currentTestRowScoreList && currentTestRowScoreList.length > 0 ? (
            <div className='flex_row'>
              <RowScoreSection scoreListData={currentTestRowScoreList} />
              <ApScoreSection score={currentTestRowScoreList[0].scaledScore} />
            </div>
          ) : (
            ''
          )}
          {currentTestSectionAndPartList && currentTestSectionAndPartList.length > 0 ? (
            <IndividualQuestionResultsSection
              isAcademy
              sectionAndPartList={currentTestSectionAndPartList}
              currentIndividualQuestionResults={currentIndividualQuestionResults}
              selectCallback={getIndividualQuestionResults}
              isLoading={isLoading}
              reviewPeriodDate={reviewPeriodDate}
            />
          ) : (
            ''
          )}
        </OuterBox>

        {isLoading && <CommonLoadingAnimation size='10rem' className='' />}
      </S.Warp>
    </BtoBMainTabFrameHOC>
  );
}

const S = {
  Warp: styled.div`
    .main_wrap {
      width: 1194px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .flex_row {
      display: flex;
      gap: 0.75rem;
    }
  `,
};
