import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImageSvgIconHelp } from 'assets/img/svg/icon/icon_help_circle_01.svg';
import ApExamHeaderBase from 'components/ap/_components/examLayout/examHeader/ApExamHeaderBase';

import DirectionsPopupBtn from 'components/ap/_components/examLayout/examHeader/_components/directionsPopup/DirectionsPopupBtn';
import { EDITOR_CONSTANT } from '../_constant';

/** ap/question-bank/test-editor 에서 사용될 header */
export default function TestEditorHeader({ editorState, currentQuestion, handlePostApTestData, handlePublish, handleSaveAllContent }) {
  const [isShowDirectionsPopup, setIsShowDirectionsPopup] = useState(false);

  const handleClickPreview = () => {
    handleSaveAllContent();
    window.open('/ap/question-bank/test-preview', '_blank');
  };

  const leftSection = (
    <S.LeftArea>
      <p className='module_title'>
        {editorState[EDITOR_CONSTANT.currentSection].section_name} : {editorState[EDITOR_CONSTANT.currentPart].name}
      </p>
      <DirectionsPopupBtn isShowDirectionsPopup={isShowDirectionsPopup} setIsShowDirectionsPopup={setIsShowDirectionsPopup} />
    </S.LeftArea>
  );

  const centerSection = (
    <S.CenterArea>
      <p className='test_name'>{currentQuestion.test_name}</p>
      <p className='subject_name'>{currentQuestion.subject_name}</p>
    </S.CenterArea>
  );

  const rightSection = (
    <S.RightArea>
      <button type='button' title='' className='button btn_sub' onClick={handleClickPreview}>
        Preview
      </button>
      <button type='button' title='' className='button btn_sub' onClick={handlePostApTestData}>
        Save
      </button>
      <button type='button' title='' className='button btn_primary' onClick={handlePublish}>
        Publish
      </button>
      <button className='help_button'>
        <ImageSvgIconHelp />
      </button>
    </S.RightArea>
  );

  return <ApExamHeaderBase leftSection={leftSection} centerSection={centerSection} rightSection={rightSection} />;
}

const S = {
  LeftArea: styled.div`
    padding-left: 4.375rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

    .module_title {
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.375rem;
    }
  `,

  CenterArea: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    text-align: center;
    .test_name {
      font-size: 1.375rem;
      font-weight: 600;
    }
    .subject_name {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.75rem;
    }
  `,

  RightArea: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1.25rem;
    padding-right: 3.875rem;

    .button {
      font-size: 1.125rem;
      font-weight: 500;
      padding: 0.5rem 1.25rem;
      border-radius: 6.25rem;
      cursor: pointer;
      line-height: 1.75rem;
    }

    .btn_primary {
      background-color: #ffde88;
    }
    .btn_sub {
      border: 1px solid #767676;
    }

    .help_button {
      color: #111111;
      cursor: pointer;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  `,
};
