import React, { useEffect, useRef, useState } from 'react';
//component
import Header from 'components/user/_Header';
import PopBack from 'components/user/_PopBack';
// Image
import request from 'utils/Request.utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { nvl, validateEmail } from 'utils/Common.utils';
import LoadingBar from 'utils/LoadingBar';

import { USER_LEVELS } from 'utils/constants';

const Join = () => {
  useEffect(() => {
    document.body.classList.add('gate_member', 'signup_form'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('gate_member', 'signup_form'); //다른 페이지에선 removeClass
    };
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isBack, setBack] = useState(false);

  const [signupInfo, setSignupInfo] = useState({
    email: true,
    duplicateEmail: false,
    password: true,
    confirmPassword: true,
    passwordEight: false,
    passwordSpecial: false,
    passwordMismatch: false,
    name: true,
    mobile: true,
    mobileDuplicate: false,
    reqCheckbox: false,
    marketingCheckbox: true,
    letterCheckbox: true,
  });

  const emailRef = useRef('');
  const passwordRef = useRef('');
  const confirmPasswordRef = useRef('');
  const nameRef = useRef('');
  const mobile1Ref = useRef('');
  const mobile2Ref = useRef('');
  const mobile3Ref = useRef('');

  const nextBtnClick = () => {
    let allPassed = true;

    if (nvl(mobile1Ref.current.value) === '' && nvl(mobile2Ref.current.value) === '' && nvl(mobile3Ref.current.value) === '') {
      setSignupInfo((prev) => {
        return { ...prev, mobile: false };
      });
      allPassed = false;
      mobile1Ref.current.focus();
    } else
      setSignupInfo((prev) => {
        return { ...prev, mobile: true };
      });

    if (nvl(nameRef.current.value) === '') {
      setSignupInfo((prev) => {
        return { ...prev, name: false };
      });
      allPassed = false;
      nameRef.current.focus();
    } else
      setSignupInfo((prev) => {
        return { ...prev, name: true };
      });

    if (!validateEmail(nvl(emailRef.current.value))) {
      setSignupInfo((prev) => {
        return { ...prev, email: false };
      });
      allPassed = false;
      emailRef.current.focus();
    } else
      setSignupInfo((prev) => {
        return { ...prev, email: true };
      });

    passwordCheck();

    if (passwordRef.current.value.length < 8 || !signupInfo.password || !signupInfo.confirmPassword) {
      allPassed = false;
    }

    if (!signupInfo.reqCheckbox) allPassed = false;

    if (allPassed) {
      dispatch({
        type: 'setCustomer',
        payload: { fieldName: 'userName', data: nameRef.current.value },
      });
      dispatch({
        type: 'setCustomer',
        payload: { fieldName: 'userEmail', data: emailRef.current.value },
      });
      dispatch({
        type: 'setCustomer',
        payload: { fieldName: 'userPasswd', data: passwordRef.current.value },
      });
      dispatch({
        type: 'setCustomer',
        payload: {
          fieldName: 'userMobile',
          data: mobile1Ref.current.value + '-' + mobile2Ref.current.value + '-' + mobile3Ref.current.value,
        },
      });
      dispatch({
        type: 'setCustomer',
        payload: {
          fieldName: 'mailYn',
          data: signupInfo.letterCheckbox ? 'Y' : 'N',
        },
      });
      dispatch({
        type: 'setCustomer',
        payload: {
          fieldName: 'marketingYn',
          data: signupInfo.marketingCheckbox ? 'Y' : 'N',
        },
      });

      dispatch({
        type: 'setCustomer',
        payload: { fieldName: 'userLevel', data: USER_LEVELS.PARENT },
      });

      checkEmailDuplicate();
    }
  };

  const passwordCheck = () => {
    if (nvl(passwordRef.current.value).length >= 8) {
      setSignupInfo((prev) => {
        return { ...prev, password: true, passwordEight: true };
      });
    } else {
      setSignupInfo((prev) => {
        return { ...prev, password: false, passwordEight: false };
      });
    }
    const patternSpecial = /[\W_]/;
    const patternNum = /[0-9]/;
    if (patternSpecial.test(nvl(passwordRef.current.value)) && patternNum.test(nvl(passwordRef.current.value))) {
      setSignupInfo((prev) => {
        return { ...prev, password: true, passwordSpecial: true };
      });
    } else {
      setSignupInfo((prev) => {
        return { ...prev, password: false, passwordSpecial: false };
      });
    }
    if (nvl(passwordRef.current.value) === nvl(confirmPasswordRef.current.value) && nvl(confirmPasswordRef.current.value).length !== 0) {
      setSignupInfo((prev) => {
        return { ...prev, confirmPassword: true, passwordMismatch: true };
      });
    } else {
      setSignupInfo((prev) => {
        return { ...prev, confirmPassword: false, passwordMismatch: false };
      });
    }
  };
  const phoneNumberCheck = (event, maxLength) => {
    const inputValue = event.target.value;

    event.target.value = inputValue.replace(/[^0-9]/g, '');

    if (inputValue.length > maxLength) {
      event.target.value = inputValue.slice(0, maxLength);
    }
  };

  const reqCheckbox = (e) => {
    setSignupInfo((prev) => {
      return { ...prev, reqCheckbox: e.target.checked };
    });
  };
  const marketingCheckbox = (e) => {
    setSignupInfo((prev) => {
      return { ...prev, marketingCheckbox: e.target.checked };
    });
  };
  const letterCheckbox = (e) => {
    setSignupInfo((prev) => {
      return { ...prev, letterCheckbox: e.target.checked };
    });
  };

  // 중복 체크
  const checkEmailDuplicate = () => {
    setLoading(true);
    let params = {
      userEmail: emailRef.current.value,
      mobile: mobile1Ref.current.value + mobile2Ref.current.value + mobile3Ref.current.value,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let emailDuplicate = response.result.idDuplicate;
        let mobileDuplicate = response.result.mobileDuplicate;

        if (emailDuplicate || mobileDuplicate) {
          setSignupInfo((prev) => {
            return {
              ...prev,
              email: !emailDuplicate,
              mobile: !mobileDuplicate,
              duplicateEmail: emailDuplicate,
              mobileDuplicate: mobileDuplicate,
            };
          });
          if (emailDuplicate) emailRef.current.focus();
          else if (mobileDuplicate) mobile1Ref.current.focus();
          setLoading(false);
        } else {
          sendMail();
        }
      }
    };

    request.get(`/api/common/email/duplicate?userEmail=${params.userEmail}&userMobile=${params.mobile}`, null, successHandler);
  };

  const sendMail = () => {
    let params = {
      userEmail: emailRef.current.value,
      userName: nameRef.current.value,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        navigate('/signup/academy/confirm');
        setLoading(false);
      }
    };

    request.post('/api/common/email', params, successHandler);
  };

  return (
    <>
      <div className='container'>
        <Header>
          <p className='sub_title'>BECOME A GATE +</p>
          <h1>PARENT</h1>
          <hr className='divider' />
        </Header>
        <section className='form'>
          {/* 이메일 */}
          <article className={`input_wrap ${signupInfo.email ? '' : 'error'}`}>
            {/*틀릴 경우 addClass error*/}
            <div className='email_form'>
              <h2 className='form_title'>
                <span className='essential'>*</span>
                <span>EMAIL</span>
              </h2>
              <div className='box input_email'>
                <input className='sign_up_input' type='text' ref={emailRef} />
              </div>
              <p className='error_message'>
                {signupInfo.duplicateEmail ? 'The email is already in use. Please use another email.' : 'Email format is not acceptable. Please check again.'}
              </p>
            </div>
          </article>
          {/* 비밀번호 */}
          <article className={`input_wrap ${signupInfo.password ? '' : 'error'}`}>
            <h2 className='form_title'>
              <span className='essential'>*</span>
              <span>PASSWORD</span>
            </h2>
            <div className='box input_pass '>
              <input className='sign_up_input' type='password' ref={passwordRef} onChange={passwordCheck} />
            </div>
          </article>
          {/* 비밀번호 확인 */}
          <article className={`input_wrap ${signupInfo.confirmPassword ? '' : 'error'}`}>
            <h2 className='form_title'>
              <span className='essential'>*</span>
              <span>CONFIRM PASSWORD</span>
            </h2>
            <div className='box input_pass_confirm '>
              <input className='sign_up_input' type='password' ref={confirmPasswordRef} onChange={passwordCheck} />
            </div>
            <div className='com_password_message'>
              <p className={`check ${signupInfo.passwordEight ? 'valid' : ''}`} id='lengthMessage'>
                {/*유효할경우 addClass valid*/}
                Must be over 8 characters
              </p>
              <p className={`check ${signupInfo.passwordSpecial ? 'valid' : ''}`} id='characterMessage'>
                Must have a number and a special character (!, @ , ?)
              </p>
              <p className={`check ${signupInfo.passwordMismatch ? 'valid' : ''}`} id='matchMessage'>
                Passwords must match
              </p>
            </div>
          </article>
          {/* 이름 입력 */}
          <article className={`input_wrap ${signupInfo.name ? '' : 'error'}`}>
            <h2 className='form_title'>
              <span className='essential'>*</span>
              <span>NAME</span>
            </h2>
            <div className='box input_name '>
              <input className='sign_up_input' type='name' ref={nameRef} />
            </div>
            <p className='error_message'>Please type in your full name</p>
          </article>
          {/* 전화번호 */}
          <article className={`input_wrap ${signupInfo.mobile ? '' : 'error'}`}>
            <h2 className='form_title'>
              <span className='essential'>*</span>
              <span>MOBILE PHONE NO.</span>
            </h2>
            <div className='phone'>
              <div className='box input_phone'>
                <input className='sign_up_input' type='tel' ref={mobile1Ref} onChange={(event) => phoneNumberCheck(event, 3)} />
              </div>
              <span className='hyphen'>-</span>
              <div className='box input_phone'>
                <input className='sign_up_input' type='tel' ref={mobile2Ref} onChange={(event) => phoneNumberCheck(event, 4)} />
              </div>
              <span className='hyphen'>-</span>
              <div className='box input_phone'>
                <input className='sign_up_input' type='tel' ref={mobile3Ref} onChange={(event) => phoneNumberCheck(event, 4)} />
              </div>
            </div>
            <p className='error_message'>
              {signupInfo.mobileDuplicate ? 'The phone number is already in use. Please use another phone number.' : 'Please type in a phone number.'}
            </p>
            <p className='tip'>Your mobile number will be used to identify you</p>
          </article>
          {/* 체크박스 */}
          <label className='custom_checkbox'>
            <input id='agreeCheckbox' type='checkbox' onChange={(e) => reqCheckbox(e)} />
            <span className='checkbox_icon' />
            <div className='agree'>
              I have read and agree to Gate⁺
              <a
                className='btn_txt'
                href='https://gateplus.notion.site/Privacy-policy-460855e375544490ba01ca7d24404dd3'
                target='_blank'
                rel='noopener noreferrer'>
                Privacy Policy
              </a>{' '}
              and
              <br />
              <a
                className='btn_txt'
                href='https://gateplus.notion.site/Terms-of-Service-5a16766471974bb0b51f63a9f983dc13'
                target='_blank'
                rel='noopener noreferrer'>
                Terms of Service
              </a>
              (Mandatory)
            </div>
          </label>
          <label className='custom_checkbox'>
            <input id='marketingCheckbox' type='checkbox' checked={signupInfo.marketingCheckbox} onChange={(e) => marketingCheckbox(e)} />
            <span className='checkbox_icon' />
            <div className='agree'>
              I agree to receive{' '}
              <a
                className='btn_txt'
                href='https://gateplus.notion.site/Marketing-Consent-f0623a824e9a4c7a8de7b4c4639f4369'
                target='_blank'
                rel='noopener noreferrer'>
                marketing communications
              </a>{' '}
              from Gate⁺
              <br />
              (optional)
            </div>
          </label>
          <label className='custom_checkbox'>
            <input id='newsletterCheckbox' type='checkbox' className='checkbox' checked={signupInfo.letterCheckbox} onChange={(e) => letterCheckbox(e)} />
            <span className='checkbox_icon' />
            <span className='agree'>I want to receive Gate⁺ Newsletter (Optional)</span>
          </label>
        </section>
        <section className='btns'>
          <button className='btn btn_stroke btn_big ' onClick={() => setBack(true)}>
            Back
          </button>
          <button id='nextBtn' className={`btn btn_fill btn_big ${signupInfo.reqCheckbox ? '' : 'gray'}`} onClick={() => nextBtnClick()}>
            Next
          </button>
        </section>
      </div>
      {/*뒤로가기 클릭 시 팝업*/}
      {isBack && <PopBack closePopBack={() => setBack(false)} />}
      {isLoading && <LoadingBar />}
    </>
  );
};

export default Join;
