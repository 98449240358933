import { useEffect } from 'react';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import SourcesForReview from 'components/ap/com/ap-components/_components/SourcesForReview';
import TestEditComBtn from 'components/ap/com/ap-components/_components/TestEditComBtn';
import ApExamFooter from '../_components/layout/ApExamFooter';
import AnswerChoiceList from './_components/AnswerChoiceList';
import QuestionEditor from './_components/QuestionEditor';
import SourceContainer from './_components/SourceContainer';
import SourceDirectionArea from './_components/SourceDirectionArea';
import TestEditorHeader from './_components/TestEditorHeader';
//#region    ///// UI components
import UI_EditorContainer from './_components/ui/UI_EditorContainer';
import UI_ExamContainer from './_components/ui/UI_ExamContainer';
//#endregion ///// UI components
//#endregion ////////// components

//#region    ////////// utils & hooks
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { transformChoicesToFrontend } from './_utils';
import useTestEditor from './useTestEditor';
//#endregion ////////// utils & hooks

//#region    ////////// constants
import { EDITOR_CONSTANT, QUESTION_TYPE_CONSTANT } from './_constant';
//#endregion ////////// constants

/** QuestionBank에서 Test를 Edit 하는 페이지 */
export default function QuestionBankTestEditorPage() {
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryTestSeq = getObfuscatedValue('test_seq');
  const queryTestStatus = getObfuscatedValue('status');

  const {
    apReduxState,
    formatQuestionForSubmit,
    //
    getTestMetadata,
    getEditingTestData,
    postApTestData,
    // State
    editorState,
    // State handler
    editorStateSetter,
    editorStateUpdater,
    editorStateMutator,
    // helper functions
    handleSaveAllContent,
    getCurrentPartQuestion,
    validateQuestionsCount,
    validatePartScores,
    copyPreviousSource,
    handleQuestionNavigation,
    validateCorrectAnswer,
    isNavigating,
  } = useTestEditor();

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** "현재 Source" 변경 */
  const moveCurrentSource = ({ order = 0 }) => {
    editorStateSetter(EDITOR_CONSTANT.currentSourceOrder, order);
  };
  /** 테스트 서버 저장 */
  const handlePostApTestData = async () => {
    await handleSaveAllContent(); // 전역 상태 업데이트

    /** 업데이트 or 새로운 데이터가 추가된 데이터 배열 */
    let questionsToSend;
    if (
      apReduxState.questions.find(
        (question) =>
          question.question_order === editorState[EDITOR_CONSTANT.currentIndex] && question.part_seq === editorState[EDITOR_CONSTANT.currentPart].part_seq
      )
    ) {
      questionsToSend = apReduxState.questions.map((question) =>
        question.question_order === editorState[EDITOR_CONSTANT.currentIndex] && question.part_seq === editorState[EDITOR_CONSTANT.currentPart].part_seq
          ? formatQuestionForSubmit()
          : question
      );
    } else {
      questionsToSend = [...apReduxState.questions, formatQuestionForSubmit()];
    }

    // 수정된 데이터로 API 호출
    await postApTestData('Editing', questionsToSend);
  };
  /** 테스트 게시 */
  const handlePublish = async () => {
    /** 업데이트 or 새로운 데이터가 추가된 데이터 배열 */
    let questionsToSend;
    if (
      apReduxState.questions.find(
        (question) =>
          question.question_order === editorState[EDITOR_CONSTANT.currentIndex] && question.part_seq === editorState[EDITOR_CONSTANT.currentPart].part_seq
      )
    ) {
      questionsToSend = apReduxState.questions.map((question) =>
        question.question_order === editorState[EDITOR_CONSTANT.currentIndex] && question.part_seq === editorState[EDITOR_CONSTANT.currentPart].part_seq
          ? formatQuestionForSubmit()
          : question
      );
    } else {
      questionsToSend = [...apReduxState.questions, formatQuestionForSubmit()];
    }
    // 문제 수 검증
    const isValidPublish = validateQuestionsCount(questionsToSend);

    if (isValidPublish) {
      // 파트별 점수 총합 검증
      const isValidPartScores = validatePartScores(questionsToSend);
      if (isValidPartScores) {
        const isValidCorrectAnswer = validateCorrectAnswer(questionsToSend);

        if (isValidCorrectAnswer) {
          if (
            confirm(
              'You can modify the test after publishing.\nIf a student is taking the test, the current section stays the same, and updates apply from the next section onward.'
            )
          ) {
            await postApTestData('Completed', questionsToSend);
          }
        } else {
          handleSaveAllContent();
        }
      } else {
        handleSaveAllContent();
      }
    } else {
      handleSaveAllContent();
    }
  };

  /** "Add Source" 버튼 핸들러 */
  const handleAddSource = () => {
    /** 새로 추가 할 Source */
    const newSource = {
      id: 0,
      order: editorState.currentQuestion.source_group.sources.length,
      content: '',
    };

    editorStateMutator(EDITOR_CONSTANT.currentQuestion, (prev) => {
      return {
        ...prev,
        source_group: {
          ...prev.source_group,
          sources: prev.source_group.sources.concat(newSource),
        },
      };
    });

    moveCurrentSource({ order: editorState.currentQuestion.source_group.sources.length });
  };

  /** "Source 삭제" 버튼 핸들러 */
  const handleDeleteSource = () => {
    const newData = editorState[EDITOR_CONSTANT.currentQuestion].source_group.sources
      .filter((item) => Number(item.order) !== Number(editorState[EDITOR_CONSTANT.currentSourceOrder])) // 삭제된 소스 제외
      .map((item, index) => ({ ...item, order: index })); // 삭제된 소스의 순서(order)를 재할당

    moveCurrentSource({ order: newData.length - 1 });

    editorStateMutator(EDITOR_CONSTANT.currentQuestion, (prev) => {
      return {
        ...prev,
        source_group: {
          ...prev.source_group,
          sources: newData,
        },
      };
    });
  };

  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    // 이탈 방지
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Your test has not been saved. Are you sure you want to leave?'; // 브라우저에 따라 이 메시지가 표시되지 않을 수 있음
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    const handlePopState = (event) => {
      history.pushState(null, '', window.location.href);
    };
    window.addEventListener('popstate', handlePopState);
    history.pushState(null, '', window.location.href);

    const loadInitialData = () => {
      // 이미 리덕스에 데이터가 있는 경우 editorState 업데이트 ( 새로고침 대응 )
      if (apReduxState.questions.length > 0 && apReduxState.sectionMetadata) {
        const currentSection = apReduxState.currentSectionData;
        const currentPart = apReduxState.currentPartData;
        const firstQuestion = apReduxState.questions.find((question) => question.question_order === 0);

        // editorState 초기화
        editorStateSetter(EDITOR_CONSTANT.currentIndex, firstQuestion.question_order);
        editorStateSetter(EDITOR_CONSTANT.currentSection, currentSection);
        editorStateSetter(EDITOR_CONSTANT.currentPart, currentPart);
        editorStateSetter(EDITOR_CONSTANT.currentQuestion, firstQuestion);
        editorStateSetter(EDITOR_CONSTANT.answerChoiceInfo, { answerChoices: transformChoicesToFrontend(firstQuestion.answer_options) });
      } else {
        // query string에서 test_seq를 받아와서 해당 데이터를 불러온다.
        const isNew = queryTestStatus === 'new';

        // 유효하지 않은 test_seq시 테스트 생성으로 방출
        if (!queryTestSeq) {
          alert('testSeq is not exist');
          window.location.href = '/ap/question-bank/create-test';
          return;
        }

        // create-test 페이지에서 생성된 테스트일 경우
        if (isNew)
          getTestMetadata(queryTestSeq); // 테스트 메타데이터 불러오기 & 프론트에서 파트데이터 설정
        // 기존 테스트 편집 일때 데이터 호출 ( 단, 게시 완료 수정 시에는 이미 데이터가 있으므로 호출 없이 종료 )
        else getEditingTestData(queryTestSeq); // 수정중이던 테스트 데이터 불러오기
      }
    };

    loadInitialData();

    // 언마운트 시 이탈 방지 이벤트 제거
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  // console.log(`\n┏━━━ 💡 💡 apReduxState 💡 💡 ━━━\n`, apReduxState);
  // console.log(`\n┏━━━ 💡 💡 editorState 💡 💡 ━━━\n`, editorState);

  return (
    <S.QuestionBankTestEditorPageWrapper className='mathjax-ignore'>
      <TestEditorHeader
        editorState={editorState}
        handlePostApTestData={handlePostApTestData}
        handlePublish={handlePublish}
        handleSaveAllContent={handleSaveAllContent}
      />

      <S.TestEditorPageWrap>
        <UI_ExamContainer className='left'>
          <UI_EditorContainer>
            <SourceDirectionArea editorState={editorState} editorStateMutator={editorStateMutator} />

            {editorState.currentQuestion.source_group.sources.length > 0 && (
              <>
                {editorState.currentQuestion.source_group.sources.length > 1 && (
                  <S.SourcesForReviewContainer>
                    <S.SectionTitle>Sources for Review : </S.SectionTitle>
                    <SourcesForReview
                      sourceData={editorState.currentQuestion.source_group.sources}
                      currentOrder={editorState.currentSourceOrder}
                      handleClickReviewItem={(item) => {
                        moveCurrentSource({ order: item.order });
                      }}
                    />
                  </S.SourcesForReviewContainer>
                )}

                <SourceContainer
                  sourceOrder={editorState[EDITOR_CONSTANT.currentSourceOrder]}
                  editorState={editorState}
                  editorStateMutator={editorStateMutator}
                  handleDelete={handleDeleteSource}
                />
              </>
            )}

            <div className='source_btn_container'>
              <TestEditComBtn onClick={handleAddSource} variant='blue'>
                Add <span className='bold_text'>Source</span>
              </TestEditComBtn>
              {editorState[EDITOR_CONSTANT.currentIndex] !== 0 && (
                <TestEditComBtn
                  onClick={() => {
                    console.log('copyPreviousSource');
                    copyPreviousSource();
                  }}
                  variant='blue'
                  withIcon={false}>
                  <p className='bold_text'>Copy previous source</p>
                </TestEditComBtn>
              )}
            </div>
          </UI_EditorContainer>
        </UI_ExamContainer>

        <S.VerticalDivider />

        <UI_ExamContainer className='right'>
          <UI_EditorContainer>
            <QuestionEditor currentQuestion={editorState[EDITOR_CONSTANT.currentQuestion]} editorStateUpdater={editorStateUpdater} />

            {editorState[EDITOR_CONSTANT.currentPart].type_of_question === QUESTION_TYPE_CONSTANT.M && (
              <>
                <S.HorizontalDivider />

                <AnswerChoiceList editorState={editorState} editorStateUpdater={editorStateUpdater} isNavigating={isNavigating} />
              </>
            )}
          </UI_EditorContainer>
        </UI_ExamContainer>
      </S.TestEditorPageWrap>
      <ApExamFooter
        currentPartQuestion={getCurrentPartQuestion()}
        editorState={editorState}
        handleQuestionNavigation={handleQuestionNavigation}
        isNavigating={isNavigating}
      />
    </S.QuestionBankTestEditorPageWrapper>
  );
}

// --- --- --- Styled components --- --- ---
const S = {
  QuestionBankTestEditorPageWrapper: styled.div`
    background-color: #ffffff;
    width: 100%;
    min-width: 1024px;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,

  TestEditorPageWrap: styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    min-height: 0;
    & > * {
      padding-top: 4.435rem;
    }
  `,

  VerticalDivider: styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 1px;
    background-color: black;
  `,
  HorizontalDivider: styled.div`
    height: 2px;
    width: 100%;
    background-color: #767676;
  `,

  SourcesForReviewContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,

  AnswerChoiceContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  `,
  AnswerChoiceList: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
};
