//#region    ////////// packages
import { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import QuillEditorContainer from 'components/ap/_components/common/QuillEditorContainer';
import ApExamBodyBase from 'components/ap/_components/examLayout/examBody/ApExamBodyBase';
import QuestionTopArea from 'components/ap/com/ap-components/_components/QuestionTopArea';
// import FileUpload from 'components/ap/_components/examLayout/examBody/FileUpload';
//#endregion ////////// components

//#region    ////////// utils & hooks
import { AP_PARAMS } from 'components/ap/_utils/constants/queryParamsString';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import request from 'utils/Request.utils';
//#endregion ////////// utils & hooks

//#region    ////////// assets
import FileItemDownload from 'components/ap/_components/examLayout/examBody/FileItemDownload';
import ExamAnswerList from 'components/ap/com/score/grading/submission/question-view/_components/ExamAnswerList';
//#endregion ////////// assets

/** AP review 페이지 본문 영역 컴포넌트 */
const ApReviewQuestionView = forwardRef((props, ref) => {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** 현재 선택 된 UTH 시퀀스 */
  const queryCurUthSeq = getObfuscatedValue(AP_PARAMS.UTH_SEQ.KEY);
  /** 현재 선택 된 Part 시퀀스 */
  const queryCurPartSeq = getObfuscatedValue(AP_PARAMS.PART_SEQ.KEY);
  /** 현재 선택 된 문제의 순서 */
  const queryCurQuestionOrder = getObfuscatedValue(AP_PARAMS.QUESTION_ORDER.KEY);

  if (!queryCurUthSeq || !queryCurPartSeq || !queryCurQuestionOrder) {
    console.error('Score Review 페이지에 필요한 UthSeq / PartSeq / QuestionOrder 를 확인해 주세요. ');
    return;
  }
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** 로딩 상태 */
  const [isLoading, setIsLoading] = useState(false);
  /** 현재 선택 된 문제 데이터 (API 응답) */
  const [reviewQuestionData, setReviewQuestionData] = useState(false);
  /** 사용자가 입력하는 점수 (주관식) */
  const [scoreInput, setScoreInput] = useState('');
  /** 초기 마운트를 알리는 state ( useEffect로 첫 마운트때 변경 ) */
  const [isInitialRender, setIsInitialRender] = useState(true);
  /** 현재 객관식 문제의 보기 목록 */
  const [answerOptionsArray, setAnswerOptionsArray] = useState([]);
  /** 현재 객관식 문제에서 정답 목록(number) */
  const [correctAnswersArray, setCorrectAnswersArray] = useState([]);
  /** 현재 객관식 문제에서 사용자가 선택한 답변 목록(number) */
  const [selectAnswersArray, setSelectAnswersArray] = useState([]);
  /** 주관식 문제 첨삭 내용 */
  const [editorContentState, setEditorContentState] = useState('');
  /** 하이브리드 학생 파일 목록 */
  const [uploadFileList, setUploadFileList] = useState([]);
  /** 하이브리드 선생 파일 목록 */
  const [teacherFileList, setTeacherFileList] = useState([]);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** "이전" 문제 버튼 핸들러 */
  const handlePrevQuestion = () => {
    /** 이전 문제로 라우트 파라미터 수정 */
    const movePrev = () => {};
  };
  /** "다음" 문제 버튼 핸들러 */
  const handleNextQuestion = () => {};
  /** 개별 점수 입력 핸들러 */
  const handleScoreInputChange = ({ event, max }) => {
    if (Number(event.target.value) === isNaN) {
      console.error('숫자 값만 입력 할 수 있습니다.');
    }

    if (Number(event.target.value) <= max) {
      setScoreInput(event.target.value);
    } else {
      console.error('최대 값을 넘을 수 없습니다.');
    }
  };
  /** 안전 JSON 파싱 함수 - 실패시 [] 반환 */
  const safeJsonParse = (jsonString) => {
    if (jsonString === '' || jsonString === null) return [];

    try {
      return jsonString ? JSON.parse(jsonString) : [];
    } catch (e) {
      return [];
    }
  };

  /** 하이브리드 문제 파일 조회 - 파일 조회 및 파일 목록 업데이트 */
  const getHybridQuestionFile = ({ etcSeq }) => {
    if (!etcSeq) return;
    const successHandler = (response) => {
      if (response.code === 200) {
        setUploadFileList(response.result.uploadFiles);
      }
    };

    request.get(`/api/v2/ap/get/exam/files/${'answer_pdf'}/${etcSeq}`, null, successHandler).finally(() => {});
  };

  const getHybridTeacherFile = ({ etcSeq }) => {
    if (!etcSeq) return;
    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);
      if (response.code === 200) {
        setTeacherFileList(response.result.uploadFiles);
      }
    };

    request.get(`/api/v2/ap/get/exam/files/comment_pdf/${etcSeq}`, null, successHandler).finally(() => {});
  };

  /** 파일 다운로드 API */
  const downloadHybridQuestionFile = ({ fileCate, upFileSeq, fileName }) => {
    request.downloadFile(`/api/v2/ap/question/${fileCate}/pdf/${upFileSeq}`, fileName).finally(() => {});
  };

  /** 현재 선택 된 파일 다운로드 버튼 핸들러 */
  const handleDownload = async ({ upFileSeq, fileName }) => {
    downloadHybridQuestionFile({ fileCate: 'answer_pdf', upFileSeq, fileName });
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    if (queryCurUthSeq && queryCurPartSeq && queryCurQuestionOrder) {
      /** 각 개별 문제 페이지의 데이터 불러 오기 */
      const getQuestionReview = ({ queryCurUthSeq, queryCurPartSeq, queryCurQuestionOrder }) => {
        setIsLoading(true);
        const successHandler = (response) => {
          if (response.code === 200) {
            const reviewQuestion = response.result.reviewQuestion;

            setReviewQuestionData(reviewQuestion);
          }
        };

        request
          .apGet(`/api/v1/academy/score/review/${queryCurUthSeq}/${queryCurPartSeq}/question/${queryCurQuestionOrder}`, null, successHandler)
          .catch((error) => console.error(error))
          .finally(() => {
            setIsLoading(false);
          });
      };

      getQuestionReview({ queryCurUthSeq, queryCurPartSeq, queryCurQuestionOrder });
    } else {
      console.error('Score Review 페이지의 API 호출에 필요한 UthSeq / PartSeq / QuestionOrder 를 확인해 주세요. ');
    }
  }, [queryCurUthSeq, queryCurPartSeq, queryCurQuestionOrder]);

  useEffect(() => {
    if (reviewQuestionData && reviewQuestionData.questionType === 'M') {
      // 현재 선택 된 객관식 문제의 객관식 보기 추출하기
      /** 객관식 JSON 형식 변환 */
      const answerOptions = reviewQuestionData.answerOptions ? JSON.parse(reviewQuestionData.answerOptions) : null;

      /** 객체 형태에서 객관식 답변만 추출하여, 배열로 변환 */
      const getOptionsArray = ({ answerOptionsData }) => {
        /** "option"으로 시작하는 키를 배열로 추출 */
        let optionArray = [];

        for (let key in answerOptionsData) {
          if (key.startsWith('option')) {
            optionArray.push(answerOptionsData[key]);
          }
        }
        return optionArray;
      };

      setAnswerOptionsArray(getOptionsArray({ answerOptionsData: answerOptions }));

      /** 사용자가 선택한 답변 번호 목록 얻기 (문자열 배열에서 번호만 추출하여 숫자 배열로 변환) */
      const getCorrectAnswersArray = ({ correctAnswerStringArray }) => {
        /** 인수로 전달 받은 데이터의 타입에 따라서 객체로 변환 할 데이터 */
        let sourceObject = correctAnswerStringArray;

        if (typeof sourceObject !== 'object' && typeof sourceObject === 'string') {
          // 인수로 전달 받은 데이터가 객체가 아니라면, JSON이라고 판단
          sourceObject = JSON.parse(correctAnswerStringArray);
        }
        const correctAnswerNumberArray = sourceObject.map((item) => Number(item.replace('option', '')));
        /** Test Data : 틀린 답 */
        // const correctAnswerNumberArray = [3];

        return correctAnswerNumberArray;
      };

      setCorrectAnswersArray(getCorrectAnswersArray({ correctAnswerStringArray: reviewQuestionData.answer }));

      /** 사용자가 선택한 답변 번호 목록 얻기 (문자열 배열에서 번호만 추출하여 숫자 배열로 변환) */
      const getSelectAnswersArray = ({ selectAnswerStringArray }) => {
        const selectAnswerNumberArray = selectAnswerStringArray?.map((item) => Number(item.replace('option', '')));
        return selectAnswerNumberArray;
      };

      setSelectAnswersArray(getSelectAnswersArray({ selectAnswerStringArray: JSON.parse(reviewQuestionData.isAnswered) }));
    } else if (reviewQuestionData && reviewQuestionData.questionType === 'E') {
      setScoreInput(String(reviewQuestionData?.receivedPoint) || ''); // 주관식 문제의 경우, 입력된 점수를 설정
      setEditorContentState(reviewQuestionData?.answerComment || ''); // 주관식 문제의 경우, 첨삭 내용을 설정
      // setUploadFileList(reviewQuestionData?.teacherFileList || []); // 주관식 문제의 경우, 첨삭 파일 목록을 설정
    }

    if (reviewQuestionData && reviewQuestionData.studentTestResultSeq && reviewQuestionData.questionType === 'H') {
      getHybridQuestionFile({ etcSeq: reviewQuestionData.studentTestResultSeq });
      getHybridTeacherFile({ etcSeq: reviewQuestionData.studentTestResultSeq });
    }
  }, [reviewQuestionData]);

  // 초기 마운트시 MathJax가 정상적으로 랜더링 될수 있게 돕는 useEffect
  useEffect(() => {
    // 즉시 State 업데이트시 종종 씹히는 문제 있어 setTimeout 추가
    const initRender = setTimeout(() => {
      if (isInitialRender) setIsInitialRender(false);
    }, 300);

    return () => clearTimeout(initRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  // reviewQuestionData이 존재할 때만 파싱 시도
  const parsedSourceContent = safeJsonParse(reviewQuestionData?.sourceContent);
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    reviewQuestionData && (
      <ApExamBodyBase
        examSection={[
          reviewQuestionData.sourceDirections !== null &&
          reviewQuestionData.sourceDirections !== '' &&
          reviewQuestionData.sourceDirections !== '<p><br></p>' ? (
            <S.ExamContainerInner>
              {reviewQuestionData.sourceDirections && <div className='passage' dangerouslySetInnerHTML={{ __html: reviewQuestionData.sourceDirections }} />}

              {reviewQuestionData.sourceContent &&
                parsedSourceContent.map((item, index) => (
                  <div className='passage' dangerouslySetInnerHTML={{ __html: item.sourceContent }} key={`key_${index}`} />
                ))}
            </S.ExamContainerInner>
          ) : null,
          reviewQuestionData.questionType === 'E' ? (
            // 주관식
            <S.ExamContainerInner>
              <S.QuestionItem className='passage'>
                <QuestionTopArea order={reviewQuestionData.customQuestionOrder} />
                <div className='text' dangerouslySetInnerHTML={{ __html: reviewQuestionData.questionContent }}></div>
                <S.SectionTitle>Your Answer : </S.SectionTitle>

                <S.EssayQuestionContainer content={reviewQuestionData.isAnswered ?? 'no answer'} draggable />

                {reviewQuestionData.answerComment && (
                  <div className='teachers_comment_area'>
                    <div className='title'></div>
                    <S.SectionTitle>Teacher’s comment :</S.SectionTitle>
                    <S.CommentWrapper className='custom_scroll'>
                      <p className='contents' dangerouslySetInnerHTML={{ __html: reviewQuestionData.answerComment }} />
                    </S.CommentWrapper>
                  </div>
                )}
              </S.QuestionItem>
            </S.ExamContainerInner>
          ) : null,
          reviewQuestionData.questionType === 'M' ? (
            // 객관식
            <S.ExamContainerInner>
              <S.QuestionItem className='passage'>
                <QuestionTopArea order={reviewQuestionData.customQuestionOrder} />
                <div className='text' dangerouslySetInnerHTML={{ __html: reviewQuestionData.questionContent }}></div>
                <ExamAnswerList questionTextList={answerOptionsArray} correctAnswerArray={correctAnswersArray} selectAnswerArray={selectAnswersArray} />
                {reviewQuestionData.isAnswered && JSON.parse(reviewQuestionData.isAnswered).length <= 0 && (
                  <div className='guide'>you have omitted this question</div>
                )}
              </S.QuestionItem>
            </S.ExamContainerInner>
          ) : null,
          reviewQuestionData.questionType === 'H' ? (
            // 하이브리드 문제
            <S.ExamContainerInner>
              <S.QuestionItem className='passage'>
                <QuestionTopArea order={reviewQuestionData.customQuestionOrder} />

                <div className='text' dangerouslySetInnerHTML={{ __html: reviewQuestionData.questionContent }}></div>

                <S.SectionTitle>Your Answer : </S.SectionTitle>

                <div className='uploaded_files_area'>
                  {uploadFileList && uploadFileList.length > 0
                    ? uploadFileList?.map((fileItem, index) => (
                        <FileItemDownload
                          key={`fileInfo_${index}`}
                          fileItem={fileItem}
                          handleDownload={() => {
                            handleDownload({ fileName: fileItem.fileName, upFileSeq: fileItem.upFileSeq });
                          }}
                        />
                      ))
                    : null}
                </div>

                <div className='score_area'>
                  <div className='score_box'>
                    <div className='title'>Raw Score</div>
                    <div className='score'>
                      <span className='user_score_text'>{reviewQuestionData.receivedPoint}</span> / {reviewQuestionData.maxScore}
                    </div>
                  </div>
                </div>

                {reviewQuestionData.answerComment && (
                  <div className='teachers_comment_area'>
                    <div className='title'></div>
                    <S.SectionTitle>Teacher’s comment :</S.SectionTitle>
                    <S.CommentWrapper className='custom_scroll'>
                      <p className='contents' dangerouslySetInnerHTML={{ __html: reviewQuestionData.answerComment }} />
                    </S.CommentWrapper>
                  </div>
                )}
                {
                  <div className='uploaded_files_area'>
                    {teacherFileList && teacherFileList.length > 0
                      ? teacherFileList?.map((fileItem, index) => (
                          <>
                            <S.SectionTitle>Teacher’s Files : </S.SectionTitle>{' '}
                            <FileItemDownload
                              key={`fileInfo_${index}`}
                              fileItem={fileItem}
                              handleDownload={() => {
                                handleDownload({ fileName: fileItem.fileName, upFileSeq: fileItem.upFileSeq });
                              }}
                            />
                          </>
                        ))
                      : null}
                  </div>
                }
              </S.QuestionItem>
            </S.ExamContainerInner>
          ) : null,
        ]}></ApExamBodyBase>
    )
  );
});

export default ApReviewQuestionView;

// --- --- --- Styled Components --- --- --- //
const S = {
  ButtonWrap: styled.div`
    .button_prev_next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      width: 3.75rem;
      height: 3.75rem;
      border: 1px solid #e5e5e5;
      transition: 0.2s;
      &:hover {
        box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.16);
      }
      svg {
        width: 3.75rem;
        color: #505050;
      }
      &:disabled {
        &:hover {
          box-shadow: none;
        }
        svg {
          color: #c5ccd2;
        }
      }
      &.prev {
        left: 2.5rem;
        svg {
          transform: rotateZ(0);
        }
      }
      &.next {
        right: 2.5rem;
        svg {
          transform: rotateZ(180deg);
        }
      }
    }
  `,

  ExamContainer: styled.section`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 0;
    min-height: 100%;
    height: fit-content;
    max-width: 50%;

    &.center {
      max-width: 100%;
      align-items: center;
    }
  `,
  ExamContainerInner: styled.div`
    padding: 2rem 4.375rem;
    max-width: 61.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    // 문제 지문
    .passage {
      font-feature-settings:
        'liga' off,
        'clig' off;
      font-family: 'Noto Serif';
    }

    .raw_score_area {
      display: flex;
      justify-content: right;
      dl {
        height: 3.5rem;
        width: fit-content;
        padding: 0.625rem 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2.5rem;
        border-radius: 0.625rem;
        border: 1px solid #c5ccd2;
        background: #f8f8f9;
        dt {
          color: #111;
          text-align: center;
          font-family: Roboto;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.75rem;
        }
        dd {
          color: #505050;
          text-align: center;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;
          b {
            color: #111;
            text-align: center;
            font-family: Roboto;
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 1.75rem;
          }
          input {
            width: 4rem;
            height: 2.25rem;
            padding: 0.5rem 0.625rem;
            border-radius: 0.25rem;
            border: 1px solid #d7dadf;
            background: #fff;
            color: #111111;
            font-family: Roboto;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
            margin-right: 0.5rem;
            &:focus {
              border-color: #0068bd;
            }
          }
        }
      }

      &.input_mode {
        dl {
          border-color: #ececec;
          background: #ffffff;
          dt {
            color: #384cc0;
          }
          dd {
          }
        }
        &.entered {
          &:not(:has(input:focus)) {
            dl {
              border-color: #111111;
            }
          }
        }
      }
    }
  `,

  QuestionItem: styled.div`
    .text {
      padding: 15px 0;

      .MJXc-display {
        margin: 0 !important;
      }
    }

    .guide {
      color: #f24b4b;
      font-family: Roboto;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.625rem; /* 162.5% */
      text-transform: uppercase;
      margin-top: 2rem;
    }

    .uploaded_files_area {
      margin-bottom: 1.25rem;
    }

    .score_area {
      display: flex;
      justify-content: end;
      .score_box {
        display: flex;
        height: 3.5rem;
        padding: 0.625rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 2.5rem;
        border-radius: 0.625rem;
        border: 1px solid #c5ccd2;
        background: #f8f8f9;

        .title {
          color: #111;
          font-family: Roboto;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.75rem;
        }

        .score {
          color: #505050;
          text-align: center;
          font-family: Roboto;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.25rem;

          span {
            color: #111;
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.75rem;
          }
        }
      }
    }
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  `,

  EssayQuestionContainer: styled(QuillEditorContainer)`
    padding: 12px 8px;
    background-color: #f1f1f1;
    margin-bottom: 1rem;

    p {
      align-self: stretch;
      color: #000000;
      font-family: 'Noto Sans';
      letter-spacing: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
      max-width: 100%;
    }

    .ql-editor {
      padding: 0;
    }
  `,

  CommentWrapper: styled.div`
    background-color: #ffffff;
    border: 1px solid #999;
    height: 286px;
    padding: 12px;
    width: 100%;
    .contents {
      font-size: 14px;
      width: 100%;
      height: 100%;
    }
    .placeholder {
      color: #999999;
    }
  `,
};
