import { useState } from 'react';
import styled from 'styled-components';

import PartArea from './_components/PartArea';
import SectionArea from './_components/SectionArea';
import MultipleChoiceQuestionArea from './_components/multipleChoiceQuestion/MultipleChoiceQuestionArea';
import FreeResponseQuestionArea from './_components/freeResponseQuestion/FreeResponseQuestionArea';
import HybridFreeResponseQuestion from './_components/hybridFreeResponseQuestion/HybridFreeResponseQuestion';

/**
 * ap/com/score/grading/submission 페이지의 "Raw 점수 영역" 컴포넌트
 * Section과 Part의 드롭다운 상태를 RawScoreArea에서 관리함
 */
export default function RawScoreArea({ sectionInfo }) {
  /** 드롭다운 상태 - sessionStorage에서 해당 값 저장 & 복원 */
  const [dropdownStates, setDropdownStates] = useState(() => JSON.parse(window.sessionStorage.getItem('dropdownStates') || '{}'));

  /** 드롭다운 버튼 토글 핸들러 - 일치 하는 키 값 업데이트 및 sessionStorage에 저장 */
  const handleToggleDropdown = (key) => {
    setDropdownStates((prev) => {
      const newStates = { ...prev, [key]: !prev[key] };
      window.sessionStorage.setItem('dropdownStates', JSON.stringify(newStates));
      return newStates;
    });
  };

  return (
    <S.Wrap>
      {sectionInfo &&
        sectionInfo.map((item, index) => (
          <SectionArea
            key={index}
            sectionInfo={item}
            isShowDropdown={dropdownStates[`s_${item.sectionNum}`]} // 드롭다운 State에서 키 조회 ( 예시 : s_1 )
            onToggleDropdown={() => handleToggleDropdown(`s_${item.sectionNum}`)}
            dropdownChildren={item.partList?.map((partItem, partIndex, partArray) => {
              if (partArray.length > 1) {
                return (
                  <PartArea
                    key={`part_${partIndex}`}
                    partInfo={partItem}
                    isShowDropdown={dropdownStates[`p_${item.sectionNum}_${partItem.partNum}`]} // 드롭다운 State에서 키 조회 ( 예시 : p_1_1 )
                    onToggleDropdown={() => handleToggleDropdown(`p_${item.sectionNum}_${partItem.partNum}`)}
                    dropdownChildren={
                      partItem.typeOfQuestion === 'M' ? (
                        <MultipleChoiceQuestionArea key={partIndex} partSeq={partItem.partSeq} questionsData={partItem.questionList} />
                      ) : partItem.typeOfQuestion === 'E' ? (
                        <FreeResponseQuestionArea key={partIndex} partSeq={partItem.partSeq} questionsData={partItem.questionList} />
                      ) : partItem.typeOfQuestion === 'H' ? (
                        <HybridFreeResponseQuestion key={partIndex} partSeq={partItem.partSeq} questionsData={partItem.questionList} />
                      ) : null
                    }
                  />
                );
              } else {
                return partItem.typeOfQuestion === 'M' ? (
                  <MultipleChoiceQuestionArea key={partIndex} partSeq={partItem.partSeq} questionsData={partItem.questionList} />
                ) : partItem.typeOfQuestion === 'E' ? (
                  <FreeResponseQuestionArea key={partIndex} partSeq={partItem.partSeq} questionsData={partItem.questionList} />
                ) : partItem.typeOfQuestion === 'H' ? (
                  <HybridFreeResponseQuestion key={partIndex} partSeq={partItem.partSeq} questionsData={partItem.questionList} />
                ) : null;
              }
            })}
          />
        ))}
    </S.Wrap>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  Wrap: styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
};
