//#region    ////////// packages
import { useEffect, useState } from 'react';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import FileUpload from './FileUpload';
//#endregion ////////// components

//#region    ////////// utils & hooks
import request from 'utils/Request.utils';
//#endregion ////////// utils & hooks

/** 하이브리드 문제 영역 컴포넌트 */
export default function HybridQuestionArea({ currentQuestionInfo, setLoadingInfo, stateExamInfo, setQuestionInfo }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [uploadFileList, setUploadFileList] = useState([]);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  useEffect(() => {
    setUploadFileList([]); // 문제 변경 시 파일 목록 초기화
    /** 하이브리드 문제 파일 조회 - 파일 조회 및 파일 목록 업데이트 */
    const getHybridQuestionFile = () => {
      setLoadingInfo((prev) => ({ ...prev, isLoading: true, text: 'Loading file...' })); // 로딩
      const successHandler = (response) => {
        if (response.code === 200) {
          const uploadFiles = response.result.uploadFiles;
          setUploadFileList(uploadFiles);

          // 필요 없을지도
          // uploadFiles 에서 seq 추출 해서 ,로 join
          // const fileSeq = uploadFiles.map((file) => file.upFileSeq).join(',');
          // console.log('🚀 ~ successHandler ~ fileSeq:', fileSeq);

          // 상태 업데이트
          // const currentTestKey = `apTest_${LocalStorage.getItemJsonParse('currentTest').uthSeq}`;
          // LocalStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { answerContent: fileSeq });
          // console.log('🚀 ~ successHandler ~ stateExamInfo.currentNum:', stateExamInfo.currentNum);

          // 상태 업데이트
          // setQuestionInfo((prev) => {
          //   console.log('🚀 ~ setQuestionInfo ~ prev:', prev);
          //   const updated = [...prev];
          //   updated[stateExamInfo.currentNum - 1] = {
          //     ...updated[stateExamInfo.currentNum - 1],
          //     answerContent: fileSeq,
          //   };
          //   return updated;
          // });
        }
      };

      request
        .get(`/api/v2/ap/get/exam/files/answer_pdf/${currentQuestionInfo.studentTestResultSeq}`, null, successHandler)
        .finally(() => setLoadingInfo((prev) => ({ ...prev, isLoading: false })));
    };

    getHybridQuestionFile();
  }, [setLoadingInfo, currentQuestionInfo]);

  /** 파일 업로드 API - 유저가 파일 업로드 시 호출 */
  const postFileUpload = (file) => {
    setLoadingInfo((prev) => ({ ...prev, isLoading: true, text: 'Uploading file...' }));

    // 파일 배열이 아닌 경우 배열로 변환
    const fileArray = Array.isArray(file) ? file : [file];

    return Promise.all(
      fileArray.map((file) => {
        return new Promise((resolve, reject) => {
          // 파일 크기 체크
          const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB
          if (file.size > MAX_FILE_SIZE) {
            setLoadingInfo((prev) => ({ ...prev, isLoading: false }));
            window.alert('File size cannot exceed 20MB.');
            reject(new Error('file size exceeded'));
            return;
          }

          const params = {
            uploadFiles: file,
            etcSeq: currentQuestionInfo.studentTestResultSeq,
          };

          const successHandler = (response) => {
            if (response.code === 200) {
              resolve(response.result);
            } else {
              reject(new Error('Upload failed'));
            }
          };

          request.postMultipart(`/api/v2/ap/upload/exam/files/answer_pdf`, params, successHandler);
        });
      })
    ).finally(() => setLoadingInfo((prev) => ({ ...prev, isLoading: false })));
  };

  // .finally(() => setLoadingInfo((prev) => ({ ...prev, isLoading: false })));

  /** 파일 삭제 API - 유저가 파일 삭제 시 호출 */
  const deleteFile = (fileSeq) => {
    setLoadingInfo((prev) => ({ ...prev, isLoading: true, text: 'Deleting file...' })); // 로딩
    const successHandler = (response) => {
      if (response.code === 200) return;
    };

    request.del(`/api/v2/ap/delete/exam/files/${fileSeq}`, null, successHandler).finally(() => setLoadingInfo((prev) => ({ ...prev, isLoading: false })));
  };

  return (
    <S.FileUploadSection>
      <p className='your_answer'>Your answer:</p>

      {stateExamInfo.totalQuestion !== currentQuestionInfo.questionOrder + 1 ? (
        <p className='important'>
          Important: Capture your answer to this question along with your responses to the other questions and upload them as a PDF in the final question.
        </p>
      ) : (
        <p className='instructions'>
          Instructions: Use a scanner app like CamScanner, Adobe Scan, or Microsoft Lens to capture your document and submit it as a PDF. If you have multiple
          pages, make sure to merge them into a single file before submitting.
          <br />
          <br />
          <span>
            Warning: In the actual hybrid exam, you must submit a physical paper, not a digital capture. Please note that mobile phones are not allowed.
          </span>
        </p>
      )}

      {stateExamInfo.totalQuestion === currentQuestionInfo.questionOrder + 1 && (
        <FileUpload uploadFileList={uploadFileList} setUploadFileList={setUploadFileList} postFileUpload={postFileUpload} deleteFile={deleteFile} />
      )}
    </S.FileUploadSection>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  FileUploadSection: styled.div`
    margin-top: 1.5rem;

    .your_answer {
      color: black;
      font-family: 'Noto Serif';
      font-size: 1.125rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    .important {
      color: #ff5858;
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      font-weight: 700;
    }

    .instructions {
      color: #ff5858;
      font-size: 0.875rem;
      margin-bottom: 1.25rem;

      span {
        font-weight: 700;
      }
    }
  `,
};
