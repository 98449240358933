import { useEffect, useRef, useState } from 'react';
import 'styles/css/exam.css';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
import ModuleOver from './_components/examLayout/common/ModuleOver';
import ExamBody from './_components/examLayout/examBody/ExamBody';
import ExamFooter from './_components/examLayout/examFooter/ExamFooter';
import ExamHeader from './_components/examLayout/examHeader/ExamHeader';

export default function ExamApPage() {
  // 문제 입력 방법 가이드 팝업
  const [isDirectionsPopup, setIsDirectionsPopup] = useState(true);

  // 시험 정보
  const [stateExamInfo, setStateExamInfo] = useState({
    isUnderFiveMinutes: false, // 남은 시간이 5분 미만일 때
    currentNum: 0, // 현재 문제 번호
    totalQuestion: 0, // 총 문제 수
    sessionName: '', // 세션 이름
    isReviewPopupOpen: false, // 리뷰 팝업 오픈 여부
    showModuleOver: false, // 모듈 종료 팝업
  });

  // 문제 정보 리스트
  const [questionInfo, setQuestionInfo] = useState([]);

  // 현재 문제 정보
  const [currentQuestionInfo, setCurrentQuestionInfo] = useState({});

  const prevCurrentNumRef = useRef(stateExamInfo.currentNum);

  // 문제 업데이트 함수
  const updateQuestionContent = () => {
    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
    const currentTestKey = `apTest_${uthSeq}`;
    // testInfo
    const testInfo = localStorage?.getItemJsonParse(currentTestKey);

    if (!testInfo) {
      console.warn(`키 없음: ${currentTestKey}`);
      return;
    }

    const currentQuestion = testInfo.questionList[prevCurrentNumRef.current - 1];
    if (!currentQuestion) {
      console.warn('문제 없음.');
      return;
    }

    const studentTestResultSeq = currentQuestion?.studentTestResultSeq;
    const params = currentQuestion;

    const successHandler = (response) => {
      if (response.code === 200) {
        console.log('성공');
      } else {
        console.error('Error in API response:', response);
      }
    };

    request.apPut(`/api/v1/student/test/${uthSeq}/question/${studentTestResultSeq}`, params, successHandler);
  };

  useEffect(() => {
    const preventContextMenu = (e) => e.preventDefault();
    window.addEventListener('contextmenu', preventContextMenu);

    const handleBeforeUnload = (e) => {
      updateQuestionContent(); // 페이지 닫기(새로고침, 뒤로가기 등) 시 업데이트
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      updateQuestionContent();
      window.removeEventListener('contextmenu', preventContextMenu);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!stateExamInfo.showModuleOver) {
      const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
      const testInfo = localStorage.getItemJsonParse(`apTest_${uthSeq}`);

      setStateExamInfo((prevState) => ({
        ...prevState,
        sessionName: testInfo.sessionName,
        remainTime: testInfo.totalTime,
        currentNum: testInfo.currentNum,
        totalQuestion: testInfo.totalQuestionCount,
      }));

      setQuestionInfo(testInfo.questionList);
    }
  }, [stateExamInfo.showModuleOver]);

  useEffect(() => {
    if (questionInfo.length > 0 && stateExamInfo.currentNum > 0 && stateExamInfo.currentNum <= questionInfo.length) {
      setCurrentQuestionInfo(questionInfo[stateExamInfo.currentNum - 1]);
    }
  }, [questionInfo, stateExamInfo.currentNum]);

  useEffect(() => {
    if (stateExamInfo.currentNum !== prevCurrentNumRef.current) {
      updateQuestionContent();
    }

    prevCurrentNumRef.current = stateExamInfo.currentNum;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamInfo.currentNum]);

  return (
    <>
      {stateExamInfo.showModuleOver ? (
        <ModuleOver showModuleOver={stateExamInfo.showModuleOver} setStateExamInfo={setStateExamInfo} />
      ) : (
        <div className={`com_popup pop_exam active ${isDirectionsPopup ? 'direction_on' : ''}`} onContextMenu={(e) => e.preventDefault()}>
          <div className='pop_container'>
            <ExamHeader
              stateExamInfo={stateExamInfo}
              setStateExamInfo={setStateExamInfo}
              isDirectionsPopup={isDirectionsPopup}
              setIsDirectionsPopup={setIsDirectionsPopup}
            />

            <ExamBody
              stateExamInfo={stateExamInfo}
              setStateExamInfo={setStateExamInfo}
              currentQuestionInfo={currentQuestionInfo}
              setQuestionInfo={setQuestionInfo}
            />

            <ExamFooter stateExamInfo={stateExamInfo} setStateExamInfo={setStateExamInfo} questionInfo={questionInfo} />

            {/*완료 5분전 alert*/}
            {/* <FiveMinutesAlert /> */}
            {/* MathJax 랜더링 실패 alert */}
            {/* <MathJaxAlert /> */}
          </div>
        </div>
      )}
    </>
  );
}
