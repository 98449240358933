import React from 'react';

/** 메세지 보내기 팝업 */
export default function SendMessage({ setSendMessage, sendMessage }) {
  return (
    <div className={`com_popup type_alert pop_send_message ${sendMessage ? 'active' : ''}`}>
      <div className='pop_container'>
        <div className='pop_body ok_contents'>
          <p className='alert_title'>Send a message to the selected student(s)?</p>
          <p className='alert_text'>The message will be sent via individual chat room.</p>
          <div className='container'>
            <textarea className='textarea' defaultValue='It seems like you have not yet taken the test. Please take the test ASAP.' />
          </div>
        </div>
        <div className='pop_footer com_btn_wrap many'>
          <button className='com_btn point line xl' onClick={setSendMessage}>
            Cancel
          </button>
          <button className='com_btn point xl'>Send</button>
        </div>
      </div>
    </div>
  );
}
