import { useState } from 'react';
import styled from 'styled-components';

/** 공용 툴팁 컴포넌트 (hover 요소를 자식으로 전달) */
export default function Tooltip01({ children, text = '문구를 입력해 주세요' }) {
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  });

  const handleHover = (event) => {
    const element = event.target;
    /** 현재 이벤트가 발생한 요소의 위치 값 얻기 */
    const rect = element.getBoundingClientRect();
    setMousePosition({
      x: rect.left,
      y: rect.top,
    });
  };

  return (
    <S.Tooltip $position={mousePosition} onMouseEnter={handleHover}>
      {children}
      <div className='tooltipText'>{text}</div>
    </S.Tooltip>
  );
}

const S = {};

S.Tooltip = styled.div`
  position: relative;
  width: fit-content;
  display: inline-block;

  .text_box {
    background-color: #c9e7ff;
    border-radius: 0.25rem;
    padding: 0.125rem 0.625rem;
    font-size: 0.813rem;
    margin-right: 0.25rem;
  }

  .tooltipText {
    visibility: hidden;
    font-size: 11px;
    position: fixed;
    left: ${(props) => `${props.$position.x - 4}px`};
    top: ${(props) => `${props.$position.y + 28}px`};
    min-width: 10rem;
    width: 14rem;
    justify-content: center;
    align-items: center;

    background: #dff1ff;
    color: #4692d1;
    border-radius: 4px;
    padding: 10px 12px;
    z-index: 100;

    &:after {
      border-top: 0 solid transparent;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-bottom: 1rem solid #dff1ff;
      content: '';
      position: absolute;
      top: -0.5rem;
      left: 1rem;
      /* right: ${(props) => (props.$position === 'right' ? `1rem` : 'none')}; */
    }
  }

  &:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
`;
