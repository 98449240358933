import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';

import request from 'utils/Request.utils.js';
import { nvlNumber } from 'utils/Common.utils.js';
import LoadingBar from 'utils/LoadingBar.js';

import { SCORE_PARAMS } from 'utils/constants';

//components
//chart
import OverallAverageScore from '../_components/sections/overallAverageScore/OverallAverageScore';
import AdvancedReport from '../_components/sections/advancedReportSection/AdvancedReport';
import MainTopArea from '../_components/layouts/main-top-area/MainTopArea';
import SelectDetailSection from '../_components/layouts/main-top-area/SelectDetailSection';
import MainTab from '../_components/layouts/main-top-area/MainTab';
import Profile from '../_components/sections/profile/Profile';
import TestList from '../_components/sections/testList/TestList';
import OuterBox from '../_components/common/OuterBox';
import AverageWeeklyScores from '../_components/sections/averageWeeklyScores/AverageWeeklyScores';
import BtoBMainTabFrameHOC from 'layout/commonFrame/BtoBMainTabFrameHOC';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { getValidParam, useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

  /** selectedStudentTestList의 초기화 객체 */
  const selectedStudentTestListInitData = [
    {
      uthSeq: 0, // number             => 유저 시험 기록 시퀀스(난독화X)
      uthSeqStr: '', // string         => 난독화된 유저 시험 기록 시퀀스
      customSetSeq: 0, // number       => Test 시퀀스
      mathScore: 0, // number          => 수학 점수
      rwScore: 0, // number            => 영어 점수
      className: '', // string         => 반 이름
      testName: '', // string          => 시험 이름
      testTakenEndDate: '', // string  => 시험 완료 한 일자
    },
  ];

/** com/score/ 페이지 */
export default function ScoreStudentPage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  /** 현재 studentSeq - Route Parameter */
  const { studentSeq: encStudentSeq } = useParams();
  const studentSeq = deobfuscateUrlParam(encStudentSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurScoreStudentSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_STUDENT_SEQ);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** 선택된 학생의 정보 */
  const [selectedStudentInfo, setSelectedStudentInfo] = useState({
    userSeq: 0, // number         => 유저 시퀀스
    upFileSeq: 0, // number       => 유저 프로필 이미지 시퀀스
    userName: '', // string       => 유저 이름
    userEmail: '', // string      => 유저 이메일
    studentSchool: '', // string  => 유저의 학교명
    classList: [
      {
        classSeq: 0, // number    => 유저가 속한 클래스 시퀀스
        className: '', // string  => 유저가 속한 클래스 이름
      },
    ],
  });
  /** 학원에 속한 학생들의 정보 - 검색 결과 */
  const [searchedStudents, setSearchedStudents] = useState([
    {
      userSeq: 0, // number      => 유저 시퀀스
      userName: '', // string       => 유저 이름
      userEmail: '', // string      => 유저 이메일
      // 현재 유저가 속해있는 class 목록
      classList: [
        {
          classSeq: 0, // number    => 반 시퀀스
          className: '', // string  => 반 이름
        },
      ],
    },
  ]);
  /** 검색 데이터 */
  const [searchInfo, setSearchInfo] = useState({
    searchField: '', // '' | 'user_name' | 'class_name' | 'user_email'  => 검색 필드
    searchKeyword: '', // string                                        => 검색 키워드
  });
  /** 선택된 학생이 본 시험 list */
  const [selectedStudentTestList, setSelectedStudentTestList] = useState(selectedStudentTestListInitData);
  /////////////////// React useState 선언 영역 끝  ///////////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  // 현재 studentSeq가 바뀔때 마다 데이터 호출
  useEffect(() => {
    /** queryCurScoreStudentSeq가 있다면 그걸 사용하고 없다면 studentSeq */
    const targetStudentSeq = getValidParam(queryCurScoreStudentSeq) ? getValidParam(queryCurScoreStudentSeq) : (getValidParam(studentSeq) ?? 0);
    /** 해당하는 학생의 상세 데이터를 불러오는 함수 */
    const getCurrentStudentInfo = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentInfo = response.result.studentInfo;
          if (fetchedStudentInfo) setSelectedStudentInfo(fetchedStudentInfo);
          else {
            console.error(`데이터 없음 \n - apiURL: /api/v2/score/student/info?userSeq=${targetStudentSeq}`, response);
            setSelectedStudentInfo(null);
          }
        }
      };

      request
        .get(`/api/v2/score/student/info?userSeq=${targetStudentSeq}`, null, successHandler)
        .catch((error) => console.error('', error).finally(console.log('데이터 호출 완료')));
    };

    /** 선택된 학생의 TestList를 불러오는 함수 */
    const getCurrentStudentTestList = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentTestList = response.result.studentTestList;
          if (fetchedStudentTestList.length > 0) setSelectedStudentTestList(fetchedStudentTestList);
          else setSelectedStudentTestList(selectedStudentTestListInitData);
        }
      };

      request
        .get(`/api/v2/score/student/test-list?userSeq=${targetStudentSeq}`, null, successHandler)
        .catch((error) => console.error('', error).finally(console.log('데이터 호출 완료')));
    };

    // 학생의 정보가 유효할때만 호출
    if (getValidParam(queryCurScoreStudentSeq) || getValidParam(studentSeq)) {
      getCurrentStudentInfo();
      getCurrentStudentTestList();
    }
  }, [queryCurScoreStudentSeq, studentSeq]);

  // 초기 페이지 진입시 - 전체 학생 목록 불러오기
  // 검색조건 변경시 - 해당하는 학생 목록 호출
  useEffect(() => {
    /** 현재 학원에 속한 학생을 불러오는 함수 */
    const getStudentList = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentList = response.result.studentList;
          setSearchedStudents(fetchedStudentList);
        }
      };

      request
        .get(`/api/v2/score/student/list-modal?searchField=${searchInfo.searchField}&searchKeyword=${searchInfo.searchKeyword}`, null, successHandler)
        .catch((error) => console.error('', error).finally(console.log('데이터 호출 완료')));
    };

    getStudentList();
  }, [searchInfo]);

  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <BtoBMainTabFrameHOC>
        <S.Warp className='p_score_main'>
          <OuterBox className='main_wrap'>
            <MainTopArea>
              <SelectDetailSection
                variant='student'
                dataList={searchedStudents}
                isModalActive={Number(studentSeq ?? 0) === 0}
                modalStateHandler={setSearchInfo}
              />
              <MainTab />
            </MainTopArea>
            <div className='flex_row'>
              <Profile studentInfo={selectedStudentInfo} />
              <TestList selectedStudentTestList={selectedStudentTestList} />
            </div>
            <div className='flex_row'>
              {/*Scores*/}
              <OverallAverageScore />

              <AverageWeeklyScores currentClassTestData={selectedStudentTestList} />
            </div>
            {/*ADVANCED REPORT*/}
            <AdvancedReport mode={'student'} />
          </OuterBox>
        </S.Warp>
      </BtoBMainTabFrameHOC>

      {/* {loading && <LoadingBar />} */}
    </>
  );
}

const S = {
  Warp: styled.div`
    .main_wrap {
      width: 1194px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .flex_row {
      display: flex;
      gap: 0.75rem;
    }
  `,
};
