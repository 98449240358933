import React from 'react';
import styled from 'styled-components';

import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import { ReactComponent as ImgSvgIconImportant } from 'assets/img/svg/important.svg';

/** 유저가 PDF 페이지에 들어올 때 최초 발생하는 Warning 문구 */
export default function WarningModal({ setShowModal }) {
  return (
    <S.Wrap>
      <SimpleModalBase w={'35rem'} h={'27.188rem'} p={'3.75rem'} gap={'1.5rem'}>
        <div className='modal_img_wrapper'>
          <ImgSvgIconImportant />
        </div>
        <h2>Warning</h2>
        <div className='modal_text_container'>
          <p>
            All provided questions are protected as GATEPLUS’ intellectual property. Each generated test and PDF include a unique identifier and account code
            enabling the tracking of unauthorized reproduction or distribution. Unauthorized copy, use or distribution may result in criminal prosecution for
            intellectual property infringement.
          </p>
        </div>
        <div className='modal_btn_container'>
          <button className='btn_fill' onClick={() => setShowModal((current) => !current)}>
            Close
          </button>
        </div>
      </SimpleModalBase>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .modal_img_wrapper {
      svg {
        width: 4.5rem;
      }
    }
    h2 {
      text-transform: uppercase;
    }
    .modal_text_container {
      p {
        color: #505050;
        font-size: 0.875rem;
      }
    }
  `,
};
