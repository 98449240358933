import { useEffect, useRef, useState } from 'react';
import ApLeftArea from './ApLeftArea';
import QuestionArea from './QuestionArea';

import ReviewPopup from './ReviewPopup';

/** AP 시험 화면 본문 영역 컴포넌트 */
function ExamBody({
  stateExamInfo,
  setStateExamInfo,
  currentQuestionInfo,
  setQuestionInfo,
  setLoadingInfo,
  // 하이라이트 관련 props
  isAnnotation,
  setIsAnnotation,
  isDragged,
  setIsDragged,
  // 그룹 소스 관련 props
  questionSourceGroup,
  setQuestionSourceGroup,
}) {
  const [questionArea, setQuestionArea] = useState(null);

  // 어노테이트가 적용될 컴포넌트 ref (sourceDirections, sourceContent, questionContent)
  const questionRef = useRef(null);
  const sourceRef = useRef(null);
  const contentRef = useRef(null);

  // 통합된 텍스트 드래그 감지 이벤트 핸들러
  useEffect(() => {
    const handleMouseUp = () => {
      const selection = window.getSelection();

      if (selection && !selection.isCollapsed) {
        // 드래그된 컨테이너 확인 (question || source || content)
        const questionContainsStart = questionRef.current && questionRef.current.contains(selection.anchorNode);
        const questionContainsEnd = questionRef.current && questionRef.current.contains(selection.focusNode);

        const sourceContainsStart = sourceRef.current && sourceRef.current.contains(selection.anchorNode);
        const sourceContainsEnd = sourceRef.current && sourceRef.current.contains(selection.focusNode);

        const contentContainsStart = contentRef.current && contentRef.current.contains(selection.anchorNode);
        const contentContainsEnd = contentRef.current && contentRef.current.contains(selection.focusNode);

        // 한 컨테이너 내에서만 선택이 이루어졌는지 확인
        const isQuestionSelection = questionContainsStart && questionContainsEnd;
        const isSourceSelection = sourceContainsStart && sourceContainsEnd;
        const isContentSelection = contentContainsStart && contentContainsEnd;

        if (isQuestionSelection || isSourceSelection || isContentSelection) {
          // 유효한 드래그로 판단
          setIsDragged(true);
          return;
        }
      }

      // 조건에 맞지 않으면 드래그 상태 해제
      setIsDragged(false);
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [setIsDragged]);

  // 타이머와 관계없는 속성들이 바뀔때만 렌더링
  useEffect(() => {
    setQuestionArea(
      stateExamInfo.isReviewPopupOpen ? (
        <ReviewPopup stateExamInfo={stateExamInfo} setStateExamInfo={setStateExamInfo} />
      ) : (
        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }} className='pop_body mathjax-ignore'>
          {((currentQuestionInfo.sourceContent && currentQuestionInfo.sourceContent?.length > 0) || currentQuestionInfo.sourceDirections) && (
            <ApLeftArea
              sourceDirections={currentQuestionInfo?.sourceDirections}
              source={currentQuestionInfo?.sourceContent}
              currentQuestionOrder={currentQuestionInfo?.questionOrder}
              setQuestionInfo={setQuestionInfo}
              // 하이라이트 관련 props
              isAnnotation={isAnnotation}
              setIsAnnotation={setIsAnnotation}
              isDragged={isDragged}
              setIsDragged={setIsDragged}
              questionRef={questionRef}
              sourceRef={sourceRef}
              // 그룹 소스 관련 props
              questionSourceGroup={questionSourceGroup}
              setQuestionSourceGroup={setQuestionSourceGroup}
              questionSourceInfo={currentQuestionInfo?.questionSourceInfo}
            />
          )}
          <QuestionArea
            stateExamInfo={stateExamInfo}
            currentQuestionInfo={currentQuestionInfo}
            setQuestionInfo={setQuestionInfo}
            setLoadingInfo={setLoadingInfo}
            // 하이라이트 관련 props 추가
            isAnnotation={isAnnotation}
            setIsAnnotation={setIsAnnotation}
            isDragged={isDragged}
            setIsDragged={setIsDragged}
            contentRef={contentRef}
          />
        </div>
      )
    );
  }, [
    currentQuestionInfo,
    setQuestionInfo,
    setStateExamInfo,
    stateExamInfo,
    setLoadingInfo,
    isAnnotation,
    setIsDragged,
    setIsAnnotation,
    isDragged,
    questionSourceGroup,
    setQuestionSourceGroup,
  ]);

  return questionArea;
}

export default ExamBody;
