export const USER_LEVELS = {
  STUDENT: '001001004000000',
  TEACHER: '001001003000000',
  TUTOR: '001001002000000',
  ACADEMY: '001001001000000',
  PARENT: '001001005000000',
};

/** com/score 페이지에서 사용하는 파라미터 쿼리 관리 상수 */
export const SCORE_PARAMS = {
  CUR_TEST: 'curTest', // 현재 선택 된 시험
  CUR_UTH: 'curUth', // 현재 선택 된 UTH
  CUR_STU: 'curStu', // 현재 선택 된 학생
  SEL_TEST_CLASS_SEQ: 'selTestClassSeq', // 선택 된 시험 반의 시퀀스
  CUR_SCORE_TEST_SEQ: 'csts', // score 페이지에서 현재 선택 된 시험 세트의 시퀀스
  CUR_SCORE_STUDENT_SEQ: 'csss', // score 페이지에서 현재 선택 된 학생의 시퀀스
  CUR_SCORE_CLASS_SEQ: 'cscs', // score 페이지에서 현재 선택 된 class의 시퀀스
};

/** student/dashboard 페이지에서 사용하는 파라미터 쿼리 관리 상수 */
export const STUDENT_PARAMS = {
  UTH_SEQ: {
    // 현재 선택 된 시험 세트의 UTH Seq
    KEY: 'uthSeq',
  },
  SUBJECT: {
    // 현재 선택 된 과목 문자열
    KEY: 'subject',
    VALUE: {
      RW: 'E',
      MATH: 'M',
    },
  },
  MODULE_NUM: {
    // 현재 선택 된 모듈 번호
    KEY: 'moduleNum',
    VALUE: {
      MODULE_1: '1',
      MODULE_2: '2',
    },
  },
};
