//#region    ////////// packages
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import MainPeriodicTable from './_components/MainPeriodicTable';
import AdditionalPeriodicTable from './_components/AdditionalPeriodicTable';
//#endregion ////////// components

/** AP 시험에서 보일 주기율표 컴포넌트 */
export default function PeriodicTable() {
  return (
    <S.Container>
      <S.Title>Periodic Table</S.Title>

      <S.Divider />

      <MainPeriodicTable />
      <AdditionalPeriodicTable />
    </S.Container>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  Container: styled.div`
    font-family: 'Noto Serif', serif;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    margin: 0 auto;
    min-width: 990px;
    max-width: 100%;
    padding: 1rem;
  `,

  Title: styled.h3`
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 1rem;
  `,

  Divider: styled.hr`
    border: none;
    width: 100%;
    height: 1px;
    background: #aeaeae;
    margin-bottom: 1rem;
  `,
};
