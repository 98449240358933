import { useEffect, useRef, useState } from 'react';
import PercentileWithinGatePlusAndGradeChangeFromPastTests from './PercentileWithinGatePlusAndGradeChangeFromPastTests';
import ClassVsGatePlus from './ClassVsGatePlus';
import ClassVsClass from './ClassVsClass';
import request from 'utils/Request.utils';
import { useObfuscatedSearchParam, getValidParam } from 'hooks/useObfuscatedSearchParam';
import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { SCORE_PARAMS } from 'utils/constants';

/** 학생의 성적 변화를 확인할 수 있게 해주는 section - percentile & Vs Chart */
export default function GradeChangeFromPastSection() {
  const userInfo = request.tokenDecoder();
  /** 현재 classSeq - Route Parameter */
  const { classSeq: encClassSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);
  /** 중복호출 방지용 Ref값 초기화 여부 */
  const [isInitialized, setIsInitialized] = useState(false);

  const [selectedSubject, setSelectedSubject] = useState(''); // "E" | "M" => 현재 선택된 과목 - 그래프 및 데이터 노출용 State ( 영어("E") | 수학("M") )
  const [selectedStudentSeq, setSelectedStudentSeq] = useState(0); // number  => 현재 선택된 학생의 Seq

  /** 해당 Class에서 본 시험목록 */
  const [takenTestList, setTakenTestList] = useState([
    {
      ecSeq: 0, // number                  => 해당 시험의 학원 시퀀스
      classSeq: 0, // number               => 해당 시험을 본 반 시퀀스
      customSetSeq: 0, // number           => 해당 시험의 시퀀스
      rwPoint: 0, // number                => 시험을 본 반의 영어 평균 점수
      mathPoint: 0, // number              => 시험을 본 반의 수학 평균 점수
      className: '', // string             => 해당 시험을 본 반의 이름
      testName: '', // string              => 해당 시험의 이름
      subject: '', // "F" | "E" | "M"      => 해당 시험의 과목 ( FullSet(F) | 영어(E) | 수학(M) )
      difficulty: '', // null | "E" | "H"  => 해당 시험의 난이도 ( Easy(E) | Hard(H) )
      testStatus: 'P', // "P"               => 시험 상태 - "P"로 고정 ( 시험완료("P") )
      testStartDate: '', // string          => 해당 시험의 시작일
      testEndDate: '', // string            => 해당 시험의 종료일
      trhRegDate: '', // string             => 시험을 응시한 날
      customSeqStr: '', // string           => 해당 시험의 시퀀스 (난독화)
    },
  ]);
  const [selectedTestInfo, setSelectedTestInfo] = useState({}); // takenTestList에서 선택된 시험 정보
  /** 선택된 시험을 본 학생들의 평균 점수 변화 데이터 */
  const [studentPercentileGradeAverage, setStudentPercentileGradeAverage] = useState([
    {
      userSeq: 0, // number         => 해당 학생의 시퀀스
      upFileSeq: 0, // number       => 해당 학생의 프로필 사진 시퀀스
      userName: '', // string       => 해당 학생의 이름
      testName: '', // string       => 해당 시험의 이름
      testStartDate: '', // string  => 해당 시험의 시작일
      testEndDate: '', // string    => 해당 시험의 종료일
      all: 0, // number             => 전체 평균 점수
      tests3: 0, // null | number   => 최근 3회 평균 점수
      tests5: 0, // null | number   => 최근 5회 평균 점수
      tests10: 0, // null | number  => 최근 10회 평균 점수
    },
  ]);

  //////////////////// START --- API_중복_호출_방지용_Ref --- START ////////////////////
  const prevRatePercentileDeltaRef = useRef({}); // rate/percentile/delta API 중복 호출 방지용 Ref - userInfo.ecSeq, classSeq, queryCurScoreTestSeq, subject
  const prevScoreClassTakenRef = useRef({}); // score/class/taken API 중복 호출 방지용 Ref - userInfo.ecSeq, classSeq
  ////////////////////  END  --- API_중복_호출_방지용_Ref ---  END  ////////////////////

  /** 데이터 호출 없이 랜더링에 필요한 데이터 업데이트 */
  const updateSelectedTestDetails = (takenTestData) => {
    const currentTest = takenTestData.find((item) => item.customSetSeq == getValidParam(queryCurScoreTestSeq));
    setSelectedTestInfo(currentTest);

    const subject = currentTest?.subject === 'F' ? 'E' : currentTest?.subject; // FullSet(F) 이면 영어(E)로 설정 그 외에는 그대로
    setSelectedSubject(subject);
    getStudentPercentileGradeAverage(subject);
  };

  /** 학생의 평균 성적 변화(백분위) 데이터 불러오기 API */
  const getStudentPercentileGradeAverage = (subject) => {
    // 중복 방지 Ref가 초기화가 되지 않았거나, API 파라미터가 유효하지 않을 경우 early return
    if (!(isInitialized && userInfo?.ecSeq && getValidParam(classSeq) && getValidParam(queryCurScoreTestSeq) && subject)) return;
    // 이전 호출한 API의 파라미터와 현재 파라미터가 같다면 함수 종료 ( 중복 호출 방지 )
    if (
      prevRatePercentileDeltaRef.current.ecSeq === userInfo?.ecSeq &&
      prevRatePercentileDeltaRef.current.classSeq === classSeq &&
      prevRatePercentileDeltaRef.current.queryCurScoreTestSeq === queryCurScoreTestSeq &&
      prevRatePercentileDeltaRef.current.subject === subject
    )
      return;
    prevRatePercentileDeltaRef.current = { ecSeq: userInfo.ecSeq, classSeq, queryCurScoreTestSeq, subject }; // 현재 파라미터 값 업데이트

    const successHandler = (response) => {
      console.log(response);

      if (response.code === 200) {
        const fetchedPercentileAndAvgDiff = response.result.percentileAndAvgDiff;
        if (fetchedPercentileAndAvgDiff.length > 0) {
          setStudentPercentileGradeAverage(fetchedPercentileAndAvgDiff);
        }
      }
    };

    request
      .get(
        `/api/dsat/rate/percentile/delta?ecSeq=${userInfo.ecSeq}&classSeq=${classSeq}&customSetSeq=${queryCurScoreTestSeq}&subject=${subject}`,
        null,
        successHandler
      )
      .catch((error) => console.error('', error));
  };

  //////////////////// START --- useEffect_선언_영역 --- START ////////////////////
  // 컴포넌트 마운트시 Ref값 초기화 ( 새로고침시 이전 Ref 값으로 중복호출방지가 실행되는 것을 막음 )
  useEffect(() => {
    prevRatePercentileDeltaRef.current = {};
    prevScoreClassTakenRef.current = {};
    setIsInitialized(true);
  }, []);

  // API 인자가 유효할 경우 해당 Class에서 본 시험정보 리스트 불러오기
  useEffect(() => {
    if (!(isInitialized && getValidParam(classSeq) && getValidParam(queryCurScoreTestSeq))) return;

    /** Class에서 본 시험정보 리스트 불러오기 API */
    const getClassTakenTest = () => {
      // 이전 호출한 API의 파라미터와 현재 파라미터가 같다면 함수 종료 ( 중복 호출 방지 )
      if (prevScoreClassTakenRef.current.ecSeq === userInfo?.ecSeq && prevScoreClassTakenRef.current.classSeq === classSeq) return;
      prevScoreClassTakenRef.current = { ecSeq: userInfo?.ecSeq, classSeq }; // 현재 파라미터 값 업데이트

      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedTakenTest = response.result.takenTest;

          if (fetchedTakenTest.length > 0) {
            setTakenTestList(fetchedTakenTest);
            // 선택된 시험 정보 업데이트
            updateSelectedTestDetails(fetchedTakenTest);
          }
        }
      };
      request.get(`/api/dsat/score/class/taken?ecSeq=${userInfo?.ecSeq}&classSeq=${classSeq}`, null, successHandler).catch((error) => console.error('', error));
    };

    getClassTakenTest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, classSeq, queryCurScoreTestSeq]);

  // 선택된 Test 정보 변경시 takenTestList에서 해당 정보로 업데이트
  useEffect(() => {
    // 유효성 체크 및 이전에 선택된 시험 정보가 없을 경우 early return
    if (!(isInitialized && getValidParam(queryCurScoreTestSeq) && prevScoreClassTakenRef.current?.ecSeq && prevScoreClassTakenRef.current?.classSeq)) return;

    updateSelectedTestDetails(takenTestList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCurScoreTestSeq]);
  ////////////////////  END  --- useEffect_선언_영역 ---  END  ////////////////////

  /** F(FullSet) 인지 단일 과목 인지 - PercentileWithinGatePlusAndGradeChangeFromPastTests 컴포넌트의 과목 표시 및 disable을 위해 필요 */
  const currentSubject = selectedTestInfo?.subject;

  return (
    <div className='com_container'>
      {/* Percentile within Gate+ and Grade Change from Past tests */}
      <PercentileWithinGatePlusAndGradeChangeFromPastTests
        studentPercentileGradeAverage={studentPercentileGradeAverage}
        currentSubject={currentSubject}
        selectedSubject={selectedSubject}
        setSelectedSubject={setSelectedSubject}
        selectedStudentSeq={selectedStudentSeq}
        setSelectedStudentSeq={setSelectedStudentSeq}
      />
      {/* Class | Student VS GATE+ */}
      <ClassVsGatePlus selectedStudentSeq={selectedStudentSeq} setSelectedStudentSeq={setSelectedStudentSeq} />
      {/* Class | Student VS Class */}
      <ClassVsClass currentSubject={currentSubject} selectedStudentSeq={selectedStudentSeq} setSelectedStudentSeq={setSelectedStudentSeq} />
    </div>
  );
}
