import styled from 'styled-components';
import { MathJax } from 'better-react-mathjax';

//#region    ////////// components
import EquationsPanel from './_components/EquationsPanel';
import EquationsPanelContentContainer from './_components/EquationsPanelContentContainer';
import EquationsList from './_components/EquationsList';
import ScrollableTable from 'components/_common/ScrollableTable';
//#endregion ////////// components

//#region    ////////// assets
import descriptiveStatistics1 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/descriptive_statistics_1.png';
import probabilitiesDistributions1 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/probabilities_distributions_1.png';
import probabilitiesDistributions2 from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics/probabilities_distributions_2.png';

import tableA from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics_table/table_a.png';
import tableB from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics_table/table_b.png';
import tableC from 'assets/img/ap_exam/reference_sheet/equations/statistics/statistics_table/table_c.png';
//#endregion ////////// assets

/** Statistics 레퍼런스 시트 수식 컴포넌트 */
export default function StatisticsTableEquations() {
  const tableAHeaders = ['', '.00', '.01', '.02', '.03', '.04', '.05', '.06', '.07', '.08', '.09'];
  const tableALeftColumn = [
    '-3.4',
    '-3.3',
    '-3.2',
    '-3.1',
    '-3.0',
    '-2.9',
    '-2.8',
    '-2.7',
    '-2.6',
    '-2.5',
    '-2.4',
    '-2.3',
    '-2.2',
    '-2.1',
    '-2.0',
    '-1.9',
    '-1.8',
    '-1.7',
    '-1.6',
    '-1.5',
    '-1.4',
    '-1.3',
    '-1.2',
    '-1.1',
    '-1.0',
    '-0.9',
    '-0.8',
    '-0.7',
    '-0.6',
    '-0.5',
    '-0.4',
    '-0.3',
    '-0.2',
    '-0.1',
    '-0.0',
  ];
  const tableAData = [
    ['.0003', '.0003', '.0003', '.0003', '.0003', '.0003', '.0003', '.0003', '.0003', '.0002'],
    ['.0005', '.0005', '.0005', '.0004', '.0004', '.0004', '.0004', '.0004', '.0004', '.0003'],
    ['.0007', '.0007', '.0006', '.0006', '.0006', '.0006', '.0006', '.0005', '.0005', '.0005'],
    ['.0010', '.0009', '.0009', '.0009', '.0008', '.0008', '.0008', '.0008', '.0007', '.0007'],
    ['.0013', '.0013', '.0013', '.0012', '.0012', '.0011', '.0011', '.0011', '.0010', '.0010'],
    ['.0019', '.0018', '.0018', '.0017', '.0016', '.0016', '.0015', '.0015', '.0014', '.0014'],
    ['.0026', '.0025', '.0024', '.0023', '.0023', '.0022', '.0021', '.0021', '.0020', '.0019'],
    ['.0035', '.0034', '.0033', '.0032', '.0031', '.0030', '.0029', '.0028', '.0027', '.0026'],
    ['.0047', '.0045', '.0044', '.0043', '.0041', '.0040', '.0039', '.0038', '.0037', '.0036'],
    ['.0062', '.0060', '.0059', '.0057', '.0055', '.0054', '.0052', '.0051', '.0049', '.0048'],
    ['.0082', '.0080', '.0078', '.0075', '.0073', '.0071', '.0069', '.0068', '.0066', '.0064'],
    ['.0107', '.0104', '.0102', '.0099', '.0096', '.0094', '.0091', '.0089', '.0087', '.0084'],
    ['.0139', '.0136', '.0132', '.0129', '.0125', '.0122', '.0119', '.0116', '.0113', '.0110'],
    ['.0179', '.0174', '.0170', '.0166', '.0162', '.0158', '.0154', '.0150', '.0146', '.0143'],
    ['.0228', '.0222', '.0217', '.0212', '.0207', '.0202', '.0197', '.0192', '.0188', '.0183'],
    ['.0287', '.0281', '.0274', '.0268', '.0262', '.0256', '.0250', '.0244', '.0239', '.0233'],
    ['.0359', '.0351', '.0344', '.0336', '.0329', '.0322', '.0314', '.0307', '.0301', '.0294'],
    ['.0446', '.0436', '.0427', '.0418', '.0409', '.0401', '.0392', '.0384', '.0375', '.0367'],
    ['.0548', '.0537', '.0526', '.0516', '.0505', '.0495', '.0485', '.0475', '.0465', '.0455'],
    ['.0668', '.0655', '.0643', '.0630', '.0618', '.0606', '.0594', '.0582', '.0571', '.0559'],
    ['.0808', '.0793', '.0778', '.0764', '.0749', '.0735', '.0721', '.0708', '.0694', '.0681'],
    ['.0968', '.0951', '.0934', '.0918', '.0901', '.0885', '.0869', '.0853', '.0838', '.0823'],
    ['.1151', '.1131', '.1112', '.1093', '.1075', '.1056', '.1038', '.1020', '.1003', '.0985'],
    ['.1357', '.1335', '.1314', '.1292', '.1271', '.1251', '.1230', '.1210', '.1190', '.1170'],
    ['.1587', '.1562', '.1539', '.1515', '.1492', '.1469', '.1446', '.1423', '.1401', '.1379'],
    ['.1841', '.1814', '.1788', '.1762', '.1736', '.1711', '.1685', '.1660', '.1635', '.1611'],
    ['.2119', '.2090', '.2061', '.2033', '.2005', '.1977', '.1949', '.1922', '.1894', '.1867'],
    ['.2420', '.2389', '.2358', '.2327', '.2296', '.2266', '.2236', '.2206', '.2177', '.2148'],
    ['.2743', '.2709', '.2676', '.2643', '.2611', '.2578', '.2546', '.2514', '.2483', '.2451'],
    ['.3085', '.3050', '.3015', '.2981', '.2946', '.2912', '.2877', '.2843', '.2810', '.2776'],
    ['.3446', '.3409', '.3372', '.3336', '.3300', '.3264', '.3228', '.3192', '.3156', '.3121'],
    ['.3821', '.3783', '.3745', '.3707', '.3669', '.3632', '.3594', '.3557', '.3520', '.3483'],
    ['.4207', '.4168', '.4129', '.4090', '.4052', '.4013', '.3974', '.3936', '.3897', '.3859'],
    ['.4602', '.4562', '.4522', '.4483', '.4443', '.4404', '.4364', '.4325', '.4286', '.4247'],
    ['.5000', '.4960', '.4920', '.4880', '.4840', '.4801', '.4761', '.4721', '.4681', '.4641'],
  ];

  const tableBHeaders = ['', '.25', '.20', '.15', '.10', '.05', '.025', '.02', '.01', '.005', '.0025', '.001', '.0005'];
  const tableBLeftColumn = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '40',
    '50',
    '60',
    '80',
    '100',
    '1000',
    '∞',
    ' ',
  ];
  const tableBColumnGroups = ['', { title: 'Tail probability p', span: tableBHeaders.length }];
  const tableBData = [
    ['1.000', '1.376', '1.963', '3.078', '6.314', '12.71', '15.89', '31.82', '63.66', '127.3', '318.3', '636.6'],
    ['.816', '1.061', '1.386', '1.886', '2.920', '4.303', '4.849', '6.965', '9.925', '14.09', '22.33', '31.60'],
    ['.765', '.978', '1.250', '1.638', '2.353', '3.182', '3.482', '4.541', '5.841', '7.453', '10.21', '12.92'],
    ['.741', '.941', '1.190', '1.533', '2.132', '2.776', '2.999', '3.747', '4.604', '5.598', '7.173', '8.610'],
    ['.727', '.920', '1.156', '1.476', '2.015', '2.571', '2.757', '3.365', '4.032', '4.773', '5.893', '6.869'],
    ['.718', '.906', '1.134', '1.440', '1.943', '2.447', '2.612', '3.143', '3.707', '4.317', '5.208', '5.959'],
    ['.711', '.896', '1.119', '1.415', '1.895', '2.365', '2.517', '2.998', '3.499', '4.029', '4.785', '5.408'],
    ['.706', '.889', '1.108', '1.397', '1.860', '2.306', '2.449', '2.896', '3.355', '3.833', '4.501', '5.041'],
    ['.703', '.883', '1.100', '1.383', '1.833', '2.262', '2.398', '2.821', '3.250', '3.690', '4.297', '4.781'],
    ['.700', '.879', '1.093', '1.372', '1.812', '2.228', '2.359', '2.764', '3.169', '3.581', '4.144', '4.587'],
    ['.697', '.876', '1.088', '1.363', '1.796', '2.201', '2.328', '2.718', '3.106', '3.497', '4.025', '4.437'],
    ['.695', '.873', '1.083', '1.356', '1.782', '2.179', '2.303', '2.681', '3.055', '3.428', '3.930', '4.318'],
    ['.694', '.870', '1.079', '1.350', '1.771', '2.160', '2.282', '2.650', '3.012', '3.372', '3.852', '4.221'],
    ['.692', '.868', '1.076', '1.345', '1.761', '2.145', '2.264', '2.624', '2.977', '3.326', '3.787', '4.140'],
    ['.691', '.866', '1.074', '1.341', '1.753', '2.131', '2.249', '2.602', '2.947', '3.286', '3.733', '4.073'],
    ['.690', '.865', '1.071', '1.337', '1.746', '2.120', '2.235', '2.583', '2.921', '3.252', '3.686', '4.015'],
    ['.689', '.863', '1.069', '1.333', '1.740', '2.110', '2.224', '2.567', '2.898', '3.222', '3.646', '3.965'],
    ['.688', '.862', '1.067', '1.330', '1.734', '2.101', '2.214', '2.552', '2.878', '3.197', '3.611', '3.922'],
    ['.688', '.861', '1.066', '1.328', '1.729', '2.093', '2.205', '2.539', '2.861', '3.174', '3.579', '3.883'],
    ['.687', '.860', '1.064', '1.325', '1.725', '2.086', '2.197', '2.528', '2.845', '3.153', '3.552', '3.850'],
    ['.686', '.859', '1.063', '1.323', '1.721', '2.080', '2.189', '2.518', '2.831', '3.135', '3.527', '3.819'],
    ['.686', '.858', '1.061', '1.321', '1.717', '2.074', '2.183', '2.508', '2.819', '3.119', '3.505', '3.792'],
    ['.685', '.858', '1.060', '1.319', '1.714', '2.069', '2.177', '2.500', '2.807', '3.104', '3.485', '3.768'],
    ['.685', '.857', '1.059', '1.318', '1.711', '2.064', '2.172', '2.492', '2.797', '3.091', '3.467', '3.745'],
    ['.684', '.856', '1.058', '1.316', '1.708', '2.060', '2.167', '2.485', '2.787', '3.078', '3.450', '3.725'],
    ['.684', '.856', '1.058', '1.315', '1.706', '2.056', '2.162', '2.479', '2.779', '3.067', '3.435', '3.707'],
    ['.684', '.855', '1.057', '1.314', '1.703', '2.052', '2.158', '2.473', '2.771', '3.057', '3.421', '3.690'],
    ['.683', '.855', '1.056', '1.313', '1.701', '2.048', '2.154', '2.467', '2.763', '3.047', '3.408', '3.674'],
    ['.683', '.854', '1.055', '1.311', '1.699', '2.045', '2.150', '2.462', '2.756', '3.038', '3.396', '3.659'],
    ['.683', '.854', '1.055', '1.310', '1.697', '2.042', '2.147', '2.457', '2.750', '3.030', '3.385', '3.646'],
    ['.681', '.851', '1.050', '1.303', '1.684', '2.021', '2.123', '2.423', '2.704', '2.971', '3.307', '3.551'],
    ['.679', '.849', '1.047', '1.299', '1.676', '2.009', '2.109', '2.403', '2.678', '2.937', '3.261', '3.496'],
    ['.679', '.848', '1.045', '1.296', '1.671', '2.000', '2.099', '2.390', '2.660', '2.915', '3.232', '3.460'],
    ['.678', '.846', '1.043', '1.292', '1.664', '1.990', '2.088', '2.374', '2.639', '2.887', '3.195', '3.416'],
    ['.677', '.845', '1.042', '1.290', '1.660', '1.984', '2.081', '2.364', '2.626', '2.871', '3.174', '3.390'],
    ['.675', '.842', '1.037', '1.282', '1.646', '1.962', '2.056', '2.330', '2.581', '2.813', '3.098', '3.300'],
    ['.674', '.841', '1.036', '1.282', '1.645', '1.960', '2.054', '2.326', '2.576', '2.807', '3.091', '3.291'],
    ['50%', '60%', '70%', '80%', '90%', '95%', '96%', '98%', '99%', '99.5%', '99.8%', '99.9%'],
  ];

  const tableCHeaders = ['', '.25', '.20', '.15', '.10', '.05', '.025', '.02', '.01', '.005', '.0025', '.001', '.0005'];
  const tableCLeftColumn = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '40',
    '50',
    '60',
    '80',
    '100',
  ];
  const tableCColumnGroups = ['', { title: 'Tail probability p', span: tableCHeaders.length }];
  const tableCData = [
    ['1.32', '1.64', '2.07', '2.71', '3.84', '5.02', '5.41', '6.63', '7.88', '9.14', '10.83', '12.12'],
    ['2.77', '3.22', '3.79', '4.61', '5.99', '7.38', '7.82', '9.21', '10.60', '11.98', '13.82', '15.20'],
    ['4.11', '4.64', '5.23', '6.25', '7.81', '9.35', '9.84', '11.34', '12.84', '14.32', '16.27', '17.73'],
    ['5.39', '5.99', '6.74', '7.78', '9.49', '11.14', '11.67', '13.28', '14.86', '16.42', '18.47', '20.00'],
    ['6.63', '7.29', '8.12', '9.24', '11.07', '12.83', '13.39', '15.09', '16.75', '18.39', '20.51', '22.11'],
    ['7.84', '8.56', '9.45', '10.64', '12.59', '14.45', '15.03', '16.81', '18.55', '20.25', '22.46', '24.10'],
    ['9.04', '9.80', '10.75', '12.02', '14.07', '16.01', '16.62', '18.48', '20.28', '22.04', '24.32', '26.02'],
    ['10.22', '11.03', '12.03', '13.36', '15.51', '17.53', '18.17', '20.09', '21.95', '23.77', '26.12', '27.87'],
    ['11.39', '12.24', '13.29', '14.68', '16.92', '19.02', '19.68', '21.67', '23.59', '25.46', '27.88', '29.67'],
    ['12.55', '13.44', '14.53', '15.99', '18.31', '20.48', '21.16', '23.21', '25.19', '27.11', '29.59', '31.42'],
    ['13.70', '14.63', '15.77', '17.28', '19.68', '21.92', '22.62', '24.72', '26.76', '28.73', '31.26', '33.14'],
    ['14.85', '15.81', '16.99', '18.55', '21.03', '23.34', '24.05', '26.22', '28.30', '30.32', '32.91', '34.82'],
    ['15.98', '16.98', '18.20', '19.81', '22.36', '24.74', '25.47', '27.69', '29.82', '31.88', '34.53', '36.48'],
    ['17.12', '18.15', '19.41', '21.06', '23.68', '26.12', '26.87', '29.14', '31.32', '33.43', '36.12', '38.11'],
    ['18.25', '19.31', '20.60', '22.31', '25.00', '27.49', '28.26', '30.58', '32.80', '34.95', '37.70', '39.72'],
    ['19.37', '20.47', '21.79', '23.54', '26.30', '28.85', '29.63', '32.00', '34.27', '36.46', '39.25', '41.31'],
    ['20.49', '21.61', '22.98', '24.77', '27.59', '30.19', '31.00', '33.41', '35.72', '37.95', '40.79', '42.88'],
    ['21.60', '22.76', '24.16', '25.99', '28.87', '31.53', '32.35', '34.81', '37.16', '39.42', '42.31', '44.43'],
    ['22.72', '23.90', '25.33', '27.20', '30.14', '32.85', '33.69', '36.19', '38.58', '40.88', '43.82', '45.97'],
    ['23.83', '25.04', '26.50', '28.41', '31.41', '34.17', '35.02', '37.57', '40.00', '42.34', '45.31', '47.50'],
    ['24.93', '26.17', '27.66', '29.62', '32.67', '35.48', '36.34', '38.93', '41.40', '43.78', '46.80', '49.01'],
    ['26.04', '27.30', '28.82', '30.81', '33.92', '36.78', '37.66', '40.29', '42.80', '45.20', '48.27', '50.51'],
    ['27.14', '28.43', '29.98', '32.01', '35.17', '38.08', '38.97', '41.64', '44.18', '46.62', '49.73', '52.00'],
    ['28.24', '29.55', '31.13', '33.20', '36.42', '39.36', '40.27', '42.98', '45.56', '48.03', '51.18', '53.48'],
    ['29.34', '30.68', '32.28', '34.38', '37.65', '40.65', '41.57', '44.31', '46.93', '49.44', '52.62', '54.95'],
    ['30.43', '31.79', '33.43', '35.56', '38.89', '41.92', '42.86', '45.64', '48.29', '50.83', '54.05', '56.41'],
    ['31.53', '32.91', '34.57', '36.74', '40.11', '43.19', '44.14', '46.96', '49.64', '52.22', '55.48', '57.86'],
    ['32.62', '34.03', '35.71', '37.92', '41.34', '44.46', '45.42', '48.28', '50.99', '53.59', '56.89', '59.30'],
    ['33.71', '35.14', '36.85', '39.09', '42.56', '45.72', '46.69', '49.59', '52.34', '54.97', '58.30', '60.73'],
    ['34.80', '36.25', '37.99', '40.26', '43.77', '46.98', '47.96', '50.89', '53.67', '56.33', '59.70', '62.16'],
    ['45.62', '47.27', '49.24', '51.81', '55.76', '59.34', '60.44', '63.69', '66.77', '69.70', '73.40', '76.09'],
    ['56.33', '58.16', '60.35', '63.17', '67.50', '71.42', '72.61', '76.15', '79.49', '82.66', '86.66', '89.56'],
    ['66.98', '68.97', '71.34', '74.40', '79.08', '83.30', '84.58', '88.38', '91.95', '95.34', '99.61', '102.7'],
    ['88.13', '90.41', '93.11', '96.58', '101.9', '106.6', '108.1', '112.3', '116.3', '120.1', '124.8', '128.3'],
    ['109.1', '111.7', '114.7', '118.5', '124.3', '129.6', '131.1', '135.8', '140.2', '144.3', '149.4', '153.2'],
  ];
  return (
    <EquationsList>
      <EquationsPanel
        panelHeaderText='Table A - Standard Normal Probabilities'
        PanelContent={
          <EquationsPanelContentContainer
            contents={[
              <S.ScrollableTableContainer>
                <div className='table_a_img_container'>
                  <img className='table_a_img' src={tableA} alt='tableA' />
                  <p>Table entry for z is the probability lying below z.</p>
                </div>
                <ScrollableTable cornerText='Z' headers={tableAHeaders} leftColumn={tableALeftColumn} data={tableAData} />
              </S.ScrollableTableContainer>,
            ]}
            containerHeight='60rem'
          />
        }
      />
      <EquationsPanel
        renderLatex
        panelHeaderText='Table B - $t$ Distribution Critical'
        PanelContent={
          <EquationsPanelContentContainer
            contents={[
              <S.ScrollableTableContainer>
                <div className='table_a_img_container'>
                  <img className='table_a_img' src={tableB} alt='tableB' />
                  <MathJax>
                    {'Table entry for p and C is the point $t^{*}$ with probability p lying above it and probability C lying between $-t^{*}$ and $t^{*}$.'}
                  </MathJax>
                </div>
                <ScrollableTable cornerText='df' columnGroups={tableBColumnGroups} headers={tableBHeaders} leftColumn={tableBLeftColumn} data={tableBData} />
              </S.ScrollableTableContainer>,
            ]}
            containerHeight='60rem'
          />
        }
      />
      <EquationsPanel
        renderLatex
        panelHeaderText='Table C - $\chi ^{2}$ Critical Values'
        PanelContent={
          <EquationsPanelContentContainer
            contents={[
              <S.ScrollableTableContainer>
                <div className='table_a_img_container'>
                  <img className='table_a_img' src={tableC} alt='tableC' />
                  <MathJax>{'Table entry for p is the point $(\\chi ^{2})$ with probability p lying above it.'}</MathJax>
                </div>
                <ScrollableTable cornerText='df' columnGroups={tableCColumnGroups} headers={tableCHeaders} leftColumn={tableCLeftColumn} data={tableCData} />
              </S.ScrollableTableContainer>,
            ]}
            containerHeight='60rem'
          />
        }
      />
    </EquationsList>
  );
}

const S = {
  ScrollableTableContainer: styled.div`
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .table_a_img_container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
      .table_a_img {
        height: 15rem;
        object-fit: contain;
      }
    }
  `,
};
