import React, { useEffect, useState } from 'react';
import request from 'utils/Request.utils';

import MiniAlert from 'components/_common/alerts/MiniAlert';
import TimePerQuestionChartSection from './questionsStatisticsComponents/_components/TimePerQuestionChartSection';

import QuestionsStatisticsHeader from './questionsStatisticsComponents/QuestionsStatisticsHeader';
import ClassQuestionsStatsTable from './questionsStatisticsComponents/ClassQuestionsStatsTable';
import StudentQuestionsStatsTable from './questionsStatisticsComponents/StudentQuestionsStatsTable';
import { useObfuscatedSearchParam, getValidParam } from 'hooks/useObfuscatedSearchParam';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useParams } from 'react-router-dom';
import { SCORE_PARAMS } from 'utils/constants';

/** "/com/score" 페이지의 "Advanced Report" 영역의 "Correct answer (rate) by Questions" 영역 컴포넌트 */
export default function QuestionsStatisticsSection({
  mode,
  activeSatBox,
  setActiveSatBox,
  currentTestModuleList,
  selectedModuleInfo,
  setSelectedModuleInfo,
  handleSelectSubject,
}) {
  /** 현재 classSeq - Route Parameter */
  const { classSeq: encClassSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** score/student에서 사용하는 UTH시퀀스 */
  const queryCurUthSeq = getObfuscatedValue(SCORE_PARAMS.CUR_UTH);
  /** score/student에서 사용하는 Test의 시퀀스 */
  const queryCurTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_TEST);
  /** 전체 score에서 추적중인 Test의 시퀀스 */
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);
  const querySelTestClassSeq = getObfuscatedValue(SCORE_PARAMS.SEL_TEST_CLASS_SEQ);

  /** 선택된 모듈의 정오답 정보 */
  const [selectedModuleChoiceRate, setSelectedModuleChoiceRate] = useState([
    {
      questionSeq: 0, // number            => 문제 시퀀스
      questionSeqStr: '', // string        => 문제 시퀀스 (난독화)
      uthSeqStr: '', // string             => 유저 시험 기록 시퀀스 (난독화)
      questionOrder: 0, // number          => 문제 번호
      subject: '', // "E" | "M"            => 문제 과목 ( 영어(E) | 수학(M) )
      difficulty: '', // null | "E" | "H"  => 문제 난이도 ( Easy("E") | Hard("H") )
      contentDomain: '', // string         => 시험 모듈 안에서 세부 카테고리 정보 (노출되는 색상 제어)
      bookmarkYn: '', // "Y" | "N"         => 북마크 여부
      isCorrect: false, // boolean         => 정답 여부
      correctAnswerRate: 0, // number      => 문제 정답률
    },
  ]);
  const [timePerQuestionData, setTimePerQuestionData] = useState({
    // 선택된 방의 시험 시간 정보
    targetTimePerQuestion: [
      {
        questionOrder: 0, // number        => 문제 번호
        timeSpent: null, // null | number  => 학생이 문제를 푼 시간 (초)
      },
    ],
    // GATE⁺ 시험 시간 정보
    gateTimePerQuestion: [
      {
        questionOrder: 0, // number        => 문제 번호
        timeSpent: null, // null | number  => 학생이 문제를 푼 시간 (초)
      },
    ],
  });
  const [forceFetch, setForceFetch] = useState(false);

  // --- --- ---

  const [alert, alertSet] = useState(false);

  useEffect(() => {
    const getClassCorrectAnswerRate = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const classCorrectAnswerByQuestion = response.result.classCorrectAnswerByQuestion.sort((a, b) => a.questionOrder - b.questionOrder);
          setSelectedModuleChoiceRate(classCorrectAnswerByQuestion);
        } else {
          console.error('Error: ', response);
        }
      };

      request
        .get(
          `/api/v2/score/class/correct-answer/${selectedModuleInfo.testModuleSeq}?classSeq=${classSeq}&customSetSeq=${queryCurScoreTestSeq}`,
          null,
          successHandler
        )
        .catch((error) => console.error('', error));
    };
    const getClassTimePerQuestion = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const classTimePerQuestion = response.result.classTimePerQuestion;
          const gateTimePerQuestion = response.result.gateTimePerQuestion;

          setTimePerQuestionData({
            targetTimePerQuestion: classTimePerQuestion,
            gateTimePerQuestion: gateTimePerQuestion,
          });
        } else {
          console.error('Error: ', response);
        }
      };

      request
        .get(`/api/v2/score/class/time-per-question/${selectedModuleInfo.testModuleSeq}?classSeq=${classSeq}`, null, successHandler)
        .catch((error) => console.error('', error));
    };

    const getStudentCorrectAnswerRate = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentCorrectAnswerByQuestion = response.result.studentCorrectAnswerByQuestion.sort((a, b) => a.questionOrder - b.questionOrder);
          setSelectedModuleChoiceRate(fetchedStudentCorrectAnswerByQuestion);
        } else {
          console.error('Error: ', response);
        }
      };

      request
        .get(
          `/api/v2/score/student/correct-answer/${queryCurTestSeq}?testModuleSeq=${selectedModuleInfo.testModuleSeq}&classSeq=${querySelTestClassSeq}&uthSeq=${queryCurUthSeq}`,
          null,
          successHandler
        )
        .catch((error) => console.error('', error));
    };
    const getStudentTimePerQuestion = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentTimePerQuestion = response.result.studentTimePerQuestion;
          const fetchedGateTimePerQuestion = response.result.gateTimePerQuestion;
          setTimePerQuestionData({
            targetTimePerQuestion: fetchedStudentTimePerQuestion,
            gateTimePerQuestion: fetchedGateTimePerQuestion,
          });
        } else {
          console.error('Error: ', response);
        }
      };

      const apiURL = getValidParam(queryCurUthSeq)
        ? `/api/v2/score/student/time-per-question/${selectedModuleInfo.testModuleSeq}?uthSeq=${queryCurUthSeq}`
        : `/api/v2/score/student/time-per-question/${selectedModuleInfo.testModuleSeq}`;

      request.get(apiURL, null, successHandler).catch((error) => console.error('', error));
    };

    // 현재 모드별 다른 api 호출
    const isValidModuleInfo = selectedModuleInfo && selectedModuleInfo?.testModuleSeq > 0;

    if (mode === 'class' || mode === 'lecture') {
      if (isValidModuleInfo) {
        getClassCorrectAnswerRate();
        getClassTimePerQuestion();
      }
    }
    if (mode === 'student') {
      if (isValidModuleInfo && getValidParam(queryCurUthSeq)) {
        getStudentCorrectAnswerRate();
        getStudentTimePerQuestion();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModuleInfo, activeSatBox, queryCurUthSeq, querySelTestClassSeq, queryCurTestSeq, queryCurScoreTestSeq, classSeq, forceFetch]);

  return (
    <>
      <article className='box_graph blue active correct_answer_sec'>
        <QuestionsStatisticsHeader
          activeSatBox={activeSatBox}
          setActiveSatBox={setActiveSatBox}
          handleSelectSubject={handleSelectSubject}
          //
          currentTestModuleList={currentTestModuleList}
          selectedModuleInfo={selectedModuleInfo}
          setSelectedModuleInfo={setSelectedModuleInfo}
        />
        <div className='content_area'>
          {/* Questions Stats Table */}
          {(mode === 'class' || mode === 'lecture') && (
            <ClassQuestionsStatsTable
              activeSatBox={activeSatBox}
              testModuleSeq={selectedModuleInfo.testModuleSeq}
              selectedModuleChoiceRate={selectedModuleChoiceRate}
              setForceFetch={setForceFetch}
            />
          )}
          {mode === 'student' && <StudentQuestionsStatsTable selectedModuleChoiceRate={selectedModuleChoiceRate} setForceFetch={setForceFetch} />}

          {/* Time per Question */}
          <div className='box_graph active time_per_question'>
            <div className='top_area'>
              <span className='tit_field'>Time per Question</span>
            </div>
            <div className='chart_area'>
              <TimePerQuestionChartSection activeSatBox={activeSatBox} timePerQuestionData={timePerQuestionData} />
            </div>
          </div>
        </div>
      </article>
      {alert && <MiniAlert text='Student Selected' type='onlyText' active={alert} inactive={() => alertSet(false)} timeOut={1500} />}
    </>
  );
}
