import React, { useState, useCallback, useEffect } from 'react';
// import Calendar from 'react-calendar';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import dayjsTZ from 'utils/functions/time/dayjs-config';

import { getRBCEventColor } from 'utils/Common.utils';

// components
import DateHeader from 'components/_common/calendar/CalendarDateHeader';
import DateCellWrapper from 'components/_common/calendar/CalendarDateCellWrapper';
import CalendarSetTimeModal from 'components/com/tests/_components/CalendarSetTimeModal';
import CustomAlert from 'components/_common/alerts/CustomAlert';

//css
import 'react-big-calendar/lib/css/react-big-calendar.css';
import request from 'utils/Request.utils';
import Toolbar from 'components/com/tests/_components/_SetTestCalendarToolbar';

dayjs.locale('en');
const localizer = dayjsLocalizer(dayjs);

export default function SetTestCalendar(props) {
  const {
    setCurrentDate,
    events,
    setEvents,
    customSetInfo,
    setCustomSetInfo,
    setSelectedTime,
    setSelectedPeriod,
    selectedDate,
    setSelectedDate,
    handleTestConfirm,
    timeLayer,
    setTimeLayer,
    selectedTest,
  } = props;

  const [selectedEventTitle, setSelectedEventTitle] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // customAlert 코드
  const alertAttributeValue = {
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    returnValue: () => {},
  };
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  // 이전 api 호출로 날짜를 불러왔던 흔적 ( 추후 삭제 예정 )
  const [dateValue, setDateValue] = useState();
  useEffect(() => {
    setDateValue(dayjsTZ().toDate());
  }, []);

  const eventsForThisDay = (slotInfo) => {
    const { action, start, end, slots } = slotInfo;

    if (selectedTest === null) return;

    // 선택한 시작 일자가 오늘보다 과거인 경우 ( 시작일을 과거로 잡을수 없다는 안내 )
    if (dayjsTZ(start).isBefore(dayjsTZ(dateValue), 'day')) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the start date of the exam.',
        };
      });
      return;
    }
    setSelectedDate(dayjsTZ(start).format('YYYY-MM-DD'));

    if (slots.length > 1) {
      return;
    } else {
      setTimeLayer(true);
    }

    setSelectedEventTitle(false);
    setSelectedEvent(null);
  };

  const selectDate = (event) => {
    setSelectedEventTitle(event.title);
    setSelectedEvent(event);
    return;
  };

  const closeTimeLayer = () => {
    setTimeLayer(false);
    setSelectedDate(null);
  };

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleClick = (event) => {
    setMousePosition({ x: event.clientX + 20 || 0, y: event.clientY || 0 });
  };

  return (
    <>
      <div className='CalendarTable' onClick={handleClick}>
        <Calendar
          defaultDate={dayjsTZ().toDate()}
          localizer={localizer}
          events={events}
          startAccessor='start'
          endAccessor='end'
          defaultView='month'
          style={{ height: 974 }}
          components={{
            toolbar: (props) => <Toolbar {...props} classInfo={customSetInfo} setClassInfo={setCustomSetInfo} events={events} setEvents={setEvents} />,
            month: {
              dateHeader: (props) => <DateHeader {...props} selectedDate={selectedDate} />,
            },
            dateCellWrapper: DateCellWrapper,
          }} // 이 부분 삭제하면 default 옵션이 나옴
          onSelectSlot={(slotInfo) => {
            eventsForThisDay(slotInfo);
          }}
          eventPropGetter={(event) => {
            const barColorInfo = getRBCEventColor().find((item) => item.name === event.classColor);
            return {
              style: {
                ...barColorInfo,
                marginBottom: '4px',
                borderWidth: '2px',
                borderStyle: 'solid',
                outline: event === selectedEvent ? '2px solid rgb(13, 101, 255, 0.7)' : 'none',
              },
            };
          }}
          onSelectEvent={selectDate}
          selectable
          popup={true}
          onNavigate={(date, _) => {
            setCurrentDate(dayjsTZ(date).toDate());
          }}
          messages={{ showMore: (count) => `${count} More...` }}
        />

        {/*시간 세팅*/}
        {timeLayer && (
          <CalendarSetTimeModal
            btnType='skip'
            mousePosition={mousePosition}
            setTimeLayer={setTimeLayer}
            setSelectedTime={setSelectedTime}
            setSelectedPeriod={setSelectedPeriod}
            confirm={() => handleTestConfirm()}
            close={() => handleTestConfirm('skip')}
          />
        )}
      </div>
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </>
  );
}
