import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import request from 'utils/Request.utils';
import { STUDENT_PARAMS } from 'utils/constants';

import useOutsideClick from 'hooks/useOutsideClick.js';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

import CorrectAnswerRateByQuestions from './_components/sections/correctAnswerRateByQuestions/CorrectAnswerRateByQuestions';
import AnswerRateGraphsSection from './_components/sections/AnswerRateGraphsSection';
import ExamStatisticsGraphSection from './_components/sections/ExamStatisticsGraphSection';
import CheckViewAboutAverageOfGateplus from './_components/CheckViewAboutAverageOfGateplus';
import SectionTitle from 'components/dashboard/_components/common/SectionTitle';
import useUserLevels from 'hooks/useUserLevels';

/** 학생 대시보드 페이지의 "Advanced Report" Section 컴포넌트 */
export default function AdvancedReportSection({ selectedTest, testResult, filterValue, setFilterValue, setTestResult, setLoading, testList, childSeqState }) {
  const { getObfuscatedValue, setBulkObfuscatedValues } = useObfuscatedSearchParam();
  /** 현재 선택 된 시험 세트의 UTH Seq */
  const selectedUthSeq = getObfuscatedValue(STUDENT_PARAMS.UTH_SEQ.KEY);
  /** 현재 선택 된 시험의 과목 문자열 */
  const selectedSubject = getObfuscatedValue(STUDENT_PARAMS.SUBJECT.KEY);
  /** 현재 선택 된 시험의 모듈 번호 문자열 */
  const selectedModuleNum = getObfuscatedValue(STUDENT_PARAMS.MODULE_NUM.KEY);

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo.userLevel);

  /** "View GATEPLUS Average" 체크박스 상태 */
  const [checkViewAboutAverageOfGateplus, setCheckViewAboutAverageOfGateplus] = useState(true);

  /** 현재 Test의 모듈들에 대한 정보 */
  const [currentTestModuleList, setCurrentTestModuleList] = useState([
    {
      // 선택된 모듈의 Test에 대한 정보 ( TEST > MODULES )
      setSubject: '', // "F" | "E" | "M"       => 모듈들의 시험 과목, FullSet(F) 혹은 단일 과목일때(adaptive) ( 영어("E") | 수학("M") )
      setDifficulty: '', // "F" | "E" | "H"    => FullSet(F) 혹은 단일 과목일때(adaptive) ( Easy("E") | Hard("H") )
      // 해당 모듈에 대한 정보
      testModuleSeq: 0, // 선택된 모듈의 시퀀스
      tmSubject: '', // "E" | "M"              => 모듈 과목 ( 영어(E) | 수학(M) )
      tmDifficulty: null, // null | "E" | "H"  => 모듈 난이도 ( Easy("E") | Hard("H") )
      moduleNum: '', // "1" | "2"              => 모듈 번호
    },
  ]);
  /** 현재 선택된 모듈 정보 */
  const [selectedModuleInfo, setSelectedModuleInfo] = useState({
    // 선택된 모듈의 Test에 대한 정보 ( TEST > MODULES )
    setSubject: '', // "F" | "E" | "M"       => 모듈들의 시험 과목, FullSet(F) 혹은 단일 과목일때(adaptive) ( 영어("E") | 수학("M") )
    setDifficulty: '', // "F" | "E" | "H"    => FullSet(F) 혹은 단일 과목일때(adaptive) ( Easy("E") | Hard("H") )
    // 해당 모듈에 대한 정보
    testModuleSeq: 0, // 선택된 모듈의 시퀀스
    tmSubject: '', // "E" | "M"              => 모듈 과목 ( 영어(E) | 수학(M) )
    tmDifficulty: null, // null | "E" | "H"  => 모듈 난이도 ( Easy("E") | Hard("H") )
    moduleNum: '', // "1" | "2"              => 모듈 번호
  });
  const [filteredList, setFilteredList] = useState([]);
  const [perSubject, setPerSubject] = useState(false);
  const [perModuleNum, setPerModuleNum] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [timePerQuestionData, setTimePerQuestionData] = useState({
    series: [
      {
        name: 'You',
        data: [],
        color: '#0068BD',
      },
      {
        name: 'GATE⁺',
        data: [],
        color: '#E3E7ED',
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const xAxisValue = w.globals.labels[dataPointIndex];
          return `<div className="custom-tooltip">Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        fontFamily: 'Roboto, Arial, sans-serif',
        markers: {
          width: 25,
          height: 8,
          radius: 8,
        },
        itemMargin: {
          horizontal: 15,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.7,
          },
        },
      },
    },
  });

  // hook
  const subjectBox = useRef(null);
  useOutsideClick(subjectBox, () => setPerSubject(false));
  const moduleBox = useRef(null);
  useOutsideClick(moduleBox, () => setPerModuleNum(false));

  /** "View GATEPLUS Average" 체크박스 핸들러 */
  const handleCheckViewAboutAverageOfGateplus = () => {
    setCheckViewAboutAverageOfGateplus((current) => !current);
  };

  /** 과목 변경 핸들러 */
  const handleSelectSubject = (subject) => {
    /** 시험 목록 중 영어 시험이 존재 하는지 여부 */
    const isRW = testList.findIndex((item) => {
      return !!item.rwDiff;
    });
    /** 시험 목록 중 수학 시험이 존재 하는지 여부 */
    const isMa = testList.findIndex((item) => {
      return !!item.mathDiff;
    });
    if (isRW !== -1 && isMa !== -1) {
      // 시험 목록 중 두 과목의 데이터가 하나라도 둘 다 존재 할 경우
      setBulkObfuscatedValues({ [STUDENT_PARAMS.SUBJECT.KEY]: subject });
    }
  };

  /** 과목 변화에 따른 실제 구현 함수  */
  const setSubject = (subject) => {
    const subjectValue = subject === STUDENT_PARAMS.SUBJECT.VALUE.RW ? 'E' : 'M';
    setSelectedModuleInfo(currentTestModuleList.find((item) => item.tmSubject === subjectValue && item.moduleNum === '1'));

    setFilterValue((prev) => {
      return {
        ...prev,
        tmSubject: subjectValue,
        color: `${subjectValue === 'E' ? '#008CFF' : '#1FDF9F'}`,
        moduleNum: '1',
        className: `custom-tooltip${subjectValue === 'E' ? '' : '-gate'}`,
      };
    });
  };

  /** 시험 정오표 조회 API 요청 */
  const getQuestionErrata = ({ uthSeq, testModuleSeq, childSeq = null }) => {
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        let questionList = response.result.studentCorrectAnswerByQuestion || [];

        setTestResult((prev) => {
          return {
            ...prev,
            questionList: questionList,
            trhDate: questionList[questionList.length - 1]?.trhRegDate,
            questionAccuracyList: questionList,
          };
        });
      }
      setLoading(false);
    };

    request
      .get(
        `/api/v2/student/dashboard/correct-answer/${testModuleSeq}?uthSeq=${uthSeq}${childSeq ? `${selectedUthSeq ? '&' : '?'}childStudentSeq=${childSeq}` : ''}`,
        null,
        successHandler
      )
      .catch((error) => console.error('학생 대시보드 - 시험 정오표 조회 API 실패', error));
  };

  /** 시험 문제 별 소요 시간 조회 API */
  const timePerQuestion = ({ uthSeq, testModuleSeq, childSeq = null }) => {
    setLoading(true);

    let retryCount = 0; // 재시도 횟수

    const successHandler = (response) => {
      if (response.code === 200) {
        let gateTimePerQuestion = response.result.gateTimePerQuestion;
        let studentTimePerQuestion = response.result.studentTimePerQuestion;

        const gateUseSeconds = gateTimePerQuestion?.map((item) => item.timeSpent);
        const youUseSeconds = studentTimePerQuestion?.map((item) => item.timeSpent);

        if (youUseSeconds && gateUseSeconds) {
          setTimePerQuestionData((prevData) => ({
            ...prevData,
            series: [
              { name: 'You', data: youUseSeconds, color: filterValue.color },
              { name: 'GATE⁺', data: gateUseSeconds, color: '#E3E7ED' },
            ],
            options: {
              ...prevData.options,
              xaxis: {
                categories: youUseSeconds?.map((_, index) => index + 1),
              },
              tooltip: {
                custom: function ({ seriesIndex, dataPointIndex, w }) {
                  const xAxisValue = w.globals.labels[dataPointIndex];
                  const seriesName = w.config.series[seriesIndex]['name'].toUpperCase();

                  if (seriesName === 'YOU' && seriesIndex === 0) {
                    return `<div class=${filterValue.className}>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
                  } else if (seriesName === 'GATE⁺' && seriesIndex === 1) {
                    return `<div class='custom-tooltip-tests'>Question ${xAxisValue}: ${w.config.series[seriesIndex].data[dataPointIndex]}</div>`;
                  }
                },
              },
            },
          }));
          setLoadData((prev) => !prev);
        } else if (retryCount++ < 5) timePerQuestion(uthSeq, testModuleSeq, childSeq);
      }
      setLoading(false);
    };

    request
      .get(
        `/api/v2/student/dashboard/time-per-question/${testModuleSeq}?uthSeq=${uthSeq}${childSeq ? `${selectedUthSeq ? '&' : '?'}childStudentSeq=${childSeq}` : ''}`,
        null,
        successHandler
      )
      .catch((error) => console.error('학생 대시보드 - 시험 문제 별 소요 시간 조회 API 실패', error));
  };

  useEffect(() => {
    if (!useLevelsIsLoading && selectedUthSeq && selectedModuleInfo && selectedModuleInfo.testModuleSeq) {
      if (useLevels.isParent) {
        // 학부모 계정 일 경우
        if (!childSeqState.isLoading && childSeqState.value) {
          // 자식 시퀸스 로딩이 끝났을 경우에만 실행
          // getCategoriesRate(selectedUthSeq, childSeqState.value);
          timePerQuestion({ uthSeq: selectedUthSeq, testModuleSeq: selectedModuleInfo.testModuleSeq, childSeq: childSeqState.value });
          getQuestionErrata({ uthSeq: selectedUthSeq, testModuleSeq: selectedModuleInfo.testModuleSeq, childSeq: childSeqState.value });
        }
      } else {
        // 학부모 계정이 아닐 경우
        // getCategoriesRate(selectedUthSeq);
        timePerQuestion({ uthSeq: selectedUthSeq, testModuleSeq: selectedModuleInfo.testModuleSeq });
        getQuestionErrata({ uthSeq: selectedUthSeq, testModuleSeq: selectedModuleInfo.testModuleSeq });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUthSeq, selectedModuleInfo, filterValue.moduleNum, useLevels, useLevelsIsLoading, childSeqState]);

  useEffect(() => {
    // modified by psk 20231220 - 수정 (list 같은 경우 null 체크부터 하고 사용하기)
    if (testResult.questionList != null && testResult.questionList.length > 0) {
      const sortedQuestionList = testResult.questionList.sort((a, b) => a.questionOrder - b.questionOrder);

      setFilteredList(sortedQuestionList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, loadData, testResult.questionAccuracyList, timePerQuestionData]);

  useEffect(() => {
    if (selectedSubject === STUDENT_PARAMS.SUBJECT.VALUE.MATH) {
      setFilterValue((prev) => ({ ...prev, tmSubject: 'M', moduleNum: '1' }));
    } else {
      setFilterValue((prev) => ({ ...prev, tmSubject: 'E', moduleNum: '1' }));
    }

    setSubject(selectedSubject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject]);

  useEffect(() => {
    /** 선택된 Test의 모듈 정보 가져오기 */
    const getTestModules = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedModuleInfoList = response.result.moduleInfoList;
          setCurrentTestModuleList(fetchedModuleInfoList);

          // F: fullSet, E: RW, M: Math
          // E(영어) 거나 F(FullSet) 이면 RW 과목의 첫번째 모듈 선택, M(수학) 이면 Math의 첫번째 모듈 선택
          if (fetchedModuleInfoList[0]?.setSubject === 'E' || fetchedModuleInfoList[0]?.setSubject === 'F') {
            setSelectedModuleInfo(fetchedModuleInfoList.find((item) => item.tmSubject === 'E' && item.moduleNum === '1'));
          } else if (fetchedModuleInfoList[0]?.setSubject === 'M') {
            setSelectedModuleInfo(fetchedModuleInfoList.find((item) => item.tmSubject === 'M' && item.moduleNum === '1'));

            // 모듈 정보가 없을 경우 (시험이 삭제됨 or 기타 이유로 정보가 없음)
          } else {
            console.error('Error: 해당 시험에 모듈 정보 없음', fetchedModuleInfoList);
          }
        } else {
          console.error('Error: ', response);
        }
      };

      request
        .get(`/api/dsat/customsets${selectedTest ? `/${selectedTest.customSetSeq}` : ''}/modules`, null, successHandler)
        .catch((error) => console.error('', error));
    };

    !!selectedTest && getTestModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTest]);

  useEffect(() => {
    /** 현재 시험의 테스트 모듈 목록 중 선택한 옵션(과목, 모듈 번호)에 해당하는 목록 얻기 */
    const getFilterCurrentTestModuleList = ({ currentTestModuleList, moduleNumber, subject, selectedTest }) => {
      /** 선택 한 모듈 */
      const selectedModule = currentTestModuleList.filter((item) => {
        /** 과목 비교 조건 */
        const conditionOfSubject = item.tmSubject === subject;
        /** Module 번호 비교 조건 */
        const conditionOfModuleNum = item.moduleNum === moduleNumber;
        /** 난이도 비교 조건 */
        const conditionOfDifficulty = () => {
          if (item.moduleNum === STUDENT_PARAMS.MODULE_NUM.VALUE.MODULE_1) {
            return true;
          } else {
            // 모듈 2 일 경우,
            if (item.tmSubject === STUDENT_PARAMS.SUBJECT.VALUE.RW) {
              // 현재 선택한 영어 시험 기록의 난이도와 비교
              return item.tmDifficulty === selectedTest.rwDiff;
            } else {
              // 현재 선택한 수학 시험 기록의 난이도와 비교
              return item.tmDifficulty === selectedTest.mathDiff;
            }
          }
        };

        return conditionOfModuleNum && conditionOfSubject && conditionOfDifficulty();
      });
      return selectedModule;
    };

    if (selectedSubject && selectedModuleNum && currentTestModuleList.length > 0 && selectedTest) {
      /** 선택 한 모듈 목록 */
      const selectedModule = getFilterCurrentTestModuleList({ currentTestModuleList, moduleNumber: selectedModuleNum, subject: selectedSubject, selectedTest });
      /** 선택한 모듈 시퀸스 */
      const selectedModuleSeq = selectedModule[0]?.testModuleSeq;

      setSelectedModuleInfo((current) => {
        return {
          ...current,
          testModuleSeq: selectedModuleSeq,
        };
      });
    }
  }, [currentTestModuleList, selectedModuleNum, selectedSubject, selectedTest]);

  // 리뷰 창(새 탭, /student/review)에서 북마크 클릭시 호출
  window.getParentModule = (uthSeq) => {
    if (useLevels.isParent) {
      // 학부모 계정 일 경우
      if (!childSeqState.isLoading && childSeqState.value) {
        getQuestionErrata({ uthSeq, testModuleSeq: selectedModuleInfo.testModuleSeq, childSeq: childSeqState.value });
      }
    } else {
      getQuestionErrata({ uthSeq, testModuleSeq: selectedModuleInfo.testModuleSeq });
    }
  };

  return (
    <S.Wrap className='full com_container'>
      <div className='top_area'>
        <SectionTitle>ADVANCED REPORT</SectionTitle>
        <CheckViewAboutAverageOfGateplus checkState={checkViewAboutAverageOfGateplus} handleChange={handleCheckViewAboutAverageOfGateplus} />
      </div>
      <S.GraphContainer>
        <S.GraphBoxContainer>
          <AnswerRateGraphsSection
            handleSelectSubject={handleSelectSubject}
            checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
            testList={testList}
            childSeqState={childSeqState}
          />

          <ExamStatisticsGraphSection checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus} childSeqState={childSeqState} />
        </S.GraphBoxContainer>
      </S.GraphContainer>
      {selectedUthSeq && (
        <CorrectAnswerRateByQuestions
          perSubject={perSubject}
          setPerSubject={setPerSubject}
          setPerModuleNum={setPerModuleNum}
          subjectBox={subjectBox}
          filterValue={filterValue}
          testResult={testResult}
          perModuleNum={perModuleNum}
          moduleBox={moduleBox}
          setFilterValue={setFilterValue}
          filteredList={filteredList}
          timePerQuestionData={timePerQuestionData}
        />
      )}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    flex-wrap: wrap;
    margin-top: 0;
    position: relative;

    .top_area {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.5rem;
    }

    .main_box {
      padding: 1.25rem 1.5rem;
    }

    .com_sec_tit {
      width: 100%;
    }

    .boxes_wrap {
      display: flex;
      gap: 1rem;
    }
  `,

  GraphContainer: styled.section`
    width: 100%;
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
  `,

  GraphBoxContainer: styled.section`
    width: 100%;
    display: flex;
    gap: 1.25rem;
    flex-direction: column;

    .average_time_container {
      display: flex;
      flex-direction: row;
      gap: 1.25rem;
    }
  `,
};
