//#region    ////////// packages
import { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import Draggable from 'react-draggable';
//#endregion ////////// packages

//#region    ////////// assets
import { ReactComponent as IconCollapse } from 'assets/img/svg/exam-icon/icon_collapse.svg';
import { ReactComponent as IconExpand } from 'assets/img/svg/exam-icon/icon_expand.svg';
import { ReactComponent as IconMoveIndicator } from 'assets/img/svg/exam-icon/icon_move_indicator.svg';
import { ReactComponent as IconArrow } from 'assets/img/svg/icon_arrow_02.svg';
import { ReactComponent as IconClose } from 'assets/img/svg/x-close.svg';
//#endregion ////////// assets

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const MIN_READER_HEIGHT = 30;
const MEDIUM_READER_HEIGHT = 60;
const MAX_READER_HEIGHT = 90;
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** AP 시험 라인 리더 컴포넌트 */
export default function LineReaderPopup({ isShowLineReader, setIsShowLineReader }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [isPopupExpand, setIsPopupExpand] = useState(false); // 팝업 창 확장
  const [isDragging, setIsDragging] = useState(false); // 드래그 상태
  // 드래그 상태 관리
  const [dragState, setDragState] = useState({
    activeDrags: 0,
    deltaPosition: { x: 0, y: 0 },
    controlledPosition: { x: -400, y: 200 },
  });
  const [readerHeight, setReaderHeight] = useState(MIN_READER_HEIGHT); // 라인 리더 높이
  const [isIncreasing, setIsIncreasing] = useState(false); // 높이 증가 방향 추적
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const nodeRef = useRef(null);
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const onStart = () => setDragState({ ...dragState, activeDrags: ++dragState.activeDrags });
  const onStop = () => setDragState({ ...dragState, activeDrags: --dragState.activeDrags });
  const dragHandlers = { onStart, onStop };

  // 라인 리더 높이 조절 함수
  const handleHeightChange = () => {
    if (readerHeight === MAX_READER_HEIGHT) {
      setReaderHeight(MEDIUM_READER_HEIGHT);
      setIsIncreasing(false);
    } else if (readerHeight === MEDIUM_READER_HEIGHT) {
      if (isIncreasing) {
        setReaderHeight(MAX_READER_HEIGHT);
      } else {
        setReaderHeight(MIN_READER_HEIGHT);
      }
    } else if (readerHeight === MIN_READER_HEIGHT) {
      setReaderHeight(MEDIUM_READER_HEIGHT);
      setIsIncreasing(true);
    }
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  if (!isShowLineReader) return null;
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <Draggable nodeRef={nodeRef} handle='strong' {...dragHandlers}>
      <S.PopupContainer ref={nodeRef} $isExpanded={isPopupExpand}>
        <S.PopupHeader onMouseDown={() => setIsDragging(true)} onMouseUp={() => setIsDragging(false)}>
          <S.MoveIndicator $isDragging={isDragging}>
            <IconMoveIndicator />
          </S.MoveIndicator>
          <div className='button_area'>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsPopupExpand(!isPopupExpand);
              }}>
              {isPopupExpand ? <IconCollapse /> : <IconExpand />}
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsShowLineReader(false);
              }}>
              <IconClose />
            </button>
          </div>
        </S.PopupHeader>

        <S.PopupBody
          $arrowDirection={(isIncreasing && readerHeight === MAX_READER_HEIGHT) || (!isIncreasing && readerHeight >= MEDIUM_READER_HEIGHT) ? 'up' : 'down'}>
          <S.LineReaderHole $height={readerHeight} />
          <button className='arrow_button' onClick={handleHeightChange}>
            <IconArrow />
          </button>
        </S.PopupBody>
      </S.PopupContainer>
    </Draggable>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  PopupContainer: styled.div`
    position: fixed;
    left: 0rem;
    top: 64px;
    z-index: 1000;
    width: 50vw;
    height: 240px;
    ${({ $isExpanded }) =>
      $isExpanded &&
      css`
        width: 100vw;
      `}
  `,

  PopupHeader: styled.strong`
    background-color: rgba(0, 0, 0, 0.85);
    position: relative;
    color: #fff;
    height: 48px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .button_area {
      display: flex;
      align-items: center;
      gap: 16px;

      button {
        background-color: transparent;
        cursor: pointer;
        color: white;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25%;
        padding: 0.5rem;
        border: 0.0625rem solid transparent;
        transition: all 0.2s;

        &:hover {
          border: 0.0625rem solid rgba(255, 255, 255, 0.5);
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    cursor: grab;
    :active {
      cursor: grabbing;
    }
    user-select: none;
  `,
  MoveIndicator: styled.div`
    padding: 4px 6px;
    width: 40px;
    height: 24px;
    pointer-events: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: background-color 0.4s;
    color: white;
    border-radius: 4px;
    ${({ $isDragging }) =>
      $isDragging &&
      css`
        background-color: rgba(255, 255, 255, 0.6);
      `}
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  `,
  PopupBody: styled.div`
    border: 0.0625rem solid #1e1e1e;
    border-top: none;
    height: 208px;
    position: relative;
    overflow: hidden;

    .arrow_button {
      position: absolute;
      bottom: 7%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: white;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25%;

      padding: 0.5rem;
      transition:
        background-color 0.2s,
        border 0.2s,
        transform 0.4s;
      border: 1px solid transparent;
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
      ${({ $arrowDirection }) =>
        $arrowDirection === 'up'
          ? css`
              transform: translateX(-50%) translateY(-50%) rotate(180deg);
            `
          : css`
              transform: translateX(-50%) translateY(-50%) rotate(0deg);
            `}
    }
  `,

  LineReaderHole: styled.div`
    border-radius: 0.25rem;
    width: calc(100% - 10%);
    height: ${(props) => props.$height}px;
    background-color: transparent;
    position: absolute;
    left: 50%;
    top: 48px;
    transform: translateX(-50%);
    box-shadow: 0 0 0 125rem rgba(0, 0, 0, 0.85);
  `,
};
