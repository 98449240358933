import {
  ADD_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  UPDATE_AP_TEST_SEQ,
  CLEAR_AP_TEST_EDITOR,
  UPDATE_CURRENT_QUESTION_INDEX,
  UPDATE_CURRENT_PART_DATA,
  UPDATE_CURRENT_SECTION_DATA,
  SECTION_META_DATA,
  ADD_SOURCE,
} from './actionTypes';

////// "ap test editor" 관련 전역 상태 리덕스 액션 ///////
export const addQuestion = (question) => ({
  type: ADD_QUESTION,
  payload: question,
});
export const updateQuestion = (partSeq, questionOrder, question) => {
  return {
    type: UPDATE_QUESTION,
    payload: { partSeq, questionOrder, question },
  };
};
export const deleteQuestion = (index) => ({
  type: DELETE_QUESTION,
  payload: index,
});
export const updateApTestSeq = (test_seq) => ({
  type: UPDATE_AP_TEST_SEQ,
  payload: test_seq,
});
export const updateCurrentQuestionIndex = (index) => ({
  type: UPDATE_CURRENT_QUESTION_INDEX,
  payload: index,
});
export const updateCurrentPartData = (partData) => ({
  type: UPDATE_CURRENT_PART_DATA,
  payload: partData,
});
export const updateCurrentSectionData = (sectionData) => ({
  type: UPDATE_CURRENT_SECTION_DATA,
  payload: sectionData,
});

export const updateSectionMetadata = (sectionMetadata) => ({
  type: SECTION_META_DATA,
  payload: sectionMetadata,
});
export const clearApTestEditor = () => ({
  type: CLEAR_AP_TEST_EDITOR,
});
/** "ap test editor" 전역 상태 업데이트 액션 *
 * @param {Object} 
 * {
  part_seq: 0,
  source_directions: '',
  source_order: 0,
  source_content: '',
  question_order: 0,
  question_type: '',
  question_content: '',
  question_context: '',
  answer_options: {
    options: [],
    total: 0,
    answerCount: 0,
  },
  answer: [],
  score: 0,
}
//  */
// export const updateApTestEditor = (payload) => {
//   return {
//     type: UPDATE_AP_TEST_EDITOR,
//     payload,
//   };
// };

// /** "ap test editor" 전역 상태 초기화 액션 */
// export const clearApTestEditor = () => {
//   return {
//     type: CLEAR_AP_TEST_EDITOR,
//   };
// };
