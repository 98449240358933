import { useState } from 'react';
import styled from 'styled-components';

import usePressESC from 'hooks/usePressESC.js';

import { ReactComponent as ImgSvgIconArrow } from 'assets/img/svg/icon_arrow_02.svg';

/** ap/com/score/ 페이지에서 사용 할 "커스텀 디자인 셀렉트 박스" 스타일 용 컴포넌트 */
export default function CustomSelectBox({ optionsArray = [], selectedValue, defaultText = '', handleSelectCallback, disabled = false }) {
  if (!optionsArray) return;

  /** 드롭다운 영역 노출 여부 */
  const [isView, setIsView] = useState(false);

  usePressESC(() => {
    setIsView(false);
  });

  /** 드롭다운 영역 열기/닫기 핸들러 */
  const handleDropdownToggle = () => {
    setIsView((current) => !current);
  };

  /** 옵션 선택 핸들러 */
  const handleSelect = (item) => {
    handleSelectCallback(item.sectionSeq ? item.sectionSeq : item.partSeq);
    setIsView(false);
  };

  return (
    <S.Wrap className={` ${isView ? 'on' : ''}`}>
      <button type='button' title='open/close' onClick={handleDropdownToggle} disabled={disabled} className='cover_area'>
        {selectedValue ? selectedValue : defaultText} <ImgSvgIconArrow className='icon_arrow' />
      </button>
      {isView && (
        <div className='dropdown_area'>
          {optionsArray?.map((item, index) => {
            return (
              <button
                key={`key_${index}`}
                type='button'
                title='select'
                onClick={() => {
                  handleSelect(item);
                }}
                className='option'>
                {item.sectionName ? item.sectionName : item.partName}
              </button>
            );
          })}
        </div>
      )}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    position: relative;
    .cover_area {
      position: relative;

      min-width: 9.25rem;
      height: 2.75rem;
      padding: 0.5rem 3.25rem 0.5rem 1.25rem;
      border-radius: 0.25rem;
      border: 1px solid #c5ccd2;
      background: #fff;

      color: #111;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.75rem;
      text-align: left;

      .icon_arrow {
        position: absolute;
        right: 1.25rem;
        top: 0;
        width: 0.875rem;
        height: 100%;
        color: #111111;
        transition: 0.2s;
      }

      &:disabled {
        background-color: #f4f4f4;
        .icon_arrow {
          color: #999999;
        }
      }
    }

    .dropdown_area {
      position: absolute;
      left: 0;
      top: 2.75rem;
      z-index: 10;
      width: 100%;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      overflow-y: auto;
      border: 1px solid #c5ccd2;
      border-top: none;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
      .option {
        width: 100%;
        display: flex;
        padding: 0.5rem 0.625rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;

        background: #fff;

        color: #505050;
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        & + .option {
          border-top: 1px solid #c5ccd2;
        }
      }
    }

    &.on {
      .cover_area {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .icon_arrow {
          transform: rotateZ(180deg);
        }
      }
    }
  `,
};
