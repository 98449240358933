import {
  SET_STUDENT_ID,
  SET_STUDENT_LOGIN_TYPE,
  SET_STUDENT_USER_EMAIL,
  SET_STUDENT_USER_MOBILE,
  SET_STUDENT_AGREE_PRIVACY_POLICY,
  SET_STUDENT_AGREE_TERMS_SERVICE,
  SET_STUDENT_AGREE_NEWS_LETTER,
  SET_STUDENT_USER_NAME,
  SET_STUDENT_SCHOOL_GRADE,
  SET_STUDENT_SCHOOL_LOCATION,
  SET_STUDENT_SCHOOL_NAME,
  SET_STUDENT_SCHOOL_TYPE,
  CLEAR_STUDENT_STATE,
} from './student.actionTypes';
import { USER_LEVELS } from 'utils/constants';

// default state
const initialState = {
  userId: '',
  loginType: '',
  userEmail: '',
  userLevel: USER_LEVELS.STUDENT, // 고정
  userMobile: '',
  userName: '',
  schoolName: '',
  schoolType: '',
  schoolGrade: 0,
  schoolLocation: '',
  agree_privacyPolicy: false,
  agree_termsService: false,
  agree_newsLetter: false,
};

// 전역 상태 Reducer
const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STUDENT_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case SET_STUDENT_LOGIN_TYPE:
      return {
        ...state,
        loginType: action.payload,
      };
    case SET_STUDENT_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload,
      };
    case SET_STUDENT_USER_MOBILE:
      return {
        ...state,
        userMobile: action.payload,
      };
    case SET_STUDENT_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };
    case SET_STUDENT_AGREE_PRIVACY_POLICY:
      return {
        ...state,
        agree_privacyPolicy: action.payload,
      };
    case SET_STUDENT_AGREE_TERMS_SERVICE:
      return {
        ...state,
        agree_termsService: action.payload,
      };
    case SET_STUDENT_AGREE_NEWS_LETTER:
      return {
        ...state,
        agree_newsLetter: action.payload,
      };
    case SET_STUDENT_SCHOOL_GRADE:
      return {
        ...state,
        schoolGrade: action.payload,
      };
    case SET_STUDENT_SCHOOL_LOCATION:
      return {
        ...state,
        schoolLocation: action.payload,
      };
    case SET_STUDENT_SCHOOL_NAME:
      return {
        ...state,
        schoolName: action.payload,
      };
    case SET_STUDENT_SCHOOL_TYPE:
      return {
        ...state,
        schoolType: action.payload,
      };
    case CLEAR_STUDENT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default studentReducer;
