import React, { useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import request from 'utils/Request.utils';
import { nvl, validateEmail } from 'utils/Common.utils';
import SessionStorage from 'utils/SessionStorage.utils';
import LocalStorage from 'utils/LocalStorage.utils';
import clearAppData from 'utils/clearAppData.js';

//img
import ImgLogo from 'assets/img/gateplus_logo_shadow.svg';
import ImgBar from 'assets/img/bar.svg';
import ImgOk from 'assets/img/icon_leave.svg';
import styled from 'styled-components';

/** Account 삭제 팝업 */
const PopDelAccount = ({ setOpenDeleteAccountModal }) => {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  console.log('🚀 ~ PopDelAccount ~ userInfo:', userInfo);
  const [delAccountStep, setDelAccountStep] = useState(0); // 팝업 내용 노출여부
  const [delAccountNotice, setDelAccountNotice] = useState(false); // 삭제 완료 팝업
  const loginType = userInfo.login_type;
  const openDelAccountNotice = () => {
    // setDeleteAccount(false)
    setDelAccountNotice((prev) => !prev);
  };
  const dispatch = useDispatch();

  const emailRef = useRef();
  const passwdRef = useRef();
  const [email, setEmail] = useState();
  const [passwd, setPasswd] = useState();
  const [error, setError] = useState(false);

  const checkAccount = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        setEmail(emailRef.current.value);
        setPasswd(passwdRef.current.value);
        setError(false);
        setDelAccountStep(1);
      } else if (response.code === 401) {
        setError(true);
      }
    };

    request.post(
      `/api/member/profile/user/personal/${userInfo.userSeq}?userId=${emailRef.current.value}&userPasswd=${passwdRef.current.value}&modiUserSeq=${userInfo.userSeq}`,
      null,
      successHandler
    );
  };

  const [selectMessage, setSelectMessage] = useState('It was too difficult to use');

  const handleRadioChange = (event) => {
    setSelectMessage(event.target.nextSibling.textContent);
  };

  const insertFeedback = () => {
    let pms = {
      selectMessage: selectMessage,
      textMessage: text,
      regUserSeq: userInfo.userSeq,
    };

    if (text.length > 40) {
      const successHandler = (response) => {
        // console.log(response);

        if (response.code === 200) {
          delAccount();
        }
      };

      request.post(`/api/member/profile/user/personal/${userInfo.userSeq}/feedback`, pms, successHandler);
    } else setTestError(true);
  };

  const delAccount = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        openDelAccountNotice();
      }
    };
    // google 로그인일 경우
    if (loginType === 'GOOGLE') {
      const _userId = request.tokenDecoder().userId;
      request.del(
        `/api/member/profile/user/personal/${userInfo.userSeq}?userId=${_userId}&userPasswd=${_userId}&modiUserSeq=${userInfo.userSeq}`,
        null,
        successHandler
      );
    } else {
      request.del(
        `/api/member/profile/user/personal/${userInfo.userSeq}?userId=${email}&userPasswd=${passwd}&modiUserSeq=${userInfo.userSeq}`,
        null,
        successHandler
      );
    }
  };

  const nextBtn = () => {
    // google 로그인일 경우 비밀번호 입력 없이 다음으로 넘어감
    if (loginType === 'GOOGLE') {
      setDelAccountStep(1);
      return;
    }
    if (!validateEmail(nvl(emailRef.current.value))) {
      setError(true);
      emailRef.current.focus();
      return;
    } else if (nvl(passwdRef.current.value) === '') {
      setError(true);
      passwdRef.current.focus();
      return;
    }

    checkAccount();
  };

  // feedbak
  const [text, setText] = useState('');
  const [testError, setTestError] = useState(false);

  const handleChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= 450) {
      setText(inputText);
    }
  };

  // 문자열의 길이를 계산하여 91/450 형태의 문자열을 반환하는 함수
  const countCharacters = (text) => {
    const charCount = text.length;
    return `${charCount}/450`;
  };

  const Notice = styled.p`
    .border-bottom-none {
      border-bottom: none !important;
    }
  `;

  // 삭제 성공 후 버튼
  const logout = () => {
    clearAppData(dispatch, userInfo?.userId);

    window.location.replace(process.env.REACT_APP_MAINGATE_ROOT_URL);
  };

  const TextArea = styled.textarea`
    width: 100%;
    height: 86px;
    border: 1px solid #d2dbe2;
  `;

  return (
    <>
      <div className='com_popup type_dim pop_del_account active'>
        <div className='pop_container l'>
          <section className='pop_header'>
            <h1 className='pop_tit'>DELETE ACCOUNT</h1>
            <button className='svg_icon btn_pop_close gray' onClick={() => setOpenDeleteAccountModal(false)}>
              &nbsp;
            </button>
          </section>
          <section className='pop_body'>
            {delAccountStep === 0 ? (
              <>
                <ul className='com_input_sec'>
                  <Notice className='notice'>
                    <p className='tit'>Deleting your account?</p>
                    <p className={`txt ${loginType === 'GOOGLE' ? 'border-bottom-none' : ''}`}>
                      You have <b className='com_txt_point'>1</b> profiles under your account. If you choose to delete your account, you will be deleting all
                      the profiles under your account
                    </p>
                  </Notice>
                  {loginType === 'GOOGLE' ? (
                    <></>
                  ) : (
                    <li className='item'>
                      <p className='tit_field'>Re-enter your email and password</p>
                      <div className={`input_field ${error ? 'error' : ''}`}>
                        {/*틀렸을 경우 addClass error*/}
                        <div className='com_input type_icon'>
                          <i className='svg_icon icon_email gray'>&nbsp;</i>
                          <input type='text' placeholder='Enter your Email' required='required' ref={emailRef} />
                          <input type='button' className='btn_del' onClick={() => (emailRef.current.value = '')} />
                        </div>
                        <div className='com_input type_icon'>
                          <i className='svg_icon icon_lock gray'>&nbsp;</i>
                          <input type='password' placeholder='Enter your password' required='required' autoComplete='off' ref={passwdRef} />
                          <input type='button' className='btn_del' onClick={() => (passwdRef.current.value = '')} />
                        </div>
                        <p className='error_message'>You have typed incorrect email or password. Please try again.</p>
                      </div>
                    </li>
                  )}
                </ul>
              </>
            ) : delAccountStep === 1 ? (
              <>
                <ul className='com_input_sec type_feedback'>
                  <li className='notice'>
                    <p className='tit'>we would like to hear your feedback</p>
                    <p className='txt'>we’d appreciate it if you would take a moment to let us know why you are deleting your account.</p>
                  </li>
                  <li className='item'>
                    <div className='input_field'>
                      <input type='radio' name='radio111' className='com_chk radio' id='radio1' defaultChecked onChange={handleRadioChange} />
                      <label htmlFor='radio1'>It was too difficult to use</label>
                    </div>
                    <div className='input_field'>
                      <input type='radio' name='radio111' className='com_chk radio' id='radio2' onChange={handleRadioChange} />
                      <label htmlFor='radio2'>It was too expensive</label>
                    </div>
                    <div className='input_field'>
                      <input type='radio' name='radio111' className='com_chk radio' id='radio3' onChange={handleRadioChange} />
                      <label htmlFor='radio3'>I purchased the wrong product</label>
                    </div>
                    <div className='input_field'>
                      <input type='radio' name='radio111' className='com_chk radio' id='radio4' onChange={handleRadioChange} />
                      <label htmlFor='radio4'>Not enough product support</label>
                    </div>
                    <div className='input_field'>
                      <input type='radio' name='radio111' className='com_chk radio' id='radio5' onChange={handleRadioChange} />
                      <label htmlFor='radio5'>Service no longer needed</label>
                    </div>
                    <div className='input_field'>
                      <input type='radio' name='radio111' className='com_chk radio' id='radio6' onChange={handleRadioChange} />
                      <label htmlFor='radio6'>We’re switching to another provider</label>
                    </div>
                    <div className={`input_field ${testError && 'error'}`}>
                      <textarea className='textarea' style={{ border: '1px solid #d2dbe2', height: '86px' }} onChange={handleChange} value={text}></textarea>
                      <p className='textarea_tip'>{countCharacters(text)}</p>
                      <p className='error_message'>Please enter more than 40 characters.</p>
                    </div>
                  </li>
                </ul>
              </>
            ) : (
              <></>
            )}
          </section>
          <section className='pop_footer com_btn_wrap'>
            <button className='com_btn xl point line' onClick={() => setOpenDeleteAccountModal(false)}>
              Cancel
            </button>
            {delAccountStep === 0 ? (
              <>
                <button className='com_btn xl point' onClick={nextBtn}>
                  Next
                </button>
              </>
            ) : (
              delAccountStep === 1 && (
                <>
                  <button className='com_btn xl point' onClick={insertFeedback}>
                    Next
                  </button>
                </>
              )
            )}
          </section>
        </div>
      </div>
      {/*삭제 완료 팝업*/}
      {delAccountNotice && (
        <div className='com_popup type_alert active'>
          <div className='pop_container'>
            <div className='pop_body ok_contents'>
              <div className='logo'>
                <img src={ImgLogo} alt='' />
              </div>
              <div className='bar'>
                <img src={ImgBar} alt='' />
              </div>
              <p className='alert_title'>We are sorry to let you go...</p>
              <div className='alert_text com_txt_point'>
                We strive to make a better service for all and
                <br />
                hopefully we can see you next time!{' '}
              </div>
              <div className='icon'>
                <img src={ImgOk} alt='' />
              </div>
              {/* <div className="alert_text">Your account info <b className="highlight">will be kept for 30 days.</b><br />
              You can always come back and retrieve your account during this period.</div> */}
              <div className='alert_text'>
                Your information will be kept for a limited period <br />
                according to our Privacy Policy. <br />
                If you want to reactivate your account, please contact us.
              </div>
            </div>
            <div className='pop_footer com_btn_wrap'>
              <button className='com_btn point xl' onClick={logout}>
                Go to GATE+ Home
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopDelAccount;
