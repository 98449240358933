import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { calculatePercentage } from 'utils/Common.utils';
import request from 'utils/Request.utils';

import ChartContainer from './_components/ChartContainer';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { STUDENT_PARAMS } from 'utils/constants';
import useUserLevels from 'hooks/useUserLevels';

/** OverallAverageScore에서 정오답 정보를 보여주는 컴포넌트 */
export default function OverallAverageScore({ childSeqState }) {
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** 현재 선택 된 시험 세트의 UTH Seq */
  const selectedUthSeq = getObfuscatedValue(STUDENT_PARAMS.UTH_SEQ.KEY);

  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo.userLevel);

  /** 선택된 시험에 대한 다른 유저들의 평균 점수 */
  const [scorePercentile, setScorePercentile] = useState({
    userSeq: 0, // Number         => 유저 시퀀스
    mathScore: 0, // Number       => 수학 점수
    rwScore: 0, // Number         => 영어 점수
    mathPercentile: 0, // Number  => 수학 백분위
    rwPercentile: 0, // Number    => 영어 백분위
  });
  /** 선택된 시험에 대한 다른 유저들의 정답률 */
  const [scoreCorrectRate, setScoreCorrectRate] = useState([
    {
      subject: '', // "E" | "M"  => 시험 과목 ( 영어("E") | 수학("M") )
      correctRate: 0, // Number  => 정답률
      omittedRate: 0, // Number  => 안푼 문제 비율
      wrongRate: 0, // Number    => 틀린 문제 비율
    },
  ]);

  // 선택된 정답률 인덱스
  const [RWSelectedRateIndex, setRWSelectedRateIndex] = useState(0);
  const [mathSelectedRateIndex, setMathSelectedRateIndex] = useState(0);

  useEffect(() => {
    /** 선택된 학생의 TestList를 불러오는 API 요청 함수 */
    const getTestScoreAverage = ({ selectedUthSeq, childSeq = null }) => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentTestScorePercentile = response.result.studentTestScorePercentile[0];
          const fetchedStudentTestCorrectRate = response.result.studentTestCorrectRate;

          if (fetchedStudentTestScorePercentile) setScorePercentile(fetchedStudentTestScorePercentile);
          if (fetchedStudentTestCorrectRate) setScoreCorrectRate(fetchedStudentTestCorrectRate);
        }
      };
      request
        .get(
          `/api/v2/student/dashboard/tests/average-score${selectedUthSeq ? `?uthSeq=${selectedUthSeq}` : ''}${childSeq ? `${selectedUthSeq ? '&' : '?'}childStudentSeq=${childSeq}` : ''}`,
          null,
          successHandler
        )
        .catch((error) => console.error('', error).finally(console.log('데이터 호출 완료')));
    };

    if (!useLevelsIsLoading) {
      if (useLevels.isParent) {
        // 학부모 계정 일 경우
        if (!childSeqState.isLoading && childSeqState.value) {
          // 자식 시퀸스 로딩이 끝났을 경우에만 실행
          getTestScoreAverage({ selectedUthSeq, childSeq: childSeqState.value });
        }
      } else {
        // 학부모 계정이 아닐 경우
        getTestScoreAverage({ selectedUthSeq });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUthSeq, childSeqState, useLevels, useLevelsIsLoading]);

  /** scoreRate가 없을때 에러가 나오지 않게 해주는 초기화 객체 */
  const initScoreCorrectRate = { correctRate: 0, omittedRate: 0, subject: '', wrongRate: 0 };
  // 영어, 수학 정답률
  const RWCorrectRate = scoreCorrectRate.find((item) => item.subject === 'E') ?? initScoreCorrectRate;
  const mathCorrectRate = scoreCorrectRate.find((item) => item.subject === 'M') ?? initScoreCorrectRate;

  return (
    <S.Wrap>
      <h2>{!!selectedUthSeq ? '' : 'Overall Average'} Score</h2>
      <S.ContentContainer>
        <ChartContainer
          subject='RW'
          fillColor='#008CFF'
          chartData={{
            // 단일 값 차트 ( radialBar 차트 )
            chart01: {
              score: Math.round(scorePercentile.rwScore),
              totalScore: 800,
              label: `Percentile ${Math.round(calculatePercentage(scorePercentile.rwScore, 800))}%`,
            },
            // 다중 값 차트 ( donut 차트 )
            chart02: {
              data: [
                { value: RWCorrectRate.correctRate, color: '#008cff', label: 'correct' },
                { value: RWCorrectRate.wrongRate, color: '#929292', label: 'incorrect' },
                { value: RWCorrectRate.omittedRate, color: '#fafaf8', label: 'omit' },
              ],
              label: ['correct', 'incorrect', 'omit'][RWSelectedRateIndex],
              handleClickData: (index) => setRWSelectedRateIndex(index),
            },
          }}
          stats={[
            { iconClass: 'rw', label: 'Total Correct', value: `${RWCorrectRate.correctRate}%` },
            { iconClass: 'incorrect', label: 'Total Incorrect', value: `${RWCorrectRate.wrongRate}%` },
            { iconClass: '', label: 'Total Omitted', value: `${RWCorrectRate.omittedRate}%` },
          ]}
        />
        <hr />
        <ChartContainer
          subject='MA'
          fillColor='#1fdf9f'
          chartData={{
            // 단일 값 차트 ( radialBar 차트 )
            chart01: {
              score: scorePercentile.mathScore,
              totalScore: 800,
              label: `Percentile ${Math.round(calculatePercentage(scorePercentile.mathScore, 800))}%`,
            },
            // 다중 값 차트 ( donut 차트 )
            chart02: {
              data: [
                { value: mathCorrectRate.correctRate, color: '#1fdf9f', label: 'correct' },
                { value: mathCorrectRate.wrongRate, color: '#929292', label: 'incorrect' },
                { value: mathCorrectRate.omittedRate, color: '#fafaf8', label: 'omit' },
              ],
              label: ['correct', 'incorrect', 'omit'][mathSelectedRateIndex],
              handleClickData: (index) => setMathSelectedRateIndex(index),
            },
          }}
          stats={[
            { iconClass: 'math', label: 'Total Correct', value: `${mathCorrectRate.correctRate}%` },
            { iconClass: 'incorrect', label: 'Total Incorrect', value: `${mathCorrectRate.wrongRate}%` },
            { iconClass: '', label: 'Total Omitted', value: `${mathCorrectRate.omittedRate}%` },
          ]}
        />
      </S.ContentContainer>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    flex: 5.7;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    > h2 {
      font-weight: 600;
      font-size: 1.25rem;
    }

    .answer_data_container {
      display: flex;
      gap: 1.5rem;
    }
  `,
  ContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    hr {
      background-color: #f1f1f5;
      height: 1px;
      border: 0;
    }
  `,
};
