import React, { useRef } from 'react';
import styled from 'styled-components';

import useOutsideClick from 'hooks/useOutsideClick';
import { nvl } from 'utils/Common.utils';

import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod'; //팝업 달력
import CustomSelect from 'components/_common/inputs/CustomSelect';

/** student main - Table_Header_검색영역 */
export default function SearchArea({ setSearchInfo, setStudentInfo, searchInfo }) {
  const searchKeywordRef = useRef();
  const searchBox = useRef(null);
  useOutsideClick(
    searchBox,
    () =>
      setSearchInfo((prev) => {
        return { ...prev, searchFieldActive: false };
      }),
    searchInfo.searchFieldActive
  );

  const searchFiledClick = (value) => {
    setSearchInfo((prev) => {
      return { ...prev, searchFieldActive: false, setSearchField: value };
    });
  };

  const keywordSearchDo = (e) => {
    // console.log(nvl(searchKeywordRef.current.value));

    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: nvl(searchKeywordRef.current.value) };
    });
  };

  const limitSelect = (value) => {
    let limit = value;

    setStudentInfo((prev) => {
      return { ...prev, numPerPage: value };
    });
  };

  return (
    <S.Wrap className='search_area'>
      {/* <PopupCalendarPeriod setSearchInfo={setSearchInfo} /> */}
      <div
        className={`com_select_layer ${searchInfo.searchFieldActive ? 'active' : ''}`}
        onClick={() =>
          setSearchInfo((prev) => {
            return {
              ...prev,
              searchFieldActive: !searchInfo.searchFieldActive,
            };
          })
        }
        ref={searchBox}>
        {/*클래스 active 포함 시 옵션 노출*/}
        <input type='text' className='selected search_area_input' placeholder={searchInfo.setSearchField} />
        <div className='layer'>
          <p className='option' onClick={() => searchFiledClick('Name')}>
            Name
          </p>
          <p className='option' onClick={() => searchFiledClick('Class')}>
            Class
          </p>
        </div>
      </div>
      <div className='search'>
        <input
          type='text'
          placeholder='Enter keywords'
          className='input search_area_input'
          ref={searchKeywordRef}
          onKeyUp={(e) => (e.key === 'Enter' ? keywordSearchDo() : null)}
        />
        <button className='com_btn m blue btn_search' onClick={keywordSearchDo}>
          <i className='svg_icon icon_search blue before'></i>
          SEARCH
        </button>
      </div>
      <CustomSelect
        options={[
          { label: '10 line', value: 10 },
          { label: '25 line', value: 25 },
          { label: '50 line', value: 50 },
          { label: '100 line', value: 100 },
        ]}
        onSelect={limitSelect}
      />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.article`
    .search_area_input {
      width: 6.25rem;
    }
  `,
};
