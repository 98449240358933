import { Navigate, Route, Routes } from 'react-router-dom';

import request from 'utils/Request.utils';

//components
import Footer from 'layout/Footer'; //Footer
import Gnb from 'layout/Gnb';
import Lnb from 'layout/Lnb'; //Lnb

import Profile from 'components/user/profile/Page';
//import StudentList from "../components/student/List";
import StudentDashboard from 'components/dashboard/Page';
import ReviewMain from 'components/student/Review/Main/Page';
import ReportCardPage from 'components/report/Page';
import PDFViewerPage from 'components/student/pdf/Page';

const StudentRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  // console.log('pathname', pathname)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    if (authBool) {
      // 로그인 한 사람만 사용
      const authRoutes = [
        // { path: "/", element: <Students /> },
        { path: '/', element: <Navigate to='/student/dashboard' /> },
        { path: '/dashboard', element: <StudentDashboard /> },
        { path: '/dashboard/:uthSeq', element: <StudentDashboard /> },
        { path: '/report', element: <ReportCardPage /> },
        { path: '/review', element: <ReviewMain /> },
        { path: '/pdf', element: <PDFViewerPage /> },
        { path: '/profile', element: <Profile /> },

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unauthRoutes = [
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unauthRoutes);
    }
  };

  const PathStudents = pathname.toLowerCase().startsWith('/student/dashboard');
  const PathReview = pathname.toLowerCase().startsWith('/student/review');
  const PathReportCard = pathname.toLowerCase().startsWith('/student/report');
  const PathStudentsList = pathname.toLowerCase().startsWith('/student');

  const renderGnb = () => {
    if (PathReportCard || PathReview) return <></>;
    else return <Gnb />;
  };

  const renderLnb = () => {
    //경로에 따른 lnb 노출 설정
    if (PathReportCard || PathReview) return <></>;
    else if (PathStudents || PathStudentsList) return <Lnb />;
    else return <></>;
  };

  const renderFooter = () => {
    //경로에 따른 Footer 노출 설정
    if (PathReview || PathReportCard) return <></>;
    else return <Footer />;
  };

  return (
    <>
      {renderLnb()}
      {renderGnb()}
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      {renderFooter()}
    </>
  );
};

export default StudentRoute;
