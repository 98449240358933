import React from 'react';
import UI_Tooltip from '../UI_Tooltip';
import { useFeedbackTooltip } from './useFeedbackTooltip';

/**
 * 피드백 툴팁 컴포넌트
 * 결과나 사용자 액션에 대한 피드백을 표시하는데 사용됨
 *
 * @param {React.ReactNode} children 툴팁 컴포넌트의 자식 요소
 * @param {Object} tooltipControl 툴팁 제어 객체
 * @param {string} tooltipControl.text 툴팁 텍스트
 * @param {string} tooltipControl.type 툴팁 타입
 * @param {boolean} tooltipControl.isVisible 툴팁 표시 여부
 * @param {boolean} tooltipControl.hoverEnabled 호버시 툴팁 표시 여부
 * @param {number} tooltipControl.autoHideDelay 툴팁 자동 숨김 시간(ms)
 * @param {string} position 툴팁 위치 // top, bottom, left, right
 */
export function FeedbackTooltip({
  children,
  tooltipControl, // text, type, isVisible, hoverEnabled, autoHideDelay
  position = 'top',
}) {
  // 외부에서 받은 상태를 직접 사용
  return (
    <UI_Tooltip
      text={tooltipControl.text}
      position={position}
      type={tooltipControl.type}
      visible={tooltipControl.isVisible}
      hoverEnabled={tooltipControl.hoverEnabled}>
      {children}
    </UI_Tooltip>
  );
}

export default FeedbackTooltip;
export { useFeedbackTooltip };
