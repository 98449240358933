////// "ap test editor" 관련 전역 상태 리덕스 액션 타입 ///////

export const UPDATE_AP_TEST_SEQ = 'UPDATE_AP_TEST_SEQ';
export const ADD_QUESTION = 'ADD_QUESTION';
export const UPDATE_CURRENT_QUESTION_INDEX = 'UPDATE_CURRENT_QUESTION_INDEX';
export const UPDATE_CURRENT_PART_DATA = 'UPDATE_CURRENT_PART_DATA';
export const UPDATE_CURRENT_SECTION_DATA = 'UPDATE_CURRENT_SECTION_DATA';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const SECTION_META_DATA = 'SECTION_META_DATA';
export const CLEAR_AP_TEST_EDITOR = 'CLEAR_AP_TEST_EDITOR';
