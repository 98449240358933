import { useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils';

import { ReactComponent as ImgSvgIconHeartColor } from 'assets/img/svg/icon_heart_01_color.svg';
import { ReactComponent as ImgSvgIconHeartLine } from 'assets/img/svg/icon_heart_01_line.svg';

/** 즐겨찾기 버튼 컴포넌트 */
export default function FavoriteButton({ active, seq, setCustomSetInfo }) {
  const [isLoading, setIsLoading] = useState(false);

  /** 즐겨찾기 버튼 핸들러 */
  const handler = ({ event, seq, active }) => {
    event.stopPropagation(); // 버튼 요소가 겹쳐 있기 때문에, 이벤트 전파 막기

    if (isLoading) return;

    setIsLoading(true);

    /** 해당 커스텀 시험 세트 "좋아요 목록에 추가" API 요청 */
    const postFetch = (seq) => {
      const successHandler = (response) => {
        if (response.code === 201) {
          setCustomSetInfo((current) => {
            const newArray = current.list.map((item) => (item.testSetSeq === seq ? { ...item, isLiked: true } : item));
            return { list: newArray };
          }); // API를 다시 요청하지 않고, 실시간으로 동작을 반영하여 랜더링만 다시 하기 위하여 상태 갱신 (매번 목록 API를 호출 할 수 없기 때문에, 클라이언트 단에서만 리랜더링 해놓는다.)
          setIsLoading(false);
        }
      };
      request.apPost(`/api/v1/academy/exam/test-like?testSetSeq=${seq}`, null, successHandler).catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
    };
    /** 해당 커스텀 시험 세트 "좋아요 목록에서 제거" API 요청 */
    const deleteFetch = (seq) => {
      const successHandler = (response) => {
        setCustomSetInfo((current) => {
          const newArray = current.list.map((item) => (item.testSetSeq === seq ? { ...item, isLiked: false } : item));
          return { list: newArray };
        }); // API를 다시 요청하지 않고, 실시간으로 동작을 반영하여 랜더링만 다시 하기 위하여 상태 갱신 (매번 목록 API를 호출 할 수 없기 때문에, 클라이언트 단에서만 리랜더링 해놓는다.)
        setIsLoading(false);
      };
      request.apDel(`/api/v1/academy/exam/test-like/${seq}`, null, successHandler).catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
    };

    if (active) {
      deleteFetch(seq);
    } else {
      postFetch(seq);
    }
  };

  return (
    <S.Button onClick={(event) => handler({ event, seq, active })} title='Favorites'>
      {active ? <ImgSvgIconHeartColor className='color' /> : <ImgSvgIconHeartLine className='line' />}
    </S.Button>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  Button: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: all 0.2s ease;

    svg {
      width: 1.25rem;
      &.color {
        color: #f24b4b;
      }
      &.line {
        color: #000000;
      }
    }

    &:hover {
      border-color: #f24b4b33; // #f24b4b에 투명도(20%) 추가
      background-color: #f24b4b0d; // #f24b4b에 투명도(5%) 추가
    }
  `,
};
