import { useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils';

// import SvgIconTextBtn from 'components/_common/button/SvgIconTextBtn';
import MiniAlert from 'components/_common/alerts/MiniAlert';
import DeleteModal from 'components/_common/modals/DeleteModal';
import PopClassManage from '../../popup/_PopClassManage';
import PopSuccessfullyDeleted from '../../popup/_PopSuccessfullyDeleted';

import { ReactComponent as ImgSvgIconPlus } from 'assets/img/svg/icon_plus.svg';
import { ReactComponent as ImgSvgIconTrash } from 'assets/img/svg/icon_trash_01.svg';
import { useNavigate } from 'react-router-dom';

/** 아카데미 대시보드 - Students - Classes 페이지의 대표 Table 상단 "버튼" 영역 컴포넌트 */
export default function TopBtnArea({
  setEditClassData,
  setOpenClassManage,
  checkedClassIds,
  setAlertLayerPopup,
  searchInfo,
  searchKeyword,
  searchData,
  searchClasses,
  handleShowEdit,
  openClassManage,
  editClassData,
}) {
  const navigate = useNavigate();
  //////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  const [userInfo, _] = useState(request.tokenDecoder());
  ////////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** Download 버튼 코드 */
  const [btnDownload, setBtnDownload] = useState(false);
  /** Print 버튼 코드 */
  const [btnPrint, setBtnPrint] = useState(false);
  /** Class 삭제 팝업 */
  const [deleteClass, setDeleteClass] = useState(false);
  /** Class 삭제 confirm 팝업 */
  const [deleteClassAlert, setDeleteClassAlert] = useState(false);
  const [allSearchData, setAllSearchData] = useState([]);
  const [actionType, setActionType] = useState('');
  const [alert, setAlert] = useState({ active: false, message: '' });
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- API 호출 --- START ////////////////////
  /** 실제 삭제 동작 API 요청 */
  const deleteClassAlertOpen = () => {
    setDeleteClass(false);
    setDeleteClassAlert(false);

    const successHandler = (response) => {
      if (response.code === 200) {
        searchClasses();
        setAlert({
          active: true,
          message: 'Successfully deleted selected class.',
        });
      }
    };

    const classList = checkedClassIds.join(',');
    request.apDel(`/api/v1/academy/class/delete/${classList}`, null, successHandler);
  };
  ////////////////////  END  --- API 호출 ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////

  /** "Class 관리" 팝업 열기 핸들러 (반 생성) */
  const handleClassManageOpen = (boolean) => {
    setEditClassData(null);
    setOpenClassManage(boolean);
  };

  /** class "삭제" 버튼 핸들러 (확인 팝업 열기) */
  const handleDeleteBtn = () => {
    if (checkedClassIds.length > 0) {
      setDeleteClass(true);
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the student you want to delete.',
        };
      });
    }
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- React useEffect --- START ////////////////////
  ////////////////////  END  --- React useEffect ---  END  ////////////////////

  const apAcademySeq = Number(process.env.REACT_APP_AP_ACADEMY); // ap가 보이는 학원 seq
  const isApAcademy = userInfo?.ecSeq === apAcademySeq;

  return (
    <>
      <S.Wrap className='btn_area'>
        <button type='button' title='Add Student' className='btn_add_student' onClick={() => handleClassManageOpen(true)}>
          <ImgSvgIconPlus />
          Create Class
        </button>

        {isApAcademy && (
          <button type='button' title='go to labeling' className='btn_add_student' onClick={() => navigate('/ap/academy/teacher-import')}>
            <ImgSvgIconPlus />
            teacher subject labelling
          </button>
        )}

        {/* <SvgIconTextBtn
          text='Create Class'
          svgClassName='svg_icon icon_person_add blue'
          bgColor='#DFF1FF'
          color='#4692D1'
          borderColor='none'
          onClick={() => handleClassManageOpen(true)}
        /> */}

        {/* <MiniSvgIconBtn text='Edit' svgClassName='svg_icon icon_write' onClick={() => handleShowEdit()} radius='medium' dataHover /> */}

        {/* <div className='com_btn_drop'>
          <button type='button' title='Download' onClick={toggleBtnDownload} className='btn_icon'>
            <ImgSvgIconDownload />
          </button>
          <div className={`layer ${btnDownload ? 'active' : ''}`}>
            <button className='btn' onClick={excelDownloadSelect}>
              <i className='svg_icon icon_person_select'>&nbsp;</i>
              Selected Classes
            </button>
            <button className='btn' onClick={excelDownloadAll}>
              <i className='svg_icon icon_person_all'>&nbsp;</i>All Classes
            </button>
          </div>
        </div> */}

        {/* <div className='com_btn_drop'>
          <button type='button' title='Print' onClick={toggleBtnPrint} className='btn_icon'>
            <ImgSvgIconPrinter />
          </button>
          <div className={`layer ${btnPrint ? 'active' : ''}`}>
            <button className='btn' onClick={printSelect}>
              <i className='svg_icon icon_person_select'>&nbsp;</i>
              Selected Classes
            </button>
            <button className='btn' onClick={printAll}>
              <i className='svg_icon icon_person_all'>&nbsp;</i>All Classes
            </button>
          </div>
        </div> */}

        <button type='button' title='Delete' onClick={handleDeleteBtn} className='btn_icon'>
          <ImgSvgIconTrash />
        </button>
      </S.Wrap>

      {/*반관리 팝업*/}
      {openClassManage && (
        <PopClassManage handleClassManageOpen={handleClassManageOpen} editClassData={editClassData} searchClasses={searchClasses} setAlert={setAlert} />
      )}

      {/* 학생 삭제 팝업 */}
      {deleteClass && (
        <DeleteModal
          hideModal={() => setDeleteClass(false)}
          callback={deleteClassAlertOpen}
          text={'The data for this class will still be available \n but will not be linked together.'}
        />
      )}

      <PopSuccessfullyDeleted deleteClassAlert={deleteClassAlert} setDeleteClassAlert={setDeleteClassAlert} />

      {alert.active && <MiniAlert text={alert.message} active={alert.active} inactive={() => setAlert({ ...alert, active: false })} timeOut={2000} />}
    </>
  );
}

const S = {
  Wrap: styled.article`
    button {
      transition: 0.2s;
      &:hover {
        filter: brightness(95%);
      }
    }
    .btn_add_student {
      background-color: #f4f8ff;
      color: #0068bd;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.5rem;
      padding: 0.5rem 0.625rem;
      font-weight: 400;
      font-size: 0.875rem;
      svg {
        width: 1.125rem;
      }
    }
    .btn_icon {
      border: 1px solid #eaeaea;
      border-radius: 0.5rem;
      width: 2rem;
      height: 2rem;
      transition: 0.2s;
      background-color: #ffffff;
      svg {
        width: 1rem;
        color: #4b5563;
      }
    }
  `,
};
