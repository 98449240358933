import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import SubButton from 'components/user/_components/common/buttons/SubButton';
import MainBox from 'components/user/_components/layouts/MainBox';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import Label from 'components/user/_components/common/forms/Label';
import Input from 'components/user/_components/common/forms/Input';
import ErrorText from 'components/user/_components/styles/ErrorText';
import Select from 'components/user/_components/common/forms/Select';
import Header from 'components/user/sign-up/_components/layouts/Header';
import Logo from 'components/user/_components/layouts/Logo';
import { updateReduxAcademyAdminCompanyName, updateReduxAcademyAdminOfficePhone } from 'reducers/auth/academyadmin.action';
import { useDispatch, useSelector } from 'react-redux';
/** 회원가입 - 소셜 - 아카데미 어드민 - 학원 정보 입력 페이지 */
export default function SignUpAcademyAdminAcademyInformationPage() {
  const navigate = useNavigate();
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const dispatch = useDispatch();
  // Redux에서 저장된 데이터 가져오기
  const storedCompanyName = useSelector((state) => state.academyAdminReducer.companyName);
  const storedOfficePhone = useSelector((state) => state.academyAdminReducer.officePhone);
  const companyName = watch('companyName', storedCompanyName);
  const officePhone1 = watch('officePhone1', storedOfficePhone.num1);
  const officePhone2 = watch('officePhone2', storedOfficePhone.num2);
  const officePhone3 = watch('officePhone3', storedOfficePhone.num3);

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    console.log('🚀 ~ onValid ~ data:', data);
    dispatch(updateReduxAcademyAdminCompanyName(data.companyName));
    // const officePhone = `${data.officePhone}-${data.officePhone02}-${data.officePhone03}`;
    const officePhone = {
      num1: data.officePhone1,
      num2: data.officePhone2,
      num3: data.officePhone3,
    };
    dispatch(updateReduxAcademyAdminOfficePhone(officePhone));
    navigate('/signup/social/academy-admin/upload');
  };

  /** 뒤로가기 버튼 핸들러 */
  const handleClickBackButton = () => {
    navigate('/signup/social/academy-admin/information-review');
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);
  return (
    <S.Wrap>
      <MainBox>
        <Logo />
        <Header title={'Academy Admin'} />
        <form onSubmit={handleSubmit(onValid)}>
          <p className='guide'>Enter information about your institution</p>
          <FormItemWrap>
            <Label className='red-dot' htmlFor='companyName'>
              Company Name
            </Label>
            <Input
              id='companyName'
              value={companyName}
              onChange={(e) => setValue('companyName', e.target.value)}
              register={register('companyName', { required: 'Please enter in your Company Name' })}
              type='text'
              autoFocus
            />
            {errors.companyName && <ErrorText>{errors.companyName.message}</ErrorText>}
          </FormItemWrap>
          <FormItemWrap>
            <Label className='red-dot' htmlFor='officePhone'>
              Office Phone No.
            </Label>
            <div className='phone-input-wrap'>
              <Input
                id='officePhone1'
                value={officePhone1}
                onChange={(e) => setValue('officePhone1', e.target.value)}
                register={register('officePhone1', { required: 'Please enter your office phone number' })}
                type='tel'
              />
              -
              <Input
                id='officePhone2'
                value={officePhone2}
                onChange={(e) => setValue('officePhone2', e.target.value)}
                register={register('officePhone2', { required: 'Please enter your office phone number' })}
                type='tel'
              />
              -
              <Input
                id='officePhone3'
                value={officePhone3}
                onChange={(e) => setValue('officePhone3', e.target.value)}
                register={register('officePhone3', { required: 'Please enter your office phone number' })}
                type='tel'
              />
            </div>
            {(errors.officePhone1 || errors.officePhone2 || errors.officePhone3) && <ErrorText>Please enter your office phone number</ErrorText>}
            {/* {errors.officePhone && <ErrorText>{errors.officePhone.message}</ErrorText>} */}
          </FormItemWrap>

          <PrimaryButtonArea>
            <SubButton type='button' onClick={handleClickBackButton} title='이전 페이지'>
              Back
            </SubButton>
            <PrimaryButton type='submit' title='다음 페이지'>
              Next
            </PrimaryButton>
          </PrimaryButtonArea>
        </form>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .guide {
    color: #7f8998;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
  }
  .phone-input-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    input {
      width: 30%;
      flex: 1;
    }
  }
  .red-dot {
    padding-left: 0.65rem;
    ::before {
      content: '*';
      color: var(--sub-red);
      margin-top: 2px;
      margin-left: -10px;
      position: absolute;
    }
  }
`;
