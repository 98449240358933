import React from 'react';
import styled from 'styled-components';

/** "Ap" - "Question Bank" - "Create Test" 페이지의 "Advanced Setting" 내부 박스 안에서 사용 할 input + label 요소 정렬 스타일 용 컴포넌트 */
export default function InputWrapForInnerBox({ className = '', children, ...rest }) {
  return (
    <S.Box className={` ${className}`} {...rest}>
      {children}
    </S.Box>
  );
}

const S = {
  Box: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input_wrap {
      display: flex;
      justify-content: start;
      gap: 0.75rem;
      .first_row {
        position: relative;
        .part_title {
          position: absolute;
          top: -1.5rem;
          width: 100%;
          text-align: center;
          color: #111;
          font-family: Roboto;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.25rem;
        }
      }

      .empty {
        width: 3.5rem;
      }
    }
  `,
};
