import lineBg from 'assets/img/exam/line_bg.png';
import styled from 'styled-components';
import UndoBtn from './UndoBtn';

/** 시험 상단 공용 컴포넌트 */
export default function QuestionTopArea({ order }) {
  const handleUndo = () => {
    console.log('undo 버튼 눌림!');
  };

  return (
    <StyledTopArea>
      <div className='num'>
        <p>{order}</p>
      </div>
      <UndoBtn callback={handleUndo} />
    </StyledTopArea>
  );
}

const StyledTopArea = styled.div`
  font-family: 'Roboto', 'Noto Sans KR', sans-serif;

  background-color: #f0f0f0;
  display: flex;
  height: 2.1875rem;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.875rem;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${lineBg});
    width: 100%;
    height: 1.5px;
    background-repeat: repeat-x;
  }

  .num {
    background-color: black;
    color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    height: 35px;
    min-width: 29px;
    max-width: 100px;
    padding: 0 0.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  /* 북마크 기능은 안쓰는듯 해서 일단 보류 */
  /* .bookmark {
    background-color: rgba(0, 0, 0, 0);
    color: rgb(51, 51, 51);
    display: block;
    height: 20px;
    position: static;
    width: 125.243px;
  }
  .btn_bookmark {
    background-color: rgba(0, 0, 0, 0);
    color: rgb(75, 85, 99);
    height: 44px;
    position: static;
    width: 100%;
  }
  label {
    background-color: rgba(0, 0, 0, 0);
    color: rgb(51, 51, 51);
    display: flex;
    height: 20px;
    width: 125.243px;
  } */
`;
