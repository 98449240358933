import { useState } from 'react';
import styled from 'styled-components';

import ExpendedItemList from './_components/ExpendedItemList';
import CustomCheckbox from 'components/_common/CustomCheckbox';
import ApSettingSelectBox from './_components/ApSettingSelectBox';
import ApSettingStepper from './_components/ApSettingStepper';
import ApSettingInput from './_components/ApSettingInput';

import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/round_triangle.svg';

export default function QuestionSettingPopup({ isOpen }) {
  // question setting data
  const [questionSettingData, setQuestionSettingData] = useState({
    questionNumber: 6,
    questionContext: false,
    questionType: 'Multiple Choice',
    questionDifficulty: null,
    possibleScore: 5,
    questionCode: 'Q111111',
  });
  /** source setting data 변경 핸들러 */
  const handleChangeQuestionSettingData = (key, value) => {
    setQuestionSettingData((prev) => {
      const newData = { ...prev };
      newData[key] = value;
      return newData;
    });
  };

  if (!isOpen) return null;

  return (
    <S.Container>
      <div className='popup_header'>
        <h2>Question Settings</h2>
      </div>

      <S.ContentWrapper>
        <div className='control_row'>
          <span className='label'>Question Number</span>
          <ApSettingInput
            className='ap_input number_input'
            value={questionSettingData.questionNumber}
            onChange={(e) => handleChangeQuestionSettingData('questionNumber', e.target.value)}
          />
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Question Context</span>

          <CustomCheckbox
            size='1.25rem'
            checkBoxId={`questionContext_checkbox`}
            isChecked={questionSettingData.questionContext}
            onChange={() => handleChangeQuestionSettingData('questionContext', !questionSettingData.questionContext)}
          />
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Question Type</span>
          <div className='select_control'>
            <ApSettingSelectBox
              options={[
                { label: 'Multiple Choice', value: 'Multiple Choice' },
                { label: 'Free Response', value: 'Free Response' },
              ]}
              onSelect={(value) => handleChangeQuestionSettingData('questionType', value)}
            />
          </div>
        </div>

        <div className='control_row inner_row'>
          <span className='label'>Multiple choice type</span>
          <div className='select_control'>
            <ApSettingSelectBox
              options={[
                { label: 'Single-select', value: 'Single-select' },
                { label: 'Multi-select', value: 'Multi-select' },
              ]}
              onSelect={(value) => handleChangeQuestionSettingData('questionType', value)}
            />
          </div>
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Question Difficulty</span>

          <div className='select_control'>
            <ApSettingSelectBox
              options={[
                { label: 'None', value: null },
                { label: 'Easy', value: 'Easy' },
                { label: 'Medium', value: 'Medium' },
                { label: 'Hard', value: 'Hard' },
              ]}
              onSelect={(value) => handleChangeQuestionSettingData('questionDifficulty', value)}
            />
          </div>
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Possible Score</span>

          <ApSettingStepper
            number={questionSettingData.possibleScore}
            onIncrease={() => {
              handleChangeQuestionSettingData('possibleScore', questionSettingData.possibleScore + 1);
            }}
            onDecrease={() => {
              handleChangeQuestionSettingData('possibleScore', questionSettingData.possibleScore - 1);
            }}
          />
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Question Code</span>

          <ApSettingInput
            className='ap_input code_input'
            value={questionSettingData.questionCode}
            onChange={(e) => handleChangeQuestionSettingData('questionCode', e.target.value)}
          />
        </div>
      </S.ContentWrapper>
      <ArrowIconSelect className='triangle_icon' />
    </S.Container>
  );
}

const S = {
  Container: styled.div`
    position: absolute;
    left: 116%;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 24px;
    width: 430px;

    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.24));

    .popup_header {
      width: 100%;
      h2 {
        color: #111111;
        font-family: 'Roboto-SemiBold', Helvetica;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin: 0;
      }
    }

    .triangle_icon {
      position: absolute;
      left: -3%;
      top: 50%;
      transform: translateY(-50%);
      color: #ffffff;
    }
  `,

  ContentWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;

    .divider {
      height: 1px;
      background-color: #f1f1f5;
      width: 100%;
    }

    .control_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &.inner_row {
        background-color: #fafafa;
        padding: 0.625rem 0.75rem;
        border-radius: 0.25rem;
      }

      .label {
        color: #111111;
        font-family: 'Roboto-Regular', Helvetica;
        font-size: 14px;
        line-height: 20px;
      }

      .ap_input {
        width: 8.75rem;
        padding: 0 10px;

        &.number_input {
          max-height: 2.375rem;
        }
        &.code_input {
          max-height: 2.125rem;
        }
      }
    }

    .group_control {
      display: flex;
      align-items: center;
      gap: 8px;

      .separator {
        color: #111111;
        font-size: 12px;
        line-height: 18px;
      }
    }
  `,

  SectionDivider: styled.div`
    height: 2px;
    background-color: #505050;
    width: 100%;
  `,
};
