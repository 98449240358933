import dayjsTZ from 'utils/functions/time/dayjs-config';

export default function DateHeader(props) {
  if (dayjsTZ(props.selectedDate).isSame(dayjsTZ(props.date), 'day')) {
    return (
      <div style={{ textAlign: 'right' }}>
        <span
          style={{
            borderRadius: '50%',
            padding: '4px',
            backgroundColor: '#0068BD',
            color: 'white',
          }}>
          {props.label}
        </span>
      </div>
    );
  }

  return (
    <div>
      <p>{props.label}</p>
    </div>
  );
}
