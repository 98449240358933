import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconSelect } from 'assets/img/svg/select.svg';

import SubjectModal from 'components/student/_components/modals/SubjectModal';
import usePressESC from 'hooks/usePressESC';

/** 아카데미 대시보드 - Students - Main Table - "class" 열 목록 펼치기 버튼 */
export default function SubjectListButton({ subjectList }) {
  /** "class list" 모달 노출 여부 */
  const [isShowListModal, setIsShowListModal] = useState(false);
  const handleOpenClassList = ({ event }) => {
    event.stopPropagation();
    setIsShowListModal((current) => !current);
  };

  usePressESC(() => {
    setIsShowListModal(false);
  }, isShowListModal);

  return (
    <S.SubjectListModalContainer>
      <S.Button type='button' onClick={(event) => handleOpenClassList({ event })} className={`${isShowListModal ? 'on' : ''}`}>
        <ImgSvgIconSelect />
      </S.Button>
      {isShowListModal && <SubjectModal subjectList={subjectList} setShowModal={setIsShowListModal} />}
    </S.SubjectListModalContainer>
  );
}

const S = {
  SubjectListModalContainer: styled.span`
    position: relative;
  `,
  Button: styled.button`
    margin-left: 1rem;
    svg {
      width: 0.75rem;
      color: #4b5563;
      transition: 0.2s;
      transform-origin: center;
    }
    &.on {
      svg {
        transform: rotate(180deg);
      }
    }
  `,
};
