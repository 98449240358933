import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import styled from 'styled-components';
import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';

export default function SimpleLineChart({ chartData }) {
  /** 데이터가 하나밖에 없는지 */
  const isOnlyOneData = chartData.seriesData.length === 1;

  const seriesData = [
    {
      name: 'RW',
      data: isOnlyOneData ? [null, chartData.seriesData[0].rwScore, null] : chartData.seriesData.map((item) => item.rwScore),
    },
    {
      name: 'Math',
      data: isOnlyOneData ? [null, chartData.seriesData[0].mathScore, null] : chartData.seriesData.map((item) => item.mathScore),
    },
  ];

  const xaxis = isOnlyOneData
    ? ['', chartData.startIndex + 1, '']
    : Array.from({ length: chartData.seriesData.length }, (_, index) => chartData.startIndex + index + 1);

  const chartOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: { enabled: false },
      toolbar: { show: false },
      animations: { enabled: false },
      fontFamily: 'Roboto, Arial, sans-serif',
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth' },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: xaxis,
      tooltip: {
        formatter: function (xaxisIndex, { series, dataPointIndex, w }) {
          const targetDataIndex = isOnlyOneData ? 0 : dataPointIndex;
          return `${isoTimeToDayjsTZ(chartData.seriesData[targetDataIndex].testTakenEndDate).format('MMM D, YYYY')}`;
        },
        enabled: true,
      },
    },
    yaxis: {
      min: 100,
      max: 900,
      tickAmount: 8,
    },
    colors: ['#008CFF', '#1FDF9F'],
    legend: {
      position: 'top',
      markers: { width: 40, height: 8, radius: 8 },
      onItemClick: { toggleDataSeries: false },
      onItemHover: { highlightDataSeries: false },
    },
    tooltip: {
      // 툴팁에서 노출되는 Y축 데이터 커스텀
      // y: {
      //   title: {
      //     formatter: function (seriesName) {
      //       return `${seriesName} Score:`; // 예: "RW Score:"
      //     },
      //   },
      // },
      x: {
        formatter: function (xaxisIndex, { series, dataPointIndex, w }) {
          const targetDataIndex = isOnlyOneData ? 0 : dataPointIndex;
          return `${chartData.seriesData[targetDataIndex].testName}`;
        },
      },
    },
    markers: {
      size: 4,
    },
  };

  return (
    <S.Wrap>
      <div id='chart'>
        <ReactApexChart options={chartOptions} series={seriesData} type='line' width={460} height={350} />
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    margin-bottom: -25px;
    margin-left: -15px;
  `,
};
