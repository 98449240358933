/**
 * student sgin up 전역 상태 리덕스 action 타입
 */
/** 회원가입 시 userId 저장 */
export const SET_STUDENT_ID = 'SET_STUDENT_ID';
/** Login 시 login type 저장 */
export const SET_STUDENT_LOGIN_TYPE = 'SET_STUDENT_LOGIN_TYPE';
/** email */
export const SET_STUDENT_USER_EMAIL = 'SET_STUDENT_USER_EMAIL';
/** mobile */
export const SET_STUDENT_USER_MOBILE = 'SET_STUDENT_USER_MOBILE';
/** name */
export const SET_STUDENT_USER_NAME = 'SET_STUDENT_USER_NAME';
/** Privacy Policy */
export const SET_STUDENT_AGREE_PRIVACY_POLICY = 'SET_STUDENT_AGREE_PRIVACY_POLICY';
/** Terms of Service */
export const SET_STUDENT_AGREE_TERMS_SERVICE = 'SET_STUDENT_AGREE_TERMS_SERVICE';
/** News Letter */
export const SET_STUDENT_AGREE_NEWS_LETTER = 'SET_STUDENT_AGREE_NEWS_LETTER';
/** school grade */
export const SET_STUDENT_SCHOOL_GRADE = 'SET_STUDENT_SCHOOL_GRADE';
/** school location */
export const SET_STUDENT_SCHOOL_LOCATION = 'SET_STUDENT_SCHOOL_LOCATION';
/** school name */
export const SET_STUDENT_SCHOOL_NAME = 'SET_STUDENT_SCHOOL_NAME';
/** school type */
export const SET_STUDENT_SCHOOL_TYPE = 'SET_STUDENT_SCHOOL_TYPE';
/** all data clear */
export const CLEAR_STUDENT_STATE = 'CLEAR_STUDENT_STATE';
