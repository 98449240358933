import React from 'react';
import { ReactComponent as ImgSvgLogo } from 'assets/img/svg/logo-gateplus-row-inner-shadow-01.svg';
import styled from 'styled-components';

/** 회원가입 페이지의 상단 로고 영역 컴포넌트 */
export default function Logo() {
  return (
    <S.Wrap>
      <ImgSvgLogo />
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  padding-bottom: 1.625rem;
  svg {
    width: 5.875rem;
  }
`;
