import React, { useEffect, useRef, useState } from 'react';
import GroupedBarChart from 'components/_common/chart/GroupedBarChart';
import SectionTitle from '../../../common/SectionTitle';
import styled from 'styled-components';
import Box from '../../../common/Box';
import request from 'utils/Request.utils';
import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useObfuscatedSearchParam, getValidParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS } from 'utils/constants';
import ColumnChart from 'components/_common/chart/ColumnChart';

const classButtons = ['Class', 'Student'];

/** "/com/score" 페이지의 "Class vs Class" 영역 컴포넌트 */
export default function ClassVsClass({ currentSubject, selectedStudentSeq, setSelectedStudentSeq }) {
  const userInfo = request.tokenDecoder();
  /** 현재 classSeq - Route Parameter */
  const { classSeq: encClassSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);
  const queryCurStudentSeq = getObfuscatedValue(SCORE_PARAMS.CUR_STU);
  /** 중복호출 방지용 Ref값 초기화 여부 */
  const [isInitialized, setIsInitialized] = useState(false);

  const [selectedTarget, setSelectedTarget] = useState('Class'); // 'Class' | 'Student' => 선택된 대상 ( Class | Student )

  const prevAverageVsClassRef = useRef({}); // average/vs/gateplus API 중복 호출 방지용 Ref - userInfo.ecSeq, queryCurScoreTestSeq, classSeq, userSeq

  /** VsClass 차트에서 Class 선택시 데이터 */
  const [classVsClassChartData, setClassVsClassChartData] = useState({
    series: [
      {
        name: 'RW', // 'RW'      => 차트에 그려질 이름
        data: [0], // number[]   => 선택된 시험에 대한 class의 영어[] 점수
      },
      {
        name: 'Math', // 'Math'  => 차트에 그려질 이름
        data: [0], // number[]   => 선택된 시험에 대한 class의 수학[] 점수
      },
    ],
    xaxisCategories: ['No data'], // string[]  => 차트의 x축에 표시될 class 목록
  });

  /** VsClass 차트에서 Student 선택시 데이터 */
  const [studentVsClassChartData, setStudentVsClassChartData] = useState({
    series: [
      {
        name: 'Student', // 'Student'  => 차트에 그려질 이름
        data: [0], // number[]         => 선택된 시험에 대한 class의 영어[] 점수
      },
      {
        name: 'Class', // 'Class'      => 차트에 그려질 이름
        data: [0], // number[]         => 선택된 시험에 대한 class의 수학[] 점수
      },
    ],
    colors: ['#0068BD', '#BEDEFA'], // ['#0068BD', '#E3E7ED']  => 차트에 그려질 색
  });

  //////////////////// START --- useEffect_선언_영역 --- START ////////////////////
  // 컴포넌트 마운트시 Ref값 초기화 ( 새로고침시 이전 Ref 값으로 중복호출방지가 실행되는 것을 막음 )
  useEffect(() => {
    prevAverageVsClassRef.current = {};
    setIsInitialized(true);
  }, []);

  // API 인자가 유효할 경우 해당 Class에서 본 시험정보 리스트 불러오기
  useEffect(() => {
    if (!(isInitialized && getValidParam(classSeq) && getValidParam(queryCurScoreTestSeq))) return;
    /**
     * vsClass 영역의 차트 데이터를 가져오는 API 요청 - mode에 따라 호출되는 데이터가 다름
     * @param {String} target - 'Class' | 'Student'
     * @param {Number} userSeq - 학생의 userSeq
     */
    const getVsClassChartData = (target, userSeq = 0) => {
      // 중복 방지 Ref가 초기화가 되지 않았거나, API 파라미터가 유효하지 않을 경우 early return
      if (!(isInitialized && userInfo?.ecSeq && getValidParam(queryCurScoreTestSeq) && getValidParam(classSeq))) return;
      // 이전 호출한 API의 파라미터와 현재 파라미터가 같다면 함수 종료 ( 중복 호출 방지 )
      if (
        prevAverageVsClassRef.current.queryCurScoreTestSeq === queryCurScoreTestSeq &&
        prevAverageVsClassRef.current.ecSeq === userInfo?.ecSeq &&
        prevAverageVsClassRef.current.classSeq === classSeq &&
        prevAverageVsClassRef.current.userSeq === userSeq
      )
        return;
      prevAverageVsClassRef.current = { queryCurScoreTestSeq, ecSeq: userInfo.ecSeq, classSeq, userSeq }; // 현재 파라미터 값 업데이트

      const successHandler = (response) => {
        if (response.code === 200) {
          if (target === 'Student') {
            const fetchedRwPoint = response.result.rwPoint || 0;
            const fetchedRwAvg = response.result.rwAvg || 0;
            const fetchedMathPoint = response.result.mathPoint || 0;
            const fetchedMathAvg = response.result.mathAvg || 0;
            setStudentVsClassChartData((prev) => {
              return {
                ...prev,
                series: [
                  {
                    name: 'Student',
                    data: [fetchedRwPoint, fetchedMathPoint],
                  },
                  { name: 'Class', data: [fetchedRwAvg, fetchedMathAvg] },
                ],
              };
            });
          }
          if (target === 'Class') {
            const fetchedVsClassData = response.result.vsClass;

            const rwPoint = fetchedVsClassData?.filter((i) => i.subject === 'English').map((item) => item.point);
            const mathPoint = fetchedVsClassData?.filter((i) => i.subject === 'Math').map((item) => item.point);

            let className = [];
            if (!!fetchedVsClassData) className = removeDuplicates(fetchedVsClassData, 'className');
            const classNameList = className.map((item) => item.className);

            setClassVsClassChartData((prev) => {
              return {
                ...prev,
                series: [
                  { name: 'RW', data: rwPoint },
                  { name: 'Math', data: mathPoint },
                ],
                xaxisCategories: classNameList,
              };
            });
          }
        }
      };

      request
        .get(
          `/api/dsat/average/vs/class?customSetSeq=${queryCurScoreTestSeq}&ecSeq=${userInfo.ecSeq}&classSeq=${classSeq}&userSeq=${userSeq}`,
          null,
          successHandler
        )
        .catch((error) => console.error('', error));
    };
    /** 중복 제거 */
    const removeDuplicates = (arr, prop) => {
      return arr.filter((item, index, self) => {
        return index === self.findIndex((t) => t[prop] === item[prop]);
      });
    };

    // target에 따라 다른 데이터 호출 Class: classData, Student: studentData
    getVsClassChartData(selectedTarget, selectedTarget === 'Student' ? selectedStudentSeq : 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, classSeq, queryCurScoreTestSeq, selectedTarget, selectedStudentSeq]);
  ////////////////////  END  --- useEffect_선언_영역 ---  END  ////////////////////

  useEffect(() => {
    if (selectedStudentSeq === 0) {
      setSelectedTarget('Class');
    } else {
      setSelectedTarget('Student');
    }
  }, [selectedStudentSeq]);

  return (
    <S.Wrap className='element'>
      <Box className='main_box'>
        <SectionTitle>{selectedTarget} vs Class</SectionTitle>
        <div className='com_tab dark'>
          {classButtons?.map((item, index) => (
            <button
              key={`button_${index}`}
              className={`menu ${selectedTarget === item ? 'active' : ''}`}
              onClick={() => {
                if (item === 'Student') {
                  setSelectedStudentSeq(getValidParam(queryCurStudentSeq));
                } else {
                  setSelectedStudentSeq(0);
                }

                setSelectedTarget(item);
              }}>
              {item}
            </button>
          ))}
        </div>
        {selectedTarget === 'Class' ? (
          <ColumnChart classData={classVsClassChartData} subject={currentSubject} />
        ) : (
          <GroupedBarChart groupedBarChart={studentVsClassChartData} />
        )}
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    width: calc(25% - 15px);
    .main_box {
      padding: 1.25rem 1.5rem;
      height: 100%;
    }
  `,
};
