import React, { useEffect, useState, useRef } from 'react';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

//img
import IconOk from 'assets/img/icon_ok.svg';
// component
import MiniSvgIconBtn from 'components/_common/button/MiniSvgIconBtn';
import DeleteModal from 'components/_common/modals/DeleteModal';
// hook
import useOutsideClick from 'hooks/useOutsideClick.js';
import LoadingBar from 'utils/LoadingBar';

/**
 * 반별 학생 리스트 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/tests/scheduled
 */
export default function ClassList({ type, clickEvent, classList, getSchedules, getTestClassMember, setAlertLayerPopup, testName }) {
  const [studentListIsActive, setStudentListIsActive] = useState([]);

  const openStudentList = (uthSeq) => {
    if (studentListIsActive.includes(uthSeq)) {
      const updatedSelectedKeys = studentListIsActive.filter((key) => key !== uthSeq);
      setStudentListIsActive(updatedSelectedKeys);
    } else {
      const updatedSelectedKeys = [...studentListIsActive, uthSeq];
      setStudentListIsActive(updatedSelectedKeys);
    }
  };

  // 체크 박스
  const [selectCheckBox, setSelectCheckBox] = useState({
    seqStr: '',
    selectedKeys: [],
  });

  const checkedClass = (item) => {
    let checkedSeq = item?.map((item) => item.uthSeq); // uthSeq 배열 생성
    let allCheckedIncluded = checkedSeq.every((seq) => selectCheckBox.selectedKeys.includes(seq)); // 모든 요소가 포함되어 있는지 확인

    return allCheckedIncluded;
  };

  const allChecked = (checked, item) => {
    if (checked) {
      const idArray = [];
      for (let i = 0; i < item.length; i++) {
        idArray.push(item[i].uthSeq);
      }
      const updatedSeqStr = idArray.join('|');
      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: idArray,
        seqStr: updatedSeqStr,
      });
    } else {
      setSelectCheckBox({ ...selectCheckBox, seqStr: '', selectedKeys: [] });
    }
  };

  const [deleteStudent, setDeleteStudent] = useState(false); //학생 삭제 팝업
  const [deleteStudentAlert, setDeleteStudentAlert] = useState(false); //학생 삭제 confirm 팝업
  const deleteStudentAlertOpen = () => {
    setDeleteStudent((prevState) => !prevState);
    setDeleteStudentAlert((prevState) => !prevState);
  };

  const deletePopupOpen = () => {
    const { seqStr, selectedKeys } = selectCheckBox;

    if (selectedKeys.length > 0) {
      setDeleteStudent(true);
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the class you want to delete.',
        };
      });
    }
  };

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const delUserTestSchedule = () => {
    setIsDeleteLoading(true);
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        // 체크 박스 초기화
        setSelectCheckBox((prev) => {
          return { ...prev, selectedKeys: [] };
        });
        // 성공 창
        deleteStudentAlertOpen();
        // 리로드
        getSchedules();
        getTestClassMember();

        setIsDeleteLoading(false);
      }
    };

    request.del(`/api/test/schedules?seqStr=${encodeURI(selectCheckBox.seqStr)}`, null, successHandler);
  };

  /** 수정 버튼 클릭 핸들러 */
  const editClass = (item) => {
    const { selectedKeys, seqStr } = selectCheckBox;
    let studentList = [];

    if (selectedKeys.length > 0) {
      studentList = item.flatMap((i) => i.filter((j) => selectedKeys.includes(j.uthSeq)));
      clickEvent(studentList);
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the class you want to edit.',
        };
      });
    }
  };

  useEffect(() => {
    setSelectCheckBox((prev) => {
      return { ...prev, selectedKeys: [], seqStr: '' };
    });
    setStudentListIsActive([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classList]);

  const [showTestViewPopup, setShowTestViewPopup] = useState(false);

  const viewTest = (customSeqStr, showAnswer) => {
    window.open(`/test/test/view?viewTest=${encodeURIComponent(customSeqStr)}&showAnswer=${showAnswer}`, '_blank');
  };
  const handleViewBtnClick = (showAnswer) => {
    setShowTestViewPopup(false);
    viewTest(nvl(classList[0][0].seqStr) !== '' ? classList[0][0].seqStr : classList[0].seqStr, showAnswer);
  };

  const isTestTaken = type !== 'post';

  const testViewPopupRef = useRef(null);
  useOutsideClick(testViewPopupRef, () => setShowTestViewPopup(false), showTestViewPopup);

  return (
    <>
      <div className='com_container'>
        <div className='box full'>
          {classList.length > 0 ? (
            <div className='com_sec_tit'>
              <h2 className='tit'>{testName}</h2>
              <div className='btn_area'>
                <div className='test_view_selector_wrapper' ref={testViewPopupRef}>
                  <MiniSvgIconBtn
                    text='View'
                    svgClassName='svg_icon icon_score_report xs'
                    onClick={() => setShowTestViewPopup((prev) => !prev)}
                    radius='medium'
                    dataHover
                  />
                  {showTestViewPopup && (
                    <ul className={`test_view_selector ${isTestTaken ? '' : 'to_left'}`}>
                      <li className='selector_item'>
                        <button onClick={() => handleViewBtnClick('Y')}>With answers</button>
                      </li>
                      <li className='selector_item'>
                        <button onClick={() => handleViewBtnClick('N')}>Without answers</button>
                      </li>
                    </ul>
                  )}
                </div>
                {isTestTaken && (
                  <MiniSvgIconBtn text='Edit' svgClassName='svg_icon icon_write' onClick={() => editClass(classList)} radius='medium' dataHover />
                )}
                {isTestTaken && <MiniSvgIconBtn text='Delete' svgClassName='svg_icon icon_trash' onClick={deletePopupOpen} radius='medium' dataHover />}
              </div>
            </div>
          ) : (
            <div className='com_class_list_nodata'>No test available</div>
          )}
          <div className='scroll'>
            {/*반 start*/}
            {classList &&
              classList?.map((item, index) => (
                <section key={`class_list_${index}`} className='com_class_list'>
                  <article className='class' onClick={() => openStudentList(index)}>
                    {isTestTaken && (
                      <p className='chk_field'>
                        <input
                          type='checkbox'
                          id={`chkAll_${item[0].testStartDate}${item[0].uthSeq}${index}`}
                          className='com_chk'
                          checked={checkedClass(item)}
                          onChange={(e) => allChecked(e.target.checked, item)}
                        />
                        <label htmlFor={`chkAll_${item[0].testStartDate}${item[0].uthSeq}${index}`} />
                      </p>
                    )}
                    <p className={`class_name ${!isTestTaken ? 'no-checkbox' : ''}`}>{nvl(item[0].className) === '' ? item.className : item[0].className}</p>
                    <p className='date'>
                      {isoTimeToDayjs(item[0].testStartDate).format('MM/DD/YYYY')} ~ {isoTimeToDayjs(item[0].testEndDate).format('MM/DD/YYYY')}
                    </p>
                    <i className='svg_icon icon_select gray' onClick={() => openStudentList(index)}>
                      &nbsp;
                    </i>
                  </article>
                  <StudentList
                    type={type}
                    className={`student_list ${studentListIsActive.includes(index) ? 'active' : ''}`}
                    list={item}
                    selectCheckBox={selectCheckBox}
                    setSelectCheckBox={setSelectCheckBox}
                  />
                </section>
              ))}
          </div>
        </div>
      </div>
      {/* 학생 삭제 팝업 */}
      {deleteStudent && (
        <DeleteModal
          hideModal={() => setDeleteStudent(false)}
          callback={delUserTestSchedule}
          text={'You are removing scheduled test. \n Your Gate Cash will be refunded.'}
        />
      )}
      {/* 학생 삭제 완료 팝업 */}
      {deleteStudentAlert && (
        <div className={'com_popup type_alert active'}>
          <div className='pop_container'>
            <div className='pop_body ok_contents'>
              <p className='alert_title'>Successfully deleted</p>
              <div className='icon'>
                <img src={IconOk} alt='icon' />
              </div>
            </div>
            <div className='pop_footer com_btn_wrap'>
              <button className='com_btn point xl full' onClick={() => setDeleteStudentAlert(false)}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {isDeleteLoading && <LoadingBar type={'spin'} color={'#000000'} />}
    </>
  );
}

//학생 리스트
function StudentList(props) {
  const { type, className, list, selectCheckBox, setSelectCheckBox } = props;

  const handleCheckboxChange = (e, item) => {
    const { selectedKeys, seqStr } = selectCheckBox;

    if (e.target.checked) {
      const listUthSeq = list.map((i) => i.uthSeq);

      let updatedSelectedKeys = [...selectedKeys, item.uthSeq];
      updatedSelectedKeys = updatedSelectedKeys.filter((key) => listUthSeq.includes(key));
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== item.uthSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  return (
    <>
      <article className={className}>
        {list &&
          list?.map((item, index) => (
            <div key={`student_list_${index}`} className='student'>
              {type !== 'post' && (
                <p className='chk_field'>
                  <input
                    type='checkbox'
                    id={`${isoTimeToDayjs(item.testStartDate).format('DDhhmm')}_${item.className}_${item.uthSeq}_${index}`}
                    className='com_chk'
                    checked={selectCheckBox.selectedKeys.includes(item.uthSeq)}
                    onChange={(e) => handleCheckboxChange(e, item)}
                  />
                  <label htmlFor={`${isoTimeToDayjs(item.testStartDate).format('DDhhmm')}_${item.className}_${item.uthSeq}_${index}`} />
                </p>
              )}
              <div className='com_profile_img'>
                {item?.upFileSeq > 0 ? (
                  <img
                    className='profile-img-background'
                    src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${item.userSeq}`}
                    alt='profile'
                  />
                ) : (
                  <span className='name'>{item.userName?.[0]}</span>
                )}
              </div>
              <p className='name'>{item.userName}</p>
              <p className='time'>{isoTimeToDayjs(item.testStartDate).format('A h : mm')}</p>
            </div>
          ))}
      </article>
    </>
  );
}
