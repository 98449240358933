import React, { useEffect } from 'react';

//component
import UserType from 'components/user/_components/pages/UserType';
import { useDispatch } from 'react-redux';
import { clearReduxStateAcademyAdmin } from 'reducers/auth/academyadmin.action';
import { clearReduxStateStudent } from 'reducers/auth/student.action';
import { clearReduxPrivateTutorState } from 'reducers/auth/privatetutor.action';
import { clearReduxStateAcademyTeacher } from 'reducers/auth/academyteacher.action';
import { USER_LEVELS as CODE } from 'utils/constants';
import { updateReduxSocialUserLevel } from 'reducers/auth/action';

/** 회원가입 - 소셜 가입 - 사용자 유형 선택 데이터 배열 */
const signUpUserTypeDataArray = [
  {
    id: 'academyAdmin',
    link: '/signup/social/academy-admin/information-review',
    titleText: 'Academy Admin',
    subText: 'I am an academy manager',
    code: CODE.ACADEMY,
  },
  {
    id: 'academyTeacher',
    link: '/signup/social/academy-teacher/information-review',
    titleText: 'Academy Teacher',
    subText: 'I am an academy teacher, associated with an academy',
    code: CODE.TEACHER,
  },
  {
    id: 'tutor',
    link: '/signup/social/tutor/information-review',
    titleText: 'Tutor',
    subText: `I am a private tutor, not affiliated with an academy`,
    code: CODE.TUTOR,
  },
  {
    id: 'student',
    link: '/signup/social/student/information-review',
    titleText: 'Student',
    subText: 'I am currently studying at a school',
    code: CODE.STUDENT,
  },
  {
    id: 'parent',
    link: '/signup/social/parent/information-review',
    titleText: 'Parents',
    subText: 'I am a mother OR a father of a student',
    code: CODE.PARENT,
  },
];

/** "회원가입 - 소셜 가입 - 사용자 유형 선택" 페이지 */
export default function SignUpSocialUserTypePage() {
  const dispatch = useDispatch();
  // 소셜 redux store는 유지
  // 유저 타입별 redux store는 초기화
  dispatch(clearReduxStateAcademyAdmin());
  dispatch(clearReduxStateStudent());
  dispatch(clearReduxPrivateTutorState());
  dispatch(clearReduxStateAcademyTeacher());
  // userReducer 의 social.userLevel 초기화
  dispatch(updateReduxSocialUserLevel(''));

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    // const userSocial = useSelector((state) => state.userReducer.social);
    // console.log('🚀 ~ useEffect ~ userSocial:', userSocial);
    return () => {
      // unmount시 remove
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);
  return <UserType signUpUserTypeDataArray={signUpUserTypeDataArray} />;
}
