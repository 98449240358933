import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

import IconEmpty from 'assets/img/icon_empty.png';
import useOutsideClick from 'hooks/useOutsideClick';

import ButtonBase from 'components/_common/button/_ButtonBase';
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod';

import Paging from 'layout/Paging';
import styled from 'styled-components';
import ByTestClassListSection from './ByTestClassListSection';
import ProfilePopup from 'components/_common/modals/PopupProfile';

/**
 * tests/taken 페이지의 "By Test" 텝에 해당하는 컨텐츠 영역 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/tests/taken
 */
export default function ByTest({ tabState, scheduleInfo, setScheduleInfo }) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////
  const alertAttributeValue = {
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    returnValue: () => {},
  };
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [searchInfo, setSearchInfo] = useState({
    searchFieldActive: false,
    searchStartDate: '',
    searchEndDate: '',
    setSearchField: 'Name',
    searchKeyword: '',
  });
  const [activeTest, setActiveTest] = useState({
    customSetSeq: 0,
    classMemberList: [],
    classMemberListAll: [],
    totalCnt: 0,
    testName: '',
  });
  /** 프로필 팝업 */
  const [profileState, setProfileState] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /** 검색 */
  const searchFiledRef = useRef();
  const searchKeywordRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const searchFiledClick = (value) => {
    setSearchInfo((prev) => {
      return { ...prev, searchFieldActive: false, setSearchField: value };
    });
  };

  const keywordSearchDo = (e) => {
    // console.log(nvl(searchKeywordRef.current.value));

    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: nvl(searchKeywordRef.current.value) };
    });
  };

  const scheduleTrClick = (customSetSeq, testName) => {
    // 중복 제거2
    setActiveTest((prev) => {
      return { ...prev, customSetSeq: customSetSeq, testName: testName };
    });
  };

  /** 반 선택 */
  const removeDuplicatesOne = (arr, prop) => {
    // 중복 제거
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop] === item[prop]);
    });
  };
  const removeDuplicatesTwo = (arr, prop1, prop2) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop1] === item[prop1] && t[prop2] === item[prop2]);
    });
  };

  const getTestClassMember = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let takenList = response.result.takenListc;

        let deduplicationClass = removeDuplicatesOne(takenList, 'classSeq');
        let sameClassList = [];
        for (let i = 0; i < deduplicationClass.length; i++) {
          const classSeq = deduplicationClass[i].classSeq;
          const filteredSchedule = takenList.filter((i) => i.classSeq === classSeq);
          sameClassList.push(filteredSchedule);
        }

        let courseList = [];
        let deduplicationTestDate = [];
        for (let i = 0; i < sameClassList.length; i++) {
          deduplicationTestDate = removeDuplicatesTwo(sameClassList[i], 'testStartDate', 'testEndDate');

          for (let j = 0; j < deduplicationTestDate.length; j++) {
            const startDate = deduplicationTestDate[j].testStartDate;
            const endDate = deduplicationTestDate[j].testEndDate;

            const filteredTestSchedule = sameClassList[i].filter((i) => i.testStartDate === startDate && i.testEndDate === endDate);
            courseList.push(filteredTestSchedule);
          }
        }
        const sortList = courseList.sort((a, b) => dayjs(a[0].testStartDate) - dayjs(b[0].testStartDate));
        setActiveTest((prev) => {
          return { ...prev, classMemberList: sortList };
        });
      }
    };

    request.get(`/api/test/schedules/post/exams/${activeTest.customSetSeq}?ecSeq=${userInfo.ecSeq}`, null, successHandler);
  };

  const getSchedules = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq,
      searchField: searchInfo.setSearchField === 'Name' ? 'userName' : 'testName',
      searchKeyword: searchInfo.searchKeyword,
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      limit: 10,
      offset: (scheduleInfo.currentPage - 1) * scheduleInfo.numPerPage,
      modiUserSeq: userInfo?.userSeq,
    };

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let scheduleList = response.result.scheduleList;
        let totalCnt = response.result.totalCnt;

        setScheduleInfo((prev) => {
          return { ...prev, uthList: scheduleList, totalCount: totalCnt };
        });

        if (totalCnt > 0 && !activeTest.customSetSeq)
          setActiveTest((prev) => {
            return {
              ...prev,
              customSetSeq: scheduleList[0].customSetSeq,
              testName: scheduleList[0].testName,
            };
          });
      }
    };

    if (userInfo?.ecSeq > 0)
      request.get(
        `/api/test/schedules/post?ecSeq=${pms.ecSeq}&limit=${pms.limit}&offset=${pms.offset}&searchField=${pms.searchField}&searchKeyword=${encodeURIComponent(pms.searchKeyword)}&searchStartDate=${encodeURIComponent(pms.searchStartDate)}&searchEndDate=${encodeURIComponent(pms.searchEndDate)}`,
        null,
        successHandler
      );
  };

  const schedulesClassListReload = () => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getAllTestClassMember();
  };

  const getAllTestClassMember = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let takenListA = response.result.takenListA;
        let totalCnt = response.result.totalCnt;

        const parsedData = takenListA.map((entry) => {
          const classList = entry.classListJson;
          return classList.map((classItem) => {
            const studentList = classItem.studentList;
            return studentList.map((student) => ({
              ...student,
              seqStr: entry.seqStr,
            }));
          });
        });

        if (scheduleInfo.currentPage === 1) {
          setActiveTest((prev) => {
            return {
              ...prev,
              totalCnt: totalCnt,
              classMemberListAll: parsedData,
            };
          });
        } else {
          setActiveTest((prev) => {
            return {
              ...prev,
              totalCnt: totalCnt,
              classMemberListAll: prev.classMemberListAll.concat(parsedData),
            };
          });
        }
      }
    };

    if (userInfo?.ecSeq > 0)
      request.get(`/api/test/schedules/post/exams?ecSeq=${userInfo.ecSeq}&currentPage=${scheduleInfo.currentPage}`, null, successHandler);
  };

  /** 프로필 불러오기 API 요청 */
  const getProfileView = (e, userSeq) => {
    e.stopPropagation();

    const successHandler = (response) => {
      console.log(response);

      if (response.code === 200) {
        let studentInfo = response.result.studentInfo;

        let imageUrl = null;

        if (studentInfo.upFileSeq > 0) {
          imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}?${Date.now()}`;
        }

        studentInfo.profile = imageUrl;

        setScheduleInfo((prev) => {
          return { ...prev, studentInfoList: studentInfo };
        });
        setProfileState(true);
      }
    };

    request
      .get(`/api/test/profiles?userSeq=${userSeq}&ecSeq=${userInfo.ecSeq}`, null, successHandler)
      .catch((error) => console.error('tests/scheduled 페이지의 "프로필 불러오기" API 응답 에러', error));
  };

  const nextPageMove = (value) => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const schedulesListReload = () => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getSchedules();
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 ////////////////
  useOutsideClick(
    searchFiledRef,
    () =>
      setSearchInfo((prev) => {
        return { ...prev, searchFieldActive: false };
      }),
    searchInfo.searchFieldActive
  );
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    schedulesListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleInfo.numPerPage, searchInfo.searchKeyword, searchInfo.searchStartDate, searchInfo.searchEndDate]);

  useEffect(() => {
    if (activeTest.customSetSeq > 0) getTestClassMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTest.customSetSeq]);

  useEffect(() => {
    schedulesClassListReload();
    schedulesListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <S.ByTestWrap>
        <S.TableContainer className='com_list'>
          <S.TableTopController className='top to_right'>
            <article className='search_area'>
              <PopupCalendarPeriod setSearchInfo={setSearchInfo} />
              <div
                className={`com_select_layer ${searchInfo.searchFieldActive ? 'active' : ''}`}
                onClick={() =>
                  setSearchInfo((prev) => {
                    return {
                      ...prev,
                      searchFieldActive: !searchInfo.searchFieldActive,
                    };
                  })
                }
                ref={searchFiledRef}>
                {/*클래스 active 포함 시 옵션 노출*/}
                <input type='text' className='selected search_area_input' placeholder={searchInfo.setSearchField} />
                <div className='layer'>
                  <p className='option' onClick={() => searchFiledClick('Name')}>
                    Name
                  </p>
                  <p className='option' onClick={() => searchFiledClick('Test')}>
                    Test
                  </p>
                </div>
              </div>
              <div className='search'>
                <input type='text' placeholder='Enter keywords' className='input search_area_input' ref={searchKeywordRef} />
                <button className='com_btn m blue btn_search' onClick={keywordSearchDo}>
                  <i className='svg_icon icon_search blue before'></i>
                  SEARCH
                </button>
              </div>
            </article>
          </S.TableTopController>
          <S.Table className='list'>
            <colgroup>
              <col width='180px' />
              <col width='' />
              <col width='125px' />
              <col width='136px' />
              <col width='73px' />
            </colgroup>
            <thead>
              <tr>
                <th>Test</th>
                <th>Name</th>
                <th>Finished Time</th>
                <th>Taken Date</th>
                <th>Profile</th>
              </tr>
            </thead>
            <tbody>
              {scheduleInfo.uthList.length > 0 ? (
                scheduleInfo.uthList.map((item, idx) => {
                  return (
                    <tr
                      className={activeTest.customSetSeq === item.customSetSeq ? 'active' : ''}
                      key={`scheduleTr_${idx}`}
                      onClick={() => scheduleTrClick(item.customSetSeq, item.testName)}>
                      <td className='tit_field alignL'>
                        <p className='ellipsis'>{item.testName}</p>
                      </td>
                      <td className='alignL'>{item.userName}</td>
                      <td>{isoTimeToDayjs(item.testStartDate).format('A h : mm')}</td>
                      <td>{isoTimeToDayjs(item.testStartDate).format('MM / DD / YYYY')}</td>
                      <td>
                        <ButtonBase type='tableBtn' onClick={(e) => getProfileView(e, item.userSeq)}>
                          View
                        </ButtonBase>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='5'>
                    <div className='com_nodata'>
                      <p className='tit'>No test available.</p>
                      <p className='txt'>Go to Set Test and set more test!</p>
                      <div className='img'>
                        <img src={IconEmpty} alt='' />
                      </div>
                      <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
                        GO TO SET TEST
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </S.Table>
          <Paging
            totalCount={scheduleInfo.totalCount}
            currentPage={scheduleInfo.currentPage}
            numPerPage={scheduleInfo.numPerPage}
            pagePerBlock={scheduleInfo.pagePerBlock}
            nextPageMove={nextPageMove}
          />
        </S.TableContainer>
        <ByTestClassListSection testData={activeTest} setAlertLayerPopup={setAlertLayerPopup} />
      </S.ByTestWrap>
      {/* 학생 상세 프로필 팝업 */}
      {profileState && <ProfilePopup setProfileState={setProfileState} studentInfo={scheduleInfo.studentInfoList} />}
    </>
  );
}

// --- --- ---

const S = {
  ByTestWrap: styled.div`
    display: flex;
    background: #fff;
    gap: 1rem;
    padding: 1.25rem 1rem;
  `,

  TableContainer: styled.div``,
  TableTopController: styled.section`
    display: flex;
    align-items: center;

    .search_area {
      display: flex;
      gap: 0.5rem;

      .com_select_layer {
        width: 6.25rem;
        height: 2.5rem;

        .selected.search_area_input {
          border-radius: 0.5rem;
          min-width: 6.25rem;
          padding: 0.625rem 0.75rem;
        }

        &:after {
          right: 1rem;
        }
      }

      .com_calendar_wrap {
        input {
          width: 13.4375rem;
          border-radius: 0.5rem !important;
          padding-left: 2.5rem;
          background-position: 0.75rem center;
          text-align: right;
          padding-inline: 0.75rem !important;
        }
      }

      .search {
        .search_area_input {
          border-radius: 0.5rem;
          padding: 0.625rem 0.75rem;
        }

        .svg_icon {
          margin-right: 0.5rem;
          width: 1.125rem;
          height: 1.125rem;
        }
      }
    }

    .delete_test_btn {
      width: 2rem;
      height: 2rem;
    }
  `,
  Table: styled.table``,
};
