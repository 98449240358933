import EquationsPanel from '../EquationsPanel';
import EquationsPanelContentContainer from '../EquationsPanelContentContainer';

import prefixes1 from 'assets/img/ap_exam/reference_sheet/equations/prefixes_1.png';

/** 레퍼런스 시트 수식 컴포넌트의 Prefixes 컴포넌트 - 다른 과목에서도 반복적으로 사용되어 따로 분리 */
export default function PrefixesEquationPanel({ isExpanded }) {
  return (
    <EquationsPanel
      isExpanded={isExpanded}
      panelHeaderText='Prefixes'
      PanelContent={<EquationsPanelContentContainer contents={[prefixes1]} containerHeight='34rem' />}
    />
  );
}
