import {
  SET_ACADEMY_ADMIN_ID,
  SET_ACADEMY_ADMIN_LOGIN_TYPE,
  SET_ACADEMY_ADMIN_USER_EMAIL,
  SET_ACADEMY_ADMIN_USER_MOBILE,
  SET_ACADEMY_ADMIN_AGREE_PRIVACY_POLICY,
  SET_ACADEMY_ADMIN_AGREE_TERMS_SERVICE,
  SET_ACADEMY_ADMIN_AGREE_NEWS_LETTER,
  SET_ACADEMY_ADMIN_USER_NAME,
  SET_ACADEMY_ADMIN_OFFICE_PHONE,
  SET_ACADEMY_ADMIN_COMPANY_NAME,
  CLEAR_ACADEMY_ADMIN_STATE,
} from './academyadmin.actionTypes';

/** 리듀서 전역 상태 액션 : 학원 관리자 아이디 저장 */
export const updateReduxAcademyAdminId = (userId) => ({
  type: SET_ACADEMY_ADMIN_ID,
  payload: userId,
});

/** 리듀서 전역 상태 액션 : 학원 관리자 이름 저장 */
export const updateReduxAcademyAdminUserName = (userName) => ({
  type: SET_ACADEMY_ADMIN_USER_NAME,
  payload: userName,
});

/** 리듀서 전역 상태 액션 : 학원 관리자 로그인 타입 저장 */
export const updateReduxAcademyAdminLoginType = (loginType) => ({
  type: SET_ACADEMY_ADMIN_LOGIN_TYPE,
  payload: loginType,
});

/** 학원 관리자 이메일 저장 */
export const updateReduxAcademyAdminUserEmail = (userEmail) => ({
  type: SET_ACADEMY_ADMIN_USER_EMAIL,
  payload: userEmail,
});

/** 학원 관리자 전화번호 저장 */
export const updateReduxAcademyAdminUserMobile = (userMobile) => ({
  type: SET_ACADEMY_ADMIN_USER_MOBILE,
  payload: userMobile,
});

/** 학원 관리자 개인정보처리방침 동의 저장 */
export const updateReduxAcademyAdminAgreePrivacyPolicy = (agree) => ({
  type: SET_ACADEMY_ADMIN_AGREE_PRIVACY_POLICY,
  payload: agree,
});

/** 학원 관리자 서비스이용약관 동의 저장 */
export const updateReduxAcademyAdminAgreeTermsService = (agree) => ({
  type: SET_ACADEMY_ADMIN_AGREE_TERMS_SERVICE,
  payload: agree,
});

/** 학원 관리자 뉴스레터 동의 저장 */
export const updateReduxAcademyAdminAgreeNewsLetter = (agree) => ({
  type: SET_ACADEMY_ADMIN_AGREE_NEWS_LETTER,
  payload: agree,
});

/** 학원 관리자 사무실 전화번호 저장 */
export const updateReduxAcademyAdminOfficePhone = (officePhone) => ({
  type: SET_ACADEMY_ADMIN_OFFICE_PHONE,
  // payload: officePhone,
  payload: {
    num1: officePhone.num1,
    num2: officePhone.num2,
    num3: officePhone.num3,
  },
});

/** 학원 관리자 회사명 저장 */
export const updateReduxAcademyAdminCompanyName = (companyName) => ({
  type: SET_ACADEMY_ADMIN_COMPANY_NAME,
  payload: companyName,
});

/** 학원 관리자 리듀서 전체 초기화 */
export const clearReduxStateAcademyAdmin = () => {
  return {
    type: CLEAR_ACADEMY_ADMIN_STATE,
  };
};
