import React from 'react';
import styled from 'styled-components';

/** "회원 가입" 프로세스의 "주요 버튼 영역" 컴포넌트 */
export default function PrimaryButtonArea({ children, ...rest }) {
  return <S.Wrap {...rest}>{children}</S.Wrap>;
}

const S = {};

S.Wrap = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2.5rem;
  button {
    width: 100%;
    border-radius: 0.375rem;
    font-size: 1rem;
    padding: 1.25rem;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;
