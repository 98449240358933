import React from 'react';
//img
import IconOk from 'assets/img/icon_ok.svg';

/** 학생 삭제 완료 팝업 */
export default function PopSuccessfullyDeleted({ deleteClassAlert, setDeleteClassAlert }) {
  return (
    <div className={`com_popup type_alert ${deleteClassAlert ? 'active' : ''}`}>
      <div className='pop_container'>
        <div className='pop_body ok_contents'>
          <p className='alert_title'>Successfully deleted</p>
          <div className='icon'>
            <img src={IconOk} alt='icon' />
          </div>
        </div>
        <div className='pop_footer com_btn_wrap'>
          <button className='com_btn point xl full' onClick={() => setDeleteClassAlert(false)}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
