import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import TestEditComBtn from 'components/ap/com/ap-components/_components/TestEditComBtn';
import ReactQuillEditor from 'components/_common/test-editor/quilljs/ReactQuillEditor';
import SimpleEditorBtn from 'components/ap/com/ap-components/_components/SimpleEditorBtn';
import { EDITOR_CONSTANT } from '../_constant';
import DeleteModal from 'components/_common/modals/DeleteModal';

/** QuestionBank에서 Test를 Edit 하는 페이지 - "Source Direction" 영역 컴포넌트 */
export default function SourceDirectionArea({ editorState, editorStateMutator }) {
  /** "Source Direction" 입력창 노출 여부 */
  const [isViewEditor, setIsViewEditor] = useState(false);
  /** "Delete" 모달 노출 여부 */
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  /** "Sources" 입력 창(ReactQuillEditor) 현재 화면 표시 용 지역 상태 */
  const [editorContentState, setEditorContentState] = useState(editorState[EDITOR_CONSTANT.currentQuestion].source_group.source_directions);

  /** "Add Source Direction" 버튼 핸들러 */
  const handleAddSourceDirection = () => {
    setIsViewEditor(true);
  };

  /** "Source Direction" 삭제 버튼 핸들러 */
  const handleDeleteButton = () => {
    setIsShowDeleteModal(true);
  };
  /** "Source Direction" 삭제 동작 함수 */
  const handleDelete = () => {
    setIsViewEditor(false);

    setEditorContentState('');

    editorStateMutator(EDITOR_CONSTANT.currentQuestion, (prev) => {
      return {
        ...prev,
        source_group: {
          ...prev.source_group,
          source_directions: '',
        },
      };
    });

    setIsShowDeleteModal(false);
  };

  /** "Source Direction" 입력창 수정 핸들러 */
  const handleSourcesEditorChange = (event) => {
    setEditorContentState(event);

    editorStateMutator(EDITOR_CONSTANT.currentQuestion, (prev) => {
      return {
        ...prev,
        source_group: {
          ...prev.source_group,
          source_directions: event,
        },
      };
    });
  };

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    // 현재 보고 있는 Source 가 바뀔 때마다 입력창의 내용 갱신
    setEditorContentState(editorState[EDITOR_CONSTANT.currentQuestion].source_group.source_directions);

    if (editorState[EDITOR_CONSTANT.currentQuestion].source_group.source_directions) {
      setIsViewEditor(true);
    } else {
      setIsViewEditor(false);
    }
  }, [editorState]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <S.Wrap>
      <TestEditComBtn title='Source Direction' onClick={handleAddSourceDirection} />
      {isViewEditor && (
        <>
          <ReactQuillEditor className='quill_editor' editorContentState={editorContentState} handleChange={handleSourcesEditorChange} />
          <div className='btn_container'>
            <SimpleEditorBtn className='normal' title='Delete' onClick={handleDeleteButton}>
              Delete
            </SimpleEditorBtn>
          </div>
        </>
      )}

      {isShowDeleteModal && <DeleteModal hideModal={() => setIsShowDeleteModal(false)} callback={handleDelete} text={'All the content will be deleted.'} />}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 1rem;

    .quill_editor {
      width: 100%;
    }
  `,
};
