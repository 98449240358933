import React from 'react';
import styled from 'styled-components';
import ImgBgWatermark from 'assets/img/watermark.png';

/** PDF Viewer 페이지의 스타일링 용 컴포넌트 */
export default function TestPdfViewer({ isShowAnswer, children }) {
  return <S.Wrap className={isShowAnswer ? '' : 'none'}>{children}</S.Wrap>;
}

const S = {};

S.Wrap = styled.div`
  background-color: #fff;

  &::after {
    content: '';
    display: block;
    background-image: url(${ImgBgWatermark});
    background-size: calc(1280px * 0.8);
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    @media print {
      background-size: 60vw auto;
    }
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    //background-color: #fff;
  }
  &::before {
    @media print {
      content: 'testgate.gateplus.org';
      position: fixed;
      top: 80vh;
      left: 0;
      width: 100%;
      font-size: 3.8rem;
      font-family: 'Noto Sans', serif;
      color: #e3e5e8;
      text-align: center;
      opacity: 0.5;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
  @media print {
    padding: 1.3cm;
    box-sizing: border-box;
    border: 1px solid #fff;
    width: 100%;
  }
  &.safari-mode {
    @media print {
      max-width: 93%;
    }
  }
  figure {
    margin-inline: auto;
  }
  .single_column_space {
    width: 22rem;
    height: 22rem;
  }
  .container {
    width: 1280px;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    &.single_column {
      // 헤더안에 margin이 있어서 예외처리
      > *:not(.test_view_header) {
        /* margin-right: 22rem;
        border-right: 1px solid #000; */
        padding-right: 0.75rem;
      }
      > .first_sec {
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
      }
      .question_sec {
        width: 100%;
      }
    }

    @media print {
      width: 100%;
    }
    .test_view_header {
      position: fixed;
      left: 0;
      right: 0;
      z-index: 50;
      width: 100vw;
      height: 6rem;
      background-color: black;
      @media print {
        visibility: hidden;
      }
      .test_view_header_inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1194px;
        padding: 0 4rem;
        margin: 0 auto;
        height: 6rem;

        @media print {
          visibility: hidden;
        }

        .test_view_header_logo_section {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.75rem;
          font-size: 1.25rem;
          font-weight: 700;
          > img {
            width: 2rem;
            height: 2rem;
          }
          > p {
            color: white;
          }
        }

        .test_view_header_btn_section {
          display: flex;
          gap: 0.5rem;
          .test_view_header_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 5rem;
            height: 4.25rem;
            background-color: #121212;
            border-radius: 10px;
            .test_view_header_btn_inner {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              gap: 0.5rem;

              > img {
                width: 1.5rem;
                height: 1.5rem;
              }
              > div {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 1.5rem;
                span {
                  white-space: pre-wrap;
                  color: white;
                  font-size: 0.75rem;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
  .first_sec {
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 5px solid #000;
    background-color: #fff;
    position: relative;
    z-index: 10;
    padding-top: 6rem;

    & + .module_sec {
      break-before: auto;
      break-after: page;
    }

    @media print {
      min-height: auto;
      padding-top: 0;
    }
    .title {
      font-size: 6.25rem;
      font-weight: bold;
      padding-bottom: 30px;
      border-bottom: 5px solid #000;
      margin-bottom: 30px;
      color: #000;
      // @media print{
      //   font-size:40px;
      //   margin-bottom:18px;
      //   padding-bottom:18px;
      // }
    }
    .name {
      font-family: 'Noto Serif', serif;
      font-size: 100px;
      font-weight: 500;
      line-height: 126px;
      color: #000;
      // @media print{
      //   font-size:40px;
      //   line-height: 56px;
      // }
    }
    .test_name {
      font-family: 'Noto Serif', serif;
      font-weight: 500;
      font-size: 60px;
      line-height: 81px;
      margin-top: 10px;
      // @media print{
      //   font-size:24.5px;
      //   margin-top:0;
      //   line-height: 33.5px;
      // }
    }
    .txt {
      margin-block: 80px 40px;
      font-size: 16px;
      color: #000;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      // @media print{
      //   font-size:9px;
      //   margin-block:30px 50px;
      // }
      .logo {
        width: 107px;
        // @media print{
        //   width:44px;
        // }
      }
    }
  }
  .module_num {
    background: #eeefef;
    height: 100px;
    border: 15px solid #000;
    border-width: 0 15px 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 35px;
    margin-bottom: 2rem;
    .num {
      color: #000;
      font-weight: 700;
      font-size: 100px;
      // @media print{
      //   font-size:41px;
      // }
    }
    .name {
      border-radius: 50%;
      background: #fff;
      width: 68px;
      height: 68px;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      // @media print{
      //   width:37px;
      //   height:37px;
      //   font-size:10px;
      // }
    }
  }
  .module_sec {
    padding-bottom: 30px;
    background-color: #fff;
    padding-top: 50px;
    position: relative;
    z-index: 10;
    break-before: page;
    .tit_area {
      padding: 30px 72px;
      border-bottom: 1px solid #000;
      // @media print{
      //   padding:20px 30px;
      // }
      .test_name {
        font-weight: 700;
        font-size: 48px;
        color: #000;
        // @media print{
        //   font-size:20px;
        // }
      }
      .question_num {
        margin-top: 10px;
        font-weight: 900;
        font-size: 28px;
        color: #000;
        // @media print{
        //   margin-top: 4px;
        //   font-size:12px;
        // }
      }
      .module_name {
        margin-top: 30px;
        font-weight: 900;
        font-size: 20px;
        color: #000;
        text-transform: uppercase;
        // @media print{
        //   font-size:8px;
        //   margin-top:10px;
        // }
      }
    }
    .notice_area {
      padding: 30px 72px;
      border-bottom: 1px solid #000;
      // @media print{
      //   padding:20px 30px;
      // }
      .tit_field {
        padding: 3px 10px;
        background: #000;
        font-weight: 900;
        font-size: 20px;
        color: #fff;
        display: inline-block;
        margin-bottom: 10px;
        // @media print{
        //   font-size:8px;
        //   padding:3px 4px;
        // }
      }
      .txt_field {
        font-weight: 400;
        font-size: 20px;
        color: #000;
        // @media print{
        //   font-size:9px;
        // }
        .ul {
          margin-top: 10px;
          li {
            list-style: disc;
            margin-left: 20px;
            margin-bottom: 4px;
          }
        }
        .example {
          text-align: center;
          margin-top: 30px;
          img {
            margin-top: 30px;
          }
        }
        .img {
          text-align: center;
        }
        & + .tit_field {
          margin-top: 50px;
        }
      }
    }
    // @page {
    //   break-inside: avoid;
    // }
  }
  .question_sec {
    font-family: 'Noto Serif', serif;
    min-height: 500px;
    width: 100%;
    position: relative;
    z-index: 1;
    break-inside: avoid;
    padding-bottom: 6rem;
    &.too-long {
      grid-column: 1 / span 2;
    }
    @media print {
      min-height: auto;
      padding-top: 1.5cm;
    }
    .MJXc-display {
      font-family: 'Noto Serif', serif;
      font-style: italic;
      font-size: 0.9rem;
      margin-top: 0;
      margin-bottom: 0;
    }
    .top_area {
      height: 41px;
      background-color: #efefef;
      border: 1px solid #dedede;
      border-right: none;

      // @media print{
      //   height: 17px;
      //   display: flex;
      // }
      .num {
        margin-top: -1px;
        width: 41px;
        height: 41px;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
        // @media print{
        //   font-size:7px;
        //   width:17px;
        //   height: 17px;
        // }
      }
    }
    .question_area {
      // padding: 30px 72px;
      padding: 30px 30px;
      display: block;
      font-size: 20px;
      // @media print{
      //   padding:10px 30px;
      // }
      //&,& *{
      //font-size:20px !important;분수가 깨져서 삭제
      // @media print{
      //   font-size:9px !important
      // }
      //}
      .image,
      figure {
        text-align: center;
      }
      // @page {
      //   break-inside: avoid;
      // }
    }
    .answer_area {
      padding-inline: 72px;
      font-size: 20px;
      // @media print{
      //   padding-inline: 30px;
      // }
      //&,& *{
      //font-size:20px !important; 분수가 깨져서 삭제
      // @media print{
      //   font-size:9px !important;
      // }
      //}
      .item {
        margin-bottom: 15px;
        align-items: flex-start;
        display: flex;
        gap: 20px;
        // @media print{
        //   gap:6px;
        // }
        &.wrong {
          color: #ff4c4c;
        }
        &.correct_answer {
          color: var(--point-color);
        }
        .txt {
          font-size: 20px;
          font-family: 'Roboto', 'Noto Sans KR', sans-serif;
          display: inline-block;
          margin-left: 20px;
        }
        & > div {
          display: inline;
        }
        &:has(div div) {
          & > div {
            width: 100%;
          }
          & > div > div {
            display: inline;
          }
        }

        .num {
          word-break: keep-all;
        }
      }
      .notice {
        font-size: 20px;
        font-family: 'Roboto', 'Noto Sans KR', sans-serif;
        color: #ff4c4c;
        margin-top: 30px;
      }
      .answer_input {
        &.wrong {
          .input_box {
            border-color: #ff4c4c;
            outline: 3px solid rgba(255, 76, 76, 0.4);
          }
          .txt {
            color: #ff4c4c;
          }
        }
        .input_box {
          border: 1px solid #000;
          border-radius: 10px;
          overflow: hidden;
          padding: 5px 10px;
          width: 120px;
          border-color: $c-point;
          outline: 3px solid rgba(0, 104, 189, 0.4);
          background: #fff;
          .input {
            border: none;
            border-radius: 0;
            //border-bottom:1px solid #000;
            width: 100px;
            font-size: 20px;
            font-family: 'Noto Serif', serif;
            font-weight: 400;
          }
          &.correct_answer {
            margin-top: 20px;
            .input {
              border-bottom: none;
              //font-size:22px;
            }
          }
        }
        .txt {
          font-family: 'Roboto', 'Noto Sans KR', sans-serif;
          padding-top: 6px;
          font-size: 14px !important;
          text-transform: uppercase;
          display: block;
          color: $c-point;
        }
      }
    }

    // &__wrap {
    //   position: relative;
    //   // padding-right: 10px;
    //   // width: 66%;
    // }
    &__group {
      // display: flex;
      // justify-content: center;
      // gap: 20px;
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      break-inside: avoid;

      > :not(:last-child)::after {
        position: absolute;
        content: '';
        right: -10px;
        top: 0;
        bottom: 0;
        width: 1.2px;
        background: #b3b3b3;
      }
    }
    .mjx-chtml {
      white-space: break-spaces;
    }
  }
  &.none {
    .question_sec {
      .answer_area {
        .item {
          &:is(.correct_answer, .wrong) {
            color: #222;
            .txt {
              display: none;
            }
          }
        }
        .notice {
          display: none;
        }
        .answer_input {
          .txt {
            display: none;
          }
          .input_box {
            outline: none;
            border-color: #000;
            .input {
              font-size: 0 !important;
            }
          }
        }
      }
    }
  }
`;
