import QuestionDirection from './QuestionDirection';
import SourcesForReview from './SourcesForReview';

/** AP source 영역 컴포넌트 */
export default function ApLeftArea({
  currentQuestionOrder,
  // 하이라이트 관련 props
  isAnnotation,
  setIsAnnotation,
  isDragged,
  questionRef,
  sourceRef,
  // 그룹 소스 관련 props
  questionSourceGroup,
  setQuestionSourceGroup,
  questionSourceInfo,
}) {
  /** 소스 내용 수정 함수 */
  const editSource = (updatedSource, sourceId) => {
    setQuestionSourceGroup((prev) => {
      // 이전 State의 sourceContent 배열에서 sourceId와 일치하는 요소를 찾아 업데이트
      const newSourceContent = prev.sourceContent.map((item) => (item.sourceId === sourceId ? { ...item, sourceContent: updatedSource } : item));
      return { ...prev, sourceContent: newSourceContent };
    });
  };

  /** 소스 디렉션 수정 함수 */
  const editDirections = (directions, directionId) => {
    setQuestionSourceGroup((prev) => {
      const newSourceDirections = prev.sourceDirections.map((item) => (item.directionId === directionId ? { ...item, directionContent: directions } : item));
      return { ...prev, sourceDirections: newSourceDirections };
    });
  };

  /** 현재 문제의 direction - 현재 문제의 sourceInfo와 일치하는 questionSourceGroup에서 find */
  const currentQuestionDirection = questionSourceGroup.sourceDirections.find((item) => item.directionId === questionSourceInfo.directionId);
  /** 현재 문제의 source - 현재 문제의 sourceInfo와 일치하는 questionSourceGroup에서 find */
  const currentQuestionSource = questionSourceInfo?.sourceId.map((id) => questionSourceGroup.sourceContent.find((item) => item.sourceId === id));

  return (
    <article className='wrap'>
      <div className='scroll_wrap'>
        {currentQuestionDirection && currentQuestionDirection !== '<p><br></p>' && (
          <div style={{ position: 'relative' }} className='inner'>
            <QuestionDirection
              sourceDirections={currentQuestionDirection}
              isAnnotation={isAnnotation}
              setIsAnnotation={setIsAnnotation}
              isDragged={isDragged}
              contentRef={questionRef}
              editDirections={editDirections}
            />
          </div>
        )}
        {currentQuestionSource.length > 0 && (
          <div className='inner'>
            <SourcesForReview
              source={currentQuestionSource}
              currentQuestionOrder={currentQuestionOrder}
              isAnnotation={isAnnotation}
              setIsAnnotation={setIsAnnotation}
              isDragged={isDragged}
              contentRef={sourceRef}
              editSource={editSource}
            />
          </div>
        )}
      </div>
    </article>
  );
}
