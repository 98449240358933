import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ApLeftArea from './ApLeftArea';
import QuestionArea from './QuestionArea';

import { MathJax } from 'better-react-mathjax';
import ReviewPopup from './ReviewPopup';

/** 영어 시험 화면 본문 영역 컴포넌트 */
function ExamBody({ stateExamInfo, setStateExamInfo, currentQuestionInfo, setQuestionInfo }) {
  const dispatch = useDispatch();
  /** 초기 마운트를 알리는 state ( useEffect로 첫 마운트때 변경 ) */
  const [isInitialRender, setIsInitialRender] = useState(true);

  const isRenderMathJaxRef = useRef(false);
  const timerRef = useRef(null);

  const [questionArea, setQuestionArea] = useState(null);

  /** MathJax 타입캐스팅 결과에 따라 타이머 갱신 + 상태 변경하는 함수 */
  const updateMathJaxStatus = useCallback((value) => {
    isRenderMathJaxRef.current = value;

    const setMathJaxTimer = (currentValue) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        if (currentValue) {
          // console.log('🚀 MathJax 타입캐스팅 성공 🚀');
        } else {
          console.log('🚫 MathJax 타입캐스팅 실패 🚫');
          dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: true } });
        }
      }, 1500);
    };

    setMathJaxTimer(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 타이머와 관계없는 속성들이 바뀔때만 렌더링
  useEffect(() => {
    setQuestionArea(
      stateExamInfo.isReviewPopupOpen ? (
        <ReviewPopup stateExamInfo={stateExamInfo} setStateExamInfo={setStateExamInfo} />
      ) : (
        <MathJax
          style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}
          className={`pop_body`}
          hideUntilTypeset='first'
          onTypeset={() => updateMathJaxStatus(true)}>
          {(currentQuestionInfo?.sourceDirections || currentQuestionInfo?.sourceContent) && (
            <ApLeftArea
              stateExamInfo={stateExamInfo}
              sourceDirections={currentQuestionInfo?.sourceDirections}
              source={currentQuestionInfo?.sourceContent ? JSON.parse(currentQuestionInfo.sourceContent) : []}
            />
          )}
          <QuestionArea stateExamInfo={stateExamInfo} currentQuestionInfo={currentQuestionInfo} setQuestionInfo={setQuestionInfo} />
        </MathJax>
      )
    );
  }, [currentQuestionInfo, setQuestionInfo, setStateExamInfo, stateExamInfo, updateMathJaxStatus]);

  return questionArea;
}

export default ExamBody;
