import styled from 'styled-components';

export default function UI_MiniReview({ onClose, selectArea, questionButtons }) {
  return (
    <S.Wrap className='layer'>
      <button className='btn_layer_close' onClick={onClose}>
        <i className='svg_icon btn_pop_close black'>&nbsp;</i>
      </button>

      <div className='select_area'>{selectArea}</div>

      <button className='btn_close'>
        <i className='svg_icon icon_delete'>&nbsp;</i>
      </button>

      <div className='info_field'>
        <div className='item'>
          <i className='svg_icon icon_current'>&nbsp;</i>Current
        </div>
        <div className='item'>
          <i className='svg_icon icon_unanswered'>&nbsp;</i>Not Created
        </div>
      </div>

      <div className='question_field'>{questionButtons}</div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    position: absolute;
    bottom: 6rem;
    left: 50%;
    z-index: 100;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transform: translateX(calc(0% - 50%));
    border-radius: 10px;
    width: 35.75rem;
    padding: 1.25rem;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: -25px;
      z-index: 100;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 43.3px 25px 0px 25px;
      border-color: #ffffff transparent transparent transparent;
    }

    .select_area {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 2rem;
      padding-right: 2rem;
      select {
        width: 100%;
        height: 2.75rem;
        border-radius: 0.25rem 0.25rem 0rem 0rem;
        border: 1px solid #c5ccd2;
        padding: 0.5rem 1.25rem;
        color: #111;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.75rem;
      }
    }

    .tit_field {
      padding: 0;
      font-size: 20px;
    }
    .info_field {
      border: none;
    }
    .question_field {
      width: 100%;
      margin-top: 10px;
      padding-top: 25px;
      gap: 30px;
      .item {
        width: 40px;
        height: 40px;
        font-size: 20px;
        position: relative;
      }
    }

    .btn_layer_close {
      position: absolute;
      right: 15px;
      top: 16px;
    }
    .tit_field {
      font-size: 18px;
      line-height: 1;
      font-weight: 700;
      padding-inline: 15px;
      text-align: center;
    }
    .info_field {
      border-bottom: 1px solid #aaa;
      border-top: 1px solid #aaa;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      font-size: 15px;
      font-weight: 500;
      .item {
        display: flex;
        align-items: center;
        .svg_icon {
          margin-right: 5px;
        }
      }
    }
    .question_field {
      margin-top: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      .item {
        border: 1px dashed #aaa;
        width: 1.875rem;
        height: 1.875rem;
        color: #0047de;
        font-weight: 500;
        font-size: 0.75rem;
        position: relative;
        word-break: keep-all;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;

        &.single_char {
          font-size: 0.9375rem;
        }
        &:hover {
          background-color: #f0f0f0;
        }
        .current {
          text-decoration: underline;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -17px;
          display: inline-block;
          width: 18px;
          height: 18px;
        }
        &.answered {
          border: 1px solid #0047de;
          color: #fff;
          background: #0047de;
          &:hover {
            background-color: #355d96;
          }
        }
        &.review {
          &:after {
            @include addImg('exam/icon_bookmark_on.png', 10px, 15px, contain);
            position: absolute;
            right: -5px;
            top: -6px;
          }
        }
      }
    }
  `,
};
