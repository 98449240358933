import { useState } from 'react';
import { useSelector } from 'react-redux';
import { nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import { getRestTimeFromLocalStorage, getTimeDiffFromLocalStorage, getTimeEntryTimeLocalStorage } from '../_utils/functions/timerLocalStorageFunctions';

/** 문제 페이지 빠져나갈 때 API 요청 함수를 반환 하는 Hook (이탈, 문제 하나 마다 매번 실행) */
export default function useExitQuestion() {
  const userInfo = request.tokenDecoder();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  /** 문제 페이지 빠져나갈 때 API 요청 */
  const fetch = ({ currentNum, currentExamInfoRef }) => {
    console.log('문제 페이지 닫을 때 API 요청');
    /** 남은 시간 (실시간) */
    const restTime = getRestTimeFromLocalStorage();
    /** 지난 시험 시간 */
    const timeDiff = getTimeDiffFromLocalStorage();
    /** 진입 시간 */
    const entryTime = getTimeEntryTimeLocalStorage();

    let params = {
      actionMode: 'exitQuestion',
      tryAnswer: null, // 내가 입력한 답 (문제를 빠져 나가는 행위에서는 답을 입력 할 필요가 없기 때문에 명시적으로 빈 값, null을 보낸다.)
      remainingTime: restTime,
      modiUserSeq: userInfo?.userSeq,
      entryTime: Math.abs(Math.abs(entryTime) - Math.abs(timeDiff)), // 해당 문제에 머무른 시간
    };

    console.log(`🚨 params :`, params, `🚨`);

    const successHandler = (response) => {
      console.log('문제 페이지 닫을 때 API 응답 : ', response);
      if (response.code === 200) {
      }
      setIsLoading(false);
    };

    if (nvlNumber(currentNum) !== 0 && nvlNumber(currentExamInfoRef?.current) !== 0) {
      if (!isLoading) {
        setIsLoading(true);

        request.put(`/api/exam/exitResultHistory/${currentExamInfoRef?.current}`, params, successHandler).catch((error) => {
          console.error(error);
          setIsLoading(false);
          setError(error);
          alert('시험 - 문제 페이지 빠져나갈 때(이탈, 문제 하나 마다 매번 실행) API 응답 실패');
        });
      }
    }
  };

  return { fetch, isLoading, error };
}
