import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconSelect } from 'assets/img/svg/select.svg';
import ProfileModal from 'components/_common/modals/profileModal/ProfileModal';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';
import { GRADING_LIST_ORDER_FILTER_PARAMS, GRADING_PARAMS } from '../../../_utils/constants/params';

/** AP Grading main Table */
export default function GradingMainTable({ testList, setTestList }) {
  //////////////////// START --- 커스텀 훅 & Ref --- START ////////////////////
  const { getObfuscatedValue, navigateWithParams, deleteObfuscatedValue } = useObfuscatedSearchParam();
  /** 현재 파라미터 객체 */
  const currentParams = {
    statusOrder: getObfuscatedValue(GRADING_LIST_ORDER_FILTER_PARAMS.status.KEY),
    subjectNameOrder: getObfuscatedValue(GRADING_LIST_ORDER_FILTER_PARAMS.subjectName.KEY),
    testNameOrder: getObfuscatedValue(GRADING_LIST_ORDER_FILTER_PARAMS.testName.KEY),
    teacherNameOrder: getObfuscatedValue(GRADING_LIST_ORDER_FILTER_PARAMS.teacherName.KEY),
    studentNameOrder: getObfuscatedValue(GRADING_LIST_ORDER_FILTER_PARAMS.studentName.KEY),
    scoreOrder: getObfuscatedValue(GRADING_LIST_ORDER_FILTER_PARAMS.score.KEY),
    testDateOrder: getObfuscatedValue(GRADING_LIST_ORDER_FILTER_PARAMS.testDate.KEY),
  };
  ////////////////////  END  --- 커스텀 훅 & Ref ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 쿼리 파라미터를 사용하여 3단계 (오름차순, 내림차순, 정렬 없음) 정렬 기능 함수 */
  const orderUtilWithQueryParam3step = ({ currentParam, key }) => {
    /** 기준 경로 */
    const BASE_URL = '/ap/com/score/grading';
    /** 쿼리 파라미터 옵션 (문자열 오타 방지 및 관리편의를 위한 선언) */
    const paramOptions = {
      ASCENDING: 'ascending',
      DESCENDING: 'descending',
    };

    if (currentParam === paramOptions.ASCENDING) {
      navigateWithParams(`${BASE_URL}?${[key]}=${obfuscateUrlParam(paramOptions.DESCENDING)}`, { replace: true });
    } else if (currentParam === paramOptions.DESCENDING) {
      deleteObfuscatedValue({ paramKey: key });
    } else {
      navigateWithParams(`${BASE_URL}?${[key]}=${obfuscateUrlParam(paramOptions.ASCENDING)}`, { replace: true });
    }
  };

  /** "시험 진행 상태" 정렬 핸들러 */
  const handleToggleStatusOrder = () => {
    orderUtilWithQueryParam3step({ currentParam: currentParams.statusOrder, key: GRADING_LIST_ORDER_FILTER_PARAMS.status.KEY });
  };

  /** "시험 과목 이름" 정렬 핸들러 */
  const handleToggleSubjectNameOrder = () => {
    orderUtilWithQueryParam3step({ currentParam: currentParams.subjectNameOrder, key: GRADING_LIST_ORDER_FILTER_PARAMS.subjectName.KEY });
  };

  /** "시험 이름" 정렬 핸들러 */
  const handleToggleTestNameOrder = () => {
    orderUtilWithQueryParam3step({ currentParam: currentParams.testNameOrder, key: GRADING_LIST_ORDER_FILTER_PARAMS.testName.KEY });
  };

  /** "선생님 이름" 정렬 핸들러 */
  const handleToggleTeacherNameOrder = () => {
    orderUtilWithQueryParam3step({ currentParam: currentParams.teacherNameOrder, key: GRADING_LIST_ORDER_FILTER_PARAMS.teacherName.KEY });
  };

  /** "학생 이름" 정렬 핸들러 */
  const handleToggleStudentNameOrder = () => {
    orderUtilWithQueryParam3step({ currentParam: currentParams.studentNameOrder, key: GRADING_LIST_ORDER_FILTER_PARAMS.studentName.KEY });
  };

  /** "점수" 정렬 핸들러 */
  const handleToggleScoreOrder = () => {
    orderUtilWithQueryParam3step({ currentParam: currentParams.scoreOrder, key: GRADING_LIST_ORDER_FILTER_PARAMS.score.KEY });
  };

  /** "시험 종료 날짜" 정렬 핸들러 */
  const handleToggleTestDateOrder = () => {
    orderUtilWithQueryParam3step({ currentParam: currentParams.testDateOrder, key: GRADING_LIST_ORDER_FILTER_PARAMS.testDate.KEY });
  };

  /** 테이블 tr (표의 행) 클릭 핸들러 */
  const handleClickTableRow = ({ uthSeq, score }) => {
    const destinationUrl = navigateWithParams(`/ap/com/score/grading/submission/${obfuscateUrlParam(String(uthSeq))}`, {
      isNavigate: false,
      newParams: { [GRADING_PARAMS.scaledScore.KEY]: score },
    });

    window.open(destinationUrl, '_blank');
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    let newData;
    // "시험 진행 상태" 정렬에 따른 동작
    if (currentParams.statusOrder === GRADING_LIST_ORDER_FILTER_PARAMS.status.value.ASCENDING) {
      // 오름차순 정렬
    } else if (currentParams.statusOrder === GRADING_LIST_ORDER_FILTER_PARAMS.status.value.DESCENDING) {
      // 내림차순 정렬
    } else {
      // 정렬 해제 (모든 정렬을 해제 하였을 경우 기본 정렬은 무엇인지 기획 문의 필요)
    }
  }, [currentParams.statusOrder]);
  useEffect(() => {
    // "시험 과목 이름" 정렬에 따른 동작
  }, [currentParams.subjectNameOrder]);
  useEffect(() => {
    // "시험 이름" 정렬에 따른 동작
  }, [currentParams.testNameOrder]);
  useEffect(() => {
    // "선생님 이름" 정렬에 따른 동작
  }, [currentParams.teacherNameOrder]);
  useEffect(() => {
    // "학생 이름" 정렬에 따른 동작
  }, [currentParams.studentNameOrder]);
  useEffect(() => {
    // "점수" 정렬에 따른 동작
  }, [currentParams.scoreOrder]);
  useEffect(() => {
    // "시험 종료 날짜" 정렬에 따른 동작
  }, [currentParams.testDateOrder]);
  ////////////////////  END  --- React useEffect ---  END  ////////////////////

  //
  const [profileModalInfo, setProfileModalInfo] = useState({
    visible: false, // boolean           => 프로필 모달 노출 여부
    userSeq: 0, // number              => 학생 시퀀스
  });

  const handleShowProfile = ({ event, item }) => {
    event.stopPropagation();
    const studentInfo = item;

    setProfileModalInfo({
      visible: true,
      userSeq: studentInfo.userSeq,
    });
  };

  return (
    <S.TableWrap>
      <S.Table>
        {/* 🧩 student main - Table_MainContent_테이블헤더 🧩 */}
        <thead className='list'>
          <tr>
            <th className='col_01'></th>
            <th className='col_02'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${currentParams.statusOrder === GRADING_LIST_ORDER_FILTER_PARAMS.subjectName.value.ASCENDING ? 'up' : currentParams.statusOrder === GRADING_LIST_ORDER_FILTER_PARAMS.subjectName.value.DESCENDING ? 'down' : ''}`}
                onClick={handleToggleStatusOrder}>
                Status <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_03'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${currentParams.subjectNameOrder === GRADING_LIST_ORDER_FILTER_PARAMS.subjectName.value.ASCENDING ? 'up' : currentParams.subjectNameOrder === GRADING_LIST_ORDER_FILTER_PARAMS.subjectName.value.DESCENDING ? 'down' : ''}`}
                onClick={handleToggleSubjectNameOrder}>
                Subject <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_04'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${currentParams.testNameOrder === GRADING_LIST_ORDER_FILTER_PARAMS.testName.value.ASCENDING ? 'up' : currentParams.testNameOrder === GRADING_LIST_ORDER_FILTER_PARAMS.testName.value.DESCENDING ? 'down' : ''}`}
                onClick={handleToggleTestNameOrder}>
                Test <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_05'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${currentParams.teacherNameOrder === GRADING_LIST_ORDER_FILTER_PARAMS.teacherName.value.ASCENDING ? 'up' : currentParams.teacherNameOrder === GRADING_LIST_ORDER_FILTER_PARAMS.teacherName.value.DESCENDING ? 'down' : ''}`}
                onClick={handleToggleTeacherNameOrder}>
                Teacher <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_06'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${currentParams.studentNameOrder === GRADING_LIST_ORDER_FILTER_PARAMS.studentName.value.ASCENDING ? 'up' : currentParams.studentNameOrder === GRADING_LIST_ORDER_FILTER_PARAMS.studentName.value.DESCENDING ? 'down' : ''}`}
                onClick={handleToggleStudentNameOrder}>
                Student <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_07'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${currentParams.scoreOrder === GRADING_LIST_ORDER_FILTER_PARAMS.score.value.ASCENDING ? 'up' : currentParams.scoreOrder === GRADING_LIST_ORDER_FILTER_PARAMS.score.value.DESCENDING ? 'down' : ''}`}
                onClick={handleToggleScoreOrder}>
                Score <ImgSvgIconSelect />
              </button>
            </th>
            <th className='col_08'>
              <button
                style={{ padding: '0 2px', whiteSpace: 'nowrap' }}
                className={`btn_sort ${currentParams.testDateOrder === GRADING_LIST_ORDER_FILTER_PARAMS.testDate.value.ASCENDING ? 'up' : currentParams.testDateOrder === GRADING_LIST_ORDER_FILTER_PARAMS.testDate.value.DESCENDING ? 'down' : ''}`}
                onClick={handleToggleTestDateOrder}>
                Test Date <ImgSvgIconSelect />
              </button>
            </th>
          </tr>
        </thead>
        {/* 🧩 student main - Table_MainContent_테이블메인 🧩 */}
        {testList !== null && testList?.length > 0 && (
          <tbody>
            {testList.map((item, index) => {
              return (
                <tr
                  key={`tr_${index}_${item.userSeq}`}
                  onClick={() => handleClickTableRow({ uthSeq: item.uthSeq, score: item.score })}
                  title='Open in a New Window'>
                  <td></td>
                  <td className='status_col'>
                    {item.isCompleted !== undefined && (
                      <span className={`status ${item.isCompleted ? '' : 'grading'}`}>{item.isCompleted ? 'Completed' : 'Grading'}</span>
                    )}
                  </td>
                  <td>{item.subjectName}</td>
                  <td className='text_left'>{item.testSetName}</td>
                  <td>
                    <span className='ellipsis'>{item.teacherName}</span>
                  </td>
                  <td className='student_name_col'>
                    <span className='ellipsis'>{item.studentName}</span>
                    <button type='button' title='Open a profile' onClick={(event) => handleShowProfile({ event, item })} className='btn_view'>
                      View
                    </button>
                  </td>
                  <td>{item.score ? item.score : '-'}</td>
                  <td>{isoTimeToDayjsTZ(item.testTakenEndDate).format('MM / DD / YYYY')}</td>
                </tr>
              );
            })}
          </tbody>
        )}
      </S.Table>

      <ProfileModal profileModalInfo={profileModalInfo} closeProfileModal={() => setProfileModalInfo((prev) => ({ ...prev, visible: false }))} />

      {testList === null ||
        (testList?.length < 1 && (
          <div className='empty_data'>
            <p className='empty_data_title'>Nothing to be graded.</p>
            <p className='empty_data_desc'>Once a student completes the test, it will be shown here.</p>
          </div>
        ))}
    </S.TableWrap>
  );
}

const S = {
  TableWrap: styled.div`
    .empty_data {
      height: 32.5rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;

      .empty_data_title {
        font-size: 1rem;
        font-weight: 500;
        color: black;
      }

      .empty_data_desc {
        font-size: 0.875rem;
        font-weight: 400;
        color: #767676;
      }
    }
  `,
  Table: styled.table`
    tbody {
      tr {
        cursor: pointer;
      }
    }
    tr {
      th,
      td {
        vertical-align: middle;
        padding: 0 0.65rem;
        &.col_01 {
          width: 2.5rem;
        }
        &.col_02 {
          width: 6.8rem;
        }
        &.col_03 {
          width: 14rem;
        }
        &.col_04 {
          width: auto;
        }
        &.col_05 {
          width: 10rem;
        }
        &.col_06 {
          width: 10rem;
        }
        &.col_07 {
          width: 5.5rem;
        }
        &.col_08 {
          width: 7rem;
        }
      }
      th {
        background-color: #000;
        color: #fff;
        height: 2.5rem;
        text-align: center;
        font-weight: 500;
        font-size: 0.875rem;
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          overflow: hidden;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          overflow: hidden;
        }

        .btn_sort {
          color: #ffffff;
          font-weight: 500;
          font-size: 0.875rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          &::after {
            content: '-';
          }
          svg {
            width: 0.75rem;
            transition: 0.2s;
            transform-origin: center;
            display: none;
          }
          &.up {
            &::after {
              display: none;
            }
            svg {
              display: inline-block;
            }
          }
          &.down {
            &::after {
              display: none;
            }
            svg {
              display: inline-block;
              transform: rotate(180deg);
            }
          }
        }
      }
      td {
        height: 3rem;
        text-align: center;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        border-bottom: 1px solid #ebebeb;

        &.col_03,
        &.col_06,
        &.col_07 {
          text-align: left;
        }

        &.status_col {
          .status {
            display: flex;
            padding: 0.125rem 0.5rem;
            justify-content: center;
            align-items: center;
            border-radius: 6.25rem;
            border: 1px solid #0068bd;
            color: #0068bd;
            font-family: 'Noto Sans';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.25rem;
            &.grading {
              border-color: #f24b4b;
              color: #f24b4b;
            }
          }
        }

        &.text_left {
          text-align: left;
        }

        .table_data_inner_box {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
        }

        .ellipsis {
          display: inline-block;
          width: 100%;
        }

        // 학생 이름
        &.student_name_col {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0.75rem;
        }
      }

      .btn_view {
        width: auto;
        height: auto;
        background-color: #f2f2f2;
        color: #7f8998;
        font-weight: 400;
        font-size: 0.875rem;
        border-radius: 0.25rem;
        padding: 0.125rem 0.5rem;
        word-break: keep-all;
      }

      background-color: #fff;
      &:nth-child(even) {
        background-color: #fafafa;
      }
      &.active {
        background-color: #edf7ff;
      }
      &:has(.com_chk):hover {
        background-color: #f2f2f2;
        //color:#4B5563 ;
      }
    }
  `,
};
