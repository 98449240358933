//#region    ////////// components
import EquationsPanel from './_components/EquationsPanel';
import EquationsPanelContentContainer from './_components/EquationsPanelContentContainer';
import EquationsList from './_components/EquationsList';
import PrefixesEquationPanel from './_components/equationsContent/PrefixesEquationPanel';
//#endregion ////////// components

//#region    ////////// assets
import statisticalAnalysisAndProbability1 from 'assets/img/ap_exam/reference_sheet/equations/biology/statistical_analysis_and_probability_1.png';
import statisticalAnalysisAndProbability2 from 'assets/img/ap_exam/reference_sheet/equations/biology/statistical_analysis_and_probability_2.png';
import statisticalAnalysisAndProbability3 from 'assets/img/ap_exam/reference_sheet/equations/biology/statistical_analysis_and_probability_3.png';
import statisticalAnalysisAndProbability4 from 'assets/img/ap_exam/reference_sheet/equations/biology/statistical_analysis_and_probability_4.png';
import statisticalAnalysisAndProbability5 from 'assets/img/ap_exam/reference_sheet/equations/biology/statistical_analysis_and_probability_5.png';
import rateAndGrowth1 from 'assets/img/ap_exam/reference_sheet/equations/biology/rate_and_growth_1.png';
import rateAndGrowth2 from 'assets/img/ap_exam/reference_sheet/equations/biology/rate_and_growth_2.png';
import simpsonDiversityIndex1 from 'assets/img/ap_exam/reference_sheet/equations/biology/simpson_diversity_index_1.png';
import surfaceAreaAndVolume1 from 'assets/img/ap_exam/reference_sheet/equations/biology/surface_area_volume_1.png';
import surfaceAreaAndVolume2 from 'assets/img/ap_exam/reference_sheet/equations/biology/surface_area_volume_2.png';
import surfaceAreaAndVolume3 from 'assets/img/ap_exam/reference_sheet/equations/biology/surface_area_volume_3.png';
import waterPotential1 from 'assets/img/ap_exam/reference_sheet/equations/biology/water_potential_1.png';
import solutePotential1 from 'assets/img/ap_exam/reference_sheet/equations/biology/solute_potential_1.png';
//#endregion ////////// assets

/** biology 레퍼런스 시트 수식 컴포넌트 */
export default function BiologyEquations() {
  return (
    <EquationsList>
      <EquationsPanel
        panelHeaderText='Statistical Analysis and Probability'
        PanelContent={
          <EquationsPanelContentContainer
            contents={[
              statisticalAnalysisAndProbability1,
              statisticalAnalysisAndProbability2,
              statisticalAnalysisAndProbability3,
              statisticalAnalysisAndProbability4,
              statisticalAnalysisAndProbability5,
            ]}
            containerHeight='100rem'
          />
        }
      />
      <EquationsPanel
        panelHeaderText='Rate and Growth'
        PanelContent={<EquationsPanelContentContainer contents={[rateAndGrowth1, rateAndGrowth2]} containerHeight='29rem' />}
      />
      <EquationsPanel
        panelHeaderText="Simpson's Diversity Index"
        PanelContent={<EquationsPanelContentContainer contents={[simpsonDiversityIndex1]} containerHeight='9rem' />}
      />
      <EquationsPanel
        panelHeaderText='Surface Area and Volume'
        PanelContent={
          <EquationsPanelContentContainer contents={[surfaceAreaAndVolume1, surfaceAreaAndVolume2, surfaceAreaAndVolume3]} containerHeight='22rem' />
        }
      />
      <EquationsPanel
        panelHeaderText='Water Potential'
        PanelContent={<EquationsPanelContentContainer contents={[waterPotential1]} containerHeight='13rem' />}
      />
      <EquationsPanel
        panelHeaderText='The Solute Potential of a Solution'
        PanelContent={<EquationsPanelContentContainer contents={[solutePotential1]} containerHeight='13rem' />}
      />
      <PrefixesEquationPanel />
    </EquationsList>
  );
}
