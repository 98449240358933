import React, { useEffect } from 'react';
import SetTestsStep from './_SetTestsStep';
import PrimaryButtonArea from './_PrimaryButtonArea';
import OrderDetails from './_orderDetails/_OrderDetails';
import styled from 'styled-components';
import useUserLevels from 'hooks/useUserLevels';
import request from 'utils/Request.utils';
import getCurrentIndex from '../../_hooks/getCurrentIndex';

/** Set Tests 공통 레이아웃 관리 용 컴포넌트 */
function Layout({
  classSeq,
  selectedClassName,
  selectedStudents,
  setSelectedStudents,
  selectTestSet,
  testOrderInfo,
  selectModules,
  selectedTest,
  setSelectedTest,
  setShowInsufficientKeyModal,
  children,
}) {
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  const isB2c = useLevels.isStudent;
  const currentIndex = getCurrentIndex();

  return (
    <S.SetTestsLayout className='com_set_tests com_center_wrap'>
      <SetTestsStep isB2c={isB2c} useLevelsIsLoading={useLevelsIsLoading} />
      {currentIndex === 0 ? (
        children
      ) : (
        <div className='com_container'>
          <div className='box'>
            <section className='left_wrap'>{children}</section>
            <section className='right_wrap'>
              <OrderDetails
                isB2c={isB2c}
                classSeq={classSeq}
                selectedClassName={selectedClassName}
                selectedStudents={selectedStudents}
                setSelectedStudents={setSelectedStudents}
                selectModules={selectModules}
                selectTestSet={selectTestSet}
                testOrderInfo={testOrderInfo}
                selectedTest={selectedTest}
                setSelectedTest={setSelectedTest}
              />
              <PrimaryButtonArea
                isB2c={isB2c}
                classSeq={classSeq}
                selectedClassName={selectedClassName}
                selectedStudents={selectedStudents}
                selectTestSet={selectTestSet}
                testOrderInfo={testOrderInfo}
                setShowInsufficientKeyModal={setShowInsufficientKeyModal}
              />
            </section>
          </div>
        </div>
      )}
    </S.SetTestsLayout>
  );
}

export default Layout;

const S = {};

/** Set tests 공통 레이아웃 Styled-components */
S.SetTestsLayout = styled.div`
  padding-top: 86px;
  .com_container {
    background: #f4f8ff;
    padding: 1rem;
    border-radius: 0.625rem;
    gap: 1rem;
    flex: 1;
    flex-wrap: nowrap;
  }
  .box {
    display: flex;
    width: 100%;
    gap: 30px;
    & :is(.left_wrap, .right_wrap) {
      align-self: stretch;
    }
    .left_wrap {
      width: calc(100% - 286px - 30px);
      .com_calendar_wrap {
        .input {
          width: 195px;
        }
      }
    }
    .right_wrap {
      position: sticky;
      top: 6rem;
      flex: 1;
      align-self: stretch;
      height: fit-content;
      padding-bottom: 1rem;
      .order_detail {
        .container {
          .section {
            .top_area {
              .com_btn {
                .svg_icon {
                  &.icon_minus {
                    @include svg-icon-color('icon_minus', '0068BD');
                  }
                }
              }
            }
          }
        }
        &__wrap {
          &.fixed {
            position: sticky;
            top: 96px;
            //bottom:278px;
            z-index: 2;
          }
        }
      }
      .com_btn_wrap {
        .com_btn {
          font-size: 12px;
        }
      }
    }
  }

  /*  ----------------  */
  .rbc-btn-group.right {
    width: 122px;
    .com_btn {
      display: none;
    }
  }
`;
