//#region    ////////// packages
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
//#endregion ////////// packages

//#region    ////////// components
import Paging from 'layout/Paging'; //페이징
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod'; //팝업 달력
import TestList from 'components/com/tests/_components/_TestListClassLayer';
import CustomSelect from 'components/_common/inputs/CustomSelect';
import Layout from 'components/com/tests/_components/_common/Layout';
import ClickableTableRow from 'components/_common/table/TableRow';
//#endregion ////////// components

//#region    ////////// utils
import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
//#endregion ////////// utils

/** Set Tests : 학원 선택 페이지 */
export default function TestsSetSelectClassPage() {
  //#region    ////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  const userInfo = request.tokenDecoder();
  //#endregion //////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [classesInfo, setClassesInfo] = useState({
    classesList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10, // line 수
    pagePerBlock: 10,
    selectedClassSeq: nvl(stateSetTest.selectedClass.classSeq) !== '' ? stateSetTest.selectedClass.classSeq : 0,
    selectedClassName: nvl(stateSetTest.selectedClass.className) !== '' ? stateSetTest.selectedClass.className : '',
  });
  const [searchInfo, setSearchInfo] = useState({
    searchStartDate: '',
    searchEndDate: '',
    searchField: '',
    searchKeyword: '',
  });
  /** API 로딩 상태 */
  const [isLoading, setIsLoading] = useState(true);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const searchKeywordRef = useRef();
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  const getClassesList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const classesList = response.result.classesList;
        const totalCount = response.result.totalCount;

        setClassesInfo((prev) => ({ ...prev, classesList: classesList, totalCount: totalCount }));
      }
    };

    const urlParams = {
      ecSeq: userInfo?.ecSeq,
      chargeSeq: userInfo?.userSeq,
      searchKeyword: searchInfo.searchKeyword,
      limit: classesInfo.numPerPage,
      offset: (classesInfo.currentPage - 1) * classesInfo.numPerPage,
    };
    const apiUrl = `/api/academy/classes?ecSeq=${urlParams.ecSeq}&chargeSeq=${urlParams.chargeSeq}&searchKeyword=${encodeURIComponent(urlParams.searchKeyword)}&limit=${urlParams.limit}&offset=${urlParams.offset}&actionMode=setTest`;

    request.get(apiUrl, null, successHandler).finally(() => {
      setIsLoading(false);
    });
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const onChangeLineHandler = (value) => {
    setClassesInfo((prev) => {
      return { ...prev, numPerPage: value };
    });
  };
  const nextPageMove = (value) => {
    setClassesInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };
  /** ClassList Row 선택 핸들러 */
  const handleClassSelect = (selectedClass) => {
    if (selectedClass.classSeq === classesInfo.selectedClassSeq) {
      setClassesInfo((prev) => ({
        ...prev,
        selectedClassSeq: 0,
        selectedClassName: '',
      }));
    } else {
      setClassesInfo((prev) => ({
        ...prev,
        selectedClassSeq: selectedClass.classSeq,
        selectedClassName: selectedClass.className,
      }));
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearchInfo((prev) => {
        return { ...prev, searchKeyword: e.target.value };
      });
    }
  };

  const searchClassList = (e) => {
    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: searchKeywordRef.current.value };
    });
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    getClassesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classesInfo.currentPage, classesInfo.numPerPage, searchInfo.searchKeyword]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <Layout classSeq={classesInfo.selectedClassSeq} selectedClassName={classesInfo.selectedClassName}>
      <article className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_ol black'>&nbsp;</i>Class List
        </h2>
      </article>
      <article className='com_list'>
        <section className='top'>
          <article className='search_area full'>
            {/* <PopupCalendarPeriod setSearchInfo={ setSearchInfo } tit="Select Date" /> */}
            {/* <div style={{ width: 400 }}>&nbsp;</div> */}
            <div className='search'>
              <input ref={searchKeywordRef} type='text' placeholder='Enter keywords' className='input search_area_input' onKeyUp={handleOnKeyPress} />
              <button className='com_btn m blue btn_search' onClick={searchClassList}>
                <i className='svg_icon icon_search blue before'></i>
                SEARCH
              </button>
            </div>
            <CustomSelect
              options={[
                { label: '10 line', value: 10 },
                { label: '25 line', value: 25 },
                { label: '50 line', value: 50 },
                { label: '100 line', value: 100 },
              ]}
              onSelect={onChangeLineHandler}
            />
          </article>
        </section>
        <table className='list'>
          <colgroup>
            <col width='56px' />
            <col width='53px' />
            <col />
            <col width='128px' />
            <col width='123' />
            <col width='120' />
            <col width='120' />
          </colgroup>
          <thead>
            <tr>
              {/* <th><input type="checkbox"  className="com_chk" id="chkAll" /><label htmlFor="chkAll">&nbsp;</label></th> */}
              <th> </th>
              <th>No.</th>
              <th>Classes Name</th>
              <th>Start Date </th>
              <th>No. of Students</th>
              <th>Test Scheduled</th>
              <th>Test Taken</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan='7'>Loading...</td>
              </tr>
            ) : classesInfo.classesList == null || classesInfo.classesList.length < 1 ? (
              <tr>
                <td colSpan='7'>No results. Please make sure you have searched correct keyword.</td>
              </tr>
            ) : (
              classesInfo.classesList.map((item, index) => {
                return (
                  <ClickableTableRow
                    key={`row_${index}`}
                    item={item}
                    index={index}
                    isSelected={Number(item.classSeq) === Number(classesInfo.selectedClassSeq)}
                    onSelect={handleClassSelect}>
                    <td>{classesInfo.totalCount - index}</td>
                    <td className='tit_field'>
                      <b className='ellipsis'>{item.className}</b>
                    </td>
                    <td>{dayjs(item.classStartDate).format('MM / DD / YYYY')}</td>
                    <td>{item.currentPeople}</td>
                    <td>
                      <TestList status='E' dataList={item?.testHistoryScheduled} /> {/* Test Scheduled */}
                    </td>
                    <td>
                      <TestList status='P' dataList={item?.testHistoryTaken} /> {/* Test Taken */}
                    </td>
                  </ClickableTableRow>
                );
              })
            )}
          </tbody>
        </table>
        <Paging
          totalCount={classesInfo.totalCount}
          currentPage={classesInfo.currentPage}
          numPerPage={classesInfo.numPerPage}
          pagePerBlock={classesInfo.pagePerBlock}
          nextPageMove={nextPageMove}
        />
      </article>
    </Layout>
  );
}
