import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import Gnb from '../layout/Gnb';
import Lnb from '../layout/Lnb';
import Footer from '../layout/Footer';

import request from '../utils/Request.utils';

import Login from 'components/user/sign-in/Page'; //로그인
import TestsSchedule from 'components/com/tests/scheduled/Page';
import TestsTakenPage from 'components/com/tests/taken/Page';

import TestsSetSelectTypePage from 'components/com/tests/set/selection/type/Page';
import TestsSetSelectClassPage from 'components/com/tests/set/selection/class/Page';
import TestsSetSelectStudentPage from 'components/com/tests/set/selection/student/Page';
import TestsSetConfigPage from 'components/com/tests/set/selection/config/Page';
import TestsSetSelectTestMorePage from 'components/com/tests/set/selection/tests/Page';
import TestsSetDatePage from 'components/com/tests/set/selection/date/Page';
import TestsSetReviewPage from 'components/com/tests/set/review/Page';

import TestCalendarPage from 'components/com/tests/calendar/Page';

import { USER_LEVELS } from '../utils/constants';
import StudentMainPage from 'components/student/Page';
import StudentPrintPage from 'components/student/print/Page';
import ScoreMainPage from 'components/com/score/Page';

import ScoreStudentsPage from 'components/com/score/students/Page';
import ScoreClassPage from 'components/com/score/class/Page';
import ScoreLecturePage from 'components/com/score/lecture/Page';

import ReportCardPage from 'components/report/Page';
import TestStatusByClassesPage from 'components/com/tests/status/Page';
import ClassesMainPage from 'components/classes/Page';
import ClassesPrintPage from 'components/classes/print/Page';
import IntroM from 'components/com/mobile-intro/Page';

const CommonRoute = () => {
  let { pathname } = window.location;
  let authBool = false;
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const navigate = useNavigate();

  if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname);

  console.log('pathname', pathname);

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  useEffect(() => {
    if (userInfo && !(userInfo.userLevel === USER_LEVELS.STUDENT || userInfo.userLevel === USER_LEVELS.PARENT) && pathname.indexOf('/tests/set') > -1) {
      const successHandler = (response) => {
        if (response.code === 200) {
          const { isFreezing } = response.result;

          if (isFreezing) {
            navigate('/academy/dashboard');
            return;
          }
        }
      };

      request.get('/api/academy/gatekey/freezing', null, successHandler).catch((error) => {
        console.log('key freezing 에러 발생! : ', error);
      });
    }
  }, [pathname, userInfo, navigate]);

  const AuthorizationRoutes = () => {
    if (authBool) {
      if (userInfo && userInfo.userLevel === USER_LEVELS.PARENT && !pathname.includes('calendar')) {
        window.location.href = '/student/dashboard';
        return;
      }

      const authRoutes = [
        { path: '/students', element: <StudentMainPage /> },
        { path: '/students/print', element: <StudentPrintPage /> },
        { path: '/students/classes', element: <ClassesMainPage /> },
        { path: '/old-score', element: <ScoreMainPage /> },
        { path: '/score/class/:classSeq', element: <ScoreClassPage /> },
        { path: '/score/student/:studentSeq', element: <ScoreStudentsPage /> },
        { path: '/score/lecture/:classSeq', element: <ScoreLecturePage /> },

        { path: '/classes/print', element: <ClassesPrintPage /> },

        { path: '/tests/scheduled', element: <TestsSchedule /> },
        { path: '/tests/calendar', element: <TestCalendarPage /> },
        { path: '/tests/taken', element: <TestsTakenPage /> },
        { path: '/tests/status', element: <TestStatusByClassesPage /> },

        { path: '/tests/set/selection/type', element: <TestsSetSelectTypePage /> },
        { path: '/tests/set/selection/class', element: <TestsSetSelectClassPage /> },
        { path: '/tests/set/selection/student', element: <TestsSetSelectStudentPage /> },
        { path: '/tests/set/selection/config', element: <TestsSetConfigPage /> },
        { path: '/tests/set/selection/tests', element: <TestsSetSelectTestMorePage /> },
        { path: '/tests/set/selection/date', element: <TestsSetDatePage /> },
        { path: '/tests/set/review', element: <TestsSetReviewPage /> },

        { path: '/reports', element: <ReportCardPage /> },
        { path: '/intro', element: <IntroM /> }, // added by psk 20240324

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/intro', element: <IntroM /> }, // added by psk 20240324
        { path: '/signin', element: <Login /> },
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };

  const PathCalendar = pathname.startsWith('/test/calendar');
  const studentsPathPrint = pathname.startsWith('/com/students/print');
  const classesPathPrint = pathname.startsWith('/com/classes/print');
  const PathReportCard = pathname.toLowerCase().startsWith('/com/reports');
  const mobileIntro = pathname.toLowerCase().startsWith('/com/intro');

  const renderLnb = () => {
    if (PathCalendar) return null;
    if (PathReportCard || studentsPathPrint || classesPathPrint || mobileIntro) return null;
    return <Lnb />;
  };

  const renderGnb = () => {
    if (PathReportCard || mobileIntro) return null;
    return <Gnb />;
  };

  const renderFooter = () => {
    if (PathReportCard || mobileIntro) return null;
    return <Footer />;
  };

  return (
    <>
      {renderGnb()}
      {renderLnb()}
      <main>
        <Routes>{AuthorizationRoutes()}</Routes>
      </main>
      {renderFooter()}
    </>
  );
};

export default CommonRoute;
