//#region    ////////// packages
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import PeriodicElement from './PeriodicElement';
//#endregion ////////// components

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const periodicTableData = [
  [{ indexNumber: '1' }, { empty: true, span: 16 }, { indexNumber: '18' }],
  [
    { symbol: 'H', number: '1', mass: '1.008' },
    { indexNumber: '2' },
    { empty: true, span: 10 },
    { indexNumber: '13' },
    { indexNumber: '14' },
    { indexNumber: '15' },
    { indexNumber: '16' },
    { indexNumber: '17' },
    { symbol: 'He', number: '2', mass: '4.00' },
  ],
  [
    { symbol: 'Li', number: '3', mass: '6.94' },
    { symbol: 'Be', number: '4', mass: '9.01' },
    { empty: true, span: 10 },
    { symbol: 'B', number: '5', mass: '10.81' },
    { symbol: 'C', number: '6', mass: '12.01' },
    { symbol: 'N', number: '7', mass: '14.01' },
    { symbol: 'O', number: '8', mass: '16.00' },
    { symbol: 'F', number: '9', mass: '19.00' },
    { symbol: 'Ne', number: '10', mass: '20.18' },
  ],
  [
    { symbol: 'Na', number: '11', mass: '22.99' },
    { symbol: 'Mg', number: '12', mass: '24.30' },
    { indexNumber: '3' },
    { indexNumber: '4' },
    { indexNumber: '5' },
    { indexNumber: '6' },
    { indexNumber: '7' },
    { indexNumber: '8' },
    { indexNumber: '9' },
    { indexNumber: '10' },
    { indexNumber: '11' },
    { indexNumber: '12' },
    { symbol: 'Al', number: '13', mass: '26.98' },
    { symbol: 'Si', number: '14', mass: '28.09' },
    { symbol: 'P', number: '15', mass: '30.97' },
    { symbol: 'S', number: '16', mass: '32.06' },
    { symbol: 'Cl', number: '17', mass: '35.45' },
    { symbol: 'Ar', number: '18', mass: '39.95' },
  ],
  [
    { symbol: 'K', number: '19', mass: '39.10' },
    { symbol: 'Ca', number: '20', mass: '40.08' },
    { symbol: 'Sc', number: '21', mass: '44.96' },
    { symbol: 'Ti', number: '22', mass: '47.87' },
    { symbol: 'V', number: '23', mass: '50.94' },
    { symbol: 'Cr', number: '24', mass: '52.00' },
    { symbol: 'Mn', number: '25', mass: '54.94' },
    { symbol: 'Fe', number: '26', mass: '55.85' },
    { symbol: 'Co', number: '27', mass: '58.93' },
    { symbol: 'Ni', number: '28', mass: '58.69' },
    { symbol: 'Cu', number: '29', mass: '63.55' },
    { symbol: 'Zn', number: '30', mass: '65.38' },
    { symbol: 'Ga', number: '31', mass: '69.72' },
    { symbol: 'Ge', number: '32', mass: '72.63' },
    { symbol: 'As', number: '33', mass: '74.92' },
    { symbol: 'Se', number: '34', mass: '78.97' },
    { symbol: 'Br', number: '35', mass: '79.90' },
    { symbol: 'Kr', number: '36', mass: '83.80' },
  ],
  [
    { symbol: 'Rb', number: '37', mass: '85.47' },
    { symbol: 'Sr', number: '38', mass: '87.62' },
    { symbol: 'Y', number: '39', mass: '88.91' },
    { symbol: 'Zr', number: '40', mass: '91.22' },
    { symbol: 'Nb', number: '41', mass: '92.91' },
    { symbol: 'Mo', number: '42', mass: '95.95' },
    { symbol: 'Tc', number: '43', mass: '' },
    { symbol: 'Ru', number: '44', mass: '101.07' },
    { symbol: 'Rh', number: '45', mass: '102.91' },
    { symbol: 'Pd', number: '46', mass: '106.42' },
    { symbol: 'Ag', number: '47', mass: '107.87' },
    { symbol: 'Cd', number: '48', mass: '112.41' },
    { symbol: 'In', number: '49', mass: '114.82' },
    { symbol: 'Sn', number: '50', mass: '118.71' },
    { symbol: 'Sb', number: '51', mass: '121.76' },
    { symbol: 'Te', number: '52', mass: '127.60' },
    { symbol: 'I', number: '53', mass: '126.90' },
    { symbol: 'Xe', number: '54', mass: '131.29' },
  ],
  [
    { symbol: 'Cs', number: '55', mass: '132.91' },
    { symbol: 'Ba', number: '56', mass: '137.33' },
    { symbol: '*', number: '75-71', mass: false },
    { symbol: 'Hf', number: '72', mass: '178.49' },
    { symbol: 'Ta', number: '73', mass: '180.95' },
    { symbol: 'W', number: '74', mass: '183.84' },
    { symbol: 'Re', number: '75', mass: '186.21' },
    { symbol: 'Os', number: '76', mass: '190.23' },
    { symbol: 'Ir', number: '77', mass: '192.22' },
    { symbol: 'Pt', number: '78', mass: '195.08' },
    { symbol: 'Au', number: '79', mass: '196.97' },
    { symbol: 'Hg', number: '80', mass: '200.59' },
    { symbol: 'Tl', number: '81', mass: '204.38' },
    { symbol: 'Pb', number: '82', mass: '207.2' },
    { symbol: 'Bi', number: '83', mass: '208.98' },
    { symbol: 'Po', number: '84', mass: '' },
    { symbol: 'At', number: '85', mass: '' },
    { symbol: 'Rn', number: '86', mass: '' },
  ],
  [
    { symbol: 'Fr', number: '87', mass: '' },
    { symbol: 'Ra', number: '88', mass: '' },
    { symbol: '**', number: '89-103', mass: false },
    { symbol: 'Rf', number: '104', mass: '' },
    { symbol: 'Db', number: '105', mass: '' },
    { symbol: 'Sg', number: '106', mass: '' },
    { symbol: 'Bh', number: '107', mass: '' },
    { symbol: 'Hs', number: '108', mass: '' },
    { symbol: 'Mt', number: '109', mass: '' },
    { symbol: 'Ds', number: '110', mass: '' },
    { symbol: 'Rg', number: '111', mass: '' },
    { symbol: 'Cn', number: '112', mass: '' },
    { symbol: 'Nh', number: '113', mass: '' },
    { symbol: 'Fl', number: '114', mass: '' },
    { symbol: 'Mc', number: '115', mass: '' },
    { symbol: 'Lv', number: '116', mass: '' },
    { symbol: 'Ts', number: '117', mass: '' },
    { symbol: 'Og', number: '118', mass: '' },
  ],
];
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** AP 시험에서 보일 주기율표 컴포넌트 */
export default function MainPeriodicTable() {
  return (
    <div>
      {periodicTableData.map((row, rowIndex) => (
        <S.Row key={rowIndex}>
          {row.map((element, elementIndex) =>
            // 빈 공간일 경우
            element.empty ? (
              <S.EmptySpace key={`empty-${elementIndex}`} $span={element.span} />
            ) : element.indexNumber ? (
              // 원자번호 표시 영역일 경우
              <S.IndexNumber key={`index-${elementIndex}`}>
                <span>{element.indexNumber}</span>
              </S.IndexNumber>
            ) : (
              // 원소 표시 영역일 경우
              <PeriodicElement key={elementIndex} element={element} />
            )
          )}
        </S.Row>
      ))}
    </div>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  Row: styled.div`
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-gap: 1px;
  `,

  EmptySpace: styled.div`
    grid-column: span ${(props) => props.$span};
  `,

  IndexNumber: styled.div`
    border: none;
    background-color: transparent;
    font-size: 1.75rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 4px;
    color: #767676;
  `,
};
