import React from 'react';
import styled from 'styled-components';

/** 케밥 메뉴 아이콘 (세로 형태의 원으로 구성 된 메뉴 아이콘) 컴포넌트 (아이콘 스타일만을 정의 하였고, 어떠한 기능도 없다. 심지어 버튼 태그도 아닌 오직 순서한 아이콘 설정용)
 * 메뉴 아이콘 이름 참고 : https://chaeyeon-chaeyeon.tistory.com/67
 */
export default function IconKebabMenu({ color, dotSize, dotRound, ...rest }) {
  return (
    <S.Wrap dotSize={dotSize} dotRound={dotRound} color={color} {...rest}>
      <i></i>
      <i></i>
      <i></i>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    i {
      display: inline-block;
      width: ${(props) => props.dotSize || '.25rem'};
      height: ${(props) => props.dotSize || '.25rem'};
      border-radius: ${(props) => props.dotRound || '100%'};
      background-color: ${(props) => props.color || '#111111'};
    }
  `,
};
