import styled, { css } from 'styled-components';
import { ReactComponent as ImgSvgIconAlert } from 'assets/img/svg/icon_alert_circle_01.svg';
import { ReactComponent as ImgSvgIconAlertTriangle } from 'assets/img/svg/icon_alert_triangle_01.svg';

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
/** 툴팁 타입별 색상 변수 */
const tooltipColors = {
  default: '#000000',
  error: '#FF4D4D',
  info: '#17A2B8',
  // warning: '#FFA500',
  // success: '#28A745',
};
/** 화살표 생성 함수 - 모든 방향의 화살표 스타일을 생성 */
const createArrow = ({ position, color }) => {
  // 모든 방향에 대한 기본 스타일
  const baseStyle = css`
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  `;

  // 방향별 스타일 정의
  const styles = {
    top: css`
      ${baseStyle}
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-top-color: ${color};
    `,
    bottom: css`
      ${baseStyle}
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-bottom-color: ${color};
    `,
    left: css`
      ${baseStyle}
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-left-color: ${color};
    `,
    right: css`
      ${baseStyle}
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-right-color: ${color};
    `,
  };

  return styles[position];
};
/** 위치에 따른 툴팁 위치 스타일 */
const positionStyles = {
  top: css`
    bottom: calc(100% + 0.75rem);
    left: 50%;
    transform: translateX(-50%);
  `,
  bottom: css`
    top: calc(100% + 0.75rem);
    left: 50%;
    transform: translateX(-50%);
  `,
  left: css`
    right: calc(100% + 0.75rem);
    top: 50%;
    transform: translateY(-50%);
  `,
  right: css`
    left: calc(100% + 0.75rem);
    top: 50%;
    transform: translateY(-50%);
  `,
};

const shakeAnimation = (position) => {
  switch (position) {
    case 'top':
    case 'bottom':
      return css`
        @keyframes shake {
          0%,
          100% {
            transform: translateX(-50%) translateY(0);
          }
          5%,
          15%,
          25%,
          35%,
          45%,
          55%,
          65%,
          75%,
          85%,
          95% {
            transform: translateX(-50%) translateY(-1.5px);
          }
          10%,
          20%,
          30%,
          40%,
          50%,
          60%,
          70%,
          80%,
          90% {
            transform: translateX(-50%) translateY(1.5px);
          }
        }
      `;
    case 'left':
    case 'right':
      return css`
        @keyframes shake {
          0%,
          100% {
            transform: translateY(-50%) translateX(0);
          }
          5%,
          15%,
          25%,
          35%,
          45%,
          55%,
          65%,
          75%,
          85%,
          95% {
            transform: translateY(-50%) translateX(-1.5px);
          }
          10%,
          20%,
          30%,
          40%,
          50%,
          60%,
          70%,
          80%,
          90% {
            transform: translateY(-50%) translateX(1.5px);
          }
        }
      `;
    default:
      return '';
  }
};
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/**
 * 순수 툴팁 UI 컴포넌트 - 스타일링만 담당하며 호버를 사용해 퉁팁을 노출시킴
 *
 * @param {React.ReactNode} children 툴팁 컴포넌트의 자식 요소
 * @param {string} text 툴팁 텍스트
 * @param {string} position 툴팁 위치 // top, bottom, left, right
 * @param {string} type 툴팁 타입 // default, error, warning, success, info
 * @param {boolean} hoverEnabled 호버시 툴팁 표시 여부 // true, false
 * @param {boolean} visible FeedbackTooltip 컴포넌트를 통해 툴팁을 제어할때 사용되는 속성 // true, false
 */
export default function UI_Tooltip({ children, text, position = 'top', type = 'default', visible = false, hoverEnabled = false }) {
  return (
    <S.TooltipContainer $hoverEnabled={hoverEnabled} $visible={visible}>
      {children}
      <S.TooltipContent $position={position} $type={type} $visible={visible}>
        {type === 'info' && <ImgSvgIconAlert className='icon_alert' />}
        {type === 'error' && <ImgSvgIconAlertTriangle className='icon_alert' />}

        {text}
      </S.TooltipContent>
    </S.TooltipContainer>
  );
}

const S = {
  TooltipContainer: styled.div`
    position: relative;
    display: inline-block;
    width: fit-content;

    /* hoverEnabled가 true일 때만 hover 효과 적용 */
    ${(props) =>
      props.$hoverEnabled &&
      css`
        &:hover > div {
          visibility: visible;
          opacity: 1;
          transition: opacity 0.3s;
        }
      `}
  `,
  TooltipContent: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    /* visible prop이 true일 때 표시 (hoverEnabled가 false인 경우 이 조건만 사용됨) */
    visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.$visible ? 1 : 0)};
    width: max-content;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: left;
    border-radius: 0.5rem;
    padding: 0.5rem 0.75rem;
    z-index: 9999;
    position: absolute;
    pointer-events: none;
    transition: all 0.3s;
    white-space: pre-wrap; // \n 으로 줄바꿈 처리

    /* 선명한 하얀색 테두리 효과와 그림자를 위한 drop-shadow 필터 적용 */
    filter: drop-shadow(0 0 1px white) drop-shadow(0 0 1px white) drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.24));

    /* 타입에 따른 배경색과 텍스트 색상 */
    background-color: ${(props) => tooltipColors[props.$type]};
    color: white;

    /* 위치에 따른 배치 */
    ${(props) => positionStyles[props.$position]}

    /* 화살표 생성 */
    &::after {
      ${(props) =>
        createArrow({
          position: props.$position,
          color: tooltipColors[props.$type],
        })}
    }

    .icon_alert {
      width: 1.5rem;
      height: 1.5rem;
    }

    /* 타입이 error일 때 흔들리는 애니메이션 적용 */
    ${(props) =>
      props.$type === 'error' &&
      css`
        animation: shake 0.3s ease-in-out forwards;
        ${shakeAnimation(props.$position)}
      `}
  `,
};
