import styled from 'styled-components';

////////// components

/** http://localhost:3000/ap/academy/teacher-subject  */
export default function TeacherSubject() {
  return (
    <>
      <div className='com_center_wrap' style={{ paddingTop: '7rem' }}>
        {/* <SetTestsStep /> */}
        <div className='com_box'>
          <S.TopArea>
            <h2>Teacher-Student Linking</h2>
            <p>Select Students you want to import</p>
          </S.TopArea>
          <S.Layout>
            <S.LeftContainer>
              <div style={{ backgroundColor: 'red' }}>ㅁㅁ</div>
              <div style={{ backgroundColor: 'yellow' }}>ㄴㄴ</div>
            </S.LeftContainer>
            <div style={{ backgroundColor: 'blue' }}>ㅇㅇ</div>
          </S.Layout>

          <article className='com_btn_wrap bottom' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button className='com_btn point l' onClick={close}>
              NEXT
            </button>
          </article>
        </div>
      </div>
    </>
  );
}

const S = {
  TopArea: styled.div`
    margin: 1.5rem 1.25rem;
    padding: 1.5px;

    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.75rem; /* 140% */
    }
    p {
      color: #999;

      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem; /* 142.857% */
    }
  `,

  Layout: styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    gap: 20px;
  `,

  LeftContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
};
