import { useEffect, useState } from 'react';
import dayjsTZ, { getClientTimezone } from 'utils/functions/time/dayjs-config';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import localStorage from 'utils/LocalStorage.utils';

const COMPANY_TYPE_KEY = 'company_type';
const REFRESH_INTERVAL = 30000; // 30초

/** 현재 유저의 타임존을 확인시켜주는 컴포넌트 */
export default function TimeZoneDisplayArea() {
  const userInfo = request.tokenDecoder();
  const [companyType, setCompanyType] = useState('');
  const [currentTime, setCurrentTime] = useState(dayjsTZ().format('MMMM D, HH:mm'));

  /** 연결된 학원 타입 가져오기 - 로컬스토리지에서 확인후 값이 있으면 호출 대신 로컬스토리지 값 사용 */
  const getCompanyType = () => {
    const storedCompanyType = localStorage.getItemJsonParse('util')?.company_type;

    if (storedCompanyType) {
      setCompanyType(storedCompanyType);
      return;
    }

    const successHandler = (response) => {
      if (response.code === 200) {
        const companyType = response.result.companyType;
        // 로컬스토리지 - util에 데이터 저장
        localStorage.setItem('util', COMPANY_TYPE_KEY, companyType);
        setCompanyType(companyType);
      }
    };
    request.get('/api/academy/company/type', null, successHandler);
  };

  useEffect(() => {
    getCompanyType();

    // 시간 업데이트 함수
    const updateTime = () => {
      setCurrentTime(dayjsTZ().format('MMMM D, HH:mm'));
    };

    // 30초마다 시간 업데이트
    const intervalId = setInterval(updateTime, REFRESH_INTERVAL);

    // cleanup 함수
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const displayText = `${companyType} Timezone: ${currentTime}`;

  /** 유저한테 설정된 타임존이 client 타임존과 일치하거나, 타임존 정보가 없다면 -> 랜더링 안함  */
  const isRenderTimeZone = userInfo?.timeZone !== '' && userInfo?.timeZone !== getClientTimezone();

  return (
    isRenderTimeZone && (
      <S.Wrap>
        <p>{displayText}</p>
      </S.Wrap>
    )
  );
}

const S = {
  Wrap: styled.div`
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    justify-content: flex-end;
    font-size: 0.875rem;
    color: #505050;
    line-height: 1.4;
  `,
};
