import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import useOutsideClick from 'hooks/useOutsideClick';
import { ReactComponent as ImgIconSelect } from 'assets/img/svg/icon_arrow_02.svg';

/** "/com/score" 페이지 상단 메인 "클래스 선택" 영역 컴포넌트 */
export default function SelectClass({ setScorePageInfo, classTestTaken, scorePageInfo }) {
  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [classList, setClassList] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const classListBox = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const handleClass = (e, classSeq, className) => {
    e.stopPropagation();
    setScorePageInfo((prev) => {
      return { ...prev, className: className };
    });
    setClassList(false);

    classTestTaken(classSeq);
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 //////////////////
  // 외부 클릭시 닫히는 훅
  useOutsideClick(classListBox, () => setClassList(false));
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  return (
    <S.Wrap title='Open/Close' className={`type_tit type_big ${classList ? 'active' : ''}`} onClick={() => setClassList((prev) => !prev)} ref={classListBox}>
      {/*클래스 active 포함 시 옵션 노출*/}
      <section className='selected_wrap'>
        <p className='tit_field'>Class</p>
        <input type='text' className='selected' placeholder={scorePageInfo.className} />
      </section>
      <section className='layer'>
        {scorePageInfo.classList &&
          scorePageInfo.classList?.map((item, index) => (
            <p key={item.classSeq} className='option' onClick={(e) => handleClass(e, item.classSeq, item.className)}>
              {item.className}
            </p>
          ))}
        {/* <p className="option" onClick={ (e) => handleOptionClick(e, 0, "Class All") }>Class All</p> */}
      </section>
      <div className='btn_dropdown'>
        <ImgIconSelect />
      </div>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  height: 100%;
  width: 170px;
  position: relative;
  border-radius: 0.5rem;
  background: #fff;
  border: 2px solid #020202;
  cursor: pointer;
  .selected_wrap {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex: 1;
    text-align: center;
    padding: 0 3.75rem 0 1rem;
    font-weight: 700;
    font-size: 18px;
  }
  .layer {
    position: absolute;
    background: #fff;
    margin-top: 1px;
    box-shadow: 0px 2px 6px -3px #00000026;
    left: 0;
    right: 0;
    font-size: 12px;
    display: none;
    z-index: 3;
    border-radius: 4px;
    overflow: auto;
    color: #7f8998;
    max-height: 150px;
    .option {
      text-align: center;
      padding: 7px 12px;
      border-bottom: 1px solid #d2dbe2;
      &:hover {
        background: #f2f2f2;
        cursor: pointer;
      }
      &.disabled {
        background: #f4f4f4;
        color: #a6b3be;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }

  .selected {
    border-radius: 4px;
    border-color: #000;
    height: 30px;
    background: #fff;
    outline: none;
    padding: 11px;
    padding-right: 22px;
    border: 1px solid #000;
    width: 100%;
    @include multi-ellipsis(1);
    caret-color: transparent;
    cursor: default;
    &::placeholder {
      // Chrome, Safar
      color: #000;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      // IE, Edge
      color: #000;
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
  }
  textarea.selected {
    resize: none;
    line-height: 1;
    padding-block: 7px;
  }
  .layer {
    position: absolute;
    background: #fff;
    margin-top: 1px;
    box-shadow: 0px 2px 6px -3px #00000026;
    left: 0;
    right: 0;
    font-size: 12px;
    display: none;
    z-index: 3;
    border-radius: 4px;
    overflow: auto;
    color: #7f8998;
    max-height: 150px;
    .option {
      padding: 7px 12px;
      border-bottom: 1px solid #d2dbe2;
      &:hover {
        background: #f2f2f2;
        cursor: pointer;
      }
      &.disabled {
        background: #f4f4f4;
        color: #a6b3be;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
  &.active {
    .layer {
      display: block;
    }
  }
  &.type_tit {
    .selected {
      border: none;
      border-radius: 0;
      font-size: 1.125rem;
      font-weight: 600;
      &__wrap {
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        border: 1px solid #020202;
        @include flex(between);
        .tit_field {
          font-size: 12px;
          font-weight: 500;
          padding-left: 20px;
          @include flex(y);
          @include divider(1px, 15px, 10px);
        }
      }
    }
  }
  &.type_big {
    width: 170px;
    .tit_field {
      font-size: 1.125rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      &::after {
        content: '';
        display: inline-block;
        background-color: #f1f1f5;
        width: 1px;
        height: 1rem;
        vertical-align: -2px;
        margin-right: 0;
      }
    }
    .selected {
      height: 42px;
      width: 61%;
      flex: 1;
      text-align: center;
      padding-right: 43px;
      font-weight: 700;
      font-size: 18px;
      @include multi-ellipsis(1);
      &__wrap {
        border: 3px solid #020202;
      }
    }
    .layer {
      .option {
        text-align: center;
      }
    }
  }
  &.type_updown {
    &:before {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(calc(-50% - 5px)) rotate(180deg);
      @include addImg('icon_select.png', 10px, 6px, contain);
      @include svg-icon-color('icon_select', '000');
    }
    &:after {
      transform: translateY(calc(-50% + 5px));
    }
    .selected {
      border-color: $input-stroke;
    }
  }
  //컬러
  &.gray {
    &:after {
      background-color: $emphasis;
    }
    .selected {
      &__wrap {
        border-color: $emphasis;
      }
      .tit_field {
        color: $emphasis;
      }
    }
  }

  .btn_dropdown {
    background-color: #111111;
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 1rem;
      color: #ffffff;
    }
  }
`;
