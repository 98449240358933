import React from 'react';
import styled from 'styled-components';

/** Form 의 각 개별 항목을 묶는 스타일용 컴포넌트 */
export default function FormItemWrap({ children }) {
  return <S.Wrap>{children}</S.Wrap>;
}

const S = {};

S.Wrap = styled.div`
  &:has(input[required]) label::before {
    content: '*';
    color: var(--sub-red);
    margin-right: 0.325rem;
  }
`;
