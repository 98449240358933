import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import LoadingBar from 'utils/LoadingBar';

//component
import CustomAlert from 'components/_common/alerts/CustomAlert';
import MainBox from 'components/user/_components/layouts/MainBox';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import Header from 'components/user/sign-up/_components/layouts/Header';
import Upload from 'components/user/sign-up/_components/pages/upload/_Upload';

/** 회원가입 - 일반 이메일 회원 가입 - 아카데미 어드민 - File Upload 페이지 */
export default function SignUpEmailAcademyAdminUploadPage() {
  const navigate = useNavigate();
  const stateCustomer = useSelector((state) => state.Customer);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  // 알랏
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
    value: 0,
  });

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        alertType: 'alert',
        visible: false,
        alertMessage: '',
        activateMode: '',
        value: 0,
      };
    });
  };

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
    }
  };

  const signup = () => {
    // setLoading(true);
    // {
    //     "userId": "115646637300991155393",
    //     "loginType": "google",
    //     "userEmail": "wjddnr5163@gmail.com",
    //     "userLevel": "001001001000000",
    //     "userMobile": "1231",
    //     "userName": "uk jung",
    //     "companyName": "213",
    //     "officePhone": {
    //         "num1": "123",
    //         "num2": "1",
    //         "num3": "2"
    //     },
    //     "agree_privacyPolicy": true,
    //     "agree_termsService": true,
    //     "agree_newsLetter": true,
    //     "isNew": true
    // }

    console.log(stateCustomer);
    const companyNumber = stateCustomer.companyNum;
    const splitCompanyNumber = companyNumber.split('-');
    let params = {
      userId: stateCustomer.userEmail, //
      loginType: 'email', // add
      user_passwd: stateCustomer.userPasswd, // add
      userEmail: stateCustomer.userEmail, //
      userName: stateCustomer.userName, //
      userLevel: stateCustomer.userLevel,
      managerLevel: '002002000000000',
      userMobile: stateCustomer.userMobile,
      acceptReceivingMail: stateCustomer.mailYn,
      companyName: stateCustomer.companyName,
      officePhone: {
        num1: splitCompanyNumber[0],
        num2: splitCompanyNumber[1],
        num3: splitCompanyNumber[2],
      },
      agree_privacyPolicy: true,
      agree_termsService: stateCustomer.mailYn == 'Y' ? true : false,
      agree_newsLetter: stateCustomer.marketingYn == 'Y' ? true : false,
      isNew: true,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let userSeq = response.result.userSeq;
        uploadActivate(userSeq);

        request.del(`/api/common/authen/code/${params.userEmail}`);
      }
    };
    console.log(params);
    // request.post('/api/common/user', params, successHandler);
    request.post(request.apiUris.auth.signupAcademy, params, successHandler);
  };

  const uploadActivate = (userSeq) => {
    // console.log("전송", uploadFileList)

    const formData = new FormData();

    Object.values(uploadFileList).forEach((file) => formData.append('uploadFiles', file.fileData));

    // for(const fileInfo of uploadFileList) {
    //   console.log(fileInfo.fileTextData, fileInfo.fileData)
    // }

    const successHandler = (response) => {
      setLoading(false);
      navigate('/signup/successfully-submitted');
    };

    request.postMultipart(`/api/common/upload/files/academy/${userSeq}`, formData, successHandler);
  };

  const submitBtn = () => {
    if (uploadFileList.length > 0) {
      signup();
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please upload the file.',
        };
      });
    }
  };

  return (
    <>
      <MainBox>
        <S.Wrap>
          <Header title={'Academy Admin'} />
          <Upload uploadFileList={uploadFileList} setUploadFileList={setUploadFileList} setAlertLayerPopup={setAlertLayerPopup} />
          <PrimaryButtonArea>
            <PrimaryButton type='button' onClick={submitBtn} title='제출'>
              Submit
            </PrimaryButton>
          </PrimaryButtonArea>
        </S.Wrap>
      </MainBox>
      {isLoading && <LoadingBar />}
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
    </>
  );
}

const S = {};

S.Wrap = styled.div``;
