import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import dayjs from 'dayjs';

import request from 'utils/Request.utils.js';
import { nvlNumber } from 'utils/Common.utils.js';
import LoadingBar from 'utils/LoadingBar.js';

//components
//chart
import MainTopArea from './_components/layouts/old-main-top-area/MainTopArea';
import Profile from './_components/sections/profile/Profile';
import TestList from './_components/sections/testList/TestList';
import OuterBox from './_components/common/OuterBox';
import BtoBMainTabFrameHOC from 'layout/commonFrame/BtoBMainTabFrameHOC';
import SelectDetailSection from './_components/layouts/main-top-area/SelectDetailSection';
import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';

/**
 * ap/com/score/ 페이지
 * 사용하지 않는 페이지
 */
export default function ApScorePage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  /** 현재 studentSeq - Route Parameter */
  const { studentSeq: encStudentSeq } = useParams();
  const studentSeq = deobfuscateUrlParam(encStudentSeq);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////

  const [printActive, setPrintActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scorePageInfo, setScorePageInfo] = useState({
    classSeq: 0,
    testSetSeq: 0,
    classList: [],
    takenTest: [],
    memberList: [],
    memberList2: [],
    className: '',
    testName: '',
    startDate: '',
    endDate: '',
    weeklyEngScores: [],
    weeklyMathScores: [],
    weeklyDate: [],
  });

  /** 학원에 속한 학생들의 정보 - 검색 결과 */
  const [searchedStudents, setSearchedStudents] = useState([
    {
      userSeq: 0, // number      => 유저 시퀀스
      userName: '', // string       => 유저 이름
      userEmail: '', // string      => 유저 이메일
      // 현재 유저가 속해있는 class 목록
      classList: [
        {
          classSeq: 0, // number    => 반 시퀀스
          className: '', // string  => 반 이름
        },
      ],
    },
  ]);

  /** 검색 데이터 */
  const [searchInfo, setSearchInfo] = useState({
    searchField: '', // '' | 'user_name' | 'class_name' | 'user_email'  => 검색 필드
    searchKeyword: '', // string                                        => 검색 키워드
  });

  /** Class, Student, Lecture 탭 */
  const [tabState, setTabState] = useState(0);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const getClassList = () => {
    setLoading(true);
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let classList = response.result.classList || [];

        let latestClassSeq = response.result.maxClass || 0;

        let latestClass = classList?.find((item) => item.classSeq === latestClassSeq);

        setScorePageInfo((prev) => {
          return {
            ...prev,
            classSeq: latestClassSeq,
            testSetSeq: latestClass?.customSetSeq,
            classList: classList,
            className: latestClass?.className,
          };
        });

        if (latestClassSeq > 0) classTestTaken(latestClassSeq);
        else setLoading(false);
      }
    };

    request.get(`/api/dsat/score/class?ecSeq=${userInfo?.ecSeq}`, null, successHandler).catch((error) => console.error('', error));
  };

  const classTestTaken = (classSeq) => {
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        let takenTest = response.result.takenTest;

        setLoading(false);
        if (takenTest.length > 0) {
          const shortEndDate = takenTest.sort((a, b) => dayjs(b.testEndDate) - dayjs(a.testEndDate));
          setScorePageInfo((prev) => {
            return {
              ...prev,
              classSeq: classSeq,
              testSetSeq: takenTest[0]?.customSetSeq,
              takenTest: shortEndDate,
              testName: takenTest[0]?.testName,
            };
          });
          classAvgPoint(classSeq, takenTest[0].customSetSeq);
        } else {
          setScorePageInfo((prev) => {
            return {
              ...prev,
              classSeq: 0,
              testSetSeq: 0,
              takenTest: [],
              testName: '',
              memberList: [],
              memberList2: [],
            };
          });
          setScorePageInfo((prev) => {
            return {
              ...prev,
              weeklyEngScores: [null, null, null, null, null, null, null, null],
              weeklyMathScores: [null, null, null, null, null, null, null, null],
            };
          });
        }
      }
    };

    request.get(`/api/dsat/score/class/taken?ecSeq=${userInfo?.ecSeq}&classSeq=${classSeq}`, null, successHandler).catch((error) => console.error('', error));
  };

  const classAvgPoint = (classSeq, customSetSeq) => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let memberList = response.result.memberTakerList;
        let memberList2 = response.result.memberList;

        setScorePageInfo((prev) => {
          return {
            ...prev,
            classSeq: classSeq,
            testSetSeq: customSetSeq,
            memberList: memberList,
            memberList2: memberList2,
            testName: memberList[0]?.testName,
            startDate: memberList[0]?.testStartDate,
            endDate: memberList[0]?.testEndDate,
          };
        });

        weeklyScores(classSeq, memberList[0].testStartDate.split(' ')[0]);

        if (tabState === 1) {
          setTabState(0);
        } else {
        }
      }
    };

    request
      .get(`/api/dsat/average/class?ecSeq=${userInfo?.ecSeq}&customSetSeq=${customSetSeq}&classSeq=${classSeq}`, null, successHandler)
      .catch((error) => console.error('', error));
  };

  const weeklyScores = (classSeq, testStartDate, userSeq) => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let weeklyScores = response.result.weeklyScores;

        let engAvg = weeklyScores?.filter((i) => i.subject === 'English');
        let mathAvg = weeklyScores?.filter((i) => i.subject === 'Math');

        let engAvgPoint = engAvg?.map((i) => i.data);
        let mathAvgPoint = mathAvg?.map((i) => i.data);
        let weeklyDate = engAvg?.map((i) => dayjs(i.mondayDt).format('MM/DD'));

        setScorePageInfo((prev) => {
          return {
            ...prev,
            weeklyEngScores: engAvgPoint,
            weeklyMathScores: mathAvgPoint,
            weeklyDate: weeklyDate,
          };
        });
      }
    };

    request
      .get(
        `/api/dsat/score/weekly?ecSeq=${userInfo?.ecSeq}&classSeq=${classSeq}&userSeq=${nvlNumber(userSeq)}&testStartDate=${testStartDate}`,
        null,
        successHandler
      )
      .catch((error) => console.error('', error));
  };

  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    if (userInfo?.ecSeq > 0) getClassList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabState !== 1 && nvlNumber(scorePageInfo.classSeq) > 0) classAvgPoint(scorePageInfo.classSeq, scorePageInfo.testSetSeq);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState]);

  useEffect(() => {
    if (printActive) {
      window.open(`/com/reports`, '_blank');
      setPrintActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printActive]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <BtoBMainTabFrameHOC>
        <S.Warp className='p_score_main'>
          <OuterBox className='main_wrap'>
            <MainTopArea>
              <SelectDetailSection variant='student' dataList={searchedStudents} isModalActive={false} modalStateHandler={setSearchInfo} />
            </MainTopArea>
            <div className='flex_row'>
              <Profile />
              <TestList />
            </div>
          </OuterBox>
        </S.Warp>
      </BtoBMainTabFrameHOC>

      {loading && <LoadingBar />}
      {/*report card*/}
      {/* { printActive && <ReportCard setPrintActive={ setPrintActive } testResult={ testResult } barChartData={ barChartData } barChartData2={ barChartData2 } timePerQuestionData={timePerQuestionData} filterValue={filterValue} setFilterValue={setFilterValue}/> } */}
    </>
  );
}

const S = {
  Warp: styled.div`
    .main_wrap {
      width: 1194px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .flex_row {
      display: flex;
      gap: 0.75rem;
    }
  `,
};
