import { useEffect, useState } from 'react';
import ApLeftArea from './ApLeftArea';
import QuestionArea from './QuestionArea';

import ReviewPopup from './ReviewPopup';

import { MathJax } from 'better-react-mathjax';

/** AP 시험 화면 본문 영역 컴포넌트 */
function ExamBody({ stateExamInfo, setStateExamInfo, currentQuestionInfo, setQuestionInfo }) {
  const [questionArea, setQuestionArea] = useState(null);
  /** 초기 마운트를 알리는 state ( useEffect로 첫 마운트때 변경 ) */
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    // 즉시 State 업데이트시 종종 씹히는 문제 있어 setTimeout 추가
    const initRender = setTimeout(() => {
      if (isInitialRender) setIsInitialRender(false);
    }, 300);

    return () => clearTimeout(initRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 타이머와 관계없는 속성들이 바뀔때만 렌더링
  useEffect(() => {
    setQuestionArea(
      stateExamInfo.isReviewPopupOpen ? (
        <ReviewPopup stateExamInfo={stateExamInfo} setStateExamInfo={setStateExamInfo} />
      ) : (
        <MathJax hideUntilTypeset='first' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }} className={`pop_body`}>
          {(currentQuestionInfo.sourceContent || currentQuestionInfo.sourceDirections) && (
            <ApLeftArea
              stateExamInfo={stateExamInfo}
              sourceDirections={currentQuestionInfo?.sourceDirections}
              source={currentQuestionInfo?.sourceContent ? JSON.parse(currentQuestionInfo.sourceContent) : []}
              currentQuestionOrder={currentQuestionInfo?.questionOrder}
            />
          )}
          <QuestionArea stateExamInfo={stateExamInfo} currentQuestionInfo={currentQuestionInfo} setQuestionInfo={setQuestionInfo} />
        </MathJax>
      )
    );
  }, [currentQuestionInfo, setQuestionInfo, setStateExamInfo, stateExamInfo]);

  return questionArea;
}

export default ExamBody;
