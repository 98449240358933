import React from 'react';
import styled from 'styled-components';

import FavoriteButton from './FavoriteButton';
import TestLabel from './TestLabel';

const { useSelector } = require('react-redux');

/** 시험 모듈 버튼 컴포넌트 */
export default function TestButton({
  isB2c,
  tit,
  seq,
  rw,
  math,
  rwTakenNum,
  maTakenNum,
  rwTakenUser,
  maTakenUser,
  isLike,
  viewAverageSubject,
  selectModules,
  setSelectModules,
  setCustomSetInfo,
}) {
  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  const toggleActive = (item) => {
    if (!selectModules.includes(item)) {
      const updateSelectList = [...selectModules, item];
      setSelectModules(updateSelectList);
    } else {
      const updateSelectList = selectModules.filter((key) => key !== item);
      setSelectModules(updateSelectList);
    }
  };

  return (
    <S.Wrap className={`${selectModules.includes(seq) ? 'active' : ''}`}>
      <div className='top_info'>
        <div className='label_wrap'>
          {viewAverageSubject !== 'M' && rwTakenNum > 0 && (
            <TestLabel subject={'RW'} studentsNumber={isB2c ? null : stateSetTest.selectedStudents.length} isB2c={isB2c} takenUserDataArray={rwTakenUser} />
          )}
          {viewAverageSubject !== 'RW' && maTakenNum > 0 && (
            <TestLabel subject={'MA'} studentsNumber={isB2c ? null : stateSetTest.selectedStudents.length} isB2c={isB2c} takenUserDataArray={maTakenUser} />
          )}
        </div>
        <FavoriteButton active={isLike} seq={seq} isB2c={isB2c} setCustomSetInfo={setCustomSetInfo} />
      </div>
      <button className='text_area' onClick={() => toggleActive(seq)}>
        <span className='tit_field'>{tit}</span>
        {viewAverageSubject === 'F' ? (
          <span className='txt_field'>
            {rw}
            <br />
            {math}
          </span>
        ) : viewAverageSubject === 'RW' ? (
          <span className='txt_field'>
            {rw}
            <br />
          </span>
        ) : (
          <span className='txt_field'>
            {math}
            <br />
          </span>
        )}
      </button>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    border: 1px solid #d2dbe2;
    padding: 0.75rem 0.75rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    gap: 0.75rem;
    border-radius: 10px;
    align-self: stretch;
    width: calc((100% - 30px) / 3);
    min-width: calc((100% - 30px) / 3);
    max-width: calc((100% - 30px) / 3);
    position: relative;
    &:hover {
      border-color: #8cabf0;
      background-color: #fbfbff;
      color: #11456f;
    }
    .top_info {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      min-height: 1.25rem;
      width: 100%;
      .label_wrap {
        display: flex;
        gap: 0.25rem;
      }
    }
    .text_area {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      text-align: center;
    }
    .tit_field {
      font-size: 16px;
      font-weight: 500;
    }
    .txt_field {
      font-size: 10px;
      line-height: 16px;
      color: $emphasis;
      & + .com_chk_wrap {
        margin-top: 10px;
      }
    }
    &.active {
      border-color: #477bff;
      background-color: #f7f8ff;
      & :is(.tit_field, .txt_field) {
        color: #0068bd;
      }
      .layer_pop_area {
        color: #2c85cf;
        background-color: #fff;
        border-color: #d1deff;
        &:hover {
          border-color: var(--point-color);
          color: var(--point-color);
        }
      }
    }
  `,
};
