import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { nvl, nvlNumber } from 'utils/Common.utils';
import LoadingBar from 'utils/LoadingBar';
import LocalStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
import SessionStorage from 'utils/SessionStorage.utils';
import clearAppData from 'utils/clearAppData';
import { USER_LEVELS } from 'utils/constants';

import IconKey from 'assets/img/key_icon.svg';
import { ReactComponent as ImageSvgLogoGoogle } from 'assets/img/svg/logo/logo-symbol-google-01.svg';

//component
import CustomAlert from 'components/_common/alerts/CustomAlert';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import SignCheckBoxBase from 'components/user/_components/common/forms/inputs/_SignCheckBoxBase';
import MainBox from 'components/user/_components/layouts/MainBox';
import Header from 'components/user/_components/layouts/_Header';
import AgreeAccountCreatedByThirdPerson from 'components/user/sign-in/_components/AgreeAccountCreatedByThirdPerson';
import InputEmail from './_components/inputs/InputEmail';
import InputPassword from './_components/inputs/InputPassword';
import EmailLoginModal from './_components/modals/EmailLoginModal';

import { clearReduxStateAcademyAdmin } from 'reducers/auth/academyadmin.action';
import { clearReduxStateSocialUser, updateReduxSocialConfirmCode } from 'reducers/auth/action';
import { updateReduxJoinCompanyLoginType, updateReduxJoinCompanyUid, updateReduxJoinCompanyUserLevel } from 'reducers/auth/joincompany.action';
import { clearReduxStateStudent } from 'reducers/auth/student.action';

/** "로그인" 페이지 */
export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const userInfo = request.tokenDecoder();

  /** 외부 도메인의 로그인 요청인가? 여부 */
  const isExternalSign = (location.state && location.state.isExternalSign && location.state.lastRoute && location.state.buttonType) ?? false;
  /** 로그인 성공한 상황인가? (다른 컴포넌트를 다녀 올 경우) */
  const isSuccessSignIn = (location.state && location.state.isSuccessSignIn) ?? false;
  /** 다른 컴포넌트에 다녀 올 사용자 입력 데이터 */
  const submitDataState = (location.state && location.state.submitData) ?? false;

  const query = new URLSearchParams(window.location.search);
  /** 아카데미, 튜터의 경우 이메일로 회원가입 링크를 보낸다. 이 때, 담긴 쿼리 */
  const confirmCode = query.get('confirm-code');

  const etcSession = LocalStorage.getItemJsonParse('etcSession');

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      userEmail: etcSession ? etcSession.saveId : '',
      userPassword: '',
      saveUserEmail: false,
    },
  });

  const [userPasswdFailCnt, setUserPasswdFailCnt] = useState(0);
  const [loadingBarActive, setLoadingBarActive] = useState(false);
  /** 학원이 대리 가입 시킨 학생 계정이 첫 로그인 하는 상황인가? */
  const [isAgreeAccountCreatedByThirdPerson, setIsAgreeAccountCreatedByThirdPerson] = useState(false);
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
  });
  const [showEmailLoginModal, setShowEmailLoginModal] = useState(false);
  const [submitData, setSubmitData] = useState(null);

  /** 커스텀 알럿 창 "닫기" 버튼 핸들러 */
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...prev, alertType: 'alert', visible: false, alertMessage: '' };
    });
  };

  /** 커스텀 알럿 창 "확인" 버튼 핸들러 */
  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      clearErrors();
    }
  };

  /** 로그인 하기 */
  const managerLogin = (data) => {
    const { userEmail: rawUserEmail, userPassword: rawUserPassword } = data;
    const userEmail = rawUserEmail.trim();
    const userPassword = rawUserPassword.trim();

    if (alertLayerPopup.visible) {
      setAlertLayerPopup((prev) => {
        return { ...prev, visible: false };
      });
      return;
    }

    let params = {
      userId: userEmail,
      userPasswd: userPassword,
      userPasswdFailCnt: userPasswdFailCnt,
      userAgent: window.navigator.userAgent,
    };

    setLoadingBarActive(true); // 로딩바 띄우기

    const successHandler = (response) => {
      setLoadingBarActive(false); // 로딩바 없애기

      if (response.code === 200) {
        // 로그인 성공
        // ok 코드 값
        setUserPasswdFailCnt(0);
        if (data.saveUserEmail) LocalStorage.setItem('etcSession', 'saveId', nvl(data.userEmail));
        console.log('로그인 성공', response.result);
        // 토근을 로컬에 저장
        LocalStorage.clearItem('userSession');
        LocalStorage.clearItem('sendParams');
        LocalStorage.clearItem('util');
        LocalStorage.setItem('userSession', 'accessToken', response.result.accessToken);
        LocalStorage.setItem('userSession', 'refreshToken', response.result.refreshToken);

        if (isExternalSign) {
          // 외부 도메인에서 온 로그인 요청 일 경우
          if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.PARENT) {
            // 학부모 계정 일 경우
            window.location.href = '/parent/dashboard';
          } else {
            // 학부모 계정 이외의 경우
            if (query.buttonType === 'signIn') {
              if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.STUDENT) window.location.href = '/student/dashboard';
              else if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.PARENT) window.location.href = '/parent/dashboard';
              else if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.TUBLET_TUTOR) window.location.href = '/tublet/tutor/dashboard';
              else window.location.href = '/academy/dashboard';
            } else {
              /** 원래 로그인 요청이 온 외부 도메인으로 다시 보낼 링크 */
              const responseLink = `${location.state.isExternalSign}/sign/signin/?lastRoute=${location.state.lastRoute}&buttonType=${location.state.buttonType}&accessToken=${response.result.accessToken}&refreshToken=${response.result.refreshToken}`;

              window.location.replace(responseLink);
            }
          }
        } else {
          // 내부 로그인 요청의 경우
          if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.STUDENT) window.location.href = '/student/dashboard';
          else if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.PARENT) window.location.href = '/parent/dashboard';
          else if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.TUBLET_TUTOR) window.location.href = '/tublet/tutor/dashboard';
          else window.location.href = '/academy/dashboard';
        }
      } else if (response.code === 401) {
        setLoadingBarActive(true); // 로딩바 띄우기
        // 비밀번호 5회 이상 틀림
        setUserPasswdFailCnt(0);
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'You have entered incorrect email address or password more than 5 times. Contact administrator for help. ',
          };
        });
      } else if (response.code === 403) {
        // 학원이 대리 가입 시켜 준 학생이 첫 로그인 시도 했을 때 약관 동의 화면 띄우기
        setLoadingBarActive(false); // 로딩바 없애기
        setIsAgreeAccountCreatedByThirdPerson(true);
      } else if (response.code === 404) {
        setLoadingBarActive(false); // 로딩바 없애기
        // 아이디, 비밀번호 정보 없을때
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'You have entered incorrect information or the information does not exist.',
          };
        });
        setUserPasswdFailCnt(userPasswdFailCnt + 1);
      } else if (response.code === 406) {
        // 관리자 첫 로그인을 학원 코드 없이 했을때
        setShowEmailLoginModal(true);
        setLoadingBarActive(false); // 로딩바 없애기
      } else if (response.code === 409) {
        // 신규 회원인경우 code 입력 단계로 이동
        dispatch(updateReduxJoinCompanyLoginType('EMAIL'));
        dispatch(updateReduxJoinCompanyUid(response.result.userSeq));
        dispatch(updateReduxJoinCompanyUserLevel(response.result.userLevel));
        console.log('신규 회원인경우 code 입력 단계로 이동');
        console.log(response);
        setLoadingBarActive(false); // 로딩바 없애기
        navigate('/signup/social/tutor/first-time-signin');
        return;
      } else {
        setLoadingBarActive(false); // 로딩바 없애기
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'You have entered incorrect information or the information does not exist.',
          };
        });
        setUserPasswdFailCnt(userPasswdFailCnt + 1);
      }
    };

    // request.post('/api/member/login', params, successHandler);
    params.isNew = true;
    const errorHandler = (result) => {
      const message = result.response?.data?.message[0];
      setLoadingBarActive(false); // 로딩바 없애기
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: message,
        };
      });
    };
    request.post(request.apiUris.auth.signinEmail, params, successHandler, errorHandler);
  };

  /** 학원 코드 연결 API 요청 */
  const getAuthenCode = (data) => {
    if (alertLayerPopup.visible) {
      setAlertLayerPopup((prev) => {
        return { ...prev, visible: false };
      });
      return;
    }

    const successHandler = (response) => {
      setLoadingBarActive(false); // 로딩바 없애기

      if (response.code === 200) {
        // ok 코드 값
        let checkCode = response.result.checkCode;

        if (checkCode) managerLogin(data);
        else
          setAlertLayerPopup((prev) => {
            return {
              ...prev,
              visible: true,
              alertMessage: 'The academy code is wrong!',
            };
          });
      }
    };

    setLoadingBarActive(true); // 로딩바 띄우기

    request.get(`/api/common/academy/uniquecode/check?uniqueCode=${confirmCode}&userEmail=${data.userEmail}`, null, successHandler);
  };

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    setSubmitData(data);
    if (nvl(confirmCode) === '') managerLogin(data);
    else getAuthenCode(data);
  };

  /** 소셜 로그인 버튼 핸들러 : 구글 */
  const handleSnsLoginGoogle = () => {
    console.log('구글 로그인');
    // Google's OAuth 2.0 endpoint for requesting an access token
    var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    var form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {
      client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid email profile',
      include_granted_scopes: 'true',
      state: 'pass-through value',
    };
    // console.log('params', params);

    // Add form parameters as hidden input values.
    for (var p in params) {
      var input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  };
  /** 소셜 로그인 버튼 핸들러 : 페이스북 */
  const handleSnsLoginFacebook = () => {
    console.log('페이스북 로그인');
  };
  /** 소셜 로그인 버튼 핸들러 : 애플 */
  const handleSnsLoginApple = () => {
    console.log('애플 로그인');
  };

  useEffect(() => {
    clearAppData(dispatch, userInfo?.userId);
    /** 로그인 페이지 들어오면 user social redux를 초기화 해준다. */
    dispatch(clearReduxStateSocialUser());
    dispatch(clearReduxStateAcademyAdmin());
    dispatch(clearReduxStateStudent());
    // 컨펌 코드가 있을 경우, 로그인 페이지로 들어오면 컨펌 코드를 리덕스에 저장한다.
    if (confirmCode) dispatch(updateReduxSocialConfirmCode(confirmCode));
    SessionStorage.clearItem('windowManager');

    document.body.classList.add('gate_member'); //현재 페이지에서만 addClass

    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInfo != null && nvlNumber(userInfo.userSeq) > 0) {
      if (userInfo.userLevel === USER_LEVELS.STUDENT) {
        window.location.href = '/student/dashboard';
      } else if (userInfo.userLevel === USER_LEVELS.PARENT) {
        window.location.href = '/parent/dashboard';
      } else {
        window.location.href = '/academy/dashboard';
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (isSuccessSignIn && submitDataState) {
      // 학원에서 등록한 학생이 첫 로그인 했을 시, 약관 동의 화면을 다녀 온 뒤 로그인 함수 다시 실행
      managerLogin(submitDataState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSignIn, submitDataState]);

  return (
    <>
      <S.SignInForm>
        <MainBox className='main-box'>
          <div style={{ display: isAgreeAccountCreatedByThirdPerson ? 'none' : 'block' }}>
            {/*헤더*/}
            <Header>
              <p className='txt'>The door to educational aspiration</p>
            </Header>
            {/*폼*/}
            <form onSubmit={handleSubmit(onValid)}>
              <h1 className='title'>Sign In</h1>
              <div className='input-wrap'>
                {/*이메일 입력*/}
                <InputEmail
                  register={register('userEmail', {
                    required: 'Please enter Email',
                  })}
                  setValue={setValue}
                />
                {errors.userEmail ? <div className='error_message'>{errors.userEmail.message}</div> : null}

                {/*비밀번호 입력*/}
                <InputPassword
                  register={register('userPassword', {
                    required: 'Please enter password',
                  })}
                  setValue={setValue}
                />
                {errors.userPassword && <div className='error_message'>{errors.userPassword.message}</div>}

                <div className='button-wrap'>
                  {/*기억하기 체크박스*/}
                  <SignCheckBoxBase checkBoxId={'saveUserEmail'} register={register('saveUserEmail')}>
                    Remember my account
                  </SignCheckBoxBase>

                  {/*비밀번호/이메일 찾기*/}
                  <section className='forgot-button-area'>
                    <Link to={'find/passwd'} title='페이지 이동'>
                      Find password
                    </Link>
                    <Link to={'find/email'} title='페이지 이동'>
                      Find email
                    </Link>
                  </section>
                </div>
              </div>
              {/*자동으로 코드 값을 받는 경우(Academy Tutor, Tutor)*/}
              {nvl(confirmCode) !== '' && (
                <article className='input_wrap'>
                  <div className='box input_code'>
                    <img src={IconKey} lt='password' className='key_icon' alt='icon' />
                    <input type='text' id='code' name='code' className='icon input' value={confirmCode} readOnly />
                  </div>
                </article>
              )}
              {/*로그인 버튼*/}
              <PrimaryButtonArea>
                <PrimaryButton type='submit'>Sign In</PrimaryButton>
              </PrimaryButtonArea>
            </form>

            <section className='sns-button-area'>
              <button title='Google Sign in' onClick={handleSnsLoginGoogle}>
                <ImageSvgLogoGoogle />
              </button>
              {/* <button title='Facebook Sign in' onClick={handleSnsLoginFacebook}>
                <ImageSvgLogoFacebook />
              </button> */}
              {/* <button title='Apple Sign in' onClick={handleSnsLoginApple}>
                <ImageSvgLogoApple />
              </button> */}
            </section>

            {/*회원가입 버튼*/}
            <p className='sign-up-btn-area'>
              Don’t have an account?{' '}
              <Link to={'/signup'} title='페이지 이동'>
                Sign Up
              </Link>
            </p>
          </div>
          {isAgreeAccountCreatedByThirdPerson && submitData ? <AgreeAccountCreatedByThirdPerson submitData={submitData} /> : null}
        </MainBox>
      </S.SignInForm>
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
      {loadingBarActive && <LoadingBar type={'spin'} color={'#000000'} />}
      {showEmailLoginModal && <EmailLoginModal setShowEmailLoginModal={setShowEmailLoginModal} />}
    </>
  );
}

const S = {};

S.SignInForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .main-box {
    border-radius: 0.25rem;
    box-shadow: none;
  }
  .title {
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    color: #a5aab1;
    margin-bottom: 0.5rem;
  }
  .input-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }
  .button-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 640px) {
      flex-direction: column;
      align-items: start;
      gap: 1rem;
    }
  }
  .forgot-button-area {
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    a {
      display: inline-block;
      color: #505050;
      & + a::before {
        content: '';
        display: inline-block;
        height: 0.625rem;
        width: 1px;
        background-color: #a6b3be;
        margin: 0 0.625rem;
      }
      &:focus {
        outline: none;
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 640px) {
      width: 100%;
      justify-content: end;
    }
  }
  .sns-button-area {
    margin: 2rem 0 1rem;
    display: flex;
    justify-content: center;
    gap: 3rem;
    button {
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      &:focus {
        outline: 1px solid var(--point-color);
      }
    }
  }
  .sign-up-btn-area {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
  }

  .error_message {
    text-align: left;
    margin-bottom: 1rem;
    color: var(--sub-red);
    font-family: Roboto;
    font-size: 0.725rem;
  }
`;
