import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import request from 'utils/Request.utils.js';
import { nvl } from 'utils/Common.utils.js';

//components
import CustomAlert from 'components/_common/alerts/CustomAlert';
import Paging from 'layout/Paging'; //페이징

import DeleteModal from 'components/_common/modals/DeleteModal';
import MainLink from 'components/_common/button/_MainLinks.jsx';

//img
import IconOk from 'assets/img/icon_ok.svg';
import StudentsMainTable from './_components/tables/studentsMainTableArea/StudentsMainTable.jsx';
import TopBtnArea from './_components/tables/studentsMainTableArea/TopBtnArea.jsx';
import SearchArea from './_components/tables/studentsMainTableArea/SearchArea.jsx';

/** 학생 메인 페이지 */
export default function StudentMainPage() {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const navigate = useNavigate();

  const [studentInfo, setStudentInfo] = useState({
    studentList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });

  const [classList, setClassList] = useState();

  const [searchInfo, setSearchInfo] = useState({
    searchFieldActive: false,
    searchStartDate: '',
    searchEndDate: '',
    setSearchField: 'Name',
    searchKeyword: '',
    orderBy: 'desc',
  });

  let alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };

  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  useEffect(() => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        returnValue: (value) => {
          if (nvl(value) === 'OK') {
            // closeCustomAlert();
          }
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertLayerPopup?.id]);

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  const getStudentList = () => {
    const param = {
      userSeq: userInfo?.userSeq,
      managerPosition: userInfo?.managerPosition,
      setSearchField: searchInfo.setSearchField === 'Name' ? 'user_name' : 'class_name',
      searchKeyword: String(searchInfo.searchKeyword).trim(),
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      limit: studentInfo.numPerPage,
      currenPage: studentInfo.currentPage,
      offset: (studentInfo.currentPage - 1) * studentInfo.numPerPage,
      orderBy: searchInfo.orderBy,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        const studentList = response.result.studentList;
        const classList = response.result.classList;
        const totalCount = response.result.totalCount;

        setStudentInfo((prev) => {
          return { ...prev, studentList, totalCount };
        });

        setClassList(
          classList != null &&
            classList?.map((item) => ({
              value: item.classSeq,
              name: item.className,
            }))
        );

        setSelectCheckBox((prev) => {
          return { ...prev, selectedKeys: [], seqStr: '' };
        });
      }
    };

    request
      .get(
        `/api/v2/academy/students?currentPage=${param.currenPage}&limit=${param.limit}&orderDirection=${param.orderBy}&searchKeyword=${param.searchKeyword}&searchField=${param.setSearchField}`,
        null,
        successHandler
      )
      .catch((error) => {
        console.error(error);
      });
  };

  const studentListReload = () => {
    setStudentInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getStudentList();
  };

  const [selectCheckBox, setSelectCheckBox] = useState({
    seqStr: '',
    selectedKeys: [],
  });

  const deleteStudentCompanyHub = () => {
    const { seqStr, selectedKeys } = selectCheckBox;

    if (selectedKeys.length > 0) {
      let pms = {
        seqStr: seqStr,
        ecSeq: studentInfo.studentList[0].ecSeq,
      };

      const successHandler = (response) => {
        if (response.code === 200) {
          deleteStudentAlertOpen();
          studentListReload();
        }
      };

      request.del(`/api/member/hub/${pms.seqStr}/${pms.ecSeq}`, null, successHandler);
    } else {
      setDeleteStudent(false);
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the student you want to delete.',
        };
      });
    }
  };

  useEffect(() => {
    getStudentList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo.currentPage]);

  useEffect(() => {
    studentListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo.numPerPage, searchInfo.searchKeyword, searchInfo.orderBy, searchInfo.setSearchField]);

  const nextPageMove = (value) => {
    setStudentInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const [deleteStudent, setDeleteStudent] = useState(false); //학생 삭제 팝업
  const [deleteStudentAlert, setDeleteStudentAlert] = useState(false); //학생 삭제 confirm 팝업
  const deleteStudentAlertOpen = () => {
    setDeleteStudent((prevState) => !prevState);
    setDeleteStudentAlert((prevState) => !prevState);
  };

  return (
    <>
      <div className='p_student_list'>
        <MainLink type='students' />
        <div className='com_center_wrap'>
          <div className='com_box'>
            {/* 📏 student main - Table 📏 */}
            <div className='com_list'>
              {/* 📏 student main - Table_Header 📏 */}
              <section className='top'>
                {/* 🧩 student main - Table_Header_버튼영역 🧩 */}
                <TopBtnArea
                  selectCheckBox={selectCheckBox}
                  setDeleteStudent={setDeleteStudent}
                  setAlertLayerPopup={setAlertLayerPopup}
                  searchInfo={searchInfo}
                  studentInfo={studentInfo}
                  studentListReload={studentListReload}
                />

                {/* 🧩 student main - Table_Header_검색영역 🧩 */}
                <SearchArea setSearchInfo={setSearchInfo} setStudentInfo={setStudentInfo} searchInfo={searchInfo} />
              </section>

              {/* 📏 student main - Table_MainContent 📏 */}
              <StudentsMainTable
                studentInfo={studentInfo}
                setSelectCheckBox={setSelectCheckBox}
                selectCheckBox={selectCheckBox}
                searchInfo={searchInfo}
                setSearchInfo={setSearchInfo}
                classList={classList}
                getStudentList={getStudentList}
              />
            </div>

            {/* 📏 student main - Pagination 📏 */}
            <Paging
              totalCount={studentInfo.totalCount}
              currentPage={studentInfo.currentPage}
              numPerPage={studentInfo.numPerPage}
              pagePerBlock={studentInfo.pagePerBlock}
              nextPageMove={nextPageMove}
            />
            {alertLayerPopup.visible && (
              <CustomAlert
                onClose={closeCustomAlert}
                alertType={alertLayerPopup.alertType}
                alertMessage={alertLayerPopup.alertMessage}
                returnValue={alertLayerPopup.returnValue}
              />
            )}
          </div>
        </div>
      </div>

      {/* 학생 삭제 팝업 */}
      {deleteStudent && (
        <DeleteModal
          hideModal={() => setDeleteStudent(false)}
          callback={deleteStudentCompanyHub}
          text={'Once you delete a student from the list, you cannot add the \n student back until the student links back to you.'}
        />
      )}
      {/* 학생 삭제 완료 팝업 */}
      <div className={`com_popup type_alert ${deleteStudentAlert ? 'active' : ''}`}>
        <div className='pop_container'>
          <div className='pop_body ok_contents'>
            <p className='alert_title'>Successfully deleted</p>
            <div className='icon'>
              <img src={IconOk} alt='icon' />
            </div>
          </div>
          <div className='pop_footer com_btn_wrap'>
            <button className='com_btn point xl full' onClick={() => setDeleteStudentAlert(false)}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const S = {
  Wrap: styled.div``,
};
