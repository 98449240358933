import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';

function Timer({ variant, setStateExamInfo }) {
  const navigate = useNavigate();
  const [examInfo, setExamInfo] = useState(null);
  const [remainTime, setRemainTime] = useState(null);

  useEffect(() => {
    const testKey = `apTest_${localStorage.getItemJsonParse('currentTest')?.uthSeq}`;
    const info = localStorage.getItemJsonParse(testKey);

    if (!info?.startTime || !info?.totalTime || !info?.testInfo || !info?.partSeq) {
      alert('The exam information is incorrect.');
      return;
    }

    setExamInfo(info);
  }, []);

  const initialTime = useMemo(() => {
    const currentIndex = examInfo?.testInfo.partInfo.findIndex((item) => item.partSeq === examInfo.partSeq);

    return variant === 'breakTime' ? examInfo?.testInfo.partInfo[currentIndex - 1].breakTime : examInfo?.totalTime;
  }, [variant, examInfo]);

  useEffect(() => {
    if (!examInfo) return;

    const intervalId = setInterval(() => {
      setRemainTime((prevRemainTime) => {
        const currentTime = Date.now();
        const elapsedTime = Math.floor((currentTime - examInfo.startTime) / 1000);
        const updatedRemainTime = Math.max(initialTime - elapsedTime, 0);
        return updatedRemainTime;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [initialTime, examInfo]);

  useEffect(() => {
    if (!examInfo) return; // examInfo가 초기화되지 않았으면 실행하지 않음

    if (variant === 'exam' && remainTime <= 300 && remainTime > 0) {
      setStateExamInfo((prev) => ({ ...prev, isUnderFiveMinutes: true }));
    }

    if (remainTime === 0) {
      handleTimeOver();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainTime]);

  const handleTimeOver = () => {
    if (!examInfo || !examInfo.testInfo) {
      console.warn('Exam information is missing. Cannot proceed.');
      return;
    }

    const currentIndex = examInfo.testInfo.partInfo.findIndex((item) => item.partSeq === examInfo.partSeq);
    const lastPartIndex = examInfo.testInfo.partInfo.length - 1;
    const nextPartIndex = currentIndex + 1;

    const currentPartInfo = examInfo.testInfo.partInfo[currentIndex];
    const nextPartInfo = examInfo.testInfo.partInfo[nextPartIndex];

    const uthSeq = localStorage.getItemJsonParse('currentTest')?.uthSeq;
    const testKey = `apTest_${uthSeq}`;

    if (variant === 'exam') {
      if (!(lastPartIndex === currentIndex) && currentPartInfo.sectionNo < nextPartInfo.sectionNo) {
        localStorage.setItem(testKey, 'startTime', Date.now());
        navigate('/ap/exam/break', { replace: true });
      } else if (lastPartIndex === currentIndex) {
        navigate('/ap/exam/finish', { replace: true, state: { giveUp: false } });
      } else {
        setStateExamInfo((prev) => ({ ...prev, showModuleOver: true }));
      }
    } else if (variant === 'breakTime') {
      localStorage.setItem(testKey, 'startTime', Date.now());
      updateTestStatus(uthSeq);
      navigate('/ap/exam/test', { replace: true });
    }
  };

  const updateTestStatus = (uthSeq) => {
    const params = {
      testStatus: 'I',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        localStorage.setItem(`apTest_${uthSeq}`, 'isBreakTime', false);
        // 부모 창의 함수 호출
        if (window.opener && typeof window.opener.getUpcomingTestList === 'function') {
          window.opener.getUpcomingTestList();
        } else {
          console.error('부모창의 함수를 찾을 수 없습니다.');
        }
      }
    };

    request.apPut(`/api/v1/student/test/${uthSeq}/status`, params, successHandler);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return <>{remainTime === null ? '-- : --' : formatTime(remainTime)}</>;
}

export default Timer;
