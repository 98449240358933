import React, { useEffect } from 'react';

//component
import UserType from 'components/user/_components/pages/UserType';

/** 회원가입 - 이메일 가입 - 사용자 유형 선택 데이터 배열 */
const signUpUserTypeDataArray = [
  {
    id: 'academyAdmin',
    link: '/signup/email/academy-admin',
    titleText: 'Academy Admin',
    subText: 'I am an academy manager',
  },
  {
    id: 'academyTeacher',
    link: '/signup/email/academy-teacher',
    titleText: 'Academy Teacher',
    subText: 'I am an academy teacher, associated with an academy',
  },
  {
    id: 'tutor',
    link: '/signup/email/tutor',
    titleText: 'Tutor',
    subText: `I am a private tutor, not affiliated with an academy`,
  },
  {
    id: 'student',
    link: '/signup/email/student',
    titleText: 'Student',
    subText: 'I am currently studying at a school',
  },
  {
    id: 'parent',
    link: '/signup/email/parent',
    titleText: 'Parents',
    subText: 'I am a mother OR a father of a student',
  },
];

/** "회원가입 - 이메일 가입 - 사용자 유형 선택" 페이지 */
export default function SignUpEmailUserTypePage() {
  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);
  return <UserType signUpUserTypeDataArray={signUpUserTypeDataArray} />;
}
