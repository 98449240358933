import { Navigate, Route, Routes } from 'react-router-dom';

// 각종 auth check
import AuthCheck from 'layout/AuthCheck'; //Gnb

import request from 'utils/Request.utils';

import ExamApPage from 'components/ap/exam/ap/apExam/Page';
import ExamBreakTimePage from 'components/ap/exam/ap/BreakTime/Page';
import ExamCompletionPage from 'components/ap/exam/ap/Completion/Page';
import InProgress from 'components/ap/exam/ap/Inprogress/Page';
import ExamTutorialPage from 'components/ap/exam/ap/Tutorial/Page';

// const ExamIntro = lazy(() => import('components/exam/Intro'))

const ApTestRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  if (pathname.indexOf('/exam/tms') >= 0)
    authBool = true; // 무조건 통과
  else if (pathname.indexOf('/signin') < 0) authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    // 로그인 한 사람만 사용
    if (authBool) {
      const authRoutes = [
        // { path: "/intro", element: <ExamIntro /> },
        // { path: "/moduleOver", element: <ModuleOver /> },
        // { path: "/progress", element: <ExamProgress /> },
        // { path: "/main", element: <ExamMain /> },

        { path: '/tutorial', element: <ExamTutorialPage /> },
        { path: '/test', element: <ExamApPage /> },
        { path: '/break', element: <ExamBreakTimePage /> },
        { path: '/finish', element: <ExamCompletionPage /> },
        { path: 'inprogress', element: <InProgress /> },

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };

  return (
    <>
      {/* {
        pathname.toLowerCase().indexOf('/intro') < 0 && pathname.toLowerCase().indexOf('/moduleover') < 0 && pathname.toLowerCase().indexOf('/progress') < 0 ? (
          <>
            <Lnb/>
            <Gnb />
          </>
        )
        :  
          null
      } */}
      <AuthCheck />
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      {/* { pathname.toLowerCase().indexOf('/intro') < 0 && pathname.toLowerCase().indexOf('/moduleover') < 0 && pathname.toLowerCase().indexOf('/progress') < 0 ? <Footer/> : null } */}
    </>
  );
};

export default ApTestRoute;
