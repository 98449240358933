//#region    ////////// packages
import styled from 'styled-components';
import PeriodicElement from './PeriodicElement';
//#endregion ////////// packages

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const periodicTableData = [
  [
    { empty: true, span: 2, text: '* Lanthanoids' },
    { symbol: 'La', number: '57', mass: '138.91' },
    { symbol: 'Ce', number: '58', mass: '140.12' },
    { symbol: 'Pr', number: '59', mass: '140.91' },
    { symbol: 'Nd', number: '60', mass: '144.24' },
    { symbol: 'Pm', number: '61', mass: '' },
    { symbol: 'Sm', number: '62', mass: '150.36' },
    { symbol: 'Eu', number: '63', mass: '151.97' },
    { symbol: 'Gd', number: '64', mass: '157.25' },
    { symbol: 'Tb', number: '65', mass: '158.93' },
    { symbol: 'Dy', number: '66', mass: '162.50' },
    { symbol: 'Ho', number: '67', mass: '164.93' },
    { symbol: 'Er', number: '68', mass: '167.26' },
    { symbol: 'Tm', number: '69', mass: '168.93' },
    { symbol: 'Yb', number: '70', mass: '173.05' },
    { symbol: 'Lu', number: '71', mass: '174.97' },
    { empty: true, span: 1 },
  ],
  [
    { empty: true, span: 2, text: '** Actinoids' },
    { symbol: 'Ac', number: '89', mass: '' },
    { symbol: 'Th', number: '90', mass: '232.04' },
    { symbol: 'Pa', number: '91', mass: '231.04' },
    { symbol: 'U', number: '92', mass: '238.03' },
    { symbol: 'Np', number: '93', mass: '' },
    { symbol: 'Pu', number: '94', mass: '' },
    { symbol: 'Am', number: '95', mass: '' },
    { symbol: 'Cm', number: '96', mass: '' },
    { symbol: 'Bk', number: '97', mass: '' },
    { symbol: 'Cf', number: '98', mass: '' },
    { symbol: 'Es', number: '99', mass: '' },
    { symbol: 'Fm', number: '100', mass: '' },
    { symbol: 'Md', number: '101', mass: '' },
    { symbol: 'No', number: '102', mass: '' },
    { symbol: 'Lr', number: '103', mass: '' },
    { empty: true, span: 1 },
  ],
];
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** Main 주기율표 하단에 노출되는 컴포넌트 */
export default function AdditionalPeriodicTable() {
  return (
    <div>
      {periodicTableData.map((row, rowIndex) => (
        <S.Row key={rowIndex}>
          {row.map((element, elementIndex) =>
            // 빈 공간일 경우
            element.empty ? (
              <S.EmptySpace key={`empty-${elementIndex}`} $span={element.span}>
                {element.text && <span>{element.text}</span>}
              </S.EmptySpace>
            ) : (
              // 원소 표시 영역일 경우
              <PeriodicElement key={elementIndex} element={element} />
            )
          )}
        </S.Row>
      ))}
    </div>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  Row: styled.div`
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-gap: 1px;
  `,

  EmptySpace: styled.div`
    grid-column: span ${(props) => props.$span};
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-left: 0.25rem;
    padding-bottom: 0.75rem;
    font-size: 1rem;
  `,
};
