import React, { useState } from 'react';
import styled from 'styled-components';

/** 레퍼런스 시트 list 컴포넌트 */
export default function EquationsList({ children }) {
  const [isExpandedAll, setIsExpandedAll] = useState(false);

  return (
    <S.Equations>
      <S.EquationsHeader>
        <div className='button_group'>
          <button type='button' onClick={() => setIsExpandedAll(true)}>
            Expand All
          </button>
          <span className='divider' aria-hidden='true'></span>
          <button type='button' onClick={() => setIsExpandedAll(false)}>
            Collapse All
          </button>
        </div>
      </S.EquationsHeader>

      <S.EquationsContent>{React.Children.map(children, (child) => React.cloneElement(child, { isExpanded: isExpandedAll }))}</S.EquationsContent>
    </S.Equations>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  Equations: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,

  EquationsHeader: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #ddd;

    .button_group {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }

    button {
      color: #0047de;
      font-weight: 500;
      font-size: 1rem;
    }

    .divider {
      width: 1px;
      height: 1rem;
      background-color: #ddd;
    }
  `,

  EquationsContent: styled.div`
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    > *:not(:last-child) {
      border-bottom: 2px solid #f0f0f0;
    }
  `,
};
