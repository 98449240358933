import styled from 'styled-components';

/**
 * 시험 생성 페이지에서 컨텐츠를 담는 ui 컴포넌트
 * 내부에서 사용 가능한 className
 * - source_btn_container
 *   - 소스 버튼 컨테이너
 */
export default function UI_EditorContainer({ className = '', children }) {
  return <S.EditorContainer className={className}>{children}</S.EditorContainer>;
}

// --- --- --- Styled Components --- --- ---
const S = {
  EditorContainer: styled.div`
    padding: 0 4.375rem;
    max-width: 61.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    .source_btn_container {
      display: flex;
      gap: 0.75rem;
      flex-direction: column;
    }
  `,
};
