import React, { useState, useEffect, useRef } from 'react';

import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';

//components
import MiniAlert from 'components/_common/alerts/MiniAlert';
import DeviationsFromAverage from './sections/_DeviationsFromAverage';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { getValidParam, useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS } from 'utils/constants';
import GradeChangeFromPastSection from './sections/GradeChangeFromPastSection';

/** "/com/score" 페이지의 "ScoreStats" 영역 (tap 에 따라서 나타나는 영역) 컴포넌트 */
export default function ScoreStatsSection() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  /** 현재 classSeq - Route Parameter */
  const { classSeq: encClassSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [miniAlert, alertSet] = useState(false);
  /** 중복호출 방지용 Ref값 초기화 여부 */
  const [isInitialized, setIsInitialized] = useState(false);

  /** 현재 학원의 ClassList */
  const [academyClassList, setAcademyClassList] = useState({
    latestClassSeq: 0, // number       => 가장 최근에 시험을 본 class의 시퀀스
    classList: [
      {
        classSeq: 0, // number         => 해당 반의 시퀀스
        className: '', // string       => 해당 반의 이름
        classStartDate: '', // string  => 해당 반의 시작일
      },
    ],
  });
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  /** 유저의 academy 시퀀스에 해당하는 Class 목록 불러오기 API 요청 */
  const getClassList = (isCurrentRequest) => {
    // 중복 방지 Ref가 초기화가 되지 않았거나, API 파라미터가 유효하지 않을 경우 early return
    if (!(isInitialized && userInfo?.ecSeq)) return;
    // 이전 호출한 API의 파라미터와 현재 파라미터가 같다면 함수 종료 ( 중복 호출 방지 )
    if (prevScoreClassRef.current?.ecSeq === userInfo?.ecSeq) return;
    prevScoreClassRef.current = { ecSeq: userInfo?.ecSeq }; // 현재 파라미터 값 업데이트

    const successHandler = (response) => {
      // 언마운트시 진행중인 API 호출을 취소
      if (!isCurrentRequest) return;

      if (response.code === 200) {
        const fetchedClassList = response.result.classList;
        const fetchedLatestClassSeq = response.result.maxClass;
        /** 마지막에 시험을 본 class */
        const latestClass = fetchedClassList?.find((item) => item.classSeq === fetchedLatestClassSeq);

        setAcademyClassList({
          latestClassSeq: fetchedLatestClassSeq,
          classList: fetchedClassList,
        });
      }
    };

    request.get(`/api/dsat/score/class?ecSeq=${userInfo?.ecSeq}`, null, successHandler).catch((error) => console.error('', error));
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  //////////////////// START --- API 중복 호출 방지용 Ref --- START ////////////////////
  const prevScoreClassRef = useRef(null); // score/class API 중복 호출 방지용 Ref - userInfo.ecSeq
  ////////////////////  END  --- API 중복 호출 방지용 Ref ---  END  ////////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    // queryCurScoreTestSeq혹은 classSeq가 유효하지 않을 경우 early return
    if (!getValidParam(queryCurScoreTestSeq) || !getValidParam(classSeq)) return;

    let isCurrentRequest = true; // 컴포넌트 언마운트시 API 호출 방지용 변수

    getClassList(isCurrentRequest);
    // getClassTakenTest();

    // 언마운트시 진행중인 API 호출을 취소
    return () => (isCurrentRequest = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classSeq, queryCurScoreTestSeq, isInitialized]);

  // 컴포넌트 마운트시 Ref값 초기화 ( 새로고침시 이전 Ref 값으로 중복호출방지가 실행되는 것을 막음 )
  useEffect(() => {
    prevScoreClassRef.current = null;
    setIsInitialized(true);
  }, []);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <S.Wrap>
      <GradeChangeFromPastSection />
      <div className='com_container'>
        {/* Deviations from Average */}
        <DeviationsFromAverage />
      </div>
      {miniAlert && <MiniAlert text='Student Changed' type='onlyText' active={alert} inactive={() => alertSet(false)} timeOut={1500} />}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    .com_container {
      justify-content: space-between;
    }
    .element {
      height: 30rem;
    }
  `,
};
