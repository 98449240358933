import React from 'react';
import styled from 'styled-components';

import MainBox from 'components/user/_components/layouts/MainBox';
import Logo from 'components/user/_components/layouts/Logo';
import Header from 'components/user/sign-up/_components/layouts/Header';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import Label from 'components/user/_components/common/forms/Label';
import Input from 'components/user/_components/common/forms/Input';
import ErrorText from 'components/user/_components/styles/ErrorText';

import ImgIconKey from 'assets/gate_member/image/key_icon.svg';

/** "회원가입" - "첫 번째 로그인" 페이지 스타일 용 컴포넌트 */
export default function SignUpFirstTimeSignIn({ headerTitle, handleSubmit, onValid, register, errors, children }) {
  return (
    <S.Wrap>
      <MainBox>
        <Logo />
        <Header title={headerTitle} />
        <p className='guide-text'>{children}</p>
        <form onSubmit={handleSubmit(onValid)}>
          <Label htmlFor='confireCode'>Company Name</Label>
          <Input
            id='confireCode'
            register={register('confireCode', {
              required: 'Please enter Confirm Code',
            })}
            type='text'
            autoFocus
            placeholder='Enter the code'
            isError={''}
          />
          {errors.confireCode && <ErrorText>{errors.confireCode.message}</ErrorText>}
          <PrimaryButtonArea>
            <PrimaryButton type='submit' title='다음 페이지'>
              Next
            </PrimaryButton>
          </PrimaryButtonArea>
        </form>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .guide-text {
    text-align: left;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #7f8998;
  }
  label {
    display: none;
  }
  input {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    background-image: url(${ImgIconKey});
    background-repeat: no-repeat;
    background-position: 1rem center;
    padding-left: 3rem;
  }
`;
