import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { hideMiniAlert } from 'reducers/miniAlert/miniAlertSlice';
import { ReactComponent as ImgOk } from 'assets/img/icon_ok.svg';

/**
 * 전역에서 사용되는 mini alert 컴포넌트
 * useMiniAlert 훅을 사용하여 랜더링
 */
export function GlobalMiniAlert() {
  const dispatch = useDispatch();
  const { message, isVisible, duration } = useSelector((state) => state.miniAlert);

  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        dispatch(hideMiniAlert());
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [isVisible, dispatch, duration]);

  // isVisible이 false일 경우 렌더링 하지 않음
  if (!isVisible) return null;

  return (
    <S.MiniAlertContainer>
      <S.IconWrapper>
        <ImgOk />
      </S.IconWrapper>
      <S.Message>{message}</S.Message>
    </S.MiniAlertContainer>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  MiniAlertContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;

    position: fixed;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 40px;
    border-radius: 0.625rem;
    background-color: white;
    color: white;
    z-index: 9999;

    animation: slideDown 0.3s ease-out;
    box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.1490196078);

    @keyframes slideDown {
      from {
        transform: translate(-50%, -100%);
        opacity: 0;
      }
      to {
        transform: translate(-50%, 0);
        opacity: 1;
      }
    }
  `,

  IconWrapper: styled.div`
    width: 3.1875rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,

  Message: styled.p`
    background: linear-gradient(90deg, #4758ff 5.05%, #008cff 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 1.5rem;
    font-weight: 400;
  `,
};
