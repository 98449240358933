import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

import { ReactComponent as ImgSvgIconSearch } from 'assets/img/svg/icon_search.svg';
import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';

/** 학생 목록 선택 모달 */
export default function StudentListModal({ setShowModal }) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  const [studentList, setStudentList] = useState([]);
  const [searchInfo, setSearchInfo] = useState({
    setSearchField: 'user_name',
    searchKeyword: '',
  });

  const getStudentList = () => {
    let pms = {
      userSeq: userInfo?.userSeq,
      managerPosition: userInfo?.managerPosition,
      orderBy: searchInfo.orderBy,
      searchKeyword: searchInfo.searchKeyword,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let studentList = response.result.studentInfo;
        let totalCount = response.result.totalCount;

        setStudentList((prev) => {
          return { ...prev, list: studentList, totalCount: totalCount };
        });
      }
    };

    request.get(``, null, successHandler);
  };

  return (
    <SimpleModalBase onEscape={() => setShowModal(false)} w='40.125rem' h='29.25rem'>
      <div className='modal_title_container'>
        <h2>Student List</h2>
        <button onClick={() => setShowModal(false)}>
          <i className='svg_icon btn_pop_close black'>&nbsp;</i>
        </button>
      </div>
      <div className='modal_text_container'>
        <S.Wrap>
          <form action='' className='search_area'>
            <select name='' id=''>
              <option value=''>All</option>
              <option value=''>Name</option>
              <option value=''>Class</option>
              <option value=''>Email</option>
            </select>
            <div className='search_bar'>
              <input type='text' placeholder='Enter Keywords' />
              <button type='submit' title='검색'>
                <ImgSvgIconSearch />
              </button>
            </div>
          </form>
          <div className='scroll-wrap'>
            <table className='list'>
              <caption></caption>
              <colgroup>
                <col width={'10rem'} />
                <col width={'10rem'} />
                <col width={'14.125rem'} />
              </colgroup>
              <thead>
                <tr className=''>
                  <th>Name</th>
                  <th>Class</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>쿠비적사고</td>
                  <td>쿠비적쿠비적</td>
                  <td>coovi5882@coogle.com</td>
                </tr>
                <tr>
                  <td>쿠비적사고</td>
                  <td>쿠비적쿠비적</td>
                  <td>coovi5882@coogle.com</td>
                </tr>
                {studentList &&
                  studentList.length > 0 &&
                  studentList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>쿠비적사고</td>
                        <td>쿠비적쿠비적</td>
                        <td>coovi5882@coogle.com</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </S.Wrap>
      </div>
    </SimpleModalBase>
  );
}

const S = {
  Wrap: styled.div`
    .search_area {
      display: flex;
      justify-content: end;
      gap: 0.75rem;
      padding-bottom: 0.5rem;
      select {
        border: 1px solid #f1f1f5;
        padding: 0.625rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 500;
        height: 1.25rem;
        box-sizing: content-box;
        border-radius: 0.5rem;
      }
      .search_bar {
        width: 14.125rem;
        border: 1px solid #f1f1f5;
        padding: 0 0.75rem;
        display: flex;
        border-radius: 0.5rem;
        input {
          border: none;
          padding: 0.625rem 0;
          height: 1.25rem;
          box-sizing: content-box;
          font-size: 0.875rem;
          font-weight: 400;
        }
        button {
          svg {
            width: 1.125rem;
            color: #505050;
          }
        }
      }
    }

    .scroll-wrap {
      height: 18rem;
      overflow-y: auto;
    }
    table {
      border: 1px solid #f4f4fc;
      border-radius: 0.625rem;
      tr {
        &:hover {
          td {
            background-color: #f5f8ff;
          }
        }
      }
      th,
      td {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        height: 1.25rem;
        text-align: left;
      }
      th {
        background-color: #f8f8f9;
        color: #111111;
        padding: 0.625rem 0.75rem;
        &:nth-child(1) {
          width: 10rem;
        }
        &:nth-child(2) {
          width: 10rem;
        }
        &:nth-child(3) {
          width: 14.125rem;
        }
      }
      td {
        padding: 0.875rem 0.75rem;
        cursor: pointer;
        & + td {
          border-top: 1px solid #f4f4fc;
        }
      }
    }
  `,
};
