import { useState, useRef } from 'react';

import usePressESC from 'hooks/usePressESC.js';

import request from 'utils/Request.utils.js';
import { nvl, validateEmail } from 'utils/Common.utils.js';
import styled from 'styled-components';

export default function LinkYourChildModal({ userInfo, setAlertLayerPopup, linkPop, setLinkPop, setReloadData, authenCodeRef }) {
  usePressESC(() => setLinkPop(false), linkPop);

  const [childEmail, setChildEmail] = useState(false);
  const [authSeq, setAuthSeq] = useState(null);
  const childEmailRef = useRef();
  // 학부모 인증 메일 발송
  const sendEmail = () => {
    if (validateEmail(nvl(childEmailRef.current.value))) {
      let params = {
        childEmail: childEmailRef.current.value,
        userName: userInfo.userName,
        userEmail: userInfo.userEmail,
      };

      const successHandler = (response) => {
        if (response.code === 200) {
          setAuthSeq(response.result.authSeq);

          setChildEmail(true);
        } else if (response.code === 400) {
          setAlertLayerPopup((prev) => {
            return { ...prev, visible: true, alertMessage: 'Invalid e-mail.' };
          });
        }
      };

      request.post('/api/member/child/authentication/mail', params, successHandler);
    }
  };
  // 자식 이메일 인증 코드 확인
  const familyLinked = () => {
    if (authenCodeRef.current.value.length > 0) {
      let params = {
        userEmail: childEmailRef.current.value,
        emailAuthenSeq: authSeq,
        emailAuthenCode: authenCodeRef.current.value,
        parentSeq: userInfo.userSeq,
      };

      const successHandler = (response) => {
        if (response.code === 200) {
          setLinkPop(false);
          setChildEmail(false);
          setReloadData((prev) => !prev);
        } else if (response.code === 400) {
          setAlertLayerPopup((prev) => {
            return {
              ...prev,
              visible: true,
              alertMessage: 'Authentication number incorrect.',
            };
          });
        }
      };

      request.post('/api/member/profile/link/parent/student', params, successHandler);
    }
  };
  // 메일 수정
  const editEmail = () => {
    setChildEmail(false);
  };
  return (
    <S.Wrap className={'com_popup type_dim pop_link_academy active'}>
      <div className='pop_container xl'>
        <section className='pop_header'>
          <h1 className='pop_tit'>LINK YOUR CHILD</h1>
          <button className='svg_icon btn_pop_close gray' onClick={() => setLinkPop(false)}>
            &nbsp;
          </button>
        </section>
        <section className='pop_body'>
          <article className='information_sec'>
            <p className='top_notice_tit'>Enter the code for verification</p>
            <p className='top_notice_txt'>Enter your child's email to link with your child. Confirmation code will be sent to the email address.</p>
            <ul className='input_area'>
              <li className='item'>
                <p className='tit_field'>Email</p>
                <div className='input_field input_wrap'>
                  <input type='text' placeholder='Enter your Email' className='input' ref={childEmailRef} disabled={childEmail} />
                  <button className={`com_btn point m ${childEmail ? 'line' : ''}`} onClick={childEmail ? editEmail : sendEmail}>
                    {childEmail ? 'Edit' : 'SEND'}
                  </button>
                </div>
              </li>
              <li className='item'>
                <p className='tit_field'>Confirmation Code</p>
                <div className='input_field'>
                  <input type='text' className='input' ref={authenCodeRef} />
                  <p className='tip'>
                    Didn't Receive An Email?&nbsp;&nbsp;&nbsp;
                    <button className='com_btn txt point xs' onClick={sendEmail}>
                      RESEND
                    </button>
                  </p>
                </div>
              </li>
            </ul>
          </article>
        </section>
        <section className='pop_footer com_btn_wrap'>
          <button className='com_btn xl point line' onClick={() => setLinkPop(false)}>
            Cancel
          </button>
          <button className='com_btn xl point' onClick={familyLinked}>
            Next
          </button>
        </section>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    input {
      border: 1px solid #d2dbe2;
    }
  `,
};
