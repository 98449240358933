import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SessionStorage from 'utils/SessionStorage.utils.js';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import styled from 'styled-components';
import request from 'utils/Request.utils';
import { SCORE_PARAMS } from 'utils/constants';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

/** 시험의 보기명 */
const CHOICES = ['A', 'B', 'C', 'D'];

/** QuestionsStatisticsSection에서 mode가 class 일때 노출되는 Table */
export default function ClassQuestionsStatsTable({ activeSatBox, testModuleSeq, selectedModuleChoiceRate, setForceFetch }) {
  /** 현재 classSeq - Route Parameter */
  const { classSeq: encClassSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** 전체 score에서 추적중인 Test의 시퀀스 */
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);

  const [classAnswerRate, setClassAnswerRate] = useState([
    {
      correctAnswer: '', // "A" | "B" | "C" | "D"  => 문제의 정답
      questionSeq: 0, // number                    => 문제의 고유값 (Seq)
      questionFormat: '', // "M" | "S"             => 객관시("M")인지 주관식("S")인지
      rateA: 0, // number                          => A번 보기를 선택한 학생의 비율
      rateB: 0, // number                          => B번 보기를 선택한 학생의 비율
      rateC: 0, // number                          => C번 보기를 선택한 학생의 비율
      rateD: 0, // number                          => D번 보기를 선택한 학생의 비율
      omitRate: 0, // number                       => 해당 문제를 틀린 학생의 비율
    },
  ]);
  const [isShowAnswerRate, setIsShowAnswerRate] = useState(false);

  const getClassForContentDomain = (contentDomain) => {
    if (contentDomain === '008001000000000') return 'state01 click-able';
    else if (contentDomain === '008002000000000') return 'state02 click-able';
    else if (contentDomain === '008003000000000') return 'state03 click-able';
    else if (contentDomain === '008004000000000') return 'state04 click-able';
    else if (contentDomain === '009001000000000') return 'state01 click-able';
    else if (contentDomain === '009002000000000') return 'state02 click-able';
    else if (contentDomain === '009003000000000') return 'state03 click-able';
    else if (contentDomain === '009004000000000') return 'state04 click-able';

    return '';
  };

  const questionCheck = (e, item) => {
    SessionStorage.clearItem('review');
    SessionStorage.setItem('review', 'classSeq', classSeq);

    window.open(`/student/review?viewQuestion=${encodeURIComponent(item.questionSeqStr)}`, '_blank');
  };
  // 리뷰 팝업창에서 북마크 클릭시 호출
  window.getParentQuestion = () => {
    setForceFetch((prev) => !prev);
  };

  const handleClickShowAnswerRate = () => {
    if (isShowAnswerRate) {
      setIsShowAnswerRate(false);
      return;
    }
    const successHandler = (response) => {
      if (response.code === 200) {
        const fetchedClassChoiceRate = response.result.classChoiceRate;
        setClassAnswerRate(fetchedClassChoiceRate);
        setIsShowAnswerRate(true);
      } else {
        console.error('Error: ', response);
      }
    };
    request.get(`/api/v2/score/class/choice-rate/${testModuleSeq}?classSeq=${classSeq}`, null, successHandler).catch((error) => console.error('', error));
  };

  useEffect(() => {
    setIsShowAnswerRate(false);
  }, [queryCurScoreTestSeq, activeSatBox, testModuleSeq]);

  return (
    <S.ClassQuestionsStatsTableSection>
      <S.TableContainer>
        <thead>
          <tr>
            <th className='row-title' colSpan='4'>
              Question No.
            </th>
            {selectedModuleChoiceRate.map((item, index) => (
              <th key={`${item.questionOrder}-${index}`} className={getClassForContentDomain(item.contentDomain)} onClick={(e) => questionCheck(e, item)}>
                {item.questionOrder}
                {item.bookmarkYn === 'Y' && <i className='svg_icon icon_bookmark_on'>&nbsp;</i>}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='row-title' colSpan='4'>
              Difficulty
            </td>
            {selectedModuleChoiceRate.map((item, index) => {
              return <td key={`${item.difficulty}-${index}`}>{item.difficulty}</td>;
            })}
          </tr>
          <tr>
            <td className='row-title' colSpan='4'>
              Class Average
            </td>
            {selectedModuleChoiceRate.map((item, index) => (
              <td key={`${item.correctAnswerRate}-${index}`} className={item.correctAnswerRate <= 50 ? 'rate01' : item.correctAnswerRate <= 75 ? 'rate02' : ''}>
                {item.correctAnswerRate}%
              </td>
            ))}
          </tr>
          {isShowAnswerRate && (
            <>
              {CHOICES.map((choice, index) => {
                return (
                  <tr key={choice}>
                    {index === 0 && (
                      <td className='border p-2 last-td' colSpan='3' rowSpan='5'>
                        Choice
                      </td>
                    )}
                    <td colSpan='1'>{choice}</td>
                    {classAnswerRate.map((questionData, qIndex) => (
                      <td
                        key={`${choice}-${qIndex}`}
                        className={`${questionData.questionFormat === 'S' ? 'short-answer-question' : questionData.correctAnswer === choice ? 'correct-answer' : ''}`}>
                        {questionData[`rate${choice}`] ? `${questionData[`rate${choice}`]}%` : '0%'}
                      </td>
                    ))}
                  </tr>
                );
              })}
              <tr>
                <td className='sub-title'>Omit</td>
                {classAnswerRate.map((questionData, qIndex) => (
                  <td
                    className={`${questionData.questionFormat === 'S' ? 'short-answer-question' : ''}`}
                    key={`omit-${qIndex}`}>{`${questionData.omitRate ? `${questionData.omitRate}%` : '0%'}`}</td>
                ))}
              </tr>
            </>
          )}
        </tbody>
      </S.TableContainer>
      <S.ShowAnswerRateBtn onClick={handleClickShowAnswerRate}>
        Show answer rate
        <span className='arrow-icon'>
          <i className={`svg_icon icon_select gray ${isShowAnswerRate ? 'active' : ''}`}>&nbsp;</i>
        </span>
      </S.ShowAnswerRateBtn>
    </S.ClassQuestionsStatsTableSection>
  );
}

const S = {
  ClassQuestionsStatsTableSection: styled.section`
    display: flex;
    gap: 0.625rem;
    flex-direction: column;
  `,
  TableContainer: styled.table`
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    border: 1px solid #505050;
    overflow: hidden;
    text-align: center;
    width: 100%;
    background-color: white;

    font-size: 0.75rem;
    font-weight: 600;
    th,
    td {
      border-right: 1px solid #505050;
      border-bottom: 1px solid #505050;
      vertical-align: middle;

      padding: 0.5rem 0;
      box-sizing: border-box;

      // 제목 너비
      &.row-title {
        width: 8.875rem;
      }
      // 정답 보기 배경색 지정
      &.correct-answer {
        background-color: #c6c7f8;
      }
      // 정답률별 스타일 적용
      &.rate01 {
        background: #818181;
        color: #fff;
      }
      &.rate02 {
        background: #e2e2e2;
        color: #080808;
      }
      &.short-answer-question {
        background-color: #ddd;
        color: #ddd;
        border-color: #ddd;
        border-right: 1px solid #505050;
      }
    }
    /* 마지막 열의 오른쪽 테두리 제거 */
    th:last-child,
    td:last-child {
      border-right: none;
    }
    /* 마지막 행의 아래쪽 테두리 제거 */
    tr:last-child td {
      border-bottom: none;
    }
    .last-td {
      border-bottom: none;
    }

    /* 헤더 */
    th {
      background-color: #f8f9fa;
      font-weight: bold;
      position: relative;
      &.state01 {
        background-color: #ffa88c;
      }
      &.state02 {
        background-color: #8fff8c;
      }
      &.state03 {
        background-color: #8cc1ff;
      }
      &.state04 {
        background-color: #a38cff;
      }

      .icon_bookmark_on {
        position: absolute;
        left: 0;
        top: -1px;
      }
    }

    .click-able {
      cursor: pointer;
    }
  `,
  ShowAnswerRateBtn: styled.button`
    border-radius: 100px;
    display: flex;
    gap: 0.25rem;
    font-size: 0.813rem;
    font-weight: 600;
    background-color: white;
    align-items: center;
    padding: 0.5rem 0.75rem;
    width: 9.3rem;
    justify-content: center;
    .arrow-icon {
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .active {
        rotate: 180deg;
      }
    }
  `,
};
