import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconFile } from 'assets/img/svg/icon/icon-file-06.svg';
import { ReactComponent as ImgSvgX } from 'assets/img/svg/x-close.svg';

/** 업로드 된 파일 목록의 각 요소 컴포넌트 */
export default function UploadedFileItem({ fileItem, handleDeleteFile }) {
  return (
    <S.Wrap>
      <ImgSvgIconFile className='icon-file' />
      <p className='name'>{fileItem.fileName}</p>
      <button className='buttonDelete' onClick={() => handleDeleteFile(fileItem)} title='취소'>
        <ImgSvgX />
      </button>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 1.125rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--input-stroke);
  background-color: #ffffff;
  margin-bottom: 0.75rem;
  .icon-file {
    width: 1.25rem;
    color: var(--point-color);
  }
  .name {
    font-size: 0.813rem;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #111111;
    text-align: left;
    line-height: 1rem;
    letter-spacing: -0.26px;
    margin-top: 0;
  }
  .buttonDelete {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 1.25rem;
      color: #a6b3be;
    }
  }
`;
