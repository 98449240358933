import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import SignUpFirstTimeSignIn from 'components/user/sign-up/_components/pages/SignUpFirstTimeSignIn';

/** "회원가입 - 일반 이메일 가입 - Tutor - 첫 번째 로그인" 페이지 */
export default function SignUpEmailTutorFirstTimeSignInPage() {
  const navigate = useNavigate();
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {},
  });

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    navigate('/signup/email/tutor/timezone');
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <SignUpFirstTimeSignIn headerTitle={'Tutor'} handleSubmit={handleSubmit} onValid={onValid} register={register} errors={errors}>
      To complete your sign in as an Educator, you must enter your Academy Code or Tutor Code. Please type in the code from your email.
    </SignUpFirstTimeSignIn>
  );
}
