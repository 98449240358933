import styled from 'styled-components';
import { useRef, useEffect } from 'react';

export default function ScrollableTable({
  columnGroups = null,
  headers,
  leftColumn,
  data,
  cornerText = '구분', // 코너 셀 텍스트를 위한 props 추가 (기본값 설정)
}) {
  // 스크롤 동기화를 위한 ref 추가
  const contentRef = useRef(null);
  const headerRef = useRef(null);
  const headerGroupRef = useRef(null);
  const leftColumnRef = useRef(null);

  // 스크롤 동기화
  useEffect(() => {
    const handleContentScroll = () => {
      if (contentRef.current && headerRef.current) {
        headerRef.current.scrollLeft = contentRef.current.scrollLeft;
      }
      // columnGroups가 있을 때만 그룹 헤더 스크롤 처리
      if (columnGroups && contentRef.current && headerGroupRef.current) {
        headerGroupRef.current.scrollLeft = contentRef.current.scrollLeft;
      }
      if (contentRef.current && leftColumnRef.current) {
        leftColumnRef.current.scrollTop = contentRef.current.scrollTop;
      }
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', handleContentScroll);
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener('scroll', handleContentScroll);
      }
    };
  }, [columnGroups]);

  return (
    <S.TableContainer hasColumnGroups={!!columnGroups}>
      {/* 좌상단 고정 영역 - cornerText props 사용 */}
      <S.FixedCorner hasColumnGroups={!!columnGroups}>{cornerText}</S.FixedCorner>

      {/* 상단 컬럼 그룹 행 (columnGroups가 있을 때만 렌더링) */}
      {columnGroups && (
        <S.HeaderGroupRow ref={headerGroupRef}>
          {columnGroups.slice(1).map((group, index) => (
            <S.HeaderGroupCell key={index} colSpan={group.span || 1}>
              {group.title}
            </S.HeaderGroupCell>
          ))}
        </S.HeaderGroupRow>
      )}

      {/* 상단 헤더 영역 */}
      <S.HeaderRow ref={headerRef} hasColumnGroups={!!columnGroups}>
        {headers.slice(1).map((header, index) => (
          <S.HeaderCell key={index}>{header}</S.HeaderCell>
        ))}
      </S.HeaderRow>

      {/* 왼쪽 열 영역 */}
      <S.LeftColumn ref={leftColumnRef}>
        {leftColumn.map((item, index) => (
          <S.LeftHeaderCell key={index}>{item}</S.LeftHeaderCell>
        ))}
      </S.LeftColumn>

      {/* 스크롤되는 데이터 영역 */}
      <S.ScrollableContent ref={contentRef}>
        {data.map((row, rowIndex) => (
          <S.DataRow key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <S.Cell key={cellIndex}>{cell}</S.Cell>
            ))}
          </S.DataRow>
        ))}
      </S.ScrollableContent>
    </S.TableContainer>
  );
}

// Cell을 먼저 정의합니다
const Cell = styled.div`
  min-width: 120px;
  height: 40px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;

// 그 다음 S 객체 정의
const S = {
  TableContainer: styled.div`
    display: grid;
    grid-template-areas: ${(props) =>
      props.hasColumnGroups
        ? `'corner group-header'
         'corner header'
         'left content'`
        : `'corner header'
         'left content'`};
    grid-template-columns: auto 1fr;
    grid-template-rows: ${(props) => (props.hasColumnGroups ? 'auto auto 1fr' : 'auto 1fr')};
    width: 100%;
    height: 500px;
    position: relative;
    border: 1px solid #ddd;
    overflow: hidden;
  `,

  FixedCorner: styled.div`
    grid-area: corner;
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    height: ${(props) => (props.hasColumnGroups ? '80px' : '40px')};
    grid-row: ${(props) => (props.hasColumnGroups ? '1 / span 2' : '1')};
  `,

  HeaderGroupRow: styled.div`
    grid-area: group-header;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
  `,

  HeaderRow: styled.div`
    grid-area: header;
    display: flex;
    position: sticky;
    top: ${(props) => (props.hasColumnGroups ? '40px' : '0')};
    z-index: 2;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
  `,

  LeftColumn: styled.div`
    grid-area: left;
    display: flex;
    flex-direction: column;
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: #f5f5f5;
    border-right: 1px solid #ddd;
    overflow: hidden;
  `,

  ScrollableContent: styled.div`
    grid-area: content;
    overflow: auto;
    display: flex;
    flex-direction: column;

    /* 커스텀 스크롤바 스타일링 */
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bbb;
      border-radius: 4px;
      transition: background 0.2s ease;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #999;
    }

    &::-webkit-scrollbar-corner {
      background: #f1f1f1;
    }

    /* Firefox에서의 스크롤바 스타일링 */
    scrollbar-width: thin;
    scrollbar-color: #bbb #f1f1f1;
  `,

  DataRow: styled.div`
    display: flex;
  `,

  Cell, // 미리 정의한 Cell 참조

  HeaderCell: styled(Cell)`
    font-weight: bold;
    background-color: #f5f5f5;
  `,

  LeftHeaderCell: styled(Cell)`
    font-weight: bold;
    background-color: #f5f5f5;
  `,

  HeaderGroupCell: styled(Cell)`
    font-weight: bold;
    background-color: #ebebeb;
    border-bottom: 1px solid #ddd;

    /* colSpan 속성에 따라 width 조정 (기본값: 1) */
    min-width: ${(props) => (props.colSpan || 1) * 120}px;
    justify-content: center;
  `,
};
