import React, { useState, useRef, useEffect, Fragment } from 'react';

import request from 'utils/Request.utils.js';

// hook
import usePressESC from 'hooks/usePressESC';
import useScrollLock from 'hooks/useScrollLock';

// Components
import PopClassesRow from './_PopClassesRow';
import ClassDetails from './_ClassDetails';
import ProfilePopup from 'components/_common/modals/PopupProfile';
import EditModalCalendar from 'components/com/tests/scheduled/_components/EditModalCalendar';

/**
 * Classes에서 class 정보를 편집하는 모달
 * @description
 * 🔍 검색 키워드 - #modal #com/students/classes
 */
export default function PopClassManage({ handleClassManageOpen, editClassInfo, searchClasses, setAlert }) {
  const [userInfo, _] = useState(request.tokenDecoder());

  usePressESC(() => handleClassManageOpen(false));
  useScrollLock();

  //프로필 영역
  const [ProfileState, setProfileState] = useState(false);
  const [studentInfo, setStudentInfo] = useState({
    userName: '',
    profile: null,
    className: '',
    schoolType: '',
    regDate: '',
    studentGrade: 0,
    schoolLocation: '',
    userEmail: '',
    userMobile: '',
    userParentMobile: '',
  });
  const handleShowProfile = (userSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const studentInfo = response.result?.studentInfo;
        let imageUrl = null;
        if (studentInfo.upFileSeq > 0) {
          imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}?${Date.now()}`;
        }
        studentInfo.profile = imageUrl;
        setStudentInfo(studentInfo);
      }
    };

    request.get(`/api/test/profiles?ecSeq=${userInfo.ecSeq}&userSeq=${userSeq}`, null, successHandler);
    setProfileState(true);
  };

  const [selectedRange, setSelectedRange] = useState([]);

  /** 컴포넌트에 인자로 보낼 날짜 변경 핸들러 */
  const handleSelectDate = (dateArr) => {
    setSelectedRange(dateArr);
    if (dateArr.length === 2) {
      handleClassInfoChange('startDate', dateArr[0]);
      handleClassInfoChange('endDate', dateArr[1]);
    }
  };

  const classTitleInputRef = useRef(null);
  const searchInputRef = useRef(null);

  const [searchStudentData, setSearchStudentData] = useState([]);
  const [checkedStudents, setCheckedStudents] = useState(editClassInfo ? editClassInfo.studentList : []);
  const [isAllChecked, setIsAllChecked] = useState(editClassInfo ? true : false);
  const [currentStudents, setCurrentStudents] = useState(editClassInfo ? editClassInfo.studentList : []);

  const [classInfo, setClassInfo] = useState(
    editClassInfo
      ? editClassInfo
      : {
          startDate: null,
          endDate: null,
          studentList: [],
        }
  );
  /** "Class Info" 영역에 보여 줄 데이터 변경 핸들러 */
  const handleClassInfoChange = (key, value) => {
    setClassInfo((prev) => ({ ...prev, [key]: value }));
  };
  /** "Class Name" Input 창 Key Down 핸들러 */
  const handleEnterDown = (event, key, ref) => {
    event.type === 'keyup' && handleClassInfoChange(key, ref.current.value);
  };

  useEffect(() => {
    handleSearchStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClassInfo((prev) => ({
      ...prev,
      studentList: checkedStudents,
    }));
  }, [checkedStudents]);

  useEffect(() => {
    const isAllChecked = searchStudentData.every((student) => checkedStudents.some((checkedStudent) => checkedStudent.userSeq === student.userSeq));
    setIsAllChecked(isAllChecked);
    if (searchStudentData.length === 0) setIsAllChecked(false);
  }, [searchStudentData, checkedStudents]);

  const handleSearchStudents = (searchKeyword = '') => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const searchData = response.result.studentList;

        setSearchStudentData(searchData.sort((a, b) => a.userName.localeCompare(b.userName)));
      }
    };

    if (searchKeyword.length !== 0) {
      request.get(`/api/academy/${userInfo.ecSeq}/students?searchKeyword=${searchKeyword}`, null, successHandler);
    } else {
      request.get(`/api/academy/${userInfo.ecSeq}/students`, null, successHandler);
    }
  };

  const handleCheckboxChange = (student, isChecked) => {
    if (isChecked) {
      setCheckedStudents((prev) => {
        const isAlreadyIncluded = prev.some((item) => item.userSeq === student.userSeq);
        if (!isAlreadyIncluded) return [...prev, student];
        else return prev;
      });
    } else setCheckedStudents((prev) => prev.filter((data) => data.userSeq !== student.userSeq));
  };
  const handleAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsAllChecked(isChecked);

    if (isChecked) {
      const newCheckedStudents = [...checkedStudents];
      searchStudentData.forEach((student) => {
        if (!newCheckedStudents.some((checkedStudent) => checkedStudent.userSeq === student.userSeq)) {
          newCheckedStudents.push(student);
        }
      });
      setCheckedStudents(newCheckedStudents);
    } else {
      const filteredCheckedStudents = checkedStudents.filter(
        (checkedStudent) => !searchStudentData.some((student) => student.userSeq === checkedStudent.userSeq)
      );
      setCheckedStudents(filteredCheckedStudents);
    }
  };

  return (
    <div className='com_popup type_dim pop_class_manage pop_manage active'>
      <div className='pop_container xxxl'>
        <section className='pop_body'>
          <div className='left'>
            {/*1.class name*/}
            <article className='com_sec_tit'>
              <h2 className='tit'>
                <i className='svg_icon bullet_1 black'>&nbsp;</i>
                Class Name
              </h2>
            </article>
            <article className='search_sec'>
              <div className='search'>
                <input
                  type='text'
                  className='input'
                  placeholder='Enter class name'
                  defaultValue={editClassInfo?.classTitle}
                  ref={classTitleInputRef}
                  onKeyUp={(e) => handleEnterDown(e, 'classTitle', classTitleInputRef)}
                />
              </div>
            </article>
            {/*2.start date*/}
            <article className='com_sec_tit'>
              <h2 className='tit'>
                <i className='svg_icon bullet_2 black'>&nbsp;</i>
                Start and End Date
              </h2>
            </article>
            <article className='com_calendar_wrap'>
              <EditModalCalendar selectedDateState={selectedRange} setSelectedDateState={handleSelectDate} />
            </article>
          </div>
          <div className='center'>
            {/*3.Student*/}
            <article className='com_sec_tit'>
              <h2 className='tit'>
                <i className='svg_icon bullet_3 black'>&nbsp;</i>
                Total Students
              </h2>
            </article>
            <article className='search_sec'>
              <div className='search'>
                <input
                  type='text'
                  className='input'
                  placeholder='Search students'
                  ref={searchInputRef}
                  onKeyDown={(e) => {
                    e.key === 'Enter' && handleSearchStudents(searchInputRef.current.value);
                  }}
                />
                <button className='com_btn black m' onClick={() => handleSearchStudents(searchInputRef.current.value)}>
                  SEARCH
                </button>
              </div>
            </article>
            <article className='com_list'>
              <div className='scroll'>
                <table className='list'>
                  <colgroup>
                    <col width='40' />
                    <col />
                    <col width='100' />
                  </colgroup>
                  <thead>
                    <tr className='sticky top'>
                      <th>
                        <input type='checkbox' id='chkAll' className='com_chk' checked={isAllChecked} onChange={handleAllCheckboxChange} />
                        <label htmlFor='chkAll'>&nbsp;</label>
                      </th>
                      <th>Name</th>
                      <th>Profile</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchStudentData.map((studentData, idx) => {
                      return (
                        <Fragment key={idx}>
                          <PopClassesRow
                            keyIdx={idx}
                            student={studentData}
                            checked={checkedStudents.some((student) => student.userSeq === studentData.userSeq)}
                            onCheckboxChange={handleCheckboxChange}
                            handleShowProfile={handleShowProfile}
                          />
                        </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </article>
          </div>
          {
            ProfileState ? (
              <ProfilePopup type='student' setProfileState={setProfileState} studentInfo={studentInfo} /> //프로필
            ) : (
              <ClassDetails
                editClassInfo={!!editClassInfo}
                setProfileState={setProfileState}
                classInfo={classInfo}
                checkedStudents={checkedStudents}
                onCheckboxChange={handleCheckboxChange}
                handleShowProfile={handleShowProfile}
                handleClassManageOpen={handleClassManageOpen}
                searchClasses={searchClasses}
                setAlert={setAlert}
              />
            ) //클래스 디테일
          }
        </section>
      </div>
    </div>
  );
}
