import React from 'react';
import SessionStorage from 'utils/SessionStorage.utils.js';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useNavigate } from 'react-router-dom';

/** "/com/score" 페이지의 "Student List" 의 목록 개별 요소 컴포넌트 */
function MemberListItem({ studentInfo, mode = null }) {
  const navigate = useNavigate();

  /** 선택 된 학생 Report 카드 Open */
  const reportCardOpen = (uthSeq, userSeq) => {
    SessionStorage.clearItem('reportCard');
    SessionStorage.setItem('reportCard', 'uthSeq', uthSeq);
    SessionStorage.setItem('reportCard', 'userSeq', userSeq);

    window.open(`/com/reports`, '_blank');
  };

  /** 학생 선택 핸들러 - /com/score/student/:userSeq 이동 */
  const handleSelectStudent = (studentInfo) => {
    navigate(`/com/score/student/${obfuscateUrlParam(String(studentInfo.userSeq))}`);
  };

  const isLecture = mode === 'lecture';

  // P 일때만 점수, report card 노출
  const isTestEnd = studentInfo.testStatus === 'P';

  return (
    <tr onClick={() => handleSelectStudent(studentInfo)}>
      <td>
        <div className='com_profile_img'>
          {studentInfo.upFileSeq > 0 ? (
            <img
              className='profile-img-background'
              src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}`}
              alt='profile'
            />
          ) : (
            <span className='name'>{studentInfo.userName.charAt(0)}</span>
          )}
        </div>
      </td>
      <td>
        <span className='name'>{studentInfo.userName}</span>
      </td>
      <td className='alignC data_field'>{isLecture ? '-' : isTestEnd ? studentInfo.rwScore : ''}</td>
      <td className='alignC data_field'>{isLecture ? '-' : isTestEnd ? studentInfo.mathScore : ''}</td>
      <td className='alignC'>
        {isTestEnd && (
          <button
            className='com_btn_report'
            onClick={(event) => {
              event.stopPropagation();
              reportCardOpen(studentInfo.uthSeq, studentInfo.userSeq);
            }}>
            <i className='svg_icon icon_score_report s black'>&nbsp;</i>
          </button>
        )}
      </td>
    </tr>
  );
}

export default MemberListItem;
