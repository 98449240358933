import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjsTZ from 'utils/functions/time/dayjs-config';
import useOutsideClick from 'hooks/useOutsideClick';
import DirectionsPopup from './DirectionsPopup';
import LocalStorage from 'utils/LocalStorage.utils';
import useExitQuestion from 'components/exam/dsat/_hooks/useExitQuestion';
import Timer from 'components/exam/dsat/_components/timer/Timer';
import { deleteLocalStorageTime } from 'components/exam/dsat/_utils/functions/timerLocalStorageFunctions';

/** 영어 시험 화면 Header 영역 컴포넌트 */
export default function ExamHeader({ setAlertLayerPopup, tryAnswerRef, currentExamInfoRef, isDragged, setAnnotateMemo, sendTimeRef }) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////////
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateExamInfo = useSelector((state) => state.stateExamInfo);
  const stateExamComponents = useSelector((state) => state.stateExamComponents);
  const { fetch: fetchExitResultHistory } = useExitQuestion();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const examDivRef = useRef();
  const annotationEmptyTooltipRef = useRef(null);
  const moreTooltipRef = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 타이머 종료 핸들러 */
  const timeOver = () => {
    console.log('영어 타임 오버');
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'reviewDisplay', data: false } });
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'isFiveMinutesRemaining', data: false } });
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'isMathJaxError', data: false } });
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'runOutTime', data: false } });
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationEmptyDisplay', data: false } });

    deleteLocalStorageTime();

    if (stateExamInfo['currentTest'].em1 === 'I' && stateExamInfo['currentTest'].em2 === 'R') {
      // 모듈 1 타이머 종료
      // alert('타이머 종료 1');
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Time has run out. You have moved onto next section.',
        };
      });

      fetchExitResultHistory({ currentNum: state?.currentNum, currentExamInfoRef });
      // navigate('/exam/dsat/rw', { state: { currentNum: 0 } }); // openQuestion 반응하게 하기 위함

      tryAnswerRef.current = [];
      currentExamInfoRef.current = [];

      /** 다음 시험 모듈 세팅 */
      let currentExamInfo = stateExamInfo.currentTest;
      currentExamInfo = { ...currentExamInfo, em1: 'D', em2: 'I' };
      dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentTest', data: { ...currentExamInfo } } });
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'moduleOverDisplay', data: true } });

      // ----------------------------------------------------
    } else if (stateExamInfo['currentTest'].em2 === 'I') {
      // 모듈 2 타이머 종료
      // alert('타이머 종료 2');

      LocalStorage.setItem('sendTime', 'time', dayjsTZ());
      fetchExitResultHistory({ currentNum: state?.currentNum, currentExamInfoRef });

      let currentExamInfo = stateExamInfo.currentTest;
      currentExamInfo = { ...currentExamInfo, em2: 'D' };
      dispatch({ type: 'setExamInfo', payload: { fieldName: 'currentTest', data: { ...currentExamInfo } } });

      if (stateExamInfo.setSubject === 'F') {
        navigate('/exam/dsat/breaktime', {
          replace: true,
        });
      } else
        navigate('/exam/dsat/completion', {
          replace: true,
        });
    }
  };

  const annotationClickHandler = (e) => {
    if (!isDragged) {
      dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationEmptyDisplay', data: true } });

      return;
    }

    setAnnotateMemo('');
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationEmptyDisplay', data: false } });
    dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationDisplay', data: true } });

    // 블럭 지정된 게 없을 때
    // annotationEmptyDisplay true
  };

  // Hook - 툴팁 외부 클릭시 닫히게
  const handleCloseAnnotationEmptyTooltip = () => dispatch({ type: 'setExamComponents', payload: { fieldName: 'annotationEmptyDisplay', data: false } });

  useOutsideClick(annotationEmptyTooltipRef, handleCloseAnnotationEmptyTooltip);

  const handleCloseMoreTooltip = () => dispatch({ type: 'setExamComponents', payload: { fieldName: 'moreDisplay', data: false } });

  useOutsideClick(moreTooltipRef, handleCloseMoreTooltip);
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  return (
    <section ref={examDivRef} className='pop_header'>
      <div className='tit_area'>
        <div className='tit'>Section 1, Module {stateExamInfo.questionList[stateExamInfo.currentNum]?.moduleNum}: Reading and Writing</div>
        <button
          className='btn'
          onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'directionDisplay', data: !stateExamComponents.directionDisplay } })}>
          Directions<i className='svg_icon icon_arrow black'></i>
        </button>
        {stateExamComponents.directionDisplay ? (
          <DirectionsPopup dispatch={dispatch} stateExamComponents={stateExamComponents} examDivRef={examDivRef} />
        ) : null}
      </div>
      <div className={`time_area ${stateExamComponents.timeAreaDisplay ? '' : 'hide'}`}>
        <div className={`time ${stateExamComponents.runOutTime ? 'end' : ''}`}>
          <Timer timeOver={timeOver} sendTimeRef={sendTimeRef} />
        </div>
        <button
          className='btn_time'
          onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'timeAreaDisplay', data: !stateExamComponents.timeAreaDisplay } })}>
          Hide
        </button>
      </div>
      <div className='btn_area'>
        <button className={`btn ${stateExamComponents.annotationDisplay ? 'active gray' : ''}`} onClick={(e) => annotationClickHandler(e)}>
          <i className='svg_icon icon_annotate'>&nbsp;</i>
          Annotate
          {stateExamComponents.annotationEmptyDisplay && (
            <div className='tip_layer' ref={annotationEmptyTooltipRef}>
              <div className='tit'>MAKE A SELECTION FIRST</div>
              Select some text, then press annotate.
            </div>
          )}
        </button>

        <button
          className={`btn ${stateExamComponents.moreDisplay ? 'active' : ''}`}
          onClick={() => {
            dispatch({ type: 'setExamComponents', payload: { fieldName: 'moreDisplay', data: !stateExamComponents.moreDisplay } });
          }}>
          <i className='svg_icon icon_more'>&nbsp;</i>
          More
        </button>
        {stateExamComponents.moreDisplay && (
          <div className='more_layer' ref={moreTooltipRef}>
            <button className='item' onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'helpDisplay', data: true } })}>
              <i className='svg_icon icon_help'>&nbsp;</i>Help
            </button>
            <button className='item' onClick={() => dispatch({ type: 'setExamComponents', payload: { fieldName: 'exitExamDisplay', data: true } })}>
              <i className='svg_icon icon_exit'>&nbsp;</i>Exit the Exam
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
