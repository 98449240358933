import { useEffect } from 'react';
import styled from 'styled-components';

/**
 * @param {Object} graphData - 그래프 데이터 객체
 * @param {number} graphData.max - 최대값
 * @param {number} graphData.data - 현재 값
 * @param {number} graphData.gatePlusData - GATEPLUS 평균값
 * @param {number} graphData.checkViewAboutAverageOfGateplus - GATEPLUS 평균값 보여주기 여부
 */
export default function RateGraphItem({ graphData, index, checkViewAboutAverageOfGateplus }) {
  // 퍼센트 계산 함수
  const calculatePercent = (value) => {
    if (!graphData.max) return 0;
    return (value / graphData.max) * 100;
  };

  /** GATE⁺ 그래프 너비 계산 함수 */
  const getGatePlusGraphWidth = () => {
    // 두 값이 같을때 예외처리
    if (graphData.data === graphData.gatePlusData) {
      // 유저의 값과 GATE⁺ 값이 둘다 최대 값일 경우 GATE⁺값이 유저 값에 덮히게 설정
      if (graphData.data === graphData.max) {
        return `${calculatePercent(graphData.gatePlusData)}`;

        // 일반적으로 두 값이 같은 상황에서는 GATE⁺값이 주고 더 튀어나오게 설정
      } else {
        return `calc(${calculatePercent(graphData.gatePlusData)}% + .625rem)`;
      }
    } else {
      return `${calculatePercent(graphData.gatePlusData)}%`;
    }
  };

  return (
    <S.GraphItemContainer className={`item type0${index}`} toolTipCircleBgColor={graphData.graphColor}>
      <p className='tit_field'>{graphData.label}</p>
      <div className='grah_field'>
        <div className='graph_wrap' style={{ width: `${calculatePercent(graphData.data)}%` }}>
          <div
            className={`rate my ${calculatePercent(graphData.data) < calculatePercent(graphData.gatePlusData) ? 'low' : ''} ${graphData.data === 0 ? 'zero' : ''}`}>
            <S.UserGraphBar color={graphData.graphColor}>&nbsp;</S.UserGraphBar>
            <p className='num'>{`${graphData.data}${graphData.unit}`}</p>
          </div>
          <div className='layer'>
            <i className='color'>&nbsp;</i>
            {graphData.toolTipStr}&nbsp;
            <b>{`${graphData.data}${graphData.unit}`}</b>
          </div>
        </div>
        {checkViewAboutAverageOfGateplus && (
          <div className='graph_wrap' style={{ width: getGatePlusGraphWidth() }}>
            <div
              className={`rate average ${calculatePercent(graphData.data) > calculatePercent(graphData.gatePlusData) ? 'low' : ''} ${graphData.gatePlusData === 0 ? 'zero' : ''}`}>
              <div className='bar'>&nbsp;</div>
            </div>
            <div className='layer'>
              <i className='color'>&nbsp;</i>GATEPLUS Average :&nbsp;
              <b>{`${graphData.gatePlusData}${graphData.unit}`}</b>
            </div>
          </div>
        )}
        <div className='bar'>&nbsp;</div>
      </div>
    </S.GraphItemContainer>
  );
}

const S = {
  GraphItemContainer: styled.div`
    padding-bottom: 1.5rem;
    .graph_wrap {
      .layer {
        .color {
          background-color: ${(props) => props.toolTipCircleBgColor};
        }
      }
    }
  `,
  UserGraphBar: styled.div`
    position: relative;
    width: 100%;
    height: 12px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.0784313725) inset;
    border-radius: 10px;
    background-color: ${(props) => props.color};
    /* 상위 컴포넌트에 해당 컴포넌트가 클릭된 상태인지 알려주는 className */
    .box_disable & {
      background-color: #c6c6c6;
    }
  `,
};
