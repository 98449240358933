import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';

////////// Components
import BtnTest from './_components/BtnTest';
import Layout from 'components/com/tests/_components/_common/Layout';
import styled from 'styled-components';
import { styledCssEllipsis } from 'styles/styledComponents/globalStyles';
import useUserLevels from 'hooks/useUserLevels';

/** Set Tests : "시험 모듈 선택" 페이지 */
export default function TestsSetSelectTestMorePage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  const isB2c = useLevels.isStudent;
  /** "Set Tests 전역 상태" */
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [customSetInfo, setCustomSetInfo] = useState({
    list: [],
  });

  const [selectModules, setSelectModules] = useState(nvl(stateSetTest.selectedModules) === '' ? [] : stateSetTest.selectedModules.map((i) => i.customSetSeq));

  const [selectTestSet, setSelectTestSet] = useState([]);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const getCustomSet = () => {
    const {
      selectedConfig: { testConfiguration, testType, testRwDiff, testMathDiff },
      selectedStudents,
    } = stateSetTest;

    const subjectsToFetch = testConfiguration === 'RW' ? 'E' : testConfiguration;

    let difficultyToFetch = '';
    const difficultyMapping = {
      EE: 'E', // testRwDiff, testMathDiff 둘 다 'E'
      HH: 'H', // testRwDiff, testMathDiff 둘 다 'H'
      EH: 'Y', // testRwDiff == 'E', testMathDiff == 'H'
      HE: 'D', // testRwDiff == 'H', testMathDiff == 'E'
    };

    if (testType) difficultyToFetch = 'F';
    else if (testConfiguration === 'RW') difficultyToFetch = testRwDiff;
    else if (testConfiguration === 'M') difficultyToFetch = testMathDiff;
    else difficultyToFetch = difficultyMapping[testRwDiff + testMathDiff] || '난이도선택안됨!';

    let pms = {
      subject: subjectsToFetch,
      difficulty: difficultyToFetch,
      seqStr: selectedStudents.map((item) => item.userSeq).join('|'),
      isExclusive: 'Y', // 학원 전용 세트 보여주도록 함 일단은 Y
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        const customSetList = response.result.customSetList;

        setCustomSetInfo((prev) => {
          return {
            ...prev,
            list: prev.list.concat(customSetList),
          };
        });
      }
    };

    request.get(
      `/api/dsat/customset/list?subject=${pms.subject}&difficulty=${pms.difficulty}&seqStr=${encodeURIComponent(pms.seqStr)}&isExclusive=${pms.isExclusive}`,
      null,
      successHandler
    );
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    getCustomSet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterTest = [];

    customSetInfo.list.forEach((item) => {
      if (selectModules.includes(item.customSetSeq)) {
        filterTest.push(item);
      }
    });
    setSelectTestSet(filterTest);
  }, [selectModules, customSetInfo.list]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <Layout selectModules={selectModules} selectTestSet={selectTestSet}>
      <S.Wrap>
        <article className='com_sec_tit'>
          <h2 className='tit'>
            <i className='svg_icon icon_ol black'>&nbsp;</i>
            Available Tests
            <span className='tip'>(Select one or more tests)</span>
          </h2>
        </article>
        <div className='btn_test__wrap'>
          {nvl(customSetInfo.list) !== '' &&
            customSetInfo.list.map((i, idx) => {
              return (
                <Fragment key={`set_${idx}`}>
                  <BtnTest
                    isB2c={isB2c}
                    tit={i.testName}
                    seq={i.customSetSeq}
                    rw={`Average RW score : ${i.englishAvg}/800`}
                    math={`Average Math score : ${i.mathAvg}/800`}
                    rwTakenNum={i.englishCnt}
                    maTakenNum={i.mathCnt}
                    rwTakenUser={i.englishStudentList}
                    maTakenUser={i.mathStudentList}
                    viewAverageSubject={stateSetTest.selectedConfig.testConfiguration}
                    selectModules={selectModules}
                    setSelectModules={setSelectModules}
                  />
                </Fragment>
              );
            })}
        </div>
      </S.Wrap>
    </Layout>
  );
}

const S = {};

S.Wrap = styled.div`
  .btn_test {
    border: 1px solid #d2dbe2;
    padding: 30px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    gap: 30px;
    border-radius: 10px;
    align-self: stretch;
    .tit_field {
      font-size: 16px;
      font-weight: 500;
    }
    .txt_field {
      font-size: 10px;
      line-height: 16px;
      color: $emphasis;
      margin-top: 10px;
      & + .com_chk_wrap {
        margin-top: 10px;
      }
    }
    .txt_box {
      border: 1px solid #ebebeb;
      background-color: #fafafa;
      color: var(--SubText-color);
      font-size: 10px;
      font-weight: 500;
      height: 24px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      .layer {
        position: absolute;
        left: auto;
        top: 24px;
        box-shadow: 0px 2px 6px -3px #00000026;
        background-color: #fff;
        text-align: left;
        z-index: 3;
        border-radius: 4px;
        display: none;
        padding: 15px;
        width: 100%;
        &_tit {
          color: #000;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 8px;
        }
        .txt {
          color: var(--SubText-color);
          font-size: 10px;
          line-height: 11.72px;
          margin-bottom: 15px;
        }
        .student_list {
          background-color: #fafafa;
          border-radius: 8px;
          max-height: 246px;
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem;
            gap: 4px;
            .name {
              font-size: 12px;
              color: #4b5563;
              flex: 1;
              ${styledCssEllipsis}
            }
            .btn_area {
              display: flex;
              align-items: center;
              margin-left: auto;
              gap: 4px;
              .com_btn {
                border: 1px solid var(--c-border);
                &.taken {
                  color: var(--c-red);
                }
                &.scheduled {
                  color: var(--point-color);
                }
              }
            }
          }
        }
      }
      &:hover {
        border-color: #888888 !important;
        color: #4b5563 !important;
        .layer {
          display: block;
        }
      }
      &__wrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
        position: absolute;
        left: 10px;
        right: 10px;
        top: 10px;
      }
    }
    &.active {
      border-color: #477bff;
      background-color: #f7f8ff;
      & :is(.tit_field, .txt_field) {
        color: #0068bd;
      }
      .txt_box {
        color: #2c85cf;
        background-color: #fff;
        border-color: #d1deff;
        &:hover {
          border-color: var(--point-color);
          color: var(--point-color);
        }
      }
    }
  }
  .btn_test {
    width: calc((100% - 30px) / 3);
    min-width: calc((100% - 30px) / 3);
    max-width: calc((100% - 30px) / 3);
    padding-block: 95px 76px;
    position: relative;
    &:hover {
      border-color: #8cabf0;
      background-color: #fbfbff;
      color: #11456f;
      .txt_box {
        background-color: #fff;
        color: #7191ae;
        border-color: #d1dcf0;
      }
    }
  }
  .btn_test__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
  }
`;
