import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

//component
import Paging from 'layout/Paging'; //페이징

import request from 'utils/Request.utils.js';
import { nvl } from 'utils/Common.utils.js';
import { USER_LEVELS } from 'utils/constants.js';

import usePressESC from 'hooks/usePressESC';
import useScrollLock from 'hooks/useScrollLock';
import styled from 'styled-components';

/**
 * LNB 에서 버튼으로 접근 하는 모달 : 리뷰 노트
 * @description
 * 🔍 검색 키워드 - #ReviewNote
 */
export default function ReviewNotes(props) {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  usePressESC(() => props.setReviewNotesActive(false));
  useScrollLock();

  //tab
  const [tabState, setTabState] = useState(0);
  const tab = [
    { text: 'Reading and Writing', value: 0 },
    { text: 'Math', value: 1 },
  ];
  const renderTab = useCallback(() => {
    return tab.map((v, idx) => {
      return (
        <button key={idx} className={`menu ${(tabState === v.value && 'active') || ''}`} onClick={() => setTabState(v.value)}>
          {v.text}
        </button>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const [reviewBookmarkInfo, setReviewBookmarkInfo] = useState({
    reviewBookmarkList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });

  const nextPageMove = (value) => {
    setReviewBookmarkInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const getModuleAnswer = (userSeq) => {
    const { seqStr, selectedKeys } = selectCheckBox;

    let pms = {
      userSeq: userSeq,
      subject: tabState ? 'M' : 'E',
      offset: (reviewBookmarkInfo.currentPage - 1) * reviewBookmarkInfo.numPerPage,
      domainStr: seqStr,
    };

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let reviewBookmarkList = response.result.reviewBookmarkList;
        let totalCount = response.result.total;
        let domain = response.result.contentDomain;

        setDomainList(
          domain &&
            domain.map((item) => ({
              value: item.commonCode,
              name: item.codeName,
            }))
        );

        setReviewBookmarkInfo((prev) => {
          return {
            ...prev,
            totalCount: totalCount,
            reviewBookmarkList: reviewBookmarkList,
          };
        });
      }
    };

    request.get(
      `/api/dsat/review/note?userSeq=${pms.userSeq}&subject=${pms.subject}&contentDomainStr=${encodeURI(pms.domainStr)}&offset=${pms.offset}`,
      null,
      successHandler
    );
  };

  const [domainList, setDomainList] = useState();

  const getClassForContentDomain = (contentDomain) => {
    if (contentDomain === '008001000000000') return 'sort_field orange';
    else if (contentDomain === '008002000000000') return 'sort_field green';
    else if (contentDomain === '008003000000000') return 'sort_field blue';
    else if (contentDomain === '008004000000000') return 'sort_field purple';
    else if (contentDomain === '009001000000000') return 'sort_field orange';
    else if (contentDomain === '009002000000000') return 'sort_field green';
    else if (contentDomain === '009003000000000') return 'sort_field blue';
    else if (contentDomain === '009004000000000') return 'sort_field purple';

    return '';
  };

  const [selectCheckBox, setSelectCheckBox] = useState({
    seqStr: '',
    selectedKeys: [],
  });

  const domainCheck = (e, domain) => {
    const { selectedKeys, seqStr } = selectCheckBox;
    const { checked } = e.target;

    if (checked) {
      const updatedSelectedKeys = [...selectedKeys, domain];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== domain);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  const [childSeqState, setChildSeq] = useState();
  const getChildSeq = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let childInfo = response.result.childInfo;

        if (nvl(childInfo) !== '' && childInfo.length > 0) {
          let childSeq = childInfo[0].familySeq;

          setChildSeq(childSeq);

          getModuleAnswer(childSeq);
        }
      }
    };

    request.get(`/api/member/child?userSeq=${userInfo.userSeq}`, null, successHandler);
  };

  useEffect(() => {
    setReviewBookmarkInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });
    setSelectCheckBox({ seqStr: '', selectedKeys: '' });

    if (userInfo.userLevel === USER_LEVELS.PARENT) getChildSeq();
    else getModuleAnswer(userInfo.userSeq);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState]);

  useEffect(() => {
    setReviewBookmarkInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    if (childSeqState > 0) getModuleAnswer(childSeqState);
    else if (userInfo.userSeq > 0 && userInfo.userLevel !== USER_LEVELS.PARENT) getModuleAnswer(userInfo.userSeq);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCheckBox.seqStr]);

  useEffect(() => {
    if (childSeqState > 0) getModuleAnswer(childSeqState);
    else if (userInfo.userSeq > 0 && userInfo.userLevel !== USER_LEVELS.PARENT) getModuleAnswer(userInfo.userSeq);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewBookmarkInfo.currentPage]);

  const questionCheck = (item) => {
    window.open(`/student/review?viewQuestion=${encodeURIComponent(item.uthSeqStr + 'GATEPLUS' + item.seqStr)}`, '_blank');
  };

  return (
    <S.Wrap className='com_popup pop_review_notes type_dim active'>
      <div className='pop_container xxl'>
        <section className='pop_header'>
          <h1 className='pop_tit'>Review Note</h1>
          <button className='svg_icon btn_pop_close black' onClick={() => props.setReviewNotesActive(false)}>
            &nbsp;
          </button>
        </section>
        <section className='com_container'>
          <div className='com_tab type_with_box'>{renderTab()}</div>
          <div className='box scroll'>
            {tabState === 0 ? (
              <div className='com_list'>
                <section className='sort_area'>
                  {domainList &&
                    domainList?.map((item, index) => {
                      return (
                        <Fragment key={`rwDomain_${index}`}>
                          <input
                            type='checkbox'
                            className={`btn_sort ${getClassForContentDomain(item.value)}`}
                            onChange={(e) => domainCheck(e, item.value)}
                            id={`short010${index + 1}`}
                          />
                          <label htmlFor={`short010${index + 1}`}>{item.name}</label>
                        </Fragment>
                      );
                    })}
                </section>
                <table className='list'>
                  <colgroup>
                    <col width='53' />
                    <col />
                    <col width='65' />
                    <col width='74' />
                    <col width='250' />
                    <col width='166' />
                    <col width='128' />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Test</th>
                      <th>Module</th>
                      <th>Number</th>
                      <th>Subject Classification</th>
                      <th>Added on</th>
                      <th>View Question</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviewBookmarkInfo.reviewBookmarkList &&
                      reviewBookmarkInfo.reviewBookmarkList?.map((item, index) => (
                        <tr key={item.questionSeq}>
                          <td>{reviewBookmarkInfo.numPerPage * (reviewBookmarkInfo.currentPage - 1) + index + 1}</td>
                          <td>{item.testName}</td>
                          <td className='number_field'>{item.moduleNum}</td>
                          <td className='number_field'>{item.questionOrder}</td>
                          <td className={getClassForContentDomain(item.contentDomain)}>{item.contentDomainName}</td>
                          <td>{isoTimeToDayjs(item.trhModiDate).format('MM / DD / YYYY')}</td>
                          <td>
                            <button className='com_btn_report' onClick={() => questionCheck(item)}>
                              <i className='svg_icon icon_score_report s black'>&nbsp;</i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Paging
                  totalCount={reviewBookmarkInfo.totalCount}
                  currentPage={reviewBookmarkInfo.currentPage}
                  numPerPage={reviewBookmarkInfo.numPerPage}
                  pagePerBlock={reviewBookmarkInfo.pagePerBlock}
                  nextPageMove={nextPageMove}
                />
              </div>
            ) : (
              <div className='com_list'>
                <section className='sort_area'>
                  {domainList &&
                    domainList?.map((item, index) => {
                      return (
                        <Fragment key={`mDomain_${index}`}>
                          <input
                            type='checkbox'
                            className={`btn_sort ${getClassForContentDomain(item.value)}`}
                            onChange={(e) => domainCheck(e, item.value)}
                            id={`short020${index}`}
                          />
                          <label htmlFor={`short020${index}`}>{item.name}</label>
                        </Fragment>
                      );
                    })}
                </section>
                <table className='list'>
                  <colgroup>
                    <col width='53' />
                    <col />
                    <col width='65' />
                    <col width='74' />
                    <col width='250' />
                    <col width='166' />
                    <col width='128' />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Test</th>
                      <th>Module</th>
                      <th>Number</th>
                      <th>Subject Classification</th>
                      <th>Added on</th>
                      <th>View Question</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviewBookmarkInfo.reviewBookmarkList &&
                      reviewBookmarkInfo.reviewBookmarkList?.map((item, index) => (
                        <tr key={item.questionSeq}>
                          <td>{reviewBookmarkInfo.numPerPage * (reviewBookmarkInfo.currentPage - 1) + index + 1}</td>
                          <td>{item.testName}</td>
                          <td className='number_field'>{item.moduleNum}</td>
                          <td className='number_field'>{item.questionOrder}</td>
                          <td className={getClassForContentDomain(item.contentDomain)}>{item.contentDomainName}</td>
                          <td>{isoTimeToDayjs(item.trhModiDate).format('MM / DD / YYYY')}</td>
                          <td>
                            <button className='com_btn_report' onClick={() => questionCheck(item)}>
                              <i className='svg_icon icon_score_report s black'>&nbsp;</i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Paging
                  totalCount={reviewBookmarkInfo.totalCount}
                  currentPage={reviewBookmarkInfo.currentPage}
                  numPerPage={reviewBookmarkInfo.numPerPage}
                  pagePerBlock={reviewBookmarkInfo.pagePerBlock}
                  nextPageMove={nextPageMove}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .com_container {
      background-color: #f4f8ff;
      padding: 1rem;
      border-radius: 0.625rem;
    }
  `,
};
