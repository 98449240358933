import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';

/** 각 메인별 이동 버튼 */
export default function MainLink() {
  const location = useLocation();

  /** 현재 경로에 따라 활성화 시킬 Tab에 className을 부여하는 함수 */
  const isSelected = (type) => (location.pathname.includes(type) ? 'active' : '');

  return (
    <S.Wrap className='com_main_link'>
      <Link to='/com/students' className={`link students ${isSelected('students')}`}>
        <i className='icon'>&nbsp;</i>
        <p className='tit'>STUDENTS</p>
        <p className='txt'>Manage student settings</p>
      </Link>
      <Link to='/com/tests/scheduled' className={`link test ${isSelected('tests')}`}>
        <i className='icon'>&nbsp;</i>
        <p className='tit'>TEST</p>
        <p className='txt'>Manage test settings</p>
      </Link>
      {/* 클릭시 기본 초기값으로 호출 */}
      <Link to={`/com/score/class/${obfuscateUrlParam('0')}`} className={`link score ${isSelected('score')}`}>
        <i className='icon'>&nbsp;</i>
        <p className='tit'>SCORE</p>
        <p className='txt'>See test reports</p>
      </Link>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    &.com_main_link {
      padding-bottom: 3.125rem;
    }
  `,
};
