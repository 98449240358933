import {
  SET_SOCIAL_USER_ID,
  SET_SOCIAL_LOGIN_TYPE,
  SET_SOCIAL_LOGIN_TIME,
  SET_SOCIAL_USER_EMAIL,
  CLEAR_SOCIAL_USER_STATE,
  SET_SOCIAL_USER_NAME,
  SET_SOCIAL_CONFIRM_CODE,
  SET_USER_LEVEL,
} from './actionTypes';

/** 리듀서 전역 상태 액션 : 사용자 아이디 저장 */
export const updateReduxSocialUserId = (userId) => ({
  type: SET_SOCIAL_USER_ID,
  payload: userId,
});

/** 리듀서 전역 상태 액션 : 사용자 로그인 타입 저장 */
export const updateReduxSocialLoginType = (loginType) => ({
  type: SET_SOCIAL_LOGIN_TYPE,
  payload: loginType,
});

/** 사용자 로그인 타임 저장 */
export const updateReduxSocialLoginTime = (loginTime) => ({
  type: SET_SOCIAL_LOGIN_TIME,
  payload: loginTime,
});
/** 사용자 이메일 저장 */
export const updateReduxSocialUserEmail = (userEmail) => ({
  type: SET_SOCIAL_USER_EMAIL,
  payload: userEmail,
});
/** 사용자 이름 저장 */
export const updateReduxSocialUserName = (userName) => ({
  type: SET_SOCIAL_USER_NAME,
  payload: userName,
});
/** 사용자 리듀서 전체 초기화 */
export const clearReduxStateSocialUser = () => {
  return {
    type: CLEAR_SOCIAL_USER_STATE,
  };
};
/** 사용자 레벨 저장 */
export const updateReduxSocialUserLevel = (userLevel) => ({
  type: SET_USER_LEVEL,
  payload: userLevel,
});
/** confirm-code 저장 */
export const updateReduxSocialConfirmCode = (confirmCode) => ({
  type: SET_SOCIAL_CONFIRM_CODE,
  payload: confirmCode,
});
