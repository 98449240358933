/**
 * student sgin up 전역 상태 리덕스 action 타입
 * 001001002000000	Private Tutor
 */
export const SET_PRIVATE_TUTOR_ID = 'SET_PRIVATE_TUTOR_ID';
/** Login 시 login type 저장 */
export const SET_PRIVATE_TUTOR_LOGIN_TYPE = 'SET_PRIVATE_TUTOR_LOGIN_TYPE';
/** email */
export const SET_PRIVATE_TUTOR_USER_EMAIL = 'SET_PRIVATE_TUTOR_USER_EMAIL';
/** mobile */
export const SET_PRIVATE_TUTOR_USER_MOBILE = 'SET_PRIVATE_TUTOR_USER_MOBILE';
/** name */
export const SET_PRIVATE_TUTOR_USER_NAME = 'SET_PRIVATE_TUTOR_USER_NAME';
/** Privacy Policy */
export const SET_PRIVATE_TUTOR_AGREE_PRIVACY_POLICY = 'SET_PRIVATE_TUTOR_AGREE_PRIVACY_POLICY';
/** Terms of Service */
export const SET_PRIVATE_TUTOR_AGREE_TERMS_SERVICE = 'SET_PRIVATE_TUTOR_AGREE_TERMS_SERVICE';
/** News Letter */
export const SET_PRIVATE_TUTOR_AGREE_NEWS_LETTER = 'SET_PRIVATE_TUTOR_AGREE_NEWS_LETTER';
/** tutoring name */
export const SET_PRIVATE_TUTOR_TUTORING_NAME = 'SET_PRIVATE_TUTOR_TUTORING_NAME';
/** all data clear */
export const CLEAR_PRIVATE_TUTOR_STATE = 'CLEAR_PRIVATE_TUTOR_STATE';
