import React from 'react';

import { ReactComponent as IconEmail } from 'assets/gate_member/image/mail_icon.svg';
import SignInputBase from 'components/user/_components/common/forms/inputs/_SignInputBase';

/** 로그인 - "이메일" 입력 창 컴포넌트 */
export default function InputEmail({ register, setValue }) {
  return (
    <SignInputBase
      inputReset={() => {
        setValue('userEmail', '');
      }}>
      <IconEmail className='icon' />
      <label htmlFor='userEmail'>Email</label>
      <input type='text' id='userEmail' {...register} placeholder='Enter your email' />
    </SignInputBase>
  );
}
