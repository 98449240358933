import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import { useLocation } from 'react-router-dom';

////////// components
import Paging from 'layout/Paging'; //페이징
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod'; //팝업 달력
import TestList from 'components/com/tests/_components/_TestListLayer';
import CustomSelect from 'components/_common/inputs/CustomSelect';
import Layout from '../../../_components/_common/Layout';
////////// img
//import ImgAddMoreStudent from "../../assets/img/icon_add_student.svg"

/**
 * AP Set Tests : "학생 선택" 페이지
 * @description
 * 🔍 검색 키워드 - #com/tests/set/selection/student
 */
export default function ApTestsSetSelectStudentPage() {
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 /////////////////
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 /////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const location = useLocation();
  const classSeq = location.state?.value;
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  const userInfo = request.tokenDecoder();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [studentInfo, setStudentInfo] = useState({
    list: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });
  const [searchInfo, setSearchInfo] = useState({
    setSearchField: 'user_name',
    searchKeyword: '',
    orderBy: 'MCH.reg_date DESC',
  });
  /** 체크 박스 */
  const [selectCheckBox, setSelectCheckBox] = useState(nvl(stateSetTest.selectedStudents) !== '' ? stateSetTest.selectedStudents : []);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const searchKeywordRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const nextPageMove = (value) => {
    setStudentInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearchInfo((prev) => {
        return { ...prev, searchKeyword: e.target.value };
      });
    }
  };

  const keywordSearchDo = (e) => {
    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: nvl(searchKeywordRef.current.value) };
    });
  };

  const limitSelect = (value) => {
    setStudentInfo((prev) => {
      return { ...prev, numPerPage: value };
    });
  };

  const getStudentList = () => {
    let pms = {
      userSeq: userInfo?.userSeq,
      managerPosition: userInfo?.managerPosition,
      orderBy: searchInfo.orderBy,
      searchKeyword: searchInfo.searchKeyword,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let studentList = response.result.studentInfo;
        let totalCount = response.result.totalCount;

        setStudentInfo((prev) => {
          return { ...prev, list: studentList, totalCount: totalCount };
        });
      }
    };

    request.get(
      `/api/member/students?orderBy=${pms.orderBy}&searchField=user_name&searchKeyword=${encodeURIComponent(pms.searchKeyword)}&classSeq=${nvl(stateSetTest.selectedClass.classSeq) !== '' ? stateSetTest.selectedClass.classSeq : 0}`,
      null,
      successHandler
    );
  };

  const studentListReload = () => {
    setStudentInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getStudentList();
  };

  /** 체크박스 동작 함수 */
  const handleCheckBox = (event, student) => {
    const { checked } = event.target;
    if (checked) {
      setSelectCheckBox(selectCheckBox.concat(student));
    } else {
      const updatedSelectedKeys = selectCheckBox.filter((item) => item.userSeq !== student.userSeq);
      setSelectCheckBox(updatedSelectedKeys);
    }
  };

  /** 체크박스 상태 값에 모든 체크박스가 선택되어 있는지 확인 */
  const getIsAllChecked = () => {
    if (nvl(studentInfo.list) !== '') {
      /** uthSeq 배열 생성 */
      const checkedSeq = studentInfo.list?.map((item) => item.userSeq);
      /** 모든 요소가 포함되어 있는지 확인 */
      const isAllCheckedIncluded = checkedSeq.every((seq) => selectCheckBox.map((item) => item.userSeq).includes(seq));

      return isAllCheckedIncluded;
    }
  };

  /** 체크 박스 전체 선택 동작 함수 */
  const handleAllCheck = (checked) => {
    if (studentInfo.list && studentInfo.list.length > 0) {
      if (checked) {
        const idArray = [];
        for (let i = 0; i < studentInfo.list.length; i++) {
          idArray.push(studentInfo.list[i]);
        }
        const updatedSelectedKeys = [...selectCheckBox.concat(idArray)];
        // eslint-disable-next-line no-undef
        const setList = [...new Set(updatedSelectedKeys)];

        setSelectCheckBox(setList);
      } else {
        setSelectCheckBox([]);
      }
    }
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    getStudentList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo.currentPage]);

  useEffect(() => {
    studentListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo.numPerPage, searchInfo.searchKeyword]);

  useEffect(() => {
    if (classSeq !== undefined && selectCheckBox.length === 0) {
      setSelectCheckBox(studentInfo.list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <Layout selectedStudents={selectCheckBox}>
      <article className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_ol black'>&nbsp;</i>Student List
        </h2>
      </article>
      <article className='com_list'>
        <section className='top'>
          <article className='search_area full'>
            {/* <PopupCalendarPeriod tit="Select Date"/> */}
            <div className='search'>
              <input type='text' placeholder='Enter keywords' className='input search_area_input' ref={searchKeywordRef} onKeyUp={handleOnKeyPress} />
              <button className='com_btn m blue btn_search' onClick={keywordSearchDo}>
                <i className='svg_icon icon_search blue before'></i>
                SEARCH
              </button>
            </div>
            <CustomSelect
              options={[
                { label: '10 line', value: 10 },
                { label: '25 line', value: 25 },
                { label: '50 line', value: 50 },
                { label: '100 line', value: 100 },
              ]}
              onSelect={limitSelect}
            />
          </article>
        </section>
        <table className='list'>
          <colgroup>
            <col width='56px' />
            <col width='66px' />
            <col width='104px' />
            <col width='64' />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>
                <input
                  type='checkbox'
                  className='com_chk'
                  id='chkAll'
                  checked={studentInfo.list && studentInfo.list.length > 0 ? getIsAllChecked() : false}
                  onChange={(e) => handleAllCheck(e.target.checked)}
                />
                <label htmlFor='chkAll'>&nbsp;</label>
              </th>
              <th>No.</th>
              <th>Name</th>
              <th>Grade</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {studentInfo.list && studentInfo.list.length > 0 ? (
              studentInfo.list
                .slice((studentInfo.currentPage - 1) * studentInfo.numPerPage, studentInfo.currentPage * studentInfo.numPerPage)
                .map((item, index) => (
                  <tr key={`tr_${index}`} className={selectCheckBox.map((item) => item.userSeq).includes(item.userSeq) ? 'active' : ''}>
                    <td>
                      <input
                        type='checkbox'
                        className='com_chk'
                        id={`student_${index + 1}`}
                        onChange={(event) => handleCheckBox(event, item)}
                        checked={selectCheckBox.map((item) => item.userSeq).includes(item.userSeq)}
                      />
                      <label htmlFor={`student_${index + 1}`}>&nbsp;</label>
                    </td>
                    <td>{studentInfo.totalCount - studentInfo.numPerPage * (studentInfo.currentPage - 1) - index}</td>
                    <td className='tit_field alignL'>
                      <p className='ellipsis'>{item.userName}</p>
                    </td>
                    <td>{item.studentGrade}</td>
                    <td className='alignL'>
                      <p className='ellipsis'>{item.userEmail}</p>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan='5'>No results. Please make sure you have searched correct keyword.</td>
              </tr>
            )}
          </tbody>
        </table>
        <Paging
          totalCount={studentInfo.totalCount}
          currentPage={studentInfo.currentPage}
          numPerPage={studentInfo.numPerPage}
          pagePerBlock={studentInfo.pagePerBlock}
          nextPageMove={nextPageMove}
        />
      </article>
    </Layout>
  );
}
