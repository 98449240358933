import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import dayjsTZ from 'utils/functions/time/dayjs-config';
import SessionStorage from 'utils/SessionStorage.utils';

import MiniSvgIconBtn from 'components/_common/button/MiniSvgIconBtn';
// import SvgIconTextBtn from 'components/_common/button/SvgIconTextBtn';
import PopClassManage from '../../popup/_PopClassManage';
import DeleteModal from 'components/_common/modals/DeleteModal';
import MiniAlert from 'components/_common/alerts/MiniAlert';
import PopSuccessfullyDeleted from '../../popup/_PopSuccessfullyDeleted';

import { ReactComponent as ImgSvgIconPlus } from 'assets/img/svg/icon_plus.svg';
import { ReactComponent as ImgSvgIconDownload } from 'assets/img/svg/icon_download_01.svg';
import { ReactComponent as ImgSvgIconPrinter } from 'assets/img/svg/icon_printer_01.svg';
import { ReactComponent as ImgSvgIconTrash } from 'assets/img/svg/icon_trash_01.svg';

/** 아카데미 대시보드 - Students - Classes 페이지의 대표 Table 상단 "버튼" 영역 컴포넌트 */
export default function TopBtnArea({
  setEditClassInfo,
  setOpenClassManage,
  checkedClassIds,
  setAlertLayerPopup,
  searchInfo,
  searchKeyword,
  searchData,
  searchClasses,
  handleShowEdit,
  openClassManage,
  editClassInfo,
}) {
  const [userInfo, _] = useState(request.tokenDecoder());
  /** Download 버튼 코드 */
  const [btnDownload, setBtnDownload] = useState(false);
  /** Print 버튼 코드 */
  const [btnPrint, setBtnPrint] = useState(false);
  /** Class 삭제 팝업 */
  const [deleteClass, setDeleteClass] = useState(false);
  /** Class 삭제 confirm 팝업 */
  const [deleteClassAlert, setDeleteClassAlert] = useState(false);
  const [allSearchData, setAllSearchData] = useState([]);
  const [actionType, setActionType] = useState('');
  const [alert, setAlert] = useState({ active: false, message: '' });

  /** 오늘 날짜를 문자열로 return 하는 함수 */
  const getTodayDate = (value) => {
    const now = dayjsTZ();
    const formats = {
      time: 'YYYY. MM. DD. HH : mm',
      hyphen: 'YYYY-MM-DD',
      default: 'YYYY년 MM월 DD일',
    };
    const week = ['일', '월', '화', '수', '목', '금', '토'];

    const formatted = now.format(formats[value] || formats.default);
    return value === 'default' ? `${formatted} (${week[now.day()]})` : formatted;
  };

  const handleClassManageOpen = (boolean) => {
    setEditClassInfo(null);
    setOpenClassManage(boolean);
  };

  const handleDeleteBtn = () => {
    if (checkedClassIds.length > 0) {
      setDeleteClass(true);
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the student you want to delete.',
        };
      });
    }
  };

  const toggleBtnDownload = () => {
    setBtnDownload((prevState) => !prevState);
    setBtnPrint(false);
  };

  const excelDownloadSelect = () => {
    if (checkedClassIds.length === 0) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please select classes you want to download or print',
        };
      });
    } else {
      let filename = 'Selected classes' + userInfo.companyName + '_' + getTodayDate('hyphen'); // 파일명
      const classList = checkedClassIds.join('|');
      request.downloadFile(`/api/academy/classes/excel?ecSeq=${userInfo.ecSeq}&seqStr=${encodeURI(classList)}`, filename);
    }
    setBtnDownload(false);
  };

  /** 전체 데이터 호출하기 */
  const getAllData = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const allSearchData = response.result.classesList.map((data) => {
          return {
            classId: data.classSeq,
            classTitle: data.className,
            startDate: data.classStartDate.split(' ')[0],
            endDate: data.classEndDate.split(' ')[0],
            studentList: data.studentList ? JSON.parse(data.studentList) : [],
            testScheduled: data.testHistoryScheduled ? JSON.parse(data.testHistoryScheduled) : [],
            testTaken: data.testHistoryTaken ? JSON.parse(data.testHistoryTaken) : [],
          };
        });
        setAllSearchData((prev) => (prev = allSearchData));
      }
    };

    const searchStartDate = encodeURIComponent(dayjsTZ(searchInfo.searchStartDate).startOf('day').format());
    const searchEndDate = encodeURIComponent(dayjsTZ(searchInfo.searchEndDate).endOf('day').format());

    if (!searchInfo.isDateRangeSelected && !searchKeyword) {
      request.get(`/api/academy/classes?ecSeq=${userInfo.ecSeq}`, null, successHandler);
    } else if (searchInfo.isDateRangeSelected && searchKeyword === '') {
      request.get(`/api/academy/classes?ecSeq=${userInfo.ecSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`, null, successHandler);
    } else {
      request.get(
        `/api/academy/classes?ecSeq=${userInfo.ecSeq}&searchKeyword=${searchKeyword}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`,
        null,
        successHandler
      );
    }
  };

  const excelDownloadAll = () => {
    setActionType('download');
    if (userInfo.ecSeq > 0) getAllData();

    setBtnDownload(false);
  };

  const toggleBtnPrint = () => {
    setBtnPrint((prevState) => !prevState);
    setBtnDownload(false);
  };

  const printAll = () => {
    setActionType('print');
    if (userInfo.ecSeq > 0) getAllData();

    setBtnPrint(false);
  };

  const printSelect = () => {
    if (checkedClassIds.length === 0) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please select classes you want to download or print',
        };
      });
    } else {
      SessionStorage.clearItem('printCard');
      const selectClassesList = searchData.filter((item) => checkedClassIds.includes(item.classId));
      SessionStorage.setItem('printCard', 'classes', selectClassesList);

      window.open('/com/classes/print', '_blank');
    }
    setBtnPrint(false);
  };

  const deleteClassAlertOpen = () => {
    setDeleteClass(false);
    setDeleteClassAlert(false);

    const successHandler = (response) => {
      if (response.code === 200) {
        // console.log(response)
        searchClasses();
        setAlert({
          active: true,
          message: 'Successfully deleted selected class.',
        });
      }
    };

    const classList = checkedClassIds.join('|');
    request.del(`/api/academy/classes?ecSeq=${userInfo.ecSeq}&seqStr=${encodeURI(classList)}`, null, successHandler);
  };

  useEffect(() => {
    if (allSearchData.length > 0) {
      if (actionType === 'print') {
        const printData = allSearchData.map(({ testScheduled, testTaken, ...selectedData }) => selectedData);

        SessionStorage.clearItem('printCard');
        SessionStorage.setItem('printCard', 'classes', printData);

        window.open('/com/classes/print', '_blank');
      }

      if (actionType === 'download') {
        let filename = 'Selected classes' + userInfo.companyName + '_' + getTodayDate('hyphen'); // 파일명
        const classList = allSearchData.map((data) => data.classId).join('|');
        request.downloadFile(`/api/academy/classes/excel?ecSeq=${userInfo.ecSeq}&seqStr=${encodeURI(classList)}`, filename);
      }
    }

    setActionType('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSearchData]);

  return (
    <>
      <S.Wrap className='btn_area'>
        <button type='button' title='Add Student' className='btn_add_student' onClick={() => handleClassManageOpen(true)}>
          <ImgSvgIconPlus />
          Create Class
        </button>
        {/* <SvgIconTextBtn
          text='Create Class'
          svgClassName='svg_icon icon_person_add blue'
          bgColor='#DFF1FF'
          color='#4692D1'
          borderColor='none'
          onClick={() => handleClassManageOpen(true)}
        /> */}

        {/* <MiniSvgIconBtn text='Edit' svgClassName='svg_icon icon_write' onClick={() => handleShowEdit()} radius='medium' dataHover /> */}

        <div className='com_btn_drop'>
          <button type='button' title='Download' onClick={toggleBtnDownload} className='btn_icon'>
            <ImgSvgIconPrinter />
          </button>
          <div className={`layer ${btnDownload ? 'active' : ''}`}>
            <button className='btn' onClick={excelDownloadSelect}>
              <i className='svg_icon icon_person_select'>&nbsp;</i>
              Selected Classes
            </button>
            <button className='btn' onClick={excelDownloadAll}>
              <i className='svg_icon icon_person_all'>&nbsp;</i>All Classes
            </button>
          </div>
        </div>

        <div className='com_btn_drop'>
          <button type='button' title='Print' onClick={toggleBtnPrint} className='btn_icon'>
            <ImgSvgIconDownload />
          </button>
          <div className={`layer ${btnPrint ? 'active' : ''}`}>
            <button className='btn' onClick={printSelect}>
              <i className='svg_icon icon_person_select'>&nbsp;</i>
              Selected Classes
            </button>
            <button className='btn' onClick={printAll}>
              <i className='svg_icon icon_person_all'>&nbsp;</i>All Classes
            </button>
          </div>
        </div>

        <button type='button' title='Delete' onClick={handleDeleteBtn} className='btn_icon'>
          <ImgSvgIconTrash />
        </button>
      </S.Wrap>

      {/*반관리 팝업*/}
      {openClassManage && (
        <PopClassManage handleClassManageOpen={handleClassManageOpen} editClassInfo={editClassInfo} searchClasses={searchClasses} setAlert={setAlert} />
      )}

      {/* 학생 삭제 팝업 */}
      {deleteClass && (
        <DeleteModal
          hideModal={() => setDeleteClass(false)}
          callback={deleteClassAlertOpen}
          text={'The data for this class will still be available \n but will not be linked together.'}
        />
      )}

      <PopSuccessfullyDeleted deleteClassAlert={deleteClassAlert} setDeleteClassAlert={setDeleteClassAlert} />

      {alert.active && <MiniAlert text={alert.message} active={alert.active} inactive={() => setAlert({ ...alert, active: false })} timeOut={2000} />}
    </>
  );
}

const S = {
  Wrap: styled.article`
    button {
      transition: 0.2s;
      &:hover {
        filter: brightness(95%);
      }
    }
    .btn_add_student {
      background-color: #f4f8ff;
      color: #0068bd;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.5rem;
      padding: 0.5rem 0.625rem;
      font-weight: 400;
      font-size: 0.875rem;
      svg {
        width: 1.125rem;
      }
    }
    .btn_icon {
      border: 1px solid #eaeaea;
      border-radius: 0.5rem;
      width: 2rem;
      height: 2rem;
      transition: 0.2s;
      background-color: #ffffff;
      svg {
        width: 1rem;
        color: #4b5563;
      }
    }
  `,
};
