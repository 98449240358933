import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import request from 'utils/Request.utils';

//components
import Lnb from 'layout/Lnb';
import Gnb from 'layout/Gnb'; //Gnb
import Footer from 'layout/Footer'; //Footer
import Profile from 'components/user/profile/Page';

const TutorRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    if (authBool) {
      // 로그인 한 사람만 사용
      const authRoutes = [
        // { path: '/dashboard', element: <Dashboard /> }, // component 수정해야 함
        { path: '/profile', element: <Profile /> }, // component 수정해야 함

        { path: '*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unauthRoutes = [
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unauthRoutes);
    }
  };

  const PathStudents = pathname.toLowerCase().startsWith('/student/dashboard');
  const PathReview = pathname.toLowerCase().startsWith('/student/review');
  const PathStudentsList = pathname.toLowerCase().startsWith('/student');
  const PathProfile = pathname.toLowerCase().startsWith('/tutor/profile');

  const renderLnb = () => {
    //경로에 따른 lnb 노출 설정
    if (PathStudents || PathStudentsList || PathProfile) return <Lnb />;
    else return <></>;
  };

  const renderFooter = () => {
    //경로에 따른 Footer 노출 설정
    if (PathReview) return <></>;
    else return <Footer />;
  };

  return (
    <>
      <Gnb />
      {renderLnb()}
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      {renderFooter()}
    </>
  );
};

export default TutorRoute;
