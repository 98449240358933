import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//img
import ImgGnbMessage from 'assets/img/gnb_message.png';
import ImgGnbPush from 'assets/img/gnb_push.png';
import ImgGnbTest from 'assets/img/gnb_test.png';
import ImgIconDashboard from 'assets/img/logo.png';
import noInternetIcon from 'assets/img/svg/no_internet_icon.svg';

// hook
import useOnlineStatus from 'hooks/useOnlineStatus.js';
import useUserLevels from 'hooks/useUserLevels.js';
// components
import CustomAlert from 'components/_common/alerts/CustomAlert';
import ImageModal from 'components/_common/modals/ImageModal';
import useChannelTalk from 'hooks/useChannelTalk.js';
import Notification from 'layout/Notification';
import GateKeyBtn from './_gnb-component/gateKeyPopup/GateKeyBtn';
import UserProfileBtn from './_gnb-component/userProfile/UserProfileBtn';
import DebugTimezone from './_gnb-component/DebugTimezone';

import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import clearAppData from 'utils/clearAppData.js';

import SessionStorage from 'utils/SessionStorage.utils';
import ImportantAnnouncementModal from 'components/_common/modals/ImportantAnnouncementModal';
// import EmergencyWarningModal from 'components/_common/modals/EmergencyWarningModal'; // 사용안하는 긴급공지 가림 - 24.7.22
import { fetchGetRemainingKeyInBToB, fetchGetRemainingKeyInBToC } from 'utils/functions/fetch/getRemainingKey';
import { updateGateKeyAmount } from 'reducers/gateKeys/action';
import FreezingKeyModal from 'components/_common/modals/FreezingKeyModal';
import LoadingBar from 'utils/LoadingBar';
import localStorage from 'utils/LocalStorage.utils';
import ModalChuseok from 'components/_common/modals/ModalChuseok';

import db from '../firebase.js';
import { doc, onSnapshot } from 'firebase/firestore';
import jwt_decode from 'jwt-decode';

import dayjsTZ from 'utils/functions/time/dayjs-config';

/** 메인 Header 컴포넌트 */
export default function Header(props) {
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 /////////////////
  const alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  let { pathname } = window.location;
  const windowManager = SessionStorage.getItemJsonParse('windowManager');

  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 /////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userSession = localStorage.getItemJsonParse('userSession');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo?.userLevel);
  const isB2c = useLevels.isStudent; // || useLevels.isParent;
  const stateMenuManager = useSelector((state) => state.MenuManager);
  /** 오프라인시 유저 로그아웃 */
  const isOnline = useOnlineStatus();
  const displayAcademyName = stateMenuManager?.academyName || userInfo?.companyName || 'UNKNOWN';
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** alertLayerPopup 코드 */
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  /** 스크롤 시 addClass */
  const [scroll, setScroll] = useState(false);
  /** user layer 노출,비노출 */
  const [userLayer, setUserLayer] = useState(false);
  /** Notifications 레이어 노출 */
  const [notification, setNotification] = useState(false);
  const [unseenNotificationsCnt, setUnseenNotificationsCnt] = useState(0);
  /** 유저의 남은 Key 가져오기 */
  const [remainingKey, setRemainingKey] = useState(0);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const intervalRef = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      if (alertLayerPopup.activateMode === 'testLogout') {
        clearAppData(dispatch, userInfo?.userId);

        window.location.href = '/';
      }
    }
  };

  const logout = (offline = false) => {
    if (offline) {
      clearAppData(dispatch, userInfo?.userId);

      window.location.href = '/signin';
    } else {
      if (windowManager?.popupOn === true)
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            alertType: 'confirm',
            activateMode: 'testLogout',
            visible: true,
            alertMessage: 'Test is in progress. Are you sure you want to log out? Your test window will also close.',
          };
        });
      else {
        clearAppData(dispatch, userInfo?.userId);

        window.location.href = '/signin';
      }
    }
  };

  const logoClick = () => {
    if (useLevels.isAcademy)
      navigate('/academy/dashboard'); // 학원 관리자
    else if (useLevels.isStudent)
      navigate('/student/dashboard'); // 학색
    else if (useLevels.isTeacher)
      navigate('/academy/dashboard'); // 선생님
    else if (useLevels.isTutor)
      navigate('/academy/dashboard'); // 강사
    else navigate('/parent/dashboard'); // 학부모
  };

  function renderGnb() {
    let text;
    if (pathname.includes('/dashboard')) text = 'DASHBOARD';
    else if (pathname === '/com/students') text = 'STUDENTS';
    else if (pathname === '/com/tests/taken') text = 'TEST TAKEN';
    else if (pathname === '/com/tests/scheduled') text = 'TEST SCHEDULED';
    else if (pathname.includes('/tests/set/')) text = 'SET TEST';
    else if (pathname === '/com/tests/calendar') text = 'CALENDAR';
    else if (pathname === '/com/students/classes') text = 'CLASSES';
    else if (pathname.startsWith('/com/score')) text = 'SCORE';
    else if (pathname.includes('/gateplus/news')) text = 'GATE⁺ NEWS';
    else if (pathname.includes('/dsat')) text = 'SAT INFO';
    else if (pathname.includes('/com/tests/status')) text = 'TEST STATUS';
    else if (pathname.includes('/profile')) text = 'PROFILE';
    else text = 'GATE PLUS';

    return text;
  }

  const notificationPop = (e) => {
    e.stopPropagation();

    setNotification(!notification);
  };

  /** 읽지 않은 알람 수 확인 */
  const getUnseenNotifications = () => {
    // console.log("호출 확인")
    const successHandler = (response) => {
      if (response.code === 200) {
        let notiList = response.result.notificationList;
        let gloNoti = response.result.globalNotificationList;

        let notiCnt = notiList.filter((i) => nvl(i.notiStatus) === '').length + gloNoti.filter((i) => nvl(i.notiStatus) === '').length;

        setUnseenNotificationsCnt(notiCnt);
      }
    };

    request.get(`/api/etc/notifications?userSeq=${userInfo?.userSeq}&userLevel=${userInfo?.userLevel}`, null, successHandler).catch((error) => {
      console.log('GNB Notification 에러 발생! : ', error);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    });
  };

  /** 유저가 가진 key를 불러오는 함수 */
  const getRemainingKey = (isB2c) => {
    /** API 요청 성공 시 CallBack 함수 */
    const successHandler = (response) => {
      if (response.code === 200) {
        const remainingKey = isB2c ? response.result.b2cGateKeyAmount : response.result.gateKeyAmount;

        setRemainingKey(remainingKey === 0 ? 0 : remainingKey);
        dispatch(updateGateKeyAmount(remainingKey));
      }
    };
    /** API 요청 실패 시 CallBack 함수 */
    const errorHandler = (error) => {
      console.log('GNB KeyData 에러 발생! : ', error);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    };

    isB2c ? fetchGetRemainingKeyInBToC(successHandler, errorHandler) : fetchGetRemainingKeyInBToB(successHandler, errorHandler);
  };

  const offlineModalInfo = {
    modalImage: noInternetIcon,
    alertTitle: 'Unstable network connection',
    alertText:
      'Your network connection is low or unstable.\nYou will now move to sign in page.\nYou can continue using our service when your network becomes stable.',
    buttonTextAndOnClick: [{ text: 'OK', onClick: () => logout(true) }],
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// 기타 hook등 사용 영역 시작 ///////////////////////
  // 채널톡 활성화 코드
  useChannelTalk(userInfo);
  /////////////////// 기타 hook등 사용 영역 끝 ///////////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });

    if (nvlNumber(userInfo?.userSeq) > 0) {
      getUnseenNotifications();

      intervalRef.current = setInterval(() => {
        getUnseenNotifications();
        getRemainingKey();
      }, 10000);
    }

    return () => {
      clearInterval(intervalRef.current);

      intervalRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!useLevelsIsLoading) {
      getRemainingKey(isB2c);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLevelsIsLoading, useLevels]);

  /////////////////// React useEffect 영역 끝 ////////////////////////

  // set test 페이지로 이동
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /** 닫기 버튼 */
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const goToSetTest = () => {
    let url = isB2c ? '/com/tests/set/selection/config' : '/com/tests/set/selection/type';
    if (!isB2c) {
      setIsLoading(true);

      const successHandler = (response) => {
        // console.log('🚀 ~ successHandler ~ response:', response);
        if (response.code === 200) {
          const { isFreezing } = response.result;

          setIsLoading(false);

          if (!isFreezing) navigate(url);
          else setShowPopup(true);
        }
      };

      request.get('/api/academy/gatekey/freezing', null, successHandler).catch((error) => {
        console.log('key freezing 에러 발생! : ', error);
      });
    } else navigate(url);
  };

  const [firebaseInfo, setFirebaseInfo] = useState({
    ec_seq: 0,
    user_level: '',
    isGet: false,
  });

  useEffect(() => {
    const fetchUsers = async () => {
      if (!userInfo?.userSeq) return;

      const userDocRef = doc(db, 'users', String(userInfo.userSeq));
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const { ec_seq, user_level } = data;
          setFirebaseInfo({ ec_seq, user_level, isGet: true });
        } else {
          console.log('No such document!');
        }
      });

      return () => {
        unsubscribe();
      };
    };

    fetchUsers();
  }, [userInfo?.userSeq]);

  useEffect(() => {
    // console.log('🚀 ~ useEffect ~ firebaseInfo.ec_seq, userInfo.ecSeq:', firebaseInfo.ec_seq, userInfo.ecSeq, firebaseInfo.isGet);

    if (firebaseInfo.isGet && firebaseInfo.ec_seq !== userInfo.ecSeq) {
      const successHandler = (response) => {
        // console.log('🚀 ~ successHandler ~ response:', response);
        if (response.code === 200) {
          const { accessToken, refreshToken } = response.result;

          localStorage.setItem('userSession', 'accessToken', accessToken);
          localStorage.setItem('userSession', 'refreshToken', refreshToken);
          setUserInfo(jwt_decode(accessToken));
        }
      };

      request.post('/api/member/token/refresh', { refreshToken: userSession?.refreshToken }, successHandler).catch((error) => {
        console.log('refresh token error! : ', error);
      });
    }
  }, [firebaseInfo, userInfo, userSession]);

  return (
    <header className={scroll ? 'header bg' : 'header '}>
      {/* <ModalChuseok /> */}
      <ImportantAnnouncementModal />
      {/* 사용안하는 긴급공지 가림 - 24.7.22 */}
      {/* <EmergencyWarningModal /> */}
      <div className='com_center_wrap'>
        <div className='pg_tit__wrap'>
          {props.children}
          <button className='icon_area' onClick={logoClick}>
            <img src={ImgIconDashboard} alt='아이콘' className='icon' />
          </button>
          <h1 className='pg_tit'>{renderGnb()}</h1>

          {/* 개발중 타인존 확인을 위한 디버깅용 요소 */}
          <DebugTimezone />
        </div>
        <nav className='nav'>
          {
            // parent 일때 랜더링X
            !useLevels.isParent && userInfo?.ecSeq !== 0 && (
              <button className='menu' onClick={logoClick}>
                <div className='academy-icon icon com_profile_img T'>
                  {nvlNumber(userInfo?.academyUpFileSeq) > 0 || nvl(stateMenuManager?.academyProfileImage) !== '' ? (
                    nvl(stateMenuManager?.academyProfileImage) !== '' ? (
                      <img className='profile-img-background' src={stateMenuManager?.academyProfileImage} alt='Your Academy' />
                    ) : (
                      <img
                        className='profile-img-background'
                        src={`${process.env.REACT_APP_API_URL}/api/common/profile/upload/academyProfile/${userInfo?.ecSeq}?${Date.now()}`}
                        alt='Your Academy'
                      />
                    )
                  ) : (
                    <span className='name'>{displayAcademyName[0]}</span>
                  )}
                </div>
                <p className='tit'>{displayAcademyName}</p>
              </button>
            )
          }
          {useLevels.isParent || (
            <button className='menu' onClick={goToSetTest}>
              {/*Academy Admin, Tutor*/}
              <img src={ImgGnbTest} alt='Set Tests' className='icon' />
              <p className='tit'>Set Tests</p>
            </button>
          )}
          {/* GATE KEY 코드 */}
          {useLevels.isParent || <GateKeyBtn remainingKey={remainingKey} isB2c={isB2c} />}
          <button className='menu' onClick={(e) => notificationPop(e)}>
            {/*Academy Admin, Student, Parent Dashboard, Tutor*/}
            {unseenNotificationsCnt > 0 && <span className='num'>{unseenNotificationsCnt}</span>}
            <img src={ImgGnbPush} alt='아이콘' className='icon' />
            <p className='tit'>Notifications</p>
          </button>
          {notification > 0 && <Notification notification={notification} close={() => setNotification(false)} />}
          {/* 메세지 기능 잠정 보류 */}
          {/* <div className='message_container'>
            <button className='menu' onClick={() => setShowMessagePopup(true)}>
              <img src={ImgGnbMessage} alt='아이콘' className='icon' />
              <p className='tit'>Message</p>
            </button>
            {showMessagePopup && <MessageContainer />}
          </div> */}
          <button
            className='menu'
            onClick={() =>
              setAlertLayerPopup((prev) => {
                return {
                  ...prev,
                  visible: true,
                  alertMessage: 'Please stay tuned for future updates… it’s coming soon!',
                };
              })
            }>
            {/*Academy Admin, Student, Parent Dashboard, Tutor*/}
            <img src={ImgGnbMessage} alt='아이콘' className='icon' />
            <p className='tit'>Message</p>
          </button>
          <UserProfileBtn setAlertLayerPopup={setAlertLayerPopup} />
        </nav>
      </div>

      {/* --- --- --- alert & modal 랜더링 부분 --- --- --- */}
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
      {!isOnline && (
        <ImageModal
          modalImage={offlineModalInfo.modalImage}
          buttonTextAndOnClick={offlineModalInfo.buttonTextAndOnClick}
          alertTitle={offlineModalInfo.alertTitle}
          alertText={offlineModalInfo.alertText}
        />
      )}
      {showPopup && <FreezingKeyModal hideModal={handleClosePopup} />}
      {isLoading && <LoadingBar type={'spin'} color={'#000000'} />}
    </header>
  );
}
