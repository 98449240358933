import { useState } from 'react';
import DirectionsPopup from './DirectionsPopup';
import Timer from './Timer';

/** ap시험 화면 Header 영역 컴포넌트 */
export default function ExamHeader({ stateExamInfo, setStateExamInfo, isDirectionsPopup, setIsDirectionsPopup }) {
  const [isTimerHide, setIsTimerHide] = useState(false);

  const [isMore, setIsMore] = useState(false);

  return (
    <section className='pop_header'>
      <div className='tit_area'>
        <div className='tit'>{stateExamInfo.sessionName}</div>
        <button className='btn' onClick={() => setIsDirectionsPopup((prev) => !prev)}>
          Directions<i className='svg_icon icon_arrow black'></i>
        </button>

        {isDirectionsPopup && <DirectionsPopup setIsDirectionsPopup={setIsDirectionsPopup} />}
      </div>
      <div className={`time_area ${isTimerHide ? 'hide' : ''}`}>
        <div className={`time ${stateExamInfo.isUnderFiveMinutes ? 'end' : ''}`}>
          <Timer variant='exam' setStateExamInfo={setStateExamInfo} />
        </div>
        <button className='btn_time' onClick={() => setIsTimerHide((prev) => !prev)}>
          Hide
        </button>
      </div>
      <div className='btn_area'>
        {/* <button className={`btn`} onClick={(e) => console.log('클릭')}>
          <i className='svg_icon icon_annotate'>&nbsp;</i>
          Annotate
          {stateExamComponents.annotationEmptyDisplay && (
            <div className='tip_layer' ref={annotationEmptyTooltipRef}>
              <div className='tit'>MAKE A SELECTION FIRST</div>
              Select some text, then press annotate.
            </div>
          )} 
        </button> */}
        <button
          className={`btn`}
          onClick={() => {
            setIsMore((prev) => !prev);
          }}>
          <i className='svg_icon icon_more'>&nbsp;</i>
          More
        </button>
        {isMore && (
          <div className='more_layer'>
            <button className='item' onClick={() => console.log('했겠냐?')}>
              <i className='svg_icon icon_help'>&nbsp;</i>Help
            </button>
            <button className='item' onClick={() => console.log('했겠냐?')}>
              <i className='svg_icon icon_exit'>&nbsp;</i>Exit the Exam
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
