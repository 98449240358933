import React from 'react';
import styled from 'styled-components';

import Box from '../../common/styles/Box';
import SectionTitle from '../../common/styles/SectionTitle';

/** ap/com/score 페이지의 "Raw Score" 섹션 컴포넌트 */
export default function RowScoreSection({ scoreListData = [], className = '', ...rest }) {
  return (
    <S.Wrap className={`${className}`} {...rest}>
      <Box className='main_box'>
        <SectionTitle>Raw Score</SectionTitle>
        <div className='contents_area'>
          {scoreListData.map((sectionItem, index) => {
            return (
              <div key={`key_${index}`} className='item'>
                <h5 className='tit_section'>{sectionItem.sectionName}</h5>
                <div className='parts_list_wrap'>
                  {sectionItem.partData.map((partItem, index, array) => {
                    return (
                      <React.Fragment key={`key_${index}`}>
                        <div className='part_wrap'>
                          <h6 className='tit_part'>{partItem.partName}</h6>
                          <div className='score_wrap'>
                            <span>{partItem.partRawScore}</span>/{partItem.partTotalScore}
                          </div>
                        </div>
                        {array[index + 1] && <hr className='divide_line' />}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    .main_box {
      padding: 1.25rem 1.5rem;
      height: 100%;
    }

    .contents_area {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 0.75rem;
      & > .item {
        display: flex;
        align-items: start;
        gap: 2rem;
        .tit_section {
          color: #000;
          font-family: Roboto;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.25rem;
        }

        .parts_list_wrap {
          display: flex;
          justify-content: left;
          gap: 2rem;

          .divide_line {
            width: 1px;
            height: 4.75rem;
            border: none;
            background-color: #dddddd;
          }
        }
        .tit_part {
          color: #767676;
          font-family: Roboto;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.25rem;
          height: 1.25rem;
        }
        .score_wrap {
          color: #999;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.75rem;
          span {
            color: #111;
            font-size: 3.5rem;
            font-weight: 500;
            line-height: 100%;
            padding-right: 0.5rem;
          }
        }
      }
    }
  `,
};
