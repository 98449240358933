import { useState, useEffect } from 'react';

import { USER_LEVELS } from '../utils/constants';

/** 사용자 유형 얻어오는 Hook */
export default function useUserLevels(userLevel) {
  const [isLoading, setIsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    isStudent: false,
    isTeacher: false,
    isTutor: false,
    isAcademy: false,
    isParent: false,
  });

  useEffect(() => {
    const newPermissions = {
      isStudent: userLevel === USER_LEVELS.STUDENT,
      isTeacher: userLevel === USER_LEVELS.TEACHER,
      isTutor: userLevel === USER_LEVELS.TUTOR,
      isAcademy: userLevel === USER_LEVELS.ACADEMY,
      isParent: userLevel === USER_LEVELS.PARENT,
    };

    setPermissions(newPermissions);
    setIsLoading(false);
  }, [userLevel]);

  return { permissions, isLoading };
}
