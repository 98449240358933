import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import LocalStorage from 'utils/LocalStorage.utils';
import SessionStorage from 'utils/SessionStorage.utils';
import clearAppData from 'utils/clearAppData';
import LoadingBar from 'utils/LoadingBar';
import { USER_LEVELS } from 'utils/constants';

import IconDel from 'assets/img/x-circle_icon.svg';
import IconKey from 'assets/img/key_icon.svg';

//component
import Header from 'components/user/_Header';
import CustomAlert from 'components/_common/alerts/CustomAlert';
import AgreeAccountCreatedByThirdPerson from 'components/user/sign-in/_components/AgreeAccountCreatedByThirdPerson';
import EmailLoginModal from './_components/modals/EmailLoginModal';

/** "로그인" 페이지 */
export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userInfo = request.tokenDecoder();

  /** 외부 도메인의 로그인 요청인가? 여부 */
  const isExternalSign = (location.state && location.state.isExternalSign && location.state.lastRoute && location.state.buttonType) ?? false;

  const query = new URLSearchParams(window.location.search);
  const confirmCode = query.get('confirm-code');

  const etcSession = LocalStorage.getItemJsonParse('etcSession');

  const [userPasswdFailCnt, setUserPasswdFailCnt] = useState(0);
  const [clickedValue, setClickedValue] = useState(false);
  const [loadingBarActive, setLoadingBarActive] = useState(false);
  /** 학원이 대리 가입 시킨 학생 계정이 첫 로그인 하는 상황인가? */
  const [isAgreeAccountCreatedByThirdPerson, setIsAgreeAccountCreatedByThirdPerson] = useState(false);
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
  });
  const [showEmailLoginModal, setShowEmailLoginModal] = useState(false);

  const userIdRef = useRef();
  const userPasswdRef = useRef();

  if (userInfo != null && nvlNumber(userInfo.userSeq) > 0) {
    if (userInfo.userLevel === USER_LEVELS.STUDENT) {
      // if(userIdRef.current.value === 'kookee@gateplus.org') window.location.href = "/student/dashboard_2024";
      // else window.location.href = "/student/dashboard";
      window.location.href = '/student/dashboard';
    } else if (userInfo.userLevel === USER_LEVELS.PARENT) {
      window.location.href = '/parent/dashboard';
    } else {
      window.location.href = '/academy/dashboard';
    }
  }

  /** 커스텀 알럿 창 "닫기" 버튼 핸들러 */
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...prev, alertType: 'alert', visible: false, alertMessage: '' };
    });
  };

  /** 커스텀 알럿 창 "확인" 버튼 핸들러 */
  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
      // if(activateMode === '뭐 할게 있나?') 몰러();
    }
  };

  const saveIdToggle = (event) => {
    setClickedValue(event.target.checked);
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (alertLayerPopup.visible) {
        setAlertLayerPopup((prev) => {
          return { ...prev, visible: false };
        });
        return;
      }
      if (nvl(confirmCode) === '') managerLogin();
      else getAuthenCode();
    }
  };

  /** 회원가입 페이지 가기 */
  const gotoSignup = () => {
    navigate('/signup');
  };

  /** 로그인 하기 */
  const managerLogin = () => {
    if (nvl(userIdRef.current.value) === '') {
      return;
    } else if (alertLayerPopup.visible) {
      setAlertLayerPopup((prev) => {
        return { ...prev, visible: false };
      });
      return;
    } else if (nvl(userPasswdRef.current.value) === '') {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please enter password',
        };
      });

      return;
    }

    let params = {
      userId: userIdRef.current.value,
      userPasswd: userPasswdRef.current.value,
      userPasswdFailCnt: userPasswdFailCnt,
      userAgent: window.navigator.userAgent,
    };

    setLoadingBarActive(true); // 로딩바 띄우기

    const successHandler = (response) => {
      // console.log("일단 response 확인", response)

      setLoadingBarActive(false); // 로딩바 없애기

      if (response.code === 200) {
        // 로그인 성공
        // ok 코드 값
        setUserPasswdFailCnt(0);
        if (clickedValue) LocalStorage.setItem('etcSession', 'saveId', nvl(userIdRef.current.value));

        // 토근을 로컬에 저장
        LocalStorage.clearItem('userSession');
        LocalStorage.clearItem('sendParams');
        LocalStorage.clearItem('util');

        LocalStorage.setItem('userSession', 'accessToken', response.result.accessToken);
        LocalStorage.setItem('userSession', 'refreshToken', response.result.refreshToken);

        if (isExternalSign) {
          // 외부 도메인에서 온 로그인 요청 일 경우
          if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.PARENT) {
            // 학부모 계정 일 경우
            window.location.href = '/parent/dashboard';
          } else {
            // 학부모 계정 이외의 경우
            /** 원래 로그인 요청이 온 외부 도메인으로 다시 보낼 링크 */
            const responseLink = `${location.state.isExternalSign}/sign/signin/?lastRoute=${location.state.lastRoute}&buttonType=${location.state.buttonType}&accessToken=${response.result.accessToken}&refreshToken=${response.result.refreshToken}`;

            window.location.replace(responseLink);
          }
        } else {
          // 내부 로그인 요청의 경우
          if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.STUDENT) window.location.href = '/student/dashboard';
          else if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.PARENT) window.location.href = '/parent/dashboard';
          else window.location.href = '/academy/dashboard';
        }
      } else if (response.code === 401) {
        setLoadingBarActive(true); // 로딩바 띄우기
        // 비밀번호 5회 이상 틀림
        setUserPasswdFailCnt(0);
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'You have entered incorrect email address or password more than 5 times. Contact administrator for help. ',
          };
        });
      } else if (response.code === 403) {
        // 학원이 대리 가입 시켜 준 학생이 첫 로그인 시도 했을 때 약관 동의 화면 띄우기
        setLoadingBarActive(false); // 로딩바 없애기
        setIsAgreeAccountCreatedByThirdPerson(true);
      } else if (response.code === 404) {
        setLoadingBarActive(false); // 로딩바 없애기
        // 아이디, 비밀번호 정보 없을때
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'You have entered incorrect information or the information does not exist.',
          };
        });
        setUserPasswdFailCnt(userPasswdFailCnt + 1);
      } else if (response.code === 406) {
        // 관리자 첫 로그인을 학원 코드 없이 했을때
        setShowEmailLoginModal(true);
        setLoadingBarActive(false); // 로딩바 없애기
      } else {
        setLoadingBarActive(false); // 로딩바 없애기
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: 'You have entered incorrect information or the information does not exist.',
          };
        });
        setUserPasswdFailCnt(userPasswdFailCnt + 1);
      }
    };

    request.post('/api/member/login', params, successHandler);
  };

  /** 학원 코드 연결 API 요청 */
  const getAuthenCode = () => {
    if (alertLayerPopup.visible) {
      setAlertLayerPopup((prev) => {
        return { ...prev, visible: false };
      });
      return;
    }

    const successHandler = (response) => {
      setLoadingBarActive(false); // 로딩바 없애기

      if (response.code === 200) {
        // ok 코드 값
        let checkCode = response.result.checkCode;

        if (checkCode) managerLogin();
        else
          setAlertLayerPopup((prev) => {
            return {
              ...prev,
              visible: true,
              alertMessage: 'The academy code is wrong!',
            };
          });
      }
    };

    setLoadingBarActive(true); // 로딩바 띄우기

    request.get(`/api/common/academy/uniquecode/check?uniqueCode=${confirmCode}&userEmail=${userIdRef.current.value}`, null, successHandler);
  };

  /** 사용자 ID 초기화 */
  const userIdInit = (event) => {
    event.target.parentElement.children[1].value = '';

    userIdRef.current.value = '';
  };

  /** 사용자 비밀번호 초기화 */
  const userPasswdInit = (event) => {
    event.target.parentElement.children[1].value = '';

    userPasswdRef.current.value = '';
  };

  useEffect(() => {
    if (nvl(etcSession) !== '' && nvl(confirmCode) === '') userIdRef.current.value = nvl(etcSession?.saveId);

    clearAppData(dispatch, userInfo?.userId);

    SessionStorage.clearItem('windowManager');

    document.body.classList.add('gate_member', 'login'); //현재 페이지에서만 addClass

    return () => {
      document.body.classList.remove('gate_member', 'login'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='container'>
        <div style={{ display: isAgreeAccountCreatedByThirdPerson ? 'none' : 'block' }}>
          {/*헤더*/}
          <Header>
            <p className='txt'>The door to educational aspiration</p>
          </Header>
          {/*폼*/}
          <section className='form'>
            <h1 className='title'>Sign In</h1>
            {/*이메일 입력*/}
            <article className='input_wrap email_wrap'>
              <div className='box input_email'>
                <i className='icon_email'>&nbsp;</i>
                <input type='text' ref={userIdRef} placeholder='Enter your email' className='icon input' />
                <img src={IconDel} alt='delete' className='btn_del' onMouseDown={userIdInit} />
              </div>
            </article>
            {/*비밀번호 입력*/}
            <article className='input_wrap'>
              <div className='box input_pass'>
                <i className='icon_lock'>&nbsp;</i>
                <input type='password' ref={userPasswdRef} placeholder='Enter your password' className='icon input' onKeyUp={handleOnKeyPress} />
                <img src={IconDel} alt='delete' className='btn_del' onMouseDown={userPasswdInit} />
              </div>
            </article>
            {/*기억하기 체크박스*/}
            <label className='custom_checkbox'>
              <input type='checkbox' id='saveIdToggleCheckBox' onClick={saveIdToggle} />
              <span className='checkbox_icon'></span>
              <span className='remember'>Remember my account</span>
            </label>
            {/*에러메시지*/}
            <div className='error_message'>Write your message here.</div>
            {/*자동으로 코드 값을 받는 경우(Academy Tutor, Tutor)*/}
            {nvl(confirmCode) !== '' && (
              <article className='input_wrap'>
                <div className='box input_code'>
                  <img src={IconKey} lt='password' className='key_icon' alt='icon' />
                  <input type='text' id='code' name='code' className='icon input' value={confirmCode} readOnly />
                </div>
              </article>
            )}
          </section>

          {/*로그인 버튼*/}
          <section className='sign_in'>
            <button type='submit' className='btn btn_fill btn_big' onClick={nvl(confirmCode) === '' ? managerLogin : getAuthenCode}>
              Sign In
            </button>
          </section>

          {/*비밀번호/이메일 찾기*/}
          <section className='forgot'>
            {/* <a href="/signin/find/email" className="link bar">Forgot your email?</a>
          <a href="/signin/find/passwd" className="link">Forgot your password?</a> */}
            <span className='link bar' onClick={() => navigate('find/email')}>
              Forgot your email?
            </span>
            <span className='link' onClick={() => navigate('find/passwd')}>
              Forgot your password?
            </span>
          </section>

          {/*회원가입 버튼*/}
          <button className='btn btn_stroke btn_big' onClick={gotoSignup}>
            Sign Up
          </button>
        </div>
        {isAgreeAccountCreatedByThirdPerson ? <AgreeAccountCreatedByThirdPerson userId={userIdRef.current.value} onLogin={managerLogin} /> : null}
      </div>
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
      {loadingBarActive && <LoadingBar type={'spin'} color={'#000000'} />}
      {showEmailLoginModal && <EmailLoginModal setShowEmailLoginModal={setShowEmailLoginModal} />}
    </>
  );
}
