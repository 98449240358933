import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AP_PARAMS } from 'components/ap/_utils/constants/queryParamsString';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS, USER_LEVELS } from 'utils/constants';

import { ReactComponent as ImgSvgIconCheck } from 'assets/img/svg/icon_check_02.svg';
import { ReactComponent as ImgSvgIconX } from 'assets/img/svg/x-close.svg';

import request from 'utils/Request.utils';
import Box from '../../common/styles/Box';
import CustomSelectBox from '../../common/styles/CustomSelectBox';
import SectionTitle from '../../common/styles/SectionTitle';

/** 테이블 하나 당 보여 줄 데이터 갯수 */
const NUMBER_OF_DATA_TO_DISPLAY_PER_TABLE = 15;

/** ap/com/score 페이지의 "Individual Question Results" 섹션 컴포넌트 */
export default function IndividualQuestionResultsSection({
  sectionAndPartList = [],
  isAcademy = false,
  currentIndividualQuestionResults,
  selectCallback = ({ queryCurUthSeq, queryCurPartSeq }) => {},
  isLoading = false,
  className = '',
  reviewPeriodDate = null,
  ...rest
}) {
  const { setBulkObfuscatedValues, getObfuscatedValue, navigateWithParams } = useObfuscatedSearchParam();
  const userInfo = request.tokenDecoder();

  /** 현재 선택 된 UTH 시퀀스 */
  const queryCurUthSeq = getObfuscatedValue(isAcademy ? SCORE_PARAMS.CUR_UTH : AP_PARAMS.UTH_SEQ.KEY);
  /** 현재 선택 된 Section 시퀀스 */
  const queryCurSectionSeq = getObfuscatedValue(AP_PARAMS.SECTION_SEQ.KEY);
  /** 현재 선택 된 Part 시퀀스 */
  const queryCurPartSeq = getObfuscatedValue(AP_PARAMS.PART_SEQ.KEY);

  /** 현재 선택 된 section 데이터 */
  const [currentSection, setCurrentSection] = useState();
  /** 현재 선택 된 part 데이터 */
  const [currentPart, setCurrentPart] = useState();

  /** Section 선택 핸들러 */
  const handleSelectSection = (sectionSeq) => {
    const currentSectionFilter = sectionAndPartList.find((item) => {
      return Number(item.sectionSeq) === Number(sectionSeq);
    });

    if (currentSectionFilter) {
      setBulkObfuscatedValues({
        paramValues: {
          [isAcademy ? SCORE_PARAMS.CUR_UTH : AP_PARAMS.UTH_SEQ.KEY]: queryCurUthSeq,
          [AP_PARAMS.SECTION_SEQ.KEY]: sectionSeq,
          [AP_PARAMS.PART_SEQ.KEY]: currentSectionFilter.partData[0].partSeq,
        },
      });
    }
  };

  /** Part 선택 핸들러 */
  const handleSelectPart = (partSeq) => {
    setBulkObfuscatedValues({
      paramValues: {
        [isAcademy ? SCORE_PARAMS.CUR_UTH : AP_PARAMS.UTH_SEQ.KEY]: queryCurUthSeq,
        [AP_PARAMS.SECTION_SEQ.KEY]: queryCurSectionSeq,
        [AP_PARAMS.PART_SEQ.KEY]: partSeq,
      },
    });

    selectCallback({ queryCurUthSeq, queryCurPartSeq: partSeq });
  };

  /** 각 개별 문제 클릭 핸들러 */
  const handleClickQuestion = ({ uthSeq, sectionSeq, partSeq, questionOrder }) => {
    if (userInfo.userLevel === USER_LEVELS.STUDENT) {
      const successHandler = (response) => {
        if (response.code === 200) {
          const destinationRoute = navigateWithParams(`/review`, {
            newParams: {
              [AP_PARAMS.UTH_SEQ.KEY]: uthSeq,
              [AP_PARAMS.SECTION_SEQ.KEY]: sectionSeq,
              [AP_PARAMS.PART_SEQ.KEY]: partSeq,
              [AP_PARAMS.QUESTION_ORDER.KEY]: questionOrder,
            },
            isNavigate: false,
          });
          window.open(`${isAcademy ? '/ap/com/score' : '/ap/student'}${destinationRoute}`, '_blank');
        } else if (response.code === 410) {
          window.alert('Your teacher-set review period has expired. Please reach out to your academy instructor.');
        }
      };

      request.apGet(`/api/v1/student/dashboard/review/${uthSeq}`, null, successHandler);
      return;
    }

    if (uthSeq && sectionSeq && partSeq && questionOrder !== undefined && questionOrder !== null) {
      const destinationRoute = navigateWithParams(`/review`, {
        newParams: {
          [AP_PARAMS.UTH_SEQ.KEY]: uthSeq,
          [AP_PARAMS.SECTION_SEQ.KEY]: sectionSeq,
          [AP_PARAMS.PART_SEQ.KEY]: partSeq,
          [AP_PARAMS.QUESTION_ORDER.KEY]: questionOrder,
        },
        isNavigate: false,
      });
      window.open(`${isAcademy ? '/ap/com/score' : '/ap/student'}${destinationRoute}`, '_blank');
    } else {
      console.error('uthSeq와 partSeq, questionOrder 를 확인해 주세요.');
    }
  };

  useEffect(() => {
    /** 현재 선택 된 section 데이터 */
    const currentSectionFilter = sectionAndPartList.find((item) => {
      return Number(item.sectionSeq) === Number(queryCurSectionSeq);
    });
    setCurrentSection(currentSectionFilter);
  }, [sectionAndPartList, queryCurSectionSeq]);

  useEffect(() => {
    /** 현재 선택 된 part 데이터 */
    const currentPart = currentSection?.partData.find((item) => {
      return Number(item.partSeq) === Number(queryCurPartSeq);
    });

    if (currentPart) {
      setCurrentPart(currentPart);
    }
  }, [queryCurPartSeq, currentSection]);

  useEffect(() => {
    if (currentSection) {
      handleSelectPart(currentSection?.partData[0].partSeq);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection]);

  // 선택한 옵션 값 변경
  const mapOptionToLetter = (option) => {
    const mapping = {
      option1: 'A',
      option2: 'B',
      option3: 'C',
      option4: 'D',
      option5: 'E',
      option6: 'F',
      option7: 'G',
      option8: 'H',
      option9: 'I',
      option10: 'J',
      option11: 'K',
      option12: 'L',
      option13: 'M',
    };

    try {
      const parsedAnswers = JSON.parse(option);
      return parsedAnswers.map((option) => mapping[option.toLowerCase()] || option).join(', ');
    } catch (error) {
      console.error('JSON parsing error:', error);
      return '';
    }
  };

  return (
    <S.Wrap className={` ${className}`} {...rest}>
      <Box className='main_box'>
        <div className='top_wrap'>
          <div>
            <SectionTitle>Individual Question Results</SectionTitle>
            <div className='sub_title'>Click each item to review the results for each question.</div>
          </div>
          <div className='select_box_wrap'>
            {currentSection && (
              <CustomSelectBox
                selectedValue={isLoading ? `Loading...` : currentSection?.sectionName}
                optionsArray={sectionAndPartList}
                handleSelectCallback={handleSelectSection}
                disabled={isLoading}
              />
            )}
            <CustomSelectBox
              selectedValue={currentPart ? currentPart.partName : currentSection?.partData[0]?.partName}
              optionsArray={currentSection?.partData}
              handleSelectCallback={handleSelectPart}
              disabled={!currentSection?.partData || currentSection.partData.length <= 1 || isLoading}
            />
          </div>
        </div>
        {chunkArray(currentIndividualQuestionResults, NUMBER_OF_DATA_TO_DISPLAY_PER_TABLE).map((chunkItem, chunkIndex) => {
          return (
            <div key={`key_${chunkIndex}`} className='contents_area'>
              <table>
                <caption>Individual Question Results</caption>
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Question No.</th>
                    {chunkItem.map((item, index) => {
                      return (
                        <td key={`key_${index}`} className={`${item.isCorrect ? 'correct' : ''}`}>
                          <button
                            type='button'
                            title=''
                            onClick={() => {
                              handleClickQuestion({
                                uthSeq: queryCurUthSeq,
                                partSeq: queryCurPartSeq,
                                sectionSeq: queryCurSectionSeq,
                                questionOrder: item.questionOrder,
                              });
                            }}>
                            {item.customQuestionOrder}
                          </button>
                        </td>
                      );
                    })}
                  </tr>
                  {chunkItem[0].questionType === 'M' && (
                    <>
                      <tr>
                        <th>Result</th>
                        {chunkItem.map((item, index) => {
                          return (
                            <td key={`key_${index}`}>
                              <p>{item.isCorrect ? <ImgSvgIconCheck className='icon_check' /> : <ImgSvgIconX className='icon_x' />}</p>
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Correct Answer</th>
                        {chunkItem.map((item, index) => {
                          return (
                            <td key={`key_${index}`}>
                              <p>{item.correctAnswer && mapOptionToLetter(item.correctAnswer)}</p>
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Your Answer</th>
                        {chunkItem.map((item, index) => {
                          return (
                            <td key={`key_${index}`}>
                              <p>{item.selectAnswer && item.selectAnswer !== '[]' ? mapOptionToLetter(item.selectAnswer) : '-'}</p>
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th>Correct %</th>
                        {chunkItem.map((item, index) => {
                          return (
                            <td key={`key_${index}`}>
                              <p>{`${item.correctAnswerRate.toFixed(0)}%`}</p>
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  )}
                  <tr>
                    <th>Time spent</th>
                    {chunkItem.map((item, index) => {
                      return (
                        <td key={`key_${index}`}>
                          <p>{formatTime(item.solvingTime)}</p>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </Box>
    </S.Wrap>
  );
}

/** 배열을 특정 갯수로 나누는 역할 */
function chunkArray(arr, size) {
  return Array.from({ length: Math.ceil(arr.length / size) }, (_, i) => arr.slice(i * size, i * size + size));
}

/** "초를 받아서 MM:SS 형식으로 출력" 하는 함수 */
function formatTime(seconds) {
  const minutes = Math.floor(Number(seconds) / 60);
  const secs = Number(seconds) % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
}

const S = {
  Wrap: styled.div`
    width: 100%;
    .main_box {
      padding: 1.25rem 1.5rem;
    }

    .top_wrap {
      display: flex;
      justify-content: space-between;
      align-items: end;
    }

    .select_box_wrap {
      display: flex;
      justify-content: end;
      gap: 0.75rem;
    }

    .contents_area {
      margin-top: 1.25rem;
      border-radius: 1rem;
      border: 1px solid #e9e9e9;
      overflow: hidden;
      table {
        position: relative;
        border-collapse: collapse;
        table-layout: auto;
        caption {
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          padding: 0;
          margin: 0;
          border: none;
          overflow: hidden;
        }

        td,
        th {
          text-align: center;
          align-content: center;
          height: 3rem;
          border: 1px solid #e9e9e9;
          overflow: hidden;
        }
        th {
          width: 10.25rem;
          background-color: #f8f8f8;
          color: #505050;
          font-size: 1.125rem;
          font-weight: 400;
          line-height: 1.75rem;
        }
        td {
          width: auto;
          color: #111;
          font-size: 1.125rem;
          font-weight: 400;
          line-height: 1.75rem;
          button,
          p,
          a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 0.875rem;
          }
        }

        tr {
          & > * {
            &:first-child {
              border-left: none;
            }
            &:last-child {
              border-right: none;
            }
          }
          &:first-child {
            & > * {
              border-top: none;
              &:first-child {
                border-top-left-radius: 1rem;
              }
              &:last-child {
                border-top-right-radius: 1rem;
              }
            }

            td {
              background-color: #ffa88c;
              &.correct {
                background-color: #defff4;
              }
            }
          }
          &:last-child {
            & > * {
              border-bottom: none;
              &:first-child {
                border-bottom-left-radius: 1rem;
              }
              &:last-child {
                border-bottom-right-radius: 1rem;
              }
            }
          }
        }
      }
      .icon_check {
        width: 1.75rem;
        color: #0fb300;
      }
      .icon_x {
        width: 1.25rem;
        height: 1.25rem;
        color: #ff7474;
      }
    }
  `,
};
