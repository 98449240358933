import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

/////////// img
import ImgClass from 'assets/img/select_class.png';
import ImgClass2 from 'assets/img/select_class2.png';
import ImgClass3 from 'assets/img/select_class3.png';
import ImgStudent from 'assets/img/select_student.png';
import ImgStudent2 from 'assets/img/select_student2.png';
import ImgStudent3 from 'assets/img/select_student3.png';
//////////// components
import { clearReduxStateSetTests, updateReduxStateSelectedUserType } from 'reducers/setTests/action';
import Layout from '../../../_components/_common/Layout';

/** AP Set Tests 첫 화면 : 학원 / 학생 중 하나를 선택 한다. */
export default function ApTestsSetSelectTypePage() {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  /** 사용자 선택 버튼 동작 함수 */
  const handleUserTypeSelectButton = (type) => {
    dispatch(clearReduxStateSetTests());
    if (type === 'class') {
      dispatch(updateReduxStateSelectedUserType('Class'));
      navigate('/ap/com/tests/set/selection/class');
    } else {
      // 클릭시 AP Set Test- Select Student 단계로 넘어가나, 1차 배포때는 비활성화
      // dispatch(updateReduxStateSelectedUserType('Student'));
      // navigate('/ap/com/tests/set/selection/student');
    }
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  return (
    <Layout>
      <S.SetTestsSelectType>
        <h2 className='tit'>
          Select
          <br />
          <b>Class</b> <span className='s'>or</span> <b>individual students</b>
        </h2>
        <div className='btn_area'>
          <button onClick={() => handleUserTypeSelectButton('class')} className='btn'>
            <p className='tit'>
              <b>Class</b>
              test setting
            </p>
            <p className='txt'>
              Set up tests for your class. One class at a time.
              <br />
              Multiple tests all the time.
            </p>
            <img src={ImgClass2} alt='class icon' className='img hover' />
            <img src={ImgClass3} alt='class icon' className='img' />
            <img src={ImgClass} alt='class icon' className='bg' />
          </button>
          <button onClick={() => handleUserTypeSelectButton('student')} className='btn' disabled>
            <p className='tit'>
              <b>STUDENT</b>
              test setting
            </p>
            <p className='txt'>
              Set up tests for individual students. <br />
              Choose however many you want.{' '}
            </p>
            <img src={ImgStudent2} alt='student icon' className='img hover' />
            <img src={ImgStudent3} alt='student icon' className='img' />
            <img src={ImgStudent} alt='student icon' className='bg' />
          </button>
        </div>
      </S.SetTestsSelectType>
    </Layout>
  );
}

const S = {};

/** Set tests "" Styled-components */
S.SetTestsSelectType = styled.div`
  --speed: 0.3s;

  padding-bottom: 80px;
  .tit {
    color: #19456d;
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
    b {
      font-weight: bold;
    }
    .s {
      font-size: 26px;
    }
  }
  .btn_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    margin-top: 30px;
    .btn {
      flex: 1;
      border-radius: 10px;
      border: 1px solid #d5ebff;
      color: #7a98b4;
      background: linear-gradient(117deg, #fff 40.62%, #e6f4ff 98.51%);
      text-align: left;
      position: relative;
      overflow: hidden;
      transition-duration: var(--speed);
      .tit {
        font-size: 30px;
        text-transform: uppercase;
        padding: 71px 0 10px 51px;
        text-align: left;
        color: #7a98b4;
        b {
          font-weight: bold;
          display: block;
        }
      }
      .txt {
        font-size: 16px;
        padding-left: 51px;
        text-align: left;
        margin-bottom: 22px;
      }
      img {
        &.img {
          position: absolute;
          left: 42px;
          bottom: 0;
          filter: drop-shadow(2px 2px 2px rgba(255, 255, 255, 1));
          z-index: 2;
          transition-duration: var(--speed);
          transform: translate(0, 5px);
          &.hover {
            opacity: 0;
          }
        }
        &.bg {
          opacity: 0.3;
          margin-left: 136px;
          margin-bottom: 12px;
        }
      }
      &:hover {
        box-shadow: 30px 50px 20px 0px rgba(16, 62, 99, 0.15);
        overflow: visible;
        transition-duration: var(--speed);
        transform: translate(-10px, -10px);
        .tit {
          color: #19456d;
        }
        .txt {
          color: #19456d;
        }
        img {
          &.img {
            transform: translate(0, 0);
            transition-duration: var(--speed);
            opacity: 0;
            &.hover {
              opacity: 1;
              transition-duration: var(--speed);
            }
          }
          &.bg {
            opacity: 1;
          }
        }
        &:after {
          @include addImg('go.png', 106px, 52px, contain);
          bottom: 80px;
          right: -9px;
          position: absolute;
        }
      }

      &:disabled {
        &:hover {
          box-shadow: none;
          overflow: visible;
          transition-duration: var(--speed);
          transform: translate(0, 0);
          .tit {
            color: #19456d;
          }
          .txt {
            color: #19456d;
          }
          img {
            &.img {
              transform: translate(0, 0);
              transition-duration: var(--speed);
              opacity: 1;
              &.hover {
                opacity: 0;
              }
            }
            &.bg {
              opacity: 1;
            }
          }
          &:after {
            bottom: 0;
            right: 0;
            position: absolute;
          }
        }
      }
    }
  }
`;
