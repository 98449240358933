import styled from 'styled-components';

import CollapsibleListItem from './CollapsibleListItem';

/**
 * @typedef {Object} CollapsibleListProps
 * @property {Array<any>} [items=[]] - 표시할 아이템 목록
 * @property {boolean} [headerSelectable=false] - 헤더 선택 가능 여부
 * @property {boolean} [contentSelectable=false] - 컨텐츠 선택 가능 여부
 * @property {Function} [handleSelectHeader=null] - 헤더 선택 시 호출될 콜백 함수
 * @property {Function} [handleSelectContent=null] - 컨텐츠 선택 시 호출될 콜백 함수
 * @property {Function} [renderHeader=null] - 헤더를 커스텀하게 렌더링하는 함수
 * @property {Function} [renderContent=null] - 컨텐츠를 커스텀하게 렌더링하는 함수
 * @property {Function} [itemId] - 아이템의 고유 ID
 * @property {Function} [expandedContentInfo] - 확장된 컨텐츠 정보의 정보 ( 데이터에 접근하는 key 정보 )
 */

/**
 * 접을 수 있는 리스트 컴포넌트
 * 헤더와 컨텐츠 영역을 가지며, 각각 선택 가능하도록 설정할 수 있습니다.
 *
 * @param {CollapsibleListProps} props
 * @returns {JSX.Element}
 *
 * @example
 * <CollapsibleList
 *   items={dateList}
 *   headerSelectable
 *   contentSelectable
 *   itemId={"id"}
 *   childItemId={"expandedContent"}
 *   renderHeader={(item) => (
 *     <div>{item.title}</div>
 *   )}
 * />
 */
export default function CollapsibleList({
  items = [],
  headerSelectable = false,
  contentSelectable = false,
  handleSelectHeader = null,
  handleSelectContent = null,
  maxContentItemCount = null,
  maxHeaderItemCount = null,
  selectedHeaderItems = [],
  renderHeader = null,
  renderContent = null,
  itemId,
  expandedContentInfo = {
    contentKey: '',
    itemId: '',
  },
  collapsibleListId = '',
  className = '',
}) {
  return (
    <S.ListContainer className={className}>
      {items.map((item, index) => (
        <CollapsibleListItem
          key={`${item[itemId]}_${index}`}
          // 체크 박스의 label - input을 위한 고유 id를 만들기 위해 전달
          listIndex={`${collapsibleListId}_${index}`}
          // 랜더링 데이터
          itemList={item}
          headerSelectable={headerSelectable}
          contentSelectable={contentSelectable}
          handleSelectHeader={handleSelectHeader}
          handleSelectContent={handleSelectContent}
          maxContentItemCount={maxContentItemCount}
          maxHeaderItemCount={maxHeaderItemCount}
          selectedHeaderItems={selectedHeaderItems}
          renderHeader={renderHeader}
          renderContent={renderContent}
          itemId={itemId}
          expandedContentInfo={expandedContentInfo}
        />
      ))}
    </S.ListContainer>
  );
}

const S = {
  ListContainer: styled.div`
    width: 100%;
    font-size: 0.875rem;
    height: auto;
  `,
};
