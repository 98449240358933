import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deobfuscateUrlParam, obfuscateUrlParam } from 'utils/urlParamObfuscator';

/**
 * 난독화된 URL 검색 파라미터를 관리하기 위한 커스텀 React 훅
 * @returns {[
 *   (paramName: string) => string | null,
 *   (paramName: string, value: string | number) => void,
 *   (paramName: string) => void
 * ]} {getObfuscatedValue, setObfuscatedValue, deleteObfuscatedValue} 함수 배열
 */
export const useObfuscatedSearchParam = () => {
  // react-router-dom의 useSearchParams 훅 사용
  const [searchParams, setSearchParams] = useSearchParams();
  // 페이지 이동을 위한 useNavigate 훅 사용
  const navigate = useNavigate();

  /**
   * 주어진 파라미터 이름에 해당하는 난독화된 값을 복호화
   * @param {string} paramName - 가져올 파라미터의 이름
   * @returns {string | null} - 복호화된 값 또는 null (파라미터가 없는 경우)
   */
  const getObfuscatedValue = useCallback(
    (paramName) => {
      const obfuscatedValue = searchParams.get(paramName);
      // 값이 있으면 복호화하여 반환, 없으면 null 반환
      return obfuscatedValue ? deobfuscateUrlParam(obfuscatedValue) : null;
    },
    [searchParams]
  );

  /**
   * 주어진 파라미터 이름과 값을 난독화하여 URL에 설정
   * @param {string} paramKey - 설정할 파라미터의 이름
   * @param {string | number} value - 설정할 값
   * @param {boolean} replace - 파라미터 변경을 라우트 히스토리에 남길지 여부
   */
  const setObfuscatedValue = useCallback(
    ({ paramKey, value, replace = true }) => {
      // 파라미터 값 난독화
      const obfuscatedValue = obfuscateUrlParam(value);

      setSearchParams(
        (prev) => {
          const updatedSearchParams = new URLSearchParams(prev);
          // 난독화된 키와 값을 URL 파라미터로 설정
          updatedSearchParams.set(paramKey, obfuscatedValue);
          return updatedSearchParams;
        },
        { replace }
      );
    },
    [setSearchParams]
  );

  /**
   * 주어진 파라미터 이름과 값을 난독화하여 URL에 설정 - 여러 개의 파라미터를 한 번에 설정
   * @param {Object.<string, string | number>} paramValues - 설정할 파라미터 이름과 값의 객체
   * @param {boolean} replace - 파라미터 변경을 라우트 히스토리에 남길지 여부
   * @example
   * setBulkObfuscatedValues({
   *  paramValues : {
   *    key1: 'value1',
   *    key2: 123,
   *  },
   *  replace: true
   * });
   */
  const setBulkObfuscatedValues = useCallback(
    ({ paramValues, replace = true }) => {
      setSearchParams(
        (prev) => {
          const updatedSearchParams = new URLSearchParams(prev);
          Object.entries(paramValues).forEach(([paramKey, value]) => {
            const obfuscatedValue = obfuscateUrlParam(value);
            updatedSearchParams.set(paramKey, obfuscatedValue);
          });
          return updatedSearchParams;
        },
        { replace }
      );
    },
    [setSearchParams]
  );

  /**
   * 주어진 파라미터 이름에 해당하는 값을 URL에서 삭제
   * @param {string} paramKey - 삭제할 파라미터의 이름
   * @param {boolean} replace - 파라미터 변경을 라우트 히스토리에 남길지 여부
   */
  const deleteObfuscatedValue = useCallback(
    ({ paramKey, replace = true }) => {
      setSearchParams(
        (prev) => {
          const newSearchParams = new URLSearchParams(prev);
          // 주어진 이름의 파라미터를 URL에서 제거
          newSearchParams.delete(paramKey);
          return newSearchParams;
        },
        {
          replace,
        }
      );
    },
    [setSearchParams]
  );
  /**
   * 주어진 파라미터 이름에 해당하는 값을 URL에서 삭제 - 여러 개의 파라미터를 한 번에 삭제
   * @param {Array.<string>} paramValues - 삭제할 파라미터 이름의 배열
   * @example
   * deleteBulkObfuscatedValues(['param1', 'param2']);
   * @param {boolean} replace - 파라미터 변경을 라우트 히스토리에 남길지 여부
   */
  const deleteBulkObfuscatedValues = useCallback(
    ({ paramValues, replace = true }) => {
      setSearchParams(
        (prev) => {
          const updatedSearchParams = new URLSearchParams(prev);
          paramValues.forEach((paramName) => {
            updatedSearchParams.delete(paramName);
          });
          return updatedSearchParams;
        },
        {
          replace,
        }
      );
    },
    [setSearchParams]
  );

  /**
   * 지정된 검색 파라미터만 유지하면서 새로운 경로로 이동
   * @param {string} pathToMove - 이동할 경로
   * @param {Object} options - 이동 옵션
   * @param {Object.string[]} [options.keepParams] - 유지할 파라미터 이름 배열
   * @param {Object.<string, string | number>} [options.newParams] - 새로 추가할 파라미터
   * @param {Object.boolean} [options.replace] - history를 대체할지 여부
   * @param {Object.boolean} [options.isNavigate] - 바로 navigate로 라우트 이동 할 지 / URL을 반환 할 지 여부
   */
  const navigateWithParams = useCallback(
    (pathToMove, options = {}) => {
      const { keepParams = [], newParams = {}, replace = false, isNavigate = true } = options;
      const newSearchParams = new URLSearchParams();

      // keepParams 옵션이 있을때 ( 이동하면서 유지할 파라미터 설정 )
      if (keepParams.length > 0) {
        // 유지할 파라미터 복사및 이동할 URL에 추가
        keepParams.forEach((paramName) => {
          const value = searchParams.get(paramName);
          if (value) {
            newSearchParams.set(paramName, value);
          }
        });
      }

      // newParams 옵션이 있을때 ( 이동하면서 새로운 파라미터 추가 )
      Object.entries(newParams).forEach(([key, value]) => {
        const obfuscatedValue = obfuscateUrlParam(value);
        newSearchParams.set(key, obfuscatedValue);
      });

      // 새로운 URL 생성 ( 세팅할 파라미터가 있으면 추가, 없으면 기존 URL )
      const search = newSearchParams.toString();
      const destination = search ? `${pathToMove}?${search}` : pathToMove;

      // isNavigate 옵션이 있으면 - 페이지 이동
      if (isNavigate) {
        // 페이지 이동 ( replace 옵션이 있으면 replace, 없으면 push )
        navigate(destination, { replace });

        // isNavigate 옵션이 없으면 - 이동할 URL 반환
      } else {
        return destination;
      }
    },
    [searchParams, navigate]
  );

  // 메모이제이션된 함수 배열 반환
  return useMemo(() => {
    return { getObfuscatedValue, setObfuscatedValue, setBulkObfuscatedValues, deleteObfuscatedValue, deleteBulkObfuscatedValues, navigateWithParams };
  }, [getObfuscatedValue, setObfuscatedValue, setBulkObfuscatedValues, deleteObfuscatedValue, deleteBulkObfuscatedValues, navigateWithParams]);
};

/** 받아온 queryParameter가 유효한지 검증하고 각 타입에 맞는 값으로 return해주는 함수 */
export const getValidParam = (value) => {
  // 문자열이 아니면 null 반환
  if (typeof value !== 'string') return null;
  // 문자열 앞뒤 공백 제거 ( 빈 문자열 혹은 0이면 null 반환 )
  const trimmed = value.trim();
  if (trimmed === '') return null;
  if (trimmed === '0') return null;

  // 숫자로 변환 가능하면 숫자로 변환하여 반환
  if (/^\d+$/.test(trimmed)) {
    return Number(trimmed);
  }
  // 숫자로 변환 불가능하면 문자열 그대로 반환
  return trimmed;
};
