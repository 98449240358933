import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import MainBox from 'components/user/_components/layouts/MainBox';
import Logo from 'components/user/_components/layouts/Logo';
import SignCheckBoxBase from 'components/user/_components/common/forms/inputs/_SignCheckBoxBase';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import SubButton from 'components/user/_components/common/buttons/SubButton';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';

import ErrorText from 'components/user/_components/styles/ErrorText';
import Header from 'components/user/sign-up/_components/layouts/Header';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import Label from 'components/user/_components/common/forms/Label';
import Input from 'components/user/_components/common/forms/Input';
import request from 'utils/Request.utils';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateReduxPrivateTutorId,
  updateReduxPrivateTutorLoginType,
  updateReduxPrivateTutorUserEmail,
  updateReduxPrivateTutorUserMobile,
  updateReduxPrivateTutorUserName,
  updateReduxPrivateTutorAgreePrivacyPolicy,
  updateReduxPrivateTutorAgreeTermsService,
  updateReduxPrivateTutorAgreeNewsLetter,
  // updateReduxPrivateTutorTutoringName,
} from 'reducers/auth/privatetutor.action';
import { updateReduxSocialUserLevel } from 'reducers/auth/action';
import { USER_LEVELS } from 'utils/constants';

/** 회원가입 - 소셜 - Tutor - 정보 확인 페이지 */
export default function SignUpTutorInformationReviewPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSocial = useSelector((state) => state.userReducer.social);
  const userSocialEmail = userSocial.userEmail;
  const userSocialEmailName = userSocial.userName;

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {},
  });
  // Redux에서 저장된 데이터 가져오기
  // const storedUserEmail = useSelector((state) => state.privateTutorReducer.userEmail);
  const storedUserName = useSelector((state) => state.privateTutorReducer.userName);
  const storedUserMobilePhone = useSelector((state) => state.privateTutorReducer.userMobile);
  const storedAgree01 = useSelector((state) => state.privateTutorReducer.agree_privacyPolicy);
  const storedAgree02 = useSelector((state) => state.privateTutorReducer.agree_termsService);
  const storedAgree03 = useSelector((state) => state.privateTutorReducer.agree_newsLetter);
  // const userEmail = watch('userEmail', storedUserEmail);
  const userMobilePhone = watch('userMobilePhone', storedUserMobilePhone);
  // storedUserName 이 값이 잇는경우 storedUserName 값으로 초기화
  // 없는 경우 userSocialEmailName 값으로 초기화
  const userName = watch('userName', storedUserName || userSocialEmailName);
  const agree01 = watch('agree01', storedAgree01);
  const agree02 = watch('agree02', storedAgree02);
  const agree03 = watch('agree03', storedAgree03);

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    dispatch(updateReduxPrivateTutorId(userSocial.userId));
    dispatch(updateReduxPrivateTutorLoginType(userSocial.loginType));
    dispatch(updateReduxPrivateTutorUserEmail(userSocialEmail));
    dispatch(updateReduxPrivateTutorUserMobile(userMobilePhone));
    dispatch(updateReduxPrivateTutorUserName(userName));
    dispatch(updateReduxPrivateTutorAgreePrivacyPolicy(agree01));
    dispatch(updateReduxPrivateTutorAgreeTermsService(agree02));
    dispatch(updateReduxPrivateTutorAgreeNewsLetter(agree03));
    dispatch(updateReduxSocialUserLevel(USER_LEVELS.TUTOR));
    duplicatedMobile(userMobilePhone);
  };

  /** 이메일 확인 */
  const duplicatedMobile = (mobile) => {
    const successHandler = (response) => {
      if (response.result.count === 0) {
        // 중복된 번호가 없을 경우
        navigate('/signup/social/tutor/tutor-information');
      } else {
        // 중복된 번호가 있을 경우
        setError('userMobilePhone', { type: 'duplicate', message: 'This mobile number is already in use' });
      }
    };
    const url = request.apiUris.common.duplicateMobile;
    const replace_url = url.replace(/:number/g, mobile);
    request.get(replace_url, { isNew: true }, successHandler);
  };

  /** 뒤로가기 버튼 핸들러 */
  const handleClickBackButton = () => {
    navigate('/signup/social/user-type');
  };

  useEffect(() => {
    console.log(`🚨 errors :`, errors, `🚨`);
  }, [errors]);

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <S.Wrap>
      <MainBox className='main-box'>
        <Logo />
        <Header title={'Tutor'} />
        <form onSubmit={handleSubmit(onValid)}>
          <p className='guide'>Enter your personal information</p>
          <FormItemWrap>
            <Label className='red-dot' htmlFor='userEmail'>
              Email
            </Label>
            <Input
              id='userEmail'
              register={register('userEmail', {
                required: '필수 입력 필드 입니다.',
              })}
              type='email'
              readOnly
              value={userSocialEmail}
            />
            {errors.userEmail && <ErrorText>{errors.userEmail.message}</ErrorText>}
          </FormItemWrap>
          <FormItemWrap>
            <Label className='red-dot' htmlFor='userName'>
              Name
            </Label>
            <Input
              id='userName'
              value={userName}
              onChange={(e) => setValue('userName', e.target.value)}
              register={register('userName', { required: 'Please enter in your full name' })}
              type='text'
              autoFocus
            />
            {errors.userName && <ErrorText>{errors.userName.message}</ErrorText>}
          </FormItemWrap>
          <FormItemWrap>
            <Label className='red-dot' htmlFor='userMobilePhone'>
              Mobile Phone No.
            </Label>
            <Input
              id='userMobilePhone'
              value={userMobilePhone}
              onChange={(e) => {
                clearErrors('userMobilePhone');
                setValue('userMobilePhone', e.target.value);
              }}
              register={register('userMobilePhone', {
                required: 'Your mobile number will be used to identify you',
              })}
              type='tel'
            />
            {errors.userMobilePhone && <ErrorText>{errors.userMobilePhone.message}</ErrorText>}
          </FormItemWrap>
          <div className='agree-wrap'>
            <SignCheckBoxBase
              checkBoxId='agree01'
              checked={agree01}
              onChange={() => setValue('agree01', !agree01)}
              register={register('agree01', { required: 'You must agree with our terms to use our service!' })}>
              I have read and agree to Gate⁺
              <a
                className='btn_txt style1'
                href='https://gateplus.notion.site/Privacy-policy-460855e375544490ba01ca7d24404dd3'
                target='_blank'
                rel='noopener noreferrer'
                title='새 탭으로 열기'>
                Privacy Policy
              </a>
              and
              <br />
              <a
                className='btn_txt'
                href='https://gateplus.notion.site/Terms-of-Service-5a16766471974bb0b51f63a9f983dc13'
                target='_blank'
                rel='noopener noreferrer'
                title='새 탭으로 열기'>
                Terms of Service
              </a>
              (Mandatory)
            </SignCheckBoxBase>
            {errors.agree01 && <ErrorText errer_type={'check-error'}>{errors.agree01.message}</ErrorText>}
            <SignCheckBoxBase checkBoxId={'agree02'} checked={agree02} onChange={() => setValue('agree02', !agree02)} register={register('agree02')}>
              I agree to receive
              <a
                className='btn_txt'
                href='https://gateplus.notion.site/Marketing-Consent-f0623a824e9a4c7a8de7b4c4639f4369'
                target='_blank'
                rel='noopener noreferrer'>
                marketing communications
              </a>
              from Gate⁺ (Optional)
            </SignCheckBoxBase>
            <SignCheckBoxBase checkBoxId={'agree03'} checked={agree03} onChange={() => setValue('agree03', !agree03)} register={register('agree03')}>
              I want to receive Gate⁺ Newsletter (Optional)
            </SignCheckBoxBase>
          </div>
          <PrimaryButtonArea>
            <SubButton type='button' onClick={handleClickBackButton} title='이전 페이지'>
              Back
            </SubButton>
            <PrimaryButton type='submit' title='다음 페이지'>
              Next
            </PrimaryButton>
          </PrimaryButtonArea>
        </form>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .guide {
    color: #7f8998;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
  }
  .agree-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding-left: 2.5px;
    margin-top: 2.5rem;
    .btn_txt {
      text-decoration: none;
      line-height: 16px;
      height: 20px;
      margin: 0 3px;
    }
    .line-height-18 {
      line-height: 18px;
    }
  }
  .red-dot {
    padding-left: 0.65rem;
    ::before {
      content: '*';
      color: var(--sub-red);
      margin-top: 2px;
      margin-left: -10px;
      position: absolute;
    }
  }
`;
