//#region    ////////// packages
import { useState } from 'react';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import ProfileModal from 'components/_common/modals/profileModal/ProfileModal';
import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
//#endregion ////////// components

//#region    ////////// utils & hooks
import { isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';
import usePressESC from 'hooks/usePressESC.js';
//#endregion ////////// utils & hooks

//#region    ////////// assets
import { ReactComponent as ImgSvgX } from 'assets/img/svg/x-close.svg';
//#endregion ////////// assets

/** 컴포넌트 JSDoc */
export default function TeacherClassModal({ editClassData, setIsShowTeacherClassModal }) {
  const [profileModalInfo, setProfileModalInfo] = useState({ visible: false, userSeq: 0 });

  /** 프로필 자세히 보기 핸들러 */
  const handleShowProfile = (userSeq) => setProfileModalInfo({ visible: true, userSeq: userSeq });

  usePressESC(() => setIsShowTeacherClassModal(false), !profileModalInfo.visible);

  return (
    <>
      <SimpleModalBase noPad>
        <S.ModalContainer>
          <S.ModalHeader>
            <h3 className='modal_title'>Class Information</h3>
            <S.CloseButton onClick={() => setIsShowTeacherClassModal(false)}>
              <ImgSvgX />
            </S.CloseButton>
          </S.ModalHeader>

          <S.ModalBody>
            <S.ModalBodyItem>
              <p className='body_item_title'>Class Name</p>
              <p>{editClassData.className}</p>
            </S.ModalBodyItem>

            <S.ModalBodyItem>
              <p className='body_item_title'>Subject Name</p>
              <p>{editClassData.subjectName}</p>
            </S.ModalBodyItem>

            <S.ModalBodyItem>
              <p className='body_item_title'>Teacher Name</p>
              <p>{editClassData.teacherName}</p>
            </S.ModalBodyItem>

            <S.ModalBodyItem>
              <p className='body_item_title'>Date</p>
              <div className='date_input_container'>
                <S.DateInput type='text' value={isoTimeToDayjsTZ(editClassData.classStartDate).format('MM / DD / YYYY') ?? '-'} readOnly /> ~
                <S.DateInput type='text' value={isoTimeToDayjsTZ(editClassData.classEndDate).format('MM / DD / YYYY') ?? '-'} readOnly />
              </div>
            </S.ModalBodyItem>

            <S.StudentListContainer>
              <div className='student_list_header'>
                <p>Student List</p>
                <p className='student_number'>
                  <i className='svg_icon icon_person white'>&nbsp;</i>
                  <span>{editClassData.studentList.length}</span>
                </p>
              </div>

              <ul className='student_list custom_scroll'>
                {editClassData.studentList.map((student) => (
                  <li key={student.userSeq}>
                    <p>{student.userName}</p>
                    <button className='com_btn s line gray' onClick={() => handleShowProfile(student.userSeq)}>
                      View
                    </button>
                  </li>
                ))}
              </ul>
            </S.StudentListContainer>
          </S.ModalBody>
        </S.ModalContainer>
      </SimpleModalBase>

      <ProfileModal
        profileModalInfo={profileModalInfo}
        closeProfileModal={() => setProfileModalInfo((prev) => ({ ...prev, visible: false }))}
        isShowMemoArea={false}
      />
    </>
  );
}

const S = {
  ModalContainer: styled.div`
    width: 25rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  `,

  ModalHeader: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 1rem;
    padding-left: 0.25rem;
    border-bottom: 1px solid #e0e0e0;
    .modal_title {
      font-weight: 700;
    }
  `,

  ModalBody: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.5rem 1rem;
  `,

  ModalBodyItem: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.125rem;

    .body_item_title {
      font-size: 0.75rem;
      font-weight: 500;
      color: gray;
    }

    .date_input_container {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  `,

  StudentListContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    .student_list_header {
      background-color: black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.75rem;
      font-weight: 700;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      .student_number {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
    .student_list {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;
      border-radius: 0.125rem;
      padding: 0 0.25rem;
      height: 30rem;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        font-size: 1.125rem;
        padding: 0.125rem;
      }
    }
  `,

  DateInput: styled.input`
    height: 32px;
    text-align: center;
    border: 0px;
    border-bottom: 1px solid black;
    border-radius: 0px;
    font-size: 1rem;
  `,

  // 닫기 버튼
  CloseButton: styled.button`
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    border: 1px solid transparent; // 호버 안했을떄
    svg {
      width: 1.25rem;
      height: 1.25rem;
      color: #616161;
    }
    &:hover {
      svg {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        color: black;
      }
      border-color: #0068bd;
    }
  `,
};
