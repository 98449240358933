import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import LoadingBar from 'utils/LoadingBar';

//component
import PopBack from 'components/_common/popups/_PopBack';
import MainBox from 'components/user/_components/layouts/MainBox';
import Logo from 'components/user/_components/layouts/Logo';
import Header from 'components/user/sign-up/_components/layouts/Header';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import Label from 'components/user/_components/common/forms/Label';
import Input from 'components/user/_components/common/forms/Input';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import SubButton from 'components/user/_components/common/buttons/SubButton';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import ErrorText from 'components/user/_components/styles/ErrorText';
import Select from 'components/user/_components/common/forms/Select';

/** 회원 가입 - 이메일 회원 가입 - 학생 - 추가 정보 입력 페이지 */
export default function SignUpEmailStudentInformationPage() {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  /** "뒤로 가기" 버튼이 눌렸는가 여부 */
  const [isBack, setBack] = useState(false);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    setFocus,
  } = useForm({
    defaultValues: {
      schoolName: '',
      schoolType: '',
      userGrade: '',
      schoolLocation: '',
    },
    mode: 'onChange',
  });

  /** Form 제출 핸들러 (react-hook-form) */
  const onValid = (data) => {
    navigate('/signup/complete');
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //현재페이지에서만 removeClass
    };
  }, []);

  return (
    <>
      <S.Wrap>
        <MainBox>
          <Logo />
          <Header title={'Student'} />
          <form onSubmit={handleSubmit(onValid)} className='form'>
            <FormItemWrap>
              <Label htmlFor='userName'>Name</Label>
              <Input
                id='userName'
                type='text'
                register={register('userName', { required: 'Please type in your full name' })}
                required
                className={errors.userName ? 'error' : ''}
              />
              {errors.userName && <ErrorText>{errors.userName.message}</ErrorText>}
            </FormItemWrap>

            <div className='row-input-wrap'>
              <FormItemWrap>
                <Label htmlFor='schoolType'>School Type</Label>
                <Select id='schoolType' register={register('schoolType', {})}>
                  <option>1</option>
                  <option>2</option>
                </Select>
                {errors.schoolType && <ErrorText>{errors.schoolType.message}</ErrorText>}
              </FormItemWrap>
              <FormItemWrap>
                <Label htmlFor='userGrade'>Grade</Label>
                <Select id='userGrade' register={register('userGrade', {})}>
                  <option>1</option>
                  <option>2</option>
                </Select>
                {errors.userGrade && <ErrorText>{errors.userGrade.message}</ErrorText>}
              </FormItemWrap>
            </div>

            <FormItemWrap>
              <Label htmlFor='schoolLocation'>School Location</Label>
              <Select id='schoolLocation' register={register('schoolLocation', {})}>
                <option>1</option>
                <option>2</option>
              </Select>
              {errors.schoolLocation && <ErrorText>{errors.schoolLocation.message}</ErrorText>}
            </FormItemWrap>

            <PrimaryButtonArea>
              <SubButton type='button' onClick={() => setBack(true)} title='이전 페이지'>
                Back
              </SubButton>
              <PrimaryButton type='submit' title='다음 페이지'>
                Next
              </PrimaryButton>
            </PrimaryButtonArea>
          </form>
        </MainBox>
      </S.Wrap>
      {/*뒤로가기 클릭 시 팝업*/}
      {isBack && <PopBack closePopBack={() => setBack(false)} />}
      {isLoading && <LoadingBar />}
    </>
  );
}

const S = {};

S.Wrap = styled.div`
  .guide {
    color: #7f8998;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
  }
  .row-input-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    & > *:nth-child(1) {
      flex: 1;
    }
    & > *:nth-child(2) {
      flex: 0 0 25%;
    }
  }
`;
