import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ImgSvgIconList } from 'assets/img/svg/icon_list_01.svg';
import { ReactComponent as ImgSvgIconRefresh } from 'assets/img/svg/icon_refresh_ccw_01.svg';
import Box from 'components/dashboard/_components/common/Box';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { STUDENT_PARAMS } from 'utils/constants';

/** 학생 대시보드 페이지에서 현재 선택 된 시험 이름을 보여주는 영역 컴포넌트 */
export default function SelectedTitle({ selectedTest, setSelectedTest, testListAreaPosition }) {
  const navigate = useNavigate();
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** 현재 선택 된 시험 세트의 UTH Seq */
  const selectedUthSeq = getObfuscatedValue(STUDENT_PARAMS.UTH_SEQ.KEY);

  /** 전체 스크롤 상태 */
  const [scrollY, setScrollY] = useState(0);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  /** 스크롤에 따라서 위치를 파악하고 싶은 요소 */
  const targetElementRef = useRef(null);

  /** 시험 목록 테이블로 스크롤 이동 */
  const handleReturnToTestList = (testListAreaPosition) => {
    /** 인수로 받은 포지션 top 값에 임의로 더하거나 빼고 싶은 값 (px 단위) */
    const optionTop = -120;
    window.scrollTo({ top: testListAreaPosition.top + optionTop, left: 0, behavior: 'smooth' });
    // window.location.href = '#testListArea';
  };
  /** 현재 선택 된 시험 선택 해제 (전체 통계) */
  const handleResetToDefault = () => {
    setSelectedTest(null);
    navigate(`/student/dashboard`);
  };

  /** 스크롤 이벤트 핸들러 */
  function onScroll() {
    setScrollY(window.scrollY);
  }
  useLayoutEffect(() => {
    if (targetElementRef.current) {
      const rect = targetElementRef.current.getBoundingClientRect();
      setPosition({ top: rect.top, left: rect.left });
    }

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <S.Wrap ref={targetElementRef} className={`${position.top < scrollY ? 'sticky' : ''}`}>
      <Box className='main_box'>
        <div className='text_area'>{selectedUthSeq && selectedTest?.testName ? selectedTest.testName : 'Your Overall Average'}</div>
        <div className='btn_area'>
          <button type='button' title='Return to Test List' onClick={() => handleReturnToTestList(testListAreaPosition)}>
            <ImgSvgIconList />
          </button>
          <button type='button' title='Reset to Default' onClick={handleResetToDefault}>
            <ImgSvgIconRefresh />
          </button>
        </div>
      </Box>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .main_box {
      position: relative;
      width: 100%;
      padding: 0;
      height: 3.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text_area {
      text-align: center;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2rem;
      color: #111111;
    }
    .btn_area {
      position: absolute;
      right: 2rem;
      top: 1rem;
      display: flex;
      gap: 1rem;
      button {
        color: #505050;
        svg {
          width: 2rem;
        }
      }
    }

    &.sticky {
      position: sticky;
      top: 6rem;
      left: 0;
      z-index: 10;
      width: 35.5rem;
      transform: translateX(17.75rem);

      .main_box {
        position: relative;
        margin: auto;
        width: 100%;
        border-radius: 4.25rem;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06);
      }
    }
  `,
};
