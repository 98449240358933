import styled from 'styled-components';
import { EDITOR_CONSTANT } from 'components/ap/question-bank/test-editor/_constant';

/** 소스 네비게이션 영역 버튼 컴포넌트 */
export default function SourcesForReview({ sourceData = [{ id: null, order: 0, content: '' }], currentOrder, handleClickReviewItem }) {
  return (
    <S.SourcesForReviewContainer>
      {sourceData.map((item, index) => {
        return (
          item.id !== null && (
            <SourcesForReviewItem
              key={`key_${item.order}`}
              isSelected={Number(currentOrder) === Number(item.order)}
              text={index + 1}
              onClick={() => handleClickReviewItem(item)}
            />
          )
        );
      })}
      {/* <SourcesForReviewItem isSelected={false} text='+' /> */}
    </S.SourcesForReviewContainer>
  );
}

/** 각 개별 소스 네비게이션 버튼 컴포넌트 */
function SourcesForReviewItem({
  isSelected = false,
  text = '1',
  onClick = () => {
    console.log('핸들러를 전달해 주세요.');
  },
}) {
  return (
    <S.SourcesForReviewItem className={`${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <p className='element'>{text}</p>
    </S.SourcesForReviewItem>
  );
}

// --- --- --- Styled components --- --- ---
const S = {
  SourcesForReviewContainer: styled.div`
    align-items: center;
    border: 1px solid #999;
    border-radius: 8px;
    display: flex;

    width: fit-content;

    overflow: hidden;

    .component {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border-right: 1px solid #999999;
      padding: 6px 24px;
      position: relative;
    }

    .text-wrapper {
      color: #0068bd;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }

    > button {
      border-right: 1px solid #999999;
      &:last-child {
        border-right: none;
      }
    }
  `,

  SourcesForReviewItem: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0068bd;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    width: 3.5rem;
    height: 1.875rem;
    cursor: pointer;

    .element {
      font-size: 13px;
      font-weight: 600;
      background-color: white;
    }
    &.selected {
      background-color: #004a9e;
      .element {
        color: white;
        background-color: #004a9e;
      }
    }
  `,
};
