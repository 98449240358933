//#region    ////////// components
import EquationsPanel from './_components/EquationsPanel';
import EquationsPanelContentContainer from './_components/EquationsPanelContentContainer';
import EquationsList from './_components/EquationsList';
import PrefixesEquationPanel from './_components/equationsContent/PrefixesEquationPanel';
//#endregion ////////// components

//#region    ////////// assets
import atomicStructure1 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/atomic_structure_1.png';
import atomicStructure2 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/atomic_structure_2.png';
import atomicStructure3 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/atomic_structure_3.png';
import gasesLiquidsSolutions1 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/gases_liquids_solutions_1.png';
import gasesLiquidsSolutions2 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/gases_liquids_solutions_2.png';
import gasesLiquidsSolutions3 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/gases_liquids_solutions_3.png';
import kinetics1 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/kinetics_1.png';
import kinetics2 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/kinetics_2.png';
import equilibrium1 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/equilibrium_1.png';
import equilibrium2 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/equilibrium_2.png';
import thermodynamicsElectrochemistry1 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/thermodynamics_electrochemistry_1.png';
import thermodynamicsElectrochemistry2 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/thermodynamics_electrochemistry_2.png';
import thermodynamicsElectrochemistry3 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/thermodynamics_electrochemistry_3.png';
import unitConversions1 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/unit_conversions_1.png';
import unitSymbols1 from 'assets/img/ap_exam/reference_sheet/equations/chemistry/unit_symbols_1.png';
//#endregion ////////// assets

/** 화학 레퍼런스 시트 수식 컴포넌트 */
export default function ChemistryEquations() {
  return (
    <EquationsList>
      <EquationsPanel
        panelHeaderText='Atomic Structure'
        PanelContent={<EquationsPanelContentContainer contents={[atomicStructure1, atomicStructure2, atomicStructure3]} containerHeight='45rem' />}
      />
      <EquationsPanel
        panelHeaderText='Gases, Liquids, and Solutions'
        PanelContent={
          <EquationsPanelContentContainer contents={[gasesLiquidsSolutions1, gasesLiquidsSolutions2, gasesLiquidsSolutions3]} containerHeight='85rem' />
        }
      />
      <EquationsPanel panelHeaderText='Kinetics' PanelContent={<EquationsPanelContentContainer contents={[kinetics1, kinetics2]} containerHeight='25rem' />} />
      <EquationsPanel
        panelHeaderText='Equilibrium'
        PanelContent={<EquationsPanelContentContainer contents={[equilibrium1, equilibrium2]} containerHeight='70rem' />}
      />
      <EquationsPanel
        panelHeaderText='Thermodynamics and Electrochemistry'
        PanelContent={
          <EquationsPanelContentContainer
            contents={[thermodynamicsElectrochemistry1, thermodynamicsElectrochemistry2, thermodynamicsElectrochemistry3]}
            containerHeight='73rem'
          />
        }
      />
      <EquationsPanel
        panelHeaderText='Unit Conversions'
        PanelContent={<EquationsPanelContentContainer contents={[unitConversions1]} containerHeight='18rem' />}
      />
      <EquationsPanel panelHeaderText='Unit Symbols' PanelContent={<EquationsPanelContentContainer contents={[unitSymbols1]} containerHeight='12rem' />} />
      <PrefixesEquationPanel />
    </EquationsList>
  );
}
