import React from 'react';
import styled from 'styled-components';

/** "Ap" - "Question Bank" - "Create Test" 페이지에서 사용 할 "Input" 요소 스타일 용 컴포넌트 */
export default function Input({ children, ...rest }) {
  return <S.Input {...rest}>{children}</S.Input>;
}

const S = {
  Input: styled.input`
    border: 1px solid #d2dbe2;
  `,
};
