import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import MainBox from 'components/user/_components/layouts/MainBox';
import Logo from 'components/user/_components/layouts/Logo';
import Header from 'components/user/sign-up/_components/layouts/Header';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import Label from 'components/user/_components/common/forms/Label';
import Select from 'components/user/_components/common/forms/Select';
import ErrorText from 'components/user/_components/styles/ErrorText';

import ImgIconKey from 'assets/gate_member/image/key_icon.svg';
import { getClientTimezone } from 'utils/functions/time/dayjs-config';
// import { matchPath } from 'react-router-dom';
// import TimezoneSelectBox from 'components/user/sign-up/_components/common/forms/TimezoneSelectBox';
// import request from 'utils/Request.utils';

/** "회원가입" - "타임존 설정" 페이지 스타일 용 컴포넌트 */
export default function SignUpTimezone({ timezoneArray, headerTitle, handleSubmit, onValid, register, errors, setValue, children }) {
  // 사용자 default timezone
  const userTimezone = getClientTimezone();
  // const userTimezone = 'Asia/Tokyo';
  // console.log('🚀 ~ SignUpTimezone ~ userTimezone:', userTimezone);

  // setValue('timezone', 12);
  if (!timezoneArray) {
    return <div>Loading</div>;
  }

  timezoneArray.map((item) => {
    if (item.timezone_name === userTimezone) {
      // timezoneArray에서 사용자의 timezone을 찾아서 해당 timezone의 seq값을 setValue
      console.log('item.tz_seq::', item.tz_seq);
      // setValue('timezone', item.tz_seq);
    }
  });

  return (
    <S.Wrap>
      <MainBox>
        <Logo />
        <Header title={headerTitle} />
        <h4 className='guide-title'>Where are you located?</h4>
        <p className='guide-text'>
          Your timezone will be applied to students linked to you.
          <br />
          You can change your settings later in Profile
        </p>
        <form onSubmit={handleSubmit(onValid)}>
          <Label htmlFor='timezone'>Timezone</Label>
          <Select
            id='timezone'
            onChange={(e) => {
              setValue('timezone', e.target.value);
            }}
            register={register('timezone', { require: ' 필수 입력 값입니다.' })}
            required
            autoFocus
            isError={''}>
            {timezoneArray.map((item) => (
              <option key={item.tz_seq} value={item.timezone_name} checked={true}>
                {item.timezone_name} (UTC{item.gmt_offset})
              </option>
            ))}
          </Select>
          {errors.companyName && <ErrorText>{errors.companyName.message}</ErrorText>}
          <PrimaryButtonArea>
            <PrimaryButton type='submit' title='다음 페이지'>
              Next
            </PrimaryButton>
          </PrimaryButtonArea>
        </form>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .guide-title {
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #7f8998;
  }
  .guide-text {
    margin-top: 0.5rem;
    text-align: left;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #7f8998;
  }
  label {
    margin-top: 0.75rem;
  }
  input {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    background-image: url(${ImgIconKey});
    background-repeat: no-repeat;
    background-position: 1rem center;
    padding-left: 3rem;
  }
`;
