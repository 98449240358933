import ReactQuillEditor from 'components/_common/test-editor/quilljs/ReactQuillEditor';
import { useEffect, useState } from 'react';
import localStorage from 'utils/LocalStorage.utils';

/** ap 시험 문제 풀이 영역 컴포넌트 */
export default function QuestionArea({ stateExamInfo, currentQuestionInfo, setQuestionInfo }) {
  const [answerOptions, setAnswerOptions] = useState({});
  const [isUndoActive, setIsUndoActive] = useState(false);

  useEffect(() => {
    if (!currentQuestionInfo?.answerOptions) return;
    setAnswerOptions(JSON.parse(currentQuestionInfo.answerOptions));
  }, [currentQuestionInfo]);

  // 북마크
  const bookmarkChangeHandler = (e) => {
    const isBookmarked = e.target.checked;
    const currentTestKey = `apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`;

    localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { isBookmarked });

    setQuestionInfo((prev) => {
      const updatedQuestionInfo = [...prev];
      updatedQuestionInfo[stateExamInfo.currentNum - 1] = {
        ...updatedQuestionInfo[stateExamInfo.currentNum - 1],
        isBookmarked,
      };
      return updatedQuestionInfo;
    });
  };

  // 객관식 선택 함수 (answerCount에 따라 단일/다중 선택 제어)
  const answerCheckHandler = (e, num) => {
    const { value: selectedKey, checked } = e.target; // 수정: 선택한 값이 key값이 되도록 (option1, option2...)
    const currentTestKey = `apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`;
    const currentAnswers = localStorage.getItemJsonParse(currentTestKey)?.questionList[stateExamInfo.currentNum - 1]?.selectAnswer || [];
    const { answerCount = 1 } = answerOptions;

    let updatedAnswers;
    if (answerCount > 1) {
      // 복수 선택 가능
      updatedAnswers = checked ? [...currentAnswers, selectedKey] : currentAnswers.filter((answer) => answer !== selectedKey);
    } else {
      // 단일 선택만 가능
      updatedAnswers = checked ? [selectedKey] : [];
    }

    // 로컬 스토리지 업데이트
    localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { selectAnswer: updatedAnswers });

    // 상태 업데이트
    setQuestionInfo((prev) => {
      const updatedQuestionInfo = [...prev];
      let targetQuestion = { ...updatedQuestionInfo[stateExamInfo.currentNum - 1], selectAnswer: updatedAnswers };

      // 답안을 선택할 때 취소선이 설정되어 있으면 해제
      if (checked) {
        const strikethroughKey = `strikethrough${num}`;
        if (targetQuestion[strikethroughKey] === true) {
          // 취소선 해제
          targetQuestion[strikethroughKey] = false;

          // 로컬 스토리지 업데이트
          localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { [strikethroughKey]: false });
        }

        // 선택한 답안의 취소선만 해제
        if (checked) {
          const strikethroughKey = `strikethrough${num}`;
          if (targetQuestion[strikethroughKey] === true) {
            // 취소선 해제
            targetQuestion[strikethroughKey] = false;

            // 로컬 스토리지 업데이트
            localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { [strikethroughKey]: false });
          }
        }
      }

      updatedQuestionInfo[stateExamInfo.currentNum - 1] = targetQuestion;
      return updatedQuestionInfo;
    });
  };

  // 객관식 선택 취소(취소선) 함수
  const dangerouslyToggle = (e, num) => {
    const strikethrough = `strikethrough${num}`;
    const currentTestKey = `apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`;
    const isStrikethrough = currentQuestionInfo && currentQuestionInfo[strikethrough] === true;
    const updatedValue = !isStrikethrough;

    // 현재 선택된 답안 (key값으로 저장된 상태)
    const currentAnswers = localStorage.getItemJsonParse(currentTestKey)?.questionList[stateExamInfo.currentNum - 1]?.selectAnswer || [];
    let updatedAnswers = [...currentAnswers];

    // 취소선 적용 시 해당 답안이 선택되어 있다면 제거
    const answerValue = e.target.value;
    if (updatedValue && updatedAnswers.includes(answerValue)) {
      updatedAnswers = updatedAnswers.filter((answer) => answer !== answerValue);
    }

    // 로컬 스토리지 업데이트 (취소선 상태와 선택 답안 둘 다 반영)
    localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, {
      [strikethrough]: updatedValue,
      selectAnswer: updatedAnswers,
    });

    // 상태 업데이트
    setQuestionInfo((prev) => {
      const updatedQuestionInfo = [...prev];
      updatedQuestionInfo[stateExamInfo.currentNum - 1] = {
        ...updatedQuestionInfo[stateExamInfo.currentNum - 1],
        [strikethrough]: updatedValue,
        selectAnswer: updatedAnswers,
      };
      return updatedQuestionInfo;
    });
  };

  // 서술형 답안 변경 핸들러 (ReactQuillEditor로 입력받은 값을 저장)
  const handleEssayChange = (value) => {
    const currentTestKey = `apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`;

    // 로컬 스토리지 업데이트
    localStorage.updateItemFieldAtIndex(currentTestKey, 'questionList', stateExamInfo.currentNum - 1, { answerContent: value });

    // 상태 업데이트
    setQuestionInfo((prev) => {
      const updated = [...prev];
      updated[stateExamInfo.currentNum - 1] = {
        ...updated[stateExamInfo.currentNum - 1],
        answerContent: value,
      };
      return updated;
    });
  };

  return (
    <article className={`wrap ${isUndoActive ? 'undo_active' : ''}`}>
      <div className='scroll_wrap'>
        <div className='inner'>
          <div className='top_area'>
            <div className='num'>{stateExamInfo.currentNum}</div>
            <div className='bookmark'>
              <input
                type='checkbox'
                id='bookmark1'
                className='btn_bookmark'
                onChange={bookmarkChangeHandler}
                checked={currentQuestionInfo?.isBookmarked === true}
              />
              <label htmlFor='bookmark1'>Mark for Review</label>
            </div>
            <button className={`btn_undo ${isUndoActive ? 'undo_active' : ''}`} onClick={() => setIsUndoActive((prev) => !prev)}>
              <i className='svg_icon icon_undo'>&nbsp;</i>
              <div className='tip_layer'>Cross out answer choices you think are wrong.</div>
            </button>
          </div>
          <div className='text'>
            <div dangerouslySetInnerHTML={{ __html: currentQuestionInfo?.questionContent }} />
          </div>
          <div id='answerArea' className='answer_area'>
            {/* 객관식 */}
            {currentQuestionInfo?.questionType === 'M' && (
              <>
                {Array.from({ length: answerOptions?.total || 0 }, (_, index) => {
                  const num = index + 1;
                  const answerKey = `option${num}`; // key값
                  const answerLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

                  // key값을 이용하여 선택 여부 판단
                  const isSelected = Array.isArray(currentQuestionInfo?.selectAnswer) ? currentQuestionInfo.selectAnswer.includes(answerKey) : false;
                  const strikethrough = `strikethrough${num}`;
                  const isStrikethrough = strikethrough in currentQuestionInfo ? currentQuestionInfo[strikethrough] : false;

                  return (
                    <div key={num} className={isStrikethrough ? 'item undo_selected' : 'item'}>
                      <input
                        type='checkbox'
                        name='answer1'
                        id={`answer1_${num}`}
                        className='radio_answer'
                        value={answerKey} // key값으로 저장
                        checked={isSelected}
                        onChange={(e) => answerCheckHandler(e, num)}
                      />
                      <label className='answer' htmlFor={`answer1_${num}`}>
                        <span className='num'>{answerLabels[index]}</span>
                        <div dangerouslySetInnerHTML={{ __html: answerOptions[answerKey] }} />
                      </label>
                      <button className='num' onClick={(e) => dangerouslyToggle(e, num)} value={answerKey}>
                        {answerLabels[index]}
                      </button>
                    </div>
                  );
                })}
              </>
            )}

            {/* 서술형 */}
            {currentQuestionInfo?.questionType === 'E' && (
              <div className='editor_area'>
                <ReactQuillEditor editorContentState={currentQuestionInfo?.answerContent} setEditorContentState={handleEssayChange} />
              </div>
            )}
          </div>
        </div>
      </div>
    </article>
  );
}
