import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import request from 'utils/Request.utils';
import { USER_LEVELS } from 'utils/constants.js';

//components
import Login from 'components/user/sign-in/Page';

import FindEmailPage from 'components/user/sign-in/find/email/Page';
import FindEmailCompletePage from 'components/user/sign-in/find/email/complete/Page';
import FindPasswordPage from 'components/user/sign-in/find/password/Page';
import FindPasswordCompletePage from 'components/user/sign-in/find/password/complete/Page';
import ResetPasswordPage from 'components/user/sign-in/password-reset/Page';
import ResetPasswordCompletePage from 'components/user/sign-in/password-reset/complete/Page';
import SocialLoginGoogle from 'components/user/sign-in/SocialLoginGoogle';
import SocialLoginFacebook from 'components/user/sign-in/SocialLoginFacebook';
import SocialLoginApple from 'components/user/sign-in/SocialLoginApple';
import AgreePage from 'components/user/sign-in/AgreePage';

const SigninRoute = () => {
  const userInfo = request.tokenDecoder();

  let { pathname } = window.location;
  let authBool = false;

  authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    if (authBool) {
      let redirectUrl;

      if (userInfo.userLevel === USER_LEVELS.STUDENT) {
        redirectUrl = '/student/dashboard';
      } else {
        redirectUrl = '/academy/dashboard';
      }

      const authRoutes = [
        { path: '/', element: <Navigate to={redirectUrl} /> },
        { path: '/login', element: <Navigate to={redirectUrl} /> },
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/', element: <Login /> },
        { path: '/find/email', element: <FindEmailPage /> },
        { path: '/find/email/complete', element: <FindEmailCompletePage /> },
        { path: '/find/passwd', element: <FindPasswordPage /> },
        { path: '/find/passwd/complete', element: <FindPasswordCompletePage /> },
        { path: '/passwd/reset', element: <ResetPasswordPage /> },
        { path: '/passwd/reset/complete', element: <ResetPasswordCompletePage /> },
        { path: '/social/google', element: <SocialLoginGoogle /> },
        { path: '/social/facebook', element: <SocialLoginFacebook /> },
        { path: '/social/apple', element: <SocialLoginApple /> },
        { path: '/agree', element: <AgreePage /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };

  const signinGoogle = pathname.toLowerCase().startsWith('/signin/social/google');

  const renderFooter = () => {
    if (signinGoogle) return null;
    return <div className='copyright'>Copyright © 2023 Gate⁺ All rights reserved.</div>;
  };

  return (
    <>
      <Routes> {AuthorizationRoutes()} </Routes>
      {renderFooter()}
    </>
  );
};

export default SigninRoute;
