import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useAnnotation } from 'hooks/useAnnotation';
import Tooltip from './Tooltip';
import AnnotationBottom from './AnnotationBottom';
import QuillEditorContainer from 'components/ap/_components/common/QuillEditorContainer';

export default function SourcesForReview({
  source,
  currentQuestionOrder,
  isAnnotation: parentIsAnnotation,
  setIsAnnotation: setParentIsAnnotation,
  isDragged,
  contentRef,
  editSource,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const tooltipRef = useRef(null);

  // useAnnotation 훅 사용 - 모든 주석 로직 캡슐화
  const {
    initializeRangy,
    handleAnnotateSelection,
    reattachAnnotationEvents,
    handleSaveAnnotation,
    handleDeleteAnnotation,
    handleCancelAnnotation,
    selectedAnnotation,
    isShowAnnotationInput,
    annotationMemo,
    setAnnotationMemo,
    isAnnotation,
    setIsAnnotation,
  } = useAnnotation({
    containerRef: contentRef,
    tooltipRef,
  });

  // source가 변경될 때 activeTab을 0으로 초기화
  useEffect(() => {
    setActiveTab(0);
  }, [currentQuestionOrder]);

  // 컴포넌트 마운트 시 rangy 초기화
  useEffect(() => {
    initializeRangy();
  }, [initializeRangy]);

  // 부모 컴포넌트의 isAnnotation 상태와 동기화
  useEffect(() => {
    setIsAnnotation(parentIsAnnotation);
  }, [parentIsAnnotation, setIsAnnotation]);

  // isAnnotation이 변경되었을 때 실행
  useEffect(() => {
    if (isAnnotation && isDragged && contentRef.current.contains(window.getSelection().anchorNode)) {
      handleAnnotateSelection();
    }
  }, [isAnnotation, isDragged, handleAnnotateSelection, contentRef]);

  // source가 변경될 때 이벤트 다시 연결
  useEffect(() => {
    // DOM이 업데이트된 후 실행하기 위해 setTimeout 사용
    const timer = setTimeout(() => {
      reattachAnnotationEvents();
    }, 100);
    return () => clearTimeout(timer);
  }, [source, activeTab, reattachAnnotationEvents]);

  // 현재 훅의 상태가 변경되면 부모 컴포넌트에게 알림
  useEffect(() => {
    setParentIsAnnotation(isAnnotation);
  }, [isAnnotation, setParentIsAnnotation]);

  // 각 동작에 editSource와 현재 소스 정보 전달
  const onSaveAnnotation = () => handleSaveAnnotation(editSource, source[activeTab].sourceId);
  const onDeleteAnnotation = () => handleDeleteAnnotation(editSource, source[activeTab].sourceId);

  return (
    <S.Container>
      {source.length > 1 && (
        <>
          <h1>Sources for Review</h1>
          <S.Tabs>
            {source.map((_, index) => (
              <S.TabLabel key={index + 1} className={activeTab === index ? 'active' : ''}>
                <input type='radio' name='tabs' checked={activeTab === index} onChange={() => setActiveTab(index)} />
                {index + 1}
              </S.TabLabel>
            ))}
          </S.Tabs>
        </>
      )}
      {/* source 뒤에 몇번째 탭인지 */}
      Document {activeTab + 1}
      <div ref={contentRef}>
        {source.map(
          (tab, index) =>
            activeTab === index && (
              <S.TabContent key={index + 1}>
                <QuillEditorContainer content={tab.sourceContent} draggable />
              </S.TabContent>
            )
        )}
      </div>
      <Tooltip ref={tooltipRef} />
      {isShowAnnotationInput && (
        <AnnotationBottom
          handleDeleteAnnotation={onDeleteAnnotation}
          handleSaveAnnotation={onSaveAnnotation}
          handleCancelAnnotation={handleCancelAnnotation}
          annotateInfo={selectedAnnotation}
          annotateMemo={annotationMemo}
          setAnnotateMemo={setAnnotationMemo}
          isNewAnnotation={selectedAnnotation?.memo === ''}
        />
      )}
    </S.Container>
  );
}

// Styled Components
const S = {
  Container: styled.div`
    margin: 20px auto;
    padding: 20px;
  `,
  Tabs: styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  `,
  TabLabel: styled.label`
    padding: 10px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #f9f9f9;

    input {
      display: none;
    }

    &.active {
      background-color: #007bff;
      color: #fff;
      font-weight: bold;
    }

    .rangy-highlight {
      background-color: #fae89d;
      border-bottom: 1px dashed black;
    }
    .rangy-highlight-hover {
      background-color: #fad021;
    }
    .rangy-highlight-selected {
      background-color: #fad021;
    }
  `,
  TabContent: styled.div`
    animation: fadeIn 0.3s ease-in-out;
    p {
      word-break: break-word !important;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `,
};
