import React from 'react';
import styled from 'styled-components';

/** "회원 가입"페이지의 select 요소 컴포넌트 */
export default function Select({ register, isError, children, ...rest }) {
  return (
    <S.Select {...register} className={isError ? 'error' : ''} {...rest}>
      {children}
    </S.Select>
  );
}

const S = {};

S.Select = styled.select`
  border: 1px solid #ececec;
  height: 2.75rem;
  width: 100%;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #111111;

  &.error {
    border-color: #f24b4b;
  }
  &:focus.error {
    border-color: #f24b4b;
  }
`;
