import styled from 'styled-components';

/** 아카데미 대시보드 - Students - Main Table - "class 목록" 모달 */
export default function SubjectModal({ subjectList, setShowModal }) {
  return (
    <S.SubjectModalContainer onClick={(event) => event.stopPropagation()}>
      <div className='modal_title_container'>
        <div className='modal_title_area'>
          <h2>Subject</h2>
        </div>
        <button className='modal_title_btn' onClick={() => setShowModal(false)}>
          <i className='svg_icon btn_pop_close black'>&nbsp;</i>
        </button>
      </div>
      <div className='contents_area'>
        <div className='custom_scroll'>
          {subjectList && subjectList.length > 0 ? (
            <ul>
              {subjectList.map((item, index) => {
                return <li key={`item_${index}`}>{item.subject}</li>;
              })}
            </ul>
          ) : (
            <>no data</>
          )}
        </div>
      </div>
    </S.SubjectModalContainer>
  );
}

const S = {
  SubjectModalContainer: styled.div`
    position: absolute;
    top: 120%;
    left: 0;
    padding: 0.9375rem;
    width: 15rem;
    height: 12.0625rem;
    border-radius: 0.625rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
    box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.15);
    z-index: 100;

    .modal_title_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        color: #111;
        font-family: Roboto;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.25rem; /* 142.857% */
        text-transform: capitalize;
      }

      .modal_title_btn {
        .svg_icon {
          max-width: 1rem;
          max-height: 1rem;
          min-width: 1rem;
          min-height: 1rem;
        }
      }
    }
    .contents_area {
      background-color: #fafafa;
      overflow-y: hidden;
      border-radius: 0.5rem;
      max-height: 8rem;
      .custom_scroll {
        overflow-y: auto;
        height: 8rem;
      }
      li {
        padding: 1rem 0.5rem;
        font-family: Roboto;
        font-size: 0.75rem;
      }
    }
  `,
};
