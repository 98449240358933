import ScoreCircleChart from 'components/_common/chart/ScoreCircleChart';
import DonutPieChart from 'components/_common/chart/DonutPieChart';
import React from 'react';
import CountUp from 'react-countup';
import styled from 'styled-components';
import { calculatePercentage } from 'utils/Common.utils';

export default function ChartContainer({ subject, fillColor, chartData, stats }) {
  const currentDonutPieChartData = chartData.chart02.data.find((item) => item.label === chartData.chart02.label).value;
  return (
    <S.SubjectContainer>
      <h3>{subject}</h3>
      <div className='answer_data_container'>
        <S.ChartContainer>
          <div className='chart_wrapper horizontal_padding'>
            <ScoreCircleChart
              rank={calculatePercentage(chartData.chart01.score, chartData.chart01.totalScore)}
              size='9.125rem'
              chartStyle={{
                countAnimation: true,
                fillOptions: {
                  fillColor: fillColor,
                },
                chartHollowSize: '50%',
              }}
              CustomLabel={
                <S.ChartLabel>
                  <CountUp start={0} end={chartData.chart01.score} duration={3} className='label-value' />
                  <p className='label-name'>{chartData.chart01.label}</p>
                </S.ChartLabel>
              }
            />
          </div>
          <div className='chart_wrapper'>
            <DonutPieChart
              data={chartData.chart02.data}
              size='9.125rem'
              handleClickData={chartData.chart02.handleClickData}
              CustomLabel={
                <S.ChartLabel>
                  <div className='label-value'>
                    <CountUp start={0} end={currentDonutPieChartData} duration={3} />
                    <p>%</p>
                  </div>
                  <p className='label-name'>{chartData.chart02.label}</p>
                </S.ChartLabel>
              }
            />
          </div>
        </S.ChartContainer>
        <S.AnswerDataListSection>
          {stats.map((stat, index) => (
            <li
              key={index}
              onClick={() => {
                chartData.chart02.handleClickData && chartData.chart02.handleClickData(index);
              }}>
              <div className='answer_label'>
                <i className={`circle_icon ${stat.iconClass}`} />
                <p>{stat.label}</p>
              </div>
              <p className='value_test'>{stat.value}</p>
            </li>
          ))}
        </S.AnswerDataListSection>
      </div>
    </S.SubjectContainer>
  );
}

const S = {
  SubjectContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ChartContainer: styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-left: 3.25rem;
  `,
  ChartLabel: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .label-value {
      display: flex;
      font-size: 2rem;
      font-weight: 700;
      line-height: 118%;
    }
    .label-name {
      font-size: 0.8125rem;
      color: #4b5563;
    }
  `,
  AnswerDataListSection: styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.75rem;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      border-radius: 10px;
      padding: 0 0.25rem;
      &:hover {
        background-color: #f1f1f5;
      }
      .answer_label {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        .circle_icon {
          border-radius: 50%;
          width: 0.75rem;
          height: 0.75rem;
          border: 1px solid #ebebeb;
          background-color: #fafaf8;

          &.incorrect {
            background-color: #929292;
          }
          &.rw {
            background-color: #008cff;
          }
          &.math {
            background-color: #1fdf9f;
          }
        }
      }
      .value_test {
        font-weight: 600;
      }
    }
  `,

  Divider: styled.hr`
    border: 0;
    border-top: 1px solid #f1f1f5;
  `,
};
