import { forwardRef } from 'react';
import styled from 'styled-components';

/** Quill 에디터로 작업한 text를 랜더링 해주기 위한 컴포넌트 */
const QuillEditorContainer = forwardRef(({ content, className = '', draggable = false, ...props }, ref) => {
  return (
    <S.EditorContainer
      ref={ref}
      className={`ql-container ql-snow custom-quill-container ${draggable ? 'draggable' : ''} ${className}`}
      dangerouslySetInnerHTML={{
        __html: `<div class="ql-editor">${content?.replace('/common/commonImageView.do', `${process.env.REACT_APP_API_URL}/api/common/commonImageView`) ?? ''}</div>`,
      }}
      {...props}
    />
  );
});

const S = {
  EditorContainer: styled.div`
    width: 100%;
    height: 100%;
    cursor: default;
    user-select: none;
    // 기본 폰트 설정
    font-family: 'Noto Serif';
    font-size: 14px;

    // 수학 공식 입력시 스크롤 생기는 문제 해결
    .ql-editor {
      overflow: visible;
      height: auto;
      min-height: 100%;
    }

    .ql-font-noto-serif {
      font-family: 'Noto Serif' !important;
    }
    .ql-font-sans-serif {
      font-family: sans-serif !important;
    }
    .ql-font-monospace {
      font-family: monospace !important;
    }
    .ql-font-courier-prime,
    .ql-code-block-container {
      font-family: 'Courier Prime', monospace !important;
    }

    * {
      cursor: default;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
    // quill 에디터 기본 스타일 덮어 씌우기용 className
    &.custom-quill-container {
      border: none;
    }
    // 하이라이트를 위한 드래그 가능 스타일 추가
    &.draggable {
      * {
        cursor: text;
        user-select: text;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
      }
    }
  `,
};

export default QuillEditorContainer;
