import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';

/** 모달 "드래그" 기능 구현용 HOC 컴포넌트 */
export default function DraggableModal({ children }) {
  const dragWrapperRef = useRef(null);
  const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: { x: 0, y: 0 },
    controlledPosition: { x: -400, y: 200 },
  });

  const onStart = () => setState({ ...state, activeDrags: state.activeDrags + 1 });
  const onStop = () => setState({ ...state, activeDrags: state.activeDrags - 1 });
  const dragHandlers = { onStart, onStop };

  return (
    <Draggable nodeRef={dragWrapperRef} {...dragHandlers}>
      <div ref={dragWrapperRef}>{children}</div>
    </Draggable>
  );
}
