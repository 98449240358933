import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import LoadingBar from 'utils/LoadingBar';

//component
import CustomAlert from 'components/_common/alerts/CustomAlert';
import MainBox from 'components/user/_components/layouts/MainBox';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import Header from 'components/user/sign-up/_components/layouts/Header';
import Upload from 'components/user/sign-up/_components/pages/upload/_Upload';
import { USER_LEVELS } from 'utils/constants';

/** 회원가입 - 일반 이메일 회원 가입 - Tutor - File Upload 페이지 */
export default function SignUpEmailTutorUploadPage() {
  const navigate = useNavigate();
  const stateCustomer = useSelector((state) => state.Customer);
  // console.log('🚀 ~ SignUpEmailTutorUploadPage ~ stateCustomer:', stateCustomer);

  const [uploadFileList, setUploadFileList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  // 알랏
  const [alertLayerPopup, setAlertLayerPopup] = useState({
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    activateMode: '',
    value: 0,
  });

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        alertType: 'alert',
        visible: false,
        alertMessage: '',
        activateMode: '',
        value: 0,
      };
    });
  };

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
    }
  };

  const signup = () => {
    setLoading(true);
    // let params = {
    //   userId: stateCustomer.userEmail,
    //   userPasswd: stateCustomer.userPasswd,
    //   userEmail: stateCustomer.userEmail,
    //   userName: stateCustomer.userName,
    //   userNickname: stateCustomer.userName,
    //   userLevel: stateCustomer.userLevel,
    //   managerLevel: '002002000000000',
    //   userMobile: stateCustomer.userMobile,
    //   acceptReceivingMail: stateCustomer.mailYn,
    //   userStatus: '005001000000000',
    //   regUserSeq: 1,
    //   requestedAcademyName: stateCustomer.companyName,
    //   requestedAcademyContact: stateCustomer.companyNum,
    // };
    const payload = {
      userId: stateCustomer.userEmail,
      loginType: 'email',
      userEmail: stateCustomer.userEmail,
      userLevel: USER_LEVELS.TUTOR,
      userMobile: stateCustomer.userMobile,
      userName: stateCustomer.userName,
      tutoringName: stateCustomer.companyName,
      user_passwd: stateCustomer.userPasswd,
      agree_privacyPolicy: true,
      agree_termsService: stateCustomer.marketingYn === 'Y' ? true : false,
      agree_newsLetter: stateCustomer.mailYn === 'Y' ? true : false,
      isNew: true,
    };

    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);

      if (response.code === 200) {
        let userSeq = response.result.userSeq;
        uploadActivate(userSeq);
      } else {
        setLoading(false);
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: response.message,
          };
        });
      }
    };

    request.post(request.apiUris.auth.signupTutor, payload, successHandler);
  };

  const uploadActivate = (userSeq) => {
    // console.log("전송", uploadFileList)

    const formData = new FormData();

    Object.values(uploadFileList).forEach((file) => formData.append('uploadFiles', file.fileData));

    // for(const fileInfo of uploadFileList) {
    //   console.log(fileInfo.fileTextData, fileInfo.fileData)
    // }

    const successHandler = (response) => {
      setLoading(false);
      navigate('/signup/successfully-submitted');
    };

    request.postMultipart(`/api/common/upload/files/academy/${userSeq}`, formData, successHandler);
  };

  const submitBtn = () => {
    if (uploadFileList.length > 0) {
      signup();
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please upload the file.',
        };
      });
    }
  };

  return (
    <>
      <MainBox>
        <S.Wrap>
          <Header title={'Tutor'} />
          <Upload uploadFileList={uploadFileList} setUploadFileList={setUploadFileList} setAlertLayerPopup={setAlertLayerPopup} userPermission={'tutor'} />
          <PrimaryButtonArea>
            <PrimaryButton type='button' onClick={submitBtn} title='제출'>
              Submit
            </PrimaryButton>
          </PrimaryButtonArea>
        </S.Wrap>
      </MainBox>
      {isLoading && <LoadingBar />}
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
    </>
  );
}

const S = {};

S.Wrap = styled.div``;
