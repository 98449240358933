//#region    ////////// components
import EquationsPanel from './_components/EquationsPanel';
import EquationsPanelContentContainer from './_components/EquationsPanelContentContainer';
import EquationsList from './_components/EquationsList';
//#endregion ////////// components

//#region    ////////// assets
import stringClass1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_a/string_class_1.png';
import integerClass1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_a/integer_class_1.png';
import doubleClass1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_a/double_class_1.png';
import mathClass1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_a/math_class_1.png';
import arrayListClass1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_a/array_list_class_1.png';
import objectClass1 from 'assets/img/ap_exam/reference_sheet/equations/computer_science_a/object_class_1.png';
//#endregion ////////// assets

/** computer science A 레퍼런스 시트 수식 컴포넌트 */
export default function ComputerScienceAEquations() {
  return (
    <EquationsList>
      <EquationsPanel panelHeaderText='String Class' PanelContent={<EquationsPanelContentContainer contents={[stringClass1]} containerHeight='39rem' />} />
      <EquationsPanel panelHeaderText='Integer Class' PanelContent={<EquationsPanelContentContainer contents={[integerClass1]} containerHeight='24rem' />} />
      <EquationsPanel panelHeaderText='Double Class' PanelContent={<EquationsPanelContentContainer contents={[doubleClass1]} containerHeight='15rem' />} />
      <EquationsPanel panelHeaderText='Math Class' PanelContent={<EquationsPanelContentContainer contents={[mathClass1]} containerHeight='30rem' />} />
      <EquationsPanel
        panelHeaderText='ArrayList Class'
        PanelContent={<EquationsPanelContentContainer contents={[arrayListClass1]} containerHeight='38rem' />}
      />
      <EquationsPanel panelHeaderText='Object Class' PanelContent={<EquationsPanelContentContainer contents={[objectClass1]} containerHeight='15rem' />} />
    </EquationsList>
  );
}
