import React, { useRef, useState, useEffect } from 'react';
import AgreeAccountCreatedByThirdPerson from 'components/user/sign-in/_components/AgreeAccountCreatedByThirdPerson';
import styled from 'styled-components';
import MainBox from 'components/user/_components/layouts/MainBox';
import { useSelector } from 'react-redux';

/** "약관 동의" 페이지 */
export default function Agree() {
  const [error, setError] = useState(false);
  const [submitData, setSubmitData] = useState({
    userEmail: '',
    isSocial: true,
  });
  const userSocial = useSelector((state) => state.userReducer.social);
  // userSocial.userId 가 있을 경우 정상접근 아닌경우 404 페이지로 이동
  // const dispatch = useDispatch();
  useEffect(() => {
    console.log('userSocial:: ', userSocial);
    document.body.classList.add('gate_member'); //현재 페이지에서만 addClass
    // 현재 페이지 직접 접속이나 아니면 시간이 만료 된경우 백엔드에서 체크 가능한가?
    if (userSocial.userId) {
      setSubmitData({
        userEmail: userSocial.userId,
        isSocial: true,
      });
    }
  }, [userSocial]);
  useEffect(() => {
    if (error) {
      window.location.href = '/404';
    }
  }, [error]);
  return (
    <>
      <S.SignInForm>
        <MainBox className='main-box'>
          <AgreeAccountCreatedByThirdPerson submitData={submitData} />
        </MainBox>
      </S.SignInForm>
    </>
  );
}

const S = {};

S.SignInForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .main-box {
    border-radius: 0.25rem;
    box-shadow: none;
  }
  .title {
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    color: #a5aab1;
    margin-bottom: 0.5rem;
  }
  .input-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }
  .button-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 640px) {
      flex-direction: column;
      align-items: start;
      gap: 1rem;
    }
  }
  .forgot-button-area {
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    a {
      display: inline-block;
      color: #505050;
      & + a::before {
        content: '';
        display: inline-block;
        height: 0.625rem;
        width: 1px;
        background-color: #a6b3be;
        margin: 0 0.625rem;
      }
    }

    @media screen and (max-width: 640px) {
      width: 100%;
      justify-content: end;
    }
  }
  .sns-button-area {
    margin: 2rem 0 1rem;
    display: flex;
    justify-content: center;
    gap: 3rem;
    button {
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
    }
  }
  .sign-up-btn-area {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
  }

  .error_message {
    text-align: left;
    margin-bottom: 1rem;
    color: var(--sub-red);
    font-family: Roboto;
    font-size: 0.725rem;
  }
`;
