import React from 'react';
import styled from 'styled-components';

/** "/com/score" 페이지 상단 영역 (셀렉트, Test List, 텝 등) 스타일 용 고차(HOC) 컴포넌트
 * (단순히 스타일 용 컴포넌트를 위하여, Props 드릴링을 늘리는 것은 비 효율적이므로 고차 컴포넌트로 만들어서 자식 요소만을 props로 받는다.)
 */
export default function MainTopArea({ children }) {
  return <S.Wrap>{children}</S.Wrap>;
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 0 0 auto;
  & > * {
    flex: auto;
  }
`;
