import { useEffect } from 'react';
import 'styles/css/exam.css';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';

/** 모듈 이 끝났을 때, 잠시 노출되는 컴포넌트 */
const ModuleOver = ({ showModuleOver, setStateExamInfo }) => {
  useEffect(() => {
    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
    let sessionOrder = localStorage.getItemJsonParse(`apTest_${uthSeq}`).sessionOrder;
    // student test result 업데이트
    const hasExecutedKey = `moduleOverExecuted_${uthSeq}`;
    const hasExecuted = localStorage.getItem(hasExecutedKey);
    // 실행 여부 확인
    if (showModuleOver && !hasExecuted) {
      localStorage.setItem(hasExecutedKey, true);

      const questionList = localStorage.getItemJsonParse(`apTest_${uthSeq}`).questionList;

      const params = {
        questions: questionList,
      };

      const successHandler = (response) => {
        if (response.code === 200) {
          sessionOrder += 1;

          localStorage.removeItem(`moduleOverExecuted_${uthSeq}_${sessionOrder}`);
          insertQuestion(uthSeq, sessionOrder);
        }
      };

      request.apPut('/api/v1/student/test', params, successHandler);
    } else {
      console.warn('module over already executed');
      setStateExamInfo((prev) => ({ ...prev, showModuleOver: false, currentNum: 1, isReviewPopupOpen: false }));
    }

    return () => {
      localStorage.removeItem(hasExecutedKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const insertQuestion = (uthSeq, sessionOrder) => {
    const params = {
      uthSeq: uthSeq,
      sessionOrder: sessionOrder,
    };

    const successHandler = (response) => {
      if (response.code === 201 || response.code === 409) {
        getQuestion(uthSeq, sessionOrder);
      }
    };

    request.apPost('/api/v1/student/test', params, successHandler);
  };

  const getQuestion = (uthSeq, sessionOrder) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const { testSessionName, questionList, totalQuestionCount, totalTime } = response.result.getTestSessionQuestionList;

        localStorage.setItem(`apTest_${uthSeq}`, 'sessionOrder', sessionOrder); // 세션 순서 업데이트
        localStorage.setItem(`apTest_${uthSeq}`, 'sessionName', testSessionName);
        localStorage.setItem(`apTest_${uthSeq}`, 'questionList', questionList);
        localStorage.setItem(`apTest_${uthSeq}`, 'totalQuestionCount', totalQuestionCount);
        localStorage.setItem(`apTest_${uthSeq}`, 'currentNum', 1);
        localStorage.setItem(`apTest_${uthSeq}`, 'totalTime', totalTime);
        localStorage.setItem(`apTest_${uthSeq}`, 'startTime', Date.now());
      }
      setStateExamInfo((prev) => ({ ...prev, showModuleOver: false, currentNum: 1, isReviewPopupOpen: false }));
      localStorage.removeItem(`moduleOverExecuted_${uthSeq}`);
    };

    request.apGet(`/api/v1/student/test?uthSeq=${uthSeq}&sessionOrder=${sessionOrder}`, null, successHandler);
  };

  return (
    <div className='com_popup type_normal active pop_module_over'>
      <div className='pop_container'>
        <section className='pop_body'>
          <div className='dsat-intro-2_main-flex-container'>
            <h1>This Module is over</h1>
            <p>
              All your work has been saved.
              <br />
              You’ll move on automatically in just a moment.
              <br />
              Do not refresh this page or quit the app.
            </p>
          </div>
          <div className='dsat-intro-2_loading-container'>
            <div className='dsat-intro-2_loader'>
              <div className='dsat-intro-2_loading-spinning-bubbles'>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
                <div className='dsat-intro-2_bubble-container'>
                  <div className='dsat-intro-2_bubble'></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ModuleOver;
