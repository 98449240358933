import { useEffect, useRef } from 'react';

/** (ap 시험)문제 입력 방법 가이드 툴팁 컴포넌트 */
export default function DirectionsPopup({ setIsDirectionsPopup }) {
  const directionRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (directionRef.current && !directionRef.current.contains(event.target)) {
        setIsDirectionsPopup(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <article ref={directionRef} className='direction_layer layer'>
      <div className='scroll'>
        The questions in this section address a number of important reading and writing skills. Each question includes one or more passages, which may include a
        table or graph. Read each passage and question carefully, and then choose the best answer to the question based on the passage(s).
        <br />
        All questions in this section are multiple-choice with four answer choices. Each question has a single best answer.
      </div>
      <div className='btn_field'>
        <button className='btn close' onClick={() => setIsDirectionsPopup(false)}>
          Close
        </button>
      </div>
    </article>
  );
}
