import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

//component
import Header from 'components/user/_components/layouts/_Header';
import MainBox from 'components/user/_components/layouts/MainBox';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';

/** "비밀 번호 재설정 완료" 페이지 */
export default function ResetPasswordCompletePage() {
  const navigate = useNavigate();

  /** "Go back to Sign in" 버튼 핸들러 */
  const handleGoBack = () => {
    navigate('/signin');
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <S.Wrap>
      <MainBox>
        <Header>
          <p className='txt'>The door to educational aspiration</p>
        </Header>
        <section className='top-text-area'>
          <h4 className='forgot_title'>Your password has been reset</h4>
          <p className='txt'>Please sign in again with your new password.</p>
        </section>
        <PrimaryButtonArea>
          <PrimaryButton type='button' title='페이지 이동' onClick={handleGoBack}>
            Go back to Sign in
          </PrimaryButton>
        </PrimaryButtonArea>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .top-text-area {
    text-align: left;
    color: #7f8998;
    .forgot_title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .txt {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-top: 0.5rem;
    }
  }
`;
