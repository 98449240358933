import useOutsideClick from 'hooks/useOutsideClick';
import { useRef, useState } from 'react';

/**  */
export default function SelectSubjectBox({ selectedModuleInfo, handleSelectSubject, activeSatBox }) {
  const [isShowSelectSubjectLayer, setIsShowSelectSubjectLayer] = useState(false);

  /** Correct answer (rate) by Questions의 header에 있는 과목 선택을 위한 객체 */
  const optionData = [
    {
      value: 'RW',
      label: 'RW',
      isShowOption: selectedModuleInfo?.setSubject !== 'M', // 시험세트가 M(수학)이 아닐때 RW 노출 ( F(풀세트), E(영어) 일때 노출 )
    },
    {
      value: 'MATH',
      label: 'MATH',
      isShowOption: selectedModuleInfo?.setSubject !== 'E', // 시험세트가 E(영어)가 아닐때 MATH 노출 ( F(풀세트), M(수학) 일때 노출 )
    },
  ];

  const subjectSelectBox = useRef();
  useOutsideClick(subjectSelectBox, () => setIsShowSelectSubjectLayer(false));

  return (
    <div
      className={`com_select_layer ${isShowSelectSubjectLayer ? 'active' : ''}`}
      onClick={() => setIsShowSelectSubjectLayer((prev) => !prev)}
      ref={subjectSelectBox}>
      {/*클래스 active 포함 시 옵션 노출*/}
      <section className='selected__wrap'>
        <input type='text' className='selected' placeholder={activeSatBox} size='5' />
      </section>
      <section className='layer'>
        {optionData.map((item, index) => {
          // option item의 키를 확인하고 early return
          if (!item.isShowOption) return;

          return (
            <p className='option' key={`${item}-${index}`} onClick={() => handleSelectSubject(item.value)}>
              {item.label}
            </p>
          );
        })}
      </section>
    </div>
  );
}
