import { createSlice } from '@reduxjs/toolkit';

/** miniAlert 슬라이스 */
export const miniAlertSlice = createSlice({
  name: 'miniAlert',
  initialState: {
    message: null,
    isVisible: false,
    duration: 1500, // 기본값 1.5초
  },
  reducers: {
    showMiniAlert: (state, action) => {
      state.isVisible = true;
      state.message = action.payload.message;
      state.duration = action.payload.duration || 1500; // 기본값 1.5초
    },
    hideMiniAlert: (state) => {
      state.isVisible = false;
      state.message = null;
    },
  },
});

export const { showMiniAlert, hideMiniAlert } = miniAlertSlice.actions;

export default miniAlertSlice.reducer;
