import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import commonLoadingAnimation from 'assets/lottie/common_loading_animation.json';

/**
 * 공통 로딩 컴포넌트
 * 기본적인 애니메이션 컴포넌트의 크기가 커서 scale로 적당한 비율을 맞춘뒤 overflow: hidden으로 영역 조정
 */
export default function CommonLoadingAnimation({ baseTime = 0.2, size = '18rem', text = null, className }) {
  const [isToLong, setIsToLong] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsToLong(true);
    }, baseTime * 1000);

    return () => {
      setIsToLong(false);
    };
  }, [baseTime]);

  return isToLong ? (
    <S.LoadingContainer className={className}>
      <S.ContentWrapper>
        <div style={{ width: size, height: size, overflow: 'hidden' }}>
          <Lottie animationData={commonLoadingAnimation} style={{ width: size, height: size, transform: 'scale(2.5)' }} />
        </div>
        {/* common_loading_animation_text 클래스 이름으로 상위 컴포넌트에서 스타일링 가능 */}
        {text && <S.LoadingText className='common_loading_animation_text'>{text}</S.LoadingText>}
      </S.ContentWrapper>
    </S.LoadingContainer>
  ) : (
    <S.ShortLoading />
  );
}

const S = {
  LoadingContainer: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
  `,

  /** 로딩 애니메이션과 텍스트를 감싸는 컴포넌트 */
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,

  /** 로딩 텍스트 */
  LoadingText: styled.p`
    font-size: 1.25rem;
    text-align: center;
    padding: 0.5rem 1.5rem;
    color: #333;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(1px);
    border-radius: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  `,

  /** 로딩중 더블클릭을 막기 위한 투명한 오버레이  */
  ShortLoading: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.01);
    filter: blur(0.6);
  `,
};
