import { useState, useRef } from 'react';
import { ReactComponent as ImgSvgIconArrow } from 'assets/img/svg/icon_arrow_02.svg';

import useOutsideClick from 'hooks/useOutsideClick';

/**
 * Score/Student에서 학생이 여러 개의 Class에 속해있을때 보여줄 Dropbox 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/score/student/:studentSeq #modal
 */
export default function ClassListDropboxItem({ dataList }) {
  const [isShowDropBox, setIsShowDropBox] = useState(false);
  const refSelect = useRef(null);
  /** 아이템 클릭시 동작하는 함수 - 이벤트 버블링 방지 & 드롭박스 토글 (어차피 아무런 역할이 없는 모달이므로 닫히기만 하면 됨) */
  const handleClickItem = (event) => {
    event.stopPropagation();
    setIsShowDropBox((prev) => !prev);
  };

  useOutsideClick(refSelect, () => setIsShowDropBox(false), isShowDropBox);

  return (
    <td>
      <div className='dropdown_item' ref={refSelect}>
        <button className='class_name' type='button' title='Open/Close' onClick={handleClickItem}>
          <p>{dataList[0].label}</p>
          <ImgSvgIconArrow className={`${isShowDropBox ? 'active' : ''}`} />
        </button>
        <ul className={`${isShowDropBox ? 'active' : ''} custom_scroll`}>
          {dataList.map((item, index) => (
            <li key={`${item.label}-${index}`}>{item.label}</li>
          ))}
        </ul>
      </div>
    </td>
  );
}
