import React, { Fragment, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { nvl } from 'utils/Common.utils';

import ClassList from 'components/com/tests/_components/_ClassList';

import IconEmpty from 'assets/img/icon_empty.png';

/** 
 * tests/scheduled 페이지의 "By Test Set" 텝에 해당하는 컨텐츠 영역 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/tests/scheduled
*/
export default function ByTestSet({
  activeTest,
  classEditClick,
  getSchedules,
  schedulesClassListReload,
  setAlertLayerPopup,
  isLoading,
  setIsLoading,
  setScheduleInfo,
}) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const navigate = useNavigate();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const obsRef = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  useEffect(() => {
    let options = {
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !isLoading) {
        if (activeTest.totalCnt > activeTest.classMemberListAll.length) {
          setIsLoading(true);
          setScheduleInfo((prev) => {
            return { ...prev, currentPage: prev.currentPage + 1 };
          });
        }
      }
    }, options);

    if (obsRef.current) {
      observer.observe(obsRef.current);
    }

    // 옵저버 해제
    return () => {
      if (obsRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(obsRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTest.classMemberListAll]);

  return (
    <>
      <div className='sec_test'>
        {nvl(activeTest.classMemberListAll) !== '' && activeTest.classMemberListAll.length > 0 ? (
          activeTest.classMemberListAll.map((item, idx) => {
            return (
              <Fragment key={`class_${idx + 1}`}>
                <ClassList
                  clickEvent={classEditClick}
                  testName={item[0][0].testName}
                  classList={item}
                  getSchedules={getSchedules}
                  getTestClassMember={schedulesClassListReload}
                  setAlertLayerPopup={setAlertLayerPopup}
                />
              </Fragment>
            );
          })
        ) : (
          // 데이터 없을 경우 노출
          <div className='com_nodata'>
            <p className='tit'>No test available.</p>
            <p className='txt'>Go to Set Test and set more test!</p>
            <div className='img'>
              <img src={IconEmpty} alt='' />
            </div>
            <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
              GO TO SET TEST
            </button>
          </div>
        )}
      </div>
      {!isLoading && <div ref={obsRef}></div>}
    </>
  );
}
