import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import CheckBox from 'components/_common/inputs/react-hook-form/CheckBox';
import PrimaryBtn from 'components/_common/button/PrimaryBtn';
import request, { apiUris } from 'utils/Request.utils.js';
import { ReactComponent as IconLightbulb } from 'assets/img/svg/icon_lightbulb_01.svg';
import LocalStorage from 'utils/LocalStorage.utils';
import { nvl } from 'utils/Common.utils';
import jwt_decode from 'jwt-decode';
import { USER_LEVELS } from 'utils/constants';

/** 학원이 대리 가입 시킨 학생 계정이 첫 로그인 할 때, 발생하는 약관 동의 단계 */
function AgreeAccountCreatedByThirdPerson({ submitData = null }) {
  const navigate = useNavigate();
  const [pms, setPms] = useState({
    userId: '',
    acceptMarketingMail: '',
    acceptReceivingMail: '',
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  console.log('submitData:: ', submitData);

  /** API 요청 함수 */
  const postTermsAgree = ({ acceptMarketingMail, acceptReceivingMail }, submitData) => {
    let pms = {
      userId: submitData.userEmail,
      acceptMarketingMail: acceptMarketingMail ? 'Y' : 'N',
      acceptReceivingMail: acceptReceivingMail ? 'Y' : 'N',
      isNew: submitData.isSocial ? true : false,
    };
    const successHandler = (response) => {
      console.log('🚀 ~ successHandler ~ response:', response);
      if (response.code === 200) {
        // submitData 에 isSocial 이 true 일 경우, 소셜 로그인으로 가입한 계정
        if (submitData.isSocial) {
          console.log('소셜 로그인으로 가입한 계정');
          // 토근을 로컬에 저장
          LocalStorage.clearItem('userSession');
          LocalStorage.clearItem('sendParams');
          LocalStorage.clearItem('util');
          LocalStorage.setItem('userSession', 'accessToken', response.result.accessToken);
          LocalStorage.setItem('userSession', 'refreshToken', response.result.refreshToken);
          if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.STUDENT) window.location.href = '/student/dashboard';
          else if (nvl(jwt_decode(response.result.accessToken).userLevel) === USER_LEVELS.PARENT) window.location.href = '/parent/dashboard';
          else window.location.href = '/academy/dashboard';
        } else {
          navigate('/signin', {
            replace: true,
            state: {
              isSuccessSignIn: true, // 다음 화면으로 보낼 로그인 성공 여부
              submitData, // 이전 로그인 화면에서 받은 사용자 입력 데이터
            },
          });
        }
      } else {
        // 200 이 아닌경우 에러 메시지 출력 후 페이지 이동 sign-in 페이지로 이동
        // console.log('🚨 response :', response);
        alert(response.message);
        navigate('/signin');
      }
    };
    if (pms.isNew) {
      console.log('🚀 ~ postTermsAgree ~ pms:', pms);
      const errorHandler = (error) => {
        console.error('🚨 error :', error);
      };

      request.post(apiUris.auth.agreeTeams, pms, successHandler, errorHandler);
    } else {
      // @ts-ignore
      request.post('/api/member/terms/agree', pms, successHandler);
    }
  };

  /** Form 제출 핸들러 함수 */
  const onValid = (data) => {
    if (submitData) {
      postTermsAgree(data, submitData);
    } else {
      console.log('로그인 데이터가 없습니다.');
    }
  };

  /** Form 제출 실패 핸들러 함수 */
  const onInvalid = (errors) => {
    console.error('🚨 errors :', errors, '🚨');
  };

  return (
    <S.Wrap>
      <IconLightbulb />
      <h2>Welcome to GATE+!</h2>
      <div className='explain-area'>
        <p>Thank you for joining our community!</p>
        <p>Because your account was created by a third person, you need to agree with our terms before using our service. </p>
      </div>
      <form onSubmit={handleSubmit(onValid, onInvalid)}>
        <CheckBox
          register={register('checkRequired01', {
            required: 'You must agree with our terms to use our service!',
          })}
          inputId='checkRequired01'>
          <p>
            I have read and agree to Gate+{' '}
            <a
              className='btn_txt'
              href='https://gateplus.notion.site/Privacy-policy-460855e375544490ba01ca7d24404dd3'
              target='_blank'
              rel='noopener noreferrer'
              title='Open in a New Window'>
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              className='btn_txt'
              href='https://gateplus.notion.site/Terms-of-Service-5a16766471974bb0b51f63a9f983dc13'
              target='_blank'
              rel='noopener noreferrer'
              title='Open in a New Window'>
              {' '}
              Terms of Service
            </a>{' '}
            (Mandatory)
          </p>
        </CheckBox>
        {errors?.checkRequired01?.message ? <p className='error'>{String(errors.checkRequired01.message)}</p> : null}
        <CheckBox register={register('acceptMarketingMail')} inputId='acceptMarketingMail'>
          <p>
            I agree to receive{' '}
            <a
              className='btn_txt'
              href='https://gateplus.notion.site/Marketing-Consent-f0623a824e9a4c7a8de7b4c4639f4369'
              target='_blank'
              rel='noopener noreferrer'
              title='Open in a New Window'>
              marketing communications
            </a>{' '}
            from GATE+ (Optional)
          </p>
        </CheckBox>
        <CheckBox register={register('acceptReceivingMail')} inputId='acceptReceivingMail'>
          <p>I want to receive Gate+ Newsletter (Optional)</p>
        </CheckBox>
        <PrimaryBtn type='submit' className='submit-btn' title='Submit'>
          Confirm
        </PrimaryBtn>
      </form>
    </S.Wrap>
  );
}

export default AgreeAccountCreatedByThirdPerson;

const S = {};
S.Wrap = styled.div`
  h2 {
    font-size: 1.125rem;
    font-weight: 700;
    margin-top: 2.5rem;
  }
  .explain-area {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    font-size: 0.875rem;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    margin-top: 2.5rem;
    .error {
      text-align: center;
      font-size: 0.875rem;
      color: #dd0000;
    }
    .submit-btn {
      margin-top: 2.5rem;
    }
  }
`;
