import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import request from 'utils/Request.utils.js';

// banner img
import AcaBanner1 from 'assets/img/banner/academy-dashboard/aca_dash_banner_01.jpg';
import AcaBanner2 from 'assets/img/banner/academy-dashboard/aca_dash_banner_02.jpg';
import AcaBanner3 from 'assets/img/banner/academy-dashboard/aca_dash_banner_03.jpg';
import AcaBanner4 from 'assets/img/banner/academy-dashboard/aca_dash_banner_04.jpg';
import AcaBanner7 from 'assets/img/banner/academy-dashboard/aca_dash_banner_07.jpg';
import AcaBanner8 from 'assets/img/banner/academy-dashboard/aca_dash_banner_08.jpg';
//components
import MiniCalendarSection from './_components/MiniCalendarSection';
import LineAreaChart from 'components/_common/chart/LineAreaChart';
import BoardPreView from 'components/board/_components/_boardPreView';
import SwiperContainer from 'components/_common/lib/swiper/SwiperContainer';
import MainVisualArea from './_components/MainVisualArea';
import styled from 'styled-components';
import TestStatus from './_components/sections/testStatus/TestStatus';
import OuterBox from './_components/common/OuterBox';
import Box from './_components/common/Box';

/** 아케데미 대시보드 페이지 */
export default function AcademyDashboardPage() {
  //////////////////// useState 선언 영역 시작 ///////////////////
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  // TOTAL STUDENTS AND TESTS STAT 차트
  const [examCountData, setExamCountData] = useState([]);
  const [studentCountData, setStudentCountData] = useState([]);
  const [dateArray, setDateArray] = useState([]);

  const [lineAreaChartData, setLineAreaChartData] = useState([
    {
      name: 'CUMULATIVE TESTS',
      type: 'area',
      data: examCountData,
    },
    {
      name: 'CUMULATIVE STUDENTS',
      type: 'line',
      data: studentCountData,
    },
  ]);
  const [buttons, setButtons] = useState([
    { text: 'Week', value: 0, active: true },
    { text: 'Month', value: 1, active: false },
    { text: '3 Month', value: 2, active: false },
    { text: 'Year', value: 3, active: false },
  ]);
  //////////////////// useState 선언 영역 끝 ///////////////////

  //////////////////// 기타 핸들러 선언 영역 시작 ///////////////////

  const dailyCountExam = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq, // 나중에 바꿔야함
    };

    const successHandler = (response) => {
      // console.log(response)

      if (response.code === 200) {
        let dailyExamCount = response.result.dailyExamCount;

        let dailyTakerCountData = dailyExamCount?.map((i) => i.cumulativeCntStudents);
        let dailyExamCountData = dailyExamCount?.map((i) => i.cumulativeCntTests);
        let dateArrayData = Array.from({ length: dailyExamCount.length }, (_, index) => {
          const dailyDate = dayjs(dailyExamCount[index].timezoneDate).format('MM/DD');

          return dailyDate;
        });

        setExamCountData(dailyExamCountData);
        setStudentCountData(dailyTakerCountData);
        setDateArray(dateArrayData);
      }
    };

    request.get(`/api/dsat/count/exam/daily?ecSeq=${pms.ecSeq}`, null, successHandler);
  };

  const weeklyCountExam = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq, // 나중에 바꿔야함
    };

    const successHandler = (response) => {
      //console.log(response);

      if (response.code === 200) {
        let weeklyExamCount = response.result.weeklyExamCount;

        let weeklyTakersCountData = weeklyExamCount?.map((i) => i.cumulativeCntStudents);
        let weeklyExamCountData = weeklyExamCount?.map((i) => i.cumulativeCntTests);

        let dateArrayData = Array.from({ length: weeklyExamCount.length }, (_, index) => {
          const dailyDate = dayjs(weeklyExamCount[index].dt1).format('MM/DD');

          return dailyDate;
        });

        setExamCountData(weeklyExamCountData);
        setStudentCountData(weeklyTakersCountData);
        setDateArray(dateArrayData);
      }
    };

    request.get(`/api/dsat/count/exam/weekly?ecSeq=${pms.ecSeq}`, null, successHandler);
  };

  const fortnightlyCountExam = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq, // 나중에 바꿔야함
    };

    const successHandler = (response) => {
      //console.log(response);

      if (response.code === 200) {
        let fortnightlyExamCount = response.result.fortnightlyExamCount;

        let fortnightlyTakersCountData = fortnightlyExamCount?.map((i) => i.cumulativeCntStudents);
        let fortnightlyExamCountData = fortnightlyExamCount?.map((i) => i.cumulativeCntTests);

        let dateArrayData = Array.from({ length: fortnightlyExamCount.length }, (_, index) => {
          const dailyDate = dayjs(fortnightlyExamCount[index].dt1).format('MM/DD');

          return dailyDate;
        });

        setExamCountData(fortnightlyExamCountData);
        setStudentCountData(fortnightlyTakersCountData);
        setDateArray(dateArrayData);
      }
    };

    request.get(`/api/dsat/count/exam/fortnightly?ecSeq=${pms.ecSeq}`, null, successHandler);
  };

  const monthlyCountExam = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq, // 나중에 바꿔야함
    };

    const successHandler = (response) => {
      //console.log(response);

      if (response.code === 200) {
        let monthlyExamCount = response.result.monthlyExamCount;

        let monthlyTakersCountData = monthlyExamCount?.map((i) => i.cumulativeCntStudents);
        let monthlyExamCountData = monthlyExamCount?.map((i) => i.cumulativeCntTests);

        let dateArrayData = monthlyExamCount?.map((i) => i.month1);

        setExamCountData(monthlyExamCountData);
        setStudentCountData(monthlyTakersCountData);
        setDateArray(dateArrayData);
      }
    };

    request.get(`/api/dsat/count/exam/monthly?ecSeq=${pms.ecSeq}`, null, successHandler);
  };
  //////////////////// 기타 핸들러 선언 영역 끝 ///////////////////

  //////////////////// useEffect 선언 영역 시작 ///////////////////
  useEffect(() => {
    //body에 클래스 추가
    document.body.classList.add('main_page'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('main_page'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let activeButton = buttons.find((button) => button.active);

    if (activeButton.value === 0) dailyCountExam();
    if (activeButton.value === 1) weeklyCountExam();
    if (activeButton.value === 2) fortnightlyCountExam();
    if (activeButton.value === 3) monthlyCountExam();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttons]);
  //////////////////// useEffect 선언 영역 끝 ///////////////////

  return (
    <S.Wrap className='p_main'>
      <MainVisualArea />
      <section className='dashboard_sec com_center_wrap'>
        <OuterBox className='com_container left'>
          <Box>
            {/*CALENDAR*/}
            <MiniCalendarSection />
          </Box>
          {/*GATE+ News*/}
          <BoardPreView type='gate' />
          {/*DSAT Info*/}
          <BoardPreView type='dsat' />
          <SwiperContainer
            containerClass={'banner-container'}
            itemClass={'banner'}
            bannerContent={[
              // { link: 'https://forms.gle/GUN1FVtRqUGXn7dF9', image: AcaBanner6, visibleTo: 'B2B' },
              { link: 'https://pf.kakao.com/_prxbCG/106579635', image: AcaBanner7, visibleTo: 'B2B' },
              { link: 'http://pf.kakao.com/_prxbCG/106579515', image: AcaBanner8, visibleTo: 'B2B' },
              {
                link: 'https://product.kyobobook.co.kr/detail/S000213501792',
                image: AcaBanner1,
                visibleTo: 'ALL',
              },
              { link: 'https://www.youtube.com/watch?v=YhTTOtyFlAU', image: AcaBanner2, visibleTo: 'ALL' },
              { link: 'https://pf.kakao.com/_prxbCG', image: AcaBanner3, visibleTo: 'ALL' },
              { link: 'https://www.gateplus.org', image: AcaBanner4, visibleTo: 'ALL' },
            ]}
          />
        </OuterBox>
        <OuterBox className='com_container right'>
          <TestStatus />
          {/* TOTAL STUDENTS AND TESTS STAT*/}
          <article className='box full total_students_tests'>
            <div className='com_sec_tit'>
              <h2 className='tit'>
                <i className='svg_icon icon_graph black'>&nbsp;</i>
                TOTAL STUDENTS AND TESTS STAT
              </h2>
            </div>
            <LineAreaChart
              buttons={buttons}
              setButtons={setButtons}
              lineAreaChartData={lineAreaChartData}
              dateArray={dateArray}
              examCountData={examCountData}
              studentCountData={studentCountData}
            />
          </article>
        </OuterBox>
      </section>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .com_container {
      &.left {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
      }
    }
    .dashboard_sec {
      position: relative;
      z-index: 1;
    }
    .banner-container {
      width: 100%;
      .banner {
        display: flex;
        justify-content: center;
      }
    }
  `,
};
