import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconFileSearch } from 'assets/img/svg/icon_file_search_02.svg';

import TestListModal from '../../modals/testListModal/TestListModal';
import StudentListModal from '../../modals/testListModal/_components/StudentListModal';

/** 아카데미 대시보드 - Students - Classes 페이지의 대표 "Table" 컴포넌트 */
export default function ClassesMainTable({ setCheckedClassIds, searchData, checkedClassIds, lineLength, handleShowEdit, pagingInfo, isLoading }) {
  //////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [isAllChecked, setIsAllChecked] = useState(false);
  /** "시험 목록" 모달 노출 여부 */
  const [isShowTestListModal, setIsShowTestListModal] = useState(false);
  /** 현재 선택 된 class */
  const [currentClass, setCurrentClass] = useState({
    seq: 0, // 고유 번호
    name: null, // class 의 이름
    companyName: null, // 학원 이름
  });
  /** 현재 선택 class 의 학생 목록 (overflow 영역이 있는 모달 위에 모달을 띄우기 위한 조치) */
  const [selectedStudentList, setSelectedStudentList] = useState({
    studentList: [], // API 에서 받아 온 학생 목록 (Class 목록에 포함 되어 있음)
    isView: false, // 학생 목록 모달 노출 여부
    position: {
      // 학생 목록 모달 위치 값 (overflow 영역이 있는 모달 위에 모달을 띄우기 위한 조치)
      x: null,
      y: null,
    },
  });
  /** 학생 history 모달 관련 */
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  ////////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 체크박스 전체 선택 핸들러 */
  const handleAllCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsAllChecked(isChecked);
    if (isChecked) setCheckedClassIds(searchData.map((data) => data.classId));
    else setCheckedClassIds([]);
  };

  /** 한 개의 열 체크하기 핸들러 */
  const handleCheckboxChange = ({ classId }) => {
    /** 이미 체크가 되어 있는가? 여부 */
    const isChecked = !!checkedClassIds.find((item) => item === classId);

    if (isChecked) {
      setCheckedClassIds((prev) => prev.filter((item) => item !== classId));
    } else {
      setCheckedClassIds((prev) => [...prev, classId]);
    }

    setIsAllChecked(searchData.length === checkedClassIds.length + (isChecked ? -1 : 1));
  };

  /** Score History 버튼 클릭 핸들러 */
  const handleClickScoreHistory = ({ event, classId, classTitle, companyName }) => {
    event.stopPropagation();
    setCurrentClass((current) => {
      return {
        seq: classId, // 고유 번호
        name: classTitle, // class 의 이름
        companyName, // 학원 이름
      };
    });
    setShowHistoryModal(true);
  };

  /** "Test List" 버튼 핸들러 */
  const handleTestListButton = ({ event, classId, classTitle, companyName }) => {
    event.stopPropagation();
    setCurrentClass((current) => {
      return {
        seq: classId, // 고유 번호
        name: classTitle, // class 의 이름
        companyName, // 학원 이름
      };
    });
    setIsShowTestListModal(true);
  };
  ////////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    setIsAllChecked(false);
    setCheckedClassIds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineLength, searchData]);
  ////////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <>
      <S.Table className='list'>
        <thead>
          <tr>
            <th className='col_01'>
              <input type='checkbox' className='com_chk' id='chk01' checked={isAllChecked} onChange={handleAllCheckboxChange} />
              <label htmlFor='chk01'>&nbsp;</label>
            </th>
            <th className='col_02'>No.</th>
            <th className='col_04'>subject</th>
            <th className='col_03'>Class</th>

            <th className='col_04'>Start Date</th>
            <th className='col_05'>End Date</th>

            <th className='col_06'>No. of Students</th>
            <th className='col_07'>Test List</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={8}>
                <h2>Loading...</h2>
              </td>
            </tr>
          ) : searchData.length === 0 ? (
            <tr>
              <td colSpan={8}>
                <h2>You do not have any classes… yet!</h2>
              </td>
            </tr>
          ) : (
            searchData.map((item, index) => {
              const checkboxId = `chk02-${item.classId}`;
              return (
                <tr key={`index_${index}`} title='Class List'>
                  <td className='col_01'>
                    <input
                      type='checkbox'
                      className='com_chk'
                      id={checkboxId}
                      checked={checkedClassIds.includes(item.classId)}
                      onChange={() => {
                        handleCheckboxChange({ classId: item.classId });
                      }}
                    />
                    <label htmlFor={checkboxId}>&nbsp;</label>
                  </td>
                  <td className='col_02'>{searchData.length - pagingInfo.numPerPage * (pagingInfo.currentPage - 1) - index}</td>
                  <td className='col_04'>{item.subjectName}</td>

                  <td className='col_03 alignL'>
                    <p className='name'>{item.classTitle}</p>
                  </td>
                  <td className='col_04'>{item.startDate.format('MM/DD/YYYY')}</td>
                  <td className='col_05'>{item.endDate.format('MM/DD/YYYY')}</td>
                  <td className='col_06'>{item.studentCount}</td>

                  <td className='col_07 table_data_btn_area'>
                    <button
                      type='button'
                      title='Open a Modal Test List'
                      onClick={(event) => {
                        handleTestListButton({ event, classId: item.classId, classTitle: item.classTitle, companyName: item.companyName });
                      }}>
                      <ImgSvgIconFileSearch />
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </S.Table>

      {isShowTestListModal && (
        <TestListModal
          setShowModal={setIsShowTestListModal}
          classSeq={currentClass.seq}
          className={currentClass.name}
          setSelectedStudentList={setSelectedStudentList}
        />
      )}

      <StudentListModal selectedStudentList={selectedStudentList} />
    </>
  );
}

const S = {
  Table: styled.table`
    tr {
      th,
      td {
        vertical-align: middle;
        padding: 0 0.65rem;
        &.col_01 {
          width: 2.5rem;
        }
        &.col_02 {
          width: 3rem;
        }
        &.col_03 {
          width: auto;
        }
        &.col_04 {
          width: 12rem;
        }
        &.col_05 {
          width: 12rem;
        }
        &.col_06 {
          width: 8rem;
        }
        &.col_07 {
          width: 7.25rem;
        }
        &.col_08 {
          width: 7.25rem;
        }
      }
      th {
        background-color: #000;
        color: #fff;
        height: 2.5rem;
        text-align: center;
        font-weight: 500;
        font-size: 0.875rem;
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          overflow: hidden;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          overflow: hidden;
        }
      }
      td {
        height: 3rem;
        text-align: center;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        border-bottom: 1px solid #ebebeb;
        cursor: pointer;

        &.col_03 {
          text-align: left;
        }
        .table_data_inner_box {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
        }

        & > button,
        .table_data_inner_box > button {
          width: 1.5rem;
          height: 1.5rem;
          transition: 0.2s;
          &:hover {
            filter: brightness(95%);
          }

          &.btn_view {
            width: auto;
            height: auto;
            background-color: #f2f2f2;
            color: #7f8998;
            font-weight: 400;
            font-size: 0.875rem;
            border-radius: 0.25rem;
            padding: 0.125rem 0.5rem;
          }

          &.btn_change_class {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #f2f2f2;
            border-radius: 0.25rem;
            svg {
              width: 1rem;
              color: #a6b3be;
            }
          }
        }

        &.table_data_btn_area {
          button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #e8f0ff;
            border-radius: 0.25rem;
            svg {
              width: 1.125rem;
              color: #0068bd;
            }
          }
        }
      }
      background-color: #fff;
      &:nth-child(even) {
        background-color: #fafafa;
      }
      &.active {
        background-color: #edf7ff;
      }
      &:has(.com_chk):hover {
        background-color: #f2f2f2;
        //color:#4B5563 ;
      }
    }
  `,
};
