import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import SignUpTimezone from 'components/user/sign-up/_components/pages/Timezone';

/** "회원가입 - 소셜 가입 - 튜터 - 타입존 설정" 페이지 */
export default function SignUpSocialTutorTimezonePage() {
  const navigate = useNavigate();

  const x = new Date();

  /** 타임존 목록 */
  const timeZones = Intl.supportedValuesOf('timeZone');

  /** 현재 사용자 장치의 타입존 */
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log('🚀 ~ SignUpSocialTutorTimezonePage ~ userTimeZone:', userTimeZone);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      timezone: userTimeZone,
    },
  });

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    console.log(`🚨 data :`, data, `🚨`);
    navigate('/signup/complete');
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <SignUpTimezone
      headerTitle={'Tutor'}
      handleSubmit={handleSubmit}
      onValid={onValid}
      register={register}
      errors={errors}
      TimezoneArray={timeZones}></SignUpTimezone>
  );
}
