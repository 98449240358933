import { useEffect, useState } from 'react';
import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import { USER_LEVELS } from 'utils/constants.js';
import AcademyInformation from '../_AcademyInformation';

const ChildInformation = ({ setLinkPop, reloadData }) => {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  const [linkedChild, setLinkedChild] = useState([]);

  const getChildren = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let childrenList = response.result.childrenList;

        if (nvl(childrenList) !== '') {
          childrenList.forEach((child) => {
            let imageUrl = null;
            if (child.upFileSeq > 0) {
              imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${child.userSeq}?${Date.now()}`;
            }

            child.profile = imageUrl;
          });
        } else {
          getChildren();
        }

        setLinkedChild(childrenList || []);
      }
    };
    request.get(`/api/member/profile/link/children?userSeq=${userInfo.userSeq}`, null, successHandler);
  };

  useEffect(() => {
    getChildren();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  // 활성화 자식 변경
  const changeActiveChild = (userSeq, fhSeq) => {
    let pms = {
      activateYn: 'Y',
    };

    const successHandler = (response) => {
      // console.log(response)
      if (response.code === 200) {
        getChildren();
      }
    };

    request.put(`/api/member/profile/family/activate/${userSeq}/${fhSeq}`, pms, successHandler);
  };

  return (
    <>
      <h2 className='tit'>Child Information</h2>
      <p className='sub_tit'>View your child's profile</p>
      <div className='btn_area'>
        <button className='com_btn m point' onClick={() => setLinkPop(true)}>
          <i className='svg_icon icon_link white before'></i>LINK YOUR CHILD
        </button>
      </div>
      {nvl(linkedChild) !== '' && linkedChild.length === 0 ? (
        <div className='no_academy'>
          <i className='svg_icon icon_no_link gray'>&nbsp;</i>
          No linked child.
          <br />
          You need to link with your child using your child's email to see your child's information.
        </div>
      ) : (
        linkedChild.map((item, idx) => {
          return (
            <AcademyInformation
              userInfo={userInfo}
              key={`${item}-${idx}`}
              student={true}
              info={item}
              userLevel={USER_LEVELS.STUDENT}
              viewProfile={true}
              handleClickCheckbox={changeActiveChild}
            />
          );
        })
      )}
    </>
  );
};

export default ChildInformation;
