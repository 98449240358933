import styled from 'styled-components';

/**
 * @component
 * 상호작용 로직에 중점을 둔 버튼 컴포넌트
 * @param {Object} props
 * @param {React.ReactNode} props.children - 버튼 내부 컨텐츠
 * @param {function} props.onClick - 클릭 핸들러
 * @param {string} props.text - 버튼 텍스트
 * @param {boolean} props.dataHover - 툴팁 표시 여부
 * @param {object} props.style - 인라인 스타일
 */

export default function IntButtonBase({ children = null, onClick, text = 'TEXT', dataHover = false, hoverColor = '', style = {}, className, ...rest }) {
  return (
    <S.IntButtonBase onClick={onClick} data-hover={dataHover ? text : null} $hoverColor={hoverColor} style={style} className={className} {...rest}>
      {children ?? text}
    </S.IntButtonBase>
  );
}

const S = {
  IntButtonBase: styled.button`
    position: relative;

    &:hover {
      ${(props) => (props.$hoverColor ? `background-color: ${props.$hoverColor};` : 'filter: brightness(95%);')}
    }

    /// data-hover 속성에 따른 툴팁 스타일 ///
    &::before {
      content: attr(data-hover);
      visibility: hidden;
      opacity: 0;
      width: max-content;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      padding: 5px 5px;
      transition: opacity 0.3s ease-in-out;

      position: absolute;
      z-index: 9999;
      left: 50%;
      bottom: 120%;
      transform: translateX(-50%);
      display: ${(props) => (props['data-hover'] ? 'block' : 'none')};
    }
    &:hover::before {
      content: attr(data-hover);
      opacity: 1;
      visibility: visible;
    }
  `,
};
