/**
 * URL 파라미터 난독화 유틸리티
 */

/** Base64 인코딩에 사용되는 문자 집합 */
const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

/** XOR 연산에 사용되는 비밀 키 */
const SECRET = process.env.REACT_APP_AES_PRIVATE_KEY || 'fallback-secret-key';

/**
 * 문자열을 UTF-8 바이트 배열로 변환
 */
const stringToBytes = (str) => {
  const bytes = [];
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    if (char < 0x80) {
      bytes.push(char);
    } else if (char < 0x800) {
      bytes.push(0xc0 | (char >> 6), 0x80 | (char & 0x3f));
    } else if (char < 0x10000) {
      bytes.push(0xe0 | (char >> 12), 0x80 | ((char >> 6) & 0x3f), 0x80 | (char & 0x3f));
    }
  }
  return bytes;
};

/**
 * UTF-8 바이트 배열을 문자열로 변환
 */
const bytesToString = (bytes) => {
  let result = '';
  for (let i = 0; i < bytes.length; i++) {
    const byte = bytes[i];
    if (byte < 0x80) {
      result += String.fromCharCode(byte);
    } else if (byte < 0xe0) {
      const byte2 = bytes[++i];
      result += String.fromCharCode(((byte & 0x1f) << 6) | (byte2 & 0x3f));
    } else {
      const byte2 = bytes[++i];
      const byte3 = bytes[++i];
      result += String.fromCharCode(((byte & 0x0f) << 12) | ((byte2 & 0x3f) << 6) | (byte3 & 0x3f));
    }
  }
  return result;
};

/**
 * XOR 연산을 수행
 */
const xorBytes = (bytes, key) => {
  const keyBytes = stringToBytes(key);
  return bytes.map((byte, i) => byte ^ keyBytes[i % keyBytes.length]);
};

/**
 * 바이트 배열을 Base64로 인코딩
 */
const bytesToBase64 = (bytes) => {
  let result = '';
  for (let i = 0; i < bytes.length; i += 3) {
    const chunk = (bytes[i] << 16) | ((bytes[i + 1] || 0) << 8) | (bytes[i + 2] || 0);
    result += CHARS[(chunk >> 18) & 63] + CHARS[(chunk >> 12) & 63] + CHARS[(chunk >> 6) & 63] + CHARS[chunk & 63];
  }
  // 패딩 처리
  const paddingLength = bytes.length % 3;
  if (paddingLength > 0) {
    result = result.slice(0, result.length - (3 - paddingLength));
  }
  return result;
};

/**
 * Base64를 바이트 배열로 디코딩
 */
const base64ToBytes = (base64) => {
  const bytes = [];
  for (let i = 0; i < base64.length; i += 4) {
    const chunk =
      (CHARS.indexOf(base64[i] || 'A') << 18) |
      (CHARS.indexOf(base64[i + 1] || 'A') << 12) |
      (CHARS.indexOf(base64[i + 2] || 'A') << 6) |
      CHARS.indexOf(base64[i + 3] || 'A');
    bytes.push((chunk >> 16) & 255);
    if (base64[i + 2]) bytes.push((chunk >> 8) & 255);
    if (base64[i + 3]) bytes.push(chunk & 255);
  }
  return bytes;
};

/**
 * URL 파라미터 난독화
 */
export const obfuscateUrlParam = (value) => {
  // 값을 문자열로 변환
  const str = typeof value === 'object' ? JSON.stringify(value) : String(value);

  // 문자열을 바이트 배열로 변환하고 XOR 연산 수행
  const bytes = stringToBytes(str);
  const xored = xorBytes(bytes, SECRET);

  // Base64로 인코딩
  return bytesToBase64(xored);
};

/**
 * URL 파라미터 복호화
 * @returns {string} 복호화된 문자열, 실패 시 null
 */
export const deobfuscateUrlParam = (obfuscatedText) => {
  if (!obfuscatedText) return null;

  try {
    // Base64 디코딩
    const bytes = base64ToBytes(obfuscatedText);

    // XOR 복호화
    const xored = xorBytes(bytes, SECRET);

    // 바이트 배열을 문자열로 변환
    const decodedStr = bytesToString(xored);

    // object인 경우에도 문자열로 반환
    try {
      const parsed = JSON.parse(decodedStr);
      return typeof parsed === 'object' ? JSON.stringify(parsed) : String(parsed);
    } catch {
      return decodedStr;
    }
  } catch (error) {
    console.error('Deobfuscation failed:', error);
    return null;
  }
};