import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UI_ExamFooter from 'components/_common/exam/UI_ExamFooter';
import { ReactComponent as ImageSvgIconArrowBlack02 } from 'assets/img/svg/icon_arrow_02.svg';
import { updateCurrentQuestionIndex } from 'reducers/apTestEditor/action';
import PreviewMiniReview from '../../test-editor/_components/PreviewMiniReview';

import useOutsideClick from 'hooks/useOutsideClick';

/** question-bank Preview 푸터 */
export default function PreviewFooter() {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const dispatch = useDispatch();
  const apTestEditorReducer = useSelector((state) => state.apTestEditorReducer);
  const { currentQuestion_index, questions, currentPartData } = apTestEditorReducer;
  const currentPartQuestions = questions.filter((question) => question.part_seq === currentPartData.part_seq);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [isShowMiniReview, setIsShowMiniReview] = useState(false);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const miniReviewRef = useRef(null); // MiniReview 외부 클릭용 Ref
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** question을 이동할때 현재 part에 있는 데이터를 참조하여 이동 ( 없는 order는 건너뜀 ) */
  const handleNavigation = (direction) => {
    const sortedQuestions = currentPartQuestions.sort((a, b) => a.question_order - b.question_order);
    const currentIndex = sortedQuestions.findIndex((question) => question.question_order === currentQuestion_index);

    if (direction === 'back' && currentIndex > 0) {
      dispatch(updateCurrentQuestionIndex(currentPartQuestions[currentIndex - 1].question_order));
    } else if (direction === 'next' && currentIndex < currentPartQuestions.length - 1) {
      dispatch(updateCurrentQuestionIndex(currentPartQuestions[currentIndex + 1].question_order));
    }
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 --- START ////////////////////
  useOutsideClick(miniReviewRef, () => setIsShowMiniReview(false), isShowMiniReview); // MiniReview - 외부 클릭시 닫기
  //#endregion //////////////////  END  --- 커스텀 훅 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  const centerContent = (
    <div ref={miniReviewRef}>
      <button type='button' title='Open/Close' onClick={() => setIsShowMiniReview((prev) => !prev)} className='question_nav_btn'>
        <p>
          Question <span>{currentQuestion_index + 1}</span> of <span>{currentPartQuestions.length}</span>
        </p>
        <span className='question_nav_btn_arrow'>
          <ImageSvgIconArrowBlack02 />
        </span>
      </button>
      {isShowMiniReview && <PreviewMiniReview setIsShowMiniReview={setIsShowMiniReview} />}
    </div>
  );

  const rightContent = (
    <>
      <button disabled={currentQuestion_index <= 0} onClick={() => handleNavigation('back')}>
        Back
      </button>
      <button disabled={currentQuestion_index >= currentPartQuestions.length - 1} onClick={() => handleNavigation('next')}>
        Next
      </button>
    </>
  );
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return <UI_ExamFooter leftContent={null} centerContent={centerContent} rightContent={rightContent} />;
}
