import { useState } from 'react';
import { ReactComponent as ImgSvgIconArrow } from 'assets/img/svg/icon_arrow_02.svg';

/**
 * Score/Student에서 학생이 여러 개의 Class에 속해있을때 보여줄 Dropbox 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/score/student/:studentSeq #modal
 */
export default function ClassListDropboxItem({ classList }) {
  const [isShowDropBox, setIsShowDropBox] = useState(false);

  /** 아이템 클릭시 동작하는 함수 - 이벤트 버블링 방지 & 드롭박스 토글 (어차피 아무런 역할이 없는 모달이므로 닫히기만 하면 됨) */
  const handleClickItem = (event) => {
    event.stopPropagation();
    setIsShowDropBox((prev) => !prev);
  };

  return (
    <td>
      <div className='dropdown_item'>
        <button className='class_name' type='button' title='Open/Close' onClick={handleClickItem}>
          {classList[0].className} <ImgSvgIconArrow className={`${isShowDropBox ? 'active' : ''}`} />
        </button>
        <ul className={`${isShowDropBox ? 'active' : ''} scroll`}>
          {classList.map((classItem, index) => (
            <li key={`${classItem.className}-${index}`}>{classItem.className}</li>
          ))}
        </ul>
      </div>
    </td>
  );
}
