import { useState } from 'react';
import dayjsTZ from 'utils/functions/time/dayjs-config';

import request from 'utils/Request.utils';

function DebugTimezone() {
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());

  /** 개발모드인지 확인 */
  const isDevEnvironmental = process.env.REACT_APP_ENVIRONMENT === 'localhost' || process.env.REACT_APP_ENVIRONMENT === 'development';

  if (!isDevEnvironmental) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        margin: '0 20px',
        padding: '2px 10px',
        border: '1px solid black',
      }}>
      <div
        style={{
          border: '1px solid black',
          padding: 2,
        }}>
        <h2>{dayjsTZ().format()}</h2>
      </div>
      <div
        style={{
          border: '1px solid black',
          padding: 2,
        }}>
        <h2>{userInfo?.timeZone}</h2>
      </div>
    </div>
  );
}

export default DebugTimezone;
