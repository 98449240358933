import { useEffect, useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils.js';
import dayjsTZ from 'utils/functions/time/dayjs-config';

//components

import MainLink from 'components/_common/button/_MainLinks.jsx';

//img
import Paging from 'layout/Paging.jsx';
import GradingMainTable from './_components/tables/studentsMainTableArea/GradingMainTable.jsx';
import SearchArea from './_components/tables/studentsMainTableArea/SearchArea.jsx';
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod.jsx';

/** ap/com/score/grading 페이지 */
export default function ApComScoreGradingPage() {
  const [testList, setTestList] = useState([
    {
      uthSeq: 0,
      isGrading: false,
      studentName: '',
      testSetName: '',
      teacherName: '',
      subjectName: '',
      score: 0,
      testTakenEndDate: '',
    },
  ]);
  const [filteredTestList, setFilteredTestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [pagingInfo, setPagingInfo] = useState({
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });

  const [searchInfo, setSearchInfo] = useState({
    searchFieldActive: false,
    searchType: 'Test',
    searchKeyword: '',
  });

  useEffect(() => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const testList = response.result.testList;

        setTestList(testList);
      }
    };

    setIsLoading(true);
    request
      .apGet('/api/v1/academy/score/grading', null, successHandler)
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const nextPageMove = (value) => {
    setPagingInfo({ ...pagingInfo, currentPage: value });
  };

  const filterTestList = () => {
    return testList.slice((pagingInfo.currentPage - 1) * pagingInfo.numPerPage, pagingInfo.currentPage * pagingInfo.numPerPage);
  };

  useEffect(() => {
    setFilteredTestList(filterTestList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagingInfo, testList]);

  return (
    <>
      <S.Wrap className='p_student_list'>
        <MainLink type='students' />
        <div className='com_center_wrap'>
          <div className='com_box'>
            <div className='com_list'>
              {/* 🧰 AP 일단 검색 막아둠 */}
              {/* <section className='top'>
                <PopupCalendarPeriod tit={dayjsTZ().format('MM / DD / YYYY')} setSearchInfo={setSearchInfo} />
                <SearchArea setSearchInfo={setSearchInfo} setPagingInfo={setPagingInfo} searchInfo={searchInfo} />
              </section> */}
              {!isLoading && <GradingMainTable testList={filteredTestList} setTestList={setTestList} />}
            </div>

            {/* 📏 student main - Pagination 📏 */}
            <Paging
              totalCount={testList.length}
              currentPage={pagingInfo.currentPage}
              numPerPage={pagingInfo.numPerPage}
              pagePerBlock={pagingInfo.pagePerBlock}
              nextPageMove={nextPageMove}
            />
          </div>
        </div>
      </S.Wrap>
    </>
  );
}

const S = {
  Wrap: styled.div`
    .com_center_wrap {
      .com_box {
        .com_list {
          max-height: 32.5rem;
          .top {
            justify-content: flex-end;
            gap: 0.75rem;
          }
        }
      }
    }
  `,
};
