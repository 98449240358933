import { useRef } from 'react';
import styled from 'styled-components';
import useOutsideClick from 'hooks/useOutsideClick';

import DirectionsPopup from './DirectionsPopup';

import { ReactComponent as ImageSvgIconArrowBlack02 } from 'assets/img/svg/icon_arrow_02.svg';

/** 시험에서 사용되는 Directions 팝업을 보여주는 버튼 ( + 팝업 포함 )  */
export default function DirectionsPopupBtn({ isShowDirectionsPopup, setIsShowDirectionsPopup }) {
  //#region    ////////////////// START --- Ref --- START ////////////////////
  const directionsPopupBtnRef = useRef(null);
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 --- START ////////////////////
  useOutsideClick(directionsPopupBtnRef, () => setIsShowDirectionsPopup((prev) => !prev), isShowDirectionsPopup);
  //#endregion //////////////////  END  --- 커스텀 훅 ---  END  ////////////////////

  return (
    <S.DirectionsPopupBtnContainer ref={directionsPopupBtnRef}>
      <button className='direction_button' onClick={() => setIsShowDirectionsPopup((prev) => !prev)}>
        Directions
        <ImageSvgIconArrowBlack02 className={isShowDirectionsPopup ? 'show' : ''} />
      </button>
      {isShowDirectionsPopup && <DirectionsPopup setIsShowDirectionsPopup={setIsShowDirectionsPopup} />}
    </S.DirectionsPopupBtnContainer>
  );
}

const S = {
  DirectionsPopupBtnContainer: styled.div`
    position: relative;
    .direction_button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.125rem;
      font-weight: 500;
      cursor: pointer;

      svg {
        color: black;
        width: 1.125rem;
        height: 1.125rem;
        transition: 0.2s;
        margin-left: 0.5rem;

        &.show {
          transform: rotate(180deg);
        }
      }
    }
  `,
};
