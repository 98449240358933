import React from 'react';
import styled from 'styled-components';

/** Set Tests 페이지들에서 사용 할 타이틀 문구 스타일 용 컴포넌트 */
export default function MainTitle({ children }) {
  return <S.Title>{children}</S.Title>;
}

const S = {
  Title: styled.h2`
    font-weight: 600;
    font-size: 1.25rem;
    color: #111111;
  `,
};
