import styled from 'styled-components';

/** (ap 시험)문제 입력 방법 가이드 툴팁 컴포넌트 */
export default function DirectionsPopup({ setIsShowDirectionsPopup, directionContent }) {
  return (
    <S.DirectionsPopupLayer>
      <div className='direction_text'>{directionContent}</div>
      <div className='btn_section'>
        <button className='close_btn' onClick={() => setIsShowDirectionsPopup(false)}>
          Close
        </button>
      </div>
    </S.DirectionsPopupLayer>
  );
}

const S = {
  DirectionsPopupLayer: styled.article`
    height: 60vh;
    width: 55vw;
    top: 220%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: #fff;
    position: absolute;
    padding: 20px;

    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));

    .direction_text {
      font-family: 'Noto Serif', serif;
      font-size: 1rem;
      line-height: 2;
      flex: 1;
      text-align: left;
    }

    .btn_section {
      display: flex;
      justify-content: flex-end;
      .close_btn {
        background: #f8dc4a;
        color: #000;
        height: 45px;
        padding-inline: 21px;
        font-size: 13px;
        border: 1px solid #000;
        border-radius: 6.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        text-align: center;
      }
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid white;
      background: transparent;
      padding: 0;
      cursor: pointer;
      position: absolute;
      top: -13px;
      left: 2%;
      /* transform: translateX(-50%); */
    }
  `,
};
