import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconFile } from 'assets/img/svg/icon/icon-file-06.svg';
import { ReactComponent as ImgSvgIconDownload } from 'assets/img/svg/icon_download_01.svg';

/** 업로드 된 파일 목록의 각 요소 컴포넌트 */
export default function FileItemDownload({ fileItem, handleDownload }) {
  return (
    <S.Button type='button' onClick={() => handleDownload(fileItem)} title='Download' className='buttonDelete'>
      <div className='left_flex'>
        <ImgSvgIconFile className='icon-file' />
        <p className='name'>{fileItem.fileName}</p>
      </div>
      <ImgSvgIconDownload className='icon_download' />
    </S.Button>
  );
}

const S = {};

S.Button = styled.button`
  cursor: pointer;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 1.125rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--input-stroke);
  background-color: #ffffff;
  margin-bottom: 0.75rem;

  .left_flex {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 1rem;
  }

  .icon-file {
    width: 1.25rem;
    color: var(--point-color);
  }
  .name {
    font-size: 0.813rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #111111;
    text-align: left;
    line-height: 1rem;
    letter-spacing: -0.26px;
    margin-top: 0;
  }
  svg.icon_download {
    width: 1.25rem;
    color: #a6b3be;
  }
`;
