import { useState, useEffect } from 'react';
import AnswerRateGraphBox from '../graphComponent/AnswerRateGraphBox';
import styled from 'styled-components';
import { SCORE_PARAMS } from 'utils/constants';
import { deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useParams } from 'react-router-dom';
import request from 'utils/Request.utils';
import { useObfuscatedSearchParam, getValidParam } from 'hooks/useObfuscatedSearchParam';

// 차트 설정 데이터: 각 타입별 라벨과 그래프 색상 정의
const CHART_CONFIG = {
  // RW
  E: {
    label: 'RW',
    unit: '%',
    chartOptions: [
      { label: 'CAS', graphColor: '#ffa88c' },
      { label: 'IAI', graphColor: '#8fff8c' },
      { label: 'SEC', graphColor: '#8cc1ff' },
      { label: 'EOI', graphColor: '#a38cff' },
    ],
  },
  // MATH
  M: {
    label: 'MATH',
    unit: '%',
    chartOptions: [
      { label: 'Alg', graphColor: '#ffa88c' },
      { label: 'AM', graphColor: '#8fff8c' },
      { label: 'PSADA', graphColor: '#8cc1ff' },
      { label: 'GAT', graphColor: '#a38cff' },
    ],
  },
};

/** advanced report에서 과목별 정답률을 보여주는 영역 */
export default function AnswerRateGraphsSection({
  mode,
  currentModuleSubject,
  activeSatBox,
  setActiveSatBox,
  handleSelectSubject,
  checkViewAboutAverageOfGateplus,
}) {
  /** 현재 classSeq, studentSeq - Route Parameter */
  const { classSeq: encClassSeq, studentSeq: encStudentSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  const studentSeq = deobfuscateUrlParam(encStudentSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);
  const queryCurUthSeq = getObfuscatedValue(SCORE_PARAMS.CUR_UTH);

  const [RWChartData, setRWChartData] = useState({
    label: 'RW', // 'RW'            => 차트에 노출될 라벨
    unit: '', // string             => 차트에 노출될 단위
    chartDate: [
      {
        data: 0, // number          => 해당 카테고리의 유저 정답률
        gatePlusData: 0, // number  => 해당 카테고리의 GATE⁺ 정답률
        graphColor: '', // string   => 해당 카테고리의 그래프 색상
        label: '', // string        => 해당 카테고리의 라벨
        toolTipStr: '', // string   => 해당 카테고리의 툴팁
      },
    ],
  });
  const [mathChartData, setMathChartData] = useState({
    label: 'MATH', // 'MATH'        => 차트에 노출될 라벨
    unit: '', // string             => 차트에 노출될 단위
    chartDate: [
      {
        data: 0, // number          => 해당 카테고리의 유저 정답률
        gatePlusData: 0, // number  => 해당 카테고리의 GATE⁺ 정답률
        graphColor: '', // string   => 해당 카테고리의 그래프 색상
        label: '', // string        => 해당 카테고리의 라벨
        toolTipStr: '', // string   => 해당 카테고리의 툴팁
      },
    ],
  });

  useEffect(() => {
    /** mode가 class일때 카테고리별 정답률을 조회하고 차트 데이터를 설정하는 함수 */
    const getClassCategoriesRate = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const processChartData = (response) => {
            // 기본 데이터 유효성 검사
            const classCorrectRateByCategory = response.result.classCorrectRateByCategory;
            const gateplusCorrectRateByCategory = response.result.gateplusCorrectRateByCategory;

            if (!(classCorrectRateByCategory && gateplusCorrectRateByCategory)) return;

            // RW와 MATH 데이터 생성 및 반환
            return {
              rwChartData: generateChartData('E', {
                gateplusCorrectRate: gateplusCorrectRateByCategory,
                targetCorrectRate: classCorrectRateByCategory,
              }),
              mathChartData: generateChartData('M', {
                gateplusCorrectRate: gateplusCorrectRateByCategory,
                targetCorrectRate: classCorrectRateByCategory,
              }),
            };
          };
          // RW(Reading and Writing)와 MATH 차트 데이터 설정
          const chartData = processChartData(response);
          if (chartData) {
            setRWChartData(chartData.rwChartData);
            setMathChartData(chartData.mathChartData);
          }
        }
      };

      request
        .get(`/api/v2/score/class/category-rate?classSeq=${classSeq}&customSetSeq=${queryCurScoreTestSeq}`, null, successHandler)
        .catch((error) => console.error('API 호출 중 오류 발생:', error));
    };
    /** mode가 student일때 카테고리별 정답률을 조회하고 차트 데이터를 설정하는 함수 */
    const getStudentCategoriesRate = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          const processChartData = (response) => {
            // 기본 데이터 유효성 검사
            const fetchedStudentTestTypeCorrectRate = response.result.studentTestTypeCorrectRate;
            const fetchedGateplusTestTypeCorrectRate = response.result.gateplusTestTypeCorrectRate;

            if (!(fetchedStudentTestTypeCorrectRate && fetchedGateplusTestTypeCorrectRate)) return;

            // RW와 MATH 데이터 생성 및 반환
            return {
              rwChartData: generateChartData('E', {
                gateplusCorrectRate: fetchedGateplusTestTypeCorrectRate,
                targetCorrectRate: fetchedStudentTestTypeCorrectRate,
              }),
              mathChartData: generateChartData('M', {
                gateplusCorrectRate: fetchedGateplusTestTypeCorrectRate,
                targetCorrectRate: fetchedStudentTestTypeCorrectRate,
              }),
            };
          };
          // RW(Reading and Writing)와 MATH 차트 데이터 설정
          const chartData = processChartData(response);
          if (chartData) {
            setRWChartData(chartData.rwChartData);
            setMathChartData(chartData.mathChartData);
          }
        }
      };

      const apiURL = queryCurUthSeq
        ? `/api/v2/score/student/category-rate?userSeq=${studentSeq}&uthSeq=${queryCurUthSeq}`
        : `/api/v2/score/student/category-rate?userSeq=${studentSeq}`;
      request.get(apiURL, null, successHandler).catch((error) => console.error('API 호출 중 오류 발생:', error));
    };
    /** RW와 MATH 차트 데이터를 한번에 처리하는 함수 */
    const generateChartData = (type, fetchedData) => {
      // 선택된 타입별 차트 테이터 설정
      const config = CHART_CONFIG[type];
      const fetchedTargetCategoryRate = fetchedData.targetCorrectRate.find((item) => item.subject === type);
      const fetchedTargetGateplusCorrectRate = fetchedData.gateplusCorrectRate.find((item) => item.subject === type);

      // 각 타입(CAS, IAI 등)별 데이터 처리
      const chartData = config.chartOptions.map((chartOption) => {
        // classAnswer와 overall 데이터에서 해당하는 타입의 데이터 찾기
        const targetCategory = fetchedTargetCategoryRate?.categoriesRate.find((item) => item.dispContentDomainName === chartOption.label);
        const categoryRate = targetCategory?.correctRate ?? 0;
        const categoryToolTip = targetCategory?.contentDomainName ?? '';

        const gateplusCorrectRate =
          fetchedTargetGateplusCorrectRate?.categoriesRate.find((item) => item.dispContentDomainName === chartOption.label)?.correctRate ?? 0;
        // 차트 아이템에 데이터 결합
        return {
          ...chartOption,
          data: categoryRate,
          gatePlusData: gateplusCorrectRate,
          toolTipStr: categoryToolTip,
        };
      });
      return {
        label: config.label,
        unit: config.unit,
        max: config.max,
        chartDate: chartData,
      };
    };

    /// --- mode별 api 호출 --- ///
    if (mode === 'class' || mode === 'lecture') {
      if (getValidParam(queryCurScoreTestSeq)) getClassCategoriesRate();
    }
    if (mode === 'student') {
      if (getValidParam(studentSeq) && getValidParam(queryCurUthSeq)) getStudentCategoriesRate();
      else if (getValidParam(studentSeq)) getStudentCategoriesRate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCurScoreTestSeq, studentSeq, queryCurUthSeq]);

  return (
    <S.AnswerRateSection>
      <AnswerRateGraphBox
        boxTitle='Correct answer rate by Categories'
        setActiveSatBox={setActiveSatBox}
        isActive={activeSatBox === 'RW'}
        chartInfo={RWChartData}
        handleSelectSubject={handleSelectSubject}
        isShowAverage
        checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
        disabled={currentModuleSubject === 'M'}
      />
      <AnswerRateGraphBox
        boxTitle='Correct answer rate by Categories'
        setActiveSatBox={setActiveSatBox}
        isActive={activeSatBox === 'MATH'}
        chartInfo={mathChartData}
        handleSelectSubject={handleSelectSubject}
        isShowAverage
        checkViewAboutAverageOfGateplus={checkViewAboutAverageOfGateplus}
        disabled={currentModuleSubject === 'E'}
      />
    </S.AnswerRateSection>
  );
}

const S = {
  AnswerRateSection: styled.section`
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
  `,
};
