import { useState } from 'react';
import styled from 'styled-components';

import TestEditComBtn from './_components/TestEditComBtn';
import SourcesForReview from './_components/SourcesForReview';

import SourceSettingPopupSection from './_components/SourceSettingPopupSection';
import QuestionSettingPopupSection from './_components/QuestionSettingPopupSection';
import AnswerChoiceSettingPopupSection from './_components/AnswerChoiceSettingPopupSection';

/** AP 프로젝트에서 사용할 컴포넌트 모음 */
export default function ApComponentsPage() {
  const [selectedItemId, setSelectedItemId] = useState(1);
  const handleClickReviewItem = (item) => {
    setSelectedItemId(item.id);
    console.log(item);
  };
  // 목업 데이터
  const reviewData = [
    { id: 1, data: '그럴듯한_데이터1' },
    { id: 2, data: '그럴듯한_데이터2' },
    { id: 3, data: '그럴듯한_데이터3' },
    { id: 4, data: '그럴듯한_데이터4' },
  ];

  return (
    <S.Wrap>
      <S.ButtonSection>
        <h2>버튼들</h2>

        <TestEditComBtn title='아악' onClick={() => console.log('콜백 주세영')} variant='blue' />

        <TestEditComBtn title='악1' onClick={() => console.log('콜백 주세영')} />

        {/* 데이터에 id를 받아 선택된 데이터를 보여주는 컴포넌트  */}
        <SourcesForReview data={reviewData} selectedItemId={selectedItemId} callback={handleClickReviewItem} />
      </S.ButtonSection>

      <S.PopupSection>
        <h2>Source setting 팝업</h2>
        <SourceSettingPopupSection />
      </S.PopupSection>

      <S.PopupSection>
        <h2>Question setting 팝업</h2>
        <QuestionSettingPopupSection />
      </S.PopupSection>

      <S.PopupSection>
        <h2>Answer Choice setting 팝업</h2>
        <AnswerChoiceSettingPopupSection />
      </S.PopupSection>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 100px 20px;
  `,

  ButtonSection: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
  `,

  PopupSection: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    border: 1px solid #d7dadf;

    h2 {
      width: 80%;
      text-align: left;
    }
  `,
};
