import imgCheckIcon from 'assets/gate_member/image/check_icon.svg';
import imgCheckboxDefault from 'assets/gate_member/image/select-box_default.svg';
import imgCheckboxCheck from 'assets/gate_member/image/select-box_pressed.svg';
import imgCheckIconSuccess from 'assets/gate_member/image/success_icon.svg';
import SchoolLocationSelectBox from 'components/_common/inputs/SchoolLocationSelectBox';
import SelectBox from 'components/_common/inputs/SelectBox';
import usePressESC from 'hooks/usePressESC';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import request from 'utils/Request.utils';
// hook

/** 학생 추가 form modal */
export default function AddStudentAccount({ setBack, setShowAddStudentModal, callback }) {
  usePressESC(() => handleButtonBack());

  const [schoolTypeList, setSchoolTypeList] = useState(); // 학교 종류 목록 상태
  const [locationList, setLocationList] = useState([]); // 학교 위치 목록 상태
  const [schoolType, setSchoolType] = useState(''); // 학교 종류 상태
  const [schoolLocation, setSchoolLocation] = useState(''); // 학교 위치 상태

  /** react-hook-form */
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      userName: '',
      mobilePhoneNumber: '',
      password: '',
      confirmPassword: '',
      schoolName: '',
      schoolType: '',
      grade: '',
      schoolLocation: '',
      memo: '',
    },
  });

  // watch를 사용해 textarea의 값을 실시간으로 감시
  const textareaValue = watch('memo', ''); // 'message'는 필드 이름

  /** 비밀번호 실시간 유효성 검증 메소드 묶음 */
  const vaildationPassword = {
    islength: (text) => text.length >= 8,
    isPatteurn: (text) => {
      const patternSpecial = /[\W_]/;
      const patternNum = /[0-9]/;
      return patternSpecial.test(text) && patternNum.test(text);
    },
    isMatch: (text1, text2) => text1 === text2,
  };
  /** 전화 번호 입력 시, 포메팅 */
  const phoneNumberCheck = (event, filedName, maxLength = 13) => {
    let formattedValue = event.target.value.replace(/[^0-9-]/g, '');

    if (formattedValue.length > maxLength) {
      formattedValue = formattedValue.slice(0, maxLength);
    }

    setValue(filedName, formattedValue);
  };

  /** Form 제출 로직 */
  const onValid = (data, e) => {
    const userInfo = request.tokenDecoder();
    if (!data) {
      console.log('입력 받은 데이터에 오류 발생');
      return;
    } else if (!userInfo) {
      console.log('유저 정보를 받아 올 수 없습니다.');
      return;
    }

    // 비밀번호 매칭 확인
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', { message: '비밀번호가 일치하지 않습니다.' });
      return;
    }

    /** Post 요청으로 보낼 data */
    const params = {
      userEmail: String(data.email).trim(),
      userPasswd: String(data.password).trim(),
      userName: String(data.userName).trim(),
      studentSchool: String(data.schoolName).trim(), // 학교 이름
      schoolType: String(schoolType).trim(), // 학교 유형 (공통코드)
      studentGrade: String(data.grade).trim(),
      schoolLocation: String(schoolLocation).trim(), // 공통코드
      parentPhone: String(data.parentPhoneNumber).trim(),
      userMobile: String(data.mobilePhoneNumber1).trim() + String(data.mobilePhoneNumber2).trim() + String(data.mobilePhoneNumber3).trim(),
      memo: String(data.memo).trim(),
    };

    /** 응답 이후 동작 */
    const successHandler = (response) => {
      if (response.code === 407) {
        setError('email', { message: 'The email is already in use. Please use another email.' });
        return;
      }
      if (response.code === 408) {
        setError('mobilePhoneNumber', { message: 'The phone number is already in use. Please use another phone number.' });
        return;
      }
      if (response.code === 200) {
        // 응답 성공 후 form 비우고, 모달 닫기
        clearErrors();
        reset();
        setShowAddStudentModal(false);
        callback();
      }
    };

    request.post(`/api/academy/student`, params, successHandler);
  };
  /** Form 제출 실패 로직 */
  const onInValid = (errors, e) => {
    console.log('실패 : ', errors, e);
    return null;
  };

  /** "Back" 버튼 핸들러 */
  const handleButtonBack = () => {
    setBack(true);
  };

  /** Grade 목록 설정 */
  const gradeList = Array.from({ length: 12 }, (_, index) => ({
    value: String(index + 1),
    name: String(index + 1),
  }));
  /** 학교 분류 목록, 학교 위치 목록 API 얻어오기 */
  const getSchoolTypeList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let commonCodeList = response.result.commonCodeList;
        let locationList = response.result.locationList;

        setSchoolTypeList(
          commonCodeList &&
            commonCodeList
              .filter((i) => i.parentCode === '012000000000000')
              .map((item) => ({
                value: item.commonCode,
                name: item.codeName,
              }))
        );

        setLocationList(locationList);
      }
    };

    request.get(`/api/common/common-code?multiCode=${encodeURI('012000000000000')}&schoolLocationYn=Y`, null, successHandler);
  };

  /** 셀렉트 박스(SchoolType) 선택 동작 핸들러 */
  const handleSelectChangeSchoolType = (name = '', item) => {
    setSchoolType(item.value);
    setValue(name, item.name);
  };
  /** 셀렉트 박스(Grade) 선택 동작 핸들러 */
  const handleSelectChangeGrade = (name = '', item) => {
    setValue(name, item.name);
  };
  /** 셀렉트 박스(SchoolLocation) 선택 동작 핸들러 */
  const handleSelectChangeSchoolLocation = (name = '', item) => {
    setSchoolLocation(item.commonCode);
    setValue(name, item.schoolLocation);
  };

  /*  memo 글자 수 체크 */
  const [charCount, setCharCount] = useState(0);

  const handleMemoChange = (event) => {
    setCharCount(event.target.value.length); // 입력된 글자 수를 상태로 업데이트
  };

  useEffect(() => {
    getSchoolTypeList();
  }, []);

  return (
    <ModalBackgroundWrap>
      <ModalBox>
        <div className='inner_scroll_area custom_scroll'>
          <h2 className='title_main'>Add student account</h2>
          <p className='title_sub'>Manually enter account information to create new Student account</p>
          <form onSubmit={handleSubmit(onValid, onInValid)}>
            <div className='input_area'>
              <fieldset className='w-50'>
                <legend>required information</legend>
                <ul>
                  <li>
                    <label htmlFor='email'>Email</label>
                    <input
                      type='email'
                      id='email'
                      name='userEmail'
                      className={errors.email && 'error_input'}
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        },
                      })}
                      required
                      autoComplete='off'
                    />
                    {errors.email && <p className='error_message'>{errors.email.message}</p>}
                  </li>
                  <li>
                    <label htmlFor='userName'>Name</label>
                    <input
                      type='text'
                      id='userName'
                      name='userName'
                      className={errors.userName && 'error_input'}
                      {...register('userName', {
                        required: 'Name is required',
                      })}
                      required
                      autoComplete='off'
                    />
                    {errors.userName && <p className='error_message'>{errors.userName.message}</p>}
                  </li>
                  <li>
                    <label htmlFor='mobilePhoneNumber'>Mobile Phone NO.</label>
                    <div style={{ display: 'flex', gap: '0.5rem' }} name={'mobilePhoneNumber'}>
                      <input
                        type='tel'
                        id='mobilePhoneNumber1'
                        maxLength='3'
                        className={errors.mobilePhoneNumber1 && 'error_input'}
                        {...register('mobilePhoneNumber1')}
                        onChange={(e) => {
                          if (e.target.value.length === 3) {
                            document.getElementById('mobilePhoneNumber2').focus();
                          }
                        }}
                        required
                        autoComplete='off'
                      />
                      <span className='hyphen'>-</span>
                      <input
                        type='tel'
                        id='mobilePhoneNumber2'
                        maxLength='4'
                        className={errors.mobilePhoneNumber2 && 'error_input'}
                        {...register('mobilePhoneNumber2')}
                        onChange={(e) => {
                          if (e.target.value.length === 4) {
                            document.getElementById('mobilePhoneNumber3').focus();
                          }
                        }}
                        required
                        autoComplete='off'
                      />
                      <span className='hyphen'>-</span>
                      <input
                        type='tel'
                        id='mobilePhoneNumber3'
                        maxLength='4'
                        className={errors.mobilePhoneNumber3 && 'error_input'}
                        {...register('mobilePhoneNumber3')}
                        required
                        autoComplete='off'
                      />
                    </div>

                    {errors.mobilePhoneNumber && <p className='error_message'>{errors.mobilePhoneNumber.message}</p>}
                  </li>
                  <li>
                    <label htmlFor='password'>Password</label>
                    <input
                      type='password'
                      id='password'
                      name='userPassword'
                      className={errors.password && 'error_input'}
                      {...register('password', {
                        required: 'Password is required',
                        minLength: {
                          value: 8,
                          message: 'Password must be at least 8 characters',
                        },
                        // validate: {
                        //   isValidPassword: (value) => {
                        //     if (value === '12345678') {
                        //       console.log('중복체크')
                        //       return '중복입니다.'
                        //     }
                        //   },
                        // },
                      })}
                      required
                      minLength={8}
                    />
                    {errors.password && <p className='error_message'>{errors.password.message}</p>}
                  </li>
                  <li>
                    <label htmlFor='confirmPassword'>Confirm Password</label>
                    <input
                      type='password'
                      id='confirmPassword'
                      name='confirmPassword'
                      className={errors.confirmPassword && 'error_input'}
                      {...register('confirmPassword', {
                        required: 'Confirm Password is required',
                        minLength: {
                          value: 8,
                          message: 'Confirm Password must be at least 8 characters',
                        },
                        validate: {
                          isMatch: (value) => {
                            return value === watch('password') || 'Passwords must match';
                          },
                        },
                      })}
                      required
                      minLength={8}
                    />
                    {errors.confirmPassword && <p className='error_message'>{errors.confirmPassword.message}</p>}
                  </li>
                </ul>
                <div className='com_password_message'>
                  <p className={`check ${watch().password && watch().password.length >= 8 ? 'valid' : ''}`}>Must be over 8 characters</p>
                  <p className={`check ${watch('password') && vaildationPassword.isPatteurn(watch('password')) ? 'valid' : ''}`} id='characterMessage'>
                    Must have a number and a special character (!, @ , ?)
                  </p>
                  <p
                    className={`check ${watch('password') && watch().password.length >= 8 && watch().password === watch().confirmPassword ? 'valid' : ''}`}
                    id='matchMessage'>
                    Passwords must match
                  </p>
                </div>
              </fieldset>
              <fieldset className='w-50'>
                <legend>optional information</legend>
                <ul>
                  <li>
                    <label htmlFor='schoolName'>School Name</label>
                    <input
                      type='text'
                      id='schoolName'
                      name='schoolName'
                      className={errors.schoolName && 'error_input'}
                      {...register('schoolName', {})}
                      autoComplete='off'
                    />
                    {errors.schoolName && <p className='error_message'>{errors.schoolName.message}</p>}
                  </li>
                  <li className='horizontal_area'>
                    <div>
                      <div className='label'>School Type</div>
                      <SelectBox
                        register={register('schoolType', {})}
                        handleChange={handleSelectChangeSchoolType}
                        optionList={schoolTypeList}
                        autoComplete='off'
                        name={'schoolType'}
                      />
                    </div>
                    <div>
                      <div className='label'>Grade</div>
                      <SelectBox
                        register={register('grade', {})}
                        handleChange={handleSelectChangeGrade}
                        placeholder='Grade'
                        optionList={gradeList}
                        autoComplete='off'
                        name={'grade'}
                      />
                    </div>
                  </li>
                  <li>
                    <div>
                      <div className='label'>School Location</div>
                      <SchoolLocationSelectBox
                        register={register('schoolLocation', {})}
                        handleChange={handleSelectChangeSchoolLocation}
                        placeholder='Enter school location'
                        optionList={locationList}
                        name={'schoolLocation'}
                      />
                    </div>
                    {errors.schoolLocation && <p className='error_message'>{errors.schoolLocation.message}</p>}
                  </li>
                  <li className='memo_area'>
                    <label htmlFor='memo'>Memo</label>
                    <textarea
                      id='memo'
                      className={errors.memo && 'error_input'}
                      {...register('memo', {
                        maxLength: {
                          value: 1000,
                          message: 'The memo field can contain up to 1000 characters.',
                        },
                      })}
                      placeholder='Only you can see this memo. Write notes about the student.'
                      name={'memo'}
                    />
                    <div className={`display_character_count ${textareaValue.length > 1000 ? 'error' : ''}`}>{textareaValue.length} / 1000</div>
                    {errors.memo && <p className='error_message'>{errors.memo.message}</p>}
                  </li>
                </ul>
              </fieldset>
            </div>
            <div className='button_area'>
              <button type='button' className='btn_stroke' onClick={handleButtonBack}>
                Back
              </button>
              <button type='submit' className='btn_fill'>
                Save
              </button>
            </div>
          </form>
        </div>
      </ModalBox>
    </ModalBackgroundWrap>
  );
}

const ModalBackgroundWrap = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBox = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
  width: 52.6rem;
  box-sizing: border-box;
  overflow: hidden;
  .inner_scroll_area {
    overflow-y: scroll;
    max-height: 80vh;
    padding: 2rem;
  }
  .title_main {
    color: var(--sub-text, #111111);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: normal;
    // text-transform: uppercase;
    line-height: 2rem;
  }
  .title_sub {
    color: #7f8998;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  .hyphen {
    font-size: $gate-font-size-xl;
    color: var(--sub-text, #7f8998);
    line-height: 1.375rem;
    display: flex;
    align-items: center;
  }
  fieldset {
    & > ul {
      & > li {
        &.horizontal_area {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 0.5rem;
          & > *:nth-child(1) {
            flex: 2.5;
          }
          & > *:nth-child(2) {
            flex: 1;
          }
        }
        input[type='text'],
        input[type='email'],
        input[type='password'],
        input[type='tel'] {
          border: 1px solid #d2dbe2;
        }
        .select_box {
          input[type='text'] {
            border: none;
          }
        }
        &:has(input[required]) label::before,
        &:has(.required) .label::before {
          content: '*';
          color: var(--error-color, #f24b4b);
          font-size: 1rem;
          font-weight: 700;
          line-height: 0.75rem; /* 75% */
          text-transform: uppercase;
          margin-right: 0.375rem;
        }
        .error_message {
          display: block;
          flex: 100%;
          width: 100%;
          color: #f24b4b;
          margin-top: 0.5rem;
        }
        &.memo_area {
          textarea {
            height: 12.375rem;
          }
          .display_character_count {
            text-align: right;
            color: #a5aab1;
            font-weight: 400;
            font-size: 0.875rem;
            margin-top: 0.25rem;
            &.error {
              display: block;
              color: #f24b4b;
            }
          }
        }
      }
      & > li + li {
        margin-top: 1.875rem;
      }
    }
  }
  .input_area {
    display: flex;
    gap: 2.5rem;
    margin-top: 2.813rem;
  }
  .w-50 {
    width: 50%;
  }
  /* .w-50의 오른쪽에 세로선 추가 */
  .w-50:first-child {
    border-right: 0.0625rem solid var(--input-stroke, #d2dbe2);
    padding-right: 2.5rem;
  }

  .memo-container {
    display: flex;
    flex-direction: column;
  }

  .memo-label {
    font-size: 0.875rem;
    font-weight: 400;
    color: #7f8998;
    margin-bottom: 0.5rem;
  }

  .memo-textarea {
    height: 12.375rem;
    resize: none;
    padding: 1rem;
    font-size: 0.875rem;
    border: 1px solid #d2dbe2;
    border-radius: 0.25rem;
    outline: none;
  }

  .memo-textarea:focus {
    border-color: #0068bd;
  }

  .memo-char-count {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: var(--sub-color2, #a5aab1);
    text-align: right;
  }

  legend {
    color: var(--sub-text, #7f8998);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem; /* 111.111% */
    text-transform: uppercase;
    // display: block;
    width: 100%;
    padding-bottom: 1.5rem;
    // border-bottom: 1px solid #eff1f3;
    // margin-bottom: 1rem;
  }
  label,
  .label {
    color: var(--sub-text, #7f8998);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
    text-transform: uppercase;
    display: block;
    margin-bottom: 0.5rem;
    a {
      text-decoration: underline;
      color: #0068bd;
    }
  }
  input.error_input {
    border-color: #f24b4b;
  }
  input[type='checkbox'] {
    font-size: inherit;
    border: none;
    border-radius: 0;
    height: 0;
    width: 0;
    padding-inline: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    & + label {
      color: #434d5b;
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 0.75rem;
      cursor: pointer;
      display: block;
      position: relative;
      box-sizing: border-box;
      padding-left: 2rem;
      margin-top: 0.625rem;
      &::before {
        content: '';
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: calc(50% - 0.775rem);
        left: 0;
        display: inline-block;
        background: url(${imgCheckboxDefault}) no-repeat center center;
        background-size: cover;
      }
    }
    &:checked + label {
      &::before {
        background: url(${imgCheckboxCheck}) no-repeat center center;
      }
    }
  }
  textarea {
    padding: 1rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    border: 1px solid #d2dbe2;
  }
  .button_area {
    margin-top: 2.813rem;
    display: flex;
    justify-content: flex-end;
    gap: 1.25rem;
    button {
      min-width: 11.25rem;
      height: 3.5rem;
      padding: 1.25rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 100%;
      border-radius: 6px;
      transition: 0.3s;
      cursor: pointer;
      &.btn_stroke {
        background-color: #fff;
        border: 1px solid #0068bd;
        color: #0068bd;
      }
      &.btn_fill {
        background-color: #0068bd;
        color: #fff;
      }
    }
  }

  .com_password_message {
    margin-block: 0.5rem 1.25rem !important;
    .check {
      color: rgb(34, 34, 34, 0.5);
      font-weight: 400;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      &:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background-image: ${imgCheckIcon};
        background-position: center;
        background-repeat: no-repeat;
      }
      &.valid {
        color: rgb(34, 34, 34);
        &::before {
          background-image: url(${imgCheckIconSuccess});
        }
      }
    }
  }
`;
