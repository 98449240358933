import React from 'react';
import styled from 'styled-components';

/** AP / "Question Bank" 페이지에서 사용 할 메인 내부 박스 컴포넌트  */
export default function InnerBox({ className = '', children, ...rest }) {
  return (
    <S.Box className={` ${className}`} {...rest}>
      {children}
    </S.Box>
  );
}

const S = {
  Box: styled.div`
    width: 100%;
    border-radius: 0.25rem;
    border: 1px solid #d2dbe2;
    background: #f8f8f9;
    padding: 1.25rem;

    .inner_box_contents_wrap {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
    }
  `,
};
