import { useState } from 'react';
import styled from 'styled-components';
import CollapsibleContentItemContainer from './CollapsibleContentItemContainer';

/**
 * @typedef {Object} CollapsibleListProps
 * @property {Array<any>} [itemList] - 표시할 아이템 목록
 * @property {boolean} [headerSelectable=false] - 헤더 선택 가능 여부
 * @property {boolean} [contentSelectable=false] - 컨텐츠 선택 가능 여부
 * @property {Function} [handleSelectHeader=null] - 헤더 선택 시 호출될 콜백 함수
 * @property {Function} [handleSelectContent=null] - 컨텐츠 선택 시 호출될 콜백 함수
 * @property {Function} [renderHeader=null] - 헤더를 커스텀하게 렌더링하는 함수
 * @property {Function} [renderContent=null] - 컨텐츠를 커스텀하게 렌더링하는 함수
 * @property {Function} [itemId] - 아이템의 고유 ID
 * @property {Object} [expandedContentInfo] - 확장된 컨텐츠 정보의 정보 ( 데이터에 접근하는 key 정보 )
 */
export default function CollapsibleListItem({
  itemList,
  listIndex,
  headerSelectable,
  contentSelectable,
  handleSelectHeader = null,
  handleSelectContent = null,
  maxContentItemCount = null,
  maxHeaderItemCount = null,
  selectedHeaderItems = [],
  renderHeader = null,
  renderContent = null,
  itemId,
  expandedContentInfo,
}) {
  /** 확장된 컨텐츠 노출 여부 */
  const [isShowExpandedContent, setIsShowExpandedContent] = useState(false);
  /** 선택된 아이템 목록 */
  const [selectedItems, setSelectedItems] = useState([]);

  /** 헤더의 선택 상태를 확인하는 함수 - 하위 항목이 모두 선택되었는지 확인 */
  const isHeaderSelected = () => {
    const childItems = itemList[expandedContentInfo.contentKey];
    if (!childItems?.length) return false;
    return childItems.every((content) => selectedItems.includes(content[expandedContentInfo.itemId]));
  };

  /** Header 선택 핸들러 */
  const handleSelectHeaderItem = (item, isSelected) => {
    // 최대 선택 개수 제한 체크
    if (maxHeaderItemCount !== null && selectedHeaderItems.length >= maxHeaderItemCount && isSelected) {
      return;
    }

    // 커스텀 핸들러 호출
    if (handleSelectHeader) {
      handleSelectHeader(item, isSelected);
    }

    const contentIds = itemList[expandedContentInfo.contentKey].map((content) => content[expandedContentInfo.itemId]);

    if (isSelected) {
      setSelectedItems((prev) => [...new Set([...prev, ...contentIds])]);
    } else {
      setSelectedItems((prev) => prev.filter((id) => !contentIds.includes(id)));
    }
  };
  /** 하위 요소 선택 핸들러 - 커스텀 핸들러 있을 때 추가동작 */
  const handleSelectContentItem = (content, isSelected) => {
    // 최대 선택 개수 제한이 있을시 - 최대 개수 초과시 추가 동작 중단
    if (maxContentItemCount !== null && selectedItems.length >= maxContentItemCount && isSelected) return;
    // 커스텀 핸들러 있을 때 추가 동작
    if (handleSelectContent) handleSelectContent(content, isSelected);

    const itemId = content[expandedContentInfo.itemId];

    // 선택 시 하위 아이템 목록 추가
    setSelectedItems((prev) => (isSelected ? [...prev, itemId] : prev.filter((id) => id !== itemId)));
  };

  const uniqueHeaderInputId = `${itemList[itemId]}-${listIndex}-checkbox`;
  const uniqueContentInputId = `${itemList[itemId]}-${listIndex}-content`;

  return (
    <S.ListItem key={itemList[itemId]} className={`${isShowExpandedContent ? 'list_item_expanded' : ''}`}>
      <S.Header
        className={`${headerSelectable ? '' : 'no_select_able'}`}
        onClick={() => setIsShowExpandedContent((prev) => !prev)}
        isSelectedItem={isHeaderSelected(itemList)}
        isExpanded={isShowExpandedContent}>
        {headerSelectable && (
          <S.CheckboxWrapper onClick={(e) => e.stopPropagation()}>
            <input
              type='checkbox'
              id={uniqueHeaderInputId}
              checked={isHeaderSelected(itemList)}
              onChange={(e) => handleSelectHeaderItem(itemList, e.target.checked)}
            />
            <label htmlFor={uniqueHeaderInputId} />
          </S.CheckboxWrapper>
        )}
        {renderHeader ? (
          renderHeader(itemList)
        ) : (
          <div className='title_wrapper'>
            <p className='title'>{itemList.title}</p>
            <p className='sub_title'>{itemList.subTitle}</p>
          </div>
        )}
        <S.ExpandIcon isExpanded={isShowExpandedContent}>
          <i className='svg_icon icon_select gray'>&nbsp;</i>
        </S.ExpandIcon>
      </S.Header>

      <S.Content className={`${isShowExpandedContent ? 'expanded scroll' : ''}`}>
        {itemList[expandedContentInfo.contentKey]?.map((contentItem, index) => {
          const inputId = `${contentItem[expandedContentInfo.itemId]}-${index}_${uniqueContentInputId}`;
          return (
            <S.ContentItem
              className={`${contentSelectable ? '' : 'no_select_able'}`}
              key={inputId}
              isSelectedItem={selectedItems.includes(contentItem[expandedContentInfo.itemId])}>
              {contentSelectable && (
                <S.CheckboxWrapper>
                  <input
                    type='checkbox'
                    id={inputId}
                    checked={selectedItems.includes(contentItem[expandedContentInfo.itemId])}
                    onChange={(e) => handleSelectContentItem(contentItem, e.target.checked)}
                  />
                  <label htmlFor={inputId} />
                </S.CheckboxWrapper>
              )}

              <S.ContentItemContainer>
                {renderContent ? (
                  renderContent(contentItem)
                ) : (
                  <CollapsibleContentItemContainer
                    userInfo={{
                      userName: contentItem.expandedItemTitle,
                      userSeq: contentItem.additionalInfo?.userSeq,
                      upFileSeq: contentItem.additionalInfo?.upFileSeq,
                    }}
                    title={contentItem.expandedItemTitle}
                    subTitle={contentItem.expandedItemSubTitle}
                  />
                )}
              </S.ContentItemContainer>
            </S.ContentItem>
          );
        })}
      </S.Content>
    </S.ListItem>
  );
}

const S = {
  ListItem: styled.section`
    display: block;
    margin-bottom: 0.75rem;
  `,

  Header: styled.article`
    display: flex;
    height: 3rem;
    border: 1px solid #d2dbe2;
    border-radius: ${({ isExpanded }) => (isExpanded ? '0.25rem 0.25rem 0 0' : '0.25rem')};
    align-items: center;
    justify-content: space-between;
    background-color: ${({ isSelectedItem }) => (isSelectedItem ? '#edf7ff' : '#f8f8f8')};

    &.no_select_able {
      padding-left: 0.75rem;
    }
    .title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      .title {
        font-weight: 700;
        width: 6.75rem;
        flex: 1;
      }

      .sub_title {
        color: #6c7685;
        font-size: 0.813rem;
      }
    }
  `,

  CheckboxWrapper: styled.div`
    display: flex;
    width: 3rem;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;

    input[type='checkbox'] {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: 1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }

    label {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      width: 1rem;
      height: 1rem;

      &:before {
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        border: 1px solid #d1d5db;
        border-radius: 0.25rem;
        background: #fff;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0.3125rem;
        top: 0.125rem;
        width: 0.375rem;
        height: 0.5625rem;
        border: solid #fff;
        border-width: 0 0.125rem 0.125rem 0;
        transform: rotate(45deg);
        opacity: 0;
      }
    }

    input[type='checkbox']:checked + label {
      &:before {
        background: #0068bd;
        border-color: #0068bd;
      }

      &:after {
        opacity: 1;
      }
    }
  `,

  ExpandIcon: styled.div`
    width: 2.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0)')};
  `,

  Content: styled.article`
    background: #f8f8f8;
    max-height: 0;
    transition: all 0.3s;
    pointer-events: none;
    overflow: hidden;
    border: 1px solid transparent;

    &.expanded {
      max-height: 30rem;
      pointer-events: auto;
      overflow-y: auto;
      border-radius: 0 0 0.25rem 0.25rem;
      border: 1px solid #d2dbe2;
      border-top: none;
    }
  `,

  ContentItem: styled.div`
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ isSelectedItem }) => (isSelectedItem ? '#edf7ff' : '#f8f8f8')};
    &.no_select_able {
      padding-left: 0.75rem;
    }
  `,

  ContentItemContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    border-radius: 0.5rem;
    .expanded_title_wrapper {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }
    .expanded_sub_title {
      color: #6c7685;
      font-size: 0.875rem;
      padding-right: 0.75rem;
    }
  `,
};
