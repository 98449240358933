import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as ImgSvgIconDataBase } from 'assets/img/svg/icon_database_02.svg';
import { ReactComponent as ImgSvgIconFileCheck } from 'assets/img/svg/icon_file_check_03.svg';
import { ReactComponent as ImgSvgIconAdd } from 'assets/img/svg/icon_add.svg';

/** "아케데미 대시보드" 페이지 - "Test Status" 컴포넌트의 데이터가 없을 경우 보여 줄 화면 */
export default function NoData({ ...rest }) {
  return (
    <S.Wrap {...rest}>
      <ImgSvgIconDataBase className='main_icon' />
      <h5 className='tit'>No data to show...</h5>
      <p className='guide'>Set more tests or add a class to check test status</p>
      <div className='button_area'>
        <Link to={'/com/tests/set/selection/type'} title='페이지 이동'>
          <ImgSvgIconFileCheck />
          Go to Set Test
        </Link>
        <Link to={'/com/students/classes'} title='페이지 이동'>
          <ImgSvgIconAdd />
          Add more classes
        </Link>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    height: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    text-align: center;
    color: #111111;
    position: absolute;
    left: 0;
    top: 6rem;
    z-index: 1;
    .main_icon {
      color: #999999;
      width: 3rem;
    }
    .tit {
      font-weight: 600;
      font-size: 1rem;
    }
    .guide {
      margin-top: 0.5rem;
      font-weight: 400;
      font-size: 0.875rem;
    }
    .button_area {
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;
      gap: 0.75rem;
      a {
        color: #0068bd;
        background-color: #f4f8ff;
        border-radius: 0.5rem;
        padding: 0.375rem 0.625rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        svg {
          width: 1.125rem;
        }
      }
    }
  `,
};
