import { useState, useCallback, useRef } from 'react';

import request from 'utils/Request.utils.js';
import { nvl } from 'utils/Common.utils.js';
import { USER_LEVELS } from 'utils/constants.js';

import usePressESC from 'hooks/usePressESC.js';
import OtherUserId from '../_OtherUserId';

function LinkAnAcademyOrTutorModal({
  userInfo,
  useLevels,
  setLinkPop,
  authenCodeRef,
  requestLink,
  setRequestLink,
  setRequestCompanyName,
  setRequestEcSeq,
  confirmCodeError,
  setConfirmCodeError,
  setAlertLayerPopup,
}) {
  usePressESC(() => setLinkPop(false), !requestLink);

  const [linkPopTabState, setLinkPopTabState] = useState(1); // Link an Academy or Tutor 팝업 내 탭
  const linkPopTab = [
    { text: 'Using Code', value: 0 },
    { text: 'Searching Name', value: 1 },
  ];

  const renderLinkPopTab = useCallback(() => {
    return linkPopTab.map((v, idx) => {
      return (
        <button key={idx} className={`menu ${(linkPopTabState === v.value && 'active') || ''}`} onClick={() => setLinkPopTabState(v.value)}>
          {v.text}
        </button>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkPopTab]);

  // 학원 검색
  const searchKeywordRef = useRef();
  const [companyList, setCompanyList] = useState([]);
  const searchAcademy = () => {
    if (searchKeywordRef.current.value.length > 0) {
      const successHandler = (response) => {
        // console.log(response)
        if (response.code === 200) {
          let companyList = response.result.companyList;

          if (nvl(companyList) !== '' && companyList.length > 0) {
            // managerInfo 안에 있는 userLevel 값을 밖으로 꺼냄
            companyList = companyList.map((company) => {
              if (nvl(company.managerInfo) !== '') {
                const { userLevel, ...updatedManagerInfo } = JSON.parse(company.managerInfo)[0];
                return {
                  ...company,
                  userLevel,
                };
              } else if (nvl(company.tutorInfo) !== '') {
                const { userLevel, ...updatedTutorInfo } = JSON.parse(company.tutorInfo)[0];
                return {
                  ...company,
                  userLevel,
                };
              } else {
                return company;
              }
            });

            setCompanyList(companyList);
          } else {
            setCompanyList([]);
          }
        }
      };

      request.get(`/api/member/academies?searchField=company_name&searchKeyword=${searchKeywordRef.current.value}`, null, successHandler);
    }
  };
  const searchLink = (companyName, ecSeq, userLevel) => {
    if (useLevels.isTeacher && userLevel === USER_LEVELS.TUTOR) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Private Tutors can only be linked with students. <br/> Please try again.',
        };
      });
      return;
    }
    setRequestLink(true);
    setRequestCompanyName(companyName);
    setRequestEcSeq(ecSeq);
  };
  // 코드 확인
  const acaCodeCheck = () => {
    if (authenCodeRef.current.value.length > 0) {
      const successHandler = (response) => {
        // console.log(response)
        if (response.code === 200) {
          let academyList = response.result.academyList;

          if (nvl(academyList) !== '') {
            setRequestCompanyName(academyList.companyName);
            setRequestEcSeq(academyList.ecSeq);

            setRequestLink(true);
          }
        } else if (response.code === 401) {
          authenCodeRef.current.focus();
          setConfirmCodeError(true);
        }
      };

      request.get(`/api/member/profile/link/uniquecode?&code=${authenCodeRef.current.value}`, null, successHandler);
    } else {
      authenCodeRef.current.focus();
      setConfirmCodeError(true);
    }
  };

  return (
    <div className='com_popup type_dim pop_link_academy active'>
      <div className='pop_container xl'>
        <section className='pop_header'>
          <h1 className='pop_tit'>
            LINK AN {useLevels.isTeacher ? 'ACADEMY' : 'ACADEMY OR TUTOR'}
            <span className='detail'>
              Add an {useLevels.isTeacher ? 'Academy' : 'Academy or a Tutor'} by entering the associated code or by searching the name.
            </span>
          </h1>
          <button className='svg_icon btn_pop_close gray' onClick={() => setLinkPop(false)}>
            &nbsp;
          </button>
        </section>
        <section className='pop_body'>
          <article className='com_tab type_line'>{renderLinkPopTab()}</article>
          <article className='information_sec'>
            {linkPopTabState === 0 ? (
              <>
                <p className='top_notice_txt'>
                  Please enter either {useLevels.isTeacher ? 'Academy' : 'Academy or Tutor'} Code. If you don't have one, you can search by name.
                </p>
                <ul className='input_area'>
                  <li className='item'>
                    <p className='tit_field'>{useLevels.isTeacher ? 'academy' : 'academy / tutor'} code</p>
                    <div className={`input_field ${confirmCodeError ? 'error' : ''}`}>
                      {/*틀렸을 경우 addClass error*/}
                      <div className='input_wrap '>
                        <input type='text' className='input' ref={authenCodeRef} />
                        <button className='com_btn point line m' onClick={acaCodeCheck}>
                          CONFIRM
                        </button>
                      </div>
                      {confirmCodeError && <p className='error_message'>Incorrect code. Please try again.</p>}
                    </div>
                  </li>
                </ul>
              </>
            ) : (
              linkPopTabState === 1 && (
                <>
                  <p className='top_notice_txt'>Search the name of an {useLevels.isTeacher ? 'Academy' : 'Academy or a Tutor'}</p>
                  <div className='search_academy'>
                    <div className='input'>
                      <button className='btn_search'>
                        <i className='svg_icon icon_search dark_gray'>&nbsp;</i>
                      </button>
                      <input type='text' placeholder='Search' ref={searchKeywordRef} onKeyUp={searchAcademy} />
                    </div>
                    {companyList &&
                      companyList.length > 0 &&
                      companyList
                        .filter((i) => nvl(i.userLevel) !== '')
                        .map((item, index) => (
                          <div className='item' key={`academy-list-${index}`}>
                            <OtherUserId accountInfo={item} />
                            <div className='btn_field'>
                              <button className='com_btn s line' onClick={() => searchLink(item.companyName, item.ecSeq, item.userLevel)}>
                                Request link
                              </button>
                            </div>
                          </div>
                        ))}
                  </div>
                </>
              )
            )}
          </article>
        </section>
        <section className='pop_footer com_btn_wrap'>
          <button className='com_btn xl point line' onClick={() => setLinkPop(false)}>
            Cancel
          </button>
        </section>
      </div>
    </div>
  );
}

export default LinkAnAcademyOrTutorModal;
