import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import clearAppData from 'utils/clearAppData.js';
import request from 'utils/Request.utils';
import SessionStorage from 'utils/SessionStorage.utils';

import useOutsideClick from 'hooks/useOutsideClick';
import useUserLevels from 'hooks/useUserLevels.js';

import UserProfilePopup from './UserProfilePopup';

import ProfileImage from 'components/_common/ProfileImage';

/** 사용자 프로필 버튼 컴포넌트 - 사용자 정보를 표시하고 프로필 관련 작업을 수행 */
function UserProfileBtn({ setAlertLayerPopup }) {
  // 사용자 정보와 레이어 표시 여부 상태
  const [userInfo, _] = useState(request.tokenDecoder());
  // 상태 관리
  const [userLayer, setUserLayer] = useState(false);
  // 레이어 표시를 위한 Ref
  const userMenuRef = useRef(null);
  // 패키지 Hook 관리
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // 세션 스토리지
  const windowManager = SessionStorage.getItemJsonParse('windowManager');
  // 선택자 및 사용자 정의 훅
  const stateMenuManager = useSelector((state) => state.MenuManager);
  const { permissions: useLevels } = useUserLevels(userInfo?.userLevel);

  // 기타 랜더링 변수
  const isEducationStaff = useLevels.isTeacher || useLevels.isTutor || useLevels.isAcademy;

  /** 로그아웃 처리 함수 */
  const handleLogout = () => {
    // 테스트 진행 중일 경우 확인 팝업 표시 ( 아니면 로그아웃 )
    if (windowManager?.popupOn) {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          alertType: 'confirm',
          activateMode: 'testLogout',
          visible: true,
          alertMessage: 'Test is in progress. Are you sure you want to log out? Your test window will also close.',
        };
      });
    } else {
      clearAppData(dispatch, userInfo?.userId);
      window.location.href = '/signin';
    }
  };

  /** 사용자 레벨에 따른 프로필 설정 페이지로 이동 */
  const handleProfileSetting = () => {
    const { pathname } = window.location;

    const prefix = pathname.includes('/ap/') ? '/ap' : '';

    const routes = {
      isAcademy: `${prefix}/academy/profile`,
      isStudent: `${prefix}/student/profile`,
      isTeacher: `${prefix}/tutor/profile`,
      isTutor: `${prefix}/tutor/profile`,
      isParent: `${prefix}/parent/profile`,
      isTubletAdmin: `${prefix}/academy/profile`,
      isTubletTutor: `${prefix}/tutor/profile`,
    };

    const routeKey = Object.keys(routes).find((key) => useLevels[key]);
    if (routeKey) {
      navigate(routes[routeKey]);
    }
    setUserLayer(false);
  };

  // 외부 클릭시 팝업 닫기
  useOutsideClick(userMenuRef, () => setUserLayer(false), userLayer);

  const profileInfo = {
    userName: userInfo?.userName,
    userSeq: userInfo?.userSeq,
    upFileSeq: userInfo?.upFileSeq,
  };

  return (
    <S.Wrap className='user_wrap' ref={userMenuRef}>
      {/* 사용자 프로필 버튼 */}
      <button className='menu' onClick={() => setUserLayer((prev) => !prev)}>
        <ProfileImage className='gnb_profile_img' userInfo={profileInfo} isB2B={useLevels.isAcademy} size='1.875rem' />
        <p className='tit'>{userInfo?.userName}</p>
      </button>

      {/* 사용자 옵션 레이어 */}
      {userLayer && <UserProfilePopup handleLogout={handleLogout} setUserLayer={setUserLayer} handleProfileSetting={handleProfileSetting} />}
    </S.Wrap>
  );
}

export default UserProfileBtn;

const S = {
  Wrap: styled.div`
    .gnb_profile_img {
      margin-bottom: 0.4375rem;
    }
  `,
};
