import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

//img
import imgLink from 'assets/img/icon_link.svg';

import MiniAlert from 'components/_common/alerts/MiniAlert';
import styled from 'styled-components';

export default function Post({ cate, type, boardInfo, onClick }) {
  const navigate = useNavigate();

  const getFirstImageHtml = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const firstImg = doc.querySelector('img'); // 첫 번째 이미지 태그를 선택
    if (firstImg) {
      return firstImg.outerHTML; // 이미지 태그 HTML 문자열로 반환
    }
    return ''; // 이미지가 없으면 빈 문자열 반환
  };

  return (
    <article className={`box post ${type}`} onClick={type !== 'view' ? () => onClick(boardInfo.boardSeq, boardInfo.notiType, boardInfo.contents) : null}>
      <div className='top_area'>
        <p className='cate'>{cate}</p>
        <p className='date'>{isoTimeToDayjs(boardInfo.regDate).format('MM. DD. YYYY')}</p>
      </div>
      <div className='tit'>{boardInfo.title}</div>
      {/* {type === 'view' && <Share />} */}
      {/* <div className='thumb'><img src={ImgThumb} alt='img'/></div> */}
      {type === 'big' ? ( // list 상단 큰 사이즈 게시글
        // <div className='detail'>{parse(boardInfo.contents)}</div>
        boardInfo.notiType !== 'Y' && (
          <div
            className='detail'
            dangerouslySetInnerHTML={{
              __html: getFirstImageHtml(
                boardInfo.contents?.replace(/\/common\/commonImageView\.do/g, `${process.env.REACT_APP_API_URL}/api/common/commonImageView`)
              ),
            }}
          />
        )
      ) : type === 'view' ? ( // 상세페이지
        <>
          {/* <div className='detail'>{parse(nvl(boardInfo.contents))}</div> */}
          <div
            className='detail'
            dangerouslySetInnerHTML={{
              __html: boardInfo.contents?.replace(/\/common\/commonImageView\.do/g, `${process.env.REACT_APP_API_URL}/api/common/commonImageView`),
            }}
          />
          {/* <Share /> */}
          <div className='com_btn_wrap bottom'>
            <S.Button
              onClick={() => {
                cate === 'Gate⁺ NEWS' ? navigate('/gateplus/news/list') : navigate('/dsat/info/list');
              }}
              className='com_btn m line point'>
              Go To List
            </S.Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </article>
  );
}

const Share = () => {
  const [alert, setAlert] = useState(false);
  const copyPathname = () => {
    // 복사할 URL 가져오기
    let pathname = window.location.href;

    // URL 복사하기
    navigator.clipboard
      .writeText(pathname)
      .then(() => {
        setAlert(true);
      })
      .catch((err) => {
        console.error('복사 실패: ', err);
      });
  };

  return (
    <>
      <div className='share'>
        {/* <button><img src={imgBlog} alt="blog"/></button>
        <button><img src={imgKakao} alt="kakao"/></button>
        <button><img src={imgInsta} alt="insta"/></button>
        <button><img src={imgFacebook} alt="facebook"/></button> */}
        <button onClick={copyPathname}>
          <img src={imgLink} alt='link' />
        </button>
      </div>
      {alert && <MiniAlert text='Copy success' type='onlyText' active={alert} inactive={() => setAlert(false)} timeOut={2000} />}
    </>
  );
};

const S = {
  Button: styled.button`
    text-transform: uppercase;
  `,
};
