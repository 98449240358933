import React from 'react';
import styled from 'styled-components';
import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import { ReactComponent as Logo } from 'assets/img/svg/logo_gateplus_row_01.svg';

export default function IntroModal({ handleClose }) {
  return (
    <SimpleModalBase noPad={true}>
      <S.Wrap>
        <div className='modal_title_container'>
          <Logo className='logo' />
        </div>
        <div className='contents-area'>
          <h2 style={{ fontSize: '20px' }}>READ BEFORE YOU TAKE THE TEST!</h2>
          <p>
            Once you begin, you cannot go back to previous question. When you hit Next button, your answer will be graded and you cannot change your answer.
            Please solve your test carefully.
          </p>
          <p>ALSO,</p>
          <p>
            DO NOT refresh or exit the test screen. Once you refresh or exit the test screen, your answers up until what you have solved will be graded
            immediately.{' '}
          </p>
        </div>
        <div className='button-area'>
          <button onClick={handleClose} className='black'>
            OK
          </button>
        </div>
      </S.Wrap>
    </SimpleModalBase>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 340px;
  padding: 1.875rem;
  box-sizing: content-box;
  .modal_title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eaeaea;
    .logo {
      width: 100px;
      color: #000000;
    }
  }
  .contents-area {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.875rem;
    padding: 1.25rem 0;
    h2 {
      font-size: 1rem;
      font-weight: bold;
    }
    p {
      text-align: left;
      font-size: 0.875rem;
      width: 100%;
    }
    .time {
      font-size: 1rem;
      font-weight: bold;
    }
  }
  .button-area {
    display: flex;
    gap: 0.5rem;
    button {
      width: 100%;
      border-radius: 0.5rem;
      padding: 0.625rem 0;
      border: 1px solid #000000;
      font-size: 0.875rem;
      &.black {
        background-color: #000000;
        color: #ffffff;
      }
    }
  }
`;
