import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import request from 'utils/Request.utils';

import IconEmpty from 'assets/img/icon_empty.png';

import ByClassListSection from './ByClassListSection';

/**
 * tests/scheduled 페이지의 "By Class" 텝에 해당하는 컨텐츠 영역 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/tests/scheduled
 */
export default function ByClass({ reloadData, setSimpleAlert }) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const navigate = useNavigate();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  const [testScheduleList, setTestScheduleList] = useState([]);
  // testScheduleList 타입 : [{
  //   className: "", // string    => 클래스 이름
  //   classSeq: 0, // number      => 클래스 고유번호
  //   subjectName: "", // string  => 과목 이름
  //   subjectSeq: 0, // number    => 과목 고유번호
  //   testCount: 0, // number     => 전체 테스트 갯수

  //   testSetList: [
  //     {
  //       testName: '', // string       => 테스트 이름
  //       testStartDate: '', // string  => 테스트 시작일
  //       testEndDate: '', // string    => 테스트 종료일
  //       subjectSeq: 0, // number      => 과목 고유번호
  //       testSetSeq: '', // string     => 테스트 세트 고유번호
  //
  //       studentList: [
  //         {
  //           uthSeq: 0, // number     => 유저 고유번호
  //           userSeq: 0, // number    => 유저 고유번호
  //           userName: '', // string  => 유저 이름
  //           testDate: '', // string  => 테스트 일자
  //         },
  //       ],
  //     },
  //   ],
  // }]

  const getTestScheduleList = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const fetchedTestScheduleList = response.result.testScheduleList;

        setTestScheduleList(fetchedTestScheduleList);
      }
    };

    request
      .apGet(`/api/v1/academy/exam/taken/by-class`, null, successHandler)
      .catch((error) => console.error('ap/com/tests/scheduled > ByClass > getTestScheduleList() - API 호출 실패', error))
      .finally(() => console.log('데이터 호출 완료'));
  };

  useEffect(() => {
    getTestScheduleList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  return (
    <>
      <S.ByClassWrap>
        {testScheduleList.length > 0 ? (
          <S.ClassListWrap>
            {testScheduleList.map((item, index) => (
              <ByClassListSection testData={item} setSimpleAlert={setSimpleAlert} key={`class_${item.classSeq}_${index + 1}`} />
            ))}
          </S.ClassListWrap>
        ) : (
          // 데이터 없을 경우 노출
          <div className='com_nodata'>
            <p className='tit'>No test available.</p>
            <p className='txt'>Go to Set Test and set more test!</p>
            <div className='img'>
              <img src={IconEmpty} alt='' />
            </div>
            <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
              GO TO SET TEST
            </button>
          </div>
        )}
      </S.ByClassWrap>
    </>
  );
}

const S = {
  ByClassWrap: styled.div`
    padding: 1.25rem 1rem;
    background: #fff;
  `,
  ClassListWrap: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    background: white;
  `,
  ClassTestListSection: styled.div`
    flex: 1 1 400px;
    max-width: calc(50% - 0.5rem);
    min-width: 0;
    max-height: 39.75rem;
    padding: 1.25rem 1rem;
    border-radius: 0.625rem;
    background: #f8f8f9;

    .class_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.75rem;

      .class_header_title {
        line-height: 1.5;
        font-weight: 600;
        color: #0068bd;
      }

      .class_header_btn {
        font-size: 0.875rem;
        font-weight: 700;
        color: #4b5563;
        border: 1px solid #ebebeb;
        background: white;
        border-radius: 0.5rem;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .class_list_wrap {
      max-height: 34.625rem;
      overflow-x: hidden;
    }
  `,
};
