import {
  SET_PRIVATE_TUTOR_ID,
  SET_PRIVATE_TUTOR_LOGIN_TYPE,
  SET_PRIVATE_TUTOR_USER_EMAIL,
  SET_PRIVATE_TUTOR_USER_MOBILE,
  SET_PRIVATE_TUTOR_USER_NAME,
  SET_PRIVATE_TUTOR_AGREE_PRIVACY_POLICY,
  SET_PRIVATE_TUTOR_AGREE_TERMS_SERVICE,
  SET_PRIVATE_TUTOR_AGREE_NEWS_LETTER,
  SET_PRIVATE_TUTOR_TUTORING_NAME,
  CLEAR_PRIVATE_TUTOR_STATE,
} from './privatetutor.actionTypes';
import { USER_LEVELS } from 'utils/constants';

// default state
const initialState = {
  userId: '',
  loginType: '',
  userEmail: '',
  userLevel: USER_LEVELS.TUTOR, // 고정
  userMobile: '',
  userName: '',
  tutoringName: '',
  agree_privacyPolicy: false,
  agree_termsService: false,
  agree_newsLetter: false,
};
const privateTutorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRIVATE_TUTOR_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case SET_PRIVATE_TUTOR_LOGIN_TYPE:
      return {
        ...state,
        loginType: action.payload,
      };
    case SET_PRIVATE_TUTOR_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload,
      };
    case SET_PRIVATE_TUTOR_USER_MOBILE:
      return {
        ...state,
        userMobile: action.payload,
      };
    case SET_PRIVATE_TUTOR_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };
    case SET_PRIVATE_TUTOR_AGREE_PRIVACY_POLICY:
      return {
        ...state,
        agree_privacyPolicy: action.payload,
      };
    case SET_PRIVATE_TUTOR_AGREE_TERMS_SERVICE:
      return {
        ...state,
        agree_termsService: action.payload,
      };
    case SET_PRIVATE_TUTOR_AGREE_NEWS_LETTER:
      return {
        ...state,
        agree_newsLetter: action.payload,
      };
    case SET_PRIVATE_TUTOR_TUTORING_NAME:
      return {
        ...state,
        tutoringName: action.payload,
      };
    case CLEAR_PRIVATE_TUTOR_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
// 전역 상태 Reducer
export default privateTutorReducer;
