import SelectBox from 'components/_common/inputs/SelectBox';
import useOutsideClick from 'hooks/useOutsideClick';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import request from 'utils/Request.utils';

/** 반 변경 모달 */
function ChangeClassModal({ studentInfo, studentClassList, companyClassList, selectClass, setSelectClass, alertSet, getStudentList }) {
  const defaultClassList = studentClassList || [];
  const [selectedClass, setSelectedClass] = useState(defaultClassList.filter((item) => item.className !== 'Individual')); // individual 제외

  const classChangeLayerRef = useRef(null);
  useOutsideClick(
    classChangeLayerRef,
    () => {
      setSelectClass(false);
      setSelectedClass(defaultClassList);
    },
    selectClass
  );

  /** SelectBox에서 데이터 선택시 ( 중복된 반 선택시 업데이트하지 않음 ) */
  const handleSelect = (name, value) => {
    if (!selectedClass) {
      setSelectedClass([{ className: name, classSeq: value }]);
    } else {
      const isDuplicate = selectedClass.some((item) => item.classSeq === value);
      if (isDuplicate) return;

      setSelectedClass((prev) => [...prev, { className: name, classSeq: value }]);
    }
  };

  const updateClass = () => {
    const classSeqList = selectedClass.map((item) => item.classSeq).join(',');
    const isAp = window.location.href.includes('ap');

    const defaultClassSeqList = defaultClassList?.map((item) => item.classSeq).join(',');

    if (classSeqList === defaultClassSeqList) {
      setSelectClass(false);
      return;
    }

    const pms = {
      userSeq: studentInfo.userSeq,
      classSeqStr: classSeqList,
    };
    if (isAp) pms.classSeqList = selectedClass.map((item) => item.classSeq);

    const successHandler = (response) => {
      if (response.code === 200) {
        getStudentList();
        setSelectClass(false);
        alertSet({ visible: true, alertMessage: studentInfo.userName + '’s class has been updated.' });
      }
    };

    // API 호출
    if (isAp) request.apPost('/api/v1/academy/class/student', pms, successHandler);
    else request.post('/api/member/student/multi-class', pms, successHandler);
  };

  return (
    <S.ChangeClassModalContainer className={selectClass ? 'active' : ''} ref={classChangeLayerRef}>
      <div className='modal-title-section'>
        <div className='change-class-title'>Change class</div>
        <button
          type='button'
          className='svg_icon btn_pop_close'
          onClick={() => {
            setSelectClass(false);
            setSelectedClass(defaultClassList);
          }}
        />
      </div>
      <div className='selected-class-list-section'>
        <p className='class-list-title'>Current class</p>
        <ul className='class-list'>
          {/* individual을 제외한 class 목록이 0보다 클때 리스트 보여주기  */}
          {selectedClass.length ? (
            selectedClass.map((item, index) => {
              return (
                <li key={`class_list_${index}`}>
                  <p className='class-index'>Class {index + 1}</p>
                  <div className='class-list-item'>
                    <p className='class-name'>{item.className}</p>
                    <button className='delete-class-btn' onClick={() => setSelectedClass((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])}>
                      <i className='svg_icon btn_pop_close'>&nbsp;</i>
                    </button>
                  </div>
                </li>
              );
            })
          ) : (
            <li>
              <p>No Class (Individual)</p>
            </li>
          )}
        </ul>
      </div>
      <hr />
      <div className='select-class-section'>
        <p>Add a class</p>
        <SelectBox
          handleChange={handleSelect}
          placeholder='Select Class'
          optionList={companyClassList} // individual 제외
          fixedValue='Select Class'
        />
      </div>
      <div className='button-section'>
        {/* <button onClick={() => updateClass(studentData.userSeq, studentData.classSeq)}>SAVE</button> */}
        <button onClick={updateClass}>SAVE</button>
      </div>
    </S.ChangeClassModalContainer>
  );
}

export default ChangeClassModal;

// --- styled-components ---
const S = {};

S.ChangeClassModalContainer = styled.div`
  position: absolute;
  right: 0;
  box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.1490196078);
  border-radius: 0.625rem;
  background: #fff;
  padding: 1.25rem;
  z-index: 5;
  width: 20.125rem;
  text-align: left;

  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;

  display: none;

  &.active {
    display: flex;
  }

  .modal-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
    .change-class-title {
      color: black;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }

  .selected-class-list-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .class-list-title {
      font-size: 0.875rem;
      font-weight: 500;
      color: #4b5563;
    }
    .class-list {
      li {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        min-width: 0;
        padding: 0.5rem 0;

        .class-index {
          white-space: nowrap;
          font-size: 0.8125rem;
          color: #7f8998;
        }
        .class-list-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
          min-width: 0;

          .class-name {
            color: #4b5563;
            font-size: 0.875rem;
            font-weight: 500;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .delete-class-btn {
            background-color: #f1f1f5;
            width: 1rem;
            height: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;

            i {
              width: 1rem;
              height: 1rem;
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }

  .select-class-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.25rem;

    > p {
      font-size: 0.875rem;
      font-weight: 500;
      color: #4b5563;
    }
  }
  .button-section {
    display: flex;
    justify-content: flex-end;

    button {
      background: #0068bd;
      border-radius: 0.375rem;
      padding: 0.75rem 1.125rem;
      color: white;
      font-size: 0.875rem;
      font-weight: 500;
      cursor: pointer;
      transition: filter 0.3s;

      &:hover {
        filter: brightness(90%);
      }
    }
  }
`;
