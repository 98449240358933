import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import useOutsideClick from 'hooks/useOutsideClick';
import { ReactComponent as ImgIconSelect } from 'assets/img/svg/icon_arrow_02.svg';

import { Link, useParams, useNavigate } from 'react-router-dom';
import request from 'utils/Request.utils.js';
import { obfuscateUrlParam, deobfuscateUrlParam } from 'utils/urlParamObfuscator';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { SCORE_PARAMS } from 'utils/constants';

/**
 * Score 페이지 상단 메인 "클래스 선택" 영역 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/score/:{class | lecture}
 */
export default function SelectClass({ mode }) {
  const userInfo = request.tokenDecoder();

  /** 현재 classSeq - Route Parameter */
  const { classSeq: encClassSeq } = useParams();
  const classSeq = deobfuscateUrlParam(encClassSeq);
  /** Query Parameters hook */
  const { getObfuscatedValue, navigateWithParams } = useObfuscatedSearchParam();
  const queryCurScoreTestSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_TEST_SEQ);
  const queryCurScoreStudentSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_STUDENT_SEQ);
  const queryCurScoreClassSeq = getObfuscatedValue(SCORE_PARAMS.CUR_SCORE_CLASS_SEQ);

  const navigate = useNavigate();

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [isLoading, setIsLoading] = useState(false);
  /** 클래스 리스트 노출 여부 - State */
  const [isShowClassList, setIsShowClassList] = useState(false);
  /** 클래스 리스트 */
  const [classList, setClassList] = useState([
    {
      classSeq: 0, // number         => 클래스 고유번호
      className: '', // string       => 클래스 이름
      classStartDate: '', // string  => 클래스 시작일
    },
  ]);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const classListBox = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 //////////////////
  // 외부 클릭시 닫히는 훅
  useOutsideClick(classListBox, () => setIsShowClassList(false));
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  /////////////////// React useEffect 영역 시작 ///////////////////////
  useEffect(() => {
    /** 아카데미 유저의 class목록을 불러오는 함수  */
    const getClassList = () => {
      setIsLoading(true);
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedClassList = response.result.classList || [];
          setClassList(
            fetchedClassList.map((item) => ({
              className: item.className,
              classSeq: item.classSeq,
            }))
          );
          // queryCurScoreClassSeq가 유효하지 않을때(데이터가 없을때)만 가장 최근에 시험을 본 클래스로 네비게이트 && QueryParams 세팅
          if (!queryCurScoreClassSeq && fetchedClassList.length > 0) {
            const latestExamClassSeq = String(response.result.maxClass);
            navigateWithParams(`/com/score/${mode}/${obfuscateUrlParam(latestExamClassSeq)}`, {
              keepParams: [SCORE_PARAMS.CUR_SCORE_STUDENT_SEQ, SCORE_PARAMS.CUR_SCORE_TEST_SEQ, SCORE_PARAMS.CUR_SCORE_CLASS_SEQ, SCORE_PARAMS.CUR_STU],
              newParams: { [SCORE_PARAMS.CUR_SCORE_CLASS_SEQ]: latestExamClassSeq },
              replace: true,
            });
          }
        }
      };

      request
        .get(`/api/dsat/score/class?ecSeq=${userInfo?.ecSeq}`, null, successHandler)
        .catch((error) => console.error('', error).finally(() => setIsLoading(false)));
    };

    getClassList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCurScoreTestSeq]);
  /////////////////// React useEffect 영역 끝 ///////////////////////

  /** 선택된 className - params에 있는 데이터를 기반으로 설정 */
  const selectedClass = classList.find((item) => item.classSeq === Number(classSeq))?.className ?? 'Invalid Class';
  /** 선택된 class를 query parameter에 등록 및 선택된 classSeq에 해당하는 경로 생성 */
  const getSelectedClassURL = (classSeq) => {
    navigateWithParams(`/com/score/${mode}/${obfuscateUrlParam(String(classSeq))}`, {
      keepParams: [SCORE_PARAMS.CUR_SCORE_STUDENT_SEQ],
      newParams: { [SCORE_PARAMS.CUR_SCORE_CLASS_SEQ]: classSeq },
      replace: true,
    });
  };

  // 클래스 선택 처리를 위한 핸들러 함수 수정
  const handleClassSelect = (selectedClassSeq) => {
    // 현재 선택된 클래스와 동일한 경우
    if (Number(selectedClassSeq) === Number(classSeq)) {
      setIsShowClassList(false);
      return;
    }

    // 다른 클래스 선택시 네비게이션 실행
    getSelectedClassURL(selectedClassSeq);
    setIsShowClassList(false);
  };

  return (
    <S.Wrap
      title='Open/Close'
      className={`type_tit type_big ${classList ? 'active' : ''}`}
      onClick={() => setIsShowClassList((prev) => !prev)}
      ref={classListBox}>
      {/*클래스 active 포함 시 옵션 노출*/}
      <div className='selected_input_section'>
        <section className='selected_wrap'>
          <p className='tit_field'>Class</p>
          <S.SelectInput readOnly type='text' className='selected' placeholder={selectedClass} />
        </section>
        <div className='btn_dropdown'>
          <ImgIconSelect />
        </div>
      </div>
      <section className='layer'>
        {isShowClassList &&
          classList?.map((item, idx) => (
            <S.ClassLink
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClassSelect(item.classSeq);
              }}
              key={`${item.className}-${idx}`}
              className={`option ${Number(item.classSeq) === Number(classSeq) ? 'active' : ''}`}>
              {item.className}
            </S.ClassLink>
          ))}
      </section>
    </S.Wrap>
  );
}

const S = {
  ClassLink: styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  `,
  SelectInput: styled.input`
    cursor: pointer;
  `,
  Wrap: styled.div`
    height: 3.25rem;
    position: relative;
    flex: 0 0 26.5rem;
    cursor: pointer;
    .selected_input_section {
      height: 100%;
      overflow: hidden;
      display: flex;
      border-radius: 0.5rem;
      background: #fff;
      border: 2px solid #020202;
    }
    .selected_wrap {
      display: flex;
      align-items: center;
      height: 100%;
      box-sizing: border-box;
      flex: 1;
      padding-left: 1rem;
      font-weight: 700;
      font-size: 18px;
    }
    .layer {
      position: absolute;
      background: #fff;
      margin-top: 1px;
      box-shadow: 0px 2px 6px -3px #00000026;
      left: 0;
      right: 0;
      font-size: 12px;
      display: none;
      z-index: 3;
      border-radius: 4px;
      overflow: auto;
      color: #7f8998;
      max-height: 150px;
      .option {
        text-align: center;
        padding: 7px 12px;
        border-bottom: 1px solid #d2dbe2;
        &:hover {
          background: #f2f2f2;
          cursor: pointer;
        }
        &.disabled {
          background: #f4f4f4;
          color: #a6b3be;
          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }

    .selected {
      border-color: #000;
      height: 30px;
      background: #fff;
      outline: none;
      border: 1px solid #000;
      width: 100%;
      @include multi-ellipsis(1);
      caret-color: transparent;
      cursor: default;
      &::placeholder {
        // Chrome, Safar
        color: #000;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        // IE, Edge
        color: #000;
        opacity: 1;
      }
      &:focus {
        outline: none;
      }
    }
    textarea.selected {
      resize: none;
      line-height: 1;
      padding-block: 7px;
    }
    .layer {
      position: absolute;
      background: #fff;
      margin-top: 1px;
      box-shadow: 0px 2px 6px -3px #00000026;
      left: 0;
      right: 0;
      font-size: 12px;
      display: none;
      z-index: 3;
      border-radius: 4px;
      overflow: auto;
      color: #7f8998;
      max-height: 150px;
      .option {
        padding: 7px 12px;
        border-bottom: 1px solid #d2dbe2;
        &:hover {
          background: #f2f2f2;
          cursor: pointer;
        }
        &.disabled {
          background: #f4f4f4;
          color: #a6b3be;
          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
    &.active {
      .layer {
        display: block;
      }
    }
    &.type_tit {
      .selected {
        border: none;
        border-radius: 0;
        font-size: 1.125rem;
        font-weight: 600;
        &__wrap {
          border-radius: 4px;
          overflow: hidden;
          background: #fff;
          border: 1px solid #020202;
          @include flex(between);
          .tit_field {
            font-size: 12px;
            font-weight: 500;
            padding-left: 20px;
            @include flex(y);
            @include divider(1px, 15px, 10px);
          }
        }
      }
    }
    &.type_big {
      .tit_field {
        font-size: 1.125rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        &::after {
          content: '';
          display: inline-block;
          background-color: #f1f1f5;
          width: 1px;
          height: 1rem;
          vertical-align: -2px;
          margin-right: 0;
        }
      }
      .selected {
        height: 42px;
        width: 61%;
        flex: 1;
        text-align: center;
        padding-right: 43px;
        font-weight: 700;
        font-size: 18px;
        @include multi-ellipsis(1);
        &__wrap {
          border: 3px solid #020202;
        }
      }
      .layer {
        .option {
          text-align: center;
        }
      }
    }
    &.type_updown {
      &:before {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(calc(-50% - 5px)) rotate(180deg);
        @include addImg('icon_select.png', 10px, 6px, contain);
        @include svg-icon-color('icon_select', '000');
      }
      &:after {
        transform: translateY(calc(-50% + 5px));
      }
      .selected {
        border-color: $input-stroke;
      }
    }
    //컬러
    &.gray {
      &:after {
        background-color: $emphasis;
      }
      .selected {
        &__wrap {
          border-color: $emphasis;
        }
        .tit_field {
          color: $emphasis;
        }
      }
    }

    .btn_dropdown {
      background-color: #111111;
      width: 3rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 1.5rem;
        color: #ffffff;
      }
    }
  `,
};
