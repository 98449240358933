import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import request from 'utils/Request.utils.js';
import { nvl, validateEmail } from 'utils/Common.utils.js';
import LoadingBar from 'utils/LoadingBar';

import useUserLevels from 'hooks/useUserLevels';

//img
import { ReactComponent as ImgSvgIconMailing } from 'assets/img/svg/icon/icon-mail-illust-01.svg';

//component
import MiniAlert from 'components/_common/alerts/MiniAlert'; //한줄 알럿
import MainBox from 'components/user/_components/layouts/MainBox';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import Label from 'components/user/_components/common/forms/Label';
import Input from 'components/user/_components/common/forms/Input';
import ErrorText from 'components/user/_components/styles/ErrorText';
import SubButton from 'components/user/_components/common/buttons/SubButton';
import { USER_LEVELS } from 'utils/constants';
import LocalStorage from 'utils/LocalStorage.utils';

import {
  updateReduxStudentId,
  updateReduxStudentLoginType,
  updateReduxStudentUserEmail,
  updateReduxStudentUserMobile,
  updateReduxStudentUserName,
  updateReduxStudentAgreePrivacyPolicy,
  updateReduxStudentAgreeTermsService,
  updateReduxStudentAgreeNewsLetter,
} from 'reducers/auth/student.action';

/** 회원 가입 - 이메일 확인 코드 입력 페이지 */
export default function SignUpConfirmCodePage() {
  const stateCustomer = useSelector((state) => state.Customer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(stateCustomer.userLevel);
  // hook을 사용한 비교
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(stateCustomer.userLevel);

  const [alert, alertSet] = useState(true);
  /** 이메일 form 수정 가능 여부 */
  const [isEdit, setIsEdit] = useState(false);
  const [authenCodeCorrect, setAuthenCodeCorrect] = useState(true);
  /** 이메일 중복 여부 */
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    setFocus,
    reset,
  } = useForm({
    defaultValues: {
      userEmail: stateCustomer.userEmail,
      confirmationCode: '',
    },
  });

  /** 이메일 값 수정 */
  const editEmail = (emailValue) => {
    dispatch({
      type: 'setCustomer',
      payload: { fieldName: 'userEmail', data: emailValue },
    });
    setIsEdit(false);
  };

  /** 이메일 보내기 */
  const sendMail = (emailValue) => {
    let params = {
      userEmail: emailValue,
      userName: stateCustomer.userName,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        alertSet(true);
      }
    };

    request.post('/api/common/email', params, successHandler);
  };

  /** 이메일 중복 검사 */
  const checkEmailDuplicate = (emailValue) => {
    if (!validateEmail(nvl(emailValue))) {
      setFocus('userEmail');
      setIsDuplicateEmail(false);
      return;
    }

    let params = {
      userEmail: emailValue,
      confirmationCode: '',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let emailDuplicate = response.result.idDuplicate;

        if (emailDuplicate) {
          // 중복 일 경우
          setIsDuplicateEmail(true);
        } else {
          // 중복이 아닐 경우
          sendMail(emailValue);
          editEmail(emailValue);
        }
      }
    };

    request.get(`/api/common/email/duplicate?&userEmail=${params.userEmail}`, null, successHandler);
  };

  /** 회원 가입 API 요청 */
  const fetchSignup = (data) => {
    let params = {
      userId: data.userEmail,
      userPasswd: stateCustomer.userPasswd,
      userEmail: data.userEmail,
      userName: stateCustomer.userName,
      userNickname: stateCustomer.userName,
      userLevel: stateCustomer.userLevel,
      userMobile: stateCustomer.userMobile,
      schoolType: stateCustomer.schoolType,
      studentGrade: stateCustomer.studentGrade,
      studentSchool: stateCustomer.studentSchool,
      schoolLocation: stateCustomer.schoolLocation,
      acceptReceivingMail: stateCustomer.mailYn,
      acceptMarketingMail: stateCustomer.marketingYn,
      familyPhone: stateCustomer.parentsPhone,
      userStatus: '005003000000000',
      regUserSeq: 1,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        setLoading(false);
        if (useLevels.isStudent || useLevels.isParent) navigate('/signup/complete');

        request.del(`/api/common/authen/code/${params.userEmail}`);
      }
    };

    request.post('/api/common/user', params, successHandler);
  };

  /** 인증 코드 확인 */
  const authenCodeCheck = (data) => {
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        if (useLevels.isAcademy) navigate('/signup/email/academy-admin/upload');
        if (useLevels.isTeacher) navigate('/signup/email/academy-teacher/academy-code');
        else if (useLevels.isTutor) navigate('/signup/email/tutor/upload');
        // else if (useLevels.isStudent) fetchSignup(data);
        else if (useLevels.isStudent) gotoStudentNextPage(data);
        else if (useLevels.isParent) gotoParentNextPage(data);
      } else if (response.code === 401) {
        setAuthenCodeCorrect(false);
        setLoading(false);
      }
    };

    request.get(`/api/common/authen/code?authenCode=${data.confirmationCode}&userEmail=${stateCustomer.userEmail}`, null, successHandler);
  };

  const gotoStudentNextPage = (data) => {
    dispatch(updateReduxStudentId(data.userEmail));
    dispatch(updateReduxStudentLoginType('email'));
    dispatch(updateReduxStudentUserEmail(data.userEmail));
    dispatch(updateReduxStudentUserMobile(stateCustomer.userMobile));
    dispatch(updateReduxStudentUserName(stateCustomer.userName));
    dispatch(updateReduxStudentAgreePrivacyPolicy(true));
    dispatch(updateReduxStudentAgreeTermsService(stateCustomer.marketingYn === 'Y'));
    dispatch(updateReduxStudentAgreeNewsLetter(stateCustomer.mailYn === 'Y'));
    setTimeout(() => {
      navigate('/signup/social/student/student-information');
    }, 500);
  };

  const gotoParentNextPage = (data) => {
    const params = {
      userId: data.userEmail,
      userEmail: data.userEmail,
      loginType: 'email',
      userLevel: USER_LEVELS.PARENT,
      userName: stateCustomer.userName,
      userMobile: stateCustomer.userMobile,
      agree_privacyPolicy: true,
      agree_termsService: stateCustomer.marketingYn === 'Y',
      agree_newsLetter: stateCustomer.mailYn === 'Y',
      isNew: true,
    };
    console.log(`🚨 params :`, params, `🚨`);
    const successHandler = (response) => {
      console.log(`🚨 response :`, response, `🚨`);
      // 200
      if (response.code === 200) {
        LocalStorage.setItem('userSession', 'accessToken', response.result.token.accessToken);
        LocalStorage.setItem('userSession', 'refreshToken', response.result.token.refreshToken);
        setTimeout(() => {
          navigate('/signup/complete');
        }, 1000);
      }
      // navigate('/signup/complete');
    };
    request.post(request.apiUris.auth.signupParent, params, successHandler);
  };

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    if (!isDuplicateEmail && data.confirmationCode !== '') {
      authenCodeCheck(data);
    } else {
      setFocus('confirmationCode');
      setAuthenCodeCorrect(false);
    }
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <>
      <S.Wrap>
        <MainBox>
          <ImgSvgIconMailing className='main-icon' />
          <h4 className='code_title'>Enter the code from your email</h4>
          <p className='code_desc'>Confirmation code has been sent to the email address. Please check your email and enter the confirmation code.</p>
          {/* 이메일 입력 */}
          <form onSubmit={handleSubmit(onValid)}>
            <div className='row-wrap'>
              <FormItemWrap>
                <Label htmlFor='userEmail'>Email</Label>
                <Input id='userEmail' register={register('userEmail', {})} type='email' required readOnly={!isEdit} />
                {errors.userEmail && (
                  <ErrorText>
                    {errors.userEmail.message}{' '}
                    {isDuplicateEmail ? 'The email is already in use. Please use another email.' : 'Email format is not acceptable. Please check again.'}
                  </ErrorText>
                )}
              </FormItemWrap>
              {!isEdit ? (
                <SubButton className='btn-small' onClick={() => setIsEdit(true)}>
                  EDIT
                </SubButton>
              ) : (
                <PrimaryButton className='btn-small' onClick={() => checkEmailDuplicate(watch().userEmail)}>
                  SEND
                </PrimaryButton>
              )}
            </div>
            <FormItemWrap>
              <Label htmlFor='confirmationCode'>Confirmation Code</Label>
              <Input id='confirmationCode' register={register('confirmationCode', {})} type='text' required autoFocus isError={!authenCodeCorrect} />
              {errors.confirmationCode && <ErrorText>{errors.confirmationCode.message}</ErrorText>}
              {!authenCodeCorrect && <ErrorText>Incorrect code. Please try again.</ErrorText>}
            </FormItemWrap>

            <p className='tip'>
              Didn't Receive An Email?&nbsp;&nbsp;&nbsp;
              <button className='btn-txt' onClick={() => sendMail()}>
                RESEND
              </button>
            </p>

            <PrimaryButtonArea className='primary-btn-area'>
              <PrimaryButton type='submit' title='제출'>
                Submit
              </PrimaryButton>
            </PrimaryButtonArea>
          </form>
        </MainBox>
      </S.Wrap>
      {/*텍스트 형 알럿*/}
      {isLoading && <LoadingBar />}
      {alert && <MiniAlert text='Email sent successfully' type='onlyText' active={alert} inactive={() => alertSet(false)} timeOut={2000} />}
    </>
  );
}

const S = {};

S.Wrap = styled.div`
  margin-bottom: 2.5rem;
  .main-icon {
    width: 5.125rem;
  }
  .code_title {
    margin-top: 2rem;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 500;
    color: #1f2937;
  }
  .code_desc {
    margin-top: 0.5rem;
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: #7f8998;
    margin-bottom: 1.25rem;
  }

  .row-wrap {
    display: flex;
    align-items: end;
    gap: 0.5rem;
    margin-bottom: 1rem;
    & > *:nth-child(1) {
      flex: 1;
    }
    & > *:nth-child(2) {
      flex: 0 0 20%;
    }
  }

  .btn-small {
    height: auto;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    font-weight: normal;
    border-radius: 0.375rem;
  }

  .tip {
    text-align: left;
    color: #7f8998;
    font-weight: 400;
    font-size: 0.813rem;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }
  .btn-txt {
    display: inline;
    color: var(--point-color);
    font-weight: 400;
    font-size: 0.813rem;
  }
`;
