import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import request from 'utils/Request.utils';
import ToastMessage from './ToastMessage';

/** 아카데미 대시보드 - Student 페이지의 학생 프로필 모달에서 열고 닫을 수 있는 "Memo" 영역 컴포넌트 */
export default function ProfileMemoArea({ studentInfo, setIsShowMemoArea }) {
  /** 로딩 상태 */
  const [isLoading, setIsLoading] = useState(true);
  /** 제출 결과 상태 알림 노출 여부 */
  const [isShowSuccessSubmit, setIsShowSuccessSubmit] = useState(false);
  /** textarea 에 입력 되는 값 */
  const [textareaValue, setTextareaValue] = useState('');

  /** 학생 메모 조회 API 요청 */
  const getMemo = ({ userSeq }) => {
    if (!userSeq) return;

    const successHandler = (response) => {
      if (response.code === 200) {
        // 요청 성공
        const studentMemo = response.result.studentMemo;

        setTextareaValue(studentMemo);
        setIsLoading(false);
      } else {
        // 요청 실패
        console.error(response);
      }
    };

    request.get(`/api/v2/academy/student/memo?userSeq=${userSeq}`, null, successHandler).catch((error) => {
      // 기타 응답 에러
      console.error(error);
      setIsLoading(false);
    });
  };

  /** 학생 메모 수정 API 요청 */
  const putMemo = ({ userSeq, userEmail, memo }) => {
    if (!userSeq || !userEmail || !memo) return;

    const param = {
      userSeq,
      userEmail,
      memo: String(memo).trim(),
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        // 제출 성공
        setIsShowSuccessSubmit(true);
        setIsLoading(false);
      } else {
        // 제출 실패
        console.error(response);
      }
    };

    request.put(`/api/v2/academy/student/memo`, param, successHandler).catch((error) => {
      // 기타 응답 에러
      console.error(error);
      setIsLoading(false);
    });
  };

  /** textarea 입력 핸들러 */
  const handleTextarea = (event) => {
    setTextareaValue(event.target.value);
  };

  /** Form 제출 핸들러 */
  const handleSubmit = ({ event, studentInfo, textareaValue }) => {
    event.preventDefault();
    putMemo({ userSeq: studentInfo.userSeq, userEmail: studentInfo.profile.userEmail, memo: textareaValue });
  };

  /** "Cancel" 버튼 핸들러 */
  const handleCancelButton = () => {
    setIsShowMemoArea(false);
  };

  useEffect(() => {
    if (studentInfo.userSeq) {
      getMemo({ userSeq: studentInfo.userSeq });
    }
  }, [studentInfo]);

  return (
    <S.Wrap>
      <h4 className='title'>Memo</h4>
      {isLoading ? (
        'Loading ...'
      ) : (
        <form
          onSubmit={(event) => {
            handleSubmit({ event, studentInfo, textareaValue });
          }}>
          <div className='textarea_wrap'>
            <label htmlFor='userProfileMemo'>Memo</label>
            <textarea
              name='userProfileMemo'
              id='userProfileMemo'
              value={textareaValue ? textareaValue : ''}
              onChange={handleTextarea}
              placeholder='Only you can see this memo. Write notes about 
the student.'
            />
            {textareaValue && <div className={`display_character_count ${textareaValue?.length > 1000 ? 'error' : ''}`}>{textareaValue?.length} / 1000</div>}
            {isShowSuccessSubmit && (
              <ToastMessage isShowSuccessSubmit={isShowSuccessSubmit} setShowThisComponent={setIsShowSuccessSubmit}>
                Memo saved
              </ToastMessage>
            )}
          </div>
          <div className='button_wrap'>
            <button type='button' onClick={handleCancelButton} className='line'>
              Cancel
            </button>
            <button type='submit' className='color'>
              Save
            </button>
          </div>
        </form>
      )}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    border-radius: 0.625rem;
    background: #fff;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    .title {
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 0.75rem;
    }
    form {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
    }

    .textarea_wrap {
      position: relative;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      width: 21.5rem;
      padding: 1rem;
      border-radius: 0.625rem;
      border: 1px solid var(--input-stroke, #d2dbe2);
      background: #fff;
      label {
        display: none;
      }
      textarea {
        flex: 1 0 auto;
        width: 100%;
        padding: 0;
        border: none;
      }
      .display_character_count {
        text-align: right;
        color: #a5aab1;
        font-weight: 400;
        font-size: 0.875rem;
        margin-top: 0.25rem;
        &.error {
          display: block;
          color: #f24b4b;
        }
      }
    }

    .button_wrap {
      margin-top: 0.75rem;
      display: flex;
      justify-content: right;
      gap: 0.75rem;
      button {
        display: flex;
        height: 2.375rem;
        padding: 0.75rem 1.125rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.25rem;
        border-width: 1px;
        border-style: solid;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 500;
        text-transform: uppercase;
        &.color {
          border-color: #0068bd;
          background-color: #0068bd;
          color: #fff;
        }
        &.line {
          border-color: #0068bd;
          background-color: #ffffff;
          color: #0068bd;
        }
      }
    }
  `,
};
