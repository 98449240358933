import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

/** 아카데미 대시보드의 Students - Classes 페이지 : "Test List" 모달의 "Student List" 모달 컴포넌트 */
export default function StudentListModal({ selectedStudentList }) {
  const {
    studentList,
    isView,
    position: { x, y },
  } = selectedStudentList;

  return (
    isView && (
      <S.Wrap positionTop={y} positionLeft={x}>
        <h4 className='main_title'>Student List</h4>
        <div className='scroll_area'>
          <ul>
            {studentList && studentList.length > 0
              ? studentList.map((item, index) => {
                  /** API에서 받아 온 시험 상태 */
                  const status = item.testStatus;

                  /** 화면에 표시 할 시험 상태 */
                  let displayStatus = '';
                  /** 화면에 표시 할 Style 용 시험 상태 class name */
                  let displayStatusClassName = '';

                  switch (status) {
                    case 'P':
                      // 완료
                      displayStatus = 'Taken';
                      displayStatusClassName = 'p';
                      break;
                    case 'E':
                      // 진행 전
                      displayStatus = 'Scheduled';
                      displayStatusClassName = 'e';
                      break;
                    case 'I':
                      // 진행 중
                      displayStatus = 'In-progress';
                      displayStatusClassName = 'i';
                      break;
                    case 'B':
                      // 진행 중 (쉬는 시간)
                      displayStatus = 'In-progress';
                      displayStatusClassName = 'i';
                      break;
                    default:
                      alert('어떤 값인지 파악이 되지 않습니다.');
                  }

                  return (
                    <li key={`list_${index}`}>
                      <span className='name'>{item.userName}</span>
                      <span className={`status_box ${displayStatusClassName}`}>{displayStatus}</span>
                    </li>
                  );
                })
              : 'no data'}
          </ul>
        </div>
      </S.Wrap>
    )
  );
}

const S = {
  Wrap: styled.div`
    position: fixed;
    top: ${(props) => props.positionTop || 0}px;
    left: ${(props) => props.positionLeft || 0}px;
    width: 14rem;
    padding: 0.625rem;
    border-radius: 0.625rem;
    background: #fff;
    box-shadow: 6px -6px 20px 0px rgba(0, 0, 0, 0.06);
    z-index: 999;
    .main_title {
      color: #111;
      font-size: 0.875rem;
      font-weight: 500;
    }

    .scroll_area {
      background-color: #fafafa;
      max-height: 15rem;
      overflow-y: auto;
      ul {
        li {
          display: flex;
          padding: 0.625rem 0.5rem;
          align-items: center;
          align-self: stretch;
          .name {
            overflow: hidden;
            color: #4b5563;
            text-overflow: ellipsis;
            font-size: 0.8125rem;
            font-weight: 400;
            width: 8.5rem;
          }
          .status_box {
            font-weight: 400;
            font-size: 0.8125rem;
            background-color: #eff1f3;
            border-radius: 0.25rem;
            display: flex;
            padding: 0.125rem 0.25rem;
            justify-content: center;
            align-items: center;
            &.p {
              color: #ff5858;
            }
            &.e {
              color: #0068bd;
            }
            &.i {
              color: #0da674;
            }
          }
        }
      }
    }
  `,
};
