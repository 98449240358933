import DraggableModal from 'components/_common/modals/DraggableModal';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import request from 'utils/Request.utils';

import usePressESC from 'hooks/usePressESC';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

import SimpleAlertModal from 'components/_common/modals/_SimpleAlertModal';
import ProfileImage from 'components/_common/ProfileImage';
import ProfileMemoArea from './_components/ProfileMemoArea';

import { ReactComponent as ImgSvgIconEdit } from 'assets/img/svg/icon_edit_memo_01.svg';
import { ReactComponent as ImgSvgIconSchool } from 'assets/img/svg/icon_school_building.svg';
import { ReactComponent as ImgSvgIconX } from 'assets/img/svg/x-close.svg';

import CommonLoadingAnimation from '../../CommonLoadingAnimation';

/**
 * 학생 "프로필" 상세 모달
 * @example
 * const [profileModalInfo, setProfileModalInfo] = useState({
 *   visible: false, // boolean  => 프로필 모달 노출 여부
 *   userSeq: 0, // number       => 학생 시퀀스
 * });
 * <ProfileModal profileModalInfo={profileModalInfo} closeProfileModal={() => setProfileModalInfo((prev) => ({ ...prev, visible: false }))} />
 */
export default function ProfileModal({ profileModalInfo, closeProfileModal }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** 메모 영역 노출 여부 상태 */
  const [isShowMemoArea, setIsShowMemoArea] = useState(true);
  /** 메모 창의 최초 입력 값에서 수정 되었는지 여부 상태 */
  const [isEditMemo, setIsEditMemo] = useState(false);
  /**  simpleAlertModal 상태 */
  const [simpleAlert, setSimpleAlert] = useState({
    visible: false,
    modalText: 'SOME_MODAL_TEXT',
  });
  /** 프로필 상세 데이터 */
  const profileDetailInitData = {
    userSeq: 0, // number              => 학생 시퀀스
    userName: '', // string            => 학생 이름
    upFileSeq: null, // number | null  => 프로필 이미지 파일 시퀀스
    classLists: [
      {
        classSeq: 0, // number    => 반 시퀀스
        className: '', // string  => 반 이름
      },
    ],
    schoolTypeName: null, // string | null      => 학교 유형
    schoolLocationName: null, // string | null  => 학교 위치
    studentGrade: 0, // number                  => 학년
    memo: null, // string | null                => 학생의 메모
    userEmail: '', // string                    => 학생의 이메일
    userMobile: '', // string                   => 학생의 휴대폰 번호
    studentSchool: '', // string                => 학생의 학교 이름
    regDate: '', // string                      => 학생의 등록 일자
  };
  const [profileDetail, setProfileDetail] = useState(profileDetailInitData);
  /** 프로필 로딩 상태 */
  const [isLoading, setIsLoading] = useState(true);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const handleCloseModal = () => {
    closeProfileModal();
    setProfileDetail(profileDetailInitData);
    setIsLoading(true);
  };
  /** 메모 영역 노출 토글 버튼 핸들러 */
  const handleButtonMemoToggle = () => {
    setIsShowMemoArea((current) => !current);
  };
  /** 현재 모달 컴포넌트 닫기 버튼 핸들러 */
  const handleClose = () => {
    // 메모 창이 열려있고 && 메모 창이 수정되었다면
    if (isShowMemoArea && isEditMemo) {
      setSimpleAlert({
        visible: true,
        modalText: 'Your memo will not be saved. Are you sure?',
        isConfirm: true,
        callback: () => handleCloseModal(),
      });
    } else {
      handleCloseModal();
    }
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  // 프로필 데이터 조회
  useEffect(() => {
    const getProfileDetail = (userSeq) => {
      setIsLoading(true);
      const successHandler = (response) => {
        if (response.code === 200) {
          const fetchedStudentProfile = response.result.studentProfile;

          setProfileDetail(fetchedStudentProfile);
        }
        setIsLoading(false);
      };

      request.get(`/api/v2/academy/student/${userSeq}/profile`, null, successHandler).catch((error) => {
        console.error('ProfileModal 프로필 불러오기 API 응답 에러', error);
        setIsLoading(false);
      });
    };

    if (profileModalInfo.userSeq === 0) return;
    getProfileDetail(profileModalInfo.userSeq);
  }, [profileModalInfo]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 --- START ////////////////////
  // 키보드 ESC 키 이벤트
  usePressESC(() => handleClose(), profileModalInfo.visible);
  //#endregion //////////////////  END  --- 커스텀 훅 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  /** 화면에 보여 줄 "Registration Date" */
  const displayRegDate = isoTimeToDayjs(profileDetail.regDate ? profileDetail.regDate : profileDetail.regDate.split[0]).format('YYYY / MM / DD');

  const classList = profileDetail.classLists || [{ class_name: 'individual', class_seq: 1 }];
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  const isDevEnvironmental = process.env.REACT_APP_ENVIRONMENT === 'localhost' || process.env.REACT_APP_ENVIRONMENT === 'development';
  const profileImgUrl =
    !isDevEnvironmental && profileDetail.upFileSeq > 0
      ? `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${profileDetail.userSeq}?${Date.now()}`
      : '';

  if (!profileModalInfo.visible) return null;

  return (
    <S.Wrap className='com_popup active pop_profile student'>
      {!isLoading && profileModalInfo.userSeq === 0 ? (
        <DraggableModal>
          <h3>Oops</h3>
          <h4>There's no profile data on the server</h4>
          <p> Something's wrong with this</p>
          <p>Try refreshing or contact your administrator</p>
        </DraggableModal>
      ) : (
        <DraggableModal>
          <div className='pop_container'>
            {isLoading && <CommonLoadingAnimation className='draggable_handle' />}

            <div className='right com_profile draggable_handle'>
              {profileImgUrl !== '' && <img src={profileImgUrl} alt='프로필이미지' className='bg profile-img-background' />}
              <article className='top_area'>
                <h3 className='tit'>Profile</h3>
                <div className='btn_wrap'>
                  <button type='button' title='Toggle Memo' className='btn_toggle_memo' onClick={handleButtonMemoToggle}>
                    <ImgSvgIconEdit />
                  </button>
                  <button type='button' title='Close Modal' className='btn_pop_close' onClick={handleClose}>
                    <ImgSvgIconX />
                  </button>
                </div>
              </article>
              <div className='wrap'>
                <article className='profile_area'>
                  <ProfileImage
                    className='com_profile_img'
                    size='6.875rem'
                    userInfo={{
                      userName: profileDetail.userName,
                      userSeq: profileDetail.userSeq,
                      upFileSeq: profileDetail.upFileSeq,
                    }}
                  />
                  <p className='name'> {profileDetail.userName} </p>
                </article>
                <div className='top_info_box'>
                  <div className='scroll_area custom_scroll'>
                    {classList &&
                      classList.length > 0 &&
                      classList.map((item, index) => {
                        return (
                          <article className='class_area' key={`class_${index}`}>
                            <p className='tit'>CLASS{index + 1}</p>
                            <p className='name'>{item.className}</p>
                          </article>
                        );
                      })}

                    <article className='school_area'>
                      <div className='item'>
                        <p className='tit_field'>School Type</p>
                        <p className='info_field'>{profileDetail.schoolTypeName ?? '-'}</p>
                      </div>
                      <div className='item'>
                        <p className='tit_field'>Grade</p>
                        <p className='info_field'>{profileDetail.studentGrade ?? '-'}</p>
                      </div>
                      <div className='item'>
                        <p className='tit_field'>School Location</p>
                        <p className='info_field'>{profileDetail.schoolLocationName ?? '-'}</p>
                      </div>
                    </article>
                  </div>
                </div>
                <article className='info_area'>
                  <div className='item'>
                    <i className='svg_icon icon_email blue'>&nbsp;</i>
                    <p className='tit_field'>Email</p>
                    <p className='info_field'>{profileDetail.userEmail}</p>
                  </div>
                  <div className='item'>
                    <ImgSvgIconSchool className='icon_school_building' />
                    <p className='tit_field'>School</p>
                    <p className='info_field'>{profileDetail.studentSchool}</p>
                  </div>
                  <div className='item'>
                    <i className='svg_icon icon_tel blue'>&nbsp;</i>
                    <p className='tit_field'>Students</p>
                    <p className='info_field'>{profileDetail.userMobile}</p>
                  </div>

                  <div className='item'>
                    <i className='svg_icon icon_class blue'>&nbsp;</i>
                    <p className='tit_field'>Registration Date</p>
                    <p className='info_field'>{displayRegDate}</p>
                  </div>
                </article>
              </div>
            </div>
            {isShowMemoArea && <ProfileMemoArea profileDetail={profileDetail} setIsShowMemoArea={setIsShowMemoArea} setIsEditMemo={setIsEditMemo} />}
          </div>
        </DraggableModal>
      )}

      <SimpleAlertModal alertInfo={simpleAlert} closeModal={() => setSimpleAlert((prev) => ({ ...prev, visible: false }))} />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .draggable_handle {
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }

    b {
      font-family: Roboto;
      font-weight: 600;
    }

    /* &.pop_profile {
      &.active {
        background-color: rgba(0, 0, 0, 0.24);
      }
    } */
    .pop_container {
      max-height: 728px;
      transform: translate(-50%, -50%);
      overflow: hidden;
      display: flex;
      gap: 0.125rem;
      background-color: inherit;
      box-shadow: none;
      overflow: visible;
      & > * {
        box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.15);
        border-radius: 0.625rem;
        overflow: hidden;
      }
    }

    .com_profile {
    }

    .top_area {
      position: relative;
      padding: 1.25rem 1rem 0;
      .tit {
        padding-top: 0;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 1rem;
      }
      .btn_wrap {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        padding: 1.25rem 1rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .btn_toggle_memo {
          width: 1.5rem;
          height: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            color: #ffffff;
            width: 1.35rem;
          }
        }
        .btn_pop_close {
          width: 1.5rem;
          height: 1.5rem;
          position: static;
          svg {
            color: #ffffff;
            width: 1.5rem;
          }
        }
      }
    }

    .profile_area {
      & > .name {
        padding-block: 5.15rem 1.94rem;
      }
    }

    .top_info_box {
      background-color: #fafafa;
      border-radius: 0.625rem;
      overflow: hidden;
      max-height: 12rem;
      height: fit-content;
    }

    .scroll_area {
      padding: 0.5rem 0rem;
      max-height: 12rem;
      height: fit-content;
      overflow-y: scroll;
    }

    .class_area {
      gap: 0.25rem;
      padding-block: 0;
      & + .class_area {
        margin-top: 0.75rem;
      }
      .tit {
        font-weight: 400;
        font-size: 0.8125rem;
        color: #7f8998;
        text-transform: none;
      }
      .name {
        font-weight: 400;
        font-size: 0.8125rem;
      }
    }

    .school_area {
      .item {
        .tit_field {
          font-weight: 400;
          font-size: 0.8125rem;
          color: #7f8998;
          text-transform: none;
        }
        .info_field {
          font-weight: 400;
          font-size: 0.8125rem;
        }
      }
    }

    .info_area {
      .icon_school_building {
        color: #0068bd;
        width: 1.25rem;
      }
      .item {
        padding: 0.88rem;
        .tit_field {
          font-weight: 400;
          font-size: 0.8125rem;
          color: #7f8998;
          margin-inline: 0.5rem auto;
        }
        .info_field {
          font-weight: 400;
          font-size: 0.8125rem;
          color: #4b5563;
        }
      }
    }
  `,
};
