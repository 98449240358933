import React from 'react';
import styled from 'styled-components';

/** "Ap" - "Question Bank" - "Create Test" 페이지에서 사용 할 "Label" 요소 스타일 용 컴포넌트 */
export default function LabelText({ children, ...rest }) {
  return <S.Wrap {...rest}>{children}</S.Wrap>;
}

const S = {
  Wrap: styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #7f8998;
  `,
};
