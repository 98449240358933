import { Fragment } from 'react';
import styled from 'styled-components';

import FreeResponseSection from './FreeResponseSection';
import MultiChoiceSection from './MultiChoiceSection';
import HybridSection from './hybridSection/HybridSection';

/** Report Card */
export default function Section({ section }) {
  return (
    <S.Section>
      <h3 className='section_title'>{section?.sectionName}</h3>
      {section?.parts?.length > 0 &&
        section.parts.map((part, index) => {
          return (
            <Fragment key={`part-${index}`}>
              {part.typeOfQuestion === 'M' && <MultiChoiceSection part={part} />}
              {part.typeOfQuestion === 'E' && <FreeResponseSection part={part} />}
              {part.typeOfQuestion === 'H' && <HybridSection part={part} />}
            </Fragment>
          );
        })}
    </S.Section>
  );
}

const S = {
  Section: styled.div`
    .section_title {
      margin-bottom: 1.875rem;
      font-size: 1.5rem;
      font-weight: 700;
    }
  `,
};
