// Packages
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
// Assets
import 'styles/css/exam.css';
// Components
// Functions
import request from 'utils/Request.utils';
import LocalStorage from 'utils/LocalStorage.utils';
import { handleMessageEvent, checkValidAccess } from 'utils/examValidAccess';

/** 시험 목록에서 Start 버튼을 눌러서 새 창에서 시험이 시작했을 때, 가장 먼저 열리는 화면 */
export default function ExamIntroPage() {
  /////////////////// 기타 React Hook 및 외부 패키지 선언 영역 시작 //////////////
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sendParams = LocalStorage.getItemJsonParse('sendParams');
  /////////////////// 기타 React Hook 및 외부 패키지 선언 영역 끝 //////////////

  /** Start 버튼 동작 핸들러 */
  const handleButtonTestStart = () => {
    navigate('/exam/dsat/acknowledgement', {
      replace: true,
    });
  };

  useEffect(() => {
    document.body.classList.add('dsat-intro-1');

    dispatch({ type: 'clearExamInfo' });
    dispatch({ type: 'clearExamComponents' });

    // 유효한 시험 접근 인지 확인하는 로직
    window.addEventListener('message', handleMessageEvent); // 부모창 메세지 이벤트의 data를 세션 스토리지에 넣음
    setTimeout(() => checkValidAccess(), 2000); // 2초 뒤에 sessionStorage에 있는 데이터를 확인

    return () => {
      document.body.classList.remove('dsat-intro-1');
      window.removeEventListener('message', handleMessageEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='main'>
      <div className='dsat-intro-1_main-flex-container'>
        <h1>
          <svg xmlns='http://www.w3.org/2000/svg' width='125' height='127' viewBox='0 0 125 127' fill='none'>
            <path
              d='M62.5072 44.1837V44.1719H62.4954V44.1837L52.0895 49.6931L41.6719 55.2144V121.481L52.0895 127.003V60.7357L62.5072 55.2262L72.9131 60.7357V127.003L83.3307 121.481V55.2144L72.9131 49.6931L62.5072 44.1837Z'
              fill='black'
            />
            <path
              d='M93.7477 115.959L104.165 110.438V44.171L93.7477 38.6497L83.3301 33.1285L72.9124 27.6072L62.5065 22.0859H62.4948L52.0889 27.6072L41.6712 33.1285L31.2536 38.6497L20.8359 44.171V110.438L31.2536 115.959V49.6923L41.6712 44.171L62.5065 33.1285L83.3301 44.171L93.7477 49.6923V115.959Z'
              fill='black'
            />
            <path
              d='M114.582 27.6063L104.165 22.0851L93.7471 16.5638L83.3294 11.0425L72.9118 5.52127L62.5059 0H62.4941L52.0882 5.52127L41.6706 11.0425L31.2529 16.5638L20.8353 22.0851L10.4176 27.6063L0 33.1276V99.3946L10.4176 104.916V38.6489L20.8353 33.1276L31.2529 27.6063L41.6706 22.0851L52.0882 16.5638L62.5059 11.0425L72.9118 16.5638L83.3294 22.0851L93.7471 27.6063L104.165 33.1276L114.582 38.6489V104.916L125 99.3946V33.1276L114.582 27.6063Z'
              fill='black'
            />
          </svg>
          <svg xmlns='http://www.w3.org/2000/svg' width='249' height='85' viewBox='0 0 249 85' fill='none'>
            <path d='M249.003 14.3621H234.59V0H224.101V14.3621H209.688V24.7715H224.101V39.1336H234.59V24.7715H249.003V14.3621Z' fill='black' />
            <path
              d='M113.463 83.7365H124.713L96.8358 26.9297H87.4861L59.6094 83.7365H70.8587L76.8041 72.193H107.493L113.439 83.7365H113.463ZM82.6015 60.9695L92.1486 42.4359L101.72 60.9695H82.6261H82.6015Z'
              fill='black'
            />
            <path d='M157.684 26.9297H117.945V38.1532H132.145V83.7365H143.484V38.1532H157.684V26.9297Z' fill='black' />
            <path
              d='M205.037 38.1532V26.9297H175.758H164.453V38.1532V49.7213V60.9449V72.513V82.9242V83.7365H205.037V72.513H175.758V60.9449H200.079V49.7213H175.758V38.1532H205.037Z'
              fill='black'
            />
            <path
              d='M30.0625 51.9399H26.9372V63.1982H45.4659C42.6878 69.3952 36.4124 73.7406 29.1696 73.7406C19.3224 73.7406 11.3107 65.7659 11.3107 55.9643C11.3107 46.1626 19.3224 38.188 29.1696 38.188C34.4281 38.188 39.1408 40.4594 42.415 44.064H55.7596C51.1708 33.9661 41.0011 26.9297 29.1696 26.9297C13.0717 26.9297 0 39.9656 0 55.9643C0 71.9629 13.0966 84.9989 29.1696 84.9989C45.2426 84.9989 58.3392 71.9629 58.3392 55.9643C58.3392 54.6064 58.2152 53.2484 58.0167 51.9399H30.0377H30.0625Z'
              fill='black'
            />
          </svg>
        </h1>
        <div className='dsat-intro-1-sub-flex-container'>
          <p>{sendParams.testName}</p>
          <p>{dayjs(sendParams.testDate).format('MMMM DD, YYYY')}</p>
        </div>
        <p className='user-name'>{userInfo.userName}</p>
        <button onClick={() => handleButtonTestStart()}>Start</button>
      </div>
    </div>
  );
}
