import React, { useEffect, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import request from 'utils/Request.utils';

import SignUpTimezone from 'components/user/sign-up/_components/pages/Timezone';
import LocalStorage from 'utils/LocalStorage.utils';

/** "회원가입 - 소셜 가입 - 아카데미 관리자 - 타입존 설정" 페이지 */
export default function SignUpSocialAcademyAdminTimezonePage() {
  const navigate = useNavigate();
  const joincompany = useSelector((state) => state.joinCompanyReducer);
  console.log('🚀 ~ SignUpSocialAcademyAdminTimezonePage ~ joincompany:', joincompany);
  if (!joincompany.confirmCode) {
    navigate('/404');
  }
  const x = new Date();

  /** 타임존 목록 */
  const timeZones = Intl.supportedValuesOf('timeZone');

  /** 현재 사용자 장치의 타입존 */
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      timezone: userTimeZone,
    },
  });
  console.log(userTimeZone);
  const [timezoneList, setTimezoneList] = useState(null);

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    // console.log(`🚨 data :`, data, `🚨`);
    // set('timezone', data.timezone);
    const selectedTimezone = timezoneList.find((item) => item.timezone_name === data.timezone);
    // console.log('🚀 ~ onValid ~ selectedTimezone:', selectedTimezone);
    submitHandler(selectedTimezone);
  };

  const submitHandler = (selectedTimezone) => {
    console.log('🚀 ~ subitHandler ~ data:', selectedTimezone);
    const params = {
      isNew: true,
      // data.timezone 은 number type으로 casting.
      timezone: selectedTimezone.tz_seq,
      loginType: joincompany.loginType,
      userLevel: joincompany.userLevel,
      userUid: joincompany.userUid,
      confirmCode: joincompany.confirmCode,
    };
    console.log('🚀 ~ submitHandler ~ params:', params);
    const successHandler = (response) => {
      // console.log('🚀 ~ successHandler ~ response:', response);
      // navigate('/signup/complete');
      if (response.code === 200) {
        // 토근을 로컬에 저장
        LocalStorage.setItem('userSession', 'accessToken', response.result.token.accessToken);
        LocalStorage.setItem('userSession', 'refreshToken', response.result.token.refreshToken);
        // 저장후 이동
        setTimeout(() => {
          navigate('/signup/complete');
        }, 500);
      } else {
        // 관리자에게 문의 안내
        alert('Please contact the administrator.');
      }
    };
    request.post(request.apiUris.auth.joinAcademy, params, successHandler);
  };

  useEffect(() => {
    const getTimezoneList = () => {
      const successHandler = (response) => {
        // console.log('🚀 ~ successHandler ~ response:', response);
        // response 에 있는 gmt_offset이 분단위 이니 시간으로 변경 포맷은 +09:00
        response.forEach((element) => {
          element.gmt_offset_ori = element.gmt_offset;
          element.gmt_offset = formatGmtOffset(element.gmt_offset);
        });
        setTimezoneList(response);
      };
      request.get(request.apiUris.common.getTimezone, { isNew: true }, successHandler);
    };

    getTimezoneList();
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  const formatGmtOffset = (gmtOffset) => {
    const hours = Math.floor(gmtOffset / 60);
    const minutes = Math.abs(gmtOffset % 60);
    const sign = hours >= 0 ? '+' : '-';
    return `${sign}${String(Math.abs(hours)).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  return (
    <SignUpTimezone
      timezoneArray={timezoneList}
      headerTitle={'EDUCATOR'}
      handleSubmit={handleSubmit}
      onValid={onValid}
      register={register}
      errors={errors}
      setValue={setValue}
      TimezoneArray={timeZones}></SignUpTimezone>
  );
}
