// !!!! components/classes/_PopClassManage.js, components/test/_PopAddStudents.js, components/user/academy/Profile.js,  components/test/Schedule.js 에 사용됨 !!!!!
import { React, useState } from 'react';
import styled from 'styled-components';

import usePressESC from 'hooks/usePressESC';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import { nvl } from 'utils/Common.utils.js';

import DraggableModal from 'components/_common/modals/DraggableModal';
import { ReactComponent as ImgSvgIconEdit } from 'assets/img/svg/icon_edit_memo_01.svg';
import { ReactComponent as ImgSvgIconX } from 'assets/img/svg/x-close.svg';
import { ReactComponent as ImgSvgIconSchool } from 'assets/img/svg/icon_school_building.svg';
import ProfileMemoArea from './_components/ProfileMemoArea';
import ConfirmModal from './_components/ConfirmModal';

/** 아카데미 대시보드 - Student 페이지 에서 사용 할 "프로필" 모달 */
export default function ProfileModal({ type, setProfileState, studentInfo }) {
  usePressESC(() => setProfileState(false));

  if (!studentInfo) {
    return (
      <DraggableModal>
        <h3>Oops</h3>
        <h4>There's no profile data on the server</h4>
        <p> Something's wrong with this</p>
        <p>Try refreshing or contact your administrator</p>
      </DraggableModal>
    );
  }

  /** 메모 영역 노출 여부 상태 */
  const [isShowMemoArea, setIsShowMemoArea] = useState(false);
  /** 이탈 방지 컴펌창 노출 여부 상태 */
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const classList = studentInfo.profile.classLists || [{ class_name: 'individual', class_seq: 1 }];
  const displayRegDate = isoTimeToDayjs(studentInfo.profile.regDate ? studentInfo.profile.regDate.split[0] : studentInfo.profile.regDate.split[0]).format(
    'YYYY / MM / DD'
  );

  /** 메모 영역 노출 토글 버튼 핸들러 */
  const handleButtonMemoToggle = () => {
    setIsShowMemoArea((current) => !current);
  };

  /** "이탈 방지" 확인 모달의 "취소" 버튼 핸들러 */
  const handleCancelInConfirmModal = () => {
    setIsShowConfirmModal(false);
  };
  /** "이탈 방지" 확인 모달의 "확인" 버튼 핸들러 */
  const handleConfirmInConfirmModal = () => {
    setIsShowConfirmModal(false);
    setProfileState(false);
  };

  /** 현재 모달 컴포넌트 닫기 버튼 핸들러 */
  const handleClose = () => {
    if (isShowMemoArea) {
      // "메모 창이 열려 있다면?"
      setIsShowConfirmModal(true);
    } else {
      setProfileState(false);
    }
  };

  return (
    <S.Wrap className='com_popup active pop_profile student'>
      <DraggableModal>
        <div className='pop_container'>
          <div className={`right com_profile ${type} `}>
            {/*화면 구성 상(학생, 선생 항목 노출용) ${props.type} 적용 →  개발 시 수정 가능*/}
            {/* {studentInfo.profile && <img src={studentInfo.profile} alt="프로필이미지" className="bg" />} */}
            {nvl(studentInfo.profile.imageUrl) !== '' && <img src={studentInfo.profile.imageUrl} alt='프로필이미지' className='bg profile-img-background' />}
            <article className='top_area'>
              <h3 className='tit'>Profile</h3>
              <div className='btn_wrap'>
                <button type='button' title='Toggle Memo' className='btn_toggle_memo' onClick={handleButtonMemoToggle}>
                  <ImgSvgIconEdit />
                </button>
                <button type='button' title='Close Modal' className='btn_pop_close' onClick={handleClose}>
                  <ImgSvgIconX />
                </button>
              </div>
            </article>
            <div className='wrap'>
              <article className='profile_area'>
                <div className={`com_profile_img xl ${type === 'student' ? '' : 'T'}`}>
                  {studentInfo.profile.imageUrl ? (
                    <img className='profile-img-background' src={studentInfo.profile.imageUrl} alt='프로필이미지' />
                  ) : (
                    <span className='name'>{studentInfo.userName.substring(0, 1)}</span>
                  )}
                </div>
                {/*선생 일 경우 addClass T*/}
                <p className='name'> {studentInfo.userName} </p>
              </article>
              <div className='scroll_area scroll'>
                {/*학생 프로필일 경우에만 노출 start*/}
                {classList &&
                  classList.length > 0 &&
                  classList.map((item, index) => {
                    return (
                      <article className='class_area' key={`class_${index}`}>
                        <p className='tit'>CLASS{index + 1}</p>
                        <p className='name'>{item.className}</p>
                      </article>
                    );
                  })}

                <article className='school_area'>
                  <div className='item'>
                    <p className='tit_field'>School Type</p>
                    <p className='info_field'>{studentInfo.profile.schoolTypeName}</p>
                  </div>
                  <div className='item'>
                    <p className='tit_field'>Grade</p>
                    <p className='info_field'>{studentInfo.profile.studentGrade}</p>
                  </div>
                  <div className='item'>
                    <p className='tit_field'>School Location</p>
                    <p className='info_field'>{studentInfo.profile.schoolLocationName}</p>
                  </div>
                </article>
                {/*학생 프로필일 경우에만 노출 end*/}
              </div>
              <article className='info_area'>
                <div className='item'>
                  <i className='svg_icon icon_email blue'>&nbsp;</i>
                  <p className='tit_field'>Email</p>
                  <p className='info_field'>{studentInfo.profile.userEmail}</p>
                </div>
                {/*학생 프로필일 경우에만 노출 start*/}
                <div className='item student'>
                  <ImgSvgIconSchool className='icon_school_building' />
                  <p className='tit_field'>School</p>
                  <p className='info_field'>{studentInfo.profile.studentSchool}</p>
                </div>
                <div className='item student'>
                  <i className='svg_icon icon_tel blue'>&nbsp;</i>
                  <p className='tit_field'>Students</p>
                  <p className='info_field'>{studentInfo.profile.userMobile}</p>
                </div>
                {/*학생 프로필일 경우에만 노출 end*/}
                <div className='item'>
                  <i className='svg_icon icon_class blue'>&nbsp;</i>
                  <p className='tit_field'>Registration Date</p>
                  <p className='info_field'>{displayRegDate}</p>
                </div>
              </article>
            </div>
          </div>
          {isShowMemoArea && <ProfileMemoArea studentInfo={studentInfo} setIsShowMemoArea={setIsShowMemoArea} />}
        </div>
      </DraggableModal>
      {isShowConfirmModal && (
        <ConfirmModal handleCancel={handleCancelInConfirmModal} handleConfirm={handleConfirmInConfirmModal}>
          Your memo will not be saved. Are you sure?
        </ConfirmModal>
      )}
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  &.pop_profile {
    &.active {
      background-color: rgba(0, 0, 0, 0.24);
    }
  }
  .pop_container {
    max-height: 728px;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: flex;
    gap: 0.125rem;
    background-color: inherit;
    box-shadow: none;
    overflow: visible;
    & > * {
      box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.15);
      border-radius: 0.625rem;
      overflow: hidden;
    }
  }

  .com_profile {
  }

  .top_area {
    position: relative;
    padding: 1.25rem 1rem 0;
    .tit {
      padding-top: 0;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 1rem;
    }
    .btn_wrap {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      padding: 1.25rem 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .btn_toggle_memo {
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: #ffffff;
          width: 1.35rem;
        }
      }
      .btn_pop_close {
        width: 1.5rem;
        height: 1.5rem;
        position: static;
        svg {
          color: #ffffff;
          width: 1.5rem;
        }
      }
    }
  }

  .profile_area {
    & > .name {
      padding-block: 5.15rem 1.94rem;
    }
  }

  .scroll_area {
    background-color: #fafafa;
    border-radius: 0.625rem;
    padding: 0.5rem 0rem;
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .class_area {
    gap: 0.25rem;
    padding-block: 0;
    & + .class_area {
      margin-top: 0.75rem;
    }
    .tit {
      font-weight: 400;
      font-size: 0.8125rem;
      color: #7f8998;
      text-transform: none;
    }
    .name {
      font-weight: 400;
      font-size: 0.8125rem;
    }
  }

  .school_area {
    .item {
      .tit_field {
        font-weight: 400;
        font-size: 0.8125rem;
        color: #7f8998;
        text-transform: none;
      }
      .info_field {
        font-weight: 400;
        font-size: 0.8125rem;
      }
    }
  }

  .info_area {
    .icon_school_building {
      color: #0068bd;
      width: 1.25rem;
    }
    .item {
      padding: 0.88rem;
      .tit_field {
        font-weight: 400;
        font-size: 0.8125rem;
        color: #7f8998;
        margin-inline: 0.5rem auto;
      }
      .info_field {
        font-weight: 400;
        font-size: 0.8125rem;
        color: #4b5563;
      }
    }
  }
`;
