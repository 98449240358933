import React, { useState, useCallback, useEffect, useRef, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

////////// components
import Paging from 'layout/Paging'; //페이징
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod'; //팝업 달력
import ClassList from 'components/com/tests/_components/_ClassList'; // 반별 학생 리스트
import MiniAlert from 'components/_common/alerts/MiniAlert'; //텍스트 알럿
import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import CustomAlert from 'components/_common/alerts/CustomAlert';
import ButtonBase from 'components/_common/button/_ButtonBase';
////////// hook
import useOutsideClick from 'hooks/useOutsideClick';

////////// Image
import IconEmpty from 'assets/img/icon_empty.png';
import DraggableProfilePopup from 'components/_common/modals/DraggableProfilePopup';
import MainLink from 'components/_common/button/_MainLinks';

export default function TestsTakenPage() {
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 /////////////////
  /** 커스텀 알럿 */
  let alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };

  const tab = [
    { text: 'By Individual Test', value: 0 },
    { text: 'By Test Set', value: 1 },
  ];
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 /////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const navigate = useNavigate();
  const userInfo = request.tokenDecoder();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  const [scheduleInfo, setScheduleInfo] = useState({
    uthList: [],
    studentInfoList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });

  const [searchInfo, setSearchInfo] = useState({
    searchFieldActive: false,
    searchStartDate: '',
    searchEndDate: '',
    setSearchField: 'Name',
    searchKeyword: '',
  });

  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  const [activeTest, setActiveTest] = useState({
    customSetSeq: 0,
    classMemberList: [],
    classMemberListAll: [],
    totalCnt: 0,
    testName: '',
  });

  const [tabState, setTabState] = useState(0);

  const [miniAlert, setMiniAlert] = useState({
    active: false,
    text: '',
    type: '',
    timeOut: 0,
  });

  /** 프로필 팝업 */
  const [profileState, setProfileState] = useState(false);

  // 인피니티 스크롤
  const [isLoading, setIsLoading] = useState();
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /** 검색 */
  const searchFiledRef = useRef();
  const searchKeywordRef = useRef();
  const obsRef = useRef(null);
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const nextPageMove = (value) => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  const searchFiledClick = (value) => {
    setSearchInfo((prev) => {
      return { ...prev, searchFieldActive: false, setSearchField: value };
    });
  };

  const keywordSearchDo = (e) => {
    // console.log(nvl(searchKeywordRef.current.value));

    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: nvl(searchKeywordRef.current.value) };
    });
  };

  /** 반 선택 */
  const removeDuplicatesOne = (arr, prop) => {
    // 중복 제거
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop] === item[prop]);
    });
  };

  const removeDuplicatesTwo = (arr, prop1, prop2) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop1] === item[prop1] && t[prop2] === item[prop2]);
    });
  };

  const scheduleTrClick = (customSetSeq, testName) => {
    // 중복 제거2
    setActiveTest((prev) => {
      return { ...prev, customSetSeq: customSetSeq, testName: testName };
    });
  };

  const getTestClassMember = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let takenList = response.result.takenListc;

        let deduplicationClass = removeDuplicatesOne(takenList, 'classSeq');
        let sameClassList = [];
        for (let i = 0; i < deduplicationClass.length; i++) {
          const classSeq = deduplicationClass[i].classSeq;
          const filteredSchedule = takenList.filter((i) => i.classSeq === classSeq);
          sameClassList.push(filteredSchedule);
        }

        let courseList = [];
        let deduplicationTestDate = [];
        for (let i = 0; i < sameClassList.length; i++) {
          deduplicationTestDate = removeDuplicatesTwo(sameClassList[i], 'testStartDate', 'testEndDate');

          for (let j = 0; j < deduplicationTestDate.length; j++) {
            const startDate = deduplicationTestDate[j].testStartDate;
            const endDate = deduplicationTestDate[j].testEndDate;

            const filteredTestSchedule = sameClassList[i].filter((i) => i.testStartDate === startDate && i.testEndDate === endDate);
            courseList.push(filteredTestSchedule);
          }
        }
        const sortList = courseList.sort((a, b) => dayjs(a[0].testStartDate) - dayjs(b[0].testStartDate));
        setActiveTest((prev) => {
          return { ...prev, classMemberList: sortList };
        });
      }
    };

    request.get(`/api/test/schedules/post/exams/${activeTest.customSetSeq}?ecSeq=${userInfo.ecSeq}`, null, successHandler);
  };

  const getSchedules = () => {
    let pms = {
      ecSeq: userInfo?.ecSeq,
      searchField: searchInfo.setSearchField === 'Name' ? 'userName' : 'testName',
      searchKeyword: searchInfo.searchKeyword,
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      limit: 10,
      offset: (scheduleInfo.currentPage - 1) * scheduleInfo.numPerPage,
      modiUserSeq: userInfo?.userSeq,
    };

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let scheduleList = response.result.scheduleList;
        let totalCnt = response.result.totalCnt;

        setScheduleInfo((prev) => {
          return { ...prev, uthList: scheduleList, totalCount: totalCnt };
        });

        if (totalCnt > 0 && !activeTest.customSetSeq)
          setActiveTest((prev) => {
            return {
              ...prev,
              customSetSeq: scheduleList[0].customSetSeq,
              testName: scheduleList[0].testName,
            };
          });
      }
    };

    if (userInfo?.ecSeq > 0)
      request.get(
        `/api/test/schedules/post?ecSeq=${pms.ecSeq}&limit=${pms.limit}&offset=${pms.offset}&searchField=${pms.searchField}&searchKeyword=${encodeURIComponent(pms.searchKeyword)}&searchStartDate=${pms.searchStartDate}&searchEndDate=${pms.searchEndDate}`,
        null,
        successHandler
      );
  };

  const getProfileView = (e, userSeq) => {
    e.stopPropagation();

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let studentInfo = response.result.studentInfo;

        let imageUrl = null;

        if (studentInfo.upFileSeq > 0) {
          imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}?${Date.now()}`;
        }

        studentInfo.profile = imageUrl;

        setScheduleInfo((prev) => {
          return { ...prev, studentInfoList: studentInfo };
        });
        setProfileState(true);
      }
    };

    // TODO: 학생이 해당 학원에 속하지 않았을떄 에러 발생 - 기획 필요함
    // request.get(`/api/test/profiles?userSeq=${userSeq}&ecSeq=${userInfo.ecSeq}`, null, successHandler)
    request.get(`/api/test/profiles?userSeq=${userSeq}`, null, successHandler); // 에러 발생 막아둠
  };

  const schedulesListReload = () => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getSchedules();
  };

  const schedulesClassListReload = () => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getAllTestClassMember();
  };

  const renderTab = useCallback(() => {
    return tab.map((v, idx) => {
      return (
        <button key={idx} className={`menu ${(tabState === v.value && 'active') || ''}`} onClick={() => setTabState(v.value)}>
          {v.text}
        </button>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const getAllTestClassMember = () => {
    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let takenListA = response.result.takenListA;
        let totalCnt = response.result.totalCnt;

        const parsedData = takenListA.map((entry) => {
          const classList = entry.classListJson;
          return classList.map((classItem) => {
            const studentList = classItem.studentList;
            return studentList.map((student) => ({
              ...student,
              seqStr: entry.seqStr,
            }));
          });
        });

        if (scheduleInfo.currentPage === 1) {
          setActiveTest((prev) => {
            return {
              ...prev,
              totalCnt: totalCnt,
              classMemberListAll: parsedData,
            };
          });
        } else {
          setActiveTest((prev) => {
            return {
              ...prev,
              totalCnt: totalCnt,
              classMemberListAll: prev.classMemberListAll.concat(parsedData),
            };
          });
        }
        setIsLoading(false);
      }
    };

    if (userInfo?.ecSeq > 0)
      request.get(`/api/test/schedules/post/exams?ecSeq=${userInfo.ecSeq}&currentPage=${scheduleInfo.currentPage}`, null, successHandler);
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    setAlertLayerPopup((prev) => {
      return {
        ...prev,
        returnValue: (value) => {
          if (nvl(value) === 'OK') {
            console.log('요기에서 작업');

            // closeCustomAlert();
          }
        },
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertLayerPopup?.id]);

  useEffect(() => {
    if (activeTest.customSetSeq > 0) getTestClassMember();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTest.customSetSeq]);

  useEffect(() => {
    if (tabState) {
      getAllTestClassMember();
    } else {
      getSchedules();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleInfo.currentPage]);

  useEffect(() => {
    schedulesListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleInfo.numPerPage, searchInfo.searchKeyword, searchInfo.searchStartDate, searchInfo.searchEndDate]);

  useEffect(() => {
    // 반 별 전체 예정된 시험 조회
    if (tabState && scheduleInfo.currentPage === 1) {
      schedulesClassListReload();
      setIsLoading(false);
    } else {
      schedulesListReload();
      setActiveTest((prev) => {
        return { ...prev, classMemberListAll: [] };
      });
      setIsLoading(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState]);

  useEffect(() => {
    let options = {
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !isLoading) {
        if (activeTest.totalCnt > activeTest.classMemberListAll.length) {
          setIsLoading(true);
          setScheduleInfo((prev) => {
            return { ...prev, currentPage: prev.currentPage + 1 };
          });
        }
      }
    }, options);

    if (obsRef.current) {
      observer.observe(obsRef.current);
    }

    // 옵저버 해제
    return () => {
      if (obsRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(obsRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTest.classMemberListAll]);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  useOutsideClick(
    searchFiledRef,
    () =>
      setSearchInfo((prev) => {
        return { ...prev, searchFieldActive: false };
      }),
    searchInfo.searchFieldActive
  );

  return (
    <>
      <S.Wrap className='com_schedule'>
        <MainLink type='test' />
        <div className='com_center_wrap'>
          <div className='com_tab type_with_box'>{renderTab()}</div>
          <div className='container'>
            {tabState === 0 ? (
              <div className='sec_individual'>
                <div className='com_list'>
                  <section className='top'>
                    <article className='btn_area'>
                      {/* <button className="com_btn hover_btn lightgray s icon" data-hover="Delete" onClick={ () => deletePopupOpen() }><i className="svg_icon icon_trash">&nbsp;</i><span className="name">Delete</span></button> */}
                    </article>
                    <article className='search_area'>
                      <PopupCalendarPeriod setSearchInfo={setSearchInfo} />
                      <div
                        className={`com_select_layer ${searchInfo.searchFieldActive ? 'active' : ''}`}
                        onClick={() =>
                          setSearchInfo((prev) => {
                            return {
                              ...prev,
                              searchFieldActive: !searchInfo.searchFieldActive,
                            };
                          })
                        }
                        ref={searchFiledRef}>
                        {/*클래스 active 포함 시 옵션 노출*/}
                        <input type='text' className='selected search_area_input' placeholder={searchInfo.setSearchField} />
                        <div className='layer'>
                          <p className='option' onClick={() => searchFiledClick('Name')}>
                            Name
                          </p>
                          <p className='option' onClick={() => searchFiledClick('Test')}>
                            Test
                          </p>
                        </div>
                      </div>
                      <div className='search'>
                        <input type='text' placeholder='Enter keywords' className='input search_area_input' ref={searchKeywordRef} />
                        <button className='com_btn m blue btn_search' onClick={keywordSearchDo}>
                          <i className='svg_icon icon_search blue before'></i>
                          SEARCH
                        </button>
                      </div>
                    </article>
                  </section>
                  <table className='list'>
                    {/* <colgroup><col width="56px"/><col width=""/><col width="90px"/><col width="110"/><col width="110"/><col width="117"/><col width="66"/></colgroup> */}
                    <colgroup>
                      <col width='' />
                      <col width='90px' />
                      <col width='110' />
                      <col width='110' />
                      <col width='117' />
                      <col width='66' />
                    </colgroup>
                    <thead>
                      <tr>
                        {/* <th>&nbsp;</th> */}
                        <th>Test</th>
                        <th>Time</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Name</th>
                        <th>Profile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scheduleInfo.uthList.length > 0 ? (
                        scheduleInfo.uthList.map((item, idx) => {
                          return (
                            <tr
                              className={activeTest.customSetSeq === item.customSetSeq ? 'active' : ''}
                              key={`scheduleTr_${idx}`}
                              onClick={() => scheduleTrClick(item.customSetSeq, item.testName)}>
                              <td className='tit_field alignL'>{item.testName}</td>
                              <td>{isoTimeToDayjs(item.testStartDate).format('A h : mm')}</td>
                              <td>{isoTimeToDayjs(item.testStartDate).format('MM / DD / YYYY')}</td>
                              <td>{isoTimeToDayjs(item.testEndDate).format('MM / DD / YYYY')}</td>
                              <td className='alignL'>{item.userName}</td>
                              <td>
                                <ButtonBase text='View' type='tableBtn' onClick={(e) => getProfileView(e, item.userSeq)} />
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan='6'>
                            <div className='com_nodata'>
                              <p className='tit'>No test available.</p>
                              <p className='txt'>Go to Set Test and set more test!</p>
                              <div className='img'>
                                <img src={IconEmpty} alt='' />
                              </div>
                              <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
                                GO TO SET TEST
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Paging
                    totalCount={scheduleInfo.totalCount}
                    currentPage={scheduleInfo.currentPage}
                    numPerPage={scheduleInfo.numPerPage}
                    pagePerBlock={scheduleInfo.pagePerBlock}
                    nextPageMove={nextPageMove}
                  />
                </div>
                <ClassList
                  type='post'
                  testName={activeTest.testName}
                  classList={activeTest.classMemberList}
                  getSchedules={getSchedules}
                  getTestClassMember={getTestClassMember}
                  setAlertLayerPopup={setAlertLayerPopup}
                />
              </div>
            ) : tabState === 1 ? (
              <>
                <div className='sec_test'>
                  {nvl(activeTest.classMemberListAll) !== '' && activeTest.classMemberListAll.length > 0 ? (
                    activeTest.classMemberListAll.map((item, idx) => {
                      return (
                        <Fragment key={`class_${idx + 1}`}>
                          <ClassList
                            type='post'
                            testName={item[0][0].testName}
                            classList={item}
                            getSchedules={getSchedules}
                            getTestClassMember={schedulesClassListReload}
                            setAlertLayerPopup={setAlertLayerPopup}
                          />
                        </Fragment>
                      );
                    })
                  ) : (
                    // 데이터 없을 경우 노출
                    <div className='com_nodata'>
                      <p className='tit'>No test available.</p>
                      <p className='txt'>Go to Set Test and set more test!</p>
                      <div className='img'>
                        <img src={IconEmpty} alt='' />
                      </div>
                      <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
                        GO TO SET TEST
                      </button>
                    </div>
                  )}
                </div>
                {!isLoading && <div ref={obsRef}></div>}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </S.Wrap>
      {miniAlert.active && (
        <MiniAlert
          text={miniAlert.text}
          type={miniAlert.type}
          active={miniAlert.active}
          inactive={() =>
            setMiniAlert((prev) => {
              return { ...prev, active: false };
            })
          }
          timeOut={miniAlert.timeOut}
        />
      )}
      {/*//To Do : 테스트 셋팅 완료 후 Test schedule 페이지로 이동 시 뜨도록*/}
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}

      {/* 학생 상세 프로필 팝업 */}
      {profileState && <DraggableProfilePopup setProfileState={setProfileState} studentInfo={scheduleInfo.studentInfoList} />}
    </>
  );
}

const S = {
  Wrap: styled.div`
    .com_container {
      background: #f4f8ff;
      padding: 15px;
      border-radius: 10px;
    }
    .full {
      width: 100%;
    }
  `,
};
