import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import request from 'utils/Request.utils';

//components
import Gnb from 'layout/Gnb';
import Footer from 'layout/Footer'; //Footer
import Login from 'components/user/sign-in/Page'; //로그인

import TestgateNewsViewPage from 'components/board/testgate-news/view/Page';
import TestgateNewsListPage from 'components/board/testgate-news/list/Page';

const TestgateNewsRoute = () => {
  let { pathname } = window.location;
  let authBool = false;

  authBool = request.tokenVerify(pathname); // 토큰 체크 (refresh 도 됨)

  const returnRoutes = (getRoutes) => {
    return getRoutes.map((element) => <Route key={element.path} path={element.path} element={element.element} />);
  };

  const AuthorizationRoutes = () => {
    // 로그인 한 사람만 사용
    if (authBool) {
      const authRoutes = [
        { path: '/', element: <Navigate to='/gateplus/news/list' /> },
        // { path: "/feedback" , element: <TestgateNewsList/> }, // component 수정해야 함
        { path: '/news/list', element: <TestgateNewsListPage /> },
        { path: '/news/view', element: <TestgateNewsViewPage /> },

        { path: '*', element: <Navigate to='/gateplus/news/list' /> },
        // { path: "/" , element: <DSATInfoView/> }, // component 수정해야 함
      ];

      return returnRoutes(authRoutes);
    } else {
      const unAuthRoutes = [
        { path: '/signin', element: <Login /> },
        { path: '/', element: <Navigate to='/signin' /> },
        { path: '/*', element: <Navigate to='/signin' /> },
      ];

      return returnRoutes(unAuthRoutes);
    }
  };

  console.log(pathname, authBool);

  return (
    <>
      {/*로그인, 회원가입 시 비노출 start*/}
      {pathname.toLowerCase().indexOf('/login') < 0 &&
      pathname.toLowerCase().indexOf('/jointype') < 0 &&
      pathname.toLowerCase().indexOf('/agree') < 0 &&
      pathname.toLowerCase().indexOf('/join') < 0 ? (
        <Gnb />
      ) : null}
      {/*로그인, 회원가입 시 비노출 end*/}
      <main>
        <Routes> {AuthorizationRoutes()} </Routes>
      </main>
      <Footer />
    </>
  );
};

export default TestgateNewsRoute;
