/** AP 시험 과목 분류 */
export const AP_SUBJECT_CATEGORY = {
  mathematics: {
    // 수학
    NAME: 'Mathematics',
    COLOR: '#FDD6D6',
    detailSubjects: {
      precalculus: {
        // 프리칼큘러스
        SUBJECT_NAME: 'AP Precalculus',
        seq: '11',
      },
      calculusAB: {
        // 미적분학 AB
        SUBJECT_NAME: 'AP Calculus AB ',
        seq: '2',
      },
      calculusBC: {
        // 미적분학 BC
        SUBJECT_NAME: 'AP Calculus BC',
        seq: '3',
      },
      statistics: {
        // 통계학
        SUBJECT_NAME: 'AP Statistics',
        seq: '12',
      },
    },
  },
  computerScience: {
    // 컴퓨터 과학
    NAME: 'Computer Science',
    COLOR: '#D2DAFD',
    detailSubjects: {
      computerScienceA: {
        // 컴퓨터 과학 A
        SUBJECT_NAME: 'AP Computer Science A',
        seq: '15',
      },
      computerSciencePrinciples: {
        // 컴퓨터 과학 원리
        SUBJECT_NAME: 'AP Computer Science Principles',
        seq: '16',
      },
    },
  },
  science: {
    // 과학
    NAME: 'Science',
    COLOR: '#DFF3FF',
    detailSubjects: {
      biology: {
        // 생물
        SUBJECT_NAME: 'AP Biology',
        seq: '1',
      },
      chemistry: {
        // 화학
        SUBJECT_NAME: 'AP Chemistry',
        seq: '4',
      },
      physics1: {
        // 물리학 1
        SUBJECT_NAME: 'AP Physics 1: Algebra-Based',
        seq: '7',
      },
      physics2: {
        // 물리학 2
        SUBJECT_NAME: 'AP Physics 2: Algebra-Based',
        seq: '8',
      },
      physicsCElectricityAndMagnetism: {
        // 물리학C: 전자기학
        SUBJECT_NAME: 'AP Physics C: Electricity and Magnetism',
        seq: '9',
      },
      physicsCMechanics: {
        // 물리학C: 역학
        SUBJECT_NAME: 'AP Physics C: Mechanics',
        seq: '10',
      },
      environmentalScience: {
        // 환경과학
        SUBJECT_NAME: 'AP Environmental Science',
        seq: '23',
      },
    },
  },
  languageAndCulture: {
    // 언어와 문화
    NAME: 'Language and Culture',
    COLOR: '#FBF0DA',
    detailSubjects: {},
  },
  history: {
    // 역사
    NAME: 'History',
    COLOR: '#E3D4C7',
    detailSubjects: {
      unitedStatesHistory: {
        // 미국사
        SUBJECT_NAME: 'AP United States History',
        seq: '13',
      },
      worldHistoryModern: {
        // 세계사
        SUBJECT_NAME: 'AP World History: Modern',
        seq: '14',
      },
    },
  },
  government: {
    // 행정
    NAME: 'Government',
    COLOR: '#FFE1C8',
    detailSubjects: {},
  },
  socialSciences: {
    // 사회 과학
    NAME: 'Social Sciences',
    COLOR: '#E8F7F0',
    detailSubjects: {
      humanGeography: {
        // 인간지리학
        SUBJECT_NAME: 'AP Human Geography',
        seq: '19',
      },
      unitedStatesGNP: {
        // 미국정부와 정치
        SUBJECT_NAME: 'AP United States Government and Politics',
        seq: '20',
      },
      comparativeGNP: {
        // 비교정부와 정치
        SUBJECT_NAME: 'AP Comparative Government and Politics',
        seq: '21',
      },
      psychology: {
        // 심리학
        SUBJECT_NAME: 'AP Psychology',
        seq: '22',
      },
    },
  },
  economics: {
    // 경제
    NAME: 'Economics',
    COLOR: '#EED6FF',
    detailSubjects: {
      macroeconomics: {
        // 거시경제학
        SUBJECT_NAME: 'AP Macroeconomics',
        seq: '5',
      },
      microeconomics: {
        // 미시경제학
        SUBJECT_NAME: 'AP Microeconomics',
        seq: '6',
      },
    },
  },
  art: {
    // 예술
    NAME: 'Art',
    COLOR: '#FFDAF0',
    detailSubjects: {},
  },
  english: {
    // 영어
    NAME: 'English',
    COLOR: '#FFD3D3',
    detailSubjects: {
      englishLanguageAndComposition: {
        // 영어 언어와 작문
        SUBJECT_NAME: 'AP English Language and Composition',
        seq: '17',
      },
      englishLiteratureAndComposition: {
        // 영어 문학과 작문
        SUBJECT_NAME: 'AP English Literature and Composition',
        seq: '18',
      },
    },
  },
  others: {
    // 기타
    NAME: 'Others',
    COLOR: '#EEEEEE',
    detailSubjects: {},
  },
};
