import styled from 'styled-components';

/** AP Test Editor - question setting popup 에서 고정된 값을 보여주는 박스 */
export default function ApSettingFixedBox({ value, className = '' }) {
  return (
    <S.InputWrapper className={className}>
      <S.StyledInput>{value}</S.StyledInput>
    </S.InputWrapper>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  InputWrapper: styled.div`
    width: 10.875rem;
    user-select: none;
    cursor: default;
  `,

  StyledInput: styled.div`
    height: 2.125rem;
    border: 1px solid #d7dadf;
    border-radius: 0.25rem;
    background-color: #fafafa;
    padding: 0.4375rem 0;
    padding-left: 0.625rem;
    font-size: 0.875rem;
    color: black;
  `,
};
