import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import request from 'utils/Request.utils.js';
import { nvl } from 'utils/Common.utils.js';

// hook
import useUserLevels from 'hooks/useUserLevels.js';
import usePressESC from 'hooks/usePressESC.js';

//component
import TabMenu from './_components/common/TabMenu';

// Alert component
import MiniAlert from 'components/_common/alerts/MiniAlert';
import CustomAlert from 'components/_common/alerts/CustomAlert';

import EditModal from './_components/modals/EditModal';
import LinkYourChildModal from './_components/modals/LinkYourChildModal';
import LinkAnAcademyOrTutorModal from './_components/modals/LinkAnAcademyOrTutorModal';
import RequestLinkModal from './_components/modals/RequestLinkModal';
import UserDetails from './_components/UserDetails';
import MultiProfile from './_components/_MultiProfile';
import ID from './_components/common/_Id';
import PersonalInformation from './_components/tabContents/PersonalInformation';
import ChildInformation from './_components/tabContents/ChildInformation';
import AcademyTutorInformation from './_components/tabContents/AcademyTutorInformation';
import TeacherListInformation from './_components/tabContents/TeacherList';
import AdditionalSettings from './_components/tabContents/additionalSettings/AdditionalSettings';
import PopDelAccount from './_components/modals/_PopDelAccount.jsx';
import styled from 'styled-components';

/**
 * Profile 페이지 컴포넌트
 * @description
 * 🔍 검색 키워드 - #/profile
 */
export default function ProfilePage() {
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 ///////////////////
  const alertAttributeValue = {
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    returnValue: () => {},
  };
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 ///////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////////
  const location = useLocation();
  const stateMenuManager = useSelector((state) => state.MenuManager);
  const userInfo = request.tokenDecoder();
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo.userLevel);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////////

  /////////////////// React useState 선언 영역 시작 //////////////////////
  /** 정보 수정시 데이터 호출을 위한 State */
  const [reloadData, setReloadData] = useState(false);
  /** Alert 코드 */
  const [alert, setAlert] = useState({ active: false, message: '' });
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  const [dbUserInfo, setDbUserInfo] = useState({
    userName: '',
    regDate: '',
    email: '',
    mobile: '',
    userLevel: '',
    studentSchool: '',
    schoolLocation: '',
    schoolTypeName: '',
    grade: '',
    loginType: '',
  });

  /** 멀티 프로필 코드 */
  /** 멀티프로필 모달 */
  const [openSwitchModal, setOpenSwitchModal] = useState(false);
  /** 멀티프로필 정보 */
  const [switchAccountInfo, setSwitchAccountInfo] = useState([{ mcSeq: '0', ecSeq: '0', companyName: '' }]);
  // Link an Academy or Tutor 팝업 코드
  /**Link an Academy or Tutor 팝업 */
  const [linkPop, setLinkPop] = useState(false);
  /** Request link 팝업 */
  const [requestLink, setRequestLink] = useState(false);
  const [requestCompanyName, setRequestCompanyName] = useState(null);
  const [tabState, setTabState] = useState('Personal information');
  const [modifiedData, setModifiedData] = useState();
  /** personal information 수정 모달 */
  const [openEditModal, setOpenEditModal] = useState(false);
  /** 비밀번호 수정 여부 */
  const [noPassword, setNoPassword] = useState(false);
  /** Account 삭제 팝업 */
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);
  const [requestEcSeq, setRequestEcSeq] = useState(0);
  const [confirmCodeError, setConfirmCodeError] = useState(false);
  /** 타임존 리스트 */
  const [timezoneList, setTimezoneList] = useState([]);
  /////////////////// React useState 선언 영역 끝 //////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const authenCodeRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  const handleClickOpenSwitchModal = () => {
    setOpenSwitchModal(true);
  };

  /** 권한별 탭 데이터 설정 */
  const renderTabData = () => {
    // 학생
    if (useLevels.isStudent) return [{ text: 'Academy / Tutor information' }];
    // 선생님
    if (useLevels.isTeacher) return [{ text: 'Academy / Tutor information' }];
    // 강사
    if (useLevels.isTutor) return [{ text: 'Academy / Tutor information' }, { text: 'Additional Settings' }];
    // 학원 관리자
    if (useLevels.isAcademy) return [{ text: 'Academy / Tutor information' }, { text: 'Teacher List' }, { text: 'Additional Settings' }];
    // 학부모
    if (useLevels.isParent) return [{ text: 'Child information' }];

    return [];
  };

  const renderingData = {
    tab: [{ text: 'Personal information' }, ...renderTabData()],
  };

  /** 텝 메뉴에 따른 화면 */
  const TAB_COMPONENT_MAP = {
    'Personal information': (
      <PersonalInformation
        ID={ID}
        UserDetails={UserDetails}
        dbUserInfo={dbUserInfo}
        setOpenEditModal={setOpenEditModal}
        setOpenDeleteAccountModal={setOpenDeleteAccountModal}
        setModifiedData={setModifiedData}
        setNoPassword={setNoPassword}
        reloadData={reloadData}
      />
    ),
    'Child information': <ChildInformation setLinkPop={setLinkPop} reloadData={reloadData} />,
    'Academy / Tutor information': (
      <AcademyTutorInformation
        ID={ID}
        userLevel={dbUserInfo.userLevel}
        setLinkPop={setLinkPop}
        switchAccountInfo={switchAccountInfo[0]}
        setOpenEditModal={setOpenEditModal}
        ecSeq={switchAccountInfo[0]?.ecSeq}
        setModifiedData={setModifiedData}
        setNoPassword={setNoPassword}
        reloadData={reloadData}
        setReloadData={setReloadData}
      />
    ),
    'Teacher List': <TeacherListInformation />,
    'Additional Settings': timezoneList.length > 0 && <AdditionalSettings setAlert={setAlert} timezoneList={timezoneList} />,
  };

  /** 선택된 Tab 데이터에 따라 랜더링할 컴포넌트를 설정 */
  const InformationSection = TAB_COMPONENT_MAP[tabState];

  const accountInfo = {
    userLevel: userInfo.userLevel, // 권한
    upFileSeq: userInfo.upFileSeq, // 프로필 이미지
    academyUpFileSeq: userInfo.academyUpFileSeq, // 속해있는 학원의 프로필 이미지
    // 아카데미 || 튜터일때 아카데미 이름을 보여주고, 외에는 유저 이름을 랜더링
    ...(useLevels.isAcademy || useLevels.isTutor
      ? {
          companyName: nvl(stateMenuManager?.academyName) !== '' ? stateMenuManager?.academyName : userInfo?.companyName,
          ecSeq: userInfo.ecSeq,
        }
      : {
          userName: nvl(stateMenuManager?.userName) !== '' ? stateMenuManager?.userName : userInfo?.userName,
          companyName: '',
          userSeq: userInfo.userSeq,
        }),
  };

  // 타임존 리스트 가져오기
  const getTimezoneList = () => {
    // 타임존 세팅
    const successHandler = (response) => {
      if (response.code !== 200) return;

      const timezone = response.result.timezone;
      setTimezoneList(timezone || []);
    };

    request.get('/api/etc/timezone', null, successHandler);
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ///////////////////////
  useEffect(() => {
    // 초기 데이터 불러오기 & 랜더링 데이터 선정
    const getMultiProfile = () => {
      const successHandler = (response) => {
        if (response.code !== 200) return;

        setSwitchAccountInfo(response.result.multiProfile);
      };

      request.get(`/api/member/profile/user/multi/${userInfo.userSeq}`, null, successHandler);
    };

    const getPersonalProfile = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          if (nvl(response.result.memberList) === '') return;

          const memberData = response.result.memberList[0];

          setDbUserInfo({
            userSeq: nvl(memberData.userSeq),
            ecSeq: nvl(memberData.ecSeq),
            mcSeq: nvl(memberData.mcSeq),
            userName: nvl(memberData.userName),
            companyName: nvl(memberData.companyName),
            regDate: memberData.regDate ? memberData.regDate?.split(' ')[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$2 / $3 / $1') : '',
            email: nvl(memberData.userEmail),
            mobile: nvl(memberData.userMobile),
            userLevel: nvl(memberData.userLevel),
            studentSchool: nvl(memberData.studentSchool),
            schoolLocationName: nvl(memberData.schoolLocationName),
            schoolTypeName: nvl(memberData.schoolTypeName),
            grade: nvl(memberData.studentGrade),
            upFileSeq: nvl(userInfo.upFileSeq),
            academyUpFileSeq: nvl(userInfo.academyUpFileSeq),
            loginType: userInfo.login_type,
          });

          getMultiProfile();
        }
      };

      request.get(`/api/member/list?userSeq=${userInfo.userSeq}`, null, successHandler);
    };

    getPersonalProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  useEffect(() => {
    if (location.state?.tab && location.state.tab === 'academy') {
      setTabState('Academy / Tutor information');
    }
  }, [location]);

  // 타임존 세팅 후 새로고침 뒤 'Additional Settings' 탭으로 이동하는 useEffect
  useEffect(() => {
    /** 타임존 세팅이 일어났었는지 확인하는 쿠키명 */
    const IS_SET_TIMEZONE = 'isSetTimezoneCookie';
    const isSetTimezone = Cookies.get(IS_SET_TIMEZONE);

    if (isSetTimezone) {
      setTabState('Additional Settings');
      Cookies.remove(IS_SET_TIMEZONE);
    }
    getTimezoneList();
  }, []);
  /////////////////// React useEffect 영역 끝 ///////////////////////

  return (
    <S.Wrap className='p_profile'>
      <div className='com_center_wrap'>
        <div className='com_container'>
          <aside className='aside'>
            {/* <section className="id_sec" onClick={handleClickOpenSwitchModal}> */}
            <section className='id_sec no_multi'>
              {/* <ID accountInfo={userInfo.userLevel === "001002000000000" ? userInfo : switchAccountInfo[0]} /> */}
              <ID accountInfo={accountInfo} />
            </section>
            <nav className='lnb'>
              <TabMenu tab={renderingData.tab} tabState={tabState} setTabState={setTabState} />
            </nav>
          </aside>
          <section className='profile_body'>{InformationSection}</section>
        </div>
      </div>
      {/* Edit Modal */}
      {openEditModal && (
        <EditModal
          ID={ID}
          setOpenEditModal={setOpenEditModal}
          userSeq={userInfo.userSeq}
          modifiedData={modifiedData}
          setAlert={setAlert}
          setAlertLayerPopup={setAlertLayerPopup}
          setReloadData={setReloadData}
          ecSeq={switchAccountInfo[0]?.ecSeq || ''}
          mcSeq={switchAccountInfo[0]?.mcSeq || ''}
          userLevel={dbUserInfo.userLevel}
          upFileSeq={dbUserInfo.upFileSeq}
          academyUpFileSeq={dbUserInfo.academyUpFileSeq}
          noPassword={noPassword}
          dbUserInfo={dbUserInfo}
        />
      )}
      {/*Switch account 팝업*/}
      {openSwitchModal && <MultiProfile tit='SWITCH ACCOUNT' setOpenSwitchModal={setOpenSwitchModal} switchAccountInfo={switchAccountInfo} />}
      {/*delete account 팝업*/}
      {openDeleteAccountModal && <PopDelAccount setOpenDeleteAccountModal={setOpenDeleteAccountModal} />}
      {/*Link YOUR CHILD 팝업*/}
      {useLevels.isParent && linkPop && (
        <LinkYourChildModal
          userInfo={userInfo}
          setAlertLayerPopup={setAlertLayerPopup}
          linkPop={linkPop}
          setLinkPop={setLinkPop}
          setReloadData={setReloadData}
          authenCodeRef={authenCodeRef}
        />
      )}
      {/*Link an Academy or Tutor 팝업*/}
      {!useLevels.isParent && linkPop && (
        <LinkAnAcademyOrTutorModal
          useLevels={useLevels}
          setLinkPop={setLinkPop}
          authenCodeRef={authenCodeRef}
          setAlertLayerPopup={setAlertLayerPopup}
          requestLink={requestLink}
          setRequestLink={setRequestLink}
          userInfo={userInfo}
          setRequestCompanyName={setRequestCompanyName}
          setRequestEcSeq={setRequestEcSeq}
          confirmCodeError={confirmCodeError}
          setConfirmCodeError={setConfirmCodeError}
        />
      )}
      {/* Request link 모달 */}
      {requestLink && (
        <RequestLinkModal
          useLevels={useLevels}
          requestCompanyName={requestCompanyName}
          setRequestLink={setRequestLink}
          setConfirmCodeError={setConfirmCodeError}
          setAlert={setAlert}
          setReloadData={setReloadData}
          userInfo={userInfo}
          requestEcSeq={requestEcSeq}
          authenCodeRef={authenCodeRef}
          setLinkPop={setLinkPop}
        />
      )}

      {/* 알럿 */}
      {alert.active && <MiniAlert text={alert.message} active={alert.active} inactive={() => setAlert({ ...alert, active: false })} timeOut={2000} />}
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    .com_container {
      background: #f4f8ff;
      padding: 1rem;
      border-radius: 0.625rem;
    }
  `,
};
