//#region    ////////// packages
import styled from 'styled-components';
import { useEffect, useState } from 'react';
//#endregion ////////// packages

//#region    ////////// components
import ApSettingFixedBox from './_components/ApSettingFixedBox';
import ApSettingInput from './_components/ApSettingInput';
//#endregion ////////// components

//#region    ////////// constants
import { QUESTION_CONSTANT, QUESTION_TYPE_CONSTANT } from 'components/ap/question-bank/test-editor/_constant';
//#endregion ////////// constants

//#region    ////////// assets
import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/round_triangle.svg';
//#endregion ////////// assets

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
/** 문제 유형 데이터 조회 용 객체 */
const questionTypeObj = {
  [QUESTION_TYPE_CONSTANT.M]: QUESTION_TYPE_CONSTANT.M_LABEL,
  [QUESTION_TYPE_CONSTANT.E]: QUESTION_TYPE_CONSTANT.E_LABEL,
  [QUESTION_TYPE_CONSTANT.H]: QUESTION_TYPE_CONSTANT.H_LABEL,
};
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** AP Test Editor - 문제 데이터를 편집 하는 팝업 */
export default function QuestionSettingPopup({ isOpen, pos = 'left', questionInfo, handleUpdateQuestionInfo, triggerRef }) {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  // 위치 업데이트 함수
  const updatePosition = () => {
    if (isOpen && triggerRef?.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top + rect.height / 2,
        left: rect.left + (pos === 'left' ? -450 : rect.width),
      });
    }
  };
  // 팝업 위치 업데이트
  useEffect(() => {
    if (isOpen) {
      updatePosition();
      const scrollContainer = triggerRef.current?.closest('.custom_scroll');
      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', updatePosition);
      }

      return () => {
        if (scrollContainer) {
          scrollContainer.removeEventListener('scroll', updatePosition);
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, pos, triggerRef]);

  const isMultipleChoice = questionInfo.question_type === QUESTION_TYPE_CONSTANT.M;

  if (!isOpen) return null;
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <S.Container style={{ top: position.top, left: position.left }} $pos={pos}>
      <div className='popup_header'>
        <h2>Question Settings</h2>
      </div>

      <S.ContentWrapper>
        <div className='control_row'>
          <span className='label'>Question Type</span>
          <div className='select_control'>
            <ApSettingFixedBox value={questionTypeObj[questionInfo.question_type]} />
          </div>
        </div>

        <div className='divider' />

        <div className='control_row'>
          <span className='label'>Possible Score</span>

          {isMultipleChoice ? (
            <ApSettingFixedBox value={1} />
          ) : (
            <ApSettingInput
              value={questionInfo[QUESTION_CONSTANT.score]}
              onChange={(value) => {
                handleUpdateQuestionInfo(QUESTION_CONSTANT.score, Number(value));
              }}
            />
          )}
        </div>
      </S.ContentWrapper>
      <ArrowIconSelect className='triangle_icon' />
    </S.Container>
  );
}

// --- --- --- Styled Components --- --- --- //
const getPopupPosition = (pos) => {
  switch (pos) {
    case 'left':
      return `
        transform: translateY(-50%);
        
        .triangle_icon {
          right: -3%;
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
        }
      `;
    case 'right':
      return `
        transform: translateY(-50%);
        
        .triangle_icon {
          left: -3%;
          top: 50%;
          transform: translateY(-50%);
        }
      `;
    case 'top':
      return `
        transform: translate(-50%, -100%);
        
        .triangle_icon {
          bottom: -4%;
          left: 50%;
          transform: translateX(-50%) rotate(270deg);
        }
      `;
    case 'bottom':
      return `
        transform: translate(-50%, 0);
        
        .triangle_icon {
          top: -4%;
          left: 50%;
          transform: translateX(-50%) rotate(90deg);
        }
      `;
    default:
      return `
        transform: translateY(-50%);
        
        .triangle_icon {
          left: -3%;
          transform: translateY(-50%);
        }
      `;
  }
};
const S = {
  Container: styled.div`
    position: fixed;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 24px;
    width: 430px;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.24));
    z-index: 99;
    ${({ $pos }) => getPopupPosition($pos)}

    .triangle_icon {
      position: absolute;
      color: #ffffff;
    }
  `,

  ContentWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8125rem;

    .divider {
      height: 1px;
      background-color: #f1f1f5;
      width: 100%;
    }

    .control_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &.inner_row {
        background-color: #fafafa;
        padding: 0.625rem 0.75rem;
        border-radius: 0.25rem;
      }

      .label {
        color: #111111;
        font-family: 'Roboto-Regular', Helvetica;
        font-size: 14px;
        line-height: 20px;
      }

      .ap_input {
        width: 8.75rem;
        padding: 0 10px;

        &.number_input {
          max-height: 2.375rem;
        }
        &.code_input {
          max-height: 2.125rem;
        }
      }
    }

    .group_control {
      display: flex;
      align-items: center;
      gap: 8px;

      .separator {
        color: #111111;
        font-size: 12px;
        line-height: 18px;
      }
    }
  `,

  SectionDivider: styled.div`
    height: 2px;
    background-color: #505050;
    width: 100%;
  `,
};
