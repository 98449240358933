import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import SubButton from 'components/user/_components/common/buttons/SubButton';
import MainBox from 'components/user/_components/layouts/MainBox';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import Label from 'components/user/_components/common/forms/Label';
import Input from 'components/user/_components/common/forms/Input';
import ErrorText from 'components/user/_components/styles/ErrorText';
import Header from 'components/user/sign-up/_components/layouts/Header';
import Logo from 'components/user/_components/layouts/Logo';
import { useSelector, useDispatch } from 'react-redux';
import { updateReduxPrivateTutorTutoringName } from 'reducers/auth/privatetutor.action';
/** 회원가입 - Tutor - Tutor 정보 입력 페이지 */
export default function SignUpTutorInformationPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSocial = useSelector((state) => state.userReducer.social);
  const userSocialEmail = userSocial.userEmail;

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {},
  });
  const storedTutoringName = useSelector((state) => state.privateTutorReducer.tutoringName);
  const tutoringName = watch('tutoringName', storedTutoringName);

  /** Form 제출 핸들러 */
  const onValid = (data) => {
    console.log('🚀 ~ onValid ~ data:', data);
    dispatch(updateReduxPrivateTutorTutoringName(data.tutoringName));
    navigate('/signup/social/tutor/upload');
  };

  /** 뒤로가기 버튼 핸들러 */
  const handleClickBackButton = () => {
    navigate('/signup/social/tutor/information-review');
  };

  useEffect(() => {
    // userSocialEmail 값이 없으면 에러처리 404
    if (!userSocialEmail) {
      navigate('/404');
    }
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrap>
      <MainBox>
        <Logo />
        <Header title={'Tutor'} />
        <form onSubmit={handleSubmit(onValid)}>
          <h4>What is your tutoring name? </h4>
          <p className='guide'>This name will be shown to your students. You can edit the name in your profile later. </p>
          <FormItemWrap>
            <Label className='red-dot' htmlFor='tutoringName'>
              Tutoring name
            </Label>
            <Input
              id='tutoringName'
              value={tutoringName}
              onChange={(e) => setValue('tutoringName', e.target.value)}
              register={register('tutoringName', {
                required: 'Please enter in your tutoring name',
              })}
              type='text'
              autoFocus
            />
            {errors.tutoringName && <ErrorText>{errors.tutoringName.message}</ErrorText>}
          </FormItemWrap>

          <PrimaryButtonArea>
            <SubButton type='button' onClick={handleClickBackButton} title='이전 페이지'>
              Back
            </SubButton>
            <PrimaryButton type='submit' title='제출'>
              Submit
            </PrimaryButton>
          </PrimaryButtonArea>
        </form>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  h4 {
    font-size: 1rem;
    font-weight: 500;
    color: #7f8998;
    text-align: left;
  }
  .guide {
    margin-top: 0.75rem;
    margin-bottom: 1.5rem;
    color: #7f8998;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
  }
  .red-dot {
    padding-left: 0.65rem;
    ::before {
      content: '*';
      color: var(--sub-red);
      margin-top: 2px;
      margin-left: -10px;
      position: absolute;
    }
  }
`;
