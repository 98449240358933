import React, { useEffect } from 'react';
import styled from 'styled-components';
import UserTypeButton from 'components/user/_components/common//buttons/UserTypeButton';
import MainBox from 'components/user/_components/layouts/MainBox';
import Logo from 'components/user/_components/layouts/Logo';
import { useDispatch } from 'react-redux';
import { clearReduxStateAcademyAdmin } from 'reducers/auth/academyadmin.action';
import { clearReduxStateStudent } from 'reducers/auth/student.action';

/** "회원가입  - 사용자 유형 선택" 페이지 용 컴포넌트 (이메일/소셜 회원가입 라우트 페이지 들에서 공유) */
export default function UserType({ signUpUserTypeDataArray }) {
  const dispatch = useDispatch();
  // 소셜 redux store는 유지
  // 유저 타입별 redux store는 초기화
  dispatch(clearReduxStateAcademyAdmin());
  dispatch(clearReduxStateStudent());

  const linkfucntion = (link) => {
    console.log('🚀 ~ linkfucntion ~ link:', link);
  };

  // useEffect(() => {
  //   console.log('🚀 ~ UserType ~ userSocial:', userSocial);
  // }, []);
  return (
    <S.Wrap>
      <MainBox className='main-box'>
        <Logo />
        <section>
          <h2 className='main-tit'>Which type of account do you want to sign in to?</h2>
          <div>
            {signUpUserTypeDataArray.map((item, index) => (
              <UserTypeButton key={`id${index}`} itemId={item.id} link={item.link} titleText={item.titleText} subText={item.subText} userLevel={item.code} />
            ))}
          </div>
        </section>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .main-box {
    width: 460px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .main-tit {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #7f8998;
    text-align: left;
  }
`;
