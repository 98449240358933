import { nvl } from 'utils/Common.utils';
import localStorage from 'utils/LocalStorage.utils';

/** 문제 선택 (전체 창) 컴포넌트 (영어) */
export default function ReviewPopup({ stateExamInfo, setStateExamInfo }) {
  const questionInfo = localStorage.getItemJsonParse(`apTest_${localStorage.getItemJsonParse('currentTest').uthSeq}`).questionList;

  const gotoChoiceQuestion = (page) => {
    setStateExamInfo((prev) => ({ ...prev, currentNum: page, isReviewPopupOpen: false }));
  };

  return (
    <article className='review_sec'>
      <div className='tit'>Check Your Work</div>
      <div className='txt'>
        On test day, you won't be able to move on to the next module until time expires.
        <br />
        For these practice questions, you can click <b>Next</b> when you're ready to move on.
      </div>
      <section className='question_layer'>
        <div className='tit_field'>Section 1, Module {1}: Reading and Writing</div>
        <div className='info_field'>
          <div className='item'>
            <i className='svg_icon icon_unanswered'>&nbsp;</i>Unanswered
          </div>
          <div className='item'>
            <i className='svg_icon icon_bookmark'>&nbsp;</i>For Review
          </div>
        </div>
        <div className='question_field'>
          {Array.from({ length: stateExamInfo.totalQuestion }, (_, index) => (
            <button
              key={`review_${index + 1}`}
              className={`
                  item
                  ${questionInfo[index]?.selectAnswer.length > 0 || nvl(questionInfo[index]?.answerContent?.replace(/<[^>]*>/g, '').trim()) != '' ? 'answered' : ''}
                  ${questionInfo[index]?.isBookmarked ? 'review' : ''}
                `}
              onClick={() => gotoChoiceQuestion(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
      </section>
    </article>
  );
}
