import {
  ADD_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  UPDATE_AP_TEST_SEQ,
  CLEAR_AP_TEST_EDITOR,
  UPDATE_CURRENT_QUESTION_INDEX,
  UPDATE_CURRENT_PART_DATA,
  UPDATE_CURRENT_SECTION_DATA,
  SECTION_META_DATA,
  ADD_SOURCE,
} from './actionTypes';

/** "Gate Key" 관련 전역 상태 기본 값 */
const initialState = {
  test_seq: 0, // 테스트 시퀀스
  currentQuestion_index: 0, // 현재 질문 인덱스
  questions: [], // 질문 배열
  sectionMetadata: null, // 섹션 메타데이터
  currentPartData: null, // 현재 파트 데이터
  currentSectionData: null, // 현재 섹션 데이터
};
const apTestEditorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_QUESTION:
      return {
        ...state,
        questions: [...state.questions, action.payload], // 질문 추가
      };
    // 배열 전달시 전체 question 업데이트
    case UPDATE_QUESTION:
      return {
        ...state,
        questions: Array.isArray(action.payload.questions)
          ? action.payload.questions // 전체 배열 업데이트
          : [
              // 해당 part_seq와 question_order가 일치하지 않는 문제들만 필터링
              ...state.questions.filter((item) => !(item.part_seq === action.payload.partSeq && item.question_order === action.payload.questionOrder)),
              // 새로운 문제 추가
              action.payload.question,
            ],
      };
    case DELETE_QUESTION:
      return {
        ...state,
        questions: state.questions.filter((_, idx) => idx !== action.payload), // 질문 삭제
      };
    case UPDATE_AP_TEST_SEQ:
      return {
        ...state,
        test_seq: action.payload, // 테스트 시퀀스 업데이트
      };
    case UPDATE_CURRENT_QUESTION_INDEX:
      return {
        ...state,
        currentQuestion_index: action.payload, // 현재 질문 인덱스 업데이트
      };
    case SECTION_META_DATA:
      return {
        ...state,
        sectionMetadata: action.payload, // 섹션 메타데이터 업데이트
        currentPartData: action.payload.sections[0].parts[0], // 현재 파트 데이터 업데이트
        currentSectionData: action.payload.sections[0], // 현재 섹션 데이터 업데이트
      };
    case CLEAR_AP_TEST_EDITOR:
      return {
        ...state,
        test_seq: 0,
        currentQuestion_index: 0,
        sectionMetadata: null,
        questions: [],
      };
    case UPDATE_CURRENT_PART_DATA:
      return { ...state, currentPartData: action.payload }; // 전달받은 현재 파트 데이터 업데이트
    case UPDATE_CURRENT_SECTION_DATA:
      return { ...state, currentSectionData: action.payload }; // 전달받은 현재 섹션 데이터 업데이트

    default:
      return state;
  }
};
export default apTestEditorReducer;
// const initialState = {
//   part_seq: 0,
//   source_directions: '',
//   source_order: 0,
//   source_content: '',
//   question_order: 0,
//   question_type: '',
//   question_content: '',
//   question_context: '',
//   answer_options: {
//     options: [],
//     total: 0,
//     answerCount: 0,
//   },
//   answer: [],
//   score: 0,
// };

// const validatePayload = (payload) => {
//   const requiredFields = [
//     'part_seq',
//     'source_directions',
//     'source_order',
//     'source_content',
//     'question_order',
//     'question_type',
//     'question_content',
//     'question_context',
//     'answer_options',
//     'answer',
//     'score',
//   ];
//   requiredFields.forEach((field) => {
//     if (!(field in payload)) {
//       console.warn(`Missing field in payload: ${field}`);
//     }
//   });
// };

// const stateApTestEditor = (state = initialState, action) => {
//   switch (action.type) {
//     case UPDATE_AP_TEST_EDITOR:
//       validatePayload(action.payload);
//       console.log('Previous State:', state);
//       console.log('Payload:', action.payload);
//       console.log('Updated State:', {
//         ...state,
//         ...action.payload,
//       });
//       return {
//         ...state,
//         ...action.payload, // 필요한 필드만 덮어쓰기
//       };
//     case CLEAR_AP_TEST_EDITOR:
//       return {
//         ...state,
//         part_seq: 0,
//         source_directions: '',
//         source_order: 0,
//         source_content: '',
//         question_order: 0,
//         question_type: '',
//         question_content: '',
//         question_context: '',
//         answer_options: {
//           options: [],
//           total: 0,
//           answerCount: 0,
//         },
//         answer: [],
//         score: 0,
//       };
//     default:
//       return state;
//   }
// };

// export default stateApTestEditor;
