import React from 'react';
import styled from 'styled-components';

/** 보조 버튼 스타일 용 컴포넌트 */
export default function SkipButton({ children, ...rest }) {
  return <S.Button {...rest}>{children}</S.Button>;
}

const S = {};

S.Button = styled.button`
  border: 1px solid var(--point-color);
  background-color: #ffffff;
  color: var(--point-color);
  font-weight: 500;
`;
