import CustomAlert from 'components/_common/alerts/CustomAlert';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import { ARRAY_CURRENT_STEP_PRIMARY_BUTTON_DATA } from '../../_utils/constants/stepOfSetTests';
import getCurrentIndex from '../../_hooks/getCurrentIndex';
import {
  updateReduxStateSelectedClass,
  updateReduxStateSelectedConfig,
  updateReduxStateSelectedUserList,
  updateReduxStateSelectedModules,
  updateReduxStateSelectedDate,
  clearReduxStateSetTests,
} from 'reducers/setTests/action';
import { convertTimeHHMMSSFormat } from 'utils/functions/time/convertTimeFormat';
import dayjsTZ from 'utils/functions/time/dayjs-config';

/** "Set Tests 주요 버튼 (Next/Back 등) 영역 컴포넌트" */
export default function PrimaryButtonArea({ isB2c, selectTestSet, testOrderInfo, classSeq, selectedClassName, selectedStudents, setShowInsufficientKeyModal }) {
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 /////////////////
  /** CustomAlert 코드 */
  const alertAttributeValue = {
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    returnValue: () => {},
  };
  /** 마지막 단계 index */
  const lastStep = ARRAY_CURRENT_STEP_PRIMARY_BUTTON_DATA.length - 1;
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝 /////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /** "Set Tests 전역 상태" */
  const stateSetTests = useSelector((state) => state.stateSetTests.setTests);
  /** 사용자의 Gate Key 전역 상태 */
  const stateGateKeys = useSelector((state) => state.stateGateKeys);
  const currentIndex = getCurrentIndex();
  /** Review 단계에서 존재해야 하는 선택 항목이 비어있는가? (시험 셋트 목록 or 학생 목록) */
  const isEmpty = isB2c
    ? stateSetTests.selectedModules.length <= 0
    : (stateSetTests.selectedModules.length <= 0) | (stateSetTests.selectedStudents.length <= 0);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////

  /////////////////// React useState 선언 영역 시작 //////////////////
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  /////////////////// React useState 선언 영역 끝 //////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 //////////////////
  /** "Next 버튼 핸들러 함수" */
  const handleNextBtn = () => {
    const nextLink = ARRAY_CURRENT_STEP_PRIMARY_BUTTON_DATA[currentIndex].nextRoute;

    /** 선택 하지 않았을 경우 검사 및, 에러 처리 */
    const checkNonSelect = (condition, subjectText = '') => {
      if (typeof condition !== 'boolean') {
        console.error('조건문은 Boolean 타입으로 평가 되어야 합니다.');
      }
      if (condition) {
        return true;
      } else {
        // 필수 선택을 하지 않고, Next 버튼을 눌렀을 경우
        setAlertLayerPopup((prev) => {
          return { ...prev, visible: true, alertMessage: `Please select${subjectText ? ` ${subjectText}` : ''}` };
        });
        return false;
      }
    };

    //// 각 단계별 실행문
    if (currentIndex === 1) {
      // Select Class : Class list 단계 Next 버튼

      /** 이 단계에서 다음 단계로 진행하기 위한 필수 조건 */
      const requirement = nvlNumber(classSeq) !== 0 && nvl(selectedClassName) !== '';
      /** 이 단계에서 선택 하여야 할 것들의 선택 여부 */
      const isSelect = checkNonSelect(requirement, 'Class');
      if (!isSelect) return;

      dispatch(
        updateReduxStateSelectedClass({
          classSeq: classSeq,
          className: selectedClassName,
        })
      );

      navigate(nextLink, { state: { value: classSeq } });
      return;
    } else if (currentIndex === 2) {
      // Select Student : Student list 단계 Next 버튼

      /** 이 단계에서 다음 단계로 진행하기 위한 필수 조건 */
      const requirement = selectedStudents && nvl(selectedStudents) !== '' && selectedStudents.length > 0;
      /** 이 단계에서 선택 하여야 할 것들의 선택 여부 */
      const isSelect = checkNonSelect(requirement, 'Student');
      if (!isSelect) return;

      dispatch(updateReduxStateSelectedUserList(selectedStudents));
      navigate(nextLink);
      return;
    } else if (currentIndex === 3) {
      // Select Test : Configuration 단계 Next 버튼

      /** 이 단계에서 다음 단계로 진행하기 위한 필수 조건 */
      const requirement = nvl(stateSetTests.selectedConfig.testConfiguration) !== '';
      /** 이 단계에서 선택 하여야 할 것들의 선택 여부 */
      const isSelect = checkNonSelect(requirement, 'Test');
      if (!isSelect) return;

      navigate(nextLink);
      return;
    } else if (currentIndex === 4) {
      // Select Test : Available Tests 단계 Next 버튼

      /** 이 단계에서 다음 단계로 진행하기 위한 필수 조건 */
      const requirement = nvl(selectTestSet) !== '' && selectTestSet.length > 0;
      /** 이 단계에서 선택 하여야 할 것들의 선택 여부 */
      const isSelect = checkNonSelect(requirement, 'Test');
      if (!isSelect) return;
      dispatch(updateReduxStateSelectedModules(selectTestSet));

      isB2c ? navigate(ARRAY_CURRENT_STEP_PRIMARY_BUTTON_DATA[currentIndex + 1].nextRoute) : navigate(nextLink);

      return;
    } else if (currentIndex === 5) {
      // Select Date 단계 Next 버튼

      /** 이 단계에서 다음 단계로 진행하기 위한 필수 조건 */
      const requirement = testOrderInfo.every((item) => {
        return item.testTimeInfo.start.date !== '' && item.testTimeInfo.start.time !== '';
      });
      /** 이 단계에서 선택 하여야 할 것들의 선택 여부 */
      const isSelect = checkNonSelect(requirement, 'Date');
      /** 이 단계에서 선택 하여야 할 것들의 선택 여부 */
      if (!isSelect) return;

      const newDateArray = testOrderInfo.map((item) => {
        return item.testTimeInfo;
      });

      dispatch(updateReduxStateSelectedDate(newDateArray));

      navigate(nextLink);
      return;
    } else {
      console.error('잘 못된 접근 입니다.');
      return;
    }
  };

  /** "Back" 버튼 핸들러 함수 */
  const handleBackButton = () => {
    navigate(-1);
  };

  /** 시험 등록 API 요청 함수 */
  const PostTestData = (navigatePath) => {
    const api = isB2c ? '/api/gatekey/used/b2c' : `/api/gatekey/used/academy`;

    const userSeqStr = isB2c ? null : stateSetTests.selectedStudents.map((item) => item.userSeq).join('|');
    const scheduleStr = isB2c
      ? stateSetTests.selectedModules
          .map((item) => {
            return `${item.customSetSeq}`;
          })
          .join('||')
      : stateSetTests.selectedModules
          .map((item) => {
            const timeForm = convertTimeHHMMSSFormat(item.testTimeInfo.start.time);

            return `${item.customSetSeq},${dayjsTZ(`${item.testTimeInfo.start.date} ${timeForm}`).format()},${dayjsTZ(`${item.testTimeInfo.end.date} ${timeForm}`).format()}`;
          })
          .join('|');

    const params = isB2c
      ? {
          keyToUse: Number(`-${stateSetTests.totalGateKey}`),
          customSetSeqStr: scheduleStr,
        }
      : {
          amount: Number(`-${stateSetTests.totalGateKey}`),
          classSeq: Number(stateSetTests.selectedClass.classSeq),
          userSeqStr: userSeqStr,
          scheduleStr: scheduleStr,
        };

    const successHandler = (response) => {
      if (response.code === 200) {
        dispatch(clearReduxStateSetTests());
        navigate(navigatePath);
      }
    };
    request.post(api, params, successHandler).catch((error) => console.error(error));
  };

  /** 최종 결제 버튼 핸들러 */
  const handleGoToPayment = () => {
    /** API 응답 성공 후 이동 할 라우트 */
    const nextRoute = isB2c ? '/student/dashboard' : '/com/tests/scheduled';
    // 최종 결제 전 필수 값들을 확인 및 에러 처리 한 이후, API 요청을 보낸다.
    if (Number(stateGateKeys.remainingKey) < Number(stateSetTests.totalGateKey)) {
      // Gate Key 부족 시, Insufficient Gate Key 모달 띄우기
      setShowInsufficientKeyModal(true);
    }

    PostTestData(nextRoute);
  };

  /** 첫 단계로 이동 핸들러 */
  const handleGoToFirst = () => {
    const firstRoute = isB2c ? '/com/tests/set/selection/config' : '/com/tests/set/selection/type';
    navigate(firstRoute);
    dispatch(clearReduxStateSetTests());
  };

  /** 팝업 닫기 버튼 핸들러 */
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  return (
    <>
      <S.ComBtnWrap className='com_btn_wrap bottom'>
        {(isB2c ? currentIndex === 3 : currentIndex === 0) ? (
          <button onClick={handleNextBtn} className='com_btn point full l' title='Move to another Page'>
            NEXT
          </button>
        ) : currentIndex === lastStep ? (
          !isEmpty ? (
            <>
              <button className='com_btn full point l' onClick={handleGoToPayment} title='Move to another Page'>
                Confirm
              </button>
              <button onClick={handleBackButton} className='com_btn full point line l' title='Move to another Page'>
                BACK
              </button>
            </>
          ) : (
            <button className='com_btn full point l' onClick={handleGoToFirst} title='Move to another Page'>
              Set Test
            </button>
          )
        ) : (
          <>
            <button onClick={handleBackButton} className='com_btn point line l' title='Move to another Page'>
              BACK
            </button>
            <button onClick={handleNextBtn} className='com_btn point l' title='Move to another Page'>
              NEXT
            </button>
          </>
        )}
      </S.ComBtnWrap>
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </>
  );
}

const S = {};

/** "주문 상세 내역" 하단 버튼 영역의 컴포넌트의 styled-components */
S.ComBtnWrap = styled.div`
  flex-wrap: wrap;
  gap: 1rem;
  .com_btn {
    width: calc((100% - 1rem) / 2);
    &.full {
      width: 100%;
    }
  }
`;
