import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import dayjsTZ, { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

// hook
import { STUDENT_PARAMS, USER_LEVELS } from 'utils/constants';
import { nvl, nvlNumber } from 'utils/Common.utils';
import request from 'utils/Request.utils';
import LoadingBar from 'utils/LoadingBar.js';

//components
import CustomAlert from 'components/_common/alerts/CustomAlert';
import MiniCalendarSection from 'components/academy/dashboard/_components/MiniCalendarSection';

import ModalHaveToLinkWithAcademyOrTutor from 'components/_common/modals/HaveToLinkWithAcademyOrTutor';
import UpcomingTestSection from './_components/layouts/sections/upcomingTest/UpcomingTestSection';
import TimeLineSection from './_components/layouts/sections/setTimeLine/TimeLineSection';
import AdvancedReportSection from './_components/layouts/sections/advancedReport/AdvancedReportSection';
import VisualArea from './_components/layouts/visualArea/VisualArea';
import VisualTextArea from './_components/layouts/visualArea/VisualTextArea';
import DsatInfoArea from './_components/layouts/dsatInfo/DsatInfoArea';
import { removeDuplicates } from 'utils/functions/removeDuplicates';
import PopTestList from 'components/com/score/_components/modals/_PopTestList';
import TestList from './_components/layouts/sections/testList/TestList';
import OuterBox from './_components/common/OuterBox';
import Box from './_components/common/Box';
import SelectedTitle from './_components/layouts/sections/selectedTitle/SelectedTitle';
import ScoreTrend from './_components/sections/ScoreTrend/ScoreTrend';
import useUserLevels from 'hooks/useUserLevels';
import OverallAverageScore from './_components/sections/overallAverageScore/OverallAverageScore';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';

/** 대시보드 페이지 (Student, Parent 라우트에서 공유하는 페이지) */
export default function DashboardPage() {
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 시작 ///////////////////
  const alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  /////////////////// 컴포넌트 내 전역 상수 선언 영역 끝  ///////////////////

  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ///////
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** 현재 선택 된 시험 세트의 UTH Seq */
  const selectedUthSeq = getObfuscatedValue(STUDENT_PARAMS.UTH_SEQ.KEY);
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 //////////

  /////////////////// React useState 선언 영역 시작 //////////////////////
  const [userInfo, setUserInfo] = useState(request.tokenDecoder());
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);
  const [tableListActive, setTableListActive] = useState(false);
  /** 자식 시퀸스 (부모 계정 일 경우) */
  const [childSeqState, setChildSeq] = useState({
    isLoading: true,
    value: null,
  });
  const [childEcSeq, setChildEcSeq] = useState(0);
  const [testResult, setTestResult] = useState({
    //Report Card data
    userName: '',
    studentGrade: '',
    questionList: [],
    questionAccuracyList: [],
    firstTestDate: '',
    rwCurrentScore: 0,
    mathCurrentScore: 0,
    rwCurrentRank: 0,
    mathCurrentRank: 0,
    rwRank: 0,
    mathRank: 0,

    subject: '',
    difficulty: '',
    engDiff: '',
    mathDiff: '',
    trhDate: '',
    dateForm: '',
    engAllRate: 0,
    mathAllRate: 0,
    testName: '',
    className: '',
    ecSeq: 0,
    academyUpFileSeq: 0,
    companyName: null,
  });
  const [barChartData, setBarChartData] = useState([
    {
      data: [0, 0, 0, 0],
      gatePlusData: [0, 0, 0, 0],
    },
  ]);

  const [barChartData2, setBarChartData2] = useState([
    {
      data: [0, 0, 0, 0],
      gatePlusData: [0, 0, 0, 0],
    },
  ]);

  const [userExpectTestList, setUserExpectTestList] = useState([]);
  const [userInProgressTestList, setUserInProgressTestList] = useState([]);
  /** 수학 주관식 문제만 있는 시험 */
  const [userOnlySubjectTestList, setUserOnlySubjectTestList] = useState();
  const [userTestHistoryList, setUserTestHistoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState({
    uthSeq: 0,
    moduleNum: '',
    tmSubject: '',
    color: '',
    subjectOption: false,
    moduleNumOption: false,
    className: 'custom-tooltip',
  });

  const [isModalHaveToLinkWithAcademyOrTutor, setIsModalHaveToLinkWithAcademyOrTutor] = useState(false);

  /** 시험 목록 */
  const [testList, setTestList] = useState([]);
  /** 현재 선택 된 시험 데이터 */
  const [selectedTest, setSelectedTest] = useState(null);
  /** "test list" 영역의 화면 상 위치 데이터 */
  const [testListAreaPosition, setTestListAreaPosition] = useState({ top: 0, left: 0 });
  /////////////////// React useState 선언 영역 끝 //////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  const tResultSeqRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////
  const { permissions: useLevels, isLoading: useLevelsIsLoading } = useUserLevels(userInfo.userLevel);

  /** 커스텀 알럿 창 닫기 핸들러 */
  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  /** 커스텀 알럿 창이 반환하는 값 핸들러 */
  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
    }
  };

  /** 학생 시퀀스 얻어오기 API 요청 */
  const getChildSeq = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let childInfo = response.result.childInfo;
        if (childInfo) {
          let childSeq = childInfo.familySeq;
          let childEcSeq = childInfo.ecSeq;
          setChildSeq((current) => {
            return {
              value: childSeq,
              isLoading: false,
            };
          });
          setChildEcSeq(childEcSeq);
          getTodayDate(childSeq, childEcSeq);
        }
      }
    };

    request
      .get(`/api/member/child?userSeq=${userInfo.userSeq}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 학생 시퀀스 얻어오기 API 실패', error));
  };

  /** 시험 목록 가져오기 */
  const getUpcomingTestList = (userSeq, ecSeq, reload) => {
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        let userTestList = response.result.userTestHistoryList;

        setUserInProgressTestList(userTestList?.filter((i) => (i.testStatus === 'I' || i.testStatus === 'B') && isoTimeToDayjs(i.testEndDate) >= dayjsTZ())); // 보던 시험
        setUserExpectTestList(userTestList?.filter((i) => i.testStatus === 'E' && isoTimeToDayjs(i.testEndDate) >= dayjsTZ())); // 볼 시험

        setUserOnlySubjectTestList(userTestList?.filter((item) => item.simpleExamSeq) ?? []);

        setLoading(false);

        if (!reload) getTestHistoryList(userSeq, ecSeq);
      }
    };

    request.get(`/api/dsat/history/test/detail?userSeq=${userSeq}&ecSeq=${ecSeq}`, null, successHandler).catch((error) => {
      console.error('학생 대시보드 - 시험 목록 가져오기 API 실패', error);
      setLoading(false);
    });
  };

  /** 현재 시간 얻어오기 & 세팅 된 testList 불러오기 */
  const getTodayDate = (userSeq, ecSeq = userInfo.ecSeq, reload = false) => {
    setLoading(true);

    getUpcomingTestList(userSeq, ecSeq, reload);
  };

  /** 시험 일정 호출 API */
  const getTestHistoryList = (userSeq, ecSeq) => {
    let pms = {
      userSeq: userSeq,
      ecSeq: ecSeq,
      testStatus: 'P',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let userTestHistoryList = response.result.userTestHistoryList;

        if (userTestHistoryList != null && userTestHistoryList?.length > 0) {
          setUserTestHistoryList(userTestHistoryList);
          setTestResult((prev) => {
            return {
              ...prev,
              testName: userTestHistoryList[0].testName,
              className: userTestHistoryList[0].className,
              firstTestDate: userTestHistoryList[userTestHistoryList?.length - 1].testStartDate,
            };
          });

          getTestPointOX(userTestHistoryList[0].uthSeq, userSeq);
          dailyAveragePoint(userTestHistoryList[userTestHistoryList?.length - 1].testStartDate, userSeq);
        }

        setLoading(false);
      }
    };

    request.get(`/api/exam/userTestHistory?userSeq=${pms.userSeq}&ecSeq=${pms.ecSeq}&testStatus=${pms.testStatus}`, null, successHandler).catch((error) => {
      console.error('학생 대시보드 - 시험 일정 호출 API 실패', error);
      setLoading(false);
    });
  };

  /** 시험 정오답 내역 조회 API 요청 */
  const getTestPointOX = (uthSeq = userInfo.ecSeq, userSeq = userInfo.userSeq) => {
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        const { testInfo, eDiff, mDiff, mathPoint, mathRank, rwPoint, rwRank } = response.result;
        setTestResult((prev) => {
          return {
            ...prev,
            ...testInfo,
            mathDiff: mDiff,
            engDiff: eDiff,
            mathCurrentScore: mathPoint,
            mathCurrentRank: 100 - mathRank,
            rwCurrentScore: rwPoint,
            rwCurrentRank: 100 - rwRank,
          };
        });
        setFilterValue((prev) => {
          return { ...prev, tmSubject: 'E', uthSeq: uthSeq, color: '#008CFF' };
        });

        const fetchedUserSeq = userInfo.userLevel === USER_LEVELS.STUDENT ? userSeq : childSeqState.value; // 학생일 경우 userSeq, 학부모일 경우 childSeqState
        if (uthSeq && fetchedUserSeq) {
          getCategoriesRate(uthSeq, fetchedUserSeq);
        }
      }
      setLoading(false);
    };

    request.get(`/api/dsat/history/test/score?uthSeq=${uthSeq}`, null, successHandler).catch((error) => {
      console.error('학생 대시보드 - 시험 정오답 내역 조회 API 실패', error);
      setLoading(false);
    });
  };

  /** 시험 유형 별 정답율 조회 API 요청 */
  const getCategoriesRate = (uthSeq, userSeq) => {
    setLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        let personal = response.result.personal;
        let overall = response.result.overall;

        if (personal != null && overall?.length > 0) {
          let CAS = personal?.filter((item) => item.type === 'CAS');
          let IAI = personal?.filter((item) => item.type === 'IAI');
          let SEC = personal?.filter((item) => item.type === 'SEC');
          let EOI = personal?.filter((item) => item.type === 'EOI');

          let ALG = personal?.filter((item) => item.type === 'ALG');
          let AM = personal?.filter((item) => item.type === 'AM');
          let PSADA = personal?.filter((item) => item.type === 'PSADA');
          let GAT = personal?.filter((item) => item.type === 'GAT');

          let CAS_ = overall?.filter((item) => item.type === 'CAS');
          let IAI_ = overall?.filter((item) => item.type === 'IAI');
          let SEC_ = overall?.filter((item) => item.type === 'SEC');
          let EOI_ = overall?.filter((item) => item.type === 'EOI');

          let ALG_ = overall?.filter((item) => item.type === 'ALG');
          let AM_ = overall?.filter((item) => item.type === 'AM');
          let PSADA_ = overall?.filter((item) => item.type === 'PSADA');
          let GAT_ = overall?.filter((item) => item.type === 'GAT');

          setBarChartData((prev) => [
            {
              ...prev,
              data: [nvlNumber(CAS[0]?.per), nvlNumber(IAI[0]?.per), nvlNumber(SEC[0]?.per), nvlNumber(EOI[0]?.per)],
              gatePlusData: [nvlNumber(CAS_[0]?.per), nvlNumber(IAI_[0]?.per), nvlNumber(SEC_[0]?.per), nvlNumber(EOI_[0]?.per)],
            },
          ]);
          setBarChartData2((prev) => [
            {
              ...prev,
              data: [nvlNumber(ALG[0]?.per), nvlNumber(AM[0]?.per), nvlNumber(PSADA[0]?.per), nvlNumber(GAT[0]?.per)],
              gatePlusData: [nvlNumber(ALG_[0]?.per), nvlNumber(AM_[0]?.per), nvlNumber(PSADA_[0]?.per), nvlNumber(GAT_[0]?.per)],
            },
          ]);
        }
      }
      setLoading(false);
    };

    request.get(`/api/dsat/accuracy/category?uthSeq=${uthSeq}&userSeq=${userSeq}`, null, successHandler).catch((error) => {
      console.error('학생 대시보드 - 시험 유형 별 정답율 조회 API 실패', error);
      setLoading(false);
    });
  };

  /** 시험 일일 평균 점수 조회 API 요청 */
  const dailyAveragePoint = (firstTestDate, userSeq) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let dailyAverageList = response.result.dailyAverageList;

        /** dt 중복제거 */
        const dtList = removeDuplicates(dailyAverageList, 'dt');

        let firstDtDateIndex = -1;

        for (let i = 0; i < dtList.length; i++) {
          const firstDt = dtList[i];
          if (firstDt.data != null) {
            firstDtDateIndex = i;
            break;
          }
        }
      }
    };

    request
      .get(`/api/dsat/average/point/daily?userSeq=${userSeq}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 일일 평균 점수 조회 API 실패', error));
  };

  /** 학생 - 학원 연결 팝업 닫기 기능 */
  const handleModalHaveToLinkWithAcademyOrTutorClose = () => {
    setIsModalHaveToLinkWithAcademyOrTutor(false);
  };

  /** 시험 목록 불러오기 API 요청 */
  const getTestList = (childSeq = null) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const testList = response.result.testList;
        setTestList(testList);
      }
    };

    request
      .get(`/api/v2/student/dashboard/test-list${useLevels.isParent && childSeq ? `?childStudentSeq=${childSeq}` : ''}`, null, successHandler)
      .catch((error) => console.error('학생 대시보드 - 시험 목록 조회 API 실패', error));
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////

  /////////////////// React useEffect 영역 시작 ///////////////////////
  useEffect(() => {
    if (!useLevelsIsLoading) {
      if (useLevels.isParent) {
        // 학부모 계정 일 경우
        if (!childSeqState.isLoading && childSeqState.value) {
          // 자식 시퀸스 로딩이 끝났을 경우에만 실행
          getTestList(childSeqState.value);
        }
      } else {
        // 학부모 계정이 아닐 경우
        getTestList();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childSeqState, useLevels, useLevelsIsLoading]);

  useEffect(() => {
    if (testList.length > 0 && selectedUthSeq) {
      /** 현재 선택 된 시험 */
      const selectTest = testList.find((item) => {
        return String(item.uthSeq) === String(selectedUthSeq);
      });
      setSelectedTest(selectTest);
    }
  }, [selectedUthSeq, testList]);

  useEffect(() => {
    if (!useLevelsIsLoading && useLevels.isParent) {
      // 사용자 유형 얻어오기 준비가 끝났고 사용자 유형이 부모 일 경우 실행
      getChildSeq();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLevels, useLevelsIsLoading]);

  useEffect(() => {
    if (userInfo.ecSeq > 0) {
      console.log('학원 연결 됨');
      setIsModalHaveToLinkWithAcademyOrTutor(false);
    } else {
      console.log('학원 연결 안 됨');
      // 연결 된 학원 목록이 0개 이하인 경우, 모달 노출
      setIsModalHaveToLinkWithAcademyOrTutor(true);
    }

    if (userInfo.userLevel === USER_LEVELS.STUDENT) getTodayDate(userInfo.userSeq, userInfo.ecSeq);
    else getChildSeq();

    document.body.classList.add('main_page'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('main_page'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  /////////////////// React useEffect 영역 끝 ///////////////////////

  /////////////////// window 조작 영역 시작 //////////////////
  // added by psk 20240117 - 팝업창과 통신
  window.parentLoadData = () => {
    getTodayDate(userInfo.userSeq, userInfo.ecSeq, true);
  };

  window.tResultSeqRefUpdate = (tResultSeq) => {
    tResultSeqRef.current = tResultSeq;
  };

  /////////////////// window 조작 영역 끝 //////////////////

  return (
    <>
      <S.Wrap className='p_student_dashboard'>
        <VisualArea />
        <div className='com_center_wrap'>
          <VisualTextArea />
          <div className='dashboard_wrap'>
            <OuterBox className='com_container left'>
              {/*CALENDAR*/}
              <Box className='full'>
                <MiniCalendarSection />
              </Box>
            </OuterBox>
            <OuterBox className='com_container right'>
              <Box className='full upcoming_test_sec'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_time black'>&nbsp;</i>
                    Upcoming Test
                  </h2>
                </article>
                <UpcomingTestSection
                  setLoading={setLoading}
                  userExpectTestList={userExpectTestList}
                  setUserExpectTestList={setUserExpectTestList}
                  userInProgressTestList={userInProgressTestList}
                  setUserInProgressTestList={setUserInProgressTestList}
                  userOnlySubjectTestList={userOnlySubjectTestList}
                  setAlertLayerPopup={setAlertLayerPopup}
                  getTodayDate={getTodayDate}
                  tResultSeqRef={tResultSeqRef}
                />
              </Box>
              <Box className='full'>
                <article className='com_sec_tit'>
                  <h2 className='tit'>
                    <i className='svg_icon icon_time black'>&nbsp;</i>
                    SAT Timeline
                  </h2>
                </article>
                <TimeLineSection />
              </Box>
            </OuterBox>
            <OuterBox className='full'>
              <Box className='full'>
                <TestList testList={testList} setTestListAreaPosition={setTestListAreaPosition} />
              </Box>
            </OuterBox>
            <OuterBox className='full custom_flex'>
              <SelectedTitle selectedTest={selectedTest} setSelectedTest={setSelectedTest} testListAreaPosition={testListAreaPosition} />

              <div className='flex_row'>
                <Box className='scores_chart_section_box'>
                  <OverallAverageScore childSeqState={childSeqState} />
                </Box>
                <Box className='average_weekly_scores_box'>
                  <ScoreTrend currentClassTestData={[...testList].reverse()} />
                </Box>
              </div>
              <Box>
                <div className='com_container full'>
                  <AdvancedReportSection
                    selectedTest={selectedTest}
                    testResult={testResult}
                    barChartData={barChartData}
                    barChartData2={barChartData2}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    setLoading={setLoading}
                    setTestResult={setTestResult}
                    testList={testList}
                    childSeqState={childSeqState}
                  />
                </div>
              </Box>
            </OuterBox>
            <OuterBox className='com_container'>
              <DsatInfoArea />
            </OuterBox>
          </div>
        </div>
      </S.Wrap>
      {/* { printActive && <ReportCard setPrintActive={ setPrintActive } testResult={ testResult } barChartData={ barChartData } barChartData2={ barChartData2 } timePerQuestionData={ timePerQuestionData } filterValue={ filterValue } setFilterValue={ setFilterValue }/> } */}
      {tableListActive && (
        <PopTestList close={() => setTableListActive(false)} className={testResult.className} testTakenList={userTestHistoryList} clickEvent={getTestPointOX} />
      )}
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
      {loading && <LoadingBar />}

      {/* Modals area Start */}
      {isModalHaveToLinkWithAcademyOrTutor && <ModalHaveToLinkWithAcademyOrTutor close={handleModalHaveToLinkWithAcademyOrTutorClose} />}
      {/* Modals area End */}
    </>
  );
}

const S = {
  Wrap: styled.div`
    .full {
      width: 100%;
    }
    .flex_row {
      display: flex;
      gap: 0.75rem;
      height: 29rem;
    }
    .custom_flex {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }
    .scores_chart_section_box {
      width: calc(100% - 31.25rem);
    }
    .average_weekly_scores_box {
      width: 31.25rem;
    }
  `,
};
