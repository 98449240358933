//#region    ////////// packages
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
//#endregion ////////// packages

//#region    ////////// components
import Paging from 'layout/Paging'; //페이징
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod'; //팝업 달력
import TestList from 'components/com/tests/_components/_TestListLayer';
import CustomSelect from 'components/_common/inputs/CustomSelect';
import Layout from 'components/com/tests/_components/_common/Layout';
import ClickableTableRow from 'components/_common/table/TableRow';
//#endregion ////////// components

//#region    ////////// utils
import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import { useLocation } from 'react-router-dom';
//#endregion ////////// utils

/**
 * Set Tests : "학생 선택" 페이지
 * @description
 * 🔍 검색 키워드 - #com/tests/set/selection/student
 */
export default function TestsSetSelectStudentPage() {
  //#region    ////////////////// START --- 유저 정보 및 브라우저 Storage 관련 --- START ////////////////////
  const userInfo = request.tokenDecoder();
  //#endregion //////////////////  END  --- 유저 정보 및 브라우저 Storage 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const location = useLocation();
  const classSeq = location.state?.value;
  const stateSetTest = useSelector((state) => state.stateSetTests.setTests);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [studentInfo, setStudentInfo] = useState({
    list: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });
  const [searchInfo, setSearchInfo] = useState({
    setSearchField: 'user_name',
    searchKeyword: '',
    orderBy: 'MCH.reg_date DESC',
  });
  /** 체크 박스 */
  const [selectCheckBox, setSelectCheckBox] = useState(nvl(stateSetTest.selectedStudents) !== '' ? stateSetTest.selectedStudents : []);
  /** API 로딩 상태 */
  const [isLoading, setIsLoading] = useState(true);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const searchKeywordRef = useRef();
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  const getStudentList = () => {
    let pms = {
      userSeq: userInfo?.userSeq,
      managerPosition: userInfo?.managerPosition,
      orderBy: searchInfo.orderBy,
      searchKeyword: searchInfo.searchKeyword,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        let studentList = response.result.studentInfo;
        let totalCount = response.result.totalCount;

        setStudentInfo((prev) => {
          return { ...prev, list: studentList, totalCount: totalCount };
        });
      }
    };

    request
      .get(
        `/api/member/students?orderBy=${pms.orderBy}&searchField=user_name&searchKeyword=${encodeURIComponent(pms.searchKeyword)}&classSeq=${nvl(stateSetTest.selectedClass.classSeq) !== '' ? stateSetTest.selectedClass.classSeq : 0}`,
        null,
        successHandler
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const studentListReload = () => {
    setStudentInfo((prev) => ({ ...prev, currentPage: 1 }));
    getStudentList();
  };
  /** StudentList Row 선택 핸들러 */
  const handleStudentSelect = (selectedStudent) => {
    const selectCheckBoxSeq = selectCheckBox.map((item) => item.userSeq);

    if (selectCheckBoxSeq.includes(selectedStudent.userSeq)) {
      setSelectCheckBox(selectCheckBox.filter((item) => item.userSeq !== selectedStudent.userSeq));
    } else {
      setSelectCheckBox([...selectCheckBox, selectedStudent]);
    }
  };
  /** 체크박스 상태 값에 모든 체크박스가 선택되어 있는지 확인 */
  const getIsAllChecked = () => {
    if (nvl(studentInfo.list) !== '') {
      /** uthSeq 배열 생성 */
      const checkedSeq = studentInfo.list?.map((item) => item.userSeq);
      /** 모든 요소가 포함되어 있는지 확인 */
      return checkedSeq.every((seq) => selectCheckBox.map((item) => item.userSeq).includes(seq));
    }
  };
  /** 체크 박스 전체 선택 동작 함수 */
  const handleAllCheck = (checked) => {
    if (studentInfo.list && studentInfo.list.length > 0) {
      if (checked) {
        const idArray = [];
        for (let i = 0; i < studentInfo.list.length; i++) {
          idArray.push(studentInfo.list[i]);
        }
        const updatedSelectedKeys = [...selectCheckBox.concat(idArray)];
        // eslint-disable-next-line no-undef
        const setList = [...new Set(updatedSelectedKeys)];

        setSelectCheckBox(setList);
      } else {
        setSelectCheckBox([]);
      }
    }
  };
  const nextPageMove = (value) => {
    setStudentInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearchInfo((prev) => {
        return { ...prev, searchKeyword: e.target.value };
      });
    }
  };

  const keywordSearchDo = (e) => {
    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: nvl(searchKeywordRef.current.value) };
    });
  };

  const limitSelect = (value) => {
    setStudentInfo((prev) => {
      return { ...prev, numPerPage: value };
    });
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    getStudentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo.currentPage]);

  useEffect(() => {
    studentListReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo.numPerPage, searchInfo.searchKeyword]);

  useEffect(() => {
    if (classSeq !== undefined && selectCheckBox.length === 0) setSelectCheckBox(studentInfo.list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentInfo]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <Layout selectedStudents={selectCheckBox}>
      <article className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_ol black'>&nbsp;</i>Student List
        </h2>
      </article>
      <article className='com_list'>
        <section className='top'>
          <article className='search_area full'>
            {/* <PopupCalendarPeriod tit="Select Date"/> */}
            <div className='search'>
              <input type='text' placeholder='Enter keywords' className='input search_area_input' ref={searchKeywordRef} onKeyUp={handleOnKeyPress} />
              <button className='com_btn m blue btn_search' onClick={keywordSearchDo}>
                <i className='svg_icon icon_search blue before'></i>
                SEARCH
              </button>
            </div>
            <CustomSelect
              options={[
                { label: '10 line', value: 10 },
                { label: '25 line', value: 25 },
                { label: '50 line', value: 50 },
                { label: '100 line', value: 100 },
              ]}
              onSelect={limitSelect}
            />
          </article>
        </section>
        <table className='list'>
          <colgroup>
            <col width='56px' />
            <col width='66px' />
            <col width='104px' />
            <col width='64' />
            <col />
            <col width='115' />
            <col width='137' />
            <col width='137' />
          </colgroup>
          <thead>
            <tr>
              <th>
                <input
                  type='checkbox'
                  className='com_chk'
                  id='chkAll'
                  checked={studentInfo.list && studentInfo.list.length > 0 ? getIsAllChecked() : false}
                  onChange={(e) => handleAllCheck(e.target.checked)}
                />
                <label htmlFor='chkAll'>&nbsp;</label>
              </th>
              <th>No.</th>
              <th>Name</th>
              <th>Grade</th>
              <th>Email</th>
              <th>Class</th>
              <th>Test Scheduled</th>
              <th>Test Taken</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan='8'>Loading...</td>
              </tr>
            ) : studentInfo.list && studentInfo.list.length > 0 ? (
              studentInfo.list
                .slice((studentInfo.currentPage - 1) * studentInfo.numPerPage, studentInfo.currentPage * studentInfo.numPerPage)
                .map((item, index) => (
                  <ClickableTableRow
                    item={item}
                    index={index}
                    isSelected={selectCheckBox.map((item) => item.userSeq).includes(item.userSeq)}
                    onSelect={handleStudentSelect}
                    key={`row_${index}`}>
                    <td>{studentInfo.totalCount - studentInfo.numPerPage * (studentInfo.currentPage - 1) - index}</td>
                    <td className='tit_field alignL'>
                      <p className='ellipsis'>{item.userName}</p>
                    </td>
                    <td>{item.studentGrade}</td>
                    <td>
                      <p className='ellipsis'>{item.userEmail}</p>
                    </td>
                    <td>{item.className}</td>
                    <td>
                      <TestList item={item.testHistoryInfo} status={'E'} />
                    </td>
                    <td>
                      <TestList item={item.testHistoryPastInfo} status={'P'} />
                    </td>
                  </ClickableTableRow>
                ))
            ) : (
              <tr>
                <td colSpan='8'>No results. Please make sure you have searched correct keyword.</td>
              </tr>
            )}
          </tbody>
        </table>
        <Paging
          totalCount={studentInfo.totalCount}
          currentPage={studentInfo.currentPage}
          numPerPage={studentInfo.numPerPage}
          pagePerBlock={studentInfo.pagePerBlock}
          nextPageMove={nextPageMove}
        />
      </article>
    </Layout>
  );
}
