import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import LocalStorage from 'utils/LocalStorage.utils';

import { ReactComponent as ImgSvgIconAlert } from 'assets/img/svg/icon_alert_triangle_01.svg';
import { ReactComponent as ImgSvgIconCheck } from 'assets/img/svg/icon_radio_chk.svg';

/** 시험 "지적 재산권 보호 동의" 페이지 */
export default function ExamAcknowledgementPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sendParams = LocalStorage.getItemJsonParse('sendParams');

  /** 동의 여부 */
  const [isAgreed, setIsAgreed] = useState(false);

  /** 다음 버튼 동작 함수 */
  const handleButtonNext = () => {
    dispatch({
      type: 'setExamInfo',
      payload: { fieldName: 'uthSeq', data: sendParams?.uthSeq },
    });
    dispatch({
      type: 'setExamInfo',
      payload: { fieldName: 'testName', data: sendParams?.testName },
    });
    dispatch({
      type: 'setExamInfo',
      payload: { fieldName: 'testStartDate', data: sendParams?.testDate },
    });

    navigate('/exam/dsat/tutorial', {
      state: {
        uthSeq: sendParams?.uthSeq,
        testName: sendParams?.testName,
        testDate: sendParams?.testDate,
      },
      replace: true,
    });
  };

  return (
    <S.Wrap className='com_popup pop_tutorial active'>
      <section className='pop_body'>
        <S.AcknowledgementBox>
          <h2>
            <ImgSvgIconAlert />
            Acknowledgement
          </h2>
          <p>
            All provided questions are protected as GATEPLUS’s intellectual property. Each generated test and PDF include a <strong>unique identifier</strong>{' '}
            and <strong>account code enabling</strong> the tracking of unauthorized reproduction or distribution. Unauthorized copy, use or distribution may
            result in criminal prosecution for intellectual property infringement.
          </p>

          <div className='check_agree_wrap'>
            <input
              type='checkbox'
              id='agree'
              checked={isAgreed}
              onChange={() => {
                setIsAgreed((current) => !current);
              }}
            />
            <label htmlFor='agree' title='동의'>
              <div className='check_box'>
                <ImgSvgIconCheck />
              </div>
              <p>
                I understand that the following mock test is GATEPLUS’s property and that any attempt to copy, use, or distribute its content may result in
                criminal prosecution.
              </p>
            </label>
          </div>
        </S.AcknowledgementBox>
      </section>
      <section className='pop_footer'>
        <article className='btn_area'>
          {/* <button className="btn" onClick={() => navigate(-1)}>Back</button> */}
          <button
            type='button'
            title='페이지 이동'
            className={`btn ${isAgreed ? '' : 'disabled'}`}
            onClick={() => (isAgreed ? handleButtonNext() : null)}
            disabled={!isAgreed}>
            Next
          </button>
        </article>
      </section>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    &.pop_tutorial {
      .pop_body {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .pop_footer {
        .btn_area {
          .btn {
            &:disabled,
            &.disabled {
              background-color: #999999;
            }
          }
        }
      }
    }
  `,
  AcknowledgementBox: styled.div`
    padding: 2.25rem;
    border-radius: 1.25rem;
    background-color: #ffffff;
    width: 23.125rem;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    box-sizing: content-box;
    h2 {
      color: #111111;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 600;
      font-size: 1.25rem;
      padding-bottom: 0.75rem;
      svg {
        width: 1.5rem;
      }
    }
    p {
      color: #505050;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    .check_agree_wrap {
      margin-top: 11.25rem;
      input {
        display: block;
        overflow: hidden;
        width: 0;
        height: 0;
        visibility: hidden;
        & + label {
          color: #111111;
          font-weight: 400;
          font-size: 0.875;
          line-height: 1.25rem;
          display: flex;
          align-items: flex-start;
          gap: 0.5rem;
          cursor: pointer;
          p {
            flex: auto;
          }
          .check_box {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 auto;
            width: 1.125rem;
            height: 1.125rem;
            border: 1px solid #d7dadf;
            border-radius: 0.125rem;
            background-color: #ffffff;
            svg {
              color: #ffffff;
              width: 0.625rem;
            }
          }
        }
        &:checked {
          & + label {
            .check_box {
              border-color: #111111;
              background-color: #111111;
            }
          }
        }
      }
    }
  `,
};
