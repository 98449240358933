import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { nvl } from 'utils/Common.utils';
import request from 'utils/Request.utils';

//component
import Header from 'components/user/_components/layouts/_Header';
import CustomAlert from 'components/_common/alerts/CustomAlert';
import MainBox from 'components/user/_components/layouts/MainBox';
import FormItemWrap from 'components/user/_components/common/forms/FormItemWrap';
import Label from 'components/user/_components/common/forms/Label';
import Input from 'components/user/_components/common/forms/Input';
import ErrorText from 'components/user/_components/styles/ErrorText';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';

/** "비밀 번호 재설정" 페이지 */
export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const resetId = query.get('reset_id');

  const alertAttributeValue = {
    visible: false,
    alertMessage: '',
    alertType: 'alert',
    returnValue: () => {},
    id: '',
  };
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  /** Form 유효성 검사 상태 (중복 검사 및 매치 여부 등) */
  const [validityForm, setValidityForm] = useState({
    isPasswordLengthEight: false, // 비밀 번호 8자 이상 인지 여부
    isPasswordSpecial: false, // 비밀 번호 유효성 형식에 맞는지 여부
    isPasswordMismatch: false, // 입력 한 비밀 번호 확인과 동일 한지 여부
  });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    setFocus,
  } = useForm({
    defaultValues: {
      userPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  const closeCustomAlert = () => {
    setAlertLayerPopup((prev) => {
      return { ...alertAttributeValue };
    });
  };

  const returnAlertValue = (value) => {
    if (nvl(value) === 'OK') {
    }
  };

  /** 비밀 번호 유효성 검사 */
  const passwordCheck = (data) => {
    if (nvl(data.userPassword).length >= 8) {
      setValidityForm((prev) => {
        return { ...prev, isPasswordLengthEight: true };
      });
    } else {
      setValidityForm((prev) => {
        return { ...prev, isPasswordLengthEight: false };
      });
    }
    const patternSpecial = /[\W_]/;
    const patternNum = /[0-9]/;
    if (patternSpecial.test(nvl(data.userPassword)) && patternNum.test(nvl(data.userPassword))) {
      setValidityForm((prev) => {
        return { ...prev, isPasswordSpecial: true };
      });
    } else {
      setValidityForm((prev) => {
        return { ...prev, isPasswordSpecial: false };
      });
    }
    if (nvl(data.userPassword) === nvl(data.confirmPassword) && nvl(data.confirmPassword).length !== 0) {
      setValidityForm((prev) => {
        return { ...prev, isPasswordMismatch: true };
      });
    } else {
      setValidityForm((prev) => {
        return { ...prev, isPasswordMismatch: false };
      });
    }
  };

  /** 비밀번호 재설정 API 요청 */
  const updatePW = (data) => {
    let pms = {
      userPasswd: data.userPassword,
      userEmail: resetId,
      userId: resetId,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        navigate('complete');
      } else if (response.code === 444) {
        setAlertLayerPopup((prev) => {
          return {
            ...prev,
            visible: true,
            alertMessage: "You've already changed your password.",
          };
        });
      }
    };

    request.put(`/api/common/reset/passwd`, pms, successHandler);
  };

  /** Form 제출 핸들러 (react-hook-form 용) */
  const onValid = (data) => {
    if (validityForm.isPasswordLengthEight && validityForm.isPasswordSpecial && validityForm.isPasswordMismatch) {
      updatePW(data);
    } else {
      console.error('비밀번호 형식 확인하슈!');
    }
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass

    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <>
      <S.Wrap>
        <MainBox>
          <Header>
            <p className='txt'>The door to educational aspiration</p>
          </Header>
          <section className='top-text-area'>
            <h4 className='tit'>Reset your password</h4>
            <p className='txt'>You need to reset your password. Please type in new password and sign in with your new password.</p>
          </section>
          <form onSubmit={handleSubmit(onValid)}>
            <FormItemWrap>
              <Label htmlFor='userPassword'>Password</Label>
              <Input
                id='userPassword'
                type='password'
                register={register('userPassword', {
                  required: 'Please enter your password',
                  onChange: (e) => {
                    passwordCheck(watch());
                  },
                })}
                required
                isError={errors.userPassword || !validityForm.isPasswordLengthEight || !validityForm.isPasswordSpecial}
              />
              {errors.userPassword && <ErrorText>{errors.userPassword.message}</ErrorText>}
            </FormItemWrap>
            <FormItemWrap>
              <Label htmlFor='confirmPassword'>Conform Password</Label>
              <Input
                id='confirmPassword'
                type='password'
                register={register('confirmPassword', { required: 'Please enter your password', onChange: (e) => passwordCheck(watch()) })}
                required
                isError={errors.confirmPassword || !validityForm.isPasswordLengthEight || !validityForm.isPasswordSpecial || !validityForm.isPasswordMismatch}
              />
              {errors.confirmPassword && <ErrorText>{errors.confirmPassword.message}</ErrorText>}
            </FormItemWrap>
            <div className='com_password_message'>
              <p className={`check ${validityForm.isPasswordLengthEight ? 'valid' : ''}`} id='lengthMessage'>
                Must be over 8 characters
              </p>
              <p className={`check ${validityForm.isPasswordSpecial ? 'valid' : ''}`} id='characterMessage'>
                Must have a number and a special character (!, @ , ?)
              </p>
              <p className={`check ${validityForm.isPasswordMismatch ? 'valid' : ''}`} id='matchMessage'>
                Passwords must match
              </p>
            </div>
            <PrimaryButtonArea>
              <PrimaryButton
                type='submit'
                title='다음 페이지'
                disabled={!(validityForm.isPasswordLengthEight && validityForm.isPasswordSpecial && validityForm.isPasswordMismatch)}>
                Next
              </PrimaryButton>
            </PrimaryButtonArea>
          </form>
        </MainBox>
      </S.Wrap>
      {alertLayerPopup.visible ? (
        <CustomAlert
          onClose={closeCustomAlert}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={returnAlertValue}
        />
      ) : null}
    </>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .top-text-area {
    text-align: left;
    color: #7f8998;
    padding-bottom: 1.5rem;
    .tit {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .txt {
      margin-top: 0.5rem;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
`;
