import React from 'react';
import styled from 'styled-components';

/** "Ap" - "Question Bank" - "Create Test" 페이지에서 사용 할 "Input" 요소 스타일 용 컴포넌트 */
export default function InputStyleWrap02({ children, placeholder = '', ...rest }) {
  return <S.Wrap>{children}</S.Wrap>;
}

const S = {
  Wrap: styled.div`
    input {
      width: 3.5rem;
      border: 1px solid #d2dbe2;
      &:not(:placeholder-shown) {
        border-color: #0068bd;
      }
      &:read-only {
        background-color: #f4f4f4;
        &:not(:placeholder-shown) {
          border-color: #d2dbe2;
        }
      }
    }
  `,
};
