//#region    ////////// packages
import { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import Desmos from 'desmos';
//#endregion ////////// packages

//#region    ////////// assets
import ImgBtnMove from 'assets/img/exam/btn_move.png';
import ImgBtnMoveOn from 'assets/img/exam/btn_move_on.png';
import ImgExpand from 'assets/img/exam/icon_expand.png';
import ImgCollapse from 'assets/img/exam/icon_collapse.png';
//#endregion ////////// assets

/** 계산기 팝업 컴포넌트 */
export default function CalculatorPopup({ isShowCalculator, setIsShowCalculator }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [isPopupExpand, setIsPopupExpand] = useState(false);
  const [calculatorState, setCalculatorState] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  // 드래그 상태 관리
  const [dragState, setDragState] = useState({
    activeDrags: 0,
    deltaPosition: { x: 0, y: 0 },
    controlledPosition: { x: -400, y: 200 },
  });
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const nodeRef = useRef(null);
  const calculatorRef = useRef(null);
  const desmosAreaRef = useRef(null);
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const updateCalculatorSize = () => {
    if (!desmosAreaRef.current) return;

    desmosAreaRef.current.style.width = isPopupExpand ? '820px' : '410px';
    desmosAreaRef.current.style.height = isPopupExpand ? '645px' : '580px';

    if (calculatorRef.current) {
      calculatorRef.current.resize();
    }
  };
  const restoreCalculatorState = () => {
    if (!calculatorRef.current || !calculatorState?.length) return;

    calculatorState.forEach((expression) => {
      calculatorRef.current.setExpression({
        id: expression.id,
        color: expression.color,
        latex: expression.latex ?? undefined,
      });
    });
  };

  const onStart = () => setDragState({ ...dragState, activeDrags: ++dragState.activeDrags });
  const onStop = () => setDragState({ ...dragState, activeDrags: --dragState.activeDrags });
  const dragHandlers = { onStart, onStop };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  // 계산기 초기화 및 설정
  useEffect(() => {
    if (!isShowCalculator) return;

    const desmosArea = document.createElement('div');
    desmosAreaRef.current = desmosArea;

    // 계산기 인스턴스 생성
    const calculator = Desmos.GraphingCalculator(desmosArea);
    calculatorRef.current = calculator;

    // 크기 설정
    updateCalculatorSize();

    // 이전 상태 복원
    restoreCalculatorState();

    // DOM에 계산기 추가
    const container = document.getElementById('desmosCalculator');
    if (container) {
      container.innerHTML = '';
      container.appendChild(desmosArea);
    }

    // 상태 변경 감지
    calculator.observeEvent('change', () => {
      const currentState = calculator.getState();
      const newList =
        currentState.expressions.list?.map((item, index) => ({
          ...item,
          id: `g_${index + 1}`,
        })) || [];
      setCalculatorState(newList);
    });

    return () => {
      calculator.destroy();
      if (container) {
        container.innerHTML = '';
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowCalculator]);
  // 크기 변경 시 계산기 크기 업데이트
  useEffect(() => {
    updateCalculatorSize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupExpand]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  if (!isShowCalculator) return null;
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <Draggable nodeRef={nodeRef} handle='strong' {...dragHandlers}>
      <S.PopupContainer ref={nodeRef} $isExpanded={isPopupExpand}>
        <S.PopupHeader onMouseDown={() => setIsDragging(true)} onMouseUp={() => setIsDragging(false)}>
          <div className='calculator_title'>Calculator</div>
          <S.MoveIndicator>
            <img src={ImgBtnMove} alt='move' style={{ opacity: isDragging ? 0 : 1 }} />
            <img src={ImgBtnMoveOn} alt='move' style={{ opacity: isDragging ? 1 : 0 }} />
          </S.MoveIndicator>
          <div className='button_area'>
            <button
              className='expand_button'
              onClick={(e) => {
                e.stopPropagation();
                setIsPopupExpand(!isPopupExpand);
              }}>
              <img src={isPopupExpand ? ImgCollapse : ImgExpand} alt='expand' />
              {isPopupExpand ? 'Collapse' : 'Expand'}
            </button>
            <button
              className='svg_icon btn_pop_close white'
              onClick={(e) => {
                e.stopPropagation();
                setIsShowCalculator(false);
              }}>
              &nbsp;
            </button>
          </div>
        </S.PopupHeader>

        <S.PopupBody>
          <div id='desmosCalculator' className='pop_body' style={{ overflow: 'hidden' }} draggable='false' dragstart='false'>
            {/* 계산기 영역 */}
          </div>
        </S.PopupBody>
      </S.PopupContainer>
    </Draggable>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  PopupContainer: styled.div`
    background-color: white;
    position: fixed;
    left: 15px; // 원본 코드 치수
    top: 75px; // 원본 코드 치수
    z-index: 1000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
  `,

  PopupHeader: styled.strong`
    background: #1e1e1e;
    color: #fff;
    height: 44px;
    padding: 0 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .calculator_title {
      font-size: 16px;
      font-weight: 700;
    }

    .button_area {
      display: flex;
      align-items: center;
      gap: 20px;

      .expand_button {
        display: flex;
        gap: 6px;
        align-items: center;
        color: white;
        font-weight: 700;
        transition: opacity 0.2s;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    cursor: grab;
    :active {
      cursor: grabbing;
    }
    user-select: none;
  `,
  MoveIndicator: styled.div`
    width: 31px;
    height: 25px;
    pointer-events: none;
    position: relative;

    img {
      transition: opacity 0.2s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `,

  PopupBody: styled.div`
    border: 1px solid #1e1e1e;
    border-top: none;
  `,
};
