import { useState, useRef, useCallback, useEffect, useMemo } from 'react';

/**
 * 피드백 툴팁 동작을 제어하는 훅
 *
 * @param {string} text 툴팁 텍스트
 * @param {string} type 툴팁 타입
 * @param {boolean} initialVisible 초기 표시 여부
 * @param {boolean} hoverEnabled 호버시 툴팁 표시 여부
 * @param {number} autoHideDelay 자동 숨김 시간(ms)
 * @returns {Object} 툴팁 제어 객체
 */
export function useFeedbackTooltip({ text = '', type = 'default', initialVisible = false, hoverEnabled = true, autoHideDelay = 3000 } = {}) {
  const initialControl = useMemo(
    () => ({
      text,
      type,
      isVisible: initialVisible,
      hoverEnabled,
      autoHideDelay,
    }),
    [text, type, initialVisible, hoverEnabled, autoHideDelay]
  );

  const [control, setControl] = useState(initialControl);
  const timerRef = useRef(null);

  // 컴포넌트 언마운트 시 모든 타이머 정리
  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  // 툴팁 표시 함수
  const showTooltip = useCallback(
    (text, options = {}) => {
      if (timerRef.current) clearTimeout(timerRef.current); // 이전 타이머가 있다면 제거 ( 방어 코드 )

      // 1. 기존 상태에 새로운 텍스트와 옵션을 넣어 랜더링
      setControl((prev) => ({ ...prev, text, isVisible: true, ...options }));

      // 2. 자동 숨김 시간 동안 컴포넌트 표시 후 숨김
      timerRef.current = setTimeout(() => {
        setControl((prev) => ({ ...prev, isVisible: false }));

        // 3. 숨김 후 자연스러운 전환을 위해 초기 상태로 되돌리기
        setTimeout(() => {
          setControl(initialControl);
        }, 300);

        timerRef.current = null;
      }, autoHideDelay);
    },
    [autoHideDelay, initialControl]
  );

  return {
    showTooltip,
    control,
  };
}
