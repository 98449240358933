import 'styles/css/exam.css';

//#region    ////////// packages
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import CommonLoadingAnimation from 'components/_common/CommonLoadingAnimation';
import ModuleOver from 'components/ap/_components/examLayout/common/ModuleOver';
import ExamBody from 'components/ap/_components/examLayout/examBody/ExamBody';
import ExamFooter from 'components/ap/_components/examLayout/examFooter/ExamFooter';
import ExamHeader from 'components/ap/_components/examLayout/examHeader/ExamHeader';
import ExitModal from 'components/ap/_components/examLayout/examModal/ExitModal';
import HelpModal from 'components/ap/_components/examLayout/examModal/HelpModal';
import ExamUpload from './_components/sections/upload/ExamUpload';
//#endregion ////////// components

//#region    ////////// utils & hooks
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
//#endregion ////////// utils & hooks

/** AP 시험 페이지 */
export default function ExamApPage() {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  // 문제 입력 방법 가이드 팝업
  const [isDirectionsPopup, setIsDirectionsPopup] = useState(false);
  // 시험 종료 팝업
  const [isExitModal, setIsExitModal] = useState(false);
  // 도움말 팝업
  const [isHelpModal, setIsHelpModal] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState({
    isLoading: false,
    text: '',
  });
  // 시험 정보
  const [stateExamInfo, setStateExamInfo] = useState({
    isUnderFiveMinutes: false, // 남은 시간이 5분 미만일 때
    currentNum: 0, // 현재 문제 번호
    totalQuestion: 0, // 총 문제 수
    sectionName: '', // 섹션 이름
    partName: '',
    isReviewPopupOpen: false, // 리뷰 팝업 오픈 여부
    showModuleOver: false, // 모듈 종료 팝업
    isTimeOver: false, // 타이머 종료
  });
  // 문제 정보 리스트
  const [questionInfo, setQuestionInfo] = useState([]);
  // 현재 문제 정보
  const [currentQuestionInfo, setCurrentQuestionInfo] = useState({});
  // 현재 시험의 소스 정보 ( 그룹화 )
  const [questionSourceGroup, setQuestionSourceGroup] = useState({
    sourceDirections: [{ directionId: 0, directionContent: '' }],
    sourceContent: [{ sourceId: 0, sourceContent: '' }],
  });
  // 하이라이트 관련 상태 추가
  const [isAnnotation, setIsAnnotation] = useState(false); // 하이라이트 모드
  const [isDragged, setIsDragged] = useState(false); // 텍스트 드래그 상태
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const prevCurrentNumRef = useRef(stateExamInfo.currentNum);
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- API 호출 --- START ////////////////////
  /** 진행중이던 문제 서버에 업데이트 요청 ( 페이지 닫기(새로고침, 뒤로가기 등) 시 업데이트 ) */
  const updateQuestionContent = () => {
    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
    const currentTestKey = `apTest_${uthSeq}`;
    // testInfo
    const testInfo = localStorage?.getItemJsonParse(currentTestKey);

    if (!testInfo) {
      console.warn(`키 없음: ${currentTestKey}`);
      return;
    }

    const currentQuestion = testInfo.questionList[prevCurrentNumRef.current - 1];
    if (!currentQuestion) {
      console.warn('no question.');
      return;
    }

    const studentTestResultSeq = currentQuestion?.studentTestResultSeq;
    const params = currentQuestion;

    const successHandler = (response) => {
      if (response.code === 200) {
        console.log('성공');
      } else {
        console.error('Error in API response:', response);
      }
    };

    request.apPut(`/api/v1/student/test/${uthSeq}/question/${studentTestResultSeq}`, params, successHandler);
  };
  //#endregion //////////////////  END  --- API 호출 ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 하이라이트 토글 버튼 handler */
  const handleToggleAnnotation = () => {
    // 이미 하이라이트 모드인 경우 끔
    if (isAnnotation) {
      setIsAnnotation(false);
      setIsDragged(false); // 드래그 상태도 리셋
    }
    // 하이라이트 모드가 아니고 텍스트가 드래그된 상태인 경우 켬
    else if (isDragged) {
      setIsAnnotation(true);
    }
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    const preventContextMenu = (e) => e.preventDefault();
    window.addEventListener('contextmenu', preventContextMenu);

    const handleBeforeUnload = (e) => {
      updateQuestionContent(); // 페이지 닫기(새로고침, 뒤로가기 등) 시 업데이트
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      updateQuestionContent();
      window.removeEventListener('contextmenu', preventContextMenu);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!stateExamInfo.showModuleOver) {
      const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
      const testInfo = localStorage.getItemJsonParse(`apTest_${uthSeq}`);
      setStateExamInfo((prevState) => ({
        ...prevState,
        sectionName: testInfo.sectionName,
        partName: testInfo.partName,
        remainTime: testInfo.totalTime,
        currentNum: testInfo.currentNum,
        totalQuestion: testInfo.totalQuestionCount,
      }));

      /** 소스 컨텐츠 파싱 */
      const questionList = testInfo.questionList.map((question) => ({
        ...question,
        sourceContent: question.sourceContent ? JSON.parse(question.sourceContent) : [],
      }));

      /** 소스 direction 정보 - 내용이 정확히 일치하는 소스만 추가 */
      const questionDirectionsInfo = questionList.reduce((acc, question) => {
        if (question.sourceDirections !== '' && !acc.find((item) => item.directionContent === question.sourceDirections)) {
          return [...acc, { directionId: acc.length + 1, directionContent: question.sourceDirections }];
        }
        return acc;
      }, []);

      /** 소스 content 정보 - 내용이 정확히 일치하는 소스만 추가 */
      const questionContentInfo = questionList.reduce((acc, question) => {
        if (question.sourceContent.length === 0) return acc; // 소스 컨텐츠가 없는 경우 무시
        question.sourceContent.forEach((questionSourceContent) => {
          if (!acc.find((item) => item.sourceContent === questionSourceContent.sourceContent)) {
            acc = [...acc, { sourceId: acc.length + 1, sourceContent: questionSourceContent.sourceContent }];
          }
        });
        return acc;
      }, []);

      // 소스 그룹 정보 업데이트
      setQuestionSourceGroup({ sourceDirections: questionDirectionsInfo, sourceContent: questionContentInfo });

      // 문제 정보 업데이트 (소스 그룹 정보 추가)
      setQuestionInfo(
        questionList.map((question) => ({
          ...question,
          questionSourceInfo: {
            sourceId: question.sourceContent.map((source) => questionContentInfo.find((item) => item.sourceContent === source.sourceContent)?.sourceId),
            directionId: questionDirectionsInfo.find((item) => item.directionContent === question.sourceDirections)?.directionId,
          },
        }))
      );
    }
  }, [stateExamInfo.showModuleOver]);
  useEffect(() => {
    if (questionInfo.length > 0 && stateExamInfo.currentNum > 0 && stateExamInfo.currentNum <= questionInfo.length) {
      setCurrentQuestionInfo(questionInfo[stateExamInfo.currentNum - 1]);
    }
  }, [questionInfo, stateExamInfo.currentNum]);
  useEffect(() => {
    if (stateExamInfo.currentNum !== prevCurrentNumRef.current) {
      updateQuestionContent();
    }

    prevCurrentNumRef.current = stateExamInfo.currentNum;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateExamInfo.currentNum]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <>
      {stateExamInfo.showModuleOver ? (
        <ModuleOver showModuleOver={stateExamInfo.showModuleOver} setStateExamInfo={setStateExamInfo} />
      ) : (
        <S.ExamContainer>
          <div className={`com_popup pop_exam active ${isDirectionsPopup ? 'direction_on' : ''}`} onContextMenu={(e) => e.preventDefault()}>
            <div className='pop_container'>
              <ExamHeader
                stateExamInfo={stateExamInfo}
                setStateExamInfo={setStateExamInfo}
                isDirectionsPopup={isDirectionsPopup}
                setIsDirectionsPopup={setIsDirectionsPopup}
                setIsExitModal={setIsExitModal}
                setIsHelpModal={setIsHelpModal}
                // 하이라이트 관련 props 전달
                isAnnotation={isAnnotation}
                isDragged={isDragged}
                handleToggleAnnotation={handleToggleAnnotation}
              />

              {stateExamInfo.isTimeOver ? (
                <ExamUpload currentQuestionInfo={currentQuestionInfo} />
              ) : (
                <ExamBody
                  stateExamInfo={stateExamInfo}
                  setStateExamInfo={setStateExamInfo}
                  currentQuestionInfo={currentQuestionInfo}
                  setQuestionInfo={setQuestionInfo}
                  setLoadingInfo={setLoadingInfo}
                  // 하이라이트 관련 props
                  isAnnotation={isAnnotation}
                  setIsAnnotation={setIsAnnotation}
                  isDragged={isDragged}
                  setIsDragged={setIsDragged}
                  // 그룹 소스 관련 props
                  questionSourceGroup={questionSourceGroup}
                  setQuestionSourceGroup={setQuestionSourceGroup}
                />
              )}

              <ExamFooter stateExamInfo={stateExamInfo} setStateExamInfo={setStateExamInfo} questionInfo={questionInfo} />

              {/*완료 5분전 alert*/}
              {/* <FiveMinutesAlert /> */}
            </div>
          </div>
          {isExitModal && <ExitModal setIsExitModal={setIsExitModal} />}
          {isHelpModal && <HelpModal setIsHelpModal={setIsHelpModal} />}

          {loadingInfo.isLoading && <CommonLoadingAnimation size='15rem' text={loadingInfo.text} className='loading_container' />}
        </S.ExamContainer>
      )}
    </>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  ExamContainer: styled.div`
    .loading_container {
      position: fixed;
      .common_loading_animation_text {
        font-size: 1%.5;
      }
    }
  `,
};
