import styled from 'styled-components';

import { ReactComponent as ArrowIconSelect } from 'assets/img/svg/icon_arrow_02.svg';

/**
 * ap/com/score/grading/submission 페이지의 "Raw 점수 영역" 의 각 "Section" 영역 컴포넌트
 * @param {object} sectionInfo : 시험 섹션 정보
 * @param {boolean} isGatePlus : 게이트플러스를 통해서 진행한 시험인지 여부
 * @param {ReactNode} dropdownChildren : 드롭다운 영역에 보여 줄 자식 요소 JSX
 * @param {function} onToggleDropdown : 드롭다운 버튼 토글 핸들러
 * @param {boolean} isShowDropdown : 드롭다운 영역 노출 여부 ( sessionStorage 에서 해당 값 조회 )
 */
export default function SectionArea({ sectionInfo, isShowDropdown, onToggleDropdown, dropdownChildren, isGatePlus = true, ...rest }) {
  /** 드롭다운 버튼 토글 핸들러 */
  const handleToggleDropdown = () => {
    if (isGatePlus) onToggleDropdown();
  };
  return (
    <S.Wrap className={`${isShowDropdown ? 'show' : ''} ${isGatePlus ? '' : 'not_gateplus'}`} {...rest}>
      <div className='outerWrap'>
        <button type='button' title='' onClick={handleToggleDropdown} className='cover_area'>
          <div className='inner_wrap'>
            <h3 className='section_name'>Section {sectionInfo.sectionNum}</h3>
            {isGatePlus && (
              <>
                <div className='score_area'>
                  <h4>Raw Score</h4>
                  <div className='score'>
                    <strong className='value'>{sectionInfo.correctRawScore != null ? sectionInfo.correctRawScore : '-'}</strong> / {sectionInfo.totalRawScore}
                  </div>
                </div>
                <i className='arrow_icon_wrap'>
                  <ArrowIconSelect />
                </i>
              </>
            )}
          </div>
        </button>
        {isGatePlus && <div className={`dropdown_area`}>{dropdownChildren}</div>}
      </div>
      {!isGatePlus && (
        <div className='guide'>
          Only Section 1 of this test has been taken through GATE+. <br /> Please type in the scaled score of Section 2, and the combined total scaled score of
          this test on the right.
        </div>
      )}
    </S.Wrap>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  Wrap: styled.div`
    width: 45.8rem;

    .outerWrap {
      background: #fff;
      overflow: hidden;
      border-radius: 0.625rem;
      border: 1px solid #c5ccd2;
    }

    .cover_area {
      width: 100%;
      height: 3.75rem;
      box-sizing: border-box;
      padding: 0 1.25rem;
      .inner_wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
      }

      .section_name {
        padding: 1rem 1.25rem 1rem 0;
        color: #111;
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.75rem;
      }
      .score_area {
        flex: 1 0 auto;
        width: auto;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 1rem;
        padding: 0 1.25rem;
        color: #111;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        .score {
          color: #505050;
          .value {
            color: #111;
            font-weight: 500;
          }
        }
      }
      .arrow_icon_wrap {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 1rem;
          color: #999999;
          transition: 0.2s;
        }
      }
    }

    .dropdown_area {
      background-color: #eaeaea;
      overflow: hidden;
      transition: 0.2s;
      height: 0;
      padding-top: 0;

      .part_area + .part_area {
        .inner_wrap {
          border-top: 1px solid #c5ccd2;
        }
      }
    }

    & > .guide {
      color: #999;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
      padding: 0.5rem;
    }

    &.show {
      .outerWrap > .cover_area {
        .arrow_icon_wrap {
          svg {
            transform: rotateZ(180deg);
          }
        }
      }
      .outerWrap > .dropdown_area {
        height: auto;
        padding-top: 0.625rem;
      }
    }

    &.not_gateplus {
      & > .cover_area {
        cursor: default;
      }
    }
  `,
};
