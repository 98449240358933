import Lottie from 'lottie-react';
import examLoadingAnimation from 'assets/lottie/exam_loading_animation.json';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import request from 'utils/Request.utils';

import { MathJax } from 'better-react-mathjax';

import { nvl } from 'utils/Common.utils';
import { USER_LEVELS } from 'utils/constants';

import RwM1TestQuestion from './_components/testView_question/RwM1TestQuestion';
import RwM2EasyTestQuestion from './_components/testView_question/RwM2EasyTestQuestion.jsx';
import RwM2HardTestQuestion from './_components/testView_question/RwM2HardTestQuestion.jsx';
import MathM1TestQuestion from './_components/testView_question/MathM1TestQuestion.jsx';
import MathM2EasyTestQuestion from './_components/testView_question/MathM2EasyTestQuestion.jsx';
import MathM2HardTestQuestion from './_components/testView_question/MathM2HardTestQuestion.jsx';
import { getBrowser } from 'utils/getBrowser';
import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import ModuleNum from './_components/common/ModuleNum';
import PdfViewerHeader from './_components/layouts/PdfViewerHeader';
import ModuleDirectionsReadingAndWriting from './_components/moduleDirections/ReadingAndWriting';
import ModuleDirectionsReadingAndWritingModule2Easy from './_components/moduleDirections/ReadingAndWritingModule2Easy';
import ModuleDirectionsReadingAndWritingModule2Hard from './_components/moduleDirections/ReadingAndWritingModule2Hard';
import ModuleDirectionsMath from './_components/moduleDirections/Math';
import ModuleDirectionsMathModule2Easy from './_components/moduleDirections/MathModule2Easy';
import ModuleDirectionsMathModule2Hard from './_components/moduleDirections/MathModule2Hard';
import FirstSection from './_components/layouts/FirstSection';
import PrintPage from './_components/common/PrintPage';
import TestPdfViewer from './_components/layouts/TestPdfViewer';
import WarningModal from './_components/common/modals/WarningModal';

// #pdf
/** PDF Viewer 페이지 */
export default function TestViewPage() {
  const isSafari = getBrowser() === 'Safari';
  const userInfo = request.tokenDecoder();
  const [searchParams, setSearchParams] = useSearchParams();

  const query = new URLSearchParams(window.location.search);
  const viewTest = query.get('viewTest');
  const subject = query.get('subject');
  const diff = query.get('diff');
  const type = query.get('type');
  const mode = query.get('actionMode');

  const isStudentUserLevel = type === 'student' || userInfo.userLevel === USER_LEVELS.STUDENT || userInfo.userLevel === USER_LEVELS.PARENT;

  /** 정답 표시 여부 (searchParams "showAnswer" 값이 "Y" 거나 null 이라면 정답 표시 하기) */
  const [isShowAnswer, setShowAnswer] = useState(searchParams.get('showAnswer') === 'Y' || searchParams.get('showAnswer') === null);
  const [isSingleCol, setIsSingleCol] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isMathJaxCasting, setIsMathJaxCasting] = useState(false);
  const [isMathJaxSorting, setIsMathJaxSorting] = useState(false);
  const [testList, setTestList] = useState();
  /** 영어 시험 난이도 */
  const [rwTestDiff, setRwTestDiff] = useState([]);
  /** 수학 시험 난이도 */
  const [mathTestDiff, setMathTestDiff] = useState([]);
  /** 문제를 가로로 배치 할 것인지, 세로로 배치 할 것인지 여부 */
  const [isHorizontal, setIsHorizontal] = useState(true);
  /** 경고 모달 노출 여부 */
  const [isShowWarningModal, setIsShowWarningModal] = useState(true);

  useEffect(() => {
    document.body.classList.add('blank_page'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('blank_page'); //다른 페이지에선 removeClass
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.addEventListener('contextmenu', (e) => e.preventDefault()); // 마우스 우클릭 방지
  // document.addEventListener('contextmenu', function(e) { e.preventDefault(); }); // 이거도 마우스 우클릭 방지

  /** HTML을 파싱하여 텍스트만 추출하는 함수 */
  function extractTextFromHTML(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  /** 정답, 시도한 답을 비교해서 boolean return */
  const answerCheck = (correctAnswer, tryAnswer) => {
    const correctAnswers = correctAnswer.split(',').map((item) => item.trim());
    const normalizedTryAnswer = tryAnswer;

    return correctAnswers.includes(normalizedTryAnswer);
  };

  /** 배열을 순환하며, 각 문제의 글자 수를 파악하고, 레이아웃을 결정하는 함수 */
  const groupArray = (array) => {
    if (array.length === 0) {
      return;
    }

    const result = [];
    let i = 0;
    /** 2 Column 배치 시, 분리 선을 위한 홀/짝 선택용 변수 */
    let isOdd = false;
    /** 너무 긴 문제의 갯수 */
    let tooLongCount = 0;

    while (i < array.length) {
      const passageLength = extractTextFromHTML(array[i].passage).length;
      const questionLength = extractTextFromHTML(array[i].question).length;
      const multiple_choice_items1Length = extractTextFromHTML(array[i].multiple_choice_items1).length;
      const multiple_choice_items2Length = extractTextFromHTML(array[i].multiple_choice_items2).length;
      const multiple_choice_items3Length = extractTextFromHTML(array[i].multiple_choice_items3).length;
      const multiple_choice_items4Length = extractTextFromHTML(array[i].multiple_choice_items4).length;

      /** 문제의 전체 글자 수 */
      const textTotalLength =
        passageLength +
        questionLength +
        multiple_choice_items1Length +
        multiple_choice_items2Length +
        multiple_choice_items3Length +
        multiple_choice_items4Length;
      if (i === array.length - 1) {
        // 마지막 문제의 경우 이면서,
        let newObj = array[i];
        if (array.length % 2 === 1) {
          // 모듈 문제 갯수 중 너무 길어서 1column으로 표시 된 문제를 제외한 문제의 갯수가 홀수 일 경우
          // 요소에서 사용 할 새로운 Key를 추가
          newObj.lastOdd = true;
        }
        result.push(newObj);
      } else {
        // 마지막 문제가 아닐 경우
        if (condition(textTotalLength)) {
          // 글자 수가 너무 많을 경우, 해당 요소만 1column으로 표시
          let newObj = array[i];
          // 글자 수가 너무 많을 경우, 요소에서 사용 할 새로운 Key를 추가
          newObj.isTooLong = true;

          if (i % 2 === 0) {
            // 너무 긴 문제가 홀수 일 경우
            // 해당 요소의 이전 문제도 1column으로 표시
            let newObj = array[i + 1];

            if (newObj) {
              // 다음 문제가 존재 할 경우
              newObj.isTooLong = true;
              newObj.rightElement = false;
            }
          } else {
            // 너무 긴 문제가 짝수 일 경우
            // 해당 요소의 다음 문제도 1column으로 표시
            let newObj = array[i - 1];

            if (newObj) {
              // 이전 문제가 존재 할 경우
              newObj.isTooLong = true;
              newObj.rightElement = false;
            }
          }

          result.push(newObj);

          // isOdd = !isOdd;
          tooLongCount += 1;
        } else {
          if ((i % 2 === 0) === isOdd && !array[i].isTooLong) {
            // 짝수 문제이면서, 너무 긴 문제가 아닐 경우
            let newObj = array[i];
            // 두 개씩 짝 지을 경우 가운데에 구분선을 그리기 위하여, 오른쪽 에 위치하는 요소 표시
            newObj.rightElement = true;
            result.push(newObj);
          } else {
            result.push(array[i]);
          }
        }
      }

      i += 1;
    }

    /** 두 개씩 짝 지을 것인지 아닌지 구분 할 조건 */
    function condition(textTotalLength) {
      /** 너무 긴 문제인가? */
      const isTooLong = textTotalLength > 1500;

      return isTooLong;
    }

    return result;
  };

  /** 영어 시험 모듈 1 문제 리스트 (해당하는 조건에 맞는 문제 필터링) */
  const rwM1Test = (testList && testList?.filter((item) => item.module_subject === 'E' && item.module_num === '1')) || [];
  /** 영어 시험 모듈 2 Easy 문제 리스트 (해당하는 조건에 맞는 문제 필터링) */
  const rwM2EasyTest =
    (testList &&
      testList[0].set_subject !== 'M' &&
      testList[0].set_difficulty !== 'H' &&
      diff !== 'H' &&
      testList?.filter((item) => item.module_subject === 'E' && item.module_num === '2' && item.module_difficulty === 'E')) ||
    [];
  /** 영어 시험 모듈 2 Hard 문제 리스트 (해당하는 조건에 맞는 문제 필터링) */
  const rwM2HardTest =
    (testList &&
      testList[0].set_subject !== 'M' &&
      testList[0].set_difficulty !== 'E' &&
      diff !== 'E' &&
      testList?.filter((item) => item.module_subject === 'E' && item.module_num === '2' && item.module_difficulty === 'H')) ||
    [];
  /** 수학 시험 모듈 1 문제 리스트 (해당하는 조건에 맞는 문제 필터링) */
  const mathM1Test = (testList && testList[0].set_subject !== 'E' && testList.filter((item) => item.module_subject === 'M' && item.module_num === '1')) || [];
  /** 수학 시험 모듈 2 Easy 문제 리스트 (해당하는 조건에 맞는 문제 필터링) */
  const mathM2EasyTest =
    (testList &&
      testList[0].set_subject !== 'E' &&
      testList[0].set_difficulty !== 'H' &&
      diff !== 'H' &&
      testList.filter((item) => item.module_subject === 'M' && item.module_num === '2' && item.module_difficulty === 'E')) ||
    [];
  /** 수학 시험 모듈 2 Hard 문제 리스트 (해당하는 조건에 맞는 문제 필터링) */
  const mathM2HardTest =
    (testList &&
      testList[0].set_subject !== 'E' &&
      testList[0].set_difficulty !== 'E' &&
      diff !== 'E' &&
      testList.filter((item) => item.module_subject === 'M' && item.module_num === '2' && item.module_difficulty === 'H')) ||
    [];

  /** Column 배치 스위치 (1 or 2) 핸들러 */
  const switchTestCol = (test) => {
    const sortTest = test?.sort((a, b) => a.question_order - b.question_order) || [];
    return isSingleCol ? sortTest : groupArray(sortTest);
  };

  useEffect(() => {
    let mathJaxTimer;
    let mathJaxExtraTimer;

    /** 문제 얻어오기 API 요청 */
    const getTest = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          // 데이터 처리 로직
          let testInfo = response.result.testInfo;
          let testDiff = response.result.testDiff;

          if (nvl(testDiff) !== '') {
            setRwTestDiff(testDiff.filter((i) => i.subject === 'E')[0]?.difficulty);
            setMathTestDiff(testDiff.filter((i) => i.subject === 'M')[0]?.difficulty);
          }

          testInfo != null &&
            testInfo.length > 0 &&
            testInfo.map((outerItem, outerIndex) => {
              const parsedInfo = JSON.parse(outerItem?.customSetQuestionInfo);
              return setTestList(parsedInfo);
            });

          setIsFetching(false);
          setIsMathJaxCasting(true);

          // 초기 5초 타이머
          mathJaxTimer = setTimeout(() => {
            setIsMathJaxCasting(false);
            setIsMathJaxSorting(true);

            // 추가 15초 타이머
            mathJaxExtraTimer = setTimeout(() => {
              setIsMathJaxSorting(false);
            }, 15000);
          }, 5000);
        }
      };

      request.get(
        `/api/dsat/test?${isStudentUserLevel ? 'uthSeqStr' : 'customSeqStr'}=${encodeURIComponent(viewTest)}&actionMode=${mode}`,
        null,
        successHandler
      );
    };

    getTest();

    return () => {
      clearTimeout(mathJaxTimer);
      clearTimeout(mathJaxExtraTimer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTypeset = () => {
    setTimeout(() => {
      setIsMathJaxSorting(false);
    }, 1000);
  };

  return testList ? (
    <TestPdfViewer className={`p_test_view ${isSafari ? 'safari-mode' : ''}`} isShowAnswer={isShowAnswer}>
      <PdfViewerHeader
        testList={testList}
        isSingleCol={isSingleCol}
        setIsSingleCol={setIsSingleCol}
        isStudentUserLevel={isStudentUserLevel}
        isShowAnswer={isShowAnswer}
        setShowAnswer={setShowAnswer}
        isSafari={isSafari}
        isHorizontal={isHorizontal}
        setIsHorizontal={setIsHorizontal}
      />
      {/*정답이 안보이려면 addClass none*/}
      <div className={`container ${isSingleCol ? 'single_column' : ''}`}>
        <MathJax onTypeset={handleTypeset}>
          <FirstSection testList={testList} />

          {subject !== 'M' && (
            <>
              <ModuleDirectionsReadingAndWriting testList={testList} />
              <PrintPage isSingleCol={isSingleCol} isHorizontal={isHorizontal}>
                {/*문제*/}
                {switchTestCol(rwM1Test)?.map((item, idx) => {
                  return (
                    <RwM1TestQuestion key={`RwM1TestSingleColQuestion-${idx}`} userLevel={isStudentUserLevel} rwTestDiff={rwTestDiff} item={item} idx={idx} />
                  );
                })}
              </PrintPage>
              <ModuleDirectionsReadingAndWritingModule2Easy testList={testList} rwTestDiff={rwTestDiff} />
              <PrintPage isSingleCol={isSingleCol} isHorizontal={isHorizontal}>
                {/*문제*/}
                {switchTestCol(rwM2EasyTest)?.map((item, idx) => {
                  return (
                    <RwM2EasyTestQuestion
                      key={`RwM2EasyTestSingleColQuestion-${idx}`}
                      userLevel={isStudentUserLevel}
                      rwTestDiff={rwTestDiff}
                      item={item}
                      idx={idx}
                    />
                  );
                })}
              </PrintPage>
              <ModuleDirectionsReadingAndWritingModule2Hard testList={testList} rwTestDiff={rwTestDiff} />
              <PrintPage isSingleCol={isSingleCol} isHorizontal={isHorizontal}>
                {switchTestCol(rwM2HardTest)?.map((item, idx) => {
                  return (
                    <RwM2HardTestQuestion
                      key={`RwM2HardTestSingleColQuestion-${idx}`}
                      userLevel={isStudentUserLevel}
                      rwTestDiff={rwTestDiff}
                      item={item}
                      idx={idx}
                    />
                  );
                })}
              </PrintPage>
            </>
          )}

          {subject !== 'E' && (
            <>
              <ModuleDirectionsMath testList={testList} />
              <PrintPage isSingleCol={isSingleCol} isHorizontal={isHorizontal}>
                {/*문제*/}
                {switchTestCol(mathM1Test)?.map((item, idx) => {
                  return (
                    <MathM1TestQuestion
                      key={`MathM1TestSingleColQuestion-${idx}`}
                      userLevel={isStudentUserLevel}
                      item={item}
                      idx={idx}
                      answerCheck={answerCheck}
                    />
                  );
                })}
              </PrintPage>

              <ModuleDirectionsMathModule2Easy testList={testList} mathTestDiff={mathTestDiff} />
              <PrintPage isSingleCol={isSingleCol} isHorizontal={isHorizontal}>
                {/*문제*/}
                {switchTestCol(mathM2EasyTest)?.map((item, idx) => {
                  return (
                    <MathM2EasyTestQuestion
                      key={`MathM2EasyTestSingleColQuestion-${idx}`}
                      userLevel={isStudentUserLevel}
                      item={item}
                      idx={idx}
                      mathTestDiff={mathTestDiff}
                      answerCheck={answerCheck}
                    />
                  );
                })}
              </PrintPage>
              <ModuleDirectionsMathModule2Hard testList={testList} mathTestDiff={mathTestDiff} />
              <PrintPage isSingleCol={isSingleCol} isHorizontal={isHorizontal}>
                {/*문제*/}
                {switchTestCol(mathM2HardTest)?.map((item, idx) => {
                  return (
                    <MathM2HardTestQuestion
                      key={`MathM2HardTestSingleColQuestion-${idx}`}
                      userLevel={isStudentUserLevel}
                      item={item}
                      idx={idx}
                      mathTestDiff={mathTestDiff}
                      answerCheck={answerCheck}
                    />
                  );
                })}
              </PrintPage>
            </>
          )}
        </MathJax>
      </div>
      {isFetching && (
        <SimpleModalBase>
          <Lottie animationData={examLoadingAnimation} style={{ width: '26.5rem', height: '26.5rem' }} />
        </SimpleModalBase>
      )}
      {!isFetching && (isMathJaxCasting || isMathJaxSorting) && (
        <SimpleModalBase>
          <Lottie animationData={examLoadingAnimation} style={{ width: '26.5rem', height: '26.5rem' }} />
        </SimpleModalBase>
      )}

      {isShowWarningModal && <WarningModal setShowModal={setIsShowWarningModal} />}

      {/* 프린트 경고 모달 - MathJax 변환 대기 로직 추가로 주석 처리해둠 ( 24/7/5 ) */}
      {/* {showPrintModal && <PrintWarningModal setShowModal={setShowPrintModal} />} */}
      {/* {showBtnPrintModal && <BtnPrintWarningModal handlePrint={handlePrintWarningBtn} />} */}
    </TestPdfViewer>
  ) : null;
}
