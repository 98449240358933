import styled from 'styled-components';
import CommonNumberInput from 'components/_common/inputs/CommonNumberInput';

/** AP 시험 세팅 팝업에 사용되는 인풋 */
export default function ApSettingInput({ value, onChange }) {
  return <S.ApSettingInput defaultValue={value} handleCallback={(newValue) => onChange(newValue)} maxNumber={999} minNumber={1} />;
}

// --- --- --- Styled Components --- --- --- //
const S = {
  ApSettingInput: styled(CommonNumberInput)`
    border: 1px solid #d7dadf;
    border-radius: 4px;
    width: 8.75rem;
    max-height: 2.125rem;
    padding: 0 10px;
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-size: 14px;
  `,
};
