import React from 'react';
import styled from 'styled-components';

/** "Ap" - "Question Bank" - "Create Test" 페이지의 "Advanced Setting" 내부 박스 안에서 사용 할 "Label" 요소 스타일 용 컴포넌트 */
export default function LabelTextForInnerBox({ children, ...rest }) {
  return <S.Wrap {...rest}>{children}</S.Wrap>;
}

const S = {
  Wrap: styled.div`
    color: #111;
    font-family: Roboto;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  `,
};
