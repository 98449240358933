import { useEffect, useState } from 'react';
import styled from 'styled-components';
import SectionScaledScoreArea from './_components/SectionArea';
import TotalScaledScoreArea from './_components/TotalSectionArea';

/** ap/com/score/grading/submission 페이지의 "전체 등급 점수 영역" 컴포넌트 */
export default function ScaledScoreAsrea({ sectionInfo }) {
  const [totalScore, setTotalScore] = useState({ correctRawScore: 0, totalRawScore: 0 });

  useEffect(() => {
    if (sectionInfo && sectionInfo.length > 0) {
      const totalCorrect = sectionInfo.reduce((acc, curr) => acc + (curr.correctRawScore || 0), 0);
      const totalRaw = sectionInfo.reduce((acc, curr) => acc + (curr.totalRawScore || 0), 0);

      setTotalScore({ correctRawScore: totalCorrect, totalRawScore: totalRaw });
    }
  }, [sectionInfo]);

  return sectionInfo && sectionInfo[0].sectionSeq === 0 ? (
    'sectionInfo 데이터 없음'
  ) : (
    <S.Wrap>
      {sectionInfo && sectionInfo.map((item, index) => <SectionScaledScoreArea key={index} sectionInfo={item} />)}
      <TotalScaledScoreArea totalScore={totalScore} sectionInfo={sectionInfo} />
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.section`
    width: 22.375rem;
    height: fit-content;
    border-radius: 0.625rem;
    border: 1px solid #c5ccd2;
    background: #f8f8f9;
    overflow: hidden;
    .section_scaled_score_area {
      & + .section_scaled_score_area {
        .inner_wrap {
          border-top: 1px solid #c5ccd2;
        }
      }
    }
  `,
};
