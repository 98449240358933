// Packages
// Assets
import ImgCompletion from 'assets/img/exam/icon_completion.png';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';
// Functions

/** 시험 완료 페이지 컴포넌트 */
export default function ExamCompletionPage() {
  const { state } = useLocation();
  const { giveUp } = state || false; // state에서 giveUp 값을 추출

  useEffect(() => {
    // 우클릭 방지
    const preventContextMenu = (e) => e.preventDefault();
    window.addEventListener('contextmenu', preventContextMenu);

    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;

    // 중도 포기 후 시험 완료 페이지로 이동 시
    if (giveUp) {
      localStorage.removeItem('currentTest');
      localStorage.removeItem(`apTest_${uthSeq}`);
      localStorage.removeItem('firstRender');
      return;
    }
    // 시험 상태 업데이트
    updateTestStatus(uthSeq);

    const params = {
      questions: localStorage.getItemJsonParse(`apTest_${uthSeq}`).questionList,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        // console.log(response.result);
        // 로컬 스토리지 초기화
        localStorage.removeItem('currentTest');
        // localStorage에서 'apTest_'로 시작하는 모든 키를 찾아서 삭제
        Object.keys(window.localStorage).forEach((key) => {
          if (key.startsWith('apTest_')) {
            localStorage.removeItem(key);
          }
        });
        localStorage.removeItem('firstRender');
      }
    };

    request.apPut('/api/v1/student/test', params, successHandler); // 문제 내용으로 업데이트

    return () => {
      window.removeEventListener('contextmenu', preventContextMenu);
    };
    // eslint-disable-next-line
  }, []);

  // Return to Dashboard 버튼
  const handleReturnDashboard = () => {
    // 부모창 새로고침 (시험 완료 시 부모창 새로고침)
    if (window.opener) {
      try {
        window.opener.location.reload();
      } catch (error) {
        console.error('부모창 새로고침 실패:', error);
      }
    } else {
      console.error('부모창이 없습니다.');
    }

    // 창 닫기
    window.close();
  };

  // 시험 상태 업데이트
  const updateTestStatus = (uthSeq) => {
    const params = {
      testStatus: 'P',
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        if (window.opener && typeof window.opener.getUpcomingTestList === 'function') {
          window.opener.getUpcomingTestList();
        } else {
          console.error('부모창의 함수를 찾을 수 없습니다.');
        }
      }
    };

    request.apPut(`/api/v1/student/test/${uthSeq}/status`, params, successHandler);
  };

  return (
    <div className='com_popup type_normal pop_completion active'>
      <div className='pop_container'>
        <section className='pop_body'>
          <div className='tit'>You're All Finished!</div>
          <div className='box'>
            <img src={ImgCompletion} className='icon' alt='Completion' />
            <p className='txt'>
              Return to <b>Student Dashboard</b> to view your score.
            </p>
            <p className='txt'>When you see this page on test day, you'll know your scores have been submitted.</p>
            <article className='btn_area'>
              <button className='btn' onClick={handleReturnDashboard}>
                Return to Dashboard
              </button>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
}
