import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import request from 'utils/Request.utils';
import { AP_PARAMS } from 'components/ap/_utils/constants/queryParamsString';

// 🧰 기획 요청으로 1phase에서는 미사용
// import { ReactComponent as ImgSvgIconCheck } from 'assets/img/svg/icon/icon_check_square_01.svg';
// import { ReactComponent as ImgSvgIconHelp } from 'assets/img/svg/icon/icon_help_circle_01.svg';
import { ReactComponent as ImgSvgIconLogout } from 'assets/img/svg/icon/icon_log_out_01.svg';

// import DropDownButtonWrap from './_components/DropDownButtonWrap';

import ApExamHeaderBase from 'components/ap/_components/examLayout/examHeader/ApExamHeaderBase';
import ExitModal from '../../common/modals/ExitModal';

/** AP Review Header 컴포넌트 */
export default function ReviewHeader({ isLoadingProps = false, sectionName = '', partName = '' }) {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  /** 현재 선택 된 UTH 시퀀스 */
  const queryCurUthSeq = JSON.parse(getObfuscatedValue(AP_PARAMS.UTH_SEQ.KEY));

  /** 현재 페이지에 필요한 쿼리 파라미터 확인. 하나라도 없다면 true 반환 */
  const checkParamError = (paramKeyArray = []) => {
    const isError = paramKeyArray.find((item) => {
      const param = JSON.parse(getObfuscatedValue(item));
      if (!param) {
        console.error(`Score Review 페이지에 필요한 ${item}을/를 확인해 주세요.`);
        return true;
      }
      return false;
    });
    return isError;
  };

  if (checkParamError([AP_PARAMS.UTH_SEQ.KEY])) {
    return;
  }
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  /** 현재 시험 리포트에 해당하는 유저 정보 (API 응답 데이터) */
  const [reportUserInfo, setReportUserInfo] = useState();
  /** 로딩 상태 */
  const [isLoading, setIsLoading] = useState(isLoadingProps);
  /** "Exit" 모달 노출 여부 */
  const [isViewExitModal, setIsViewExitModal] = useState(false);

  /** 나가기 버튼 핸들러 */
  const handleExit = () => {
    // setIsViewExitModal(true);
    window.close();
  };

  useEffect(() => {
    if (queryCurUthSeq) {
      /** 시험 리포트의 유저 정보 불러오기 API 요청 */
      const getReportUserInfo = ({ queryCurUthSeq }) => {
        setIsLoading(true);
        const successHandler = (response) => {
          if (response.code === 200) {
            const data = response.result.reportUserInfo;
            setReportUserInfo(data);
          }
        };

        request
          .apGet(`/api/v1/academy/score/report-user?uthSeq=${queryCurUthSeq}`, null, successHandler)
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };

      getReportUserInfo({ queryCurUthSeq });
    }
  }, [queryCurUthSeq]);

  if (isLoading) {
    return 'Loading ...';
  }

  const leftSection = (
    <S.LeftArea>
      <div className='txt_wrap'>
        <h1>Review</h1>
        <div>
          {sectionName}, {partName}
        </div>
      </div>
    </S.LeftArea>
  );
  const centerSection = (
    <S.CenterArea>
      <div className='txt_wrap'>
        <div className='title'>{reportUserInfo?.testName}</div>
        <div className='sub_title'>{reportUserInfo?.subject}</div>
      </div>
    </S.CenterArea>
  );
  const rightSection = (
    <S.RightArea>
      <button type='button' title='Close this browser tab' onClick={handleExit} className='button_exit'>
        <ImgSvgIconLogout />
        <span>Return to results</span>
      </button>
    </S.RightArea>
  );

  return (
    <>
      <ApExamHeaderBase leftSection={leftSection} centerSection={centerSection} rightSection={rightSection} />
      {isViewExitModal && <ExitModal setShowModal={setIsViewExitModal} />}
    </>
  );
}

const S = {
  LeftArea: styled.div`
    width: 100%;
    padding-left: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: start;
    .txt_wrap {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.5rem;

      color: #000;
      font-family: Roboto;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.75rem;
      h1 {
        font-size: 1.375rem;
        font-weight: 600;
        line-height: 1.375rem;
      }
    }
  `,

  CenterArea: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .txt_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      color: #000;
      text-align: center;
      font-family: Roboto;

      .title {
        font-size: 1.375rem;
        font-weight: 600;
        line-height: 1.375rem;
      }
      .sub_title {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.75rem;
      }
    }
  `,

  RightArea: styled.div`
    width: 100%;
    padding-right: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: end;

    .button_exit {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.4375rem;

      color: #000;
      font-family: Roboto;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;

      svg {
        width: 1.25rem;
      }
    }
  `,
};
