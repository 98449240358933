import React from 'react';
import styled from 'styled-components';

/** 로그인/회원가입 페이지의 컨데이너 박스 컴포넌트 */
export default function MainBox({ children, ...rest }) {
  return <S.Wrap {...rest}>{children}</S.Wrap>;
}

const S = {};

S.Wrap = styled.div`
  width: 28.75rem;
  max-width: 28.75rem;
  padding: 3.125rem 2.5rem;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  box-sizing: border-box;
  //margin: 0 auto;
  margin-bottom: 3.125rem; //align-self: stretch;
  @media screen and (max-width: 1024px) {
    margin-bottom: 0; //align-self: stretch;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
    padding: 2.5rem 1rem;
  }
`;
