//#region    ////////// packages
import { useRef, useState } from 'react';
//#endregion ////////// packages

//#region    ////////// components
import MiniReview from '../../test-editor/_components/MiniReview';
import UI_ExamFooter from 'components/_common/exam/UI_ExamFooter';
//#endregion ////////// components

//#region    ////////// utils & hooks
import useOutsideClick from 'hooks/useOutsideClick';
//#endregion ////////// utils & hooks

//#region    ////////// constants
import { EDITOR_CONSTANT } from '../../test-editor/_constant';
//#endregion ////////// constants

//#region    ////////// assets
import { ReactComponent as ImageSvgIconArrowBlack02 } from 'assets/img/svg/icon_arrow_02.svg';
//#endregion ////////// assets

export default function ApExamFooter({ editorState, currentPartQuestion, handleQuestionNavigation, isNavigating }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** "Mini Review" 영역 노출 여부 */
  const [isShowMiniReview, setIsShowMiniReview] = useState(false);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const miniReviewRef = useRef(null); // MiniReview 외부 클릭용 Ref
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  /** 현재 위치하고 있는 문제 인덱스 */
  const currentQuestionIndex = editorState[EDITOR_CONSTANT.currentIndex];
  //#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** "Mini Review" 여닫기 버튼 핸들러 */
  const handleShowMiniReview = () => {
    setIsShowMiniReview((current) => !current);
  };

  /** "Back" 버튼 클릭 핸들러 */
  const handleBack = () => {
    handleQuestionNavigation(currentQuestionIndex - 1);
    const containers = document.querySelectorAll('.custom_scroll'); // 모든 .custom_scroll 요소 선택
    containers.forEach((container) => {
      container.scrollTo({
        top: 0, // 최상단으로 이동
        behavior: 'smooth', // 부드럽게 이동
      });
    });
  };

  /** "Next" 버튼 클릭 핸들러 */
  const handleNext = () => {
    handleQuestionNavigation(currentQuestionIndex + 1);
    const containers = document.querySelectorAll('.custom_scroll'); // 모든 .custom_scroll 요소 선택
    containers.forEach((container) => {
      container.scrollTo({
        top: 0, // 최상단으로 이동
        behavior: 'smooth', // 부드럽게 이동
      });
    });
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 --- START ////////////////////
  useOutsideClick(miniReviewRef, () => setIsShowMiniReview(false), isShowMiniReview); // MiniReview - 외부 클릭시 닫기
  //#endregion //////////////////  END  --- 커스텀 훅 ---  END  ////////////////////

  const rightContent = (
    <>
      <button disabled={currentQuestionIndex <= 0 || isNavigating} onClick={handleBack}>
        Back
      </button>
      <button disabled={currentQuestionIndex >= editorState[EDITOR_CONSTANT.currentPart].number_of_questions - 1 || isNavigating} onClick={handleNext}>
        Next
      </button>
    </>
  );

  // 로딩 중일 때는 MiniReview 클릭도 비활성화
  const centerContent = (
    <div ref={miniReviewRef}>
      <button type='button' title='Open/Close' onClick={handleShowMiniReview} className='question_nav_btn' disabled={isNavigating}>
        <p>
          Question <span>{currentQuestionIndex + 1}</span> of <span>{editorState[EDITOR_CONSTANT.currentPart]?.number_of_questions}</span>
        </p>
        <span className='question_nav_btn_arrow'>
          <ImageSvgIconArrowBlack02 />
        </span>
      </button>
      {isShowMiniReview && !isNavigating && (
        <MiniReview
          currentPartQuestion={currentPartQuestion}
          editorState={editorState}
          setIsShowMiniReview={setIsShowMiniReview}
          handleQuestionNavigation={handleQuestionNavigation}
        />
      )}
    </div>
  );

  return <UI_ExamFooter leftContent={null} centerContent={centerContent} rightContent={rightContent} />;
}
