import ApExamHeaderBase from 'components/ap/_components/examLayout/examHeader/ApExamHeaderBase';
import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';

import DirectionsPopupBtn from 'components/ap/_components/examLayout/examHeader/_components/directionsPopup/DirectionsPopupBtn';

/** ap/question-bank/test-preview 에서 사용될 header */
export default function TestPreviewHeader({ headerInfo }) {
  const { getObfuscatedValue } = useObfuscatedSearchParam();
  const queryTestSeq = getObfuscatedValue('test_seq');
  const queryTestStatus = getObfuscatedValue('status');
  const navigate = useNavigate();

  const handleEdit = () => {
    if (window.confirm('If you edit the test, any ongoing student tests will be interrupted. Once the test is published, students can resume their tests.')) {
      navigate(`/ap/question-bank/test-editor?test_seq=${obfuscateUrlParam(queryTestSeq)}&status=${obfuscateUrlParam('publish')}`);
    }
  };

  const handleExit = () => {
    if (queryTestStatus === 'publish') {
      navigate('/ap/question-bank');
    } else {
      window.close();
    }
  };

  const leftSection = (
    <S.LeftArea>
      <p className='module_title'>{`${headerInfo.sectionName}${headerInfo.partName !== '' ? `: ${headerInfo.partName}` : ''}`}</p>
      <DirectionsPopupBtn isShowDirectionsPopup={false} setIsShowDirectionsPopup={() => {}} directionContent={''} />
    </S.LeftArea>
  );

  const rightSection = (
    <S.RightArea>
      {queryTestStatus === 'publish' && (
        <button className='button' onClick={handleEdit}>
          Edit
        </button>
      )}
      <button type='button' title='' className='button btn_primary' onClick={handleExit}>
        Exit
      </button>
    </S.RightArea>
  );

  return <ApExamHeaderBase leftSection={leftSection} rightSection={rightSection} />;
}

const S = {
  LeftArea: styled.div`
    padding-left: 4.375rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

    .module_title {
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.375rem;
    }
  `,

  RightArea: styled.div`
    padding-right: 4.375rem;

    .button {
      font-size: 1.125rem;
      font-weight: 500;
      padding: 0.5rem 1.25rem;
      border-radius: 6.25rem;
      cursor: pointer;
      line-height: 1.75rem;
      border: 1px solid #767676;
      margin: 0 0.5rem;
      &.btn_primary {
        background-color: #ffde88;
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        filter: grayscale(100%);
      }
    }
  `,
};
