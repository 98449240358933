import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const GAP_SIZE = 2;

/** 수식 이미지 및 컴포넌트를 비율에 맞게 보여주는 컨테이너 컴포넌트 */
export default function EquationsPanelContentContainer({ contents, containerHeight }) {
  const [itemHeights, setItemHeights] = useState([]);
  const containerRef = useRef(null);

  // 이미지 실제 높이 계산 및 저장
  useEffect(() => {
    const calculateHeights = async () => {
      // 이미지 URL만 필터링
      const imageItems = contents.filter((item) => typeof item === 'string');

      const promises = imageItems.map(
        (src, index) =>
          new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
              resolve({
                src,
                height: img.height, // 실제 이미지 높이값
              });
            };
            img.src = src;
          })
      );

      const results = await Promise.all(promises);

      // 단위 추출 (rem, px 등)
      const heightValue = parseFloat(containerHeight);
      const unit = containerHeight.replace(/[0-9.]/g, '');

      // 컨테이너 높이에서 간격(gap) 제외한 높이
      const gapTotal = (contents.length - 1) * GAP_SIZE;
      const availableHeight = heightValue - gapTotal / 16; // px gap을 rem으로 변환

      // 높이 정보를 담을 객체 맵 생성
      const heightsMap = new Map();

      // 이미지 항목들의 높이값 합 계산
      const actualHeights = results.map((r) => r.height);
      const totalHeight = actualHeights.reduce((sum, height) => sum + height, 0);

      // 이미지 항목들의 높이 비율에 따라 공간 배분
      results.forEach((result, idx) => {
        const scaledHeight = `${((availableHeight * result.height) / totalHeight).toFixed(2)}${unit}`;
        heightsMap.set(result.src, scaledHeight);
      });

      // 컴포넌트 항목은 기본값으로 설정
      const defaultHeight = `${(availableHeight / contents.length).toFixed(2)}${unit}`;
      const finalHeights = contents.map((item) => (typeof item === 'string' ? heightsMap.get(item) : defaultHeight));

      setItemHeights(finalHeights);
    };

    calculateHeights();
  }, [contents, containerHeight]);

  return (
    <S.Container height={containerHeight} ref={containerRef}>
      {contents?.map((content, index) => (
        <S.ContentWrapper key={`equation-content-${index}`} height={itemHeights[index]}>
          {typeof content === 'string' ? <img src={content} alt={`equation-${index + 1}`} /> : content}
        </S.ContentWrapper>
      ))}
    </S.Container>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${GAP_SIZE}px;
    justify-content: flex-start;
    height: ${({ height }) => height};
    overflow-y: auto;
    background-color: #d9d9d9;
  `,

  ContentWrapper: styled.div`
    height: ${({ height }) => height || 'auto'};
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f0f0f0;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      width: auto;
      height: auto;
      user-select: none;
      pointer-events: none;
    }
  `,
};
