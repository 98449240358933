import EditModalCalendar from 'components/com/tests/scheduled/_components/EditModalCalendar';
import dayjsTZ, { isoTimeToDayjs, isoTimeToDayjsTZ } from 'utils/functions/time/dayjs-config';
import { USER_LEVELS } from 'utils/constants';
import request from 'utils/Request.utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TimeZoneDisplayArea from './TimeZoneDisplayArea';
import Box from 'components/dashboard/_components/common/Box';

/**
 * 아카데미 대쉬보드 - 미니 캘린더
 * @description
 * 🔍 검색 키워드 - #calendar
 */
function MiniCalendarSection() {
  const userInfo = request.tokenDecoder();
  const [currentDate, setCurrentDate] = useState(dayjsTZ());
  const [mark, setMark] = useState({});
  const navigate = useNavigate();

  /** 앞 뒤로 넉넉히 데이터 불러오게 설정 */
  const getCalendarData = (ecSeq, userSeq = userInfo.userSeq) => {
    const today = dayjsTZ();

    const successHandler = (response) => {
      if (response.code === 200) {
        const scheduleInfo = response.result.scheduleInfo.map((item) => ({
          testStartDate: isoTimeToDayjsTZ(item.testStartDate).format('YYYY-MM-DD'),
          testEndDate: isoTimeToDayjsTZ(item.testEndDate).format('YYYY-MM-DD'),
          classColor: item.classColor,
        }));

        const newMark = {};
        scheduleInfo.forEach((item) => {
          const { testStartDate, testEndDate, classColor } = item;
          if (dayjsTZ(testEndDate).isAfter(today)) {
            if (!newMark[testStartDate]) newMark[testStartDate] = [];
            newMark[testStartDate].push(classColor);
          }
        });

        setMark(newMark);
      }
    };
    const searchStartDate = encodeURIComponent(currentDate.startOf('day').subtract(1, 'month').date('1').format());
    const searchEndDate = encodeURIComponent(currentDate.startOf('day').add(2, 'month').date('1').format());

    if (userInfo.userLevel === USER_LEVELS.ACADEMY || userInfo.userLevel === USER_LEVELS.TUTOR || userInfo.userLevel === USER_LEVELS.TEACHER) {
      // 학원관리자, 선생님, 튜터
      request.get(`/api/dsat/calendar/schedules?ecSeq=${ecSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`, null, successHandler);
    } else {
      // 학부모, 학생
      request.get(
        `/api/dsat/calendar/schedules?ecSeq=${ecSeq}&userSeq=${userSeq}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`,
        null,
        successHandler
      );
    }
  };
  /** 해당하는 학생의 mark( 시험 데이터 ) 불러오기 */
  const childEcSeq = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        const childInfo = response.result.childInfo;

        if (childInfo && childInfo.length > 0) {
          const childEcSeq = childInfo[0].ecSeq;
          const childUserSeq = childInfo[0].userSeq;

          getCalendarData(childEcSeq, childUserSeq);
        }
      }
    };
    const failHandler = (error) => {
      console.error('error : ', error);
    };

    request.get(`/api/member/child?userSeq=${userInfo.userSeq}`, null, successHandler, failHandler);
  };

  // currentDate가 바뀔때 마다 시험 데이터 호출
  useEffect(() => {
    if (userInfo.userLevel === USER_LEVELS.PARENT) childEcSeq();
    else getCalendarData(userInfo.ecSeq);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  // console.log(`\n┏━━━ 💡 💡 mark 💡 💡 ━━━\n`, mark, `\n┗━━━━━━ 💡 💡 💡 💡 💡 ━━━━━━━━━\n`);

  return (
    <div className='calendar'>
      <div className='com_sec_tit'>
        <h2 className='tit'>
          <i className='svg_icon icon_calendar black'>&nbsp;</i>
          CALENDAR
        </h2>
      </div>
      <EditModalCalendar
        markData={mark}
        setCurrentDate={setCurrentDate}
        readOnly
        calendarType='singleDate'
        selectedDateState={dayjsTZ()}
        setSelectedDateState={null}
        highlightToday
        onClickDay={(date) => {
          navigate('/com/tests/calendar', {
            state: { selectedDate: dayjsTZ(date).format('YYYY-MM-DD') },
          });
        }}
      />
      <TimeZoneDisplayArea />
    </div>
  );
}

export default MiniCalendarSection;
