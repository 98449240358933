import { useState } from 'react';

import request from 'utils/Request.utils';
import localStorage from 'utils/LocalStorage.utils';

import { ReactComponent as ImageSvgIconMyHome } from 'assets/img/svg/icon-myhome.svg';

import useUserLevels from 'hooks/useUserLevels.js';

/** 프로필 버튼을 누르면 노출되는 팝업 */
function UserProfilePopup({ handleLogout, setUserLayer, handleProfileSetting }) {
  const [userInfo, _] = useState(request.tokenDecoder());
  const { permissions: useLevels } = useUserLevels(userInfo?.userLevel);
  // 로컬 스토리지
  const userSession = localStorage.getItemJsonParse('userSession');

  return (
    <div className='user_layer active'>
      {/* 마이페이지 버튼 (학부모 제외) */}
      {!useLevels.isParent && (
        <button
          className='btn'
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_MAINGATE_ROOT_URL}/sign/signin/?lastRoute=/en-US/mypage&accessToken=${userSession.accessToken}&refreshToken=${userSession.refreshToken}`
            )
          }
          title='Open in a New Tab'>
          <i style={{ width: '1.5rem', marginRight: '0.25rem' }}>
            <ImageSvgIconMyHome />
          </i>
          &nbsp; My Page
        </button>
      )}

      {/* 프로필 설정 버튼 */}
      <button className='btn' onClick={handleProfileSetting}>
        <i className='svg_icon icon_profile'>&nbsp;</i>Profile Settings
      </button>
      {/* 도움말 및 피드백 링크 */}
      <button className='btn' onClick={() => setUserLayer(false)}>
        <a href='https://gateplus.notion.site/Digital-SAT-2-0-FAQ-3575f21ae8bc4e44a5ef99b7ac188cc2' target='_blank' rel='noopener noreferrer'>
          <i className='svg_icon icon_help2'>&nbsp;</i>Help & feedback
        </a>
      </button>
      {/* 로그아웃 버튼 */}
      <button className='btn' onClick={() => handleLogout()}>
        <i className='svg_icon icon_logout'>&nbsp;</i>Logout
      </button>
    </div>
  );
}

export default UserProfilePopup;
