import React, { useState } from 'react';
import styled from 'styled-components';
import ExitModal from '../common/modals/ExitModal';

/** Grading Submission Footer 컴포넌트 */
export default function GradingFooter() {
  /** "Exit" 모달 노출 여부 */
  const [isViewExitModal, setIsViewExitModal] = useState(false);

  /** "Exit" 버튼 핸들러 */
  const handleExitButton = () => {
    setIsViewExitModal(true);
  };

  return (
    <S.Footer>
      <hr />
      <div className='center_wrap'>
        <div className='user_name'>Teacher Name</div>
        <div className='button_wrap'>
          <button type='button' title='' onClick={handleExitButton}>
            Exit
          </button>
        </div>
      </div>
      {isViewExitModal && <ExitModal setShowModal={setIsViewExitModal} />}
    </S.Footer>
  );
}

const S = {
  Footer: styled.footer`
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 10rem;
    background-color: #f4f8ff;
    hr {
      position: absolute;
      top: 0;
      border: none;
      background-image: linear-gradient(to right, black 80%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 80px 2px;
      background-repeat: repeat-x;
      height: 2px;
      width: 100%;
    }
    .center_wrap {
      padding: 3rem 4.5rem 2rem;
      display: flex;
      justify-content: space-between;
      .user_name {
        color: #000;
        font-size: 1.375rem;
        font-weight: 600;
        line-height: 1.375rem;
      }
      .button_wrap {
        button {
          color: #fff;
          font-size: 1.375rem;
          font-weight: 600;
          line-height: 1.625rem;
          border-radius: 1.875rem;
          background-color: #004a9e;
          padding: 0.875rem 1.5rem;
        }
      }
    }
  `,
};
