import { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactQuillEditor from 'components/_common/test-editor/quilljs/ReactQuillEditor';
import SimpleEditorBtn from 'components/ap/com/ap-components/_components/SimpleEditorBtn';
import { EDITOR_CONSTANT } from '../_constant';
import DeleteModal from 'components/_common/modals/DeleteModal';

/** 소스를 입력하는 컴포넌트 */
export default function SourceContainer({ sourceOrder = 0, editorState, handleDelete, editorStateMutator }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  /** "Sources" 입력 창(ReactQuillEditor) 현재 화면 표시 용 지역 상태 */
  const [editorContentState, setEditorContentState] = useState('');
  /** "Delete" 모달 노출 여부 */
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** "Source Direction" 삭제 버튼 핸들러 */
  const handleDeleteButton = () => {
    setIsShowDeleteModal(true);
  };
  /** "Sources" 입력 창(ReactQuillEditor) 수정 핸들러 */
  const handleSourcesEditorChange = (event) => {
    setEditorContentState(event);

    const newData = editorState.currentQuestion.source_group.sources.map((item) => {
      return Number(item.order) === Number(editorState.currentSourceOrder) ? { ...item, content: event } : item;
    });

    editorStateMutator(EDITOR_CONSTANT.currentQuestion, (prev) => {
      return {
        ...prev,
        source_group: {
          ...prev.source_group,
          sources: newData,
        },
      };
    });
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    // 현재 보고 있는 Source 가 바뀔 때마다 입력창의 내용 갱신

    const currentSource = editorState.currentQuestion.source_group.sources.find((item) => {
      return Number(item.order) === Number(editorState.currentSourceOrder);
    });
    setEditorContentState(currentSource?.content);
  }, [editorState.currentSourceOrder, editorState.currentQuestion.source_group.sources]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  return (
    <S.SourceContainer>
      <S.SectionTitle>Sources {sourceOrder + 1}</S.SectionTitle>
      <ReactQuillEditor className='quill_editor' editorContentState={editorContentState} handleChange={handleSourcesEditorChange} />
      <div className='btn_container'>
        <SimpleEditorBtn className='normal' title='Delete' onClick={handleDeleteButton}>
          Delete
        </SimpleEditorBtn>
      </div>

      {isShowDeleteModal && (
        <DeleteModal
          hideModal={() => setIsShowDeleteModal(false)}
          callback={() => {
            setIsShowDeleteModal(false);
            handleDelete();
          }}
          text={'All the content will be deleted.'}
        />
      )}
    </S.SourceContainer>
  );
}

const S = {
  SourceContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    // quill editor 커스텀 스타일 적용
    .quill_editor {
      .ql-editor {
        min-height: 10.125rem;
      }
    }

    .btn_container {
      display: flex;
      gap: 0.75rem;
      justify-content: flex-end;
    }
  `,

  SectionTitle: styled.h4`
    color: #111;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Noto Serif';
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
};
