import styled from 'styled-components';

import CustomCheckbox from '../CustomCheckbox';

/**
 * Table에서 사용되는 클릭 가능한 행 ( 행 전체 클릭시 체크도 같이 됨 )
 * @example
 * <ClickableTableRow item={item} index={index} isSelected={isSelected} onSelect={onSelect} className={className} key={`row_${index}`}>
 *   {children}
 * </ClickableTableRow>
 */
export default function ClickableTableRow({ item, index = 0, isSelected = false, onSelect = () => console.log('onSelect!'), className = '', children }) {
  return (
    <S.ClickableTableRow onClick={() => onSelect(item)} className={`${className} ${isSelected ? 'active' : ''}`}>
      <td>
        <CustomCheckbox size='1rem' checkBoxId={`row_${index}`} isChecked={isSelected} onChange={() => onSelect(item)} />
      </td>
      {children}
    </S.ClickableTableRow>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  ClickableTableRow: styled.tr`
    cursor: pointer;

    &.active {
      background-color: #f0f0f0;
    }
  `,
};
