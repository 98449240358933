/**
 * ACADEMY_TEACHER sgin up 전역 상태 리덕스 action 타입
 */
/** 회원가입 시 userId 저장 */
export const SET_ACADEMY_TEACHER_ID = 'SET_ACADEMY_TEACHER_ID';
/** Login 시 login type 저장 */
export const SET_ACADEMY_TEACHER_LOGIN_TYPE = 'SET_ACADEMY_TEACHER_LOGIN_TYPE';
/** email */
export const SET_ACADEMY_TEACHER_USER_EMAIL = 'SET_ACADEMY_TEACHER_USER_EMAIL';
/** mobile */
export const SET_ACADEMY_TEACHER_USER_MOBILE = 'SET_ACADEMY_TEACHER_USER_MOBILE';
/** name */
export const SET_ACADEMY_TEACHER_USER_NAME = 'SET_ACADEMY_TEACHER_USER_NAME';
/** Privacy Policy */
export const SET_ACADEMY_TEACHER_AGREE_PRIVACY_POLICY = 'SET_ACADEMY_TEACHER_AGREE_PRIVACY_POLICY';
/** Terms of Service */
export const SET_ACADEMY_TEACHER_AGREE_TERMS_SERVICE = 'SET_ACADEMY_TEACHER_AGREE_TERMS_SERVICE';
/** News Letter */
export const SET_ACADEMY_TEACHER_AGREE_NEWS_LETTER = 'SET_ACADEMY_TEACHER_AGREE_NEWS_LETTER';
/** ACADEMY CODE */
export const SET_ACADEMY_TEACHER_ACADEMY_CODE = 'SET_ACADEMY_TEACHER_ACADEMY_CODE';
/** all data clear */
export const CLEAR_ACADEMY_TEACHER_STATE = 'CLEAR_ACADEMY_TEACHER_STATE';
