import React from 'react';
import styled from 'styled-components';

/** AP / "Question Bank" 페이지에서 사용 할 메인 박스 컴포넌트  */
export default function Box({ className = '', children, ...rest }) {
  return (
    <S.Box className={` ${className}`} {...rest}>
      {children}
    </S.Box>
  );
}

const S = {
  Box: styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 20px #00000029;

    padding: 4.375rem 2rem 2.5rem;
  `,
};
