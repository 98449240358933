// styled-component 전역 변수 설정 용 파일

import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --point-color: #0068bd;
    --SubText-color: #7f8998;
    --sub-color2: #a5aab1;
    --main-black: #000;
    --sub-red: #f24b4b;
    --emphasis: #4b5563;
    --input-stroke: #d2dbe2;
    --table-text: #4b5563;
    --rw: #008cff;
    --rw-text: #fff;
    --math: #1fdf9f;
    --math-text: #023524;
    --Table-set-Table_fill_blue: #edf7ff;
    --Table-set-table-line: #eaeaea;
    --c-blue: #0132b8;
    --Dashboard-primary-table-text: #080808;
    --c-red: #ff4c41;
    --c-blue-bg: #e9efff;
    --c-gray-bg: #f4f4f4;
    --c-gray: #6d6d6d;
    --c-lightgray: #f1f1f1;
    --c-disabled: #f8f8f8;
    --c-border: #ebebeb;
  }

  //#region    ////////// Quill Editor 기본 스타일 덮어씌우기
  /* 일반 텍스트(왼쪽 정렬 또는 기본 정렬)에 대한 들여쓰기 - 1.5em 간격 */
  .ql-editor .ql-indent-1:not(.ql-align-right):not(.ql-direction-rtl) { padding-left: 1.5em; padding-right: 0; }
  .ql-editor .ql-indent-2:not(.ql-align-right):not(.ql-direction-rtl) { padding-left: 3em; padding-right: 0; }
  .ql-editor .ql-indent-3:not(.ql-align-right):not(.ql-direction-rtl) { padding-left: 4.5em; padding-right: 0; }
  .ql-editor .ql-indent-4:not(.ql-align-right):not(.ql-direction-rtl) { padding-left: 6em; padding-right: 0; }
  .ql-editor .ql-indent-5:not(.ql-align-right):not(.ql-direction-rtl) { padding-left: 7.5em; padding-right: 0; }
  .ql-editor .ql-indent-6:not(.ql-align-right):not(.ql-direction-rtl) { padding-left: 9em; padding-right: 0; }
  .ql-editor .ql-indent-7:not(.ql-align-right):not(.ql-direction-rtl) { padding-left: 10.5em; padding-right: 0; }
  .ql-editor .ql-indent-8:not(.ql-align-right):not(.ql-direction-rtl) { padding-left: 12em; padding-right: 0; }
  /* 오른쪽 정렬 텍스트에 대한 들여쓰기 - 1.5em 간격 */
  .ql-editor .ql-indent-1.ql-align-right:not(.ql-direction-rtl) { padding-right: 1.5em; padding-left: 0; }
  .ql-editor .ql-indent-2.ql-align-right:not(.ql-direction-rtl) { padding-right: 3em; padding-left: 0; }
  .ql-editor .ql-indent-3.ql-align-right:not(.ql-direction-rtl) { padding-right: 4.5em; padding-left: 0; }
  .ql-editor .ql-indent-4.ql-align-right:not(.ql-direction-rtl) { padding-right: 6em; padding-left: 0; }
  .ql-editor .ql-indent-5.ql-align-right:not(.ql-direction-rtl) { padding-right: 7.5em; padding-left: 0; }
  .ql-editor .ql-indent-6.ql-align-right:not(.ql-direction-rtl) { padding-right: 9em; padding-left: 0; }
  .ql-editor .ql-indent-7.ql-align-right:not(.ql-direction-rtl) { padding-right: 10.5em; padding-left: 0; }
  .ql-editor .ql-indent-8.ql-align-right:not(.ql-direction-rtl) { padding-right: 12em; padding-left: 0; }
  //#endregion ////////// Quill Editor 기본 스타일 덮어씌우기
`;

export const styledCssEllipsis = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: nowrap;
  display: block;
`;
export const styledCssMultiEllipsis = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;
