import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CountUp from 'react-countup';
import { nvlNumber } from 'utils/Common.utils';
import SimpleDonutChart from 'components/_common/chart/SimpleDonutChart';
import Title from './_components/Title';

/** "아케데미 대시보드" 페이지 - "Test Status By Total" 컴포넌트 */
export default function TestStatusByTotal({ currentClassSeq, firstClassSeq, donutChartData, ...rest }) {
  const navigate = useNavigate();

  const handleClickTestStatus = () => {
    navigate('/com/tests/status', {
      state: {
        classSeq: nvlNumber(currentClassSeq) === 0 ? firstClassSeq : nvlNumber(currentClassSeq),
        seeEveryTest: true,
      },
    });
  };

  return (
    <S.Wrap {...rest}>
      <Title>By Total</Title>
      {/* 그래프 영역 */}
      <div className='com_donut_chart'>
        <div className='chart_area'>
          <div className='total' onClick={() => handleClickTestStatus()}>
            {donutChartData.data[0] + donutChartData.data[1] + donutChartData.data[2] === 0 ? null : (
              <div>
                <p className='tit_field'>TOTAL</p>
                <CountUp start={0} end={donutChartData.data[0] + donutChartData.data[1] + donutChartData.data[2]} duration={3} className='num_field' />
              </div>
            )}
          </div>
          <SimpleDonutChart donutChartData={donutChartData} />
        </div>
        <ul className='com_chart_name'>
          <li className='item' onClick={() => handleClickTestStatus()}>
            <span className='color black'>&nbsp;</span>
            <p className='name'>
              Not taking
              <br />
              the test
            </p>
            <CountUp start={0} end={donutChartData.data[1]} duration={3} className='num' />
          </li>
          <li className='item' onClick={() => handleClickTestStatus()}>
            <span className='color blue'>&nbsp;</span>
            <p className='name'>
              Currently
              <br />
              taking
            </p>
            <CountUp start={0} end={donutChartData.data[0]} duration={3} className='num' />
          </li>
          <li className='item' onClick={() => handleClickTestStatus()}>
            <span className='color gray'>&nbsp;</span>
            <p className='name'>
              Completed
              <br />
              tests
            </p>
            <CountUp start={0} end={donutChartData.data[2]} duration={3} className='num' />
          </li>
        </ul>
      </div>
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    .com_donut_chart {
      flex: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 1.25rem;
      .com_chart_name {
        display: flex;
        justify-content: space-between;
        .item {
          .color {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 100%;
          }
          .name {
            color: #505050;
            font-weight: 400;
            font-size: 0.875rem;
            margin-bottom: 8px;
          }
        }
      }
    }
  `,
};
