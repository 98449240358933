import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';

import request from 'utils/Request.utils';
import { nvl } from 'utils/Common.utils';
import LoadingBar from 'utils/LoadingBar';

import IconEmpty from 'assets/img/icon_empty.png';
import useOutsideClick from 'hooks/useOutsideClick';

import ButtonBase from 'components/_common/button/_ButtonBase';
import MiniSvgIconBtn from 'components/_common/button/MiniSvgIconBtn';
import PopupCalendarPeriod from 'components/_common/modals/PopupCalendarPeriod';

import Paging from 'layout/Paging';
import DeleteModal from 'components/_common/modals/DeleteModal';
import DeleteStudentAlert from '../DeleteStudentAlert';
import styled from 'styled-components';
import ByTestClassListSection from './ByTestClassListSection';
import CustomAlert from 'components/_common/alerts/CustomAlert';

import CommonTestBtn from 'components/com/tests/_components/_common/_CommonTestBtn';
import IconTrashcan2 from 'assets/img/svg/icon_trashcan_2.svg';
import ProfilePopup from 'components/_common/modals/PopupProfile';

/**
 * tests/scheduled 페이지의 "By Test" 텝에 해당하는 컨텐츠 영역 컴포넌트
 * @description
 * 🔍 검색 키워드 - #com/tests/scheduled
 */
export default function ByTest({ setEditTest, schedulesClassListReload }) {
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 시작 ////////
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  /////////////////// 외부 패키지 및 기타 React Hook 선언 영역 끝 ////////
  const alertAttributeValue = {
    alertType: 'alert',
    alertMessage: '',
    visible: false,
    returnValue: () => {},
  };
  const [alertLayerPopup, setAlertLayerPopup] = useState(alertAttributeValue);

  const [scheduleInfo, setScheduleInfo] = useState({
    uthList: [],
    studentInfoList: [],
    totalCount: 0,
    currentPage: 1,
    numPerPage: 10,
    pagePerBlock: 10,
  });
  const [searchInfo, setSearchInfo] = useState({
    searchFieldActive: false,
    searchStartDate: '',
    searchEndDate: '',
    setSearchField: 'Name',
    searchKeyword: '',
  });
  const [activeTest, setActiveTest] = useState({
    customSetSeq: 0,
    classMemberList: [],
    classMemberListAll: [],
    totalCnt: 0,
    testName: '',
  });

  /** 중복 제거 1 */
  const removeDuplicatesOne = (arr, prop) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop] === item[prop]);
    });
  };
  /** 중복 제거 2 */
  const removeDuplicatesTwo = (arr, prop1, prop2) => {
    return arr.filter((item, index, self) => {
      return index === self.findIndex((t) => t[prop1] === item[prop1] && t[prop2] === item[prop2]);
    });
  };

  /** 반 별 예정 시험 조회 API 호출 */
  const getTestClassMember = () => {
    const successHandler = (response) => {
      if (response.code === 200) {
        let scheduleList = response.result.scheduleListc;

        let deduplicationClass = removeDuplicatesOne(scheduleList, 'classSeq');
        let sameClassList = [];
        for (let i = 0; i < deduplicationClass.length; i++) {
          const classSeq = deduplicationClass[i].classSeq;
          const filteredSchedule = scheduleList.filter((i) => i.classSeq === classSeq);
          sameClassList.push(filteredSchedule);
        }

        let courseList = [];
        let deduplicationTestDate = [];
        for (let i = 0; i < sameClassList.length; i++) {
          deduplicationTestDate = removeDuplicatesTwo(sameClassList[i], 'testStartDate', 'testEndDate');

          for (let j = 0; j < deduplicationTestDate.length; j++) {
            const startDate = deduplicationTestDate[j].testStartDate;
            const endDate = deduplicationTestDate[j].testEndDate;

            const filteredTestSchedule = sameClassList[i].filter((i) => i.testStartDate === startDate && i.testEndDate === endDate);
            courseList.push(filteredTestSchedule);
          }
        }

        const sortList = courseList.sort((a, b) => isoTimeToDayjs(a[0].testStartDate) - isoTimeToDayjs(b[0].testStartDate));
        setActiveTest((prev) => {
          return { ...prev, classMemberList: sortList };
        });
      }
    };

    request
      .get(`/api/test/schedules/pre/exams/${activeTest.customSetSeq}?ecSeq=${userInfo.ecSeq}`, null, successHandler)
      .catch((error) => console.error('tests/scheduled 페이지의 "반 별 예정 시험 조회" API 응답 에러', error));
  };

  /** 시험 일정 얻어오기 API 호출 */
  const getSchedules = () => {
    const pms = {
      ecSeq: userInfo?.ecSeq,
      searchField: searchInfo.setSearchField === 'Name' ? 'userName' : 'testName',
      searchKeyword: searchInfo.searchKeyword,
      searchStartDate: searchInfo.searchStartDate,
      searchEndDate: searchInfo.searchEndDate,
      limit: 10,
      offset: (scheduleInfo.currentPage - 1) * scheduleInfo.numPerPage,
      modiUserSeq: userInfo?.userSeq,
    };

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        const scheduleList = response.result.scheduleList;
        const totalCnt = response.result.totalCnt;

        setScheduleInfo((prev) => {
          return { ...prev, uthList: scheduleList ?? [], totalCount: totalCnt };
        });

        if (totalCnt > 0 && !activeTest.customSetSeq) {
          setActiveTest((prev) => {
            return {
              ...prev,
              customSetSeq: scheduleList[0].customSetSeq,
              testName: scheduleList[0].testName,
            };
          });
        }
      }
    };

    if (userInfo?.ecSeq > 0) {
      const sendSearchKeyword = pms.searchKeyword?.trim() ? encodeURIComponent(pms.searchKeyword) : '';
      request
        .get(
          `/api/test/schedules/pre?ecSeq=${pms.ecSeq}&limit=${pms.limit}&offset=${pms.offset}&searchField=${pms.searchField}&searchKeyword=${sendSearchKeyword}&searchStartDate=${encodeURIComponent(pms.searchStartDate)}&searchEndDate=${encodeURIComponent(pms.searchEndDate)}`,
          null,
          successHandler
        )
        .catch((error) => console.error('tests/scheduled 페이지의 "/api/test/schedules/pre" API 응답 에러', error));
    }
  };

  /////////////////// React useState 선언 영역 시작 ///////////////////////
  /** 체크 박스 */
  const [selectCheckBox, setSelectCheckBox] = useState({
    seqStr: '',
    selectedKeys: [],
  });
  /** 프로필 팝업 */
  const [profileState, setProfileState] = useState(false);
  /** 학생 삭제 confirm 팝업 */
  const [deleteStudentAlert, setDeleteStudentAlert] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  /** 학생 삭제 팝업 */
  const [deleteStudent, setDeleteStudent] = useState(false);
  /////////////////// React useState 선언 영역 끝 ///////////////////////

  /////////////////// React useRef 선언 영역 시작 ///////////////////////
  /** 검색 */
  const searchFiledRef = useRef();
  const searchKeywordRef = useRef();
  /////////////////// React useRef 선언 영역 끝 ///////////////////////

  /////////////////// 기타 핸들러 함수 등 영역 시작 ////////////////////
  const deleteStudentAlertOpen = () => {
    setDeleteStudent((prevState) => !prevState);
    setDeleteStudentAlert((prevState) => !prevState);
  };

  /** 시험 일정 삭제 API 호출 */
  const delUserTestSchedule = () => {
    setIsDeleteLoading(true);
    const successHandler = (response) => {
      if (response.code === 200) {
        // 체크 박스 초기화
        setSelectCheckBox((prev) => {
          return { ...prev, selectedKeys: [] };
        });
        // 성공 창
        deleteStudentAlertOpen();
        // 리로드
        schedulesListReload();
        schedulesClassListReload();

        getTestClassMember();

        setIsDeleteLoading(false);
      }
    };

    request
      .del(`/api/test/schedules?seqStr=${encodeURI(selectCheckBox.seqStr)}`, null, successHandler)
      .catch((error) => console.error('tests/scheduled 페이지의 "시험 일정 삭제" API 응답 에러', error));
  };

  const deletePopupOpen = () => {
    const { seqStr, selectedKeys } = selectCheckBox;

    if (selectedKeys.length > 0) {
      setDeleteStudent(true);
    } else {
      setAlertLayerPopup((prev) => {
        return {
          ...prev,
          visible: true,
          alertMessage: 'Please check the student you want to delete.',
        };
      });
    }
  };

  const searchFiledClick = (value) => {
    setSearchInfo((prev) => {
      return { ...prev, searchFieldActive: false, setSearchField: value };
    });
  };

  const keywordSearchDo = (e) => {
    // console.log(nvl(searchKeywordRef.current.value));

    setSearchInfo((prev) => {
      return { ...prev, searchKeyword: nvl(searchKeywordRef.current.value) };
    });
  };

  const scheduleTrClick = (customSetSeq, testName) => {
    // 중복 제거2
    setActiveTest((prev) => {
      return { ...prev, customSetSeq: customSetSeq, testName: testName };
    });
  };

  /** 체크 박스 변경 핸들러 */
  const handleCheckboxChange = (e, uthSeq) => {
    e.stopPropagation();

    const { selectedKeys, seqStr } = selectCheckBox;

    if (e.target.checked) {
      const updatedSelectedKeys = [...selectedKeys, uthSeq];
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    } else {
      const updatedSelectedKeys = selectedKeys.filter((key) => key !== uthSeq);
      const updatedSeqStr = updatedSelectedKeys.join('|');

      setSelectCheckBox({
        ...selectCheckBox,
        selectedKeys: updatedSelectedKeys,
        seqStr: updatedSeqStr,
      });
    }
  };

  /** edit */
  const uthEditClick = (item) => {
    setEditTest((prev) => {
      return { ...prev, active: true, item: item, type: 'student' };
    });
  };

  /** 프로필 불러오기 API 요청 */
  const getProfileView = (e, userSeq) => {
    e.stopPropagation();

    const successHandler = (response) => {
      // console.log(response);

      if (response.code === 200) {
        let studentInfo = response.result.studentInfo;

        let imageUrl = null;

        if (studentInfo.upFileSeq > 0) {
          imageUrl = `${process.env.REACT_APP_API_URL}/api/common/profile/upload/userProfile/${studentInfo.userSeq}?${Date.now()}`;
        }

        studentInfo.profile = imageUrl;

        setScheduleInfo((prev) => {
          return { ...prev, studentInfoList: studentInfo };
        });
        setProfileState(true);
      }
    };

    request
      .get(`/api/test/profiles?userSeq=${userSeq}&ecSeq=${userInfo.ecSeq}`, null, successHandler)
      .catch((error) => console.error('tests/scheduled 페이지의 "프로필 불러오기" API 응답 에러', error));
  };

  const nextPageMove = (value) => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: value };
    });
  };

  const schedulesListReload = () => {
    setScheduleInfo((prev) => {
      return { ...prev, currentPage: 1 };
    });

    getSchedules();
    getTestClassMember();
  };
  /////////////////// 기타 핸들러 함수 등 영역 끝 ////////////////////

  /////////////////// 기타 Hook 및 함수 등 사용 영역 시작 ////////////////
  useOutsideClick(
    searchFiledRef,
    () =>
      setSearchInfo((prev) => {
        return { ...prev, searchFieldActive: false };
      }),
    searchInfo.searchFieldActive
  );
  /////////////////// 기타 Hook 및 함수 등 사용 영역 끝 ////////////////

  /////////////////// React useEffect 영역 시작 ////////////////////////
  useEffect(() => {
    schedulesListReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleInfo.numPerPage, searchInfo.searchKeyword, searchInfo.searchStartDate, searchInfo.searchEndDate]);
  useEffect(() => {
    getTestClassMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTest.customSetSeq]);

  useEffect(() => {
    schedulesListReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /////////////////// React useEffect 영역 끝 ////////////////////////

  return (
    <>
      <S.ByTestWrap>
        <S.TableContainer className='com_list'>
          <S.TableTopController className='top'>
            <article className='btn_area'>
              <CommonTestBtn className='delete_test_btn' hoverText='Delete' onClick={deletePopupOpen} iconBtn>
                <img src={IconTrashcan2} alt='delete' />
              </CommonTestBtn>
            </article>
            <article className='search_area'>
              <PopupCalendarPeriod setSearchInfo={setSearchInfo} />
              <div
                className={`com_select_layer ${searchInfo.searchFieldActive ? 'active' : ''}`}
                onClick={() =>
                  setSearchInfo((prev) => {
                    return {
                      ...prev,
                      searchFieldActive: !searchInfo.searchFieldActive,
                    };
                  })
                }
                ref={searchFiledRef}>
                {/*클래스 active 포함 시 옵션 노출*/}
                <input type='text' className='selected search_area_input' placeholder={searchInfo.setSearchField} />
                <div className='layer'>
                  <p className='option' onClick={() => searchFiledClick('Name')}>
                    Name
                  </p>
                  <p className='option' onClick={() => searchFiledClick('Test')}>
                    Test
                  </p>
                </div>
              </div>
              <div className='search'>
                <input type='text' placeholder='Enter keywords' className='input search_area_input' ref={searchKeywordRef} />
                <button className='com_btn m blue btn_search' onClick={keywordSearchDo}>
                  <i className='svg_icon icon_search blue before'></i>
                  SEARCH
                </button>
              </div>
            </article>
          </S.TableTopController>
          <S.Table className='list'>
            <colgroup>
              <col width='38px' />
              <col width='172px' />
              <col width='' />
              <col width='84px' />
              <col width='105px' />
              <col width='105px' />
            </colgroup>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Subject</th>
                <th>Test Name</th>
                <th>Name</th>
                <th>Time</th>
                <th>Start Date</th>
              </tr>
            </thead>
            <tbody>
              {scheduleInfo.uthList.length > 0 ? (
                scheduleInfo.uthList.map((item, idx) => {
                  return (
                    <tr
                      className={activeTest.customSetSeq === item.customSetSeq ? 'active' : ''}
                      key={`scheduleTr_${idx}`}
                      onClick={() => scheduleTrClick(item.customSetSeq, item.testName)}>
                      <td>
                        <input
                          type='checkbox'
                          className='com_chk'
                          id={`schedule_${idx}`}
                          checked={selectCheckBox.selectedKeys.includes(item.uthSeq)}
                          onChange={(e) => handleCheckboxChange(e, item.uthSeq)}
                        />
                        <label htmlFor={`schedule_${idx}`}>&nbsp;</label>
                      </td>
                      <td></td>
                      <td className='tit_field alignL'>
                        <p className='ellipsis'>
                          <button onClick={() => uthEditClick(item)}>
                            <i className='svg_icon icon_annotate gray before'>&nbsp;</i>
                          </button>
                          {item.testName}
                        </p>
                      </td>
                      <td className='alignL'>{item.userName}</td>
                      <td>{isoTimeToDayjs(item.testStartDate).format('h : mm A')}</td>
                      <td>{isoTimeToDayjs(item.testEndDate).format('MM/DD/YYYY')}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='7'>
                    <div className='com_nodata'>
                      <p className='tit'>No test available.</p>
                      <p className='txt'>Go to Set Test and set more test!</p>
                      <div className='img'>
                        <img src={IconEmpty} alt='' />
                      </div>
                      <button className='com_btn point l line' onClick={() => navigate('/com/tests/set/selection/type')}>
                        GO TO SET TEST
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </S.Table>
          <Paging
            totalCount={scheduleInfo.totalCount}
            currentPage={scheduleInfo.currentPage}
            numPerPage={scheduleInfo.numPerPage}
            pagePerBlock={scheduleInfo.pagePerBlock}
            nextPageMove={nextPageMove}
          />
        </S.TableContainer>
        <ByTestClassListSection testData={activeTest} setAlertLayerPopup={setAlertLayerPopup} />
      </S.ByTestWrap>
      {/* 학생 상세 프로필 팝업 */}
      {profileState && <ProfilePopup setProfileState={setProfileState} studentInfo={scheduleInfo.studentInfoList} />}
      {/* 학생 삭제 팝업 */}
      {deleteStudent && (
        <DeleteModal
          hideModal={() => setDeleteStudent(false)}
          callback={delUserTestSchedule}
          text={'You are removing scheduled test. \n Your Gate Key will be refunded.'}
        />
      )}
      {/* 학생 삭제 완료 팝업 */}
      {deleteStudentAlert && <DeleteStudentAlert setDeleteStudentAlert={setDeleteStudentAlert} />}
      {isDeleteLoading && <LoadingBar type={'spin'} color={'#000000'} />}
      {alertLayerPopup.visible && (
        <CustomAlert
          onClose={() => setAlertLayerPopup(() => ({ ...alertAttributeValue }))}
          alertType={alertLayerPopup.alertType}
          alertMessage={alertLayerPopup.alertMessage}
          returnValue={alertLayerPopup.returnValue}
        />
      )}
    </>
  );
}

// --- --- ---

const S = {
  ByTestWrap: styled.div`
    display: flex;
    background: #fff;
    gap: 1rem;
    padding: 1.25rem 1rem;
  `,

  TableContainer: styled.div``,
  TableTopController: styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search_area {
      display: flex;
      gap: 0.5rem;

      .com_select_layer {
        width: 6.25rem;
        height: 2.5rem;

        .selected.search_area_input {
          border-radius: 0.5rem;
          min-width: 6.25rem;
          padding: 0.625rem 0.75rem;
        }

        &:after {
          right: 1rem;
        }
      }

      .com_calendar_wrap {
        input {
          width: 13.4375rem;
          border-radius: 0.5rem !important;
          padding-left: 2.5rem;
          background-position: 0.75rem center;
          text-align: right;
          padding-inline: 0.75rem !important;
        }
      }

      .search {
        .search_area_input {
          border-radius: 0.5rem;
          padding: 0.625rem 0.75rem;
        }

        .svg_icon {
          margin-right: 0.5rem;
          width: 1.125rem;
          height: 1.125rem;
        }
      }
    }

    .delete_test_btn {
      width: 2rem;
      height: 2rem;
    }
  `,
  Table: styled.table``,
};
