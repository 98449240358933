import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

//component
import Header from 'components/user/_components/layouts/_Header';
import MainBox from 'components/user/_components/layouts/MainBox';
import PrimaryButtonArea from 'components/user/_components/common/buttons/PrimaryButtonArea';
import PrimaryButton from 'components/user/_components/common/buttons/PrimaryButton';
import SubButton from 'components/user/_components/common/buttons/SubButton';

/** "이메일 찾기 성공" 페이지  */
export default function FindEmailCompletePage() {
  const navigate = useNavigate();
  const { state } = useLocation();

  /** "Find password" 버튼 핸들러 */
  const handleFindPassword = () => {
    navigate('/signin/find/passwd');
  };
  /** "Go back to Sign in" 버튼 핸들러 */
  const handleGoBack = () => {
    navigate('/signin');
  };

  useEffect(() => {
    document.body.classList.add('gate_member'); //현재페이지에서만 addClass
    return () => {
      document.body.classList.remove('gate_member'); //다른 페이지에선 removeClass
    };
  }, []);

  return (
    <S.Wrap>
      <MainBox>
        <Header>
          <p className='txt'>The door to educational aspiration</p>
        </Header>
        <section className='forgot-email-top-text-area'>
          <h4 className='forgot_title'>Forgot your email?</h4>
          <div className='result-area'>
            <p className='tit'>Your email is :</p>
            <p className='txt'> {state}</p>
          </div>
        </section>
        <PrimaryButtonArea>
          <SubButton type='button' title='페이지 이동' onClick={handleFindPassword}>
            Find password
          </SubButton>
          <PrimaryButton type='button' title='페이지 이동' onClick={handleGoBack}>
            Go back to Sign in
          </PrimaryButton>
        </PrimaryButtonArea>
      </MainBox>
    </S.Wrap>
  );
}

const S = {};

S.Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .forgot-email-top-text-area {
    text-align: left;
    color: #7f8998;
    .forgot_title {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .result-area {
      margin-top: 0.5rem;
      background-color: #fcfcfc;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem;
      font-size: 1rem;
      line-height: 1.5rem;
      .tit {
        color: #7f8998;
        font-weight: 400;
      }
      .txt {
        color: var(--point-color);
        font-weight: 500;
      }
    }
  }
`;
