import icon_x_16 from 'assets/img/svg/icon/icon_x_16.svg';
import { AP_SUBJECT_CATEGORY } from 'components/ap/_utils/constants/subjectsCategoryData';
import { getColorBySeq } from 'components/ap/_utils/functions/getColorBySeq';
import { useEffect } from 'react';
import styled from 'styled-components';
import request from 'utils/Request.utils';

export default function TeacherList({ teacherList, setTeacherList, apSubject, selectedSubject, checkedTeacher, setCheckedTeacher }) {
  // 체크 박스 선택
  const handleCheckedTeacher = (e, teacher) => {
    if (selectedSubject === 0) {
      alert('Please select a subject.');
      e.target.checked = false;
      return;
    }

    const { checked } = e.target;

    // teacherList 내 해당 teacher의 subject 배열을 업데이트
    const updatedTeacherList = teacherList.map((t) => {
      if (t.teacherSeq === teacher.teacherSeq) {
        if (checked) {
          // 체크하면 teacher의 subject 배열에 selectedSubject에 해당하는 데이터가 없으면 추가
          const exists = t.subject.some((sub) => sub.subjectSeq == selectedSubject);
          if (!exists) {
            // apSubject 데이터에서 selectedSubject에 해당하는 객체 찾기
            const subjectToAdd = apSubject.find((sub) => sub.subjectSeq == selectedSubject);

            if (subjectToAdd) {
              return {
                ...t,
                subject: [...t.subject, subjectToAdd],
              };
            }
          }
          return t;
        } else {
          // 체크 해제하면 teacher의 subject 배열에서 selectedSubject에 해당하는 데이터만 제거
          return {
            ...t,
            subject: t.subject.filter((sub) => sub.subjectSeq != selectedSubject),
          };
        }
      }
      return t;
    });
    setTeacherList(updatedTeacherList);

    // 체크된 teacherSeq 목록 업데이트 (필요에 따라 사용)
    if (checked) updateCheckedTeacher(teacher.teacherSeq);
    else {
      deleteCheckedTeacher(teacher.teacherSeq);
    }
  };

  useEffect(() => {
    if (selectedSubject === 0) {
      setCheckedTeacher([]);
    } else {
      // 체크박스 선택된 선생님 초기화
      const selectedTeacher = teacherList.filter((teacher) => teacher.subject.some((subject) => subject.subjectSeq == selectedSubject));
      setCheckedTeacher(selectedTeacher.map((teacher) => teacher.teacherSeq));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject]);

  // api 호출 teacherList 변경 시 체크박스 선택된 선생님 업데이트
  const updateCheckedTeacher = (teacherSeq) => {
    const param = {
      subjectSeq: selectedSubject,
      teacherSeqList: [teacherSeq],
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        setCheckedTeacher([...checkedTeacher, teacherSeq]);
      }
    };

    request.apPost('/api/v1/academy/teacher/subject-connect', param, successHandler);
  };

  // api 호출 teacherList 변경 시 체크박스 선택 해제된 선생님 업데이트
  const deleteCheckedTeacher = (teacherSeq, subjectSeq = selectedSubject) => {
    if (Number(subjectSeq) === Number(selectedSubject)) {
      setCheckedTeacher(checkedTeacher.filter((seq) => seq !== teacherSeq));
    }

    const successHandler = (response) => {
      if (response.code === 200) {
        // teacherList에서 subject 제거하여 업데이트
        setTeacherList((prevTeacherList) =>
          prevTeacherList.map((teacher) =>
            teacher.teacherSeq === teacherSeq ? { ...teacher, subject: teacher.subject.filter((sub) => sub.subjectSeq !== subjectSeq) } : teacher
          )
        );
      }
    };

    request.apDel(`/api/v1/academy/teacher/${subjectSeq}/subject-disconnect?teacherSeq=${teacherSeq}`, null, successHandler);
  };

  return (
    <S.Table>
      <thead className='list'>
        <tr>
          <th className='col_01'></th>
          <th className='col_02'>Name</th>
          <th className='col_03'>subject</th>
          <th className='col_04'>Email</th>
        </tr>
      </thead>

      <tbody>
        {teacherList.length > 0 ? (
          teacherList.map((item, index) => (
            <tr key={index}>
              <td className='col_01'>
                <input
                  type='checkbox'
                  className='com_chk'
                  id={`chk02_${index}`}
                  value={item.teacherSeq} // teacher 객체 전체가 아니라 teacherSeq 전달
                  onChange={(e) => handleCheckedTeacher(e, item)}
                  checked={checkedTeacher.includes(item.teacherSeq)}
                />
                <label htmlFor={`chk02_${index}`}>&nbsp;</label>
              </td>
              <td className='col_02 alien_left' onClick={() => handleCheckedTeacher({ target: { checked: !checkedTeacher.includes(item.teacherSeq) } }, item)}>
                {item.teacherName}
              </td>
              <td className='col_03'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: '0.25rem' }}>
                  {item.subject.length > 0 &&
                    item.subject.map(
                      (subject, innerIndex) =>
                        subject.subjectSeq && (
                          <S.LabelByApSubject
                            key={innerIndex}
                            bgColor={getColorBySeq({ apSubjectCategory: AP_SUBJECT_CATEGORY, seq: String(subject.subjectSeq) })}
                            onClick={() => {
                              deleteCheckedTeacher(item.teacherSeq, subject.subjectSeq);
                            }}>
                            {subject.subjectName}
                            <i
                              style={{
                                width: '16px',
                                height: '16px',
                                backgroundImage: `url(${icon_x_16})`,
                                display: 'inline-block',
                              }}>
                              &nbsp;
                            </i>
                          </S.LabelByApSubject>
                        )
                    )}
                </div>
              </td>
              <td className='col_04 alien_left'>{item.teacherEmail}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan='4'>You do not have any test… yet!</td>
          </tr>
        )}
      </tbody>
    </S.Table>
  );
}

const S = {
  Table: styled.table`
    tr {
      background-color: #fff;
      &:nth-child(even) {
        background-color: #fafafa;
      }
      &.active {
        background-color: #edf7ff;
      }
      &:has(.com_chk):hover {
        background-color: #f2f2f2;
        //color:#4B5563 ;
      }

      th,
      td {
        vertical-align: middle;
        padding: 0.875rem 0.75rem;
        &.col_01 {
          width: 2.5rem;
        }
        &.col_02 {
          cursor: pointer;
          width: 12rem;
        }
        &.col_03 {
        }
        &.col_04 {
          width: 15rem;
        }
      }
      th {
        background-color: #000;
        color: #fff;
        height: 2.5rem;
        text-align: center;
        font-weight: 700;
        font-size: 0.8125rem;
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          overflow: hidden;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          overflow: hidden;
        }

        .btn_sort {
          color: #ffffff;
          font-weight: 700;
          font-size: 0.8125rem;
          svg {
            width: 0.75rem;
            transition: 0.2s;
            transform-origin: center;
          }
          &.on {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
      td {
        height: 3rem;
        text-align: center;
        font-weight: 400;
        font-size: 0.875rem;
        color: #4b5563;
        border-bottom: 1px solid #ebebeb;

        &.alien_left {
          text-align: left;
        }

        .icon_check_circle {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 1rem;
          height: 1rem;
          border-radius: 100%;
          background-color: #0da674;
          svg {
            color: #ffffff;
            width: 1rem;
          }
        }
      }
    }
  `,

  LabelByApSubject: styled.span`
    cursor: pointer;
    background-color: ${(props) => (props.bgColor ? props.bgColor : '#efefef')};
    border-radius: 0.25rem;
    /* display: flex; */
    padding: 0.35rem 0.25rem;
    justify-content: center;
    align-items: center;
    color: #505050;
    text-align: center;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1rem;
  `,
};
