import { useMemo } from 'react';
import QuestionDirection from './QuestionDirection';
import SourcesForReview from './SourcesForReview';

/** AP source 영역 컴포넌트 */
export default function ApLeftArea({ stateExamInfo, sourceDirections, source }) {
  // `sourceDirections` 가공
  const currentQuestionInfo = useMemo(() => {
    return sourceDirections;
  }, [sourceDirections]);

  // `source` 가공
  const currentSource = useMemo(() => {
    return source && source.length > 0
      ? source.map((item) => ({
          ...item,
          sourceContent: item.sourceContent,
        }))
      : [];
  }, [source]);

  return (
    <article className='wrap'>
      <div className='scroll_wrap'>
        {currentQuestionInfo && (
          <div style={{ position: 'relative' }} className='inner'>
            <QuestionDirection sourceDirections={currentQuestionInfo} />
          </div>
        )}
        {currentSource.length > 0 && (
          <div className='inner'>
            <SourcesForReview source={currentSource} />
          </div>
        )}
      </div>
    </article>
  );
}
