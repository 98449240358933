import { useEffect, useState } from 'react';
import styled from 'styled-components';
import CompleteModal from '../../../../../common/modals/CompleteModal';

/** 비율 점수 최대 값 */
const MAX_SCALED_SCORE = 5;

/** ap/com/score/grading/submission 페이지의 "전체 등급 점수 영역"의 "Total" 컴포넌트 */
export default function TotalScaledScoreArea({ totalScore, sectionInfo = [] }) {
  /** input 태그 값 상태 관리 */
  const [inputValue, setInputValue] = useState('1');
  /** "Complete" 버튼 활성화 해도 되는지 여부 (주관식 채점이 모두 끝나야 하고, scaled score까지 입력 되어야 한다.) */
  const [isReady, setIsReady] = useState(false);
  /** "Complete" 모달 노출 여부 */
  const [isViewCompleteModal, setIsViewCompleteModal] = useState(false);

  /** input 태그 onchange 핸들러 */
  const handleOnChangeInput = (event) => {
    const newValue = event.target.value;

    // 빈 문자열이거나 숫자가 아닌 경우 입력 방지
    if (newValue !== '' && !/^\d*\.?\d*$/.test(newValue)) return;

    setInputValue(newValue);
  };

  /** input 태그 blur 핸들러 */
  const handleOnBlur = () => {
    const numValue = Number(inputValue);

    if (numValue > MAX_SCALED_SCORE) setInputValue(String(MAX_SCALED_SCORE));
    else if (numValue < 1 || inputValue === '') setInputValue('1');
    else setInputValue(String(Math.round(numValue)));
  };

  /** "Complete" 버튼 핸들러 */
  const handleComplete = () => {
    setIsViewCompleteModal(true);
  };

  useEffect(() => {
    /** 채점하지 않은 문제가 해당 색션에 존재하는지 여부  */
    const isNotGradedSection = sectionInfo.find((sectionItem) => {
      /** 채점하지 않은 문제가 해당 파트에 존재하는지 여부  */
      const isNotGradedPart = sectionItem.partList.find((partItem) => {
        if (partItem.typeOfQuestion === 'E') {
          /** 채점하지 않은 문제가 해당 주관식에 존재하는지 여부  */
          const isNotGradedFrq = partItem.questionList.find((questionItem) => {
            /** 채점하지 않은 문제인지 여부 */
            const isNotGradedQuestion = questionItem.receivedPoint === null;
            return isNotGradedQuestion;
          });

          return isNotGradedFrq;
        }
      });
      return isNotGradedPart;
    });

    console.log('🚀 ~ useEffect ~ isNotGradedSection:', isNotGradedSection);
    if (isNotGradedSection === undefined) {
      // 채점하지 않는 문제가 하나도 없다면, -> 준비 완료
      setIsReady(true);
    } else {
      // 채점하지 않는 문제가 하나라도 존재 한다면, -> 준비 안됨
      setIsReady(false);
    }
  }, [sectionInfo]);

  return (
    <S.Wrap>
      <h3 className='title'>Total</h3>
      <dl>
        <dt>Raw Score</dt>
        <dd>
          <span className='value'>{totalScore.correctRawScore}</span> / {totalScore.totalRawScore}
        </dd>
      </dl>
      <dl>
        <dt>Scaled Score</dt>
        <dd>
          <input
            type='text'
            placeholder='Score'
            value={inputValue}
            onChange={handleOnChangeInput}
            onBlur={handleOnBlur}
            onFocus={() => {
              setIsViewCompleteModal(false);
            }}
          />{' '}
          / {MAX_SCALED_SCORE}
        </dd>
      </dl>
      <button type='button' title='' onClick={handleComplete} disabled={!isReady || inputValue.length <= 0} className={`primary_button`}>
        Complete
      </button>

      {isViewCompleteModal && <CompleteModal setShowModal={setIsViewCompleteModal} rawScore={totalScore.correctRawScore} scaledScore={inputValue} />}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    border-top: 1px solid #c5ccd2;
    background: #f8f8f9;
    padding: 1.25rem;
    & > .title {
      color: #384cc0;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }
    dl {
      display: flex;
      justify-content: space-between;
      & + dl {
        margin-top: 0.65rem;
      }
      dt {
        color: #111;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
      }
      dd {
        color: #111;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        align-items: center;
        gap: 0.55rem;
        .value {
          font-size: 1rem;
          font-weight: 500;
        }
        input {
          width: 4rem;
          height: 2.25rem;
          border-radius: 0.25rem;
          border: 1px solid #d7dadf;
          background: #fff;
          padding: 0.5rem 0.625rem;
        }
      }
    }
    .primary_button {
      margin-top: 1.25rem;
      width: 100%;
      border-radius: 0.375rem;
      background: #004a9e;
      padding: 1rem 1.25rem;
      color: #fff;
      font-size: 1.125rem;
      font-weight: 500;
      &:disabled {
        border: 1px solid #ebebeb;
        background-color: #ffffff;
        color: #999999;
      }
      &.warning {
        background-color: #f24b4b;
      }
    }
  `,
};
