import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as ImgSvgArrow } from 'assets/img/svg/icon_arrow_02.svg';

import ReactQuillEditor from 'components/_common/test-editor/quilljs/ReactQuillEditor';
import LabelText from '../common/styles/LabelText';

/** "Directions" 생성 영역 컴포넌트 */
export default function CreateDirections() {
  /** 영역 여닫기 여부 */
  const [isAreaOpen, setIsAreaOpen] = useState(false);
  /** 텍스트 에디터 컨텐츠 상태 */
  const [editorContentState, setEditorContentState] = useState('');

  /** "영역 여/닫기" 버튼 클릭 핸들러 */
  const handleToggle = () => {
    setIsAreaOpen((current) => !current);
  };

  return (
    <S.Wrap>
      <button type='button' title='open/close' onClick={handleToggle}>
        <LabelText className={`toggle_btn ${isAreaOpen ? 'on' : ''}`}>
          Create Directions <ImgSvgArrow />
        </LabelText>
      </button>
      {isAreaOpen && (
        <div>
          <ReactQuillEditor className='quill_editor' editorContentState={editorContentState} setEditorContentState={setEditorContentState} />
        </div>
      )}
    </S.Wrap>
  );
}

const S = {
  Wrap: styled.div`
    width: 100%;
    .toggle_btn {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      svg {
        width: 1rem;
        transition: 0.2s;
      }

      &.on {
        svg {
          transform: rotateZ(180deg);
        }
      }
    }
  `,
};
