import styled from 'styled-components';

/** 시험 생성 페이지에서 좌우 영역을 나누는 ui 컴포넌트 */
export default function UI_ExamContainer({ className = '', children }) {
  return <S.ExamContainer className={className}>{children}</S.ExamContainer>;
}

// --- --- --- Styled Components --- --- ---
const S = {
  ExamContainer: styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0.75rem 0;
    min-height: 100%;
    height: fit-content;
    max-width: 50%;

    &.left {
      align-items: flex-end;
    }
    &.right {
      align-items: flex-start;
    }

    .full_width {
      width: 100%;
    }
  `,
};
