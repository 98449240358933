// Components
import { useNavigate } from 'react-router-dom';
import Timer from '../apExam/_components/examLayout/examHeader/Timer';

// Functions
import { useEffect, useState } from 'react';
import { nvl } from 'utils/Common.utils';
import localStorage from 'utils/LocalStorage.utils';
import request from 'utils/Request.utils';

/** 쉬는 시간 렌더링 컴포넌트 */
export default function ExamBreakTimePage() {
  const userInfo = request.tokenDecoder();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // 우클릭 방지
    const preventContextMenu = (e) => e.preventDefault();
    window.addEventListener('contextmenu', preventContextMenu);

    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
    let sessionOrder = localStorage.getItemJsonParse(`apTest_${uthSeq}`).sessionOrder;
    const breakOrder = localStorage.getItemJsonParse(`apTest_${uthSeq}`).testInfo.breakTimeSession;

    // 쉬는 시간이면 시험 재진입시 localStorage에 값을 저장
    const isBreakTime = localStorage.getItemJsonParse('breakTime')?.isBreak || false;

    // 쉬는 시간으로 재진입
    if (isBreakTime) {
      localStorage.removeItem('breakTime');
      getQuestion(uthSeq, sessionOrder);
      return;
    }

    // 첫 진입시만 문제 생성
    if (sessionOrder === breakOrder) {
      updateTestStatus(uthSeq, 'B');
      const params = {
        questions: localStorage.getItemJsonParse(`apTest_${uthSeq}`).questionList,
      };

      const successHandler = (response) => {
        if (response.code === 200) {
          sessionOrder += 1;
          insertQuestion(uthSeq, sessionOrder);
        }
      };

      request.apPut('/api/v1/student/test', params, successHandler);
    } else {
      setIsLoading(false);
    }

    return () => {
      window.removeEventListener('contextmenu', preventContextMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 쉬는 시간으로 상태 변경
  const updateTestStatus = (uthSeq, testStatus) => {
    const params = {
      testStatus: testStatus,
    };

    const successHandler = (response) => {
      if (response.code === 200) {
        // 부모 창의 함수 호출
        if (window.opener && typeof window.opener.getUpcomingTestList === 'function') {
          window.opener.getUpcomingTestList();
        } else {
          console.error('부모창의 함수를 찾을 수 없습니다.');
        }
      }
    };

    request.apPut(`/api/v1/student/test/${uthSeq}/status`, params, successHandler);
  };

  // 문제 생성
  const insertQuestion = (uthSeq, sessionOrder) => {
    const params = {
      uthSeq: uthSeq,
      sessionOrder: sessionOrder,
    };

    const successHandler = (response) => {
      if (response.code === 201 || response.code === 409) {
        getQuestion(uthSeq, sessionOrder);
      }
    };

    request.apPost('/api/v1/student/test', params, successHandler);
  };

  const getQuestion = (uthSeq, sessionOrder) => {
    const successHandler = (response) => {
      if (response.code === 200) {
        // console.log(response.result.getTestSessionQuestionList);
        const { testSessionName, questionList, totalQuestionCount, totalTime } = response.result.getTestSessionQuestionList;

        localStorage.setItem(`apTest_${uthSeq}`, 'sessionOrder', sessionOrder); // 세션 순서 업데이트
        localStorage.setItem(`apTest_${uthSeq}`, 'sessionName', testSessionName);
        localStorage.setItem(`apTest_${uthSeq}`, 'questionList', questionList);
        localStorage.setItem(`apTest_${uthSeq}`, 'totalQuestionCount', totalQuestionCount);
        localStorage.setItem(`apTest_${uthSeq}`, 'currentNum', 1);
        localStorage.setItem(`apTest_${uthSeq}`, 'totalTime', totalTime);
      }
      setIsLoading(false);
    };

    request.apGet(`/api/v1/student/test?uthSeq=${uthSeq}&sessionOrder=${sessionOrder}`, null, successHandler);
  };

  // 쉬는 시간 끝
  const handleNext = () => {
    const uthSeq = localStorage.getItemJsonParse('currentTest').uthSeq;
    updateTestStatus(uthSeq, 'I');
    localStorage.setItem(`apTest_${uthSeq}`, 'startTime', Date.now());
    navigate('/ap/exam/test', { replace: true });
  };

  return (
    <>
      <div className='com_popup active pop_exam_break' onContextMenu={(e) => e.preventDefault()}>
        <div className='pop_container'>
          <section className='pop_body'>
            <article className='time_sec'>
              <div className='time_area'>
                <p className='tit'>Remaining Break Time:</p>
                <div className='time'>
                  <Timer variant='breakTime' />
                </div>
              </div>
              <div className='com_btn_wrap bottom'>
                <button className='com_btn yellow l oval' onClick={handleNext} disabled={isLoading}>
                  Resume Testing
                </button>
              </div>
            </article>
            <article className='info_sec'>
              <dl className='item'>
                <dt className='dt'>Test Break</dt>
                <dd className='dd'>
                  You can resume this test as soon as you’re ready to move on. On test day, you’ll wait until the clock counts down. Read below to see how
                  breaks work on test day.
                </dd>
              </dl>
              <dl className='item'>
                <dt className='dt'>Take a Break: Do Not Close Your Device</dt>
                <dd className='dd'>You may leave the room, but do not disturb students who are still testing.</dd>
                <dd className='dd'>Testing won’t resume until you return.</dd>
                <dd className='dd'>
                  <b>Follow these rules during the break:</b>
                </dd>
                <dd className='dd'>
                  <ul className='list'>
                    <li>Do not access your phone, smartwatch, textbooks, notes, or the internet.</li>
                    <li>Do not eat or drink in the test room. </li>
                    <li>Do not speak in the test room; outside the test room, do not discuss the exam with anyone.</li>
                  </ul>
                </dd>
              </dl>
            </article>
          </section>
          <section className='pop_breaktime_footer'>
            <p className='name'>{nvl(userInfo?.userName)}</p>
          </section>
        </div>
      </div>
    </>
  );
}
