import styled from 'styled-components';

/** 시험 : 튜토리얼 페이지의 Footer 영역 */
export default function ExamTutorialFooter({ handleNext }) {
  return (
    <section className='pop_footer'>
      <article className='btn_area'>
        <S.Button className='btn' onClick={handleNext}>
          Next
        </S.Button>
      </article>
    </section>
  );
}

const S = {};

S.Button = styled.button`
  &:disabled {
    background-color: #999999;
  }
`;
