import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useObfuscatedSearchParam } from 'hooks/useObfuscatedSearchParam';
import { isoTimeToDayjs } from 'utils/functions/time/dayjs-config';
import request from 'utils/Request.utils';
import SessionStorage from 'utils/SessionStorage.utils';
import { obfuscateUrlParam } from 'utils/urlParamObfuscator';

import { ReactComponent as ImgSvgIconPrinter } from 'assets/img/svg/icon_printer_02.svg';
import SimpleModalBase from 'components/_common/modals/_SimpleModalBase';
import { Link } from 'react-router-dom';

/** 아카데미 대시보드의 Students 페이지 : "Score History" 모달 컴포넌트 */
function ScoreHistoryModal({ setShowModal, userSeq, userName }) {
  const { navigateWithParams } = useObfuscatedSearchParam();

  const [scoreHistory, setScoreHistory] = useState([{ class: '', testDate: '', testName: '', rw: null, math: null, totalScore: null }]); // [ { class: '6월 시험 대비반', testDate: '12 / 29 / 2024', testName: 'level test', rw: 500, math: 700, totalScore: 1200 }
  /** 리포트 카드 열기 */
  const [reportCardActive, setReportCardActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const reportHandler = (uthSeq, userSeq) => {
    SessionStorage.clearItem('reportCard');
    SessionStorage.setItem('reportCard', 'uthSeq', uthSeq);
    SessionStorage.setItem('reportCard', 'userSeq', userSeq);

    setReportCardActive(true);
  };

  // ap 리포트 카드 열기
  const goToApReportCard = (uthSeq) => {
    window.open(`/ap/com/report/${obfuscateUrlParam(uthSeq)}`, '_blank');
  };

  /** "Score History" 인쇄 페이지 이동 버튼 핸들러  */
  const handlePrintScoreHistoryPage = ({ userSeq }) => {
    if (userSeq && userName) {
      const destinationUrl = navigateWithParams(`/com/students/score-history-print/${obfuscateUrlParam(String(userSeq))}`, {
        newParams: {
          userName,
        },
        isNavigate: false,
      });

      // "userSeq" 를 암호화 하여 라우터 파라미터로 전달
      window.open(destinationUrl, '_blank');
    } else {
      alert('userSeq 가 없습니다.');
    }
  };

  useEffect(() => {
    let scoreHistory = [{ class: '', testDate: '', testName: '', rw: null, math: null, totalScore: null }];
    // 화면 열고 나서 바로 실행되는 함수
    const successHandler = (response) => {
      if (response.code === 200) {
        let scoreHistoryList = response.result.scoreHistory;
        scoreHistory = scoreHistoryList;

        getApScoreHistory();
        setIsLoading(false);
      }
    };
    request.get(`/api/member/student/score/history?userSeq=${userSeq}`, null, successHandler).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });

    // ap test list 가져오기
    const getApScoreHistory = () => {
      const successHandler = (response) => {
        if (response.code === 200) {
          let apScoreHistoryList = response.result.scoreHistory;

          // ap 시험 정보와 합치기
          scoreHistory = scoreHistory.concat(apScoreHistoryList);

          // 시험일자로 정렬
          scoreHistory.sort((a, b) => {
            return new Date(b.testTakenEndDate) - new Date(a.testTakenEndDate);
          });

          setScoreHistory(scoreHistory);
        }
      };

      request.apGet(`/api/v1/academy/student/score/history?studentSeq=${userSeq}`, null, successHandler).catch((error) => {
        console.error(error);
      });
    };
  }, [userSeq]);

  useEffect(() => {
    if (reportCardActive) {
      window.open(`/com/reports`, '_blank');
      setReportCardActive(false);
    }
  }, [reportCardActive]);

  // ap 학원인경우 true
  const [userInfo, _] = useState(request.tokenDecoder());
  const apAcademySeq = Number(process.env.REACT_APP_AP_ACADEMY); // ap가 보이는 학원 seq
  const isApAcademy = userInfo?.ecSeq === apAcademySeq;

  return (
    <SimpleModalBase onEscape={() => setShowModal(false)} p='0 1.25rem 1.25rem' w='60rem' gap='0'>
      {isLoading ? (
        'Loading...'
      ) : (
        <S.Wrap>
          <S.ModalTitleContainer className='modal_title_container'>
            <div className='modal_title_area left'>
              <S.ModalTitle>{userName}</S.ModalTitle>

              {/* 데이터가 있을때만 프린트 버튼 노출 */}
              {!isApAcademy && Array.isArray(scoreHistory) && scoreHistory.length > 0 && (
                <button
                  type='button'
                  title='Open in a New Tab'
                  className='btn_main_print'
                  onClick={() => {
                    handlePrintScoreHistoryPage({ userSeq });
                  }}>
                  <ImgSvgIconPrinter />
                </button>
              )}
            </div>
            <button className='modal_title_btn' onClick={() => setShowModal(false)}>
              <i className='svg_icon btn_pop_close black'>&nbsp;</i>
            </button>
          </S.ModalTitleContainer>

          <S.TableScrollWrapper className='custom_scroll'>
            <S.Table>
              <thead>
                <S.HeaderTableRow>
                  <th className='col_01'>No.</th>
                  {isApAcademy && <th className='col_04'>TEST FORMAT</th>}
                  <th className='col_02'>CLASS</th>
                  <th className='col_03'>DATE TAKEN</th>
                  <th className='col_04'>TEST NAME</th>

                  <th className='col_05'>RW</th>
                  <th className='col_06'>MATH</th>
                  <th className='col_07'>TOTAL SCORE</th>

                  <th className='col_08'>SCORE</th>
                  {!isApAcademy && <th className='col_09'>PDF</th>}
                </S.HeaderTableRow>
              </thead>

              <tbody>
                {Array.isArray(scoreHistory) && scoreHistory.length > 0 ? (
                  scoreHistory.map((item, index) => (
                    <Fragment key={`score_${index}`}>
                      <S.MainTableRow>
                        <td>{scoreHistory.length - index}</td>
                        {isApAcademy && <td>{item.apSubject ? item.apSubject : 'SAT'}</td>}
                        <td>{item.className}</td>
                        <td>{isoTimeToDayjs(item.testTakenEndDate).format('YYYY-MM-DD')}</td>
                        <td>{item.testName}</td>
                        {!isApAcademy ? (
                          <>
                            <td>{item.rwScore === 0 ? '-' : item.rwScore}</td>
                            <td>{item.mathScore === 0 ? '-' : item.mathScore}</td>
                            <td>{item.rwScore + item.mathScore}</td>
                          </>
                        ) : (
                          <>
                            <td> {item.rwScore == null ? '-' : item.rwScore} </td>
                            <td> {item.mathScore == null ? '-' : item.mathScore} </td>
                            <td>{item.totalScore ? item.totalScore : item.rwScore + item.mathScore ? item.rwScore + item.mathScore : '-'}</td>
                          </>
                        )}

                        <td className='btn-item'>
                          <div>
                            <button onClick={() => (item.apSubject ? goToApReportCard(item.uthSeq) : reportHandler(item.uthSeq, item.userSeq))}>
                              <i className='svg_icon icon_score_report'>&nbsp;</i>
                            </button>
                          </div>
                        </td>
                        {!isApAcademy && (
                          <td className='btn-item'>
                            <div>
                              <Link
                                target='_new'
                                to={`/test/test/view?viewTest=${encodeURIComponent(item.uthSeqStr)}&type=student`}
                                className='svg_icon icon_report_pdf'></Link>
                            </div>
                          </td>
                        )}
                      </S.MainTableRow>
                    </Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan='7' style={{ padding: '3rem' }}>
                      No data
                    </td>
                  </tr>
                )}
              </tbody>
            </S.Table>
          </S.TableScrollWrapper>
          {!isApAcademy && (
            <S.TableFooter>
              <tfoot>
                <tr>
                  <td className='col_01'>&nbsp;</td>
                  <td className='col_02'>&nbsp;</td>
                  <td className='col_03'>&nbsp;</td>
                  <td className='col_04'>Average</td>
                  <td className='col_05'>
                    {Array.isArray(scoreHistory) && scoreHistory.length > 0
                      ? Math.ceil(
                          scoreHistory.reduce((accumulator, currentObject) => {
                            return accumulator + currentObject.rwScore;
                          }, 0) / scoreHistory.filter((S) => S.rwScore > 0).length
                        )
                      : 0}
                  </td>
                  <td className='col_06'>
                    {Array.isArray(scoreHistory) && scoreHistory.length > 0
                      ? Math.ceil(
                          scoreHistory.reduce((accumulator, currentObject) => {
                            return accumulator + currentObject.mathScore;
                          }, 0) / scoreHistory.filter((S) => S.mathScore > 0).length
                        )
                      : 0}
                  </td>
                  <td className='col_07'>
                    {Array.isArray(scoreHistory) && scoreHistory.length > 0
                      ? Math.ceil(
                          scoreHistory.reduce((accumulator, currentObject) => {
                            const totalScore = currentObject.rwScore + currentObject.mathScore;
                            return accumulator + totalScore;
                          }, 0) / scoreHistory.filter((S) => S.rwScore > 0).length
                        )
                      : 0}
                  </td>
                  <td className='col_08'>&nbsp;</td>
                  <td className='col_09'>&nbsp;</td>
                </tr>
              </tfoot>
            </S.TableFooter>
          )}
        </S.Wrap>
      )}
    </SimpleModalBase>
  );
}

export default ScoreHistoryModal;

// --- --- ---

const S = {
  Wrap: styled.div`
    position: relative;
  `,

  ModalTitleContainer: styled.div`
    .left {
      display: flex;
      align-items: center;
    }
    .btn_main_print {
      width: 2rem;
      height: 2rem;
      border: 1px solid #f1f1f5;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
      svg {
        width: 1.5rem;
        color: #505050;
      }
      &:hover {
        filter: brightness(95%);
        svg {
          color: #808080;
        }
      }
    }
  `,
}; // S-dot 문법용 객체

S.ModalTitle = styled.h2`
  color: #111111;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1.2rem 1rem;
`;

S.TableScrollWrapper = styled.div`
  max-height: 23.5rem;
  background-color: white;
  padding-bottom: 3rem;
`;
S.Table = styled.table`
  width: 100%;
  text-align: center;
  th,
  td {
    &.col_01 {
      width: 3.38rem;
    }
    &.col_02 {
      width: auto;
    }
    &.col_03 {
      width: 7.25rem;
    }
    &.col_04 {
      width: 9rem;
    }
    &.col_05 {
      width: 4.3rem;
    }
    &.col_06 {
      width: 4.44rem;
    }
    &.col_07 {
      width: 7.63rem;
    }
    &.col_08 {
      width: 3.5rem;
    }
    &.col_09 {
      width: 3.5rem;
    }
  }
`;

S.HeaderTableRow = styled.tr`
  position: sticky;
  z-index: 2;
  margin-bottom: 1px;
  th {
    position: sticky;
    top: 0;
    background-color: #000;
    color: #fff;
    height: 2.5rem;
    text-align: center;
    font-weight: 400;
    font-size: 0.875rem;
    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      overflow: hidden;
    }
    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      overflow: hidden;
    }
  }
`;
S.MainTableRow = styled.tr`
  border-top: 1px solid #eff1f3;

  td {
    font-size: 0.875rem;
    height: 3rem;
    color: #4b5563;
  }
  .btn-item {
    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
`;

S.TableFooter = styled.table`
  height: 3rem;
  background-color: #ffffff;
  border-bottom: 1px solid #eff1f3;
  position: absolute;
  bottom: 0;
  width: 100%;

  td {
    border-top: 1px solid #505050;
    text-align: center;
    &.col_01 {
      width: 3.38rem;
    }
    &.col_02 {
      width: auto;
    }
    &.col_03 {
      width: 7.25rem;
    }
    &.col_04 {
      width: 9.38rem;
    }
    &.col_05 {
      width: 4.3rem;
    }
    &.col_06 {
      width: 4.44rem;
    }
    &.col_07 {
      width: 7.63rem;
    }
    &.col_08 {
      width: 3.5rem;
    }
    &.col_09 {
      width: 3.5rem;
    }
  }

  .col_05,
  .col_06,
  .col_07 {
    font-weight: 700;
  }
`;
