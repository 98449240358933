import React, { useRef } from 'react';
import ReactApexChart from 'react-apexcharts';

const StackedColumnsChart = ({ colChartData, currentClassSeq, setCurrentClassSeq }) => {
  const series = colChartData.series;

  const clickRememberRef = useRef(-1);

  // 그래프에 문자열을 지우는 코드
  colChartData.xaxis.categories = colChartData.xaxis.categories.map((item) => '');

  const options = {
    chart: {
      type: 'bar',
      height: 260,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
      events: {
        dataPointMouseEnter: function (e, chartContext, config) {
          let seriesData = chartContext.w.config.series[config.seriesIndex]; // [config.seriesIndex];

          setCurrentClassSeq(seriesData.seq[config.dataPointIndex]);

          clickRememberRef.current = config.dataPointIndex;
        },
        dataPointMouseLeave: function () {
          setCurrentClassSeq(0);

          clickRememberRef.current = -1;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: colChartData.xaxis.categories.length === 1 ? '30%' : '70%',
        dataLabels: {
          total: {
            enabled: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: colChartData.enabled,
    },
    xaxis: colChartData.xaxis,
    yaxis: colChartData.yaxis,
    legend: {
      // show: colChartData.labels,
      show: false,
      position: 'top',
      offsetY: -4,
      horizontalAlign: 'center',
      fontSize: '13px',
      labels: {
        colors: ['#000000', '#F90303', '#000000'],
      },
      marginBottom: '1%',
    },
    fill: {
      opacity: 1,
    },
    colors: colChartData.colors,
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.7,
        },
      },
    },
  };

  return (
    <div id='chart'>
      <ReactApexChart options={options} series={series} type='bar' height={320} />
    </div>
  );
};

export default StackedColumnsChart;
