//#region    ////////// packages
import { useState, useRef, useEffect } from 'react';
import Draggable from 'react-draggable';
import styled, { css } from 'styled-components';
//#endregion ////////// packages

//#region    ////////// components
import PeriodicTable from './_components/periodicTable/PeriodicTable';
import ChemistryEquations from './_components/equations/ChemistryEquations';
import BiologyEquations from './_components/equations/BiologyEquations';
import PhysicsAlgebraBasedEquations from './_components/equations/PhysicsAlgebraBasedEquations';
import StatisticsEquations from './_components/equations/StatisticsEquations';
import StatisticsTableEquations from './_components/equations/StatisticsTableEquations';
import ComputerScienceAEquations from './_components/equations/ComputerScienceAEquations';
import ComputerSciencePrinciplesEquations from './_components/equations/ComputerSciencePrinciplesEquations';
//#endregion ////////// components

//#region    ////////// utils & hooks
import usePressESC from 'hooks/usePressESC';
//#endregion ////////// utils & hooks

//#region    ////////// constants
import { SUBJECT_SEQUENCE } from './_constant';
//#endregion ////////// constants

//#region    ////////// assets
import ImgBtnMove from 'assets/img/exam/btn_move.png';
import ImgBtnMoveOn from 'assets/img/exam/btn_move_on.png';
import ImgExpand from 'assets/img/exam/icon_expand.png';
import ImgCollapse from 'assets/img/exam/icon_collapse.png';
//#endregion ////////// assets

//#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) --- START ////////////////////
const CHEMISTRY_TAB_LIST = [
  { label: 'Periodic Table', Component: PeriodicTable },
  { label: 'Equations', Component: ChemistryEquations },
];
const BIOLOGY_TAB_LIST = [{ label: 'Equations', Component: BiologyEquations }];
const PHYSICS_ALGEBRA_BASED_TAB_LIST = [{ label: 'Equations', Component: PhysicsAlgebraBasedEquations }];
const STATISTICS_TAB_LIST = [
  { label: 'Statistics', Component: StatisticsEquations },
  { label: 'Tables', Component: StatisticsTableEquations },
];
const COMPUTER_SCIENCE_A_TAB_LIST = [{ label: 'Equations', Component: ComputerScienceAEquations }];
const COMPUTER_SCIENCE_PRINCIPLES_TAB_LIST = [{ label: 'Equations', Component: ComputerSciencePrinciplesEquations }];
// subjectSeq에 따라 적절한 탭 리스트 선택
const getTabList = (subjectSeq) => {
  if (subjectSeq === SUBJECT_SEQUENCE.BIOLOGY) return BIOLOGY_TAB_LIST;
  else if (subjectSeq === SUBJECT_SEQUENCE.CHEMISTRY) return CHEMISTRY_TAB_LIST;
  else if (subjectSeq === SUBJECT_SEQUENCE.PHYSICS_ALGEBRA_BASED) return PHYSICS_ALGEBRA_BASED_TAB_LIST;
  else if (subjectSeq === SUBJECT_SEQUENCE.STATISTICS) return STATISTICS_TAB_LIST;
  else if (subjectSeq === SUBJECT_SEQUENCE.COMPUTER_SCIENCE_A) return COMPUTER_SCIENCE_A_TAB_LIST;
  else if (subjectSeq === SUBJECT_SEQUENCE.COMPUTER_SCIENCE_PRINCIPLES) return COMPUTER_SCIENCE_PRINCIPLES_TAB_LIST;
  else return CHEMISTRY_TAB_LIST;
};
//#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 안됨 ) ---  END  ////////////////////

/** AP 시험 Reference Sheet 팝업 */
export default function ApReferenceSheetPopup({ isShowRefSheet, setIsShowRefSheet, subjectSeq }) {
  //#region    ////////////////// START --- 상태 관리 ( useState, etc ) --- START ////////////////////
  const [isPopupExpand, setIsPopupExpand] = useState(false); // 팝업 창 확장
  const [isDragging, setIsDragging] = useState(false); // 드래그 상태
  // 드래그 상태 관리
  const [dragState, setDragState] = useState({
    activeDrags: 0,
    deltaPosition: { x: 0, y: 0 },
    controlledPosition: { x: -400, y: 200 },
  });
  const [currentTab, setCurrentTab] = useState(null); // 현재 탭
  const [tabList, setTabList] = useState([]); // 탭 리스트
  //#endregion //////////////////  END  --- 상태 관리 ( useState, etc ) ---  END  ////////////////////

  //#region    ////////////////// START --- Ref --- START ////////////////////
  const nodeRef = useRef(null);
  //#endregion //////////////////  END  --- Ref ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  const onStart = () => setDragState({ ...dragState, activeDrags: ++dragState.activeDrags });
  const onStop = () => setDragState({ ...dragState, activeDrags: --dragState.activeDrags });
  const dragHandlers = { onStart, onStop };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- React useEffect --- START ////////////////////
  useEffect(() => {
    const newTabList = getTabList(subjectSeq);

    setTabList(newTabList);
    setCurrentTab(newTabList[0]);
  }, [subjectSeq]);
  //#endregion //////////////////  END  --- React useEffect ---  END  ////////////////////

  //#region    ////////////////// START --- 커스텀 훅 --- START ////////////////////
  usePressESC(() => setIsShowRefSheet(false), isShowRefSheet);
  //#endregion //////////////////  END  --- 커스텀 훅 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  if (!isShowRefSheet) return null;
  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return (
    <Draggable nodeRef={nodeRef} handle='strong' {...dragHandlers}>
      <S.PopupContainer ref={nodeRef} $isExpanded={isPopupExpand}>
        <S.PopupHeader onMouseDown={() => setIsDragging(true)} onMouseUp={() => setIsDragging(false)}>
          <div className='reference_sheet_title'>Reference Sheet</div>
          <S.MoveIndicator>
            <img src={ImgBtnMove} alt='move' style={{ opacity: isDragging ? 0 : 1 }} />
            <img src={ImgBtnMoveOn} alt='move' style={{ opacity: isDragging ? 1 : 0 }} />
          </S.MoveIndicator>
          <div className='button_area'>
            <button
              className='expand_button'
              onClick={(e) => {
                e.stopPropagation();
                setIsPopupExpand(!isPopupExpand);
              }}>
              <img src={isPopupExpand ? ImgCollapse : ImgExpand} alt='expand' />
              {isPopupExpand ? 'Collapse' : 'Expand'}
            </button>
            <button
              className='svg_icon btn_pop_close white'
              onClick={(e) => {
                e.stopPropagation();
                setIsShowRefSheet(false);
              }}>
              &nbsp;
            </button>
          </div>
        </S.PopupHeader>

        <S.PopupBody>
          {tabList.length > 1 && (
            <S.TabList>
              {tabList.map((tab, index) => (
                <li
                  className={`tab_item ${currentTab.label === tab.label ? 'active' : ''}`}
                  key={`ApReferenceSheetPopup-${subjectSeq}-${index}`}
                  onClick={() => setCurrentTab(tab)}>
                  {tab.label}
                </li>
              ))}
            </S.TabList>
          )}

          <S.TabContent className='custom_scroll'>
            <currentTab.Component />
          </S.TabContent>
        </S.PopupBody>
      </S.PopupContainer>
    </Draggable>
  );
}

// --- --- --- Styled Components --- --- --- //
const S = {
  PopupContainer: styled.div`
    background-color: white;
    position: fixed;
    left: 15px; // 원본 코드 치수
    top: 75px; // 원본 코드 치수
    z-index: 1000;
    width: 30vw; // 원본 코드 치수
    height: calc(100vh - 100px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    ${({ $isExpanded }) =>
      $isExpanded &&
      css`
        width: 65vw; // 원본 코드 치수
      `}
  `,

  PopupHeader: styled.strong`
    background: #1e1e1e;
    color: #fff;
    height: 44px;
    padding: 0 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .reference_sheet_title {
      font-size: 16px;
      font-weight: 700;
    }

    .button_area {
      display: flex;
      align-items: center;
      gap: 20px;

      .expand_button {
        display: flex;
        gap: 6px;
        align-items: center;
        color: white;
        font-weight: 700;
        transition: opacity 0.2s;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    cursor: grab;
    :active {
      cursor: grabbing;
    }
    user-select: none;
  `,
  MoveIndicator: styled.div`
    width: 31px;
    height: 25px;
    pointer-events: none;
    position: relative;

    img {
      transition: opacity 0.2s ease;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `,

  PopupBody: styled.div`
    border: 1px solid #1e1e1e;
    border-top: none;
    height: calc(100% - 44px);
  `,

  TabContent: styled.div`
    height: calc(100% - 44px);
    overflow-y: auto;
  `,

  TabList: styled.ul`
    font-family: 'Noto Serif', serif;
    padding: 0 1rem;
    display: flex;
    gap: 10px;
    background-color: #e6edf8;

    .tab_item {
      padding: 10px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      &.active {
        border-bottom: 2px solid black;
        font-weight: 700;
      }
    }
  `,
};
