import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { EDITOR_CONSTANT } from '../_constant';
import UI_MiniReview from './ui/UI_MiniReview';

import ApSettingSelectBox from 'components/ap/com/ap-components/_components/SourceSettingPopup/_components/ApSettingSelectBox';
import styled from 'styled-components';

/** 문제 선택 (Mini Review 팝업) 컴포넌트  */
export default function MiniReview({ currentPartQuestion, editorState, setIsShowMiniReview, handleQuestionNavigation }) {
  //#region    ////////////////// START --- 외부 라이브러리 관련 --- START ////////////////////
  const apTestEditorReducer = useSelector((state) => state.apTestEditorReducer);
  //#endregion //////////////////  END  --- 외부 라이브러리 관련 ---  END  ////////////////////

  //#region    ////////////////// START --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) --- START ////////////////////
  /** 테스트의 전체 섹션 데이터 */
  const testSectionData = apTestEditorReducer.sectionMetadata.sections;
  /** 테스트의 전체 파트 데이터 */
  const testPartData = apTestEditorReducer.sectionMetadata.sections.map((item) => item.parts).flat();
  //#endregion //////////////////  END  --- 초기 데이터 및 상수 정의( 컴포넌트에 종속 ) ---  END  ////////////////////

  //#region    ////////////////// START --- 이벤트 핸들러 및 유틸리티 --- START ////////////////////
  /** 받은 문제 번호가 설정되어 있는지 확인 ( 현재 문제 번호는 true 반환 ) */
  const isSetQuestion = (questionIndex) => {
    if (apTestEditorReducer.currentQuestion_index === questionIndex) return true;
    return currentPartQuestion.some((question) => question.question_order === questionIndex);
  };

  /** 현재 Section 변경 handler */
  const handleSelectSection = (value) => {
    const selectedSection = testSectionData.find((item) => Number(item.section_seq) === Number(value)); // 선택된 section

    if (selectedSection) {
      const targetPartSeq = selectedSection.parts[0].part_seq;
      handleQuestionNavigation(0, targetPartSeq); // 선택된 섹션의 첫번째 파트의 첫번째 문제로 이동
    } else console.error('유효하지 않은 section_seq');
  };
  /** 현재 Part 변경 handler */
  const handleSelectPart = (value) => {
    const selectedPart = testPartData.find((item) => Number(item.part_seq) === Number(value)); // 선택된 part

    if (selectedPart) {
      const targetPartSeq = selectedPart.part_seq;
      handleQuestionNavigation(0, targetPartSeq); // 선택된 파트의 첫번째 문제로 이동
    } else console.error('유효하지 않은 part_seq');
  };
  //#endregion //////////////////  END  --- 이벤트 핸들러 및 유틸리티 ---  END  ////////////////////

  //#region    ////////////////// START --- 랜더링 보조 --- START ////////////////////
  /** 현재 선택된 Section 옵션 */
  const sectionOptions = testSectionData.map((item) => ({
    label: item.section_name,
    value: item.section_seq,
  }));
  /** 현재 선택된 Part 옵션 */
  const partOptions = editorState[EDITOR_CONSTANT.currentSection].parts.map((item) => ({
    label: item.name,
    value: item.part_seq,
  }));
  /** Section, Part 선택 영역 */
  const selectArea = (
    <S.SelectArea>
      <ApSettingSelectBox
        options={sectionOptions}
        onSelect={(value) => handleSelectSection(value)}
        defaultValue={editorState[EDITOR_CONSTANT.currentSection].section_seq}
        className='review_select_box'
      />
      {partOptions.length > 1 && (
        <ApSettingSelectBox
          options={partOptions}
          onSelect={(value) => handleSelectPart(value)}
          defaultValue={editorState[EDITOR_CONSTANT.currentPart].part_seq}
          className='review_select_box'
        />
      )}
    </S.SelectArea>
  );

  /** 미니 리뷰 버튼들 */
  const questionButtons = Array.from({ length: editorState[EDITOR_CONSTANT.currentPart].number_of_questions }, (_, index) => (
    <Fragment key={`mini_${index}`}>
      <button className={`item ${isSetQuestion(index) ? 'answered' : ''}`} onClick={() => handleQuestionNavigation(index)}>
        {editorState[EDITOR_CONSTANT.currentIndex] === index && <i className='svg_icon icon_current current'>&nbsp;</i>}
        {currentPartQuestion.find((question) => question.question_order === index)?.custom_question_order ?? index + 1}
      </button>
    </Fragment>
  ));

  //#endregion //////////////////  END  --- 랜더링 보조 ---  END  ////////////////////

  return <UI_MiniReview onClose={() => setIsShowMiniReview(false)} selectArea={selectArea} questionButtons={questionButtons} />;
}

// --- --- --- Styled Components --- --- --- //
const S = {
  SelectArea: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .review_select_box {
      max-width: none;
      width: 100%;
      font-weight: 600;
      * div {
        font-size: 1.125rem;
      }
    }
  `,
};